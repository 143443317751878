import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Grid, FormControl, TextField, MenuItem, Paper, Divider, Checkbox, FormControlLabel } from '@material-ui/core';
import { IRootState } from 'app/shared/reducers';
import './automated-control-room.scss';
import { Autocomplete } from '@material-ui/lab';
import { getEntities as serviceAreaEntities, reset as serviceAreaReset } from '../service-area/service-area.reducer';
import IOSSwitch from 'app/modules/Setting/RolesAndPermissionsSettings/Components/IOSswitchComponent';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Chip from '@material-ui/core/Chip';
import Switch from '@material-ui/core/Switch';
import { ACRGeneralSettings } from 'app/shared/model/automated-control-room-general-settings.model';

export interface IAutomatedComtrolRoomGeneralProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
  values?: ACRGeneralSettings;
  setValues?: any;
  ACRGeneralSettingsValues?: ACRGeneralSettings;
}

export const General: React.FC<IAutomatedComtrolRoomGeneralProps> = props => {
  const { values, setValues } = props;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [switchButtons, setSwitchButtons] = useState({
    all_service_areas_button: false,
    'Shift Confirmation': false,
    'Sign In': false,
    'Sign Out': false,
    'Welfare Check': false,
  });

  useEffect(() => {
    props.serviceAreaReset();
    props.serviceAreaEntities(-1, '');
  }, []);

  useEffect(() => {
    setSwitchButtons({
      all_service_areas_button: false,
      'Shift Confirmation': false,
      'Sign In': false,
      'Sign Out': false,
      'Welfare Check': false,
    });
  }, [props.ACRGeneralSettingsValues]);

  const handleReminders = event => {
    if (event.target.name === 'outbound_reminders') {
      if (event.target.checked === true) {
        setValues(oldValues => ({ ...oldValues, reminders: { ...oldValues.reminders, outbound_reminders: true } }));
      } else {
        setValues(oldValues => ({
          ...oldValues,
          reminders: { ...oldValues.reminders, outbound_reminders: false },
          settingsNotifications: { ...oldValues.settingsNotifications, SMS_OUTBOUND: false, CALL_OUTBOUND: false, EMAIL_OUTBOUND: false },
        }));
      }
    }
    if (event.target.name === 'inbound_reminders') {
      if (event.target.checked === true) {
        setValues(oldValues => ({ ...oldValues, reminders: { ...oldValues.reminders, inbound_reminders: true } }));
      } else {
        setValues(oldValues => ({
          ...oldValues,
          reminders: { ...oldValues.reminders, inbound_reminders: false },
          settingsNotifications: { ...oldValues.settingsNotifications, SMS_INBOUND: false, CALL_INBOUND: false, EMAIL_INBOUND: false },
        }));
      }
    }
  };

  const handleInboundMethod = tag => {
    if (tag === 'SMS') {
      setValues(oldValues => ({
        ...oldValues,
        settingsNotifications: { ...oldValues.settingsNotifications, SMS_INBOUND: !values?.settingsNotifications?.SMS_INBOUND },
      }));
    } else if (tag === 'Call') {
      setValues(oldValues => ({
        ...oldValues,
        settingsNotifications: { ...oldValues.settingsNotifications, CALL_INBOUND: !values?.settingsNotifications?.CALL_INBOUND },
      }));
    } else if (tag === 'Email') {
      setValues(oldValues => ({
        ...oldValues,
        settingsNotifications: { ...oldValues.settingsNotifications, EMAIL_INBOUND: !values?.settingsNotifications?.EMAIL_INBOUND },
      }));
    }
  };

  const handleOutboundMethod = tag => {
    if (tag === 'SMS') {
      setValues(oldValues => ({
        ...oldValues,
        settingsNotifications: { ...oldValues.settingsNotifications, SMS_OUTBOUND: !values?.settingsNotifications?.SMS_OUTBOUND },
      }));
    } else if (tag === 'Call') {
      setValues(oldValues => ({
        ...oldValues,
        settingsNotifications: { ...oldValues.settingsNotifications, CALL_OUTBOUND: !values?.settingsNotifications?.CALL_OUTBOUND },
      }));
    } else if (tag === 'Email') {
      setValues(oldValues => ({
        ...oldValues,
        settingsNotifications: { ...oldValues.settingsNotifications, EMAIL_OUTBOUND: !values?.settingsNotifications?.EMAIL_OUTBOUND },
      }));
    }
  };

  const handleServiceAreas = (_, companies) => {
    setValues(oldValues => ({ ...oldValues, serviceAreas: companies }));
  };

  const handleAllServiceAreas = event => {
    if (switchButtons.all_service_areas_button) {
      setSwitchButtons(oldValues => ({ ...oldValues, all_service_areas_button: false }));
      setValues(oldValues => ({ ...oldValues, serviceAreas: [] }));
    } else {
      setSwitchButtons(oldValues => ({ ...oldValues, all_service_areas_button: true }));
      setValues(oldValues => ({ ...oldValues, serviceAreas: props.serviceAreaList }));
    }
  };

  const handleNotificationSwitches = event => {
    setSwitchButtons(oldValues => ({ ...oldValues, [event.target.name]: event.target.checked }));

    setValues(oldValues => ({
      ...oldValues,
      shiftExecutionEventTypes: { ...oldValues.shiftExecutionEventTypes, [event.target.name]: event.target.checked },
    }));
  };

  return (
    <div className="general-main-container">
      <div className="automated-reminder-component-div">
        <div className="reminider-component-main-div">
          <div className="reminder-component-switch-div">
            <div className="switch-div-text">
              <span style={{ color: '#7A55DE', fontSize: '22px' }}>Out-bound Reminders</span>
              <span className="switch-div-text-span">
                {values?.reminders?.outbound_reminders === true ? 'Currently the feature is enabled' : 'Currently the feature is disabled'}
              </span>
            </div>

            <div className="switch-div-icon">
              <Switch
                checked={values.reminders?.outbound_reminders ? values.reminders.outbound_reminders : false}
                onChange={handleReminders}
                color="primary"
                name="outbound_reminders"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </div>
          </div>

          <div className="reminder-component-text-div">
            You can set types of Out-Bound reminders from this tab.
            <br></br>
            For example if you select Call the system will generate
            <br></br>
            automated calls to users based on their Shift status.
          </div>
        </div>
      </div>

      {values?.reminders?.outbound_reminders === true ? (
        <div className="outbound-reminders-buttons">
          <span>Select a method for outbound reminders to send to user:</span>
          <br></br>
          <Button
            className="reminder-buttons"
            onClick={() => {
              handleOutboundMethod('SMS');
            }}
            style={{
              color: values?.settingsNotifications?.SMS_OUTBOUND === true ? 'white' : 'black',
              background:
                values?.settingsNotifications?.SMS_OUTBOUND === true
                  ? 'linear-gradient(to bottom, #efb5ee, #d488d3)'
                  : 'linear-gradient(to bottom, #FCFCFC, #EFEFEF)',
              border: values?.settingsNotifications?.SMS_OUTBOUND === true ? '1px solid #E5A5E4' : '1px solid #E9E9E9',
            }}
          >
            SMS
          </Button>
          <Button
            className="reminder-buttons"
            onClick={() => {
              handleOutboundMethod('Call');
            }}
            style={{
              color: values?.settingsNotifications?.CALL_OUTBOUND === true ? 'white' : 'black',
              background:
                values?.settingsNotifications?.CALL_OUTBOUND === true
                  ? 'linear-gradient(to bottom, #efb5ee, #d488d3)'
                  : 'linear-gradient(to bottom, #FCFCFC, #EFEFEF)',
              border: values?.settingsNotifications?.CALL_OUTBOUND === true ? '1px solid #E5A5E4' : '1px solid #E9E9E9',
            }}
          >
            Call
          </Button>

          <Button
            className="reminder-buttons"
            onClick={() => {
              handleOutboundMethod('Email');
            }}
            style={{
              color: values?.settingsNotifications?.EMAIL_OUTBOUND === true ? 'white' : 'black',
              background:
                values?.settingsNotifications?.EMAIL_OUTBOUND === true
                  ? 'linear-gradient(to bottom, #efb5ee, #d488d3)'
                  : 'linear-gradient(to bottom, #FCFCFC, #EFEFEF)',
              border: values?.settingsNotifications?.EMAIL_OUTBOUND === true ? '1px solid #E5A5E4' : '1px solid #E9E9E9',
            }}
          >
            Email
          </Button>
        </div>
      ) : null}

      <div className="automated-reminder-component-div">
        <div className="reminider-component-main-div">
          <div className="reminder-component-switch-div">
            <div className="switch-div-text">
              <span style={{ color: '#7A55DE', fontSize: '22px' }}>In-bound Reminders</span>
              <span className="switch-div-text-span">
                {values?.reminders?.inbound_reminders === true ? 'Currently the feature is enabled' : 'Currently the feature is disabled'}
              </span>
            </div>

            <div className="switch-div-icon">
              <Switch
                checked={values.reminders?.inbound_reminders ? values.reminders.inbound_reminders : false}
                onChange={handleReminders}
                color="primary"
                name="inbound_reminders"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </div>
          </div>

          <div className="reminder-component-text-div">
            You can set types of In-Bound reminders from this tab.
            <br></br>
            For example if you select Call the user will be allowed
            <br></br>
            to change the status of their Shift by calling the system.
          </div>
        </div>
      </div>

      {values?.reminders?.inbound_reminders === true ? (
        <div className="outbound-reminders-buttons">
          <span>Select a method for inbound reminders to be recieved on system:</span>
          <br></br>
          <Button
            className="reminder-buttons"
            onClick={() => {
              handleInboundMethod('SMS');
            }}
            style={{
              color: values?.settingsNotifications?.SMS_INBOUND === true ? 'white' : 'black',
              background:
                values?.settingsNotifications?.SMS_INBOUND === true
                  ? 'linear-gradient(to bottom, #efb5ee, #d488d3)'
                  : 'linear-gradient(to bottom, #FCFCFC, #EFEFEF)',
              border: values?.settingsNotifications?.SMS_INBOUND === true ? '1px solid #E5A5E4' : '1px solid #E9E9E9',
            }}
          >
            SMS
          </Button>
          <Button
            className="reminder-buttons"
            onClick={() => {
              handleInboundMethod('Call');
            }}
            style={{
              color: values?.settingsNotifications?.CALL_INBOUND === true ? 'white' : 'black',
              background:
                values?.settingsNotifications?.CALL_INBOUND === true
                  ? 'linear-gradient(to bottom, #efb5ee, #d488d3)'
                  : 'linear-gradient(to bottom, #FCFCFC, #EFEFEF)',
              border: values?.settingsNotifications?.CALL_INBOUND === true ? '1px solid #E5A5E4' : '1px solid #E9E9E9',
            }}
          >
            Call
          </Button>

          <Button
            className="reminder-buttons"
            onClick={() => {
              handleInboundMethod('Email');
            }}
            style={{
              color: values?.settingsNotifications?.EMAIL_INBOUND === true ? 'white' : 'black',
              background:
                values?.settingsNotifications?.EMAIL_INBOUND === true
                  ? 'linear-gradient(to bottom, #efb5ee, #d488d3)'
                  : 'linear-gradient(to bottom, #FCFCFC, #EFEFEF)',
              border: values?.settingsNotifications?.EMAIL_INBOUND === true ? '1px solid #E5A5E4' : '1px solid #E9E9E9',
            }}
          >
            Email
          </Button>
        </div>
      ) : null}

      <Divider style={{ marginTop: '40px', marginBottom: '30px' }}></Divider>

      <span style={{ marginTop: '25px' }}>Select Service Area(s) to apply notification/reminder effects on: </span>

      <div className="select-serviceArea-div">
        <Grid item xs={12} md={8} sm={12}>
          <Autocomplete
            className={'person-fields'}
            ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
            limitTags={1}
            multiple
            options={props.serviceAreaList}
            disableCloseOnSelect
            getOptionLabel={option => option.name}
            value={values.serviceAreas ? values.serviceAreas : []}
            renderTags={values => values.map(value => <Chip key={value.id} label={value.name} />)}
            getOptionSelected={(option, value) => option.id === value.id}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected ? selected : null} />
                {option.name}
              </React.Fragment>
            )}
            onChange={handleServiceAreas}
            renderInput={params => <TextField {...params} required variant="outlined" label="Select Service Areas" />}
          />
        </Grid>

        <div className="control-room-service-area-switch">
          <span>Apply to all Service Area</span>
          <Switch
            checked={switchButtons.all_service_areas_button}
            onChange={handleAllServiceAreas}
            color="primary"
            name="all_service_areas_button"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </div>
      </div>

      <div className="control-room-notifications-settings">
        <span>Shift Confirmation Notification(s)</span>
        <Switch
          checked={switchButtons['Shift Confirmation']}
          onChange={handleNotificationSwitches}
          color="primary"
          name="Shift Confirmation"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </div>

      <div className="control-room-notifications-settings">
        <span>Sign In Notification(s)</span>
        <Switch
          checked={switchButtons['Sign In']}
          onChange={handleNotificationSwitches}
          color="primary"
          name="Sign In"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </div>

      <div className="control-room-notifications-settings">
        <span>Welfare Check Notification(s)</span>
        <Switch
          checked={switchButtons['Welfare Check']}
          onChange={handleNotificationSwitches}
          color="primary"
          name="Welfare Check"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </div>

      <div className="control-room-notifications-settings">
        <span>Sign Out Notifications</span>
        <Switch
          checked={switchButtons['Sign Out']}
          onChange={handleNotificationSwitches}
          color="primary"
          name="Sign Out"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  serviceAreaList: storeState.serviceArea.entities,
});

const mapDispatchToProps = {
  serviceAreaEntities,
  serviceAreaReset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(General);
