import { useTable, useExpanded } from 'react-table';
import React, { useState, useEffect } from 'react';
import './patrolReport.scss';
import '../../../utils/GlobalCssRequiredInWebsite.scss';
import styled from 'styled-components';
import moment from 'moment';
import { position } from 'html2canvas/dist/types/css/property-descriptors/position';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import ImageIcon from '@material-ui/icons/Image';
import { Paper, Modal } from '@material-ui/core';
import FileViewer from 'react-file-viewer';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

export const Styles = styled.div`
  table {
    border-spacing: 0;
    border: 1px solid black;
    width: 100%;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

function SubRows({ row, rowProps, data, loading, timeFormatSettings }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [docData, setDocData] = useState({ ext: '', file: null });
  const [timeFormat, setTimeFormat] = useState(timeFormatSettings === 1 ? 'HH:mm' : 'hh:mm A');

  const handleDownload = () => {
    fetch(docData?.file).then(response => {
      response.blob().then(blob => {
        const fileURL = window.URL.createObjectURL(blob);
        const name = 'Patrol Record.' + docData?.ext;
        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = name;
        alink.click();
      });
    });
  };

  const displayPictures = object => {
    setDocData({ ext: object.ext, file: object.file });
    setModalOpen(true);
  };

  const handleCloseViewDocModal = () => {
    setModalOpen(false);
    setDocData({ ext: null, file: null });
  };

  return (
    <>
      {data && data.length > 0 ? (
        <>
          {data.map((patrol, index) => (
            <React.Fragment key={index}>
              <tr className="innerExpandedRow">
                <th className="patrolTableBodyCell" style={{ color: '#683EDB' }}>
                  Patrol {index + 1}{' '}
                  {patrol.status === true ? (
                    `(${patrol.checkpoints_completed}/${patrol.checkPointsRecord.length})`
                  ) : (
                    <CancelIcon className="CancelIcon" />
                  )}
                  <br />
                  <span className="timeDisplay">
                    {patrol.start_time ? moment(patrol.start_time).format(timeFormat) : ''}
                    {' - '}
                    {patrol.end_time ? moment(patrol.end_time).format(timeFormat) : ''}
                  </span>
                </th>
                <th className="patrolTableBodyCell" style={{ display: patrol.status ? 'table-cell' : 'none' }}></th>
                <th className="patrolTableBodyCell bg_clr" style={{ display: patrol.status ? 'table-cell' : 'none' }}>
                  {'Check point(s)'}
                </th>
                <th className="patrolTableBodyCell bg_clr" style={{ display: patrol.status ? 'table-cell' : 'none' }}>
                  {'QR scanned'}
                </th>
                <th className="patrolTableBodyCell bg_clr" style={{ display: patrol.status ? 'table-cell' : 'none' }}>
                  {'Scan time'}
                </th>
                <th className="patrolTableBodyCell bg_clr" style={{ display: patrol.status ? 'table-cell' : 'none' }}>
                  {'Notes'}
                </th>
              </tr>
              {patrol.status === true &&
                patrol.checkPointsRecord.map((x, i) => {
                  return (
                    <tr key={`${index}-expanded-${i}`} className="entities_Reports_React_Table_InnerRow">
                      <td className="patrolTableBodyCell imageIcon">
                        {i === 0 && patrol?.pictures_uploaded[0] ? (
                          <>
                            <ImageIcon />
                            <span onClick={() => displayPictures(patrol.pictures_uploaded[0])}>
                              <u style={{ cursor: 'pointer' }}>
                                <i>Image {index + 1}</i>
                              </u>
                            </span>
                          </>
                        ) : null}
                      </td>
                      <td className="patrolTableBodyCell"></td>
                      <td className="patrolTableBodyCell">{x.checkPointName ? x.checkPointName : '-'}</td>
                      <td className="patrolTableBodyCell">
                        {x.qrScaned === true ? <CheckCircleIcon className="CheckCircleIcon" /> : <CancelIcon className="CancelIcon" />}
                      </td>
                      <td className="patrolTableBodyCell">{x.qrScaned && x.scanTime ? moment(x.scanTime).format(timeFormat) : '-'}</td>
                      <td className="patrolTableBodyCell">{x.skip ? x.skipReason : '-'}</td>
                    </tr>
                  );
                })}
            </React.Fragment>
          ))}
        </>
      ) : (
        !loading && <div className="no-record-warning">No Records found</div>
      )}

      <Modal open={modalOpen} className="file-view-modal">
        <Paper style={{ width: '100%', height: '100%' }}>
          <div style={{ padding: '20px' }}>
            <IconButton
              className="downloaANdPrintButton"
              size="small"
              style={{ marginLeft: '48%' }}
              onClick={() => {
                handleDownload();
              }}
            >
              <img alt="" src="content/images/icons8-download-24.png" />
            </IconButton>
            <IconButton
              className="downloaANdPrintButton"
              size="small"
              style={{ marginLeft: '2%' }}
              onClick={() => {
                window.print();
              }}
            >
              <img alt="" src="content/images/icons8-print-24.png" />
            </IconButton>
            <CloseIcon style={{ marginLeft: '40%' }} onClick={handleCloseViewDocModal}></CloseIcon>{' '}
          </div>
          <div className="file-viewer-style">
            <FileViewer fileType={docData?.ext} filePath={docData?.file} />
          </div>
        </Paper>
      </Modal>
    </>
  );
}

export function SubRowAsync({ row, rowProps, loading, timeFormatSettings }) {
  const [data, setData] = React.useState([]);
  const detailData = [];

  row.original.patrol_object.map(val => {
    const data = { ...val };
    detailData.push(data);
  });

  React.useEffect(() => {
    setData(detailData);
  }, []);

  return <SubRows row={row} rowProps={rowProps} data={data} loading={loading} timeFormatSettings={timeFormatSettings} />;
}

function GetSpecificCoulmnsForAgggregateReports({ patrolReportList, headerGroups, loading }) {
  return (
    <>
      {headerGroups && headerGroups.length > 0
        ? headerGroups.map(headerGroup => {
            return headerGroup.headers.map((column, i) => {
              return (
                <>
                  <td {...column.getHeaderProps()} key={i} className="timesheetTotalTextDesignAndFont timesheetTotals">
                    {column.render('Header') === 'Patrols Completed' ? patrolReportList.patrols_completed : ''}
                    {column.render('Header') === 'Patrols Missed' ? patrolReportList.patrols_missed : ''}
                    {column.render('Header') === 'Checkpoints Completed' ? patrolReportList.checkpoints_completed : ''}
                    {column.render('Header') === 'Checkpoints Missed' ? patrolReportList.checkpoints_missed : ''}
                    {column.render('Header') === 'Pictures Uploaded' ? patrolReportList.pictures_uploaded : ''}
                  </td>
                </>
              );
            });
          })
        : !loading && <div className="no-record-warning">No Totals found</div>}
    </>
  );
}

export function Table({ columns: userColumns, data, renderRowSubComponent, patrolReportList, loading }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { expanded },
  } = useTable(
    {
      columns: userColumns,
      data,
    },
    useExpanded
  );
  return (
    <>
      <div className="globalTableHeaderGradientColor clientTableHeaderRow sticky_div">
        <div className="reportTableHeaderCell patrolReportHeader" style={{ padding: '1rem' }}>
          Name
        </div>
        {/* <div className="reportTableHeaderCell patrolReportHeaderExpand"></div> */}
        <div className="reportTableHeaderCell patrolReportHeader" style={{ padding: '1rem' }}>
          Date
        </div>
        <div className="reportTableHeaderCell patrolReportHeader" style={{ padding: '1rem' }}>
          Service Area
        </div>
        <div className="reportTableHeaderCell patrolReportHeader" style={{ padding: '1rem' }}>
          Patrol Frequency
        </div>
        <div className="reportTableHeaderCell patrolReportHeader" style={{ padding: '1rem' }}>
          Status
        </div>
      </div>
      <table {...getTableProps()} className="entities_Reports_React_Table">
        {/* <thead className="entities_Reports_React_Table_Header">
          {headerGroups && headerGroups.length > 0
            ? headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                {headerGroup.headers.map((column, i) => (
                  <th {...column.getHeaderProps()} key={i} className="entities_Reports_RTBHC" style={{ maxWidth: '8%' }}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))
            : !loading && <div className="no-record-warning">No Header found</div>}
        </thead> */}
        <tbody {...getTableBodyProps()} style={{ position: 'relative' }}>
          {rows && rows.length > 0
            ? rows.map((row, i) => {
                prepareRow(row);
                const rowProps = row.getRowProps();
                return (
                  <React.Fragment key={rowProps.key}>
                    <tr {...rowProps} key={i} className="entities_Reports_React_Table_OuterRow">
                      {row.cells.map((cell, i) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            key={i}
                            className="patrolTableBodyCell"
                            // style={{ maxWidth: '8%' }}
                          >
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                    {row.isExpanded && renderRowSubComponent({ row, rowProps, loading })}
                  </React.Fragment>
                );
              })
            : !loading && (
                <tr style={{ borderBottom: 'unset', position: 'relative' }}>
                  <div className="no-record-warning" style={{ position: 'absolute', width: '100%' }}>
                    No Records found
                  </div>
                </tr>
              )}
          <tr className="entities_Reports_React_Table_OuterRow">
            {' '}
            <GetSpecificCoulmnsForAgggregateReports patrolReportList={patrolReportList} headerGroups={headerGroups} loading={loading} />
          </tr>
        </tbody>
        <br />
        <br />
      </table>
    </>
  );
}
