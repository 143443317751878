import React from 'react';
import { TextField, Grid, InputAdornment, FormControl, Paper } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import CustomToolbarComponent from '../service-area/CustomToolbarComponent';
import './person.scss';
import { getDateFormat } from 'app/shared/util/calender-utils';
import { IPersonalSettings } from 'app/shared/model/personalSetting.model';
import { IPerson } from 'app/shared/model/person.model';

interface PersonUpdatePayrateInformationProps {
  CurrencyChecker?: (data: any) => string;
  currency?: string | any;
  fieldValues?: IPerson | any;
  fieldError?: Record<string, any>;
  setting?: IPersonalSettings | any;
  pay_rate_start_date?: string | any;
  handlePayRateStart?: (data: any) => void;
  pay_rate_effective_date?: string | any;
  handlePayRateEnd?: (data: any) => void;
  handleEmploymentDetail?: (data: any) => void;
}

const PersonUpdatePayrateInformation: React.FC<PersonUpdatePayrateInformationProps> = props => {
  return (
    <React.Fragment>
      <Paper className="personOtherPaper">
        <Grid item xs={12} md={6} sm={12}>
          <FormControl component="fieldset" className="person-fields">
            <TextField
              variant="outlined"
              type="number"
              onChange={props.handleEmploymentDetail}
              label="Pay Rate"
              required
              name="pay_rate"
              id="shift-payrate"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end" className="personPayrateCurrencySign">
                    <span className="personCurrencySign">{props.CurrencyChecker(props.currency)}</span>
                  </InputAdornment>
                ),
              }}
              inputProps={{
                step: '0.01',
                min: 0,
              }}
              value={
                props.fieldValues['employment_detail'] ? props.fieldValues['employment_detail']['pay_rate'] : props.fieldValues['pay_rate']
              }
              error={props.fieldError['payRate_error']}
            />
          </FormControl>
        </Grid>
        <div className="personFieldDivsideBySide">
          <Grid item xs={12} md={6} sm={12}>
            <FormControl className="person-fields">
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <KeyboardDatePicker
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  label="Pay Rate Start"
                  name="pay_rate_start_date"
                  required
                  error={props.fieldError['payRateStart_error']}
                  format={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'MM/DD/YYYY'}
                  value={
                    props.fieldValues['employment_detail']
                      ? props.fieldValues['employment_detail'].pay_rate_start_date
                        ? props.fieldValues['employment_detail'].pay_rate_start_date
                        : null
                      : null
                  }
                  ToolbarComponent={prop => (
                    <>
                      <CustomToolbarComponent {...prop} settingFormat={props.setting.date_format} />
                    </>
                  )}
                  placeholder={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'MM/DD/YYYY'}
                  InputAdornmentProps={{ position: 'start' }}
                  onChange={date => props.handlePayRateStart(date)}
                />{' '}
              </MuiPickersUtilsProvider>
              {props.fieldError['payRateStart_error'] && (
                <span style={{ color: 'red', fontSize: '17' }}>{props.fieldError['payRateStart_helper']}</span>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} sm={12}>
            <FormControl className="person-fields">
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <KeyboardDatePicker
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  label="Pay Rate End"
                  name="pay_rate_effective_date"
                  required
                  error={props.fieldError['payRateEnd_error']}
                  format={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'MM/DD/YYYY'}
                  value={
                    props.fieldValues['employment_detail']
                      ? props.fieldValues['employment_detail'].pay_rate_effective_date
                        ? props.fieldValues['employment_detail'].pay_rate_effective_date
                        : null
                      : null
                  }
                  ToolbarComponent={prop => (
                    <>
                      <CustomToolbarComponent {...prop} settingFormat={props.setting.date_format} />
                    </>
                  )}
                  placeholder={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'MM/DD/YYYY'}
                  InputAdornmentProps={{ position: 'start' }}
                  onChange={date => props.handlePayRateEnd(date)}
                />{' '}
              </MuiPickersUtilsProvider>
              {props.fieldError['payRateEnd_error'] && (
                <span style={{ color: 'red', fontSize: '17' }}>{props.fieldError['payRateEnd_helper']}</span>
              )}
            </FormControl>
          </Grid>
        </div>
      </Paper>
    </React.Fragment>
  );
};
export default PersonUpdatePayrateInformation;
