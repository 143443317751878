import React from 'react';
import { Switch } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import EmploymentStatus from './employment-status';
import EmploymentDetails from './employment-details';
import EducationLevel from './education-level';
import Education from './education';
import EducationalInstituteType from './educational-institute-type';
import EducationalInstitute from './educational-institute';
import Country from './country';
import CountryAdminUnitType from './country-admin-unit-type';
import CountryAdminUnit from './country-admin-unit';
import City from './city';
import Address from './address';
import ContactType from './contact-type';
import Contact from './contact';
import EmployeeContractType from './employee-contract-type';
import EmployeeContract from './employee-contract';
import ServiceAreaType from './service-area-type';
import ServiceArea from './service-area';
import Person from './person';
import Shift from './shift';
import IdentificationType from './identification-type';
import Identification from './identification';
import DesignationQualificationType from './designation-qualification-type';
import DesignationQualification from './designation-qualification';
import OrganizationType from './organization-type';
import Organization from './organization';
import Department from './department';
import Designation from './designation';
import Client from './client';
import TimesheetSummary from './timesheet-summary';
import BannedPeople from './banned-people-report';
import ClientCoverage from './client-coverage';
import SubContractor from './sub-contractor';
import ActionHistory from './action-history';
import PatrolReport from './patrol-report';
import Incident from './incident';
import LeaveManagement from './leave-management';
import PrivateRoute from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';

/* jhipster-needle-add-route-import - JHipster will add routes here */

const Routes = ({ match }) => (
  <div>
    <Switch>
      {/* prettier-ignore */}
      <ErrorBoundaryRoute path={`${match.url}employment-status`} component={EmploymentStatus} />
      <ErrorBoundaryRoute path={`${match.url}employment-details`} component={EmploymentDetails} />
      <ErrorBoundaryRoute path={`${match.url}education-level`} component={EducationLevel} />
      <ErrorBoundaryRoute path={`${match.url}education`} component={Education} />
      <ErrorBoundaryRoute path={`${match.url}educational-institute-type`} component={EducationalInstituteType} />
      <ErrorBoundaryRoute path={`${match.url}educational-institute`} component={EducationalInstitute} />
      <ErrorBoundaryRoute path={`${match.url}country`} component={Country} />
      <ErrorBoundaryRoute path={`${match.url}country-admin-unit-type`} component={CountryAdminUnitType} />
      <ErrorBoundaryRoute path={`${match.url}country-admin-unit`} component={CountryAdminUnit} />
      <ErrorBoundaryRoute path={`${match.url}city`} component={City} />
      <ErrorBoundaryRoute path={`${match.url}address`} component={Address} />
      <ErrorBoundaryRoute path={`${match.url}contact-type`} component={ContactType} />
      <ErrorBoundaryRoute path={`${match.url}contact`} component={Contact} />
      <ErrorBoundaryRoute path={`${match.url}employee-contract-type`} component={EmployeeContractType} />
      <ErrorBoundaryRoute path={`${match.url}employee-contract`} component={EmployeeContract} />
      <ErrorBoundaryRoute path={`${match.url}service-area-type`} component={ServiceAreaType} />
      <PrivateRoute path={`${match.url}service-areas`} component={ServiceArea} hasAnyAuthorities={[AUTHORITIES.ORGANIZATION_ADMINISTRATOR,AUTHORITIES.SUPERVISOR,AUTHORITIES.MANAGER]} />
      <PrivateRoute path={`${match.url}staff`} component={Person} hasAnyAuthorities={[AUTHORITIES.ORGANIZATION_ADMINISTRATOR,AUTHORITIES.SUPERVISOR,AUTHORITIES.MANAGER]} />
      <PrivateRoute path={`${match.url}roster`} component={Shift} hasAnyAuthorities={[AUTHORITIES.ORGANIZATION_ADMINISTRATOR,AUTHORITIES.SUPERVISOR,AUTHORITIES.MANAGER]} />
      <ErrorBoundaryRoute path={`${match.url}identification-type`} component={IdentificationType} />
      <ErrorBoundaryRoute path={`${match.url}identification`} component={Identification} />
      <ErrorBoundaryRoute path={`${match.url}designation-qualification-type`} component={DesignationQualificationType} />
      <ErrorBoundaryRoute path={`${match.url}designation-qualification`} component={DesignationQualification} />
      <ErrorBoundaryRoute path={`${match.url}organization-type`} component={OrganizationType} />
      <PrivateRoute path={`${match.url}customer`} component={Organization} hasAnyAuthorities={[AUTHORITIES.SYSTEM_ADMIN]}/>
      <PrivateRoute path={`${match.url}departments`} component={Department} hasAnyAuthorities={[AUTHORITIES.ORGANIZATION_ADMINISTRATOR,AUTHORITIES.SUPERVISOR,AUTHORITIES.MANAGER]} />
      <PrivateRoute path={`${match.url}positions`} component={Designation} hasAnyAuthorities={[AUTHORITIES.ORGANIZATION_ADMINISTRATOR,AUTHORITIES.SUPERVISOR,AUTHORITIES.MANAGER]}/>
      <PrivateRoute path={`${match.url}clients`} component={Client}  hasAnyAuthorities={[AUTHORITIES.ORGANIZATION_ADMINISTRATOR,AUTHORITIES.SUPERVISOR,AUTHORITIES.MANAGER]} />
      <PrivateRoute path={`${match.url}time-card`} component={TimesheetSummary} hasAnyAuthorities={[AUTHORITIES.ORGANIZATION_ADMINISTRATOR,AUTHORITIES.SUPERVISOR,AUTHORITIES.MANAGER]} />
      <PrivateRoute path={`${match.url}unauthorized-people`} component={BannedPeople} hasAnyAuthorities={[AUTHORITIES.ORGANIZATION_ADMINISTRATOR,AUTHORITIES.SUPERVISOR,AUTHORITIES.MANAGER]} />
      <PrivateRoute path={`${match.url}client-report`} component={ClientCoverage} hasAnyAuthorities={[AUTHORITIES.ORGANIZATION_ADMINISTRATOR,AUTHORITIES.SUPERVISOR,AUTHORITIES.MANAGER]} />
      <PrivateRoute path={`${match.url}subcontractors`} component={SubContractor} hasAnyAuthorities={[AUTHORITIES.ORGANIZATION_ADMINISTRATOR,AUTHORITIES.SUPERVISOR,AUTHORITIES.MANAGER]} />
      <PrivateRoute path={`${match.url}activity-log`} component={ActionHistory} hasAnyAuthorities={[AUTHORITIES.ORGANIZATION_ADMINISTRATOR,AUTHORITIES.SUPERVISOR,AUTHORITIES.MANAGER]} />
      <PrivateRoute path={`${match.url}patrol-report`} component={PatrolReport} hasAnyAuthorities={[AUTHORITIES.ORGANIZATION_ADMINISTRATOR,AUTHORITIES.SUPERVISOR,AUTHORITIES.MANAGER]} />
      <PrivateRoute path={`${match.url}incident-management`} component={Incident} hasAnyAuthorities={[AUTHORITIES.ORGANIZATION_ADMINISTRATOR,AUTHORITIES.SUPERVISOR,AUTHORITIES.MANAGER]} />
      <PrivateRoute path={`${match.url}leave`} component={LeaveManagement} hasAnyAuthorities={[AUTHORITIES.ORGANIZATION_ADMINISTRATOR,AUTHORITIES.SUPERVISOR,AUTHORITIES.MANAGER]} />

      {/* jhipster-needle-add-route-path - JHipster will add routes here */}
    </Switch>
  </div>
);

export default Routes;
