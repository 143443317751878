import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { ISubcontractor, defaultValue, IOrganizationWithSubcontractor, ISubcontractorDocs } from 'app/shared/model/subcontractor.model';
import { ICountry } from 'app/shared/model/country.model';
import { ICity } from 'app/shared/model/city.model';
import { toast } from 'react-toastify';

import { Storage } from 'react-jhipster';
import { Country } from '../country/country';

export const ACTION_TYPES = {
  GET_ALL_SUBCONTRACTORS: 'subcontractor/GET_ALL_SUBCONTRACTOR',
  GET_SINGLE_SUBCONTRACTOR: 'subcontractor/GET_SINGLE_SUBCONTRACTOR',
  CREATE_SUBCONTRACTOR: 'subcontractor/CREATE_SUBCONTRACTOR',
  UPDATE_SUBCONTRACTOR: 'subcontractor/UPDATE_SUBCONTRACTOR',
  PARTIAL_UPDATE_SUBCONTRACTOR: 'subcontractor/PARTIAL_UPDATE_SUBCONTRACTOR',
  DELETE_SUBCONTRACTOR: 'subcontractor/DELETE_SUBCONTRACTOR',
  RESET: 'subcontractor/RESET',
  GET_CITY: 'subcontractor/GET_CITY',
  GET_COUNTRY: 'subcontractor/GET_COUNTRY',
  GET_SUBCONTRACTORS_LIST: 'subcontractor/GET_SUBCONTRACTORS_LIST',
  CREATE_SUBCONTRACTOR_DOCUMENTS: 'subcontractor/CREATE_SUBCONTRACTOR_DOCUMENTS',
  GET_SUBCONTRACTOR_DOCUMENTS: 'subcontractor/GET_SUBCONTRACTOR_DOCUMENTS',
  DELETE_SUBCONTRACTOR_DOCUMENT: 'subcontractor/DELETE_SUBCONTRACTOR_DOCUMENT',
  SET_PAGE_NUMBER: 'subcontractor/SET_PAGE_NUMBER',
  ADD_IMAGE: 'subcontractor/ADD_IMAGE',
  GET_SUBCONTRACTOR_DOCUMENTS_DATA: 'subcontractor/GET_SUBCONTRACTOR_DOCUMENTS_DATA',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as Array<ISubcontractor>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  CountryEntity: [] as Array<ICountry>,
  CityEntity: [] as Array<ICity>,
  subcontractors_list: [],
  SubcontractorDocuments: [] as Array<ISubcontractorDocs>,
  pageNumber: 1,
  documentData: { ext: null, file: null },
};

export type SubcontractorState = Readonly<typeof initialState>;

// Reducer

export default (state: SubcontractorState = initialState, action): SubcontractorState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.GET_ALL_SUBCONTRACTORS):
    case REQUEST(ACTION_TYPES.GET_SINGLE_SUBCONTRACTOR):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_SUBCONTRACTOR):
    case REQUEST(ACTION_TYPES.UPDATE_SUBCONTRACTOR):
    case REQUEST(ACTION_TYPES.DELETE_SUBCONTRACTOR):
    case REQUEST(ACTION_TYPES.DELETE_SUBCONTRACTOR_DOCUMENT):
    case REQUEST(ACTION_TYPES.PARTIAL_UPDATE_SUBCONTRACTOR):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case REQUEST(ACTION_TYPES.GET_CITY):
      return {
        ...state,
        errorMessage: null,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.GET_COUNTRY):
      return {
        ...state,
        errorMessage: null,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.GET_SUBCONTRACTOR_DOCUMENTS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.ADD_IMAGE):
      return {
        ...state,
        errorMessage: null,
      };
    case REQUEST(ACTION_TYPES.CREATE_SUBCONTRACTOR_DOCUMENTS):
    case FAILURE(ACTION_TYPES.GET_ALL_SUBCONTRACTORS):
    case FAILURE(ACTION_TYPES.GET_SINGLE_SUBCONTRACTOR):
    case FAILURE(ACTION_TYPES.CREATE_SUBCONTRACTOR):
    case FAILURE(ACTION_TYPES.CREATE_SUBCONTRACTOR_DOCUMENTS):
    case FAILURE(ACTION_TYPES.GET_SUBCONTRACTOR_DOCUMENTS):
    case FAILURE(ACTION_TYPES.UPDATE_SUBCONTRACTOR):
    case FAILURE(ACTION_TYPES.PARTIAL_UPDATE_SUBCONTRACTOR):
    case FAILURE(ACTION_TYPES.GET_CITY):
    case FAILURE(ACTION_TYPES.GET_COUNTRY):
    case FAILURE(ACTION_TYPES.DELETE_SUBCONTRACTOR):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case FAILURE(ACTION_TYPES.DELETE_SUBCONTRACTOR_DOCUMENT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case FAILURE(ACTION_TYPES.ADD_IMAGE):
      return {
        ...state,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.GET_ALL_SUBCONTRACTORS):
      return {
        ...state,
        loading: false,
        entities: [...state.entities, ...action.payload.data],
      };
    case SUCCESS(ACTION_TYPES.GET_SINGLE_SUBCONTRACTOR):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.GET_SUBCONTRACTOR_DOCUMENTS_DATA):
      return {
        ...state,
        updating: false,
        documentData: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_SUBCONTRACTOR):
    case SUCCESS(ACTION_TYPES.CREATE_SUBCONTRACTOR_DOCUMENTS):
    case SUCCESS(ACTION_TYPES.UPDATE_SUBCONTRACTOR):
    case SUCCESS(ACTION_TYPES.PARTIAL_UPDATE_SUBCONTRACTOR):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.GET_CITY):
      return {
        ...state,
        updating: false,
        loading: false,
        CityEntity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.GET_SUBCONTRACTORS_LIST):
      return {
        ...state,
        loading: false,
        subcontractors_list: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.GET_COUNTRY):
      return {
        ...state,
        updating: false,
        loading: false,
        CountryEntity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_SUBCONTRACTOR):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case SUCCESS(ACTION_TYPES.DELETE_SUBCONTRACTOR_DOCUMENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
      };
    case SUCCESS(ACTION_TYPES.GET_SUBCONTRACTOR_DOCUMENTS):
      return {
        ...state,
        updating: false,
        SubcontractorDocuments: action.payload.data,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    case ACTION_TYPES.SET_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload,
      };
    default:
      return state;
  }
};
const apiUrl = 'api/subcontractor';
const apiUrlCountry = 'api/getCountry';
const apiUrlCity = 'api/getCityByCountryId';

export const getAllEntities = pageNo => ({
  type: ACTION_TYPES.GET_ALL_SUBCONTRACTORS,
  payload: axios.get<ISubcontractor>(`${apiUrl}?page=${pageNo}`),
});

export const getEntity: ICrudGetAction<ISubcontractor> = id => {
  return {
    type: ACTION_TYPES.GET_SINGLE_SUBCONTRACTOR,
    payload: axios.get<ISubcontractor>(`${apiUrl}/${id}`),
  };
};

const apiImageUrl = 'api/subcontractorLogo';
export const createSubcontractor = (entity, files) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_SUBCONTRACTOR,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  if (!!files.entries().next().value === true) {
    dispatch(img(files, result));
  }
  toast.success('Subcontractor Created Successfully');
  return result;
};

export const updateEntity = (entity, files) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_SUBCONTRACTOR,
    payload: axios.put(`${apiUrl}/${entity.id}`, entity),
  });
  if (!!files.entries().next().value === true) {
    dispatch(img(files, result));
  }
  toast.success('Subcontractor Edited Successfully');
  return result;
};

export const img = (files, res) => async dispatch => {
  files.append('id', res.value.data.id);
  const result = await dispatch({
    type: ACTION_TYPES.ADD_IMAGE,
    payload: axios.post(apiImageUrl, files, { headers: { 'Content-Type': 'multipart/form-data' } }),
  });
  return result;
};

export const partialUpdate: ICrudPutAction<ISubcontractor> = entity => async dispatch => {
  const uId = Storage.local.get('user_id');
  const result = await dispatch({
    type: ACTION_TYPES.PARTIAL_UPDATE_SUBCONTRACTOR,
    payload: axios.patch(`${apiUrl}/${entity.id}`, entity),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<ISubcontractor> = id => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_SUBCONTRACTOR,
    payload: axios.delete(`${apiUrl}/${id}`),
  });
  dispatch(reset());
  dispatch(setPageNumber({ pageNumber: 1 }));
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

export const getCountry: ICrudGetAllAction<ICountry> = () => ({
  type: ACTION_TYPES.GET_COUNTRY,
  payload: axios.get<ICountry>(`${apiUrlCountry}`),
});

export const getCity: ICrudGetAllAction<ICountry> = id => ({
  type: ACTION_TYPES.GET_CITY,
  payload: axios.get<ICity>(`${apiUrlCity}/${id}`),
});

export const getOrganizationWithSubContractors: ICrudGetAction<IOrganizationWithSubcontractor> = () => ({
  type: ACTION_TYPES.GET_SUBCONTRACTORS_LIST,
  payload: axios.get('api/organizationWithSubContractors'),
});

export const saveSubcontractorDocuments = (entity, files) => dispatch => {
  let result;
  axios
    .post('api/subcontractorDescription', cleanEntity(entity))
    .then(res => {
      files.append('id', res.data.id);
      axios.post('api/subcontractorDocument', files, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => {
        dispatch(getSubcontractorDocuments(entity.id));
        toast.success('Subcontractor Document submitted');
      });
    })
    .catch(err => {
      toast.error('Subcontractor Document Submission Failed');
    });

  return result;
};

const apiUrlDocs = 'api/getSubcontractorDescription';
export const getSubcontractorDocuments = id => ({
  type: ACTION_TYPES.GET_SUBCONTRACTOR_DOCUMENTS,
  payload: axios.get<ISubcontractorDocs>(`${apiUrlDocs}/${id}`),
});

const apiUrlDeleteDocs = 'api/deleteSubcontractorDocument';
export const deleteSubcontractorDocument = (id, subcontractor_id) => dispatch => {
  const result = dispatch({
    type: ACTION_TYPES.DELETE_SUBCONTRACTOR_DOCUMENT,
    payload: axios.delete(`${apiUrlDeleteDocs}/${id}`, cleanEntity(subcontractor_id)),
  });
  dispatch(getSubcontractorDocuments(subcontractor_id));
  return result;
};

export const setPageNumber: ICrudPutAction<{ pageNumber: number }> = pageNo => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.SET_PAGE_NUMBER,
    payload: pageNo,
  });
  dispatch(getAllEntities(pageNo['pageNumber']));
  return result;
};

const apiUrlDoc = 'api/getSubcontractorDocumentsByID';
export const getSubDocumentById = id => ({
  type: ACTION_TYPES.GET_SUBCONTRACTOR_DOCUMENTS_DATA,
  payload: axios.get<ISubcontractorDocs>(`${apiUrlDoc}/${id}`),
});
