import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Table } from '@material-ui/core';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './incident.scss';
import IconButton from '@material-ui/core/IconButton';
import { IRootState } from 'app/shared/reducers';
import SearchBar from 'material-ui-search-bar';
import Paper from '@material-ui/core/Paper';
import { getIncidents, getIncidentById, updateIncident } from './incident.reducer';
import IncidentTypeComponent from './component/incident-type-component';
import IncidentPoliceComponent from './component/police-details-component';
import IncidentWitnessComponent from './component/incident-witness-component';
import IncidentDocuments from './component/incident-documents-table';
import IncidentControlRoomRemarks from './component/incident-control-room-component';
import moment from 'moment';
import { getDateFormat } from 'app/shared/util/calender-utils';
import { Storage } from 'react-jhipster';
import { convertToPDF } from './pdfData';
import { dateFTConverter } from '../../../utils/TimeFormatAndTimezone';

import '../../../utils/GlobalCssRequiredInWebsite.scss';
import PoliceDetailsComponent from './component/police-details-component';

export interface IIncidentDetailsProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const IncidentDetails = props => {
  const [record, setRecord] = useState(props.incidents);
  const [date, setDate] = useState();
  const [time, setTime] = useState();
  const [controlRoomRemarks, setControlRoomRemarks] = useState({});
  const { match, loading, permissions } = props;
  const DateFormat = Storage.local.get('PersonalSetting').date_format;
  const [dateFormat, setdateFormat] = useState(DateFormat);

  useEffect(() => {
    props.getIncidentById(props.match.params.id);
  }, []);
  useEffect(() => {
    setdateFormat(props.setting.date_format);
  }, [props.setting]);

  useEffect(() => {
    setRecord(props.incidents);
    setControlRoomRemarks(oldValues => ({ ...oldValues, authentic: `${props.incidents.authentic}` }));
    setControlRoomRemarks(oldValues => ({ ...oldValues, remarks: props.incidents.remarks }));
    setControlRoomRemarks(oldValues => ({ ...oldValues, speak_to_all_witnesses: `${props.incidents.speak_to_all_witnesses}` }));
  }, [props.incidents]);

  const handleControlRoomAuthenticRadio = event => {
    setControlRoomRemarks(oldValues => ({ ...oldValues, authentic: event.target.value }));
  };

  const handleControlRoomWitnessRadio = event => {
    setControlRoomRemarks(oldValues => ({ ...oldValues, speak_to_all_witnesses: event.target.value }));
  };

  const handleControlRoomComments = event => {
    setControlRoomRemarks(oldValues => ({ ...oldValues, remarks: event.target.value }));
  };

  const submitControlRoomRemarks = () => {
    props.updateIncident(controlRoomRemarks, props.match.params.id);
  };

  const doenloadPdf = () => {
    const jsonData = [];
    const data = { ...record };
    data.police_arrival_time = dateFTConverter(data.police_arrival_time, props.setting.date_format);
    data.police_report_time = dateFTConverter(data.police_report_time, props.setting.date_format);
    jsonData.push(data);
    const incidentDate = dateFTConverter(props.incidents.incident_time, props.setting.date_format);
    const incidentTime = moment(props.incidents.incident_time).format('HH:mm');
    convertToPDF(incidentDate, incidentTime, jsonData);
  };

  return (
    <div className="incident-details-main-container">
      <div className="incident-report-name-main-conainer">
        <div className="incident-report-name-div">
          <div className="incident-reporter-name">
            <span id="incident-reporter-name-text">
              {record && record.person && record.person.first_name + ' ' + record.person.last_name}
            </span>
          </div>

          <div className="incident-report-download-button">
            <IconButton
              className="downloaANdPrintButton"
              size="small"
              onClick={() => {
                doenloadPdf();
              }}
            >
              <img src="content/images/download-icon-button.png" />
            </IconButton>
            <IconButton
              className="downloaANdPrintButton"
              size="small"
              style={{ marginLeft: '5%' }}
              onClick={() => {
                window.print();
              }}
            >
              <img src="content/images/print-icon-button.png" />
            </IconButton>
          </div>
          <div className="incident-report-edit-button">
            <Button className="incident-edit-button" component={Link} to={`${match.url}/edit`}>
              <img style={{ marginRight: '10%' }} src="content/images/icons8-edit-24.png" />
              <span style={{ alignItems: 'center', fontSize: '20px' }}>Edit</span>
            </Button>
          </div>
        </div>

        <div className="incident-report-date-div">
          <span>
            Date :{' '}
            {moment(props.incidents.incident_time).format(
              props.setting.date_format ? getDateFormat(props.setting.date_format) : 'YYYY-MM-DD'
            )}
          </span>
          <br></br>
          <span> Time : {moment(props.incidents.incident_time).format('HH:mm')}</span>
        </div>
      </div>

      <IncidentTypeComponent
        serviceAreaName={record && record.service_area && record.service_area.name}
        incidentDetails={record && record.incident_description}
        incidentType={record && record.incident_type}
      />

      <IncidentPoliceComponent
        police_report_number={record && record.police_report_number}
        police_arrival_time={record && record.police_arrival_time}
        police_involvement_comments={record && record.police_involvement_comments}
        police_report_time={record && record.police_report_time}
      />

      <IncidentWitnessComponent incident_witness={record && record.incident_witness} />

      <IncidentDocuments incident_attachment={record && record.incident_attachments} />

      <IncidentControlRoomRemarks
        handleControlRoomAuthenticRadio={handleControlRoomAuthenticRadio}
        handleControlRoomWitnessRadio={handleControlRoomWitnessRadio}
        handleControlRoomComments={handleControlRoomComments}
        submitControlRoomRemarks={submitControlRoomRemarks}
        remarksValues={controlRoomRemarks}
        records={record}
      />
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  incidents: storeState.Incident.incident,
  setting: storeState.PersonalSettings.storedPersonalSettings,
});

const mapDispatchToProps = {
  getIncidentById,
  updateIncident,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(IncidentDetails);
