import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import EmployeeContractType from './employee-contract-type';
import EmployeeContractTypeDetail from './employee-contract-type-detail';
import EmployeeContractTypeUpdate from './employee-contract-type-update';
import EmployeeContractTypeDeleteDialog from './employee-contract-type-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={EmployeeContractTypeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={EmployeeContractTypeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={EmployeeContractTypeDetail} />
      <ErrorBoundaryRoute path={match.url} component={EmployeeContractType} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={EmployeeContractTypeDeleteDialog} />
  </>
);

export default Routes;
