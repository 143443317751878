import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Table, Grid } from '@material-ui/core';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './servicearea.scss';
import IconButton from '@material-ui/core/IconButton';
import { IRootState } from 'app/shared/reducers';
import { getEntities, setPageNumber, reset } from './service-area.reducer';
import SearchBar from 'material-ui-search-bar';
import Paper from '@material-ui/core/Paper';
import '../../../utils/GlobalCssRequiredInWebsite.scss';
import InfiniteScroll from 'react-infinite-scroll-component';
import TableContainer from '@material-ui/core/TableContainer';
import { AUTHORITIES } from 'app/config/constants';
import debounce from 'lodash.debounce';
import { Storage } from 'react-jhipster';
import Joyride, { CallBackProps, STATUS } from 'react-joyride';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import GetAppIcon from '@material-ui/icons/GetApp';
import { serviceAreaConvertToPDF } from './serviceAreaConvertToPDF';
import FormControl from '@material-ui/core/FormControl';
import Spinner from 'app/commonUI/spinner/spinner';

export interface IServiceAreaProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const ServiceArea = (props: IServiceAreaProps) => {
  const [records, setRecords] = useState(props.serviceAreaList);
  const { serviceAreaList, match, loading, permissions } = props;
  const [isSearch, setSearch] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [run, setRunState] = useState(false);
  const [runAgain, setRunAgainState] = useState(false);
  const [first_login] = useState(Storage.local.get('first_login'));

  useEffect(() => {
    if (!props.updateSuccess) {
      props.reset();
      props.setPageNumber({ pageNumber: 1 });
    }
    if (first_login === 'true' && props.isOrganizationAdministrator && records.length < 1) {
      setRunState(true);
    }
  }, []);

  useEffect(() => {
    setRecords(props.serviceAreaList);
    if (first_login === 'true' && props.isOrganizationAdministrator && props.joyRide === true) {
      setRunAgainState(true);
    }
    if (runAgain && runAgain === true) {
      setRunState(false);
    }
  }, [props.serviceAreaList]);

  const handleSyncList = () => {
    props.reset();
    setSearchTerm('');
    props.setPageNumber({ pageNumber: 1 });
  };

  const search = e => {
    props.reset();
    setSearchTerm(e);
    debouncedSearch(e);
    setSearch(true);
  };

  const debouncedSearch = useCallback(
    debounce(term => {
      props.reset();
      props.getEntities(-1, term);
    }, 500),
    []
  );

  const cancelSearch = () => {
    props.reset();
    props.setPageNumber({ pageNumber: 1 });
    setSearch(false);
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, index, type, status } = data;

    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
    if (finishedStatuses.includes(status)) {
      setRunState(false);
    }
  };

  const handleJoyrideCallbackAgain = (data: CallBackProps) => {
    const { action, index, type, status } = data;

    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
    if (finishedStatuses.includes(status)) {
      setRunAgainState(false);
    }
  };

  const serviceAreaJsonData = [];
  if (records && records.length > 0) {
    records.map(elem => {
      const jsonData = { ...elem };
      serviceAreaJsonData.push(jsonData);
    });
  }
  return (
    <>
      <Joyride
        callback={data => {
          handleJoyrideCallback(data);
        }}
        run={run}
        disableScrolling
        continuous={true}
        locale={{
          last: <strong aria-label="last">Next</strong>,
          back: <strong aria-label="back">Previous</strong>,
          next: <strong aria-label="next">Next</strong>,
        }}
        showSkipButton
        styles={{
          buttonNext: {
            background: '#d48bd3',
            alignSelf: 'baseline',
          },
          buttonSkip: {
            background: '#f7b017',
          },
          tooltipContainer: {
            height: 70,
          },
          options: {
            width: 270,
          },
        }}
        steps={[
          {
            target: '#jh-create-entity',
            content: (
              <h1 style={{ fontSize: '17px' }}>
                Click this button to add a New <b style={{ color: '#7a55de' }}>Service Area</b>
              </h1>
            ),
            disableBeacon: true,
            disableOverlayClose: true,
            hideCloseButton: true,
            hideFooter: true,
            spotlightClicks: true,
            placement: 'left',
          },
        ]}
      />
      <Joyride
        callback={data => {
          handleJoyrideCallbackAgain(data);
        }}
        run={runAgain}
        disableScrolling
        continuous={true}
        locale={{
          last: <strong aria-label="last">Next</strong>,
          back: <strong aria-label="back">Previous</strong>,
          next: <strong aria-label="next">Next</strong>,
        }}
        showSkipButton
        styles={{
          buttonNext: {
            background: '#d48bd3',
          },
          buttonSkip: {
            background: '#f7b017',
          },
          tooltipContainer: {
            height: 100,
          },
          options: {
            width: 300,
          },
        }}
        steps={[
          {
            target: '.headerIconOther',
            title: (
              <h2 style={{ fontSize: '20px' }}>
                Tap <b>Scheduler</b>
              </h2>
            ),
            content: (
              <h1 style={{ fontSize: '17px' }}>
                Click on <b style={{ color: '#7a55de' }}>Calendar</b> from dropdown menu{' '}
              </h1>
            ),
            disableBeacon: true,
            disableOverlayClose: true,
            hideCloseButton: true,
            hideFooter: true,
            spotlightClicks: true,
            placement: 'bottom',
          },
        ]}
      />
      <div className="entities-reports-main-container">
        {props.loading && <Spinner />}
        <div className="entities-reports-filter-area">
          <Grid container spacing={2} style={{ flexWrap: 'nowrap', width: '65%' }}>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
              <FormControl variant="outlined" size="small">
                <SearchBar
                  value={searchTerm}
                  onChange={e => {
                    search(e);
                  }}
                  onCancelSearch={() => cancelSearch()}
                  placeholder="Search Here..."
                  style={{
                    outline: '1px solid #bbc4c2',
                    borderRadius: '4px',
                    height: '40px',
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container className="entities_reports_refresh_and_download_div" style={{ width: '35%' }}>
            <IconButton
              size="small"
              className="report-download-button"
              id="entities_reports_btn"
              onClick={() => serviceAreaConvertToPDF(serviceAreaJsonData)}
              disabled={props.loading}
            >
              <GetAppIcon fontSize="large" />
            </IconButton>

            <IconButton
              size="small"
              className="report-download-button"
              id="entities_reports_btn"
              disabled={props.loading}
              onClick={handleSyncList}
            >
              <AutorenewIcon fontSize="large" />
            </IconButton>

            {/* <Grid item xl={4} lg={4} md={4} sm={4} xs={4}> */}
            <Link
              to={`${match.url}/new`}
              className="btn jh-create-entity serviceAreaCreateButton incidentCreateButton"
              id="jh-create-entity"
              data-cy="entityCreateButton"
            >
              <FontAwesomeIcon icon="plus" />
              &nbsp; Create New
            </Link>
            {/* </Grid> */}
          </Grid>
        </div>
        <div>
          <div className="globalTableHeaderGradientColor sticky_div serviceAreaTableHeaderRow">
            <div className="subcontractorTableHeaderCell serviceAreaHeaderIdCell">ID No.</div>
            <div className="subcontractorTableHeaderCell serviceAreaHeaderNameCell">Service Area</div>
            <div className="subcontractorTableHeaderCell serviceAreaHeaderClientNameCell">Client Name</div>
            <div className="subcontractorTableHeaderCell serviceAreaHeaderLocationCell">Address</div>
            <div className="subcontractorTableHeaderCell serviceAreaHeaderRemoteAllowedCell">Patrolling Enabled</div>
          </div>
          {
            <InfiniteScroll
              dataLength={serviceAreaList.length}
              scrollThreshold="200px"
              next={() => {
                if (serviceAreaList.length > 0 && isSearch !== true) {
                  props.setPageNumber({ pageNumber: props.pageId['pageNumber'] + 1 });
                }
              }}
              hasMore={true}
              loader={''}
            >
              <TableContainer component={Paper}>
                <Table>
                  <tbody>
                    {records && records.length > 0
                      ? records.map((serviceArea, i) => (
                          <tr key={`entity-${i}`} data-cy="entityTable" className="serviceAreaTableBodyRows">
                            <td className="serviceAreaTableBodyCell serviceAreaTBC serviceAreaTableBodyIdcell">
                              {serviceArea ? (serviceArea.identification_number ? serviceArea.identification_number : null) : null}
                            </td>
                            <td className="serviceAreaTableBodyCell serviceAreaTBC  serviceAreaTableBodyNamecell">
                              {serviceArea ? (
                                serviceArea.name ? (
                                  <a href={`${match.url}/${serviceArea.id}`}>{serviceArea.name}</a>
                                ) : null
                              ) : null}
                            </td>
                            <td className="serviceAreaTableBodyCell serviceAreaTBC  serviceAreaTableBodyClientNamecell">
                              {serviceArea.client ? (serviceArea.client.clientName ? serviceArea.client.clientName : null) : null}
                            </td>
                            <td className="serviceAreaTableBodyCell serviceAreaTBC  serviceAreaTableBodyAddresscell">
                              {serviceArea.address
                                ? serviceArea.address.address_line_one
                                  ? serviceArea.address.address_line_one
                                  : null
                                : null}
                            </td>
                            <td className="serviceAreaTableBodyCell serviceAreaTBC  serviceAreaTableBodyRemotecell">
                              {serviceArea.checkpoints || serviceArea.map ? 'Yes' : 'No'}
                            </td>
                          </tr>
                        ))
                      : !loading && (
                          <div className="no-record-warning">
                            <Translate contentKey="wfmApp.serviceArea.home.notFound">No Service Areas found</Translate>
                          </div>
                        )}
                  </tbody>
                </Table>
              </TableContainer>
            </InfiniteScroll>
          }
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ serviceArea, authentication, client }: IRootState) => ({
  serviceAreaList: serviceArea.entities,
  loading: serviceArea.loading,
  permissions: authentication.account.permission,
  pageId: serviceArea.pageNumber,
  updateSuccess: serviceArea.updateSuccess,
  joyRide: serviceArea.joyRide,
  isOrganizationAdministrator: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.ORGANIZATION_ADMINISTRATOR]),
});

const mapDispatchToProps = {
  getEntities,
  setPageNumber,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ServiceArea);
