import React from 'react';
import 'react-toastify/dist/ReactToastify.css';

import SchedulingPeople from './scheduling-components/scheduling-people';
import SchedulingOperations from './scheduling-components/scheduling-operations';
import SchedulingManager from './scheduling-components/scheduling-manager';
import SchedulingDepartment from './scheduling-components/scheduling-department';
import SchedulingLocation from './scheduling-components/scheduling-location';

export default function Scheduling(props) {
  return (
    <React.Fragment>
      <SchedulingPeople
        handler={props.handler}
        barForProps={props.barForProps}
        barHandler={props.barHandler}
        valueForProps={props.valueForProps}
      />
      <SchedulingOperations
        handler={props.handler}
        barForProps={props.barForProps}
        barHandler={props.barHandler}
        valueForProps={props.valueForProps}
      />
      <SchedulingManager
        handler={props.handler}
        barForProps={props.barForProps}
        barHandler={props.barHandler}
        valueForProps={props.valueForProps}
      />
      <SchedulingDepartment
        handler={props.handler}
        barForProps={props.barForProps}
        barHandler={props.barHandler}
        valueForProps={props.valueForProps}
      />
      <SchedulingLocation
        handler={props.handler}
        barForProps={props.barForProps}
        barHandler={props.barHandler}
        valueForProps={props.valueForProps}
      />
    </React.Fragment>
  );
}
