import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { IRootState } from 'app/shared/reducers';
import { getEntity, saveNotificationSettings, reset } from '../person.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { Paper, Button, Divider, Grid, FormControl } from '@material-ui/core';
import '../../sub-contractor/subcontractor.scss';
import PersonSettingComponent from '../component/person-setting';
import { useLocation } from 'react-router-dom';

export interface IPersonDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const PersonSetting = (props: IPersonDetailProps) => {
  const [values, setValues] = useState({ shift_creation_notification: false, allow_sign_in: false });
  const location = useLocation();
  const [showEditPage, setShowEditPage] = useState(false);
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  useEffect(() => {
    setValues(oldFieldValues => ({
      ...oldFieldValues,
      shift_creation_notification: props.personEntity.notificationSettings?.shift_creation_notification,
    }));
    setValues(oldFieldValues => ({
      ...oldFieldValues,
      allow_sign_in: props.personEntity.notificationSettings?.allow_sign_in,
    }));

    setValues(oldFieldValues => ({ ...oldFieldValues, person_id: props.match.params.id }));
  }, [props.personEntity]);

  const handleNotificationSettings = (event, name) => {
    if (name === 'shift_notification') {
      setValues(oldFieldValues => ({ ...oldFieldValues, shift_creation_notification: event.target.checked }));
    } else if (name === 'allow_sign_in') {
      setValues(oldFieldValues => ({ ...oldFieldValues, allow_sign_in: event.target.checked }));
    }
  };

  const saveEntity = () => {
    setShowEditPage(false);
    props.saveNotificationSettings(values, props.match.params.id);
  };

  const editPageHandler = () => {
    setShowEditPage(true);
  };

  const profilePageHandler = () => {
    setValues({ shift_creation_notification: false, allow_sign_in: false });
    setShowEditPage(false);
  };

  const { personEntity } = props;
  return (
    <div className="person-view-main-container">
      {showEditPage === false && (
        <>
          <div className="PersonProfileEditBtnContainer">
            <Button
              variant="contained"
              className="Profile_Detials_edit_btn"
              onClick={editPageHandler}
              startIcon={<img src="content/icons/Personal Details/Edit.png" />}
            >
              Edit
            </Button>
          </div>
          <Divider style={{ margin: '4% 0' }} />
        </>
      )}

      <PersonSettingComponent
        title="Shift Assigned Notification"
        value={values && values.shift_creation_notification === true ? true : false}
        handleNotificationSettings={handleNotificationSettings}
        name="shift_notification"
        showEditPage={showEditPage}
        setShowEditPage={setShowEditPage}
      />

      <PersonSettingComponent
        title="Allow out of radius sign in"
        value={values && values.allow_sign_in === true ? true : false}
        handleNotificationSettings={handleNotificationSettings}
        name="allow_sign_in"
        showEditPage={showEditPage}
        setShowEditPage={setShowEditPage}
      />
      {/* <PersonSettingComponent title="Open shift notification as created " />
        <PersonSettingComponent title="Shift /changes/ updates / cancelled" />
        <PersonSettingComponent title="Timesheet Rejected " />
        <PersonSettingComponent title="Remainder for upcoming shift" />
        <PersonSettingComponent title="Workplace notifications" />
        <PersonSettingComponent title="Need earning report for each service area" />
        <PersonSettingComponent title="Overtime Alert" />
        <PersonSettingComponent title="Expiry Alert" />  */}
      {location.state !== true && showEditPage === true ? (
        <>
          <Divider style={{ margin: '4% 0' }} />
          <Grid item xs={12}>
            <FormControl style={{ flexDirection: 'row', justifyContent: 'flex-end', width: '100%' }}>
              <>
                <Button variant="contained" className="Person-profile-Discard-Button" onClick={profilePageHandler}>
                  Cancel
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button
                  onClick={() => {
                    saveEntity();
                  }}
                  variant="contained"
                  id="save-entity"
                  type="submit"
                  className="Person-profile-Save-Button"
                >
                  Save
                </Button>
              </>
            </FormControl>
          </Grid>
        </>
      ) : null}
      {/* </Paper> */}
    </div>
  );
};

const mapStateToProps = ({ person }: IRootState) => ({
  personEntity: person.entity,
});

const mapDispatchToProps = { getEntity, saveNotificationSettings, reset };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PersonSetting);
