import React from 'react';
import { Button, Grid, FormControl, TextField, MenuItem, Paper, Table, InputAdornment, IconButton, Switch } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import { IServiceArea } from 'app/shared/model/service-area.model';

interface ServiceAreaShiftExecutionTableProps {
  leftPanelName: string | any;
  name: string | any;
  handleReminderCheckboxes?: (index: any, data: any, event: any) => void;
  values?: any;
  settings?: any;
}

const ServiceAreaShiftExecutionTable: React.FC<ServiceAreaShiftExecutionTableProps> = props => {
  return (
    <tr className="shift-execution-table-data-row" style={{ backgroundColor: '#f3f5f7' }}>
      <td style={{ width: '150px', padding: '5px' }}>
        <Button className="shift-execution-left-panel" style={{ width: '100%', height: '100%' }}>
          {props.leftPanelName}
        </Button>
      </td>

      <td style={{ margin: '0', padding: '0' }}>
        <div style={{ display: 'flex', justifyContent: 'center', border: '1px solid grey', borderRight: 'none' }}>
          <Checkbox
            className="shift-execution-checkboxes"
            name={props.name}
            icon={<CircleUnchecked id="shift-unchecked-checkbox" />}
            checkedIcon={<CircleCheckedFilled id="shift-checked-checkbox" />}
            onChange={() => {
              props.handleReminderCheckboxes('Inbound Call', props.name, event);
            }}
            disabled={props.name === 'Patrol Start' || props.name === 'Patrol End'}
            defaultChecked={
              props.values &&
              props.values['serviceAreaSettings'] &&
              props.values['serviceAreaSettings']['settings'] &&
              props.values['serviceAreaSettings']['settings'][props.name] &&
              props.values['serviceAreaSettings']['settings'][props.name]['Inbound Call'] === true
                ? true
                : false
            }
          />
        </div>
      </td>

      <td style={{ margin: '0', padding: '0' }}>
        <div style={{ display: 'flex', justifyContent: 'center', border: '1px solid grey', borderRight: 'none', borderLeft: 'none' }}>
          <Checkbox
            className="shift-execution-checkboxes"
            name={props.name}
            icon={<CircleUnchecked id="shift-unchecked-checkbox" />}
            checkedIcon={<CircleCheckedFilled id="shift-checked-checkbox" />}
            onChange={() => {
              props.handleReminderCheckboxes('Outbound Automated Call', props.name, event);
            }}
            disabled={props.name === 'Patrol Start' || props.name === 'Patrol End'}
            defaultChecked={
              props.values &&
              props.values['serviceAreaSettings'] &&
              props.values['serviceAreaSettings']['settings'] &&
              props.values['serviceAreaSettings']['settings'][props.name] &&
              props.values['serviceAreaSettings']['settings'][props.name]['Outbound Automated Call'] === true
                ? true
                : false
            }
          />
        </div>
      </td>

      <td style={{ margin: '0', padding: '0' }}>
        <div style={{ display: 'flex', justifyContent: 'center', border: '1px solid grey', borderLeft: 'none' }}>
          <Checkbox
            className="shift-execution-checkboxes"
            name={props.name}
            icon={<CircleUnchecked id="shift-unchecked-checkbox" />}
            checkedIcon={<CircleCheckedFilled id="shift-checked-checkbox" />}
            onChange={() => {
              props.handleReminderCheckboxes('Mobile App', props.name, event);
            }}
            disabled={props.name === 'Shift Confirmation'}
            defaultChecked={
              props.values &&
              props.values['serviceAreaSettings'] &&
              props.values['serviceAreaSettings']['settings'] &&
              props.values['serviceAreaSettings']['settings'][props.name] &&
              props.values['serviceAreaSettings']['settings'][props.name]['Mobile App'] === true
                ? true
                : false
            }
          />
        </div>
      </td>
    </tr>
  );
};
export default ServiceAreaShiftExecutionTable;
