/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import { Box, Paper, Table, TableBody, TableRow, Typography } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import Link from '@material-ui/core/Link';
import { dateFTConverter } from '../../../../utils/TimeFormatAndTimezone';
import { Storage } from 'react-jhipster';
import moment from 'moment/moment';
import { render } from '@testing-library/react';
export const SummaryPdfTable = data => {
  const dateFormat = Storage.local.get('PersonalSetting')?.date_format;
  const addWorkingDays = (startDate, days) => {
    const currentDate = moment(startDate);

    for (let i = 0; i < days; i++) {
      do {
        currentDate.add(1, 'days');
      } while (currentDate.day() === 0 || currentDate.day() === 6); // Skip weekends (Sunday and Saturday)
    }

    return moment(currentDate).format('DD MMM YYYY');
  };

  const getCompletedDate = () => {
    if(data['summaryVettingActivity']){
      const completedDate =  data['summaryVettingActivity'].filter((item) => item.description === "PreVetted-->Completed");
      if(completedDate){
        return moment(completedDate[0]?.create_date).format('DD MMM YYYY');
      }
    }
    return null;
  }

  const getRagColor = () => {
    if(data['connect_api_information'] && data['connect_api_information']['band']){
      if(data['connect_api_information']['band'] <= 3 ){
        return 'Green';
      }else if (data['connect_api_information']['band'] <= 6){
        return 'Amber';
      }else{
        return 'Red';
      }
    }
  }



  return (
    <>
      <Table id={'screenerDetail'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: '200px' }}>SCREENER</TableCell>
            <TableCell style={{ width: '200px' }}>DATE OF ISSUE</TableCell>
            <TableCell style={{ width: '200px' }}>VETTING STATUS</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell style={{ width: '200px' }} component="th">
              {data['summaryScreener'] ? data['summaryScreener']['reporter'].first_name : ''}{' '}
              {data['summaryScreener'] ? data['summaryScreener']['reporter'].last_name : ''}
            </TableCell>
            <TableCell style={{ width: '200px' }} component="th">
              {data['summaryScreener'] ? moment(data['summaryScreener'].create_date).format('DD MMM YYYY') : ''}
            </TableCell>
            <TableCell style={{ width: '200px' }} component="th">{data['summaryPerson'] ? data['summaryPerson'].vetting_status : ''}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table id={'candidateSummary'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>VETTING SUMMARY</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th">Credit check and address link search completed:</TableCell>
            <TableCell component="th">{data['connect_api_information'] ? 'True' : 'False'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">References verified and confirmed:</TableCell>
            <TableCell component="th">
              {data['summaryPerson'] && data['summaryPerson'].vetting_status === 'Completed' ? 'True' : 'False'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">Gap references used.</TableCell>
            <TableCell component="th">
              {data['summaryEmploymentReferences'] && data['summaryEmploymentReferences'].length > 0 ? 'True' : 'False'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">Original proofs of identity and address checked by client.</TableCell>
            <TableCell component="th">
              {(data['summaryPerson'] && data['summaryPerson']['vetting_status'] === 'InvalidApplication') ||
                (data['summaryPerson'] && data['summaryPerson']['vetting_status'] === 'Failed')
                ? 'False'
                : 'True'}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table id={'candidateInfo'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: '200px' }}>DATE OF REQUEST</TableCell>
            <TableCell style={{ width: '200px' }}>REQUESTED BY</TableCell>
            <TableCell style={{ width: '200px' }}>DATE VETTING COMPLETED</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell style={{ width: '200px' }} component="th">{`${moment().format('DD MMM YYYY')}`}</TableCell>
            <TableCell style={{ width: '200px' }} component="th">
              {data['requestUser'] ? data['requestUser'].first_name : ''} {data['requestUser'] ? data['requestUser'].last_name : ''}
            </TableCell>
            <TableCell  style={{ width: '200px' }} component="th">{getCompletedDate()}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table id={'personalInformation'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>APPLICANT&apos;S INFORMATION</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th">
              <b>First Name:</b>
            </TableCell>
            <TableCell component="th">{data['personalInformation'] ? data['personalInformation'].first_name : ''}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">Last Name:</TableCell>
            <TableCell component="td">{data['personalInformation'] ? data['personalInformation'].last_name : ''}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">Date of Birth:</TableCell>
            <TableCell component="td">
              {data['personalInformation'] ? moment(data['personalInformation'].date_of_birth).format('DD MMM YYYY') : ''}
            </TableCell>
          </TableRow>
          {/* <TableRow>
            <TableCell component="th">Gender:</TableCell>
            <TableCell component="td">{data['personalInformation'] ? data['personalInformation'].gender : ''}</TableCell>
          </TableRow> */}
          <TableRow>
            <TableCell component="th">Email Address:</TableCell>
            <TableCell component="td">{data['personalInformation'] ? data['personalInformation'].email_address : ''}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">Telephone No.</TableCell>
            <TableCell component="td">{data['personalInformation'] ? data['personalInformation'].telephone_no : ''}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">Mobile No.</TableCell>
            <TableCell component="td">{data['personalInformation'] ? data['personalInformation'].mobile_no : ''}</TableCell>
          </TableRow>
          {/* <TableRow>
            <TableCell component="th">Nationality:</TableCell>
            <TableCell component="td">
              {data['personalInformation'] && data['personalInformation']['nationality']
                ? data['personalInformation']['nationality'].name
                : ''}
            </TableCell>
          </TableRow> */}
          <TableRow>
            <TableCell component="th">Current Address:</TableCell>
            <TableCell component="td">{data['personalInformation']?.building_name &&
                                       data['personalInformation']?.building_name +' ' +
                                       data['personalInformation']?.building_no +' ' +
                                       data['personalInformation']?.street + ', ' +
                                       data['personalInformation']?.city }</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">Postcode:</TableCell>
            <TableCell component="td">{data['personalInformation'] ? data['personalInformation'].post_code : ''}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table id={'licenseDetail'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell >Name on the License</TableCell>
            <TableCell >License No</TableCell>
            <TableCell >License Expiry</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th">{data['personalInformation'] ? data['personalInformation'].license_name : ''}</TableCell>
            <TableCell component="td">{data['personalInformation'] ? data['personalInformation'].sia_license_no : ''}</TableCell>
            <TableCell component="td">{data['personalInformation'] ? data['personalInformation'].license_expiration : ''}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
       
      {data['employmentHistory'] &&
        data['employmentHistory'].length > 0 &&
        data['employmentHistory'].map((value, index) => {
          return (
            <div key={index} className={'hidePdfTableFromVettingPage'}>
              <Table
                id={`employmentDetail-${index}`}
                className={'hidePdfTableFromVettingPage'}
        >
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>APPLICANT&apos;S EMPLOYMENT DETAILS</TableCell>
                  <TableCell>VALIDATED BY REFEREE</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    component="td"
                    style={{
                      fontWeight: 900,
                    }}
                  >
                    Company Name:
                  </TableCell>
                  <TableCell component="td">{value.company_name}</TableCell>
                  <TableCell component="td"></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <b>Job Title:</b>
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {value.job_title}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <b>Company Location:</b>
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {value.company_location}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <b>Postcode:</b>
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {value.post_code}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <b>From:</b>
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {moment(value.emp_start_date).format('DD MMM YYYY')}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {moment(value.response_details?.job_started).format('DD MMM YYYY')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <b>Till:</b>
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {moment(value.emp_end_date).format('DD MMM YYYY')}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {moment(value.response_details?.job_ended).format('DD MMM YYYY')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <b>Reason for Leaving:</b>
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {value.leaving_reason}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>

          
                <Table id={`referee-${index}`} className={'hidePdfTableFromVettingPage'}>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2}>REFEREE DETAILS</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="td">
                        <b>Referee name:</b>
                      </TableCell>
                      <TableCell component="td">{value.contact_name ? value.contact_name : ''}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="td">
                        <b>Company:</b>
                      </TableCell>
                      <TableCell component="td">{value.company_name ? value.company_name : ''}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="td">
                        <b>Job Title:</b>
                      </TableCell>
                      <TableCell component="td">{value.job_title ? value.job_title : ''}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="td">
                        <b>Email:</b>
                      </TableCell>
                      <TableCell component="td">{value.contact_email ? value.contact_email : ''}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <b>Contact no.</b>
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {value.contact_telephone ? value.contact_telephone : ''}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <b>Relation to applicant:</b>
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {value.response_details?.relation_to_applicant ? value.response_details.relation_to_applicant : ''}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>

            <Table id={`empRefEmailResponse-${index}`} className={'hidePdfTableFromVettingPage'}>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}>REFEREE RESPONSE</TableCell>
              </TableRow>
            </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="td">
                        <b>Job Title:</b>
                      </TableCell>
                      <TableCell component="td">{value.response_details?.job_title ? value.response_details?.job_title : ''}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="td">
                        <b>Job Start Date:</b>
                      </TableCell>
                      <TableCell component="td">{value.response_details?.job_started ? moment(value.response_details?.job_started).format('DD MMM YYYY') : ''}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="td">
                        <b>Job End Date:</b>
                      </TableCell>
                      <TableCell component="td">{value.response_details?.job_ended ? moment(value.response_details?.job_ended).format('DD MMM YYYY') : ''}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="td">
                        <b>Task & Responsibilities:</b>
                      </TableCell>
                      <TableCell component="td">{value.response_details?.job_responsibilities_details ? value.response_details?.job_responsibilities_details : ''}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <b>Disciplinary Action:</b>
                      </TableCell>
                      <TableCell component="td" scope="row">
                      <TableCell component="td">{value.response_details?.disciplinary_action_details ? value.response_details?.disciplinary_action_details : ''}</TableCell>
                      </TableCell>
                    </TableRow>
                  </TableBody>
            </Table>

          <Table id={`refEmailTracking-${index}`} className={'hidePdfTableFromVettingPage'}>
      <TableHead>
        <TableRow>
          <TableCell>Email Count</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Date & Time</TableCell>
          <TableCell>Response Recieved</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data['ref_tracking'] ? (
          <>
            {/* First Email */}
            <TableRow>
              <TableCell>1</TableCell>
              <TableCell>{value.track_info?.first_email_sent === true ? value.contact_email : ''}</TableCell>
              <TableCell>{value.track_info?.first_email_sent === true ? 'Successfully sent' : ''}</TableCell>
              <TableCell>{value.track_info?.first_email_sent === true ? `${moment(value.track_info?.created_at).format('DD MMM YYYY')}  ${moment(value.track_info?.created_at).format('h:mm A z')}`  : ''}</TableCell>
              <TableCell>
                  {value.track_info.first_email_sent &&
                    !value.track_info.second_email_sent &&
                    !value.track_info.third_email_sent &&
                    value.track_info.response_recieved
                    ? moment(value.track_info?.updated_at).format('DD MMM YYYY') : ''}
                </TableCell>
            </TableRow>
            {/* Second Email */}
            {value.track_info?.second_email_sent && (
              <TableRow>
                <TableCell>2</TableCell>
                <TableCell>{value.track_info?.second_email_sent === true ? value.contact_email : ''}</TableCell>
                <TableCell>{value.track_info?.second_email_sent === true ? 'Successfully sent' : ''}</TableCell>
                <TableCell>
                  {value.track_info?.second_email_sent === true
                    ? addWorkingDays(value.track_info?.created_at, 5)
                    : ''}
                </TableCell>
                <TableCell>
                  {value.track_info.first_email_sent &&
                    !value.track_info.second_email_sent &&
                    !value.track_info.third_email_sent &&
                    value.track_info.response_recieved
                    ? moment(value.track_info?.updated_at).format('DD MMM YYYY')  : ''}
                </TableCell>
              </TableRow>
            )}  
            {/* Third Email */}
            {value.track_info?.third_email_sent && (
            <TableRow>
              <TableCell>3</TableCell>
              <TableCell>{value.track_info?.third_email_sent === true ? value.contact_email : ''}</TableCell>
              <TableCell>{value.track_info?.third_email_sent === true ? 'Successfully sent' : ''}</TableCell>
              <TableCell>
                {data['ref_tracking'].third_email_sent === true
                  ? addWorkingDays(data['ref_tracking'].created_at, 10)
                  : ''}
              </TableCell>
              <TableCell>
                  {!value.track_info.first_email_sent &&
                    value.track_info.second_email_sent &&
                    !value.track_info.third_email_sent &&
                    value.track_info.response_recieved
                    ? moment(value.track_info?.updated_at).format('DD MMM YYYY')  : ''}
                </TableCell>
            </TableRow>
            )}
          </>
        ) : (
          <TableRow>
            <TableCell colSpan={4} align="center">
              No data available
            </TableCell>
          </TableRow>
        )}
      </TableBody>
          </Table>

          </div>
        );
      })}

{data['summaryEducation'] &&
        data['summaryEducation'].length > 0 &&
        data['summaryEducation'].map((value, index) => {
          return (
            <div key={index} className={'hidePdfTableFromVettingPage'}>
              <Table
                id={`educationHistory-${index}`}
                className={'hidePdfTableFromVettingPage'}
               >
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>CANDIDATE DETAILS</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    component="td"
                    style={{
                      fontWeight: 900,
                    }}
                  >
                    Institute Name:
                  </TableCell>
                  <TableCell component="td">{value.institute_name}</TableCell>
                  <TableCell component="td"></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <b>Course/Diploma:</b>
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {value.degree_title}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <b>Institute Location:</b>
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {value.institute_location}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <b>Start Date:</b>
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {moment(value.start_date).format('DD MMM YYYY')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <b>End Date:</b>
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {moment(value.end_date).format('DD MMM YYYY')}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        );
      })}


{data['summaryEducationReferences'] &&
        data['summaryEducationReferences'].length > 0 &&
        data['summaryEducationReferences'].map((value, index) => {
          return (
            <div key={index} className={'hidePdfTableFromVettingPage'}>
              <Table
                id={`educationReferee-${index}`}
                className={'hidePdfTableFromVettingPage'}
               >

                <TableHead>
                    <TableRow>
                      <TableCell colSpan={2}>REFEREE DETAILS</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="td">
                        <b>Referee name:</b>
                      </TableCell>
                      <TableCell component="td">{value.ref_name ? value.ref_name : ''}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="td">
                        <b>Email:</b>
                      </TableCell>
                      <TableCell component="td">{value.ref_email ? value.ref_email : ''}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <b>Contact no.</b>
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {value.ref_telephone ? value.ref_telephone : ''}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <b>Relation to applicant:</b>
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {value.ref_verification?.personal_verification_response?.relationship ? value.ref_verification?.personal_verification_response?.relationship : ''}
                      </TableCell>
                    </TableRow>
                  </TableBody>
             
              </Table>


              <Table id={`educationRefEmailResponse-${index}`} className={'hidePdfTableFromVettingPage'}>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>EDUCATION REFERENCE RESPONSE</TableCell>
                  </TableRow>
                </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="td">
                        <b>Known From:</b>
                      </TableCell>
                      <TableCell component="td">{value.ref_verification?.personal_verification_response?.known_from ? value.ref_verification?.personal_verification_response?.known_from : ''}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="td">
                        <b>Known Till:</b>
                      </TableCell>
                      <TableCell component="td">{value.ref_verification?.personal_verification_response?.known_till ? value.ref_verification?.personal_verification_response?.known_till : ''}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="td">
                        <b>Relationship with Applicant:</b>
                      </TableCell>
                      <TableCell component="td">{value.ref_verification?.personal_verification_response?.relationship ? value.ref_verification?.personal_verification_response?.relationship : ''}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="td">
                        <b>Information Verified ?</b>
                      </TableCell>
                      <TableCell component="td">{value.response_details?.information_verified ? value.response_details?.information_verified : ''}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <b>Comments:</b>
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {value.ref_verification?.personal_verification_response?.comments ? value.ref_verification?.personal_verification_response?.comments : ''}
                      </TableCell>
                    </TableRow>
                  </TableBody>
              </Table>

              <Table id={`educationRefEmailTracking-${index}`} className={'hidePdfTableFromVettingPage'}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Email Count</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Date & Time</TableCell>
                      <TableCell>Response Recieved</TableCell>
                    </TableRow>
                  </TableHead>
                <TableBody>
                    {data['ref_tracking'] ? (
                    <>
                {/* First Email */}
                <TableRow>
                  <TableCell>1</TableCell>
                  <TableCell>{value.ref_tracking?.first_email_sent === true ? value.contact_email : ''}</TableCell>
                  <TableCell>{value.ref_tracking?.first_email_sent === true ? 'Successfully sent' : ''}</TableCell>
                  <TableCell>{value.ref_tracking?.first_email_sent === true ? dateFTConverter(value.ref_tracking?.created_at, dateFormat) : ''}</TableCell>
                  <TableCell>
                      {value.ref_tracking.first_email_sent &&
                        !value.ref_tracking.second_email_sent &&
                        !value.ref_tracking.third_email_sent &&
                        value.ref_tracking.response_recieved
                        ? dateFTConverter(value.ref_tracking.updated_at, dateFormat) : ''}
                    </TableCell>
                </TableRow>
                {/* Second Email */}
                {value.track_info?.second_email_sent && (
                  <TableRow>
                    <TableCell>2</TableCell>
                    <TableCell>{value.ref_tracking?.second_email_sent === true ? value.contact_email : ''}</TableCell>
                    <TableCell>{value.ref_tracking?.second_email_sent === true ? 'Successfully sent' : ''}</TableCell>
                    <TableCell>
                      {value.ref_tracking?.second_email_sent === true
                        ? addWorkingDays(value.ref_tracking?.created_at, 5)
                        : ''}
                    </TableCell>
                    <TableCell>
                      {value.ref_tracking.first_email_sent &&
                        !value.ref_tracking.second_email_sent &&
                        !value.ref_tracking.third_email_sent &&
                        value.ref_tracking.response_recieved
                        ? dateFTConverter(value.ref_tracking.updated_at, dateFormat) : ''}
                    </TableCell>
                  </TableRow>
                )}  
                {/* Third Email */}
                {value.ref_tracking?.third_email_sent && (
                <TableRow>
                  <TableCell>3</TableCell>
                  <TableCell>{value.ref_tracking?.third_email_sent === true ? value.contact_email : ''}</TableCell>
                  <TableCell>{value.ref_tracking?.third_email_sent === true ? 'Successfully sent' : ''}</TableCell>
                  <TableCell>
                    {value.ref_tracking?.third_email_sent === true
                      ? addWorkingDays(data['ref_tracking'].created_at, 10)
                      : ''}
                  </TableCell>
                  <TableCell>
                      {!value.ref_tracking.first_email_sent &&
                        value.ref_tracking.second_email_sent &&
                        !value.ref_tracking.third_email_sent &&
                        value.ref_tracking.response_recieved
                        ? dateFTConverter(value.ref_tracking.updated_at, dateFormat) : ''}
                    </TableCell>
                </TableRow>
                )}
              </>
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
              </Table>
          </div>
        );
      })}

      
      <Table id={'activityClaimDetail'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>ACTIVITY CLAIMS</TableCell>
            <TableCell>REFEREE RESPONSE</TableCell>
          </TableRow>
        </TableHead>

        {data['summaryOthers'] && data['summaryOthers'].length > 0 ? (
          data['summaryOthers'].map((value, index) => {
            return (
              <TableBody key={index}>
                <TableRow>
                  <TableCell component="td">
                    <b>Claim Type:</b>
                  </TableCell>
                  <TableCell component="td">{value.reason ? value.reason : 'None'}</TableCell>
                  <TableCell component="td"></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="td">
                    <b>From:</b>
                  </TableCell>
                  <TableCell component="td">{moment(value.start_date).format('DD MMM YYYY')}</TableCell>
                  <TableCell component="td"></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="td">
                    <b>Till:</b>
                  </TableCell>
                  <TableCell component="td">{moment(value.end_date).format('DD MMM YYYY')}</TableCell>
                  <TableCell component="td"></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="td">
                    <b>Extra Note:</b>
                  </TableCell>
                  <TableCell component="td">{value.note}</TableCell>
                  <TableCell component="td"></TableCell>
                </TableRow>
              </TableBody>
            );
          })
        ) : (
          <TableBody>
            <TableRow>
              <TableCell component="td">
                <b>Claim Type:</b>
              </TableCell>
              <TableCell component="td"></TableCell>
              <TableCell component="td"></TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="td">
                <b>From:</b>
              </TableCell>
              <TableCell component="td"></TableCell>
              <TableCell component="td"></TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="td">
                <b>Till:</b>
              </TableCell>
              <TableCell component="td"></TableCell>
              <TableCell component="td"></TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="td">
                <b>Extra Note:</b>
              </TableCell>
              <TableCell component="td"></TableCell>
              <TableCell component="td"></TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>

      

      
    
      <Table id={'appendixDetail'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={4}>APPENDIX: ONLINE CHECKS</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              Reference ID
            </TableCell>
            <TableCell component="th" scope="row">
              Check Type
            </TableCell>
            <TableCell component="th" scope="row">
              Requested on
            </TableCell>
            <TableCell component="th" scope="row">
              Completed on
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="td" scope="row">
              {data['connect_api_information'] ? 'CH-' + data['connect_api_information']['customerId'] : 'No Information Available'}
            </TableCell>
            <TableCell component="td" scope="row">
              Sanctions
            </TableCell>
            <TableCell component="td" scope="row">
              {' '}
              {moment().format('DD MMM YYYY')}
            </TableCell>
            <TableCell component="td" scope="row">
              {' '}
              {data['connect_api_information']
                ? moment(data['connect_api_information']['checkDate']).format('DD MMM YYYY')
                : 'No Information Available'}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table id={'detailProcessed'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>DETAILS PROCESSED</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="td" scope="row">
              First Name:
            </TableCell>
            <TableCell component="td" scope="row">
              {data['personalInformation'] ? data['personalInformation'].first_name : ''}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="td" scope="row">
              Last Name:
            </TableCell>
            <TableCell component="td" scope="row">
              {data['personalInformation'] ? data['personalInformation'].last_name : ''}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="td" scope="row">
              Date of Birth:
            </TableCell>
            <TableCell component="td" scope="row">
              {data['personalInformation'] ? moment(data['personalInformation'].date_of_birth).format('DD MMM YYYY') : ''}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="td" scope="row">
              Current Address:
            </TableCell>
            <TableCell component="td" scope="row">
              {data['personalInformation']?.building_name &&
                                       data['personalInformation']?.building_name +' ' +
                                       data['personalInformation']?.building_no +' ' +
                                       data['personalInformation']?.street + ', ' +
                                       data['personalInformation']?.city }
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table id={'ragScore'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>RAG & SCORE</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="td" scope="row">
              RAG:
            </TableCell>
            <TableCell component="td" scope="row">
              {getRagColor()}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="td" scope="row">
              Score:
            </TableCell>
            <TableCell component="td" scope="row">
              {data['connect_api_information'] && data['connect_api_information']['score']}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table id={'alerts'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>ALERTS</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="td" scope="row">
              Message:
            </TableCell>
            <TableCell component="td" scope="row">
              No information available.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="td" scope="row">
              Type:
            </TableCell>
            <TableCell component="td" scope="row">
              No information available.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="td" scope="row">
              Score:
            </TableCell>
            <TableCell component="td" scope="row">
              No information available.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="td" scope="row">
              Category:
            </TableCell>
            <TableCell component="td" scope="row">
              No information available.
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table id={'detailProcessed2'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>DETAILS PROCESSED</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="td" scope="row">
              First Name:
            </TableCell>
            <TableCell component="td" scope="row">
              {data['personalInformation'] ? data['personalInformation'].first_name : ''}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="td" scope="row">
              Last Name:
            </TableCell>
            <TableCell component="td" scope="row">
              {data['personalInformation'] ? data['personalInformation'].last_name : ''}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="td" scope="row">
              Date of Birth:
            </TableCell>
            <TableCell component="td" scope="row">
              {data['personalInformation'] ? moment(data['personalInformation'].date_of_birth).format('DD MMM YYYY')  : ''}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="td" scope="row">
              Current Address:
            </TableCell>
            <TableCell component="td" scope="row">
              {data['personalInformation']?.building_name &&
                                       data['personalInformation']?.building_name +' ' +
                                       data['personalInformation']?.building_no +' ' +
                                       data['personalInformation']?.street + ', ' +
                                       data['personalInformation']?.city }
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table id={'identityInformation'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>DETAILS PROCESSED</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="td" scope="row">
              Electoral roll and Rolling register check
            </TableCell>
            <TableCell component="td" scope="row">
              {data['electoral_roll_entry'] && data['electoral_roll_entry']['electoral_roll_and_rolling_register_check']
                ? data['electoral_roll_entry']['electoral_roll_and_rolling_register_check']
                : 'No Information Available'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="td" scope="row">
              Currently on electoral roll?:
            </TableCell>
            <TableCell component="td" scope="row">
              {data['electoral_roll_entry'] && data['electoral_roll_entry']['currently_on_electoral_roll']
                ? data['electoral_roll_entry']['currently_on_electoral_roll']
                : 'No Information Available'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="td" scope="row">
              Name matched at Address on Electoral Register?
            </TableCell>
            <TableCell component="td" scope="row">
              {data['electoral_roll_entry'] && data['electoral_roll_entry']['name_matched_address_electoral_roll']
                ? data['electoral_roll_entry']['name_matched_address_electoral_roll']
                : 'No Information Available'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="td" scope="row">
              Electoral Roll and rolling register - Date of Birth Check
            </TableCell>
            <TableCell component="td" scope="row">
              No Information Available
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="td" scope="row">
              Years since person electoral roll entry
            </TableCell>
            <TableCell component="td" scope="row">
              {data['electoral_roll_entry'] && data['electoral_roll_entry']['years_since_on_electoral_roll']
                ? data['electoral_roll_entry']['years_since_on_electoral_roll']
                : 'No Information Available'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="td" scope="row">
              Length of residence - current address
            </TableCell>
            <TableCell component="td" scope="row">
              {data['electoral_roll_entry'] && data['electoral_roll_entry']['length_of_residence']
                ? data['electoral_roll_entry']['length_of_residence']
                : 'No Information Available'}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table id={'electoralRole'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={4}>ELECTORAL ROLL</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              Address
            </TableCell>
            <TableCell component="th" scope="row">
              Name
            </TableCell>
            <TableCell component="th" scope="row">
              Start
            </TableCell>
            <TableCell component="th" scope="row">
              End
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="td" scope="row">
              {data['electoral_roll_entry'] && data['electoral_roll_entry']['electoral_roll_address']
                ? data['electoral_roll_entry']['electoral_roll_address']
                : 'No Information Available'}
            </TableCell>
            <TableCell component="td" scope="row">
              {data['electoral_roll_entry'] && data['electoral_roll_entry']['electoral_roll_name']
                ? data['electoral_roll_entry']['electoral_roll_name']
                : 'No Information Available'}
            </TableCell>
            <TableCell component="td" scope="row">
              {data['electoral_roll_entry'] && data['electoral_roll_entry']['electoral_roll_start_date']
                ? moment(data['electoral_roll_entry']['electoral_roll_start_date']).format('DD MMM YYYY')
                : 'No Information Available'}
            </TableCell>
            <TableCell component="td" scope="row">
              {data['electoral_roll_entry'] && data['electoral_roll_entry']['electoral_roll_end_date']
                ? moment(data['electoral_roll_entry']['electoral_roll_end_date']).format('DD MMM YYYY')
                : 'No Information Available'}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table id={'rollingRegister'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={3}>ROLLING REGISTER</TableCell>
          </TableRow>
        </TableHead>
        {data['electoral_roll_entry'] &&
          data['electoral_roll_entry']['previousAddress'] &&
          data['electoral_roll_entry']['previousAddress'].length > 0 ? (
          data['electoral_roll_entry']['previousAddress'].map((value, index) => {
            return (
              <TableBody key={index}>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Address
                  </TableCell>
                  {/* <TableCell component="th" scope="row">
                    Name
                  </TableCell> */}
                  <TableCell component="th" scope="row">
                    Supply Date
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="td" scope="row">
                    {value['previous_address']}
                  </TableCell>
                  {/* <TableCell component="td" scope="row">
                    {value['previous_address_name']}
                  </TableCell> */}
                  <TableCell component="td" scope="row">
                    {moment(value['previous_address_supply_date']).format('DD MMM YYYY')}
                  </TableCell>
                </TableRow>
              </TableBody>
            );
          })
        ) : (
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                Address
              </TableCell>
              <TableCell component="th" scope="row">
                Name
              </TableCell>
              <TableCell component="th" scope="row">
                Supply Date
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="td" scope="row">
              No Information Available
              </TableCell>
              <TableCell component="td" scope="row">
              No Information Available
              </TableCell>
              <TableCell component="td" scope="row">
              No Information Available
              </TableCell>
            </TableRow>
          </TableBody>
        ) }
      </Table>
      <Table id={'insolvencyInformation'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>COURT AND INSOLVENCY INFORMATION</TableCell>
          </TableRow>
        </TableHead>
        {data['connect_api_information'] &&
          data['connect_api_information']['insolvencies'] &&
          data['connect_api_information']['insolvencies'].length > 0 ? (
          data['connect_api_information']['insolvencies'].map((value, index) => {
            return (
              <TableBody key={index}>
                <TableRow>
                  <TableCell component="td" scope="row">
                    Address:
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {value['address']['address']}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="td" scope="row">
                    Type:
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {value['restriction']['type']}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="td" scope="row">
                    Court Code:
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {value['orderTypeCode']}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="td" scope="row">
                    Court Date:
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {moment(value['orderDate']).format('DD MMM YYYY')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="td" scope="row">
                    Court Name:
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {value['court']}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="td" scope="row">
                    Old Case No.
                  </TableCell>
                  <TableCell component="td" scope="row">
                    No information available.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="td" scope="row">
                    Amount
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {value['amount']}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="td" scope="row">
                    Satisfied Date
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {moment(value['restriction']['endDate']).format('DD MMM YYYY')}
                  </TableCell>
                </TableRow>
              </TableBody>
            );
          })
        ) : (
          <TableBody>
            <TableRow>
              <TableCell component="td" scope="row">
                Address:
              </TableCell>
              <TableCell component="td" scope="row">
                No information available.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="td" scope="row">
                Type:
              </TableCell>
              <TableCell component="td" scope="row">
                No information available.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="td" scope="row">
                Court Code:
              </TableCell>
              <TableCell component="td" scope="row">
                No information available.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="td" scope="row">
                Court Date:
              </TableCell>
              <TableCell component="td" scope="row">
                No information available.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="td" scope="row">
                Court Name:
              </TableCell>
              <TableCell component="td" scope="row">
                No information available.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="td" scope="row">
                Old Case No.
              </TableCell>
              <TableCell component="td" scope="row">
                No information available.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="td" scope="row">
                Amount
              </TableCell>
              <TableCell component="td" scope="row">
                No information available.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="td" scope="row">
                Satisfied Date
              </TableCell>
              <TableCell component="td" scope="row">
                No information available.
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
      <Table id={'correctionDisputeData'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>NOTICE OF CORRECTION OR DISPUTE DATA</TableCell>
          </TableRow>
        </TableHead>
        {data['connect_api_information'] &&
          data['connect_api_information']['noticesAndDisputeData'] &&
          data['connect_api_information']['noticesAndDisputeData'].length > 0 ? (
          data['connect_api_information']['noticesAndDisputeData'].map((value, index) => {
            return (
              <TableBody key={index}>
                <TableRow>
                  <TableCell component="td" scope="row">
                    Address:
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {value['address']['address']}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="td" scope="row">
                    Information:
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {value['comment']}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="td" scope="row">
                    Date Created:
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {moment(value['dateRaised']).format('DD MMM YYYY')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="td" scope="row">
                    Type:
                  </TableCell>
                  <TableCell component="td" scope="row">
                    No information available.
                  </TableCell>
                </TableRow>
              </TableBody>
            );
          })
        ) : (
          <TableBody>
            <TableRow>
              <TableCell component="td" scope="row">
                Address:
              </TableCell>
              <TableCell component="td" scope="row">
                No information available.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="td" scope="row">
                Information:
              </TableCell>
              <TableCell component="td" scope="row">
                No information available.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="td" scope="row">
                Date Created:
              </TableCell>
              <TableCell component="td" scope="row">
                No information available.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="td" scope="row">
                Type:
              </TableCell>
              <TableCell component="td" scope="row">
                No information available.
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
      <Table id={'aliases'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>ALIASES</TableCell>
          </TableRow>
        </TableHead>

        {data['connect_api_information'] &&
          data['connect_api_information']['aliases'] &&
          data['connect_api_information']['aliases'].length > 0 ? (
          data['connect_api_information']['aliases'].map((value, index) => {
            return (
              <TableBody key={index}>
                <TableRow>
                  <TableCell component="td" scope="row">
                    Title:
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {value['alias'].split(' ')[0]}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="td" scope="row">
                    Forename:
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {value['alias'].split(' ')[1]}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="td" scope="row">
                    Middle Name:
                  </TableCell>
                  <TableCell component="td" scope="row">
                    No information available.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="td" scope="row">
                    Surname:
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {value['alias'].split(' ')[2]}
                  </TableCell>
                </TableRow>
              </TableBody>
            );
          })
        ) : (
          <TableBody>
            <TableRow>
              <TableCell component="td" scope="row">
                Title:
              </TableCell>
              <TableCell component="td" scope="row">
                No information available.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="td" scope="row">
                Forename:
              </TableCell>
              <TableCell component="td" scope="row">
                No information available.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="td" scope="row">
                Middle Name:
              </TableCell>
              <TableCell component="td" scope="row">
                No information available.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="td" scope="row">
                Surname:
              </TableCell>
              <TableCell component="td" scope="row">
                No information available.
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
      <Table id={'electoralRole2'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={4}>ELECTORAL ROLL</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              Address
            </TableCell>
            <TableCell component="th" scope="row">
              Name
            </TableCell>
            <TableCell component="th" scope="row">
              Start
            </TableCell>
            <TableCell component="th" scope="row">
              End
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="td" scope="row">
              979 ROMFORD ROAD, LONDON E12 5JR
            </TableCell>
            <TableCell component="td" scope="row">
              SRIKANTH REDDY KONTHAM
            </TableCell>
            <TableCell component="td" scope="row">
              01/1/2022
            </TableCell>
            <TableCell component="td" scope="row">
              01/1/2022
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table id={'rollingRegister2'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={3}>ROLLING REGISTER</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              Address
            </TableCell>
            <TableCell component="th" scope="row">
              Name
            </TableCell>
            <TableCell component="th" scope="row">
              Supply Date
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="td" scope="row">
              979 ROMFORD ROAD, LONDON E12 5JR
            </TableCell>
            <TableCell component="td" scope="row">
              SRIKANTH REDDY KONTHAM
            </TableCell>
            <TableCell component="td" scope="row">
              01/1/2022
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table id={'privacyIntroduction'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>
              INTRODUCTION
              <br />
              <br />
              <Typography>
                <i>Smart Workforce Privacy Notice</i>
                <br />
                <br />
                This privacy notice lets you know what happens to any personal data that you give to us, or any that we may collect from or
                about you. It applies to all services, and instances where we collect your personal data.
                <br />
                <br />
                <br />
                This privacy notice applies to personal information processed by Smart Workforce.
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              Legal entity:
            </TableCell>
            <TableCell component="th" scope="row">
              Smart Workforce is a limited company registered in England & Wales.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Registered no.
            </TableCell>
            <TableCell component="th" scope="row">13307813</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Registered Office:
            </TableCell>
            <TableCell component="th" scope="row">124 City Road, London, EC1V 2NX</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Protection license No.
            </TableCell>
            <TableCell component="th" scope="row">ZB277782</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              DPO Officer:
            </TableCell>
            <TableCell component="th" scope="row">Smart Workforce</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Purpose of Processing:
            </TableCell>
            <TableCell component="th" scope="row">Screening and Vetting</TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2} component="th" scope="row">
              Note: Smart Workforce is a “Data Processor” and is not a “Data Controller”.
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table id={'privacyPolicy'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>PRIVACY POLICY</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              Our Contact Info:
            </TableCell>
            <TableCell component="th" scope="row">
              Email: <Link>info@smartworkforce.co.uk</Link>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              FAO:
            </TableCell>
            <TableCell component="th" scope="row">
              The DPO Officer
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Office Location:
            </TableCell>
            <TableCell component="th" scope="row">
              124 City Rd, London EC1V 2NX, United Kingdom
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table id={'autoMatedGeneratedChecklist'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>
              AUTOMATED GENERATED CHECK LIST
              <br />
              <br />
              <Typography>
                Parts of your data may be passed on the following third parties to conduct relevant compliance checks:
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Company</TableCell>
            <TableCell>More Information</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              Equifax
            </TableCell>
            <TableCell component="th" scope="row">
              Click here
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Northrow
            </TableCell>
            <TableCell component="th" scope="row">
              Click here
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Employment References
            </TableCell>
            <TableCell component="th" scope="row">
              Name, Claim, DOB, NI Number
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Academic References
            </TableCell>
            <TableCell component="th" scope="row">
              Name, Claim, DOB, Number
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Activity References
            </TableCell>
            <TableCell component="th" scope="row">
              Name, Claim
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      {/* <Table id={'refEmailTracking'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell>REFERENCE EMAIL EVENTS</TableCell>
            <TableCell>SENT / RECIEVED</TableCell>
            <TableCell>DATE</TableCell>
          </TableRow>
        </TableHead>

        {data['ref_tracking'] ? (
          <TableBody>
            <TableRow>
              <TableCell component="td">
                <b>First Email</b>
              </TableCell>
              <TableCell component="td">{data['ref_tracking'].first_email_sent === true ? 'Sent' : ''}</TableCell>
              <TableCell component="td">{dateFTConverter(data['ref_tracking'].created_at, dateFormat)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="td">
                <b>Second Email</b>
              </TableCell>
              <TableCell component="td">{data['ref_tracking'].second_email_sent === true ? 'Sent' : ''}</TableCell>
              <TableCell component="td">
                {data['ref_tracking'].second_email_sent === true ? addWorkingDays(data['ref_tracking'].created_at, 5) : ''}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="td">
                <b>Third Email</b>
              </TableCell>
              <TableCell component="td">{data['ref_tracking'].third_email_sent === true ? 'Sent' : ''}</TableCell>
              <TableCell component="td">
                {data['ref_tracking'].second_email_sent === true ? addWorkingDays(data['ref_tracking'].created_at, 10) : ''}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="td">
                <b>Response Recieved</b>
              </TableCell>
              <TableCell component="td">{data['ref_tracking'].response_recieved === true ? 'Recieved' : ''}</TableCell>
              <TableCell component="td">
                {data['ref_tracking'].response_recieved === true ? dateFTConverter(data['ref_tracking'].updated_at, dateFormat)  : ''}
              </TableCell>
            </TableRow>
          </TableBody>
        ) : null}
      </Table> */}



    </>
  );
};
export default SummaryPdfTable;
