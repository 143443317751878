import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Grid, FormControl, TextField, MenuItem } from '@material-ui/core';
import { Row, Col, Label } from 'reactstrap';
import { AvFeedback, form } from 'availity-reactstrap-validation';
import { Translate, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import './employment-details.scss';
import { getEntity, updateEntity, createEntity, reset } from './employment-details.reducer';
import { IEmploymentDetails } from 'app/shared/model/employment-details.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface IEmploymentDetailsUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const EmploymentDetailsUpdate = (props: IEmploymentDetailsUpdateProps) => {
  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const { employmentDetailsEntity, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/employment-details');
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }
  }, []);

  useEffect(() => {}, [employmentDetailsEntity]);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = event => {
    event.preventDefault();

    event.target.startYear.value = convertDateTimeToServer(event.target.startYear.value);

    const entity = {
      id: event.target.id.value,
      startYear: event.target.startYear.value,
      jobTitle: event.target.jobTitle.value,
      organizationName: event.target.organizationName.value,
      description: event.target.description.value,
    };

    if (isNew) {
      props.createEntity(entity);
    } else {
      props.updateEntity(entity);
    }
  };

  return (
    <div className="employmentDetails-full-container">
      <Grid item md={12} container justify="center">
        <Grid item md={2}></Grid>
        <Grid item md={8}>
          <h2 id="wfmApp.employmentDetails.home.createOrEditLabel" data-cy="EmploymentDetailsCreateUpdateHeading">
            <Translate contentKey="wfmApp.employmentDetails.home.createOrEditLabel">Create or edit a EmploymentDetails</Translate>
          </h2>
        </Grid>
      </Grid>
      <Grid item md={12} container justify="center" spacing={1}>
        <Grid item md={2}></Grid>
        <Grid item md={8} spacing={2}>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <form onSubmit={saveEntity} className="employmentDetails-form">
              {!isNew ? (
                <Grid item md={12} container>
                  <Grid item md={12} xs={12}>
                    <FormControl className="employmentDetails-fields">
                      <TextField
                        id="employment-details-id"
                        className="employmentdetailsid"
                        variant="outlined"
                        name="id"
                        required
                        inputProps={{ readOnly: true }}
                        label={<Translate contentKey="global.field.id">ID</Translate>}
                        value={employmentDetailsEntity.id}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              ) : null}
              <Grid item md={12} container>
                <Grid item md={12} xs={12}>
                  <FormControl className="employmentDetails-fields">
                    <TextField
                      id="employment-details-startYear"
                      data-cy="startYear"
                      type="datetime"
                      className="employmentdetailsstartYear"
                      variant="outlined"
                      name="startYear"
                      placeholder={'YYYY-MM-DD HH:mm'}
                      label={<Translate contentKey="wfmApp.employmentDetails.startYear">Start Year</Translate>}
                      value={isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.employmentDetailsEntity.startYear)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item md={12} container>
                <Grid item md={12} xs={12}>
                  <FormControl className="employmentDetails-fields">
                    <TextField
                      id="employment-details-jobTitle"
                      data-cy="jobTitle"
                      className="employmentdetailsjobTitle"
                      variant="outlined"
                      name="jobTitle"
                      label={<Translate contentKey="wfmApp.employmentDetails.jobTitle">Job Title</Translate>}
                      defaultValue={employmentDetailsEntity.jobTitle}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item md={12} container>
                <Grid item md={12} xs={12}>
                  <FormControl className="employmentDetails-fields">
                    <TextField
                      id="employment-details-organizationName"
                      data-cy="organizationName"
                      className="employmentdetailsorganizationName"
                      variant="outlined"
                      name="organizationName"
                      label={<Translate contentKey="wfmApp.employmentDetails.organizationName">Organization Name</Translate>}
                      defaultValue={employmentDetailsEntity.organizationName}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item md={12} container>
                <Grid item md={12} xs={12}>
                  <FormControl className="employmentDetails-fields">
                    <TextField
                      id="employment-details-description"
                      data-cy="description"
                      className="employmentdetailsdescription"
                      variant="outlined"
                      name="description"
                      label={<Translate contentKey="wfmApp.employmentDetails.description">Description</Translate>}
                      defaultValue={employmentDetailsEntity.description}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item md={12} container>
                <Grid item md={12} xs={12}>
                  <FormControl style={{ display: 'inline-block' }} className="employmentDetails-fields">
                    <Button
                      component={Link}
                      id="cancel-save"
                      to="/employment-details"
                      replace
                      style={{ color: 'black', backgroundColor: 'rgb(13, 202, 240)' }}
                    >
                      <FontAwesomeIcon icon="arrow-left" />
                      &nbsp;
                      <span className="d-none d-md-inline">
                        <Translate contentKey="entity.action.back">Back</Translate>
                      </span>
                    </Button>
                    &nbsp;
                    <Button
                      style={{ color: 'white', backgroundColor: 'blue' }}
                      id="save-entity"
                      data-cy="entityCreateSaveButton"
                      type="submit"
                      disabled={updating}
                    >
                      <FontAwesomeIcon icon="save" />
                      &nbsp;
                      <Translate contentKey="entity.action.save">Save</Translate>
                    </Button>
                  </FormControl>
                </Grid>
              </Grid>
            </form>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  employmentDetailsEntity: storeState.employmentDetails.entity,
  loading: storeState.employmentDetails.loading,
  updating: storeState.employmentDetails.updating,
  updateSuccess: storeState.employmentDetails.updateSuccess,
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EmploymentDetailsUpdate);
