import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import FormControl from '@material-ui/core/FormControl';
import { getEntity, updateEntity, createSubcontractor, reset, getCity, getCountry, setPageNumber } from './subcontractor.reducer';
import { Divider, InputAdornment, Paper } from '@material-ui/core';
import './subcontractor.scss';
import { Link, RouteComponentProps } from 'react-router-dom';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import { Storage } from 'react-jhipster';
import { useHistory } from 'react-router-dom';
import { validationMessageOnChange, validationMessageBeforeSubmit } from '../../../utils/validationMessages';
import country_types from '../../shared/data/country_types.json';
import { getOrganizationSettings } from '../../modules/Setting/generalSettings.reducer';
import { Autocomplete } from '@material-ui/lab';
import { getCountryByCityId, resetCountry } from '../country/country.reducer';
import country from '../country/country';
import Client_Edit_Del_Btn from '../client/components/Client_Edit_Del_Btn';
import Subcontractor_Edit_Del_Btn from './component/subcontractor-edit-del-btn';
import EditIcon from '@material-ui/icons/Edit';
import Spinner from 'app/commonUI/spinner/spinner';

export interface ISubcontractorProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const SubcontractorDetailsForm = props => {
  const { subcontractorEntity, loading, updating } = props;
  const [values, setValues] = useState(subcontractorEntity);
  const history = useHistory();
  const [imageFile, setImageFile] = useState(null);
  const [isNew] = useState(!props.match.params || !props.match.params.id);
  const [fieldError, setFieldError] = useState({
    contactPerson_error: false,
    contactPerson_helper: '',
    email_error: false,
    email_helper: '',
    address_error: false,
    address_helper: '',
    city_error: false,
    city_helper: '',
    postcode_error: false,
    postcode_helper: '',
    country_error: false,
    country_helper: '',
    name_error: false,
    name_helper: '',
  });
  const [cityValue, setCityValue] = React.useState<any | null>([]);
  const [countryValue, setCountryValue] = React.useState<any | null>([]);
  const [isEdit, setIsEdit] = React.useState(false);

  useEffect(() => {
    props.getCountry();
  }, []);

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const handleClose = () => {
    history.push('/subcontractors');
  };

  useEffect(() => {
    if (subcontractorEntity && subcontractorEntity?.city && subcontractorEntity.city && subcontractorEntity.city.id) {
      const data = { id: subcontractorEntity.city.id, text: subcontractorEntity.city.name };
      props.getCountryByCityId(data.id);
      handleCityData('', data);
    }
    setValues(subcontractorEntity);
  }, [subcontractorEntity]);

  useEffect(() => {
    if (props.singleCountry && props.singleCountry !== undefined && props.singleCountry.length !== 0) {
      const data = { id: props.singleCountry[0].id, text: props.singleCountry[0].name };
      handleCountryData('Update', data);
    }
  }, [props.singleCountry]);

  useEffect(() => {
    if (subcontractorEntity.logo && subcontractorEntity.logo !== null) {
      setImageFile(subcontractorEntity.logo);
    }
  }, [subcontractorEntity.logo]);

  const checkValidations = () => {
    const result =
      !fieldError['name_error'] &&
      !fieldError['contactPerson_error'] &&
      !fieldError['email_error'] &&
      !fieldError['postcode_error'] &&
      values['name'] !== undefined &&
      values['contactPerson'] !== undefined &&
      values['email'] !== undefined &&
      values['postcode'] !== undefined;
    return result;
  };

  const checkValidationsBeforeSubmit = () => {
    if (values['contactPerson'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['contactPerson_error']: true }));
      setFieldError(oldValues => ({ ...oldValues, ['contactPerson_helper']: validationMessageBeforeSubmit.name }));
    }
    if (values['name'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['name_error']: true }));
      setFieldError(oldValues => ({ ...oldValues, ['name_helper']: validationMessageBeforeSubmit.name }));
    }
    if (values['postcode'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['postcode_error']: true }));
      setFieldError(oldValues => ({ ...oldValues, ['postcode_helper']: validationMessageBeforeSubmit.postcode }));
    }
    if (values['email'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['email_error']: true }));
      setFieldError(oldValues => ({ ...oldValues, ['email_helper']: validationMessageBeforeSubmit.email }));
    }
  };

  const saveEntity = event => {
    event.preventDefault();
    const entity = {
      ...subcontractorEntity,
      ...values,
      city: props.citiesList?.find(it => it.id.toString() === cityValue?.id?.toString()),
    };
    if (isNew && checkValidations()) {
      const formData = new FormData();
      if (values['subcontractor_logo'] !== undefined) {
        formData.append('avatar', values['subcontractor_logo']);
      }
      props.createSubcontractor(entity, formData);
      props.reset();
    } else if (!isNew && checkValidations()) {
      handleOrganizationSubcontractorRelation(entity);
    } else {
      checkValidationsBeforeSubmit();
    }
  };

  const finishChanges = () => {
    setValues(subcontractorEntity);
    setImageFile(subcontractorEntity.imageUrl);
    setIsEdit(!true);
  };

  const handleOrganizationSubcontractorRelation = value => {
    const newValues = { ...value, ['uId']: Storage.local.get('user_id') };
    const formData = new FormData();
    if (values['subcontractor_logo'] !== undefined) {
      formData.append('avatar', values['subcontractor_logo']);
    }
    props.updateEntity(newValues, formData);
  };

  const Trigger = name => {
    return ({ target: { value } }) => {
      setValues(oldValues => ({ ...oldValues, [name]: value }));
      if (name === 'name' && (!/^(?! )[a-zA-Z\s().-]*$/.test(value) || value === '')) {
        setFieldError(oldFieldError => ({ ...oldFieldError, [name + '_error']: true }));
        setFieldError(oldFieldError => ({ ...oldFieldError, [name + '_helper']: validationMessageOnChange.name }));
      } else if (name === 'contactPerson' && (!/^(?! )[a-zA-Z\s().-]*$/.test(value) || value === '')) {
        setFieldError(oldFieldError => ({ ...oldFieldError, [name + '_error']: true }));
        setFieldError(oldFieldError => ({ ...oldFieldError, [name + '_helper']: validationMessageOnChange.name }));
      } else if (name === 'address' && value === '') {
        setFieldError(oldFieldError => ({ ...oldFieldError, [name + '_error']: true }));
        setFieldError(oldFieldError => ({ ...oldFieldError, [name + '_helper']: validationMessageOnChange.address }));
      } else if (name === 'postcode' && !/^[^-\s][a-zA-Z0-9\s]*$/.test(value)) {
        setFieldError(oldFieldError => ({ ...oldFieldError, [name + '_error']: true }));
        setFieldError(oldFieldError => ({ ...oldFieldError, [name + '_helper']: validationMessageOnChange.postcode }));
      } else if (
        name === 'email' &&
        !/^(([^<>()[\],;:\s@]+(\.[^<>()[\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+\.)+[^<>()[\],;:\s@]{2,})$/i.test(value)
      ) {
        setFieldError(oldFieldError => ({ ...oldFieldError, [name + '_error']: true }));
        setFieldError(oldFieldError => ({ ...oldFieldError, [name + '_helper']: validationMessageOnChange.email }));
      } else {
        setFieldError(oldFieldError => ({ ...oldFieldError, [name + '_error']: false, [name + '_helper']: '' }));
      }
    };
  };

  const handleCountryData = (any, e) => {
    if (any !== 'Update') {
      setCityValue([]);
    }
    setCountryValue(e);
    props.getCity(e.id);
  };

  const handleCityData = (_, e) => {
    setCityValue(e);
  };

  const handlePhoneNumber = phone => {
    setValues(oldValues => ({ ...oldValues, ['phone']: phone }));
  };

  const handleProfilePicture = e => {
    if (e.target.files && e.target.files[0]) {
      setValues(oldFieldValues => ({ ...oldFieldValues, ['subcontractor_logo']: e.target.files[0] }));
      setImageFile(URL.createObjectURL(e.target.files[0]));
    }
  };
  const cancleEditHandler = () => {
    setIsEdit(!true);
  };

  return (
    <div className="subcontractor-create-main-container">
      {loading && <Spinner />}
      <div className="subcontractor-main-outer-container" style={{ padding: isNew ? '5%' : '0 5%' }}>
        {!isNew && (
          <>
            {isEdit === !true && (
              <div className="subcontractor_edit_del_Btn_div">
                <Subcontractor_Edit_Del_Btn isEdit={isEdit} setIsEdit={setIsEdit} props={props} flag="subcontractor_details_form" />
              </div>
            )}
          </>
        )}
        <div className="subcontractor-paper">
          <div className="subcontractor-logo-div">
            <div className="subcontractor-logo">
              <label htmlFor="file-input">
                <input type="file" id="file-input" name="subcontractor_logo" onChange={handleProfilePicture} style={{ display: 'none' }} />
                {isNew ? (
                  <img src="content/icons/Edit_Pic_Icon.png" className="client-pic-edit-icon" />
                ) : isEdit === true ? (
                  <img src="content/icons/Edit_Pic_Icon.png" className="client-pic-edit-icon" />
                ) : (
                  ''
                )}
              </label>
              <img src={imageFile ? imageFile : null} className="subcontractor-logo-size" />
            </div>
          </div>
          <React.Fragment>
            <form noValidate style={{ width: '80%' }}>
              <div className="subcontractor-form-container-1">
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} lg={12} md={12} justify="flex-start">
                    <FormControl className="subcontractor-name-field">
                      <TextField
                        required
                        id="name"
                        name="name"
                        defaultValue={subcontractorEntity.name}
                        value={values['name'] ? values['name'] : ''}
                        onChange={Trigger('name')}
                        label="Subcontractor name"
                        fullWidth
                        autoComplete="given-name"
                        error={fieldError['name_error']}
                        helperText={fieldError['name_helper']}
                        inputProps={{ maxLength: 255 }}
                        variant="outlined"
                        disabled={isNew || isEdit ? false : true}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </div>

              <Divider style={{ margin: '6% 0' }} />

              <Typography style={{ color: '#4720b7', fontSize: '20px', marginBottom: '4%' }}>Point of Contact</Typography>

              <div className="subcontractor-form-container-1">
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="contactPerson"
                      name="contactPerson"
                      defaultValue={subcontractorEntity.contactPerson}
                      value={values['contactPerson'] ? values['contactPerson'] : ''}
                      onChange={Trigger('contactPerson')}
                      label="Name"
                      fullWidth
                      autoComplete="contact-person-given-name"
                      error={fieldError['contactPerson_error']}
                      helperText={fieldError['contactPerson_helper']}
                      inputProps={{ maxLength: 255 }}
                      variant="outlined"
                      disabled={isNew || isEdit ? false : true}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <img src="content/icons/Last Name.png" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="email"
                      name="email"
                      defaultValue={subcontractorEntity.email}
                      value={values['email'] ? values['email'] : ''}
                      onChange={Trigger('email')}
                      label="Email"
                      fullWidth
                      autoComplete="email-address"
                      error={fieldError['email_error']}
                      helperText={fieldError['email_helper']}
                      inputProps={{ maxLength: 255 }}
                      variant="outlined"
                      disabled={isNew || isEdit ? false : true}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <img src="content/icons/Email Address.png" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <PhoneInput
                      inputProps={{
                        name: 'phone',
                        autoFocus: false,
                      }}
                      country={
                        props.settings.country_of_operations
                          ? country_types.find(country => country.value === props.settings.country_of_operations)
                            ? country_types.find(country => country.value === props.settings.country_of_operations).phoneName
                            : 'fr'
                          : 'fr'
                      }
                      value={values['phone'] ? values['phone'] : ''}
                      onChange={phone => handlePhoneNumber(phone)}
                      disabled={isNew || isEdit ? false : true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="postcode"
                      required
                      name="postcode"
                      value={values['postcode'] ? values['postcode'] : ''}
                      onChange={Trigger('postcode')}
                      label="Postcode"
                      fullWidth
                      defaultValue={subcontractorEntity.postcode}
                      autoComplete="postal-address"
                      error={fieldError['postcode_error']}
                      helperText={fieldError['postcode_helper']}
                      inputProps={{ maxLength: 10 }}
                      variant="outlined"
                      disabled={isNew || isEdit ? false : true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="address"
                      name="address"
                      value={values['address'] ? values['address'] : ''}
                      onChange={Trigger('address')}
                      label="Address"
                      fullWidth
                      autoComplete="street-address"
                      error={fieldError['address_error']}
                      helperText={fieldError['address_helper']}
                      inputProps={{ maxLength: 255 }}
                      variant="outlined"
                      defaultValue={subcontractorEntity.address}
                      disabled={isNew || isEdit ? false : true}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <img src="content/icons/location.svg" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl variant="outlined" className="subcontractor-select-fields">
                      <Autocomplete
                        options={
                          props.countriesList &&
                          props.countriesList.map(country => ({
                            id: country.id,
                            text: country.name,
                          }))
                        }
                        noOptionsText={'No Records'}
                        classes={{ listbox: 'autocompletelistbox' }}
                        ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                        getOptionLabel={option => option.text || ''}
                        className="PersonAutocomplete"
                        onChange={handleCountryData}
                        value={countryValue}
                        disabled={isNew || isEdit ? false : true}
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            className="personAutocompleteTextfield autocompleteLabel"
                            label="Country"
                            margin="normal"
                            fullWidth
                            error={fieldError['country_error']}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl variant="outlined" className="subcontractor-select-fields">
                      <Autocomplete
                        options={
                          props.citiesList &&
                          props.citiesList.map(city => ({
                            id: city.id,
                            text: city.name,
                          }))
                        }
                        noOptionsText={'Select Country First'}
                        classes={{ listbox: 'autocompletelistbox' }}
                        ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                        getOptionLabel={option => option.text || ''}
                        className="PersonAutocomplete"
                        onChange={handleCityData}
                        value={cityValue}
                        disabled={isNew || isEdit ? false : true}
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            className="personAutocompleteTextfield autocompleteLabel"
                            label="City"
                            margin="normal"
                            fullWidth
                            error={fieldError['city_error']}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
              {isEdit === true || isNew ? (
                <>
                  <Divider style={{ margin: '7% 0' }} />
                  <Grid item xs={12}>
                    <FormControl style={{ flexDirection: 'row', justifyContent: 'flex-end', width: '100%' }}>
                      <Button
                        component={Link}
                        to={isNew && '/subcontractors'}
                        variant="outlined"
                        className="clientDiscardButton"
                        onClick={finishChanges}
                        id="cancel-save"
                      >
                        <span>Cancel</span>
                      </Button>
                      &nbsp; &nbsp; &nbsp;
                      <Button
                        className="clientSaveButton"
                        style={{ color: 'white', backgroundColor: '#4720B7' }}
                        id="save-entity"
                        data-cy="entityCreateSaveButton"
                        type="submit"
                        onClick={saveEntity}
                      >
                        <span>Save</span>
                      </Button>
                    </FormControl>
                  </Grid>
                </>
              ) : (
                ''
              )}
            </form>
          </React.Fragment>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  subcontractorEntity: storeState.subcontractor.entity,
  loading: storeState.subcontractor.loading,
  updating: storeState.subcontractor.updating,
  updateSuccess: storeState.subcontractor.updateSuccess,
  countriesList: storeState.subcontractor.CountryEntity,
  citiesList: storeState.subcontractor.CityEntity,
  settings: storeState.organizationSettings.storedOrganizationSettings,
  singleCountry: storeState.country.SingleCountryEntity,
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createSubcontractor,
  reset,
  getCity,
  getCountry,
  getCountryByCityId,
  getOrganizationSettings,
  resetCountry,
  setPageNumber,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SubcontractorDetailsForm);
