import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core';
import { Collapse } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const useStyles = makeStyles(theme => ({
  mainPaper: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #d3d3d3',
    padding: '1vh 0vh',
    width: '87%',
    marginLeft: '6.5%',
  },
  subDiv: {
    marginTop: '5px',
    textAlign: 'left',
    width: '59%',
    cursor: 'pointer',
  },
  subContainer: {
    marginTop: '5px',
    marginLeft: '1.9%',
    textAlign: 'left',
    width: '57.2%',
  },
}));

export default function AvailabilityPeople(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.mainPaper}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className={classes.subDiv} onClick={props.barHandler('panel8')}>
            {props.barForProps.panel8 ? <RemoveIcon /> : <AddIcon />}Notify &quot;People&quot; of any activity
          </div>

          <div>
            <Switch
              checked={props.valueForProps['availability_people_email'] ? props.valueForProps['availability_people_email'] : false}
              onChange={props.handler}
              color="primary"
              name="availability_people_email"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </div>

          <div style={{ marginLeft: '13%' }}>
            <Switch
              checked={props.valueForProps['availability_people_sms'] ? props.valueForProps['availability_people_sms'] : false}
              onChange={props.handler}
              color="primary"
              name="availability_people_sms"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Collapse in={props.barForProps.panel8} style={{ width: '100%' }}>
            <div style={{ display: 'flex' }}>
              <div className={classes.subContainer}>Person marked unavailable</div>
              <div>
                <Switch
                  checked={
                    props.valueForProps['availability_people_unavailable_email']
                      ? props.valueForProps['availability_people_unavailable_email']
                      : false
                  }
                  onChange={props.handler}
                  color="primary"
                  name="availability_people_unavailable_email"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </div>
              <div style={{ marginLeft: '13%' }}>
                <Switch
                  checked={
                    props.valueForProps['availability_people_unavailable_sms']
                      ? props.valueForProps['availability_people_unavailable_sms']
                      : false
                  }
                  onChange={props.handler}
                  color="primary"
                  name="availability_people_unavailable_sms"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </div>
            </div>

            <div style={{ display: 'flex' }}>
              <div className={classes.subContainer}>Person marked available</div>
              <div>
                <Switch
                  checked={
                    props.valueForProps['availability_people_available_email']
                      ? props.valueForProps['availability_people_available_email']
                      : false
                  }
                  onChange={props.handler}
                  color="primary"
                  name="availability_people_available_email"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </div>
              <div style={{ marginLeft: '13%' }}>
                <Switch
                  checked={
                    props.valueForProps['availability_people_available_sms']
                      ? props.valueForProps['availability_people_available_sms']
                      : false
                  }
                  onChange={props.handler}
                  color="primary"
                  name="availability_people_available_sms"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </div>
            </div>

            <div style={{ display: 'flex' }}>
              <div className={classes.subContainer}>Person&apos;s unavailability changed</div>
              <div>
                <Switch
                  checked={
                    props.valueForProps['availability_people_unavailability_email']
                      ? props.valueForProps['availability_people_unavailability_email']
                      : false
                  }
                  onChange={props.handler}
                  color="primary"
                  name="availability_people_unavailability_email"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </div>
              <div style={{ marginLeft: '13%' }}>
                <Switch
                  checked={
                    props.valueForProps['availability_people_unavailability_sms']
                      ? props.valueForProps['availability_people_unavailability_sms']
                      : false
                  }
                  onChange={props.handler}
                  color="primary"
                  name="availability_people_unavailability_sms"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </div>
            </div>

            <div style={{ display: 'flex' }}>
              <div className={classes.subContainer}>Person&apos;s availability changed</div>
              <div>
                <Switch
                  checked={
                    props.valueForProps['availability_people_availability_email']
                      ? props.valueForProps['availability_people_availability_email']
                      : false
                  }
                  onChange={props.handler}
                  color="primary"
                  name="availability_people_availability_email"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </div>
              <div style={{ marginLeft: '13%' }}>
                <Switch
                  checked={
                    props.valueForProps['availability_people_availability_sms']
                      ? props.valueForProps['availability_people_availability_sms']
                      : false
                  }
                  onChange={props.handler}
                  color="primary"
                  name="availability_people_availability_sms"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </div>
            </div>
          </Collapse>
        </div>
      </div>
    </React.Fragment>
  );
}
