import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Storage } from 'react-jhipster';
import { getPersonalSettings, storePersonalSettings } from './Personal-Setting.reducer';
import { Select, MenuItem, FormControl, InputLabel, makeStyles, TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import './PersonalSetting.scss';
import { Typography } from '@material-ui/core';
import timezones from '../../shared/data/timezones.json';
import date_format_types from '../../shared/data/date_format_types.json';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { Autocomplete } from '@material-ui/lab';
import { getPrePopulateSettingInSettings } from '../../../utils/FilterValuesFucntions';

const useStyles = makeStyles(theme => ({
  selectBox: {
    width: '100%',
    margin: 'auto',
    marginTop: '4vh',
  },
  buttons: {
    width: '80%',
    marginTop: '3vh',
    marginRight: '5px',
    float: 'right',
  },
}));

const localeMap = [
  { label: '24Hours', value: 1 },
  { label: '12Hours', value: 2 },
];

export interface IPersonalSettingsProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

function PersonalSetting(props: IPersonalSettingsProps) {
  const classes = useStyles();
  const [locale, setLocale] = React.useState({
    id: props.setting.time_format,
    text: props.setting.time_format === 1 ? '24Hours' : '12Hours',
  });
  const [dur, setDur] = React.useState({
    id: props.setting.shift_duration ? props.setting.shift_duration : 12,
  });
  const [dateFormat, setdateFormat] = useState({
    id: props.setting.date_format ? props.setting.date_format : 2,
    text: getPrePopulateSettingInSettings('date_format_types', props.setting.date_format ? props.setting.date_format : 2),
  });
  const [timeZone, setTimezone] = useState({
    id: props.setting.timezone,
    text: getPrePopulateSettingInSettings('timezones', props.setting.timezone),
  });

  useEffect(() => {
    setdateFormat({
      id: props.setting.date_format ? props.setting.date_format : 2,
      text: getPrePopulateSettingInSettings('date_format_types', props.setting.date_format ? props.setting.date_format : 2),
    });
    setLocale({ id: props.setting.time_format, text: props.setting.time_format === 1 ? '24Hours' : '12Hours' });
    setTimezone({ id: props.setting.timezone, text: getPrePopulateSettingInSettings('timezones', props.setting.timezone) });
    setDur({ id: props.setting.shift_duration });
  }, [props.setting]);

  const handletimeformat = (_, e) => {
    if (e) {
      setLocale({ id: e.id, text: e.text });
    } else {
      setLocale({ id: 1, text: '24Hours' });
    }
  };

  const setDuration = e => {
    setDur({ id: e.target.value });
  };

  const handledateformat = (_, e) => {
    if (e) {
      setdateFormat({ id: e.id, text: e.text });
    } else {
      setdateFormat({ id: 8, text: 'YYYY/MM/DD' + ' ' + '(2021/12/31)' });
    }
  };
  const handletimezone = (_, e) => {
    if (e) {
      setTimezone({ id: e.id, text: e.text });
    } else {
      setTimezone({ id: '(GMT+00:00) London', text: 'Europe/London' });
    }
  };
  const submit = () => {
    const setting = {
      time_format: locale.id,
      date_format: dateFormat.id,
      timezone: timeZone.id,
      shift_duration: dur.id,
      type: 'USER',
    };
    props.storePersonalSettings(setting);
    <ToastContainer position={toast.POSITION.TOP_LEFT} className="toastify-container" toastClassName="toastify-toast" />;
    toast.success('Settings Saved');
  };

  const handleDiscard = () => {
    setdateFormat({ id: props.setting.date_format, text: getPrePopulateSettingInSettings('date_format_types', props.setting.date_format) });
    setLocale({ id: props.setting.time_format, text: props.setting.time_format === 1 ? '24Hours' : '12Hours' });
    setTimezone({ id: props.setting.timezone, text: getPrePopulateSettingInSettings('timezones', props.setting.timezone) });
    setDur({ id: props.setting.shift_duration });
  };

  return (
    <React.Fragment>
      <div className="personal-settings-main-container">
        <div className="personalSetting-full-container">
          <Typography variant="h5" className="personalSettingTitle">
            Date & Time
          </Typography>
          <div className="personalSettingUnderTitle" style={{}}>
            Edit your information below
          </div>
          <Paper elevation={2} square className="personalSettingContainerPaper">
            <div className="personal-settings-outer-div">
              <form>
                <Grid container item xs={10} sm={12} md={12} lg={12} spacing={2} id="personal-settings-select-box">
                  <FormControl variant="outlined" className={classes.selectBox}>
                    <Autocomplete
                      options={
                        localeMap &&
                        localeMap.map(localeItem => ({
                          id: localeItem.value,
                          text: localeItem.label,
                        }))
                      }
                      noOptionsText={'No Records'}
                      classes={{ listbox: 'autocompletelistbox' }}
                      ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                      getOptionLabel={option => option.text || ''}
                      className="PersonAutocomplete"
                      onChange={handletimeformat}
                      value={locale}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="outlined"
                          size="small"
                          className="personAutocompleteTextfield"
                          label="Time Format"
                          margin="normal"
                          fullWidth
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid container item xs={10} sm={12} md={12} lg={12} spacing={2} id="personal-settings-select-box">
                  <FormControl variant="outlined" className={classes.selectBox}>
                    <Autocomplete
                      options={
                        date_format_types &&
                        date_format_types.map(date => ({
                          id: date.value,
                          text: date.label + ' ' + date.example,
                        }))
                      }
                      noOptionsText={'No Records'}
                      classes={{ listbox: 'autocompletelistbox' }}
                      ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                      getOptionLabel={option => option.text || ''}
                      className="PersonAutocomplete"
                      onChange={handledateformat}
                      value={dateFormat}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="outlined"
                          size="small"
                          className="personAutocompleteTextfield"
                          label="Date Format"
                          margin="normal"
                          fullWidth
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid container item xs={10} md={12} sm={12} lg={12} spacing={2} id="personal-settings-select-box">
                  <FormControl variant="outlined" className={classes.selectBox}>
                    <Autocomplete
                      options={
                        timezones &&
                        timezones.map(tz => ({
                          id: tz.value,
                          text: tz.label,
                        }))
                      }
                      noOptionsText={'No Records'}
                      classes={{ listbox: 'autocompletelistbox' }}
                      ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                      getOptionLabel={option => option.text || ''}
                      className="PersonAutocomplete"
                      onChange={handletimezone}
                      value={timeZone}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="outlined"
                          size="small"
                          className="personAutocompleteTextfield"
                          label="Timezone"
                          margin="normal"
                          fullWidth
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </form>
            </div>

            <div className="personal-settings-outer-div">
              <form>
                <Grid container item xs={10} sm={12} md={12} lg={12} spacing={2} id="personal-settings-select-box">
                  <FormControl variant="outlined" className={classes.selectBox}>
                    <TextField
                      variant="outlined"
                      size="small"
                      className="personAutocompleteTextfield"
                      label="Shift Duration"
                      type="number"
                      defaultValue={dur.id}
                      value={dur.id}
                      margin="normal"
                      fullWidth
                      InputProps={{ inputProps: { min: 1, max: 24 } }}
                      onChange={setDuration}
                    />
                  </FormControl>
                </Grid>
              </form>
            </div>

            <div className="personal-settings-button-inner-div">
              <Grid container item xs={12} spacing={2} className="personal_settings_discard_button" style={{ marginLeft: 'auto' }}>
                <FormControl className="personal-settings-button">
                  <Button
                    variant="contained"
                    style={{ color: 'black', backgroundColor: '#ffffff', border: '1px solid #C6C6C6' }}
                    onClick={() => {
                      handleDiscard();
                    }}
                  >
                    Discard
                  </Button>
                </FormControl>
              </Grid>

              <Grid container item xs={12} spacing={2} className="personal_settings_save_button">
                <FormControl className="personal-settings-button">
                  <Button
                    variant="contained"
                    style={{ color: 'white', backgroundColor: '#4720B7', border: '1px solid #C6C6C6' }}
                    onClick={() => {
                      submit();
                    }}
                  >
                    Save Changes
                  </Button>
                </FormControl>
              </Grid>
            </div>
          </Paper>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = ({ PersonalSettings }: IRootState) => ({
  setting: PersonalSettings.storedPersonalSettings,
});

const mapDispatchToProps = {
  storePersonalSettings,
  getPersonalSettings,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PersonalSetting);
