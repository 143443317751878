/* eslint-disable complexity */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import {
  Button,
  Grid,
  FormControl,
  TextField,
  MenuItem,
  Paper,
  RadioGroup,
  Radio,
  FormLabel,
  Divider,
  Checkbox,
  FormControlLabel,
  lighten,
} from '@material-ui/core';
import { IRootState } from 'app/shared/reducers';
import './incident.scss';
import { TimePicker, MuiPickersUtilsProvider, DatePicker, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import { Autocomplete } from '@material-ui/lab';
import AccessibleIcon from '@material-ui/icons/Accessible';
import {
  getIncidents,
  getIncidentById,
  createIncidentReport,
  getIncidentTypes,
  getAttachmentTypes,
  updateIncident,
} from './incident.reducer';
import { getEntities as getServiceAreas, reset as resetLoc } from '.././service-area/service-area.reducer';
import { getEntities as getPersons, reset as resetPerson, getPersonForFilters } from '.././person/person.reducer';
import IncidentAttachments from './component/incident-attachments';
import CustomToolbarComponent from '../service-area/CustomToolbarComponent';
import person from '../person/person';
import { getDateFormat } from 'app/shared/util/calender-utils';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Chip from '@material-ui/core/Chip';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import incidentAttachments from './component/incident-attachments';
import { forEach } from 'lodash';

export interface IIncidentUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const IncidentUpdate = props => {
  const [incidentType, setIncidentType] = useState(props.incidentType);
  const [incidentRadio, setIncidentRadio] = useState({});
  const [values, setValues] = useState(props.incidents);
  const [witness, setWitness] = useState([]);
  const [otherType, setOtherType] = useState(false);
  const [incident_type] = useState([]);
  const [comments, setComments] = useState(false);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [isNew] = useState(!props.match.params || !props.match.params.id);
  const [witnessList, setWitnessList] = useState([]);
  const [personList, setPersonList] = useState([]);
  const [fieldError, setFieldError] = useState({
    incident_time_error: false,
    incident_time_helper: '',
    incident_people_error: false,
    incident_people_helper: '',
    service_area_error: false,
    service_area_helper: '',
    incident_description_error: false,
    incident_description_helper: '',
    report_time_error: false,
    report_time_helper: '',
    incident_type_error: false,
    incident_type_helper: '',
  });

  useEffect(() => {
    setIncidentType(props.incidentType);
  }, [props.incidentType]);

  useEffect(() => {
    props.resetLoc();
    props.resetPerson();
    populateWitnessList();
    setValues(props.incidents);
    setValues(oldValues => ({ ...oldValues, incident_date: props.incidents.incident_time }));
  }, [props.incidents]);

  useEffect(() => {
    if (!isNew) {
      props.getIncidentById(props.match.params.id);
    }
    props.getServiceAreas(-1, '');
    props.getPersonForFilters();
    props.getIncidentTypes();
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  useEffect(() => {
    if (!isNew) {
      if (values && values.incident_witness && values.incident_witness.length > 0) {
        setIncidentRadio(oldValues => ({ ...oldValues, any_witness: 'true' }));
      }
      if (
        (values && values.police_arrival_time) ||
        values.police_report_time ||
        values.police_report_number ||
        values.police_involvement_comments
      ) {
        setIncidentRadio(oldValues => ({ ...oldValues, police_involved: 'true' }));
      }
      if (values && values.incident_people) {
        const value = [];
        values.incident_people.forEach(item => {
          value.push({ id: item.person.id, first_name: item.person.first_name, last_name: item.person.last_name });
        });
        setPersonList(value);
      }
    }
  }, [values]);

  const handleIncidentServiceArea = (_, e) => {
    setValues(prevState => ({ ...prevState, service_area: e }));
    setFieldError(oldFieldError => ({ ...oldFieldError, ['service_area_error']: false }));
    setFieldError(oldFieldError => ({ ...oldFieldError, ['service_area_helper']: '' }));
  };

  const populateWitnessList = () => {
    const data = props.incidents.incident_witness;
    const cp = [];

    data &&
      data.map((key, index) => {
        cp.push({
          id: data[index].id,
          witness_name: data[index].witness_name,
          witness_phone: data[index].witness_phone ? data[index].witness_phone : null,
          comment: data[index].comment ? data[index].comment : null,
        });
      });
    setWitnessList(cp);
  };

  const handleIncidentPeople = (_, persons) => {
    setValues(oldValues => ({ ...oldValues, incident_people: persons }));
    setPersonList(persons);
    setFieldError(oldFieldError => ({ ...oldFieldError, ['incident_people_error']: false }));
    setFieldError(oldFieldError => ({ ...oldFieldError, ['incident_people_helper']: '' }));
  };

  const handleIncidentDate = e => {
    const d = moment(e).format('YYYY-MM-DD');
    setValues(oldValues => ({ ...oldValues, ['incident_date']: d }));
  };

  const handleIncidentTime = e => {
    const IncidentTime = moment(moment(e).format('YYYY-MM-DD') + ' ' + moment(e).format('HH:mm')).toISOString();
    setValues(oldValues => ({ ...oldValues, ['incident_time']: IncidentTime }));
  };

  const handlePoliceArrivalTime = e => {
    const PoliceArrivalTime = moment(moment(e).format('YYYY-MM-DD') + ' ' + moment(e).format('HH:mm')).toISOString();
    setValues(oldValues => ({ ...oldValues, ['police_arrival_time']: PoliceArrivalTime }));
  };

  const handlePoliceReportTime = e => {
    const PoliceReportTime = moment(moment(e).format('YYYY-MM-DD') + ' ' + moment(e).format('HH:mm')).toISOString();
    setValues(oldValues => ({ ...oldValues, ['police_report_time']: PoliceReportTime }));
  };

  const handleIncidentType = (event, tag) => {
    const filteredCategory = props.incidentType.filter(entity => entity.id === tag);

    if (filteredCategory && filteredCategory[0]['name'] === 'Other') {
      setOtherType(true);
    } else {
      setOtherType(false);
    }

    let obj = {};
    filteredCategory.forEach((elem, i) => {
      obj = elem;
    });

    setValues(oldValues => ({ ...oldValues, incident_type: obj }));
    setFieldError(oldFieldError => ({ ...oldFieldError, ['incident_type_error']: false }));
    setFieldError(oldFieldError => ({ ...oldFieldError, ['incident_type_helper']: '' }));
  };

  const handleIncidentDetals = event => {
    setValues(oldValues => ({ ...oldValues, incident_description: event.target.value }));
    setFieldError(oldFieldError => ({ ...oldFieldError, ['incident_description_error']: false }));
    setFieldError(oldFieldError => ({ ...oldFieldError, ['incident_description_helper']: '' }));
  };

  const handlePoliceReportNumber = event => {
    setValues(oldValues => ({ ...oldValues, police_report_number: event.target.value }));
  };

  const handleClose = () => {
    props.history.push('/incident-management');
  };

  const handleWtinessName = (event, id, index) => {
    const record = witnessList.find(el => el.id === id);
    record.witness_name = event.target.value;

    const witnessData = [];
    witnessList.forEach((element, idx) => {
      witnessData[idx] = {
        witness_name: element.witness_name ? element.witness_name : null,
      };
    });

    updateWitnessObject();
  };

  const updateWitnessObject = () => {
    const witnessData = [];
    witnessList.forEach((element, idx) => {
      witnessData[idx] = {
        witness_name: element.witness_name ? element.witness_name : null,
        witness_phone: element.witness_phone ? element.witness_phone : null,
        comment: element.comment ? element.comment : null,
      };
    });

    setValues(oldValues => ({ ...oldValues, ['incident_witness']: witnessData }));
  };

  const handleWtinessContact = (event, id, index) => {
    const record = witnessList.find(el => el.id === id);
    record.witness_phone = event.target.value;

    const witnessData = [];
    witnessList.forEach((element, idx) => {
      witnessData[idx] = {
        witness_phone: element.witness_phone ? element.witness_phone : null,
      };
    });

    updateWitnessObject();
  };

  const handleWtinessComments = (event, id, index) => {
    const record = witnessList.find(el => el.id === id);
    record.comment = event.target.value;

    const witnessData = [];
    witnessList.forEach((element, idx) => {
      witnessData[idx] = {
        comment: element.comment ? element.comment : null,
      };
    });

    updateWitnessObject();
  };

  const handlePoliceComments = event => {
    setValues(oldValues => ({ ...oldValues, police_involvement_comments: event.target.value }));
  };

  const handlePoliceRadio = event => {
    setIncidentRadio(oldValues => ({ ...oldValues, police_involved: event.target.value }));
  };

  const handleWitnessRadio = event => {
    setIncidentRadio(oldValues => ({ ...oldValues, any_witness: event.target.value }));
  };

  const handleOtherType = event => {
    setValues(oldValues => ({ ...oldValues, type_other_description: event.target.value }));
  };

  const handleNewWitness = () => {
    setWitnessList([...witnessList, { id: new Date().getTime(), witness_name: '', witness_phone: '', comment: '' }]);
  };

  const typeButtonClass = id => {
    const filteredCategory = props.incidentType.filter(entity => entity?.id === id);
    if (filteredCategory && values && values.incident_type && filteredCategory[0]?.id === values.incident_type?.id) {
      return 'typeSelected';
    } else {
      return 'typeNotSelected';
    }
  };
  const checkForRequiredValidations = () => {
    let output = true;
    if (values.incident_type === undefined || values.incident_type === null) {
      setFieldError(oldFieldError => ({ ...oldFieldError, ['incident_type_error']: true }));
      output = false;
    }
    if (personList.length < 1) {
      setFieldError(oldFieldError => ({ ...oldFieldError, ['incident_people_error']: true }));
      setFieldError(oldFieldError => ({ ...oldFieldError, ['incident_people_helper']: 'Select People Involved' }));
      output = false;
    }
    if (values.incident_description === undefined || values.incident_description === null) {
      setFieldError(oldFieldError => ({ ...oldFieldError, ['incident_description_error']: true }));
      setFieldError(oldFieldError => ({ ...oldFieldError, ['incident_description_helper']: 'Enter Incident Detail' }));
      output = false;
    }
    if (values.service_area === undefined || values.service_area === null) {
      setFieldError(oldFieldError => ({ ...oldFieldError, ['service_area_error']: true }));
      setFieldError(oldFieldError => ({ ...oldFieldError, ['service_area_helper']: 'Enter Service Area' }));
      output = false;
    }
    return output;
  };

  const saveEntity = event => {
    if (checkForRequiredValidations()) {
      event.preventDefault();
      if (isNew) {
        const formData = new FormData();
        formData.append('report_time', values['report_time']);
        formData.append(
          'incident_time',
          moment(moment(values['incident_date']).format('YYYY-MM-DD') + ' ' + moment(values['incident_time']).format('HH:mm')).toISOString()
        );
        formData.append('incident_description', values['incident_description']);
        formData.append('incident_type', JSON.stringify(values['incident_type']));
        formData.append('incident_witness', JSON.stringify(values['incident_witness']) ? JSON.stringify(values['incident_witness']) : ' ');
        formData.append('service_area', JSON.stringify(values['service_area']));
        formData.append('incident_people', JSON.stringify(values['incident_people']));
        formData.append('avatar', values['attachment'] ? values['attachment'] : '');

        formData.append('incident_attachments', values['incident_attachments'] ? JSON.stringify([values['incident_attachments']]) : '');
        formData.append('police_report_time', values['police_report_time'] ? values['police_report_time'] : '');
        formData.append('police_arrival_time', values['police_arrival_time'] ? values['police_arrival_time'] : '');
        formData.append('police_report_number', values['police_report_number'] ? values['police_report_number'] : '');
        formData.append('police_involvement_comments', values['police_involvement_comments'] ? values['police_involvement_comments'] : '');

        props.createIncidentReport(formData);
      } else {
        props.updateIncident(values, values.id);
      }
    }
  };

  return (
    <div className="incident-report-main-container">
      <div className="incident-report-main-heading">Create a Incident Report</div>

      <div className="incident-report-sub-heading">You can edit this info anytime</div>

      <div className="incident-report-inner-container">
        <Paper style={{ overflow: 'auto', padding: '5%' }}>
          <div className="incident-report-sub-container-1">
            <Grid container spacing={3} style={{ marginBottom: '20px' }}>
              <Grid item xs={3}>
                <span> Incident Date </span>
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                  <KeyboardDatePicker
                    autoOk
                    id="date_of_birth"
                    variant="inline"
                    disableFuture
                    size="small"
                    disabled={!isNew ? true : false}
                    className="form-control"
                    inputVariant="outlined"
                    name="date_of_birth"
                    format={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'MM/dd/yyyy'}
                    value={values && values['incident_date'] ? values['incident_date'] : values}
                    ToolbarComponent={prop => <>{<CustomToolbarComponent {...prop} settingFormat={props.setting.date_format} />}</>}
                    placeholder={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'MM/dd/yyyy'}
                    InputAdornmentProps={{ position: 'start' }}
                    onChange={date => handleIncidentDate(date)}
                    style={{
                      width: '100%',
                    }}
                  />{' '}
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={3} lg={3}>
                <span>Incident Time</span>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardTimePicker
                    size="small"
                    ampm={props.setting.time_format === 1 ? false : true}
                    inputVariant="outlined"
                    disabled={!isNew ? true : false}
                    className="incident-report-time-picker"
                    value={values && values['incident_time'] ? values['incident_time'] : values}
                    onChange={option => {
                      handleIncidentTime(option);
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>

            <span>Incident Type</span>

            <Grid container spacing={2} style={{ marginBottom: '20px' }}>
              {incidentType && incidentType.length > 0
                ? incidentType.map((x, i) => (
                    <Grid item className="modal-input" key={i} xs={3} lg={3} md={3}>
                      <FormControl
                        style={{ minWidth: 150 }}
                        className="incident-type-buttons"
                        variant="outlined"
                        error={fieldError['incident_type_error']}
                        size="small"
                      >
                        <Button
                          onClick={() => {
                            handleIncidentType(event, x.id);
                          }}
                          disabled={!isNew ? true : false}
                          key={i}
                          className={typeButtonClass(x.id)}
                          style={{
                            border: '2px solid #D4D4D4',
                          }}
                        >
                          {x.name}
                        </Button>
                      </FormControl>
                    </Grid>
                  ))
                : null}
            </Grid>
            {fieldError['incident_type_error'] === true ? (
              <>
                <div className="incident_type_error_field" style={{ width: '200px', height: '40px' }}>
                  {' '}
                  <NotInterestedIcon fontSize="medium" style={{ width: '15px', height: '25px' }} />
                  <span className="incident_type_field" style={{ fontSize: 'medium' }}>
                    Select Incident Type
                  </span>
                </div>
              </>
            ) : null}

            {otherType === true ? (
              <Grid item lg={6} xs={6} sm={6}>
                <FormControl variant="outlined" className="incident-details-field">
                  <TextField
                    variant="outlined"
                    label="Describe Other Type"
                    disabled={!isNew ? true : false}
                    value={values && values.type_other_description ? values.type_other_description : null}
                    size="small"
                    margin="normal"
                    fullWidth
                    onChange={handleOtherType}
                    error={fieldError['incident_details_error']}
                    helperText={fieldError['incident_details_helper']}
                  />
                </FormControl>
              </Grid>
            ) : null}

            <span> Service Area Name </span>

            <Grid item xs={12} md={8} sm={12} style={{ marginBottom: '20px' }}>
              <Autocomplete
                options={
                  props.locations &&
                  props.locations.map(entity => ({
                    id: entity.id,
                    name: entity.name,
                  }))
                }
                noOptionsText={'Please Create Service Area First'}
                disabled={!isNew ? true : false}
                classes={{ listbox: 'autocompletelistbox' }}
                ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                getOptionLabel={option => option.name}
                getOptionSelected={(option, value) => option.id === value.id}
                className="PersonAutocomplete"
                onChange={handleIncidentServiceArea}
                value={values.service_area || ''}
                defaultValue={values.service_area || ''}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    className="personAutocompleteTextfield autocompleteLabel"
                    margin="normal"
                    required
                    error={(fieldError['service_area_error'] = false)}
                    helperText={fieldError['service_area_helper']}
                    fullWidth
                  />
                )}
              />
            </Grid>

            <span> Involved People </span>

            <Grid item xs={12} md={8} sm={12} style={{ marginBottom: '20px' }}>
              <Autocomplete
                className="PersonAutocomplete"
                ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                limitTags={3}
                multiple
                disabled={!isNew ? true : false}
                options={props.people}
                disableCloseOnSelect
                getOptionLabel={option => option.first_name + ' ' + option.last_name}
                value={personList}
                defaultValue={[]}
                renderTags={values => values.map(value => <Chip key={value.id} label={value.first_name + ' ' + value.last_name} />)}
                getOptionSelected={(option, value) => option.id === value.id}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected ? selected : null} />
                    {option.first_name + ' ' + option.last_name || ''}
                  </React.Fragment>
                )}
                onChange={handleIncidentPeople}
                renderInput={params => (
                  <TextField
                    {...params}
                    error={fieldError['incident_people_error']}
                    helperText={fieldError['incident_people_helper']}
                    required
                    variant="outlined"
                  />
                )}
              />
            </Grid>

            <span> Incident Details</span>
            <Grid item lg={12} xs={12} sm={12}>
              <FormControl variant="outlined" className="incident-details-field">
                <TextField
                  variant="outlined"
                  disabled={!isNew ? true : false}
                  value={values ? values?.incident_description : null}
                  size="small"
                  margin="normal"
                  multiline
                  rows={6}
                  fullWidth
                  onChange={handleIncidentDetals}
                  error={fieldError['incident_description_error']}
                  helperText={fieldError['incident_description_helper']}
                  required
                />
              </FormControl>
            </Grid>
          </div>

          <div className="incident-report-sub-container-2">
            <Grid item xs={12} md={6} sm={12}>
              <FormControl component="fieldset" className="person-fields">
                <FormLabel component="legend" style={{ color: 'black' }}>
                  Police Involved ?
                </FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  className="personRadioButtons"
                  value={incidentRadio['police_involved'] ? incidentRadio['police_involved'] : null}
                  onChange={handlePoliceRadio}
                >
                  <FormControlLabel value="true" control={<Radio style={{ color: '#4620B7' }} />} label="Yes" />
                  <FormControlLabel value="false" control={<Radio style={{ color: '#4620B7' }} />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>

            {incidentRadio['police_involved'] === 'true' ? (
              <Grid container spacing={3} style={{ marginBottom: '20px' }}>
                <Grid item xs={3} lg={3}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardTimePicker
                      size="small"
                      ampm={props.setting.time_format === 1 ? false : true}
                      inputVariant="outlined"
                      label="Report Time"
                      className="incident-report-time-picker"
                      value={values && values['police_report_time'] ? values['police_report_time'] : values}
                      onChange={option => {
                        handlePoliceReportTime(option);
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={3} lg={3}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardTimePicker
                      size="small"
                      ampm={props.setting.time_format === 1 ? false : true}
                      inputVariant="outlined"
                      label="Arrival Time"
                      className="incident-report-time-picker"
                      value={values && values['police_arrival_time'] ? values['police_arrival_time'] : values}
                      onChange={option => {
                        handlePoliceArrivalTime(option);
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item lg={4} xs={6} sm={6}>
                  <FormControl variant="outlined" className="incident-report-number-field">
                    <TextField
                      variant="outlined"
                      size="small"
                      margin="normal"
                      fullWidth
                      value={values && values['police_report_number'] ? values['police_report_number'] : null}
                      label="Report Ref No. (CAD)"
                      placeholder="Report Ref No. (CAD)"
                      onChange={handlePoliceReportNumber}
                    />
                  </FormControl>
                </Grid>

                <Grid item lg={12} xs={12} sm={12}>
                  <FormControl variant="outlined" className="incident-details-field">
                    <TextField
                      variant="outlined"
                      size="small"
                      margin="normal"
                      label="Police Comments"
                      value={values && values['police_involvement_comments'] ? values['police_involvement_comments'] : null}
                      onChange={handlePoliceComments}
                      multiline
                      rows={6}
                      fullWidth
                    />
                  </FormControl>
                </Grid>
              </Grid>
            ) : null}

            <Grid item xs={12} md={6} sm={12}>
              <FormControl component="fieldset" className="person-fields">
                <FormLabel component="legend" style={{ color: 'black' }}>
                  Any Witnesse(s)
                </FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  className="personRadioButtons"
                  value={incidentRadio['any_witness'] ? incidentRadio['any_witness'] : null}
                  onChange={handleWitnessRadio}
                >
                  <FormControlLabel value="true" control={<Radio style={{ color: '#4620B7' }} />} label="Yes" />
                  <FormControlLabel value="false" control={<Radio style={{ color: '#4620B7' }} />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>

            {incidentRadio['any_witness'] === 'true' ? (
              <>
                {witnessList &&
                  witnessList.map((entity, index) => (
                    <Grid container spacing={3} key={index} style={{ marginBottom: '20px' }}>
                      <Grid item lg={4} xs={6} sm={6}>
                        <FormControl variant="outlined" className="incident-officer-name-field">
                          <TextField
                            variant="outlined"
                            disabled={!isNew ? true : false}
                            size="small"
                            margin="normal"
                            fullWidth
                            label="Witness Name"
                            onChange={() => {
                              handleWtinessName(event, entity.id, index);
                            }}
                            placeholder="Witness Name"
                            value={entity.witness_name ? entity.witness_name : ''}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item lg={4} xs={6} sm={6}>
                        <FormControl variant="outlined" className="incident-officer-name-field">
                          <TextField
                            variant="outlined"
                            disabled={!isNew ? true : false}
                            size="small"
                            margin="normal"
                            fullWidth
                            label="Phone No"
                            onChange={() => {
                              handleWtinessContact(event, entity.id, index);
                            }}
                            value={entity.witness_phone ? entity.witness_phone : ''}
                            placeholder="Phone No"
                          />
                        </FormControl>
                      </Grid>

                      <Grid item lg={12} xs={12} sm={12}>
                        <FormControl variant="outlined" className="incident-details-field">
                          <TextField
                            variant="outlined"
                            disabled={!isNew ? true : false}
                            size="small"
                            margin="normal"
                            label="Witness Comments"
                            value={entity.comment ? entity.comment : ''}
                            onChange={() => {
                              handleWtinessComments(event, entity.id, index);
                            }}
                            multiline
                            rows={6}
                            fullWidth
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  ))}

                <div style={{ marginLeft: 'auto' }}>
                  <Button onClick={handleNewWitness} variant="contained" id="save-entity" type="submit" className="incidentSaveButton">
                    Add New Witness
                  </Button>
                </div>
              </>
            ) : null}
          </div>

          <div className="incident-report-sub-container-3">
            <IncidentAttachments
              isNew={isNew}
              setValues={setValues}
              values={values}
              match={undefined}
              history={undefined}
              location={undefined}
            />
          </div>

          <Grid item xs={12} md={12} sm={12} style={{ textAlign: 'center', marginTop: '50px' }}>
            <FormControl style={{ display: 'inline-block', float: 'right' }}>
              <Button variant="outlined" className="incidentDiscardButton" component={Link} to="/incident-management">
                Cancel
              </Button>
              &nbsp;
              <Button
                onClick={() => {
                  saveEntity(event);
                }}
                variant="contained"
                id="save-entity"
                type="submit"
                className="incidentSaveButton"
              >
                Save Changes
              </Button>
            </FormControl>
          </Grid>
        </Paper>
      </div>
    </div>
  );
};

const mapStateToProps = ({ Incident, serviceArea, person, PersonalSettings }: IRootState) => ({
  incidentType: Incident.incident_type_list,
  locations: serviceArea.entities,
  people: person.filterPersons,
  setting: PersonalSettings.storedPersonalSettings,
  incidents: Incident.incident,
  updateSuccess: Incident.updateSuccess,
});

const mapDispatchToProps = {
  getIncidentTypes,
  getServiceAreas,
  getPersons,
  createIncidentReport,
  getIncidentById,
  updateIncident,
  resetLoc,
  resetPerson,
  getPersonForFilters,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(IncidentUpdate);
