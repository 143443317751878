import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { RouteComponentProps } from 'react-router-dom';
import { getNotificationSettings, updateNotificationSettings } from './notification-setting.reducer';
import 'react-toastify/dist/ReactToastify.css';
import { Drawer, Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { NotificationsActiveSharp, SubjectOutlined } from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { FormControl, makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import './index.scss';
import Clocking from './notification-settings-components/clocking';
import Scheduling from './notification-settings-components/scheduling';
import Availability from './notification-settings-components/availability';
import StaffData from './notification-settings-components/staff-data';
import DailySignUps from './notification-settings-components/daily-sign-ups';
import SystemAccess from './notification-settings-components/system-access';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  paper: {
    margin: 'auto',
    marginTop: '1%',
    width: '70%',
  },
  buttons: {
    minWidth: 100,
    marginTop: '3vh',
    marginRight: '2vh',
    float: 'right',
  },
  drawer: {
    width: drawerWidth,
  },
  drawerPaper: {
    width: drawerWidth,
    position: 'absolute',
    marginTop: '1vh',
    marginLeft: 0,
    zIndex: 1,
  },
  active: {
    background: '#F4F4F4',
  },
  title: {
    textAlign: 'left',
    marginLeft: '6.5%',
  },
  mainTitle: {
    marginTop: '4%',
    marginLeft: '3%',
    textAlign: 'left',
    color: '#6610f2',
    fontWeight: 450,
  },
  subTitle: {
    marginTop: '0.6%',
    marginLeft: '3%',
    textAlign: 'left',
  },
  flexContainer: {
    display: 'flex',
    background: 'linear-gradient(to top, #FFFFFF 50%, #EBECF0 50%)',
  },
  mainContainer: {
    backgroundColor: 'white',
    marginLeft: '3%',
    marginTop: '5%',
    marginRight: '4%',
  },
}));

export interface INotificationSettingProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

function NotificationSettings(props: INotificationSettingProps) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [values, setValues] = useState(props.settings);
  const [panelValue, SetPanelValue] = useState({
    panel1: false,
    panel2: false,
    panel3: false,
    panel4: false,
    panel5: false,
    panel6: false,
    panel7: false,
    panel8: false,
    panel9: false,
    panel10: false,
    panel11: false,
    panel12: false,
    panel13: false,
    panel14: false,
    panel15: false,
    panel16: false,
    panel17: false,
    panel18: false,
    panel19: false,
    panel20: false,
    panel21: false,
    panel22: false,
    panel23: false,
    panel24: false,
    panel25: false,
  });

  useEffect(() => {
    props.getNotificationSettings();
  }, []);

  useEffect(() => {
    setValues(props.settings);
  }, [props.settings]);

  const handleChange = e => {
    if (e.target.name === 'reply') {
      setValues(oldValues => ({ ...oldValues, [e.target.name]: e.target.value }));
    } else {
      setValues(oldValues => ({ ...oldValues, [e.target.name]: e.target.checked }));
    }
  };

  const submit = () => {
    props.updateNotificationSettings(values);
  };

  const handleBar = e => param1 => {
    SetPanelValue(oldPanelValue => ({ ...oldPanelValue, [e]: !oldPanelValue[e] }));
  };

  return (
    <div style={{ textAlign: 'center' }} className={classes.flexContainer}>
      <div className="notification-settings-main-external-div" style={{ width: '100%' }}>
        <Typography variant="h5" className={classes.mainTitle}>
          Notification Settings
        </Typography>
        <div className={classes.subTitle}>Edit your settings below</div>
        <div className={classes.mainContainer}>
          <br />
          <br />
          <br />
          <br />
          <Typography variant="h6" className={classes.title}>
            Edit Email
          </Typography>

          <div style={{ display: 'flex', marginLeft: '6.5%', marginTop: '1%', paddingBottom: '3%' }}>
            &nbsp;
            <h6 style={{ marginTop: '1vh' }}>Reply to Email For People Notifications</h6>
            &nbsp;
            <TextField
              style={{ width: '30%' }}
              id="reply"
              variant="outlined"
              name="reply"
              size="small"
              inputProps={{ min: 0, style: { textAlign: 'left' } }}
              value={values['reply'] ? values['reply'] : ''}
              onChange={handleChange}
              label=""
              fullWidth
              autoComplete="given-name"
            />
          </div>

          <Typography variant="h6" className={classes.title}>
            CLOCKING
          </Typography>

          <div style={{ display: 'flex' }}>
            <div style={{ width: '58.5%' }} />
            <div>Email</div>
            <div style={{ marginLeft: '12.5%' }}>SMS</div>
          </div>

          <Clocking handler={handleChange} barForProps={panelValue} barHandler={handleBar} valueForProps={values} />

          <Typography variant="h6" className={classes.title} style={{ padding: '2% 1% 0% 0%' }}>
            SCHEDULING
          </Typography>
          <Scheduling handler={handleChange} barForProps={panelValue} barHandler={handleBar} valueForProps={values} />

          <Typography variant="h6" className={classes.title} style={{ padding: '2% 1% 0% 0%' }}>
            AVAILABILITY
          </Typography>
          <Availability handler={handleChange} barForProps={panelValue} barHandler={handleBar} valueForProps={values} />

          <Typography variant="h6" className={classes.title} style={{ padding: '2% 1% 0% 0%' }}>
            STAFF DATA
          </Typography>
          <StaffData handler={handleChange} barForProps={panelValue} barHandler={handleBar} valueForProps={values} />

          <Typography variant="h6" className={classes.title} style={{ padding: '2% 1% 0% 0%' }}>
            DAILY SIGN-UPS
          </Typography>
          <DailySignUps handler={handleChange} barForProps={panelValue} barHandler={handleBar} valueForProps={values} />

          <Typography variant="h6" className={classes.title} style={{ padding: '2% 1% 0% 0%' }}>
            SYSTEM ACCESS
          </Typography>
          <SystemAccess handler={handleChange} barForProps={panelValue} barHandler={handleBar} valueForProps={values} />

          <Grid item xs={12} md={12} sm={12} style={{ textAlign: 'center' }}>
            <FormControl className="organizationSetting-fields organizationSettingSaveDiscardButtons">
              <Button variant="outlined" className="DiscardButtonLayout notificationSettingDiscardButton" href="/">
                Cancel
              </Button>
              &nbsp;
              <Button
                onClick={() => {
                  submit();
                }}
                variant="contained"
                id="save-entity"
                type="submit"
                className="SaveButtonLayout notificationSettingSaveButton"
              >
                Save Changes
              </Button>
            </FormControl>
          </Grid>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ NotificationSetting }: IRootState) => ({
  settings: NotificationSetting.storedSettings,
});

const mapDispatchToProps = {
  updateNotificationSettings,
  getNotificationSettings,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSettings);
