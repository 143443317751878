/* eslint-disable no-console */
/* eslint-disable no-debugger */
import 'react-toastify/dist/ReactToastify.css';
import './app.scss';
import 'app/config/dayjs';

import React, { useEffect, useContext, useState } from 'react';
import { connect } from 'react-redux';
import { Card } from 'reactstrap';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { hot } from 'react-hot-loader';
import { Storage } from 'react-jhipster';
import { IRootState } from 'app/shared/reducers';
import { getSession } from 'app/shared/reducers/authentication';
import { getProfile } from 'app/shared/reducers/application-profile';
import { setLocale } from 'app/shared/reducers/locale';
import Header from 'app/shared/layout/header/header';
import Footer from 'app/shared/layout/footer/footer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import ErrorBoundary from 'app/shared/error/error-boundary';
import { AUTHORITIES } from 'app/config/constants';
import AppRoutes from 'app/routes';
import { ThemeContext } from './shared/layout/global/ThemeProvider';
import { getPersonalSettings } from '../app/modules/Setting/Personal-Setting.reducer';
import { getOrganizationSettings } from '../app/modules/Setting/generalSettings.reducer';
import { getPermissionsSettings } from '../app/modules/Setting/RolesAndPermissionsSettings/RolesAndPermissions.reducer';
import IconBreadcrumbs from './shared/layout/header/breadCrumbs';
import { Divider } from '@material-ui/core';
import { onMessageListener } from '../firebase.js';
import {
  getNotifications,
  getBackGroundPushNotifications,
  getBackGroundPushNotificationsForUser,
} from '../app/modules/notifications/Notifications.reducer';

const baseHref = document.querySelector('base').getAttribute('href').replace(/\/$/, '');

export interface IAppProps extends StateProps, DispatchProps {}

export const App = (props: IAppProps) => {
  const { theme, toggleTheme } = useContext(ThemeContext);

  useEffect(() => {
    const setup = async () => {
      props.getSession();
      document.addEventListener('visibilitychange', getBackgroundNotifications);
      const token = (await Storage.local.get('jhi-authenticationToken')) || Storage.session.get('jhi-authenticationToken');
      const org = Storage.local.get('organizationSelected');
      if (token && props.is_vetted) {
        if (org && !props.isUser) {
          props.getPersonalSettings();
          props.getOrganizationSettings();
          props.getPermissionsSettings();
          props.getNotifications(1);
        } else if (org && props.isUser) {
          props.getPersonalSettings();
          props.getOrganizationSettings();
        }
      }
    };
    setup();
  }, [props.is_vetted]);

  const getBackgroundNotifications = () => {
    if (
      (document.visibilityState === 'visible' && Storage.local.get('jhi-authenticationToken')) ||
      Storage.session.get('jhi-authenticationToken')
    ) {
      if (props.isManager || props.isOrganizationAdministrator || props.isSupervisor || props.isSystemAdmin) {
        getBackGroundPushNotifications();
      }
      if (props.isUser) {
        getBackGroundPushNotificationsForUser();
      }
    }
  };

  onMessageListener();

  const paddingTop = '60px';
  return (
    // <h1>Sorry! We are under maintenance currently!!</h1>
    <Router basename={baseHref}>
      <div className="app-container">
        {/* <div id="themeToggle">
          <button className="theme-toggle" onClick={toggleTheme}>
            Switch to {theme === 'light' ? 'dark' : 'light'} mode
          </button>
        </div> */}
        <ToastContainer position={toast.POSITION.TOP_LEFT} className="toastify-container" toastClassName="toastify-toast" />
        {props.isAuthenticated && (
          <ErrorBoundary>
            <Header
              isAuthenticated={props.isAuthenticated}
              isVetted={props.is_vetted}
              isSystemAdmin={props.isSystemAdmin}
              isOrganizationAdministrator={props.isOrganizationAdministrator}
              isSupervisor={props.isSupervisor}
              isManager={props.isManager}
              isUser={props.isUser}
              currentLocale={props.currentLocale}
              onLocaleChange={props.setLocale}
              ribbonEnv={props.ribbonEnv}
              isInProduction={props.isInProduction}
              isOpenAPIEnabled={props.isOpenAPIEnabled}
              imageUrl={props.imageUrl}
              canViewClients={props.permissions ? props.permissions.add_remove_clients : false}
              canViewServiceArea={props.permissions ? props.permissions.add_remove_locations : false}
              canViewSubContractors={props.permissions ? props.permissions.manage_subcontractors : false}
              organizationSelected={Storage.local.get('organizationSelected') ? true : false}
            />
            <Divider className="dividerLine" style={{ color: '#00000066' }} />
            <IconBreadcrumbs />
          </ErrorBoundary>
        )}
        <div className="container-fluid view-container" id="app-view-container">
          <Card className="jh-card">
            <ErrorBoundary>
              <AppRoutes />
            </ErrorBoundary>
          </Card>
          <br />
        </div>
      </div>
      {props.isAuthenticated && <Footer />}
    </Router>
  );
};

const mapStateToProps = ({ authentication, applicationProfile, locale, PersonalSettings, organizationSettings }: IRootState) => ({
  currentLocale: locale.currentLocale,
  isAuthenticated: authentication.isAuthenticated,
  is_vetted: authentication.is_vetted,
  isSystemAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.SYSTEM_ADMIN]),
  isOrganizationAdministrator: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.ORGANIZATION_ADMINISTRATOR]),
  isSupervisor: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.SUPERVISOR]),
  isManager: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.MANAGER]),
  isUser: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.USER]),
  ribbonEnv: applicationProfile.ribbonEnv,
  isInProduction: applicationProfile.inProduction,
  isOpenAPIEnabled: applicationProfile.isOpenAPIEnabled,
  setting: PersonalSettings.storedPersonalSettings,
  settings: organizationSettings.storedOrganizationSettings,
  permissions: authentication.account.permission,
  imageUrl: authentication.account.imageUrl,
});

const mapDispatchToProps = {
  setLocale,
  getSession,
  getProfile,
  getNotifications,
  getPersonalSettings,
  getOrganizationSettings,
  getPermissionsSettings,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(hot(module)(App));
