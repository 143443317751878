import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { useState, useEffect } from 'react';
import '../oraganization_qualification.scss';
import { Grid, TextField, IconButton, FormControl, Button, makeStyles, FormLabel } from '@material-ui/core';
import { Radio, RadioGroup } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import { Divider } from '@material-ui/core';
import IOSSwitch from '../../RolesAndPermissionsSettings/Components/IOSswitchComponent';

export default function Qualification(props) {
  return (
    <>
      <div style={{ display: 'flex' }}>
        <Grid item xs={10}>
          <TextField
            className="add-qualification-text-field"
            variant="outlined"
            name="name"
            onChange={() => {
              props.handleQualification(event);
            }}
            value={props.values.name}
          />
        </Grid>

        <Grid item xs={3} style={{ paddingLeft: '2%' }} className="qualification-delete-button">
          <IconButton
            onClick={() => {
              props.handleDeleteQualification(props.values.id);
            }}
          >
            <img style={{ width: '65%' }} src="content/images/Trash4.png" />
          </IconButton>
        </Grid>
      </div>

      <div className="qualification-attributes-text">Attributes saved for this Qualification</div>

      <Grid container spacing={2}>
        <Grid item className="modal-input" xs={5} sm={5} md={3} lg={3}>
          <FormControl variant="outlined" size="small" className="qualification-checkbox-buttons">
            <FormControlLabel
              id={props.values.expiry_required === true ? 'checked' : 'unchecked'}
              className="organization-qualification-checkbox"
              name="expiry"
              label="Expiry"
              labelPlacement="start"
              control={
                <Checkbox
                  checked={props.values.expiry_required}
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleCheckedFilled />}
                  onChange={() => props.handleExpiryCheck()}
                />
              }
              style={{
                backgroundColor: props.values.expiry_required === true ? '#D48BD3' : 'white',
                color: props.values.expiry_required === true ? 'white' : 'black',
              }}
            />
          </FormControl>
        </Grid>

        <Grid item className="modal-input" xs={6} sm={5} md={3} lg={3}>
          <FormControl variant="outlined" size="small" className="qualification-checkbox-buttons">
            <FormControlLabel
              id={props.values.proof_required === true ? 'checked' : 'unchecked'}
              className="organization-qualification-checkbox"
              name="attachProof"
              label="Attach Proof"
              labelPlacement="start"
              control={
                <Checkbox
                  checked={props.values.proof_required}
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleCheckedFilled />}
                  onChange={() => props.handleProofCheck()}
                />
              }
              style={{
                backgroundColor: props.values.proof_required === true ? '#D48BD3' : 'white',
                color: props.values.proof_required === true ? 'white' : 'black',
              }}
            />
          </FormControl>

          {props.values.proof_required === true ? (
            <div>
              {props.values.mandatory_proof === true ? (
                <a style={{ fontWeight: 'normal' }}>Mandatory</a>
              ) : (
                <a style={{ fontWeight: 'normal' }}>Optional</a>
              )}
              <IOSSwitch checked={props.values.mandatory_proof}> </IOSSwitch>
            </div>
          ) : null}
        </Grid>
      </Grid>

      <Divider style={{ color: '#00000066', width: '90%', marginTop: '50px', marginBottom: '50px' }} />
    </>
  );
}
