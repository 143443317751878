import { IAddress } from 'app/shared/model/address.model';
import { IOrganization } from 'app/shared/model/organization.model';
import { IDesignation } from './designation.model';
import { IClient } from './client.model';
import { ICheckpoint } from './checkpoint.model';
import { IPerson } from './person.model';

export interface IServiceArea {
  id?: number;
  name?: string | null;
  identification_number?: string | null;
  remoteAllowed?: boolean | null;
  remote_allowed?: boolean | null;
  address?: IAddress | null;
  designation?: IDesignation | null;
  charge_rate?: number | null;
  charge_rate_start_date?: string | null;
  charge_rate_effective_date?: string | null;
  organizations?: IOrganization[] | null;
  client?: IClient | null;
  checkpoints?: boolean | null;
  sequence?: string | null;
  checkpointValues?: ICheckpoint[] | null;
  no_of_checkpoints?: number | null;
  clientId?: number | null;
  employeeNames?: any;
  map?: boolean;
}

export const defaultValue: Readonly<IServiceArea> = {
  remoteAllowed: true,
  checkpoints: false,
  sequence: null,
};

export interface ICheckpointData {
  id?: number | null;
}

export interface IBannedEmployees {
  id?: number;
  person?: IPerson | null;
  serviceArea?: IServiceArea | null;
  start_date?: string | null;
  end_date?: string | null;
  description?: string | null;
}

export const BannedDefaultValue: Readonly<IBannedEmployees> = {};
