import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { getEntity } from './contact-type.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IContactTypeDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const ContactTypeDetail = (props: IContactTypeDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { contactTypeEntity } = props;
  return (
    <div>
      <Grid item container>
        <Grid item xs={8}>
          <h2 data-cy="contactTypeDetailsHeading">
            <Translate contentKey="wfmApp.contactType.detail.title">ContactType</Translate>
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="id">
                <Translate contentKey="global.field.id">ID</Translate>
              </span>
            </dt>
            <dd>{contactTypeEntity.id}</dd>
            <dt>
              <span id="name">
                <Translate contentKey="wfmApp.contactType.name">Name</Translate>
              </span>
            </dt>
            <dd>{contactTypeEntity.name}</dd>
          </dl>
          <Button
            component={Link}
            to="/contact-type"
            replace
            style={{ color: 'black', backgroundColor: 'rgb(13, 202, 240)' }}
            data-cy="entityDetailsBackButton"
          >
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button
            component={Link}
            to={`/contact-type/${contactTypeEntity.id}/edit`}
            replace
            style={{ color: 'white', backgroundColor: 'blue' }}
          >
            <FontAwesomeIcon icon="pencil-alt" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = ({ contactType }: IRootState) => ({
  contactTypeEntity: contactType.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ContactTypeDetail);
