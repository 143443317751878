export interface IAppFormAboutPosition {
  id?: number;
  position?: string | null;
  jobAvailability?: string | null;
  personAvailability?: IPersonAvailability | null;
  expectedDate?: Date | string | null;
}

interface IPersonAvailability {
  monday?: boolean | null;
  tuesday?: boolean | null;
  wednesday?: boolean | null;
  thursday?: boolean | null;
  friday?: boolean | null;
  saturday?: boolean | null;
  sunday?: boolean | null;
}
export const defaultValue: Readonly<IAppFormAboutPosition> = {};
