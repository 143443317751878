import moment from 'moment';
import { exportPDF } from '../../entities/timesheet-summary/exportPDF';

const header = ['Date/Time', 'Assignee Name', 'Action Performed', 'DESCRIPTION', 'Action Details'];

export const convertToPDF = (jsonData, selectedDateFrom, selectedDateTo) => {
  const DateFrom = 'From: ' + (selectedDateFrom === undefined ? '' : moment(selectedDateFrom).format('DD-MM-YYYY'));
  const DateTo = 'Till: ' + (selectedDateTo === undefined ? '' : moment(selectedDateTo).format('DD-MM-YYYY'));
  const headers = [header];
  const title = 'Activity Log Report';

  const data = jsonData.map(row => [
    row.time,
    row.personName,
    row.action,
    row.description +
      '\n( Shift Date: ' +
      row.time.split('\n')[0] +
      ')' +
      '\nStart Time: ' +
      row.startTimeDescription +
      '\nEnd Time: ' +
      row.endTimeDescription,
    row.actionBy,
  ]);
  const filename = 'Activity_Log_Report.pdf';

  exportPDF(DateFrom, DateTo, headers, title, data, filename);
};
