import React, { useLayoutEffect } from 'react';
import { connect } from 'react-redux';

import { IRootState } from 'app/shared/reducers';
import { logout } from 'app/shared/reducers/authentication';
import { destroyDeviceToken } from 'app/shared/reducers/authentication';
import { useHistory } from 'react-router-dom';
export interface ILogoutProps extends StateProps, DispatchProps {
  idToken: string;
  logoutUrl: string;
  deviceToken: any;
}

export const Logout = (props: ILogoutProps) => {
  const history = useHistory();
  useLayoutEffect(() => {
    const clearDeviceTokenLogout = async () => {
      await destroyDeviceToken(props.deviceToken?.id);
      props.logout();
      const logoutUrl = props.logoutUrl;
      if (logoutUrl) {
        // if Keycloak, logoutUrl has protocol/openid-connect in it
        window.location.href = logoutUrl.includes('/protocol')
          ? logoutUrl + '?redirect_uri=' + window.location.origin
          : logoutUrl + '?id_token_hint=' + props.idToken + '&post_logout_redirect_uri=' + window.location.origin;
      }
      history.push('/login');
    };
    clearDeviceTokenLogout();
  });

  return <React.Fragment />;
};

const mapStateToProps = (storeState: IRootState) => ({
  logoutUrl: storeState.authentication.logoutUrl,
  idToken: storeState.authentication.idToken,
  deviceToken: storeState.authentication.deviceToken,
});

const mapDispatchToProps = { logout };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
