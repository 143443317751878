import React, { Component } from 'react';
import { List } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import Divider from '@material-ui/core/Divider';
import '../../form.scss';

interface Actions {
  id: number;
  completed: boolean;
  value: string;
}
interface Props {
  items: Actions[];
  activeModule: number;
  formToggle: (e: number) => void;
}

export const Sidebar: React.FC<Props> = ({ items, formToggle, activeModule }) => {
  return (
    <div className="container_steps">
      <List component="nav">
        {items.map((item, index) => (
          <ListItem
            key={index}
            className={`nav-link ${item.completed === false && `disabled-link`}`}
            // onClick={() => formToggle(item.id)}
            button
          >
            <ListItemIcon
              className={`step_index step_index${item.id} ${item.id === activeModule && `active`} ${
                item.id !== activeModule && item.completed === true && `completed-index`
              }`}
            >
              <FontAwesomeIcon icon={faCircle} />
            </ListItemIcon>

            <ListItemText
              primary={item.value}
              className={`step_text step_text${item.id} ${item.id === activeModule && `active`}   
                    ${item.id !== activeModule && item.completed === true && `completed-text`}`}
            />
          </ListItem>
        ))}
        <div>
          {/* <div className='sidebar-contact'></div> */}
          <hr
            style={{ height: '2px', width: '90%', borderWidth: '0', color: '#626262', backgroundColor: '#626262' }}
          ></hr>
        </div>
      </List>
      <div className="d-flex">
        <div className="vr"></div>
      </div>
    </div>
  );
};
