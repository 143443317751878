import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { IRootState } from 'app/shared/reducers';
import { getReport, reset } from './action-history.reducer';
import { IActionHistory } from 'app/shared/model/action-history.model';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import SearchBar from 'material-ui-search-bar';
import { getPersonalSettings } from '../../modules/Setting/Personal-Setting.reducer';
import { getEntities as getPersons, reset as resetPerson, getPersonForFilters } from 'app/entities/person/person.reducer';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import timePeriod from '../../shared/data/time_period_other.json';
import moment from 'moment';
import CustomToolbarComponent from '../service-area/CustomToolbarComponent';
import { ActivityDescriptionDateFormat, changeDateFormat, changeTimeFormat } from '../../../utils/TimeFormatAndTimezone';
import '../../../utils/GlobalCssRequiredInWebsite.scss';
import './action-history.scss';
import { CSVLink } from 'react-csv';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Autocomplete } from '@material-ui/lab';
import { Grid, IconButton, TextField } from '@material-ui/core';

import {
  handleFilterCurrentWeekStartDate,
  handleFilterCurrentWeekEndDate,
  handleFilterLastWeekStartDate,
  handleFilterLastWeekEndDate,
  getSelectedTimePeriodFilter,
} from '../../../utils/TimeFormatAndTimezone';
import { Storage } from 'react-jhipster';
import { getOrganizationSettings } from '../../modules/Setting/generalSettings.reducer';
import { convertToPDF } from './convertToPDF';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import GetAppIcon from '@material-ui/icons/GetApp';
import Popper from '@material-ui/core/Popper';
import '../client/client.scss';
import { dateFTConverter } from '../../../utils/TimeFormatAndTimezone';
import Spinner from 'app/commonUI/spinner/spinner';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      width: '35vh',
      backgroundColor: '#F5F5F5',
      height: '40px !important',
    },
    formControl2: {
      margin: theme.spacing(1),
      fontWeight: 400,
      textDecoration: 'none !important',
      color: '#000000',
    },

    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
    alertStyling: {
      background: 'lightyellow',
      padding: '2vh',
    },
  })
);

export function handleDateDifference(current_row) {
  const diff = moment().diff(moment(current_row));
  const diffDuration = moment.duration(diff);
  return dateReturnStatement(diffDuration);
}

function dateReturnStatement(input) {
  if (input.days() > 0) {
    return input.days() + ' day(s) ' + input.hours() + ' hour(s) ' + input.minutes() + ' minute(s) ago';
  }
  if (input.hours() > 0) {
    return input.hours() + ' hour(s) ' + input.minutes() + ' minute(s) ago';
  }
  if (input.minutes() > 0) {
    return input.minutes() + ' minute(s)ago';
  }
  if (input.seconds() > 0) {
    return input.seconds() + ' second(s) ago';
  }
}

function Row(props: { row: IActionHistory; timezonesetting: any; dateformatsetting: any; timeformatsetting: any }) {
  const { row, timezonesetting, dateformatsetting, timeformatsetting } = props;

  return (
    <React.Fragment>
      <TableRow className="BannedPeopleTableBodyRows">
        <TableCell className="BannedPeopleTableBodyCell ">{row.personName ? row.personName : null}</TableCell>
        <TableCell className="BannedPeopleTableBodyCell">
          <span>{changeDateFormat(row.time)}</span>
          <br className="BannedPeopleTableBodyCell" />
          <span className="BannedPeopleTableBodyCell">{changeTimeFormat(row.time, timeformatsetting)}</span>
        </TableCell>
        <TableCell className="BannedPeopleTableBodyCell ">{row.action ? row.action : null}</TableCell>
        <TableCell className="BannedPeopleTableBodyCell">{row.actionBy ? row.actionBy : null}</TableCell>
        <TableCell className="BannedPeopleTableBodyCell ">
          <span>{row.description ? row.description : null}</span>
          <br />
          <span>
            {row.startTimeDescription && dateformatsetting && ActivityDescriptionDateFormat(row.startTimeDescription, dateformatsetting)}
          </span>
          <br />
          <span>
            {row.startTimeDescription && 'Start Time : '}
            {row.startTimeDescription &&
              timeformatsetting &&
              timezonesetting &&
              moment(row.startTimeDescription).format(`${timeformatsetting === 1 ? 'HH:mm' : 'hh:mm A'}`)}
          </span>
          <span className="BannedPeopleTableBodyCell">
            {row.endTimeDescription && 'End Time : '}
            {row.endTimeDescription &&
              timeformatsetting &&
              timezonesetting &&
              moment(row.endTimeDescription).format(`${timeformatsetting === 1 ? 'HH:mm' : 'hh:mm A'}`)}
          </span>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export interface IActionHistoryProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const ActionHistory = (props: IActionHistoryProps) => {
  const [values, setValues] = useState({
    people: '',
    from_date: moment(handleFilterCurrentWeekStartDate()).format('YYYY-MM-DD'),
    to_date: moment(handleFilterCurrentWeekEndDate()).format('YYYY-MM-DD'),
  });
  const classes = useStyles();
  const [selectedTimePeriod, setTimePeriod] = useState({ id: 3, text: 'Current week' });
  const [selectedDateTo, setSelectedDateTo] = React.useState<any | null>(
    getSelectedTimePeriodFilter(selectedTimePeriod.text, 'setSelectedDateTo')
  );
  const [selectedDateFrom, setSelectedDateFrom] = React.useState<any | null>(
    getSelectedTimePeriodFilter(selectedTimePeriod.text, 'setSelectedDateFrom')
  );
  const [selectedPerson, setSelectedPerson] = React.useState<any | null>(null);
  const [selectedAction, setSelectedAction] = React.useState<any | null>(null);
  const [searched, setSearched] = useState<string>('');
  const [newRows, setRows] = useState(props.actionHistoryList);
  const [timeFormatSettings, setTimeFormatSettings] = useState(props.setting.time_format);
  const [dateFormat, setdateFormat] = useState(props.setting.date_format);
  const [timezoneFormat, setTimezoneFormat] = useState(props.setting.timezone);
  const [pageNumber, setPageNumber] = useState(1);
  const csvLinks = [''];
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setdateFormat(props.setting.date_format);
    setTimeFormatSettings(props.setting.time_format);
    setTimezoneFormat(props.setting.timezone);
  }, [props.setting]);

  useEffect(() => {
    setRows(props.actionHistoryList);
  }, [props.actionHistoryList]);

  useEffect(() => {
    if (pageNumber > 1) {
      props.getReport(values, pageNumber);
    }
  }, [pageNumber]);

  useEffect(() => {
    props.resetPerson();
    props.getPersonForFilters();
  }, []);

  useEffect(() => {
    props.reset();
    props.getReport(values, '');
  }, [values]);

  const header = [
    { label: 'DATE/TIME', key: 'time' },
    { label: 'ASSIGNEE NAME', key: 'personName' },
    { label: 'ACTION PERFORMED', key: 'action' },
    { label: 'DESCRIPTION', key: 'description' },
    { label: 'ACTION DETAILS', key: 'actionBy' },
  ];

  const handleToggle = event => {
    setOpen(prevOpen => !prevOpen);
    setAnchorEl(event.currentTarget);
  };

  const jsonData = [];
  if (props.actionHistoryList && props.actionHistoryList.length > 0) {
    props.actionHistoryList.forEach(element => {
      const data = { ...element };
      if (data.time && timeFormatSettings && timezoneFormat && dateFormat && data.startTimeDescription && data.endTimeDescription) {
        data.time = changeDateFormat(data.time) + '\n' + changeTimeFormat(data.time, timeFormatSettings);
        data.startTimeDescription = moment(data.startTimeDescription).format(`${timeFormatSettings === 1 ? 'HH:mm' : 'hh:mm A'}`);
        data.endTimeDescription = moment(data.endTimeDescription).format(`${timeFormatSettings === 1 ? 'HH:mm' : 'hh:mm A'}`);
      }
      jsonData.push(data);
    });
  }
  const csvReport = {
    data: jsonData,
    headers: header,
    filename: 'Activity_Log_Report.csv',
  };

  const handleFilterValues = (_, e) => {
    if (e) {
      switch (e.name) {
        case 'people':
          setSelectedPerson({ id: e.id, text: e.text });
          return setFilterValues(e.name, e.id);
        default:
          return null;
      }
    } else {
      setValues(oldValues => ({ ...oldValues, people: '' }));
    }
  };

  const setFilterValues = (name, value) => {
    setPageNumber(1);
    props.reset();
    setValues(oldValues => ({ ...oldValues, [name]: value }));
  };

  const handleDateFrom = e => {
    const d = moment(e).format('YYYY-MM-DD');
    setSelectedDateFrom(e);
    if (d !== 'Invalid date') {
      setValues(oldValues => ({ ...oldValues, ['from_date']: d }));
    }
  };

  const handleDateTo = e => {
    const d = moment(e).format('YYYY-MM-DD');
    setSelectedDateTo(e);
    if (d !== 'Invalid date') {
      setValues(oldValues => ({ ...oldValues, ['to_date']: d }));
    }
  };

  const handleTimePeriodFilter = (_, e) => {
    if (e) {
      setTimePeriod({ id: e.id, text: e.text });

      switch (e.id) {
        case 1:
          setPageNumber(1);
          props.reset();
          setValues(oldValues => ({ ...oldValues, ['from_date']: '', ['to_date']: moment().format('YYYY-MM-DD'), ['current_day']: '' }));
          setSelectedDateFrom(null);
          setSelectedDateTo(null);
          break;
        case 2:
          {
            setPageNumber(1);
            const first = moment().format('YYYY-MM-DD');
            setTimePeriodValues(first, first);
            setSelectedDateFrom(first);
            setSelectedDateTo(first);
          }
          break;
        case 3:
          {
            const first = handleFilterCurrentWeekStartDate();
            const last = handleFilterCurrentWeekEndDate();
            setTimePeriodValues(first, last);
            setSelectedDateFrom(first);
            setSelectedDateTo(last);
          }
          break;
        case 4:
          {
            const first = handleFilterLastWeekStartDate();
            const last = handleFilterLastWeekEndDate();
            setTimePeriodValues(first, last);
            setSelectedDateFrom(first);
            setSelectedDateTo(last);
          }
          break;
        case 5:
          {
            const first = moment().startOf('month').format('YYYY-MM-DD');
            const last = moment().endOf('month').format('YYYY-MM-DD');
            setTimePeriodValues(first, last);
            setSelectedDateFrom(first);
            setSelectedDateTo(last);
          }
          break;
        case 6:
          {
            const first = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
            const last = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
            setTimePeriodValues(first, last);
            setSelectedDateFrom(first);
            setSelectedDateTo(last);
          }
          break;
        default:
          setPageNumber(1);
          props.reset();
          setValues(oldValues => ({ ...oldValues, ['from_date']: '', ['to_date']: '', ['current_day']: '' }));
      }
    } else {
      setValues(oldValues => ({ ...oldValues, ['from_date']: '', ['to_date']: '', ['current_day']: '' }));
    }
  };

  const dateConverter = dateformat => {
    if (dateformat === null) {
      if (dateFormat === 1) {
        return 'DD-MM-YY';
      } else if (dateFormat === 2) {
        return 'DD-MM-YYYY';
      } else if (dateFormat === 3) {
        return 'MM/DD/YY';
      } else if (dateFormat === 4) {
        return 'MM/DD/YYYY';
      } else if (dateFormat === 5) {
        return 'DD.MM.YY';
      } else if (dateFormat === 6) {
        return 'DD.MM.YYYY';
      } else if (dateFormat === 7) {
        return 'YY/MM/DD';
      } else if (dateFormat === 8) {
        return 'YYYY/MM/DD';
      }
    } else {
      if (dateFormat === 1) {
        return moment(dateformat).format('DD-MM-YY');
      } else if (dateFormat === 2) {
        return moment(dateformat).format('DD-MM-YYYY');
      } else if (dateFormat === 3) {
        return moment(dateformat).format('MM/DD/YY');
      } else if (dateFormat === 4) {
        return moment(dateformat).format('MM/DD/YYYY');
      } else if (dateFormat === 5) {
        return moment(dateformat).format('DD.MM.YY');
      } else if (dateFormat === 6) {
        return moment(dateformat).format('DD.MM.YYYY');
      } else if (dateFormat === 7) {
        return moment(dateformat).format('YY/MM/DD');
      } else if (dateFormat === 8) {
        return moment(dateformat).format('YYYY/MM/DD');
      }
    }
  };

  const setTimePeriodValues = (first, second) => {
    setPageNumber(1);
    props.reset();
    setValues(oldValues => ({
      ...oldValues,
      ['from_date']: moment(first).format('YYYY-MM-DD'),
      ['to_date']: moment(second).format('YYYY-MM-DD'),
      ['current_day']: '',
    }));
  };

  const requestSearch = (searchedVal: string) => {
    setSearched(searchedVal);
    const filteredRows = props.actionHistoryList.filter(row => {
      return row.description.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setRows(filteredRows);
  };

  const handleChange = event => {
    setSelectedAction(event.target.value);
  };

  const cancelSearch = () => {
    setSearched('');
    requestSearch('');
  };

  const { actionHistoryList, match, loading } = props;
  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleSync = () => {
    props.reset();
    setSearched('');
    setSelectedPerson(null);
    setTimePeriod({ id: 3, text: 'Current week' });
    setValues({
      people: '',
      from_date: moment(handleFilterCurrentWeekStartDate()).format('YYYY-MM-DD'),
      to_date: moment(handleFilterCurrentWeekEndDate()).format('YYYY-MM-DD'),
    });
  };

  return (
    <div className="entities-reports-main-container">
      {props.loading && <Spinner />}
      <div className="entities-reports-filter-area">
        <Grid container spacing={2} style={{ flexWrap: 'nowrap', width: '65%' }}>
          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
            <FormControl variant="outlined" size="small">
              <SearchBar
                value={searched}
                onChange={searchVal => requestSearch(searchVal)}
                onCancelSearch={() => cancelSearch()}
                placeholder="Search Here..."
                style={{
                  outline: '1px solid #bbc4c2',
                  borderRadius: '4px',
                  height: '40px',
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <KeyboardDatePicker
                clearable={true}
                id="date"
                placeholder="From"
                size="small"
                inputVariant="outlined"
                className="entities_report_filter_fields datePicker"
                name="from_date"
                ToolbarComponent={prop => (
                  <>
                    <CustomToolbarComponent {...prop} settingFormat={props.setting.date_format} />
                  </>
                )}
                labelFunc={selectedDateFrom ? dateFTConverter : () => 'From'}
                value={selectedDateFrom ? moment(selectedDateFrom).format('YYYY-MM-DD') : null}
                onChange={handleDateFrom}
                InputLabelProps={{ shrink: true }}
                disabled={loading}
              />{' '}
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <KeyboardDatePicker
                clearable={true}
                id="date"
                size="small"
                inputVariant="outlined"
                ToolbarComponent={prop => (
                  <>
                    <CustomToolbarComponent {...prop} settingFormat={props.setting.date_format} />
                  </>
                )}
                labelFunc={selectedDateTo ? dateFTConverter : () => 'Till'}
                value={selectedDateTo ? moment(selectedDateTo).format('YYYY-MM-DD') : null}
                className="entities_report_filter_fields datePicker"
                name="to_date"
                onChange={handleDateTo}
                InputLabelProps={{ shrink: true }}
                disabled={loading}
              />{' '}
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
            <FormControl variant="outlined" fullWidth className="entities_report_filter_fields staffFilterTimeSheet">
              <Autocomplete
                options={
                  props.personsList &&
                  props.personsList.map(person => ({
                    id: person.id,
                    text: person.first_name + ' ' + person.last_name,
                    name: 'people',
                  }))
                }
                noOptionsText={'No Records'}
                classes={{ listbox: 'autocompletelistbox' }}
                ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                getOptionLabel={option => option.text || ''}
                onChange={handleFilterValues}
                value={selectedPerson}
                disabled={loading}
                renderInput={params => <TextField {...params} variant="outlined" size="small" placeholder="Staff" />}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container className="entities_reports_refresh_and_download_div" style={{ width: '35%' }}>
          <IconButton
            size="small"
            className="report-download-button"
            id="entities_reports_btn"
            onClick={handleToggle}
            disabled={props.loading}
          >
            <GetAppIcon fontSize="large" />
            {open && (
              <div className="export-menu">
                <MenuItem>
                  <div style={{ display: 'block', color: 'black' }}>
                    <span style={{ textAlign: 'left' }} onClick={() => convertToPDF(jsonData, selectedDateFrom, selectedDateTo)}>
                      Export PDF
                    </span>
                    <br />
                    <br />
                    <span style={{ textAlign: 'left' }}>
                      <CSVLink className="csvLinks" {...csvReport}>
                        Export CSV
                      </CSVLink>
                    </span>
                  </div>
                </MenuItem>
              </div>
            )}
          </IconButton>

          <IconButton
            size="small"
            className="report-download-button"
            onClick={handleSync}
            id="entities_reports_btn"
            disabled={props.loading}
          >
            <AutorenewIcon fontSize="large" />
          </IconButton>

          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
            <FormControl variant="outlined" fullWidth>
              <Autocomplete
                options={
                  timePeriod &&
                  timePeriod.map(time => ({
                    id: time.value,
                    text: time.label,
                  }))
                }
                noOptionsText={'--Period--'}
                classes={{ listbox: 'autocompletelistbox' }}
                ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                getOptionLabel={option => option.text || ''}
                onChange={handleTimePeriodFilter}
                value={selectedTimePeriod}
                disabled={loading}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    className="personAutocompleteTextfield entities_report_filter_fields"
                    fullWidth
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </div>

      <div>
        <div className="globalTableHeaderGradientColor clientTableHeaderRow sticky_div">
          <div className="clientTableHeaderCell bannedEmployeeHeader">Assignee Name</div>
          <div className="clientTableHeaderCell bannedEmployeeHeader">Date/Time</div>
          <div className="clientTableHeaderCell bannedEmployeeHeader">Action Performed</div>
          <div className="clientTableHeaderCell bannedEmployeeHeader">Action Details</div>
          <div className="clientTableHeaderCell bannedEmployeeHeaderReason">Description</div>
        </div>
        <InfiniteScroll
          dataLength={actionHistoryList.length}
          scrollThreshold="200px"
          next={() => {
            setPageNumber(pageNumber + 1);
          }}
          hasMore={true}
          loader={''}
        >
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableBody>
                {actionHistoryList && actionHistoryList.length > 0
                  ? newRows.map(shifts => (
                      <Row
                        key={shifts['id']}
                        row={shifts}
                        timezonesetting={props.setting.timezone}
                        timeformatsetting={props.setting.time_format}
                        dateformatsetting={props.setting.date_format}
                      />
                    ))
                  : !loading && <div className={classes.alertStyling}>No Records found</div>}
              </TableBody>
            </Table>
          </TableContainer>
        </InfiniteScroll>
      </div>
    </div>
  );
};

const mapStateToProps = (setState: IRootState) => ({
  actionHistoryList: setState.ActionHistory.entities,
  personsList: setState.person.filterPersons,
  loading: setState.ActionHistory.loading,
  setting: setState.PersonalSettings.storedPersonalSettings,
  organizationSettings: setState.organizationSettings.storedOrganizationSettings,
});

const mapDispatchToProps = {
  getReport,
  getPersons,
  getPersonalSettings,
  reset,
  getOrganizationSettings,
  resetPerson,
  getPersonForFilters,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ActionHistory);
