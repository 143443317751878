import './header.scss';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React, { useState, useEffect } from 'react';
import { Translate, Storage } from 'react-jhipster';
import { AppBar, Toolbar, Button, Collapse, useMediaQuery, useTheme } from '@material-ui/core';
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';
import Tabs from '@material-ui/core/Tabs';
import LoadingBar from 'react-redux-loading-bar';
import { Home, Brand, Client, Vetting } from './header-components';
import { grey } from '@material-ui/core/colors';
import { CancelPresentationOutlined, ImportantDevices } from '@material-ui/icons';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { AdminMenu, EntitiesMenu, AccountMenu, SchedulesMenuItems, LocaleMenu, PeopleMenu } from '../menus';
import { ReportsMenu } from '../menus/reports';
import HeaderComponentBigScreen from './hearderComponentBigScreen';
import HeaderComponentSmallScreen from './headerComponentSmallScreen';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
      list: {
        padding: '0',
      },
    },
  })
);
export interface IHeaderProps {
  isAuthenticated: boolean;
  isVetted: boolean;
  isSystemAdmin: boolean;
  isOrganizationAdministrator: boolean;
  isSupervisor: boolean;
  isManager: boolean;
  isUser: boolean;
  ribbonEnv: string;
  isInProduction: boolean;
  isOpenAPIEnabled: boolean;
  currentLocale: string;
  canViewClients: boolean;
  canViewSubContractors: boolean;
  canViewServiceArea: boolean;
  imageUrl: string;
  organizationSelected: boolean;
  onLocaleChange: (langKey: string) => void;
}
const Header = (props: IHeaderProps) => {
  const [menuOpen, setMenuOpen] = useState(true);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    props.imageUrl;
  }, [props.imageUrl]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLocaleChange = event => {
    const langKey = event.target.value;
    Storage.session.set('locale', langKey);
    props.onLocaleChange(langKey);
  };

  const renderDevRibbon = () =>
    props.isInProduction === false ? (
      <div className="ribbon dev">
        <a href="">
          <Translate contentKey={`global.ribbon.${props.ribbonEnv}`} />
        </a>
      </div>
    ) : null;

  const toggleMenu = () => setMenuOpen(!menuOpen);

  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down('sm'));

  const isRoleAllowed = (organizationadministrator, supervisor, manager, user = false) => {
    if (organizationadministrator || supervisor || manager || user) {
      return true;
    }
    return false;
  };
  return (
    <div id="app-header" className="header-first-outer-div" style={{ paddingTop: '60px' }}>
      {renderDevRibbon()}
      <LoadingBar className="loading-bar" />
      <AppBar elevation={0} position="static" className="header-AppBar-layout">
        <Toolbar className="header-Toolbar-layout">
          <Brand /> &nbsp;
          {isMatch ? (
            <>
              <Button value="justify" aria-label="menu" onClick={handleClick}>
                &nbsp;
                <FormatAlignJustifyIcon />
              </Button>
              <Collapse in={menuOpen}>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  getContentAnchorEl={null}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  className="classes.root"
                >
                  <HeaderComponentBigScreen {...props} />
                </Menu>
              </Collapse>
            </>
          ) : (
            <>
              <HeaderComponentSmallScreen {...props} />
            </>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
