import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IDesignationQualificationType, defaultValue } from 'app/shared/model/designation-qualification-type.model';

export const ACTION_TYPES = {
  FETCH_DESIGNATIONQUALIFICATIONTYPE_LIST: 'designationQualificationType/FETCH_DESIGNATIONQUALIFICATIONTYPE_LIST',
  FETCH_DESIGNATIONQUALIFICATIONTYPE: 'designationQualificationType/FETCH_DESIGNATIONQUALIFICATIONTYPE',
  CREATE_DESIGNATIONQUALIFICATIONTYPE: 'designationQualificationType/CREATE_DESIGNATIONQUALIFICATIONTYPE',
  UPDATE_DESIGNATIONQUALIFICATIONTYPE: 'designationQualificationType/UPDATE_DESIGNATIONQUALIFICATIONTYPE',
  PARTIAL_UPDATE_DESIGNATIONQUALIFICATIONTYPE: 'designationQualificationType/PARTIAL_UPDATE_DESIGNATIONQUALIFICATIONTYPE',
  DELETE_DESIGNATIONQUALIFICATIONTYPE: 'designationQualificationType/DELETE_DESIGNATIONQUALIFICATIONTYPE',
  RESET: 'designationQualificationType/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IDesignationQualificationType>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

export type DesignationQualificationTypeState = Readonly<typeof initialState>;

// Reducer

export default (state: DesignationQualificationTypeState = initialState, action): DesignationQualificationTypeState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_DESIGNATIONQUALIFICATIONTYPE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_DESIGNATIONQUALIFICATIONTYPE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_DESIGNATIONQUALIFICATIONTYPE):
    case REQUEST(ACTION_TYPES.UPDATE_DESIGNATIONQUALIFICATIONTYPE):
    case REQUEST(ACTION_TYPES.DELETE_DESIGNATIONQUALIFICATIONTYPE):
    case REQUEST(ACTION_TYPES.PARTIAL_UPDATE_DESIGNATIONQUALIFICATIONTYPE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_DESIGNATIONQUALIFICATIONTYPE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_DESIGNATIONQUALIFICATIONTYPE):
    case FAILURE(ACTION_TYPES.CREATE_DESIGNATIONQUALIFICATIONTYPE):
    case FAILURE(ACTION_TYPES.UPDATE_DESIGNATIONQUALIFICATIONTYPE):
    case FAILURE(ACTION_TYPES.PARTIAL_UPDATE_DESIGNATIONQUALIFICATIONTYPE):
    case FAILURE(ACTION_TYPES.DELETE_DESIGNATIONQUALIFICATIONTYPE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_DESIGNATIONQUALIFICATIONTYPE_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_DESIGNATIONQUALIFICATIONTYPE):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_DESIGNATIONQUALIFICATIONTYPE):
    case SUCCESS(ACTION_TYPES.UPDATE_DESIGNATIONQUALIFICATIONTYPE):
    case SUCCESS(ACTION_TYPES.PARTIAL_UPDATE_DESIGNATIONQUALIFICATIONTYPE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_DESIGNATIONQUALIFICATIONTYPE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/designation-qualification-types';

// Actions

export const getEntities: ICrudGetAllAction<IDesignationQualificationType> = (page, size, sort) => ({
  type: ACTION_TYPES.FETCH_DESIGNATIONQUALIFICATIONTYPE_LIST,
  payload: axios.get<IDesignationQualificationType>(`${apiUrl}?cacheBuster=${new Date().getTime()}`),
});

export const getEntity: ICrudGetAction<IDesignationQualificationType> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_DESIGNATIONQUALIFICATIONTYPE,
    payload: axios.get<IDesignationQualificationType>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IDesignationQualificationType> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_DESIGNATIONQUALIFICATIONTYPE,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IDesignationQualificationType> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_DESIGNATIONQUALIFICATIONTYPE,
    payload: axios.put(`${apiUrl}/${entity.id}`, cleanEntity(entity)),
  });
  return result;
};

export const partialUpdate: ICrudPutAction<IDesignationQualificationType> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.PARTIAL_UPDATE_DESIGNATIONQUALIFICATIONTYPE,
    payload: axios.patch(`${apiUrl}/${entity.id}`, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IDesignationQualificationType> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_DESIGNATIONQUALIFICATIONTYPE,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
