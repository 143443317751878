import currencies_list from '../app/shared/data/currencies_list.json';
import timezones from '../app/shared/data/timezones.json';
import date_format_types from '../app/shared/data/date_format_types.json';
import days_of_week from '../app/shared/data/days_of_week.json';
import distance_units_large_types from '../app/shared/data/distance_units_large_types.json';
import distance_units_small_types from '../app/shared/data/distance_units_small_types.json';
import language_types from '../app/shared/data/language_types.json';
import country_types from '../app/shared/data/country_types.json';

export const getPrePopulateSettingInSettings = (settingName, setting) => {
  if (settingName !== null || settingName !== undefined || setting !== null || setting !== undefined) {
    switch (settingName) {
      case 'timezones': {
        const d = timezones.filter(tz => tz.value === setting);
        const dd = d.map(tzLabel => {
          return tzLabel.label;
        });
        return dd.toString();
      }
      case 'date_format_types': {
        const d = date_format_types.filter(date => date.value === setting);
        const dd = d.map(dateLabel => {
          return dateLabel.label + ' ' + dateLabel.example;
        });
        return dd.toString();
      }
      case 'country_types': {
        const d = country_types.filter(countryTypes => countryTypes.value === setting);
        const dd = d.map(country => {
          return country.label;
        });
        return dd.toString();
      }
      case 'currencies_list': {
        const d = currencies_list.filter(currency => currency.value === setting);
        const dd = d.map(currencuSymbol => {
          return currencuSymbol.label + ' ' + currencuSymbol.symbol;
        });
        return dd.toString();
      }
      case 'days_of_week': {
        const d = days_of_week.filter(daysOfWeek => daysOfWeek.value === setting);
        const dd = d.map(day => {
          return day.label;
        });
        return dd.toString();
      }
      case 'distance_units_large_types': {
        const d = distance_units_large_types.filter(units => units.value === (setting === null ? 0 : setting));
        const dd = d.map(unit => {
          return unit.label;
        });
        return dd.toString();
      }
      case 'distance_units_small_types': {
        const d = distance_units_small_types.filter(units => units.value === (setting === null ? 0 : setting));
        const dd = d.map(unit => {
          return unit.label;
        });
        return dd.toString();
      }
      case 'language_types': {
        const d = language_types.filter(language => language.value === (setting === null ? 0 : setting));
        const dd = d.map(lang => {
          return lang.label;
        });
        return dd.toString();
      }
      default:
        return null;
    }
  } else {
    return null;
  }
};

export const FilterPositionInServiveArea = (designations, serviceAreaEntity) => {
  const d = designations.filter(x => x.id === serviceAreaEntity.positionId);
  const dd = d.map(position => {
    return position.id;
  });
  return dd.toString();
};
export const FilterClientInServiveArea = (clientsList, serviceAreaEntity) => {
  const d = clientsList.filter(x => x.id === serviceAreaEntity.clientId);
  const dd = d.map(client => {
    return client.clientName;
  });
  return dd.toString();
};

export const handleShiftReminderButtons = (tag, event, values, setValues) => {
  if (tag === 'sendAgain') {
    setValues(oldValues => ({
      ...oldValues,
      [event.target.name]: {
        ...oldValues[event.target.name],
        send_again: values ? (values[event.target.name] ? !values[event.target.name]['send_again'] : true) : true,
      },
    }));
  }
  if (tag === 'alertAdmin') {
    setValues(oldValues => ({
      ...oldValues,
      [event.target.name]: {
        ...oldValues[event.target.name],
        alert_admin: values ? (values[event.target.name] ? !values[event.target.name]['alert_admin'] : true) : true,
      },
    }));
  }
};
