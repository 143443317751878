import React, { useEffect, useState } from 'react';
import Popper from '@material-ui/core/Popper';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { dateFTConverter } from '../../../utils/TimeFormatAndTimezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-timezone';
import GoogleMapsComponent from './map';
import CloseIcon from '@material-ui/icons/Close';
import { TimePicker, MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';
import { getTodayDate } from '../../../utils/TimeFormatAndTimezone';
import MomentUtils from '@date-io/moment';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { Storage } from 'react-jhipster';
import { ShiftHistoryModalComponent } from '../calender/shifthistoryModal';
import  BreaksHistoryModal  from './BreaksHistoryModal';
import Loader from '../../../../webapp/app/commonUI/loader';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
  } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    table: {
      minWidth: '70vh',
    },
    clockoutButton: {
      textTransform: 'none',
      fontSize: 'x-small',
      borderRadius: '2px',
      color: '#fff',
      fontWeight: 'bold',
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#d9534f',
        boxShadow: '0 0 0 0.2rem rgba(0,0,0,0,11 )',
      },
    },
    locationButton: {
      textTransform: 'none',
      fontSize: 'x-small',
      borderRadius: '0px',
      color: '#fff',
      fontWeight: 'bold',
      boxShadow: 'none',
      cursor: 'pointer',
      '&:hover': {
        borderColor: '#d9534f',
        boxShadow: '0 0 0 0.2rem rgba(0,0,0,0,11 )',
      },
    },
    clockinButton: {
      textTransform: 'none',
      fontSize: 'x-small',
      borderRadius: '0px',
      color: '#fff',
      fontWeight: 'bold',
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#d9534f',
        boxShadow: '0 0 0 0.2rem rgba(0,0,0,0,11 )',
      },
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    iconColor: {
      color: 'blue',
    },
    timeclockTimeBoxes: {
      borderRadius: 'unset',
    },
    smallerTimePicker: {
      '& .MuiIconButton-root': {
        padding: '0px',
      },
    },
  }));

const ShiftRecordsComponent = (props) => {
const { activeColumns } = props;
const {shifts, loading } = props;

const [pageNumber, setPageNumber] = useState(1);
const classes = useStyles();
const [dateFormat, setdateFormat] = useState(props.setting.date_format);
const [locale, setLocale] = useState(props.setting.time_format);
const [showMap, setShowMap] = useState(false);
const [anchorEl, setAnchorEl] = React.useState(null);
const [center, setCenter] = useState({});
const [coordinate, setCoordinate] = React.useState([]);
const [mark, setMark] = useState();
const [breakStart, setBreakStart] = useState({});
const [breakEndError, setBreakEndError] = useState({});
const [timezoneFormat, setTimezoneFormat] = useState(props.setting.timezone);
const [selectedLocation, setSelectedLocation] = React.useState<any | null>(null);
const [shiftHistoryRecords, setShiftHistoryRecords] = React.useState<any | null>({});
const [ShiftHistoryModalOpen, setShiftHistoryModalOpen] = useState(false);
const [BreakHistoryModalOpen, setBreakHistoryModalOpen] = useState(false);
const [breakRecords, setBreakRecords] = React.useState<any | null>({});
const [values, setValues] = useState({
    people: '',
    location: '',
    position: '',
    client: '',
    from_date: '',
    to_date: getTodayDate(),
    current_day: '',
    department: '',
  });
const popperCloser = () => {
    setAnchorEl(null);
    setShowMap(false);
  };
const visibleColumns = Object.values(activeColumns).filter(Boolean).length;
const dynamicClassName = visibleColumns <= 4 ? 'scol-few-columns' : 'scol';
const windowOpener = (event, coordinate) => {
    setShowMap(true);
    setAnchorEl(event.currentTarget);

    const splitCoordinate = coordinate.split(',');
    const splitLat = splitCoordinate && splitCoordinate[0]?.split('(');
    const splitLng = splitCoordinate && splitCoordinate[1]?.split(')');

    setCenter({
      lat: splitLat ? parseFloat(splitLat[1]) : '',
      lng: splitLng ? parseFloat(splitLng[0]) : '',
    });
    setCoordinate([
      {
        lat: splitLat ? parseFloat(splitLat[1]) : '',
        lng: splitLng ? parseFloat(splitLng[0]) : '',
      },
    ]);
  };
const handleClockChange = (param1, param2) => event => {
    setPageNumber(1);
    if (param2 === 'start') {
        if (event?._isValid === true) {
        props.updateSingleShiftClocks(
            {
            data: { time: event, clockStatus: 'start' },
            id: param1,
            },
            values
        );
        }
    } else {
        if (event?._isValid === true) {
        props.updateSingleShiftClocks(
            {
            data: { time: event, clockStatus: 'end' },
            id: param1,
            },
            values
        );
        }
    }
};

useEffect(() => {
  if (props.shiftApprovalHistory && props.shiftApprovalHistory !== null && props.shiftApprovalHistory.length > 0) {
    filterShiftHistory();
  }
}, [props.shiftApprovalHistory]);

const filterShiftHistory = () => {
  const newRows = [];
  props.shiftApprovalHistory.filter(row => {
    const newRow = {
      ...row,
      previous_value: row.previous_value ? moment(row.previous_value).format(`${locale === 1 ? 'HH:mm' : 'hh:mm A'}`) : null,
      updated_value: row.updated_value ? moment(row.updated_value).format(`${locale === 1 ? 'HH:mm' : 'hh:mm A'}`) : null,
      time: row.time ? moment(row.time).format(`${locale === 1 ? 'HH:mm' : 'hh:mm A'}`) : null,
    };
    newRows.push(newRow);
  });
  setShiftHistoryRecords(newRows);
};

const convertUTCToTimezone = (utcDt, timezone) => {
    const newdate = moment.tz(utcDt, timezone).format();
    const offset = moment.parseZone(newdate).utcOffset();
    const finalTime = moment.utc(utcDt).utcOffset(offset).format();
    return finalTime;
};


const handleBreakStart = async shift => {
  const today = new Date().toISOString();

  const entity = {
    shift_id: shift.id,
    break_start_time: today,
  };

  await props.startShiftBreak(entity, values)
  if (props.values?.location) {
    props.getShiftCountsAgainstServiceAreaId(props.values.location);
  }
};

const handleBreakEnd = async breaks => {
  const latestBreak = breaks.reduce((latest, current) => (current.id > latest.id ? current : latest));
  const today = new Date().toISOString();

  const entity = {
    break_end_time: today,
  };
  await props.endShiftBreak(latestBreak, entity, values)
  if (props.values?.location) {
    props.getShiftCountsAgainstServiceAreaId(props.values.location);
  }
};

const isBreakActive = (breaks) => {
  const latestBreak = breaks.reduce((latest, current) => (current.id > latest.id ? current : latest));
  if(latestBreak.break_end_time === null){
    return true;
  }
};

const handleBreakHistory = breaks => {
  setBreakRecords(breaks);
  setBreakHistoryModalOpen(true);
  return null;
};

const closeBreakHistoryModal = state => {
  setBreakHistoryModalOpen(false);
  setBreakRecords({});
};

const history = useHistory();
const handleLocation = shift => {
    history.push({
    pathname: `/location/${shift.id}`,
    state: { shiftData: shift },
    });
};

const setFilterValues = (name, value) => {
    setPageNumber(1);
    props.reset();
    setValues(oldValues => ({ ...oldValues, [name]: value }));
};
const handleClockOut = async (shift_id, status) => {
    const today = new Date().toISOString();

      const entity = {
        id: shift_id,
        time: today,
        approvedBy: Storage.local.get('user_name'),
        shiftStatus: status + 1,
        uId: Storage.local.get('user_id'),
      };
      setPageNumber(1);
      await props.updateShift(entity, values);
      if (props.values?.location) {
        props.getShiftCountsAgainstServiceAreaId(props.values.location);
      }
};
const handleClockIn = async (shift_id, status) => {
    setPageNumber(1);
    const today = new Date().toISOString();
    const entity = {
      id: shift_id,
      time: today,
      approvedBy: Storage.local.get('user_name'),
      shiftStatus: status + 1,
      uId: Storage.local.get('user_id'),
    };
    await props.updateShift(entity, values);
    if (props.values?.location) {
        props.getShiftCountsAgainstServiceAreaId(props.values.location);
    }
};
const handleShiftApproved = (shift_id, status) => {
    setPageNumber(1);
    if (status === 4) {
      const entity = {
        id: shift_id,
        time: shifts.find(x => x.id === shift_id).checkOut,
        approvedBy: Storage.local.get('user_name'),
        shiftStatus: 3,
        uId: Storage.local.get('user_id'),
      };
      props.updateShift(entity, values);
    }
};

const handleShiftHistory = shiftId => {
    setShiftHistoryModalOpen(true);
    props.getShiftApprovalHistory(shiftId);
    return null;
};

const closeShiftHistoryModal = state => {
  setShiftHistoryModalOpen(false);
  setShiftHistoryRecords({});
};

function renderStatusCell(shift) {
  return (
    <TableCell align="left" className={`subcontractorTableBodyCell ${dynamicClassName}`}>
      {shift.status === 2 && <span className="timeclock_shifts_running_label_span">Running</span>}
      {shift.status === 4 && (
        <span className={classes.iconColor}>
          <FontAwesomeIcon icon={faCheckCircle} fixedWidth />
        </span>
      )}
    </TableCell>
  );
}

function renderDateTimeCell(shift) {
  return (
    <TableCell align="left" className={`subcontractorTableBodyCell ${dynamicClassName}`}>
      <span>{dateFTConverter(shift.date, dateFormat)}</span>
      <br />
      <span className="timeclockShiftTime">
        {moment(shift.shiftStartTime).format(`${locale === 1 ? 'HH:mm' : 'hh:mm A'}`)}
        {' - '}
        {moment(shift.shiftEndTime).format(`${locale === 1 ? 'HH:mm' : 'hh:mm A'}`)}
      </span>
    </TableCell>
  );
}

function renderAssigneeNameCell(shift) {
  return (
    <TableCell align="left" className={`subcontractorTableBodyCell ${dynamicClassName}`}>
      {
        shift.person ? shift.person.first_name + ' ' + shift.person.last_name : null
      }
    </TableCell>
  );
}

function renderSignInOutCell(shift) {
  return (
    <TableCell align="left" className={`subcontractorTableBodyCell ${dynamicClassName}`}>
      <span>{shift.checkIn ? moment(shift.checkIn).format(`${locale === 1 ? 'HH:mm' : 'hh:mm A'}`) : ''}</span>
      {' - '}
      <span>{shift.checkOut ? moment(shift.checkOut).format(`${locale === 1 ? 'HH:mm' : 'hh:mm A'}`) : ''}</span>
      {shift.signed_in_location ? (
        <div className="signIn-tag">
          <FiberManualRecordIcon className="fibManRec" />
          <span
            onClick={event => windowOpener(event, shift.signed_in_location)}
            className="Sign-In-Location-btn"
            style={{ cursor: 'pointer' }}
          >
            Sign-in Location
          </span>
        </div>
      ) : (
        ''
      )}
      <Popper open={showMap} anchorEl={anchorEl} className="popper">
        <div className="crossBtnDiv">
          <CloseIcon onClick={popperCloser} style={{ cursor: 'pointer' }} />
        </div>
        <div className="map-location">
          <GoogleMapsComponent center={center} setCenter={[]} setMark={mark} coordinate={coordinate} deleteMarks={[]} />
        </div>
        <div className="Sign-in-tag"> </div>
      </Popper>
    </TableCell>
  );
}

function renderCustomSignInOutCell(shift) {
  return (
    <TableCell align="left" className={`subcontractorTableBodyCell ${dynamicClassName}`}>
      <span className="clockinoutDivBoxes">
        <div className="timeclock_time_boxes_div_width_signin">
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardTimePicker
              className={classes.smallerTimePicker}
              size="small"
              id="clockIn"
              inputVariant="outlined"
              ampm={locale === 1 ? false : true}
              disabled={shift.status === 3 ? false : true}
              value={shift.checkIn ? shift.checkIn : null}
              onChange={handleClockChange(shift.id, 'start')}
              style={{ boxShadow: 'inset 0 0 6px #c984d1', background: 'white' }}
            />
          </MuiPickersUtilsProvider>
        </div>
      </span>
      &nbsp; {' - '}&nbsp;
      <span className="clockinoutDivBoxes">
        <div className="timeclock_time_boxes_div_width_signin">
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardTimePicker
              size="small"
              id="clockOut"
              inputVariant="outlined"
              className={classes.smallerTimePicker}
              ampm={locale === 1 ? false : true}
              disabled={shift.status === 3 ? false : true}
              value={shift.checkOut ? shift.checkOut : null}
              onChange={handleClockChange(shift.id, 'end')}
              style={{ boxShadow: 'inset 0 0 6px #c984d1', background: 'white' }}
            />
          </MuiPickersUtilsProvider>
        </div>
      </span>
    </TableCell>
  );
}

function renderBreakSBreakECell(shift) {
  return (
    <TableCell align="left" className={`subcontractorTableBodyCell ${dynamicClassName}`}>
      <span className="BreakStartEndDivBoxes">
        <div className="timeclock_time_boxes_div_width">
          
        { shift.breaks.length !== 0 && isBreakActive(shift.breaks) === true ? (
            <Button
              style={{ width: '12vh', backgroundColor: '#d48bd3', color: 'white', fontSize: '14px', margin: '3px' }}
              variant="contained"
              size="small"
              onClick={() => handleBreakEnd(shift.breaks)}
              className={classes.locationButton}
              disabled={props.loading}
            >
              {props.loading ?  <Loader size={30} /> : "End Break"}
            </Button>
          ) : (
            <Button
              style={{ width: '12vh', backgroundColor: '#d48bd3', color: 'white', fontSize: '14px', margin: '3px' }}
              variant="contained"
              size="small"
              onClick={() => handleBreakStart(shift)}
              className={classes.locationButton}
              disabled={shift.status !== 2 || props.loading}
            >
              {props.loading ?  <Loader  size = {30} /> : "Start Break"}
            </Button>
        )}
        { shift.breaks.length !== 0 && (
        <a className="shift-approve-popup-text" style={{fontSize : '12px'}} type="button" onClick={() => handleBreakHistory(shift.breaks)}>
          See Break Details
        </a>
        )}  


        </div>
      </span>
    </TableCell>
  );
}

function renderHoursCell(shift) {
  return(
    <TableCell align="left" className={`subcontractorTableBodyCell ${dynamicClassName}`}>
      {shift.hours}
    </TableCell>
  );
}

function renderBreakCell(shift) {
  return(
    <TableCell align="left" className={`subcontractorTableBodyCell ${dynamicClassName}`}>
      {shift.total_break_time}
    </TableCell>
  );
}

function renderActionsCell(shift) {
  return(
    <TableCell align="left" className={`subcontractorTableBodyCell ${dynamicClassName}`}>
      <div>
        {shift.patrol_frequency !== 0 && shift.status !== 1 ? (
          <Button
            style={{ width: '15vh', backgroundColor: '#d48bd3', color: 'white', fontSize: '14px', margin: '3px' }}
            variant="contained"
            size="small"
            onClick={() => {
              handleLocation(shift);
            }}
            className={classes.locationButton}
          >
            &nbsp;Track Location&nbsp;
          </Button>
        ) : null}

        {shift.status === 1 && (
          <Button
            id="timeclockClockInOutButton"
            variant="contained"
            style={{
              width: '15vh',
              backgroundColor: new Date(shift.shiftStartTime).getTime() > new Date().getTime() ? '#808080a1' : '#9973e1',
              color: 'white',
              fontSize: '14px',
              margin: '3px',
            }}
            size="small"
            onClick={() => {
              handleClockIn(shift.id, shift.status);
            }}
            className={classes.clockinButton}
            disabled={new Date(shift.shiftStartTime).getTime() > new Date().getTime()}
          >
            &nbsp;Sign In&nbsp;
          </Button>
        )}
        {shift.status === 2 && (
          <Button
            style={{ width: '15vh', backgroundColor: '#ed6f6f', color: 'white', fontSize: '14px' }}
            variant="contained"
            size="small"
            onClick={() => {
              handleClockOut(shift.id, shift.status);
            }}
            className={classes.clockoutButton}
          >
            Sign out
          </Button>
        )}
        {shift.status === 3 && (
          <Button
            style={{ width: '15vh', backgroundColor: '#3bbda9', color: 'white', fontSize: '14px' }}
            variant="contained"
            size="small"
            onClick={() => {
              handleClockIn(shift.id, shift.status);
            }}
            className={classes.clockinButton}
          >
            &nbsp;Approve&nbsp;
          </Button>
        )}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {shift.status === 4 && (
            <div className="shift-approved-buttons">
              <Button
                style={{ width: '15vh', backgroundColor: '#d48bd3', color: 'white', fontSize: '14px' }}
                variant="contained"
                size="small"
                onClick={() => {
                  handleClockIn(shift.id, shift.status);
                }}
                className={classes.clockinButton}
                disabled
              >
                &nbsp;Approved&nbsp;
              </Button>

              <IconButton
                onClick={() => {
                  handleShiftApproved(shift.id, shift.status);
                }}
                className="serviceButton"
                size="small"
                style={{ marginLeft: '1%' }}
                data-cy="entityEditButton"
              >
                <img src="content/images/Edit3.png" />
              </IconButton>
            </div>
          )}
        </div>
        {shift.status === 4 ? (
          <>
            <span className="timeclockApprovedBy">
              {'By'} {shift.approvedBy} <br />
            </span>

            <a className="shift-approve-popup-text" type="button" onClick={() => handleShiftHistory(shift.id)}>
              See more
            </a>
          </>
        ) : null}
      </div>
    </TableCell>
  );
}
    return (
      <div>
      
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            {/* <div className="addWhiteSpaceinTimeclocktable" /> */}
            {props.records && props.records.length > 0 ? (
              <TableBody className="timeclockbody">
                {props.records.map((shift, index) => (
                  <TableRow key={index} className="subcontractorTableBodyRows">
                    {activeColumns.status && renderStatusCell(shift)}
                    {activeColumns.dateTime && renderDateTimeCell(shift)}
                    {activeColumns.assigneeName && renderAssigneeNameCell(shift)}
                    {activeColumns.signInOut && renderSignInOutCell(shift)}
                    {activeColumns.customSignInOut && renderCustomSignInOutCell(shift)}
                    {activeColumns.breakSBreakE && renderBreakSBreakECell(shift)}
                    {activeColumns.workHours && renderHoursCell(shift)}
                    {activeColumns.breakTime && renderBreakCell(shift)}
                    {activeColumns.actions && renderActionsCell(shift)}
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              !loading && <div className="alert alert-warning">No Shifts found</div>
            )}
          </Table>
        </TableContainer>

      <ShiftHistoryModalComponent
        openShiftHistoryModal={ShiftHistoryModalOpen}
        shiftHistory={shiftHistoryRecords}
        setShiftHistoryModalOpen={closeShiftHistoryModal}
        heading={'Shift Approval History'}
      />

      <BreaksHistoryModal
        openModal={BreakHistoryModalOpen}
        closeModal={closeBreakHistoryModal}
        heading={'Breaks History'}
        breakRecords={breakRecords}
        locale={locale}
      />
       </div>
      
    );
};

export default ShiftRecordsComponent;