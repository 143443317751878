export const validationMessageOnChange = {
  name: 'Please enter correct name',
  firstName: 'Please enter correct first name',
  lastName: 'Please enter correct last name',
  address: 'Please enter correct address',
  SIAnumber: 'This is a 16 digit number. Please enter the number with no spaces',
  email: 'Please enter correct email',
  postcode: 'Please enter correct postcode',
  state: 'Please enter correct state',
  position: 'Please enter correct position',
  number: 'Please enter correct number',
};

export const validationMessageBeforeSubmit = {
  name: 'Please enter name',
  email: 'Please enter email',
  postcode: 'Please enter postcode',
  address: 'Please enter address',
  firstName: 'Please enter first name',
  lastName: 'Please enter last name',
  payrate: 'Please enter payrate',
};
