import { IPerson } from 'app/shared/model/person.model';
import { IOrganization } from 'app/shared/model/organization.model';
export interface IActionRequired {
  id?: string | number;
  type?: string | null;
  days_to_expire?: number | null;
  date?: Date | string | null;
  person?: IPerson;
  organization?: IOrganization;
}

export interface Idata {
  name: string | null;
  status: string | null;
  expiry_date: Date | string | null;
  message: string | null;
}

export interface INotifications {
  name?: string | null;
  date?: string | null;
  qualification?: string | null;
}

export const defaultValue: IActionRequired = {};
