/* eslint-disable complexity */

import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { IServiceArea, defaultValue, ICheckpointData, IBannedEmployees } from 'app/shared/model/service-area.model';
import { getBannedServiceAreas } from '../person/person.reducer';
import { ToastContainer, toast } from 'react-toastify';
import { IPerson } from 'app/shared/model/person.model';

export const ACTION_TYPES = {
  FETCH_SERVICEAREA_LIST: 'serviceArea/FETCH_SERVICEAREA_LIST',
  FETCH_SERVICEAREA: 'serviceArea/FETCH_SERVICEAREA',
  CREATE_SERVICEAREA: 'serviceArea/CREATE_SERVICEAREA',
  UPDATE_SERVICEAREA: 'serviceArea/UPDATE_SERVICEAREA',
  PARTIAL_UPDATE_SERVICEAREA: 'serviceArea/PARTIAL_UPDATE_SERVICEAREA',
  DELETE_SERVICEAREA: 'serviceArea/DELETE_SERVICEAREA',
  RESET: 'serviceArea/RESET',
  DELETE_CHECKPOINT: 'serviceArea/DELETE_CHECKPOINT',
  BANNED_EMPLOYEE: 'serviceArea/BANNED_EMPLOYEE',
  BANNED_EMPLOYEE_ALL_SERVICE_AREAS: 'serviceArea/BANNED_EMPLOYEE_ALL_SERVICE_AREAS',
  FETCH_BANNED_EMPLOYEES: 'serviceArea/FETCH_BANNED_EMPLOYEES',
  FETCH_BANNED_EMPLOYEES_OF_ORGANIZATION: 'serviceArea/FETCH_BANNED_EMPLOYEES_OF_ORGANIZATION',
  SET_PAGE_NUMBER: 'serviceArea/SET_PAGE_NUMBER',
  FETCH_SERVICE_AREAS_BY_ORGANIZATION_ID: 'serviceArea/FETCH_SERVICE_AREAS_BY_ORGANIZATION_ID',
  FETCH_PERSONS_BY_SERVICE_AREA_ID: 'serviceArea/FETCH_PERSONS_BY_SERVICE_AREA_ID',
  FETCH_ACTIVE_PERSONS_BY_SERVICE_AREA_ID: 'serviceArea/FETCH_ACTIVE_PERSONS_BY_SERVICE_AREA_ID',
  ADD_PERSON: 'serviceArea/ADD_PERSON',
  FETCH_SERVICE_AREAS_BY_CLIENT_ID: 'serviceArea/FETCH_SERVICE_AREAS_BY_CLIENT_ID',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as Array<IServiceArea>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  joyRide: false,
  banned: [] as ReadonlyArray<IBannedEmployees>,
  pageNumber: 1,
  organizationServiceAreas: [] as ReadonlyArray<IServiceArea>,
  serviceAreaPersons: [] as Array<IPerson>,
  activeServiceAreaPersons: [] as Array<IPerson>,
};

export type ServiceAreaState = Readonly<typeof initialState>;

// Reducer

export default (state: ServiceAreaState = initialState, action): ServiceAreaState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_SERVICEAREA_LIST):
    case REQUEST(ACTION_TYPES.FETCH_SERVICE_AREAS_BY_ORGANIZATION_ID):
    case REQUEST(ACTION_TYPES.FETCH_PERSONS_BY_SERVICE_AREA_ID):
    case REQUEST(ACTION_TYPES.FETCH_ACTIVE_PERSONS_BY_SERVICE_AREA_ID):
    case REQUEST(ACTION_TYPES.ADD_PERSON):
    case REQUEST(ACTION_TYPES.FETCH_BANNED_EMPLOYEES):
    case REQUEST(ACTION_TYPES.FETCH_BANNED_EMPLOYEES_OF_ORGANIZATION):
    case REQUEST(ACTION_TYPES.FETCH_SERVICEAREA):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_SERVICEAREA):
    case REQUEST(ACTION_TYPES.BANNED_EMPLOYEE):
    case REQUEST(ACTION_TYPES.BANNED_EMPLOYEE_ALL_SERVICE_AREAS):
    case REQUEST(ACTION_TYPES.UPDATE_SERVICEAREA):
    case REQUEST(ACTION_TYPES.DELETE_SERVICEAREA):
    case REQUEST(ACTION_TYPES.DELETE_CHECKPOINT):
    case REQUEST(ACTION_TYPES.PARTIAL_UPDATE_SERVICEAREA):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_SERVICEAREA_LIST):
    case FAILURE(ACTION_TYPES.FETCH_SERVICE_AREAS_BY_ORGANIZATION_ID):
    case FAILURE(ACTION_TYPES.FETCH_PERSONS_BY_SERVICE_AREA_ID):
    case FAILURE(ACTION_TYPES.ADD_PERSON):
    case FAILURE(ACTION_TYPES.FETCH_ACTIVE_PERSONS_BY_SERVICE_AREA_ID):
    case FAILURE(ACTION_TYPES.FETCH_BANNED_EMPLOYEES):
    case FAILURE(ACTION_TYPES.FETCH_BANNED_EMPLOYEES_OF_ORGANIZATION):
    case FAILURE(ACTION_TYPES.FETCH_SERVICEAREA):
    case FAILURE(ACTION_TYPES.CREATE_SERVICEAREA):
    case FAILURE(ACTION_TYPES.BANNED_EMPLOYEE):
    case FAILURE(ACTION_TYPES.BANNED_EMPLOYEE_ALL_SERVICE_AREAS):
    case FAILURE(ACTION_TYPES.UPDATE_SERVICEAREA):
    case FAILURE(ACTION_TYPES.PARTIAL_UPDATE_SERVICEAREA):
    case FAILURE(ACTION_TYPES.DELETE_SERVICEAREA):
    case FAILURE(ACTION_TYPES.DELETE_CHECKPOINT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_SERVICEAREA_LIST):
      return {
        ...state,
        loading: false,
        entities: [...state.entities, ...action.payload.data],
      };
    case SUCCESS(ACTION_TYPES.FETCH_SERVICE_AREAS_BY_ORGANIZATION_ID):
      return {
        ...state,
        loading: false,
        organizationServiceAreas: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_PERSONS_BY_SERVICE_AREA_ID):
      return {
        ...state,
        loading: false,
        serviceAreaPersons: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_ACTIVE_PERSONS_BY_SERVICE_AREA_ID):
      return {
        ...state,
        loading: false,
        activeServiceAreaPersons: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_BANNED_EMPLOYEES):
      return {
        ...state,
        loading: false,
        banned: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_BANNED_EMPLOYEES_OF_ORGANIZATION):
      return {
        ...state,
        loading: false,
        banned: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_SERVICEAREA):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_SERVICEAREA):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        joyRide: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.BANNED_EMPLOYEE):
    case SUCCESS(ACTION_TYPES.ADD_PERSON):
    case SUCCESS(ACTION_TYPES.BANNED_EMPLOYEE_ALL_SERVICE_AREAS):
    case SUCCESS(ACTION_TYPES.UPDATE_SERVICEAREA):
    case SUCCESS(ACTION_TYPES.PARTIAL_UPDATE_SERVICEAREA):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_SERVICEAREA):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case SUCCESS(ACTION_TYPES.DELETE_CHECKPOINT):
      return {
        ...state,
        updating: false,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    case ACTION_TYPES.SET_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_SERVICE_AREAS_BY_CLIENT_ID):
      return {
        ...state,
        loading: false,
        entities: action.payload.data, // Assuming the data structure matches the service areas.
      };
    default:
      return state;
  }
};

const apiUrl = 'api/service-areas';
const checkPointUrl = 'api/checkpoint';
const bannedUrl = 'api/banned-employee';
// const checkpointlog = 'api/checkpointlog'; /1

// Actions

export const getEntities = (pageNo, searchParams) => ({
  type: ACTION_TYPES.FETCH_SERVICEAREA_LIST,
  payload: axios.get<IServiceArea>(`${apiUrl}?cacheBuster=${new Date().getTime()}&page=${pageNo}&q=${searchParams}`),
});

export const getEntity: ICrudGetAction<IServiceArea> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_SERVICEAREA,
    payload: axios.get<IServiceArea>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IServiceArea> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_SERVICEAREA,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(reset());
  dispatch(setPageNumber({ pageNumber: 1 }));
  toast.success('Service Area Created Successfully');
  return result;
};

export const updateEntity: ICrudPutAction<IServiceArea> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_SERVICEAREA,
    payload: axios.put(`${apiUrl}/${entity.id}`, cleanEntity(entity)),
  });
  dispatch(reset());
  dispatch(setPageNumber({ pageNumber: 1 }));
  toast.success('Service Area Updated Successfully');
  return result;
};

export const partialUpdate: ICrudPutAction<IServiceArea> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.PARTIAL_UPDATE_SERVICEAREA,
    payload: axios.patch(`${apiUrl}/${entity.id}`, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IServiceArea> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_SERVICEAREA,
    payload: axios.delete(requestUrl),
  });
  dispatch(reset());
  dispatch(setPageNumber({ pageNumber: 1 }));
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});


export const bannedEntity = (action, bannedData, serviceAreaEntity, entityName) => dispatch => {
  if (action === 'currentServiceArea') {
    axios
      .post(`${bannedUrl}/currentServiceArea`, cleanEntity(bannedData))
      .then(res => {
        dispatch({
          type: ACTION_TYPES.BANNED_EMPLOYEE,
          payload: res,
        });
        toast.success('Staff Ban From Current Service Area Successfully');
        if (entityName === 'service_area') {
          dispatch(getBannedEmployees({ id: serviceAreaEntity.id }));
        } else {
          dispatch(getBannedServiceAreas({ id: bannedData.person.id }));
        }
      })
      .catch(err => {
        return err.errorMessage;
      });
  } else if (action === 'allServiceAreas') {
    axios
      .post(`${bannedUrl}/allServiceAreas`, cleanEntity(bannedData))
      .then(res => {
        dispatch({
          type: ACTION_TYPES.BANNED_EMPLOYEE_ALL_SERVICE_AREAS,
          payload: res,
        });
        toast.success('Staff Ban From All Service Area Successfully');
        if (entityName === 'service_area') {
          dispatch(getBannedEmployees({ id: serviceAreaEntity.id }));
        } else {
          dispatch(getBannedServiceAreas({ id: bannedData.person.id }));
        }
      })
      .catch(err => {
        return err.errorMessage;
      });
  }
};

export const getBannedEmployees: ICrudPutAction<IBannedEmployees> = object => {
  const requestUrl = `${bannedUrl}/serviceArea/${object.id}`;
  return {
    type: ACTION_TYPES.FETCH_BANNED_EMPLOYEES,
    payload: axios.get<IBannedEmployees>(requestUrl),
  };
};

export const getBannedEmployeesOfOrganization: ICrudPutAction<IBannedEmployees> = () => {
  const requestUrl = `api/banned-employee-of-organization`;
  return {
    type: ACTION_TYPES.FETCH_BANNED_EMPLOYEES_OF_ORGANIZATION,
    payload: axios.get<IBannedEmployees>(requestUrl),
  };
};
export const getServiceAreasByClientId = id => ({
  type: ACTION_TYPES.FETCH_SERVICE_AREAS_BY_CLIENT_ID,
  payload: axios.get<IServiceArea>(`api/getServiceAreasByClientId/${id}`),
});
export const setPageNumber: ICrudPutAction<{ pageNumber: number }> = pageNo => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.SET_PAGE_NUMBER,
    payload: pageNo,
  });
  dispatch(getEntities(pageNo['pageNumber'], ''));
  return result;
};

export const getServiceAreasByOrganizationId = id => ({
  type: ACTION_TYPES.FETCH_SERVICE_AREAS_BY_ORGANIZATION_ID,
  payload: axios.get<IServiceArea>(`api/getServiceAreasByOrganization/${id}`),
});

export const updateBannedPeople = (data, id, serviceAreaEntity, entityName) => dispatch => {
  axios
    .patch(`api/update-banned-employee/${id}`, cleanEntity(data))
    .then(res => {
      if (entityName === 'service_area') {
        dispatch(getBannedEmployees({ id: serviceAreaEntity.id }));
        toast.success('Staff Unban Successfully');
      } else {
        dispatch(getBannedServiceAreas({ id: data.person.id }));
      }
    })
    .catch(err => {
      return err;
    });
};

export const getPersonsByServiceAreaId = id => ({
  type: ACTION_TYPES.FETCH_PERSONS_BY_SERVICE_AREA_ID,
  payload: axios.get<IServiceArea>(`api/getPersonForServiceAreas/${id}`),
});

export const getActivePersonsByServiceAreaId = id => ({
  type: ACTION_TYPES.FETCH_ACTIVE_PERSONS_BY_SERVICE_AREA_ID,
  payload: axios.get<IServiceArea>(`api/getActivePersonForServiceAreas/${id}`),
});

export const addNewPerson = data => dispatch => {
  axios
    .post(`api/addNewPerson`, cleanEntity(data))
    .then(res => {
      dispatch({
        type: ACTION_TYPES.ADD_PERSON,
        payload: res,
      });
      toast.success('Staff Created Successfully');
      dispatch(getActivePersonsByServiceAreaId(data?.service_area.id));
    })
    .catch(err => {
      return err.errorMessage;
    });
};
