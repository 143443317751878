import { IOrganization } from 'app/shared/model/organization.model';
import { IPerson } from 'app/shared/model/person.model';

export interface IDepartment {
  id?: number;
  name?: string | null;
  organization?: IOrganization | null;
  people?: IPerson[] | null;
  created_at?: Date | null;
}

export const defaultValue: Readonly<IDepartment> = {
  created_at: new Date(),
};
