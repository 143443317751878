import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Grid, FormControl, TextField, Checkbox, FormControlLabel } from '@material-ui/core';
import '../automated-control-room.scss';
import { Autocomplete } from '@material-ui/lab';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';

export default function ShiftBasicSettings(props) {
  const sendAgain = props.values && props.values[props.title] && props.values[props.title]['send_again'];
  const alertAdmin = props.values && props.values[props.title] && props.values[props.title]['alert_admin'];

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item sm={5} lg={5} md={5}>
          <FormControl className="send-reminder-field">
            <Autocomplete
              options={props.autoCompleteOptions}
              noOptionsText={'No Records'}
              classes={{ listbox: 'autocompletelistbox' }}
              ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
              getOptionLabel={option => (option['label'] ? option['label'] : '')}
              getOptionSelected={(option, value) => option.id === value.id}
              value={props.autoCompleteValue ? props.autoCompleteValue : []}
              onChange={(event, option) => props.handleShiftConfirmationTime(event, option, props.autoCompleteName)}
              renderInput={params => (
                <TextField {...params} variant="outlined" size="small" label="Send Reminder Before" margin="normal" fullWidth />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item sm={1} lg={1} md={1}>
          <FormControl className="send-reminder-field">
            {props.autoCompleteValue && props.autoCompleteValue['id'] === 0 && (
              <TextField
                variant="outlined"
                className="custom-time-field"
                size="small"
                margin="normal"
                fullWidth
                type="number"
                name={props.autoCompleteName}
                onChange={props.handleTextfields}
                defaultValue={0}
                value={props.values && props.values[props.title] && props.values[props.title]['reminder_time']}
              />
            )}
          </FormControl>
        </Grid>

        <Grid item className="modal-input" xs={3} lg={3} md={3}>
          <FormControl style={{ minWidth: 150 }} className="shift-missed-buttons" variant="outlined" size="small">
            <FormControlLabel
              id={sendAgain === true ? 'checked' : 'unchecked'}
              className="shift-missed-checkbox-buttons"
              name="sendAgain"
              label="Send Again"
              labelPlacement="start"
              control={
                <Checkbox
                  name={props.title}
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleCheckedFilled />}
                  onChange={() => {
                    props.handleShiftReminderButtons('sendAgain', event, props.values, props.setValues);
                  }}
                  defaultChecked={sendAgain}
                />
              }
              style={{
                color: sendAgain === true ? 'white' : 'black',
                background:
                  sendAgain === true ? 'linear-gradient(to bottom, #efb5ee, #d488d3)' : 'linear-gradient(to bottom, #FCFCFC, #EFEFEF)',
                border: sendAgain === true ? '1px solid #E5A5E4' : '1px solid #E9E9E9',
              }}
            />
          </FormControl>
        </Grid>

        <Grid item className="modal-input" xs={3} lg={3} md={3}>
          <FormControl style={{ minWidth: 150 }} className="shift-missed-buttons" variant="outlined" size="small">
            <FormControlLabel
              id={alertAdmin === true ? 'checked' : 'unchecked'}
              className="shift-missed-checkbox-buttons"
              name="alertAdmin"
              label="Alert Admin"
              labelPlacement="start"
              control={
                <Checkbox
                  name={props.title}
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleCheckedFilled />}
                  onChange={() => {
                    props.handleShiftReminderButtons('alertAdmin', event, props.values, props.setValues);
                  }}
                  defaultChecked={alertAdmin}
                />
              }
              style={{
                color: alertAdmin === true ? 'white' : 'black',
                background:
                  alertAdmin === true ? 'linear-gradient(to bottom, #efb5ee, #d488d3)' : 'linear-gradient(to bottom, #FCFCFC, #EFEFEF)',
                border: alertAdmin === true ? '1px solid #E5A5E4' : '1px solid #E9E9E9',
              }}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item lg={6} sm={6} md={6}></Grid>

        <Grid item sm={2} lg={1} md={1}>
          <FormControl className="send-reminder-field">
            {sendAgain === true && (
              <React.Fragment>
                <label className="interval-gap-label">Interval Gap</label>
                <TextField
                  variant="outlined"
                  className="interval-gap-field"
                  size="small"
                  margin="normal"
                  fullWidth
                  type="number"
                  name={props.response_time}
                  onChange={props.handleTextfields}
                  defaultValue={0}
                  value={props.values && props.values[props.title] && props.values[props.title]['response_time']}
                />
              </React.Fragment>
            )}
          </FormControl>
        </Grid>

        <Grid item sm={2} lg={1} md={1}>
          <FormControl className="interval-gap-field">
            {sendAgain === true && (
              <React.Fragment>
                <label className="interval-gap-label">Frequency</label>
                <TextField
                  variant="outlined"
                  className="interval-gap-field"
                  size="small"
                  margin="normal"
                  fullWidth
                  type="number"
                  name={props.frequency}
                  onChange={props.handleTextfields}
                  defaultValue={0}
                  value={props.values && props.values[props.title] && props.values[props.title]['frequency']}
                />
              </React.Fragment>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
