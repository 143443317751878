import React, { useState, useEffect } from 'react';
import { Button, Checkbox, Paper, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { de } from 'date-fns/locale';

export const CalendarFilters = props => {
  const { calenderMode, prop, classes, setCalendarFiltersData, calendarFiltersData, calendarPropsData } = props;
  const [calendarFilterContainer, setCalendarFilterContainer] = useState({
    selectPersonFilter: [],
    selectPositionFilter: [],
    selectLocationFilter: [],
  });
  const [colorFilterEnabled, setColorFilterEnabled] = useState('');
  const [personFilterEnabled, setPersonFilterEnabled] = useState(false);
  const [locationFilterEnabled, setLocationFilterEnabled] = useState(false);
  const [designationFilterEnabled, setDesignationFilterEnabled] = useState(false);
  const [filterPersonsData, setFilterPersonsData] = useState([]);
  const [filterLocationsData, setFilterLocationsData] = useState([]);
  const [filterDesignationsData, setFilterDesignationsData] = useState([]);

  useEffect(() => {
    if (filterPersonsData.length > 0 || filterLocationsData.length > 0 || filterDesignationsData.length > 0) {
      filterSchedules();
    } else {
      setCalendarFiltersData(oldValues => ({ ...oldValues, filtersEnabled: false }));
    }
  }, [filterPersonsData, filterLocationsData, filterDesignationsData]);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const personList = [...calendarPropsData.entities];
  const personItems =
    personList &&
    personList
      .sort((a, b) => a.first_name.localeCompare(b.first_name))
      .map(person => ({
        id: person.id,
        text: person.first_name + ' ' + person.last_name,
      }));

  const locationItems = calendarPropsData.locations.map(location => ({
    id: location.id,
    text: location.name,
  }));

  const positionItems = calendarPropsData.positions.map(position => ({
    id: position.id,
    text: position.name,
  }));

  const handlePersonChange = (_, persons) => {
    const data = persons.map(a => a.id);
    if (data.length === 0) {
      setPersonFilterEnabled(false);
    }
    setCalendarFilterContainer(oldValues => ({ ...oldValues, selectPersonFilter: persons }));
    setFilterPersonsData(data);
  };

  const handlePositionChange = (_, positions) => {
    const data = positions.map(a => a.id);
    if (data.length === 0) {
      setDesignationFilterEnabled(false);
    }
    setCalendarFilterContainer(oldValues => ({ ...oldValues, selectPositionFilter: positions }));
    setFilterDesignationsData(data);
  };

  const handleLocationChange = (_, locations) => {
    const data = locations.map(a => a.id);
    if (data.length === 0) {
      setLocationFilterEnabled(false);
    }
    setCalendarFilterContainer(oldValues => ({ ...oldValues, selectLocationFilter: locations }));
    setFilterLocationsData(data);
  };

  const filterShiftsByColors = color => {
    const shifts = [...calendarPropsData.scheduledShifts];
    const data = shifts.filter(shift => shift.bgColor === color);
    setCalendarFiltersData(oldValues => ({ ...oldValues, filteredShifts: data }));
  };

  const handleColorFilters = shiftTime => {
    setCalendarFiltersData(oldValues => ({ ...oldValues, colorFilter: true }));
    if (shiftTime === 'MORNING') {
      filterShiftsByColors('#4888C8');
      setColorFilterEnabled('#4888C8');
    } else if (shiftTime === 'DAY') {
      filterShiftsByColors('#DB901C');
      setColorFilterEnabled('#DB901C');
    } else if (shiftTime === 'NIGHT') {
      filterShiftsByColors('#0B1E38');
      setColorFilterEnabled('#0B1E38');
    } else if (shiftTime === 'LEAVE') {
      setColorFilterEnabled('#2fa7a7');
      setCalendarFiltersData(oldValues => ({ ...oldValues, leavesFilter: true }));
    }
  };

  const filterSchedules = () => {
    const newList = [];
    let persons;
    if (filterPersonsData.length > 0) {
      persons = locationFilterEnabled || designationFilterEnabled ? [...calendarFiltersData.filteredPersons] : [...prop.entities];
      filterPersonsData.forEach(element => {
        persons.filter(person => {
          if (person.id === element) {
            newList.push(person);
          }
        });
      });
      setPersonFilterEnabled(true);
    } else if (filterDesignationsData.length > 0) {
      persons = personFilterEnabled || locationFilterEnabled ? [...calendarFiltersData.filteredPersons] : [...prop.entities];
      const newPersons = [];
      filterDesignationsData.forEach(element => {
        calendarPropsData.scheduledShifts.filter(shift => {
          if (shift.designation.id === element && shift.person) {
            newPersons.push(shift.person.id);
          }
        });
      });
      new Set(newPersons).forEach(element => {
        persons.filter(person => {
          if (person.id === (element !== null && element.toString())) {
            newList.push(person);
          }
        });
      });
      setDesignationFilterEnabled(true);
    } else if (filterLocationsData.length > 0) {
      persons = personFilterEnabled || designationFilterEnabled ? [...calendarFiltersData.filteredPersons] : [...prop.entities];
      const newPersons = [];
      filterLocationsData.forEach(element => {
        calendarPropsData.scheduledShifts.filter(shift => {
          if (shift.service_area.id === element && shift.person && shift.person) {
            newPersons.push(shift.person.id);
          }
        });
      });
      new Set(newPersons).forEach(element => {
        persons.filter(person => {
          if (person.id === (element !== null && element.toString())) {
            newList.push(person);
          }
        });
      });
      setLocationFilterEnabled(true);
    }
    setCalendarFiltersData(oldValues => ({ ...oldValues, filtersEnabled: true, filteredPersons: newList }));
  };

  const resetFilter = () => {
    setCalendarFiltersData({
      leavesFilter: false,
      colorFilter: false,
      filtersEnabled: false,
      filteredShifts: [],
      filteredPersons: [],
    });
    setColorFilterEnabled('');
    setPersonFilterEnabled(false);
    setLocationFilterEnabled(false);
    setDesignationFilterEnabled(false);
    setCalendarFilterContainer({
      selectPersonFilter: [],
      selectPositionFilter: [],
      selectLocationFilter: [],
    });
    setFilterPersonsData([]);
    setFilterLocationsData([]);
    setFilterDesignationsData([]);
  };

  return (
    <React.Fragment>
      {calenderMode !== 'month' && (
        <div className="col-12">
          <div className="calendar-component-filters-div">
            <Button className="calendar-filters-reset-button" onClick={resetFilter}>
              {' '}
              Reset{' '}
            </Button>
            <div className="calendar-filters-employee-filter-div">
              <Autocomplete
                className="filters-select-boxes"
                classes={{ listbox: classes.listbox }}
                ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                limitTags={1}
                multiple
                options={personItems}
                closeIcon={false}
                disableCloseOnSelect
                getOptionLabel={option => option.text}
                value={calendarFilterContainer.selectPersonFilter}
                renderTags={values => values.map(value => <Chip key={value.id} label={value.text} />)}
                getOptionSelected={(option, value) => option.id === value.id}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8, color: '#4620B7' }} checked={selected} />
                    {option.text}
                  </React.Fragment>
                )}
                onChange={handlePersonChange}
                renderInput={params => (
                  <Paper elevation={3}>
                    <TextField {...params} variant="outlined" size="small" label="Person" />
                  </Paper>
                )}
              />
            </div>
            <div className="calendar-filters-designation-filter">
              <Autocomplete
                className="filters-select-boxes"
                classes={{ listbox: classes.listbox }}
                ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                limitTags={1}
                multiple
                options={positionItems}
                closeIcon={false}
                disableCloseOnSelect
                getOptionLabel={option => option.text}
                value={calendarFilterContainer.selectPositionFilter}
                renderTags={values => values.map(value => <Chip key={value.id} label={value.text} />)}
                getOptionSelected={(option, value) => option.id === value.id}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8, color: '#4620B7' }} checked={selected} />
                    {option.text}
                  </React.Fragment>
                )}
                onChange={handlePositionChange}
                renderInput={params => (
                  <Paper elevation={3} className="filterTextBox">
                    <TextField {...params} variant="outlined" size="small" label="Position" />
                  </Paper>
                )}
              />
            </div>
            <div className="calendar-filters-service-area-filter">
              <Autocomplete
                className="filters-select-boxes"
                classes={{ listbox: classes.listbox }}
                ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                limitTags={1}
                multiple
                options={locationItems}
                closeIcon={false}
                disableCloseOnSelect
                getOptionLabel={option => option.text}
                value={calendarFilterContainer.selectLocationFilter}
                renderTags={values => values.map(value => <Chip key={value.id} label={value.text} />)}
                getOptionSelected={(option, value) => option.id === value.id}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8, color: '#4620B7' }} checked={selected} />
                    {option.text}
                  </React.Fragment>
                )}
                onChange={handleLocationChange}
                renderInput={params => (
                  <Paper elevation={3}>
                    <TextField {...params} variant="outlined" size="small" label="Service Area" />
                  </Paper>
                )}
              />
            </div>
            <div className="calendar-color-filters-div">
              <Button
                className="color-filter-m"
                onClick={() => {
                  handleColorFilters('MORNING');
                }}
                style={{
                  border: colorFilterEnabled === '#4888C8' ? '1px solid red' : 'unset',
                  width: colorFilterEnabled === '#4888C8' ? '45px' : '40px',
                  height: colorFilterEnabled === '#4888C8' ? '45px' : '37px',
                }}
              >
                {' '}
                M{' '}
              </Button>
              <Button
                className="color-filter-d"
                onClick={() => {
                  handleColorFilters('DAY');
                }}
                style={{
                  border: colorFilterEnabled === '#DB901C' ? '1px solid red' : 'unset',
                  width: colorFilterEnabled === '#DB901C' ? '45px' : '40px',
                  height: colorFilterEnabled === '#DB901C' ? '45px' : '37px',
                }}
              >
                {' '}
                D{' '}
              </Button>
              <Button
                className="color-filter-n"
                onClick={() => {
                  handleColorFilters('NIGHT');
                }}
                style={{
                  border: colorFilterEnabled === '#0B1E38' ? '1px solid red' : 'unset',
                  width: colorFilterEnabled === '#0B1E38' ? '45px' : '40px',
                  height: colorFilterEnabled === '#0B1E38' ? '45px' : '37px',
                }}
              >
                {' '}
                N{' '}
              </Button>
              <Button
                className={calendarFiltersData.leavesFilter === false ? 'color-filter-l' : 'color-filter-s'}
                onClick={
                  calendarFiltersData.colorFilter === false
                    ? () => {
                        handleColorFilters('LEAVE');
                      }
                    : resetFilter
                }
                style={{ border: colorFilterEnabled === '#00ffff' ? '1px solid red' : 'unset' }}
              >
                {calendarFiltersData.leavesFilter === true ? 'SHIFTS' : 'LEAVE'}
              </Button>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default CalendarFilters;
