import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './employment-details.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IEmploymentDetailsDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const EmploymentDetailsDetail = (props: IEmploymentDetailsDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { employmentDetailsEntity } = props;
  return (
    <div>
      <Grid item container>
        <Grid item xs={8}>
          <h2 data-cy="employmentDetailsDetailsHeading">
            <Translate contentKey="wfmApp.employmentDetails.detail.title">EmploymentDetails</Translate>
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="id">
                <Translate contentKey="global.field.id">ID</Translate>
              </span>
            </dt>
            <dd>{employmentDetailsEntity.id}</dd>
            <dt>
              <span id="startYear">
                <Translate contentKey="wfmApp.employmentDetails.startYear">Start Year</Translate>
              </span>
            </dt>
            <dd>
              {employmentDetailsEntity.startYear ? (
                <TextFormat value={employmentDetailsEntity.startYear} type="date" format={APP_DATE_FORMAT} />
              ) : null}
            </dd>
            <dt>
              <span id="jobTitle">
                <Translate contentKey="wfmApp.employmentDetails.jobTitle">Job Title</Translate>
              </span>
            </dt>
            <dd>{employmentDetailsEntity.jobTitle}</dd>
            <dt>
              <span id="organizationName">
                <Translate contentKey="wfmApp.employmentDetails.organizationName">Organization Name</Translate>
              </span>
            </dt>
            <dd>{employmentDetailsEntity.organizationName}</dd>
            <dt>
              <span id="description">
                <Translate contentKey="wfmApp.employmentDetails.description">Description</Translate>
              </span>
            </dt>
            <dd>{employmentDetailsEntity.description}</dd>
          </dl>
          <Button
            component={Link}
            to="/employment-details"
            replace
            style={{ color: 'black', backgroundColor: 'rgb(13, 202, 240)' }}
            data-cy="entityDetailsBackButton"
          >
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button
            component={Link}
            to={`/employment-details/${employmentDetailsEntity.id}/edit`}
            replace
            style={{ color: 'white', backgroundColor: 'blue' }}
          >
            <FontAwesomeIcon icon="pencil-alt" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = ({ employmentDetails }: IRootState) => ({
  employmentDetailsEntity: employmentDetails.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EmploymentDetailsDetail);
