import { exportPDF } from '../../timesheet-summary/exportPDF';
import moment from 'moment';
const header = [
  'Shift Date',
  'Client',
  'Position',
  'Service Area',
  'Estimated Hours',
  'Working Hours',
  'Client Rate',
  'Staff Rate',
  'Estimated Wage',
  'Actual Wage',
  'Profit',
];
export const convertToPDF = (typeFlag, jsonData, selectedDateFrom, selectedDateTo) => {
  const DateFrom = moment(selectedDateFrom).format('DD-MM-YYYY');
  const DateTo = moment(selectedDateTo).format('DD-MM-YYYY');
  const headers = [header];
  const title = 'Client Report';
  const profit = 0;
  const data = jsonData.map(row => [
    row.shiftStartTime === null && row.shiftEndTime === null
      ? row.startDate + '\n' + row.endDate
      : row.startDate + '\n' + row.shiftStartTime + '\n' + row.shiftEndTime,
    row.type === undefined ? row.client : row.client + '\n' + row.type + ' Shift(s)',
    row.position,
    row.location,
    row.scheduledTime,
    row.actualTime,
    row.plannedCharge,
    row.actualCharge,
    row.plannedPay,
    row.actualPay,
    profit,
  ]);
  const filename = typeFlag === true ? 'Client_Report.pdf' : 'Client_Report_Detailed.pdf';
  exportPDF(DateFrom, DateTo, headers, title, data, filename);
};
