/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import { Box, Paper, Table, TableBody, TableRow, Typography } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import Link from '@material-ui/core/Link';
import { dateFTConverter } from '../../../../utils/TimeFormatAndTimezone';
import { Storage } from 'react-jhipster';
import moment from 'moment/moment';
import { render } from '@testing-library/react';
export const SummaryPdfTable = data => {
  const dateFormat = Storage.local.get('PersonalSetting').date_format;
  const addWorkingDays = (startDate, days) => {
    const currentDate = moment(startDate);

    for (let i = 0; i < days; i++) {
      do {
        currentDate.add(1, 'days');
      } while (currentDate.day() === 0 || currentDate.day() === 6); // Skip weekends (Sunday and Saturday)
    }

    return dateFTConverter(currentDate, dateFormat);
  };

  return (
    <>
      <Table id={'screenerDetail'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell>SCREENER</TableCell>
            <TableCell>DATE OF ISSUE</TableCell>
            <TableCell>CANDIDATE STATUS</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th">
              {data['summaryScreener'] ? data['summaryScreener']['reporter'].first_name : ''}{' '}
              {data['summaryScreener'] ? data['summaryScreener']['reporter'].last_name : ''}
            </TableCell>
            <TableCell component="th">
              {data['summaryScreener'] ? moment(data['summaryScreener'].create_date).format('MM/DD/YYYY') : ''}
            </TableCell>
            <TableCell component="th">{data['summaryPerson'] ? data['summaryPerson'].vetting_status : ''}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table id={'candidateSummary'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>CANDIDATE SUMMARY</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th">Credit check and address link search completed:</TableCell>
            <TableCell component="th">{data['connect_api_information'] ? 'True' : 'False'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">References verified and confirmed:</TableCell>
            <TableCell component="th">
              {data['summaryPerson'] && data['summaryPerson'].vetting_status === 'Completed' ? 'True' : 'False'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">Gap references not used.</TableCell>
            <TableCell component="th">
              {data['summaryEmploymentReferences'] && data['summaryEmploymentReferences'].length > 0 ? 'True' : 'False'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">Original proofs of identity and address checked by client.</TableCell>
            <TableCell component="th">
              {(data['summaryPerson'] && data['summaryPerson']['vetting_status'] === 'InvalidApplication') ||
                (data['summaryPerson'] && data['summaryPerson']['vetting_status'] === 'Failed')
                ? 'False'
                : 'True'}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table id={'candidateInfo'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell>DATE OF REQUEST</TableCell>
            <TableCell>REQUESTED BY</TableCell>
            <TableCell>DATE CANDIDATE COMPLETED</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th">{`${moment().format('MM/DD/YYYY')}`}</TableCell>
            <TableCell component="th">
              {data['requestUser'] ? data['requestUser'].first_name : ''} {data['requestUser'] ? data['requestUser'].last_name : ''}
            </TableCell>
            <TableCell component="th">N/A</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table id={'personalInformation'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>PERSONAL INFORMATION</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th">
              <b>First Name:</b>
            </TableCell>
            <TableCell component="th">{data['personalInformation'] ? data['personalInformation'].first_name : ''}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">Last Name:</TableCell>
            <TableCell component="td">{data['personalInformation'] ? data['personalInformation'].last_name : ''}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">Date of Birth:</TableCell>
            <TableCell component="td">
              {data['personalInformation'] ? moment(data['personalInformation'].date_of_birth).format('MM/DD/YYYY') : ''}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">Gender:</TableCell>
            <TableCell component="td">{data['personalInformation'] ? data['personalInformation'].gender : ''}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">Email Address:</TableCell>
            <TableCell component="td">{data['personalInformation'] ? data['personalInformation'].email_address : ''}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">Telephone No.</TableCell>
            <TableCell component="td">{data['personalInformation'] ? data['personalInformation'].telephone_no : ''}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">Mobile No.</TableCell>
            <TableCell component="td">{data['personalInformation'] ? data['personalInformation'].mobile_no : ''}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">Nationality:</TableCell>
            <TableCell component="td">
              {data['personalInformation'] && data['personalInformation']['nationality']
                ? data['personalInformation']['nationality'].name
                : ''}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">Current Address:</TableCell>
            <TableCell component="td">{data['personalInformation'] ? data['personalInformation'].current_address : ''}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">Postcode:</TableCell>
            <TableCell component="td">{data['personalInformation'] ? data['personalInformation'].post_code : ''}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table id={'licenseDetail'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>SIA LICENSE DETAILS</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th">
              <b>Name on the License:</b>
            </TableCell>
            <TableCell component="th">{data['personalInformation'] ? data['personalInformation'].license_name : ''}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">License No.</TableCell>
            <TableCell component="td">{data['personalInformation'] ? data['personalInformation'].sia_license_no : ''}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">License Expiry:</TableCell>
            <TableCell component="td">{data['personalInformation'] ? data['personalInformation'].license_expiration : ''}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table id={'employmentDetail'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>EMPLOYMENT DETAILS</TableCell>
            <TableCell>REFEREE RESPONSE</TableCell>
          </TableRow>
        </TableHead>
        {data['summaryEmployment'] &&
          data['summaryEmployment'].length > 0 &&
          data['summaryEmployment'].map((value, index) => {
            return (
              <TableBody key={index}>
                <TableRow>
                  <TableCell
                    component="td"
                    style={{
                      fontWeight: 900,
                    }}
                  >
                    Company Name:
                  </TableCell>
                  <TableCell component="td">{value.company_name}</TableCell>
                  <TableCell component="td">Correct</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <b>Job Title:</b>
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {value.job_title}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <b>Company Location:</b>
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {value.company_location}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <b>PostCode:</b>
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {value.post_code}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <b>From:</b>
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {value.emp_start_date}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <b>Till:</b>
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {value.emp_end_date}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <b>Reason for Leaving:</b>
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {value.leaving_reason}
                  </TableCell>
                </TableRow>
              </TableBody>
            );
          })}
      </Table>
      {/* <Table id={'supportingLiterature'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>SUPPORTING LITERATURE</TableCell>
            <TableCell>REFEREE RESPONSE</TableCell>
          </TableRow>
        </TableHead>
        {data['summaryEmploymentHistoryFiles'] && data['summaryEmploymentHistoryFiles'].length > 0 ? (
          data['summaryEmploymentHistoryFiles'].map((value, index) => {
            return (
              <TableBody key={index}>
                <TableRow>
                  <TableCell component="td">
                    <b>Document Provided:</b>
                  </TableCell>
                  <TableCell component="td">Employment Document</TableCell>
                  <TableCell component="td">Correct</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <b>Acknowledgement Date:</b>
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {moment(value.created_at).format('MM/DD/YYYY')}
                  </TableCell>
                </TableRow>
              </TableBody>
            );
          })
        ) : (
          <TableBody>
            <TableRow>
              <TableCell component="td">
                <b>Document Provided:</b>
              </TableCell>
              <TableCell component="td">ABC Certificate</TableCell>
              <TableCell component="td">Correct</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <b>Acknowledgement Date:</b>
              </TableCell>
              <TableCell component="td" scope="row">
                04/01/2022
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table> */}
      <Table id={'referenceDetail'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>REFERENCE DETAILS</TableCell>
          </TableRow>
        </TableHead>
        {data['summaryEmploymentReferences'] && data['summaryEmploymentReferences'].length > 0 ? (
          data['summaryEmploymentReferences'].map((value, index) => {
            return (
              <TableBody key={index}>
                <TableRow>
                  <TableCell component="td">
                    <b>Reference name:</b>
                  </TableCell>
                  <TableCell component="td">{value.contact_name ? value.contact_name : ''}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="td">
                    <b>Email:</b>
                  </TableCell>
                  <TableCell component="td">{value.contact_email ? value.contact_email : ''}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <b>Contact no.</b>
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {value.contact_telephone ? value.contact_telephone : ''}
                  </TableCell>
                </TableRow>
              </TableBody>
            );
          })
        ) : (
          <TableBody>
            <TableRow>
              <TableCell component="td">
                <b>Reference name:</b>
              </TableCell>
              <TableCell component="td">No information available.</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <b>Contact no.</b>
              </TableCell>
              <TableCell component="td" scope="row">
                No information available.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <b>Position:</b>
              </TableCell>
              <TableCell component="td" scope="row">
                No information available.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <b>Organization:</b>
              </TableCell>
              <TableCell component="td" scope="row">
                No information available.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <b>Reference Status</b>
              </TableCell>
              <TableCell component="td" scope="row">
                No information available.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <b>Validation Date:</b>
              </TableCell>
              <TableCell component="td" scope="row">
                No information available.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <b>Validation Notes:</b>
              </TableCell>
              <TableCell component="td" scope="row">
                No information available.
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
      <Table id={'activityClaimDetail'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>ACTIVITY CLAIMS</TableCell>
            <TableCell>REFEREE RESPONSE</TableCell>
          </TableRow>
        </TableHead>

        {data['summaryOthers'] && data['summaryOthers'].length > 0 ? (
          data['summaryOthers'].map((value, index) => {
            return (
              <TableBody key={index}>
                <TableRow>
                  <TableCell component="td">
                    <b>Claim Type:</b>
                  </TableCell>
                  <TableCell component="td">{value.reason ? value.reason : 'None'}</TableCell>
                  <TableCell component="td">Correct</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="td">
                    <b>From:</b>
                  </TableCell>
                  <TableCell component="td">{moment(value.start_date).format('MM/DD/YYYY')}</TableCell>
                  <TableCell component="td"></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="td">
                    <b>Till:</b>
                  </TableCell>
                  <TableCell component="td">{moment(value.end_date).format('MM/DD/YYYY')}</TableCell>
                  <TableCell component="td"></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="td">
                    <b>Extra Note:</b>
                  </TableCell>
                  <TableCell component="td">{value.note}</TableCell>
                  <TableCell component="td"></TableCell>
                </TableRow>
              </TableBody>
            );
          })
        ) : (
          <TableBody>
            <TableRow>
              <TableCell component="td">
                <b>Claim Type:</b>
              </TableCell>
              <TableCell component="td">Residing outside of UK</TableCell>
              <TableCell component="td">Correct</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="td">
                <b>From:</b>
              </TableCell>
              <TableCell component="td"></TableCell>
              <TableCell component="td"></TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="td">
                <b>Till:</b>
              </TableCell>
              <TableCell component="td"></TableCell>
              <TableCell component="td"></TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="td">
                <b>Extra Note:</b>
              </TableCell>
              <TableCell component="td"></TableCell>
              <TableCell component="td"></TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
      <Table id={'referenceDetail2'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>REFERENCE DETAILS</TableCell>
          </TableRow>
        </TableHead>
        {data['summaryOtherReferences'] ? (
          <TableBody>
            <TableRow>
              <TableCell component="td">
                <b>Reference name:</b>
              </TableCell>
              <TableCell component="td">
                {data['summaryOtherReferences']['ref_name'] ? data['summaryOtherReferences']['ref_name'] : ''}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <b>Contact no.</b>
              </TableCell>
              <TableCell component="td" scope="row">
                {data['summaryOtherReferences']['ref_telephone'] ? data['summaryOtherReferences']['ref_telephone'] : ''}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <b>Position:</b>
              </TableCell>
              <TableCell component="td" scope="row">
                No information available.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <b>Organization:</b>
              </TableCell>
              <TableCell component="td" scope="row">
                No information available.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <b>Reference Status</b>
              </TableCell>
              <TableCell component="td" scope="row">
                No information available.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <b>Validation Date:</b>
              </TableCell>
              <TableCell component="td" scope="row">
                No information available.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <b>Validation Notes:</b>
              </TableCell>
              <TableCell component="td" scope="row">
                No information available.
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>
            <TableRow>
              <TableCell component="td">
                <b>Reference name:</b>
              </TableCell>
              <TableCell component="td">No information available.</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <b>Contact no.</b>
              </TableCell>
              <TableCell component="td" scope="row">
                No information available.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <b>Position:</b>
              </TableCell>
              <TableCell component="td" scope="row">
                No information available.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <b>Organization:</b>
              </TableCell>
              <TableCell component="td" scope="row">
                No information available.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <b>Reference Status</b>
              </TableCell>
              <TableCell component="td" scope="row">
                No information available.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <b>Validation Date:</b>
              </TableCell>
              <TableCell component="td" scope="row">
                No information available.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <b>Validation Notes:</b>
              </TableCell>
              <TableCell component="td" scope="row">
                No information available.
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
      <Table id={'appendixDetail'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={4}>APPENDIX: ONLINE CHECKS</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              Reference ID
            </TableCell>
            <TableCell component="th" scope="row">
              Check Type
            </TableCell>
            <TableCell component="th" scope="row">
              Requested on
            </TableCell>
            <TableCell component="th" scope="row">
              Completed on
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="td" scope="row">
              {data['connect_api_information'] ? 'CH-' + data['connect_api_information']['customerId'] : 'No Information Available'}
            </TableCell>
            <TableCell component="td" scope="row">
              Sanctions
            </TableCell>
            <TableCell component="td" scope="row">
              {' '}
              {moment().format('MM/DD/YYYY')}
            </TableCell>
            <TableCell component="td" scope="row">
              {' '}
              {data['connect_api_information']
                ? moment(data['connect_api_information']['checkDate']).format('MM/DD/YYYY')
                : 'No Information Available'}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table id={'detailProcessed'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>DETAILS PROCESSED</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="td" scope="row">
              First Name:
            </TableCell>
            <TableCell component="td" scope="row">
              {data['personalInformation'] ? data['personalInformation'].first_name : ''}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="td" scope="row">
              Last Name:
            </TableCell>
            <TableCell component="td" scope="row">
              {data['personalInformation'] ? data['personalInformation'].last_name : ''}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="td" scope="row">
              Gender:
            </TableCell>
            <TableCell component="td" scope="row">
              {data['personalInformation'] ? data['personalInformation'].gender : ''}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="td" scope="row">
              Date of Birth:
            </TableCell>
            <TableCell component="td" scope="row">
              {data['personalInformation'] ? data['personalInformation'].date_of_birth : ''}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="td" scope="row">
              Current Address:
            </TableCell>
            <TableCell component="td" scope="row">
              {data['personalInformation'] ? data['personalInformation'].current_address : ''}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table id={'ragScore'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>RAG & SCORE</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="td" scope="row">
              RAG:
            </TableCell>
            <TableCell component="td" scope="row">
              {data['connect_api_information'] && data['connect_api_information']['band']}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="td" scope="row">
              Score:
            </TableCell>
            <TableCell component="td" scope="row">
              {data['connect_api_information'] && data['connect_api_information']['score']}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table id={'alerts'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>ALERTS</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="td" scope="row">
              Message:
            </TableCell>
            <TableCell component="td" scope="row">
              No information available.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="td" scope="row">
              Type:
            </TableCell>
            <TableCell component="td" scope="row">
              No information available.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="td" scope="row">
              Score:
            </TableCell>
            <TableCell component="td" scope="row">
              No information available.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="td" scope="row">
              Category:
            </TableCell>
            <TableCell component="td" scope="row">
              No information available.
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table id={'detailProcessed2'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>DETAILS PROCESSED</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="td" scope="row">
              First Name:
            </TableCell>
            <TableCell component="td" scope="row">
              {data['personalInformation'] ? data['personalInformation'].first_name : ''}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="td" scope="row">
              Last Name:
            </TableCell>
            <TableCell component="td" scope="row">
              {data['personalInformation'] ? data['personalInformation'].last_name : ''}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="td" scope="row">
              Gender:
            </TableCell>
            <TableCell component="td" scope="row">
              {data['personalInformation'] ? data['personalInformation'].gender : ''}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="td" scope="row">
              Date of Birth:
            </TableCell>
            <TableCell component="td" scope="row">
              {data['personalInformation'] ? data['personalInformation'].date_of_birth : ''}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="td" scope="row">
              Current Address:
            </TableCell>
            <TableCell component="td" scope="row">
              {data['personalInformation'] &&
                `${data['personalInformation'].buildingNo || ''}, ${data['personalInformation'].building_name || ''}, ${
                  data['personalInformation'].street || ''
                }, ${data['personalInformation'].city || ''}`}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table id={'identityInformation'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>DETAILS PROCESSED</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="td" scope="row">
              Electoral roll and Rolling register check
            </TableCell>
            <TableCell component="td" scope="row">
              {data['electoral_roll_entry'] && data['electoral_roll_entry']['electoral_roll_and_rolling_register_check']
                ? data['electoral_roll_entry']['electoral_roll_and_rolling_register_check']
                : 'No Information Available'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="td" scope="row">
              Currently on electoral roll?:
            </TableCell>
            <TableCell component="td" scope="row">
              {data['electoral_roll_entry'] && data['electoral_roll_entry']['currently_on_electoral_roll']
                ? data['electoral_roll_entry']['currently_on_electoral_roll']
                : 'No Information Available'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="td" scope="row">
              Name matched at Address on Electoral Register?
            </TableCell>
            <TableCell component="td" scope="row">
              {data['electoral_roll_entry'] && data['electoral_roll_entry']['name_matched_address_electoral_roll']
                ? data['electoral_roll_entry']['name_matched_address_electoral_roll']
                : 'No Information Available'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="td" scope="row">
              Electoral Roll and rolling register - Date of Birth Check
            </TableCell>
            <TableCell component="td" scope="row">
              No
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="td" scope="row">
              Years since person electoral roll entry
            </TableCell>
            <TableCell component="td" scope="row">
              {data['electoral_roll_entry'] && data['electoral_roll_entry']['years_since_on_electoral_roll']
                ? data['electoral_roll_entry']['years_since_on_electoral_roll']
                : 'No Information Available'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="td" scope="row">
              Length of residence - current address
            </TableCell>
            <TableCell component="td" scope="row">
              {data['electoral_roll_entry'] && data['electoral_roll_entry']['length_of_residence']
                ? data['electoral_roll_entry']['length_of_residence']
                : 'No Information Available'}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table id={'electoralRole'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={4}>ELECTORAL ROLL</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              Address
            </TableCell>
            <TableCell component="th" scope="row">
              Name
            </TableCell>
            <TableCell component="th" scope="row">
              Start
            </TableCell>
            <TableCell component="th" scope="row">
              End
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="td" scope="row">
              {data['electoral_roll_entry'] && data['electoral_roll_entry']['electoral_roll_address']
                ? data['electoral_roll_entry']['electoral_roll_address']
                : 'No Information Available'}
            </TableCell>
            <TableCell component="td" scope="row">
              {data['electoral_roll_entry'] && data['electoral_roll_entry']['electoral_roll_name']
                ? data['electoral_roll_entry']['electoral_roll_name']
                : 'No Information Available'}
            </TableCell>
            <TableCell component="td" scope="row">
              {data['electoral_roll_entry'] && data['electoral_roll_entry']['electoral_roll_start_date']
                ? moment(data['electoral_roll_entry']['electoral_roll_start_date']).format('DD/MM/YYYY')
                : 'No Information Available'}
            </TableCell>
            <TableCell component="td" scope="row">
              {data['electoral_roll_entry'] && data['electoral_roll_entry']['electoral_roll_end_date']
                ? moment(data['electoral_roll_entry']['electoral_roll_end_date']).format('DD/MM/YYYY')
                : 'No Information Available'}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table id={'rollingRegister'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={3}>ROLLING REGISTER</TableCell>
          </TableRow>
        </TableHead>
        {data['electoral_roll_entry'] &&
          data['electoral_roll_entry']['previousAddress'] &&
          data['electoral_roll_entry']['previousAddress'].length > 0 ? (
          data['electoral_roll_entry']['previousAddress'].map((value, index) => {
            return (
              <TableBody key={index}>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Address
                  </TableCell>
                  {/* <TableCell component="th" scope="row">
                    Name
                  </TableCell> */}
                  <TableCell component="th" scope="row">
                    Supply Date
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="td" scope="row">
                    {value['previous_address']}
                  </TableCell>
                  {/* <TableCell component="td" scope="row">
                    {value['previous_address_name']}
                  </TableCell> */}
                  <TableCell component="td" scope="row">
                    {moment(value['previous_address_supply_date']).format('DD/MM/YYYY')}
                  </TableCell>
                </TableRow>
              </TableBody>
            );
          })
        ) : (
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                Address
              </TableCell>
              <TableCell component="th" scope="row">
                Name
              </TableCell>
              <TableCell component="th" scope="row">
                Supply Date
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="td" scope="row">
                979 ROMFORD ROAD, LONDON E12 5JR
              </TableCell>
              <TableCell component="td" scope="row">
                SRIKANTH REDDY KONTHAM
              </TableCell>
              <TableCell component="td" scope="row">
                01/1/2022
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
      <Table id={'insolvencyInformation'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>COURT AND INSOLVENCY INFORMATION</TableCell>
          </TableRow>
        </TableHead>
        {data['connect_api_information'] &&
          data['connect_api_information']['insolvencies'] &&
          data['connect_api_information']['insolvencies'].length > 0 ? (
          data['connect_api_information']['insolvencies'].map((value, index) => {
            return (
              <TableBody key={index}>
                <TableRow>
                  <TableCell component="td" scope="row">
                    Address:
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {value['address']['address']}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="td" scope="row">
                    Type:
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {value['restriction']['type']}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="td" scope="row">
                    Court Code:
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {value['orderTypeCode']}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="td" scope="row">
                    Court Date:
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {moment(value['orderDate']).format('DD/MM/YYYY')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="td" scope="row">
                    Court Name:
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {value['court']}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="td" scope="row">
                    Old Case No.
                  </TableCell>
                  <TableCell component="td" scope="row">
                    No information available.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="td" scope="row">
                    Amount
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {value['amount']}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="td" scope="row">
                    Satisfied Date
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {moment(value['restriction']['endDate']).format('DD/MM/YYYY')}
                  </TableCell>
                </TableRow>
              </TableBody>
            );
          })
        ) : (
          <TableBody>
            <TableRow>
              <TableCell component="td" scope="row">
                Address:
              </TableCell>
              <TableCell component="td" scope="row">
                No information available.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="td" scope="row">
                Type:
              </TableCell>
              <TableCell component="td" scope="row">
                No information available.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="td" scope="row">
                Court Code:
              </TableCell>
              <TableCell component="td" scope="row">
                No information available.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="td" scope="row">
                Court Date:
              </TableCell>
              <TableCell component="td" scope="row">
                No information available.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="td" scope="row">
                Court Name:
              </TableCell>
              <TableCell component="td" scope="row">
                No information available.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="td" scope="row">
                Old Case No.
              </TableCell>
              <TableCell component="td" scope="row">
                No information available.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="td" scope="row">
                Amount
              </TableCell>
              <TableCell component="td" scope="row">
                No information available.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="td" scope="row">
                Satisfied Date
              </TableCell>
              <TableCell component="td" scope="row">
                No information available.
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
      <Table id={'correctionDisputeData'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>NOTICE OF CORRECTION OR DISPUTE DATA</TableCell>
          </TableRow>
        </TableHead>
        {data['connect_api_information'] &&
          data['connect_api_information']['noticesAndDisputeData'] &&
          data['connect_api_information']['noticesAndDisputeData'].length > 0 ? (
          data['connect_api_information']['noticesAndDisputeData'].map((value, index) => {
            return (
              <TableBody key={index}>
                <TableRow>
                  <TableCell component="td" scope="row">
                    Address:
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {value['address']['address']}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="td" scope="row">
                    Information:
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {value['comment']}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="td" scope="row">
                    Date Created:
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {moment(value['dateRaised']).format('DD/MM/YYYY')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="td" scope="row">
                    Type:
                  </TableCell>
                  <TableCell component="td" scope="row">
                    No information available.
                  </TableCell>
                </TableRow>
              </TableBody>
            );
          })
        ) : (
          <TableBody>
            <TableRow>
              <TableCell component="td" scope="row">
                Address:
              </TableCell>
              <TableCell component="td" scope="row">
                No information available.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="td" scope="row">
                Information:
              </TableCell>
              <TableCell component="td" scope="row">
                No information available.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="td" scope="row">
                Date Created:
              </TableCell>
              <TableCell component="td" scope="row">
                No information available.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="td" scope="row">
                Type:
              </TableCell>
              <TableCell component="td" scope="row">
                No information available.
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
      <Table id={'aliases'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>ALIASES</TableCell>
          </TableRow>
        </TableHead>

        {data['connect_api_information'] &&
          data['connect_api_information']['aliases'] &&
          data['connect_api_information']['aliases'].length > 0 ? (
          data['connect_api_information']['aliases'].map((value, index) => {
            return (
              <TableBody key={index}>
                <TableRow>
                  <TableCell component="td" scope="row">
                    Title:
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {value['alias'].split(' ')[0]}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="td" scope="row">
                    Forename:
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {value['alias'].split(' ')[1]}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="td" scope="row">
                    Middle Name:
                  </TableCell>
                  <TableCell component="td" scope="row">
                    No information available.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="td" scope="row">
                    Surname:
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {value['alias'].split(' ')[2]}
                  </TableCell>
                </TableRow>
              </TableBody>
            );
          })
        ) : (
          <TableBody>
            <TableRow>
              <TableCell component="td" scope="row">
                Title:
              </TableCell>
              <TableCell component="td" scope="row">
                No information available.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="td" scope="row">
                Forename:
              </TableCell>
              <TableCell component="td" scope="row">
                No information available.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="td" scope="row">
                Middle Name:
              </TableCell>
              <TableCell component="td" scope="row">
                No information available.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="td" scope="row">
                Surname:
              </TableCell>
              <TableCell component="td" scope="row">
                No information available.
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
      <Table id={'electoralRole2'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={4}>ELECTORAL ROLL</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              Address
            </TableCell>
            <TableCell component="th" scope="row">
              Name
            </TableCell>
            <TableCell component="th" scope="row">
              Start
            </TableCell>
            <TableCell component="th" scope="row">
              End
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="td" scope="row">
              979 ROMFORD ROAD, LONDON E12 5JR
            </TableCell>
            <TableCell component="td" scope="row">
              SRIKANTH REDDY KONTHAM
            </TableCell>
            <TableCell component="td" scope="row">
              01/1/2022
            </TableCell>
            <TableCell component="td" scope="row">
              01/1/2022
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table id={'rollingRegister2'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={3}>ROLLING REGISTER</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              Address
            </TableCell>
            <TableCell component="th" scope="row">
              Name
            </TableCell>
            <TableCell component="th" scope="row">
              Supply Date
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="td" scope="row">
              979 ROMFORD ROAD, LONDON E12 5JR
            </TableCell>
            <TableCell component="td" scope="row">
              SRIKANTH REDDY KONTHAM
            </TableCell>
            <TableCell component="td" scope="row">
              01/1/2022
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table id={'privacyIntroduction'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>
              INTRODUCTION
              <br />
              <br />
              <Typography>
                <i>Smart Workforce Privacy Notice</i>
                <br />
                <br />
                This privacy notice lets you know what happens to any personal data that you give to us, or any that we may collect from or
                about you. It applies to all services, and instances where we collect your personal data.
                <br />
                <br />
                <br />
                This privacy notice applies to personal information processed by Smart Workforce.
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              Legal entity:
            </TableCell>
            <TableCell component="th" scope="row">
              Smart Workforce is a limited company registered in England & Wales.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Registered no.
            </TableCell>
            <TableCell component="th" scope="row"></TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Registered Office:
            </TableCell>
            <TableCell component="th" scope="row"></TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Protection license No.
            </TableCell>
            <TableCell component="th" scope="row"></TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              DPO Officer:
            </TableCell>
            <TableCell component="th" scope="row"></TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Purpose of Processing:
            </TableCell>
            <TableCell component="th" scope="row"></TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Basis:
            </TableCell>
            <TableCell component="th" scope="row"></TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2} component="th" scope="row">
              Note: Smart Workforce are a “Data Processor” and are not a “Data Controller”.
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table id={'privacyPolicy'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>PRIVACY POLICY</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              Our Contact Info:
            </TableCell>
            <TableCell component="th" scope="row">
              Email: <Link>info@smartworkforce.co.uk</Link> | Tel: +44 20 3318 0496
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              FAO:
            </TableCell>
            <TableCell component="th" scope="row">
              The DPO Officer
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Location:
            </TableCell>
            <TableCell component="th" scope="row">
              Office 10-12 Lion Chambers John William Street Huddersfield HD1 1ES
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table id={'autoMatedGeneratedChecklist'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>
              AUTOMATED GENERATED CHECK LIST
              <br />
              <br />
              <Typography>
                Parts of your data may be passed on the following third parties to conduct relevant compliance checks:
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Company</TableCell>
            <TableCell>More Information</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              Equifax
            </TableCell>
            <TableCell component="th" scope="row">
              Click here
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Northrow
            </TableCell>
            <TableCell component="th" scope="row">
              Click here
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Employment References
            </TableCell>
            <TableCell component="th" scope="row">
              Name, Claim, DOB, NI Number
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Academic References
            </TableCell>
            <TableCell component="th" scope="row">
              Name, Claim, DOB, Number
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Activity References
            </TableCell>
            <TableCell component="th" scope="row">
              Name, Claim
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Table id={'refEmailTracking'} className={'hidePdfTableFromVettingPage'}>
        <TableHead>
          <TableRow>
            <TableCell>REFERENCE EMAIL EVENTS</TableCell>
            <TableCell>SENT / RECIEVED</TableCell>
            <TableCell>DATE</TableCell>
          </TableRow>
        </TableHead>

        {data['ref_tracking'] ? (
          <TableBody>
            <TableRow>
              <TableCell component="td">
                <b>First Email</b>
              </TableCell>
              <TableCell component="td">{data['ref_tracking'].first_email_sent === true ? 'Yes' : 'No'}</TableCell>
              <TableCell component="td">{dateFTConverter(data['ref_tracking'].created_at, dateFormat)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="td">
                <b>Second Email</b>
              </TableCell>
              <TableCell component="td">{data['ref_tracking'].second_email_sent === true ? 'Yes' : 'No'}</TableCell>
              <TableCell component="td">
                {data['ref_tracking'].second_email_sent === true ? addWorkingDays(data['ref_tracking'].created_at, 5) : ''}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="td">
                <b>Third Email</b>
              </TableCell>
              <TableCell component="td">{data['ref_tracking'].third_email_sent === true ? 'Yes' : 'No'}</TableCell>
              <TableCell component="td">
                {data['ref_tracking'].second_email_sent === true ? addWorkingDays(data['ref_tracking'].created_at, 10) : ''}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="td">
                <b>Response Recieved</b>
              </TableCell>
              <TableCell component="td">{data['ref_tracking'].response_recieved === true ? 'Yes' : 'No'}</TableCell>
              <TableCell component="td">
                {data['ref_tracking'].response_recieved === true ? dateFTConverter(data['ref_tracking'].updated_at, dateFormat)  : ''}
              </TableCell>
            </TableRow>
          </TableBody>
        ) : null}
      </Table>
    </>
  );
};
export default SummaryPdfTable;
