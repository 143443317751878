import { exportPDF } from './exportPDF';
import moment from 'moment';

const header = ['Date', 'Name', 'Status', 'Call Type', 'Phone Number'];

export const convertToPDF = (typeFlag, jsonData, selectedDateFrom, selectedDateTo) => {
  const DateFrom = moment(selectedDateFrom).format('DD-MM-YYYY');
  const DateTo = moment(selectedDateTo).format('DD-MM-YYYY');
  const headers = [header];
  const title = 'Call Logs Report';
  const data = jsonData.map(row => [row.date, row.person, row.contactType, row.reason, row.contact]);
  const filename = 'Call Logs Report.pdf';

  exportPDF(DateFrom, DateTo, headers, title, data, filename);
};
