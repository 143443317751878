import dayjs from 'dayjs';
import { IEmployeeContractType } from 'app/shared/model/employee-contract-type.model';
import { IPerson } from 'app/shared/model/person.model';

export interface IEmployeeContract {
  id?: number;
  startDate?: string | null;
  endDate?: string | null;
  employeeContractType?: IEmployeeContractType | null;
  person?: IPerson | null;
}

export const defaultValue: Readonly<IEmployeeContract> = {};
