import React, { useEffect, useState } from 'react';
import PickerToolbar from '@material-ui/pickers/_shared/PickerToolbar';
import ToolbarButton from '@material-ui/pickers/_shared/ToolbarButton';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';

export const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    backgroundColor: '#4620B7',
  },
});

export const CustomToolbarComponent = function (props: any, settingFormat: any) {
  const { date, isLandscape, openView, setOpenView, title } = props;
  const [dateFormat, setdateFormat] = useState(props.settingFormat);
  const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  useEffect(() => {
    setdateFormat(props.settingFormat);
  }, [props.setting]);

  const handleChangeViewClick = (view: any) => (e: any) => {
    setOpenView(view);
  };

  const classes = useStyles();

  const dateConverter = dateformat => {
    const dateString = dateformat.split(' ');
    const month = dateString[0];
    const dates = dateString[1];
    const year = dateString[2];
    if (dateformat === null) {
      if (dateFormat === 1) {
        return 'DD-MM-YY';
      } else if (dateFormat === 2) {
        return 'DD-MM-YYYY';
      } else if (dateFormat === 3) {
        return 'MM/DD/YY';
      } else if (dateFormat === 4) {
        return 'MM/DD/YYYY';
      } else if (dateFormat === 5) {
        return 'DD.MM.YY';
      } else if (dateFormat === 6) {
        return 'DD.MM.YYYY';
      } else if (dateFormat === 7) {
        return 'YY/MM/DD';
      } else if (dateFormat === 8) {
        return 'YYYY/MM/DD';
      }
    } else {
      if (dateFormat === 1) {
        return `${dates}-${month}-${moment(year).format('YY')}`;
      } else if (dateFormat === 2) {
        return `${dates}-${month}-${year}`;
      } else if (dateFormat === 3) {
        return `${month}/${dates}/${moment(year).format('YY')}`;
      } else if (dateFormat === 4) {
        return `${month}/${dates}/${year}`;
      } else if (dateFormat === 5) {
        return `${dates}.${month}.${moment(year).format('YY')}`;
      } else if (dateFormat === 6) {
        return `${dates}.${month}.${year}`;
      } else if (dateFormat === 7) {
        return `${moment(year).format('YY')}/${month}/${dates}`;
      } else if (dateFormat === 8) {
        return `${year}/${month}/${dates}`;
      }
    }
  };

  return (
    <PickerToolbar className={classes.toolbar} title={title} isLandscape={isLandscape}>
      <ToolbarButton
        onClick={handleChangeViewClick('year')}
        variant="h6"
        label={moment(date).format('YYYY')}
        selected={openView === 'year'}
      />
      <ToolbarButton
        onClick={handleChangeViewClick('date')}
        variant="h5"
        label={dateConverter(`${months[moment(date).month()]} ${moment(date).date()} ${moment(date).year()}`)}
        selected={openView === 'date'}
      />
    </PickerToolbar>
  );
};

export default CustomToolbarComponent;
