import React, { useState, useEffect } from 'react';
import ShiftsChart from './ShiftChartComponent';
import Loader from '../../../commonUI/loader';
import { Divider } from '@material-ui/core';

export const ShiftsColumnChart = props => (
  <div className="dashboard-upcoming-shifts-column-chart-div">
    <div className="shifts-bar-chart-component-header-div">
      <div className="shifts-bar-chart-component-header-label">
        <strong> Upcoming Shifts </strong>
      </div>
      <Divider style={{ margin: 'unset', marginTop: '2%' }} />
    </div>
    {props.week_schedules === undefined ? (
      <div style={{ display: 'flex', justifyContent: 'center' }} className="shifts-bar-chart-component">
        <Loader size = {30}/>
      </div>
    ) : props.week_schedules !== undefined ? (
      <ShiftsChart {...props} />
    ) : (
      <div className="no-record-warning">No Shifts found</div>
    )}
  </div>
);

export default ShiftsColumnChart;
