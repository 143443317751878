import React, { useState, useEffect } from 'react';
import { Translate, translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Button, Grid, FormControl, TextField, FormHelperText, FormControlLabel, Checkbox } from '@material-ui/core';
import { form } from 'availity-reactstrap-validation';
import { Row, Col } from 'reactstrap';
import { Link, RouteComponentProps } from 'react-router-dom';
import './password.scss';
import { IRootState } from 'app/shared/reducers';
import { getSession } from 'app/shared/reducers/authentication';
import PasswordStrengthBar from 'app/shared/layout/password/password-strength-bar';
import { savePassword, reset } from './password.reducer';
import { Storage } from 'react-jhipster';
import { Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { encryptData } from '../../../modules/login/encryption';

export interface UserDispatchProps {
  savePassword: (id, old, passw) => void;
  reset: () => void;
  getSession: () => void;
}

export interface IUserPasswordProps extends StateProps, UserDispatchProps, RouteComponentProps<{ url: string }> {}

export const PasswordPage = (props: IUserPasswordProps) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const [spaceError, setSpaceError] = useState(false);

  useEffect(() => {
    props.reset();
    props.getSession();
    return () => {
      props.reset();
    };
  }, []);

  const handleValidSubmit = event => {
    event.preventDefault();
    if (CheckPassword(event.target.newPassword.value) && error === false) {
      const currentPassword = encryptData(event.target.currentPassword.value);

      const newPassword = encryptData(event.target.newPassword.value);
      props.savePassword(Storage.local.get('user_id'), currentPassword, newPassword);
    }
  };

  const updatePassword = event => setPassword(event.target.value);

  const CheckPassword = value => {
    const passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,50}$/;
    const whitespaces = /^\S*$/;
    if (value.match(passw)) {
      if (value.match(whitespaces)) {
        setError(false);
        setSpaceError(false);
        return true;
      } else {
        setSpaceError(true);
        return false;
      }
    } else {
      setError(true);
      return false;
    }
  };

  return (
    <React.Fragment>
      <div className="password-main-container">
        <div className="password-full-container">
          <div style={{ display: 'flex' }}>
            <Paper elevation={2} square className="passwordContainerPaper">
              <div className="passwordTitleDiv">
                <Typography variant="h5" className="passwordTitle">
                  <h2 id="password-title">
                    {/* <Translate contentKey="password.title" interpolate={{ username: props.account.login }}> */}
                    <span style={{ color: '#4720b7' }}>Password for {props.account.login}</span>
                    {/* </Translate> */}
                  </h2>
                </Typography>
              </div>
              <form className="password-form" onSubmit={handleValidSubmit}>
                <Grid container item xs={12} md={12} lg={12} spacing={2}>
                  <FormControl className="password-fields">
                    <TextField
                      name="currentPassword"
                      variant="outlined"
                      className="password-currentpassword"
                      label={translate('global.form.currentpassword.label')}
                      placeholder={translate('global.form.currentpassword.placeholder')}
                      type="password"
                      required
                      data-cy="currentPassword"
                    />
                  </FormControl>
                </Grid>
                <Grid container item xs={12} md={12} lg={12} spacing={2}>
                  <FormControl className="password-fields">
                    <TextField
                      name="newPassword"
                      variant="outlined"
                      label={translate('global.form.newpassword.label')}
                      placeholder={translate('global.form.newpassword.placeholder')}
                      className="change_password_new_password_div"
                      type="password"
                      required
                      inputProps={{
                        minLength: 6,
                        maxLength: 50,
                      }}
                      onChange={updatePassword}
                      data-cy="newPassword"
                    />
                  </FormControl>
                </Grid>
                {error && (
                  <span className="invalid_feedback" style={{ color: '#dc3545', fontSize: 12 }}>
                    &nbsp; Your password is required to have upper case letter, lower case letter, number and a special character.{' '}
                  </span>
                )}
                {spaceError && (
                  <span className="invalid_feedback" style={{ color: '#dc3545', fontSize: 12 }}>
                    &nbsp; Your password can not contain spaces.&nbsp;
                  </span>
                )}
                <div className="password-fields">
                  {' '}
                  <PasswordStrengthBar password={password} />{' '}
                </div>
                <Grid container item xs={12} md={12} lg={12} spacing={2}>
                  <FormControl className="password-fields">
                    <TextField
                      name="confirmPassword"
                      className="password-confirmpassword"
                      variant="outlined"
                      label={translate('global.form.confirmpassword.label')}
                      placeholder={translate('global.form.confirmpassword.placeholder')}
                      type="password"
                      required
                      inputProps={{
                        minLength: 6,
                        maxLength: 50,
                        match: 'newPassword',
                      }}
                      data-cy="confirmPassword"
                    />
                  </FormControl>
                </Grid>
                <Grid container item xs={12} md={12} lg={12} spacing={2}>
                  <FormControl style={{ display: 'block' }} className="password-fields">
                    <Button className="passwordSaveButton" type="submit" data-cy="submit">
                      <Translate contentKey="password.form.button">Save</Translate>
                    </Button>
                  </FormControl>
                </Grid>
                <br />
              </form>
            </Paper>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ authentication }: IRootState) => ({
  account: authentication.account,
  isAuthenticated: authentication.isAuthenticated,
});

const mapDispatchToProps = { getSession, savePassword, reset };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PasswordPage);
