import React from 'react';
import { makeStyles, Avatar } from '@material-ui/core';
import { IRootState } from 'app/shared/reducers';
import Divider from '@material-ui/core/Divider';
import Modal from '@material-ui/core/Modal';
import { connect } from 'react-redux';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import CloseIcon from '@material-ui/icons/Close';
import '../../../content/css/calender.css';

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: '50%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '10%',
    left: '24%',
    overflow: 'auto',
    maxHeight: '71%',
  },
  checkBoxes: {
    backgroundColor: 'white important!',
    boxShadow: '1px 1px 1px #dfdbdb important!',
    border: '1px solid #cfcccc',
    width: '300px',
    '&:hover': {
      backgroundColor: '#d68fd9',
      color: 'white',
    },
  },
  paperUpdate: {
    position: 'absolute',
    width: '25%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '10%',
    left: '36%',
    overflow: 'auto',
  },
  shiftModalTitle: {
    textAlign: 'center',
    marginTop: '30px',
    marginBottom: '20px',
    color: '#4620B7',
  },
  modalForms: {
    marginBottom: theme.spacing(2),
    minWidth: 120,
    width: '100%',
  },
  modalLabel: {
    alignItems: 'center',
    display: 'flex',
  },
  modalButtonSave: {
    backgroundColor: '#4620b7',
    color: 'white',
    width: '95%',
  },
  modalButtonCancel: {
    backgroundColor: 'white',
    color: 'black',
    width: '95%',
  },
  modalNote: {
    width: '100%',
  },
  modalButton: {
    width: '35%',
  },
  modalInput: {
    width: '75%',
  },
  weekLists: {
    width: '100%',
    display: 'flex',
  },
  modalFormForAutoComplete: {
    marginTop: '0 !important',
  },
  listbox: {
    border: '1px solid #dfdbdb !important',
  },
}));

export interface IShiftHistoryModalComponent extends StateProps, DispatchProps {
  openShiftHistoryModal: boolean;
  shiftHistory: any;
  setShiftHistoryModalOpen: (argument) => void;
  heading: string;
}

export const ShiftHistoryModalComponent = (props: IShiftHistoryModalComponent) => {
  const classes = useStyles();

  return (
    <Modal open={props.openShiftHistoryModal} onClose={() => props.setShiftHistoryModalOpen(false)} className="shift-history-modal">
      <div>
        <div className={classes.paper}>
          <div className="shift-history-modal-close-button">
            <CloseIcon className="shift-history-modal-close-icon" fontSize="small" onClick={() => props.setShiftHistoryModalOpen(false)} />
          </div>
          <h2 id="shift-modal-title" className={classes.shiftModalTitle}>
            {props.heading}
          </h2>
          {props.shiftHistory.length > 0 ? (
            props.shiftHistory.map((data, i) => (
              <div key={i} className="shift-history-modal-component-outer-div">
                <div className="shift-history-modal-created-at-div">
                  <span style={{ float: 'left' }}>{data.date}</span>
                  <span style={{ marginLeft: '60%', width: '50%', color: 'rgb(122 114 114)' }}>{data.time}</span>
                </div>
                <div className="shift-history-image">
                  <div className="shift-history-modal-image-div">
                    {data.image ? (
                      <Avatar style={{ width: '60px', height: '60px' }} src={data.image} alt="img"></Avatar>
                    ) : (
                      <Avatar style={{ width: '60px', height: '60px' }}>{data.action_performed_by.charAt(0)}</Avatar>
                    )}
                    <span className="shift-history-image-vertical-line"></span>
                  </div>
                </div>
                <div className="shift-history-modal-created-by">
                  <span style={{ fontWeight: 'bold' }}>{data.action_performed_by}</span>
                  <span key={i} style={{ color: 'rgb(122 114 114)', fontSize: '12px', fontStyle: 'italic' }}>
                    {data.role}
                  </span>
                  <div className="shift-history-modal-action">
                    <span style={{ width: 'fit-content', fontSize: '13px', marginRight: '10px' }}>{data.action_performed}</span>
                    {data.previous_value ? (
                      <>
                        <span className="shift-history-previous-value">{data.previous_value}</span>
                        <span>
                          <ArrowRightAltIcon />
                        </span>
                      </>
                    ) : null}
                    {data.updated_value ? <span className="shift-history-updated-value"> {data.updated_value} </span> : null}
                  </div>
                  <Divider />
                </div>
              </div>
            ))
          ) : (
            <div style={{ width: '18vh', padding: '2vh', background: 'lightyellow' }}>No Record Found</div>
          )}
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = ({ calendar, PersonalSettings }: IRootState) => ({
  shiftHistory: calendar.shiftEditHistory,
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ShiftHistoryModalComponent));
