import React, { useEffect, useState } from 'react';
import DrawerComponent from './SettingsComponents/DrawerComponent';
import PersonRolePermissionSettings from './RolesAndPermissionsSettings/RolesAndPermissionsSettings';
import './index.scss';
import GeneralSettings from './generalSettings';
import NotificationSettings from './notification-setting';
import PeopleQualification from './PeopleSettings/PeopleQualification/PeopleQualification';
import PeopleHealth from './PeopleSettings/PeopleHealth/PeopleHealth';
import PeoplePositions from './PeopleSettings/PeoplePositions/PeoplePositions';
import PeopleEmployment from './PeopleSettings/PeopleEmployment/PeopleEmployment';
import PeopleGeneral from './PeopleSettings/PeopleGeneral/PeopleGeneral';

const Settings = props => {
  const [page, setPage] = useState(1);

  return (
    <div className="settings-view-deisgns-main-container" style={{ padding: '32px' }}>
      <div className="settings-outer-div">
        <div className="settings-drawer-container">
          <DrawerComponent setPage={setPage} page={page} />
        </div>
        <div className="settings-container-body">
          {page === 1 && <GeneralSettings {...props} />}
          {page === 2 && <NotificationSettings {...props} />}
          {page === 3 && <PersonRolePermissionSettings />}
          {page === 5 && <PeopleQualification {...props} />}
          {page === 6 && <PeopleHealth {...props} />}
          {page === 7 && <PeoplePositions {...props} />}
          {page === 8 && <PeopleEmployment {...props} />}
          {page === 9 && <PeopleGeneral {...props} />}
        </div>
      </div>
    </div>
  );
};

export default Settings;
