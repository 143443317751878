import { exportPDF } from './exportPDF';
import moment from 'moment';

const header = [
  'Shift Date/ Time',
  'Assignee Name',
  'Position',
  'Service Area',
  'Start - End',
  'Patrols Completed',
  'Patrols Missed',
  'Checkpoints Completed',
  'Checkpoints Missed',
  'Pictures Uploaded',
  'Any Note Received',
];

export const convertToPDF = (typeFlag, jsonData, selectedDateFrom, selectedDateTo) => {
  const DateFrom = moment(selectedDateFrom).format('DD-MM-YYYY');
  const DateTo = moment(selectedDateTo).format('DD-MM-YYYY');
  const headers = [header];
  const title = 'Patrol Report';
  const data = jsonData.map(row => [
    row.start_time,
    row.assignee_name,
    row.position,
    row.service_area,
    row.start_end,
    row.patrols_completed,
    row.patrols_missed,
    row.checkpoints_completed,
    row.checkpoints_missed,
    row.pictures_uploaded,
    row.any_note_received,
  ]);
  const filename = typeFlag === true ? 'Patrol Report.pdf' : 'Patrol Report Detailed.pdf';

  exportPDF(DateFrom, DateTo, headers, title, data, filename);
};
