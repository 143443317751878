import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import IncidentUpdate from './incident-update';
import IncidentDetails from './incident-detail';
import Incident from './incident';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={IncidentUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={IncidentUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={IncidentDetails} />
      <ErrorBoundaryRoute path={match.url} component={Incident} />
    </Switch>
  </>
);

export default Routes;
