import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity, deleteEntity } from './organization.reducer';

export interface IOrganizationDeleteDialogProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const OrganizationDeleteDialog = (props: IOrganizationDeleteDialogProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const handleClose = () => {
    props.history.push('/customer');
  };

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const confirmDelete = () => {
    props.deleteEntity(props.organizationEntity.id);
  };

  const { organizationEntity } = props;
  return (
    <Dialog open onClose={handleClose} className="DeleteBox">
      <DialogTitle data-cy="organizationDeleteDialogHeading">
        Confirm delete operation
        <IconButton aria-label="close" onClick={handleClose} style={{ right: '2px', top: '6px', color: 'black', position: 'absolute' }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent id="demoApp.organization.delete.question">
        Are you sure you want to delete this Organization {props.organizationEntity.id}?
      </DialogContent>
      <DialogActions>
        <Button style={{ color: 'white', backgroundColor: '#14a37f' }} onClick={handleClose}>
          <FontAwesomeIcon icon="ban" />
          &nbsp; Cancel
        </Button>
        <Button
          id="jhi-confirm-delete-organization"
          data-cy="entityConfirmDeleteButton"
          style={{ color: 'white', backgroundColor: '#b71c1c' }}
          onClick={confirmDelete}
        >
          <FontAwesomeIcon icon="trash" />
          &nbsp; Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = ({ organization }: IRootState) => ({
  organizationEntity: organization.entity,
  updateSuccess: organization.updateSuccess,
});

const mapDispatchToProps = { getEntity, deleteEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationDeleteDialog);
