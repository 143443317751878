import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { IOrganization, defaultValue } from 'app/shared/model/organization.model';
import { ICity } from 'app/shared/model/city.model';
import { ICompany } from 'app/shared/model/company.model';
import { ToastContainer, toast } from 'react-toastify';
import { IState } from 'app/shared/model/state.model';

export const ACTION_TYPES = {
  FETCH_DESIGNATIONS_LIST: 'designations/GET_DESIGNATIONS_LIST',
  FETCH_ORGANIZATION_LIST: 'organization/FETCH_ORGANIZATION_LIST',
  FETCH_ORGANIZATION: 'organization/FETCH_ORGANIZATION',
  CREATE_ORGANIZATION: 'organization/CREATE_ORGANIZATION',
  UPDATE_ORGANIZATION: 'organization/UPDATE_ORGANIZATION',
  PARTIAL_UPDATE_ORGANIZATION: 'organization/PARTIAL_UPDATE_ORGANIZATION',
  DELETE_ORGANIZATION: 'organization/DELETE_ORGANIZATION',
  RESET: 'organization/RESET',
  UPLOAD_AVATAR_SETTINGS: 'organization/UPLOAD_AVATAR_SETTINGS',
  GET_STATE: 'organization/GET_STATE',
  GET_CITY: 'organization/GET_CITY',
  ADD_IMAGE: 'organization/ADD_IMAGE',
  SET_PAGE_NUMBER: 'organization/SET_PAGE_NUMBER',
};

const initialState = {
  loading: false,
  errorMessage: null,
  designations: [],
  entities: [] as ReadonlyArray<IOrganization>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  uploadedAvatar: '',
  StateEntity: [] as Array<IState>,
  CityEntity: [] as Array<ICity>,
  pageNumber: 1,
};

export type OrganizationState = Readonly<typeof initialState>;

// Reducer

export default (state: OrganizationState = initialState, action): OrganizationState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_ORGANIZATION_LIST):
    case REQUEST(ACTION_TYPES.FETCH_ORGANIZATION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_ORGANIZATION):
    case REQUEST(ACTION_TYPES.UPDATE_ORGANIZATION):
    case REQUEST(ACTION_TYPES.DELETE_ORGANIZATION):
    case REQUEST(ACTION_TYPES.PARTIAL_UPDATE_ORGANIZATION):
    case REQUEST(ACTION_TYPES.FETCH_DESIGNATIONS_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case REQUEST(ACTION_TYPES.GET_STATE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
      };
    case REQUEST(ACTION_TYPES.GET_CITY):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
      };
    case REQUEST(ACTION_TYPES.ADD_IMAGE):
      return {
        ...state,
        errorMessage: null,
      };
    case FAILURE(ACTION_TYPES.FETCH_ORGANIZATION_LIST):
    case FAILURE(ACTION_TYPES.FETCH_ORGANIZATION):
    case FAILURE(ACTION_TYPES.CREATE_ORGANIZATION):
    case FAILURE(ACTION_TYPES.UPDATE_ORGANIZATION):
    case FAILURE(ACTION_TYPES.PARTIAL_UPDATE_ORGANIZATION):
    case FAILURE(ACTION_TYPES.DELETE_ORGANIZATION):
    case FAILURE(ACTION_TYPES.GET_STATE):
    case FAILURE(ACTION_TYPES.GET_CITY):
    case FAILURE(ACTION_TYPES.FETCH_DESIGNATIONS_LIST):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case FAILURE(ACTION_TYPES.ADD_IMAGE):
      return {
        ...state,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_DESIGNATIONS_LIST):
      return {
        ...state,
        loading: false,
        designations: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_ORGANIZATION_LIST):
      return {
        ...state,
        loading: false,
        entities: [...state.entities, ...action.payload.data],
      };
    case SUCCESS(ACTION_TYPES.FETCH_ORGANIZATION):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.GET_STATE):
      return {
        ...state,
        loading: false,
        StateEntity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.GET_CITY):
      return {
        ...state,
        loading: false,
        CityEntity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_ORGANIZATION):
    case SUCCESS(ACTION_TYPES.UPDATE_ORGANIZATION):
    case SUCCESS(ACTION_TYPES.PARTIAL_UPDATE_ORGANIZATION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_ORGANIZATION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case SUCCESS(ACTION_TYPES.UPLOAD_AVATAR_SETTINGS):
      return {
        ...state,
        uploadedAvatar: action.payload,
      };
    case ACTION_TYPES.SET_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const imageApiUrl = 'api/uploadGeneralSettingsImage';
const apiUrl = 'api/organization';
const apiDesignationsUrl = 'api/designations';
// Actions

export const getEntities = pageNo => ({
  type: ACTION_TYPES.FETCH_ORGANIZATION_LIST,
  payload: axios.get<IOrganization>(`${apiUrl}?cacheBuster=${new Date().getTime()}&page=${pageNo}`),
});

export const getEntity: ICrudGetAction<IOrganization> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_ORGANIZATION,
    payload: axios.get<IOrganization>(requestUrl),
  };
};

export const createEntity = (entity, files) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_ORGANIZATION,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  if (!!files.entries().next().value === true) {
    dispatch(img(files, result));
  }
  toast.success('Customer Created Successfully');
  return result;
};

const apiUrlState = 'api/getStateByCountryId';
export const getState: ICrudGetAction<IState> = id => {
  const requestUrl = `${apiUrlState}/${id}`;
  return {
    type: ACTION_TYPES.GET_STATE,
    payload: axios.get<IState>(requestUrl),
  };
};

const apiUrlCity = 'api/getCityByStateId';
export const getCityByState: ICrudGetAction<ICity> = id => {
  const requestUrl = `${apiUrlCity}/${id}`;
  return {
    type: ACTION_TYPES.GET_CITY,
    payload: axios.get<ICity>(requestUrl),
  };
};

export const updateEntity = (entity, files) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_ORGANIZATION,
    payload: axios.put(`${apiUrl}/${entity.id}`, cleanEntity(entity)),
  });
  if (!!files.entries().next().value === true) {
    dispatch(img(files, result));
  }
  toast.success('Customer Updated Successfully');
  return result;
};

export const img = (files, res) => async dispatch => {
  files.append('id', res.value.data.id);
  const result = await dispatch({
    type: ACTION_TYPES.ADD_IMAGE,
    payload: axios.post(imageApiUrl, files, { headers: { 'Content-Type': 'multipart/form-data' } }),
  });
  return result;
};

export const partialUpdate: ICrudPutAction<IOrganization> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.PARTIAL_UPDATE_ORGANIZATION,
    payload: axios.patch(`${apiUrl}/${entity.id}`, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IOrganization> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_ORGANIZATION,
    payload: axios.delete(requestUrl),
  });
  dispatch(reset());
  dispatch(setPageNumber({ pageNumber: 1 }));
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

export const getDesignations = () => ({
  type: ACTION_TYPES.FETCH_DESIGNATIONS_LIST,
  payload: axios.get(`${apiDesignationsUrl}?cacheBuster=${new Date().getTime()}`),
});

export const setPageNumber: ICrudPutAction<{ pageNumber: number }> = pageNo => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.SET_PAGE_NUMBER,
    payload: pageNo,
  });
  dispatch(getEntities(pageNo['pageNumber']));
  return result;
};
