import moment from 'moment';
import { exportPDF } from '../../entities/timesheet-summary/exportPDF';

const header = ['Date/ Time', 'Assignee Name', 'Sign In', 'Sign Out', 'Break S', 'Break E', 'Total Work Hours', 'Total Break Time'];

export const convertToPDF = (jsonData, selectedDateFrom, selectedDateTo) => {
  const DateFrom = selectedDateFrom === undefined ? '' : moment(selectedDateFrom).format('DD-MM-YYYY');
  const DateTo = selectedDateTo === undefined ? '' : moment(selectedDateTo).format('DD-MM-YYYY');
  const headers = [header];
  const title = 'Real Time Report';
  const data = jsonData.map(row => [
    row.date + '\n' + row.shiftStartTime + '\n' + row.shiftEndTime,
    row.person && row.person.first_name + ' ' + row.person.last_name,
    row.checkIn === '' ? null : row.checkIn,
    row.checkOut === '' ? null : row.checkOut,
    row.breakStart === '' ? null : row.breakStart,
    row.breakEnd === '' ? null : row.breakEnd,
    row.hours,
    row.total_break_time,
  ]);
  const filename = 'Real Time Report.pdf';

  exportPDF(DateFrom, DateTo, headers, title, data, filename);
};
