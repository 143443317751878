import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Shift from './shift';
import ShiftDetail from './shift-detail';
import ShiftUpdate from './shift-update';
import ShiftDeleteDialog from './shift-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ShiftUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ShiftUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ShiftDetail} />
      <ErrorBoundaryRoute path={match.url} component={Shift} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={ShiftDeleteDialog} />
  </>
);

export default Routes;
