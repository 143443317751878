import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import Button from '@material-ui/core/Button';
import { ActionButtons } from '../actionButtons/actionButtons';
import '../../form.scss';
import { storeApplicationForm } from '../../../applicationForm.reducer';
import { CloudUpload, ArrowDownwardSharp } from '@material-ui/icons';
import { IconButton, Modal, Paper, Table } from '@material-ui/core';
import '../../../../../entities/incident/incident.scss';
import { brown } from '@material-ui/core/colors';
import { setDate } from 'date-fns';
import { values } from 'lodash';

interface Props {
  personalInfo: any;
  storeRequiredDocuments: (data: any) => void;
  updateEntity: (data: any) => void;
}

export const DocumentsRequired: React.FC<Props> = props => {
  const { documents, supporting_document } = props['employeeFormData'];
  const isEdit = props['editEmployeeForm'];
  const [fileName, setFilesName] = useState({
    Upload_Photo: 'No file chosen',
    proof_of_address: 'No file chosen',
    proof_of_id: 'No file chosen',
    p46_form: 'No file chosen',
    Download_P46: '',
    browse_file: 'browse-file',
    right_to_work: 'No file chosen',
  });
  const [files, setFiles] = useState([]);
  const [personId, setPersonId] = useState(props.personalInfo.id);
  const [userId, setUserId] = useState(props.personalInfo.user_id);
  const [oldState, setOldState] = useState(props.personalInfo.vetting_status);
  const [browseFiles, setBrowseFiles] = useState([]);
  const [fieldError, setFieldError] = useState({
    Upload_Photo: false,
    proof_of_address: false,
    proof_of_id: false,
    p46_form: false,
    Download_P46: false,
    browse_file: false,
  });

  useEffect(() => {
    setPersonId(props.personalInfo.id);
    setOldState(props.personalInfo?.vetting_status || 'NewApplicant');
    setUserId(props.personalInfo.user_id);
    if (isEdit && documents) {
      const makeEditFilesCopy = files;
      documents.p46_form && makeEditFilesCopy.push({ file_name: 'p46_form', file: documents.p46_form['doc_link'] });
      documents.proof_of_id && makeEditFilesCopy.push({ file_name: 'proof_of_id', file: documents.proof_of_id['doc_link'] });
      documents.proof_of_address && makeEditFilesCopy.push({ file_name: 'proof_of_address', file: documents.proof_of_address['doc_link'] });
      documents['upload_photo_name'] && makeEditFilesCopy.push({ file_name: 'Upload_Photo', file: documents['upload_photo_name'] });
      documents.right_to_work && makeEditFilesCopy.push({ file_name: 'right_to_work', file: documents.right_to_work['doc_link'] });
      setFiles(makeEditFilesCopy);

      setFilesName({
        Upload_Photo: documents['upload_photo_name'] ? documents['upload_photo_name'].split('/') : 'No file chosen',
        proof_of_address: documents['proof_of_address_name'] ? documents['proof_of_address_name'] : 'No file chosen',
        proof_of_id: documents['proof_of_id_name'] ? documents['proof_of_id_name'] : 'No file chosen',
        p46_form: documents['p46_form_name'] ? documents['p46_form_name'].split('/') : 'No file chosen',
        Download_P46: '',
        browse_file: 'browse-file',
        right_to_work : documents['right_to_work_name'] ? documents['right_to_work_name'] : 'No file chosen',
      });
    }
  }, [props['employeeFormData']]);

  useEffect(() => {
    if (isEdit && supporting_document && supporting_document.length > 0) {
      const makeUploadedFilesCopy = browseFiles;
      supporting_document.map((item, index) => {
        if (item['doc_link']) {
          makeUploadedFilesCopy.push({ file_name: item['doc_link'].split('/')[1], file: item['doc_link'] });
        }
      });
      setBrowseFiles(makeUploadedFilesCopy);
    }
  }, [props['employeeFormData']]);

  const handleFilesData = (event, name) => {
    if (name && event.target.files[0].name) {
      setFieldError(oldValues => ({ ...oldValues, [event.target.name]: false }));
      const makeFilesCopy = files;
      makeFilesCopy.push({ file_name: name, file: event.target.files[0] });
      setFiles(makeFilesCopy);
      setFilesName(oldValues => ({ ...oldValues, [name]: event.target.files[0].name }));
    }
  };
  const handleBrowsedFilesData = event => {
    setFieldError(oldValues => ({ ...oldValues, [event.target.name]: false }));
    const makeFilesCopy = browseFiles;
    makeFilesCopy.push({ file_name: event.target.files[0].name, file: event.target.files[0] });
    setBrowseFiles(makeFilesCopy);
    setFilesName(oldValues => ({ ...oldValues, browse_file: event.target.files[0].name }));
  };

  const submit = () => {
    checkValidationsBeforeSubmit();
    if (
      !fieldError['Upload_Photo'] &&
      !fieldError['proof_of_address'] &&
      !fieldError['proof_of_id'] &&
      !fieldError['p46_form'] &&
      // !fieldError['browse-file'] &&
      fileName['Upload_Photo'] !== 'Upload_Photo' &&
      fileName['proof_of_address'] !== 'proof_of_address' &&
      fileName['proof_of_id'] !== 'proof_of_id' &&
      fileName['p46_form'] !== 'p46_form'
    ) {
      const object = new FormData();
      files.forEach(element => {
        object.append(element.file_name, element.file);
      });
      browseFiles.forEach(element => {
        object.append('supporting_document[]', element.file);
      });
      object.append('person_id', personId);
      props.storeRequiredDocuments(object);
    }
  };

  const checkValidationsBeforeSubmit = () => {
    if (fileName['Upload_Photo'] === 'No file chosen') {
      setFieldError(oldValues => ({ ...oldValues, ['Upload_Photo']: true }));
    }
    if (fileName['proof_of_address'] === 'No file chosen') {
      setFieldError(oldValues => ({ ...oldValues, ['proof_of_address']: true }));
    }
    if (fileName['proof_of_id'] === 'No file chosen') {
      setFieldError(oldValues => ({ ...oldValues, ['proof_of_id']: true }));
    }
    if (fileName['p46_form'] === 'No file chosen') {
      setFieldError(oldValues => ({ ...oldValues, ['p46_form']: true }));
    }
    if (fileName['browse-file'] === 'No file chosen') {
      setFieldError(oldValues => ({ ...oldValues, ['browse-file']: true }));
    }
  };

  const FILE_URL = fileName['Download_P46'];
  const downloadFileURL = () => {
    fetch(`../../../../../../content/pdfFile/p46.pdf`).then(response => {
      response.blob().then(blob => {
        const fileURL = window.URL.createObjectURL(blob);
        const name = 'P46.pdf';
        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = name;
        alink.click();
      });
    });
  };
  const handleDelete = i => {
    setBrowseFiles([...browseFiles.slice(0, i), ...browseFiles.slice(i + 1, browseFiles.length)]);
  };

  return (
    <fieldset className="field-set">
      <div className="job-application-form-sections-top-heading">Please upload all required documents mentioned below:</div>
      <div className="form-group">
        <div className="upload-documents-row">
          <div id="documents_section">
            <label className="col form-label" style={{ color: 'red' }}>
            *Max size: 5MB I Format allowed: jpg/ png/ doc/ pdf only
            </label>
            <div id="button-div-col">
              <div className="button-div-col-1">
                <div className="col">
                  <input type="hidden" name="person_id" value={personId} />
                  <input type="file" name="Upload_Photo" onChange={() => handleFilesData(event, 'Upload_Photo')} id="actual-btn1" />
                  <div className="col">
                    <label className="file-btn" htmlFor="actual-btn1">
                      <img style={{ padding: '4px' }} src="content/images/upload-cloud.png" />
                      Upload Photo
                    </label>
                    <br /><br />
                    <span id="file-chosen1" className="browse-file-name" style={{ color: fieldError['Upload_Photo'] ? 'red' : 'black' }}>
                      {fileName.Upload_Photo}
                    </span>
                  </div>
                </div>

                <div className="col proof_of_address">
                  <input type="file" name="proof_of_address" onChange={() => handleFilesData(event, 'proof_of_address')} id="actual-btn3" />
                  <div className="col">
                    <label style={{ paddingLeft: '18px' }} className="file-btn3" htmlFor="actual-btn3">
                      <img style={{ padding: '4px' }} src="content/images/upload-cloud.png" />
                      Proof of Address
                    </label>
                    <br /><br />
                    <span
                      id="file-chosen3"
                      className="browse-file-name"
                      style={{ color: fieldError['proof_of_address'] ? 'red' : 'black' }}
                    >
                      {fileName.proof_of_address}
                    </span>
                  </div>
                </div>

                <div className="col right_to_work">
                <input type="hidden" name="person_id" value={personId} />
                  <input type="file" name="right_to_work" onChange={() => handleFilesData(event, 'right_to_work')} id="actual-btn6" />
                  <div className="col">
                    <label style={{ paddingLeft: '18px' }} className="file-btn3" htmlFor="actual-btn6">
                      <img style={{ padding: '4px' }} src="content/images/upload-cloud.png" />
                      Right to Work
                    </label>
                    <br />
                    <span
                      id="file-chosen9"
                      className="browse-file-name"
                      style={{ color: fieldError['right_to_work'] ? 'red' : 'black' }}
                    >
                      {fileName.right_to_work}
                    </span>
                  </div>
                </div>

              </div>

              <div className="button-div-col-2">
                <div className="col proof_of_id">
                  <input type="file"  name="proof_of_id" onChange={() => handleFilesData(event, 'proof_of_id')} id="actual-btn2" />
                  <div className="col">
                    <label className="file-btn" htmlFor="actual-btn2">
                      <img style={{ padding: '4px' }} src="content/images/upload-cloud.png" />
                      Proof of Id
                    </label>
                    <br /><br />
                    <span id="file-chosen2" className="browse-file-name" style={{ color: fieldError['proof_of_id'] ? 'red' : 'black' }}>
                      {fileName.proof_of_id}
                    </span>
                  </div>
                </div>

                <div className="col proof_of_id">
                  <div>
                    <input
                      type="file"
                      className="p46_form_download"
                      name="p46_form"
                      onChange={() => handleFilesData(event, 'p46_form')}
                      id="actual-btn4"
                      multiple
                    />
                    <div className="col">
                      <label className="file-btn" htmlFor="actual-btn4">
                        <img style={{ padding: '4px' }} src="content/images/upload-cloud.png" />
                        P46 Form
                      </label>
                      <br /><br />
                      <span id="file-chosen2" style={{ color: fieldError['p46_form'] ? 'red' : 'black' }}>
                        {fileName.p46_form}
                      </span>
                    </div>
                  </div>
                  <div className="col proof_of_id">
                    <div className="col-download">
                      <span
                        id="file-chosen2"
                        onChange={() => handleFilesData(event, 'Download_P46')}
                        style={{ color: fieldError['Download_P46'] ? 'red' : 'black' }}
                      >
                        {/* {fileName.Download_P65} */}

                        <span className="download" onClick={downloadFileURL}>
                          DownloadP46 Form <img style={{ marginLeft: '5px' }} src="content/images/download.png" />{' '}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="documents-group">
          <div className="browse-doc col proof_of_id">
            <div className="add-documents">
              <span>Please add more documents here</span>
            </div>
            <div>
              <input type="file" name="Browse" className="browse-button" onChange={handleBrowsedFilesData} id="actual-btn5" />
              <div className="browser_col">
                <label className="browse-btn" htmlFor="actual-btn5">
                  <CloudUpload style={{ marginRight: '4px', color: 'color: white !important' }} />
                  Browse
                </label>
                {/* <span id="file-chosen5" style={{ color: fieldError['browse_file'] ? 'red' : 'black' }}>
                    {fileName.browse_file}
                    </span> */}
              </div>
            </div>
          </div>

          <div className="incident-report-details-div" style={{ height: '100%', marginLeft: '10px' }}>
            <div className="subcontractor-view-documents-table-div">
              <div className="table-responsive">
                {browseFiles && browseFiles.length > 0 ? (
                  <Table>
                    <thead className="globalTableHeaderGradientColor">
                      <tr className="documentsTableHeaderRow">
                        <th
                          style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginTop: '10px'}}
                          className="subcontractor-documentsTableHeaderCell  subcontractorHeaderDocumentCell"
                        >
                          Document
                        </th>
                        <th className="subcontractor-documentsTableHeaderCell subcontractorHeaderActionsCell">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {browseFiles
                        ? browseFiles.map((docs, i) => (
                            <tr key={`entity-${i}`} data-cy="entityTable" className="documentsTableBodyRows">
                              <td
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignContent: 'center',
                                  alignSelf: 'center',
                                  alignItems: 'center',
                                  height: '76px',
                                  width: '100%',
                                }}
                                className="document-documentsTableBodyCell documentTableBodyDocumentcell"
                              >
                                <dd>{`${docs['file_name']}-${i + 1}`}</dd>
                              </td>

                              <td
                                style={{ height: '40px' }}
                                className="text-right subcontractor-documentsTableBodyCell documentTableBodyActioncell"
                              >
                                <div className="btn-group serviceAreaActionCellButtons">
                                  <IconButton style={{ margin: '1%' }} data-cy="entityDeleteButton" onClick={() => handleDelete(i)}>
                                    <img src="content/images/Trash5.png" width="26px" height="30px" />
                                  </IconButton>
                                </div>
                              </td>
                            </tr>
                          ))
                        : null}
                    </tbody>
                  </Table>
                ) : null}
              </div>
              <br />
              <ActionButtons backButton={true} buttonText={false} submit={submit} />
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  );
};
