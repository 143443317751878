import React from 'react';
// import MenuItem from 'app/shared/layout/menus/menu-item';
// import TodayIcon from '@material-ui/icons/Today';
import { Translate, translate } from 'react-jhipster';
import { NewNavDropdown } from './menu-components';
import Item from './Item';
export const SchedulesMenuItems = () => (
  <div className="headerIconScheduler">
    <NewNavDropdown name="Operations" id="schedules-menu" data-cy="schedulesMenu">
      <span className="grid-containers1"></span>
      <div className="grid-container">
        <Item
          stylesProperty="calendar"
          link="/calendar"
          unFillImage="../../../../content/images/Calendar.png"
          fillImage="../../../../content/images/Calendarfill.png"
          name="Calendar"
        />

        <Item
          stylesProperty="realtime"
          link="/real-time"
          unFillImage="../../../../content/images/Real Time.png"
          fillImage="../../../../content/images/Real Timefill.png"
          name="Real Time"
        />

        <Item
          stylesProperty="incident"
          link="/incident-management"
          unFillImage="../../../../content/images/Incident Mgmt.png"
          fillImage="../../../../content/images/Incident Mgmt.fill.png"
          name="Incident Management"
        />

        <Item
          stylesProperty="rota"
          link="/roster"
          unFillImage="../../../../content/images/Rota.png"
          fillImage="../../../../content/images/Rotafill.png"
          name="Rota"
        />

        <Item
          stylesProperty="deletedshifts"
          link="/deleted-shifts"
          unFillImage="../../../../content/images/Deleted Shifts.png"
          fillImage="../../../../content/images/Deleted Shiftsfill.png"
          name="Deleted Shifts"
        />

        <Item
          stylesProperty="automated"
          link="/automated-control-room"
          unFillImage="../../../../content/images/Automated Control Room.png"
          fillImage="../../../../content/images/Automated Control Roomfill.png"
          name="Automated Control Room"
        />
      </div>
    </NewNavDropdown>
    <img className="icon" src="../../../../content/images/angle-right.png" alt="dropdown-icon" />
  </div>
);

export default SchedulesMenuItems;
