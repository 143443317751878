import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import '../../form.scss';
import { ArrowForwardIos } from '@material-ui/icons';

interface Props {
  backButton: boolean;
  buttonText: boolean;
  action?: (tag: string, activeModule: number) => void;
  submit?: () => void;
  employeeHistory?: boolean;
  handleEmpHist?: () => boolean;
}

export const ActionButtons: React.FC<Props> = ({ backButton, buttonText, action, submit, employeeHistory = false, handleEmpHist }) => {
  return (
    <div className="action_btn">
      <div className="btn_save">
        {buttonText ? (
          <Button variant="outlined" id="save_button" className="custom_save save_and_continue" onClick={submit}>
            Save & Continue
            <ArrowForwardIos style={{ color: 'black', border: '2px solid #FFFFFF', width: '20px', height: '35px' }} />
          </Button>
        ) : (
          <Button variant="outlined" id="save_button_submit" className="custom_save" onClick={() => submit()}>
            Submit Application
          </Button>
        )}
      </div>
    </div>
  );
};
