import { useTable, useExpanded } from 'react-table';
import React, { useState, useEffect } from 'react';
import './timesheetSummary.scss';
import '../../../utils/GlobalCssRequiredInWebsite.scss';
import styled from 'styled-components';
import { CurrencyChecker } from '../../../utils/TimeFormatAndTimezone';
import '../entities-reports.scss';

export const Styles = styled.div`
  padding: 1rem;

  table {
    width: 100%;

    th,
    td {
      padding: 0.5rem;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

function SubRows({ row, rowProps, data, loading, currency }) {
  const currencySign = CurrencyChecker(currency);
  return (
    <>
      {data && data.length > 0
        ? data.map((x, i) => {
            return (
              <tr {...rowProps} key={`${rowProps.key}-expanded-${i}`} className="entities_Reports_React_Table_InnerRow">
                {row.cells.map((cell, i) => {
                  return (
                    <td {...cell.getCellProps()} key={i} className="entities_Reports_React_Table_InnerRow_Cell">
                      {cell.render('Header') === 'Client Rate' ? (cell.value === '-' || cell.value === null ? '' : currencySign) : ''}
                      {cell.render('Header') === 'Staff Rate' ? (cell.value === '-' || cell.value === null ? '' : currencySign) : ''}
                      {cell.render('Header') === 'Estimated Wage' ? (cell.value === '-' || cell.value === null ? '' : currencySign) : ''}
                      {cell.render('Header') === 'Actual Wage' ? (cell.value === '-' || cell.value === null ? '' : currencySign) : ''}
                      {cell.render('Header') === 'Cost' ? (cell.value === '-' || cell.value === null ? '' : currencySign) : ''}
                      {cell.render('Header') === 'Profit' ? (cell.value === '-' || cell.value === null ? '' : currencySign) : ''}
                      {cell.render(cell.column.SubCell ? 'SubCell' : 'Cell', {
                        value: cell.column.accessor && cell.column.accessor(x, i),
                        row: { ...row, original: x },
                      })}
                    </td>
                  );
                })}
              </tr>
            );
          })
        : !loading && <div className="no-record-warning">No Records found</div>}
    </>
  );
}

export function SubRowAsync({ row, rowProps, loading, currency }) {
  const [data, setData] = React.useState([]);
  const detailData = [];

  row.original.PAYLOAD.map(val => {
    const data = { ...val };
    detailData.push(data);
  });

  React.useEffect(() => {
    setData(detailData);
  }, []);

  return <SubRows row={row} rowProps={rowProps} data={data} loading={loading} currency={currency} />;
}

export function Table({ columns: userColumns, data, renderRowSubComponent, timesheetSummaryList, currency, loading }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { expanded },
  } = useTable(
    {
      columns: userColumns,
      data,
    },
    useExpanded
  );
  const currencySign = CurrencyChecker(currency);
  return (
    <>
      <div className="globalTableHeaderGradientColor clientTableHeaderRow entities_Reports_React_Table_Header sticky_div">
        <div className="subcontractorTableHeaderCell timeCardHeader" style={{ border: 'none' }}>
          Name
        </div>
        <div className="subcontractorTableHeaderCell timeCardHeaderExpand"></div>
        <div className="subcontractorTableHeaderCell timeCardHeader">Date/Time</div>
        <div className="subcontractorTableHeaderCell timeCardHeader">Service Area</div>
        <div className="subcontractorTableHeaderCell timeCardHeader">Position</div>
        <div className="subcontractorTableHeaderCell timeCardHeader">Sign-In - Sign-0ut</div>
        <div className="subcontractorTableHeaderCell timeCardHeader">Working Hours</div>
        <div className="subcontractorTableHeaderCell timeCardHeader">Rate</div>
        <div className="subcontractorTableHeaderCell timeCardHeader">Total Wage</div>
      </div>
      <table {...getTableProps()} className="entities_Reports_React_Table">
        {/* <thead className="entities_Reports_React_Table_Header">
          {headerGroups && headerGroups.length > 0
            ? headerGroups.map((headerGroup, i) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                  {headerGroup.headers.map((column, i) => (
                    <th {...column.getHeaderProps()} key={i} className="entities_Reports_RTBHC">
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))
            : !loading && <div className="no-record-warning">No Header found</div>}
        </thead> */}
        <tbody {...getTableBodyProps()}>
          {rows && rows.length > 0
            ? rows.map((row, i) => {
                prepareRow(row);
                const rowProps = row.getRowProps();
                return (
                  <React.Fragment key={rowProps.key}>
                    <tr {...rowProps} key={i} className="entities_Reports_React_Table_OuterRow">
                      {row.cells.map((cell, i) => {
                        return (
                          <td {...cell.getCellProps()} key={i} className="entities_Reports_RT_Body_OuterRow_Cell">
                            {cell.render('Header') === 'Client Rate' ? (cell.value === '-' || cell.value === null ? '' : currencySign) : ''}
                            {cell.render('Header') === 'Staff Rate' ? (cell.value === '-' || cell.value === null ? '' : currencySign) : ''}
                            {cell.render('Header') === 'Estimated Wage'
                              ? cell.value === '-' || cell.value === null
                                ? ''
                                : currencySign
                              : ''}
                            {cell.render('Header') === 'Actual Wage' ? (cell.value === '-' || cell.value === null ? '' : currencySign) : ''}
                            {cell.render('Header') === 'Cost' ? (cell.value === '-' || cell.value === null ? '' : currencySign) : ''}
                            {cell.render('Header') === 'Profit' ? (cell.value === '-' || cell.value === null ? '' : currencySign) : ''}
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                    {row.isExpanded && renderRowSubComponent({ row, rowProps, loading, currency })}
                  </React.Fragment>
                );
              })
            : !loading && (
                <tr style={{ borderBottom: 'unset' }}>
                  <div className="no-record-warning">No Records found</div>
                </tr>
              )}
        </tbody>
        <br />
        <br />
      </table>
    </>
  );
}
