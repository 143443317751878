import { ExportPDF } from '../components/downloadPDFs/exportPDF';

export const serviceAreaConvertToPDF = data => {
  const Header = ['ID No', 'Service Area', 'Client Name', 'Address', 'Petrolling Enabled'];
  const header = [Header];
  const title = 'List of Service Areas';
  const serviceAreaJsonData = data.map(PDfRow => [
    PDfRow.identification_number,
    PDfRow.name,
    PDfRow.client.clientName,
    PDfRow.address.address_line_one,
    PDfRow.map || PDfRow.checkpoints ? 'Yes' : 'No',
  ]);
  const filename = 'List of Service Areas';
  ExportPDF(header, title, serviceAreaJsonData, filename);
};
