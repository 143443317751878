import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Grid, FormControl, TextField, makeStyles, Paper } from '@material-ui/core';
import './register.scss';
import { IRootState } from 'app/shared/reducers';
import { handleRegister, reset, getSelfRegisterUsers } from './register.reducer';
import { validationMessageOnChange } from '../../../../utils/validationMessages';
import PhoneInput from 'react-phone-input-2';
import { toast } from 'react-toastify';
import { encryptData } from '../../../modules/login/encryption';

export interface IRegisterProps extends StateProps, DispatchProps {
  history;
}

export const RegisterSignUp = (props: IRegisterProps) => {
  const [password, setPassword] = useState('');
  const [value, setValue] = useState({ staff_range: '1-50', personEmail: '' });
  const [fieldError, setFieldError] = useState({ name_error: false, name_helper: '' });
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    const url = window.location.href;
    const split_url = url.split('?');
    const id = split_url[1];
    props.getSelfRegisterUsers(id);
    setValue(oldValues => ({ ...oldValues, ['personEmail']: props.user_registered.email }));
  }, [props.user_registered.email]);

  const handleRegisterFormValues = e => {
    if (e.target.name !== null || e.target.name !== undefined) {
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: false }));
    }
    if (
      e.target.name === 'personFirstName' &&
      (!/^(?! )[a-zA-Z\s().-]*$/.test(e.target.value) || e.target.value === '' || e.target.value.length < 1)
    ) {
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: true }));
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_helper']: validationMessageOnChange.firstName }));
    } else if (
      e.target.name === 'personLastName' &&
      (!/^(?! )[a-zA-Z\s().-]*$/.test(e.target.value) || e.target.value === '' || e.target.value.length < 1)
    ) {
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: true }));
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_helper']: validationMessageOnChange.lastName }));
    } else if (e.target.name === 'confirm_password') {
      if (e.target.value !== value['password']) {
        setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: true }));
        setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_helper']: 'Password did not match' }));
      } else {
        setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: false }));
        setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_helper']: '' }));
      }
    } else {
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: false, [e.target.name + '_helper']: '' }));
    }
    setValue(oldValues => ({ ...oldValues, [e.target.name]: e.target.value }));
  };

  const handlePhoneNumber = phone => {
    setPhoneNumber(phone);
    setValue(oldFieldValues => ({ ...oldFieldValues, ['personPhone']: phone }));
  };

  const handleStaffRangeButton = tag => {
    if (tag === 'X') {
      setValue(oldValues => ({ ...oldValues, ['staff_range']: '1-50' }));
    } else if (tag === 'Y') {
      setValue(oldValues => ({ ...oldValues, ['staff_range']: '51-300' }));
    } else if (tag === 'Z') {
      setValue(oldValues => ({ ...oldValues, ['staff_range']: 'More than 300' }));
    }
  };

  const checkEmptyFields = () => {
    if (value['name_error'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['name_error']: true }));
    }
    if (value['personFirstName'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['personFirstName_error']: true }));
    }
    if (value['personLastName'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['personLastName_error']: true }));
    }
    if (value['password'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['password_error']: true }));
    }
    if (value['confirm_password'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['confirm_password_error']: true }));
    }
  };

  const checkFormValidations = () => {
    const result =
      !fieldError['name_error'] &&
      !fieldError['personFirstName_error'] &&
      !fieldError['personLastName_error'] &&
      !fieldError['password_error'] &&
      !fieldError['confirm_password_error'] &&
      value['name'] !== undefined &&
      value['personFirstName'] !== undefined &&
      value['personLastName'] !== undefined &&
      value['password'] !== undefined &&
      value['confirm_password'] !== undefined;
    return result;
  };

  const submitRegisterForm = async () => {
    setValue(oldValues => ({ ...oldValues, ['personEmail']: props.user_registered.email }));
    if (checkFormValidations()) {
      value['password'] = encryptData(value['password']);
      value['confirm_password'] = encryptData(value['confirm_password']);
      const result = await handleRegister(value);
      if (result.status === 201) {
        toast.success('Registration Successfully Completed !');
        window.location.href = 'https://smartworkforce.co.uk/registration-successful/';
      } else {
        toast.error('Registration Failed');
      }
    } else {
      checkEmptyFields();
    }
  };

  return (
    <React.Fragment>
      <div className="register-acount-main-div">
        <div className="register-signup-account-image-div">
          <div className="register-account-image-div-main-heading">
            You are just a <br></br> few steps away
          </div>

          <div className="register-account-image-div-email-span">{props.user_registered.email}</div>

          <img className="register-signup-image" src="content/images/account-register-signup.png"></img>
        </div>
        <div className="register-account-form-div">
          <form className="accountsregister-form">
            <Grid container item xs={12} md={12} lg={10} spacing={2}>
              <FormControl className="accountsregister-fields">
                <TextField
                  name="name"
                  variant="outlined"
                  required
                  label="Company Name"
                  placeholder="Company Name"
                  onChange={handleRegisterFormValues}
                  error={fieldError['name_error']}
                  size="small"
                  inputProps={{
                    pattern: '^[a-zA-Z0-9!$&*+=?^_`{|}~.-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$|^[_.@A-Za-z0-9-]+$',
                    minLength: 1,
                    maxLength: 50,
                  }}
                />
              </FormControl>
            </Grid>

            <Grid container item xs={12} md={12} lg={10} spacing={2}>
              <FormControl className="accountsregister-fields">
                <span className="staff-range-text-span" style={{ marginBottom: '10px', marginTop: '5px' }}>
                  Staff Range
                </span>

                <div className="register-account-staff-range-button" style={{ marginBottom: '20px' }}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      handleStaffRangeButton('X');
                    }}
                    data-cy="submit"
                    className="staff-range-buttons"
                    style={{
                      borderRadius: '50px',
                      width: '30%',
                      backgroundColor: value['staff_range'] === '1-50' ? '#F7B017' : 'white',
                      color: value['staff_range'] === '1-50' ? 'white' : 'black',
                      fontSize: '16px',
                    }}
                  >
                    1-50
                  </Button>
                  &nbsp;
                  <Button
                    onClick={() => {
                      handleStaffRangeButton('Y');
                    }}
                    variant="outlined"
                    data-cy="submit"
                    className="staff-range-buttons"
                    style={{
                      borderRadius: '50px',
                      width: '30%',
                      fontSize: '16px',
                      backgroundColor: value['staff_range'] === '51-300' ? '#F7B017' : 'white',
                      color: value['staff_range'] === '51-300' ? 'white' : 'black',
                    }}
                  >
                    51-300
                  </Button>
                  &nbsp;
                  <Button
                    onClick={() => {
                      handleStaffRangeButton('Z');
                    }}
                    variant="outlined"
                    data-cy="submit"
                    className="staff-range-buttons"
                    style={{
                      borderRadius: '50px',
                      width: '40%',
                      fontSize: '16px',
                      backgroundColor: value['staff_range'] === 'More than 300' ? '#F7B017' : 'white',
                      color: value['staff_range'] === 'More than 300' ? 'white' : 'black',
                    }}
                  >
                    More than 301
                  </Button>
                </div>
              </FormControl>
            </Grid>

            <Grid container item xs={12} md={12} lg={10} spacing={2}>
              <FormControl className="accountsregister-fields">
                <TextField
                  name="personFirstName"
                  required
                  variant="outlined"
                  label="First Name"
                  placeholder="First Name"
                  onChange={handleRegisterFormValues}
                  error={fieldError['personFirstName_error']}
                  helperText={fieldError['personFirstName_helper']}
                  size="small"
                  inputProps={{
                    pattern: '^[a-zA-Z0-9!$&*+=?^_`{|}~.-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$|^[_.@A-Za-z0-9-]+$',
                    minLength: 1,
                    maxLength: 50,
                  }}
                  data-cy="username"
                />
              </FormControl>
            </Grid>

            <Grid container item xs={12} md={12} lg={10} spacing={2}>
              <FormControl className="accountsregister-fields">
                <TextField
                  name="personLastName"
                  required
                  variant="outlined"
                  label="Last Name"
                  placeholder="Last Name"
                  onChange={handleRegisterFormValues}
                  error={fieldError['personLastName_error']}
                  helperText={fieldError['personLastName_helper']}
                  size="small"
                  inputProps={{
                    pattern: '^[a-zA-Z0-9!$&*+=?^_`{|}~.-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$|^[_.@A-Za-z0-9-]+$',
                    minLength: 1,
                    maxLength: 50,
                  }}
                  data-cy="username"
                />
              </FormControl>
            </Grid>

            <Grid container item xs={12} md={12} lg={10} spacing={2}>
              <FormControl className="accountsregister-fields">
                <PhoneInput
                  inputProps={{
                    name: 'phone',
                    autoFocus: false,
                  }}
                  country={'gb'}
                  onChange={phone => handlePhoneNumber(phone)}
                  placeholder="Enter your phone no."
                />
              </FormControl>
            </Grid>

            <Grid container item xs={12} md={12} lg={10} spacing={2}>
              <FormControl className="accountsregister-fields">
                <TextField
                  name="password"
                  required
                  variant="outlined"
                  label=" Set Password"
                  placeholder="Set Password"
                  size="small"
                  type="password"
                  onChange={handleRegisterFormValues}
                  error={fieldError['password_error']}
                  inputProps={{
                    minLength: 4,
                    maxLength: 50,
                  }}
                />
              </FormControl>
            </Grid>

            <Grid container item xs={12} md={12} lg={10} spacing={2}>
              <FormControl className="accountsregister-fields">
                <TextField
                  name="confirm_password"
                  variant="outlined"
                  required
                  label=" Confirm Password"
                  placeholder="Confirm Password"
                  type="password"
                  size="small"
                  onChange={handleRegisterFormValues}
                  error={fieldError['confirm_password_error']}
                  helperText={fieldError['confirm_password_helper']}
                  inputProps={{
                    minLength: 4,
                    maxLength: 50,
                  }}
                />
              </FormControl>
            </Grid>

            <Grid container item xs={12} md={12} lg={10} spacing={2}>
              <FormControl style={{ display: 'block' }} className="accountsregister-fields">
                <Button
                  id="register-submit"
                  onClick={submitRegisterForm}
                  variant="outlined"
                  data-cy="submit"
                  className="accountregister_register_button"
                >
                  Get Started
                </Button>
              </FormControl>
            </Grid>
            <br />
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ register }: IRootState) => ({
  user_registered: register.user_email,
});

const mapDispatchToProps = { handleRegister, getSelfRegisterUsers, reset };
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(RegisterSignUp);
