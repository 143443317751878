import moment from 'moment';
import { weeks } from '../../../utils/global';
import {
  calculateShiftDuration,
  calendarDateconverter,
  check_time_overlap,
  handleFilterCurrentWeekEndDate,
  handleFilterCurrentWeekStartDate,
} from '../../../utils/TimeFormatAndTimezone';

export const CreateSchedule = (parentProp, props, calendarDisplayWindow) => {
  if (props.modalForm.modalScheduleUpdate) {
    UpdateSchedule(parentProp, props, calendarDisplayWindow);
    return;
  } else {
    const modalShiftData = {
      person: props.modalForm.person,
      start_time: moment(
        moment(props.modalForm.start_date).format('YYYY-MM-DD') + ' ' + moment(props.modalForm.start_time).format('HH:mm')
      ).toISOString(),
      end_time: moment(
        moment(props.modalForm.end_date).format('YYYY-MM-DD') + ' ' + moment(props.modalForm.end_time).format('HH:mm')
      ).toISOString(),
      start_date: moment(props.modalForm.start_date).format('YYYY-MM-DD'),
      end_date: moment(props.modalForm.end_date).format('YYYY-MM-DD'),
      status: 1,
      isAllDay: props.modalForm.person === null,
      pay_rate: props.modalForm.pay_rate,
      pay_rate_check: props.modalForm.pay_rate_check,
      subcontractor:
        props.modalForm.selectedCompany?.status === 'SUBCONTRACTOR'
          ? props.modalForm.selectedCompany
          : personIsNull('SUBCONTRACTOR', props),
      organization:
        props.modalForm.selectedCompany?.status === 'ORGANIZATION' ? props.modalForm.selectedCompany : personIsNull('ORGANIZATION', props),
      charge_rate: props.modalForm.charge_rate,
      note: props.modalForm.note,
      service_area: props.modalForm.service_area,
      designation: props.modalForm.designation,
      time: new Date().toISOString(),
      check_call_frequency: props.modalForm.check_call_frequency,
      isRepeat: props.modalForm.isRepeat,
      repeatEnd: props.modalForm.isRepeat ? moment(props.modalForm.repeatEnd).format('YYYY-MM-DD') : '',
      repeatType: props.modalForm.isRepeat ? props.modalForm.repeatType : '',
      repeatPattern: props.modalForm.isRepeat ? props.modalForm.repeatPattern : '',
      patrol_frequency: props.modalForm.patrol_frequency ? props.modalForm.patrol_frequency : 0,
      patrol_durations: props.modalForm.patrol_durations ? props.modalForm.patrol_durations : null,
      no_of_breaks: props.modalForm.no_of_breaks ? props.modalForm.no_of_breaks : null,
    };
    if (!(checkValidations(modalShiftData, props) || props.fieldError['personField_error'] === true)) {
      parentProp.storeScheduledShift('create', modalShiftData, calendarDisplayWindow);
      props.setModalForm(oldValues => ({ ...oldValues, modalScheduleUpdate: false, setModalOpen: false }));
      resetModal(props.modalForm, props.setModalForm, parentProp);
    }
  }
};

const personIsNull = (status, props) => {
  if (typeof props.modalForm?.person === 'object' && props.modalForm?.person !== null && !Array.isArray(props.modalForm.person)) {
    if (props.modalForm.person === null || props.modalForm.person === undefined) {
      if (status === 'ORGANIZATION') {
        const person = props.entities?.find(per => Number(per.id) === Number(props.setting?.person_id));
        return person?.organization;
      }
    } else {
      if (
        props.modalForm?.person.organization !== null &&
        props.modalForm?.person.subcontractors.length === 0 &&
        status === 'ORGANIZATION'
      ) {
        return props.modalForm?.person?.organization;
      } else if (
        props.modalForm?.person.organization === null &&
        props.modalForm?.person.subcontractors.length === 1 &&
        status === 'SUBCONTRACTOR'
      ) {
        return props.modalForm?.person?.subcontractors[0];
      }

      return null;
    }
  } else {
    props.modalForm &&
      props.modalForm.person?.forEach(element => {
        if (props.modalForm.person === null || props.modalForm.person === undefined) {
          if (status === 'ORGANIZATION') {
            const person = props.entities?.find(per => Number(per.id) === Number(props.setting?.person_id));
            return person?.organization;
          }
        } else {
          if (element.organization !== null && element.subcontractors.length === 0 && status === 'ORGANIZATION') {
            return element?.organization;
          } else if (element.organization === null && element.subcontractors.length === 1 && status === 'SUBCONTRACTOR') {
            return element?.subcontractors[0];
          }

          return null;
        }
      });
  }
};

const checkValidations = (modalShiftData, props) => {
  if (props.modalForm.designation === null) {
    props.setFieldError(oldValues => ({ ...oldValues, ['modalScheduleDesignation_error']: true }));
    return true;
  }
  if (props.modalForm.service_area === null) {
    props.setFieldError(oldValues => ({ ...oldValues, ['modalScheduleLocation_error']: true }));
    return true;
  }
  if (modalShiftData.organization === null && modalShiftData.subcontractor === null) {
    props.setFieldError(oldValues => ({ ...oldValues, ['selectCompanyField_error']: true }));
    return true;
  }
  return false;
};

export const UpdateSchedule = (parentProp, props, calendarDisplayWindow) => {
  parentProp.history.push('/calendar');
  objfinder(props.modalForm.repeatPattern, props.modalForm, props.setModalForm);
  const modalShiftData = {
    person: props.modalForm.person,
    start_time: moment(
      moment(props.modalForm.start_date).format('YYYY-MM-DD') + ' ' + moment(props.modalForm.start_time).format('HH:mm')
    ).toISOString(),
    end_time: moment(
      moment(props.modalForm.start_date).format('YYYY-MM-DD') + ' ' + moment(props.modalForm.end_time).format('HH:mm')
    ).toISOString(),
    start_date: moment(props.modalForm.start_date).format('YYYY-MM-DD'),
    end_date: moment(props.modalForm.end_date).format('YYYY-MM-DD'),
    status: 1,
    isAllDay: props.modalForm.person === null,
    pay_rate: props.modalForm.pay_rate,
    charge_rate: props.modalForm.charge_rate,
    note: props.modalForm.note,
    service_area: props.modalForm.service_area,
    subcontractor: props.modalForm.selectedCompany?.status === 'SUBCONTRACTOR' ? props.modalForm.selectedCompany : null,
    organization: props.modalForm.selectedCompany?.status === 'ORGANIZATION' ? props.modalForm.selectedCompany : null,
    time: new Date().toISOString(),
    designation: props.modalForm.designation,
    check_call_frequency: props.modalForm.check_call_frequency,
    scheduleId: props.modalForm.id,
    isRepeat: props.modalForm.isRepeat,
    repeatEnd: props.modalForm.isRepeat ? moment(props.modalForm.repeatEnd).format('YYYY-MM-DD') : '',
    repeatType: props.modalForm.isRepeat ? props.modalForm.repeatType : '',
    repeatPattern: props.modalForm.isRepeat ? props.modalForm.repeatPattern : '',
    patrol_frequency: props.modalForm.patrol_frequency ? props.modalForm.patrol_frequency : 0,
    patrol_durations: props.modalForm.patrol_durations ? props.modalForm.patrol_durations : null,
    no_of_breaks: props.modalForm.no_of_breaks ? props.modalForm.no_of_breaks : 0,
  };
  if (!checkValidations(modalShiftData, props)) {
    if (modalShiftData.isRepeat === true) {
      parentProp.storeScheduledShift('update', modalShiftData, calendarDisplayWindow);
    } else {
      parentProp.updateShiftOccurence(modalShiftData, calendarDisplayWindow);
    }
    resetModal(props.modalForm, props.setModalForm, parentProp);
    handleResetWeek(props.modalForm);
    props.setModalForm(oldValues => ({ ...oldValues, setModalOpen: false, modalScheduleUpdate: false }));
  }
  return;
};

export const resetModal = (modalForm, setModalForm, parentProp) => {
  setModalForm({
    id: null,
    start_date: moment(new Date().toISOString()).format('YYYY-MM-DD'),
    end_date: moment(new Date().toISOString()).format('YYYY-MM-DD'),
    person: null,
    subcontractor: null,
    organization: null,
    service_area: null,
    designation: null,
    note: null,
    pay_rate: 10,
    charge_rate: 15,
    no_of_breaks: 0,
    start_time: new Date(),
    pay_rate_check: 'false',
    end_time: moment(new Date()).add(parentProp.setting.shift_duration ? parentProp.setting.shift_duration : 12, 'hours'),
    check_call_frequency: { label: 'Not Required', id: 1 },
    isRepeat: false,
    repeatEnd: moment(new Date().toISOString()).format('YYYY-MM-DD'),
    repeatType: 'Day',
    repeatPattern: '1',
    patrol_frequency: 0,
    repeatPatternObject: { id: 0, text: 'Does not repeat' },
    duration: parentProp.setting.shift_duration ? parentProp.setting.shift_duration : '12:00',
    selectedCompany: null,
    timeOverlapPersons: [],
    repeatEvery: { value: 'Day' },
    shift_Id: null,
    copied_Id: null,
    getBeforeCreateData: {},
    modalSchedulePaste: false,
    checkPersonListInBannedPeople: [],
    week: weeks,
    modalScheduleUpdate: false,
    setModalOpen: false,
    dbShiftData: parentProp.shifts,
  });
};

export const handleResetWeek = modalForm => {
  const arr = [];
  for (let i = 0; i < modalForm?.week?.length; i++) {
    if (modalForm?.week[i].active === true) {
      modalForm.week[i].active = false;
      arr.push(modalForm?.week[i]);
    } else {
      arr.push(modalForm?.week[i]);
    }
  }
};

export const objfinder = (arr, modalForm, setModalForm) => {
  const week = [modalForm?.week];
  if (arr && arr.length) {
    for (let i = 0; i < arr.length; i++) {
      const index = week?.findIndex(week => week.id === parseInt(arr[i], 10));
      const newWeek = [...week];
      if (index !== -1) {
        newWeek[index].active = true;
        setModalForm(oldValues => ({ ...oldValues, week: newWeek }));
      }
    }
  }
};

export const modalDateChange = (event, parentProp, props) => {
  props.setModalForm(oldValues => ({ ...oldValues, ['start_date']: event, ['end_date']: event }));
  if (props?.modalForm?.person !== null) {
    personWithDoubleShifs(props.modalForm.person, event, parentProp, props);
    checkTimeGapWithPerviousShift(props.modalForm.person, props.modalForm.start_time, event, parentProp, props);
    checkPersonHasDuplicateShiftOrNot(
      props.modalForm.start_time,
      props.modalForm.end_time,
      event,
      parentProp,
      props.modalForm.person,
      props
    );
  }
};

export const modalTimeEndChange = (event, parentProp, props) => {
  props.setModalForm(oldValues => ({
    ...oldValues,
    ['end_time']: event,
    duration: calculateShiftDuration(props.modalForm.start_time, event),
  }));
  if (props.modalForm.person !== null) {
    checkPersonHasDuplicateShiftOrNot(
      props.modalForm.start_time,
      event,
      props.modalForm.start_date,
      parentProp,
      props.modalForm.person,
      props
    );
  }
};

export const modalTimeStartChange = (event, parentProp, props) => {
  const shiftDuration = props.setting.shift_duration ? props.setting.shift_duration : 12;
  props.setModalForm(oldValues => ({
    ...oldValues,
    ['start_time']: event,
    ['end_time']: moment(event).add(shiftDuration, 'hours'),
    duration: calculateShiftDuration(event, moment(event).add(shiftDuration, 'hours')),
  }));
  if (props.modalForm.person !== null) {
    checkTimeGapWithPerviousShift(props.modalForm.person, event, props.modalForm.start_date, parentProp, props);
    checkPersonHasDuplicateShiftOrNot(
      event,
      props.modalForm.end_time,
      props.modalForm.start_date,
      parentProp,
      props.modalForm.person,
      props
    );
  }
};

export const handleShiftHistory = (parentProp, props) => {
  parentProp.history.push('/calendar');
  const selectedShift = props?.modalForm?.dbShiftData.filter(x => x.id === props.modalForm.id)[0];
  parentProp.getScheduleEditHistory(selectedShift?.id);
};

export const timeOverlapWithPreviousShift = (start_time, end_time, date, props) => {
  const personsWithTimeOverlap = [];

  const sameDayShifts = props.shifts.filter(shift => moment(shift.start_time).isSame(date, 'day'));

  const previousDayShifts = props.shifts.filter(shift => moment(shift.end_time).isSame(moment(date).subtract(1, 'day'), 'day'));

  const nextDayShifts = props.shifts.filter(shift => moment(shift.start_time).isSame(moment(date).add(1, 'day'), 'day'));

  sameDayShifts.concat(previousDayShifts, nextDayShifts).forEach(shift => {
    const timeOverlap = check_time_overlap(shift, start_time, end_time, date);
    if (timeOverlap === true) {
      personsWithTimeOverlap.push(shift);
    }
  });

  return personsWithTimeOverlap;
};

export const timeOverlapWShiftPersonCheck = (start_time, end_time, date, parentProp, option, props) => {
  let check = null;
  const personsWithTimeOverlap = timeOverlapWithPreviousShift(start_time, end_time, date, parentProp);
  personsWithTimeOverlap.forEach(element => {
    if (element?.id !== props.modalForm?.id) {
      if (element.person?.id === parseInt(option.id, 10)) {
        check = true;
      }
    }
  });

  if (check) {
    return true;
  } else {
    return false;
  }
};

export const personWithDoubleShifs = (person, date, parentProps, props) => {
  if (person) {
    const checkMultipleShifts = parentProps.shifts.filter(
      shift =>
        moment(shift.start_time).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD') &&
        shift.person &&
        shift.person.id === parseInt(person.id, 10)
    );

    if (checkMultipleShifts.length >= 2) {
      props.setFieldError(oldValues => ({ ...oldValues, ['personField_error']: true }));
      props.setFieldError(oldValues => ({ ...oldValues, ['personField_helper']: 'Person is already assigned to two shifts' }));
    } else {
      props.setFieldError(oldValues => ({ ...oldValues, ['personField_error']: false }));
      props.setFieldError(oldValues => ({ ...oldValues, ['personField_helper']: '' }));
    }
  } else {
    props.setFieldError(oldValues => ({ ...oldValues, ['personField_error']: false }));
    props.setFieldError(oldValues => ({ ...oldValues, ['personField_helper']: '' }));
  }
};
export const checkTimeGapWithPerviousShift = (person, time, date, parentProp, props) => {
  if (person !== null) {
    const sameDayShifts = parentProp.shifts.filter(
      shift =>
        moment(shift.start_time).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD') &&
        shift.person &&
        shift.person.id === parseInt(person.id, 10)
    );

    if (sameDayShifts.length !== 0) {
      sameDayShifts.forEach(shift => {
        const diff = calculateShiftDuration(shift.end_time, time);
        if (diff < '11:00') {
          props.setFieldError(oldValues => ({ ...oldValues, ['timeGapWithPerviousShift_warning']: true }));
          return '';
        } else {
          props.setFieldError(oldValues => ({ ...oldValues, ['timeGapWithPerviousShift_warning']: false }));
        }
      });
    } else {
      props.setFieldError(oldValues => ({ ...oldValues, ['timeGapWithPerviousShift_warning']: false }));
    }
  } else {
    props.setFieldError(oldValues => ({ ...oldValues, ['timeGapWithPerviousShift_warning']: false }));
  }
};

export const checkSelectedLocationInBannedEmployees = (location, parentProp) => {
  if (location) {
    const checkLocation = parentProp.bannedEmployees.filter(loc => loc.serviceArea.id === location.id);
    return checkLocation;
  }
};

export const checkEmployeeBanned = (person, location, modalForm, setFieldError, parentProp) => {
  const checkLocation = checkSelectedLocationInBannedEmployees(location, parentProp);
  if (checkLocation.length > 0) {
    if (modalForm.person !== null) {
      const checkPerson = checkLocation.filter(per => per.person.id === person.id);
      if (checkPerson.length > 0) {
        setFieldError(oldValues => ({ ...oldValues, ['banned_error']: true }));
        setFieldError(oldValues => ({ ...oldValues, ['personField_error']: true }));
      }
    }
  } else {
    setFieldError(oldValues => ({ ...oldValues, ['banned_error']: false }));
    setFieldError(oldValues => ({ ...oldValues, ['personField_error']: false }));
  }
};

export const handleShiftClose = (parentProp, props) => {
  parentProp.history.push('/calendar');
  handleResetForm(props);
  props.setModalForm({
    id: null,
    start_date: moment(new Date().toISOString()).format('YYYY-MM-DD'),
    end_date: moment(new Date().toISOString()).format('YYYY-MM-DD'),
    person: null,
    service_area: null,
    designation: null,
    note: null,
    subcontractor: null,
    organization: null,
    pay_rate: 10,
    charge_rate: 15,
    modalScheduleBreak: 0,
    start_time: new Date(),
    end_time: moment(new Date()).add(props.setting.shift_duration ? props.setting.shift_duration : 12, 'hours'),
    check_call_frequency: { label: 'Not Required', id: 1 },
    isRepeat: false,
    repeatEnd: moment(new Date().toISOString()).format('YYYY-MM-DD'),
    repeatType: 'Day',
    repeatPattern: '1',
    patrol_frequency: 0,
    repeatPatternObject: { id: 0, text: 'Does not repeat' },
    duration: props.setting.shift_duration ? props.setting.shift_duration : '12:00',
    selectedCompany: null,
    timeOverlapPersons: [],
    repeatEvery: { value: 'Day' },
    shift_Id: null,
    copied_Id: null,
    getBeforeCreateData: {},
    modalSchedulePaste: false,
    checkPersonListInBannedPeople: [],
    week: weeks,
    dbShiftData: parentProp.shifts,
  });
  props.setFieldError(oldValues => ({
    ...oldValues,
    dateError: false,
    personField_helper: '',
    personField_error: false,
    timeGapWithPerviousShift_warning: false,
    modalScheduleLocation_error: false,
    modalScheduleDesignation_error: false,
    selectCompanyField_error: false,
  }));
};

export const handleResetForm = props => {
  const newModalForm = props.modalForm;
  newModalForm.repeatPattern = 'Day';
  props.setModalForm(newModalForm);
};

export const handleRepeat = (e, props) => {
  props.setModalForm(prevState => ({ ...props.modalForm, isRepeat: true, repeatPatternObject: e }));
};

export const handleWeekdayPattern = (e, props) => {
  props.setModalForm({
    ...props.modalForm,
    isRepeat: true,
    repeatEnd: calendarDateconverter(new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toString()),
    repeatType: 'Week',
    repeatPattern: '1,2,3,4,5',
    repeatPatternObject: e,
  });
};

export const handleDailyPattern = (e, props) => {
  props.setModalForm({
    ...props.modalForm,
    isRepeat: true,
    repeatEnd: calendarDateconverter(new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toString()),
    repeatType: 'Day',
    repeatPattern: '1',
    repeatPatternObject: e,
  });
};

export const handleWeeklyPattern = (e, props) => {
  props.setModalForm({
    ...props.modalForm,
    isRepeat: true,
    repeatEnd: calendarDateconverter(new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toString()),
    repeatType: 'Week',
    repeatPattern: '1,2,3,4,5,6,7',
    repeatPatternObject: e,
  });
};

export const modalRepeatEndDateChange = (event, props) => {
  props.setModalForm(oldValues => ({ ...oldValues, ['repeatEnd']: event }));
};

export const modalPatrolFrequencyChange = (event, props) => {
  props.setModalForm(oldValues => ({ ...oldValues, ['patrol_frequency']: event.target.value }));
};

export const companyAndSubcontractors = (modalForm): Array<any> => {
  const data = [];
  if (modalForm?.person && modalForm?.person?.subcontractors?.length > 0) {
    modalForm.person.subcontractors.map(element => {
      data.push({ ...element, status: 'SUBCONTRACTOR' });
    });
  }
  if (modalForm?.person && modalForm?.person?.organization) {
    data.push({ ...modalForm.person.organization, status: 'ORGANIZATION' });
  }
  return data;
};

export const objectFindByKeyArr = (array, key, value) => {
  const arr = [];
  for (let i = 0; i < array.length; i++) {
    if (array[i][key] === value) {
      arr.push(array[i].id + ',');
    }
  }
  return arr;
};

export const handleWeek = (value, props) => {
  if (value) {
    const index = props.modalForm.week.findIndex(week => week.value === value.value);
    const newWeek = [...props.modalForm.week];
    newWeek[index].value = value.value;
    newWeek[index].active = !value.active;
    const selectedWeek = objectFindByKeyArr(newWeek, 'active', true);
    const newModalForm = props.modalForm;
    newModalForm.repeatPattern = selectedWeek?.join('');
    props.setModalForm(newModalForm => ({ ...newModalForm, week: newWeek }));
  } else {
    const newModalForm = props.modalForm;
    newModalForm.repeatPattern = null;
    props.setModalForm(newModalForm);
  }
};

export const modalBreakChange = (event, props) => {
  props.setModalForm({ ...props.modalForm, no_of_breaks: event.target.value });
};

export const modalNotesChange = (event, props) => {
  props.setModalForm({ ...props.modalForm, note: event.target.value.toString() });
};

export const handleRepeatsEvery = (event, props) => {
  if (event) {
    props.setModalForm({ ...props.modalForm, repeatPattern: event.target.value });
  } else {
    props.setModalForm({ ...props.modalForm, repeatPattern: null });
  }
};

export const modalPayRateChange = (event, props) => {
  props.setModalForm({ ...props.modalForm, pay_rate: event.target.value });
};

export const modalChargeRateChange = (event, props) => {
  props.setModalForm({ ...props.modalForm, charge_rate: event.target.value });
};

export const checkEmployeeBannedOrNot = (location, parentProp, props) => {
  const checkLocation = checkSelectedLocationInBannedEmployees(location, parentProp);
  if (checkLocation.length > 0) {
    if (props.modalForm.person !== null) {
      const checkPerson = checkLocation.filter(per => Number(per.person.id) === Number(props.modalForm.person.id));
      if (checkPerson.length > 0 && checkDateForBan(checkPerson, props)) {
        props.setFieldError(oldValues => ({ ...oldValues, ['banned_error']: true }));
        props.setFieldError(oldValues => ({ ...oldValues, ['personField_error']: true }));
      } else {
        props.setFieldError(oldValues => ({ ...oldValues, ['banned_error']: false }));
        props.setFieldError(oldValues => ({ ...oldValues, ['personField_error']: false }));
      }
    }
  } else {
    props.setFieldError(oldValues => ({ ...oldValues, ['banned_error']: false }));
    props.setFieldError(oldValues => ({ ...oldValues, ['personField_error']: false }));
  }
};
export const checkPersonHasDuplicateShiftOrNot = (start_time, end_time, date, parentProp, option, props) => {
  if (props.modalForm.person !== null) {
    if (timeOverlapWShiftPersonCheck(start_time, end_time, date, parentProp, option, props)) {
      props.setFieldError(oldValues => ({ ...oldValues, ['personShift_error']: true }));
      props.setFieldError(oldValues => ({ ...oldValues, ['personField_error']: true }));
    } else {
      props.setFieldError(oldValues => ({ ...oldValues, ['personShift_error']: false }));
      props.setFieldError(oldValues => ({ ...oldValues, ['personField_error']: false }));
    }
  } else {
    props.setFieldError(oldValues => ({ ...oldValues, ['personShift_error']: false }));
    props.setFieldError(oldValues => ({ ...oldValues, ['personField_error']: false }));
  }
};

export const checkDateForBan = (checkPerson, props) => {
  let modalDate;
  if (props.modalForm.start_date?._d) {
    modalDate = moment(props.modalForm.start_date?._d).format('YYYY-MM-DD');
  } else {
    modalDate = props.modalForm.start_date;
  }
  if (checkPerson?.length > 0) {
    if (
      moment(checkPerson[0].start_date).valueOf() < moment(modalDate).valueOf() &&
      (checkPerson[0].end_date === null || checkPerson[0].end_date !== null)
    ) {
      if (moment(checkPerson[0].end_date).valueOf() < moment(modalDate).valueOf()) {
        return false;
      }
      return true;
    }
    if (
      moment(checkPerson[0].start_date).valueOf() === moment(modalDate).valueOf() &&
      (checkPerson[0].end_date === null || checkPerson[0].end_date !== null)
    ) {
      return true;
    } else {
      return false;
    }
  }
};

export const checkPersonListInBanned = (option, props) => {
  const checkPerson = props.modalForm.checkPersonListInBannedPeople?.filter(per => Number(per.person.id) === Number(option.id));
  if (checkPerson !== null || checkPerson !== undefined || checkPerson?.length > 0) {
    if (checkDateForBan(checkPerson, props)) {
      const matchPerson = props.modalForm.checkPersonListInBannedPeople?.map(ban => ban.person.id === option.id);
      if (matchPerson?.includes(true)) {
        return true;
      }
    } else {
      return false;
    }
  }
};

export const applyPeriodShiftsFilter = (tag, props, setCalendarDisplayWindow) => {
  if (tag === 'DAY') {
    const first = moment().format('YYYY-MM-DD');
    props.getScheduledShifts({ from_date: '', to_date: '', current_day: first });
    setCalendarDisplayWindow(oldValues => ({ ...oldValues, ['start_date']: first, ['end_date']: first }));
  } else if (tag === 'WEEK') {
    const first = handleFilterCurrentWeekStartDate().format('YYYY-MM-DD');
    const last = handleFilterCurrentWeekEndDate().format('YYYY-MM-DD');
    props.getScheduledShifts({ from_date: first, to_date: last, current_day: '' });
    setCalendarDisplayWindow(oldValues => ({ ...oldValues, ['start_date']: first, ['end_date']: last }));
  } else if (tag === 'MONTH') {
    const first = moment().startOf('month').format('YYYY-MM-DD');
    const last = moment().endOf('month').format('YYYY-MM-DD');
    props.getScheduledShifts({ from_date: first, to_date: last, current_day: '' });
    setCalendarDisplayWindow(oldValues => ({ ...oldValues, ['start_date']: first, ['end_date']: last }));
  }
};

export const sorting = (startDayWeek, modalForm) => {
  const weekA = modalForm?.week;
  const temp = weekA.splice(0, startDayWeek - 1);
  const newWeek = [...new Set([...modalForm.week, ...temp])];
  return newWeek;
};

export const _getFormattedTime = time => {
  const date = new Date(time);
  const h = date.getHours();
  const m = date.getMinutes();

  return `${h}:${m}`;
};

export const objectFindByKey = (array, key, value) => {
  for (let i = 0; i < array.length; i++) {
    if (array[i][key] === value) {
      return array[i];
    }
  }
  return null;
};

export const _getTimeTemplate = (schedule, isAllDay) => {
  const html = [];
  if (!isAllDay) {
    html.push('<strong>' + _getFormattedTime(schedule.start) + '</strong> ');
  }
  if (schedule.isPrivate) {
    html.push('<span class="calendar-font-icon ic-lock-b"></span>');
    html.push(' Private');
  } else {
    if (schedule.isReadOnly) {
      html.push('<span class="calendar-font-icon ic-readonly-b"></span>');
    } else if (schedule.recurrenceRule) {
      html.push('<span class="calendar-font-icon ic-repeat-b"></span>');
    } else if (schedule.attendees.length) {
      html.push('<span class="calendar-font-icon ic-user-b"></span>');
    } else if (schedule.location) {
      html.push('<span class="calendar-font-icon ic-location-b"></span>');
    }
    html.push(' ' + schedule.title);
  }
  return html.join('');
};
