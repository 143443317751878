import React, { useEffect, useState } from 'react';
import PersonalSettingDrawerComponent from './SettingsComponents/PersonalSettingDrawerComponent';
import PersonRolePermissionSettings from './RolesAndPermissionsSettings/RolesAndPermissionsSettings';
import './index.scss';
import PersonalSetting from './PersonalSetting';
import OrganizationQualification from './OrganizationQualification/oraganization_qualification';

const PersonalSettings = props => {
  const [page, setPage] = useState(1);

  return (
    <div className="settings-view-deisgns-main-container" style={{ padding: '32px' }}>
      <div className="settings-outer-div">
        <div className="settings-drawer-container">
          <PersonalSettingDrawerComponent setPage={setPage} page={page} />
        </div>
        <div className="settings-container-body">
          {page === 1 && <PersonalSetting {...props} />}
          {page === 2 && <OrganizationQualification {...props} />}
        </div>
      </div>
    </div>
  );
};

export default PersonalSettings;
