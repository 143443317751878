import React, { useState, useEffect } from 'react';
import { makeStyles, CardContent } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RoomIcon from '@material-ui/icons/Room';
import Divider from '@material-ui/core/Divider';
import { faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import buildingIcon from '../../../../content/images/buildingIcon.png';
import forwardIcon from '../../../../content/images/forwardIcon.png';
import '../person.scss';
export interface IServiceAreaTeamBannedComponent {
  setOpen: (value: boolean) => void;
  setBannedModalMode: (value: any) => void;
  prop: any;
  editMode: any;
}
const BannedServiceAreaComponent = (props: IServiceAreaTeamBannedComponent) => {
  const { setOpen, setBannedModalMode, prop } = props;
  const [records, setRecords] = useState(prop.bannedServiceAreas);
  const [deleteMessage, setDeleteMessage] = useState('');
  const [deletePersonId, setDeletePersonId] = useState(props.prop.personEntity.id);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteServiceAreaId, setDeleteServiceAreaId] = useState(null);

  useEffect(() => {
    if (prop.bannedServiceAreas && prop.bannedServiceAreas.length > 0) {
      setRecords(prop.bannedServiceAreas);
    }
  }, [prop.bannedServiceAreas]);

  const handleEditClose = id => {
    props.prop.history.push(`/service-areas/${id}`);
  };

  const handleEditIconButton = id => {
    setOpen(true);
    setBannedModalMode(oldValues => ({ ...oldValues, Mode: 'edit', banned_id: id }));
  };

  const handleDeleteIconButton = id => {
    setOpen(true);
    setBannedModalMode(oldValues => ({ ...oldValues, Mode: 'delete', banned_id: id }));
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const confirmDelete = () => {
    if (deleteServiceAreaId !== null) {
      props.prop.deleteStaffEntity(deleteServiceAreaId, deletePersonId);
      setOpenDialog(false);
    }
  };

  return (
    <div className="Card-Container" style={{ display: 'flex', flexWrap: 'wrap' }}>
      {records.length > 0
        ? records.map((banned, i) => (
            <div key={i}>
              <CardContent
                className="active-service-area-cardContent"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  background: '#0000 0% 0% no-repeat padding-box',
                  border: '1px solid #D4D4D4',
                  borderRadius: '4px',
                  width: '310px',
                  height: '100px',
                  paddingTop: '0',
                  paddingLeft: '33px',
                  marginBottom: '30px',
                  marginRight: '10px',
                  overflow: 'hidden',
                }}
              >
                <div>
                  <img
                    src={forwardIcon}
                    alt="Forward icon"
                    onClick={() => handleEditClose(banned.serviceArea.id)}
                    style={{ width: '12px', height: '12px', opacity: 1, marginLeft: '250px', marginTop: '13px', cursor: 'pointer' }}
                  />
                </div>
                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                  <img
                    src={buildingIcon}
                    alt="Building Icon"
                    style={{ width: '18px', height: '18px', opacity: 1, marginRight: '12.5px' }}
                  />
                  <h1 style={{ fontFamily: 'Roboto', fontSize: '20px', color: '#2D2D2D' }}>{banned.serviceArea.name}</h1>
                </div>

                <div className="cards-bottom" style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <p
                    style={{
                      color: '#707070',
                      fontSize: '12px',
                      fontFamily: 'Roboto',
                      flex: '1',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      margin: '0',
                    }}
                  >
                    {banned.serviceArea.address !== null ? banned.serviceArea.address.address_line_one : null}
                  </p>
                  {props.editMode === true ? (
                    <div className="edit-delete-icons" style={{ display: 'flex' }}>
                      <FontAwesomeIcon
                        icon={faEdit}
                        color="#bebebe"
                        style={{ marginRight: '5px', cursor: 'pointer' }}
                        onClick={() => handleEditIconButton(banned.id)}
                      />
                      &nbsp;
                      <Divider orientation="vertical" flexItem style={{ width: '2px', height: '20px' }} />
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        color="#bebebe"
                        style={{ marginLeft: '10px', color: '#bebebe', cursor: 'pointer' }}
                        onClick={() => handleDeleteIconButton(banned.id)}
                      />
                    </div>
                  ) : null}
                </div>
              </CardContent>
            </div>
          ))
        : !prop.loading && <div className="no-record-warning">No Unauthorised Service Areas found</div>}
    </div>
  );
};

export default BannedServiceAreaComponent;
