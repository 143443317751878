/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import { IRootState } from 'app/shared/reducers';
import Divider from '@material-ui/core/Divider';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import '../../person/person.scss';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import Checkbox from '@material-ui/core/Checkbox';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

export interface IPersonServiceAreaComponent {
  openPersonModal: boolean;
  setOpenPersonModal: (value: boolean) => void;
  prop: any;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const AddPersonComponent = (props: IPersonServiceAreaComponent) => {
  const { openPersonModal, setOpenPersonModal, prop } = props;
  const [fieldError, setFieldError] = useState({
    serviceAreaField_error: false,
    DescriptionField_error: false,
    startDateField_error: false,
    endDateField_error: false,
  });
  const [newPersonModal, setNewPersonModal] = useState({
    serviceArea: null,
    person: null,
    allPersons: false,
  });

  const handleClose = () => {
    setOpenPersonModal(false);
    setFieldError(oldValues => ({
      ...oldValues,
      employeeField_error: false,
      DescriptionField_error: false,
      startDateField_error: false,
      endDateField_error: false,
    }));
    reset();
  };

  const handlePersonValues = (_, e) => {
    setNewPersonModal(oldValues => ({ ...oldValues, person: e }));
    setNewPersonModal(oldValues => ({ ...oldValues, serviceArea: prop.serviceAreaEntity }));
  };

  const handleAllPersonValues = () => {
    setNewPersonModal(oldValues => ({ ...oldValues, person: prop.personServiceArea, allPersons: true }));
    setNewPersonModal(oldValues => ({ ...oldValues, serviceArea: prop.serviceAreaEntity }));
  };

  const reset = () => {
    setNewPersonModal(oldValues => ({
      ...oldValues,
      person: null,
      serviceArea: null,
      allPersons: false,
    }));
  };

  const handleAddPerson = () => {
    prop.addNewPerson(newPersonModal);
    reset();
    setOpenPersonModal(false);
    setNewPersonModal(oldValues => ({ ...oldValues, serviceArea: null, allPersons: false }));
  };

  return (
    <>
      <Modal open={openPersonModal} onClose={handleClose}>
        <div className="personBannedModaPaper">
          <h2 className="personBannedModalTitle"> Add new Staff</h2>

          <Divider className="personBannedModalDivider" />

          <Grid item className="personBannedModalSelectBox" xs={12} lg={12}>
            <FormControl variant="outlined" size="small" className="personBannedModalFields radioButtonAndOR">
              <FormControlLabel
                className={newPersonModal?.allPersons === true ? 'allServiceAreaTrue' : 'allServiceAreaFalse'}
                label={'Add All Staff'}
                labelPlacement="start"
                control={
                  <Checkbox
                    icon={
                      <CircleUnchecked
                        className={newPersonModal?.allPersons === false ? 'personCircleUncheckedInBannedModal' : 'personCheckBoxWhite'}
                      />
                    }
                    checkedIcon={
                      <CircleCheckedFilled
                        className={newPersonModal?.allPersons === true ? 'personCheckCircleFilledInBannedModal' : 'personCheckBoxWhite'}
                      />
                    }
                    checked={newPersonModal?.allPersons}
                    onChange={handleAllPersonValues}
                    className="personBannedModalFirstRadioButtion"
                  />
                }
              />
              <span className="personBannedModalORText">Or</span>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12} lg={12} sm={12}>
            <FormControl variant="outlined" size="small" className="personBannedModalFields" style={{ marginTop: '1%' }}>
              <Autocomplete
                className="PersonBannedModalAutocomplete"
                ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                limitTags={1}
                multiple
                options={prop.personServiceArea}
                disableCloseOnSelect
                getOptionLabel={option => option.first_name + ' ' + option.last_name}
                value={newPersonModal.person ? newPersonModal.person : []}
                renderTags={values => values.map(value => <Chip key={value.id} label={value.first_name + ' ' + value.last_name} />)}
                getOptionSelected={(option, value) => option.id === value.id}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected ? selected : null} />
                    {option.first_name + ' ' + option.last_name || ''}
                  </React.Fragment>
                )}
                onChange={handlePersonValues}
                renderInput={params => (
                  <TextField {...params} error={fieldError['serviceAreaField_error']} required variant="outlined" label="Select Staff" />
                )}
              />
            </FormControl>
          </Grid>

          <Divider className="personBannedModalDivider" />

          <FormControl className="personBannedModalFields">
            <FormGroup row={true}>
              <Grid item xs={3} lg={4}>
                <Button variant="contained" className="personBannedModalCancelButton" onClick={handleClose}>
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={3} lg={4}>
                <Button
                  variant="contained"
                  className="personBannedModalSaveButton"
                  onClick={() => {
                    handleAddPerson();
                  }}
                >
                  Confirm
                </Button>
              </Grid>
            </FormGroup>
          </FormControl>
        </div>
      </Modal>
    </>
  );
};
