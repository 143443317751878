import React, { useState, useEffect } from 'react';
import { Drawer } from '@material-ui/core';
import { Select, MenuItem, FormControl, InputLabel, makeStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { AddCircleOutline, AddCircleOutlined, Subject, SubjectOutlined, NotificationsActive } from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router';
import { faUserLock } from '@fortawesome/free-solid-svg-icons';
import { faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { faFirstAid } from '@fortawesome/free-solid-svg-icons';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { faLanguage } from '@fortawesome/free-solid-svg-icons';
import { faUserTie } from '@fortawesome/free-solid-svg-icons';
import { faUserGraduate } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Collapse from '@material-ui/core/Collapse';
import ScheduleIcon from '@material-ui/icons/Schedule';
import WorkIcon from '@material-ui/icons/Work';
import StarsIcon from '@material-ui/icons/Stars';
import { IRootState } from 'app/shared/reducers';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import { connect } from 'react-redux';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';

const useStyles = makeStyles(theme => ({
  selectBox: {
    minWidth: '35vh',
    margin: 'auto',
    marginTop: '2vh',
    paddingTop: '1vh',
  },
  textBox: {
    minWidth: 200,
    margin: 'auto',
    marginTop: '1vh',
    paddingTop: '1vh',
  },
  buttons: {
    minWidth: 100,
    marginTop: '3vh',
    marginRight: '2vh',
    float: 'right',
  },
  sideBySide: {
    display: 'flex',
    flexDirection: 'row',
  },
  drawer: {
    width: '18%',
  },
  drawerPaper: {
    width: '19%',
    position: 'absolute',
    marginTop: '104px',
    marginLeft: 0,
    zIndex: 1,
    paddingLeft: '18px',
    paddingTop: '20px',
  },
  active: {
    background: '#F4F4F4',
  },
  title: {
    textAlign: 'left',
    marginLeft: '10vh',
  },
  mainTitle: {
    padding: theme.spacing(2),
    marginLeft: '3.2vh',
    textAlign: 'left',
    color: '#6610f2',
    fontWeight: 450,
  },
  flexContainer: {
    display: 'flex',
  },
  tempDrawerforMobile: {
    width: '50%',
  },
  tempDrawerforTab: {
    width: '30%',
  },
}));

const DrawerComponent = props => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', e => {
      setWindowWidth(window.innerWidth);
    });
  }, [window.innerWidth]);

  const menuItems = [
    {
      text: 'Date & Time',
      icon: <ScheduleIcon style={{ color: '#4720b7' }} />,
      pageNo: 1,
    },
    ...(props.isOrganizationAdministrator
      ? [
          {
            text: 'Organization Qualification',
            icon: <WorkIcon style={{ color: '#4720b7' }} />,
            pageNo: 2,
          },
        ]
      : []),
  ];

  const handleClick = () => {
    setOpen(!open);
  };

  const handleDrawerMenu = pageNo => {
    if (pageNo === 5) {
      handleClick();
    } else {
      props.setPage(pageNo);
    }
  };

  const closeDrawerMenu = () => {
    setMobileOpen(!mobileOpen);
  };

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div>
      <Hidden smUp implementation="css">
        <Toolbar>
          <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle}>
            <PlaylistPlayIcon />
          </IconButton>
        </Toolbar>
        <Drawer
          className={windowWidth <= 600 ? classes.tempDrawerforMobile : classes.tempDrawerforTab}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          anchor="left"
        >
          <div>
            <Typography variant="h5" className={classes.title}></Typography>
          </div>
          <div style={{ marginTop: '20px', paddingRight: '20px' }}>
            <MenuOpenIcon style={{ color: '#4720b7', float: 'right' }} onClick={handleDrawerToggle} />
          </div>

          <List style={{ marginTop: '50px' }}>
            {menuItems.map(item => (
              <ListItem
                key={item.text}
                button
                onClick={() => {
                  handleDrawerMenu(item.pageNo);
                }}
                className={props.page === item.pageNo ? classes.active : null}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
          </List>
        </Drawer>
      </Hidden>

      <Hidden smDown implementation="css">
        <Drawer className={classes.drawer} variant="permanent" anchor="left" classes={{ paper: classes.drawerPaper }}>
          <div>
            <Typography variant="h5" className={classes.title}></Typography>
          </div>
          <List>
            {menuItems.map(item => (
              <ListItem
                key={item.text}
                button
                onClick={() => {
                  handleDrawerMenu(item.pageNo);
                }}
                className={props.page === item.pageNo ? classes.active : null}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
          </List>
        </Drawer>
      </Hidden>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  isOrganizationAdministrator: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.ORGANIZATION_ADMINISTRATOR]),
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(DrawerComponent);
