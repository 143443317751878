import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';

export const ShiftsChart = props => {
  const [dateFormat, setdateFormat] = useState(props.setting.date_format);

  useEffect(() => {
    setdateFormat(props.setting.date_format);
  }, [props.setting]);

  useEffect(() => {
    props.shiftDetails;
  });

  const dateConverter = dateformat => {
    if (dateformat === null) {
      if (dateFormat === 1) {
        return 'DD-MM-YY';
      } else if (dateFormat === 2) {
        return 'DD-MM-YYYY';
      } else if (dateFormat === 3) {
        return 'MM/DD/YY';
      } else if (dateFormat === 4) {
        return 'MM/DD/YYYY';
      } else if (dateFormat === 5) {
        return 'DD.MM.YY';
      } else if (dateFormat === 6) {
        return 'DD.MM.YYYY';
      } else if (dateFormat === 7) {
        return 'YY/MM/DD';
      } else if (dateFormat === 8) {
        return 'YYYY/MM/DD';
      }
    } else {
      if (dateFormat === 1) {
        return moment(dateformat).format('DD-MM-YY');
      } else if (dateFormat === 2) {
        return moment(dateformat).format('DD-MM-YYYY');
      } else if (dateFormat === 3) {
        return moment(dateformat).format('MM/DD/YY');
      } else if (dateFormat === 4) {
        return moment(dateformat).format('MM/DD/YYYY');
      } else if (dateFormat === 5) {
        return moment(dateformat).format('DD.MM.YY');
      } else if (dateFormat === 6) {
        return moment(dateformat).format('DD.MM.YYYY');
      } else if (dateFormat === 7) {
        return moment(dateformat).format('YY/MM/DD');
      } else if (dateFormat === 8) {
        return moment(dateformat).format('YYYY/MM/DD');
      }
    }
  };

  const data = {
    labels: [
      dateConverter(props.week_schedules[0][0]),
      dateConverter(props.week_schedules[0][1]),
      dateConverter(props.week_schedules[0][2]),
      dateConverter(props.week_schedules[0][3]),
      dateConverter(props.week_schedules[0][4]),
      dateConverter(props.week_schedules[0][5]),
      dateConverter(props.week_schedules[0][6]),
    ],
    datasets: [
      {
        label: ' No of Unassigned Shifts',
        data: [
          props.week_schedules[1].open_shifts,
          props.week_schedules[2].open_shifts,
          props.week_schedules[3].open_shifts,
          props.week_schedules[4].open_shifts,
          props.week_schedules[5].open_shifts,
          props.week_schedules[6].open_shifts,
          props.week_schedules[7].open_shifts,
        ],
        backgroundColor: '#efbb4b',
        stack: 'Stack 0',
      },
      {
        label: ' No of Scheduled Shifts',
        data: [
          props.week_schedules[1].scheduled_shifts,
          props.week_schedules[2].scheduled_shifts,
          props.week_schedules[3].scheduled_shifts,
          props.week_schedules[4].scheduled_shifts,
          props.week_schedules[5].scheduled_shifts,
          props.week_schedules[6].scheduled_shifts,
          props.week_schedules[7].scheduled_shifts,
        ],
        backgroundColor: '#8761f3',
        stack: 'Stack 1',
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      yAxes: {
        beginAtZero: true,
        ticks: {
          min: 0,
          stepSize: 1,
        },
      },
    },
  };

  return <Bar className="shifts-bar-chart-component" data={data} options={options} />;
};
export default ShiftsChart;
