export interface IActionHistory {
  id?: number | null;
  time?: string | null;
  personName?: string | null;
  description?: string | null;
  action?: string | null;
  actionBy?: string | null;
  startTimeDescription?: string | null;
  endTimeDescription?: string | null;
  totalPages?: number | null;
  totalItems?: number | null;
  dashboardActivityDescription?: string | null;
  dashboardActivityLocation?: string | null;
}

export const defaultValue: Readonly<IActionHistory> = {};
