import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RoomIcon from '@material-ui/icons/Room';
import Divider from '@material-ui/core/Divider';
import { faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons';
import '../organization.scss';

const useStyles = makeStyles(theme => ({
  selectBox: {
    minWidth: '35vh',
    margin: 'auto',
    marginTop: '2vh',
    paddingTop: '1vh',
  },
  textBox: {
    minWidth: 200,
    margin: 'auto',
    marginTop: '1vh',
    paddingTop: '1vh',
  },
  buttons: {
    minWidth: 100,
    marginTop: '3vh',
    marginRight: '2vh',
    float: 'right',
  },
  sideBySide: {
    display: 'flex',
    flexDirection: 'row',
  },
  drawer: {
    width: '18%',
  },
  drawerPaper: {
    width: '18%',
    position: 'absolute',
    marginTop: '1vh',
    marginLeft: 0,
    zIndex: 1,
  },
  active: {
    background: '#F4F4F4',
  },
  title: {
    textAlign: 'left',
    marginLeft: '10vh',
  },
  mainTitle: {
    padding: theme.spacing(2),
    marginLeft: '3.2vh',
    textAlign: 'left',
    color: '#6610f2',
    fontWeight: 450,
  },
  flexContainer: {
    display: 'flex',
    background: 'linear-gradient(to top, #FFFFFF 50%, #EBECF0 50%)',
  },
}));

const ServiceAreaComponent = props => {
  const handleEditClose = service_area_id => {
    props.history.push(`/service-area/${service_area_id}/edit`);
  };

  const confirmDelete = service_area_id => {
    props.deleteEntity(service_area_id);
  };

  return (
    <div>
      {props && props.serviceAreasList.length > 0
        ? props.serviceAreasList.map((service_area, i) => (
            <div key={i} className="organization-service-area-component-main-container">
              <div className="organization-service-area-component-text-container" style={{ width: '90%', paddingLeft: '2%' }}>
                <div className="organization-service-area-component-name-div">{service_area.name}</div>

                <div className="organization-service-area-component-address-div">
                  <RoomIcon className="organization-service-area-component-location-icon" />
                  {''}
                </div>
              </div>

              <div className="organization-service-area-component-icons-container">
                <FontAwesomeIcon
                  icon={faEdit}
                  color="#bebebe"
                  style={{ marginRight: '5px' }}
                  onClick={() => handleEditClose(service_area.id)}
                />
                &nbsp;
                <Divider orientation="vertical" flexItem style={{ width: '2px' }} />
                <FontAwesomeIcon
                  icon={faTrashAlt}
                  color="#bebebe"
                  style={{ marginLeft: '10px' }}
                  onClick={() => confirmDelete(service_area.id)}
                />
              </div>
            </div>
          ))
        : !props.loading && <div className="no-record-warning">No Service Area Found</div>}
    </div>
  );
};

export default ServiceAreaComponent;
