import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Grid, FormControl, TextField, MenuItem, FormControlLabel, Checkbox } from '@material-ui/core';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';
import { Row, Col, Label } from 'reactstrap';
import { AvFeedback, form, AvField } from 'availity-reactstrap-validation';
import { Translate, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import './shift.scss';
import { IDesignation } from 'app/shared/model/designation.model';
import { getEntities as getDesignations } from 'app/entities/designation/designation.reducer';
import { IServiceArea } from 'app/shared/model/service-area.model';
import { getEntities as getServiceAreas } from 'app/entities/service-area/service-area.reducer';
import { IPerson } from 'app/shared/model/person.model';
import { getEntities as getPeople } from 'app/entities/person/person.reducer';
import { getEntity, updateEntity, createEntity, reset } from './shift.reducer';
import { IShift } from 'app/shared/model/shift.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface IShiftUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const ShiftUpdate = (props: IShiftUpdateProps) => {
  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const { shiftEntity, designations, serviceAreas, people, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/roster');
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }

    props.getDesignations(-1);
    props.getServiceAreas(-1, '');
    props.getPeople(-1, '');
  }, []);

  useEffect(() => {}, [shiftEntity]);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = event => {
    event.preventDefault();

    const entity = {
      shiftId: event.target.id.value,
      startTime: event.target.startTime.value,
      endTime: event.target.endTime.value,
      startDate: event.target.startDate.value,
      endDate: event.target.endDate.value,
      isAllDay: false,
      status: 1,
      payRate: 10,
      note: 'adsdfnhjkh',
      designation_id: event.target.designation_id.value,
      service_area_id: event.target.service_area_id.value,
      id: event.target.personId.value,
    };

    if (isNew) {
      props.createEntity(entity);
    } else {
      props.updateEntity(entity);
    }
  };

  return (
    <div className="shift-full-container">
      <Grid item md={12} container justify="center">
        <Grid item md={2}></Grid>
        <Grid item md={8}>
          <h2 id="wfmApp.shift.home.createOrEditLabel" data-cy="ShiftCreateUpdateHeading">
            <Translate contentKey="wfmApp.shift.home.createOrEditLabel">Create or edit a Shift</Translate>
          </h2>
        </Grid>
      </Grid>
      <Grid item md={12} container justify="center" spacing={1}>
        <Grid item md={2}></Grid>
        <Grid item md={8} spacing={2}>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <form onSubmit={saveEntity} className="shift-form">
              {!isNew ? (
                <Grid item md={12} container>
                  <Grid item md={12} xs={12}>
                    <FormControl className="shift-fields">
                      <TextField
                        id="shift-id"
                        className="shiftid"
                        variant="outlined"
                        name="id"
                        required
                        inputProps={{ readOnly: true }}
                        label={<Translate contentKey="global.field.id">ID</Translate>}
                        value={shiftEntity.id}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              ) : null}
              <Grid item md={12} container>
                <Grid item md={12} xs={12}>
                  <FormControl className="shift-fields">
                    <TextField
                      id="shift-startTime"
                      data-cy="startTime"
                      type="time"
                      className="shiftstartTime"
                      variant="outlined"
                      name="startTime"
                      InputLabelProps={{ shrink: true }}
                      label={<Translate contentKey="wfmApp.shift.startTime">Start Time</Translate>}
                      placeholder={'HH:mm'}
                      // value={isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.shiftEntity.startTime)}
                      defaultValue={shiftEntity.start_time}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item md={12} container>
                <Grid item md={12} xs={12}>
                  <FormControl className="shift-fields">
                    <TextField
                      id="shift-endTime"
                      data-cy="endTime"
                      type="time"
                      className="shiftendTime"
                      variant="outlined"
                      name="endTime"
                      InputLabelProps={{ shrink: true }}
                      label={<Translate contentKey="wfmApp.shift.endTime">End Time</Translate>}
                      placeholder={'HH:mm'}
                      // value={isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.shiftEntity.endTime)}
                      defaultValue={shiftEntity.end_time}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item md={12} container>
                <Grid item md={12} xs={12}>
                  <FormControl className="shift-fields">
                    <TextField
                      id="shift-startDate"
                      data-cy="startDate"
                      type="date"
                      className="shiftstartDate"
                      variant="outlined"
                      name="startDate"
                      InputLabelProps={{ shrink: true }}
                      label="Start Date"
                      placeholder={'YYYY-MM-DD'}
                      // value={isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.shiftEntity.startTime)}
                      value={shiftEntity.start_date}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item md={12} container>
                <Grid item md={12} xs={12}>
                  <FormControl className="shift-fields">
                    <TextField
                      id="shift-endDate"
                      data-cy="endDate"
                      type="date"
                      className="shiftendDate"
                      variant="outlined"
                      name="endDate"
                      InputLabelProps={{ shrink: true }}
                      label="End Date"
                      placeholder={'YYYY-MM-DD'}
                      // value={isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.shiftEntity.startTime)}
                      value={shiftEntity.end_date}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item md={12} container>
                <Grid item md={12} xs={12}>
                  <FormControl className="shift-fields">
                    <TextField
                      id="shift-designation"
                      data-cy="designation"
                      select
                      className="shiftdesignation"
                      variant="outlined"
                      name="designation_id"
                      // label={ <Translate contentKey="wfmApp.shift.designation">Designation</Translate>}
                      label="Position"
                      defaultValue={shiftEntity.designation}
                    >
                      <MenuItem value="" key="0" />
                      {designations
                        ? designations.map(otherEntity => (
                            <MenuItem value={otherEntity.id} key={otherEntity.id}>
                              {otherEntity.name}
                            </MenuItem>
                          ))
                        : null}
                    </TextField>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item md={12} container>
                <Grid item md={12} xs={12}>
                  <FormControl className="shift-fields">
                    <TextField
                      id="shift-serviceArea"
                      data-cy="serviceArea"
                      select
                      className="shiftserviceArea"
                      variant="outlined"
                      name="service_area_id"
                      label={<Translate contentKey="wfmApp.shift.serviceArea">Service Area</Translate>}
                      defaultValue={shiftEntity.service_area}
                    >
                      <MenuItem value="" key="0" />
                      {serviceAreas
                        ? serviceAreas.map(otherEntity => (
                            <MenuItem value={otherEntity.id} key={otherEntity.id}>
                              {otherEntity.name}
                            </MenuItem>
                          ))
                        : null}
                    </TextField>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item md={12} container>
                <Grid item md={12} xs={12}>
                  <FormControl className="shift-fields">
                    <TextField
                      id="shift-person"
                      data-cy="person"
                      select
                      className="shiftperson"
                      variant="outlined"
                      name="personId"
                      label={<Translate contentKey="wfmApp.shift.person">Person</Translate>}
                      defaultValue={shiftEntity.person}
                    >
                      <MenuItem value="" key="0" />
                      {people
                        ? people.map(otherEntity => (
                            <MenuItem value={otherEntity.id} key={otherEntity.id}>
                              {otherEntity.first_name}
                            </MenuItem>
                          ))
                        : null}
                    </TextField>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item md={12} container>
                <Grid item md={12} xs={12}>
                  <FormControl style={{ display: 'inline-block' }} className="shift-fields">
                    <Button component={Link} id="cancel-save" to="/roster" replace style={{ color: 'black', backgroundColor: '#17a2b8' }}>
                      <FontAwesomeIcon icon="arrow-left" />
                      &nbsp;
                      <span className="d-none d-md-inline">
                        <Translate contentKey="entity.action.back">Back</Translate>
                      </span>
                    </Button>
                    &nbsp;
                    <Button
                      style={{ color: 'white', backgroundColor: 'blue' }}
                      id="save-entity"
                      data-cy="entityCreateSaveButton"
                      type="submit"
                      disabled={updating}
                    >
                      <FontAwesomeIcon icon="save" />
                      &nbsp;
                      <Translate contentKey="entity.action.save">Save</Translate>
                    </Button>
                  </FormControl>
                </Grid>
              </Grid>
            </form>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  designations: storeState.designation.entities,
  serviceAreas: storeState.serviceArea.entities,
  people: storeState.person.entities,
  shiftEntity: storeState.shift.entity,
  loading: storeState.shift.loading,
  updating: storeState.shift.updating,
  updateSuccess: storeState.shift.updateSuccess,
});

const mapDispatchToProps = {
  getDesignations,
  getServiceAreas,
  getPeople,
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ShiftUpdate);
