import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { Translate, translate } from 'react-jhipster';
import { Button, Grid, FormControl, TextField } from '@material-ui/core';
import { AvForm } from 'availity-reactstrap-validation';
import './setting.scss';
import { locales, languages } from 'app/config/translation';
import { IRootState } from 'app/shared/reducers';
import { getSession } from 'app/shared/reducers/authentication';
import { saveAccountSettings, reset } from './settings.reducer';

export interface IUserSettingsProps extends StateProps, DispatchProps {}

export const SettingsPage = (props: IUserSettingsProps) => {
  useEffect(() => {
    props.getSession();
    return () => {
      props.reset();
    };
  }, []);

  const handleValidSubmit = event => {
    const account = {
      first_name: event.target.first_name.value,
      last_name: event.target.last_name.value,
      email: event.target.email.value,
      langKey: event.target.langKey.value,
    };

    props.saveAccountSettings(account);
    event.persist();
  };

  return (
    <div className="accountssetting-full-container ">
      <Grid item md={12} container justify="center">
        <Grid item md={2}></Grid>
        <Grid item md={8} spacing={2}>
          <h2 id="settings-title">
            <Translate contentKey="settings.title" interpolate={{ username: props.account.login }}>
              User settings for {props.account.login}
            </Translate>
          </h2>
          <form id="settings-form" onSubmit={handleValidSubmit} className="accountssetting-form">
            <Grid item md={12} container>
              <Grid item md={12} xs={12}>
                <FormControl className="accountssetting-fields">
                  <TextField
                    name="first_name"
                    label={translate('settings.form.firstname')}
                    id="first_name"
                    variant="outlined"
                    placeholder={translate('settings.form.firstname.placeholder')}
                    required
                    inputProps={{
                      minLength: 1,
                      maxLength: 50,
                    }}
                    value={props.account.first_name}
                    data-cy="first_name"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item md={12} container>
              <Grid item md={12} xs={12}>
                <FormControl className="accountssetting-fields">
                  <TextField
                    name="last_name"
                    label={translate('settings.form.lastname')}
                    id="last_name"
                    variant="outlined"
                    placeholder={translate('settings.form.lastname.placeholder')}
                    required
                    inputProps={{
                      minLength: 1,
                      maxLength: 50,
                    }}
                    value={props.account.last_name}
                    data-cy="last_name"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item md={12} container>
              <Grid item md={12} xs={12}>
                <FormControl className="accountssetting-fields">
                  <TextField
                    name="email"
                    label={translate('global.form.email.label')}
                    placeholder={translate('global.form.email.placeholder')}
                    type="email"
                    variant="outlined"
                    required
                    inputProps={{
                      minLength: 5,
                      maxLength: 254,
                    }}
                    value={props.account.email}
                    data-cy="email"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item md={12} container>
              <Grid item md={12} xs={12}>
                <FormControl className="accountssetting-fields">
                  <TextField
                    select
                    id="langKey"
                    variant="outlined"
                    name="langKey"
                    label={translate('settings.form.language')}
                    value={props.account.langKey}
                    data-cy="langKey"
                  >
                    {locales.map(locale => (
                      <option value={locale} key={locale}>
                        {languages[locale].name}
                      </option>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item md={12} container>
              <Grid item md={12} xs={12}>
                <FormControl style={{ display: 'block' }} className="accountssetting-fields">
                  <Button style={{ color: 'white', backgroundColor: '#3f51b5' }} type="submit" data-cy="submit">
                    <Translate contentKey="settings.form.button">Save</Translate>
                  </Button>
                </FormControl>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = ({ authentication }: IRootState) => ({
  account: authentication.account,
  isAuthenticated: authentication.isAuthenticated,
});

const mapDispatchToProps = { getSession, saveAccountSettings, reset };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
