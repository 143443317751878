import React from 'react';
import './timeclock.scss';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

export interface ICircularBarComponent {
    shiftCounts
}


const CircularBarComponent = (props : ICircularBarComponent) => {

    const getColorCode = (index) => {
        if(index === 'completed_shifts') {
            return '#4820B8';
        }else if(index === 'running_shifts') {
            return '#3DD764';
        }else if(index === 'on_break_shifts') {
            return '#F7B017';
        }
    }

    const formatKey = (key) => {
      return key.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
    };

  return (

    <div className = 'circular-progress-component-div' >
      {Object.entries(props.shiftCounts).map(([key, value,]) => (
        <div key={key}  className = 'circular-progress-bar-div' >
          <CircularProgressbar
            value={Number(value)}
            text={String(value)}
            maxValue = {props.shiftCounts['total_live_shifts']}
            strokeWidth={8}
            counterClockwise
            styles={buildStyles({
              strokeLinecap: 'butt',
              textSize: '24px',
              pathTransitionDuration: 0.5,
              pathColor: getColorCode(key),
              textColor: '#000000',
              trailColor: '#d6d6d6',
              backgroundColor: '#3e98c7',
            })}

          />
          <div style={{textAlign: 'center', padding: 20, fontSize : '14px'  }}>  {formatKey(key)}</div>
        </div>
      ))}
    </div>

  );
};
export default React.memo(CircularBarComponent);
