import React, { useState } from 'react';
import forwardIcon from '../../../../content/images/forwardIcon.png';
import { Grid, Paper, makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Divider from '@material-ui/core/Divider';
import { faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import '../servicearea.scss';

const ServiceAreaTeamActiveEmployees = props => {
  const { isEdit } = props;
  const [openDialog, setOpenDialog] = useState(false);
  const [deletingPersonId, setDeletingPersonId] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState('');

  const handleEditClose = person_id => {
    props.history.push(`/staff/${person_id}/edit`);
  };
  const handleRedirect = person_id => {
    // debugger;
    // console.log(props);
    props.history.push(`/staff/${person_id}`);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const confirmDelete = () => {
    if (deletingPersonId) {
      props.deleteStaffEntity(props.serviceAreaEntity?.id, deletingPersonId);
    }
    setOpenDialog(false);
  };

  const handleDeleteClick = entity => {
    setDeletingPersonId(entity.id);

    setDeleteMessage(`${entity.first_name} ${entity.last_name} will be removed from this Service Area`);
    setOpenDialog(true);
  };
  return (
    <Grid container spacing={2} className="Card-Container" style={{ display: 'flex', flexWrap: 'wrap' }}>
      {props && props.activePersonServiceArea && props.activePersonServiceArea
        ? props.activePersonServiceArea.map((entity, i) => (
            <Grid item lg={6} md={6} sm={6} xs={6} key={i}>
              <Paper elevation={2} className="service-area-component-main-container">
                <div className="service-area-team-component-text-container">
                  <div className="service-area-component-name-div">{entity.first_name + ' ' + entity.last_name}</div>

                  <div className="service-area-component-address-div">
                    {/* <EmailIcon className="service-area-component-location-icon" /> */}
                    {/* {entity.contacts ? entity.contacts[0] && entity.contacts[0].value : ''} */}
                    {props.serviceAreaEntity.designation.name || 'No designation found'}
                  </div>
                  {/* <div className="service-area-component-address-div">
                  <PhoneIcon className="service-area-component-location-icon" />{' '}
                  {entity.contacts ? entity.contacts[1] && entity.contacts[1].value : ''}
                </div> */}
                  {isEdit ? (
                    <div className="service-area-component-icons-container">
                      {/* <FontAwesomeIcon icon={faEdit} className="team-component-edit-icon" onClick={() => handleEditClose(entity.id)} /> */}
                      {/* &nbsp; */}
                      {/* <Divider orientation="vertical" className="service-area-component-icons-divider" flexItem /> */}
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        color="#bebebe"
                        style={{ cursor: 'pointer', marginLeft: '5px' }}
                        onClick={() => handleDeleteClick(entity)}
                      />
                      {/* <FontAwesomeIcon icon={faTrashAlt} className="team-component-delete-icon" onClick={() => handleDeleteClick(entity)} /> */}
                    </div>
                  ) : null}
                </div>

                <div className="redirect_icon">
                  <img
                    src={forwardIcon}
                    alt="Forward icon"
                    style={{
                      cursor: 'pointer',
                      fontFamily: 'Roboto',
                      width: '12px',
                      height: '12px',
                      opacity: 1,
                      marginLeft: '250px',
                      marginTop: '13px',
                    }}
                    onClick={() => handleRedirect(entity.id)}
                  />
                </div>
              </Paper>
            </Grid>
          ))
        : !props.loading && <div className="no-record-warning">No Employee found</div>}

      <Divider style={{ margin: '4% 0' }} />

      <Dialog open={openDialog} onClose={handleClose} className="DeleteBox">
        <DialogTitle data-cy="serviceAreaDeleteDialogHeading">
          <span>{deleteMessage}</span>
        </DialogTitle>
        <DialogContent id="wfmApp.serviceArea.delete.question">
          <span>Are you sure you want to continue?</span>
        </DialogContent>
        <DialogActions>
          <Button style={{ color: 'white', backgroundColor: '#14a37f' }} onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button style={{ color: 'white', backgroundColor: '#b71c1c' }} onClick={confirmDelete} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default withRouter(ServiceAreaTeamActiveEmployees);
