import { exportPDF } from '../timesheet-summary/exportPDF';

const header = ['Incident Date / Time', 'Incident Type', 'Name', 'Service Area'];

export const convertToPDF = (jsonData, selectedDateFrom, selectedDateTo) => {
  const headers = [header];
  const title = 'Incident Management Report';
  const data = jsonData.map(row => [
    row.incident_time && row.incident_time,
    row.incident_type && row.incident_type.name,
    row.person && row.person.first_name + ' ' + row.person.last_name,
    row.service_area && row.service_area.name,
  ]);
  const filename = 'Incident Report.pdf';

  exportPDF(selectedDateFrom, selectedDateTo, headers, title, data, filename);
};
