export interface IApplicantReferenceVerification {
  id?: string | null;
  appliedFor?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  jobTitle?: string | null;
  organization?: string | null;
  referee?: string | null;
}

export const defaultValue: IApplicantReferenceVerification = {};
