import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Sidebar } from './components/sideBar/sidebar';
import { AboutPosition } from './components/formSections/aboutPosition';
import { AboutYou } from './components/formSections/aboutYou';
import { Education } from './components/education';
import { ProfessionalHistory } from './components/formSections/professionalHistory';
import { Ethnicity } from './components/ethnicity';
import { MedicalDetails } from './components/formSections/medicalDetails';
import { References } from './components/references';
import { DataProtection } from './components/formSections/dataProtection';
import { DocumentsRequired } from './components/formSections/documentsRequired';
import { Declaration } from './components/formSections/declaration';
import {
  storeApplicationForm,
  storeAboutPositionSection,
  updateAboutPositionSection,
  handleIncrementDecrement,
  formToggle,
  storePersonalDetailsSection,
  updatePersonalDetailsSection,
  storeEmploymentHistory,
  updateEmploymentHistory,
  storeVettingConsent,
  storeRequiredDocuments,
  updateEntity,
  getEmployeeFormInformation,
  aboutPositionFormReset,
  getFormDetail,
  getEmployeeFormInformationForUser
} from '../applicationForm.reducer';
import Grid from '@material-ui/core/Grid';
import BarWave from 'react-cssfx-loading/lib/BarWave';
import Divider from '@material-ui/core/Divider';
import './form.scss';
import { IRootState } from 'app/shared/reducers';
import { getPersonalSettings } from '../../../modules/Setting/Personal-Setting.reducer';
import { RouteComponentProps, useHistory, useParams } from 'react-router-dom';
import { useLocation } from 'react-router';
import { parseInt } from 'lodash';
import { Storage } from 'react-jhipster';

const useStyles = makeStyles(theme => ({
  root: {
    width: 'fit-content',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    '& svg': {
      margin: theme.spacing(1.5),
    },
    '& hr': {
      margin: theme.spacing(0, 0.5),
    },
  },
}));

export const Form = props => {
  const location = useLocation();
  const urlId = parseInt(location.pathname.split('/').pop());
  const [isNew] = useState(!urlId);
  const classes = useStyles();
  const [provideReference, setProvideReference] = useState(false);
  const [professionalHistory, setProfessionalHistory] = useState({ start_date: '', end_date: '' });
  const [educationHistory, setEducationHistory] = useState({ edu_start_date: '', edu_end_date: '' });
  const [componentArr, setComponentsArray] = useState(['item-1']);
  const [educationComponentArr, setEducationComponentsArray] = useState(['item-1']);
  const [values, setValues] = useState({ person_id: '' });

  useEffect(() => {
    if (!isNew) {
      props.getEmployeeFormInformation(urlId);
    }
  }, [urlId]);

  useEffect(() => {
    if (isNew) {
      const id = Storage.local.get('user_id');
      props.getFormDetail(id);
    }
  }, []);

  useEffect(() => {
    if (props.formSubmitted != null) {
      if (props.formSubmitted === true) {
        props.history.push('/responseSubmitted');
      }else{
        props.getEmployeeFormInformationForUser(props.account.person_id);
      }
    }
  }, [props.formSubmitted]);

  useEffect(() => {}, [props.activeModule]);

  const handleFormData = data => {
    const object = new FormData();
    data.forEach(element => {
      object.append(element.file_name, element.file);
    });
    props.storeApplicationForm(values, object);
  };

  const submitProfessionalHistory = data => {
    const object = values;
    object.person_id = props.personalInfo.id;
    data.append('person_id', props.personalInfo.id);
    if (!isNew) {
      props.updateEmploymentHistory(object, data);
    } else {
      props.storeEmploymentHistory(object, data);
    }
  };
  const { loading, activeModule, setting, settings, employeeFormData, editEmployeeForm } = props;

  return (
    <div className="job-application-form-main-container">
      <Grid container className="job_application_form_outer_div">
        <Grid item xs={12} md={3}>
          <Sidebar {...props} />
        </Grid>
        <Grid item xs={12} md={8} className="job-application-form-sections">
          {!loading ? (
            <React.Fragment>
              {activeModule === 0 && <Declaration {...props} />}
              {activeModule === 1 && <AboutPosition {...props} />}
              {activeModule === 2 && <AboutYou {...props} />}
              {activeModule === 3 && (
                <ProfessionalHistory
                  setValues={setValues}
                  values={values}
                  provideReference={provideReference}
                  setProvideReference={setProvideReference}
                  professionalHistory={professionalHistory}
                  setProfessionalHistory={setProfessionalHistory}
                  educationHistory={educationHistory}
                  setEducationHistory={setEducationHistory}
                  componentArr={componentArr}
                  setComponentsArray={setComponentsArray}
                  educationComponentArr={educationComponentArr}
                  setEducationComponentsArray={setEducationComponentsArray}
                  submit={submitProfessionalHistory}
                  setting={setting}
                  employeeFormData={employeeFormData}
                  isEdit={editEmployeeForm}
                  settings={settings}
                />
              )}
              {activeModule === 4 && <DataProtection {...props} />}
              {activeModule === 5 && <DocumentsRequired {...props} />}
            </React.Fragment>
          ) : (
            <BarWave />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = ({ ApplicationForm, organizationSettings, PersonalSettings, authentication }: IRootState) => ({
  account: authentication.account,
  loading: ApplicationForm.loading,
  editEmployeeForm: ApplicationForm.editEmployeeForm,
  items: ApplicationForm.formSteps,
  activeModule: ApplicationForm.activeModule,
  aboutPosition: ApplicationForm.aboutPosition,
  personalInfo: ApplicationForm.personalInfo,
  employeeFormData: ApplicationForm.employeeFormData,
  settings: organizationSettings.storedOrganizationSettings,
  setting: PersonalSettings.storedPersonalSettings,
  formSubmitted: ApplicationForm.formDetails,
});

const mapDispatchToProps = {
  storeApplicationForm,
  storeAboutPositionSection,
  updateAboutPositionSection,
  handleIncrementDecrement,
  storePersonalDetailsSection,
  updatePersonalDetailsSection,
  formToggle,
  storeEmploymentHistory,
  updateEmploymentHistory,
  storeVettingConsent,
  storeRequiredDocuments,
  updateEntity,
  getPersonalSettings,
  getEmployeeFormInformation,
  aboutPositionFormReset,
  getFormDetail,
  getEmployeeFormInformationForUser,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Form);
