import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Grid, FormControl, TextField, MenuItem } from '@material-ui/core';
import { Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Translate, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import './country.scss';
import { IPerson } from 'app/shared/model/person.model';
import { getEntities as getPeople } from 'app/entities/person/person.reducer';
import { getEntity, updateEntity, createEntity, resetCountry } from './country.reducer';
import { ICountry } from 'app/shared/model/country.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface ICountryUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const CountryUpdate = (props: ICountryUpdateProps) => {
  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const { countryEntity, people, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/country');
  };

  useEffect(() => {
    if (isNew) {
      props.resetCountry();
    } else {
      props.getEntity(props.match.params.id);
    }

    props.getPeople(-1, '');
  }, []);

  useEffect(() => {}, [countryEntity]);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = event => {
    event.preventDefault();

    const entity = {
      id: event.target.id.value,
      name: event.target.name.value,
      isoCode: event.target.isoCode.value,
      addressUnitIdentifier: event.target.addressUnitIdentifier.value,
    };

    if (isNew) {
      props.createEntity(entity);
    } else {
      props.updateEntity(entity);
    }
  };

  return (
    <div className="country-full-container">
      <Grid item md={12} container justify="center">
        <Grid item md={2}></Grid>
        <Grid item md={8}>
          <h2 id="wfmApp.country.home.createOrEditLabel" data-cy="CountryCreateUpdateHeading">
            <Translate contentKey="wfmApp.country.home.createOrEditLabel">Create or edit a Country</Translate>
          </h2>
        </Grid>
      </Grid>
      <Grid item md={12} container justify="center" spacing={1}>
        <Grid item md={2}></Grid>
        <Grid item md={8} spacing={2}>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <form onSubmit={saveEntity} className="country-form">
              {!isNew ? (
                <Grid item md={12} container spacing={1}>
                  <Grid item md={12} spacing={2}>
                    <FormControl className="country-fields">
                      <TextField
                        id="country-id"
                        variant="outlined"
                        className="countryid"
                        name="id"
                        required
                        inputProps={{ readOnly: true }}
                        label={<Translate contentKey="global.field.id">ID</Translate>}
                        value={countryEntity.id}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              ) : null}
              <Grid item md={12} container spacing={1}>
                <Grid item md={12} spacing={2}>
                  <FormControl className="country-fields">
                    <TextField
                      id="country-name"
                      data-cy="name"
                      variant="outlined"
                      className="countryname"
                      name="name"
                      label={<Translate contentKey="wfmApp.country.name">Name</Translate>}
                      defaultValue={countryEntity.name}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item md={12} container spacing={1}>
                <Grid item md={12} spacing={2}>
                  <FormControl className="country-fields">
                    <TextField
                      id="country-isoCode"
                      data-cy="isoCode"
                      variant="outlined"
                      name="isoCode"
                      className="countryisoCode"
                      label={<Translate contentKey="wfmApp.country.isoCode">Iso Code</Translate>}
                      defaultValue={countryEntity.isoCode}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item md={12} container spacing={1}>
                <Grid item md={12} spacing={2}>
                  <FormControl className="country-fields">
                    <TextField
                      id="country-addressUnitIdentifier"
                      data-cy="addressUnitIdentifier"
                      name="addressUnitIdentifier"
                      variant="outlined"
                      className="countryidentifier"
                      label={<Translate contentKey="wfmApp.country.addressUnitIdentifier">Address Unit Identifier</Translate>}
                      defaultValue={countryEntity.addressUnitIdentifier}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item md={12} container spacing={1}>
                <Grid item md={12} spacing={2}>
                  <FormControl style={{ display: 'inline-block' }} className="country-fields">
                    <Button
                      component={Link}
                      id="cancel-save"
                      to="/country"
                      replace
                      style={{ color: 'black', backgroundColor: 'rgb(13, 202, 240)' }}
                    >
                      <FontAwesomeIcon icon="arrow-left" />
                      &nbsp;
                      <span className="d-none d-md-inline">
                        <Translate contentKey="entity.action.back">Back</Translate>
                      </span>
                    </Button>
                    &nbsp;
                    <Button
                      style={{ color: 'white', backgroundColor: 'blue' }}
                      id="save-entity"
                      data-cy="entityCreateSaveButton"
                      type="submit"
                      disabled={updating}
                    >
                      <FontAwesomeIcon icon="save" />
                      &nbsp;
                      <Translate contentKey="entity.action.save">Save</Translate>
                    </Button>
                  </FormControl>
                </Grid>
              </Grid>
            </form>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  people: storeState.person.entities,
  countryEntity: storeState.country.entity,
  loading: storeState.country.loading,
  updating: storeState.country.updating,
  updateSuccess: storeState.country.updateSuccess,
});

const mapDispatchToProps = {
  getPeople,
  getEntity,
  updateEntity,
  createEntity,
  resetCountry,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CountryUpdate);
