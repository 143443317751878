import React, { useEffect, useState } from 'react';
import { TextField, Grid, InputAdornment, FormControl, Paper, FormLabel, Button } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import CustomToolbarComponent from '../service-area/CustomToolbarComponent';
import './person.scss';
import { getDateFormat } from 'app/shared/util/calender-utils';
import { IPersonalSettings } from 'app/shared/model/personalSetting.model';
import { IPerson } from 'app/shared/model/person.model';
import settings from 'app/modules/account/settings/settings';
import IOSSwitch from '../../modules/Setting/RolesAndPermissionsSettings/Components/IOSswitchComponent';
import { IOrganizationQualification } from 'app/shared/model/organization-qualification.model';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

interface PersonUpdateOrganizationQualificationProps {
  setFormValues?: (data: any) => void;
  CurrencyChecker?: (data: any) => string;
  currency?: string | any;
  fieldValues?: IPerson | any;
  fieldError?: Record<string, any>;
  setting?: IPersonalSettings | any;
  handleQualificationSwitch?: (data: any) => void;
  qualificationDiv?: boolean;
  organizationQualificationList?: Array<any> | any;
  handleQualificationData?: (data: any, event: any, index: any) => void;
  handleQualificationExpiry?: (event: any, index: any, data: any) => void;
  handleQualificationProofs?: (data: any, index: any) => void;
  setFieldValues?: (data: any) => void;
  qualificationProofs?: (data: any) => void;
  setQualificationDiv?: (data: any) => void;
  isNew?: boolean;
  personEntity?: IPerson | any;
  setFiles?: (data: any) => void;
  files?: any;
  handleQualificationIndex?: (index: any) => void;
  qualificationFileName?: any;
  isEdit?: boolean;
}

const PersonUpdateOrganizationQualification: React.FC<PersonUpdateOrganizationQualificationProps> = props => {
  const [qualificationData, setQualificationData] = useState({});
  const [qualificationProofs, setQualificationProofs] = useState([]);
  const [qualificationObject, setQualificationObject] = useState([]);

  useEffect(() => {
    props.setFieldValues(oldValues => ({ ...oldValues, qualificationData }));
  }, [qualificationData]);

  useEffect(() => {
    setQualificationData(props.fieldValues['qualificationData']);
  }, []);

  useEffect(() => {
    if (props.fieldValues['qualificationData'] && props.fieldValues['qualificationData'].length > 0) {
      props.setQualificationDiv(true);
    }
    setQualificationObject(props.fieldValues['qualificationData']);
  }, [props.fieldValues]);

  useEffect(() => {
    if (qualificationProofs.length > 0) {
      const object = new FormData();
      qualificationProofs.forEach(element => {
        object.append('avatar', element.file);
      });
    }
  }, [qualificationProofs]);

  const handleQualificationData = (event, data, index) => {
    if (props.isNew) {
      setQualificationData(oldValues => ({
        ...oldValues,
        [`data-${index}`]: {
          value: event.target.value,
          expiry_date:
            props.fieldValues['qualificationData'] && props.fieldValues['qualificationData'][`data-${index}`]
              ? props.fieldValues['qualificationData'][`data-${index}`]['expiry_date']
              : null,
          qualification_type: data.name,
        },
      }));
    } else {
      if (props.personEntity.qualificationData.length > 0) {
        setQualificationData(oldValues => ({
          ...oldValues,
          [index]: {
            id: props.personEntity.qualificationData[index]?.id,
            name: 'qualification',
            person_id: props.fieldValues && props.fieldValues['id'],
            value: event.target.value,
            designation_qualification_type_id: data.id,
            expiry_date:
              props.fieldValues['qualificationData'] && props.fieldValues['qualificationData'][index]
                ? props.fieldValues['qualificationData'][index]['expiry_date']
                : null,
          },
        }));
        // const newState = [...qualificationObject];
        // newState[index]['value'] = event.target.value;
        // setQualificationObject(newState);
        // props.setFieldValues(oldValues => ({ ...oldValues, qualificationData: qualificationObject }));
      } else {
        setQualificationData(oldValues => ({
          ...oldValues,
          [index]: {
            name: 'qualification',
            person_id: props.fieldValues && props.fieldValues['id'],
            value: event.target.value,
            designation_qualification_type_id: data.id,
            expiry_date:
              props.fieldValues['qualificationData'] && props.fieldValues['qualificationData'][index]
                ? props.fieldValues['qualificationData'][index]['expiry_date']
                : null,
          },
        }));
      }
    }
  };

  const handleQualificationExpiry = (event, index, data) => {
    const d = moment(event).format('YYYY-MM-DD');
    if (props.isNew) {
      setQualificationData(oldValues => ({
        ...oldValues,
        [`data-${index}`]: {
          value:
            props.fieldValues['qualificationData'] && props.fieldValues['qualificationData'][`data-${index}`]
              ? props.fieldValues['qualificationData'][`data-${index}`]['value']
              : null,
          expiry_date: event ? d : null,
          qualification_type: data.name,
        },
      }));
    } else {
      if (props.personEntity.qualificationData.length > 0) {
        setQualificationData(oldValues => ({
          ...oldValues,
          [index]: {
            id: props.personEntity.qualificationData[index]?.id,
            name: 'qualification',
            person_id: props.fieldValues && props.fieldValues['id'],
            value:
              props.fieldValues['qualificationData'] && props.fieldValues['qualificationData'][index]
                ? props.fieldValues['qualificationData'][index]['value']
                : null,
            designation_qualification_type_id: data.id,
            expiry_date: d,
          },
        }));
        // const newState = [...qualificationObject];
        // newState[index].expiry_date = event;
        // setQualificationObject(newState);
        // props.setFieldValues(oldValues => ({ ...oldValues, qualificationData: qualificationObject }));
      } else {
        setQualificationData(oldValues => ({
          ...oldValues,
          [index]: {
            name: 'qualification',
            person_id: props.fieldValues && props.fieldValues['id'],
            value:
              props.fieldValues['qualificationData'] && props.fieldValues['qualificationData'][index]
                ? props.fieldValues['qualificationData'][index]['value']
                : null,
            designation_qualification_type_id: data.id,
            expiry_date: d,
          },
        }));
      }
    }
  };

  return (
    <React.Fragment>
      {/* <div style={{ marginLeft: '130px', marginTop: '20px' }}>
        Set organization Qualification <IOSSwitch checked={props.qualificationDiv} onChange={props.handleQualificationSwitch} />{' '}
      </div> */}

      <Paper style={{ backgroundColor: 'transparent' }}>
        {props.organizationQualificationList.map((data, index) => (
          <>
            <Divider style={{ margin: '4% 0' }} />
            <Typography className="subcontractor-main-title-heading" variant="h5" gutterBottom style={{ color: '#4720b7' }}>
              {data.name}
            </Typography>
            {/* <FormLabel component="legend" style={{ color: 'black' }}>
              {data.name}
            </FormLabel> */}
            <div className="personFieldDivsideBySide" style={{ marginBottom: '20px' }}>
              <Grid container spacing={2}>
                <Grid item lg={6} xs={12} md={6} sm={12}>
                  <FormControl>
                    <TextField
                      type="text"
                      variant="outlined"
                      onChange={() => {
                        handleQualificationData(event, data, index);
                      }}
                      label="Value"
                      name={data.name}
                      id="person_licence_number"
                      value={
                        props.fieldValues['qualificationData'] && props.fieldValues['qualificationData'][index]
                          ? props.fieldValues['qualificationData'][index]['value']
                          : props.fieldValues['qualificationData'] && props.fieldValues['qualificationData'][`data-${index}`]
                          ? props.fieldValues['qualificationData'][`data-${index}`]['value']
                          : ''
                      }
                      inputProps={{ maxLength: 16 }}
                      disabled={!props.isEdit}
                    />
                  </FormControl>
                </Grid>

                {data.expiry_required === true ? (
                  <Grid item lg={3} xs={3} md={3} sm={12} key={index}>
                    <FormControl>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          autoOk
                          disablePast={true}
                          variant="inline"
                          inputVariant="outlined"
                          label="Expiry Date"
                          format={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'DD-MM-YY'}
                          value={
                            props.fieldValues['qualificationData'] && props.fieldValues['qualificationData'][index]
                              ? props.fieldValues['qualificationData'][index]['expiry_date']
                              : props.fieldValues['qualificationData'] && props.fieldValues['qualificationData'][`data-${index}`]
                              ? props.fieldValues['qualificationData'][`data-${index}`]['expiry_date']
                              : ''
                          }
                          ToolbarComponent={prop => (
                            <>
                              <CustomToolbarComponent {...prop} settingFormat={props.setting.date_format} />
                            </>
                          )}
                          placeholder={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'MM/DD/YYYY'}
                          InputAdornmentProps={{ position: 'start' }}
                          onChange={date => handleQualificationExpiry(date, index, data)}
                          error={false}
                          helperText=""
                          disabled={!props.isEdit}
                        />{' '}
                      </MuiPickersUtilsProvider>
                    </FormControl>
                  </Grid>
                ) : null}

                {data.proof_required === true ? (
                  <Grid item xs={3} md={3} sm={3} style={{ textAlign: 'center' }} key={index}>
                    <FormControl className="PersonAttachProofButton">
                      <input
                        type="file"
                        id="proof-qualify"
                        name={`qualification-proof-${index}`}
                        onChange={() => {
                          props.handleQualificationProofs(event, index);
                        }}
                        style={{ display: 'none' }}
                        disabled={!props.isEdit}
                      />
                      <label htmlFor="proof-qualify">
                        <Button
                          variant="contained"
                          className="personAttachProofButton"
                          component="span"
                          onClick={() => {
                            props.handleQualificationIndex(index);
                          }}
                        >
                          Please Attach Proof
                        </Button>
                        <span style={{ fontSize: '12px' }}>{props.qualificationFileName[`file-${index}`]}</span>
                      </label>
                    </FormControl>
                  </Grid>
                ) : null}
              </Grid>
            </div>
          </>
        ))}
      </Paper>
    </React.Fragment>
  );
};
export default PersonUpdateOrganizationQualification;
