import axios from 'axios';
import { Storage, ICrudGetAllAction } from 'react-jhipster';
import { IOrganizationQualification } from 'app/shared/model/organization-qualification.model';
import moment from 'moment-timezone';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { ILeave, ILeaveType, ILeaveCategory, ILeavePayment } from 'app/shared/model/leave-management';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { toast, ToastContainer } from 'react-toastify';

export const ACTION_TYPES = {
  STORE_LEAVE_TYPE: 'leave/STORE_LEAVE_TYPE',
  FETCH_LEAVE_TYPE: 'leave/FETCH_LEAVE_TYPE',
  STORE_LEAVE_CATEGORY: 'leave/STORE_LEAVE_CATEGORY',
  FETCH_LEAVE_CATEGORY: 'leave/FETCH_LEAVE_CATEGORY',
  STORE_LEAVE_PAYMENT: 'leave/STORE_LEAVE_PAYMENT',
  FETCH_LEAVE_PAYMENT: 'leave/FETCH_LEAVE_PAYMENT',

  RESET: 'leave/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  updateSuccess: false,
  successfullyCreated: false,
  leave_type: [] as Array<ILeaveType>,
  leave_type_list: [] as Array<ILeaveType>,
  leave_category: [] as Array<ILeaveCategory>,
  leave_category_list: [] as Array<ILeaveCategory>,
  leave_payment: [] as Array<ILeavePayment>,
  leave_payment_list: [] as Array<ILeavePayment>,
  attachment_list: [],
};

export type LeaveTypeState = Readonly<typeof initialState>;

export default (state: LeaveTypeState = initialState, action): LeaveTypeState => {
  switch (action.type) {
    case ACTION_TYPES.STORE_LEAVE_TYPE:
      return {
        ...state,
        leave_type: action.payload,
      };

    case REQUEST(ACTION_TYPES.STORE_LEAVE_TYPE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
      };

    case SUCCESS(ACTION_TYPES.STORE_LEAVE_TYPE):
      return {
        ...state,
        updateSuccess: true,
        leave_type: action.payload.data,
      };

    // case ACTION_TYPES.FETCH_LEAVE_TYPE:
    //   return {
    //     ...state,
    //     leave_type_list: action.payload.data,
    //   };

    // case REQUEST(ACTION_TYPES.FETCH_LEAVE_TYPE):
    //   return {
    //     ...state,
    //     errorMessage: null,
    //     updateSuccess: false,
    //     loading: true,
    //   };

    // case SUCCESS(ACTION_TYPES.FETCH_LEAVE_TYPE):
    //   return {
    //     ...state,
    //     loading: false,
    //     leave_type_list: action.payload.data,
    //   };

    case ACTION_TYPES.FETCH_LEAVE_TYPE:
      return {
        ...state,
        leave_type_list: action.payload.data,
      };

    case REQUEST(ACTION_TYPES.FETCH_LEAVE_TYPE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };

    case SUCCESS(ACTION_TYPES.FETCH_LEAVE_TYPE):
      return {
        ...state,
        loading: false,
        leave_type_list: action.payload.data,
      };

    case ACTION_TYPES.FETCH_LEAVE_CATEGORY:
      return {
        ...state,
        leave_category_list: action.payload.data,
      };

    case REQUEST(ACTION_TYPES.FETCH_LEAVE_CATEGORY):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };

    case SUCCESS(ACTION_TYPES.FETCH_LEAVE_CATEGORY):
      return {
        ...state,
        loading: false,
        leave_category_list: action.payload.data,
      };

    case ACTION_TYPES.FETCH_LEAVE_PAYMENT:
      return {
        ...state,
        leave_payment_list: action.payload.data,
      };

    case REQUEST(ACTION_TYPES.FETCH_LEAVE_PAYMENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };

    case SUCCESS(ACTION_TYPES.FETCH_LEAVE_PAYMENT):
      return {
        ...state,
        loading: false,
        leave_payment_list: action.payload.data,
      };

    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrlLeaveType = 'api/leaveType';
export const createLeaveType = values => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.STORE_LEAVE_TYPE,
    payload: axios.post(`${apiUrlLeaveType}`, values),
  });

  toast.success('Leave Type Created Successfully');
  return result;
};

const apiUrLeaveCategory = 'api/leaveCategory';
export const createLeaveCategory = values => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.STORE_LEAVE_CATEGORY,
    payload: axios.post(`${apiUrLeaveCategory}`, values),
  });

  toast.success('Leave Category Created Successfully');
  return result;
};

const apiUrLeavePayment = 'api/leavePayment';
export const createLeavePayment = values => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.STORE_LEAVE_PAYMENT,
    payload: axios.post(`${apiUrLeavePayment}`, values),
  });

  toast.success('Leave Payment Created Successfully');
  return result;
};

export const getLeaveTypes = () => ({
  type: ACTION_TYPES.FETCH_LEAVE_TYPE,
  payload: axios.get(`api/leaveType`),
});

export const getLeaveCategory = () => ({
  type: ACTION_TYPES.FETCH_LEAVE_CATEGORY,
  payload: axios.get(`api/leaveCategory`),
});

export const getLeavePayment = () => ({
  type: ACTION_TYPES.FETCH_LEAVE_PAYMENT,
  payload: axios.get(`api/leavePayment`),
});

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
