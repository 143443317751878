import { IActionRequired, defaultValue } from 'app/shared/model/actionsRequired.model';
import React, { useState, useEffect } from 'react';
import { Button, Divider } from '@material-ui/core';
import { IRootState } from 'app/shared/reducers';
import { getNotifications } from './Alerts&Notifications.reducer';
import { connect } from 'react-redux';
import moment from 'moment';
import '../home.scss';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';

export interface IAlertsAndNotifications extends StateProps, DispatchProps {}
export interface IAlertsAndNotificationsSia {
  className?: string;
  title?: string;
  count?: number;
  description?: string;
  shouldExpand?: boolean;
  action?: (value: boolean) => void;
  data?: IActionRequired[];
}

function AlertsAndNotificationsSiaCards(props) {
  return (
    <div
      className="alerts-and-notifications-sia-licence-card-main-div"
      onClick={() => {
        props.action(!props.shouldExpand);
      }}
    >
      {!props.shouldExpand && (
        <div className={`alerts-and-notifications-card-div ${props.className}`}>
          <div className="alerts-and-notifications-card-description"> {props.description} </div>
          <div className="alerts-and-notifications-card-counter"> {props.count} </div>
        </div>
      )}
      {props.shouldExpand && (
        <div className="alerts-and-notification-sia-expand-card-div">
          <div className={`alerts-and-notification-sia-expand-card-color-pattern-div ${props.className}`}></div>
          <div className="alerts-and-notifications-sia-expand-header">
            <div className="alerts-and-notifications-sia-expand-header-description"> {props.description} </div>
            <div className="alerts-and-notifications-sia-expand-header-count"> {props.count} </div>
          </div>
          {props.data &&
            props.data.length > 0 &&
            props.data.map((item, index) => (
              <div key={index}>
                <Divider />
                <div className="alerts-and-notifications-sia-expanded-row-div">
                  <div className="alerts-and-notifications-sia-expanded-row-date"> {moment(new Date(item.date)).format('LL')}</div>
                  <div className="alerts-and-notifications-sia-expanded-row-name">
                    {item.person.first_name + ' ' + item.person.last_name}
                  </div>
                  {item.type !== 'SIGN IN MISSED' ? (
                    <>
                      <div className="alerts-and-notifications-sia-expanded-row-qualification">{item.type}</div>
                    </>
                  ) : null}
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

function AlertsAndNotifications(props: IAlertsAndNotifications) {
  const [licenceExpired, setLicenceExpired] = useState([]);
  const [expandLicenceExpired, setExpandLicenceExpired] = useState(false);
  const [licenceExpiringIn15Days, setLicenceExpiringIn15Days] = useState([]);
  const [expandLicenceExpiringIn15Days, setExpandLicenceExpiringIn15Days] = useState(false);
  const [licenceExpiringIn30Days, setLicenceExpiringIn30Days] = useState([]);
  const [expandLicenceExpiringIn30Days, setExpandLicenceExpiringIn30Days] = useState(false);
  const [signInMissedNotifications, setSignInMissedNotifications] = useState([]);
  const [expandSignInMissed, setExpandSignInMissed] = useState(false);

  useEffect(() => {
    if (props.isSystemAdmin || props.isOrganizationAdminstrator || props.isSupervisor || props.isManager) {
      props.getNotifications();
    }
  }, []);

  useEffect(() => {
    if (props.notifications.length > 0) {
      filterNotificationsByExpiryDate(props.notifications);
    }
  }, [props.notifications]);

  const filterNotificationsByExpiryDate = notifications => {
    setLicenceExpired(notifications.filter(notification => notification.days_to_expire === 0 && notification.type !== 'SIGN IN MISSED'));
    setLicenceExpiringIn15Days(notifications.filter(notification => notification.days_to_expire === 15));
    setLicenceExpiringIn30Days(notifications.filter(notification => notification.days_to_expire === 30));
    setSignInMissedNotifications(notifications.filter(notification => notification.type === 'SIGN IN MISSED'));
  };

  return (
    <div className="alerts-and-notifications-outer-div">
      <div className="alerts-and-notifications-header-div">Alerts</div>
      <Divider style={{ marginBottom: '3%', marginTop: '6%' }} />
      {props.notifications && props.notifications.length > 0 ? (
        <React.Fragment>
          <AlertsAndNotificationsSiaCards
            className="employee-sign-in-missed-alerts"
            title="Sign In Missed"
            count={signInMissedNotifications.length}
            description={'Sign in Missed'}
            data={signInMissedNotifications}
            shouldExpand={expandSignInMissed}
            action={setExpandSignInMissed}
          />
          <AlertsAndNotificationsSiaCards
            className="employee-qualification-expired-card"
            title="SIA Licence"
            count={licenceExpired.length}
            description={'Employee Qualifications Expired'}
            data={licenceExpired}
            shouldExpand={expandLicenceExpired}
            action={setExpandLicenceExpired}
          />
          <AlertsAndNotificationsSiaCards
            className="employee-qualification-expired-15-days-card"
            title="SIA Licence"
            count={licenceExpiringIn15Days.length}
            description={'Employee Qualifications going to be expired in 15 days'}
            data={licenceExpiringIn15Days}
            shouldExpand={expandLicenceExpiringIn15Days}
            action={setExpandLicenceExpiringIn15Days}
          />
          <AlertsAndNotificationsSiaCards
            className="employee-qualification-expired-30-days-card"
            title="SIA Licence"
            count={licenceExpiringIn30Days.length}
            description={'Employee Qualifications going to be expired in 30 days'}
            data={licenceExpiringIn30Days}
            shouldExpand={expandLicenceExpiringIn30Days}
            action={setExpandLicenceExpiringIn30Days}
          />
        </React.Fragment>
      ) : (
        <div></div>
      )}
    </div>
  );
}

const mapStateToProps = (storeState: IRootState) => ({
  notifications: storeState.ActionRequired.entities,
  isSystemAdmin: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.SYSTEM_ADMIN]),
  isOrganizationAdminstrator: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.ORGANIZATION_ADMINISTRATOR]),
  isSupervisor: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.SUPERVISOR]),
  isManager: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.MANAGER]),
});

const mapDispatchToProps = {
  getNotifications,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AlertsAndNotifications);
