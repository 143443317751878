import dayjs from 'dayjs';
import { IPerson } from 'app/shared/model/person.model';

export interface IEducation {
  id?: number;
  degreeTitle?: string | null;
  endYear?: string | null;
  startYear?: string | null;
  candidateEducations?: IPerson[] | null;
}

export const defaultValue: Readonly<IEducation> = {};
