import dayjs from 'dayjs';
import { IDesignation } from 'app/shared/model/designation.model';
import { IServiceArea } from 'app/shared/model/service-area.model';
import { IPerson } from 'app/shared/model/person.model';
import { IClient } from 'app/shared/model/client.model';

export interface IShift {
  id?: number;
  start_date?: string | null;
  end_date?: string | null;
  start_time?: string | null;
  end_time?: string | null;
  designation?: IDesignation | null;
  service_area?: IServiceArea | null;
  location?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  person?: IPerson | null;
  shiftId?: number;
  charge_rate?: number | null;
  pay_rate?: number | null;
  ClientInfo?: IClient[] | null;
}

export const defaultValue: Readonly<IShift> = {};
