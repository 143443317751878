import React from 'react';
import { Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import './PeoplePositions.scss';
import PeoplePositionsComponent from '../Component/PeoplePositionsComponent';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IOSSwitch from '../../RolesAndPermissionsSettings/Components/IOSswitchComponent';
import { storePeoplePositionSettings } from '../peopleSettings.reducer';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { getPeopleGeneralSettings } from '../peopleSettings.reducer';
import { deletePositionSetting } from '../peopleSettings.reducer';

export interface IPeopleSettingsProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const PeoplePositions = (props: IPeopleSettingsProps) => {
  const [open, setOpen] = React.useState(false);
  const [positionName, setPositionName] = useState('');
  const [shortCode, setshortCode] = useState('');
  const [chargeRate, setChargeRate] = useState('');
  const [payRate, setPayRate] = useState('');

  useEffect(() => {
    props.getPeopleGeneralSettings();
  }, []);

  useEffect(() => {}, [props.settings]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const submit = () => {
    const setting = {
      name: positionName,
      short_code: shortCode,
      charge_rate: chargeRate,
      pay_rate: payRate,
    };
    props.storePeoplePositionSettings(setting);
    setOpen(false);
  };

  const deletePositionRecords = id => {
    props.deletePositionSetting(id);
  };

  return (
    <div className="people-positions-setting-external-div">
      <div className="people-positions-heading-div">
        <div className="people-positions-main-heading">
          <Typography variant="h5" className="people-positions-heading-main-title">
            Positions
          </Typography>
        </div>
        <div className="people-positions-heading-message">Edit your information below</div>
      </div>

      <Paper className="people-positions-paper" elevation={2} square>
        <div className="people-positions-external-div">
          <table className="people-positions-table">
            <tr className="people-positions-table-heading-row">
              <td className="people-positions-table-column-1">COLOUR</td>
              <td className="people-positions-table-column-2">NAME</td>
              <td className="people-positions-table-column-3">SHORT CODE</td>
              <td className="people-positions-table-column-4">PAY RATE</td>
              <td className="people-positions-table-column-5">CHARGE RATE</td>
              <td className="people-positions-table-column-6"></td>
            </tr>
            {props.settings.positions && props.settings.positions.length > 0 ? (
              props.settings.positions.map((setting, index) => (
                <PeoplePositionsComponent
                  key={index}
                  msg={setting['name']}
                  msg2={setting['short_code']}
                  msg3={setting['pay_rate']}
                  msg4={setting['charge_rate']}
                  id={setting.id}
                  remove={deletePositionRecords}
                />
              ))
            ) : (
              <div>no records</div>
            )}

            <button
              type="button"
              id="people-position-button"
              onClick={handleClickOpen}
              style={{ backgroundColor: '#4720b7', border: '1px solid #4720b7', marginTop: '20%' }}
              className="btn btn-success btn-sm"
            >
              Add Positions
            </button>

            <Dialog
              className="position-dialog-main-box"
              open={open}
              onClose={handleClose}
              aria-labelledby="form-dialog-title"
              fullWidth={true}
            >
              <DialogTitle className="position-popup-title">
                {' '}
                <a style={{ color: '#6610f2' }}>Add Positions</a>
              </DialogTitle>
              <DialogContent className="position-popup-content-div">
                <div className="position-dialog-name-div" style={{ display: 'flex' }}>
                  <tr style={{ width: '20%', padding: '0' }}>
                    <td>
                      <p style={{ marginTop: '15px' }}>Name*: &nbsp; </p>
                    </td>
                  </tr>

                  <TextField
                    className="dialog-name-text"
                    autoFocus
                    margin="dense"
                    id="standard-full-width"
                    type="text"
                    variant="outlined"
                    onChange={event => {
                      setPositionName(event.target.value);
                    }}
                  />
                </div>
                <div className="position-dialog-code-div" style={{ display: 'flex' }}>
                  <tr style={{ width: '20%', padding: '0' }}>
                    <td>
                      <p style={{ marginTop: '15px' }}>Short Code: &nbsp; </p>
                    </td>
                  </tr>
                  <TextField
                    className="popup-text"
                    autoFocus
                    margin="dense"
                    id="standard-full-width"
                    type="text"
                    variant="outlined"
                    onChange={event => {
                      setshortCode(event.target.value);
                    }}
                    inputProps={{ maxLength: 3 }}
                  />

                  <p style={{ marginTop: '16px', fontSize: '12px' }}> &nbsp; Max 3 Characters</p>
                </div>

                <div className="position-dialog-code-div" style={{ display: 'flex' }}>
                  <tr style={{ width: '20%', padding: '0' }}>
                    <td>
                      <p style={{ marginTop: '15px', textAlign: 'left' }}>Pay Rate: &nbsp; </p>
                    </td>
                  </tr>
                  <TextField
                    className="position-dialog-payrate-textfield"
                    autoFocus
                    margin="dense"
                    id="standard-full-width"
                    type="number"
                    variant="outlined"
                    onChange={event => {
                      setPayRate(event.target.value);
                    }}
                  />
                  <p style={{ marginTop: '16px', fontSize: '12px' }}> &nbsp; default payout rate for people</p>
                </div>

                <div className="position-dialog-code-div" style={{ display: 'flex' }}>
                  <tr style={{ width: '20%', padding: '0' }}>
                    <td>
                      <p style={{ marginTop: '15px', textAlign: 'left' }}>Charge Rate: &nbsp; </p>
                    </td>
                  </tr>

                  <TextField
                    className="position-dialog-chargerate-textfield"
                    autoFocus
                    margin="dense"
                    id="standard-full-width"
                    type="number"
                    variant="outlined"
                    onChange={event => {
                      setChargeRate(event.target.value);
                    }}
                  />
                  <p style={{ marginTop: '16px', fontSize: '12px' }}> &nbsp; default charge rate for people</p>
                </div>

                {/* <input type="color" id="head" name="head" value="#2E8B57"/> */}
              </DialogContent>
              <DialogActions>
                <button
                  type="button"
                  className="btn btn-success btn-sm"
                  style={{ marginRight: '75%', backgroundColor: '#4720b7', border: '1px solid #4720b7' }}
                  onClick={submit}
                >
                  Save
                </button>
                <button type="button" className="btn btn-outline-secondary btn-sm" style={{ marginRight: '2%' }} onClick={handleClose}>
                  Cancel
                </button>
              </DialogActions>
            </Dialog>
          </table>
        </div>
      </Paper>
    </div>
  );
};

const mapStateToProps = ({ PeopleSetting }) => ({
  settings: PeopleSetting.storedPeopleSettings,
  loading: PeopleSetting.loading,
});

const mapDispatchToProps = {
  storePeoplePositionSettings,
  getPeopleGeneralSettings,
  deletePositionSetting,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PeoplePositions);
