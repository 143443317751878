import React from 'react';
import { Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import './PeopleQualification.scss';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IOSSwitch from '../../RolesAndPermissionsSettings/Components/IOSswitchComponent';
import { storePeopleQualificationSettings } from '../peopleSettings.reducer';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { useState, useEffect } from 'react';
import PeopleQualificationComponent from '../Component/PeopleQualificationComponent';
import { getPeopleGeneralSettings } from '../peopleSettings.reducer';

export interface IPeopleSettingsProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const PeopleQualification = (props: IPeopleSettingsProps) => {
  const [open, setOpen] = React.useState(false);
  const [qualificationName, setName] = useState('');
  const [qualificationProvider, setProvider] = useState('');

  useEffect(() => {
    props.getPeopleGeneralSettings();
  }, []);

  useEffect(() => {}, [props.settings]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const submit = () => {
    const setting = {
      name: qualificationName,
      provider: qualificationProvider,
    };
    props.storePeopleQualificationSettings(setting);
    setOpen(false);
  };
  return (
    <div className="person-qualification-settings-external-div">
      <div className="person-qualification-settings-heading-div">
        <div className="person-qualification-settings-main-heading">
          <Typography variant="h5" className="person-qualification-settings-heading-main-title">
            People Qualification Settings
          </Typography>
        </div>
        <div className="person-qualification-settings-heading-message">Edit your information below</div>
      </div>
      <Paper elevation={2} square style={{ width: '90%', height: 'auto', margin: 'auto' }}>
        <div className="people-qualification-settings-external-div">
          <table style={{ width: '100%', margin: 'auto' }}>
            <tr>
              <td>
                <p style={{ fontSize: '18px', marginLeft: '10px', color: 'grey' }}>QUALIFICATIONS</p>
              </td>
            </tr>
            <tr style={{ marginBottom: '3%', width: '100%', backgroundColor: '#DCDCDC' }}>
              <td style={{ width: '5%', textAlign: 'left', color: 'grey', fontSize: '14px' }}>COLOUR</td>
              <td style={{ width: '20%', textAlign: 'left', color: 'grey', fontSize: '14px' }}>NAME</td>
              <td style={{ width: '15%', textAlign: 'left', color: 'grey', fontSize: '14px' }}>PROVIDER</td>
              <td style={{ width: '25%', color: 'grey', fontSize: '14px' }}>PEOPLE CAN ADD & EDIT</td>
              <td style={{ width: '25%', color: 'grey', fontSize: '14px' }}>INDUSRTY STANDARD</td>
              <td style={{ width: '10%' }}></td>
            </tr>
            <br />
            {props.settings.qualifications && props.settings.qualifications.length > 0 ? (
              props.settings.qualifications.map((setting, index) => (
                <PeopleQualificationComponent key={index} msg={setting['name']} msg2={setting['provider']} />
              ))
            ) : (
              <div>no records</div>
            )}

            <br />

            <tr style={{ borderBottom: 'unset' }}>
              <td>
                <button
                  type="button"
                  className="btn btn-success"
                  style={{ backgroundColor: '#4720b7', border: '1px solid #4720b7' }}
                  onClick={handleClickOpen}
                >
                  Add Qualification
                </button>
              </td>
            </tr>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
              <DialogTitle className="qualification-popup-title">
                {' '}
                <a style={{ color: '#6610f2' }}>Add Qualification</a>
              </DialogTitle>
              <DialogContent className="qualification-popup-content-div">
                <span>Name* </span>:
                <TextField
                  className="popup-text"
                  autoFocus
                  margin="dense"
                  id="standard-full-width"
                  type="text"
                  variant="outlined"
                  fullWidth
                  onChange={event => {
                    setName(event.target.value);
                  }}
                />
                Provider:
                <TextField
                  className="popup-text"
                  autoFocus
                  margin="dense"
                  id="standard-full-width"
                  type="text"
                  variant="outlined"
                  fullWidth
                  onChange={event => {
                    setProvider(event.target.value);
                  }}
                />
                <tr>
                  <td>People can add & edit*</td>
                  <td>
                    <IOSSwitch />
                  </td>
                  <br />
                </tr>
                <tr>
                  <td style={{ textAlign: 'left' }}>Industry Standard*</td>
                  <td>
                    <IOSSwitch />
                  </td>
                </tr>
              </DialogContent>
              <DialogActions>
                <button
                  type="button"
                  className="btn btn-success btn-sm"
                  style={{ marginRight: '75%', backgroundColor: '#4720b7', border: '1px solid #4720b7' }}
                  onClick={submit}
                >
                  Save
                </button>
                <button type="button" className="btn btn-outline-secondary btn-sm" style={{ marginRight: '2%' }} onClick={handleClose}>
                  Cancel
                </button>
              </DialogActions>
            </Dialog>
          </table>
        </div>
      </Paper>
    </div>
  );
};

const mapStateToProps = ({ PeopleSetting }) => ({
  settings: PeopleSetting.storedPeopleSettings,
  loading: PeopleSetting.loading,
});

const mapDispatchToProps = {
  storePeopleQualificationSettings,
  getPeopleGeneralSettings,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PeopleQualification);
