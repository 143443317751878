import axios from 'axios';
import { IPeopleSettings } from 'app/shared/model/peopleSetting.model';
import { ToastContainer, toast } from 'react-toastify';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

export const ACTION_TYPES = {
  STORE_PEOPLE_SETTINGS: 'settings/STORE_PEOPLE_SETTINGS',
  GET_PEOPLE_SETTINGS: 'settings/GET_PEOPLE_SETTINGS',
  DELETE_PEOPLE_SETTINGS: 'settings/DELETE_PEOPLE_SETTINGS',
};

const initialState = {
  setting: [] as Array<IPeopleSettings>,
  storedPeopleSettings: [] as Array<IPeopleSettings>,
  loading: false,
  errorMessage: null,
  updating: false,
  updateSuccess: false,
};

export type PeopleSettingState = Readonly<typeof initialState>;

export default (state: PeopleSettingState = initialState, action): PeopleSettingState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.GET_PEOPLE_SETTINGS):
      return {
        ...state,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.GET_PEOPLE_SETTINGS):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.STORE_PEOPLE_SETTINGS):
      return {
        ...state,
        setting: action.payload,
      };

    case SUCCESS(ACTION_TYPES.GET_PEOPLE_SETTINGS):
      return {
        ...state,
        storedPeopleSettings: action.payload.data,
        loading: false,
        errorMessage: null,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/peopleHealthSettings';
export const storePeopleHealthSettings = name => dispatch => {
  const result = axios
    .post(`${apiUrl}`, name)
    .then(res => {
      dispatch(getPeopleGeneralSettings());
    })
    .catch(err => {});
};

const apiUrlPosition = 'api/peoplePositionSettings';
export const storePeoplePositionSettings = name => dispatch => {
  const result = axios
    .post(`${apiUrlPosition}`, name)
    .then(res => {
      dispatch(getPeopleGeneralSettings());
    })
    .catch(err => {});
};

const apiUrlQualification = 'api/peopleQualificationSettings';
export const storePeopleQualificationSettings = name => dispatch => {
  const result = axios
    .post(`${apiUrlQualification}`, name)
    .then(res => {
      dispatch(getPeopleGeneralSettings());
    })
    .catch(err => {});
};

const apiUrlGeneral = 'api/peopleGeneralSettings';
export const storePeopleGeneralSettings = name => dispatch => {
  const result = axios
    .post(`${apiUrlGeneral}`, name)
    .then(res => {
      dispatch(getPeopleGeneralSettings());
    })
    .catch(err => {});
};

export const getPeopleGeneralSettings = () => ({
  type: ACTION_TYPES.GET_PEOPLE_SETTINGS,
  payload: axios.get<IPeopleSettings>(`${apiUrlGeneral}`),
});

const apiUrlEmployment = 'api/peopleEmploymentSettings';
export const storePeopleEmploymentSettings = name => dispatch => {
  const result = axios
    .post(`${apiUrlEmployment}`, name)
    .then(res => {
      dispatch(getPeopleGeneralSettings());
    })
    .catch(err => {});
};

const deleteApiUrl = 'api/deletepeopleHealthSettings';
export const deleteHealthSetting = id => dispatch => {
  let result;
  axios
    .delete(`${deleteApiUrl}/${id}`)
    .then(res => {
      result = res;
      dispatch(getPeopleGeneralSettings());
    })
    .catch(err => {});
  return result;
};

const deletePosition = 'api/deletePosition';
export const deletePositionSetting = id => dispatch => {
  let result;
  axios
    .delete(`${deletePosition}/${id}`)
    .then(res => {
      result = res;
      dispatch(getPeopleGeneralSettings());
    })
    .catch(err => {});
  return result;
};

const deleteLanguage = 'api/deleteLanguage';
export const deleteLanguageSetting = id => dispatch => {
  let result;
  axios
    .delete(`${deleteLanguage}/${id}`)
    .then(res => {
      result = res;
      dispatch(getPeopleGeneralSettings());
    })
    .catch(err => {});
  return result;
};

const deleteRegion = 'api/deleteRegion';
export const deleteRegionSetting = id => dispatch => {
  let result;
  axios
    .delete(`${deleteRegion}/${id}`)
    .then(res => {
      result = res;
      dispatch(getPeopleGeneralSettings());
    })
    .catch(err => {});
  return result;
};
