import { IAddress } from 'app/shared/model/address.model';
import { IOrganizationType } from 'app/shared/model/organization-type.model';
import { IPeopleSettings } from './peopleSetting.model';
import { ICountry } from 'app/shared/model/country.model';
import { ICity } from 'app/shared/model/city.model';
export interface IOrganization {
  id?: number;
  name?: string | null;
  address?: IAddress | null;
  phone?: string | null;
  organizationTimezone?: any;
  organizationType?: IOrganizationType | null;
  serviceAreas?: string | null;
  country?: ICountry | null;
  city?: ICity | null;
  postcode?: string | null;
  state?: string | null;
  personFirstName?: string | null;
  personLastName?: string | null;
  personEmail?: string | null;
  personPhone?: string | null;
  organization_image?: string | null;
}

export const defaultValue: Readonly<IOrganization> = {};
