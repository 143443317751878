import React from 'react';
import { Translate } from 'react-jhipster';
import { Button } from '@material-ui/core';
import { NavItem, NavLink, NavbarBrand } from 'reactstrap';
import { Link } from 'react-router-dom';
import AppsIcon from '@material-ui/icons/Apps';
import HomeIcon from '@material-ui/icons/Home';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './header.scss';
import appConfig from 'app/config/constants';
import { useHistory } from 'react-router-dom';

export const BrandIcon = props => (
  <div {...props} className="brand-icon">
    <img src="content/images/logo-2.png" alt="Logo" />
    &nbsp;
  </div>
);

export const Brand = props => {
  const history = useHistory();

  return (
    <Button onClick={() => history.push('/')} className="brand-logo">
      <BrandIcon />
    </Button>
  );
};

export const Home = props => {
  const history = useHistory();

  return (
    <Button onClick={() => history.push('/')} className="headercomponentHomeButtonlink headerIcon">
      &nbsp;
      <span>
        <Translate contentKey="global.menu.home">Home</Translate>
      </span>
    </Button>
  );
};

export const Client = props => {
  const history = useHistory();

  return (
    <Button onClick={() => history.push('/customer')} className="headercomponentClientButtonlink headerIcon">
      <FontAwesomeIcon icon="plus" />
      &nbsp;
      <span>Customer</span>
    </Button>
  );
};

export const Employees = props => (
  <Button href="/staff" className="headercomponentEmployeesButtonlink headerIcon">
    <FontAwesomeIcon icon="users" />
    &nbsp;
    <span>Employees</span>
  </Button>
);

// export const HR = props => {
//   const history = useHistory();
//   return (
//     <Button onClick={() => history.push('/employeeForm')} className="headercomponentHomeButtonlink headerIcon">
//       <AppsIcon color="primary" />
//       &nbsp;
//       <span>HR</span>
//     </Button>
//   );
// };

export const Vetting = props => (
  <Button href="/vettingDashboard" className="headercomponentHomeButtonlink headerIcon">
    <FontAwesomeIcon icon="th-list" />
    &nbsp;
    <span>Vetting</span>
  </Button>
);
