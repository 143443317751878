import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router';
import { ThemeProvider } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RoomIcon from '@material-ui/icons/Room';
import Divider from '@material-ui/core/Divider';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import { faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles(theme => ({
  selectBox: {
    minWidth: '35vh',
    margin: 'auto',
    marginTop: '2vh',
    paddingTop: '1vh',
  },
  textBox: {
    minWidth: 200,
    margin: 'auto',
    marginTop: '1vh',
    paddingTop: '1vh',
  },
  buttons: {
    minWidth: 100,
    marginTop: '3vh',
    marginRight: '2vh',
    float: 'right',
  },
  sideBySide: {
    display: 'flex',
    flexDirection: 'row',
  },
  drawer: {
    width: '18%',
  },
  drawerPaper: {
    width: '18%',
    position: 'absolute',
    marginTop: '1vh',
    marginLeft: 0,
    zIndex: 1,
  },
  active: {
    background: '#F4F4F4',
  },
  title: {
    textAlign: 'left',
    marginLeft: '10vh',
  },
  mainTitle: {
    padding: theme.spacing(2),
    marginLeft: '3.2vh',
    textAlign: 'left',
    color: '#6610f2',
    fontWeight: 450,
  },
  flexContainer: {
    display: 'flex',
    background: 'linear-gradient(to top, #FFFFFF 50%, #EBECF0 50%)',
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  detailNote: {
    width: '100%',
  },
}));

const AntSwitch = withStyles(theme => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const PersonHealthComponent = props => {
  const [open, setOpen] = useState(false);
  const [details, setDetails] = useState('');
  const classes = useStyles();
  const history = useHistory();
  const [userNeedToggler, setUserNeedToggler] = React.useState({
    isCheckedA: true,
    isCheckedB: true,
  });

  const handleClick = () => {
    setOpen(!open);
  };

  const handleDrawerMenu = pageNo => {
    if (pageNo === 9) {
      handleClick();
    } else {
      props.setPage(pageNo);
    }
  };

  const handleChange = event => {
    setUserNeedToggler({ ...userNeedToggler, [event.target.name]: event.target.checked });
  };

  const modalNotesChange = event => {
    setDetails(event.target.value.toString().trim());
  };

  return (
    <div className="service-area-component-main-container">
      <div className="service-area-component-text-container" style={{ width: '90%', paddingLeft: '2%' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>Does the user need to be accompanied by a carier or a supporter</span>
          <span>
            <AntSwitch checked={userNeedToggler.isCheckedA} onChange={handleChange} name="isCheckedA" style={{ display: 'flex' }} />
          </span>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>Does the user have a medical condition?</span>
          <span>
            <AntSwitch checked={userNeedToggler.isCheckedB} onChange={handleChange} name="isCheckedB" />
          </span>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>How many days has the user been absent from the work in the last 2 years due to sickness?</span>
          <span>
            <TextField
              className={classes.margin}
              label="Days"
              variant="outlined"
              id="mui-theme-provider-outlined-input"
              size="small"
              type="number"
              style={{ width: '80px' }}
            />
          </span>
        </div>
        <div>
          <span>Details of any other illness or injuries relevant to the user</span>
          <span>
            <TextField
              id="shift-Note"
              label="Details"
              className={classes.detailNote}
              variant="outlined"
              value={details}
              onChange={modalNotesChange}
              multiline={true}
              minRows={2}
            ></TextField>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PersonHealthComponent;
