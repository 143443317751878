import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Table } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './client.scss';
import { IRootState } from 'app/shared/reducers';
import { getAllClients, reset, setPageNumber } from './client.reducer';
import IconButton from '@material-ui/core/IconButton';
import { IClient } from 'app/shared/model/client.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import SearchBar from 'material-ui-search-bar';
import Paper from '@material-ui/core/Paper';
import InfiniteScroll from 'react-infinite-scroll-component';
import TableContainer from '@material-ui/core/TableContainer';
import { getPersonalSettings } from '../../modules/Setting/Personal-Setting.reducer';
import { getDateFormat } from 'app/shared/util/calender-utils';
import moment from 'moment';
import { Storage } from 'react-jhipster';
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS, Step } from 'react-joyride';
import { AUTHORITIES } from 'app/config/constants';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { InputLabel, MenuItem, OutlinedInput, Select, TextField } from '@material-ui/core';
import { convertToPDF } from './convertToPDF';
import Spinner from 'app/commonUI/spinner/spinner';
import GetAppIcon from '@material-ui/icons/GetApp';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import PrintIcon from '@material-ui/icons/Print';
import { Grid } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';

export interface IClientProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const Client = (props: IClientProps) => {
  const [searched, setSearched] = useState<string>('');
  const [records, setRecords] = useState(props.clientList);
  const [isNew] = useState(!props.match.params || !props.match.params.id);
  const [run, setRunState] = useState(false);
  const [runAgain, setRunAgainState] = useState(false);
  const [first_login] = useState(Storage.local.get('first_login'));
  const [reportArray, setReportArray] = useState(props.clientList);
  useEffect(() => {
    if (!props.updateSuccess) {
      props.reset();
      props.setPageNumber({ pageNumber: 1 });
    }
    if (first_login === 'true' && props.isOrganizationAdministrator && props.clientList.length < 1) {
      setRunState(true);
    }
  }, []);

  const handleSyncList = () => {
    props.reset();
    setSearched('');
    props.setPageNumber({ pageNumber: 1 });
  };
  const jsonData = [];
  if (reportArray && reportArray.length > 0) {
    reportArray.forEach(element => {
      const data = { ...element };
      jsonData.push(data);
    });
  }

  useEffect(() => {
    setRecords(props.clientList);
    if (first_login === 'true' && props.isOrganizationAdministrator && props.joyRide === true) {
      setRunAgainState(true);
    }
    if (runAgain && runAgain === true) {
      setRunState(false);
    }
  }, [props.clientList]);

  useEffect(() => {
    setReportArray(props.clientList);
  }, [props.clientList]);

  const requestSearch = (searchedVal: string) => {
    setSearched(searchedVal);
    const value = searchedVal.toLowerCase();
    const filteredRows = props.clientList.filter(row => {
      return (
        (row.identification_number && row.identification_number !== null && row.identification_number.toLowerCase().includes(value)) ||
        (row.clientName && row.clientName !== null && row.clientName.toLowerCase().includes(value)) ||
        (row.personFirstName && row.personFirstName !== null && row.personFirstName.toLowerCase().includes(value)) ||
        (row.personLastName && row.personLastName !== null && row.personLastName.toLowerCase().includes(value)) ||
        (row.phone && row.phone !== null && row.phone.toString().includes(value)) ||
        (row.personEmail && row.personEmail !== null && row.personEmail.toLowerCase().includes(value)) ||
        (row.state && row.state !== null && row.state.name.toLowerCase().includes(value))
      );
    });
    setRecords(filteredRows);
  };

  const cancelSearch = () => {
    setSearched('');
    requestSearch('');
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, index, type, status } = data;

    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
    if (finishedStatuses.includes(status)) {
      setRunState(false);
    }
  };

  const handleJoyrideCallbackAgain = (data: CallBackProps) => {
    const { action, index, type, status } = data;

    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
    if (finishedStatuses.includes(status)) {
      setRunAgainState(false);
    }
  };

  const { clientList, match, loading, permissions, setting } = props;

  return (
    <>
      <Joyride
        callback={data => {
          handleJoyrideCallback(data);
        }}
        run={run}
        disableScrolling
        continuous={true}
        locale={{
          last: <strong aria-label="last">Next</strong>,
          back: <strong aria-label="back">Previous</strong>,
          next: <strong aria-label="next">Next</strong>,
        }}
        showSkipButton
        styles={{
          buttonNext: {
            background: '#d48bd3',
          },
          buttonSkip: {
            background: '#f7b017',
          },
          tooltipContainer: {
            height: 70,
          },
          options: {
            width: 300,
          },
        }}
        steps={[
          {
            target: '#jh-create-entity',
            content: (
              <h1 style={{ fontSize: '17px' }}>
                Click this button to add a New <b style={{ color: '#7a55de' }}>Client</b>
              </h1>
            ),
            disableBeacon: true,
            disableOverlayClose: true,
            hideCloseButton: true,
            hideFooter: true,
            spotlightClicks: true,
            placement: 'left',
          },
        ]}
      />
      <Joyride
        callback={data => {
          handleJoyrideCallbackAgain(data);
        }}
        run={runAgain}
        continuous={true}
        locale={{
          last: <strong aria-label="last">Next</strong>,
          back: <strong aria-label="back">Previous</strong>,
          next: <strong aria-label="next">Next</strong>,
        }}
        showSkipButton
        styles={{
          buttonNext: {
            background: '#d48bd3',
          },
          buttonSkip: {
            background: '#f7b017',
          },
          tooltipContainer: {
            height: 100,
          },
          options: {
            width: 300,
          },
        }}
        steps={[
          {
            target: '.headerIconScheduler',
            title: (
              <h2 style={{ fontSize: '20px' }}>
                Tap <b>Others</b>
              </h2>
            ),
            content: (
              <h1 style={{ fontSize: '17px' }}>
                Click on <b style={{ color: '#7a55de' }}>Service Areas</b> from dropdown menu
              </h1>
            ),
            disableBeacon: true,
            disableOverlayClose: true,
            hideCloseButton: true,
            hideFooter: true,
            spotlightClicks: true,
            placement: 'bottom',
          },
        ]}
      />
      <div className="entities-reports-main-container">
        {loading && <Spinner />}
        <div className="entities-reports-filter-area">
          <Grid container spacing={2} style={{ flexWrap: 'nowrap', width: '65%' }}>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
              <FormControl variant="outlined" size="small">
                <SearchBar
                  value={searched}
                  onChange={searchVal => requestSearch(searchVal)}
                  onCancelSearch={() => cancelSearch()}
                  placeholder="Search Here..."
                  style={{
                    outline: '1px solid #bbc4c2',
                    borderRadius: '4px',
                    height: '40px',
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container className="entities_reports_refresh_and_download_div" style={{ width: '35%' }}>
            <IconButton
              size="small"
              className="report-download-button"
              id="entities_reports_btn"
              onClick={() => {
                convertToPDF(jsonData);
              }}
              disabled={props.loading}
            >
              <GetAppIcon fontSize="large" />
            </IconButton>

            <IconButton
              size="small"
              className="report-download-button"
              id="entities_reports_btn"
              disabled={props.loading}
              onClick={handleSyncList}
            >
              <AutorenewIcon fontSize="large" />
            </IconButton>

            {/* <Grid item xl={4} lg={4} md={4} sm={4} xs={4}> */}
            <Link
              to={`${match.url}/new`}
              className="btn jh-create-entity serviceAreaCreateButton incidentCreateButton"
              id="jh-create-entity"
              data-cy="entityCreateButton"
            >
              <FontAwesomeIcon icon="plus" />
              &nbsp; Create New
            </Link>
            {/* </Grid> */}
          </Grid>
        </div>

        <div>
          <div className="globalTableHeaderGradientColor clientTableHeaderRow sticky_div">
            <div className="clientTableHeaderCell clientTableHeaderIdCell">ID No.</div>
            <div className="clientTableHeaderCell clientTableHeaderClientNameCell">Client Name</div>
            <div className="clientTableHeaderCell clientTableHeaderRegionCell">Country of Residence</div>
            <div className="clientTableHeaderCell clientTableHeaderContactPersonCell">Point of Contact</div>
            <div className="clientTableHeaderCell clientTableHeaderContactDetailsCell">Contact Details</div>
          </div>

          <InfiniteScroll
            dataLength={clientList.length}
            scrollThreshold="200px"
            next={() => {
              if (clientList.length > 0) {
                props.setPageNumber({ pageNumber: props.pageId['pageNumber'] + 1 });
              }
            }}
            hasMore={true}
            loader={''}
          >
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <tbody>
                  {records && records.length > 0
                    ? records.map((client, i) => (
                        <tr key={`entity-${i}`} data-cy="entityTable" className="clientTableBodyRows">
                          <td className="clientTableBodyCell clientTableBodyIdcell">{client.identification_number}</td>
                          <td className="clientTableBodyCell clientTableBodyNamecell">
                            <Link to={`${match.url}/${client.id}/view`} className="clientNameLink" data-cy="clientNameLink">
                              {client.clientName}
                            </Link>
                          </td>
                          <td className="clientTableBodyCell clientTableBodyStatecell">{client.state && client.state.name}</td>
                          <td className="clientTableBodyCell clientTableBodyPersonNamecell">
                            <span className="clientTableBodyFirstNameCell">{client.personFirstName}</span>&nbsp;
                            <span className="clientTableBodyLastNameCell">{client.personLastName}</span>
                          </td>
                          <td className="clientTableBodyCell clientTableEmailPhoneCell" style={{}}>
                            <span className="clientTableBodyCell clientTableBodyPhonecell">{client.phone}</span>
                            <br />
                            <span className="clientTableBodyCell clientTableBodyEmailcell">{client.personEmail}</span>
                          </td>
                        </tr>
                      ))
                    : !props.loading && <div className="no-record-warning">Client not found</div>}
                </tbody>
              </Table>
            </TableContainer>
          </InfiniteScroll>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ serviceArea, PersonalSettings, client, authentication }: IRootState) => ({
  clientList: client.entities,
  loading: client.loading,
  permissions: authentication.account.permission,
  pageId: client.pageNumber,
  setting: PersonalSettings.storedPersonalSettings,
  updateSuccess: client.updateSuccess,
  joyRide: client.joyRide,
  isOrganizationAdministrator: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.ORGANIZATION_ADMINISTRATOR]),
});

const mapDispatchToProps = {
  getAllClients,
  reset,
  setPageNumber,
  getPersonalSettings,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Client);
