import React, { useState } from 'react';
import { Drawer } from '@material-ui/core';
import { Select, MenuItem, FormControl, InputLabel, makeStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import WorkIcon from '@material-ui/icons/Work';
import StarsIcon from '@material-ui/icons/Stars';
import { useHistory, useLocation } from 'react-router';
import { faBookOpen, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles(theme => ({
  selectBox: {
    minWidth: '35vh',
    margin: 'auto',
    marginTop: '2vh',
    paddingTop: '1vh',
  },
  textBox: {
    minWidth: 200,
    margin: 'auto',
    marginTop: '1vh',
    paddingTop: '1vh',
  },
  buttons: {
    minWidth: 100,
    marginTop: '3vh',
    marginRight: '2vh',
    float: 'right',
  },
  sideBySide: {
    display: 'flex',
    flexDirection: 'row',
  },
  drawer: {
    width: '18%',
  },
  drawerPaper: {
    width: '19%',
    position: 'absolute',
    marginTop: '0',
    marginLeft: 0,
    zIndex: 1,
    paddingLeft: '18px',
    paddingTop: '124px',
  },
  active: {
    background: '#F3F4FA',
    color: '#4820B8',
  },
  title: {
    textAlign: 'left',
    marginLeft: '10vh',
  },
  mainTitle: {
    padding: theme.spacing(2),
    marginLeft: '3.2vh',
    textAlign: 'left',
    color: '#6610f2',
    fontWeight: 450,
  },
  flexContainer: {
    display: 'flex',
    background: 'linear-gradient(to top, #FFFFFF 50%, #EBECF0 50%)',
  },
}));

const ServiceAreaDrawerComponent = props => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const history = useHistory();

  const menuItems = [
    {
      text: 'Service Area Details',
      icon: <img src="content/icons/Personal Details/Service Areas.png" />,
      activeIcon: <img src="content/icons/General Active.svg" />,
      pageNo: 1,
    },
    {
      text: 'Patrol Settings',
      icon: <img src="content/icons/Personal Details/Qualifications  Certifications.png" />,
      activeIcon: <img src="content/icons/Welfare Call Active.svg" />,
      pageNo: 3,
    },
    {
      text: 'Shift Execution',
      icon: <img src="content/icons/Personal Details/Qualifications  Certifications.png" />,
      activeIcon: <img src="content/icons/Welfare Call Active.svg" />,
      pageNo: 4,
    },
  ];

  if (!props.isNew) {
    menuItems.splice(1, 0, {
      text: 'Team',
      icon: <img src="content/icons/Personal Details/Company Info.png" />,
      activeIcon: <img src="content/icons/Shift Confirmation Call Active.svg" />,
      pageNo: 2,
    });
  }

  const handleClick = () => {
    setOpen(!open);
  };

  const handleDrawerMenu = pageNo => {
    if (pageNo === 5) {
      handleClick();
    } else {
      props.setPage(pageNo);
    }
  };

  return (
    <Drawer className={classes.drawer} variant="permanent" anchor="left" classes={{ paper: classes.drawerPaper }}>
      <div>
        <Typography variant="h5" className={classes.title}></Typography>
      </div>
      <List>
        {menuItems.map(item => (
          <ListItem
            key={item.text}
            button
            onClick={() => {
              handleDrawerMenu(item.pageNo);
            }}
            className={props.page === item.pageNo ? classes.active : null}
          >
            <ListItemIcon>{props.page === item.pageNo ? item.activeIcon : item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default ServiceAreaDrawerComponent;
