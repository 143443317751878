export interface IAppFormEmployment {
  id?: number;
  company_name?: string | null;
  job_title?: string | null;
  company_location?: string | null;
  post_code?: number | string | null;
  emp_start_date?: Date | string | null;
  emp_end_date?: Date | string | null;
  leaving_reason?: string | null;
  contact_name?: string | null;
  contact_telephone?: number | string | null;
}

export const defaultValue: Readonly<IAppFormEmployment> = {};
