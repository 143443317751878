import React from 'react';
import { Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { useState, useEffect } from 'react';
import {
  createOrganizationQualification,
  deleteQualification,
  getOrganizationQualifications,
  reset,
} from './Organization-Qualification.reducer';
import './oraganization_qualification.scss';
import { Grid, TextField, IconButton, FormControl, Button, makeStyles, FormLabel } from '@material-ui/core';
import Qualification from './Component/NewQualificationComponent';
import AddQualification from './Component/AddQualification';
import { getPeopleGeneralSettings } from '../PeopleSettings/peopleSettings.reducer';
import { IRootState } from 'app/shared/reducers';

export interface IOrganizationQualificationProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
  values: any;
}

export const OrganizationQualification = (props: IOrganizationQualificationProps) => {
  const [isNew] = useState(!props.match.params || !props.match.params.id);
  const [open, setOpen] = React.useState(false);
  const [checkBoxColor, setcheckBoxColor] = React.useState(false);
  const [values, setValues] = useState({});
  const [qualificationArray, setQualificationArray] = useState([]);
  const [expiryCheck, setExpiryCheck] = useState(false);
  const [proofCheck, setProofCheck] = useState(false);
  const [optionalSwitch, setOptionalSwitch] = useState(false);
  const [organizationQualificationList, setOrganizationQualificationList] = useState(props.organizationQualification);
  const [newOrganizationQualificationList, setNewOrganizationQualificationList] = useState([]);
  const [showNewComponent, setShowNewComponent] = useState([]);
  const [fieldError, setFieldError] = useState({
    name_error: false,
  });

  useEffect(() => {
    props.reset();
    props.getOrganizationQualifications();
  }, []);

  const submit = () => {
    if (handleValidations()) {
      props.createOrganizationQualification(values);
      setShowNewComponent(oldValues => ({ ...oldValues, ['value']: false }));
      setValues(oldValues => ({ ...oldValues, ['expiry_required']: false }));
      setValues(oldValues => ({ ...oldValues, ['proof_required']: false }));
      setValues(oldValues => ({ ...oldValues, ['mandatory_proof']: false }));
    }
  };

  const handleValidations = () => {
    let output = true;

    if (values['name'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['name_error']: true }));
      output = false;
    }
    return output;
  };

  const handleCheckbox = tag => {
    setcheckBoxColor(prev => !prev);
  };

  const handleAddNew = () => {
    setShowNewComponent(oldValues => ({ ...oldValues, ['value']: true }));
  };

  const handleDiscard = () => {
    setShowNewComponent(oldValues => ({ ...oldValues, ['value']: false }));
    setValues(oldValues => ({ ...oldValues, ['expiry_required']: false }));
    setValues(oldValues => ({ ...oldValues, ['proof_required']: false }));
  };

  const handleExpiryCheck = event => {
    setValues(oldValues => ({ ...oldValues, ['expiry_required']: event.target.checked }));
  };

  const handleProofCheck = event => {
    setValues(oldValues => ({ ...oldValues, ['proof_required']: event.target.checked }));
  };

  const handleDeleteQualification = id => {
    props.deleteQualification(id);
  };

  const handleQualification = event => {
    setValues(oldValues => ({ ...oldValues, ['name']: event.target.value }));
  };

  const handleProofSwitch = event => {
    setValues(oldValues => ({ ...oldValues, ['mandatory_proof']: event.target.checked }));
  };

  useEffect(() => {
    setOrganizationQualificationList(props.organizationQualification);
  }, [props.organizationQualification]);

  return (
    <div className="person-qualification-settings-external-div">
      <div className="person-qualification-settings-heading-div">
        <div className="person-qualification-settings-main-heading">
          <Typography variant="h5" className="person-qualification-settings-heading-main-title">
            Organization Qualification
          </Typography>
        </div>
      </div>
      <Paper elevation={2} square style={{ width: '90%', height: 'auto', margin: 'auto', padding: '3%' }}>
        <div className="organization-qualification-outer-div">
          <div className="add-organization-qualification-div">
            {organizationQualificationList.map((data, index) => (
              <Qualification
                key={index}
                handleCheckbox={handleCheckbox}
                handleQualification={handleQualification}
                expiryCheck={expiryCheck}
                proofCheck={proofCheck}
                handleDeleteQualification={handleDeleteQualification}
                handleProofSwitch={handleProofSwitch}
                optionalSwitch={optionalSwitch}
                values={data}
              />
            ))}

            {showNewComponent['value'] === true ? (
              <AddQualification
                handleCheckbox={handleCheckbox}
                handleQualification={handleQualification}
                handleExpiryCheck={handleExpiryCheck}
                handleProofCheck={handleProofCheck}
                expiryCheck={values['expiry_required']}
                proofCheck={values['proof_required']}
                handleDeleteQualification={handleDeleteQualification}
                handleProofSwitch={handleProofSwitch}
                optionalSwitch={values['mandatory_proof']}
                values={[]}
                fieldError={fieldError}
              />
            ) : null}

            {showNewComponent['value'] !== true ? (
              <div className="qualification-new-add-button-div">
                <div className="add-new-qualifcation-inner-div">
                  <Button className="add-new-qualification-button" onClick={handleAddNew}>
                    + Add New{' '}
                  </Button>
                </div>
              </div>
            ) : null}
          </div>
        </div>

        <div className="qualification-buttons-div">
          <Grid container item xs={12} spacing={2} className="qualification-discard-button" style={{ marginLeft: 'auto' }}>
            <FormControl className="add-qualification-discard-button">
              <Button
                variant="contained"
                style={{ color: 'black', backgroundColor: '#ffffff', border: '1px solid #C6C6C6' }}
                onClick={() => {
                  handleDiscard();
                }}
              >
                Discard
              </Button>
            </FormControl>
          </Grid>

          <Grid container item xs={12} spacing={2} className="qualification-save-button">
            <FormControl className="add-qualification-save-button">
              <Button
                variant="contained"
                style={{ color: 'white', backgroundColor: '#4720B7', border: '1px solid #C6C6C6' }}
                onClick={() => {
                  submit();
                }}
              >
                Save Changes
              </Button>
            </FormControl>
          </Grid>
        </div>
      </Paper>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  settings: storeState.PeopleSetting.storedPeopleSettings,
  loading: storeState.PeopleSetting.loading,
  organizationQualification: storeState.OrganizationQualification.storedQualifications,
});

const mapDispatchToProps = {
  createOrganizationQualification,
  getPeopleGeneralSettings,
  deleteQualification,
  getOrganizationQualifications,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationQualification);
