import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IEmployeeContract, defaultValue } from 'app/shared/model/employee-contract.model';

export const ACTION_TYPES = {
  FETCH_EMPLOYEECONTRACT_LIST: 'employeeContract/FETCH_EMPLOYEECONTRACT_LIST',
  FETCH_EMPLOYEECONTRACT: 'employeeContract/FETCH_EMPLOYEECONTRACT',
  CREATE_EMPLOYEECONTRACT: 'employeeContract/CREATE_EMPLOYEECONTRACT',
  UPDATE_EMPLOYEECONTRACT: 'employeeContract/UPDATE_EMPLOYEECONTRACT',
  PARTIAL_UPDATE_EMPLOYEECONTRACT: 'employeeContract/PARTIAL_UPDATE_EMPLOYEECONTRACT',
  DELETE_EMPLOYEECONTRACT: 'employeeContract/DELETE_EMPLOYEECONTRACT',
  RESET: 'employeeContract/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IEmployeeContract>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

export type EmployeeContractState = Readonly<typeof initialState>;

// Reducer

export default (state: EmployeeContractState = initialState, action): EmployeeContractState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_EMPLOYEECONTRACT_LIST):
    case REQUEST(ACTION_TYPES.FETCH_EMPLOYEECONTRACT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_EMPLOYEECONTRACT):
    case REQUEST(ACTION_TYPES.UPDATE_EMPLOYEECONTRACT):
    case REQUEST(ACTION_TYPES.DELETE_EMPLOYEECONTRACT):
    case REQUEST(ACTION_TYPES.PARTIAL_UPDATE_EMPLOYEECONTRACT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_EMPLOYEECONTRACT_LIST):
    case FAILURE(ACTION_TYPES.FETCH_EMPLOYEECONTRACT):
    case FAILURE(ACTION_TYPES.CREATE_EMPLOYEECONTRACT):
    case FAILURE(ACTION_TYPES.UPDATE_EMPLOYEECONTRACT):
    case FAILURE(ACTION_TYPES.PARTIAL_UPDATE_EMPLOYEECONTRACT):
    case FAILURE(ACTION_TYPES.DELETE_EMPLOYEECONTRACT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_EMPLOYEECONTRACT_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_EMPLOYEECONTRACT):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_EMPLOYEECONTRACT):
    case SUCCESS(ACTION_TYPES.UPDATE_EMPLOYEECONTRACT):
    case SUCCESS(ACTION_TYPES.PARTIAL_UPDATE_EMPLOYEECONTRACT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_EMPLOYEECONTRACT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/employee-contracts';

// Actions

export const getEntities: ICrudGetAllAction<IEmployeeContract> = (page, size, sort) => ({
  type: ACTION_TYPES.FETCH_EMPLOYEECONTRACT_LIST,
  payload: axios.get<IEmployeeContract>(`${apiUrl}?cacheBuster=${new Date().getTime()}`),
});

export const getEntity: ICrudGetAction<IEmployeeContract> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_EMPLOYEECONTRACT,
    payload: axios.get<IEmployeeContract>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IEmployeeContract> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_EMPLOYEECONTRACT,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IEmployeeContract> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_EMPLOYEECONTRACT,
    payload: axios.put(`${apiUrl}/${entity.id}`, cleanEntity(entity)),
  });
  return result;
};

export const partialUpdate: ICrudPutAction<IEmployeeContract> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.PARTIAL_UPDATE_EMPLOYEECONTRACT,
    payload: axios.patch(`${apiUrl}/${entity.id}`, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IEmployeeContract> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_EMPLOYEECONTRACT,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
