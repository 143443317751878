import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { IRootState } from 'app/shared/reducers';
import { getEntity } from '../person.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { Divider, Paper } from '@material-ui/core';
import '../person.scss';
import Typography from '@material-ui/core/Typography';
import { Button, Grid, FormControl } from '@material-ui/core';
import ActiveServiceAreaComponent from '../component/Person-active-serviceAreas';
import BannedServiceAreaComponent from '../component/Person-banned-serviceAreas';
import { deleteEntity, getEntities as getServiceAreas, updateBannedPeople } from '../../service-area/service-area.reducer';
import { bannedEntity } from '../../service-area/service-area.reducer';
import moment from 'moment';
import {
  deleteStaffEntity,
  getBannedServiceAreas,
  getActiveServiceAreasByPersonId,
  addNewServiceArea,
  getServiceAreasByPersonId,
} from '../person.reducer';
import { BannedComponent } from './banned-modal';
import { AddServiceAreaComponent } from './new-serviceArea-modal';
import { useLocation } from 'react-router-dom';
import editIcon from '../../../../content/images/Group 9766.png';

export interface IPersonDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const PersonServiceAreas = props => {
  const [open, setOpen] = useState(false);
  const [openServiceAreaModal, setOpenServiceAreaModal] = useState(false);
  const [BannedModalMode, setBannedModalMode] = useState({
    Mode: '',
    banned_id: null,
  });
  const location = useLocation();
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    props.getEntity(props.match.params.id);
    props.getServiceAreas(-1, '');
  }, []);

  useEffect(() => {
    if(props.personEntity.id){
      props.getBannedServiceAreas({ id: props.personEntity.id });
      props.getServiceAreasByPersonId(props.personEntity.id);
      props.getActiveServiceAreasByPersonId(props.personEntity.id);
    }
  }, [props.personEntity.id]);

  const openEditMode = () => {
    setEditMode(true);
  };
  const handleAddNewButton = () => {
    setOpen(true);
    setBannedModalMode(oldValues => ({ ...oldValues, Mode: 'new' }));
  };

  const handleAddNewServiceArea = () => {
    setOpenServiceAreaModal(true);
  };

  const closeEditMode = props => {
    setEditMode(false);
    PersonServiceAreas(props);
  };
  const handleEditMode = () => {};

  return (
    <div className="person-view-main-container">
      <div className="active-service-areas-container">
        {editMode === false ? (
          <>
            <div className="PersonProfileEditBtnContainer">
              <Button
                variant="contained"
                className="Profile_Detials_edit_btn"
                onClick={openEditMode}
                startIcon={<img src="content/icons/Personal Details/Edit.png" />}
              >
                <span style={{ color: '#4820B8' }}>Edit</span>
              </Button>
            </div>
            <Divider style={{ margin: '4% 0' }} />
          </>
        ) : null}
        <Typography className="person-main-title-heading" variant="h5" gutterBottom style={{ color: '#4720b7' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span style={{ marginBottom: '30px' }}>Active Service Areas</span>
            {editMode && location.state !== true ? (
              <span className="add-new-service-area-button" onClick={() => handleAddNewServiceArea()}>
                <div style={{ fontFamily: 'Roboto', color: '#FFFFFF', fontSize: '16px' }}>Add New </div>
              </span>
            ) : null}
          </div>
        </Typography>
        <ActiveServiceAreaComponent prop={props} setOpenServiceAreaModal={setOpenServiceAreaModal} editMode={editMode} />
        <Divider style={{ width: '97%', margin: '4% 0' }} />
      </div>

      <div className="banned-service-areas-container">
        <Typography className="person-main-title-heading" variant="h5" gutterBottom style={{ color: '#4720b7' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span style={{ marginBottom: '30px' }}>Unauthorised Service Areas</span>
            {editMode && location.state !== true ? (
              <span className="add-new-service-area-button" onClick={() => handleAddNewButton()}>
                <div style={{ font: 'Roboto', color: '#FFFFFF', fontSize: '16px' }}>Add New </div>
              </span>
            ) : null}
          </div>
        </Typography>
        <BannedServiceAreaComponent prop={props} setOpen={setOpen} setBannedModalMode={setBannedModalMode} editMode={editMode} />

        {editMode === true ? (
          <>
            <Divider style={{ margin: '4% 0' }} />
            <Grid item xs={12}>
              <FormControl style={{ flexDirection: 'row', justifyContent: 'flex-end', width: '100%' }}>
                <>
                  <Button
                    variant="contained"
                    className="Person-profile-Discard-Button"
                    onClick={() => {
                      closeEditMode(props);
                    }}
                  >
                    Cancel
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button
                    onClick={() => {
                      closeEditMode(props);
                    }}
                    variant="contained"
                    id="save-entity"
                    type="submit"
                    className="Person-profile-Save-Button"
                  >
                    Save
                  </Button>
                </>
              </FormControl>
            </Grid>
          </>
        ) : null}

        <BannedComponent
          open={open}
          setOpen={setOpen}
          BannedModalMode={BannedModalMode}
          setBannedModalMode={setBannedModalMode}
          prop={props}
          setting={props.setting.date_format}
        />
        <AddServiceAreaComponent
          openServiceAreaModal={openServiceAreaModal}
          setOpenServiceAreaModal={setOpenServiceAreaModal}
          prop={props}
          setting={props.setting.date_format}
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({ person, serviceArea, PersonalSettings }: IRootState) => ({
  personEntity: person.entity,
  loading: person.loading,
  serviceAreaList: serviceArea.entities,
  setting: PersonalSettings.storedPersonalSettings,
  bannedServiceAreas: person.banned,
  personServiceAreaList: person.personServiceAreas,
  activeServiceAreasList: person.personActiveServiceAreas,
});

const mapDispatchToProps = {
  getEntity,
  deleteEntity,
  getServiceAreas,
  getBannedServiceAreas,
  updateBannedPeople,
  addNewServiceArea,
  getServiceAreasByPersonId,
  getActiveServiceAreasByPersonId,
  deleteStaffEntity,
  bannedEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PersonServiceAreas);
