import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { TextField, Button, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

export default function Checkpoint(props) {
  return (
    <div className="service_area_checkpointRow">
      <TextField
        id="service-area-checkpoints-data"
        className="checkpointFields"
        style={{ width: props.mapValues['map'] === true ? '65%' : '100%' }}
        variant="outlined"
        size="small"
        onChange={() => {
          props.handleCheckpoint(event, props.checkpointId, props.index);
        }}
        value={props.value}
      />
      {props.mapValues['map'] === true ? (
        <>
          <TextField
            className="checkpointFields service-area-checkPoint-coordinate-fields"
            style={{ width: props.mapValues['map'] === true ? '18%' : '13%', marginLeft: '20px' }}
            label="Lat"
            disabled={props.mapValues['map']}
            variant="outlined"
            value={props.latitude}
            size="small"
          />

          <TextField
            className="checkpointFields service-area-checkPoint-coordinate-fields"
            style={{ width: props.mapValues['map'] === true ? '18%' : '13%', marginLeft: '20px' }}
            label="Lng"
            disabled={props.mapValues['map']}
            variant="outlined"
            value={props.longitude}
            size="small"
          />
        </>
      ) : null}
    </div>
  );
}
