import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { IRootState } from 'app/shared/reducers';
import { getEntity } from '../person.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { Paper } from '@material-ui/core';
import '../../sub-contractor/subcontractor.scss';
import Typography from '@material-ui/core/Typography';
import PersonBackgroundEducationComponent from '../component/person-background-and-education';

export interface IPersonDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const PersonBackgroundAndEducationComponent = (props: IPersonDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { personEntity } = props;
  return (
    <div className="subcontractor-view-service-areas-main-container">
      <Paper className="subcontractor-details-paper">
        <div className="active-service-areas-container">
          <Typography className="subcontractor-main-title-heading" variant="h5" gutterBottom style={{ color: '#4720b7' }}>
            Employment Record
          </Typography>
          <PersonBackgroundEducationComponent title="Company Name" description="Enter Position | 31/12/2030 - 31/12/2030" />
          <PersonBackgroundEducationComponent title="Company Name" description="Enter Position | 31/12/2030 - 31/12/2030" />
        </div>

        <div className="banned-service-areas-container">
          <Typography className="subcontractor-main-title-heading" variant="h5" gutterBottom style={{ color: '#4720b7' }}>
            Education Record
          </Typography>
          <PersonBackgroundEducationComponent title="Institue Name" description="Enter Degree/Diploma | 31/12/2030 - 31/12/2030" />
        </div>
      </Paper>
    </div>
  );
};

const mapStateToProps = ({ person }: IRootState) => ({
  personEntity: person.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PersonBackgroundAndEducationComponent);
