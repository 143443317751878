/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import { IRootState } from 'app/shared/reducers';
import Divider from '@material-ui/core/Divider';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import { Radio, RadioGroup } from '@material-ui/core';
import '../person.scss';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker, TimePicker } from '@material-ui/pickers';
import CustomToolbarComponent from '../../service-area/CustomToolbarComponent';
import DateFnsUtils from '@date-io/date-fns';
import Chip from '@material-ui/core/Chip';
import { getDateFormat } from '../../../shared/util/calender-utils';
import moment from 'moment';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import Checkbox from '@material-ui/core/Checkbox';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

export interface IPersonServiceAreaComponent {
  openServiceAreaModal: boolean;
  setOpenServiceAreaModal: (value: boolean) => void;
  prop: any;
  setting: any;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const AddServiceAreaComponent = (props: IPersonServiceAreaComponent) => {
  const { openServiceAreaModal, setOpenServiceAreaModal, prop, setting } = props;
  const [fieldError, setFieldError] = useState({
    serviceAreaField_error: false,
    DescriptionField_error: false,
    startDateField_error: false,
    endDateField_error: false,
  });
  const [newServiceAreaModal, setNewServiceAreaModal] = useState({
    serviceArea: null,
    person: null,
    allServiceAreas: false,
  });

  const handleClose = () => {
    setOpenServiceAreaModal(false);
    setFieldError(oldValues => ({
      ...oldValues,
      employeeField_error: false,
      DescriptionField_error: false,
      startDateField_error: false,
      endDateField_error: false,
    }));
    reset();
  };

  const handleServiceAreaValues = (_, e) => {
    setNewServiceAreaModal(oldValues => ({ ...oldValues, serviceArea: e }));
    setNewServiceAreaModal(oldValues => ({ ...oldValues, person: prop.personEntity }));
    if (newServiceAreaModal.serviceArea !== null || newServiceAreaModal.serviceArea !== undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['serviceAreaField_error']: false }));
    }
  };

  const handleAllServiceAreas = () => {
    setNewServiceAreaModal(oldValues => ({ ...oldValues, serviceArea: prop.personServiceAreaList, allServiceAreas: true }));
    setNewServiceAreaModal(oldValues => ({ ...oldValues, person: prop.personEntity }));
    if (newServiceAreaModal.serviceArea === null || newServiceAreaModal.serviceArea === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['serviceAreaField_error']: false }));
    }
  };

  const reset = () => {
    setNewServiceAreaModal(oldValues => ({
      ...oldValues,
      selectServiceArea: null,
      allServiceAreas: false,
    }));
  };

  const handleBanServiceAreas = () => {
    prop.addNewServiceArea(newServiceAreaModal);
    reset();
    setOpenServiceAreaModal(false);
    setNewServiceAreaModal(oldValues => ({ ...oldValues, serviceArea: null, allServiceAreas: false }));
  };

  return (
    <>
      <Modal open={openServiceAreaModal} onClose={handleClose}>
        <div className="personBannedModaPaper">
          <h2 className="personBannedModalTitle"> Add new Service Area(s)</h2>

          <Divider className="personBannedModalDivider" />

          <Grid item className="personBannedModalSelectBox" xs={12} lg={12}>
            <FormControl variant="outlined" size="small" className="personBannedModalFields radioButtonAndOR">
              <FormControlLabel
                className={newServiceAreaModal?.allServiceAreas === true ? 'allServiceAreaTrue' : 'allServiceAreaFalse'}
                label={'Add All Service Areas'}
                labelPlacement="start"
                control={
                  <Checkbox
                    icon={
                      <CircleUnchecked
                        className={
                          newServiceAreaModal?.allServiceAreas === false ? 'personCircleUncheckedInBannedModal' : 'personCheckBoxWhite'
                        }
                      />
                    }
                    checkedIcon={
                      <CircleCheckedFilled
                        className={
                          newServiceAreaModal?.allServiceAreas === true ? 'personCheckCircleFilledInBannedModal' : 'personCheckBoxWhite'
                        }
                      />
                    }
                    checked={newServiceAreaModal?.allServiceAreas}
                    onChange={handleAllServiceAreas}
                    className="personBannedModalFirstRadioButtion"
                  />
                }
              />
              <span className="personBannedModalORText">Or</span>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12} lg={12} sm={12}>
            <FormControl variant="outlined" size="small" className="personBannedModalFields" style={{ marginTop: '1%' }}>
              <Autocomplete
                className="PersonBannedModalAutocomplete"
                ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                limitTags={1}
                multiple
                options={prop.personServiceAreaList}
                disableCloseOnSelect
                getOptionLabel={option => option.name}
                //   value={newServiceAreaModal.selectServiceArea}
                value={newServiceAreaModal.serviceArea ? newServiceAreaModal.serviceArea : []}
                renderTags={values => values.map(value => <Chip key={value.id} label={value.name} />)}
                getOptionSelected={(option, value) => option.id === value.id}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected ? selected : null} />
                    {option.name}
                  </React.Fragment>
                )}
                onChange={handleServiceAreaValues}
                renderInput={params => (
                  <TextField
                    {...params}
                    error={fieldError['serviceAreaField_error']}
                    required
                    variant="outlined"
                    label="Select Service Area(s)"
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Divider className="personBannedModalDivider" />

          <FormControl className="personBannedModalFields">
            <FormGroup row={true}>
              <Grid item xs={3} lg={4}>
                <Button variant="contained" className="personBannedModalCancelButton" onClick={handleClose}>
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={3} lg={4}>
                <Button
                  variant="contained"
                  className="personBannedModalSaveButton"
                  onClick={() => {
                    handleBanServiceAreas();
                  }}
                >
                  Confirm
                </Button>
              </Grid>
            </FormGroup>
          </FormControl>
        </div>
      </Modal>
    </>
  );
};
