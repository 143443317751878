import React from 'react';
import { Grid, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';

const VettingSignOffRadioGroup = ({ label, name, value, onChange }) => (
  <div style={{ marginTop: '20px' }}>
    <Grid item xs={12} md={12} sm={12}>
      <FormControl component="fieldset">
        <FormLabel component="legend" style={{ color: 'black' }}>
          {label}
        </FormLabel>
        <RadioGroup
          aria-label={name}
          name={name}
          className="personRadioButtons"
          value={value}
          onChange={onChange}
        >
          <FormControlLabel value="true" control={<Radio style={{ color: '#4620B7' }} />} label="Yes" />
          <FormControlLabel value="false" control={<Radio style={{ color: '#4620B7' }} />} label="No" />
        </RadioGroup>
      </FormControl>
    </Grid>
  </div>
);

export default VettingSignOffRadioGroup;
