import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import {} from 'react-jhipster';
import { IRootState } from 'app/shared/reducers';
import { IServiceArea } from 'app/shared/model/service-area.model';

interface IIncidentTypeComponentProps {
  incidentDetails?: string | any;
  serviceAreaName?: string | any;
  incidentType?: any;
}

export const IncidentTypeComponent = (props: IIncidentTypeComponentProps) => {
  return (
    <div className="incident-report-details-div">
      <div className="incident-details-type-div">
        <div className="incident-details-left-div">
          <div className="incident-details-type-tab">Incident Type</div>
          <div className="incident-details-types-div">
            <div id="incident-details-type-text">{props.incidentType && props.incidentType.name}</div>
          </div>
        </div>

        <div className="incident-details-right-div">
          <div className="incident-details-type-tab2">Service Area</div>
          <div className="incident-details-types-div">
            <div id="incident-details-serviceArea-text">{props.serviceAreaName}</div>
          </div>
        </div>
      </div>

      <div className="incident-report-detail-comments-div">
        <div className="incident-details-comments-heading">Incident Details</div>
        <div className="incident-details-comments-text">{props.incidentDetails}</div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ organization }: IRootState) => ({
  organizationEntity: organization.entity,
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(IncidentTypeComponent);
