import React from 'react';
import { makeStyles, Avatar } from '@material-ui/core';
import { IRootState } from 'app/shared/reducers';
import Divider from '@material-ui/core/Divider';
import Modal from '@material-ui/core/Modal';
import { connect } from 'react-redux';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import CloseIcon from '@material-ui/icons/Close';
import '../../../content/css/calender.css';
import moment from 'moment-timezone';

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: '40%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '10%',
    left: '30%',
    overflow: 'auto',
    maxHeight: '71%',
  },
  checkBoxes: {
    backgroundColor: 'white important!',
    boxShadow: '1px 1px 1px #dfdbdb important!',
    border: '1px solid #cfcccc',
    width: '300px',
    '&:hover': {
      backgroundColor: '#d68fd9',
      color: 'white',
    },
  },
  paperUpdate: {
    position: 'absolute',
    width: '25%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '10%',
    left: '36%',
    overflow: 'auto',
  },
  shiftModalTitle: {
    textAlign: 'center',
    marginTop: '30px',
    marginBottom: '20px',
    color: '#4620B7',
  },
  modalForms: {
    marginBottom: theme.spacing(2),
    minWidth: 120,
    width: '100%',
  },
  modalLabel: {
    alignItems: 'center',
    display: 'flex',
  },
  modalButtonSave: {
    backgroundColor: '#4620b7',
    color: 'white',
    width: '95%',
  },
  modalButtonCancel: {
    backgroundColor: 'white',
    color: 'black',
    width: '95%',
  },
  modalNote: {
    width: '100%',
  },
  modalButton: {
    width: '35%',
  },
  modalInput: {
    width: '75%',
  },
  weekLists: {
    width: '100%',
    display: 'flex',
  },
  modalFormForAutoComplete: {
    marginTop: '0 !important',
  },
  listbox: {
    border: '1px solid #dfdbdb !important',
  },
}));

export interface IBreakHistoryModalComponent extends StateProps, DispatchProps {
  openModal: boolean;
  closeModal: (argument) => void;
  heading: string;
  breakRecords : any;
  locale : any;
}

export const BreaksHistoryModal = (props: IBreakHistoryModalComponent) => {
  const classes = useStyles();
  return (
    <Modal open={props.openModal} onClose={() => props.closeModal(false)} className="shift-history-modal">
      <div>
        <div className={classes.paper}>
          <div className="shift-history-modal-close-button">
            <CloseIcon className="shift-history-modal-close-icon" fontSize="small" onClick={() => props.closeModal(false)} />
          </div>
          <h2 id="shift-modal-title" className={classes.shiftModalTitle}>
            {props.heading}
          </h2>

              <div  className="shift-history-modal-component-outer-div">
              <table>
                    <thead>
                        <tr>
                            <th style={{width : '100px'}}>Sr no</th>
                            <th style={{width : '200px'}}>Break Start Time</th>
                            <th style={{width : '200px'}}>Break End Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(props.breakRecords) && props.breakRecords?.map((breakItem, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td><span>{breakItem.break_start_time ? moment(breakItem.break_start_time).format(`${props.locale === 1 ? 'HH:mm' : 'hh:mm A'}`) : '-'}</span></td>
                                <td><span>{breakItem.break_end_time ? moment(breakItem.break_end_time).format(`${props.locale === 1 ? 'HH:mm' : 'hh:mm A'}`) : '-'}</span></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                
              </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (IRootState) => ({
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(BreaksHistoryModal));
