import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Table } from '@material-ui/core';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntities } from './designation-qualification.reducer';
import { IDesignationQualification } from 'app/shared/model/designation-qualification.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IDesignationQualificationProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const DesignationQualification = (props: IDesignationQualificationProps) => {
  useEffect(() => {
    props.getEntities();
  }, []);

  const handleSyncList = () => {
    props.getEntities();
  };

  const { designationQualificationList, match, loading } = props;
  return (
    <div>
      <h2 id="designation-qualification-heading" data-cy="DesignationQualificationHeading">
        <Translate contentKey="wfmApp.designationQualification.home.title">Designation Qualifications</Translate>
        <div className="d-flex justify-content-end">
          <Button className="mr-2" style={{ backgroundColor: '#0dcaf0' }} onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />
            &nbsp;
            <Translate contentKey="wfmApp.designationQualification.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link to={`${match.url}/new`} className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="wfmApp.designationQualification.home.createLabel">Create new Designation Qualification</Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {designationQualificationList && designationQualificationList.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <th>
                  <Translate contentKey="wfmApp.designationQualification.id">ID</Translate>
                </th>
                <th>
                  <Translate contentKey="wfmApp.designationQualification.name">Name</Translate>
                </th>
                <th>
                  <Translate contentKey="wfmApp.designationQualification.value">Value</Translate>
                </th>
                <th>
                  <Translate contentKey="wfmApp.designationQualification.issueDate">Issue Date</Translate>
                </th>
                <th>
                  <Translate contentKey="wfmApp.designationQualification.expiryDate">Expiry Date</Translate>
                </th>
                <th>
                  <Translate contentKey="wfmApp.designationQualification.designationQualificationType">
                    Designation Qualification Type
                  </Translate>
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {designationQualificationList.map((designationQualification, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>{designationQualification.id}</td>
                  <td>{designationQualification.name}</td>
                  <td>{designationQualification.value}</td>
                  <td>
                    {designationQualification.issue_date ? (
                      <TextFormat type="date" value={designationQualification.issue_date} format={APP_LOCAL_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>
                    {designationQualification.expiry_date ? (
                      <TextFormat type="date" value={designationQualification.expiry_date} format={APP_LOCAL_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>
                    {designationQualification.designationQualificationType ? (
                      <Link to={`designation-qualification-type/${designationQualification.designationQualificationType.id}`}>
                        {designationQualification.designationQualificationType.name}
                      </Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button
                        component={Link}
                        to={`${match.url}/${designationQualification.id}`}
                        style={{ color: 'black', backgroundColor: 'rgb(13, 202, 240)' }}
                        size="small"
                        data-cy="entityDetailsButton"
                      >
                        <FontAwesomeIcon icon="eye" />
                        &nbsp;
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      &nbsp;
                      <Button
                        component={Link}
                        to={`${match.url}/${designationQualification.id}/edit`}
                        style={{ color: 'white', backgroundColor: '#007bff' }}
                        size="small"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />
                        &nbsp;
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      &nbsp;
                      <Button
                        component={Link}
                        to={`${match.url}/${designationQualification.id}/delete`}
                        style={{ color: 'white', backgroundColor: '#dc3545' }}
                        size="small"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />
                        &nbsp;
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="no-record-warning">
              <Translate contentKey="wfmApp.designationQualification.home.notFound">No Designation Qualifications found</Translate>
            </div>
          )
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ designationQualification }: IRootState) => ({
  designationQualificationList: designationQualification.entities,
  loading: designationQualification.loading,
});

const mapDispatchToProps = {
  getEntities,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(DesignationQualification);
