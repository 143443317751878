import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { IRootState } from 'app/shared/reducers';
import { getReport, reset } from './patrol-report.reducer';
import FormControl from '@material-ui/core/FormControl';
import { getEntities as getPersons, reset as resetPerson, getPersonForFilters } from 'app/entities/person/person.reducer';
import timePeriod from '../../shared/data/time_period_other.json';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { getPersonalSettings } from '../../modules/Setting/Personal-Setting.reducer';
import { getOrganizationSettings } from '../../modules/Setting/generalSettings.reducer';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import CustomToolbarComponent from '../service-area/CustomToolbarComponent';
import '../../../utils/GlobalCssRequiredInWebsite.scss';
import { Storage } from 'react-jhipster';
import { Table, SubRowAsync} from './patrol-report-react-table';
import { Autocomplete } from '@material-ui/lab';
import { Grid, IconButton, MenuItem, Popper, TextField } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import {
  handleFilterCurrentWeekStartDate,
  handleFilterCurrentWeekEndDate,
  handleFilterLastWeekStartDate,
  handleFilterLastWeekEndDate,
  getSelectedTimePeriodFilter,
} from '../../../utils/TimeFormatAndTimezone';
import SearchBar from 'material-ui-search-bar';
import { convertToPDF } from './pdfData';
import { dateFTConverter } from '../../../utils/TimeFormatAndTimezone';
import './patrolReport.scss';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import GetAppIcon from '@material-ui/icons/GetApp';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import Spinner from 'app/commonUI/spinner/spinner';
import InfiniteScroll from 'react-infinite-scroll-component';

export interface IPatrolReportProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const PatrolReport = (props: IPatrolReportProps) => {
  const [values, setValues] = useState({
    people: '',
    from_date: moment(handleFilterCurrentWeekStartDate()).format('YYYY-MM-DD'),
    to_date: moment(handleFilterCurrentWeekEndDate()).format('YYYY-MM-DD'),
  });
  const [selectedTimePeriod, setTimePeriod] = useState({ id: 3, text: 'Current week' });
  const [selectedDateTo, setSelectedDateTo] = React.useState<any>(
    getSelectedTimePeriodFilter(selectedTimePeriod.text, 'setSelectedDateTo')
  );
  const [selectedDateFrom, setSelectedDateFrom] = React.useState<any>(
    getSelectedTimePeriodFilter(selectedTimePeriod.text, 'setSelectedDateFrom')
  );
  const [selectedPerson, setSelectedPerson] = React.useState<any>(null);
  const [timeFormatSettings, setTimeFormatSettings] = useState(Storage.local.get('PersonalSetting').time_format);
  const [dateFormat, setdateFormat] = useState(Storage.local.get('PersonalSetting').date_format);
  const { patrolReportList, loading } = props;
  const [searched, setSearched] = useState<string>('');
  const [newRows, setRows] = useState(props.patrolReportList);
  const csvLinks = [''];
  const [tableData, setTableData] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = useState(false);
  const [timeFormat, setTimeFormat] = useState(timeFormatSettings === 1 ? 'HH:mm' : 'hh:mm A');
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    props.resetPerson();
    props.getPersonForFilters();
  }, []);

  useEffect(() => {
    if (pageNumber > 1) {
      props.getReport(values, pageNumber);
    }
  }, [pageNumber]);

  useEffect(() => {
    props.reset();
    props.getReport(values, pageNumber);
    setRows(null);
    setTableData([]);
  }, [values]);

  useEffect(() => {
    setRows(props.patrolReportList);
  }, [props.patrolReportList]);

  useEffect(() => {
    setdateFormat(props.setting.date_format);
    setTimeFormatSettings(props.setting.time_format);
  }, [props.setting]);

  useEffect(() => {
    const newData = [];
    let rowData: any = [];
    if (newRows) {
      if (newRows['miniReport']) {
        rowData = newRows['miniReport'];
      } else {
        rowData = newRows;
      }
      rowData?.forEach(element => {
        const data = { ...element };
        newData.push(data);
        setTableData(newData);
      });
    }
  }, [newRows]);

  const handleToggle = event => {
    setOpen(prevOpen => !prevOpen);
    setAnchorEl(event.currentTarget);
  };

  const handleTimePeriodFilter = (_, e) => {
    if (e) {
      setTimePeriod({ id: e.id, text: e.text });

      switch (e.id) {
        case 1:
          setValues(oldValues => ({ ...oldValues, ['from_date']: '', ['to_date']: moment().format('YYYY-MM-DD'), ['current_day']: '' }));
          setSelectedDateFrom(null);
          setSelectedDateTo(null);
          break;
        case 2:
          {
            const first = moment().format('YYYY-MM-DD');
            setTimePeriodValues(first, first);
            setSelectedDateFrom(first);
            setSelectedDateTo(first);
          }
          break;
        case 3:
          {
            const first = handleFilterCurrentWeekStartDate();
            const last = handleFilterCurrentWeekEndDate();
            setTimePeriodValues(first, last);
            setSelectedDateFrom(first);
            setSelectedDateTo(last);
          }
          break;
        case 4:
          {
            const first = handleFilterLastWeekStartDate();
            const last = handleFilterLastWeekEndDate();
            setTimePeriodValues(first, last);
            setSelectedDateFrom(first);
            setSelectedDateTo(last);
          }
          break;
        case 5:
          {
            const first = moment().startOf('month').format('YYYY-MM-DD');
            const last = moment().endOf('month').format('YYYY-MM-DD');
            setTimePeriodValues(first, last);
            setSelectedDateFrom(first);
            setSelectedDateTo(last);
          }
          break;
        case 6:
          {
            const first = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
            const last = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
            setTimePeriodValues(first, last);
            setSelectedDateFrom(first);
            setSelectedDateTo(last);
          }
          break;
        default:
          setValues(oldValues => ({ ...oldValues, ['from_date']: '', ['to_date']: '', ['current_day']: '' }));
      }
    } else {
      setValues(oldValues => ({ ...oldValues, ['from_date']: '', ['to_date']: '', ['current_day']: '' }));
    }
  };

  const header = [
    { label: 'Shift Date/ Time', key: 'start_time' },
    { label: 'Assignee Name', key: 'assignee_name' },
    { label: 'Position', key: 'position' },
    { label: 'Service Area', key: 'service_area' },
    { label: 'Start - End', key: 'start_end' },
    { label: 'Patrols Completed', key: 'patrols_completed' },
    { label: 'Patrols Missed', key: 'patrols_missed' },
    { label: 'Checkpoints Completed', key: 'checkpoints_completed' },
    { label: 'Checkpoints Missed', key: 'checkpoints_missed' },
    { label: 'Pictures Uploaded', key: 'pictures_uploaded' },
    { label: 'Any Note Received', key: 'any_note_received' },
  ];

  const headersDetailed = [
    { label: 'Shift Date/ Time', key: 'start_time' },
    { label: 'Assignee Name', key: 'assignee_name' },
    { label: 'Position', key: 'position' },
    { label: 'Service Area', key: 'service_area' },
    { label: 'Start - End', key: 'start_end' },
    { label: 'Patrols Completed', key: 'patrols_completed' },
    { label: 'Patrols Missed', key: 'patrols_missed' },
    { label: 'Checkpoints Completed', key: 'checkpoints_completed' },
    { label: 'Checkpoints Missed', key: 'checkpoints_missed' },
    { label: 'Pictures Uploaded', key: 'pictures_uploaded' },
    { label: 'Any Note Received', key: 'any_note_received' },
  ];

  const jsonData = [];
  const jsonDataDetailed = [];
  if (newRows && newRows['miniReport'] && newRows['miniReport'].length > 0) {
    newRows['miniReport'].forEach(element => {
      const data = { ...element };
      data.start_time =
        dateFTConverter(data.start_time, dateFormat) +
        ' ' +
        (data.start_time ? moment(data.start_time).format(`${timeFormatSettings === 1 ? 'HH:mm' : 'hh:mm A'}`) : '');
      jsonData.push(data);
    });
    newRows['miniReport'].forEach(element => {
      element['patrol_object'].forEach(individualArray => {
        const data = { ...individualArray };
        data.start_time =
          dateFTConverter(data.start_time, dateFormat) +
          ' ' +
          (data.start_time ? moment(data.start_time).format(`${timeFormatSettings === 1 ? 'HH:mm' : 'hh:mm A'}`) : '');
        data.start_end =
          dateFTConverter(data.start_end, dateFormat) +
          ' ' +
          (data.start_end ? moment(data.start_end).format(`${timeFormatSettings === 1 ? 'HH:mm' : 'hh:mm A'}`) : '');
        jsonDataDetailed.push(data);
      });
    });
  }

  const csvReport = {
    data: jsonData,
    headers: header,
    filename: 'Patrol Report.csv',
  };

  const csvReportDetailed = {
    data: jsonDataDetailed,
    headers: headersDetailed,
    filename: 'Patrol Report Detailed.csv',
  };

  const handlePersonFilterValues = (_, e) => {
    if (e) {
      setSelectedPerson({ id: e.id, text: e.text });
      return setFilterValues(e.name, e.id);
    } else {
      setValues(oldValues => ({ ...oldValues, people: '' }));
    }
  };

  const setFilterValues = (name, value) => {
    setValues(oldValues => ({ ...oldValues, [name]: value }));
  };

  const handleDateFrom = e => {
    const d = moment(e).format('YYYY-MM-DD');
    setSelectedDateFrom(e);
    if (d !== 'Invalid date') {
      setValues(oldValues => ({ ...oldValues, ['from_date']: d }));
    }
  };

  const handleDateTo = e => {
    const d = moment(e).format('YYYY-MM-DD');
    setSelectedDateTo(e);
    if (d !== 'Invalid date') {
      setValues(oldValues => ({ ...oldValues, ['to_date']: d }));
    }
  };

  const setTimePeriodValues = (first, second) => {
    setValues(oldValues => ({
      ...oldValues,
      ['from_date']: moment(first).format('YYYY-MM-DD'),
      ['to_date']: moment(second).format('YYYY-MM-DD'),
      ['current_day']: '',
    }));
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Assignee Name',
        accessor: d => <span>{d.assignee_name}</span>,
        SubCell: () => null,
      },
      {
        Header: () => null,
        id: 'expander',
        Cell: ({ row }) => (
          <>
            <span {...row.getToggleRowExpandedProps()} className="ArrowRightLeftIcon">
              {row.isExpanded ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
            </span>
          </>
        ),
        SubCell: () => null,
      },
      {
        Header: 'Date',
        accessor: d => (
          <span>
            <span className="timesheetEndDateCell">{dateFTConverter(d.start_time, dateFormat)}</span> <br />
            <span className="timeDisplay">
              {d.start_time ? moment(d.start_time).format(timeFormat) : ''}
              {' - '}
              {d.end_time ? moment(d.end_time).format(timeFormat) : ''}
            </span>
          </span>
        ),
        SubCell: cellProps => <>{cellProps.value}</>,
      },
      {
        Header: 'Service Area',
        accessor: d => d.service_area,
        SubCell: cellProps => <>{cellProps.value}</>,
      },
      {
        Header: 'Patrol Frequency',
        accessor: d => d.patrol_frequency,
        SubCell: cellProps => <>{<CheckCircleIcon className="CheckCircleIcon" />}</>,
      },
      {
        Header: 'Status',
        accessor(d) {
          const status = d.completedStatus ? 'Complete' : 'Incomplete';
          const icon = status === 'Complete' ? <CheckCircleIcon className="CheckCircleIcon" /> : <CancelIcon className="CancelIcon" />;
          return (
            <span>
              {status} {icon}
            </span>
          );
        },
        SubCell: cellProps => <>{cellProps.value}</>,
      },
    ],
    []
  );

  const renderRowSubComponent = React.useCallback(
    ({ row, rowProps, loading }) => <SubRowAsync row={row} rowProps={rowProps} loading={loading} timeFormatSettings={timeFormatSettings} />,
    []
  );
  const requestSearch = (searchedVal: string) => {
    setSearched(searchedVal);
    const value = searchedVal.toLowerCase();
    const filteredRows = props.patrolReportList['miniReport'].filter(row => {
      return (
        (row.start_time && dateFTConverter(row.start_time, dateFormat).includes(value)) ||
        (row.assignee_name && row.assignee_name.toLowerCase().includes(value)) ||
        (row.position && row.position.toLowerCase().includes(value)) ||
        (row.service_area && row.service_area.toLowerCase().includes(value)) ||
        (row.patrols_completed !== undefined && row.patrols_completed.toString().toLowerCase().includes(value)) ||
        (row.patrols_missed !== undefined && row.patrols_missed.toString().toLowerCase().includes(value)) ||
        (row.checkpoints_completed !== undefined && row.checkpoints_completed.toString().toLowerCase().includes(value)) ||
        (row.checkpoints_missed !== undefined && row.checkpoints_missed.toString().toLowerCase().includes(value)) ||
        (row.pictures_uploaded !== undefined && row.pictures_uploaded.toString().toLowerCase().includes(value)) ||
        (row.any_note_received !== undefined && row.any_note_received.toString().toLowerCase().includes(value))
      );
    });
    setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched('');
    requestSearch('');
  };

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleSync = () => {
    props.reset();
    setSelectedPerson(null);
    setSearched('');
    props.getReport(values, pageNumber);
    setTimePeriod({ id: 3, text: 'Current week' });
    setValues({ 
    people: '',
    from_date: moment(handleFilterCurrentWeekStartDate()).format('YYYY-MM-DD'),
    to_date: moment(handleFilterCurrentWeekEndDate()).format('YYYY-MM-DD'),
    });
  };

  return (
    <div className="entities-reports-main-container">
      {props.loading && <Spinner />}
      <div className="entities-reports-filter-area">
        <Grid container spacing={2} style={{ flexWrap: 'nowrap', width: '65%' }}>
          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
            <FormControl variant="outlined" size="small">
              <SearchBar
                value={searched}
                onChange={searchVal => requestSearch(searchVal)}
                onCancelSearch={() => cancelSearch()}
                placeholder="Search Here..."
                style={{
                  outline: '1px solid #bbc4c2',
                  borderRadius: '4px',
                  height: '40px',
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <KeyboardDatePicker
                id="date"
                size="small"
                inputVariant="outlined"
                className="entities_report_filter_fields datePicker"
                name="from_date"
                ToolbarComponent={prop => (
                  <>
                    <CustomToolbarComponent {...prop} settingFormat={props.setting.date_format} />
                  </>
                )}
                labelFunc={selectedDateFrom ? dateFTConverter : () => 'From'}
                value={selectedDateFrom ? moment(selectedDateFrom).format('YYYY-MM-DD') : null}
                onChange={handleDateFrom}
                InputLabelProps={{ shrink: true }}
                disabled={loading}
                placeholder="From"
              />{' '}
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <KeyboardDatePicker
                id="date"
                size="small"
                inputVariant="outlined"
                ToolbarComponent={prop => (
                  <>
                    <CustomToolbarComponent {...prop} settingFormat={props.setting.date_format} />
                  </>
                )}
                labelFunc={selectedDateTo ? dateFTConverter : () => 'Till'}
                value={selectedDateTo ? moment(selectedDateTo).format('YYYY-MM-DD') : null}
                className="entities_report_filter_fields datePicker"
                name="to_date"
                placeholder="Till"
                onChange={handleDateTo}
                InputLabelProps={{ shrink: true }}
                disabled={loading}
              />{' '}
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
            <FormControl variant="outlined" fullWidth className="entities_report_filter_fields staffFilterTimeSheet">
              <Autocomplete
                options={
                  props.personsList &&
                  props.personsList.map(person => ({
                    id: person.id,
                    text: person.first_name + ' ' + person.last_name,
                    name: 'people',
                  }))
                }
                noOptionsText={'No Records'}
                classes={{ listbox: 'autocompletelistbox' }}
                ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                getOptionLabel={option => option.text || ''}
                onChange={handlePersonFilterValues}
                value={selectedPerson}
                disabled={loading}
                renderInput={params => <TextField {...params} variant="outlined" size="small" placeholder="Staff" />}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container className="entities_reports_refresh_and_download_div" style={{ width: '35%' }}>
          <IconButton
            size="small"
            className="report-download-button"
            id="entities_reports_btn"
            onClick={handleToggle}
            disabled={props.loading}
          >
            <GetAppIcon fontSize="large" />
            {open && (
              <div className="export-menu">
                <MenuItem>
                  <div style={{ display: 'block', color: 'black' }}>
                    <span style={{ textAlign: 'left' }} onClick={() => convertToPDF(true, jsonData, selectedDateFrom, selectedDateTo)}>
                      Export PDF
                    </span>
                    <br />
                    <br />
                    <span style={{ textAlign: 'left' }}>
                      <CSVLink className="csvLinks" {...csvReport}>
                        Export CSV
                      </CSVLink>
                    </span>
                  </div>
                </MenuItem>
              </div>
            )}
          </IconButton>

          <IconButton
            size="small"
            className="report-download-button"
            id="entities_reports_btn"
            disabled={props.loading}
            onClick={handleSync}
          >
            <AutorenewIcon fontSize="large" />
          </IconButton>

          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
            <FormControl variant="outlined" fullWidth>
              <Autocomplete
                options={
                  timePeriod &&
                  timePeriod.map(time => ({
                    id: time.value,
                    text: time.label,
                  }))
                }
                noOptionsText={'--Period--'}
                classes={{ listbox: 'autocompletelistbox' }}
                ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                getOptionLabel={option => option.text || ''}
                onChange={handleTimePeriodFilter}
                value={selectedTimePeriod}
                disabled={loading}
                fullWidth
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    className="personAutocompleteTextfield entities_report_filter_fields"
                    placeholder="Time period"
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </div>

      <div>
        <Table
          columns={columns}
          data={tableData}
          patrolReportList={patrolReportList}
          renderRowSubComponent={renderRowSubComponent}
          loading={loading}
        />
        <InfiniteScroll
          dataLength={tableData.length}
          scrollThreshold="200px"
          next={() => {
            setPageNumber(pageNumber + 1);
          }}
          hasMore={true}
        loader={''}>
          <></>
        </InfiniteScroll>
      </div>
    </div>
  );
};

const mapStateToProps = (setState: IRootState) => ({
  patrolReportList: setState.patrolReport.entities,
  personsList: setState.person.filterPersons,
  loading: setState.patrolReport.loading,
  setting: setState.PersonalSettings.storedPersonalSettings,
  settings: setState.organizationSettings.storedOrganizationSettings,
});

const mapDispatchToProps = {
  getReport,
  getPersons,
  getPersonalSettings,
  getOrganizationSettings,
  resetPerson,
  reset,
  getPersonForFilters,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PatrolReport);
