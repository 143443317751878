import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity, deleteEntity, reset, setPageNumber } from './department.reducer';

export interface IDepartmentDeleteDialogProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const DepartmentDeleteDialog = props => {
  const handleClose = () => {
    props.setIsDelete(!props.isDelete);
  };

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const confirmDelete = () => {
    props.deleteEntity(props.props.match.params.id);
  };

  return (
    <Dialog open={props.isDelete} onClose={handleClose} className="DeleteBox">
      <DialogTitle data-cy="departmentDeleteDialogHeading">
        <span>This data will be deleted permanently</span>
      </DialogTitle>
      <DialogContent id="wfmApp.department.delete.question">
        <span>Are you sure you want to continue ?</span>
      </DialogContent>
      <DialogActions>
        <Button style={{ color: 'white', backgroundColor: '#14a37f' }} onClick={handleClose}>
          <FontAwesomeIcon icon="ban" />
          &nbsp;
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <Button
          id="jhi-confirm-delete-department"
          data-cy="entityConfirmDeleteButton"
          style={{ color: 'white', backgroundColor: '#b71c1c' }}
          onClick={confirmDelete}
        >
          <FontAwesomeIcon icon="trash" />
          &nbsp;
          <Translate contentKey="entity.action.delete">Delete</Translate>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = ({ department }: IRootState) => ({
  departmentEntity: department.entity,
  updateSuccess: department.updateSuccess,
  loading: department.loading,
});

const mapDispatchToProps = { getEntity, deleteEntity, reset, setPageNumber };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentDeleteDialog);
