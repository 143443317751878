import { Link, RouteComponentProps } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import '../../../form.scss';
import { IRootState } from 'app/shared/reducers';

interface Props {
  storeAboutPositionSection: (data: any) => void;
  handleIncrementDecrement: (tag: string) => void;
  setting: any;
}
interface FormData {}

export interface OtherRefrenceProps extends Props, StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const responseSubmitted = props => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <img src="content/images/response-submitted.png" style={{ maxWidth: '100%', maxHeight: '100%' }} />
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({});
const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(responseSubmitted);
