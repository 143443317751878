import React, { useState, useEffect, Fragment } from 'react';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { getDateFormat } from 'app/shared/util/calender-utils';
import CustomToolbarComponent from '../../../../../entities/service-area/CustomToolbarComponent';
import moment from 'moment';

export const ProfessionalHistoryOthersSection = props => {
  const { setFormValues, setHistory, fieldError, handleReferenceModal, handleFilesData, values, dateSetting, isEdit } = props;
  const [fileName, setFileName] = useState('');
  const [isSelected, setIsSelected] = useState(false);
  useEffect(() => {
    if (isEdit && values[`history_other_proof_name`]) {
      setIsSelected(true);
      setFileName(values[`history_other_proof_name`]);
    }
  }, []);

  const handleProofs = (event, name) => {
    setIsSelected(true);
    setFileName(event.target.files[0].name);
    handleFilesData(event, name);
  };

  return (
    <div className="OtherSectionOuterDiv">
      <Grid container spacing={3}>
        <Grid item xs={5} className="professional-history-fields professional-history-others-top-field">
          <TextField
            id="history-others-reason"
            select
            value={values['history-others-reason'] ? values['history-others-reason'] : ''}
            onChange={setFormValues}
            variant="outlined"
            label="Select One"
            name="history-others-reason"
            style={{
              width: '100%',
              color: 'black',
            }}
          >
            <MenuItem value="Unemployed">Unemployed</MenuItem>
            <MenuItem value="Holidays">Holidays</MenuItem>
            <MenuItem value="Others">Others</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={3} className="professional-history-fields professional-history-others-top-field">
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <KeyboardDatePicker
              autoOk
              variant="inline"
              inputVariant="outlined"
              name="history_others_reason_start_date"
              className="professional-history-date_picker"
              defaultValue="From"
              id="history_others_reason_start_date"
              style={{
                width: '100%',
              }}
              label="From"
              format={dateSetting ? getDateFormat(dateSetting) : 'MM/DD/YYYY'}
              value={values['history_others_reason_start_date'] && values['history_others_reason_start_date']}
              ToolbarComponent={prop => (
                <>
                  <CustomToolbarComponent {...prop} settingFormat={dateSetting} />
                </>
              )}
              placeholder={dateSetting ? getDateFormat(dateSetting) : 'MM/DD/YYYY'}
              keyboardIcon={<img src="content/images/icons8-calendar-22.png" />}
              InputAdornmentProps={{ position: 'end' }}
              onChange={date =>
                setHistory(date, 'history_others_reason_start_date', 'history_others_reason_start_date', 'history_others_reason_start_date')
              }
              helperText={fieldError['history_others_reason_start_date'] ? fieldError['history_others_reason_start_date'] : ''}
              error={fieldError['history_others_reason_start_date']}
            />{' '}
          </MuiPickersUtilsProvider>
        </Grid>
        <div style={{ alignSelf: 'center', marginTop: '55px' }}> - </div>
        <Grid item xs={3} className="professional-history-fields professional-history-others-top-field">
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <KeyboardDatePicker
              autoOk
              variant="inline"
              className="history_others_reason_end_date professional-history-date_picker"
              inputVariant="outlined"
              name="history_others_reason_end_date"
              defaultValue="From"
              id="history_others_reason_end_date"
              style={{
                width: '100%',
              }}
              label="Till"
              format={dateSetting ? getDateFormat(dateSetting) : 'MM/DD/YYYY'}
              value={values['history_others_reason_end_date'] && values['history_others_reason_end_date']}
              ToolbarComponent={prop => (
                <>
                  <CustomToolbarComponent {...prop} settingFormat={dateSetting} />
                </>
              )}
              placeholder={dateSetting ? getDateFormat(dateSetting) : 'MM/DD/YYYY'}
              keyboardIcon={<img src="content/images/icons8-calendar-22.png" />}
              InputAdornmentProps={{ position: 'end' }}
              onChange={date =>
                setHistory(date, 'history_others_reason_end_date', 'history_others_reason_end_date', 'history_others_reason_end_date')
              }
              helperText={fieldError['history_others_reason_end_date'] ? fieldError['history_others_reason_end_date'] : ''}
              error={fieldError['history_others_reason_end_date']}
            />{' '}
          </MuiPickersUtilsProvider>
        </Grid>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <input
              type="file"
              name="right_to_work"
              onChange={() => handleProofs(event, 'professioal_history_others_proof')}
              id="contained-button-file"
              style={{ display: 'none' }}
            />
            <label htmlFor="contained-button-file">
              <Button className="professional-history-others-buttons" component="span">
                Attach Proof
              </Button>
            </label>
            {isSelected && <label style={{ fontSize: 'small', marginLeft: '12px' }}>{fileName ? fileName : ''}</label>}
          </div>
          <Button className="professional-history-others-buttons" onClick={() => props.handleReferenceModal()}>
            {' '}
            Add Reference{' '}
          </Button>
        </div>
        <Grid item xs={12} className="professional-history-fields">
          <InputLabel htmlFor="history-others-reason-extra-note"> Extra Note (Optional) </InputLabel>
          <TextareaAutosize
            aria-label="empty textarea"
            className="form-control history-others-reason-extra-note"
            name={'history-others-reason-extra-note'}
            id="history-others-reason-extra-note"
            onChange={setFormValues}
            value={values['history-others-reason-extra-note'] ? values['history-others-reason-extra-note'] : ''}
          />
        </Grid>
      </Grid>
      <br />
    </div>
  );
};
