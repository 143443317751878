import axios from 'axios';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { IPatrolReport, defaultValue } from 'app/shared/model/patrol-report.model';

export const ACTION_TYPES = {
  FETCH_PATROL_REPORT: 'patrol-report/FETCH_PATROL_REPORT',
  FETCH_PATROL_REPORT_IMAGES: 'patrol-report/FETCH_PATROL_REPORT_IMAGES',
  RESET: 'patrol-report/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IPatrolReport>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  patrolImage : null,
};

export type PatrolReportState = Readonly<typeof initialState>;

export default (state: PatrolReportState = initialState, action): PatrolReportState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_PATROL_REPORT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };

    case FAILURE(ACTION_TYPES.FETCH_PATROL_REPORT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_PATROL_REPORT):
      return {
        ...state,
        loading: false,
        entities: [...state.entities, ...(action.payload.data?.miniReport || [])],
      };
      case REQUEST(ACTION_TYPES.FETCH_PATROL_REPORT_IMAGES):
        return {
          ...state,
          updateSuccess: false,
          loading: true,
        };
  
      case FAILURE(ACTION_TYPES.FETCH_PATROL_REPORT_IMAGES):
        return {
          ...state,
          loading: false,
          updateSuccess: false,
          errorMessage: action.payload,
        };
      case SUCCESS(ACTION_TYPES.FETCH_PATROL_REPORT_IMAGES):
        return {
          ...state,
          loading: false,
          patrolImage: action.payload.data,
        };   
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/patrol-reports';
const imgUrl = 'api/getPatrolReportImage';

export const getReport = (data, pageNo) => ({
  type: ACTION_TYPES.FETCH_PATROL_REPORT,
  payload: axios.get<IPatrolReport>(
    `${apiUrl}?filter[person_id]=${data.people}&filter[from_shifts]=${data.from_date}&filter[to_shifts]=${data.to_date}&page=${pageNo}`
  ),
});

export const getPatrolReportImages = (id) => ({
  type: ACTION_TYPES.FETCH_PATROL_REPORT_IMAGES,
  payload: axios.get<IPatrolReport>(
    `${imgUrl}/${id}`
  ),
});

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
