import React from 'react';
import { NavLink as Link } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import { NewNavDropdown } from './menu-components';
import DescriptionIcon from '@material-ui/icons/Description';
import AppsIcon from '@material-ui/icons/Apps';
import Item from './Item';

export const HRMenu = () => {
  return (
    <div className="headerIconScheduler">
      <NewNavDropdown name="HR" id="schedules-menu" data-cy="calendar">
        <span className="grid-containers2"></span>
        <div className="grid-containers">
          <Item
            stylesProperty="staff"
            link="/staff"
            unFillImage="../../../../content/images/Staff.png"
            fillImage="../../../../content/images/Stafffill.png"
            name="Staff"
          />

          <Item
            stylesProperty="staff"
            link="/past-employees"
            unFillImage="../../../../content/images/Staff.png"
            fillImage="../../../../content/images/Stafffill.png"
            name="Past Employees"
          />

          <Item
            stylesProperty="application"
            link="/vettingDashboard"
            unFillImage="../../../../content/images/Application.png"
            fillImage="../../../../content/images/Applicationfill.png"
            name="Vetting"
          />
          <Item
            stylesProperty="leave"
            link="/Leave"
            unFillImage="../../../../content/images/Leave.png"
            fillImage="../../../../content/images/Leavefill.png"
            name="Leave Management"
          />
        </div>
      </NewNavDropdown>
      <img className="icon-hr" src="../../../../content/images/angle-right.png" alt="dropdown-icon" />
      <span id="pointer"></span>
    </div>
  );
};
export default HRMenu;
