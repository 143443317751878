import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity, deleteEntity } from './country-admin-unit.reducer';

export interface ICountryAdminUnitDeleteDialogProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const CountryAdminUnitDeleteDialog = (props: ICountryAdminUnitDeleteDialogProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const handleClose = () => {
    props.history.push('/country-admin-unit');
  };

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const confirmDelete = () => {
    props.deleteEntity(props.countryAdminUnitEntity.id);
  };

  const { countryAdminUnitEntity } = props;
  return (
    <Dialog open onClose={handleClose} style={{ bottom: '550px' }}>
      <DialogTitle data-cy="countryAdminUnitDeleteDialogHeading">
        <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>
        <IconButton aria-label="close" onClick={handleClose} style={{ right: '2px', top: '6px', color: 'black', position: 'absolute' }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent id="wfmApp.countryAdminUnit.delete.question">
        <Translate contentKey="wfmApp.countryAdminUnit.delete.question" interpolate={{ id: countryAdminUnitEntity.id }}>
          Are you sure you want to delete this CountryAdminUnit?
        </Translate>
      </DialogContent>
      <DialogActions>
        <Button style={{ color: 'white', backgroundColor: '#14a37f' }} onClick={handleClose}>
          <FontAwesomeIcon icon="ban" />
          &nbsp;
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <Button
          id="jhi-confirm-delete-countryAdminUnit"
          data-cy="entityConfirmDeleteButton"
          style={{ color: 'white', backgroundColor: '#b71c1c' }}
          onClick={confirmDelete}
        >
          <FontAwesomeIcon icon="trash" />
          &nbsp;
          <Translate contentKey="entity.action.delete">Delete</Translate>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = ({ countryAdminUnit }: IRootState) => ({
  countryAdminUnitEntity: countryAdminUnit.entity,
  updateSuccess: countryAdminUnit.updateSuccess,
});

const mapDispatchToProps = { getEntity, deleteEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CountryAdminUnitDeleteDialog);
