import React, { useEffect, useState } from 'react';
import SubcontractorDrawerComponent from './component/SubcontractorDrawer';
import ProfileDetail from './subcontractor-view/profileDetails';
import SubcontractorServiceAreas from './subcontractor-view/subcontractor-serviceAreas';
import SubcontractorDocuments from './subcontractor-view/subcontractor-documents';
import SubcontractorRateCard from './subcontractor-view/ratecard';
import Subcontractor_Edit_Del_Btn from './component/subcontractor-edit-del-btn';
import SubcontractorDetailsForm from './subcontractorDetailsForm';

const SubcontractorView = props => {
  const [page, setPage] = useState(1);
  return (
    <div
      className="subcontractor-view-deisgns-main-container"
      style={{
        padding: '32px',
        background: 'transparent linear-gradient(180deg, #F3F5F7 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box',
      }}
    >
      <div className="settings-outer-div">
        <div className="settings-drawer-container">
          <SubcontractorDrawerComponent setPage={setPage} page={page} />
        </div>
        <div style={{ width: '80%', height: '100%' }}>
          {page === 1 && <SubcontractorDetailsForm {...props} />}
          {page === 4 && <SubcontractorDocuments {...props} />}
        </div>
      </div>
    </div>
  );
};

export default SubcontractorView;
