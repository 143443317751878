import React from 'react';

const ShiftRecordHeaderComponent = ({ headers }) => {
  const visibleColumns = Object.values(headers).length;
  const dynamicClassName = visibleColumns <= 4 ? 'scol-few-columns' : 'scol';
  return (
    <div className="globalTableHeaderGradientColor subcontractorTableHeaderRow sticky_div">
      {headers.map((header, index) => (
        <div key={index} className={`timeclockTableHeaderCell ${dynamicClassName}`}>
          {header}
        </div>
      ))}
    </div>
  );
};

export default ShiftRecordHeaderComponent;