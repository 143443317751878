/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import { IRootState } from 'app/shared/reducers';
import Divider from '@material-ui/core/Divider';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import { Radio, RadioGroup } from '@material-ui/core';
import '../../../content/css/calender.css';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useStyles } from './calendarModalStyles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { CreateSchedule } from './calendarModalFunctions';

export interface IPasteModalComponent {
  showPasteModal: boolean;
  setShowPasteModal: (value: boolean) => void;
  handleShowForPasting: () => void;
  modalProp: any;
  calendarDisplayWindow: any;
}

export const PasteModalComponent = (props: IPasteModalComponent) => {
  const { setShowPasteModal, showPasteModal, modalProp, calendarDisplayWindow } = props;
  const [occurrenceCreate, setOccurrenceCreate] = useState(false);
  const [seriesCreate, setSeriesCreate] = useState(false);
  const classes = useStyles(props);

  const handleClose = () => setShowPasteModal(false);

  const handleOccurrenceChange = () => {
    setOccurrenceCreate(true);
    modalProp?.setModalForm(oldValues => ({
      ...oldValues,
      isRepeat: false,
      repeatEnd: '',
      repeatStart: '',
      repeatType: '',
      repeatPattern: '',
    }));

    if (seriesCreate === true) {
      setSeriesCreate(false);
    }
  };

  const handleSeriesChange = () => {
    setSeriesCreate(true);
    modalProp?.setModalForm(oldValues => ({
      ...oldValues,
      isRepeat: true,
      repeatEnd: modalProp.modalForm.repeatEnd,
      repeatStart: modalProp.modalForm.start_date,
      repeatType: modalProp.modalForm.repeatType,
      repeatPattern: modalProp.modalForm.repeatPattern,
    }));

    if (occurrenceCreate === true) {
      setOccurrenceCreate(false);
    }
  };

  return (
    <>
      <Modal open={showPasteModal} onClose={handleClose}>
        <div className={classes.paperUpdate}>
          <h2 className={classes.shiftModalTitle}>Create Shift</h2>
          <p>Are you sure you want to create this shift?</p>

          <Divider className="Calendar-new-shift-modal-divider" />

          <Grid item className={classes.modalInput} xs={6} lg={6}>
            <FormControl variant="outlined" size="small">
              <RadioGroup style={{ display: 'flex', flexDirection: 'column' }}>
                <FormControlLabel
                  className={classes.checkBoxes}
                  label="Create new shift"
                  control={<Radio style={{ color: '#4620B7' }} checked={occurrenceCreate} onChange={handleOccurrenceChange} />}
                />
                <FormControlLabel
                  className={classes.checkBoxes}
                  label="Create series"
                  control={<Radio style={{ color: '#4620B7' }} checked={seriesCreate} onChange={handleSeriesChange} />}
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Divider className="Calendar-new-shift-modal-divider" />

          <FormControl className={classes.modalForms}>
            <FormGroup row={true}>
              <Grid item xs={3} lg={4}>
                <Button
                  variant="contained"
                  className={classes.modalButtonSave}
                  onClick={() => {
                    CreateSchedule(modalProp.parentProp, modalProp, calendarDisplayWindow);
                    setShowPasteModal(false);
                  }}
                >
                  Confirm
                </Button>
              </Grid>
              <Grid item xs={3} lg={4}>
                <Button variant="contained" className={classes.modalButtonCancel} onClick={handleClose}>
                  Cancel
                </Button>
              </Grid>
            </FormGroup>
          </FormControl>
        </div>
      </Modal>
    </>
  );
};
