import { exportPDF } from './exportPDF';
import moment from 'moment';

const header = ['Name', 'Date/Time', 'Service Area', 'Position', 'Sign-In - Sign-Out', 'Working Hours', 'Rate', 'Total Wage'];

export const convertToPDF = (typeFlag, jsonData, selectedDateFrom, selectedDateTo) => {
  const DateFrom = moment(selectedDateFrom).format('DD-MM-YYYY');
  const DateTo = moment(selectedDateTo).format('DD-MM-YYYY');
  const headers = [header];
  const title = 'Time Card Report';
  const data = jsonData.map(row => [
    row.person ? row.person : '',
    row.timeFrom === null && row.timeTo === null
      ? row.startDate + '\n' + row.endDate
      : row.startDate + '\n' + row.timeFrom + '\n' + row.timeTo,
    row.location,
    row.description,
    (row.checkIn === null ? '' : row.checkIn) + ' - ' + (row.checkOut === null ? '' : row.checkOut),
    row.actualTime,
    row.payRate,
    row.actualPay,
  ]);
  const filename = typeFlag === true ? 'Time Card Report.pdf' : 'Time Card Report Detailed.pdf';

  exportPDF(DateFrom, DateTo, headers, title, data, filename);
};
