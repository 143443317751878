import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import DesignationQualificationType from './designation-qualification-type';
import DesignationQualificationTypeDetail from './designation-qualification-type-detail';
import DesignationQualificationTypeUpdate from './designation-qualification-type-update';
import DesignationQualificationTypeDeleteDialog from './designation-qualification-type-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={DesignationQualificationTypeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={DesignationQualificationTypeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={DesignationQualificationTypeDetail} />
      <ErrorBoundaryRoute path={match.url} component={DesignationQualificationType} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={DesignationQualificationTypeDeleteDialog} />
  </>
);

export default Routes;
