import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Grid, Table } from '@material-ui/core';
import { IRootState } from 'app/shared/reducers';
import { getEntity } from '../person.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { Paper } from '@material-ui/core';
import '../../sub-contractor/subcontractor.scss';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { savePersonDocuments, getPersonDocuments, getDocumentById, getDocTypes, reset, deletePersonDocument } from '../person.reducer';
import { Autocomplete } from '@material-ui/lab';
import FileViewer from 'react-file-viewer';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import { useLocation } from 'react-router-dom';

export interface IPersonDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: '100%',
  },
}));

export const PersonDocuments = (props: IPersonDetailProps) => {
  const { personEntity, personDocuments } = props;
  const [fileName, setFilesName] = useState({ name: 'No File Chosen' });
  const [values, setValues] = useState(personEntity);
  const [personDocs, setPersonDocs] = useState(props.personDocuments);
  const [modalOpen, setModalOpen] = useState(false);
  const [docdata, setDocData] = useState(props.documentData);
  const location = useLocation();

  useEffect(() => {
    props.getPersonDocuments(props.match.params.id);
    props.getDocTypes();
  }, []);

  useEffect(() => {
    setPersonDocs(props.personDocuments);
  }, [props.personDocuments]);

  useEffect(() => {
    setDocData(props.documentData);
  }, [props.documentData]);

  const handleChange = (_, e) => {
    setValues(oldValues => ({ ...oldValues, ['doc_type']: e.id }));
  };

  const handleDescription = e => {
    setValues(oldValues => ({ ...oldValues, ['description']: e.target.value }));
  };

  const handlePersonDocs = e => {
    setValues(oldFieldValues => ({ ...oldFieldValues, ['person_docs']: e.target.files[0] }));
    setFilesName(oldFieldValues => ({ ...oldFieldValues, ['name']: e.target.files[0].name }));
  };

  const handleDeletePersonDocument = document_id => {
    props.deletePersonDocument(document_id, props.match.params.id);
  };

  const handleDocView = id => {
    setModalOpen(true);
    props.getDocumentById(id);
  };

  const handleCloseViewDocModal = () => {
    setModalOpen(false);
    setDocData({ ext: '', file: '' });
  };

  const submit = event => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('avatar', values['person_docs']);
    formData.append('description', values['description']);
    formData.append('person_id', values['id'].toString());
    formData.append('doc_type', values['doc_type']);
    props.savePersonDocuments(values['id'], formData);
    setValues(oldFieldValues => ({ ...oldFieldValues, ['description']: '' }));
  };
  const handleDownloadSubcontractorDocument = () => {
    fetch(docdata?.file).then(response => {
      response.blob().then(blob => {
        const fileURL = window.URL.createObjectURL(blob);
        const name = 'Sample.' + docdata?.ext;
        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = name;
        alink.click();
      });
    });
  };

  const printFile = () => {
    window.print();
  };

  return (
    <div className="person-view-main-container">
      <div style={{ padding: '5% 0' }}>
        {location.state !== true ? (
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <TextField
                variant="outlined"
                subcontractor-view-documents-table-div
                size="small"
                name="brief_description"
                onChange={handleDescription}
                label="Brief Description"
                fullWidth
                value={values['description']}
              />
            </Grid>

            <Grid item lg={2} md={2} sm={2} xs={2}>
              <FormControl required className="person-doc-type-field" size="small" fullWidth>
                <Autocomplete
                  fullWidth
                  options={
                    props.documentTypes &&
                    props.documentTypes.map(type => ({
                      id: type.id,
                      name: type.type,
                    }))
                  }
                  noOptionsText={'No Option'}
                  classes={{ listbox: 'autocompletelistbox' }}
                  ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                  getOptionLabel={option => option.name || ''}
                  onChange={handleChange}
                  renderInput={params => <TextField {...params} variant="outlined" size="small" label="Type" required fullWidth />}
                />
              </FormControl>
            </Grid>

            <Grid item lg={2} md={3} sm={2} xs={2}>
              <input type="file" id="file-input" name="subcontractor_docs" style={{ display: 'none' }} onChange={handlePersonDocs} />
              <label htmlFor="file-input" style={{ width: '100%' }}>
                <Button variant="contained" component="span" id="person-upload-docments-button">
                  Upload Document
                </Button>
              </label>
            </Grid>

            <Grid item lg={2} md={2} sm={2} xs={2}>
              <Button variant="contained" component="span" className="subcontractor-view-submitButton" onClick={submit}>
                Submit
              </Button>
              <span style={{ fontSize: '10px' }} id="file-chosen4">
                {fileName.name}
              </span>
            </Grid>
          </Grid>
        ) : null}

        <div className="subcontractor-view-documents-table-div">
          <div>
            {personDocs && personDocs.length > 0 ? (
              <Table>
                <thead className="globalTableHeaderGradientColor">
                  <tr className="subcontractor-documentsTableHeaderRow">
                    <th className="subcontractor-documentsTableHeaderCell subcontractorHeaderDocumentCell">Document</th>
                    <th className="subcontractor-documentsTableHeaderCell subcontractorHeaderDescriptionCell">Description</th>
                    <th className="subcontractor-documentsTableHeaderCell subcontractorHeaderActionsCell">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {personDocs
                    ? personDocs.map((docs, i) => (
                        <tr key={`entity-${i}`} data-cy="entityTable" className="subcontractor-documentsTableBodyRows">
                          <td className="subcontractor-documentsTableBodyCell subcontractorTableBodyDocumentcell">
                            <dd>{i + 1}</dd>
                          </td>
                          <td className="subcontractor-documentsTableBodyCell subcontractorTableBodyDescriptioncell">
                            <a
                              onClick={() => {
                                handleDocView(docs.id);
                              }}
                            >
                              {docs.description}
                            </a>
                          </td>
                          <td className="text-right subcontractor-documentsTableBodyCell subcontractorTableBodyActioncell">
                            <div className="btn-group serviceAreaActionCellButtons">
                              <>
                                &nbsp;
                                <IconButton
                                  style={{ margin: '1%' }}
                                  data-cy="entityDeleteButton"
                                  className="subcontractor_doc_delete_btn"
                                  onClick={() => {
                                    handleDeletePersonDocument(docs.id);
                                  }}
                                >
                                  <img src="content/icons/Icon awesome-trash-alt.png" />
                                </IconButton>
                              </>
                            </div>
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </Table>
            ) : (
              <div className="no-record-warning">No Record found</div>
            )}
          </div>
        </div>

        <Modal open={modalOpen} className="file-view-modal">
          <Paper style={{ width: '100%', height: '100%' }}>
            <div style={{ padding: '20px' }}>
              <IconButton
                className="downloaANdPrintButton"
                size="small"
                style={{ marginLeft: '48%' }}
                onClick={() => {
                  handleDownloadSubcontractorDocument();
                }}
              >
                <img src="content/images/icons8-download-24.png" />
              </IconButton>
              <IconButton
                className="downloaANdPrintButton"
                size="small"
                style={{ marginLeft: '2%' }}
                onClick={() => {
                  printFile();
                }}
              >
                <img src="content/images/icons8-print-24.png" />
              </IconButton>
              <CloseIcon style={{ marginLeft: '40%' }} onClick={handleCloseViewDocModal}></CloseIcon>{' '}
            </div>

            <div className="file-viewer-style">
              <FileViewer fileType={docdata?.ext} filePath={docdata?.file} />
            </div>
          </Paper>
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = ({ person }: IRootState) => ({
  personEntity: person.entity,
  personDocuments: person.personDocuments,
  documentTypes: person.documentTypes,
  documentData: person.documentData,
});

const mapDispatchToProps = {
  getEntity,
  savePersonDocuments,
  getPersonDocuments,
  getDocTypes,
  reset,
  deletePersonDocument,
  getDocumentById,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PersonDocuments);
