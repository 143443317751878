import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Grid, Divider } from '@material-ui/core';
import { IRootState } from 'app/shared/reducers';
import './automated-control-room.scss';
import { ACRShiftSettings } from 'app/shared/model/automated-control-room-shift-settings.model';
import shift_confirmation_times from '../../shared/data/shift_confirmation_times.json';
import shift_sign_in_times from '../../shared/data/shift_sign_in_times.json';
import shift_sign_out_times from '../../shared/data/shift_sign_out_times.json';
import ShiftBasicSettings from './components/shift-basic-settings';
import { handleShiftReminderButtons } from '../../../utils/FilterValuesFucntions';
export interface IAutomatedComtrolRoomShiftProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
  values?: ACRShiftSettings;
  setValues?: any;
  ACRShiftSettingsValues?: ACRShiftSettings;
}

export const ShiftConfirmation: React.FC<IAutomatedComtrolRoomShiftProps> = props => {
  const { values, setValues } = props;
  const [shiftConfirmationValue, setShiftConfirmationValue] = React.useState<{ label: string; id: number }>(null);
  const [shiftSignInValue, setShiftSignInValue] = React.useState<{ label: string; id: number }>(null);
  const [shiftSignOutValue, setShiftSignOutValue] = React.useState<{ label: string; id: number }>(null);

  useEffect(() => {
    updateAutoCompleteBoxes(values);
  }, [props.ACRShiftSettingsValues]);

  const handleShiftConfirmationTime = (event, time, trigger) => {
    const name = trigger.split('|');
    if (name[0] === 'Shift Confirmation') {
      setShiftConfirmationValue(time);
    }
    if (name[0] === 'Sign In') {
      setShiftSignInValue(time);
    }
    if (name[0] === 'Sign Out') {
      setShiftSignOutValue(time);
    }
    setValues(oldValues => ({
      ...oldValues,
      [name[0]]: { ...oldValues[name[0]], [name[1]]: time ? time.id : 0 },
    }));
  };

  const handleTextfields = event => {
    const name = event.target.name.split('|');
    setValues(oldValues => ({
      ...oldValues,
      [name[0]]: { ...oldValues[name[0]], [name[1]]: parseInt(event.target.value, 10) },
    }));
  };

  const updateAutoCompleteBoxes = newValues => {
    if (newValues && newValues['Shift Confirmation'] && newValues['Shift Confirmation']['reminder_time']) {
      const time = Object.values(shift_confirmation_times).find(e => e.id === newValues['Shift Confirmation']['reminder_time']);
      if (time) {
        setShiftConfirmationValue(time);
      } else {
        setShiftConfirmationValue({ label: 'Custom', id: 0 });
      }
    }
    if (newValues && newValues['Sign In'] && newValues['Sign In']['reminder_time']) {
      const time = Object.values(shift_sign_in_times).find(e => e.id === newValues['Sign In']['reminder_time']);
      if (time) {
        setShiftSignInValue(time);
      } else {
        setShiftSignInValue({ label: 'Custom', id: 0 });
      }
    }
    if (newValues && newValues['Sign Out'] && newValues['Sign Out']['reminder_time']) {
      const time = Object.values(shift_sign_out_times).find(e => e.id === newValues['Sign Out']['reminder_time']);
      if (time) {
        setShiftSignOutValue(time);
      } else {
        setShiftSignOutValue({ label: 'Custom', id: 0 });
      }
    }
  };

  return (
    <div className="shift-confirmation-main-container">
      <div className="control-room-shift-confirmation-main-div">
        <div className="shift-confirmation-main-heading">
          <h1 style={{ color: '#7A55DE', fontWeight: 'bold' }}>Shift Confirmation (Mins)</h1>
        </div>

        <Grid container spacing={4}>
          <Grid item sm={6} lg={6} md={6}>
            <h6 style={{ color: '#7A55DE' }}>Send Shift Confirmation</h6>
          </Grid>

          <Grid item sm={6} lg={6} md={6}>
            <h6 style={{ color: '#7A55DE' }}> If Confirmation Missed?</h6>
          </Grid>
        </Grid>

        <Grid container className="custom-time-label" spacing={4}>
          <Grid item sm={5} lg={5} md={5}></Grid>

          <Grid className="label-custom-time" item sm={1} lg={1} md={1}>
            {shiftConfirmationValue && shiftConfirmationValue['id'] === 0 && <label className="custom-label">Custom Time</label>}
          </Grid>
        </Grid>

        <ShiftBasicSettings
          autoCompleteOptions={shift_confirmation_times}
          autoCompleteValue={shiftConfirmationValue}
          handleShiftConfirmationTime={handleShiftConfirmationTime}
          handleTextfields={handleTextfields}
          values={values}
          setValues={setValues}
          handleShiftReminderButtons={handleShiftReminderButtons}
          autoCompleteName="Shift Confirmation|reminder_time"
          title="Shift Confirmation"
          response_time="Shift Confirmation|response_time"
          frequency="Shift Confirmation|frequency"
        />

        <Divider className="automated-control-room-divider"></Divider>

        <Grid container spacing={4}>
          <Grid item sm={6} lg={6} md={6}>
            <h6 style={{ color: '#7A55DE' }}>Shift Confirmation If Shift Starts but Not Signed-in</h6>
          </Grid>

          <Grid item sm={6} lg={6} md={6}>
            <h6 style={{ color: '#7A55DE' }}> If Confirmation Missed?</h6>
          </Grid>
        </Grid>

        <Grid container className="custom-time-label" spacing={4}>
          <Grid item sm={5} lg={5} md={5}></Grid>

          <Grid className="label-custom-time" item sm={1} lg={1} md={1}>
            {shiftSignInValue && shiftSignInValue['id'] === 0 && <label className="custom-label">Custom Time</label>}
          </Grid>
        </Grid>

        <ShiftBasicSettings
          autoCompleteOptions={shift_sign_in_times}
          autoCompleteValue={shiftSignInValue}
          handleShiftConfirmationTime={handleShiftConfirmationTime}
          handleTextfields={handleTextfields}
          values={values}
          setValues={setValues}
          handleShiftReminderButtons={handleShiftReminderButtons}
          autoCompleteName="Sign In|reminder_time"
          title="Sign In"
          response_time="Sign In|response_time"
          frequency="Sign In|frequency"
        />

        <Divider className="automated-control-room-divider"></Divider>

        <Grid container spacing={4}>
          <Grid item sm={6} lg={6} md={6}>
            <h6 style={{ color: '#7A55DE' }}>Shift Confirmation If Shift Ends but Not Signed-out</h6>
          </Grid>

          <Grid item sm={6} lg={6} md={6}>
            <h6 style={{ color: '#7A55DE' }}> If Confirmation Missed?</h6>
          </Grid>
        </Grid>

        <Grid container className="custom-time-label" spacing={4}>
          <Grid item sm={5} lg={5} md={5}></Grid>

          <Grid className="label-custom-time" item sm={1} lg={1} md={1}>
            {shiftSignOutValue && shiftSignOutValue['id'] === 0 && <label className="custom-label">Custom Time</label>}
          </Grid>
        </Grid>

        <ShiftBasicSettings
          autoCompleteOptions={shift_sign_out_times}
          autoCompleteValue={shiftSignOutValue}
          handleShiftConfirmationTime={handleShiftConfirmationTime}
          handleTextfields={handleTextfields}
          values={values}
          setValues={setValues}
          handleShiftReminderButtons={handleShiftReminderButtons}
          autoCompleteName="Sign Out|reminder_time"
          title="Sign Out"
          response_time="Sign Out|response_time"
          frequency="Sign Out|frequency"
        />
      </div>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ShiftConfirmation);
