import { exportPDF } from '../timesheet-summary/exportPDF';

const header = ['Employee Name', 'Service Area', 'Start Date', 'End Date', 'Description'];

export const convertToPDF = (jsonData, selectedDateFrom, selectedDateTo) => {
  const headers = [header];
  const title = 'Unauthorised-Staff Report';
  const data = jsonData.map(row => [
    row.person && row.person.first_name + ' ' + row.person.last_name,
    row.serviceArea && row.serviceArea.name,
    row.start_date,
    row.end_date,
    row.description,
  ]);
  const filename = 'Unauthorised_Staff_Report.pdf';

  exportPDF(selectedDateFrom, selectedDateTo, headers, title, data, filename);
};
