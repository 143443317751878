import axios from 'axios';
import React, { useEffect, useState } from 'react';

import { Storage } from 'react-jhipster';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IOrganizationSettings } from 'app/shared/model/organizationSettings.model';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment-timezone';

export const ACTION_TYPES = {
  UPDATE_ORGANIZATION_SETTINGS: 'settings/UPDATE_ORGANIZATION_SETTINGS',
  GET_ORGANIZATION_SETTINGS: 'settings/GET_ORGANIZATION_SETTINGS',
  UPLOAD_AVATAR_SETTINGS: 'settings/UPLOAD_AVATAR_SETTINGS',
};

const initialState = {
  settings: [] as Array<IOrganizationSettings>,
  storedOrganizationSettings: [] as IOrganizationSettings,
  loading: false,
  errorMessage: null,
  updating: false,
  updateSuccess: false,
  uploadedAvatar: '',
};

export type OrganizationSettingsState = Readonly<typeof initialState>;
export default (state: OrganizationSettingsState = initialState, action): OrganizationSettingsState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.GET_ORGANIZATION_SETTINGS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.UPDATE_ORGANIZATION_SETTINGS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.GET_ORGANIZATION_SETTINGS):
    case FAILURE(ACTION_TYPES.UPDATE_ORGANIZATION_SETTINGS):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.GET_ORGANIZATION_SETTINGS):
      Storage.local.set('IndividualSetting', action.payload.data);
      moment.updateLocale('en', {
        week: {
          dow: action.payload.data.start_day_of_week,
        },
      });
      return {
        ...state,
        loading: false,
        storedOrganizationSettings: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.UPDATE_ORGANIZATION_SETTINGS):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        settings: action.payload,
      };
    case SUCCESS(ACTION_TYPES.UPLOAD_AVATAR_SETTINGS):
      return {
        ...state,
        uploadedAvatar: action.payload,
      };
    default:
      return state;
  }
};

export const updateOrganizationSettings = (settings, files) => dispatch => {
  let result;
  axios
    .put(`api/setting/1`, settings)
    .then(res => {
      if (!!files.entries().next().value === true) {
        files.append('id', res.data.organization_id);
        axios.post(`api/uploadGeneralSettingsImage`, files, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => {
          <ToastContainer position={toast.POSITION.TOP_LEFT} className="toastify-container" toastClassName="toastify-toast" />;
          toast.success('Settings Saved');
          dispatch(getOrganizationSettings());
        });
      } else {
        <ToastContainer position={toast.POSITION.TOP_LEFT} className="toastify-container" toastClassName="toastify-toast" />;
        toast.success('Settings Saved');
        dispatch(getOrganizationSettings());
      }
    })
    .catch(err => {
      <ToastContainer position={toast.POSITION.TOP_LEFT} className="toastify-container" toastClassName="toastify-toast" />;
      toast.error('Error');
    });

  return result;
};

export const getOrganizationSettings = () => ({
  type: ACTION_TYPES.GET_ORGANIZATION_SETTINGS,
  payload: axios.get(`api/individualSetting`),
});
