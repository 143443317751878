import React from 'react';
import { Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import './PeopleGeneral.scss';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IOSSwitch from '../../RolesAndPermissionsSettings/Components/IOSswitchComponent';
import InfoIcon from '@material-ui/icons/Info';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PeopleGeneralComponent from '../Component/PeopleGeneralComponent';
import { useState, useEffect } from 'react';
import { getPeopleGeneralSettings, storePeopleGeneralSettings } from '../peopleSettings.reducer';
import { deleteLanguageSetting, deleteRegionSetting } from '../peopleSettings.reducer';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

export interface IPeopleSettingsProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}
export const PeopleGeneral = (props: IPeopleSettingsProps) => {
  const [open, setOpen] = React.useState(false);
  const [opened, setOpened] = React.useState(false);
  const [languageName, setLanguage] = useState('');
  const [regionName, setRegion] = useState('');

  useEffect(() => {
    props.getPeopleGeneralSettings();
  }, []);

  useEffect(() => {}, [props.settings]);

  const handleLanguageOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRegionClose = () => {
    setOpened(false);
  };

  const handleRegionOpen = () => {
    setOpened(true);
  };

  const submitlanguage = () => {
    props.storePeopleGeneralSettings({ language_name: languageName, tag: 'LANGUAGE' });
    setOpen(false);
  };

  const submitregion = () => {
    props.storePeopleGeneralSettings({ region_name: regionName, tag: 'REGION' });
    setOpened(false);
  };

  const deleteGeneralRecords = id => {
    props.deleteLanguageSetting(id);
  };

  const deleteGeneralRegionRecords = id => {
    props.deleteRegionSetting(id);
  };

  return (
    <div className="people-general-setting-external-div">
      <div className="people-general-heading-div">
        <div className="people-general-main-heading">
          <Typography variant="h5" className="people-general-heading-main-title">
            People General
          </Typography>
        </div>
        <div className="people-general-heading-message">Edit your information below</div>
      </div>
      <Paper className="people-general-paper" elevation={2} square>
        <div className="people-general-external-div">
          <div className="people-general-setting-upper-div">
            <table className="people-general-table">
              <tr>
                <td style={{ width: '50%', textAlign: 'left' }}>
                  <InfoIcon /> Persons Name Format
                </td>
                <td style={{ textAlign: 'left' }}>
                  <select>
                    <option value="first_name">Firstname Lastname</option>
                    <option value="LastName">Lastname Firstname</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: 'left' }}>
                  <InfoIcon /> People can join team on login page
                </td>
                <td style={{ textAlign: 'left' }}>
                  <IOSSwitch />
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: 'left' }}>
                  <InfoIcon />
                </td>
                <td style={{ textAlign: 'left' }}>
                  <IOSSwitch />
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: 'left' }}>
                  <InfoIcon /> Staff Portal FAQ-visible to people
                </td>
              </tr>
            </table>
          </div>
          <br />

          <div className="people-general-settings-lower-div">
            <div className="people-general-settings-lower-div-language-table">
              <Typography className="people-general-tables-heading" style={{ marginBottom: 'revert' }}>
                Language Section
              </Typography>
              <table className="people-general-settings-language-table">
                <tr style={{ backgroundColor: '#DCDCDC', width: '100%' }} className="language-table-heading-row">
                  <td style={{ width: '90%', textAlign: 'left', padding: '1%', color: 'grey' }} className="language-table-heading-td">
                    Name
                  </td>
                  <td className="language-table-icon-td"></td>
                </tr>
                {props.settings.languages && props.settings.languages.length > 0 ? (
                  props.settings.languages.map((setting, index) => (
                    <PeopleGeneralComponent
                      key={index}
                      msg={setting['name']}
                      id={setting.id}
                      tag={'LANGUAGE'}
                      remove={deleteGeneralRecords}
                    />
                  ))
                ) : (
                  <div>no records</div>
                )}

                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true}>
                  <DialogTitle className="language-popup-title">
                    {' '}
                    <a style={{ color: '#6610f2' }}>Add Language</a>
                  </DialogTitle>
                  <DialogContent className="language-popup-content-div">
                    Name* :
                    <TextField
                      className="popup-text"
                      autoFocus
                      margin="dense"
                      id="people_general_name"
                      type="text"
                      variant="outlined"
                      onChange={event => {
                        setLanguage(event.target.value);
                      }}
                      fullWidth
                    />
                  </DialogContent>
                  <DialogActions>
                    <button
                      type="button"
                      className="btn btn-success btn-sm"
                      style={{ marginRight: '75%', backgroundColor: '#4720b7', border: '1px solid #4720b7' }}
                      onClick={submitlanguage}
                    >
                      {' '}
                      Save{' '}
                    </button>
                    <button type="button" className="btn btn-outline-secondary btn-sm" style={{ marginRight: '2%' }} onClick={handleClose}>
                      Cancel
                    </button>
                  </DialogActions>
                </Dialog>
              </table>
              <button
                type="button"
                className="btn btn-success btn-sm"
                style={{ backgroundColor: '#4720b7', border: '1px solid #4720b7', marginTop: '7%' }}
                onClick={handleLanguageOpen}
              >
                Add Language
              </button>
            </div>

            <div className="people-general-settings-lower-div-region-table">
              <Typography className="people-region-table-heading" style={{ marginBottom: 'revert', marginLeft: '10%', color: 'grey' }}>
                Region
              </Typography>
              <table className="people-general-settings-region-table">
                <tr style={{ backgroundColor: '#DCDCDC' }}>
                  <td style={{ width: '90%', padding: '1%', textAlign: 'left', color: 'grey' }}>Name</td>
                  <td className="region-table-icon-td"></td>
                </tr>
                {props.settings.regions && props.settings.regions.length > 0 ? (
                  props.settings.regions.map((setting, index) => (
                    <PeopleGeneralComponent key={index} msg={setting['name']} id={setting.id} remove={deleteGeneralRegionRecords} />
                  ))
                ) : (
                  <div>no records</div>
                )}

                <Dialog open={opened} onClose={handleRegionClose} aria-labelledby="form-dialog-title" fullWidth={true}>
                  <DialogTitle className="region-popup-title">
                    {' '}
                    <a style={{ color: '#6610f2' }}>Add Region</a>
                  </DialogTitle>
                  <DialogContent className="region-popup-content-div">
                    Name* :
                    <TextField
                      className="popup-text"
                      autoFocus
                      margin="dense"
                      id="people_language_name"
                      type="text"
                      variant="outlined"
                      onChange={event => {
                        setRegion(event.target.value);
                      }}
                      fullWidth
                    />
                  </DialogContent>
                  <DialogActions>
                    <button
                      type="button"
                      className="btn btn-success btn-sm"
                      style={{ marginRight: '75%', backgroundColor: '#4720b7', border: '1px solid #4720b7' }}
                      onClick={submitregion}
                    >
                      {' '}
                      Save{' '}
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-secondary btn-sm"
                      style={{ marginRight: '2%' }}
                      onClick={handleRegionClose}
                    >
                      Cancel
                    </button>
                  </DialogActions>
                </Dialog>
              </table>
              <button
                type="button"
                style={{ marginLeft: '10%', backgroundColor: '#4720b7', border: '1px solid #4720b7', marginTop: '7%' }}
                className="btn btn-success btn-sm"
                onClick={handleRegionOpen}
              >
                Add Region
              </button>
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
};

const mapStateToProps = ({ PeopleSetting }) => ({
  settings: PeopleSetting.storedPeopleSettings,
  loading: PeopleSetting.loading,
});

const mapDispatchToProps = {
  storePeopleGeneralSettings,
  getPeopleGeneralSettings,
  deleteLanguageSetting,
  deleteRegionSetting,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PeopleGeneral);
