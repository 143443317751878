import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import {} from 'react-jhipster';
import { IRootState } from 'app/shared/reducers';
import { IServiceArea } from 'app/shared/model/service-area.model';
import '../incident.scss';

interface IIncidentPoliceComponentProps {
  police_report_number?: string | any;
  police_report_time?: string | any;
  police_involvement_comments?: string | any;
  police_arrival_time?: string | any;
}

export const IncidentPoliceComponent = (props: IIncidentPoliceComponentProps) => {
  return (
    <div className="incident-report-details-div">
      <div className="incident-police-div">
        <div className="incident-police-details-tab">Police Details</div>
      </div>

      <div className="police-attribute-text">Report Time: {props.police_report_time} </div>
      <div className="police-attribute-text">Arrival Time: {props.police_arrival_time}</div>
      <div className="police-attribute-text">Ref. No : {props.police_report_number}</div>

      <div style={{ marginTop: '30px', marginBottom: '15px', fontWeight: 'bold' }}>Police Comments</div>
      <div>{props.police_involvement_comments}</div>
    </div>
  );
};

const mapStateToProps = ({ organization }: IRootState) => ({
  organizationEntity: organization.entity,
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(IncidentPoliceComponent);
