import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, Redirect } from 'react-router-dom';
import { Button, Grid, FormControl, TextField, MenuItem, FormControlLabel, Checkbox, Divider } from '@material-ui/core';
import { Row, Col, Label } from 'reactstrap';
import { AvFeedback, form, AvField } from 'availity-reactstrap-validation';
import { Translate, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import './designation.scss';
import { IDesignationQualification } from 'app/shared/model/designation-qualification.model';
import { getEntities as getDesignationQualifications } from 'app/entities/designation-qualification/designation-qualification.reducer';
import { IPerson } from 'app/shared/model/person.model';
import { getEntities as getPeople } from 'app/entities/person/person.reducer';
import { getEntity, updateEntity, createEntity, reset, setPageNumber } from './designation.reducer';
import { getEntities as getDepartment } from 'app/entities/department/department.reducer';
import { ToastContainer, toast } from 'react-toastify';
import { IDesignation } from 'app/shared/model/designation.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { validationMessageOnChange, validationMessageBeforeSubmit } from '../../../utils/validationMessages';
import { Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import { Storage } from 'react-jhipster';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import Designation_Edit_Del_Btn from './designation-EAD-Buttons';
import Spinner from 'app/commonUI/spinner/spinner';

export interface IDesignationUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const DesignationUpdate = (props: IDesignationUpdateProps) => {
  const [idsdesignationQualitification, setIdsdesignationQualitification] = useState([]);
  const [isNew] = useState(!props.match.params || !props.match.params.id);
  const { designationEntity, designationQualifications, loading, updating, match } = props;
  const [values, setValues] = useState({});
  const [check, setCheck] = useState(false);
  const [first_login] = useState(Storage.local.get('first_login'));
  const [valuesError, setValuesError] = useState({
    firstName_error: false,
    id_helper: '',
    id_error: false,
    name_helper: '',
    name_error: false,
  });
  const [isEdit, setIsEdit] = React.useState(false);

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }
  }, []);

  useEffect(() => {
    setValues(designationEntity);
    if (designationEntity.is_vettable) {
      setCheck(true);
    }
  }, [designationEntity]);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const setNewValues = e => {
    setValues(oldValues => ({ ...oldValues, [e.target.name]: e.target.value }));
    if (e.target.name === 'name' && (!/^[a-zA-Z0-9\s().-]*$/.test(e.target.value) || e.target.value === '')) {
      setValuesError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: true }));
      setValuesError(oldFieldError => ({
        ...oldFieldError,
        [e.target.name + '_helper']: validationMessageOnChange.name,
      }));
    } else {
      setValuesError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: false, [e.target.name + '_helper']: '' }));
    }
  };

  const trimSpaces = e => {
    return setValues(oldValues => ({ ...oldValues, [e.target.name]: e.target.value.replace(/\s+/g, ' ').trim() }));
  };

  const setFormValues = name => {
    setNewValues(name);
    return ({ target: { value } }) => {
      setValues(oldValues => ({ ...oldValues, [name]: value }));
    };
  };

  const handleProcessVetting = e => {
    const { name, checked } = e.target;
    setValues(prevValues => ({
      ...prevValues,
      [name]: checked,
    }));
    setValues(prevValues => ({ ...prevValues, is_vettable: checked }));
    setCheck(checked);
  };

  const checkForValidations = () => {
    const result = !valuesError['name_error'] && !(values['name'] === undefined);
    return result;
  };

  const handleClose = () => {
    props.history.push('/positions');
  };

  const checkValidationsBeforeSubmit = () => {
    if (values['name'] === undefined) {
      setValuesError(oldFieldError => ({ ...oldFieldError, ['name_error']: true }));
      setValuesError(oldFieldError => ({ ...oldFieldError, ['name_helper']: validationMessageBeforeSubmit.name }));
    }
  };

  const saveEntity = event => {
    event.preventDefault();
    if (isNew && checkForValidations()) {
      props.createEntity(values);
      props.reset();
    } else if (!isNew && checkForValidations()) {
      props.updateEntity(values);
    } else {
      checkValidationsBeforeSubmit();
    }
  };

  const onKeyUp = event => {
    if (event.key === 'Enter') {
      saveEntity(event);
    }
  };

  const cancleEditHandler = () => {
    setIsEdit(!true);
  };

  return (
    <React.Fragment>
      <div className="designation-create-main-container">
        {loading && <Spinner />}
        <div style={{ width: '100%', height: ' auto !important' }}>
          <div style={{ display: 'flex' }}>
            <div className="designationContainerPaper">
              <div className="PositionInnerDiv">
                {!isNew && <>{isEdit === !true && <Designation_Edit_Del_Btn isEdit={isEdit} setIsEdit={setIsEdit} props={props} />}</>}
                <Grid item md={12} container justify="flex-start" spacing={1} style={{ marginTop: '3%' }}>
                  {loading ? (
                    <p>Loading...</p>
                  ) : (
                    <form
                      className="designation-form"
                      onKeyPress={e => {
                        onKeyUp(e);
                      }}
                      noValidate
                    >
                      {' '}
                      <div className="sideBySide" style={{ height: '10vh' }}>
                        <Grid item md={12} container>
                          <Grid item md={12} sm={12} xs={12}>
                            <FormControl className="designation-fields">
                              <TextField
                                id="designation-name"
                                data-cy="name"
                                variant="outlined"
                                className="designationid"
                                required
                                name="name"
                                label="Position Name  "
                                onChange={setFormValues}
                                value={values['name'] ? values['name'] : ''}
                                error={valuesError['name_error']}
                                helperText={valuesError['name_helper']}
                                defaultValue={designationEntity.name}
                                inputProps={{ maxLength: 255 }}
                                onClick={trimSpaces}
                                style={{ background: '#ffffff' }}
                                disabled={isNew ? false : isEdit === !true ? true : false}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </div>
                      <div className="sideBySide" style={{ height: '10vh' }}>
                        <Grid item xs={6} md={4} sm={6}>
                          <FormControl style={{ minWidth: 150 }} className="shift-missed-buttons" variant="outlined" size="small">
                            <FormControlLabel
                              id={check === true ? 'checked' : 'unchecked'}
                              className="position_checkbox_btn"
                              name="processVetting"
                              label="Process Vetting"
                              labelPlacement="end"
                              control={
                                <Checkbox
                                  name={'processVetting'}
                                  icon={<img src="content/icons/Send Again.svg" style={{ width: '19px' }} />}
                                  checkedIcon={<img src="content/icons/Send Again 02.svg" style={{ width: '19px' }} />}
                                  checked={check}
                                  onChange={handleProcessVetting}
                                  defaultChecked={values['processVettting'] ? values['processVettting'] : false}
                                  disabled={isNew ? false : isEdit === !true ? true : false}
                                />
                              }
                              style={{
                                color: check === true ? '#4820B8' : 'black',
                                background: check === true ? '#F3F4FA 0% 0% no-repeat padding-box' : '#ffffff',
                                border: check === true ? '1px solid #9D89D8' : '1px solid #ACACAC',
                                borderRadius: '25px',
                              }}
                            />
                          </FormControl>
                        </Grid>
                      </div>
                      {isEdit === true || isNew ? (
                        <>
                          <Divider />
                          <div className="positionDiscardSaveButtons">
                            <Grid item md={2} sm={2} xs={2} container justify="center">
                              <FormControl className="PositionButtons">
                                <Button
                                  component={Link}
                                  to="/positions"
                                  id="cancel-save"
                                  className="PositionBackButton"
                                  onClick={() => props.reset()}
                                >
                                  &nbsp;
                                  <span className="d-none d-md-inline">Cancel</span>
                                </Button>
                              </FormControl>
                            </Grid>{' '}
                            &nbsp;&nbsp;
                            <Grid item md={2} sm={2} xs={2} container justify="center">
                              <FormControl className="PositionButtons">
                                <Button
                                  className="PositionSaveButton"
                                  id="save-entity"
                                  data-cy="entityCreateSaveButton"
                                  type="submit"
                                  disabled={updating}
                                  onClick={saveEntity}
                                  style={{ fontSize: '1rem !important' }}
                                >
                                  &nbsp; Save
                                </Button>
                              </FormControl>
                            </Grid>
                          </div>
                        </>
                      ) : (
                        ''
                      )}
                    </form>
                  )}
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  designationQualifications: storeState.designationQualification.entities,
  people: storeState.person.entities,
  designationEntity: storeState.designation.entity,
  loading: storeState.designation.loading,
  updating: storeState.designation.updating,
  updateSuccess: storeState.designation.updateSuccess,
  department: storeState.department.entities,
  isOrganizationAdministrator: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.ORGANIZATION_ADMINISTRATOR]),
});

const mapDispatchToProps = {
  getDesignationQualifications,
  getPeople,
  getDepartment,
  getEntity,
  updateEntity,
  createEntity,
  reset,
  setPageNumber,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect<Record<string, unknown>>(mapStateToProps, mapDispatchToProps)(DesignationUpdate);
