import React from 'react';
import PermissionsRowComponent from './PermissionsRowComponent';

export default function PermissionsDocumentsComponent(props) {
  return (
    <React.Fragment>
      <PermissionsRowComponent
        msg="Can see, add, edit and remove documents?"
        ls={props.supervisorPerm && props.supervisorPerm.add_remove_documents}
        ds={props.userPerm && props.userPerm.add_remove_documents}
        ma={props.mngrPerm && props.mngrPerm.add_remove_documents}
        sa={props.orgAdminPerm && props.orgAdminPerm.add_remove_documents}
        name="add_remove_documents"
        handler={props.handleChange}
      />
      <PermissionsRowComponent
        msg="Can see Timesheet Summary report?"
        ls={props.supervisorPerm && props.supervisorPerm.see_timesheet_summary}
        ds={props.userPerm && props.userPerm.see_timesheet_summary}
        ma={props.mngrPerm && props.mngrPerm.see_timesheet_summary}
        sa={props.orgAdminPerm && props.orgAdminPerm.see_timesheet_summary}
        name="see_timesheet_summary"
        handler={props.handleChange}
      />
      <PermissionsRowComponent
        msg="Can see Time Clock report?"
        ls={props.supervisorPerm && props.supervisorPerm.see_timeclock_eport}
        ds={props.userPerm && props.userPerm.see_timeclock_eport}
        ma={props.mngrPerm && props.mngrPerm.see_timeclock_eport}
        sa={props.orgAdminPerm && props.orgAdminPerm.see_timeclock_eport}
        name="see_timeclock_eport"
        handler={props.handleChange}
      />
      <PermissionsRowComponent
        msg="Can see Checkpoint report?"
        ls={props.supervisorPerm && props.supervisorPerm.see_checktime_report}
        ds={props.userPerm && props.userPerm.see_checktime_report}
        ma={props.mngrPerm && props.mngrPerm.see_checktime_report}
        sa={props.orgAdminPerm && props.orgAdminPerm.see_checktime_report}
        name="see_checktime_report"
        handler={props.handleChange}
      />
      <PermissionsRowComponent
        msg="Can see Absences report?"
        ls={props.supervisorPerm && props.supervisorPerm.see_absenses_report}
        ds={props.userPerm && props.userPerm.see_absenses_report}
        ma={props.mngrPerm && props.mngrPerm.see_absenses_report}
        sa={props.orgAdminPerm && props.orgAdminPerm.see_absenses_report}
        name="see_absenses_report"
        handler={props.handleChange}
      />
      <PermissionsRowComponent
        msg="Can see Absence Summary report?"
        ls={props.supervisorPerm && props.supervisorPerm.see_absense_summary_report}
        ds={props.userPerm && props.userPerm.see_absense_summary_report}
        ma={props.mngrPerm && props.mngrPerm.see_absense_summary_report}
        sa={props.orgAdminPerm && props.orgAdminPerm.see_absense_summary_report}
        name="see_absense_summary_report"
        handler={props.handleChange}
      />
      <PermissionsRowComponent
        msg="Can see Position Coverage report?"
        ls={props.supervisorPerm && props.supervisorPerm.see_position_coverage_report}
        ds={props.userPerm && props.userPerm.see_position_coverage_report}
        ma={props.mngrPerm && props.mngrPerm.see_position_coverage_report}
        sa={props.orgAdminPerm && props.orgAdminPerm.see_position_coverage_report}
        name="see_position_coverage_report"
        handler={props.handleChange}
      />
      <PermissionsRowComponent
        msg="Can see Revenue Summary report?"
        ls={props.supervisorPerm && props.supervisorPerm.see_revenue_summary_report}
        ds={props.userPerm && props.userPerm.see_revenue_summary_report}
        ma={props.mngrPerm && props.mngrPerm.see_revenue_summary_report}
        sa={props.orgAdminPerm && props.orgAdminPerm.see_revenue_summary_report}
        name="see_revenue_summary_report"
        handler={props.handleChange}
      />
      <PermissionsRowComponent
        msg="Can see Action History report?"
        ls={props.supervisorPerm && props.supervisorPerm.see_action_history_report}
        ds={props.userPerm && props.userPerm.see_action_history_report}
        ma={props.mngrPerm && props.mngrPerm.see_action_history_report}
        sa={props.orgAdminPerm && props.orgAdminPerm.see_action_history_report}
        name="see_action_history_report"
        handler={props.handleChange}
      />
    </React.Fragment>
  );
}
