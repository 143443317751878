import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import OrganizationType from './organization-type';
import OrganizationTypeDetail from './organization-type-detail';
import OrganizationTypeUpdate from './organization-type-update';
import OrganizationTypeDeleteDialog from './organization-type-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={OrganizationTypeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={OrganizationTypeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={OrganizationTypeDetail} />
      <ErrorBoundaryRoute path={match.url} component={OrganizationType} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={OrganizationTypeDeleteDialog} />
  </>
);

export default Routes;
