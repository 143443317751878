import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { IApplicants, defaultValue } from 'app/shared/model/applicants.models';

export const ACTION_TYPES = {
  FETCH_APPLICANTS_LIST: 'vetting/FETCH_APPLICANTS_LIST',
  FETCH_APPLICANT: 'vetting/FETCH_APPLICANT',
  GET_APPLICANT_SUMMARY: 'vetting/GET_APPLICANT_SUMMARY',
  UPDATE_APPLICANT: 'vetting/UPDATE_APPLICANT',
  UPDATE_DOC_SEEN: 'vetting/UPDATE_DOC_SEEN',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IApplicants>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

export type VettingApplicantsState = Readonly<typeof initialState>;

// Reducer

export default (state: VettingApplicantsState = initialState, action): VettingApplicantsState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_APPLICANTS_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.FETCH_APPLICANT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_APPLICANTS_LIST):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case FAILURE(ACTION_TYPES.FETCH_APPLICANT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_APPLICANTS_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_APPLICANT):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case REQUEST(ACTION_TYPES.UPDATE_APPLICANT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case SUCCESS(ACTION_TYPES.UPDATE_APPLICANT):
      return {
        ...state,
        loading: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case FAILURE(ACTION_TYPES.UPDATE_APPLICANT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case REQUEST(ACTION_TYPES.UPDATE_DOC_SEEN):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
      };
    case SUCCESS(ACTION_TYPES.UPDATE_DOC_SEEN):
      return {
        ...state,
        loading: false,
        updateSuccess: true,
      };
    case FAILURE(ACTION_TYPES.UPDATE_DOC_SEEN):
      return {
        ...state,
        loading: false,
        updating: false,
      };
    case REQUEST(ACTION_TYPES.GET_APPLICANT_SUMMARY):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case SUCCESS(ACTION_TYPES.GET_APPLICANT_SUMMARY):
      return {
        ...state,
        loading: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case FAILURE(ACTION_TYPES.GET_APPLICANT_SUMMARY):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/getApplicantsList';
const vettingApiUrl = 'api/vetting';

// Actions

export const getEntities: ICrudGetAllAction<VettingApplicantsState> = data => ({
  type: ACTION_TYPES.FETCH_APPLICANTS_LIST,
  payload: axios.get<VettingApplicantsState>(`${apiUrl}`, { data }),
});

export const getEntity: ICrudGetAction<VettingApplicantsState> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_APPLICANT,
    payload: axios.get<VettingApplicantsState>(requestUrl),
  };
};
export const getApplicantDetail: ICrudGetAction<VettingApplicantsState> = id => {
  const cacheBuster = new Date().getTime(); // Use the current timestamp as the cache buster

  const requestUrl = `api/vetting/getapplicantinformation/${id}?cacheBuster=${cacheBuster}`;
  return {
    type: ACTION_TYPES.GET_APPLICANT_SUMMARY,
    payload: axios.get(requestUrl),
  };
};

export const updateEntity = (data: { id: number; newState: string; oldState: string }) => {
  return {
    type: ACTION_TYPES.UPDATE_APPLICANT,
    payload: axios.post(`${vettingApiUrl}/updateapplicant`, data),
  };
};

export const updateOriginalSeenDoc = (data, updateData) => {
  return {
    type: ACTION_TYPES.UPDATE_DOC_SEEN,
    payload: axios.put(`api/updateOriginalDocSeen/${data.id}`, updateData),
  };
};
