import axios from 'axios';
import { ICrudGetAction } from 'react-jhipster';
import { ITodaysShifts, defaultValue } from 'app/shared/model/TodaysShifts.model';
import { IShiftDetails, defaultValue as defaultShift } from 'app/shared/model/shift_details.model';
import { Storage } from 'react-jhipster';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import moment from 'moment';

// actions types
export const ACTION_TYPES = {
  FETCH_SHIFTS_TIME: 'TodaysShift/FETCH_NAME',
  FETCH_PERSON: 'TodaysShift/FETCH_NAME',
  FETCH_ALL_SHIFTS: 'TodaysShift/FETCH_ALL_SHIFTS',
  UPADATE_SHIFTS: 'TodaysShift/UPADATE_SHIFTS',
  GET_SHIFT_DETAILS: 'Dashboard/GET_SHIFT_DETAILS',
  FETCH_SHIFTS_LIST: 'TodaysShift/FETCH_SHIFTS_LIST',
  FETCH_UPDATED_SHIFTS_LIST: 'TodaysShift/FETCH_UPDATED_SHIFTS_LIST',
  RESET: 'TodaysShift/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  AllShifts: [] as Array<ITodaysShifts>,
  entity: defaultValue,
  ShiftTime: [],
  shiftDetails: defaultShift,
  updating: false,
  updateSuccess: false,
};

export type TodaysShiftState = Readonly<typeof initialState>;

// reducer

export default (state: TodaysShiftState = initialState, action): TodaysShiftState => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_SHIFTS_TIME:
      return {
        ...state,
        loading: false,
        ShiftTime: action.payload.data,
      };
    case ACTION_TYPES.FETCH_PERSON:
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.GET_SHIFT_DETAILS):
      return {
        ...state,
        loading: false,
        shiftDetails: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_SHIFTS_LIST):
      return {
        ...state,
        loading: false,
        AllShifts: [...state.AllShifts, ...action.payload.data.shifts || []],
      };
    case SUCCESS(ACTION_TYPES.FETCH_UPDATED_SHIFTS_LIST):
      return {
        ...state,
        loading: false,
        AllShifts: action.payload.data.shifts,
      };
    case ACTION_TYPES.UPADATE_SHIFTS:
      return {
        ...state,
        loading: false,
        updating: true,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/liveShifts';
const apiUpdateUrl = 'api/shift';
const apiShiftDetails = 'api/getShiftsDetails';
const apiShiftDetailsForUser = 'api/getShiftsDetailsForUser';

// actions

export const getAllShifts = () => async dispatch => {
  return axios.get<ITodaysShifts>(`${apiUrl}?sort=start_time&filter[live_shifts]=${moment().format('YYYY-MM-DD')}`);
};

export const getWeeklyShifts = (data, pageNo) => ({
  type: ACTION_TYPES.FETCH_SHIFTS_LIST,
  payload: axios.get<ITodaysShifts>(
    `${apiUrl}?include=person&filter[from_shifts]=${data.from_date}&filter[to_shifts]=${data.to_date}&filter[live_shifts]=${data.current_day}
      &page=${pageNo}`
  ),
});

export const getUpdatedShifts = (data, pageNo) => ({
  type: ACTION_TYPES.FETCH_UPDATED_SHIFTS_LIST,
  payload: axios.get<ITodaysShifts>(
    `${apiUrl}?include=person&filter[from_shifts]=${data.from_date}&filter[to_shifts]=${data.to_date}&filter[live_shifts]=${data.current_day}
      &page=${pageNo}`
  ),
});

export const UpdateShifts = (entity, data) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPADATE_SHIFTS,
    payload: axios.put(`${apiUpdateUrl}/${entity.id}`, cleanEntity(entity)),
  });

  dispatch(getUpdatedShifts(data, ''));
  return result;
};

export const getEntity: ICrudGetAction<ITodaysShifts> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_PERSON,
    payload: axios.get<ITodaysShifts>(requestUrl),
  };
};

export const getShiftDetails = data => ({
  type: ACTION_TYPES.GET_SHIFT_DETAILS,
  payload: axios.get(
    `${apiShiftDetails}?&filter[from_shifts]=${data.from_date}
     &filter[to_shifts]=${data.to_date}&filter[live_shifts]=${data.current_day}`
  ),
});

export const getShiftDetailsForUser = data => ({
  type: ACTION_TYPES.GET_SHIFT_DETAILS,
  payload: axios.get(
    `${apiShiftDetailsForUser}?&filter[from_shifts]=${data.from_date}
     &filter[to_shifts]=${data.to_date}&filter[live_shifts]=${data.current_day}`
  ),
});

export const getShiftTime: ICrudGetAction<ITodaysShifts> = id => {
  return {
    type: ACTION_TYPES.FETCH_SHIFTS_TIME,
    payload: axios.get<ITodaysShifts>(`${apiUrl}/2?userId=${id}`),
  };
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
