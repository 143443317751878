import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { defaultValue as IApplicantReferenceVerification } from 'app/shared/model/applicant-reference-verification.model';

export const ACTION_TYPES = {
  GET_APPLICANT_DETAILS: 'vetting/GET_APPLICANT_DETAILS',
  VERIFICATION_COMPLETED: 'vetting/VERIFICATION_COMPLETED',
  GET_PERSONAL_REFERENCE_DETAILS: 'vetting/GET_PERSONAL_REFERENCE_DETAILS',
  STORE_PERSONAL_REFERENCE_VERIFICATION: 'vetting/STORE_PERSONAL_REFERENCE_VERIFICATION',
  GET_REF_FORM_DETAILS: 'vetting/GET_REF_FORM_DETAILS',
};

const initialState = {
  applicantData: IApplicantReferenceVerification,
  personalReferenceDetails: [],
  refFormDetails: { employment_history: null, personal_history: null },
  verificationCompleted: false,
  loading: true,
  errorMessage: null,
  updating: false,
  updateSuccess: false,
};

export type VettingReferenceVerificationState = Readonly<typeof initialState>;
export default (state: VettingReferenceVerificationState = initialState, action): VettingReferenceVerificationState => {
  switch (action.type) {
    case ACTION_TYPES.GET_APPLICANT_DETAILS:
      return {
        ...state,
        applicantData: action.payload,
        loading: false,
      };
    case ACTION_TYPES.VERIFICATION_COMPLETED:
      return {
        ...state,
        verificationCompleted: true,
        loading: false,
      };
    case ACTION_TYPES.GET_PERSONAL_REFERENCE_DETAILS:
      return {
        ...state,
        personalReferenceDetails: action.payload,
        loading: false,
      };
    case ACTION_TYPES.GET_REF_FORM_DETAILS:
      return {
        ...state,
        refFormDetails: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const getApplicantDetailsForEmploymentHistoryVerification = id => dispatch => {
  let result;
  axios
    .get(`api/getApplicantDetails/${id}`)
    .then(res => {
      dispatch({
        type: ACTION_TYPES.GET_APPLICANT_DETAILS,
        payload: res.data,
      });
    })
    .catch(err => {
      toast.error('Error : ' + err);
    });
  return result;
};

export const storeEmploymentHistoryVerification = data => dispatch => {
  let result;
  axios
    .post(`api/storeEmploymentReference`, data)
    .then(res => {
      toast.success('Employment verification submitted');
      dispatch({
        type: ACTION_TYPES.VERIFICATION_COMPLETED,
      });
    })
    .catch(err => {
      toast.error('Error : ' + err);
    });

  return result;
};

export const getApplicantDetailsForPersonalReferenceVerification = id => dispatch => {
  let result;
  axios
    .get(`api/getApplicantDetailsForPersonalReferenceVerification/${id}`)
    .then(res => {
      dispatch({
        type: ACTION_TYPES.GET_PERSONAL_REFERENCE_DETAILS,
        payload: res.data,
      });
    })
    .catch(err => {
      toast.error('Error : ' + err);
    });
  return result;
};

export const storePersonalReferenceVerification = data => dispatch => {
  let result;
  axios
    .post(`api/storePersonalReferenceVerification`, data)
    .then(res => {
      toast.success('Reference verification submitted');
      dispatch({
        type: ACTION_TYPES.STORE_PERSONAL_REFERENCE_VERIFICATION,
      });
    })
    .catch(err => {
      toast.error('Error : ' + err);
    });

  return result;
};

export const getReferenceFormDetail = id => dispatch => {
  let result;
  axios
    .get(`api/getReferenceFormDetail/${id}`)
    .then(res => {
      dispatch({
        type: ACTION_TYPES.GET_REF_FORM_DETAILS,
        payload: res.data,
      });
    })
    .catch(err => {
      toast.error('Error : ' + err);
    });
  return result;
};
