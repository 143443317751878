import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Grid, FormControl, TextField, MenuItem } from '@material-ui/core';
import { Row, Col, Label } from 'reactstrap';
import { AvFeedback, form, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import './employee-contract-type.scss';
import { getEntity, updateEntity, createEntity, reset } from './employee-contract-type.reducer';
import { IEmployeeContractType } from 'app/shared/model/employee-contract-type.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface IEmployeeContractTypeUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const EmployeeContractTypeUpdate = (props: IEmployeeContractTypeUpdateProps) => {
  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const { employeeContractTypeEntity, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/employee-contract-type');
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }
  }, []);

  useEffect(() => {}, [employeeContractTypeEntity]);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = event => {
    event.preventDefault();

    const entity = {
      id: event.target.id.value,
      name: event.target.name.value,
      hoursPerWeek: event.target.hoursPerWeek.value,
    };

    if (isNew) {
      props.createEntity(entity);
    } else {
      props.updateEntity(entity);
    }
  };

  return (
    <div className="employeeContractType-full-container">
      <Grid item md={12} container justify="center">
        <Grid item md={2}></Grid>
        <Grid item md={8}>
          <h2 id="wfmApp.employeeContractType.home.createOrEditLabel" data-cy="EmployeeContractTypeCreateUpdateHeading">
            <Translate contentKey="wfmApp.employeeContractType.home.createOrEditLabel">Create or edit a EmployeeContractType</Translate>
          </h2>
        </Grid>
      </Grid>
      <Grid item md={12} container justify="center" spacing={1}>
        <Grid item md={2}></Grid>
        <Grid item md={8} spacing={2}>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <form onSubmit={saveEntity} className="employeeContractType-form">
              {!isNew ? (
                <Grid item md={12} container>
                  <Grid item md={12} xs={12}>
                    <FormControl className="employeeContractType-fields">
                      <TextField
                        id="employee-contract-type-id"
                        className="employeecontracttypeid"
                        variant="outlined"
                        name="id"
                        required
                        inputProps={{ readOnly: true }}
                        label={<Translate contentKey="global.field.id">ID</Translate>}
                        value={employeeContractTypeEntity.id}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              ) : null}
              <Grid item md={12} container>
                <Grid item md={12} xs={12}>
                  <FormControl className="employeeContractType-fields">
                    <TextField
                      id="employee-contract-type-name"
                      data-cy="name"
                      className="employeecontracttypename"
                      variant="outlined"
                      name="name"
                      label={<Translate contentKey="wfmApp.employeeContractType.name">Name</Translate>}
                      defaultValue={employeeContractTypeEntity.name}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item md={12} container>
                <Grid item md={12} xs={12}>
                  <FormControl className="employeeContractType-fields">
                    <TextField
                      id="employee-contract-type-hoursPerWeek"
                      data-cy="hoursPerWeek"
                      type="string"
                      className="employeecontracttypehoursPerWeek"
                      variant="outlined"
                      name="hoursPerWeek"
                      label={<Translate contentKey="wfmApp.employeeContractType.hoursPerWeek">Hours Per Week</Translate>}
                      defaultValue={employeeContractTypeEntity.hoursPerWeek}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item md={12} container>
                <Grid item md={12} xs={12}>
                  <FormControl style={{ display: 'inline-block' }} className="employeeContractType-fields">
                    <Button
                      component={Link}
                      id="cancel-save"
                      to="/employee-contract-type"
                      replace
                      style={{ color: 'black', backgroundColor: 'rgb(13, 202, 240)' }}
                    >
                      <FontAwesomeIcon icon="arrow-left" />
                      &nbsp;
                      <span className="d-none d-md-inline">
                        <Translate contentKey="entity.action.back">Back</Translate>
                      </span>
                    </Button>
                    &nbsp;
                    <Button
                      style={{ color: 'white', backgroundColor: 'blue' }}
                      id="save-entity"
                      data-cy="entityCreateSaveButton"
                      type="submit"
                      disabled={updating}
                    >
                      <FontAwesomeIcon icon="save" />
                      &nbsp;
                      <Translate contentKey="entity.action.save">Save</Translate>
                    </Button>
                  </FormControl>
                </Grid>
              </Grid>
            </form>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  employeeContractTypeEntity: storeState.employeeContractType.entity,
  loading: storeState.employeeContractType.loading,
  updating: storeState.employeeContractType.updating,
  updateSuccess: storeState.employeeContractType.updateSuccess,
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeContractTypeUpdate);
