import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Grid, FormControl, TextField, Divider } from '@material-ui/core';
import { Translate } from 'react-jhipster';
import { IRootState } from 'app/shared/reducers';
import './department.scss';
import { getEntity, updateEntity, createEntity, reset, setPageNumber } from './department.reducer';
import { validationMessageOnChange, validationMessageBeforeSubmit } from '../../../utils/validationMessages';
import { getAllClients as getClient } from 'app/entities/client/client.reducer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import { Storage } from 'react-jhipster';
import Department_Edit_Del_Btn from './Department_Edit_Del_Btn';
import Spinner from 'app/commonUI/spinner/spinner';

export interface IDepartmentUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const DepartmentUpdate = (props: IDepartmentUpdateProps) => {
  const [isNew] = useState(!props.match.params || !props.match.params.id);
  const { departmentEntity, organizations, people, loading, updating, match } = props;
  const [values, setValues] = useState(departmentEntity);
  const [fieldError, setFieldError] = useState({
    name_error: false,
    name_helper: '',
  });
  const [first_login] = useState(Storage.local.get('first_login'));
  const [isEdit, setIsEdit] = React.useState(false);

  const handleClose = () => {
    props.history.push('/departments');
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }
  }, []);

  useEffect(() => {
    setValues(departmentEntity);
  }, [departmentEntity]);

  useEffect(() => {
    props.getClient(-1);
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const setNewValues = e => {
    setValues(oldValues => ({ ...oldValues, [e.target.name]: e.target.value }));
    if (e.target.name === 'name' && (!/^(?! )[a-zA-Z\s().-]*$/.test(e.target.value) || e.target.value === '')) {
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: true }));
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_helper']: validationMessageOnChange.name }));
    } else {
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: false, [e.target.name + '_helper']: '' }));
    }
  };

  const checkForValidations = () => {
    const result = !fieldError['name_error'] && !(values['name'] === undefined);
    return result;
  };

  const checkValidationsBeforeSubmit = () => {
    if (values['name'] === undefined) {
      setFieldError(oldFieldError => ({ ...oldFieldError, ['name_error']: true }));
      setFieldError(oldFieldError => ({ ...oldFieldError, ['name_helper']: validationMessageBeforeSubmit.name }));
    }
  };

  const saveEntity = event => {
    event.preventDefault();
    if (isNew && checkForValidations()) {
      props.createEntity(values);
      props.reset();
      props.history.push('/departments');
    } else if (!isNew && checkForValidations()) {
      props.updateEntity(values);
    } else {
      checkValidationsBeforeSubmit();
    }
  };

  const onKeyUp = event => {
    if (event.key === 'Enter') {
      saveEntity(event);
    }
  };

  return (
    <React.Fragment>
      <div className="department-create-main-container">
        <div className="department-full-container">
          <div style={{ display: 'flex' }}>
            <div className="departmentContainerPaper">
              <div className="departmentInnerDiv">
                {!isNew && <>{!isEdit && <Department_Edit_Del_Btn isEdit={isEdit} setIsEdit={setIsEdit} props={props} />}</>}
                <Grid item md={12} container justify="flex-start" spacing={1} style={{ marginTop: '3%' }}>
                  {loading ? (
                    <Spinner />
                  ) : (
                    <form
                      className="department-form"
                      onKeyPress={e => {
                        onKeyUp(e);
                      }}
                      noValidate
                    >
                      {' '}
                      <div className="sideBySide">
                        <Grid item md={12} container>
                          <Grid item md={12} sm={12} xs={12}>
                            <FormControl className="department-fields">
                              <TextField
                                required
                                id="department-name"
                                data-cy="name"
                                variant="outlined"
                                name="name"
                                label={<Translate contentKey="wfmApp.department.name">Name</Translate>}
                                placeholder="Write department name"
                                defaultValue={departmentEntity.name}
                                value={values['name'] ? values['name'] : ''}
                                onChange={setNewValues}
                                error={fieldError['name_error']}
                                helperText={fieldError['name_helper']}
                                inputProps={{ maxLength: 255 }}
                                style={{ background: '#ffffff' }}
                                disabled={isNew ? false : isEdit === !true ? true : false}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </div>
                      {isEdit === true || isNew ? (
                        <>
                          <Divider style={{ margin: '5% 0', width: '125%' }} />
                          <div className="departmentupdateDiscardSaveButtons">
                            <Grid item md={2} sm={2} xs={2}>
                              <FormControl className="departmentButtons " fullWidth>
                                <Button
                                  component={Link}
                                  to="/departments"
                                  onClick={() => props.reset()}
                                  id="cancel-save"
                                  className="departmentUpdateBackButton"
                                >
                                  &nbsp;
                                  <span className="d-none d-md-inline">Cancel</span>
                                </Button>
                              </FormControl>
                            </Grid>{' '}
                            &nbsp;&nbsp;
                            <Grid item md={2} sm={2} xs={2}>
                              <FormControl className="departmentButtons" fullWidth>
                                <Button
                                  className="departmentUpdateSaveButton"
                                  id="save-entity"
                                  data-cy="entityCreateSaveButton"
                                  type="submit"
                                  disabled={updating}
                                  onClick={saveEntity}
                                >
                                  &nbsp; Save
                                </Button>
                              </FormControl>
                            </Grid>
                          </div>
                        </>
                      ) : (
                        ''
                      )}
                    </form>
                  )}
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  organizations: storeState.organization.entities,
  people: storeState.person.entities,
  departmentEntity: storeState.department.entity,
  loading: storeState.department.loading,
  updating: storeState.department.updating,
  updateSuccess: storeState.department.updateSuccess,
  client: storeState.client.entities,
  isOrganizationAdministrator: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.ORGANIZATION_ADMINISTRATOR]),
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  getClient,
  reset,
  setPageNumber,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentUpdate);
