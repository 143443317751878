import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Grid, FormControl, TextField, MenuItem, Paper, Divider, Checkbox } from '@material-ui/core';
import { IRootState } from 'app/shared/reducers';
import './automated-control-room.scss';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { Autocomplete } from '@material-ui/lab';
import Chip from '@material-ui/core/Chip';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { getEntities as serviceAreaEntities } from '../service-area/service-area.reducer';
import IOSSwitch from 'app/modules/Setting/RolesAndPermissionsSettings/Components/IOSswitchComponent';
import WelfareCheck from './welfare-check';
import General from './general';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import ShiftConfirmation from './confirmation';
import ShiftConfirmationAdvancedSettings from './components/shift-confirmation-advanced-settings';
import CheckCallAdvancedSettings from './components/checkcall-advanced-settings';
import { ACRGeneralSettings } from 'app/shared/model/automated-control-room-general-settings.model';
import {
  getACRGeneralSettings,
  updateACRGeneralSettings,
  getACRShiftSettings,
  updateACRShiftSettings,
} from './automated-control-room.reducer';
import { ACRShiftSettings } from 'app/shared/model/automated-control-room-shift-settings.model';

const useStyles = makeStyles(theme => ({
  activeShiftTab: {
    width: '110px !important',
    textAlign: 'center',
    height: '0 !important',
    position: 'relative',
    borderRight: '30px solid transparent !important',
    borderBottom: '45px solid #7a55de !important',
    borderLeft: '30px solid transparent !important',
    boxSizing: 'content-box',
    backgroundColor: 'transparent !important',
    color: '#FFFFFF !important',
  },
  nonActiveShiftTab: {
    width: '110px !important',
    textAlign: 'center',
    height: '0 !important',
    position: 'relative',
    borderRight: '30px solid transparent !important',
    borderBottom: '45px solid #FCF2FC !important',
    borderLeft: '30px solid transparent !important',
    boxSizing: 'content-box',
    backgroundColor: 'transparent !important',
    color: '#6C6A6C !important',
  },
  activeTab: {
    width: '85px !important',
    textAlign: 'center',
    height: '0 !important',
    position: 'relative',
    borderRight: '30px solid transparent !important',
    borderBottom: '45px solid #7a55de !important',
    borderLeft: '30px solid transparent !important',
    boxSizing: 'content-box',
    backgroundColor: 'transparent !important',
    color: '#FFFFFF !important',
  },
  nonActiveTab: {
    width: '85px !important',
    textAlign: 'center',
    height: '0 !important',
    position: 'relative',
    borderRight: '30px solid transparent !important',
    borderBottom: '45px solid #FCF2FC !important',
    borderLeft: '30px solid transparent !important',
    boxSizing: 'content-box',
    backgroundColor: 'transparent !important',
    color: '#6C6A6C !important',
  },
}));

export interface IAutomatedComtrolRoomProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const AutomatedControlRoom = (props: IAutomatedComtrolRoomProps) => {
  const classes = useStyles();
  const [tab, setTab] = useState({ option: 'general' });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [values, setValues] = useState<ACRGeneralSettings>({});
  const [shiftValues, setShiftValues] = useState<ACRShiftSettings>({});

  useEffect(() => {
    props.getACRGeneralSettings();
    props.getACRShiftSettings();
  }, []);

  useEffect(() => {
    setValues(props.ACRGeneralSettingsValues);
  }, [props.ACRGeneralSettingsValues]);

  useEffect(() => {
    setShiftValues(props.ACRShiftSettingsValues);
  }, [props.ACRShiftSettingsValues]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleAutomatedControlRoomTabs = tag => {
    if (tag === 'general') {
      setTab(oldValues => ({ ...oldValues, ['option']: 'general' }));
    } else if (tag === 'confirmation') {
      setTab(oldValues => ({ ...oldValues, ['option']: 'confirmation' }));
    } else if (tag === 'welfare') {
      setTab(oldValues => ({ ...oldValues, ['option']: 'welfare' }));
    }
  };

  const saveACRSettings = () => {
    props.updateACRGeneralSettings(values);
    props.updateACRShiftSettings(shiftValues);
  };

  return (
    <div className="control-room-main-container">
      <Paper className="control-room-paper" elevation={2}>
        <div className="control-room-buttons">
          <ButtonGroup className="control-room-button-group">
            <Button
              disableRipple={true}
              disableFocusRipple={true}
              id="automated-button-group"
              className={tab['option'] === 'general' ? classes.activeTab : classes.nonActiveTab}
              onClick={() => {
                handleAutomatedControlRoomTabs('general');
              }}
            >
              General
            </Button>

            <Button
              id="automated-button-group"
              className={tab['option'] === 'confirmation' ? classes.activeShiftTab : classes.nonActiveShiftTab}
              onClick={() => {
                handleAutomatedControlRoomTabs('confirmation');
              }}
            >
              Shift Confirmation
            </Button>

            <Button
              id="automated-button-group"
              className={tab['option'] === 'welfare' ? classes.activeTab : classes.nonActiveTab}
              onClick={() => {
                handleAutomatedControlRoomTabs('welfare');
              }}
            >
              Welfare Check
            </Button>
          </ButtonGroup>
        </div>

        {tab['option'] === 'confirmation' || tab['option'] === 'welfare' ? (
          <div style={{ float: 'right', color: '#619BFF', fontSize: '12px' }}>
            <a onClick={handleClick}>Advanced Settings</a>
            <Popover
              className="automated-control-room-popover"
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              {tab['option'] === 'confirmation' ? (
                <ShiftConfirmationAdvancedSettings
                  values={shiftValues}
                  setValues={setShiftValues}
                  ACRGeneralSettingsValues={props.ACRGeneralSettingsValues}
                />
              ) : null}

              {tab['option'] === 'welfare' ? (
                <CheckCallAdvancedSettings
                  values={shiftValues}
                  setValues={setShiftValues}
                  ACRGeneralSettingsValues={props.ACRGeneralSettingsValues}
                />
              ) : null}
            </Popover>
          </div>
        ) : null}

        {tab['option'] === 'general' ? (
          <General
            values={values}
            setValues={setValues}
            ACRGeneralSettingsValues={props.ACRGeneralSettingsValues}
            match={undefined}
            history={undefined}
            location={undefined}
          ></General>
        ) : null}

        {tab['option'] === 'confirmation' ? (
          <ShiftConfirmation
            values={shiftValues}
            setValues={setShiftValues}
            ACRShiftSettingsValues={props.ACRShiftSettingsValues}
            match={undefined}
            history={undefined}
            location={undefined}
          ></ShiftConfirmation>
        ) : null}
        {tab['option'] === 'welfare' ? (
          <WelfareCheck
            values={shiftValues}
            setValues={setShiftValues}
            ACRShiftSettingsValues={props.ACRShiftSettingsValues}
            match={undefined}
            history={undefined}
            location={undefined}
          ></WelfareCheck>
        ) : null}

        <Grid item xs={12} className="control-room-settings-button-div">
          <FormControl style={{ display: 'inline-block', float: 'right' }}>
            <Button className="control-room-discard-button">&nbsp; Discard</Button>
            &nbsp;
            <Button
              className="control-room-save-button"
              type="submit"
              onClick={() => {
                saveACRSettings();
              }}
            >
              &nbsp; Save Changes
            </Button>
          </FormControl>
        </Grid>
      </Paper>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  ACRGeneralSettingsValues: storeState.AutomatedControlRoom.storedACRGeneralSettings,
  ACRShiftSettingsValues: storeState.AutomatedControlRoom.storedACRShiftSettings,
});

const mapDispatchToProps = {
  getACRGeneralSettings,
  updateACRGeneralSettings,
  getACRShiftSettings,
  updateACRShiftSettings,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AutomatedControlRoom);
