export enum VettingStates {
    NewApplicant = 'NewApplicant',
    Submitted = 'Submitted',
    InProgress = 'InProgress',
    PreVetted = 'PreVetted',
    InvalidApplication = 'InvalidApplication',
    Completed = 'Completed',
    Failed = 'Failed',
    Select = 'Select',
}

export const ProcessedStates: VettingStates[] = [
    VettingStates.Completed,
    VettingStates.Failed,
    VettingStates.InvalidApplication,
];

export const SignOffStates: VettingStates[] = [
    VettingStates.PreVetted,
    VettingStates.Completed,
]