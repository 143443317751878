import React from 'react';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useEffect } from 'react';

const PeopleHealthComponent = props => {
  const deleteHealthRecord = () => {
    props.remove(props.id);
  };

  return (
    <>
      <tr>
        <td style={{ textAlign: 'left' }}>
          <a href="#" style={{ marginLeft: '20px' }}>
            {' '}
            {props.msg}{' '}
          </a>
        </td>
        <td style={{ textAlign: 'right' }}>
          <a>
            {' '}
            <FontAwesomeIcon icon={faTrashAlt} onClick={deleteHealthRecord} />{' '}
          </a>
        </td>
      </tr>
    </>
  );
};

export default PeopleHealthComponent;
