import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';

import Login from 'app/modules/login/login';
import Register from 'app/modules/account/register/register';
import Activate from 'app/modules/account/activate/activate';
import PasswordResetInit from 'app/modules/account/password-reset/init/password-reset-init';
import PasswordResetFinish from 'app/modules/account/password-reset/finish/password-reset-finish';
import PasswordPage from '../app/modules/account/password/password';
import Logout from 'app/modules/login/logout';
import Home from 'app/modules/home/home';
import Entities from 'app/entities';
import PrivateRoute from 'app/shared/auth/private-route';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import PageNotFound from 'app/shared/error/page-not-found';
import { AUTHORITIES } from 'app/config/constants';
import SchedulingCalender from './modules/calender/calender';
import TimeClock from './modules/timeclock/timeclock';
import PersonalSettings from './modules/Setting/PersonalSettingIndex';
import NotificationSettings from './modules/Setting/notification-setting';
import PersonRolePermissionSettings from './modules/Setting/RolesAndPermissionsSettings/RolesAndPermissionsSettings';
import Settings from './modules/Setting/index';
import Form from './modules/employeeApplicationForm/form/index';
import VettingDocuments from './modules/vetting/documents/documents';
import Applicants from './modules/vetting/applicants/applicants';
import PersonalReferenceVerificationForm from './modules/applicantsVerification/personalReferencesVerification/PersonalReferencesVerificationForm';
import RegisterVerification from './modules/account/register/register-verification';
import RegisterSignupForm from './modules/account/register/register-signup-form';
import SetPasswordPage from './modules/account/password/setPassword';
import ResetForgotPasswordPage from './modules/account/password/forgotPassword/resetForgotPassword';
import ForgotPasswordPage from './modules/account/password/forgotPassword/forgotPassword';
import AutomatedControlRoom from './entities/check-call/automated-control-room';
import CallLogs from './entities/call-log-reports/call-log';
import LeaveManagement from './entities/leave-management/leave-management';
import DeletedShifts from './entities/deleted-shifts/deleted-shifts';
import OrganizationList from 'app/modules/login/organizations-list';
import DataDeleteRequest from 'app/modules/login/data_delete_request';
import LiveShiftLocation from 'app/entities/shift/live-shift-location';
import PastEmployee from 'app/entities/person/pastEmployees';

import otherReferenceForm from 'app/modules/employeeApplicationForm/form/components/formSections/refrence-form/otherReferenceForm';
import responseSubmitted from 'app/modules/employeeApplicationForm/form/components/formSections/refrence-form/responseSubmitted';
const Account = Loadable({
  loader: () => import(/* webpackChunkName: "account" */ 'app/modules/account'),
  loading: () => <div>loading ...</div>,
});

const Admin = Loadable({
  loader: () => import(/* webpackChunkName: "administration" */ 'app/modules/administration'),
  loading: () => <div>loading ...</div>,
});

const Routes = () => {
  return (
    <div className="view-routes">
      <Switch>
        <Route exact path="/UserDataDeletionRequest" component={DataDeleteRequest} />
        <PrivateRoute path="/vetting" component={VettingDocuments} />
        <PrivateRoute path="/vettingDashboard" component={Applicants} />
        <Route path="/personalReferenceVerification/:id" component={PersonalReferenceVerificationForm} />
        {/* <Route path="/vettingReferencesVerification/:id" component={personRefrenceForm} /> */}
        <Route exact path="/vettingReferencesVerification/:id" component={otherReferenceForm} />
        {/* <Route exact path="/employee-reference-form/:id" component={personRefrenceForm} /> */}
        <ErrorBoundaryRoute path="/Login" component={Login} />
        <ErrorBoundaryRoute path="/Organizations" component={OrganizationList} />
        <ErrorBoundaryRoute path="/logout" component={Logout} />
        <PrivateRoute path="/Real-Time" component={TimeClock}
        hasAnyAuthorities={[AUTHORITIES.ORGANIZATION_ADMINISTRATOR,AUTHORITIES.SUPERVISOR,AUTHORITIES.MANAGER]} />
        <ErrorBoundaryRoute path="/PersonalSetting" component={PersonalSettings} />
        <ErrorBoundaryRoute path="/Setting/NotificationSettings" component={NotificationSettings} />
        <ErrorBoundaryRoute path="/Setting/RolesAndPermissionsSettings" component={PersonRolePermissionSettings} />
        <PrivateRoute path="/settings" exact component={Settings} hasAnyAuthorities={[AUTHORITIES.ORGANIZATION_ADMINISTRATOR]} />
        <PrivateRoute
          path="/calendar"
          component={SchedulingCalender}
          hasAnyAuthorities={[
            AUTHORITIES.SYSTEM_ADMIN,
            AUTHORITIES.ORGANIZATION_ADMINISTRATOR,
            AUTHORITIES.SUPERVISOR,
            AUTHORITIES.MANAGER,
          ]}
        />

        <PrivateRoute path="/employeeForm" component={Form} />
        <PrivateRoute
          exact
          path={'/employeeForm/:id'}
          component={Form}
          hasAnyAuthorities={[AUTHORITIES.SYSTEM_ADMIN, AUTHORITIES.ORGANIZATION_ADMINISTRATOR]}
        />
        <ErrorBoundaryRoute path="/account/register" component={Register} />
        <ErrorBoundaryRoute path="/verification" component={RegisterVerification} />
        <ErrorBoundaryRoute path="/signup" component={RegisterSignupForm} />
        <ErrorBoundaryRoute path="/account/activate/:key?" component={Activate} />
        <ErrorBoundaryRoute path="/account/reset/request" component={PasswordResetInit} />
        <ErrorBoundaryRoute path="/account/reset/finish/:key?" component={PasswordResetFinish} />
        <PrivateRoute path="/admin" component={Admin} hasAnyAuthorities={[AUTHORITIES.SYSTEM_ADMIN]} />
        <PrivateRoute path="/account/password" component={PasswordPage} />
        <ErrorBoundaryRoute path="/set-password" component={SetPasswordPage} />
        <ErrorBoundaryRoute path="/forgot-password" component={ForgotPasswordPage} />
        <ErrorBoundaryRoute path="/resetForgotPassword" component={ResetForgotPasswordPage} />
        <ErrorBoundaryRoute path="/automated-control-room" component={AutomatedControlRoom} />
        <PrivateRoute path= "/call-log" component={CallLogs} 
        hasAnyAuthorities={[AUTHORITIES.ORGANIZATION_ADMINISTRATOR,AUTHORITIES.SUPERVISOR,AUTHORITIES.MANAGER]} />
        <ErrorBoundaryRoute path="/leaveManagement" component={LeaveManagement} />
        <ErrorBoundaryRoute path="/deleted-shifts" component={DeletedShifts} />
        <ErrorBoundaryRoute path="/location/:shiftid" component={LiveShiftLocation} />
        <ErrorBoundaryRoute path="/past-employees" component={PastEmployee} />
        <Route exact path="/responseSubmitted" component={responseSubmitted} />
        <PrivateRoute
          path="/account"
          component={Account}
          hasAnyAuthorities={[
            AUTHORITIES.SYSTEM_ADMIN,
            AUTHORITIES.ORGANIZATION_ADMINISTRATOR,
            AUTHORITIES.SUPERVISOR,
            AUTHORITIES.USER,
            AUTHORITIES.MANAGER,
          ]}
        />
        <PrivateRoute path="/" exact component={Home} />
        <PrivateRoute
          path="/"
          component={Entities}
          hasAnyAuthorities={[
            AUTHORITIES.SYSTEM_ADMIN,
            AUTHORITIES.ORGANIZATION_ADMINISTRATOR,
            AUTHORITIES.SUPERVISOR,
            AUTHORITIES.USER,
            AUTHORITIES.MANAGER,
          ]}
        />

        <ErrorBoundaryRoute component={PageNotFound} />
      </Switch>
    </div>
  );
};

export default Routes;