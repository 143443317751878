import React, { useEffect, useState } from 'react';
import { Button, FormGroup, FormControl, Grid, makeStyles, Paper } from '@material-ui/core';
/* eslint-disable complexity */
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker, TimePicker } from '@material-ui/pickers';
import { CurrencyChecker, calendarDateconverter } from '../../../utils/TimeFormatAndTimezone';
import CustomToolbarComponent from '../../entities/service-area/CustomToolbarComponent';
import check_in_frequencies from '../../shared/data/check_in_frequencies.json';
import pay_rate_check_values from '../../shared/data/pay_rate_check_values.json';
import { getDateFormat } from '../../shared/util/calender-utils';
import { IRootState } from 'app/shared/reducers';
import Divider from '@material-ui/core/Divider';
import Modal from '@material-ui/core/Modal';
import MomentUtils from '@date-io/moment';
import { connect } from 'react-redux';
import { getNoOfCheckPoints } from './calendar.reducer';
import { Autocomplete } from '@material-ui/lab';
import WarningIcon from '@material-ui/icons/Warning';
import HistoryIcon from '@material-ui/icons/History';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import DeleteModal from './deleteModal';
import UpdateModal from './updateModal';
import { occurence, pattern } from '../../../utils/global';
import { PasteModalComponent } from './pasteOccurrenceOrSeries';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import './calendar.scss';
import moment from 'moment';
import './calendar.scss';
import {
  CreateSchedule,
  modalDateChange,
  checkSelectedLocationInBannedEmployees,
  handleShiftClose,
  modalTimeEndChange,
  modalTimeStartChange,
  handleShiftHistory,
  handleWeekdayPattern,
  handleDailyPattern,
  handleWeeklyPattern,
  handleRepeat,
  modalRepeatEndDateChange,
  modalPatrolFrequencyChange,
  companyAndSubcontractors,
  modalBreakChange,
  handleRepeatsEvery,
  handleWeek,
  modalChargeRateChange,
  modalNotesChange,
  modalPayRateChange,
  checkEmployeeBannedOrNot,
  checkPersonHasDuplicateShiftOrNot,
  checkPersonListInBanned,
  personWithDoubleShifs,
  checkTimeGapWithPerviousShift,
  checkEmployeeBanned,
  timeOverlapWithPreviousShift,
  timeOverlapWShiftPersonCheck,
} from './calendarModalFunctions';
import { ShiftHistoryModalComponent } from './shifthistoryModal';
import { PatternModal } from './patternModal';
import { PatrolDurationModal } from './patrolDurationModal';
import { calculateShiftDuration } from '../../../utils/TimeFormatAndTimezone';
import { da } from 'date-fns/locale';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import { getPersonsByServiceAreaId, getActivePersonsByServiceAreaId } from '../../entities/service-area/service-area.reducer';
import organization from 'app/entities/organization/organization';

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: '50%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '10%',
    left: '24%',
    overflow: 'auto',
  },
  shiftModalTitle: {
    textAlign: 'center',
    marginTop: '30px',
    marginBottom: '20px',
    color: '#4620B7',
  },
  modalForms: {
    marginBottom: theme.spacing(2),
    minWidth: 120,
    width: '100%',
  },
  modalButtonSave: {
    backgroundColor: '#4620b7',
    color: 'white',
    width: '95%',
  },
  modalButtonCancel: {
    backgroundColor: 'white',
    color: 'black',
    width: '95%',
  },
  modalNote: {
    width: '100%',
  },
  modalButton: {
    width: '35%',
  },
  modalInput: {
    width: '75%',
  },
  assignToDiv: {
    width: '100%',
  },
  weekLists: {
    width: '100%',
    display: 'flex',
  },
  modalFormForAutoComplete: {
    marginTop: '0 !important',
  },
  listbox: {
    border: '1px solid #dfdbdb !important',
  },
}));

export interface IModalComponent extends StateProps, DispatchProps {
  parentProp: any;
  modalForm: any;
  fieldError: {
    modalScheduleLocation_error: boolean;
    modalScheduleDesignation_error: boolean;
    timeGapWithPerviousShift_warning: boolean;
    personField_error: boolean;
    personShift_error: boolean;
    banned_error: boolean;
    selectCompanyField_error: boolean;
  };
  setModalForm: (data: any) => void;
  deleteSchedule: (value) => void;
  deleteSeries: (value) => void;
  setFieldError: (data: any) => void;
  calendarDisplayWindow: any;
  calendarPropsData: any;
}

export const ModalComponent = (props: IModalComponent) => {
  const [show, setShow] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPatrolDurationModal, setShowPatrolDurationModal] = useState(false);
  const [showPasteModal, setShowPasteModal] = useState(false);
  const [ShiftHistoryModalOpen, setShiftHistoryModalOpen] = useState(false);
  const [openPatternModal, setOpenPatternModal] = useState(false);
  const [isRepeatShift, setIsRepeatShift] = useState(false);
  const handleShowForPasting = () => setShowPasteModal(true);
  const [personIdentifier, setPersonIdentifier] = useState(false);

  const {
    setting,
    modalForm,
    parentProp,
    setModalForm,
    fieldError,
    setFieldError,
    deleteSchedule,
    deleteSeries,
    calendarDisplayWindow,
    calendarPropsData,
  } = props;

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const classes = useStyles(props);
  let personList = JSON.parse(JSON.stringify(calendarPropsData?.entities));
  personList = personList?.sort((a, b) => a.first_name.localeCompare(b.first_name));

  const handleShow = () => setShow(true);

  const handleShowDeleteModal = isRepeat => {
    setShowDeleteModal(true);
    if (isRepeat) {
      setIsRepeatShift(true);
    } else {
      setIsRepeatShift(false);
    }
  };

  const handleOccurenceList = (_, event) => {
    if (event) {
      setModalForm({ ...modalForm, repeatEvery: { value: event.value }, repeatType: event.value });
    } else {
      setModalForm({ ...modalForm, repeatType: null });
    }
  };

  const handleRepeatPattern = (_, e) => {
    if (e) {
      if (e.id === 0) {
        setModalForm({
          ...modalForm,
          isRepeat: false,
          repeatEnd: null,
          repeatType: null,
          repeatPattern: null,
          repeatPatternObject: e,
        });
      } else if (e.id === 1) {
        handleWeekdayPattern(e, props);
      } else if (e.id === 2) {
        handleDailyPattern(e, props);
      } else if (e.id === 3) {
        handleWeeklyPattern(e, props);
      } else if (e.id === 4) {
        handleRepeat(e, props);
      }
    }
  };

  let checkPersonList;
  useEffect(() => {
    if (modalForm.service_area !== null || modalForm.service_area !== undefined) {
      checkPersonList = checkSelectedLocationInBannedEmployees(modalForm.service_area, parentProp);
      setModalForm({ ...modalForm, checkPersonListInBannedPeople: checkPersonList });
    }
    if (modalForm.service_area !== null || modalForm.service_area !== undefined) {
      if (modalForm.service_area && modalForm.service_area.id) {
        props.getActivePersonsByServiceAreaId(modalForm?.service_area?.id);
      }
    }
    if (modalForm.modalScheduleUpdate === true) {
      props.getActivePersonsByServiceAreaId(modalForm?.service_area?.id);
    }
  }, [modalForm.service_area]);

  useEffect(() => {
    setModalForm(prevState => ({ ...modalForm, person: [] }));
  }, []);

  useEffect(() => {
    if (modalForm.modalPersonIdentifier === true && props.serviceAreaPersons.length > 0) {
      let filtered;

      if (Array.isArray(modalForm.person)) {
        const personId = typeof modalForm.person[0]?.id !== 'string' ? modalForm.person[0]?.id.toString() : modalForm.person[0]?.id;
        filtered = props.serviceAreaPersons && props.serviceAreaPersons.find(x => x.id.toString() === personId);
      } else {
        const personId = typeof modalForm.person?.id !== 'string' ? modalForm.person?.id.toString() : modalForm.person?.id;
        filtered = props.serviceAreaPersons && props.serviceAreaPersons.find(x => x.id.toString() === personId);
      }
      // const personId = typeof modalForm.person[0]?.id !== 'string' ? modalForm.person[0]?.id.toString() : modalForm.person[0]?.id;
      // const filtered = props.serviceAreaPersons && props.serviceAreaPersons.find(x => x.id.toString() === personId);
      const array = [];
      array.push(filtered);
      if (filtered) {
        setModalForm(prevState => ({ ...modalForm, ['person']: array }));
      } else {
        setModalForm(prevState => ({ ...modalForm, ['person']: null }));
      }
    }
  }, [modalForm.modalPersonIdentifier, props.serviceAreaPersons]);

  useEffect(() => {
    if (personIdentifier === true) {
      setModalForm(prevState => ({ ...modalForm, modalPersonIdentifier: false }));
    }
  }, [personIdentifier]);

  const disableBanned = option => {
    return (
      <div className="shift_modal_disable_banned_person">
        <span style={{ color: 'grey' }}>{option.first_name + ' ' + option.last_name}</span>
        <span style={{ float: 'right' }}>
          <NotInterestedIcon fontSize="small" style={{ width: '15px', height: '15px' }} />
          <span style={{ color: '#fe6267', paddingLeft: '5px' }}>Unavailable</span>
        </span>
      </div>
    );
  };

  const modalLocationChange = (event, location) => {
    if (location) {
      if (location === '' && location === undefined) {
        setFieldError(oldValues => ({ ...oldValues, ['modalScheduleLocation_error']: true }));
        return null;
      } else {
        setFieldError(oldValues => ({ ...oldValues, ['modalScheduleLocation_error']: false }));
        const chargerate = location['charge_rate'];
        getNoOfCheckPoints(location.id);
        if (props?.bannedEmployees?.length > 0) {
          checkEmployeeBannedOrNot(location, parentProp, props);
        }
        setModalForm({ ...modalForm, charge_rate: chargerate, service_area: location });
      }
    } else {
      setFieldError(oldValues => ({ ...oldValues, ['banned_error']: false }));
      setFieldError(oldValues => ({ ...oldValues, ['personField_error']: false }));
      setModalForm({ ...modalForm, service_area: null });
    }
  };

  const modalDesignationChange = (event, position) => {
    if (position) {
      if (position === '' && position === undefined) {
        setFieldError(oldValues => ({ ...oldValues, ['modalScheduleDesignation_error']: true }));
        return null;
      } else {
        setFieldError(oldValues => ({ ...oldValues, ['modalScheduleDesignation_error']: false }));
        setModalForm(prevState => ({
          ...modalForm,
          ['designation']: position,
        }));
      }
    } else {
      setModalForm(prevState => ({
        ...modalForm,
        ['designation']: null,
      }));
    }
  };

  const handleCheckInTimeModalValue = (event, value) => {
    if (event) {
      setModalForm({ ...modalForm, check_call_frequency: value });
    } else {
      setModalForm({ ...modalForm, check_call_frequency: { label: 'Not Required', id: 1 } });
    }
  };

  const handleCompany = (_, company) => {
    if (company) {
      if (company === '' && company === undefined) {
        setFieldError(oldValues => ({ ...oldValues, ['selectCompanyField_error']: true }));
      } else {
        if (company.status === 'SUBCONTRACTOR') {
          setModalForm({ ...modalForm, subcontractor: company });
        } else {
          setModalForm({ ...modalForm, organization: company });
        }
        setFieldError(oldValues => ({ ...oldValues, ['selectCompanyField_error']: false }));
        setModalForm({ ...modalForm, selectedCompany: company });
      }
    }
  };

  const handlePayRateCheck = (event, value) => {
    if (event) {
      const newValue = value.value;
      setModalForm({ ...modalForm, pay_rate_check: newValue });
      if (value.label === 'Default Pay Rate') {
        setModalForm({ ...modalForm, pay_rate: '' });
      }
    } else {
      setModalForm({ ...modalForm, pay_rate_check: { label: 'Default Pay Rate', value: 'false' } });
    }
  };

  const handleModalPersonChange = (_, e) => {
    setPersonIdentifier(true);
    setFieldError(oldValues => ({ ...oldValues, ['banned_error']: false }));
    if (modalForm.start_date !== null && modalForm.start_time !== null) {
      setFieldError(oldValues => ({ ...oldValues, ['personShift_error']: false }));
      checkTimeGapWithPerviousShift(e, modalForm.start_time, modalForm.start_date, parentProp, props);
      personWithDoubleShifs(e, modalForm.start_date, parentProp, props);
    }
    if (modalForm.e !== null && modalForm.service_area !== null && props?.bannedEmployees?.length > 0) {
      checkEmployeeBanned(e, modalForm.service_area, modalForm, setFieldError, props);
    }
    if (e && e.length !== 0) {
      let payrate = 10;
      if (e[0] && e[0]['employment_detail']) {
        payrate = e[0]['employment_detail']['pay_rate'];
      } else {
        payrate = e['employment_detail']['pay_rate'];
      }
      setModalForm(prevState => ({
        ...modalForm,
        ['pay_rate']: payrate,
        person: e,
      }));
    } else {
      setModalForm(prevState => ({
        ...modalForm,
        ['person']: null,
      }));
    }
  };

  const handlePatrolDurationModal = () => {
    setShowPatrolDurationModal(true);
  };

  const PersonOrganizationSubcontractor = companyAndSubcontractors(modalForm);

  return (
    <>
      <Modal
        open={modalForm?.setModalOpen}
        onClose={() => {
          handleShiftClose(parentProp, props), setPersonIdentifier(false);
        }}
        className="create-modal"
      >
        <div>
          <div className={classes.paper} id="new-shift-modal">
            <h2 id="shift-modal-title" className={classes.shiftModalTitle}>
              {modalForm.modalScheduleUpdate ? 'Update Shift' : 'Create New Shift'}
            </h2>
            <Divider className="Calendar-new-shift-modal-divider" />
            <FormControl className={classes.modalForms}>
              <FormGroup row={true}>
                <Grid container spacing={3}>
                  <Grid item className={classes.modalInput} lg={6} xs={12}>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                      <KeyboardDatePicker
                        autoOk
                        variant="inline"
                        size="small"
                        inputVariant="outlined"
                        label="Shift date"
                        format={setting.date_format ? getDateFormat(setting.date_format) : 'MM/DD/YYYY'}
                        value={modalForm.start_date}
                        className={classes.modalNote}
                        ToolbarComponent={prop => (
                          <>
                            <CustomToolbarComponent {...prop} settingFormat={setting.date_format} />
                          </>
                        )}
                        placeholder={setting.date_format ? getDateFormat(setting.date_format) : 'MM/DD/YYYY'}
                        InputAdornmentProps={{ position: 'start' }}
                        onChange={date => {
                          modalDateChange(date, props.parentProp, props);
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={6} lg={3}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardTimePicker
                        size="small"
                        ampm={props.setting.time_format === 1 ? false : true}
                        inputVariant="outlined"
                        className={classes.modalNote}
                        label="Start time"
                        value={modalForm.start_time}
                        onChange={option => {
                          modalTimeStartChange(option, parentProp, props);
                        }}
                        helperText={fieldError['shiftConflict_helper']}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={6} lg={3}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardTimePicker
                        size="small"
                        ampm={props.setting.time_format === 1 ? false : true}
                        inputVariant="outlined"
                        label="End time"
                        className={classes.modalNote}
                        value={modalForm.end_time}
                        onChange={option => {
                          modalTimeEndChange(option, parentProp, props);
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  {fieldError['timeGapWithPerviousShift_warning'] === true ? (
                    <>
                      <Grid lg={6} xs={12}></Grid>
                      <Grid lg={6} xs={12}>
                        <div className="shift-time-duration-warning">
                          <WarningIcon fontSize="small" />
                          Time Gap with previous shift is less then 11 hours
                        </div>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
              </FormGroup>
            </FormControl>

            <FormControl className={classes.modalForms}>
              <FormGroup row={true}>
                <Grid container spacing={3}>
                  <Grid item xs={3} lg={3}>
                    <TextField
                      size="small"
                      variant="outlined"
                      id="shift-breaks"
                      label="Breaks"
                      type="number"
                      onChange={event => modalBreakChange(event, props)}
                      defaultValue={modalForm.modalScheduleUpdate ? modalForm.no_of_breaks : 0}
                      value={modalForm.no_of_breaks}
                      className={classes.modalNote}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TextField
                      label="Duration"
                      size="small"
                      variant="outlined"
                      id="totalShiftTime"
                      value={modalForm.duration}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{ readOnly: true }}
                    ></TextField>
                  </Grid>

                  {modalForm.modalScheduleUpdate === false && modalForm.modalSchedulePaste === false && (
                    <Grid item xs={6}>
                      <Autocomplete
                        options={pattern?.map(rePattern => ({
                          id: rePattern.value,
                          text: rePattern.label,
                        }))}
                        classes={{ listbox: classes.listbox }}
                        ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                        getOptionLabel={option => option.text || ''}
                        getOptionSelected={(option, value) => option.id === value.id}
                        className={classes.modalNote}
                        onChange={handleRepeatPattern}
                        value={modalForm.repeatPatternObject}
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            className={classes.modalFormForAutoComplete}
                            label="Repeat Shift"
                            margin="normal"
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </Grid>
                  )}
                </Grid>
              </FormGroup>
            </FormControl>

            {modalForm.isRepeat === true && modalForm.repeatPatternObject && modalForm.repeatPatternObject.id === 4 && (
              <>
                <FormControl className={classes.modalForms}>
                  <FormGroup row={true}>
                    <Grid container spacing={3}>
                      <Grid item className={classes.modalInput} xs={6} lg={3}>
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                          <>
                            <KeyboardDatePicker
                              autoOk
                              variant="inline"
                              size="small"
                              inputVariant="outlined"
                              label="End Date"
                              format={setting.date_format ? getDateFormat(setting.date_format) : 'MM/DD/YYYY'}
                              value={modalForm.repeatEnd}
                              className={classes.modalNote}
                              ToolbarComponent={prop => (
                                <>
                                  <CustomToolbarComponent {...prop} settingFormat={setting.date_format} />
                                </>
                              )}
                              placeholder={setting.date_format ? getDateFormat(setting.date_format) : 'MM/DD/YYYY'}
                              InputAdornmentProps={{ position: 'start' }}
                              onChange={date => modalRepeatEndDateChange(date, props)}
                            />{' '}
                            {fieldError['dateError'] && (
                              <span style={{ fontSize: '13px', color: 'red' }}>End date should be less than start date</span>
                            )}
                          </>
                        </MuiPickersUtilsProvider>
                      </Grid>

                      <Grid item className={classes.modalInput} xs={6} lg={3}></Grid>

                      <Grid item className={classes.modalInput} xs={6} lg={4}>
                        <Autocomplete
                          options={occurence?.map(rePattern => ({
                            value: rePattern.value,
                          }))}
                          classes={{ listbox: classes.listbox }}
                          ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                          getOptionLabel={option => option.value || ''}
                          className={classes.modalNote}
                          onChange={handleOccurenceList}
                          getOptionSelected={(option, value) => option.id === value.id}
                          value={modalForm.repeatEvery}
                          renderInput={params => (
                            <TextField
                              {...params}
                              variant="outlined"
                              size="small"
                              className={classes.modalFormForAutoComplete}
                              label="Repeats Every"
                              margin="normal"
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                        />
                      </Grid>

                      {modalForm.repeatEvery && modalForm.repeatEvery.value === 'Day' && (
                        <Grid item className={classes.modalInput} xs={6} lg={2}>
                          <TextField
                            size="small"
                            id="shift-repeat-every"
                            type="number"
                            className={classes.modalNote}
                            variant="outlined"
                            name="shift-repeat-every"
                            defaultValue={modalForm.repeatPattern}
                            onChange={e => handleRepeatsEvery(e, props)}
                            InputProps={{
                              endAdornment: <InputAdornment position="end"></InputAdornment>,
                              inputProps: { min: 1, max: 100 },
                            }}
                          ></TextField>
                        </Grid>
                      )}
                    </Grid>
                  </FormGroup>
                </FormControl>
              </>
            )}

            {modalForm.repeatEvery &&
              modalForm.repeatEvery.value === 'Week' &&
              modalForm.repeatPatternObject &&
              modalForm.repeatPatternObject.id === 4 &&
              modalForm.isRepeat && (
                <FormControl className={classes.modalForms}>
                  <FormGroup row={true}>
                    <Grid item xs={12} lg={6}></Grid>
                    <Grid item className={classes.weekLists} xs={6} lg={6}>
                      {modalForm?.week?.map(option => (
                        <Avatar
                          className="custom-shift-week-circle"
                          style={{ background: option.active ? '#3bbda9' : '#e4e4e4' }}
                          onClick={() => handleWeek(option, props)}
                          key={option.value}
                        >
                          {option.value}
                        </Avatar>
                      ))}
                    </Grid>
                  </FormGroup>
                </FormControl>
              )}

            <Divider className="Calendar-new-shift-modal-divider" />

            <Grid container spacing={3}>
              <Grid item className={classes.modalInput} xs={6} lg={6}>
                <FormControl variant="outlined" size="small" className={classes.modalForms}>
                  <Autocomplete
                    options={calendarPropsData?.positions}
                    classes={{ listbox: classes.listbox }}
                    ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                    getOptionLabel={option => option.name || modalForm.designation || ''}
                    getOptionSelected={(option, value) => option.id === value.id}
                    className={classes.modalNote}
                    onChange={modalDesignationChange}
                    value={modalForm.designation}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        className={classes.modalFormForAutoComplete}
                        label="Position"
                        margin="normal"
                        fullWidth
                        error={fieldError['modalScheduleDesignation_error']}
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              {/* <Grid item className={classes.modalInput} xs={6} lg={6}>
                <FormControl variant="outlined" size="small" className={`shift_modal_person_field ${classes.modalForms}`}>
                  <Autocomplete
                    options={personList}
                    classes={{ listbox: classes.listbox }}
                    ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                    getOptionLabel={option => option?.first_name + ' ' + option?.last_name || ''}
                    getOptionSelected={(option, value) => parseInt(option?.id, 10) === parseInt(value?.id, 10)}
                    className={classes.modalNote}
                    onChange={handleModalPersonChange}
                    value={modalForm.person}
                    getOptionDisabled={options => {
                      return (
                        !!modalForm?.timeOverlapPersons?.find(element => element?.personId === parseInt(options?.id, 10)) ||
                        (modalForm?.service_area !== null && checkPersonListInBanned(options, props)) ||
                        timeOverlapWShiftPersonCheck(
                          modalForm.start_time,
                          modalForm.end_time,
                          modalForm.start_date,
                          parentProp,
                          options,
                          props
                        )
                      );
                    }}
                    renderOption={(option, state) => {
                      return (
                        <React.Fragment>
                          <span style={{ width: '100%' }}>
                            {modalForm.modalScheduleUpdate === false && modalForm?.service_area !== null
                              ? checkPersonListInBanned(option, props) === true
                                ? disableBanned(option)
                                : option?.first_name + ' ' + option?.last_name
                              : modalForm.modalScheduleUpdate === true
                              ? checkPersonListInBanned(option, props) && checkPersonListInBanned(option, props) === true
                                ? disableBanned(option)
                                : option?.first_name + ' ' + option?.last_name
                              : option?.first_name + ' ' + option?.last_name}
                          </span>
                        </React.Fragment>
                      );
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        className={classes.modalFormForAutoComplete}
                        label="Assign to"
                        margin="normal"
                        style={
                          fieldError['personField_error'] === true && fieldError['banned_error'] === true
                            ? { backgroundColor: '#fff2f2' }
                            : { backgroundColor: 'white' }
                        }
                        fullWidth
                        error={fieldError['personField_error'] && fieldError['personShift_error']}
                        helperText={fieldError['personField_helper'] && fieldError['personShift_helper']}
                      />
                    )}
                  />
                </FormControl>
                {fieldError['banned_error'] === true && fieldError['personField_error'] === true ? (
                  <>
                    <div className="shift_modal_banned_person">
                      {' '}
                      <NotInterestedIcon fontSize="small" style={{ width: '15px', height: '15px' }} />
                      <span className="shift_modal_banned_person_error">
                        Person is banned on <span>{modalForm.service_area && modalForm.service_area.name}</span>
                      </span>
                    </div>
                  </>
                ) : null}
                {fieldError['personShift_error'] === true && fieldError['personField_error'] === true ? (
                  <>
                    <div className="duplicate_shift_for_person">
                      {' '}
                      <NotInterestedIcon fontSize="small" style={{ width: '15px', height: '15px' }} />
                      <span className="duplicate_shift_for_person_error">Person has a shift on Selected Date and Time Duration</span>
                    </div>
                  </>
                ) : null}
              </Grid> */}

              <Grid item className={classes.modalInput} xs={3} lg={3}>
                <FormControl variant="outlined" size="small" className={classes.modalForms}>
                  <Autocomplete
                    options={calendarPropsData?.locations}
                    classes={{ listbox: classes.listbox }}
                    ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                    getOptionLabel={option => option.name || modalForm.service_area || ''}
                    getOptionSelected={(option, value) => option.id === value.id}
                    className={classes.modalNote}
                    onChange={modalLocationChange}
                    value={modalForm.service_area}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        className={classes.modalFormForAutoComplete}
                        label="Service Area"
                        margin="normal"
                        fullWidth
                        error={fieldError['modalScheduleLocation_error']}
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              {modalForm.service_area && modalForm.service_area.no_of_checkpoints > 0 ? (
                <>
                  <Grid item xs={3} lg={3}>
                    <TextField
                      variant="outlined"
                      size="small"
                      id="shift-patrol_frequency"
                      label="Set Patrol Frequency"
                      type="number"
                      value={modalForm.patrol_frequency}
                      className={classes.modalNote}
                      onChange={event => modalPatrolFrequencyChange(event, props)}
                      InputProps={{
                        inputProps: { min: 0 },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    {modalForm.patrol_frequency ? (
                      <a style={{ fontSize: '12px', color: '#4720b7', textDecoration: 'underline' }} onClick={handlePatrolDurationModal}>
                        Set Patrol Durations
                      </a>
                    ) : null}
                  </Grid>
                </>
              ) : (
                <Grid item xs={3} lg={3}></Grid>
              )}
            </Grid>
            <div className="checkpoint-detail-helper-message-div">
              {modalForm.service_area && modalForm.service_area.no_of_checkpoints > 0 ? (
                <div>
                  {modalForm.service_area.no_of_checkpoints} CheckPoints Created for this Service Area. Please Set Patrol frequency if
                  required.
                </div>
              ) : null}
            </div>
            {modalForm.modalScheduleUpdate === false ? (
              <Grid item className={classes.assignToDiv} xs={12} lg={12}>
                <FormControl variant="outlined" size="small" className={`shift_modal_person_field ${classes.modalForms}`}>
                  <Autocomplete
                    options={props.serviceAreaPersons}
                    limitTags={9}
                    disableCloseOnSelect
                    multiple
                    classes={{ listbox: classes.listbox }}
                    ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                    getOptionLabel={option => option?.first_name + ' ' + option?.last_name || ''}
                    getOptionSelected={(option, value) => parseInt(option?.id, 10) === parseInt(value?.id, 10)}
                    className={classes.modalNote}
                    onChange={handleModalPersonChange}
                    value={modalForm.person ? modalForm.person : []}
                    defaultValue={[]}
                    getOptionDisabled={options => {
                      return (
                        !!modalForm?.timeOverlapPersons?.find(element => element?.personId === parseInt(options?.id, 10)) ||
                        (modalForm?.service_area !== null && checkPersonListInBanned(options, props)) ||
                        timeOverlapWShiftPersonCheck(
                          modalForm.start_time,
                          modalForm.end_time,
                          modalForm.start_date,
                          parentProp,
                          options,
                          props
                        )
                      );
                    }}
                    renderOption={(option, { selected }) => {
                      return (
                        <React.Fragment>
                          <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected ? selected : null} />
                          <span style={{ width: '100%' }}>
                            {modalForm.modalScheduleUpdate === false && modalForm?.service_area !== null
                              ? timeOverlapWShiftPersonCheck(
                                  modalForm.start_time,
                                  modalForm.end_time,
                                  modalForm.start_date,
                                  parentProp,
                                  option,
                                  props
                                ) === true
                                ? disableBanned(option)
                                : option?.first_name + ' ' + option?.last_name
                              : modalForm.modalScheduleUpdate === true
                              ? timeOverlapWShiftPersonCheck(
                                  modalForm.start_time,
                                  modalForm.end_time,
                                  modalForm.start_date,
                                  parentProp,
                                  option,
                                  props
                                ) === true
                                ? disableBanned(option)
                                : option?.first_name + ' ' + option?.last_name
                              : option?.first_name + ' ' + option?.last_name}
                          </span>
                        </React.Fragment>
                      );
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        className={classes.modalFormForAutoComplete}
                        label="Assign to"
                        margin="normal"
                        style={
                          fieldError['personField_error'] === true && fieldError['banned_error'] === true
                            ? { backgroundColor: '#fff2f2' }
                            : { backgroundColor: 'white' }
                        }
                        fullWidth
                        error={fieldError['personField_error'] && fieldError['personShift_error']}
                        helperText={fieldError['personField_helper'] && fieldError['personShift_helper']}
                      />
                    )}
                  />
                </FormControl>
                {fieldError['banned_error'] === true && fieldError['personField_error'] === true ? (
                  <>
                    <div className="shift_modal_banned_person">
                      {' '}
                      <NotInterestedIcon fontSize="small" style={{ width: '15px', height: '15px' }} />
                      <span className="shift_modal_banned_person_error">
                        Person is banned on <span>{modalForm.service_area && modalForm.service_area.name}</span>
                      </span>
                    </div>
                  </>
                ) : null}
                {fieldError['personShift_error'] === true && fieldError['personField_error'] === true ? (
                  <>
                    <div className="duplicate_shift_for_person">
                      {' '}
                      <NotInterestedIcon fontSize="small" style={{ width: '15px', height: '15px' }} />
                      <span className="duplicate_shift_for_person_error">Person has a shift on Selected Date and Time Duration</span>
                    </div>
                  </>
                ) : null}
              </Grid>
            ) : null}

            {modalForm.modalScheduleUpdate === true ? (
              <Grid item className={classes.assignToDiv} xs={12} lg={12}>
                <FormControl variant="outlined" size="small" className={`shift_modal_person_field ${classes.modalForms}`}>
                  <Autocomplete
                    options={props.serviceAreaPersons}
                    classes={{ listbox: classes.listbox }}
                    ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                    getOptionLabel={option => option?.first_name + ' ' + option?.last_name || ''}
                    getOptionSelected={(option, value) => parseInt(option?.id, 10) === parseInt(value?.id, 10)}
                    className={classes.modalNote}
                    onChange={handleModalPersonChange}
                    value={modalForm.person}
                    getOptionDisabled={options => {
                      return (
                        !!modalForm?.timeOverlapPersons?.find(element => element?.personId === parseInt(options?.id, 10)) ||
                        (modalForm?.service_area !== null && checkPersonListInBanned(options, props)) ||
                        timeOverlapWShiftPersonCheck(
                          modalForm.start_time,
                          modalForm.end_time,
                          modalForm.start_date,
                          parentProp,
                          options,
                          props
                        )
                      );
                    }}
                    renderOption={(option, state) => {
                      return (
                        <React.Fragment>
                          <span style={{ width: '100%' }}>
                            {modalForm.modalScheduleUpdate === false && modalForm?.service_area !== null
                              ? timeOverlapWShiftPersonCheck(
                                  modalForm.start_time,
                                  modalForm.end_time,
                                  modalForm.start_date,
                                  parentProp,
                                  option,
                                  props
                                ) === true
                                ? disableBanned(option)
                                : option?.first_name + ' ' + option?.last_name
                              : modalForm.modalScheduleUpdate === true
                              ? timeOverlapWShiftPersonCheck(
                                  modalForm.start_time,
                                  modalForm.end_time,
                                  modalForm.start_date,
                                  parentProp,
                                  option,
                                  props
                                ) &&
                                timeOverlapWShiftPersonCheck(
                                  modalForm.start_time,
                                  modalForm.end_time,
                                  modalForm.start_date,
                                  parentProp,
                                  option,
                                  props
                                ) === true
                                ? disableBanned(option)
                                : option?.first_name + ' ' + option?.last_name
                              : option?.first_name + ' ' + option?.last_name}
                          </span>
                        </React.Fragment>
                      );
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        className={classes.modalFormForAutoComplete}
                        label="Assign to"
                        margin="normal"
                        style={
                          fieldError['personField_error'] === true && fieldError['banned_error'] === true
                            ? { backgroundColor: '#fff2f2' }
                            : { backgroundColor: 'white' }
                        }
                        fullWidth
                        error={fieldError['personField_error'] && fieldError['personShift_error']}
                        helperText={fieldError['personField_helper'] && fieldError['personShift_helper']}
                      />
                    )}
                  />
                </FormControl>
                {fieldError['banned_error'] === true && fieldError['personField_error'] === true ? (
                  <>
                    <div className="shift_modal_banned_person">
                      {' '}
                      <NotInterestedIcon fontSize="small" style={{ width: '15px', height: '15px' }} />
                      <span className="shift_modal_banned_person_error">
                        Person is banned on <span>{modalForm.service_area && modalForm.service_area.name}</span>
                      </span>
                    </div>
                  </>
                ) : null}
                {fieldError['personShift_error'] === true && fieldError['personField_error'] === true ? (
                  <>
                    <div className="duplicate_shift_for_person">
                      {' '}
                      <NotInterestedIcon fontSize="small" style={{ width: '15px', height: '15px' }} />
                      <span className="duplicate_shift_for_person_error">Person has a shift on Selected Date and Time Duration</span>
                    </div>
                  </>
                ) : null}
              </Grid>
            ) : null}

            <Grid item className={classes.modalInput} xs={6} lg={6}>
              <FormControl variant="outlined" size="small" className={classes.modalForms}>
                {PersonOrganizationSubcontractor && PersonOrganizationSubcontractor.length > 1 && (
                  <Autocomplete
                    options={PersonOrganizationSubcontractor}
                    ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                    getOptionLabel={option => option.name}
                    disableClearable
                    disabled={modalForm.modalScheduleUpdate ? true : false}
                    className={classes.modalNote}
                    onChange={handleCompany}
                    getOptionSelected={(option, value) => option.id === value.id}
                    value={modalForm.selectedCompany}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        className={`calendarCompanySubcontractorField ${classes.modalFormForAutoComplete}`}
                        label="Company / Subcontractor"
                        margin="normal"
                        fullWidth
                        error={fieldError['selectCompanyField_error']}
                      />
                    )}
                  />
                )}
              </FormControl>
            </Grid>

            <Divider className="Calendar-new-shift-modal-divider" />

            <FormControl className={classes.modalForms}>
              <FormGroup row={true}>
                <Grid container spacing={3}>
                  <Grid item className={classes.modalInput} xs={6} lg={3}>
                    <FormControl variant="outlined" size="small" className={classes.modalForms}>
                      <Autocomplete
                        options={check_in_frequencies}
                        classes={{ listbox: classes.listbox }}
                        ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                        getOptionLabel={option => option.label || ''}
                        className={classes.modalNote}
                        onChange={handleCheckInTimeModalValue}
                        getOptionSelected={(option, value) => option.id === value.id}
                        value={modalForm.check_call_frequency}
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            className={classes.modalFormForAutoComplete}
                            label="Check Call Required"
                            margin="normal"
                            fullWidth
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} lg={3}>
                    <TextField
                      variant="outlined"
                      size="small"
                      id="shift-chargerate"
                      label="Client Rate"
                      type="number"
                      value={modalForm.charge_rate}
                      className={classes.modalNote}
                      onChange={event => modalChargeRateChange(event, props)}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">{CurrencyChecker(props.settings.currency)}</InputAdornment>,
                        inputProps: { min: 0, max: 100 },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} lg={3}>
                    <FormControl variant="outlined" size="small" className={classes.modalForms}>
                      <Autocomplete
                        options={pay_rate_check_values}
                        classes={{ listbox: classes.listbox }}
                        ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                        getOptionLabel={option => option.label || ''}
                        className={classes.modalNote}
                        onChange={handlePayRateCheck}
                        getOptionSelected={(option, value) => option.label === value.value}
                        value={pay_rate_check_values.find(option => option.value === modalForm.pay_rate_check) || null}
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            className={classes.modalFormForAutoComplete}
                            label="Pay Rate Method"
                            margin="normal"
                            fullWidth
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} lg={3}>
                    <TextField
                      variant="outlined"
                      size="small"
                      id="shift-payrate"
                      label="Staff Rate"
                      type="number"
                      className={classes.modalNote}
                      value={modalForm.pay_rate}
                      onChange={event => modalPayRateChange(event, props)}
                      disabled={modalForm.pay_rate_check === 'false'}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">{CurrencyChecker(props.settings.currency)}</InputAdornment>,
                        inputProps: { min: 0, max: 100 },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </FormGroup>
            </FormControl>

            <FormControl className={classes.modalForms}>
              <FormGroup row={true}>
                <Grid item className={classes.modalInput} xs={12} lg={12}>
                  <TextField
                    id="shift-Note"
                    label="Notes"
                    className={classes.modalNote}
                    variant="outlined"
                    value={modalForm.note}
                    onChange={event => modalNotesChange(event, props)}
                    multiline={true}
                    minRows={3}
                    InputLabelProps={{
                      shrink: modalForm.note === '' ? false : true,
                    }}
                  ></TextField>
                </Grid>
              </FormGroup>
            </FormControl>

            <FormControl className={classes.modalForms}>
              <FormGroup row={true}>
                <Grid item xs={3} className={classes.modalButton}>
                  {modalForm.modalScheduleUpdate && (
                    <Button
                      variant="contained"
                      className="modal-shift-button"
                      onClick={() => {
                        handleShowDeleteModal(modalForm.isRepeat);
                      }}
                    >
                      <div className="shift-edit-history-button-icon">
                        {' '}
                        <DeleteOutlinedIcon fontSize="small" />
                      </div>
                      Delete
                    </Button>
                  )}
                </Grid>

                <Grid item xs={3} lg={3} className={classes.modalButton}>
                  {modalForm.modalScheduleUpdate && (
                    <Button
                      variant="contained"
                      className="shift-edit-history-button"
                      onClick={() => {
                        handleShiftHistory(parentProp, props);
                        setShiftHistoryModalOpen(true);
                      }}
                    >
                      <div className="shift-edit-history-button-icon">
                        {' '}
                        <HistoryIcon fontSize="small" />
                      </div>
                      History
                    </Button>
                  )}
                </Grid>

                <Grid item xs={3} lg={3}>
                  <Button
                    variant="contained"
                    className={classes.modalButtonSave}
                    onClick={() => {
                      if (modalForm.modalScheduleUpdate && modalForm.isRepeat) {
                        handleShow();
                      } else if (modalForm.modalScheduleUpdate === true && modalForm.isRepeat === false) {
                        CreateSchedule(parentProp, props, calendarDisplayWindow);
                      } else if (
                        modalForm.modalScheduleUpdate === false &&
                        modalForm.isRepeat === true &&
                        modalForm.modalSchedulePaste === true
                      ) {
                        handleShowForPasting();
                      } else {
                        CreateSchedule(parentProp, props, calendarDisplayWindow);
                      }
                    }}
                  >
                    {modalForm.modalScheduleUpdate ? 'Update' : 'Create'}
                  </Button>
                </Grid>
                <Grid item xs={3} lg={3}>
                  <Button
                    variant="outlined"
                    className={classes.modalButtonCancel}
                    onClick={() => {
                      setModalForm({ modalScheduleUpdate: false, setModalOpen: false });
                      handleShiftClose(parentProp, props);
                      setPersonIdentifier(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </FormGroup>
            </FormControl>
          </div>

          <DeleteModal
            showDeleteModal={showDeleteModal}
            setShowDeleteModal={setShowDeleteModal}
            deleteSchedule={deleteSchedule}
            deleteSeries={deleteSeries}
            isRepeatShift={isRepeatShift}
          />

          <UpdateModal modalProp={props} show={show} setShow={setShow} calendarDisplayWindow={calendarDisplayWindow} />

          <PasteModalComponent
            modalProp={props}
            handleShowForPasting={handleShowForPasting}
            showPasteModal={showPasteModal}
            setShowPasteModal={setShowPasteModal}
            calendarDisplayWindow={calendarDisplayWindow}
          />

          <ShiftHistoryModalComponent
            openShiftHistoryModal={ShiftHistoryModalOpen}
            shiftHistory={parentProp.shiftHistory}
            setShiftHistoryModalOpen={setShiftHistoryModalOpen}
            heading={'Shift Edit History'}
          />

          <PatrolDurationModal
            showDeleteModal={showPatrolDurationModal}
            setShowDeleteModal={setShowPatrolDurationModal}
            setting={props.setting}
            patrol_frequency={modalForm.patrol_frequency}
            setModalForm={setModalForm}
            modalForm={modalForm}
          />

          <PatternModal openPatternModal={openPatternModal} dateFormatSetting={props.setting.date_format} />
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = ({ calendar, PersonalSettings, organizationSettings, serviceArea, designation }: IRootState) => ({
  entities: calendar.entities,
  locations: serviceArea.entities,
  positions: designation.entities,
  setting: PersonalSettings.storedPersonalSettings,
  settings: organizationSettings.storedOrganizationSettings,
  NoOfCheckPoints: calendar.check_points,
  shiftHistory: calendar.shiftEditHistory,
  bannedEmployees: serviceArea.banned,
  serviceAreaPersons: serviceArea.activeServiceAreaPersons,
});

const mapDispatchToProps = {
  getNoOfCheckPoints,
  getActivePersonsByServiceAreaId,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ModalComponent));
