import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Grid, Divider } from '@material-ui/core';
import { IRootState } from 'app/shared/reducers';
import './automated-control-room.scss';
import { ACRShiftSettings } from 'app/shared/model/automated-control-room-shift-settings.model';
import ShiftBasicSettings from './components/shift-basic-settings';
import shift_welfare_check_times from '../../shared/data/shift_welfare_check_times.json';
import { handleShiftReminderButtons } from '../../../utils/FilterValuesFucntions';
export interface IAutomatedComtrolRoomShiftProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
  values?: ACRShiftSettings;
  setValues?: any;
  ACRShiftSettingsValues?: ACRShiftSettings;
}

export const WelfareCheck: React.FC<IAutomatedComtrolRoomShiftProps> = props => {
  const { values, setValues } = props;
  const [shiftWelfareValue, setShiftWelfareValue] = React.useState<{ label: string; id: number }>(null);

  useEffect(() => {
    updateAutoCompleteBoxes(values);
  }, [props.ACRShiftSettingsValues]);

  const handleShiftConfirmationTime = (event, time, trigger) => {
    const name = trigger.split('|');
    if (name[0] === 'Welfare Check') {
      setShiftWelfareValue(time);
    }
    setValues(oldValues => ({
      ...oldValues,
      [name[0]]: { ...oldValues[name[0]], [name[1]]: time ? time.id : 0 },
    }));
  };

  const handleTextfields = event => {
    const name = event.target.name.split('|');
    setValues(oldValues => ({
      ...oldValues,
      [name[0]]: { ...oldValues[name[0]], [name[1]]: parseInt(event.target.value, 10) },
    }));
  };

  const updateAutoCompleteBoxes = newValues => {
    if (newValues && newValues['Welfare Check'] && newValues['Welfare Check']['reminder_time']) {
      const time = Object.values(shift_welfare_check_times).find(e => e.id === newValues['Welfare Check']['reminder_time']);
      if (time) {
        setShiftWelfareValue(time);
      } else {
        setShiftWelfareValue({ label: 'Custom', id: 0 });
      }
    }
  };

  return (
    <div className="shift-confirmation-main-container">
      <div className="control-room-shift-confirmation-main-div">
        <div className="shift-confirmation-main-heading">
          <h1 style={{ color: '#7A55DE', fontWeight: 'bold' }}>Welfare Check (Mins)</h1>
        </div>

        <Grid container spacing={4}>
          <Grid item sm={6} lg={6} md={6}>
            <h6 style={{ color: '#7A55DE' }}>Welfare Check confirmation</h6>
          </Grid>

          <Grid item sm={6} lg={6} md={6}>
            <h6 style={{ color: '#7A55DE' }}> If confirmation Missed?</h6>
          </Grid>
        </Grid>

        <Grid container className="custom-time-label" spacing={4}>
          <Grid item sm={5} lg={5} md={5}></Grid>

          <Grid className="label-custom-time" item sm={1} lg={1} md={1}>
            {shiftWelfareValue && shiftWelfareValue['id'] === 0 && <label className="custom-label">Custom Time</label>}
          </Grid>
        </Grid>

        <ShiftBasicSettings
          autoCompleteOptions={shift_welfare_check_times}
          autoCompleteValue={shiftWelfareValue}
          handleShiftConfirmationTime={handleShiftConfirmationTime}
          handleTextfields={handleTextfields}
          values={values}
          setValues={setValues}
          handleShiftReminderButtons={handleShiftReminderButtons}
          autoCompleteName="Welfare Check|reminder_time"
          title="Welfare Check"
          response_time="Welfare Check|response_time"
          frequency="Welfare Check|frequency"
        />

        <Divider className="automated-control-room-divider"></Divider>
      </div>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(WelfareCheck);
