import axios from 'axios';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

export const ACTION_TYPES = {
  STORE_PERMISSION_SETTINGS: 'settings/STORE_PERMISSION_SETTINGS',
  GET_PERMISSION_SETTINGS: 'settings/GET_PERMISSION_SETTINGS',
};

const initialState = {
  settings: {} as any,
  loading: false,
  errorMessage: null,
};

export type PermissionsSettingState = Readonly<typeof initialState>;

export default (state: PermissionsSettingState = initialState, action): PermissionsSettingState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.GET_PERMISSION_SETTINGS):
      return {
        ...state,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.GET_PERMISSION_SETTINGS):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.GET_PERMISSION_SETTINGS):
      return {
        ...state,
        loading: false,
        settings: action.payload.data,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/permissionSetting';
export const storePermissionsSettings = settings => dispatch => {
  const result = axios.post(`${apiUrl}`, settings);
};

export const getPermissionsSettings = () => ({
  type: ACTION_TYPES.GET_PERMISSION_SETTINGS,
  payload: axios.get(apiUrl),
});
