import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { NavLink as Link } from 'react-router-dom';
import PeopleIcon from '@material-ui/icons/People';
import { NewNavDropdown } from './menu-components';

const Item = ({ link, unFillImage, fillImage, name, stylesProperty }) => {
  const widgetClasses = `widget ${stylesProperty}`;
  return (
    <MenuItem className="widget-item" component={Link} to={link} data-cy="clientsItem">
      <div className={widgetClasses}>
        <div className="widget-box">
          <div className="image-container">
            <img className="widget-image" src={unFillImage} alt={`${name}-unfilled`} />
            <img className="widget-image-hover" src={fillImage} alt={`${name}-filled`} />
          </div>
          <p className="widget-text">{name}</p>
        </div>
      </div>
    </MenuItem>
  );
};

export default Item;
