import React from 'react';
import IOSSwitch from './IOSswitchComponent';

const PermissionsRowComponent = props => {
  return (
    <tr style={{ color: 'grey' }}>
      <td style={{ width: '60%', textAlign: 'left', paddingLeft: '10px' }}> {props.msg} </td>
      <td className="location_supervisor_switch">
        {' '}
        <IOSSwitch checked={props.ls} onChange={() => props.handler(props.name, 'Supervisor', props.ls)} />{' '}
      </td>
      <td className="department_supervisor_switch">
        {' '}
        <IOSSwitch checked={props.ds} onChange={() => props.handler(props.name, 'User', props.ds)} />{' '}
      </td>
      <td className="manager_switch">
        {' '}
        <IOSSwitch checked={props.ma} onChange={() => props.handler(props.name, 'Manager', props.ma)} />{' '}
      </td>
      <td className="organizationadministrator_switch">
        {' '}
        <IOSSwitch checked={props.sa} onChange={() => props.handler(props.name, 'Organization Administrator', props.sa)} />{' '}
      </td>
    </tr>
  );
};

export default PermissionsRowComponent;
