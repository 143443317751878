import React from 'react';
import 'react-toastify/dist/ReactToastify.css';

import DailySignUpsPeople from './daily-sign-ups-components/daily-sign-ups-people';
import DailySignUpsOperations from './daily-sign-ups-components/daily-sign-ups-operations';
import DailySignUpsManager from './daily-sign-ups-components/daily-sign-ups-manager';
import DailySignUpsDepartment from './daily-sign-ups-components/daily-sign-ups-department';
import DailySignUpsLocation from './daily-sign-ups-components/daily-sign-ups-location';

export default function DailySignUps(props) {
  return (
    <React.Fragment>
      <DailySignUpsPeople
        handler={props.handler}
        barForProps={props.barForProps}
        barHandler={props.barHandler}
        valueForProps={props.valueForProps}
      />
      <DailySignUpsOperations
        handler={props.handler}
        barForProps={props.barForProps}
        barHandler={props.barHandler}
        valueForProps={props.valueForProps}
      />
      <DailySignUpsManager
        handler={props.handler}
        barForProps={props.barForProps}
        barHandler={props.barHandler}
        valueForProps={props.valueForProps}
      />
      <DailySignUpsDepartment
        handler={props.handler}
        barForProps={props.barForProps}
        barHandler={props.barHandler}
        valueForProps={props.valueForProps}
      />
      <DailySignUpsLocation
        handler={props.handler}
        barForProps={props.barForProps}
        barHandler={props.barHandler}
        valueForProps={props.valueForProps}
      />
    </React.Fragment>
  );
}
