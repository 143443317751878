/* eslint-disable complexity */
import React, { useState, useEffect, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { ActionButtons } from '../actionButtons/actionButtons';
import Button from '@material-ui/core/Button';
import PhoneInput from 'react-phone-input-2';
import country_types from '../../../../../shared/data/country_types.json';
import '../../form.scss';
import { TimePicker, MuiPickersUtilsProvider, DatePicker, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { getDateFormat } from 'app/shared/util/calender-utils';
import CustomToolbarComponent from '../../../../../entities/service-area/CustomToolbarComponent';
import moment from 'moment';
import { validationMessageOnChange } from '../../../../../../utils/validationMessages';
import { FormControl, Icon, InputAdornment } from '@material-ui/core';
import Select from '@material-ui/core/Select';

interface Props {
  storePersonalDetailsSection: (data: any, files: any) => void;
  updatePersonalDetailsSection: (data: any, files: any) => void;
  handleIncrementDecrement: (tag: string, activeModule: number) => void;
  settings: any;
  setting: any;
  aboutPosition: any;
  employeeFormData: any;
  editEmployeeForm: any;
}

export const AboutYou: React.FC<Props> = props => {
  const { personal_information } = props['employeeFormData'];
  const [showDivs, setShowDivs] = useState({ sia_license: false, crime_conviction: false });
  const [fileName, setFilesName] = useState({ front_side: 'No file chosen', back_side: 'No file chosen' });
  const [filesData, setFilesData] = useState([]);
  const [values, setValues] = useState([]);
  const [dateFormat, setdateFormat] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [fieldError, setFieldError] = useState({
    first_name: false,
    first_name_helper: '',
    middle_name: false,
    middle_name_helper: '',
    last_name: false,
    last_name_helper: '',
    // current_address: false,
    building_name: false,
    building_no: false,
    street: false,
    city: false,
    date_of_birth: false,
    post_code: false,
    telephone_no: false,
    email_address: false,
    email_address_helper: '',
    national_insurance_no: false,
    mobile_no: false,
    sia_license: false,
    license_name: false,
    sia_license_no: false,
    sia_license_no_helper: '',
    license_expiration: false,
    license_image_front: false,
    license_image_back: false,
    work_uk: false,
    crime_conviction: false,
    driving_license: false,
  });
  const isEdit = props['editEmployeeForm'];

  useEffect(() => {
    if (isEdit && personal_information) {
      setValues(personal_information);
      setFilesName({
        front_side: personal_information && personal_information.license_image_front,
        back_side: personal_information && personal_information.license_image_back,
      });
    }
  }, [personal_information]);

  useEffect(() => {}, [props.aboutPosition]);

  const setFormValues = e => {
    setValues(oldValues => ({ ...oldValues, [e.target.name]: e.target.value }));
    if (
      e.target.name === 'first_name' ||
      e.target.name === 'last_name' ||
      e.target.name === 'middle_name' ||
      e.target.name === 'prev_first_name' ||
      e.target.name === 'prev_last_name'
    ) {
      checkNumbersNotAllowedValidation(e);
    } else if (e.target.name === 'email_address') {
      checkEmailValidations(e);
    } else if (e.target.name === 'sia_license_no') {
      checkSIALicenseNoFieldValidation(e);
    } else {
      checkSingleFieldValidations(e);
    }
  };

  const checkSIALicenseNoFieldValidation = e => {
    if (e.target.name === 'sia_license_no' && !/^[0-9]{16}$/.test(e.target.value)) {
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name]: true }));
      setFieldError(oldFieldError => ({
        ...oldFieldError,
        [e.target.name + '_helper']: validationMessageOnChange.SIAnumber,
      }));
    } else {
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name]: false, [e.target.name + '_helper']: '' }));
    }
  };

  const handleHideShowDiv = (name, value) => {
    setShowDivs(oldValues => ({ ...oldValues, [name]: value }));
  };

  const handleFilesData = (event, name) => {
    setFieldError(oldValues => ({ ...oldValues, [event.target.name]: false }));
    setFilesName(oldValues => ({ ...oldValues, [name]: event.target.files[0].name }));
    setValues(oldValues => ({ ...oldValues, [event.target.name]: event.target.files[0].name }));
    const makeFilesCopy = filesData;
    makeFilesCopy.push({ file_name: name, file: event.target.files[0] });
    setFilesData(makeFilesCopy);
  };

  const handleTelephonePhoneNumber = phone => {
    setValues(oldValues => ({ ...oldValues, ['telephone_no']: phone }));
  };

  const handlePhoneNumber = phone => {
    setValues(oldValues => ({ ...oldValues, ['mobile_no']: phone }));
  };

  const handleDateOfBirth = e => {
    const d = moment(e).format('YYYY-MM-DD');
    setDateOfBirth(e);
    setValues(oldValues => ({ ...oldValues, ['date_of_birth']: d }));
    if (values['date_of_birth'] !== null || values['date_of_birth'] !== undefined) {
      setFieldError(oldFieldError => ({ ...oldFieldError, ['date_of_birth']: false }));
    }
  };

  const handleDateOfLicenseExpiration = e => {
    const d = moment(e).format('YYYY-MM-DD');
    setdateFormat(e);
    setValues(oldValues => ({ ...oldValues, ['license_expiration']: d }));
    if (values['license_expiration'] !== null || values['license_expiration'] !== undefined) {
      setFieldError(oldFieldError => ({ ...oldFieldError, ['license_expiration']: false }));
    }
  };

  const checkNumbersNotAllowedValidation = e => {
    const expression = /\d/g;
    if (e.target.value === undefined || e.target.value === '' || e.target.value === null) {
      setFieldError(oldValues => ({ ...oldValues, [e.target.name]: true }));
      setFieldError(oldValues => ({ ...oldValues, [`${e.target.name}_helper`]: '' }));
    } else if (expression.test(e.target.value)) {
      setFieldError(oldValues => ({ ...oldValues, [e.target.name]: true }));
      setFieldError(oldValues => ({ ...oldValues, [`${e.target.name}_helper`]: 'Numbers are not allowed' }));
    } else {
      setFieldError(oldValues => ({ ...oldValues, [e.target.name]: false }));
      setFieldError(oldValues => ({ ...oldValues, [`${e.target.name}_helper`]: '' }));
    }
  };

  const checkEmailValidations = e => {
    const expression = /^(([^<>()[\],;:\s@]+(\.[^<>()[\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+\.)+[^<>()[\],;:\s@]{2,})$/i;
    if (e.target.value === undefined || e.target.value === '' || e.target.value === null) {
      setFieldError(oldValues => ({ ...oldValues, [e.target.name]: true }));
      setFieldError(oldValues => ({ ...oldValues, [`${e.target.name}_helper`]: '' }));
    } else if (!expression.test(e.target.value)) {
      setFieldError(oldValues => ({ ...oldValues, [e.target.name]: true }));
      setFieldError(oldValues => ({ ...oldValues, [`${e.target.name}_helper`]: 'Invalid email address' }));
    } else {
      setFieldError(oldValues => ({ ...oldValues, [e.target.name]: false }));
      setFieldError(oldValues => ({ ...oldValues, [`${e.target.name}_helper`]: '' }));
    }
  };

  const checkSingleFieldValidations = e => {
    if (e.target.value === undefined || e.target.value === '' || e.target.value === null) {
      setFieldError(oldValues => ({ ...oldValues, [e.target.name]: true }));
    } else {
      setFieldError(oldValues => ({ ...oldValues, [e.target.name]: false }));
    }
  };

  const checkValidationsBeforeSubmit = () => {
    if (values['first_name'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['first_name']: true }));
    }
    if (values['last_name'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['last_name']: true }));
    }
    if (values['building_no'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['building_no']: true }));
    }
    if (values['street'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['street']: true }));
    }
    if (values['city'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['city']: true }));
    }
    if (values['date_of_birth'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['date_of_birth']: true }));
    }
    if (values['post_code'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['post_code']: true }));
    }
    if (values['telephone_no'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['telephone_no']: true }));
    }
    if (values['email_address'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['email_address']: true }));
    }
    if (values['national_insurance_no'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['national_insurance_no']: true }));
    }
    if (values['mobile_no'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['mobile_no']: true }));
    }
    if (values['work_uk'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['work_uk']: true }));
    }
    if (values['crime_conviction'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['crime_conviction']: true }));
    }
    if (values['driving_license_uk_uae'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['driving_license_uk_uae']: true }));
    }
    if (values['sia_license'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['sia_license']: true }));
    } else if (values['sia_license'] === 'yes') {
      if (values['license_name'] === undefined) {
        setFieldError(oldValues => ({ ...oldValues, ['license_name']: true }));
      }
      if (values['sia_license_no'] === undefined) {
        setFieldError(oldValues => ({ ...oldValues, ['sia_license_no']: true }));
      }
      if (values['license_expiration'] === undefined) {
        setFieldError(oldValues => ({ ...oldValues, ['license_expiration']: true }));
      }
      if (values['license_image_front'] === undefined) {
        setFieldError(oldValues => ({ ...oldValues, ['license_image_front']: true }));
      }
      if (values['license_image_back'] === undefined) {
        setFieldError(oldValues => ({ ...oldValues, ['license_image_back']: true }));
      }
    }
  };

  const isAllowedToSubmit = () => {
    checkValidationsBeforeSubmit();
    if (
      !fieldError['first_name'] &&
      !fieldError['last_name'] &&
      !fieldError['building_no'] &&
      !fieldError['street'] &&
      !fieldError['city'] &&
      !fieldError['date_of_birth'] &&
      !fieldError['post_code'] &&
      !fieldError['telephone_no'] &&
      !fieldError['email_address'] &&
      !fieldError['national_insurance_no'] &&
      !fieldError['mobile_no'] &&
      !fieldError['sia_license'] &&
      !fieldError['work_uk'] &&
      !fieldError['crime_conviction'] &&
      !fieldError['driving_license_uk_uae'] &&
      values['first_name'] !== undefined &&
      values['last_name'] !== undefined &&
      values['date_of_birth'] !== undefined &&
      values['building_no'] !== undefined &&
      values['street'] !== undefined &&
      values['city'] !== undefined &&
      values['post_code'] !== undefined &&
      values['telephone_no'] !== undefined &&
      values['email_address'] !== undefined &&
      values['national_insurance_no'] !== undefined &&
      values['mobile_no'] !== undefined &&
      values['sia_license'] !== undefined &&
      values['work_uk'] !== undefined &&
      values['crime_conviction'] !== undefined &&
      values['driving_license_uk_uae'] !== undefined
    ) {
      if (values['sia_license'] === 'yes') {
        if (
          !fieldError['license_name'] &&
          !fieldError['sia_license_no'] &&
          !fieldError['license_expiration'] &&
          !fieldError['license_image_front'] &&
          !fieldError['license_image_back'] &&
          values['license_name'] !== undefined &&
          values['sia_license_no'] !== undefined &&
          values['license_expiration'] !== undefined &&
          values['license_image_front'] !== undefined &&
          values['license_image_back'] !== undefined
        ) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    } else {
      return false;
    }
  };

  const submitSection = () => {
    if (isEdit && isAllowedToSubmit()) {
      const object = new FormData();
      filesData.forEach(element => {
        object.append(element.file_name, element.file);
      });
      object.append('about_position_id', props.employeeFormData?.position?.id);
      props.updatePersonalDetailsSection({ ...values, id: props['employeeFormData'].person.id }, object);
    } else if (!isEdit && isAllowedToSubmit()) {
      const object = new FormData();
      filesData.forEach(element => {
        object.append(element.file_name, element.file);
      });
      object.append('about_position_id', props.aboutPosition.id);
      props.storePersonalDetailsSection(values, object);
    }
  };

  return (
    <fieldset className="about_you_section">
      <div className="job-application-form-sections-top-heading-outer-div">
        <div className="job-application-form-sections-top-heading-inner-div">Personal Information</div>
        <hr style={{ marginTop: '1rem', marginBottom: '1rem' }} />
      </div>
      <div className="about-you-form">
        <Grid container spacing={3}>
          <Grid item xs={4} className="about-you-fields">
            <TextField
              type="text"
              name="first_name"
              variant="outlined"
              label="First Name"
              error={fieldError['first_name']}
              helperText={fieldError['first_name'] && fieldError['first_name_helper']}
              className="form-control"
              id="first_name"
              onChange={setFormValues}
              defaultValue={personal_information && personal_information.first_name}
              value={values ? values['first_name'] : ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon>
                      <img src="content/images/icons8-user-22.png" />
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={4} className="about-you-fields">
            <TextField
              type="text"
              name="middle_name"
              variant="outlined"
              label="Middle Name"
              error={fieldError['middle_name']}
              helperText={fieldError['middle_name'] && fieldError['middle_name_helper']}
              className="form-control"
              id="middle_name"
              onChange={setFormValues}
              defaultValue={personal_information && personal_information.middle_name}
              value={values ? values['middle_name'] : ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon>
                      <img src="content/images/icons8-user-22.png" />
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={4} className="about-you-fields">
            <TextField
              type="text"
              name="last_name"
              variant="outlined"
              label="Last Name"
              error={fieldError['last_name']}
              helperText={fieldError['last_name'] && fieldError['last_name_helper']}
              className="form-control"
              id="last_name"
              onChange={setFormValues}
              defaultValue={personal_information && personal_information.last_name}
              value={values ? values['last_name'] : ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon>
                      <img src="content/images/icons8-user-22.png" />
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={6} className="about-you-fields">
            <TextField
              type="text"
              name="prev_first_name"
              variant="outlined"
              label="Previous First Name"
              className="form-control"
              id="prev_first_name"
              onChange={setFormValues}
              defaultValue={personal_information && personal_information.prev_first_name}
              value={values ? values['prev_first_name'] : ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon>
                      <img src="content/images/icons8-user-22.png" />
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6} className="about-you-fields">
            <TextField
              type="text"
              name="prev_last_name"
              variant="outlined"
              label="Previous Last Name"
              className="form-control"
              id="prev_last_name"
              onChange={setFormValues}
              defaultValue={personal_information && personal_information.prev_last_name}
              value={values ? values['prev_last_name'] : ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon>
                      <img src="content/images/icons8-user-22.png" />
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={6} className="about-you-fields">
            <TextField
              type="text"
              name="building_name"
              variant="outlined"
              label="Building Name"
              error={fieldError['building_name']}
              className="form-control"
              id="building_name"
              onChange={setFormValues}
              defaultValue={personal_information && personal_information.building_name}
              value={values ? values['building_name'] : ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon>
                      <img src="content/images/icons8-address-22.png" />
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6} className="about-you-fields">
            <TextField
              type="text"
              name="building_no"
              variant="outlined"
              label="Builder / House Number"
              error={fieldError['building_no']}
              className="form-control"
              id="building_no"
              onChange={setFormValues}
              defaultValue={personal_information && personal_information.building_no}
              value={values ? values['building_no'] : ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon>
                      <img src="content/images/icons8-address-22.png" />
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6} className="about-you-fields">
            <TextField
              type="text"
              name="street"
              variant="outlined"
              label="Street"
              error={fieldError['street']}
              className="form-control"
              id="street"
              onChange={setFormValues}
              defaultValue={personal_information && personal_information.street}
              value={values ? values['street'] : ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon>
                      <img src="content/images/icons8-address-22.png" />
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6} className="about-you-fields">
            <TextField
              type="text"
              name="city"
              variant="outlined"
              label="City"
              error={fieldError['city']}
              className="form-control"
              id="city"
              onChange={setFormValues}
              defaultValue={personal_information && personal_information.city}
              value={values ? values['city'] : ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon>
                      <img src="content/images/icons8-address-22.png" />
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={6} className="about-you-fields">
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <KeyboardDatePicker
                autoOk
                id="date_of_birth"
                variant="inline"
                disableFuture
                className="form-control date_birth"
                inputVariant="outlined"
                name="date_of_birth"
                label="Date of Birth"
                format={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'MM/DD/YYYY'}
                defaultValue={personal_information && personal_information.date_of_birth}
                value={values ? values['date_of_birth'] : dateOfBirth}
                ToolbarComponent={prop => (
                  <>
                    <CustomToolbarComponent {...prop} settingFormat={props.setting.date_format} />
                  </>
                )}
                placeholder={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'MM/DD/YYYY'}
                keyboardIcon={<img src="content/images/icons8-calendar-22.png" />}
                InputAdornmentProps={{ position: 'end' }}
                onChange={date => handleDateOfBirth(date)}
                error={fieldError['date_of_birth']}
                style={{
                  width: '100%',
                }}
              />{' '}
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6} className="about-you-fields">
            <TextField
              type="text"
              name="post_code"
              variant="outlined"
              label="Post Code"
              error={fieldError['post_code']}
              className="form-control"
              id="post_code"
              onChange={setFormValues}
              defaultValue={personal_information && personal_information.post_code}
              value={values ? values['post_code'] : ''}
            />
          </Grid>
          <Grid item xs={6} className="about-you-fields">
            <TextField
              type="text"
              name="national_insurance_no"
              label="National Insurance no."
              error={fieldError['national_insurance_no']}
              variant="outlined"
              className="form-control"
              id="national_insurance_no"
              onChange={setFormValues}
              defaultValue={personal_information && personal_information.national_insurance_no}
              value={values ? values['national_insurance_no'] : ''}
              inputProps={{ maxLength: 9 }}
            />
          </Grid>
          <Grid item xs={6} className="about-you-fields">
            <TextField
              type="text"
              name="email_address"
              label="Email"
              error={fieldError['email_address']}
              helperText={fieldError['email_address'] && fieldError['email_address_helper']}
              variant="outlined"
              className="form-control"
              id="email_address"
              onChange={setFormValues}
              defaultValue={personal_information && personal_information.email_address}
              value={values ? values['email_address'] : ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon>
                      <img src="content/images/icons8-email-22.png" />
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6} className="about-you-fields">
            <InputLabel htmlFor="telephone_no"> Telephone no. </InputLabel>
            <PhoneInput
              inputProps={{
                name: 'telephone_no',
                autoFocus: false,
              }}
              country={
                props.settings.country_of_operations
                  ? country_types.find(country => country.value === props.settings.country_of_operations)
                    ? country_types.find(country => country.value === props.settings.country_of_operations).phoneName
                    : 'gb'
                  : 'gb'
              }
              isValid={value => {
                if (value === '' || value === undefined) {
                  return false;
                }
                return true;
              }}
              onChange={phone => handleTelephonePhoneNumber(phone)}
              value={values ? values['telephone_no'] : ''}
            />
          </Grid>
          <Grid item xs={6} className="about-you-fields">
            <InputLabel htmlFor="mobile_no"> Mobile no. </InputLabel>
            <PhoneInput
              country={
                props.settings.country_of_operations
                  ? country_types.find(country => country.value === props.settings.country_of_operations)?.phoneName
                  : 'gb'
              }
              isValid={value => {
                if (value === '' || value === undefined) {
                  return false;
                }
                return true;
              }}
              inputProps={{
                name: 'mobile_no',
                autoFocus: false,
              }}
              value={values ? values['mobile_no'] : ''}
              onChange={phone => handlePhoneNumber(phone)}
            />
          </Grid>
          <Grid item xs={12} className="about-you-fields">
            <FormGroup>
              <InputLabel htmlFor="sia_license"> Do you have SIA license?</InputLabel>
              <div style={{ borderBottom: fieldError['sia_license'] ? '1px solid red' : '' }}>
                <RadioGroup
                  aria-label="gender"
                  name="sia_license"
                  style={{ display: 'flex', flexDirection: 'row' }}
                  onChange={setFormValues}
                >
                  <FormControlLabel
                    control={
                      <Radio
                        name="sia_license"
                        checked={values && values['sia_license'] === 'no' ? true : false}
                        onClick={() => handleHideShowDiv('sia_license', false)}
                        style={{ color: '#4620B7' }}
                        size="small"
                        value="no"
                      />
                    }
                    className="vetting-form-about-position-job-availability-radio-button"
                    label="No"
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        name="sia_license"
                        checked={values && values['sia_license'] === 'yes' ? true : false}
                        onClick={() => handleHideShowDiv('sia_license', true)}
                        style={{ color: '#4620B7' }}
                        size="small"
                        value="yes"
                      />
                    }
                    className="vetting-form-about-position-job-availability-radio-button"
                    label="Yes"
                  />
                </RadioGroup>
              </div>
            </FormGroup>
          </Grid>
          {values['sia_license'] === 'yes' || showDivs.sia_license ? (
            <>
              <Grid item xs={12} className="about-you-fields">
                <TextField
                  type="text"
                  name="license_name"
                  label="Name of the license"
                  variant="outlined"
                  className="form-control"
                  error={fieldError['license_name']}
                  id="license_name"
                  defaultValue={personal_information && personal_information.license_name}
                  value={values ? values['license_name'] : ''}
                  onChange={setFormValues}
                />
              </Grid>
              <Grid item xs={6} className="about-you-fields">
                <TextField
                  type="text"
                  name="sia_license_no"
                  label="SIA license number(16 digits)"
                  variant="outlined"
                  className="form-control"
                  error={fieldError['sia_license_no']}
                  helperText={fieldError['sia_license_no_helper']}
                  id="sia_license_no"
                  onChange={setFormValues}
                  defaultValue={personal_information && personal_information.sia_license_no}
                  value={values ? values['sia_license_no'] : ''}
                  inputProps={{ maxLength: 16 }}
                />
              </Grid>
              <Grid item xs={6} className="about-you-fields">
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                  <KeyboardDatePicker
                    autoOk
                    variant="inline"
                    className="form-control"
                    inputVariant="outlined"
                    name="license_expiration"
                    label="license expiration"
                    format={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'MM/DD/YYYY'}
                    defaultValue={personal_information && personal_information.license_expiration}
                    value={values ? values['license_expiration'] : dateFormat}
                    ToolbarComponent={prop => (
                      <>
                        <CustomToolbarComponent {...prop} settingFormat={props.setting.date_format} />
                      </>
                    )}
                    placeholder={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'MM/DD/YYYY'}
                    InputAdornmentProps={{ position: 'start' }}
                    onChange={date => handleDateOfLicenseExpiration(date)}
                    error={fieldError['license_expiration']}
                    style={{
                      width: '100%',
                    }}
                  />{' '}
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} className="about-you-fields">
                <InputLabel htmlFor="license_expiration" style={{ color: 'red' }}>
                  {' '}
                  *Max size: 5 MB | Format: jpeg/png/pdf only{' '}
                </InputLabel>
                <div style={{ marginTop: '20px' }}>
                  <input
                    type="file"
                    name="license_image_front"
                    onChange={() => handleFilesData(event, 'front_side')}
                    id="license_image_front"
                    style={{ display: 'none' }}
                  />
                  <label htmlFor="license_image_front">
                    <Button className="about-you-sia-license-button" component="span">
                      *Upload SIA license front side
                    </Button>
                  </label>
                  <span id="file-chosen4" style={{ color: fieldError['license_image_front'] ? 'red' : 'black' }}>
                    {fileName.front_side}
                  </span>
                </div>
                <div style={{ marginTop: '20px' }}>
                  <input
                    type="file"
                    name="license_image_back"
                    onChange={() => handleFilesData(event, 'back_side')}
                    id="license_image_back"
                    style={{ display: 'none' }}
                  />
                  <label htmlFor="license_image_back">
                    <Button className="about-you-sia-license-button" component="span">
                      *Upload SIA license back side
                    </Button>
                  </label>
                  <span id="file-chosen" style={{ color: fieldError['license_image_back'] ? 'red' : 'black' }}>
                    {fileName.back_side}
                  </span>
                </div>
              </Grid>
            </>
          ) : (
            ''
          )}
          <Grid item xs={12} className="about-you-fields">
            <FormGroup>
              <InputLabel htmlFor="work_uk"> Are you legally entitled to work in United Kingdom? </InputLabel>
              <div style={{ borderBottom: fieldError['work_uk'] ? '1px solid red' : '' }}>
                <RadioGroup aria-label="gender" name="work_uk" style={{ display: 'flex', flexDirection: 'row' }} onChange={setFormValues}>
                  <FormControlLabel
                    control={<Radio name="work_uk" size="small" style={{ color: '#4620B7' }} value="no" />}
                    className="vetting-form-about-position-job-availability-radio-button"
                    checked={values && values['work_uk'] === 'no' ? true : false}
                    label="No"
                  />
                  <FormControlLabel
                    control={<Radio name="work_uk" size="small" style={{ color: '#4620B7' }} value="yes" />}
                    className="vetting-form-about-position-job-availability-radio-button"
                    checked={values && values['work_uk'] === 'yes' ? true : false}
                    label="Yes"
                  />
                </RadioGroup>
              </div>
            </FormGroup>
          </Grid>
          <Grid item xs={12} className="about-you-fields">
            <FormGroup>
              <InputLabel htmlFor="crime_conviction">
                {' '}
                Have you ever been convicted of a crime, other than spent convictions under the rehabilitation of offenders Act 1974?
              </InputLabel>
              <div style={{ borderBottom: fieldError['crime_conviction'] ? '1px solid red' : '' }}>
                <RadioGroup
                  aria-label="gender"
                  name="crime_conviction"
                  style={{ display: 'flex', flexDirection: 'row' }}
                  onChange={setFormValues}
                >
                  <FormControlLabel
                    control={
                      <Radio
                        name="crime_conviction"
                        onClick={() => handleHideShowDiv('crime_conviction', false)}
                        style={{ color: '#4620B7' }}
                        size="small"
                        checked={values && values['crime_conviction'] === 'no' ? true : false}
                        value="no"
                      />
                    }
                    className="vetting-form-about-position-job-availability-radio-button"
                    label="No"
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        name="crime_conviction"
                        onClick={() => handleHideShowDiv('crime_conviction', true)}
                        style={{ color: '#4620B7' }}
                        size="small"
                        checked={values && values['crime_conviction'] === 'yes' ? true : false}
                        value="yes"
                      />
                    }
                    className="vetting-form-about-position-job-availability-radio-button"
                    label="Yes"
                  />
                </RadioGroup>
              </div>
            </FormGroup>
          </Grid>
          {values['crime_conviction'] === 'yes' || showDivs.crime_conviction ? (
            <Grid item xs={12} className="professional-history-fields">
              <TextareaAutosize
                aria-label="empty textarea"
                name="crime_conviction_details"
                className="form-control professional-history-leaving-reason-text-area"
                id="crime_conviction_details"
                onChange={setFormValues}
                value={values ? values['crime_conviction_details'] : ''}
              />
            </Grid>
          ) : (
            ''
          )}
          <Grid item xs={12} className="about-you-fields">
            <FormGroup>
              <InputLabel htmlFor="driving_license_uk_uae"> Do you have UK/EU driving license?</InputLabel>
              <div style={{ borderBottom: fieldError['driving_license_uk_uae'] ? '1px solid red' : '' }}>
                <RadioGroup
                  aria-label="gender"
                  name="driving_license_uk_uae"
                  style={{ display: 'flex', flexDirection: 'row' }}
                  onChange={setFormValues}
                >
                  <FormControlLabel
                    control={<Radio name="driving_license_uk_uae" size="small" style={{ color: '#4620B7' }} value="no" />}
                    className="vetting-form-about-position-job-availability-radio-button"
                    checked={values && values['driving_license_uk_uae'] === 'no' ? true : false}
                    label="No"
                  />
                  <FormControlLabel
                    control={<Radio name="driving_license_uk_uae" size="small" style={{ color: '#4620B7' }} value="yes" />}
                    className="vetting-form-about-position-job-availability-radio-button"
                    checked={values && values['driving_license_uk_uae'] === 'yes' ? true : false}
                    label="Yes"
                  />
                </RadioGroup>
              </div>
            </FormGroup>
          </Grid>
        </Grid>
      </div>
      <br />
      <ActionButtons backButton={true} buttonText={true} submit={submitSection} />
    </fieldset>
  );
};
