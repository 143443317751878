import React, { useCallback, useEffect, useState } from 'react';
import { withGoogleMap, GoogleMap, Marker, DirectionsRenderer, Circle } from 'react-google-maps';
import { connect } from 'react-redux';
import { fetchLocationData, fetchPatrolTrackSummary } from './shift.reducer';
import withScriptjs from 'react-google-maps/lib/withScriptjs';
import { Grid, Paper, Typography } from '@material-ui/core';
import { useLocation, useParams } from 'react-router-dom';

interface ShiftState {
  shiftData: {
    checkpointsData?: {
      latitude: string;
      longitude: string;
    }[];
  };
}

const ShiftLocation = ({ locationData, fetchLocationData, checkpointLogs, fetchPatrolTrackSummary }) => {
  const [directions, setDirections] = useState(null);
  const location = useLocation();
  const params = useParams();
  const shiftId = Object.values(params)[0];
  const { shiftData } = location.state as ShiftState;
  const checkpointData = shiftData.checkpointsData;

  useEffect(() => {
    const fetchData = async () => {
      await fetchLocationData(shiftId);
      await fetchPatrolTrackSummary(shiftId);
    };

    fetchData();
  }, [shiftId, shiftData, fetchLocationData, fetchPatrolTrackSummary]);

  const renderRoute = useCallback(() => {
    if (!window.google || !checkpointData || checkpointData.length < 2) {
      return;
    }

    const directionsService = new window.google.maps.DirectionsService();

    const origin = new window.google.maps.LatLng(parseFloat(checkpointData[0].latitude), parseFloat(checkpointData[0].longitude));
    const destination = new window.google.maps.LatLng(
      parseFloat(checkpointData[checkpointData.length - 1].latitude),
      parseFloat(checkpointData[checkpointData.length - 1].longitude)
    );
    const waypoints = checkpointData.slice(1, -1).map(checkpoint => ({
      location: new window.google.maps.LatLng(parseFloat(checkpoint.latitude), parseFloat(checkpoint.longitude)),
      stopover: true,
    }));

    directionsService.route(
      {
        origin,
        destination,
        waypoints,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setDirections(result);
        }
      }
    );
  }, [checkpointData]);

  useEffect(() => {
    renderRoute();
  }, [checkpointData, renderRoute]);

  const Map = () => {
    return (
      <>
        {checkpointData && checkpointData.length > 0 && (
          <GoogleMap
            defaultZoom={13}
            defaultCenter={{
              lat: parseFloat(checkpointData[0].latitude),
              lng: parseFloat(checkpointData[0].longitude),
            }}
          >
            {directions && (
              <DirectionsRenderer
                directions={directions}
                options={{
                  suppressMarkers: true,
                  polylineOptions: {
                    strokeColor: 'black',
                  },
                }}
              />
            )}
            {checkpointData.map((checkpoint, index) => (
              <Marker
                key={index}
                position={{
                  lat: parseFloat(checkpoint.latitude),
                  lng: parseFloat(checkpoint.longitude),
                }}
                icon={{
                  url: `./content/images/${index + 1}.svg`,
                  anchor: new window.google.maps.Point(37, 37),
                  scaledSize: new window.google.maps.Size(37, 37),
                }}
              />
            ))}
            {locationData && (
              <Circle
                center={{
                  lat: parseFloat(locationData.latitude),
                  lng: parseFloat(locationData.longitude),
                }}
                radius={3}
                options={{
                  strokeColor: 'green',
                  strokeOpacity: 1,
                  strokeWeight: 4,
                  fillColor: 'green',
                  fillOpacity: 3,
                }}
              />
            )}
          </GoogleMap>
        )}
      </>
    );
  };

  const WrappedMap: any = withScriptjs(withGoogleMap(Map));

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchLocationData(shiftId);
    }, 30000);

    return () => {
      clearInterval(intervalId);
    };
  }, [shiftId, fetchLocationData]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div style={{ width: '98%', height: '500px', display: 'flex', marginTop: '4%', justifyContent: 'center' }}>
        <div style={{ width: '60%', height: '90%' }}>
          <WrappedMap
            googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`}
            containerElement={<div style={{ height: '100%' }} />}
            mapElement={<div style={{ height: '100%' }} />}
            loadingElement={<div style={{ height: '100%' }} />}
            directions={directions}
          />
        </div>
      </div>
      {checkpointLogs && checkpointLogs.length > 0 ? (
        <div style={{ height: '100%', width: '67%' }}>
          <Grid style={{ marginLeft: '80px' }}>
            <Typography variant="body1" style={{ fontSize: '24px', fontWeight: 'bold', color: 'rgb(136, 103, 242)' }}>
              Track Summary
            </Typography>
            <Typography variant="body2" style={{ fontSize: '14px', opacity: '0.5', margin: '8px 0px 25px 0px' }}>
              Here is the highlight of tracking history
            </Typography>
          </Grid>
          <div>
            {Array.isArray(checkpointLogs) &&
              checkpointLogs.map((logsArray, index) => (
                <Grid key={index} className="petrol-grid-styling" container spacing={3}>
                  <React.Fragment>
                    <Grid style={{ marginLeft: '80px' }} item xs={2}>
                      <Typography className="patrol-detail">Patrol {index + 1}</Typography>
                    </Grid>
                    {Array.isArray(logsArray) &&
                      logsArray.map(log => (
                        <Grid key={log.id} className="box-style" item xs={2} style={{ opacity: '0.5' }}>
                          <Paper>
                            {log.skip === null || log.skip === false ? (
                              <img src="../../../content/images/QR Code.svg" alt="false" height="30px" width="30px" />
                            ) : (
                              <img src="../../../content/images/tick.svg" alt="true" height="30px" width="30px" />
                            )}
                          </Paper>
                        </Grid>
                      ))}
                  </React.Fragment>
                </Grid>
              ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = storeState => ({
  locationData: storeState.shift.locationData,
  checkpointLogs: storeState.shift.checkpointLogs,
});

const mapDispatchToProps = {
  fetchLocationData,
  fetchPatrolTrackSummary,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShiftLocation);
