import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import Spinner from '../../commonUI/spinner/spinner';
import { connect, useDispatch } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import InfiniteScroll from 'react-infinite-scroll-component';
import ShiftRecordHeaderContainer from './ShiftRecordHeaderComponent';
import ShiftRecordsComponent from './ShiftRecordsComponent';
import AbnormalShiftsModalComponent from './AbnormalShiftsModal';
import { PersonFilter, LocationFilter, ShiftTypeFilter } from 'app/commonUI/filterComponent';
import './timeclock.scss';
import {
  approveAllShifts,
  abnormalCompletedShifts,
  getTimeClockShifts,
  getInfinteTimeClockShifts,
  updateShift,
  updateShiftBreak,
  startShiftBreak,
  endShiftBreak,
  updateSingleShiftClocks,
  reset,
  getShiftApprovalHistory,
  getShiftCountsAgainstServiceAreaId,
} from './timeclock.reducer';
import { getEntities as getPersons, reset as resetPerson, getPersonForFilters } from 'app/entities/person/person.reducer';
import { getEntities as getLocations, reset as resetLoc } from 'app/entities/service-area/service-area.reducer';
import { getAllClients, reset as resetClient } from 'app/entities/client/client.reducer';
import timePeriod from '../../shared/data/time_period.json';
import {
  Grid,
  TextField
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import { Storage } from 'react-jhipster';
import { getPersonalSettings } from '../Setting/Personal-Setting.reducer';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import CustomToolbarComponent from '../../entities/service-area/CustomToolbarComponent';
import moment from 'moment-timezone';
import MomentUtils from '@date-io/moment';
import { getOrganizationSettings } from '../../modules/Setting/generalSettings.reducer';
import {
  handleFilterCurrentWeekStartDate,
  handleFilterCurrentWeekEndDate,
  handleFilterLastWeekStartDate,
  handleFilterLastWeekEndDate,
  dateFTConverter,
} from '../../../utils/TimeFormatAndTimezone';
import { Autocomplete } from '@material-ui/lab';
import IconButton from '@material-ui/core/IconButton';
import SearchBar from 'material-ui-search-bar';
import { convertToPDF } from './converToPDF';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import GetAppIcon from '@material-ui/icons/GetApp';
import CircularBarComponent from './CircularBarComponent';

export interface ITimeClock extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

function TimeClock(props: ITimeClock) {
  const [selectedDateTo, setSelectedDateTo] = React.useState(handleFilterCurrentWeekEndDate());
  const [selectedDateFrom, setSelectedDateFrom] = React.useState(handleFilterCurrentWeekStartDate());
  const [values, setValues] = useState({
    people: '',
    location: '',
    shiftType: '',
    position: '',
    client: '',
    from_date: moment(handleFilterCurrentWeekStartDate()).format('YYYY-MM-DD'),
    to_date: moment(handleFilterCurrentWeekEndDate()).format('YYYY-MM-DD'),
    current_day: '',
    department: '',
  });
  const [selectedTimePeriod, setSelectedTimePeriod] = useState({ id: 3, text: 'Current week' });
  const [selectedPerson, setSelectedPerson] = React.useState<any | null>(null);
  const [selectedShiftType, setSelectedShiftType] = React.useState<any | null>(null);
  const [selectedLocation, setSelectedLocation] = React.useState<any | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [locale, setLocale] = useState(props.setting.time_format);
  const [dateFormat, setDateFormat] = useState(props.setting.date_format);
  const [timezoneFormat, setTimezoneFormat] = useState(props.setting.timezone);
  const [records, setRecords] = useState(props.shifts);
  const [abnormalShiftModalOpen, setAbnormalShiftModalOpen] = useState(false);
  const [shiftHistoryRecords, setShiftHistoryRecords] = React.useState<any | null>({});
  const [abnormalShiftRecords, setAbnormalShiftRecords] = React.useState<any | null>({});
  const [searched, setSearched] = useState<string>('');

  useEffect(() => {
    props.reset();
    props.resetClient();
    props.resetLoc();
    props.resetPerson();
    props.getPersonForFilters();
    props.getLocations(-1, '');
    props.getAllClients(-1);
  }, []);

  useEffect(() => {
    setRecords(props.shifts);
   }, [props.shifts]);
   
  useEffect(() => {
    setDateFormat(props.setting.date_format);
    setLocale(props.setting.time_format);
    setTimezoneFormat(props.setting.timezone);
  }, [props.setting]);

  useEffect(() => {
    if (pageNumber > 1) {
      props.getTimeClockShifts(values, pageNumber);
    }
  }, [pageNumber]);

  useEffect(() => {
    props.reset();
    props.getTimeClockShifts(values, '');
    if(values.location){
      props.getShiftCountsAgainstServiceAreaId(values.location);
    }
  }, [values]);

  const handlePersonFilterValues = (_, e) => {
    if (e) {
      setSelectedPerson({ id: e.id, text: e.text });
      return setFilterValues(e.name, e.id);
    } else {
      setSelectedPerson({ id: '', text: '' });
      setValues(oldValues => ({ ...oldValues, people: '' }));
    }
  };

  const handleShiftTypeFilterValues = (_, e) => {
    if (e) {
      setSelectedShiftType({ id: e.id, text: e.text });
      return setFilterValues(e.name, e.id);
    } else {
      setSelectedShiftType({ id: '', text: '' });
      setValues(oldValues => ({ ...oldValues, shiftType: '' }));
    }
  };

  const handleLocationFilterValues = (_, e) => {
    if (e) {
      setSelectedLocation({ id: e.id, text: e.text });
      return setFilterValues(e.name, e.id);
    } else {
      setSelectedLocation({ id: '', text: '' });
      setValues(oldValues => ({ ...oldValues, location: '' }));
    }
  };

  const handleSync = () => {
    setSelectedPerson(null);
    setSelectedLocation(null);
    setSelectedShiftType(null);
    setSearched('');
    props.reset();
    setPageNumber(1);
    setSelectedTimePeriod({ id: 3, text: 'Current week' });
    setValues({  people: '',
      location: '',
      shiftType: '',
      position: '',
      client: '',
      from_date: moment(handleFilterCurrentWeekStartDate()).format('YYYY-MM-DD'),
      to_date: moment(handleFilterCurrentWeekEndDate()).format('YYYY-MM-DD'),
      current_day: '',
      department: '',
    });
  };

  const setFilterValues = (name, value) => {
    setPageNumber(1);
    props.reset();
    setValues(oldValues => ({ ...oldValues, [name]: value }));
  };

  const handleDateFrom = e => {
    const d = moment(e).format('YYYY-MM-DD');
    setSelectedDateFrom(e);
    setPageNumber(1);
    props.reset();
    setValues(oldValues => ({ ...oldValues, ['from_date']: d, ['current_day']: '' }));
    setSelectedTimePeriod(null);
  };

  const handleDateTo = e => {
    const d = moment(e).format('YYYY-MM-DD');
    setSelectedDateTo(e);
    setPageNumber(1);
    props.reset();
    setValues(oldValues => ({ ...oldValues, ['to_date']: d, ['current_day']: '' }));
    setSelectedTimePeriod(null);
  };


  const approveAllShifts = () => {
    const today = new Date().toISOString();
    const entity = {
      time: today,
      approvedBy: Storage.local.get('user_name'),
      uId: Storage.local.get('user_id'),
      from_date: selectedDateFrom
      ? moment(selectedDateFrom).format('YYYY-MM-DD')
      : '',
      to_date: selectedDateTo
      ? moment(selectedDateTo).format('YYYY-MM-DD')
      : moment(new Date().toISOString()).format('YYYY-MM-DD'),
    };

    try {
      props.approveAllShifts(entity, values);
    } catch (error) {
      console.error("Error approving shifts:", error);
    }
    abnormalShifts();
  };

  const dispatch = useDispatch();
  const abnormalShifts = async () => {
    try {
      const entity = {
        from_date: selectedDateFrom
        ? moment(selectedDateFrom).format('YYYY-MM-DD')
        : '',
        to_date: selectedDateTo
        ? moment(selectedDateTo).format('YYYY-MM-DD')
        : moment(new Date().toISOString()).format('YYYY-MM-DD'),
      };
      const data = await dispatch<any>(abnormalCompletedShifts(entity));
      setAbnormalShiftRecords(data.shifts);
      setAbnormalShiftModalOpen(true); // open the modal
    } catch (err) {
      console.error('Error fetching abnormal shifts:', err);
    }
  };

  const handleTimePeriodFilter = (_, e) => {
    const today = new Date();
    let first;
    let last;
    if (e) {
      setSelectedTimePeriod({ id: e.id, text: e.text });

      switch (e.id) {
        case 1:
          setPageNumber(1);
          props.reset();
          setValues(oldValues => ({ ...oldValues, ['from_date'] : '', ['to_date']: moment().format('YYYY-MM-DD'), ['current_day']: '' }));
          setSelectedDateFrom(null);
          setSelectedDateTo(null);
          break;
        case 2:
          first = new Date();
          setPageNumber(1);
          props.reset();
          setValues(oldValues => ({
            ...oldValues,
            ['from_date']: '',
            ['to_date']: '',
            ['current_day']: moment(first).format('YYYY-MM-DD'),
          }));
          setSelectedDateFrom(first);
          setSelectedDateTo(first);
          break;
        case 3:
          first = handleFilterCurrentWeekStartDate();
          last = handleFilterCurrentWeekEndDate();
          setTimePeriodValues(first, last);
          setSelectedDateFrom(first);
          setSelectedDateTo(last);
          break;
        case 4:
          first = handleFilterLastWeekStartDate();
          last = handleFilterLastWeekEndDate();
          setTimePeriodValues(first, last);
          setSelectedDateFrom(first);
          setSelectedDateTo(last);
          break;
        case 5:
          first = new Date(today.getFullYear(), today.getMonth(), 1);
          last = new Date(today.getFullYear(), today.getMonth() + 1, 0);
          setTimePeriodValues(first, last);
          setSelectedDateFrom(first);
          setSelectedDateTo(last);
          break;
        case 6:
          first = new Date(today.getFullYear(), today.getMonth() - 1, 1);
          last = new Date(today.getFullYear(), today.getMonth(), 0);
          setTimePeriodValues(first, last);
          setSelectedDateFrom(first);
          setSelectedDateTo(last);
          break;
        default:
          setPageNumber(1);
          props.reset();
          setValues(oldValues => ({ ...oldValues, ['from_date']: '', ['to_date']: '', ['current_day']: '' }));
      }
    } else {
      setValues(oldValues => ({ ...oldValues, ['from_date']: '', ['to_date']: '', ['current_day']: '' }));
      setSelectedDateFrom(null);
      setSelectedDateTo(null);
    }
  };

  const setTimePeriodValues = (first, second) => {
    setPageNumber(1);
    props.reset();
    setValues(oldValues => ({
      ...oldValues,
      ['from_date']: moment(first).format('YYYY-MM-DD'),
      ['to_date']: moment(second).format('YYYY-MM-DD'),
      ['current_day']: '',
    }));
  };

  const { loading } = props;

  const header = [
    { label: 'Date', key: 'date' },
    { label: 'Shift Start Time', key: 'shiftStartTime' },
    { label: 'Shift End Time', key: 'shiftEndTime' },
    { label: 'Assignee Name', key: 'name' },
    { label: 'Sign In', key: 'checkIn' },
    { label: 'Sign Out', key: 'checkOut' },
    { label: 'Break S', key: 'breakStart' },
    { label: 'Break E', key: 'breakEnd' },
    { label: 'Total Work Hours', key: 'hours' },
    { label: 'Total Break Time', key: 'total_break_time' },
  ];

  const jsonData = [];
  if (records.length > 0) {
    records.forEach(element => {
      const data = { ...element };
      data.date = dateFTConverter(data.date, dateFormat);
      data.checkIn = data.checkIn === null ? null : moment(data.checkIn).format(`${locale === 1 ? 'HH:mm' : 'hh:mm A'}`);
      data.checkOut = data.checkOut === null ? null : moment(data.checkOut).format(`${locale === 1 ? 'HH:mm' : 'hh:mm A'}`);
      data.shiftStartTime = moment(data.shiftStartTime).format(`${locale === 1 ? 'HH:mm' : 'hh:mm A'}`);
      data.shiftEndTime = moment(data.shiftEndTime).format(`${locale === 1 ? 'HH:mm' : 'hh:mm A'}`);
      data.total_break_time = data.total_break_time ?? null;
      data.breakStart = data.breaks && data.breaks.length > 0 ? 
      data.breaks.map(breakItem => breakItem.break_start_time === null ? null : moment(breakItem.break_start_time).format(`${locale === 1 ? 'HH:mm' : 'hh:mm A'}`)).join('\n') 
      : null;
    data.breakEnd = data.breaks && data.breaks.length > 0 ? 
      data.breaks.map(breakItem => breakItem.break_end_time === null ? null : moment(breakItem.break_end_time).format(`${locale === 1 ? 'HH:mm' : 'hh:mm A'}`)).join('\n') 
      : null;

      jsonData.push(data);
    });
  }

  const csvReport = {
    data: jsonData,
    headers: header,
    filename: 'Real Time Report.csv',
  };

  const requestSearch = (searchedVal: string) => {
    setSearched(searchedVal);
    const value = searchedVal.toLowerCase();
    const filteredRows = props.shifts.filter(row => {
      return (
        (row.date && dateFTConverter(row.date, dateFormat).includes(value)) ||
        (row.person && row.person.first_name && row.person.first_name.toLowerCase().includes(value)) ||
        (row.person && row.person.last_name && row.person.last_name.toLowerCase().includes(value)) ||
        (row.person &&
          row.person.first_name &&
          row.person.last_name &&
          `${row.person.first_name} ${row.person.last_name}`.toLowerCase().includes(value)) ||
        (row.checkIn &&
          moment(row.checkIn)
            .format(`${locale === 1 ? 'HH:mm' : 'hh:mm A'}`)
            .includes(value)) ||
        (row.checkOut &&
          moment(row.checkIn)
            .format(`${locale === 1 ? 'HH:mm' : 'hh:mm A'}`)
            .includes(value)) ||
        (row.hours && row.hours.toLowerCase().includes(value)) ||
        (row.location?.['name']?.toLowerCase().includes(value)) ||
        (row.position?.['name']?.toLowerCase().includes(value))
      );
    });
    setRecords(filteredRows);
  };

  const cancelSearch = () => {
    setSearched('');
    requestSearch('');
  };

  const closeAbnormalShiftModal = state => {
    setAbnormalShiftModalOpen(false);
    setAbnormalShiftRecords({});
  };

  const headers = [
    'Status',
    'Date/ Time',
    'Assignee Name',
    'Sign In - Sign Out',
    'Custom Sign In / Out',
    'Break S - Break E',
    'Work Hours',
    'Break Time',
    'Actions'
  ];

  const activeColumns = {
    'status': true,
    'dateTime': true,
    'assigneeName': true,
    'signInOut': true,
    'customSignInOut': true,
    'breakSBreakE': true,
    'workHours': true,
    'breakTime': true,
    'actions': true
  };

  return (
    <div className="entities-reports-main-container">
      <div className="entities-reports-filter-area">
        {props.loading && <Spinner />}
        <Grid container spacing={2} style={{ flexWrap: 'nowrap', width: '65%' }}>
          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
            <FormControl variant="outlined" size="small">
              <SearchBar
                value={searched}
                onChange={searchVal => requestSearch(searchVal)}
                onCancelSearch={() => cancelSearch()}
                placeholder="Search Here..."
                style={{
                  outline: '1px solid #bbc4c2',
                  borderRadius: '4px',
                  height: '40px',
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <KeyboardDatePicker
                clearable={true}
                id="date"
                placeholder="From"
                inputVariant="outlined"
                className="entities_report_filter_fields datePicker"
                name="from_date"
                size="small"
                ToolbarComponent={prop => (
                  <>
                    <CustomToolbarComponent {...prop} settingFormat={props.setting.date_format} />
                  </>
                )}
                labelFunc={selectedDateFrom ? dateFTConverter : () => 'From'}
                value={selectedDateFrom ? moment(selectedDateFrom) : null}
                onChange={handleDateFrom}
                InputLabelProps={{ shrink: true }}
                disabled={loading}
              />{' '}
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <KeyboardDatePicker
                clearable={true}
                id="date"
                inputVariant="outlined"
                className="entities_report_filter_fields datePicker"
                size="small"
                ToolbarComponent={prop => (
                  <>
                    <CustomToolbarComponent {...prop} settingFormat={props.setting.date_format} />
                  </>
                )}
                labelFunc={selectedDateFrom ? dateFTConverter : () => 'Till'}
                value={selectedDateTo ? moment(selectedDateTo).format('YYYY-MM-DD') : null}
                name="to_date"
                onChange={handleDateTo}
                InputLabelProps={{ shrink: true }}
                disabled={loading}
              />{' '}
            </MuiPickersUtilsProvider>
          </Grid>

          <PersonFilter
            personsList={props.personsList}
            selectedPerson={selectedPerson}
            loading={loading}
            handlePersonFilterValues={handlePersonFilterValues}
          />
          <LocationFilter
            locationsList={props.locationsList}
            selectedLocation={selectedLocation}
            loading={loading}
            handleLocationFilterValues={handleLocationFilterValues}
          />
          <ShiftTypeFilter
           selectedShiftType={selectedShiftType}
           handleShiftTypeFilterValues={handleShiftTypeFilterValues}
          />
        </Grid>

        <Grid container className="entities_reports_refresh_and_download_div" style={{ width: '35%' }}>
          <Button
            style={{ width: '16vh', backgroundColor: '#3bbda9', color: 'white', fontSize: '14px' }}
            variant="contained"
            size="small"
            className="report-download-button"
            id="approve-all-shifts-btn"
            onClick={() => 
              approveAllShifts()
            }
          >
            Approve All Shifts
          </Button>
          <IconButton
            size="small"
            className="report-download-button"
            id="entities_reports_btn"
            onClick={() =>
              convertToPDF(jsonData,  selectedDateFrom === undefined ? undefined : moment(selectedDateFrom).format('YYYY-MM-DD'), selectedDateFrom === undefined ? undefined : moment(selectedDateTo).format('YYYY-MM-DD'))
            }
            disabled={props.loading}
          >
            <GetAppIcon fontSize="large" />
          </IconButton>

          <IconButton
            size="small"
            className="report-download-button"
            id="entities_reports_btn"
            disabled={props.loading}
            onClick={handleSync}
          >
            <AutorenewIcon fontSize="large" />
          </IconButton>

          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
            <FormControl variant="outlined" fullWidth>
              <Autocomplete
                options={
                  timePeriod &&
                  timePeriod.map(time => ({
                    id: time.value,
                    text: time.label,
                  }))
                }
                noOptionsText={'--Period--'}
                classes={{ listbox: 'autocompletelistbox' }}
                ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                getOptionLabel={option => option.text || ''}
                onChange={handleTimePeriodFilter}
                value={selectedTimePeriod}
                disabled={loading}
                fullWidth
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    className="personAutocompleteTextfield entities_report_filter_fields"
                    placeholder="Time period"
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </div>

      <CircularBarComponent
        shiftCounts = {props.shiftCounts}
      />


      <div className="time-clock-container">
        <ShiftRecordHeaderContainer headers={headers}/>
        <InfiniteScroll
          dataLength={records.length}
          scrollThreshold="200px"
          next={() => {
            setPageNumber(pageNumber + 1);
          }}
          hasMore={true}
        loader={''}>
        <ShiftRecordsComponent {...props} activeColumns={activeColumns} shiftHistory={shiftHistoryRecords} values={values} records={records} setRecords={setRecords}/>
        </InfiniteScroll>
      </div>
      <br />
      <br />
      <AbnormalShiftsModalComponent {...props}
        openAbnormalShiftModal={abnormalShiftModalOpen}
        shifts = {abnormalShiftRecords}
        setAbnormalShiftModalOpen={closeAbnormalShiftModal}
        heading={'Abnormal Shifts'}
      />
    </div>
  );
}
const mapStateToProps = (storeState: IRootState) => ({
  shifts: storeState.timeclock.entities,
  personsList: storeState.person.filterPersons,
  locationsList: storeState.serviceArea.entities,
  clientList: storeState.client.entities,
  loading: storeState.timeclock.loading,
  setting: storeState.PersonalSettings.storedPersonalSettings,
  organizationSettings: storeState.organizationSettings.storedOrganizationSettings,
  shiftApprovalHistory: storeState.timeclock.shiftApprovalHistory,
  shiftCounts: storeState.timeclock.shiftCounts,
});

const mapDispatchToProps = ({
  approveAllShifts,
  abnormalCompletedShifts,
  getTimeClockShifts,
  updateShift,
  getPersons,
  getLocations,
  updateShiftBreak,
  startShiftBreak,
  endShiftBreak,
  getInfinteTimeClockShifts,
  getPersonalSettings,
  updateSingleShiftClocks,
  reset,
  getOrganizationSettings,
  getShiftApprovalHistory,
  getAllClients,
  resetClient,
  resetLoc,
  resetPerson,
  getPersonForFilters,
  getShiftCountsAgainstServiceAreaId,
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect<Record<string, unknown>>(mapStateToProps, mapDispatchToProps)(TimeClock);
