import React from 'react';
import Link from '@material-ui/core/Link';
import { ImageDialogueComponent } from '../showImageDialogue';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

export const DocumentRecordComponent = props => {
  const { setIsOpen, isOpen, name, url } = props;
  return (
    <>
      <div className="vetting-documents-about-you-details-uploaded-docs">
        <div className="vetting-uploaded-document-link-div">
          <Link
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            {' '}
            {name}{' '}
          </Link>
        </div>
        <div className="vetting-uploaded-document-icon-div">
          <FontAwesomeIcon icon={faDownload} />
        </div>
      </div>
      <ImageDialogueComponent open={isOpen} image={url} setOpen={setIsOpen} />
    </>
  );
};
