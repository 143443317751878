import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import {} from 'react-jhipster';
import { IRootState } from 'app/shared/reducers';
import { IServiceArea } from 'app/shared/model/service-area.model';
import '../incident.scss';
import { Divider } from '@material-ui/core';

interface IIncidentWitnessComponentProps {
  incident_witness?: any;
}

export const IncidentWitnessComponent = (props: IIncidentWitnessComponentProps) => {
  return (
    <div className="incident-report-details-div">
      <div className="incident-police-div">
        <div className="incident-police-details-tab">Witness Details</div>
      </div>

      {props.incident_witness
        ? props.incident_witness.map(entity => (
            <>
              <div>Name : {entity.witness_name} </div>
              <div>Phone :{entity.witness_phone} </div>

              <div style={{ marginTop: '30px', marginBottom: '15px', fontWeight: 'bold' }}>Witnesss Comments</div>
              <div>{entity.comment}</div>

              <Divider style={{ marginBottom: '25px', marginTop: '25px' }}></Divider>
            </>
          ))
        : null}
    </div>
  );
};

const mapStateToProps = ({ organization }: IRootState) => ({
  organizationEntity: organization.entity,
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(IncidentWitnessComponent);
