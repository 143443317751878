import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Grid, FormControl, TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity, setPageNumber, reset } from './designation.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';

export interface IDesignationDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const DesignationDetail = (props: IDesignationDetailProps) => {
  const [values, setValues] = useState(props.designationEntity);

  useEffect(() => {
    setValues(props.designationEntity);
  }, [props.designationEntity]);

  useEffect(() => {}, [props.designationEntity.is_vettable]);

  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { designationEntity } = props;
  return (
    <React.Fragment>
      <div className="designation-create-main-container">
        <div className="designation-full-container">
          <Typography variant="h5" className="designationTitle">
            Position Details
          </Typography>
          <div style={{ display: 'flex' }}>
            <Paper className="designationContainerPaper">
              <div className="designationInnerDiv">
                <Grid item md={12} container justify="flex-start" spacing={1}>
                  <form className="designation-form" noValidate>
                    {' '}
                    <div className="designationFieldDivsideBySide">
                      <Grid item md={12} container>
                        <Grid item md={12} sm={12} xs={12}>
                          <FormControl className="designation-fields">
                            <TextField
                              id="designation-id"
                              variant="outlined"
                              className="designationid"
                              name="id"
                              required
                              inputProps={{ readOnly: true }}
                              label={<Translate contentKey="global.field.id">ID</Translate>}
                              value={values['id'] ? values['id'] : ''}
                              disabled={true}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="sideBySide">
                      <Grid item md={12} container>
                        <Grid item md={12} sm={12} xs={12}>
                          <FormControl className="designation-fields">
                            <TextField
                              id="designation-name"
                              data-cy="name"
                              variant="outlined"
                              className="designationid"
                              required
                              name="name"
                              label={<Translate contentKey="wfmApp.designation.name">Name</Translate>}
                              value={values['name'] ? values['name'] : ''}
                              defaultValue={designationEntity.name}
                              inputProps={{ maxLength: 255 }}
                              disabled={true}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="sideBySide">
                      <Grid item xs={12} md={6} sm={12}>
                        <FormControl style={{ minWidth: 150 }} className="shift-missed-buttons" variant="outlined" size="small">
                          <FormControlLabel
                            id={'processVetting'}
                            className="shift-missed-checkbox-buttons"
                            name="processVetting"
                            label="Process Vetting"
                            labelPlacement="end"
                            control={
                              <Checkbox
                                name={'processVetting'}
                                icon={<CircleUnchecked />}
                                checkedIcon={<CircleCheckedFilled />}
                                checked={designationEntity.is_vettable || false}
                                disabled={true}
                              />
                            }
                            style={{
                              color: 'black',
                              background: 'linear-gradient(to bottom, #efb5ee, #d488d3)',
                              border: '1px solid #E5A5E4',
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </div>
                    <div className="designationDiscardSaveButtons">
                      <Grid item md={12} container justify="center">
                        <Grid item md={12} sm={6} xs={6}>
                          <FormControl className="designationButtons designationBackButton">
                            <Button
                              component={Link}
                              to="/positions"
                              id="cancel-save"
                              className="designationBackButton"
                              onClick={() => props.reset()}
                            >
                              &nbsp;
                              <span className="d-none d-md-inline">Back</span>
                            </Button>
                          </FormControl>
                        </Grid>
                      </Grid>{' '}
                      &nbsp;&nbsp;
                      <Grid item md={12} container justify="center">
                        <Grid item md={12} sm={6} xs={6}>
                          <FormControl className="designationButtons designationSaveButton">
                            <Button
                              className="designationSaveButton"
                              id="save-entity"
                              data-cy="entityCreateSaveButton"
                              type="submit"
                              component={Link}
                              to={`/positions/${props.match.params.id}/edit`}
                            >
                              &nbsp; Edit
                            </Button>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </div>
                  </form>
                </Grid>
              </div>
            </Paper>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ designation }: IRootState) => ({
  designationEntity: designation.entity,
});

const mapDispatchToProps = { getEntity, setPageNumber, reset };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(DesignationDetail);
