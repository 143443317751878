import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
const Tip = (props: { message: string }) => {
  return (
    <span style={{ marginLeft: '15px' }}>
      <Tooltip title={props.message} placement="top-start" arrow>
        <HelpOutlineIcon fontSize="small" />
      </Tooltip>
    </span>
  );
};

export default Tip;
