import React, { useState, useEffect } from 'react';
import { Translate, translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Button, Grid, FormControl, TextField } from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';
import '../password.scss';
import { IRootState } from 'app/shared/reducers';
import { getSession } from 'app/shared/reducers/authentication';
import { reset, sendResetPasswordEmail } from '../password.reducer';
import Paper from '@material-ui/core/Paper';
import { ToastContainer, toast } from 'react-toastify';

export interface UserDispatchProps {
  setPassword: (value) => void;
  reset: () => void;
  getSession: () => void;
}

export interface IForgotPasswordProps extends StateProps, UserDispatchProps, RouteComponentProps<{ url: string }> {}

export const ResetForgotPasswordPage = (props: IForgotPasswordProps) => {
  const [error, setError] = useState({ name_error: false, name_helper: '' });
  const [value, setValue] = useState({});
  const [emailSent, setEmailSent] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    props.reset();
    props.getSession();
    return () => {
      props.reset();
    };
  }, []);

  const handleEmailValue = e => {
    if (
      e.target.name === undefined ||
      !/^(([^<>()[\],;:\s@]+(\.[^<>()[\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+\.)+[^<>()[\],;:\s@]{2,})$/i.test(e.target.value)
    ) {
      setError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: true }));
      setError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_helper']: 'Please Enter Correct Email !' }));
    } else {
      setError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: false }));
      setError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_helper']: '' }));
    }
    setValue(oldValues => ({ ...oldValues, [e.target.name]: e.target.value }));
  };

  const checkFormValidations = () => {
    const result = !error['email_error'] && value['email'] !== undefined;
    return result;
  };

  const checkEmptyFileds = () => {
    if (value['email'] === undefined) {
      setError(oldValues => ({ ...oldValues, ['email_error']: true }));
    }
  };

  const handleEmailSubmit = async () => {
    if (checkFormValidations()) {
      const result = await sendResetPasswordEmail(value);
      if (result.status === 200) {
        setEmailSent(true);
        setIsDisabled(true);
      } else {
        setError(oldFieldError => ({ ...oldFieldError, ['email_error']: true }));
        setError(oldFieldError => ({ ...oldFieldError, ['email_helper']: 'Email is not registered. Please provide a registered email !' }));
      }
    } else {
      checkEmptyFileds();
    }
  };

  return (
    <React.Fragment>
      <div className="forgot-password-full-container">
        <div style={{ display: 'flex', height: '650px', background: 'linear-gradient(to bottom, #f3f5f7 1%, #ffffff 99%)' }}>
          <Paper elevation={2} square className="forgotPasswordContainerPaper">
            <div style={{ width: '100%' }}>
              <p className="forgot-password-title">Forgot Your Password</p>
              <p className="forgot-password-heading-2">Please enter your registered email address to</p>
              <p className="forgot-password-heading-2">reset your password</p>
            </div>
            <form className="password-form">
              <Grid container item xs={12} md={12} lg={12} spacing={2} style={{ paddingLeft: '70px', marginTop: '50px' }}>
                <FormControl className="forgot-password-email-field">
                  <TextField
                    name="email"
                    variant="outlined"
                    label={translate('global.form.email.label')}
                    placeholder="Email"
                    disabled={isDisabled}
                    onChange={handleEmailValue}
                    error={error['email_error']}
                    helperText={error['email_helper']}
                    type="email"
                    inputProps={{
                      pattern: '^[a-zA-Z0-9!$&*+=?^_`{|}~.-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$|^[_.@A-Za-z0-9-]+$',
                      minLength: 5,
                      maxLength: 254,
                    }}
                    data-cy="email"
                  />
                </FormControl>
              </Grid>

              {emailSent === false ? (
                <>
                  <Grid container item xs={12} md={12} lg={12} spacing={2} style={{ paddingLeft: '70px', marginTop: '20px' }}>
                    <FormControl style={{ display: 'block' }} className="forgot-password-continure-button">
                      <Button className="passwordSaveButton" type="submit" data-cy="submit" onClick={handleEmailSubmit}>
                        Continue
                      </Button>
                    </FormControl>
                  </Grid>
                  <br />
                  <span style={{ marginLeft: '40%' }}>
                    Back to{' '}
                    <a href="/login" style={{ color: '#4720b7', textDecoration: 'none' }}>
                      Sign In
                    </a>
                  </span>
                </>
              ) : (
                <>
                  <Grid container item xs={12} md={12} lg={12} spacing={2} style={{ paddingLeft: '70px', marginTop: '20px' }}>
                    <FormControl style={{ display: 'block' }} className="forgot-password-continure-button">
                      <Button disabled={isDisabled} className="emailSentButton">
                        Email Sent
                      </Button>
                    </FormControl>
                  </Grid>
                  <br />
                  <p className="emailSentText">We have sent a reset password link to your email.</p>
                  <p className="emailSentText">Please check your inbox or junk folder.</p>
                </>
              )}
            </form>
          </Paper>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ authentication }: IRootState) => ({});

const mapDispatchToProps = { getSession, reset };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ResetForgotPasswordPage);
