import React from 'react';
import { match, Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import LeaveManagement from './leave-management';
import LeaveView from './leave-view';
import LeaveApply from './leave-apply';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={LeaveApply} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={LeaveView} />
      <ErrorBoundaryRoute path={match.url} component={LeaveManagement} />
    </Switch>
  </>
);

export default Routes;
