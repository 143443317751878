import Modal from '@material-ui/core/Modal';
import React, { useState } from 'react';
import {
  Button,
  FormGroup,
  FormControl,
  Grid,
  makeStyles,
  TextField,
  InputAdornment,
  OutlinedInput,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';
import { IconButton, Divider } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faUser, faPhone } from '@fortawesome/free-solid-svg-icons';

interface IInviteModalComponent {
  designations: any;
  departments: any;
  handleClose: any;
  handleSubmit: any;
  open: boolean;
}

export const InviteModalComponent = (props: IInviteModalComponent) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const useStyles = makeStyles(theme => ({
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    closeButton: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
    },
    paper: {
      position: 'absolute',
      width: '50%',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: '10%',
      left: '24%',
      overflow: 'auto',
    },
    shiftModalTitle: {
      textAlign: 'center',
      marginTop: '30px',
      marginBottom: '20px',
      color: '#7A55DE',
    },
    modalForms: {
      marginBottom: theme.spacing(2),
      minWidth: 120,
      width: '100%',
    },
    modalButtonSave: {
      backgroundColor: '#7A55DE',
      color: 'white',
      width: '95%',
    },
    modalButtonCancel: {
      backgroundColor: 'white',
      color: 'black',
      width: '95%',
    },
    modalNote: {
      width: '100%',
    },
    modalButton: {
      width: '35%',
    },
    modalInput: {
      width: '75%',
    },
    weekLists: {
      width: '100%',
      display: 'flex',
    },
    modalFormForAutoComplete: {
      marginTop: '0 !important',
    },
    listbox: {
      border: '1px solid #dfdbdb !important',
    },
  }));
  const classes = useStyles();

  const [position, setPosition] = useState<any>([]);
  const [department, setDepartment] = useState<any>([]);
  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleClose = (state: string) => {
    setPosition([]);
    setDepartment([]);
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhoneNumber('');
    setLoading(false);
    props.handleClose(state);
  };

  const handlePosition = (_, e) => {
    setPosition(e || []);
  };

  const handleDepartment = (_, e) => {
    setDepartment(e);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    const data = {
      email,
      first_name,
      last_name,
      phoneNumber,
      position,
      department,
    };
    await props.handleSubmit(data);
    handleClose('s');
  };

  return (
    <>
      <Modal open={props.open} onClose={handleClose} className="create-modal">
        <div>
          <Backdrop open={loading} style={{ zIndex: 1 }}>
            <CircularProgress color="primary" />
          </Backdrop>
          <div className={classes.paper}>
            <h2 id="shift-modal-title" className={classes.shiftModalTitle}>
              Invite Via Email or Phone
            </h2>
            <Divider className="Calendar-new-shift-modal-divider" />

            <FormControl className={classes.modalForms}>
              <FormGroup row={true}>
                <Grid container spacing={2}>
                  <Grid item lg={6} xs={12} md={6} sm={12}>
                    <FormControl className="person-fields">
                      <OutlinedInput
                        type="text"
                        onChange={e => {
                          setFirstName(e.target.value);
                        }}
                        placeholder="First Name"
                        label="Forname(s)"
                        name="first_name"
                        id="first_name"
                        defaultValue={first_name}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton aria-label="toggle password visibility" edge="end">
                              <FontAwesomeIcon icon={faUser} style={{ color: '#D48BD3' }} />
                            </IconButton>
                          </InputAdornment>
                        }
                        inputProps={{
                          maxLength: 32,
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} xs={12} md={6} sm={12}>
                    <FormControl className="person-fields">
                      <OutlinedInput
                        type="text"
                        onChange={e => {
                          setLastName(e.target.value);
                        }}
                        placeholder="Last Name"
                        label="Last Name"
                        name="last_name"
                        id="last_name"
                        defaultValue={last_name}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton aria-label="toggle password visibility" edge="end">
                              <FontAwesomeIcon icon={faUser} style={{ color: '#D48BD3' }} />
                            </IconButton>
                          </InputAdornment>
                        }
                        inputProps={{
                          maxLength: 32,
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </FormGroup>
              <FormGroup row={true}>
                <Grid container spacing={2}>
                  <Grid item lg={6} xs={12} md={6} sm={12}>
                    <FormControl className="person-fields">
                      <OutlinedInput
                        type="email"
                        onChange={e => {
                          setEmail(e.target.value);
                        }}
                        placeholder="Email"
                        label="Email"
                        name="email"
                        id="email"
                        value={email}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton aria-label="toggle password visibility" edge="end">
                              <FontAwesomeIcon icon={faEnvelope} style={{ color: '#D48BD3' }} />
                            </IconButton>
                          </InputAdornment>
                        }
                        inputProps={{ maxLength: 60 }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} xs={12} md={6} sm={12}>
                    <FormControl className="person-fields">
                      <OutlinedInput
                        type="text"
                        onChange={e => {
                          setPhoneNumber(e.target.value);
                        }}
                        placeholder="Phone Number"
                        label="Phone Number"
                        name="phone"
                        id="phone"
                        value={phoneNumber}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton aria-label="toggle password visibility" edge="end">
                              <FontAwesomeIcon icon={faPhone} style={{ color: '#D48BD3' }} />
                            </IconButton>
                          </InputAdornment>
                        }
                        inputProps={{ maxLength: 16 }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </FormGroup>
              <FormGroup row={true}>
                <Grid container spacing={2}>
                  <Grid item lg={6} xs={12} md={6} sm={12}>
                    <FormControl className="person-fields">
                      <Autocomplete
                        id="position"
                        options={props.designations}
                        classes={{ listbox: classes.listbox }}
                        ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                        getOptionLabel={option => option.name || ''}
                        getOptionSelected={(option, value) => option.id === value.id}
                        className={classes.modalNote}
                        onChange={handlePosition}
                        defaultValue={props.designations.filter(x => x.id === position.id)}
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="outlined"
                            className={classes.modalFormForAutoComplete}
                            label="Position"
                            margin="normal"
                            fullWidth
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} xs={12} md={6} sm={12}>
                    <FormControl className="person-fields">
                      <Autocomplete
                        id="department"
                        options={props.departments}
                        classes={{ listbox: classes.listbox }}
                        ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                        getOptionLabel={option => option.name || ''}
                        getOptionSelected={(option, value) => option.id === value.id}
                        className={classes.modalNote}
                        onChange={handleDepartment}
                        defaultValue={department}
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="outlined"
                            className={classes.modalFormForAutoComplete}
                            label="Departments"
                            margin="normal"
                            fullWidth
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </FormGroup>
            </FormControl>

            <Divider className="Calendar-new-shift-modal-divider" />
            <div style={{ float: 'right' }}>
              <Button
                variant="outlined"
                size="large"
                className="report-download-button"
                onClick={e => {
                  handleClose('c');
                }}
              >
                &nbsp;&nbsp;Cancel&nbsp;&nbsp;
              </Button>
              <Button className='addApplicantSendInvite' variant="contained" size="large" style={{ backgroundColor: '#7A55DE', color: 'white' }} onClick={handleSubmit}>
                Send Invite
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
