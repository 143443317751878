import { Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import './PeopleHealth.scss';
import PeopleHealthComponent from '../Component/PeopleHealthComponent';
import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { storePeopleHealthSettings } from '../peopleSettings.reducer';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ListItem } from '@material-ui/core';
import { getPeopleGeneralSettings } from '../peopleSettings.reducer';
import { deleteHealthSetting } from '../peopleSettings.reducer';

export interface IPeopleSettingsProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const PeopleHealth = (props: IPeopleSettingsProps) => {
  const [open, setOpen] = React.useState(false);
  const [healthName, setHealthName] = useState('');

  useEffect(() => {
    props.getPeopleGeneralSettings();
  }, []);

  useEffect(() => {}, [props.settings]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const submit = () => {
    const setting = { name: healthName };
    props.storePeopleHealthSettings(setting);
    setOpen(false);
  };

  const deleteHealthRecords = id => {
    props.deleteHealthSetting(id);
  };

  return (
    <React.Fragment>
      <div className="people-health-settings-external-div">
        <div className="people-health-heading-div">
          <div className="people-health-main-heading">
            <Typography variant="h5" className="people-health-heading-main-title">
              Health Conditions
            </Typography>
          </div>
          <div className="people-health-heading-message">Edit your information below</div>
        </div>
        {props.settings && (
          <Paper className="people-health-paper" elevation={2} square>
            <div className="people-health-external-div">
              <table className="people-health-table">
                <tr className="people-health-table-heading-tr">
                  <td
                    className="people-health-table-heading-td"
                    style={{ textAlign: 'left', backgroundColor: '#DCDCDC', padding: '1%', color: 'grey' }}
                  >
                    NAME
                  </td>
                  <td style={{ backgroundColor: '#DCDCDC' }}></td>
                </tr>
                <br />
                {props.settings.health && props.settings.health.length > 0 ? (
                  props.settings.health.map((setting, index) => (
                    <PeopleHealthComponent key={index} msg={setting['name']} id={setting.id} remove={deleteHealthRecords} />
                  ))
                ) : (
                  <div>no records</div>
                )}
                {/* {props.settings.map((setting, index) =><PeopleHealthComponent key={index} msg={setting['name']} />} */}
                <button
                  type="button"
                  className="btn btn-success btn-sm"
                  style={{ backgroundColor: '#4720b7', border: '1px solid #4720b7', marginTop: '4%' }}
                  onClick={handleClickOpen}
                >
                  Add Health Condition
                </button>

                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true}>
                  <DialogTitle className="health-popup-title">
                    {' '}
                    <a style={{ color: '#6610f2' }}>Add Health Condition</a>
                  </DialogTitle>
                  <DialogContent className="health-popup-content-div">
                    Name* :
                    <TextField
                      className="popup-text"
                      autoFocus
                      margin="dense"
                      id="people_health_name"
                      type="text"
                      variant="outlined"
                      onChange={event => {
                        setHealthName(event.target.value);
                      }}
                      fullWidth
                    />
                  </DialogContent>
                  <DialogActions>
                    <button
                      type="button"
                      className="btn btn-success btn-sm"
                      style={{ marginRight: '75%', backgroundColor: '#4720b7', border: '1px solid #4720b7' }}
                      onClick={submit}
                    >
                      Save
                    </button>
                    <button type="button" className="btn btn-outline-secondary btn-sm" style={{ marginRight: '2%' }} onClick={handleClose}>
                      Cancel
                    </button>
                  </DialogActions>
                </Dialog>
              </table>
            </div>
          </Paper>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ PeopleSetting }) => ({
  settings: PeopleSetting.storedPeopleSettings,
  loading: PeopleSetting.loading,
});

const mapDispatchToProps = {
  storePeopleHealthSettings,
  getPeopleGeneralSettings,
  deleteHealthSetting,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PeopleHealth);
