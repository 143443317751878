import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { IRootState } from 'app/shared/reducers';
import { setOrganization, getMultiplerganizations } from 'app/shared/reducers/authentication';
import LoginModal from './login-modal';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import './login.css';
import { Storage } from 'react-jhipster';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import setupOrganization from '../../config/axios-interceptor';
import axios from 'axios';

export interface ILoginProps extends StateProps, DispatchProps, RouteComponentProps<any> {}

export const OrganizationList = props => {
  const [organizations] = useState(Storage.local.get('organizations'));
  const [selectOrganization, setSelectOrganization] = useState('');
  const [organizationArray, setOrganizationArray] = useState(props.organizationsList);

  const handleClose = () => {
    props.history.push('/');
  };

  useEffect(() => {
    props.getMultiplerganizations();
  }, []);

  useEffect(() => {
    setOrganizationArray(props.organizationsList);
  }, [props.organizationsList]);

  useEffect(() => {
    if (Storage.local.get('organizationSelected')) {
      props.history.push(props.location.state?.from?.state?.from?.pathname || '/');
    }
  }, [Storage.local.get('organizationSelected')]);

  const handleSelectedOrganization = organization => {
    props.setOrganization(organization);
  };

  return (
    <div className="login-paper">
      <Paper className="login-paper-model" elevation={4} style={{ borderRadius: '10px', width: '500px', marginTop: '100px' }}>
        <div className="login-outer">
          <img src="../../../content/images/logo-2.png" className="logo-img" />
          <br />
          <br />
          <div className="login-outer-model" style={{ fontWeight: 'bold' }}>
            Please select your organization
          </div>
          <div>
            <div className="login-inner">
              {organizationArray && organizationArray.length > 0
                ? organizationArray.map((entity, i) => (
                    <Button
                      key={i}
                      fullWidth
                      variant="contained"
                      className="login-submit"
                      onClick={() => handleSelectedOrganization(entity)}
                    >
                      <div className="organization-name-tab" style={{ display: 'flex', width: '100%' }}>
                        <span>{entity.organization && entity.organization.name}</span>
                        <ArrowRightAltIcon className="organization-list-arrow-icon"></ArrowRightAltIcon>
                      </div>
                    </Button>
                  ))
                : null}
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
};

const mapStateToProps = ({ authentication }: IRootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  organizationsList: authentication.organziation_list,
  loginError: authentication.loginError,
  showModal: authentication.showModalLogin,
  loading: authentication.loading,
  organizationSelected: authentication.organizationSelected,
});

const mapDispatchToProps = { setOrganization, getMultiplerganizations };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationList);
