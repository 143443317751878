import React from 'react';
import { Grid, Paper } from '@material-ui/core';

const ServiceAreaComponent = props => {
  const { serviceArea, setting } = props;
  return (
    <Grid item lg={6} md={6} sm={6} xs={6}>
      <Paper elevation={2} className="service-area-component-main-container">
        <div className="service_area_component_name_div">
          <span style={{ display: 'flex' }}>
            <img src="content/icons/Khired Networks.png" style={{ marginRight: '2%' }} />
            <h5>{serviceArea.name}</h5>
          </span>
          <p>
            Charge Rate: {setting.currency} {serviceArea.charge_rate}/hr
          </p>
        </div>

        <div className="service_area_component_adress_div">
          <span style={{ display: 'flex' }}>
            <img src="content/icons/location.svg" style={{ marginRight: '2%' }} />
            <p>{serviceArea.address.address_line_one}</p>
          </span>
        </div>
      </Paper>
    </Grid>
  );
};

export default ServiceAreaComponent;
