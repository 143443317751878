import { Dialog, DialogContent, DialogTitle, Divider, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import '../../../content/css/calender.css';
import moment from 'moment';
import { Storage } from 'react-jhipster';

export const EmployeeHistory = props => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [timeFormatSettings] = useState(Storage.local.get('PersonalSetting').time_format);

  useEffect(() => {
    if (props.openEmployeeHistory) {
      setOpen(true);
    }
  }, [props.openEmployeeHistory]);

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'md'}>
        <div className="d-flex justify-content-center">
          <DialogTitle className="pattern-title">
            {props.timesheetSummaryList ? props.timesheetSummaryList?.[0].person : 'No Data found'}
          </DialogTitle>
        </div>
        <Divider />
        <DialogContent>
          <Table className="dialog-padding">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Site Name</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Start-End</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.timesheetSummaryList ? (
                props.timesheetSummaryList?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.startDate}</TableCell>
                    <TableCell>{row.location}</TableCell>
                    <TableCell>{row.description}</TableCell>
                    <TableCell>
                      {row.timeFrom ? moment(row.timeFrom).format(`${timeFormatSettings === 1 ? 'HH:mm' : 'hh:mm A'}`) : ''} -{' '}
                      {row.timeTo ? moment(row.timeTo).format(`${timeFormatSettings === 1 ? 'HH:mm' : 'hh:mm A'}`) : ''}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableCell>No Shifts Found</TableCell>
              )}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EmployeeHistory;
