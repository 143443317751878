import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Grid, FormControl } from '@material-ui/core';
import './register.scss';
import { IRootState } from 'app/shared/reducers';
import CheckIcon from '@material-ui/icons/Check';

export interface IRegisterProps extends StateProps, DispatchProps {}

export const RegisterVerfication = (props: IRegisterProps) => {
  return (
    <React.Fragment>
      <div className="register-acount-main-div">
        <div className="register-account-image-div">
          <img className="register-account-image" src="content/images/account-register-image.png"></img>
        </div>
        <div className="register-account-form-div">
          <div className="register-account-form-main-heading">
            Get Started with Your <br></br> 14-Days Free Trial.
          </div>
          <div className="register-account-form-sub-heading">You are just a few steps away.</div>
          <Grid container item xs={12} md={8} lg={8}>
            <FormControl style={{ display: 'block' }} className="accountsregister-fields">
              <Button id="register-submit" data-cy="submit" className="register-account-verification-email-button">
                Verification email sent <CheckIcon className="register-account-verification-button-icon"></CheckIcon>
              </Button>
            </FormControl>
          </Grid>

          <div className="register-account-verification-email-text">
            If you do not see the email in a few minutes,
            <br /> check your “junk mail” folder or “spam” folder.
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ locale }: IRootState) => ({
  currentLocale: locale.currentLocale,
});

const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(RegisterVerfication);
