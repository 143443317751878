import { ICountry } from 'app/shared/model/country.model';
import { IState } from 'app/shared/model/state.model';
import { ICity } from 'app/shared/model/city.model';

export interface IClient {
  id?: number;
  uid?: number;
  clientName?: string | null;
  addressLineOne?: string | null;
  city?: ICity | null;
  state?: IState | null;
  postCode?: string | null;
  country?: ICountry | null;
  personFirstName?: string | null;
  personLastName?: string | null;
  contract_start_date?: Date | null;
  contract_end_date?: Date | null;
  personEmail?: string | null;
  phone?: string | null;
  jobTitle?: string | null;
  logo?: string | null;
  identification_number?: string | null;
}

export const defaultValue: Readonly<IClient> = {};
