import { exportPDF } from './exportPDF';
import moment from 'moment';

const header = [
  'Reported By',
  'Incident Type',
  'Service Area',
  'Incident Details',
  'Police Report Time',
  'Police Arival Time',
  'Police Ref. No',
  'Police Comments',
  'Witness Name',
  'Witness Phone',
  'Witness Comment',
];

export const convertToPDF = (date, time, jsonData) => {
  const headers = [header];
  const title = 'Incident Report';
  let name = '';
  let phone = '';
  let comment = '';
  if (jsonData) {
    jsonData.map(row => [
      row.incident_witness.map(entity => [
        (name = name + entity.witness_name + '\n' + '\n'),
        (phone = phone + entity.witness_phone + '\n' + '\n'),
        (comment = comment + entity.comment + '\n' + '\n'),
      ]),
    ]);
  }
  const data = jsonData.map(row => [
    row.person.first_name + ' ' + row.person.last_name,
    row.incident_type.name,
    row.service_area.name,
    row.incident_description,
    row.police_report_time ? row.police_report_time : '',
    row.police_arrival_time ? row.police_arrival_time : '',
    row.police_report_number ? row.police_report_number : '',
    row.police_involvement_comments ? row.police_involvement_comments : '',
    row.incident_witness ? name : '',
    row.incident_witness ? phone : '',
    row.incident_witness ? comment : '',
  ]);
  const filename = 'Incident Report.pdf';

  exportPDF(date, time, headers, title, data, filename);
};
