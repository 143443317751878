import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { IClient, defaultValue } from 'app/shared/model/client.model';
import { ToastContainer, toast } from 'react-toastify';

import { Storage } from 'react-jhipster';

export const ACTION_TYPES = {
  GET_ALL_CLIENTS: 'client/GET_ALL_CLIENT',
  GET_SINGLE_CLIENT: 'client/GET_SINGLE_CLIENT',
  CREATE_CLIENT: 'client/CREATE_CLIENT',
  ADD_IMAGE: 'client/ADD_IMAGE',
  UPDATE_CLIENT: 'client/UPDATE_CLIENT',
  PARTIAL_UPDATE_CLIENT: 'client/PARTIAL_UPDATE_CLIENT',
  DELETE_CLIENT: 'client/DELETE_CLIENT',
  RESET: 'client/RESET',
  SET_PAGE_NUMBER: 'client/SET_PAGE_NUMBER',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IClient>,
  entity: defaultValue,
  updating: false,
  joyRide: false,
  updateSuccess: false,
  pageNumber: 1,
};

export type ClientState = Readonly<typeof initialState>;

// Reducer

export default (state: ClientState = initialState, action): ClientState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.GET_ALL_CLIENTS):
    case REQUEST(ACTION_TYPES.GET_SINGLE_CLIENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_CLIENT):
    case REQUEST(ACTION_TYPES.UPDATE_CLIENT):
    case REQUEST(ACTION_TYPES.DELETE_CLIENT):
    case REQUEST(ACTION_TYPES.PARTIAL_UPDATE_CLIENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case REQUEST(ACTION_TYPES.ADD_IMAGE):
      return {
        ...state,
        errorMessage: null,
      };
    case FAILURE(ACTION_TYPES.GET_ALL_CLIENTS):
    case FAILURE(ACTION_TYPES.GET_SINGLE_CLIENT):
    case FAILURE(ACTION_TYPES.CREATE_CLIENT):
    case FAILURE(ACTION_TYPES.UPDATE_CLIENT):
    case FAILURE(ACTION_TYPES.PARTIAL_UPDATE_CLIENT):
    case FAILURE(ACTION_TYPES.DELETE_CLIENT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case FAILURE(ACTION_TYPES.ADD_IMAGE):
      return {
        ...state,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.GET_ALL_CLIENTS):
      return {
        ...state,
        loading: false,
        entities: [...state.entities, ...action.payload.data],
      };
    case SUCCESS(ACTION_TYPES.GET_SINGLE_CLIENT):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_CLIENT):
      return {
        ...state,
        updating: false,
        joyRide: true,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.UPDATE_CLIENT):
    case SUCCESS(ACTION_TYPES.PARTIAL_UPDATE_CLIENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_CLIENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    case ACTION_TYPES.SET_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/client';

export const getAllClients = pageNo => ({
  type: ACTION_TYPES.GET_ALL_CLIENTS,
  payload: axios.get<IClient>(`${apiUrl}?page=${pageNo}`),
});

export const getEntity: ICrudGetAction<IClient> = id => {
  return {
    type: ACTION_TYPES.GET_SINGLE_CLIENT,
    payload: axios.get<IClient>(`${apiUrl}/${id}`),
  };
};

const apiImageUrl = 'api/clientLogo';
export const createNewClient = (entity, files) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_CLIENT,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  if (!!files.entries().next().value === true) {
    dispatch(img(files, result));
  }
  dispatch(reset());
  dispatch(setPageNumber({ pageNumber: 1 }));
  toast.success('Client Created Successfully');
  return result;
};

export const updateEntity = (entity, files) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_CLIENT,
    payload: axios.put(`${apiUrl}/${entity.id}`, cleanEntity(entity)),
  });
  if (!!files.entries().next().value === true) {
    dispatch(img(files, result));
  }
  dispatch(reset());
  dispatch(setPageNumber({ pageNumber: 1 }));
  toast.success('Client Edited Successfully');
  return result;
};

export const img = (files, res) => async dispatch => {
  files.append('id', res.value.data.id);
  const result = await dispatch({
    type: ACTION_TYPES.ADD_IMAGE,
    payload: axios.post(apiImageUrl, files, { headers: { 'Content-Type': 'multipart/form-data' } }),
  });
  return result;
};

export const partialUpdate: ICrudPutAction<IClient> = entity => async dispatch => {
  const uId = Storage.local.get('user_id');
  const result = await dispatch({
    type: ACTION_TYPES.PARTIAL_UPDATE_CLIENT,
    payload: axios.patch(`${apiUrl}/${entity.id}`, entity),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IClient> = id => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_CLIENT,
    payload: axios.delete(`${apiUrl}/${id}`),
  });
  dispatch(reset());
  dispatch(setPageNumber({ pageNumber: 1 }));
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

export const setPageNumber: ICrudPutAction<{ pageNumber: number }> = pageNo => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.SET_PAGE_NUMBER,
    payload: pageNo,
  });
  dispatch(getAllClients(pageNo['pageNumber']));
  return result;
};
