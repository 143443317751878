import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { NavLink as Link } from 'react-router-dom';
import { NewNavDropdown } from './menu-components';
import Item from './Item';

export const PeopleMenu = ({ viewClients, viewSubcontractors, viewServiceArea }) => (
  <div className="headerIconScheduler">
    <NewNavDropdown name="Essentials" id="schedules-menu" data-cy="schedulesMenu">
      <div className="grid-container-essentials">
        <span className="grid-containers4"></span>
        {viewClients === true && (
          <Item
            stylesProperty="clients"
            link="/clients"
            unFillImage="../../../../content/images/Clients.png"
            fillImage="../../../../content/images/Clientsfill.png"
            name="Clients"
          />
        )}
        {viewSubcontractors === true && (
          <Item
            stylesProperty="subcontractors"
            link="/subcontractors"
            unFillImage="../../../../content/images/Subcontractorsfill.png"
            fillImage="../../../../content/images/Subcontractors.png"
            name="Subcontractors"
          />
        )}

        {viewServiceArea && (
          <Item
            stylesProperty="servicearea"
            link="/service-areas"
            unFillImage="../../../../content/images/Service.png"
            fillImage="../../../../content/images/Servicefill.png"
            name="Service Areas"
          />
        )}
        <Item
          stylesProperty="departments"
          link="/departments"
          unFillImage="../../../../content/images/Departmentsfill.png"
          fillImage="../../../../content/images/Departments.png"
          name="Departments"
        />
        <Item
          stylesProperty="positions"
          link="/positions"
          unFillImage="../../../../content/images/Real Time.png"
          fillImage="../../../../content/images/Real Timefill.png"
          name="Positions"
        />
      </div>
    </NewNavDropdown>
    <img className="icon" src="../../../../content/images/angle-right.png" alt="dropdown-icon" />
  </div>
);

export default PeopleMenu;
