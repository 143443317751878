import React, { useEffect } from 'react';
import './header.scss';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { AdminMenu, EntitiesMenu, Account, SchedulesMenuItems, HRMenu, PeopleMenu, ReportsMenu } from '../menus';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Home, Brand, Client, Vetting } from './header-components';
import Notifications from '../../../modules/notifications/Notifications';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
      list: {
        padding: '0',
      },
    },
  })
);

const HeaderComponentBigScreen = props => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    props.imageUrl;
  }, [props.imageUrl]);

  const isRoleAllowed = (organizationadministrator, supervisor, manager, user = false) => {
    if (organizationadministrator || supervisor || manager || user) {
      return true;
    }
    return false;
  };

  return (
    <>
      {props.isAuthenticated && props.isVetted && (
        <MenuItem dense={true}>
          {' '}
          <Home />{' '}
        </MenuItem>
      )}
      {props.isAuthenticated && props.isVetted && props.isSystemAdmin && (
        <>
          <MenuItem dense={true}>
            {' '}
            <Client />{' '}
          </MenuItem>
          <MenuItem dense={true}>
            {' '}
            <AdminMenu showOpenAPI={props.isOpenAPIEnabled} showDatabase={!props.isInProduction} />{' '}
          </MenuItem>
        </>
      )}
      {props.isAuthenticated && props.isVetted && isRoleAllowed(props.isOrganizationAdministrator, props.isSupervisor, props.isManager) && (
        <>
          <MenuItem dense={true}>
            {' '}
            <SchedulesMenuItems />{' '}
          </MenuItem>
          <MenuItem dense={true}>
            <ReportsMenu />
          </MenuItem>
          <MenuItem dense={true}>
            {' '}
            <PeopleMenu
              viewClients={props.canViewClients}
              viewSubcontractors={props.canViewSubContractors}
              viewServiceArea={props.canViewServiceArea}
            />{' '}
          </MenuItem>
          {/* <MenuItem dense={true}>
            {' '}
            <HRMenu />{' '}
          </MenuItem> */}
        </>
      )}
      <MenuItem dense={true}>{props.isAuthenticated && props.isVetted && props.isOrganizationAdministrator && <HRMenu />}</MenuItem>
      {/* <MenuItem dense={true}>{props.isAuthenticated && props.isSystemAdmin && <EntitiesMenu />}</MenuItem> */}
      {/* <MenuItem dense={true}>{props.isAuthenticated && props.isOrganizationAdministrator && <Vetting />}</MenuItem> */}
      <MenuItem dense={true}>
        {' '}
        <Notifications notificationsCount={4} iconColor="#344563" />{' '}
      </MenuItem>
      <MenuItem dense={true}>
        <img
          src={
            props.imageUrl === undefined
              ? 'content/images/icon5.png'
              : props.imageUrl === null || props.imageUrl === ''
              ? 'content/images/icon5.png'
              : props.imageUrl
          }
          alt="Avatar"
          className="Avatar"
        />
      </MenuItem>
      <MenuItem dense={true}>
        {' '}
        <Account
          isAuthenticated={props.isAuthenticated}
          isVetted={props.isVetted}
          isSystemAdmin={props.isSystemAdmin}
          imageUrl={props.imageUrl}
        />
      </MenuItem>
    </>
  );
};

export default HeaderComponentBigScreen;
