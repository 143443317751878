import React, { useState } from 'react';
import MenuITem from 'app/shared/layout/menus/menu-item';
import { Translate, translate } from 'react-jhipster';
import { NavDropdown } from './menu-components';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import { NavLink as Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import './menu.scss';

const accountMenuItemsAuthenticated = (
  <div>
    <MenuITem icon="wrench" to="/account/settings" data-cy="settings">
      &nbsp;
      <Translate contentKey="global.menu.account.settings">Settings</Translate>
    </MenuITem>
    <MenuITem icon="lock" to="/account/password" data-cy="passwordItem">
      &nbsp;
      <Translate contentKey="global.menu.account.password">Password</Translate>
    </MenuITem>
    <MenuITem icon="sign-out-alt" to="/logout" data-cy="logout">
      &nbsp;
      <Translate contentKey="global.menu.account.logout">Sign out</Translate>
    </MenuITem>
  </div>
);

const accountMenuItemsNotVetted = (
  <div>
    <MenuITem icon="sign-out-alt" to="/logout" data-cy="logout">
      &nbsp;
      <Translate contentKey="global.menu.account.logout">Sign out</Translate>
    </MenuITem>
  </div>
);

const accountMenuOrganizationItemsAuthenticated = (
  <div>
    <MenuITem icon="users-cog" to="/PersonalSetting" data-cy="PersonalSetting">
      Personal Settings
    </MenuITem>
    <MenuITem icon="wrench" to="/settings" data-cy="settings">
      <Translate contentKey="global.menu.account.settings">Settings</Translate>
    </MenuITem>
    <MenuITem icon="lock" to="/account/password" data-cy="passwordItem">
      &nbsp;
      <Translate contentKey="global.menu.account.password">Password</Translate>
    </MenuITem>
    <MenuITem icon="sign-out-alt" to="/logout" data-cy="logout">
      &nbsp;
      <Translate contentKey="global.menu.account.logout">Sign out</Translate>
    </MenuITem>
  </div>
);

const accountMenuItems = (
  <div className="dashboad-page-before-signIn">
    <MenuItem component={Link} id="login-item" to="/login" data-cy="login" className="dashboad-page-before-signIn">
      <FontAwesomeIcon icon="sign-in-alt" />
      &nbsp;Sign in
      {/* <Translate contentKey="global.menu.account.login">Sign in</Translate> */}
    </MenuItem>
    <MenuItem component={Link} to="/register" data-cy="register" className="dashboad-page-before-signIn-registeritem">
      <FontAwesomeIcon icon="user-plus" />
      &nbsp;Register
      {/* <Translate contentKey="global.menu.account.register">Register</Translate> */}
    </MenuItem>
  </div>
);

export const AccountMenu = ({
  isAuthenticated = false,
  isSystemAdmin = false,
  anchorEl = null,
  handleClose,
  open = false,
  isVetted = true,
}) => (
  <Menu
    id="simple-menu"
    anchorEl={anchorEl}
    keepMounted
    getContentAnchorEl={null}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    open={open}
    onClose={handleClose}
  >
    {isAuthenticated
      ? isVetted
        ? [isSystemAdmin ? accountMenuItemsAuthenticated : accountMenuOrganizationItemsAuthenticated]
        : accountMenuItemsNotVetted
      : accountMenuItems}
  </Menu>
);

export const Account = ({ isAuthenticated, isSystemAdmin, imageUrl, isVetted }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = event => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
  };

  const handleClose = () => {
    setOpenMenu(false);
  };

  return (
    <>
      <Tooltip arrow title="Account menu">
        <IconButton>
          <span className="avatarBorderCircle">
            <Avatar
              alt="Remy Sharp"
              src={
                imageUrl === undefined
                  ? 'content/images/icon5.png'
                  : imageUrl === null || imageUrl === ''
                  ? 'content/images/icon5.png'
                  : imageUrl
              }
              onClick={handleOpenMenu}
            />
            <AccountMenu
              isAuthenticated={isAuthenticated}
              isVetted={isVetted}
              isSystemAdmin={isSystemAdmin}
              anchorEl={anchorEl}
              handleClose={handleClose}
              open={openMenu}
            />
          </span>
        </IconButton>
      </Tooltip>
    </>
  );
};

export default AccountMenu;
