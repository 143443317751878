import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { IRootState } from 'app/shared/reducers';
import { getEntity, saveMeasurements } from '../person.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { Grid, Paper, Button, Divider, TextField, FormControl } from '@material-ui/core';
import '../person.scss';
import PersonMeasurementComponent from './person-measurement-component';
import { useLocation } from 'react-router-dom';

export interface IPersonDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const Measurements = (props: IPersonDetailProps) => {
  const [imageFile, setImageFile] = useState(null);
  const [personMeasurements, setPersonMeasurements] = useState(props.personEntity.measurements);
  const location = useLocation();
  const [showEditPage, setShowEditPage] = useState(false);

  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  useEffect(() => {
    setPersonMeasurements(props.personEntity.measurements);
    setPersonMeasurements(oldFieldValues => ({ ...oldFieldValues, person_id: props.match.params.id }));
  }, [props.personEntity]);

  const handleHardHat = size => {
    setPersonMeasurements(oldFieldValues => ({ ...oldFieldValues, hard_hat: size }));
  };

  const handleJacket = size => {
    setPersonMeasurements(oldFieldValues => ({ ...oldFieldValues, jacket: size }));
  };

  const handlePolo = size => {
    setPersonMeasurements(oldFieldValues => ({ ...oldFieldValues, polo_shirt: size }));
  };

  const handlePantSize = size => {
    setPersonMeasurements(oldFieldValues => ({ ...oldFieldValues, pant_size: size }));
  };

  const handleHiViz = size => {
    setPersonMeasurements(oldFieldValues => ({ ...oldFieldValues, hi_viz: size }));
  };

  const handleCollarSize = event => {
    setPersonMeasurements(oldFieldValues => ({ ...oldFieldValues, shirt_collar_size: event.target.value }));
  };

  const handleChestSize = event => {
    setPersonMeasurements(oldFieldValues => ({ ...oldFieldValues, shirt_chest_size: event.target.value }));
  };

  const handleSleevesSize = event => {
    setPersonMeasurements(oldFieldValues => ({ ...oldFieldValues, shirt_sleeves_length: event.target.value }));
  };

  const handleBodyLength = event => {
    setPersonMeasurements(oldFieldValues => ({ ...oldFieldValues, shirt_body_length: event.target.value }));
  };

  const handleTieSize = size => {
    setPersonMeasurements(oldFieldValues => ({ ...oldFieldValues, tie: size }));
  };

  const handleShoeStandard = size => {
    setPersonMeasurements(oldFieldValues => ({ ...oldFieldValues, shoes_standard: size }));
  };

  const handleShoesSize = event => {
    setPersonMeasurements(oldFieldValues => ({ ...oldFieldValues, shoes_size: event.target.value }));
  };

  const handlePantWaist = event => {
    setPersonMeasurements(oldFieldValues => ({ ...oldFieldValues, waist_size: event.target.value }));
  };

  const handleHipsSize = event => {
    setPersonMeasurements(oldFieldValues => ({ ...oldFieldValues, hips_size: event.target.value }));
  };

  const handlePantLength = event => {
    setPersonMeasurements(oldFieldValues => ({ ...oldFieldValues, pant_length: event.target.value }));
  };

  const saveMeasurements = () => {
    props.saveMeasurements(personMeasurements, props.match.params.id);
    setShowEditPage(false);
  };

  const profilePageHandler = () => {
    setShowEditPage(false);
  };

  const { personEntity } = props;

  const editPageHandler = () => {
    setPersonMeasurements(props.personEntity.measurements);
    setShowEditPage(true);
  };

  return (
    <div className="person-view-main-container">
      {showEditPage === false && (
        <>
          <div className="PersonProfileEditBtnContainer">
            <Button
              variant="contained"
              className="Profile_Detials_edit_btn"
              onClick={editPageHandler}
              startIcon={<img src="content/icons/Personal Details/Edit.png" />}
            >
              Edit
            </Button>
          </div>
          <Divider style={{ margin: '4% 0' }} />
        </>
      )}

      <Grid style={{ width: '100%' }}>
        <div style={{ display: 'flex' }}>
          <div className="person-view-measurement-main-div">
            <div className="person-view-details">
              <div className="person-measurement-left-div">
                <span style={{ color: '#4820B8', fontSize: '18px', fontFamily: 'Roboto' }}> Hard Hat</span>

                <PersonMeasurementComponent showEditPage={showEditPage} values={personMeasurements?.hard_hat} handleSize={handleHardHat} />

                <Divider style={{ marginTop: '50px', width: '90%', marginBottom: '30px' }}></Divider>

                <div className="measurements-section-2">
                  <div style={{ marginBottom: '30px', color: '#4820B8', fontSize: '18px', fontFamily: 'Roboto' }}>Shirt</div>
                  <div className="measurements-shirt-div">
                    <Grid container spacing={2} style={{ marginLeft: '0' }}>
                      <Grid lg={5} md={5}>
                        <TextField
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          size="small"
                          disabled={showEditPage === false ? true : false}
                          value={personMeasurements?.shirt_collar_size}
                          label="Collar Size"
                          onChange={handleCollarSize}
                        ></TextField>
                      </Grid>

                      <Grid lg={5} md={5} style={{ marginLeft: '20px' }}>
                        <TextField
                          variant="outlined"
                          size="small"
                          disabled={showEditPage === false ? true : false}
                          InputLabelProps={{ shrink: true }}
                          value={personMeasurements?.shirt_chest_size}
                          label="Chest Size"
                          onChange={handleChestSize}
                        ></TextField>
                      </Grid>
                    </Grid>
                  </div>

                  <div className="measurements-shirt-div">
                    <Grid container spacing={2} style={{ marginLeft: '0' }}>
                      <Grid lg={5} md={5}>
                        <TextField
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          size="small"
                          disabled={showEditPage === false ? true : false}
                          value={personMeasurements?.shirt_body_length}
                          label="Body Length"
                          onChange={handleBodyLength}
                        ></TextField>
                      </Grid>

                      <Grid lg={5} md={5} style={{ marginLeft: '20px' }}>
                        <TextField
                          variant="outlined"
                          size="small"
                          disabled={showEditPage === false ? true : false}
                          InputLabelProps={{ shrink: true }}
                          value={personMeasurements?.shirt_sleeves_length}
                          label="Sleeves Length"
                          onChange={handleSleevesSize}
                        ></TextField>
                      </Grid>
                    </Grid>
                  </div>
                </div>

                <Divider style={{ marginTop: '50px', width: '90%', marginBottom: '30px' }}></Divider>

                <div style={{ marginBottom: '30px', color: '#4820B8', fontSize: '18px', fontFamily: 'Roboto' }}>Tie</div>

                <Grid container spacing={1}>
                  <Grid item lg={2} md={2} sm={2}>
                    <Button
                      className="person-measurement-size-button"
                      id={personMeasurements?.tie === 'S' ? 'person-measurement-checked' : 'person-measurement-unchecked'}
                      disabled={showEditPage === false ? true : false}
                      onClick={() => {
                        handleTieSize('S');
                      }}
                    >
                      S
                    </Button>
                  </Grid>

                  <Grid item lg={2} md={2} sm={2}>
                    <Button
                      className="person-measurement-size-button"
                      id={personMeasurements?.tie === 'M' ? 'person-measurement-checked' : 'person-measurement-unchecked'}
                      disabled={showEditPage === false ? true : false}
                      onClick={() => {
                        handleTieSize('M');
                      }}
                    >
                      M
                    </Button>
                  </Grid>

                  <Grid item lg={2} md={2} sm={2}>
                    <Button
                      className="person-measurement-size-button"
                      id={personMeasurements?.tie === 'L' ? 'person-measurement-checked' : 'person-measurement-unchecked'}
                      disabled={showEditPage === false ? true : false}
                      onClick={() => {
                        handleTieSize('L');
                      }}
                    >
                      L
                    </Button>
                  </Grid>

                  <Grid item lg={2} md={2} sm={2}>
                    <Button
                      className="person-measurement-size-button"
                      id={personMeasurements?.tie === 'XL' ? 'person-measurement-checked' : 'person-measurement-unchecked'}
                      disabled={showEditPage === false ? true : false}
                      onClick={() => {
                        handleTieSize('XL');
                      }}
                    >
                      XL
                    </Button>
                  </Grid>
                </Grid>

                <Divider style={{ marginTop: '50px', width: '90%', marginBottom: '30px' }}></Divider>

                <div style={{ marginBottom: '30px', color: '#4820B8', fontSize: '18px', fontFamily: 'Roboto' }}>Pants</div>

                <PersonMeasurementComponent
                  showEditPage={showEditPage}
                  values={personMeasurements?.pant_size}
                  handleSize={handlePantSize}
                />

                <Grid container spacing={2} style={{ marginLeft: '0', marginTop: '20px' }}>
                  <Grid lg={5} md={5}>
                    <TextField
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      disabled={showEditPage === false ? true : false}
                      value={personMeasurements?.waist_size}
                      onChange={handlePantWaist}
                      size="small"
                      label="Waist"
                    ></TextField>
                  </Grid>

                  <Grid lg={5} md={5} style={{ marginLeft: '20px' }}>
                    <TextField
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      disabled={showEditPage === false ? true : false}
                      value={personMeasurements?.hips_size}
                      onChange={handleHipsSize}
                      size="small"
                      label="Hips"
                    ></TextField>
                  </Grid>

                  <Grid lg={5} md={5} style={{ marginTop: '20px' }}>
                    <TextField
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      disabled={showEditPage === false ? true : false}
                      value={personMeasurements?.pant_length}
                      onChange={handlePantLength}
                      size="small"
                      label="Length"
                    ></TextField>
                  </Grid>
                </Grid>
              </div>

              <div className="person-measurement-right-div">
                <span style={{ color: '#4820B8', fontSize: '18px', fontFamily: 'Roboto' }}>Jacket</span>

                <PersonMeasurementComponent showEditPage={showEditPage} values={personMeasurements?.jacket} handleSize={handleJacket} />

                <Divider style={{ marginTop: '50px', width: '90%', marginBottom: '30px' }}></Divider>

                <div className="measurements-section-2">
                  <div style={{ marginBottom: '30px', color: '#4820B8', fontSize: '18px', fontFamily: 'Roboto' }}>Polo Shirt</div>

                  <div>
                    <PersonMeasurementComponent
                      showEditPage={showEditPage}
                      values={personMeasurements?.polo_shirt}
                      handleSize={handlePolo}
                    />
                  </div>
                </div>

                <Divider style={{ marginTop: '50px', width: '90%', marginBottom: '30px' }}></Divider>

                <div style={{ marginBottom: '30px', color: '#4820B8', fontSize: '18px', fontFamily: 'Roboto' }}>Hi Viz</div>

                <PersonMeasurementComponent showEditPage={showEditPage} values={personMeasurements?.hi_viz} handleSize={handleHiViz} />

                <Divider style={{ marginTop: '50px', width: '90%', marginBottom: '30px' }}></Divider>

                <div style={{ marginBottom: '30px', color: '#4820B8', fontSize: '18px', fontFamily: 'Roboto' }}>Shoes</div>

                <Grid container spacing={1}>
                  <Grid item lg={2} md={2} sm={2}>
                    <Button
                      className="person-measurement-size-button"
                      id={personMeasurements?.shoes_standard === 'UK' ? 'person-measurement-checked' : 'person-measurement-unchecked'}
                      disabled={showEditPage === false ? true : false}
                      onClick={() => {
                        handleShoeStandard('UK');
                      }}
                    >
                      UK
                    </Button>
                  </Grid>

                  <Grid item lg={4} md={5} sm={5}>
                    <Button
                      className="person-measurement-size-button"
                      id={
                        personMeasurements?.shoes_standard === 'International'
                          ? 'person-measurement-checked'
                          : 'person-measurement-unchecked'
                      }
                      disabled={showEditPage === false ? true : false}
                      onClick={() => {
                        handleShoeStandard('International');
                      }}
                    >
                      International
                    </Button>
                  </Grid>
                </Grid>

                <Grid lg={5} md={5} style={{ marginTop: '20px' }}>
                  <TextField
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    disabled={showEditPage === false ? true : false}
                    size="small"
                    value={personMeasurements?.shoes_size}
                    onChange={handleShoesSize}
                    label="Size"
                  ></TextField>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </Grid>

      {showEditPage === true ? (
        <>
          <Divider style={{ margin: '4% 0' }} />
          <Grid item xs={12}>
            <FormControl style={{ flexDirection: 'row', justifyContent: 'flex-end', width: '100%' }}>
              <>
                <Button variant="contained" className="Person-profile-Discard-Button" onClick={profilePageHandler}>
                  Cancel
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button
                  onClick={() => {
                    saveMeasurements();
                  }}
                  variant="contained"
                  id="save-entity"
                  type="submit"
                  className="Person-profile-Save-Button"
                >
                  Save
                </Button>
              </>
            </FormControl>
          </Grid>
        </>
      ) : null}
    </div>
  );
};
const mapStateToProps = ({ person }: IRootState) => ({
  personEntity: person.entity,
});

const mapDispatchToProps = {
  getEntity,
  saveMeasurements,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Measurements);
