import React from 'react';
import MenuITem from 'app/shared/layout/menus/menu-item';
import { Translate, translate } from 'react-jhipster';
import { NavDropdown } from './menu-components';

export const EntitiesMenu = props => (
  <NavDropdown
    icon="th-list"
    name={translate('global.menu.entities.main')}
    id="entity-menu"
    data-cy="entity"
    style={{ maxHeight: '80vh', overflow: 'auto' }}
  >
    <MenuITem icon="asterisk" to="/employment-status">
      <Translate contentKey="global.menu.entities.employmentStatus" />
    </MenuITem>
    <MenuITem icon="asterisk" to="/employment-details">
      <Translate contentKey="global.menu.entities.employmentDetails" />
    </MenuITem>
    <MenuITem icon="asterisk" to="/education-level">
      <Translate contentKey="global.menu.entities.educationLevel" />
    </MenuITem>
    <MenuITem icon="asterisk" to="/education">
      <Translate contentKey="global.menu.entities.education" />
    </MenuITem>
    <MenuITem icon="asterisk" to="/educational-institute-type">
      <Translate contentKey="global.menu.entities.educationalInstituteType" />
    </MenuITem>
    <MenuITem icon="asterisk" to="/educational-institute">
      <Translate contentKey="global.menu.entities.educationalInstitute" />
    </MenuITem>
    <MenuITem icon="asterisk" to="/country">
      <Translate contentKey="global.menu.entities.country" />
    </MenuITem>
    <MenuITem icon="asterisk" to="/country-admin-unit-type">
      <Translate contentKey="global.menu.entities.countryAdminUnitType" />
    </MenuITem>
    <MenuITem icon="asterisk" to="/country-admin-unit">
      <Translate contentKey="global.menu.entities.countryAdminUnit" />
    </MenuITem>
    <MenuITem icon="asterisk" to="/city">
      <Translate contentKey="global.menu.entities.city" />
    </MenuITem>
    <MenuITem icon="asterisk" to="/address">
      <Translate contentKey="global.menu.entities.address" />
    </MenuITem>
    <MenuITem icon="asterisk" to="/contact-type">
      <Translate contentKey="global.menu.entities.contactType" />
    </MenuITem>
    <MenuITem icon="asterisk" to="/contact">
      <Translate contentKey="global.menu.entities.contact" />
    </MenuITem>
    <MenuITem icon="asterisk" to="/employee-contract-type">
      <Translate contentKey="global.menu.entities.employeeContractType" />
    </MenuITem>
    <MenuITem icon="asterisk" to="/employee-contract">
      <Translate contentKey="global.menu.entities.employeeContract" />
    </MenuITem>
    <MenuITem icon="asterisk" to="/service-area-type">
      <Translate contentKey="global.menu.entities.serviceAreaType" />
    </MenuITem>
    <MenuITem icon="asterisk" to="/service-areas">
      <Translate contentKey="global.menu.entities.serviceArea" />
    </MenuITem>
    <MenuITem icon="asterisk" to="/staff">
      <Translate contentKey="global.menu.entities.person" />
    </MenuITem>
    <MenuITem icon="asterisk" to="/roster">
      <Translate contentKey="global.menu.entities.shift" />
    </MenuITem>
    <MenuITem icon="asterisk" to="/identification-type">
      <Translate contentKey="global.menu.entities.identificationType" />
    </MenuITem>
    <MenuITem icon="asterisk" to="/identification">
      <Translate contentKey="global.menu.entities.identification" />
    </MenuITem>
    <MenuITem icon="asterisk" to="/designation-qualification-type">
      <Translate contentKey="global.menu.entities.designationQualificationType" />
    </MenuITem>
    <MenuITem icon="asterisk" to="/designation-qualification">
      <Translate contentKey="global.menu.entities.designationQualification" />
    </MenuITem>
    <MenuITem icon="asterisk" to="/organization-type">
      <Translate contentKey="global.menu.entities.organizationType" />
    </MenuITem>
    <MenuITem icon="asterisk" to="/organization">
      <Translate contentKey="global.menu.entities.organization" />
    </MenuITem>
    <MenuITem icon="asterisk" to="/departments">
      <Translate contentKey="global.menu.entities.department" />
    </MenuITem>
    <MenuITem icon="asterisk" to="/positions">
      <Translate contentKey="global.menu.entities.designation" />
    </MenuITem>
    <MenuITem icon="asterisk" to="/clients">
      <Translate contentKey="global.menu.entities.client" />
    </MenuITem>
    <MenuITem icon="asterisk" to="/subcontractors">
      <Translate contentKey="global.menu.entities.subContractor" />
    </MenuITem>
    {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
  </NavDropdown>
);
