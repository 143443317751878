import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import EducationLevel from './education-level';
import EducationLevelDetail from './education-level-detail';
import EducationLevelUpdate from './education-level-update';
import EducationLevelDeleteDialog from './education-level-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={EducationLevelUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={EducationLevelUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={EducationLevelDetail} />
      <ErrorBoundaryRoute path={match.url} component={EducationLevel} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={EducationLevelDeleteDialog} />
  </>
);

export default Routes;
