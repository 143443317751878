import React, { useState } from 'react';
import { Grid, Paper, Button, Divider, TextField } from '@material-ui/core';

interface PersonMeasurementComponentProps {
  values?: any;
  handleSize?: (size: any) => void;
  showEditPage?: any;
}

const PersonMeasurementComponent: React.FC<PersonMeasurementComponentProps> = props => {
  const [showEditPage, setShowEditPage] = useState(false);
  return (
    <Grid container spacing={1}>
      <Grid item lg={2} md={2} sm={2}>
        <Button
          className="person-measurement-size-button"
          id={props.values === 'S' ? 'person-measurement-checked' : 'person-measurement-unchecked'}
          disabled={props.showEditPage === false ? true : false}
          onClick={() => {
            props.handleSize('S');
          }}
        >
          S
        </Button>
      </Grid>

      <Grid item lg={2} md={2} sm={2}>
        <Button
          className="person-measurement-size-button"
          id={props.values === 'M' ? 'person-measurement-checked' : 'person-measurement-unchecked'}
          disabled={props.showEditPage === false ? true : false}
          onClick={() => {
            props.handleSize('M');
          }}
        >
          M
        </Button>
      </Grid>

      <Grid item lg={2} md={2} sm={2}>
        <Button
          name="L"
          className="person-measurement-size-button"
          id={props.values === 'L' ? 'person-measurement-checked' : 'person-measurement-unchecked'}
          disabled={props.showEditPage === false ? true : false}
          onClick={() => {
            props.handleSize('L');
          }}
        >
          L
        </Button>
      </Grid>

      <Grid item lg={2} md={2} sm={2}>
        <Button
          name="XL"
          className="person-measurement-size-button"
          id={props.values === 'XL' ? 'person-measurement-checked' : 'person-measurement-unchecked'}
          disabled={props.showEditPage === false ? true : false}
          onClick={() => {
            props.handleSize('XL');
          }}
        >
          XL
        </Button>
      </Grid>

      <Grid item lg={2} md={2} sm={2}>
        <Button
          name="2XL"
          className="person-measurement-size-button"
          id={props.values === '2XL' ? 'person-measurement-checked' : 'person-measurement-unchecked'}
          disabled={props.showEditPage === false ? true : false}
          onClick={() => {
            props.handleSize('2XL');
          }}
        >
          2XL
        </Button>
      </Grid>

      <Grid item lg={2} md={2} sm={2}>
        <Button
          name="3XL"
          className="person-measurement-size-button"
          id={props.values === '3XL' ? 'person-measurement-checked' : 'person-measurement-unchecked'}
          disabled={props.showEditPage === false ? true : false}
          onClick={() => {
            props.handleSize('3XL');
          }}
        >
          3XL
        </Button>
      </Grid>
    </Grid>
  );
};

export default PersonMeasurementComponent;
