import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';
import { Storage } from 'react-jhipster';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { BannedDefaultValue, IBannedEmployees } from 'app/shared/model/service-area.model';

export const ACTION_TYPES = {
  FETCH_BANNED_PEOPLE: 'banned-people/FETCH_BANNED_PEOPLE',
  RESET: 'banned-people/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IBannedEmployees>,
  entity: BannedDefaultValue,
  updating: false,
  updateSuccess: false,
};

export type BannedPeopleState = Readonly<typeof initialState>;

export default (state: BannedPeopleState = initialState, action): BannedPeopleState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_BANNED_PEOPLE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };

    case FAILURE(ACTION_TYPES.FETCH_BANNED_PEOPLE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_BANNED_PEOPLE):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/bannedReports';

export const getReport = data => ({
  type: ACTION_TYPES.FETCH_BANNED_PEOPLE,
  payload: axios.get<IBannedEmployees>(`${apiUrl}?filter[person_id]=${data.people}&filter[service_area_id]=${data.location}`),
});

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
