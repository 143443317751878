import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Table } from '@material-ui/core';
import moment from 'moment';
import '../../../utils/GlobalCssRequiredInWebsite.scss';
import IconButton from '@material-ui/core/IconButton';
import { IRootState } from 'app/shared/reducers';
import { getPersonalSettings } from '../../modules/Setting/Personal-Setting.reducer';
import { getOrganizationSettings } from '../../modules/Setting/generalSettings.reducer';
import {
  getSelectedTimePeriodFilter,
  handleFilterCurrentWeekStartDate,
  handleFilterCurrentWeekEndDate,
  handleFilterLastWeekStartDate,
  handleFilterLastWeekEndDate,
  dateFTConverter,
} from '../../../utils/TimeFormatAndTimezone';
import './leave-view.scss';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import ShiftDetails from '../../modules/home/ShiftsDetails/ShiftDetailsComponent';
import LeaveViewComponent from './components/leaveView-leave-types-component';
import PastLeavesComponent from './components/leave-shifts-component';
import { getLeaveById, updateLeave, getAccruedLeaves, getPastLeaves, getLeaveShifts, getLeaveCounter } from './leave.reducer';

export interface ILeaveProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const LeaveView = props => {
  const [values, setValues] = useState([]);

  const { shiftList, match, loading } = props;

  useEffect(() => {
    props.getLeaveById(props.match.params.id);
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const handleClose = () => {
    props.history.push('/Leave');
  };

  useEffect(() => {
    if (props.leave && props.leave.person?.id) {
      // props.getAccruedLeaves(props.leave.person.id);
      props.getLeaveCounter(props.leave.person.id);
      props.getPastLeaves(props.leave.id);
      props.getLeaveShifts(props.leave.id);
    }
  }, [props.leave]);

  const handleLeaveApproval = () => {
    const value = {
      status: 2,
      person_id: props.leave.person?.id,
    };
    props.updateLeave(props.match.params.id, value);
  };

  const handleLeaveDecline = () => {
    const value = {
      status: 3,
      person_id: props.leave.person_id,
    };
    props.updateLeave(props.match.params.id, value);
  };

  const getLeaveStatus = () => {
    if (props.leave && props.leave.status) {
      if (props.leave.status === 1) {
        return 'Awaiting Approval';
      } else if (props.leave.status === 2) {
        return 'Approved';
      } else if (props.leave.status === 3) return 'Declined';
    }
  };

  return (
    <div className="leave-view-main-container">
      <div className="leave-view-outer-div">
        <span style={{ fontSize: '24px', fontWeight: 'bold' }}>Leave Entitlement</span>
        <div className="leaves-counter-component-div">
          <ShiftDetails
            color="#d28ad4"
            count={props.leave_counter && props.leave_counter.annual}
            details="Annual"
            customClass="leave-counter"
            image={null}
            isHome={false}
          />

          <ShiftDetails
            color="#d28ad4"
            count={props.leave_counter && props.leave_counter.sick}
            details="Emergency Sick"
            customClass="leave-counter"
            image={null}
            isHome={false}
          />

          <ShiftDetails color="#d28ad4" count={'0'} details="Other" customClass="leave-counter" image={null} isHome={false} />
        </div>

        <div className="leave-view-name-div">
          <div className="leave-view-person-tab">
            {props.leave && props.leave.person ? props.leave.person.first_name + ' ' + props.leave.person.last_name : null}
          </div>
          {/* <IconButton onClick={() => window.print()} style={{ margin: '1%' }} size="small" data-cy="entityEditButton">
            <img src="content/images/print-icon-button.png" />
          </IconButton> */}

          {props.leave.status === 2 && props.leave.start_date >= moment(new Date()).format('YYYY-MM-DD') ? (
            <IconButton
              className="leaveActionButtons"
              style={{ margin: '1%' }}
              onClick={handleLeaveDecline}
              size="small"
              data-cy="entityDeleteButton"
            >
              <img src="content/images/declined_icon.png" />
            </IconButton>
          ) : null}

          {props.leave.status === 3 && props.leave.start_date >= moment(new Date()).format('YYYY-MM-DD') ? (
            <IconButton
              className="leaveActionButtons"
              style={{ margin: '1%' }}
              onClick={handleLeaveApproval}
              size="small"
              data-cy="entityDeleteButton"
            >
              <img src="content/images/approved_icon.png" />
            </IconButton>
          ) : null}

          {props.leave.status === 1 ? (
            <>
              <IconButton className="leaveActionButtons" onClick={handleLeaveApproval} size="small" data-cy="entityDeleteButton">
                <img src="content/images/approved_icon.png" />
              </IconButton>
              &nbsp;
              <IconButton className="leaveActionButtons" onClick={handleLeaveDecline} size="small" data-cy="entityDeleteButton">
                <img src="content/images/declined_icon.png" />
              </IconButton>
            </>
          ) : (
            <div className={getLeaveStatus()}>{getLeaveStatus()}</div>
          )}
        </div>

        <div className="police-attribute-text">
          Application Date: {props.leave.created_at ? moment(props.leave.created_at).format('DD-MM-YYYY') : ''}{' '}
        </div>

        <div className="leave-view-component">
          <LeaveViewComponent
            style_flag={true}
            leave_tab_heading="Leave Type"
            leave_tab_heading2="Category"
            leave_tab_data={props.leave && props.leave.leave_type && props.leave.leave_type.name}
            leave_tab_data2={props.leave && props.leave.leave_category && props.leave.leave_category.name}
          />
          <br></br>
          <LeaveViewComponent
            leave_tab_heading="Duration"
            leave_tab_heading2="Leave Status"
            leave_tab_data={
              props.leave &&
              moment(props.leave.start_date).format('DD-MM-YYYY') +
                ' - ' +
                (props.leave.end_date
                  ? moment(props.leave.end_date).format('DD-MM-YYYY')
                  : moment(props.leave.start_date).format('DD-MM-YYYY'))
            }
            leave_tab_data2={getLeaveStatus()}
          />
          <br></br>
        </div>

        <div className="leave-view-applier-remarks">
          <div className="leave-applier-remarks-heading">Employee Remarks</div>
          <div>{props.leave && props.leave.remarks ? props.leave.remarks : null}</div>
        </div>

        <div className="leaveView-leaves-table">
          <PastLeavesComponent heading={'Past Leaves'} data={props.past_leave} flag={'leave'} />

          <PastLeavesComponent heading={'Shifts During Leave'} data={props.leave_shifts} flag={'shift'} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ shift, PersonalSettings, organizationSettings, Leave }: IRootState) => ({
  shiftList: shift.entities,
  loading: shift.loading,
  setting: PersonalSettings.storedPersonalSettings,
  settings: organizationSettings.storedOrganizationSettings,
  leave: Leave.leave,
  accrued_leave: Leave.accrued_leaves,
  past_leave: Leave.past_leaves,
  leave_shifts: Leave.leave_shifts,
  updateSuccess: Leave.updateSuccess,
  leave_counter: Leave.leave_counter,
});

const mapDispatchToProps = {
  //   getEntities,
  getPersonalSettings,
  getOrganizationSettings,
  getLeaveById,
  updateLeave,
  getAccruedLeaves,
  getPastLeaves,
  getLeaveShifts,
  getLeaveCounter,

  //   reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(LeaveView);
