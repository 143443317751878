import { IPerson } from 'app/shared/model/person.model';

export interface ITimeclock {
  id?: number;
  serviceAreaId?: number;
  date?: string | null;
  name?: string | null;
  shiftStartTime?: string | null;
  shiftEndTime?: string | null;
  clockTime?: string | null;
  approvedBy?: string | null;
  status?: number | null;
  hours?: string | null;
  checkIn?: string | null;
  checkOut?: string | null;
  breakStart?: string | null;
  breakEnd?: string | null;
  break?: string | null;
  unixTime?: number | null;
  shiftapprovalhistory?: IShiftApprovalHistory[] | null;
  location?: string | null;
  position?: string | null;
  person?: IPerson | null;
  patrol_frequency?: number;
  signed_in_location?: string | null;
  total_break_time?: string | null;
  breaks?: ITimeclockBreaks[] | null;
}

export interface ITimeclockClocks {
  id?: number;
  data?: ITimeclockData;
}

export interface ITimeclockData {
  time?: string | null;
  clockStatus?: string | null;
}

export interface ITimeclockBreaks {
  id?: number;
  break_start_time?: string | null;
  break_end_time?: string | null;
}

export interface ITimeclockBreakData {
  time?: string | null;
  breakStatus?: string | null;
}
export interface ITimeclockValuesData {
  people?: string | null;
  location?: string | null;
  position?: string | null;
  from_date?: string | null;
  to_date?: string | null;
  current_day?: string | null;
  client?: string | null;
}

export interface IShiftApprovalHistory {
  text?: string | null;
  time?: string | null;
}

export const defaultValue: Readonly<ITimeclock> = {};
