import React from 'react';
import { Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import './PeopleEmployment.scss';
import PeopleEmploymentComponent from '../Component/PeopleEmploymentComponent';
import { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import IOSSwitch from '../../RolesAndPermissionsSettings/Components/IOSswitchComponent';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { storePeopleEmploymentSettings } from '../peopleSettings.reducer';
import { getPeopleGeneralSettings } from '../peopleSettings.reducer';

export interface IPeopleSettingsProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const PeopleEmployment = (props: IPeopleSettingsProps) => {
  const [open, setOpen] = React.useState(false);
  const [employmentName, setEmploymentName] = useState('');

  useEffect(() => {
    props.getPeopleGeneralSettings();
  }, []);

  useEffect(() => {}, [props.settings]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const submit = () => {
    const setting = { name: employmentName };
    props.storePeopleEmploymentSettings(setting);
    setOpen(false);
  };

  return (
    <div className="people-employment-setting-external-div">
      <div className="people-employment-heading-div">
        <div className="people-employment-main-heading">
          <Typography variant="h5" className="people-employment-heading-main-title">
            Employment Settings
          </Typography>
        </div>
        <div className="people-employment-heading-message">Edit your information below</div>
      </div>

      <Paper className="people-employment-paper" elevation={2} square>
        <div className="people-employment-external-div">
          <div className="people-employment-button-div">
            <tr>
              <td style={{ width: '84%', textAlign: 'left', fontSize: '18px' }}>Employment types</td>
              <td>
                <button
                  type="button"
                  className="btn btn-success btn-sm"
                  style={{ backgroundColor: '#4720b7', border: '1px solid #4720b7', textAlign: 'left' }}
                  onClick={handleClickOpen}
                >
                  Add Employment Type
                </button>
              </td>
            </tr>
          </div>
          <table className="people-employment-table">
            <tr className="people-employment-table-heading-row">
              <td className="people-employment-table-column-1">Active</td>
              <td className="people-employment-table-column-2">NAME</td>
              <td className="people-employment-table-column-3">Pay Schedule</td>
              <td className="people-employment-table-column-4">Absence Tracking </td>
              <td className="people-employment-table-column-5">People</td>
            </tr>
            {props.settings.employment && props.settings.employment.length > 0 ? (
              props.settings.employment.map((setting, index) => (
                <PeopleEmploymentComponent key={index} msg={setting['name']} msg2="Yes" msg3="247" />
              ))
            ) : (
              <div>no records</div>
            )}

            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true}>
              <DialogTitle className="employment-popup-title">
                {' '}
                <a style={{ color: '#6610f2' }}>Add Employment</a>
              </DialogTitle>
              <DialogContent className="people-employment-popup-content-div">
                <tr>
                  <td style={{ width: '20%', textAlign: 'left' }}>Active</td>
                  <td style={{ width: '80%', textAlign: 'left' }}>
                    <IOSSwitch />
                  </td>
                </tr>

                <tr>
                  <td style={{ textAlign: 'left', width: '20%' }}>Name:*</td>
                  <td style={{ width: '80%' }}>
                    <TextField
                      className="popup-text"
                      autoFocus
                      margin="dense"
                      id="people_employment_name"
                      type="text"
                      variant="outlined"
                      onChange={event => {
                        setEmploymentName(event.target.value);
                      }}
                      fullWidth
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ width: '20%' }}>Description</td>
                  <td style={{ width: '80%' }}>
                    <TextField
                      className="popup-text"
                      autoFocus
                      margin="dense"
                      id="people_employment_description"
                      type="text"
                      variant="outlined"
                      fullWidth
                    />
                  </td>
                </tr>
              </DialogContent>
              <DialogActions>
                <button
                  type="button"
                  className="btn btn-success btn-sm"
                  style={{ marginRight: '75%', backgroundColor: '#4720b7', border: '1px solid #4720b7' }}
                  onClick={submit}
                >
                  {' '}
                  Save{' '}
                </button>
                <button type="button" className="btn btn-outline-secondary btn-sm" style={{ marginRight: '2%' }} onClick={handleClose}>
                  Cancel
                </button>
              </DialogActions>
            </Dialog>
          </table>
        </div>
      </Paper>
    </div>
  );
};

const mapStateToProps = ({ PeopleSetting }) => ({
  settings: PeopleSetting.storedPeopleSettings,
  loading: PeopleSetting.loading,
});

const mapDispatchToProps = {
  storePeopleEmploymentSettings,
  getPeopleGeneralSettings,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PeopleEmployment);
