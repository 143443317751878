import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Table } from '@material-ui/core';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntities } from './employee-contract.reducer';
import { IEmployeeContract } from 'app/shared/model/employee-contract.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IEmployeeContractProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const EmployeeContract = (props: IEmployeeContractProps) => {
  useEffect(() => {
    props.getEntities();
  }, []);

  const handleSyncList = () => {
    props.getEntities();
  };

  const { employeeContractList, match, loading } = props;
  return (
    <div>
      <h2 id="employee-contract-heading" data-cy="EmployeeContractHeading">
        <Translate contentKey="wfmApp.employeeContract.home.title">Employee Contracts</Translate>
        <div className="d-flex justify-content-end">
          <Button className="mr-2" style={{ color: 'black', backgroundColor: '#0dcaf0' }} onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />
            &nbsp;
            <Translate contentKey="wfmApp.employeeContract.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link to={`${match.url}/new`} className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="wfmApp.employeeContract.home.createLabel">Create new Employee Contract</Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {employeeContractList && employeeContractList.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <th>
                  <Translate contentKey="wfmApp.employeeContract.id">ID</Translate>
                </th>
                <th>
                  <Translate contentKey="wfmApp.employeeContract.startDate">Start Date</Translate>
                </th>
                <th>
                  <Translate contentKey="wfmApp.employeeContract.endDate">End Date</Translate>
                </th>
                <th>
                  <Translate contentKey="wfmApp.employeeContract.employeeContractType">Employee Contract Type</Translate>
                </th>
                <th>
                  <Translate contentKey="wfmApp.employeeContract.person">Person</Translate>
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {employeeContractList.map((employeeContract, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>{employeeContract.id}</td>
                  <td>
                    {employeeContract.startDate ? (
                      <TextFormat type="date" value={employeeContract.startDate} format={APP_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>
                    {employeeContract.endDate ? <TextFormat type="date" value={employeeContract.endDate} format={APP_DATE_FORMAT} /> : null}
                  </td>
                  <td>
                    {employeeContract.employeeContractType ? (
                      <Link to={`employee-contract-type/${employeeContract.employeeContractType.id}`}>
                        {employeeContract.employeeContractType.id}
                      </Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {employeeContract.person ? <Link to={`person/${employeeContract.person.id}`}>{employeeContract.person.id}</Link> : ''}
                  </td>
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button
                        component={Link}
                        to={`${match.url}/${employeeContract.id}`}
                        style={{ color: 'black', backgroundColor: 'rgb(13, 202, 240)' }}
                        size="small"
                        data-cy="entityDetailsButton"
                      >
                        <FontAwesomeIcon icon="eye" />
                        &nbsp;
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      &nbsp;
                      <Button
                        component={Link}
                        to={`${match.url}/${employeeContract.id}/edit`}
                        style={{ color: 'white', backgroundColor: '#007bff' }}
                        size="small"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />
                        &nbsp;
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      &nbsp;
                      <Button
                        component={Link}
                        to={`${match.url}/${employeeContract.id}/delete`}
                        style={{ color: 'white', backgroundColor: '#dc3545' }}
                        size="small"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />
                        &nbsp;
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="no-record-warning">
              <Translate contentKey="wfmApp.employeeContract.home.notFound">No Employee Contracts found</Translate>
            </div>
          )
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ employeeContract }: IRootState) => ({
  employeeContractList: employeeContract.entities,
  loading: employeeContract.loading,
});

const mapDispatchToProps = {
  getEntities,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeContract);
