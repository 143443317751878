import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Table } from '@material-ui/core';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { shiftListStartEndDate, calculateShiftListTimeDuration, CurrencyChecker } from '../../../utils/TimeFormatAndTimezone';
import moment from 'moment';
import '../../../utils/GlobalCssRequiredInWebsite.scss';
import IconButton from '@material-ui/core/IconButton';
import { IRootState } from 'app/shared/reducers';
import { getEntities, reset, deleteEntity } from './shift.reducer';
import { getPersonalSettings } from '../../modules/Setting/Personal-Setting.reducer';
import { getOrganizationSettings } from '../../modules/Setting/generalSettings.reducer';
import SearchBar from 'material-ui-search-bar';
import Paper from '@material-ui/core/Paper';
import InfiniteScroll from 'react-infinite-scroll-component';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import CustomToolbarComponent from '../service-area/CustomToolbarComponent';
import MomentUtils from '@date-io/moment';
import {
  getSelectedTimePeriodFilter,
  handleFilterCurrentWeekStartDate,
  handleFilterCurrentWeekEndDate,
  handleFilterLastWeekStartDate,
  handleFilterLastWeekEndDate,
  dateFTConverter,
} from '../../../utils/TimeFormatAndTimezone';
import FormControl from '@material-ui/core/FormControl';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import timePeriod from '../../shared/data/time_period_other.json';
import { mapValues } from 'lodash';
import { DeleteModal } from '../../modules/calender/deleteModal';
import Spinner from 'app/commonUI/spinner/spinner';

export interface IShiftProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const Shift = props => {
  const [timezoneFormat, setTimezoneFormat] = useState(props.setting.timezone);
  const [locale, setLocale] = useState(props.setting.time_format);
  const [dateFormat, setdateFormat] = useState(props.setting.date_format);
  const [currency, setCurrency] = useState(props.settings.currency);
  const [searched, setSearched] = useState<string>('');
  const [records, setRecords] = useState(props.shiftList);
  const [pgNumber, setPageNumber] = useState<any | null>(1);
  const [intial, setInitial] = useState(1);
  const [selectedTimePeriod, setTimePeriod] = useState({ id: 3, text: 'Current week' });
  const [selectedDateFrom, setSelectedDateFrom] = React.useState<any | null>(
    getSelectedTimePeriodFilter(selectedTimePeriod.text, 'setSelectedDateFrom')
  );
  const [selectedDateTo, setSelectedDateTo] = React.useState<any | null>(
    getSelectedTimePeriodFilter(selectedTimePeriod.text, 'setSelectedDateTo')
  );
  const [values, setValues] = useState({
    from_date: moment(handleFilterCurrentWeekStartDate()).format('YYYY-MM-DD'),
    to_date: moment(handleFilterCurrentWeekEndDate()).format('YYYY-MM-DD'),
    pageNumber: 1,
  });
  const [deleteModalSchedule, setDeleteModalSchedule] = useState(false);
  const [shiftId, setShiftId] = useState();

  useEffect(() => {
    props.reset();
  }, []);
  useEffect(() => {
    if (intial > 1) {
      props.getEntities(values);
    } else {
      setInitial(2);
    }
  }, [values.pageNumber]);

  useEffect(() => {
    setRecords(props.shiftList);
  }, [props.shiftList]);

  useEffect(() => {
    props.reset();
    if (values.pageNumber === 1) {
      props.getEntities(values);
    } else {
      setValues(oldValues => ({ ...oldValues, pageNumber: 1 }));
      setPageNumber(1);
    }
  }, [values.from_date, values.to_date]);

  useEffect(() => {
    setCurrency(props.settings.currency);
  }, [props.settings]);

  useEffect(() => {
    setLocale(props.setting.time_format);
    setTimezoneFormat(props.setting.timezone);
    setdateFormat(props.setting.date_format);
  }, [props.setting]);

  const handleSyncList = () => {
    props.reset();
    setSearched('');
    if (values.pageNumber === 1) {
      props.getEntities(values);
    } else {
      setValues(oldValues => ({ ...oldValues, pageNumber: 1 }));
    }
  };

  const requestSearch = (searchedVal: string) => {
    setSearched(searchedVal);
    const value = searchedVal.toLowerCase();
    const filteredRows = props.shiftList.filter(row => {
      return (
        (row.start_time !== null && shiftListStartEndDate(row.start_time, dateFormat).includes(value)) ||
        (row.person && row.person.first_name && row.person.first_name.toLowerCase().includes(value)) ||
        (row.person && row.person.last_name && row.person.last_name.toLowerCase().includes(value)) ||
        (row.person &&
          row.person.first_name &&
          row.person.last_name &&
          `${row.person.first_name} ${row.person.last_name}`.toLowerCase().includes(value)) ||
        (row.service_area && row.service_area.client && row.service_area.client.clientName.toLowerCase().includes(value)) ||
        (row.service_area && row.service_area.name.toLowerCase().includes(value)) ||
        (row.designation && row.designation.name.toLowerCase().includes(value)) ||
        (row.charge_rate && row.charge_rate.toString().includes(value)) ||
        (row.pay_rate && row.pay_rate.toString().includes(value))
      );
    });
    setRecords(filteredRows);
  };

  const cancelSearch = () => {
    setSearched('');
    requestSearch('');
  };

  const setTimePeriodValues = (first, second) => {
    props.reset();
    setValues(oldValues => ({
      ...oldValues,
      ['from_date']: moment(first).format('YYYY-MM-DD'),
      ['to_date']: moment(second).format('YYYY-MM-DD'),
      ['current_day']: '',
    }));
  };

  const handleDateFrom = e => {
    props.reset();
    const d = moment(e).format('YYYY-MM-DD');
    setSelectedDateFrom(e);
    setSelectedDateTo(e);
    if (d !== 'Invalid date') {
      setValues(oldValues => ({ ...oldValues, ['from_date']: d }));
    }
  };

  const handleDateTo = e => {
    props.reset();
    const d = moment(e).format('YYYY-MM-DD');
    setSelectedDateTo(e);
    if (d !== 'Invalid date') {
      setValues(oldValues => ({ ...oldValues, ['to_date']: d }));
    }
  };

  const handleTimePeriodFilter = (_, e) => {
    const today = new Date();
    if (e) {
      setTimePeriod({ id: e.id, text: e.text });

      switch (e.id) {
        case 1:
          {
            const first = new Date(today.getFullYear(), today.getMonth(), today.getDate());
            props.reset();
            setValues(oldValues => ({
              ...oldValues,
              ['from_date']: '',
              ['to_date']: moment(first).format('YYYY-MM-DD'),
            }));
            setSelectedDateFrom(null);
            setSelectedDateTo(null);
          }
          break;
        case 2:
          {
            const first = moment().format('YYYY-MM-DD');
            setValues(oldValues => ({ ...oldValues, ['from_date']: first, ['to_date']: first, ['current_day']: first }));
            setSelectedDateFrom(first);
            setSelectedDateTo(first);
          }
          break;
        case 3:
          {
            const first = handleFilterCurrentWeekStartDate();
            const last = handleFilterCurrentWeekEndDate();
            setTimePeriodValues(first, last);
            setSelectedDateFrom(first);
            setSelectedDateTo(last);
          }
          break;
        case 4:
          {
            const first = handleFilterLastWeekStartDate();
            const last = handleFilterLastWeekEndDate();
            setTimePeriodValues(first, last);
            setSelectedDateFrom(first);
            setSelectedDateTo(last);
          }
          break;
        case 5:
          {
            const first = moment().startOf('month').format('YYYY-MM-DD');
            const last = moment().endOf('month').format('YYYY-MM-DD');
            setTimePeriodValues(first, last);
            setSelectedDateFrom(first);
            setSelectedDateTo(last);
          }
          break;
        case 6:
          {
            const first = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
            const last = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
            setTimePeriodValues(first, last);
            setSelectedDateFrom(first);
            setSelectedDateTo(last);
          }
          break;
        default:
          setValues(oldValues => ({ ...oldValues, ['from_date']: '', ['to_date']: '', ['current_day']: '' }));
      }
    } else {
      setValues(oldValues => ({ ...oldValues, ['from_date']: '', ['to_date']: '', ['current_day']: '' }));
    }
  };

  const handleShiftDelete = id => {
    setDeleteModalSchedule(true);
    setShiftId(id);
  };

  const deleteSchedule = reason => {
    props.deleteEntity(shiftId, reason, values);
  };

  const { shiftList, match, loading } = props;
  return (
    <div className="shiftList_Container">
      {props.loading && <Spinner />}
      <div className="shiftListTopButtonsDiv">
        <div className="shiftListTitleName">
          <Paper elevation={2}>
            <SearchBar
              value={searched}
              className="shiftListSearchBar"
              onChange={searchVal => requestSearch(searchVal)}
              onCancelSearch={() => cancelSearch()}
              placeholder="Search anything here .."
            />
          </Paper>
        </div>

        <div className="shiftListDateFilters">
          <FormControl size="small" variant="outlined" className="shiftListPeriodFilter">
            <Autocomplete
              options={
                timePeriod &&
                timePeriod.map(time => ({
                  id: time.value,
                  text: time.label,
                }))
              }
              noOptionsText={'--Period--'}
              classes={{ listbox: 'autocompletelistbox' }}
              ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
              getOptionLabel={option => option.text || ''}
              onChange={handleTimePeriodFilter}
              value={selectedTimePeriod}
              disabled={loading}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  className="personAutocompleteTextfield shiftListTimeperiodFilter"
                  placeholder="Time period"
                  margin="normal"
                  fullWidth
                />
              )}
            />
          </FormControl>

          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <KeyboardDatePicker
              id="date"
              size="small"
              inputVariant="outlined"
              className="shiftListDateFromToFilter"
              name="from_date"
              label="From"
              ToolbarComponent={prop => (
                <>
                  <CustomToolbarComponent {...prop} settingFormat={props.setting.date_format} />
                </>
              )}
              labelFunc={event => dateFTConverter(event, props.setting.date_format)}
              value={selectedDateFrom ? moment(selectedDateFrom).format('YYYY-MM-DD') : null}
              onChange={handleDateFrom}
              InputLabelProps={{ shrink: true }}
              disabled={loading}
            />{' '}
          </MuiPickersUtilsProvider>

          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <KeyboardDatePicker
              id="date"
              size="small"
              inputVariant="outlined"
              ToolbarComponent={prop => (
                <>
                  <CustomToolbarComponent {...prop} settingFormat={props.setting.date_format} />
                </>
              )}
              labelFunc={event => dateFTConverter(event, props.setting.date_format)}
              value={selectedDateTo ? moment(selectedDateTo).format('YYYY-MM-DD') : null}
              className="shiftListDateFromToFilter"
              name="to_date"
              label="To"
              onChange={handleDateTo}
              InputLabelProps={{ shrink: true }}
              disabled={loading}
            />{' '}
          </MuiPickersUtilsProvider>
        </div>

        <div className="shihtListCreateButtonDiv">
          <Button className="mr-2" id="shihtListRefreshButton" onClick={handleSyncList} disabled={loading}>
            &nbsp;&nbsp;Refresh&nbsp;&nbsp;
          </Button>
          <Link
            to={{
              pathname: '/Calendar',
              state: true,
            }}
            className="shihtListCreateButton"
            data-cy="entityCreateButton"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;Create New
          </Link>
        </div>
      </div>
      <div>
        <div className="globalTableHeaderGradientColor shihtListTableHeaderRow sticky_div">
          <div className="shihtListTableHeaderCell shihtListHeaderShiftDateCell">Shift Date</div>
          <div className="shihtListTableHeaderCell shihtListHeaderAssigneeCell">Assignee</div>
          <div className="shihtListTableHeaderCell shihtListHeaderTimeCell">Time</div>
          <div className="shihtListTableHeaderCell shihtListHeaderHoursCell">Hours</div>
          <div className="shihtListTableHeaderCell shihtListHeaderRoleCell">Position</div>
          <div className="shihtListTableHeaderCell shihtListHeaderClientCell">Client</div>
          <div className="shihtListTableHeaderCell shihtListHeaderLoacationCell">Service Area</div>
          <div className="shihtListTableHeaderCell shihtListHeaderPayRateCell">Pay Rate</div>
          <div className="shihtListTableHeaderCell shihtListHeaderChargeRateCell">Charge Rate</div>
          <div className="shihtListTableHeaderCell shihtListHeaderActionsCell">Actions</div>
        </div>

        <InfiniteScroll
          dataLength={records.length}
          scrollThreshold="200px"
          next={() => {
            setPageNumber(pgNumber + 1);
            setValues(oldValues => ({ ...oldValues, pageNumber: pgNumber + 1 }));
          }}
          hasMore={true}
          loader={''}
        >
          {records && records.length > 0 ? (
            <Table>
              <tbody>
                {records.map((shift, i) => (
                  <tr key={`entity-${i}`} data-cy="entityTable" className="shihtListTableBodyRows">
                    <td className="shihtListTableBodyCell shihtListTableBodyShiftDatecell">
                      {shift.start_time && dateFormat && shiftListStartEndDate(shift.start_time, dateFormat)}
                    </td>
                    <td className="shihtListTableBodyCell shihtListTableBodyAssigneecell">
                      {shift.person && shift.person.first_name} {shift.person && shift.person.last_name}
                    </td>
                    <td className="shihtListTableBodyCell shihtListTableBodyTimecell">
                      {moment(shift.start_time).format(`${locale === 1 ? 'HH:mm' : 'hh:mm A'}`)
                        ? moment(shift.start_time).format(`${locale === 1 ? 'HH:mm' : 'hh:mm A'}`)
                        : null}
                      {' - '}
                      {moment(shift.end_time).format(`${locale === 1 ? 'HH:mm' : 'hh:mm A'}`)
                        ? moment(shift.end_time).format(`${locale === 1 ? 'HH:mm' : 'hh:mm A'}`)
                        : null}
                    </td>
                    <td className="shihtListTableBodyCell shihtListTableBodyHourscell">
                      {calculateShiftListTimeDuration(shift.start_time, shift.end_time)}
                    </td>
                    <td className="shihtListTableBodyCell shihtListTableBodyRolecell">{shift.designation && shift.designation.name}</td>
                    <td className="shihtListTableBodyCell shihtListTableBodyClientcell">
                      {shift.service_area && shift.service_area.client && shift.service_area.client.clientName}
                    </td>
                    <td className="shihtListTableBodyCell shihtListTableBodyLocationcell">
                      {shift.service_area && shift.service_area.name}
                    </td>
                    <td className="shihtListTableBodyCell shihtListTableBodyPayRatecell">
                      {shift.pay_rate === null ? null : CurrencyChecker(currency)}
                      {shift.pay_rate}
                    </td>
                    <td className="shihtListTableBodyCell shihtListTableBodyChargeRatecell">
                      {shift.charge_rate === null ? null : CurrencyChecker(currency)}
                      {shift.charge_rate}
                    </td>
                    <td className="shihtListTableBodyCell shihtListTableBodyActionscell">
                      <div className="btn-group shihtListActionCellButtons">
                        <IconButton
                          component={Link}
                          className="shihtListButton"
                          to={{
                            pathname: '/Calendar',
                            state: shift.id,
                            id: shift.id,
                          }}
                          style={{ margin: '1%' }}
                          size="small"
                          data-cy="entityDetailsButton"
                        >
                          <img src="content/images/View1.png" />
                        </IconButton>
                        &nbsp;
                        <IconButton
                          className="shihtListButton"
                          onClick={() => handleShiftDelete(shift.id)}
                          style={{ margin: '1%' }}
                          size="small"
                          data-cy="entityDeleteButton"
                        >
                          <img src="content/images/Trash1.png" />
                        </IconButton>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            !loading && (
              <div className="no-record-warning">
                <Translate contentKey="wfmApp.shift.home.notFound">No Shifts found</Translate>
              </div>
            )
          )}
        </InfiniteScroll>
      </div>
      <DeleteModal
        showDeleteModal={deleteModalSchedule}
        setShowDeleteModal={setDeleteModalSchedule}
        deleteSchedule={deleteSchedule}
        deleteSeries={deleteSchedule}
        isRepeatShift={false}
      />
    </div>
  );
};

const mapStateToProps = ({ shift, PersonalSettings, organizationSettings }: IRootState) => ({
  shiftList: shift.entities,
  loading: shift.loading,
  setting: PersonalSettings.storedPersonalSettings,
  settings: organizationSettings.storedOrganizationSettings,
});

const mapDispatchToProps = {
  getEntities,
  getPersonalSettings,
  getOrganizationSettings,
  reset,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Shift);
