import React from 'react';
import PermissionsRowComponent from './PermissionsRowComponent';

export default function PermissionsScheduleComponent(props) {
  return (
    <React.Fragment>
      <PermissionsRowComponent
        msg="Can manage schedule of preferred and assigned people for locations where he/she is appointed as supervisor?"
        ls={props.supervisorPerm && props.supervisorPerm.manage_schedule}
        ds={props.userPerm && props.userPerm.manage_schedule}
        ma={props.mngrPerm && props.mngrPerm.manage_schedule}
        sa={props.orgAdminPerm && props.orgAdminPerm.manage_schedule}
        name="manage_schedule"
        handler={props.handleChange}
      />
      <PermissionsRowComponent
        msg="Can manage schedule of people in departments assigned to him/her?"
        ls={props.supervisorPerm && props.supervisorPerm.schedule_of_people_in_departments}
        ds={props.userPerm && props.userPerm.schedule_of_people_in_departments}
        ma={props.mngrPerm && props.mngrPerm.schedule_of_people_in_departments}
        sa={props.orgAdminPerm && props.orgAdminPerm.schedule_of_people_in_departments}
        name="schedule_of_people_in_departments"
        handler={props.handleChange}
      />
    </React.Fragment>
  );
}
