import axios from 'axios';
import { Storage } from 'react-jhipster';
import jwtDecode from 'jwt-decode';

const TIMEOUT = 1 * 60 * 1000;
axios.defaults.timeout = TIMEOUT;
axios.defaults.baseURL = 'https://dev.smartworkforce.co.uk/';

const refreshTokenIfExpired = async token => {
  const newToken = await axios
    .create({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .get('api/refreshAccessToken');
  if (newToken) {
    Storage.session.set('jhi-authenticationToken', newToken.data.id_token);
    return newToken.data.id_token;
  }
};

const setupAxiosInterceptors = onUnauthenticated => {
  const onRequestSuccess = async config => {
    let token = Storage.local.get('jhi-authenticationToken') || Storage.session.get('jhi-authenticationToken');
    const org = Storage.local.get('organizationSelected');
    if (token) {
      if (jwtDecode(token)['exp'] < Math.floor(Date.now() / 1000) + 300) {
        token = await refreshTokenIfExpired(token);
      }
      config.headers.Authorization = `Bearer ${token}`;
      if (org) {
        config.headers.Organization = atob(Storage.local.get('organizationSelected'));
      }
    }
    return config;
  };
  const onResponseSuccess = response => response;
  const onResponseError = err => {
    const status = err.status || (err.response ? err.response.status : 0);
    if (status === 401) {
      onUnauthenticated();
    }
    return Promise.reject(err);
  };
  axios.interceptors.request.use(onRequestSuccess);
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
  axios.defaults.headers['Access-Control-Allow-Origin'] = '*';
};

export default setupAxiosInterceptors;
