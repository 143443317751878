export interface IApplicants {
  id?: number;
  first_name?: string | null;
  last_name?: string | null;
  status?: string | null;
  email?: string | null;
  recieved_at?: string | null;
}

export const defaultValue: Readonly<IApplicants> = {};
