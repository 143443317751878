import React, { useEffect, useState } from 'react';
import PersonDrawerComponent from '../component/PersonDrawer';
import ProfileDetails from '../person-view/ProfileDetails';
import PersonServiceAreas from './person-serviceAreas';
import PersonCertificationsAndOthers from './person-certifications-and-others';
import PersonBackgroundEducationComponent from './person-background-and-education';
import PersonRateCard from './person-rate-card';
import PersonHealth from './person-health';
import PersonDocuments from './person-documents';
import PersonSetting from './person-settings';
import Measurements from './measurements';
import PersonCompanyInfo from './person-company-info';
import '../person.scss';

const PersonView = props => {
  const [page, setPage] = useState(1);
  const [profileData, setProfileData] = useState([]);
  const [isProfileFilled, setIsProfileFilled] = useState(false);
  const [checkValidation, setCheckValidation] = useState(false);
  return (
    <div className="subcontractor-view-deisgns-main-container">
      <div className="settings-outer-div">
        <div className="person-settings-drawer-container">
          <PersonDrawerComponent
            {...props}
            x={setProfileData}
            profileData={profileData}
            setPage={setPage}
            page={page}
            setIsProfileFilled={setIsProfileFilled}
            isProfileFilled={isProfileFilled}
            setCheckValidation={setCheckValidation}
            checkValidation={checkValidation}
          />
        </div>
        <div className="person-settings-container-body">
          {page === 1 && (
            <ProfileDetails
              {...props}
              setIsProfileFilled={setIsProfileFilled}
              isProfileFilled={isProfileFilled}
              profileData={profileData}
              setProfileData={setProfileData}
              setPage={setPage}
              setCheckValidation={setCheckValidation}
              checkValidation={checkValidation}
            />
          )}
          {page === 2 && <PersonServiceAreas {...props} />}
          {page === 3 && <PersonCertificationsAndOthers {...props} />}
          {page === 4 && <PersonBackgroundEducationComponent {...props} />}
          {page === 5 && <PersonRateCard {...props} />}
          {page === 6 && <PersonHealth {...props} />}
          {page === 7 && <PersonDocuments {...props} />}
          {page === 8 && <Measurements {...props} />}
          {page === 9 && <PersonSetting {...props} />}
          {page === 10 && <PersonCompanyInfo {...props} profileData={profileData} setProfileData={setProfileData} setPage={setPage} />}
        </div>
      </div>
    </div>
  );
};

export default PersonView;
