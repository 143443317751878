import React, { useState, useEffect } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import { Button, List, ListItem, ListItemText, Collapse, Checkbox } from '@material-ui/core';

export const LimitedScreeningComponent = props => {
  const { person, completeLimitedProcess } = props;
  return (
    <div className="vetting-documents-limited-history-table-rows-div">
      <Table style={{ borderCollapse: 'separate', borderSpacing: '0px 15px' }}>
        {person.emp_verification_sent && (
          <TableRow>
            <React.Fragment>
              <TableCell scope="left" className="documents-about-you-table-cells-dark">
                Employment verification sent
              </TableCell>
              <TableCell align="left" className="documents-about-you-table-cells-light">
                true
              </TableCell>
            </React.Fragment>
            <React.Fragment>
              <TableCell align="left" className="documents-about-you-table-cells-dark">
                Employment verification sent at
              </TableCell>
              <TableCell align="left" className="documents-about-you-table-cells-light">
                {person.emp_verification_sent_at}
              </TableCell>
            </React.Fragment>
          </TableRow>
        )}
        {person.emp_verification_recieved && (
          <TableRow>
            <React.Fragment>
              <TableCell scope="left" className="documents-about-you-table-cells-dark">
                Employment verification recieved
              </TableCell>
              <TableCell align="left" className="documents-about-you-table-cells-light">
                true
              </TableCell>
            </React.Fragment>
            {person.emp_verification_recieved_at && (
              <React.Fragment>
                <TableCell align="left" className="documents-about-you-table-cells-dark">
                  Employment verification recieved at
                </TableCell>
                <TableCell align="left" className="documents-about-you-table-cells-light">
                  {person.emp_verification_recieved_at}
                </TableCell>
              </React.Fragment>
            )}
          </TableRow>
        )}
        <TableRow>
          <TableCell scope="left" className="documents-about-you-table-cells-dark">
            Screening Administrator
          </TableCell>
          <TableCell align="left" className="documents-about-you-table-cells-light">
            Usman Zia
          </TableCell>
        </TableRow>
      </Table>
      {!person.emp_verification_recieved && (
        <div style={{ marginTop: '10px', width: '95%' }}>
          <b> NOTE: Waiting for employment verification response to complete limited screening process </b>
        </div>
      )}

      {person.status === 'FULL_PROCESS'
        ? ''
        : person.emp_verification_recieved && (
            <div className="vetting-documents-limited-history-action-div">
              <div className="vetting-documents-limited-history-completed-text"> Limited Screening process completed </div>
              <div className="vetting-documents-limited-history-action-buttons">
                <Checkbox /> Allow conditional employment
                <Button
                  onClick={() => {
                    completeLimitedProcess(person.id, 'FULL_PROCESS');
                  }}
                  style={{ marginLeft: '20px', backgroundColor: 'purple', color: 'white', borderRadius: 'unset' }}
                >
                  {' '}
                  Proceed to full screening process
                </Button>
              </div>
            </div>
          )}
    </div>
  );
};

export default LimitedScreeningComponent;
