/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import { IRootState } from 'app/shared/reducers';
import Divider from '@material-ui/core/Divider';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import '../servicearea.scss';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker, TimePicker } from '@material-ui/pickers';
import CustomToolbarComponent from '../CustomToolbarComponent';
import DateFnsUtils from '@date-io/date-fns';
import { getDateFormat } from '../../../shared/util/calender-utils';
import moment from 'moment';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import Checkbox from '@material-ui/core/Checkbox';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
export interface IBannedComponent {
  showBannedModal: boolean;
  setShowBannedModal: (value: boolean) => void;
  BannedModalMode: any;
  setBannedModalMode: (value: any) => void;
  prop: any;
  setting: any;
}

export const BannedComponent = (props: IBannedComponent) => {
  const { showBannedModal, setShowBannedModal, BannedModalMode, setBannedModalMode, prop, setting } = props;

  const [activeWarning, setActiveWarning] = useState(false);

  const [fieldError, setFieldError] = useState({
    employeeField_error: false,
    DescriptionField_error: false,
    startDateField_error: false,
    endDateField_error: false,
  });
  const [bannedModal, setBannedModal] = useState({
    selectEmployee: null,
    banningReason: '',
    currentServiceArea: false,
    allServiceAreas: false,
    start_date: null,
    end_date: null,
  });

  useEffect(() => {
    const activePerson = prop?.serviceAreaEntity.employeeNames?.filter(rec => rec.id === bannedModal?.selectEmployee?.id);
    if (bannedModal.currentServiceArea === true) {
      if (activePerson.length !== 0) {
        setActiveWarning(true);
      } else {
        setActiveWarning(false);
      }
    } else if (bannedModal.allServiceAreas && activePerson.length !== 0) {
      setActiveWarning(true);
    } else {
      setActiveWarning(false);
    }
  }, [bannedModal]);

  useEffect(() => {
    if (BannedModalMode.Mode === 'edit' || BannedModalMode.Mode === 'delete') {
      setValuesInBannedModal();
    }
  }, [BannedModalMode]);

  const setValuesInBannedModal = () => {
    let selectedBannedEmployeeRecord = prop?.bannedEmployees?.filter(rec => rec.id === BannedModalMode.banned_id);
    if (selectedBannedEmployeeRecord) {
      selectedBannedEmployeeRecord = {
        selectEmployee: {
          id: selectedBannedEmployeeRecord[0].person.id,
          text: selectedBannedEmployeeRecord[0].person.first_name + ' ' + selectedBannedEmployeeRecord[0].person.last_name,
        },
        banningReason: selectedBannedEmployeeRecord[0].description,
        currentServiceArea: true,
        allServiceAreas: false,
        start_date: selectedBannedEmployeeRecord[0].start_date,
        end_date: selectedBannedEmployeeRecord[0].end_date,
      };
    }
    setBannedModal(selectedBannedEmployeeRecord);
  };

  const handleClose = () => {
    setShowBannedModal(false);
    setFieldError(oldValues => ({
      ...oldValues,
      employeeField_error: false,
      DescriptionField_error: false,
      startDateField_error: false,
      endDateField_error: false,
    }));
    reset();
  };

  const handleEmployeeValues = (_, e) => {
    setBannedModal(oldValues => ({ ...oldValues, selectEmployee: { id: e.id, text: e.text } }));
    if (bannedModal.selectEmployee !== null || bannedModal.selectEmployee !== undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['employeeField_error']: false }));
    }
  };

  const handleDescriptionChange = event => {
    const alphabetWithSpaceRegex = /^[A-Za-z\s]+$/;
    if (alphabetWithSpaceRegex.test(event.target.value) || event.target.value === '') {
      setBannedModal(oldValues => ({ ...oldValues, banningReason: event.target.value }));
    }
    if (bannedModal.banningReason !== '' || bannedModal.banningReason !== undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['DescriptionField_error']: false }));
    }
  };

  const handleCurrentServiceArea = () => {
    setBannedModal(oldValues => ({ ...oldValues, currentServiceArea: true }));
    if (bannedModal.allServiceAreas === true) {
      setBannedModal(oldValues => ({ ...oldValues, allServiceAreas: false }));
    }
  };

  const handleAllServiceAreas = () => {
    setBannedModal(oldValues => ({ ...oldValues, allServiceAreas: true }));
    if (bannedModal.currentServiceArea === true) {
      setBannedModal(oldValues => ({ ...oldValues, currentServiceArea: false }));
    }
  };

  const handleStartDate = event => {
    setBannedModal(oldValues => ({ ...oldValues, start_date: event }));
    if (bannedModal.start_date !== null || bannedModal.start_date !== undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['startDateField_error']: false }));
    }
  };

  const handleEndDate = event => {
    setBannedModal(oldValues => ({ ...oldValues, end_date: event }));
    if (bannedModal.end_date !== null || bannedModal.end_date !== undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['endDateField_error']: false }));
    }
  };

  const reset = () => {
    setBannedModal(oldValues => ({
      ...oldValues,
      selectEmployee: null,
      banningReason: '',
      currentServiceArea: false,
      allServiceAreas: false,
      start_date: null,
      end_date: null,
    }));
  };

  const checkValidation = () => {
    let result;
    if (BannedModalMode.Mode === 'new' && bannedModal.selectEmployee === null) {
      setFieldError(oldValues => ({ ...oldValues, ['employeeField_error']: true }));
      result = true;
    }
    if (BannedModalMode.Mode === 'new' && bannedModal.banningReason === '') {
      setFieldError(oldValues => ({ ...oldValues, ['DescriptionField_error']: true }));
      result = true;
    }
    if (BannedModalMode.Mode === 'new' && bannedModal.start_date === null) {
      setFieldError(oldValues => ({ ...oldValues, ['startDateField_error']: true }));
      result = true;
    }
    if (BannedModalMode.Mode === 'delete' && bannedModal.end_date === null) {
      setFieldError(oldValues => ({ ...oldValues, ['endDateField_error']: true }));
      result = true;
    }
    return result;
  };

  const handleBanEmployee = () => {
    if (!checkValidation()) {
      const empId = bannedModal.selectEmployee && bannedModal.selectEmployee.id;
      const employee = prop.personsList.find(x => x.id === empId);
      const bannedData = {
        person: employee,
        service_area: bannedModal.currentServiceArea === true ? prop.serviceAreaEntity : null,
        description: bannedModal.banningReason,
        start_date: moment(bannedModal.start_date).format('YYYY-MM-DD'),
        end_date: moment(bannedModal.end_date).format('YYYY-MM-DD'),
      };
      if (bannedModal.currentServiceArea === true) {
        prop.bannedEntity('currentServiceArea', bannedData, prop.serviceAreaEntity, 'service_area');
      } else {
        prop.bannedEntity('allServiceAreas', bannedData, prop.serviceAreaEntity, 'service_area');
      }
      reset();
      setShowBannedModal(false);
    }
  };

  const handleUpdateBanEmployee = () => {
    if (!checkValidation()) {
      const empId = bannedModal.selectEmployee && bannedModal.selectEmployee.id;
      const employee = prop.personsList.find(x => x.id === empId);
      const bannedData = {
        person: employee,
        service_area: bannedModal.currentServiceArea === true ? prop.serviceAreaEntity : null,
        description: bannedModal.banningReason,
        start_date: moment(bannedModal.start_date).format('YYYY-MM-DD'),
        end_date: moment(bannedModal.end_date).format('YYYY-MM-DD'),
        allServiceAreas: bannedModal.allServiceAreas === false ? false : true,
      };
      prop.updateBannedPeople(bannedData, BannedModalMode.banned_id, prop.serviceAreaEntity, 'service_area');
      reset();
      setShowBannedModal(false);
    }
  };

  return (
    <>
      <Modal open={showBannedModal} onClose={handleClose}>
        <div className="bannedModaPaper">
          <h2 className="bannedModalTitle">{BannedModalMode.Mode === 'delete' ? 'Unban Employee' : 'Ban Employee'}</h2>
          <p style={{ marginLeft: '4%' }}>
            Are you sure you want to {BannedModalMode.Mode === 'delete' ? 'unban' : 'ban'} this employee(s)?
          </p>

          <Divider className="bannedModalDivider" />

          <Grid item className="bannedModalSelectBox" xs={12} lg={12}>
            <FormControl variant="outlined" size="small" className="bannedModalFields">
              <Autocomplete
                options={
                  prop.personsList &&
                  prop.personsList.map(person => ({
                    id: person.id,
                    text: person.first_name + ' ' + person.last_name,
                    name: 'employee',
                  }))
                }
                noOptionsText={'No Records'}
                classes={{ listbox: 'autocompletelistbox' }}
                ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                getOptionLabel={option => option.text || ''}
                onChange={handleEmployeeValues}
                value={bannedModal.selectEmployee}
                disabled={prop.loading}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    className="personAutocompleteTextfield bannedSelectBox"
                    placeholder="Select employee(s)"
                    margin="normal"
                    fullWidth
                    error={fieldError['employeeField_error']}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item className="bannedModalDatesBox" lg={12} xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                autoOk
                variant="inline"
                size="small"
                inputVariant="outlined"
                label="From"
                format={setting?.date_format ? getDateFormat(setting.date_format) : 'MM/dd/yyyy'}
                value={bannedModal.start_date}
                className="bannedModalStartDate"
                ToolbarComponent={prop => (
                  <>
                    <CustomToolbarComponent {...prop}   settingFormat={setting?.date_format ?? 'MM/dd/yyyy'} />
                  </>
                )}
                placeholder={setting?.date_format ? getDateFormat(setting.date_format) : 'MM/dd/yyyy'}
                InputAdornmentProps={{ position: 'end' }}
                onChange={date => handleStartDate(date)}
                error={fieldError['startDateField_error']}
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                autoOk
                variant="inline"
                size="small"
                inputVariant="outlined"
                label="Till"
                format={setting?.date_format ? getDateFormat(setting.date_format) : 'MM/dd/yyyy'}
                value={bannedModal.end_date}
                className="bannedModalEndDate"
                ToolbarComponent={prop => (
                  <>
                    <CustomToolbarComponent {...prop} settingFormat={setting?.date_format ?? 'MM/dd/yyyy'} />
                  </>
                )}
                placeholder={setting?.date_format ? getDateFormat(setting.date_format) : 'MM/dd/yyyy'}
                InputAdornmentProps={{ position: 'end' }}
                onChange={date => handleEndDate(date)}
                minDate={moment(bannedModal.start_date)}
                error={fieldError['endDateField_error']}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <FormControlLabel
            style={{ marginLeft: '5px' }}
            control={
              <Checkbox
                checked={bannedModal.end_date === null}
                onChange={event => {
                  if (event.target.checked) {
                    setBannedModal(oldValues => ({ ...oldValues, end_date: null }));
                  }
                }}
                color="primary"
              />
            }
            label="Until Further Notice"
          />

          <FormControl className="bannedModalFields">
            <FormGroup row={true}>
              <Grid item className="bannedModalSelectBox" xs={12} lg={12}>
                <TextField
                  id="reason-for-banning"
                  label="Reason for unauthorising"
                  className="bannedModalNote"
                  variant="outlined"
                  value={bannedModal.banningReason}
                  onChange={handleDescriptionChange}
                  multiline={true}
                  minRows={3}
                  InputLabelProps={{
                    shrink: bannedModal.banningReason === '' ? false : true,
                  }}
                  error={fieldError['DescriptionField_error']}
                />
              </Grid>
            </FormGroup>
          </FormControl>

          <Grid item className="bannedModalSelectBox" xs={12} lg={12}>
            <FormControl variant="outlined" size="small" className="bannedModalFields" style={{ marginTop: '3%' }}>
              <FormControlLabel
                className={
                  (BannedModalMode.Mode === 'delete' ? 'unbannedModalRadioButtion' : 'bannedModalRadioButtion') +
                  ' ' +
                  'bannedFirstRadioButtion' +
                  ' ' +
                  (bannedModal.currentServiceArea === true ? 'bannedModalFirstRadioButtion' : 'FormControlLabelWhite')
                }
                label={BannedModalMode.Mode === 'delete' ? 'Unban for Current Service Area' : 'Ban for Current Service Area'}
                labelPlacement="start"
                control={
                  <Checkbox
                    icon={
                      <CircleUnchecked
                        className={bannedModal.currentServiceArea === false ? 'CircleUncheckedInBannedModal' : 'checkBoxWhite'}
                      />
                    }
                    checkedIcon={
                      <CircleCheckedFilled
                        className={bannedModal.currentServiceArea === true ? 'checkCircleFilledInBannedModal' : 'checkBoxWhite'}
                      />
                    }
                    checked={bannedModal.currentServiceArea}
                    onChange={handleCurrentServiceArea}
                    className="serviceAreabannedModalFirstRadioButtion"
                  />
                }
              />
              <FormControlLabel
                className={
                  (BannedModalMode.Mode === 'delete' ? 'unbannedModalRadioButtion' : 'bannedModalRadioButtion') +
                  ' ' +
                  'banned2ndRdioButtion' +
                  ' ' +
                  (bannedModal.allServiceAreas === true ? 'bannedModalFirstRadioButtion' : 'FormControlLabelWhite')
                }
                label={BannedModalMode.Mode === 'delete' ? 'Unban for All Service Areas' : 'Ban for All Service Areas'}
                labelPlacement="start"
                control={
                  <Checkbox
                    icon={
                      <CircleUnchecked
                        className={bannedModal.allServiceAreas === false ? 'CircleUncheckedInBannedModal' : 'checkBoxWhite'}
                      />
                    }
                    checkedIcon={
                      <CircleCheckedFilled
                        className={bannedModal.allServiceAreas === true ? 'checkCircleFilledInBannedModal' : 'checkBoxWhite'}
                      />
                    }
                    checked={bannedModal.allServiceAreas}
                    onChange={handleAllServiceAreas}
                  />
                }
              />
            </FormControl>
          </Grid>

          {activeWarning === true ? (
            <div style={{ paddingLeft: '12px', marginTop: '10px' }}>
              <div style={{ color: '#856404', fontSize: '12px' }}>Attention:</div>
              <div style={{ color: '#856404', fontSize: '10px' }}>
                This person have future shifts assigned to them for the selected service area.
              </div>
            </div>
          ) : null}

          <Divider className="bannedModalDivider" />

          <FormControl className="bannedModalFields">
            <FormGroup row={true}>
              <Grid item xs={3} lg={4}>
                <Button variant="contained" className="bannedModalCancelButton" onClick={handleClose}>
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={3} lg={4}>
                <Button
                  variant="contained"
                  className="bannedModalSaveButton"
                  onClick={() => {
                    if (BannedModalMode.Mode === 'edit' || BannedModalMode.Mode === 'delete') {
                      handleUpdateBanEmployee();
                    } else {
                      handleBanEmployee();
                    }
                  }}
                >
                  Confirm
                </Button>
              </Grid>
            </FormGroup>
          </FormControl>
        </div>
      </Modal>
    </>
  );
};
