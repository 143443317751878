import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Grid, FormControl, TextField, MenuItem } from '@material-ui/core';
import { Row, Col, Label } from 'reactstrap';
import { AvFeedback, form, AvField } from 'availity-reactstrap-validation';
import { Translate, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import './countryadminunittype.scss';
import { getEntities as getCountryAdminUnitTypes } from 'app/entities/country-admin-unit-type/country-admin-unit-type.reducer';
import { ICountry } from 'app/shared/model/country.model';
import { getEntities as getCountries } from 'app/entities/country/country.reducer';
import { getEntity, updateEntity, createEntity, reset } from './country-admin-unit-type.reducer';
import { ICountryAdminUnitType } from 'app/shared/model/country-admin-unit-type.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface ICountryAdminUnitTypeUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const CountryAdminUnitTypeUpdate = (props: ICountryAdminUnitTypeUpdateProps) => {
  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const { countryAdminUnitTypeEntity, countryAdminUnitTypes, countries, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/country-admin-unit-type');
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }

    props.getCountryAdminUnitTypes();
    props.getCountries();
  }, []);

  useEffect(() => {}, [countryAdminUnitTypeEntity]);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = event => {
    event.preventDefault();

    const entity = {
      id: event.target.id.value,
      name: event.target.name.value,
      parent: countryAdminUnitTypes.find(it => it.id.toString() === event.target.parent.value.parentId.toString()),
      country: countries.find(it => it.id.toString() === event.target.country.value.countryId.toString()),
    };

    if (isNew) {
      props.createEntity(entity);
    } else {
      props.updateEntity(entity);
    }
  };

  return (
    <div className="countryadminunittype-full-container">
      <Grid item md={12} container justify="center">
        <Grid item md={2}></Grid>
        <Grid item md={8}>
          <h2 id="wfmApp.countryAdminUnitType.home.createOrEditLabel" data-cy="CountryAdminUnitTypeCreateUpdateHeading">
            <Translate contentKey="wfmApp.countryAdminUnitType.home.createOrEditLabel">Create or edit a CountryAdminUnitType</Translate>
          </h2>
        </Grid>
      </Grid>
      <Grid item md={12} container justify="center" spacing={1}>
        <Grid item md={2}></Grid>
        <Grid item md={8} spacing={2}>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <form onSubmit={saveEntity} className="countryadminunittype-form">
              {!isNew ? (
                <Grid item md={12} container spacing={1}>
                  <Grid item md={12} spacing={2}>
                    <FormControl className="countryadminunittype-fields">
                      <TextField
                        id="country-admin-unit-type-id"
                        variant="outlined"
                        className="countryadminunittypeid"
                        name="id"
                        required
                        inputProps={{ readOnly: true }}
                        label={<Translate contentKey="global.field.id">ID</Translate>}
                        value={countryAdminUnitTypeEntity.id}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              ) : null}
              <Grid item md={12} container spacing={1}>
                <Grid item md={12} spacing={2}>
                  <FormControl className="countryadminunittype-fields">
                    <TextField
                      id="country-admin-unit-type-name"
                      data-cy="name"
                      variant="outlined"
                      name="name"
                      className="countryadminunittypename"
                      label={<Translate contentKey="wfmApp.countryAdminUnitType.name">Name</Translate>}
                      defaultValue={countryAdminUnitTypeEntity.name}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item md={12} container spacing={1}>
                <Grid item md={12} spacing={2}>
                  <FormControl className="countryadminunittype-fields">
                    <TextField
                      id="country-admin-unit-type-parent"
                      data-cy="parent"
                      select
                      className="countryadminunittypeparent"
                      name="parentId"
                      variant="outlined"
                      label={<Translate contentKey="wfmApp.countryAdminUnitType.parent">Parent</Translate>}
                      defaultValue={countryAdminUnitTypeEntity.parent}
                    >
                      <MenuItem value="" key="0" />
                      {countryAdminUnitTypes
                        ? countryAdminUnitTypes.map(otherEntity => (
                            <MenuItem value={otherEntity.id} key={otherEntity.id}>
                              {otherEntity.name}
                            </MenuItem>
                          ))
                        : null}
                    </TextField>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item md={12} container spacing={1}>
                <Grid item md={12} spacing={2}>
                  <FormControl className="countryadminunittype-fields">
                    <TextField
                      id="country-admin-unit-type-country"
                      data-cy="country"
                      select
                      className="countryadminunittypecountry"
                      name="countryId"
                      variant="outlined"
                      label={<Translate contentKey="wfmApp.countryAdminUnitType.country">Country</Translate>}
                      defaultValue={countryAdminUnitTypeEntity.country}
                    >
                      <MenuItem value="" key="0" />
                      {countries
                        ? countries.map(otherEntity => (
                            <MenuItem value={otherEntity.id} key={otherEntity.id}>
                              {otherEntity.name}
                            </MenuItem>
                          ))
                        : null}
                    </TextField>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item md={12} container spacing={1}>
                <Grid item md={12} spacing={2}>
                  <FormControl style={{ display: 'inline-block' }} className="countryadminunittype-fields">
                    <Button
                      component={Link}
                      id="cancel-save"
                      to="/country-admin-unit-type"
                      replace
                      style={{ color: 'black', backgroundColor: 'rgb(13, 202, 240)' }}
                    >
                      <FontAwesomeIcon icon="arrow-left" />
                      &nbsp;
                      <span className="d-none d-md-inline">
                        <Translate contentKey="entity.action.back">Back</Translate>
                      </span>
                    </Button>
                    &nbsp;
                    <Button
                      style={{ color: 'white', backgroundColor: 'blue' }}
                      id="save-entity"
                      data-cy="entityCreateSaveButton"
                      type="submit"
                      disabled={updating}
                    >
                      <FontAwesomeIcon icon="save" />
                      &nbsp;
                      <Translate contentKey="entity.action.save">Save</Translate>
                    </Button>
                  </FormControl>
                </Grid>
              </Grid>
            </form>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  countryAdminUnitTypes: storeState.countryAdminUnitType.entities,
  countries: storeState.country.entities,
  countryAdminUnitTypeEntity: storeState.countryAdminUnitType.entity,
  loading: storeState.countryAdminUnitType.loading,
  updating: storeState.countryAdminUnitType.updating,
  updateSuccess: storeState.countryAdminUnitType.updateSuccess,
});

const mapDispatchToProps = {
  getCountryAdminUnitTypes,
  getCountries,
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CountryAdminUnitTypeUpdate);
