import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import EducationalInstituteType from './educational-institute-type';
import EducationalInstituteTypeDetail from './educational-institute-type-detail';
import EducationalInstituteTypeUpdate from './educational-institute-type-update';
import EducationalInstituteTypeDeleteDialog from './educational-institute-type-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={EducationalInstituteTypeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={EducationalInstituteTypeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={EducationalInstituteTypeDetail} />
      <ErrorBoundaryRoute path={match.url} component={EducationalInstituteType} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={EducationalInstituteTypeDeleteDialog} />
  </>
);

export default Routes;
