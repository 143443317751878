import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import {} from 'react-jhipster';
import { IRootState } from 'app/shared/reducers';
import { IServiceArea } from 'app/shared/model/service-area.model';
import '../incident.scss';
import { Grid, FormControl, TextField, FormControlLabel, FormLabel, Button, RadioGroup, Radio } from '@material-ui/core';
import { updateIncident } from '../incident.reducer';

interface IIncidentWitnessComponentProps {
  incident_witness?: any;
  handleControlRoomWitnessRadio?: (event: any) => void;
  handleControlRoomAuthenticRadio?: (event: any) => void;
  handleControlRoomComments?: (event: any) => void;
  submitControlRoomRemarks?: () => void;
  records?: any;
  remarksValues?: any;
}

export const IncidentControlRoomRemarks = (props: IIncidentWitnessComponentProps) => {
  const [values, setValues] = useState({});

  return (
    <div className="incident-report-details-div">
      <div className="control-room-remarks-radio-button">
        <div style={{ paddingTop: '35px', marginRight: '20px', width: '20%' }}> Was that Incident authentic ? </div>
        <Grid item xs={12} md={6} sm={12}>
          <FormControl component="fieldset" className="person-fields">
            <RadioGroup
              aria-label="gender"
              name="gender1"
              className="personRadioButtons"
              value={props.remarksValues && props.remarksValues.authentic ? props.remarksValues.authentic : null}
              onChange={() => props.handleControlRoomAuthenticRadio(event)}
            >
              <FormControlLabel value="true" control={<Radio style={{ color: '#4620B7' }} />} label="Yes" />
              <FormControlLabel value="false" control={<Radio style={{ color: '#4620B7' }} />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
      </div>

      <div className="control-room-remarks-radio-button">
        <div style={{ paddingTop: '35px', marginRight: '20px', width: '20%' }}> Did you speak to all witnesses ? </div>
        <Grid item xs={12} md={6} sm={12}>
          <FormControl component="fieldset" style={{ display: 'flex' }} className="person-fields">
            <RadioGroup
              aria-label="gender"
              name="gender1"
              className="personRadioButtons"
              value={props.remarksValues && props.remarksValues.speak_to_all_witnesses ? props.remarksValues.speak_to_all_witnesses : null}
              onChange={() => props.handleControlRoomWitnessRadio(event)}
            >
              <FormControlLabel value="true" control={<Radio style={{ color: '#4620B7' }} />} label="Yes" />
              <FormControlLabel value="false" control={<Radio style={{ color: '#4620B7' }} />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
      </div>

      <div className="control-room-remarks-div">
        <Grid item lg={12} xs={12} sm={12}>
          <FormControl variant="outlined" className="incident-details-field">
            <TextField
              variant="outlined"
              size="small"
              margin="normal"
              multiline
              rows={6}
              fullWidth
              value={props.remarksValues && props.remarksValues.remarks ? props.remarksValues.remarks : null}
              onChange={() => props.handleControlRoomComments(event)}
            />
          </FormControl>
        </Grid>
      </div>

      <div style={{ width: '100%' }}>
        <Button
          id="save-entity"
          className="address_save_button"
          data-cy="entityCreateSaveButton"
          type="submit"
          onClick={props.submitControlRoomRemarks}
          style={{ color: 'white', width: '170px', float: 'right', backgroundColor: '#7A55DE' }}
        >
          Save Remarks
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = ({ Incident }: IRootState) => ({});

const mapDispatchToProps = {
  updateIncident,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(IncidentControlRoomRemarks);
