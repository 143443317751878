import React from 'react';
import { Grid, FormControl, FormControlLabel, Checkbox } from '@material-ui/core';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';

// CheckboxItem Component
const FeatureCheckboxItem = ({ label, checkedCondition, handleFeatureCheckButtons }) => (
  <Grid item className="modal-input" xs={3} lg={2} md={3} style={{ paddingTop: '0' }}>
    <FormControl style={{ minWidth: 150 }} className="pictures-type-buttons" variant="outlined" size="small">
      <FormControlLabel
        className="pictures-checkbox-buttons"
        name="alertAdmin"
        label={label}
        control={
          <Checkbox
            className={checkedCondition ? 'picture-checked' : 'picture-unchecked'}
            icon={<CircleCheckedFilled />}
            checkedIcon={<CircleCheckedFilled />}
            onChange={(event) => {
              handleFeatureCheckButtons(label, event);
            }}
            defaultChecked={checkedCondition ? true : false}
          />
        }
      />
    </FormControl>
  </Grid>
);

export default FeatureCheckboxItem;