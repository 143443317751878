export interface ITodaysShifts {
  id?: any;
  shiftStartTime?: string | null;
  shiftEndTime?: string | null;
  location?: string | null;
  name?: string | null;
  position?: string | null;
  status?: number | null;
  checkIn?: string | null;
  break?: string | null;
  checkOut?: string | null;
}

export const defaultValue: Readonly<ITodaysShifts> = {};
