import React, { useEffect, useState } from 'react';
import ServiceAreaDrawerComponent from './component/service-area-drawer';
import ProfileDetail from './service-area-view-designs/serviceArea-profileDetails';
import ServiceAreaTeam from './service-area-view-designs/service-area-team';
import ServiceRateCard from './service-area-view-designs/ratecard';
import ServiceAreaUpdate from './servicearea-viewIndex';
import Spinner from 'app/commonUI/spinner/spinner';

const ServiceAreaView = props => {
  const [page, setPage] = useState(1);
  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const [formData, setFormData] = useState({});

  return (
    <div className="service-area-view-deisgns-main-container" style={{ backgroundColor: '#f3f5f7', minHeight: '80vh' }}>
      {props.loading && <Spinner />}
      <div className="settings-outer-div">
        <div className="settings-drawer-container">{<ServiceAreaDrawerComponent setPage={setPage} page={page} isNew={isNew} />}</div>
        <div className="settings-container-body">
          {page === 1 && <ProfileDetail {...props} page={'serviceAreaDetail'} formData={formData} setFormData={setFormData} />}
          {page === 2 && <ServiceAreaTeam {...props} />}
          {page === 3 && <ProfileDetail {...props} page={'patrol'} formData={formData} setFormData={setFormData} />}
          {page === 4 && <ProfileDetail {...props} page={'shift'} formData={formData} setFormData={setFormData} />}
        </div>
      </div>
    </div>
  );
};

export default ServiceAreaView;
