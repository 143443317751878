import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'react-moment';
import { IRootState } from 'app/shared/reducers';
import { getEntity } from './shift.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IShiftDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const ShiftDetail = (props: IShiftDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { shiftEntity } = props;
  return (
    <div>
      <Grid item container>
        <Grid item xs={8}>
          <h2 data-cy="shiftDetailsHeading">
            <Translate contentKey="wfmApp.shift.detail.title">Shift</Translate>
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="id">
                <Translate contentKey="global.field.id">ID</Translate>
              </span>
            </dt>
            <dd>{shiftEntity.id}</dd>
            <dt>
              <span id="startTime">
                <Translate contentKey="wfmApp.shift.startTime">Start Time</Translate>
              </span>
            </dt>
            <dd>{shiftEntity.start_time ? <TextFormat value={shiftEntity.start_time} type="date" format={'HH:mm'} /> : null}</dd>
            <dt>
              <span id="endTime">
                <Translate contentKey="wfmApp.shift.endTime">End Time</Translate>
              </span>
            </dt>
            <dd>{shiftEntity.end_time ? <TextFormat value={shiftEntity.end_time} type="date" format={'HH:mm'} /> : null}</dd>
            <dt>
              <span id="startDate">Start Date</span>
            </dt>
            <dd>{shiftEntity.start_date ? <TextFormat value={shiftEntity.start_date} type="date" format={'YYYY-MM-DD'} /> : null}</dd>
            <dt>
              <span id="endDate">End Date</span>
            </dt>
            <dd>{shiftEntity.end_date ? <TextFormat value={shiftEntity.end_date} type="date" format={'YYYY-MM-DD'} /> : null}</dd>
            <dt>Position </dt>
            <dd>{shiftEntity.designation && shiftEntity.designation[0] && shiftEntity.designation[0].name}</dd>
            <dt>
              <Translate contentKey="wfmApp.shift.serviceArea">Service Area</Translate>
            </dt>
            <dd>{shiftEntity.service_area && shiftEntity.service_area[0] && shiftEntity.service_area[0].name}</dd>
            <dt>
              <Translate contentKey="wfmApp.shift.person">Person</Translate>
            </dt>
            <dd>
              {shiftEntity.person && shiftEntity.person[0] && shiftEntity.person[0].first_name}{' '}
              {shiftEntity.person && shiftEntity.person[0] && shiftEntity.person[0].last_name}
            </dd>
          </dl>
          <Button
            component={Link}
            to="/roster"
            replace
            style={{ color: 'black', backgroundColor: 'rgb(13, 202, 240)' }}
            data-cy="entityDetailsBackButton"
          >
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button component={Link} to={`/roster/${shiftEntity.id}/edit`} replace style={{ color: 'white', backgroundColor: 'blue' }}>
            <FontAwesomeIcon icon="pencil-alt" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = ({ shift }: IRootState) => ({
  shiftEntity: shift.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ShiftDetail);
