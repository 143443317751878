import axios from 'axios';
import { Storage, ICrudGetAllAction } from 'react-jhipster';
import { IOrganizationQualification } from 'app/shared/model/organization-qualification.model';
import moment from 'moment-timezone';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { ILeave, ILeaveType, ILeaveCategory, ILeavePayment } from 'app/shared/model/leave-management';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { toast, ToastContainer } from 'react-toastify';

export const ACTION_TYPES = {
  STORE_LEAVE: 'leave/STORE_LEAVE',
  FETCH_LEAVE_LIST: 'leave/FETCH_LEAVE_LIST',
  FETCH_CALENDAR_LEAVE: 'leave/FETCH_CALENDAR_LEAVE',
  FETCH_LEAVE: 'leave/FETCH_LEAVE',
  UPDATE_PARTIAL_LEAVE: 'leave/UPDATE_PARTIAL_LEAVE',
  FETCH_ACCRUED_LEAVE: 'FETCH_ACCRUED_LEAVE',
  FETCH_PAST_LEAVE: 'FETCH_PAST_LEAVE',
  FETCH_LEAVE_SHIFTS: 'FETCH_LEAVE_SHIFTS',
  FETCH_UPCOMING_HOLIDAYS: 'FETCH_UPCOMING_HOLIDAYS',
  FETCH_LEAVE_COUNTER: 'FETCH_LEAVE_COUNTER',

  RESET: 'leave/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  updateSuccess: false,
  successfullyCreated: false,
  leave_type: [] as Array<ILeave>,
  leave_type_list: [] as Array<ILeave>,
  leave: [] as Array<ILeave>,
  leave_list: [],
  leave_category: [],
  attachment_list: [],
  accrued_leaves: null,
  past_leaves: null,
  leave_shifts: null,
  upcoming_holidays: null,
  calendar_leave_list: [],
  leave_counter: null,
};

export type LeaveState = Readonly<typeof initialState>;

export default (state: LeaveState = initialState, action): LeaveState => {
  switch (action.type) {
    case ACTION_TYPES.STORE_LEAVE:
      return {
        ...state,
        leave_type: action.payload,
      };

    case REQUEST(ACTION_TYPES.STORE_LEAVE):
      return {
        ...state,
        loading: true,
        errorMessage: null,
        updateSuccess: false,
      };

    case SUCCESS(ACTION_TYPES.STORE_LEAVE):
      return {
        ...state,
        loading: false,
        updateSuccess: true,
        leave_type: action.payload.data,
      };

    case ACTION_TYPES.FETCH_LEAVE_LIST:
      return {
        ...state,
        leave_list: action.payload.data,
      };

    case REQUEST(ACTION_TYPES.FETCH_LEAVE_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };

    case SUCCESS(ACTION_TYPES.FETCH_LEAVE_LIST):
      return {
        ...state,
        loading: false,
        leave_list: action.payload.data,
      };

    case ACTION_TYPES.FETCH_CALENDAR_LEAVE:
      return {
        ...state,
        calendar_leave_list: action.payload.data,
      };

    case REQUEST(ACTION_TYPES.FETCH_CALENDAR_LEAVE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };

    case SUCCESS(ACTION_TYPES.FETCH_CALENDAR_LEAVE):
      return {
        ...state,
        loading: false,
        calendar_leave_list: action.payload.data,
      };

    case ACTION_TYPES.FETCH_LEAVE:
      return {
        ...state,
        leave: action.payload.data,
      };

    case REQUEST(ACTION_TYPES.FETCH_LEAVE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };

    case SUCCESS(ACTION_TYPES.FETCH_LEAVE):
      return {
        ...state,
        loading: false,
        leave: action.payload.data,
      };
    case REQUEST(ACTION_TYPES.UPDATE_PARTIAL_LEAVE):
      return {
        ...state,
        loading: true,
        errorMessage: null,
        updateSuccess: false,
      };

    case SUCCESS(ACTION_TYPES.UPDATE_PARTIAL_LEAVE):
      return {
        ...state,
        loading: false,
        updateSuccess: true,
        leave: action.payload.data,
      };

    case ACTION_TYPES.FETCH_ACCRUED_LEAVE:
      return {
        ...state,
        accrued_leaves: action.payload.data,
      };

    case REQUEST(ACTION_TYPES.FETCH_ACCRUED_LEAVE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };

    case SUCCESS(ACTION_TYPES.FETCH_ACCRUED_LEAVE):
      return {
        ...state,
        loading: false,
        accrued_leaves: action.payload.data,
      };
    case ACTION_TYPES.FETCH_LEAVE_COUNTER:
      return {
        ...state,
        leave_counter: action.payload.data,
      };

    case REQUEST(ACTION_TYPES.FETCH_LEAVE_COUNTER):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };

    case SUCCESS(ACTION_TYPES.FETCH_LEAVE_COUNTER):
      return {
        ...state,
        loading: false,
        leave_counter: action.payload.data,
      };

    case ACTION_TYPES.FETCH_PAST_LEAVE:
      return {
        ...state,
        past_leaves: action.payload.data,
      };

    case REQUEST(ACTION_TYPES.FETCH_PAST_LEAVE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };

    case SUCCESS(ACTION_TYPES.FETCH_PAST_LEAVE):
      return {
        ...state,
        loading: false,
        past_leaves: action.payload.data,
      };

    case ACTION_TYPES.FETCH_LEAVE_SHIFTS:
      return {
        ...state,
        leave_shifts: action.payload.data,
      };

    case REQUEST(ACTION_TYPES.FETCH_LEAVE_SHIFTS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };

    case SUCCESS(ACTION_TYPES.FETCH_LEAVE_SHIFTS):
      return {
        ...state,
        loading: false,
        leave_shifts: action.payload.data,
      };

    case ACTION_TYPES.FETCH_UPCOMING_HOLIDAYS:
      return {
        ...state,
        upcoming_holidays: action.payload.data,
      };

    case REQUEST(ACTION_TYPES.FETCH_UPCOMING_HOLIDAYS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };

    case SUCCESS(ACTION_TYPES.FETCH_UPCOMING_HOLIDAYS):
      return {
        ...state,
        upcoming_holidays: action.payload.data,
      };

    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrlLeaveType = 'api/leave';
export const storeLeave = values => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.STORE_LEAVE,
    payload: axios.post(`${apiUrlLeaveType}`, values),
  });

  toast.success('Leave Applied Successfully');
  return result;
};

export const getLeaves = data => ({
  type: ACTION_TYPES.FETCH_LEAVE_LIST,
  payload: axios.get(`api/leave?filter[person_id]=${data.people}&filter[from_leaves]=${data.from_date}&filter[to_leaves]=${data.to_date}`),
});

export const getLeavesForCalendar = () => ({
  type: ACTION_TYPES.FETCH_CALENDAR_LEAVE,
  payload: axios.get(`api/getLeavesForCalendar`),
});

export const getLeaveById = id => ({
  type: ACTION_TYPES.FETCH_LEAVE,
  payload: axios.get(`api/leave/${id}`),
});

export const getAccruedLeaves = id => ({
  type: ACTION_TYPES.FETCH_ACCRUED_LEAVE,
  payload: axios.get(`api/accruedLeaves/${id}`),
});

export const getPastLeaves = id => ({
  type: ACTION_TYPES.FETCH_PAST_LEAVE,
  payload: axios.get(`api/pastLeaves/${id}`),
});

export const getLeaveShifts = id => ({
  type: ACTION_TYPES.FETCH_LEAVE_SHIFTS,
  payload: axios.get(`api/shiftsDuringLeave/${id}`),
});

export const getUpcomingHolidays = id => ({
  type: ACTION_TYPES.FETCH_UPCOMING_HOLIDAYS,
  payload: axios.get(`api/upcomingHolidays`),
});

export const updateLeave = (id, values) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_PARTIAL_LEAVE,
    payload: axios.patch(`api/leave/${id}`, values),
  });

  toast.success(values.status === 2 ? 'Leave Approved Successfully' : 'Leave Declined Successfully');
  return result;
};

export const getLeaveCounter = id => ({
  type: ACTION_TYPES.FETCH_LEAVE_COUNTER,
  payload: axios.get(`api/getLeavesCounter/${id}`),
});

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
