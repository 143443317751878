import React from 'react';
import 'react-toastify/dist/ReactToastify.css';

import AvailabilityPeople from './availability-components/availability-people';
import AvailabilityOperations from './availability-components/availability-operations';
import AvailabilityManager from './availability-components/availability-manager';
import AvailabilityDepartment from './availability-components/availability-department';
import AvailabilityLocation from './availability-components/availability-location';

export default function Availability(props) {
  return (
    <React.Fragment>
      <AvailabilityPeople
        handler={props.handler}
        barForProps={props.barForProps}
        barHandler={props.barHandler}
        valueForProps={props.valueForProps}
      />
      <AvailabilityOperations
        handler={props.handler}
        barForProps={props.barForProps}
        barHandler={props.barHandler}
        valueForProps={props.valueForProps}
      />
      <AvailabilityManager
        handler={props.handler}
        barForProps={props.barForProps}
        barHandler={props.barHandler}
        valueForProps={props.valueForProps}
      />
      <AvailabilityDepartment
        handler={props.handler}
        barForProps={props.barForProps}
        barHandler={props.barHandler}
        valueForProps={props.valueForProps}
      />
      <AvailabilityLocation
        handler={props.handler}
        barForProps={props.barForProps}
        barHandler={props.barHandler}
        valueForProps={props.valueForProps}
      />
    </React.Fragment>
  );
}
