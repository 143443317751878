import React from 'react';
import 'react-toastify/dist/ReactToastify.css';

import SystemAccessPeople from './system-access/system-access-people';
import SystemAccessOperations from './system-access/system-access-operations';
import SystemAccessClient from './system-access/system-access-client';
import SystemAccessAnyRegisteredUser from './system-access/system-access-any-registered-user';

export default function SystemAccess(props) {
  return (
    <React.Fragment>
      <SystemAccessPeople
        handler={props.handler}
        barForProps={props.barForProps}
        barHandler={props.barHandler}
        valueForProps={props.valueForProps}
      />
      <SystemAccessOperations
        handler={props.handler}
        barForProps={props.barForProps}
        barHandler={props.barHandler}
        valueForProps={props.valueForProps}
      />
      <SystemAccessClient
        handler={props.handler}
        barForProps={props.barForProps}
        barHandler={props.barHandler}
        valueForProps={props.valueForProps}
      />
      <SystemAccessAnyRegisteredUser
        handler={props.handler}
        barForProps={props.barForProps}
        barHandler={props.barHandler}
        valueForProps={props.valueForProps}
      />
    </React.Fragment>
  );
}
