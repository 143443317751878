import React, { useState } from 'react';
import { Grid, TextField, InputAdornment, FormControl } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import CustomToolbarComponent from './CustomToolbarComponent';
import { getDateFormat } from 'app/shared/util/calender-utils';
import { CurrencyChecker } from '../../../utils/TimeFormatAndTimezone';
import { IPersonalSettings } from 'app/shared/model/personalSetting.model';
import { IOrganizationSettings } from 'app/shared/model/organizationSettings.model';
import { IServiceArea } from 'app/shared/model/service-area.model';

interface ServiceAreaChargerateInformationProps {
  settings?: IOrganizationSettings | any;
  setNewValues?: (event: any) => void;
  serviceAreaEntity?: IServiceArea | any;
  startError?: string | any;
  setting?: IPersonalSettings | any;
  chargeRateStart?: string | any;
  handleChargeRateStart?: (data: any) => void;
  endError?: string | any;
  chargeRateEnd?: string | any;
  handleChargeRateEnd?: (data: any) => void;
  values?: IServiceArea | any;
  fieldError?: Record<string, any>;
  isEdit: boolean;
}

const ServiceAreaChargerateInformation: React.FC<ServiceAreaChargerateInformationProps> = props => {
  const [currency, setCurrency] = useState(props.settings.currency);

  return (
    <div className="create-service-area-details-outer-div create-service-area-fieldsets">
      <div className="create-service-area-detail-fields-outer-div">
        <Grid container spacing={3}>
          <Grid item lg={12} xs={12}>
            <TextField
              required
              id="service-area-charge-rate"
              className="service-area-fields"
              label="Charge Rate"
              variant="outlined"
              data-cy="charge-rate"
              type="number"
              name="serviceAreaChargeRate"
              inputProps={{
                step: '0.01',
                min: 0,
              }}
              value={props.values['serviceAreaChargeRate'] ? props.values['serviceAreaChargeRate'] : ''}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end" className="personPayrateCurrencySign">
                    <span className="personCurrencySign">{CurrencyChecker(currency)}</span>
                  </InputAdornment>
                ),
              }}
              onChange={props.setNewValues}
              error={props.fieldError['serviceAreaChargeRate_error']}
              defaultValue={props.serviceAreaEntity.charge_rate}
              disabled={!props.isEdit}
              style={{ backgroundColor: 'white' }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item lg={6} xs={12}>
            <FormControl style={{ width: '100%', backgroundColor: 'white', marginTop: '15px' }}>
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <KeyboardDatePicker
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  label="Charge Rate Start Date"
                  error={props.startError}
                  format={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'MM/dd/yyyy'}
                  // value={props.chargeRateStart}
                  value={props.values['chargeRateStartDate'] || null}
                  ToolbarComponent={prop => (
                    <>
                      <CustomToolbarComponent {...prop} settingFormat={props.setting.date_format} />
                    </>
                  )}
                  placeholder={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'MM/dd/yyyy'}
                  InputAdornmentProps={{ position: 'start' }}
                  onChange={date => props.handleChargeRateStart(date)}
                  disabled={!props.isEdit}
                />
              </MuiPickersUtilsProvider>
              {props.startError && <span style={{ color: 'red', fontSize: '17' }}>{'Start Date should be less than End Date'}</span>}
            </FormControl>
          </Grid>

          <Grid item lg={6} xs={12}>
            <FormControl style={{ width: '100%', backgroundColor: 'white', marginTop: '15px' }}>
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <KeyboardDatePicker
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  label="Charge Rate End Date"
                  error={props.endError}
                  format={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'MM/dd/yyyy'}
                  // value={props.chargeRateEnd}
                  value={props.values['chargeRateEndDate'] || null}
                  ToolbarComponent={prop => (
                    <>
                      <CustomToolbarComponent {...prop} settingFormat={props.setting.date_format} />
                    </>
                  )}
                  placeholder={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'MM/dd/yyyy'}
                  InputAdornmentProps={{ position: 'start' }}
                  onChange={date => props.handleChargeRateEnd(date)}
                  disabled={!props.isEdit}
                />
              </MuiPickersUtilsProvider>
              {props.endError && <span style={{ color: 'red', fontSize: '17' }}>{'End Date should be greater than Start Date'}</span>}
            </FormControl>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
export default ServiceAreaChargerateInformation;
