import React from 'react';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../PeoplePositions/PeoplePositions.scss';

export const PeoplePositionsComponent = props => {
  const deletePositionRecord = () => {
    props.remove(props.id);
  };

  return (
    <>
      <tr className="people-position-settings-row" style={{ borderBottom: '1px solid #e2e1e1' }}>
        <td style={{ textAlign: 'left', width: '15%', marginBottom: '3%' }}>
          <FontAwesomeIcon icon={faSquare} color={props.color} style={{ marginLeft: '5%' }} />
        </td>
        <td style={{ textAlign: 'left', width: '15%' }}>{props.msg}</td>
        <td style={{ textAlign: 'center', width: '15%' }}>{props.msg2}</td>
        <td style={{ textAlign: 'center', width: '15%' }}>{props.msg3}</td>
        <td style={{ textAlign: 'center', width: '15%' }}>{props.msg4}</td>
        <td style={{ textAlign: 'right', width: '15%' }}>
          <a>
            <FontAwesomeIcon icon={faTrashAlt} color="primary" onClick={deletePositionRecord} style={{ marginRight: '10%' }} />
          </a>
        </td>
      </tr>
    </>
  );
};

export default PeoplePositionsComponent;
