import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Table, Grid } from '@material-ui/core';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { shiftListStartEndDate, calculateShiftListTimeDuration, CurrencyChecker } from '../../../utils/TimeFormatAndTimezone';
import moment from 'moment';
import '../../../utils/GlobalCssRequiredInWebsite.scss';
import IconButton from '@material-ui/core/IconButton';
import { IRootState } from 'app/shared/reducers';
// import { getEntities, reset } from './leave.reducer';
import { IShift } from 'app/shared/model/shift.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { getPersonalSettings } from '../../modules/Setting/Personal-Setting.reducer';
import { getOrganizationSettings } from '../../modules/Setting/generalSettings.reducer';
import SearchBar from 'material-ui-search-bar';
import Spinner from '../../commonUI/spinner/spinner';
import InfiniteScroll from 'react-infinite-scroll-component';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import CustomToolbarComponent from '../service-area/CustomToolbarComponent';
import MomentUtils from '@date-io/moment';
import {
  getSelectedTimePeriodFilter,
  handleFilterCurrentWeekStartDate,
  handleFilterCurrentWeekEndDate,
  handleFilterLastWeekStartDate,
  handleFilterLastWeekEndDate,
  dateFTConverter,
} from '../../../utils/TimeFormatAndTimezone';
import FormControl from '@material-ui/core/FormControl';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import timePeriod from '../../shared/data/time_period_other.json';
import { mapValues } from 'lodash';
import './leave-management.scss';
import { getLeaves, updateLeave, reset, getUpcomingHolidays } from './leave.reducer';
import { getEntities as getPerson } from '../person/person.reducer';
import AutorenewIcon from '@material-ui/icons/Autorenew';

export interface IShiftProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const LeaveManagement = props => {
  const [timezoneFormat, setTimezoneFormat] = useState(props.setting.timezone);
  const [locale, setLocale] = useState(props.setting.time_format);
  const [dateFormat, setdateFormat] = useState(props.setting.date_format);
  const [currency, setCurrency] = useState(props.settings.currency);
  const [searched, setSearched] = useState<string>('');
  const [records, setRecords] = useState(props.leaves);
  const [pgNumber, setPageNumber] = useState<any | null>(1);
  const [selectedTimePeriod, setTimePeriod] = useState({ id: 1, text: 'Until now' });
  const [selectedDateFrom, setSelectedDateFrom] = React.useState<any | null>(
    getSelectedTimePeriodFilter(selectedTimePeriod.text, 'setSelectedDateFrom')
  );
  const [selectedDateTo, setSelectedDateTo] = React.useState<any | null>(
    getSelectedTimePeriodFilter(selectedTimePeriod.text, 'setSelectedDateTo')
  );
  const [selectedPerson, setSelectedPerson] = React.useState<any | null>(null);
  const [values, setValues] = useState({
    from_date: moment(handleFilterCurrentWeekStartDate()).format('YYYY-MM-DD'),
    to_date: moment(handleFilterCurrentWeekEndDate()).format('YYYY-MM-DD'),
    people: '',
    pageNumber: 1,
  });

  useEffect(() => {
    props.getUpcomingHolidays();
    props.getPerson(-1, '');
  }, []);

  useEffect(() => {
    props.getLeaves(values);
  }, [values]);

  useEffect(() => {
    if (props.updateSuccess) {
      props.reset();
      props.getLeaves(values);
    }
  }, [props.updateSuccess]);

  useEffect(() => {
    setLocale(props.setting.time_format);
    setTimezoneFormat(props.setting.timezone);
    setdateFormat(props.setting.date_format);
  }, [props.setting]);

  //   const handleSyncList = () => {
  //     props.getEntities(values);
  //   };

  useEffect(() => {
    setRecords(props.leaves);
  }, [props.leaves]);

  const requestSearch = (searchedVal: string) => {
    setSearched(searchedVal);
    const value = searchedVal.toLowerCase();
    const filteredRows = props.leaves.filter(row => {
      return (
        (row.created_at !== null && shiftListStartEndDate(row.created_at, dateFormat).includes(value)) ||
        (row.person && row.person.first_name.toLowerCase().includes(value)) ||
        (row.person && row.person.last_name.toLowerCase().includes(value)) ||
        (row.person &&
          row.person.first_name &&
          row.person.last_name &&
          `${row.person.first_name} ${row.person.last_name}`.toLowerCase().includes(value)) ||
        (row.leave_type && row.leave_type.name.toLowerCase().includes(value))
      );
    });
    setRecords(filteredRows);
  };

  const cancelSearch = () => {
    setSearched('');
    requestSearch('');
  };

  const setTimePeriodValues = (first, second) => {
    // props.reset();
    setValues(oldValues => ({
      ...oldValues,
      ['from_date']: moment(first).format('YYYY-MM-DD'),
      ['to_date']: moment(second).format('YYYY-MM-DD'),
      ['current_day']: '',
    }));
  };

  const handleDateFrom = e => {
    props.reset();
    const d = moment(e).format('YYYY-MM-DD');
    setSelectedDateFrom(e);
    if (d !== 'Invalid date') {
      setValues(oldValues => ({ ...oldValues, ['from_date']: d }));
    }
  };

  const handleDateTo = e => {
    props.reset();
    const d = moment(e).format('YYYY-MM-DD');
    setSelectedDateTo(e);
    if (d !== 'Invalid date') {
      setValues(oldValues => ({ ...oldValues, ['to_date']: d }));
    }
  };

  const handlePersonFilterValues = (_, e) => {
    if (e) {
      setSelectedPerson({ id: e.id, text: e.text });
      return setFilterValues(e.name, e.id);
    } else {
      setValues(oldValues => ({ ...oldValues, people: '' }));
    }
  };

  const setFilterValues = (name, value) => {
    setValues(oldValues => ({ ...oldValues, [name]: value }));
  };

  const getLeaveNoOfDays = leave => {
    if (leave) {
      const a = new Date(leave.end_date ? leave.end_date : leave.start_date);
      const b = new Date(leave.start_date);

      const difference = a.getTime() - b.getTime();

      const TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
      return TotalDays + 1;
    }
  };

  const getLeaveStatus = leave => {
    if (leave && leave.status) {
      if (leave.status === 1) {
        return 'Awaiting Approval';
      } else if (leave.status === 2) {
        return 'Approved';
      } else if (leave.status === 3) return 'Declined';
    }
  };

  const handleLeaveApproval = leave => {
    const value = {
      status: 2,
      person_id: leave.person_id,
    };
    props.updateLeave(leave.id, value);
  };

  const handleLeaveDecline = leave => {
    const value = {
      status: 3,
      person_id: leave.person_id,
    };
    props.updateLeave(leave.id, value);
  };

  const { shiftList, match, loading } = props;
  return (
    <div className="entities-reports-main-container">
      {props.loading && <Spinner />}
      <div className="entities-reports-filter-area">
        <Grid container spacing={2} style={{ flexWrap: 'nowrap', width: '65%' }}>
          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
            <FormControl variant="outlined" size="small">
              <SearchBar
                value={searched}
                onChange={searchVal => requestSearch(searchVal)}
                onCancelSearch={() => cancelSearch()}
                placeholder="Search Here..."
                style={{
                  outline: '1px solid #bbc4c2',
                  borderRadius: '4px',
                  height: '40px',
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <KeyboardDatePicker
                clearable={true}
                id="date"
                placeholder="From"
                inputVariant="outlined"
                className="entities_report_filter_fields datePicker"
                name="from_date"
                size="small"
                ToolbarComponent={prop => (
                  <>
                    <CustomToolbarComponent {...prop} settingFormat={props.setting.date_format} />
                  </>
                )}
                labelFunc={selectedDateFrom ? dateFTConverter : () => 'From'}
                value={selectedDateFrom ? moment(selectedDateFrom) : null}
                onChange={handleDateFrom}
                InputLabelProps={{ shrink: true }}
                disabled={loading}
              />{' '}
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <KeyboardDatePicker
                clearable={true}
                id="date"
                inputVariant="outlined"
                className="entities_report_filter_fields datePicker"
                size="small"
                ToolbarComponent={prop => (
                  <>
                    <CustomToolbarComponent {...prop} settingFormat={props.setting.date_format} />
                  </>
                )}
                labelFunc={selectedDateFrom ? dateFTConverter : () => 'Till'}
                value={selectedDateTo ? moment(selectedDateTo).format('YYYY-MM-DD') : null}
                name="to_date"
                onChange={handleDateTo}
                InputLabelProps={{ shrink: true }}
                disabled={loading}
              />{' '}
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
            <FormControl variant="outlined" fullWidth className="entities_report_filter_fields staffFilterTimeSheet">
              <Autocomplete
                options={
                  props.people &&
                  props.people.map(person => ({
                    id: person.id,
                    text: person.first_name + ' ' + person.last_name,
                    name: 'people',
                  }))
                }
                noOptionsText={'No Records'}
                classes={{ listbox: 'autocompletelistbox' }}
                ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                getOptionLabel={option => option.text || ''}
                onChange={handlePersonFilterValues}
                value={selectedPerson}
                disabled={loading}
                renderInput={params => (
                  <TextField {...params} variant="outlined" size="small" className="leavePersonFilter" placeholder="Staff" fullWidth />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container className="entities_reports_refresh_and_download_div" style={{ width: '35%' }}>
          <Link
            to={`${match.url}/new`}
            className="btn jh-create-entity serviceAreaCreateButton incidentCreateButton"
            id="jh-create-entity"
            data-cy="entityCreateButton"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp; Apply Leave
          </Link>

          {/* </Grid> */}
        </Grid>
      </div>

      <div className="leave-management-table-outer-div">
        <div className="leave-table-responsive">
          <InfiniteScroll
            dataLength={records.length}
            scrollThreshold="200px"
            next={() => {
              setPageNumber(pgNumber + 1);
              setValues(oldValues => ({ ...oldValues, pageNumber: pgNumber + 1 }));
            }}
            hasMore={true}
            loader={''}
          >
            {/* {records && records.length > 0 ? ( */}
            <div className="leave-management-table">
              <Table>
                <thead className="globalTableHeaderGradientColor">
                  <tr className="shihtListTableHeaderRow">
                    <th className="subcontractorTableHeaderCell">Application Date</th>
                    <th className="subcontractorTableHeaderCell">Leave type</th>
                    <th className="subcontractorTableHeaderCell">Employee Name</th>
                    <th className="subcontractorTableHeaderCell">Duration</th>
                    <th className="subcontractorTableHeaderCell">No of Days</th>
                    <th className="subcontractorTableHeaderCell">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {records.map((leave, i) => (
                    <tr key={`entity-${i}`} data-cy="entityTable" className="shihtListTableBodyRows">
                      <td className="shihtListTableBodyCell leaveDateCell">
                        {leave.created_at ? moment(leave.created_at).format('DD-MM-YYYY') : ''}
                      </td>
                      <td className="shihtListTableBodyCell leaveTypeCell">{leave.leave_type && leave.leave_type.name}</td>
                      <td className="shihtListTableBodyCell leaveEmployeeCell">
                        {leave.person && leave.person.first_name} {leave.person && leave.person.last_name}
                      </td>
                      <td className="shihtListTableBodyCell LeaveDateRangeCell">
                        {moment(leave.start_date).format('DD-MM-YYYY') ? moment(leave.start_date).format('DD-MM-YYYY') : null}
                        {' - '}
                        {leave.end_date ? moment(leave.end_date).format('DD-MM-YYYY') : moment(leave.start_date).format('DD-MM-YYYY')}
                      </td>
                      <td className="shihtListTableBodyCell leaveNoOfDayCell">{getLeaveNoOfDays(leave)}</td>

                      <td className="shihtListTableBodyCell leaveActionCell">
                        <div className="btn-group shihtListActionCellButtons">
                          {leave.status === 1 ? (
                            <>
                              <IconButton
                                className="leaveActionButtons"
                                style={{ margin: '1%' }}
                                onClick={() => handleLeaveApproval(leave)}
                                size="small"
                                data-cy="entityDeleteButton"
                                id="leaveApprove-button"
                              >
                                <img src="content/images/approved_icon.png" />
                              </IconButton>
                              &nbsp;
                              <IconButton
                                className="leaveActionButtons"
                                style={{ margin: '1%' }}
                                onClick={() => handleLeaveDecline(leave)}
                                size="small"
                                data-cy="entityDeleteButton"
                                id="leaveDecline-button"
                              >
                                <img src="content/images/declined_icon.png" />
                              </IconButton>
                            </>
                          ) : (
                            <div style={{ width: '100px', marginTop: '14px' }}>{getLeaveStatus(leave)}</div>
                          )}
                          &nbsp;
                          <IconButton
                            component={Link}
                            className="leaveActionButtons"
                            to={`/leave/${leave.id}`}
                            style={{ margin: '1%' }}
                            size="small"
                            data-cy="entityDetailsButton"
                            id="leaveView-button"
                          >
                            <img src="content/images/View3.png" />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </InfiniteScroll>
        </div>

        <div className="upcoming-holidays-div">
          <div className="subcontractorTableHeaderCell" style={{ backgroundColor: '#d28ad4' }}>
            Upcoming Public Holidays
          </div>

          {props.upcomingHolidays && props.upcomingHolidays.length > 0
            ? props.upcomingHolidays.map((record, i) => (
                <div className="upcoming-holidays-details" key={i}>
                  <div className="upcoming-holiday-name">{record.name}</div>
                  <div className="upcoming-holiday-date">{moment(record.date).format('Do MMM  YYYY')}</div>
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ shift, PersonalSettings, organizationSettings, Leave, person }: IRootState) => ({
  shiftList: shift.entities,
  loading: Leave.loading,
  setting: PersonalSettings.storedPersonalSettings,
  settings: organizationSettings.storedOrganizationSettings,
  leaves: Leave.leave_list,
  upcomingHolidays: Leave.upcoming_holidays,
  updateSuccess: Leave.updateSuccess,
  people: person.entities,
});

const mapDispatchToProps = {
  getPersonalSettings,
  getOrganizationSettings,
  getLeaves,
  updateLeave,
  getPerson,
  getUpcomingHolidays,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(LeaveManagement);
