/* eslint-disable no-console */
/* eslint-disable no-debugger */
import React from 'react';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import './login.css';
import Loader from '../../commonUI/loader';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

export interface ILoginModalProps {
  showModal: boolean;
  loginError: boolean;
  showSpinner: boolean;
  handleLogin: (username: string, password: string, rememberMe: boolean) => void;
  handleClose: () => void;
}

export interface MyState {
  showPassword: boolean;
  rememberMe: boolean;
  username: string;
  password: string;
}

class LoginModal extends React.Component<ILoginModalProps, MyState> {
  constructor(props) {
    super(props);
    this.handleCheckBox = this.handleCheckBox.bind(this);
    this.state = {
      showPassword: false,
      username: '',
      password: '',
      rememberMe: false,
    };
  }

  handleShowPassword = () => {
    this.setState(prevState => ({
      showPassword: !prevState.showPassword,
    }));
  };

  GetLogin = () => {
    const { handleLogin } = this.props;
    handleLogin(this.state.username, this.state.password, this.state.rememberMe);
  }

  handleCheckBox() {
    this.setState(prevState => ({
      rememberMe: !prevState.rememberMe,
    }));
  }

  render() {
    return (
      <div className="login-paper">
        <Paper className="login-paper-model" elevation={4} style={{ borderRadius: '10px' }}>
          <div className="login-outer">
            <img src="../../../content/images/logo-2.png" className="logo-img" />
            <br />
            <br />
            <div className="login-outer-model" style={{ fontWeight: 'bold' }}>
              Welcome back, Please Sign In to your account
            </div>
            <div>
              <div className="login-inner">
                <form noValidate>
                  <div className="login-field">
                    <TextField
                      variant="outlined"
                      label="Email"
                      id="username"
                      onChange={event => {
                        this.setState({ username: event.target.value });
                      }}
                      className="login-field"
                      required
                    />
                  </div>

                  <FormControl variant="outlined" className="login-field login-password-field" style={{ marginTop: '20px' }}>
                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={this.state.showPassword ? 'text' : 'password'}
                      onChange={event => {
                        this.setState({ password: event.target.value });
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton aria-label="toggle password visibility" onClick={this.handleShowPassword} edge="end">
                            {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      labelWidth={70}
                    />
                  </FormControl>

                  <div className="login-field-rememberMe">
                    <FormControlLabel
                      label="Remember Me"
                      style={{ color: '#a7a7a7' }}
                      control={
                        <Checkbox
                          checked={this.state.rememberMe}
                          onChange={this.handleCheckBox}
                          id="login-Modal-rememberMe"
                          style={{ color: '#4620B7' }}
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                      }
                    />
                    <div className="login-forgot-password">
                      <a href="forgot-Password" style={{ textDecoration: 'none', color: '#a7a7a7', fontWeight: '400', fontSize: '14px' }}>
                        Forgot your password?
                      </a>
                    </div>
                  </div>

                  <div className="login-button">
                  <Button fullWidth variant="contained" className="login-submit" onClick={this.GetLogin} disabled={this.props.showSpinner}>
                  {this.props.showSpinner ? <Loader size = {30}/> : "Sign in"}
                </Button>
                  </div>
                  <br />
                  <div className="login-register">
                    Need an account?
                    <Button className="login-SignUp-button" style={{ color: '#532ac5' }} href="/account/register">
                      Sign Up Now
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}

export default LoginModal;
