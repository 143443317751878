import React, { useState, useEffect } from 'react';
import { Drawer } from '@material-ui/core';
import { Select, MenuItem, FormControl, InputLabel, makeStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { AddCircleOutline, AddCircleOutlined, Subject, SubjectOutlined, NotificationsActive } from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router';
import { faUserLock } from '@fortawesome/free-solid-svg-icons';
import { faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { faFirstAid } from '@fortawesome/free-solid-svg-icons';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { faLanguage } from '@fortawesome/free-solid-svg-icons';
import { faUserTie } from '@fortawesome/free-solid-svg-icons';
import { faUserGraduate } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Collapse from '@material-ui/core/Collapse';

const useStyles = makeStyles(theme => ({
  selectBox: {
    minWidth: '35vh',
    margin: 'auto',
    marginTop: '2vh',
    paddingTop: '1vh',
  },
  textBox: {
    minWidth: 200,
    margin: 'auto',
    marginTop: '1vh',
    paddingTop: '1vh',
  },
  buttons: {
    minWidth: 100,
    marginTop: '3vh',
    marginRight: '2vh',
    float: 'right',
  },
  sideBySide: {
    display: 'flex',
    flexDirection: 'row',
  },
  drawer: {
    width: '18%',
  },
  drawerPaper: {
    width: '19%',
    position: 'absolute',
    marginTop: '104px',
    marginLeft: 0,
    zIndex: 1,
    paddingLeft: '18px',
    paddingTop: '20px',
  },
  active: {
    background: '#F4F4F4',
  },
  title: {
    textAlign: 'left',
    marginLeft: '10vh',
  },
  mainTitle: {
    padding: theme.spacing(2),
    marginLeft: '3.2vh',
    textAlign: 'left',
    color: '#6610f2',
    fontWeight: 450,
  },
  flexContainer: {
    display: 'flex',
    background: 'linear-gradient(to top, #FFFFFF 50%, #EBECF0 50%)',
  },
}));

const DrawerComponent = props => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const history = useHistory();

  const poeplSettingsItems = [
    {
      text: 'Qualifications',
      icon: <FontAwesomeIcon icon={faUserGraduate} color="#4720b7" />,
      pageNo: 5,
    },
    {
      text: 'Health',
      icon: <FontAwesomeIcon icon={faFirstAid} color="#4720b7" />,
      pageNo: 6,
    },
    {
      text: 'Positions',
      icon: <FontAwesomeIcon icon={faUserPlus} color="#4720b7" />,
      pageNo: 7,
    },
    {
      text: 'Employment',
      icon: <FontAwesomeIcon icon={faUserTie} color="#4720b7" />,
      pageNo: 8,
    },
    {
      text: 'General',
      icon: <FontAwesomeIcon icon={faLanguage} color="#4720b7" />,
      pageNo: 9,
    },
  ];

  const menuItems = [
    {
      text: 'General Settings',
      icon: <SubjectOutlined style={{ color: '#4720b7' }} />,
      pageNo: 1,
    },
    {
      text: 'Notifications',
      icon: <NotificationsActive style={{ color: '#4720b7' }} />,
      pageNo: 2,
    },
    {
      text: 'Roles & Permissions',
      icon: <FontAwesomeIcon icon={faUserLock} color="#4720b7" />,
      pageNo: 3,
    },
    {
      text: 'People Settings',
      icon: <FontAwesomeIcon icon={faUserFriends} color="#4720b7" />,
      pageNo: 5,
    },
  ];

  const handleClick = () => {
    setOpen(!open);
  };

  const handleDrawerMenu = pageNo => {
    if (pageNo === 5) {
      handleClick();
    } else {
      props.setPage(pageNo);
    }
  };

  return (
    <Drawer className={classes.drawer} variant="permanent" anchor="left" classes={{ paper: classes.drawerPaper }}>
      <div>
        <Typography variant="h5" className={classes.title}></Typography>
      </div>
      <List>
        {menuItems.map(item => (
          <ListItem
            key={item.text}
            button
            onClick={() => {
              handleDrawerMenu(item.pageNo);
            }}
            className={props.page === item.pageNo ? classes.active : null}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {poeplSettingsItems.map((item, index) => (
            <ListItem
              button
              key={index}
              onClick={() => props.setPage(item.pageNo)}
              className={props.page === item.pageNo ? classes.active : null}
              style={{ marginLeft: '5vh' }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </Drawer>
  );
};

export default DrawerComponent;
