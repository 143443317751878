import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import { withRouter } from 'react-router-dom';
import { Paper } from '@material-ui/core';
import './header.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      display: 'flex',
      fontWeight: 500,
    },
    icon: {
      marginRight: theme.spacing(0.5),
      width: 20,
      height: 20,
    },
  })
);

const capitalizeFirstLetter = string => {
  const itmes = string.split('-');
  for (let i = 0; i < itmes.length; i++) {
    itmes[i] = itmes[i].charAt(0).toUpperCase() + itmes[i].substr(1);
  }
  return itmes
    .reduce((a, b) => a + ' ' + b)
    .replace(/([A-Z])/g, ' $1')
    .trim();
};

const IconBreadcrumbs = props => {
  const classes = useStyles();

  const {
    history,
    location: { pathname },
  } = props;
  const path = pathname.replace(/\d+/g, '');
  const pathnames = path.split('/').filter(x => x);

  return (
    <Paper id="breadCrumbs" style={{ padding: '0.25% 0% 0.25% 1%' }} elevation={2}>
      <Breadcrumbs style={{ color: '#F2DCF2' }} aria-label="breadcrumb">
        {pathnames.length > 0 ? (
          <Link
            onClick={() => {
              history.push('/');
            }}
            className={classes.link}
            style={{ color: '#00000066' }}
          >
            <HomeIcon style={{ fill: '#00000066' }} />
          </Link>
        ) : (
          <Typography style={{ color: '#8867f2' }} className={classes.link}>
            <HomeIcon style={{ fill: '#00000066' }} />
            <span style={{ marginTop: '2%', marginLeft: '12px' }}>Home</span>
          </Typography>
        )}

        {pathnames.map((name, index) => {
          const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
          const isLast = index === pathnames.length - 1;
          return isLast ? (
            <Typography style={{ color: '#8867f2', fontWeight: '400' }} className={classes.link}>
              {capitalizeFirstLetter(name)}
            </Typography>
          ) : (
            <Link
              onClick={() => {
                history.push(routeTo);
              }}
              className={classes.link}
              style={{ color: '#00000066' }}
            >
              {capitalizeFirstLetter(name)}
            </Link>
          );
        })}
      </Breadcrumbs>
    </Paper>
  );
};

export default withRouter(IconBreadcrumbs);
