import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import {} from 'react-jhipster';
import { IRootState } from 'app/shared/reducers';
import { getEntity, setPageNumber } from '../organization.reducer';
import Paper from '@material-ui/core/Paper';
import '../organization.scss';

export interface IOrganizationProfileComponentProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const OrganizationProfileComponent = (props: IOrganizationProfileComponentProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { organizationEntity } = props;
  return (
    <div className="organization-view-profile-details-main-container">
      <Paper className="organization-paper">
        <div className="organization-view-profile-details-container">
          <div className="organization-view-profile-details-text-div">
            <div className="organization-name-div">
              <div>{props.organizationEntity.name}</div>
            </div>

            <div className="organization-view-details">
              <div className="organization-view-left-div">
                <div className="organization-details">
                  Address:
                  <div className="organization-details-data">
                    {props.organizationEntity.address !== null ? props.organizationEntity.address : ''}
                  </div>
                </div>
                <div className="organization-details">
                  ID No:
                  <div className="organization-details-data">{props.organizationEntity.id}</div>
                </div>
              </div>

              <div className="organization-view-right-div">
                <div className="organization-details">
                  Contact Person:
                  <div className="organization-details-data">
                    {props.organizationEntity?.personFirstName + ' ' + props.organizationEntity?.personLastName}
                  </div>
                </div>
                <div className="organization-details">
                  Contact Person Email:
                  <div className="organization-details-data">{props.organizationEntity?.personEmail}</div>
                </div>
                <div className="organization-details">
                  Contact Person Phone:
                  <div className="organization-details-data">{props.organizationEntity?.personPhone}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
};

const mapStateToProps = ({ organization }: IRootState) => ({
  organizationEntity: organization.entity,
});

const mapDispatchToProps = { getEntity, setPageNumber };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationProfileComponent);
