import React, { useEffect, useState } from 'react';
import OrganizationDrawerComponent from './component/organization-drawer';
import OrganizationProfileComponent from './component/organization-profile';
import OrganizationServiceAreas from './component/organization-service_area_view';

const OrganziationView = props => {
  const [page, setPage] = useState(1);

  return (
    <div className="subcontractor-view-deisgns-main-container" style={{ padding: '32px' }}>
      <div className="settings-outer-div">
        <div className="settings-drawer-container">
          <OrganizationDrawerComponent setPage={setPage} page={page} />
        </div>
        <div className="settings-container-body">
          {page === 1 && <OrganizationProfileComponent {...props} />}
          {page === 2 && <OrganizationServiceAreas {...props} />}
        </div>
      </div>
    </div>
  );
};

export default OrganziationView;
