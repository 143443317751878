import React, { useState } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import { DocumentRecordComponent } from './documentsRecordComponent';

export const VettingProfessionalHistory = props => {
  const [isOpen, setIsOpen] = useState(false);
  const { item } = props;

  return (
    <div className="vetting-documents-professional-history">
      <EmploymentComponent item={item} />
      <EducationComponent item={item} />
    </div>
  );
};

export const EmploymentComponent = props => {
  const { item } = props;

  const changePattern = value => {
    return value.charAt(0).toUpperCase() + value.slice(1);
  };

  return (
    <React.Fragment>
      {item.EMPLOYMENT.map((data, index) => (
        <div key={index}>
          <div className="vetting-documents-professional-history-duration">
            Duration : {new Date(data.emp_start_date).getFullYear()} - {new Date(data.emp_end_date).getFullYear()}
          </div>
          <div className="vetting-documents-professional-history-table-rows-div">
            <Table style={{ borderCollapse: 'separate', borderSpacing: '0px 15px' }}>
              <TableRow>
                <TableCell scope="left" className="documents-about-you-table-cells-dark">
                  Company Name
                </TableCell>
                <TableCell align="left" className="documents-about-you-table-cells-light">
                  {data.company_name ? changePattern(data.company_name) : data.company_name}
                </TableCell>
                <TableCell align="left" className="documents-about-you-table-cells-dark">
                  Job Title
                </TableCell>
                <TableCell align="left" className="documents-about-you-table-cells-light">
                  {data.job_title ? changePattern(data.job_title) : data.job_title}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell scope="left" className="documents-about-you-table-cells-dark">
                  Contact Person Name
                </TableCell>
                <TableCell align="left" className="documents-about-you-table-cells-light">
                  {data.job_title ? changePattern(data.job_title) : data.job_title}
                </TableCell>
                <TableCell align="left" className="documents-about-you-table-cells-dark">
                  Contact Person Telephone
                </TableCell>
                <TableCell align="left" className="documents-about-you-table-cells-light">
                  {data.contact_telephone}
                </TableCell>
              </TableRow>
            </Table>
          </div>
          <div className="vetting-documents-professional-history-inner-divs">
            {' '}
            Company Location: {data.company_location ? changePattern(data.company_location) : data.company_location}{' '}
          </div>
          <div className="vetting-documents-professional-history-inner-divs">
            {' '}
            Reason of Leaving: {data.leaving_reason ? changePattern(data.leaving_reason) : data.leaving_reason}{' '}
          </div>
        </div>
      ))}
    </React.Fragment>
  );
};

export const EducationComponent = props => {
  const [isOpen, setIsOpen] = useState(false);

  const changePattern = value => {
    return value.charAt(0).toUpperCase() + value.slice(1);
  };

  const { item } = props;
  return (
    <React.Fragment>
      {item.EDUCATION.map((data, index) => (
        <div key={index}>
          <div className="vetting-documents-professional-history-duration">
            Duration : {new Date(data.start_date).getFullYear()} - {new Date(data.end_date).getFullYear()}
          </div>
          <div className="vetting-documents-professional-history-table-rows-div">
            <Table style={{ borderCollapse: 'separate', borderSpacing: '0px 15px' }}>
              <TableRow>
                <TableCell scope="left" className="documents-about-you-table-cells-dark">
                  Degree Title
                </TableCell>
                <TableCell align="left" className="documents-about-you-table-cells-light">
                  {data.degree_title ? changePattern(data.degree_title) : data.degree_title}
                </TableCell>
                <TableCell align="left" className="documents-about-you-table-cells-dark">
                  Institute Name
                </TableCell>
                <TableCell align="left" className="documents-about-you-table-cells-light">
                  {data.institute_name ? changePattern(data.institute_name) : data.institute_name}
                </TableCell>
              </TableRow>
            </Table>
          </div>
          <div className="vetting-documents-professional-history-inner-divs"> Institute Location: {data.institute_location} </div>
          <div className="vetting-documents-professional-history-inner-divs" style={{ marginTop: '10px' }}>
            <b> Attached Proof :</b>
          </div>
          <DocumentRecordComponent setIsOpen={setIsOpen} url={data.proof} isOpen={isOpen} name="Degree" />
        </div>
      ))}
    </React.Fragment>
  );
};
