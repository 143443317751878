import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import IdentificationType from './identification-type';
import IdentificationTypeDetail from './identification-type-detail';
import IdentificationTypeUpdate from './identification-type-update';
import IdentificationTypeDeleteDialog from './identification-type-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={IdentificationTypeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={IdentificationTypeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={IdentificationTypeDetail} />
      <ErrorBoundaryRoute path={match.url} component={IdentificationType} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={IdentificationTypeDeleteDialog} />
  </>
);

export default Routes;
