import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, TableRow, TableCell, Paper } from '@material-ui/core';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { getEntities, updateEntity, getApplicantDetail, updateOriginalSeenDoc } from './applicants.reducer';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import './applicants.scss';
import SearchBar from 'material-ui-search-bar';
import Board from 'react-trello';
import Spinner from '../../../commonUI/spinner/spinner';
import { ToastContainer, toast } from 'react-toastify';
import { InviteModalComponent } from './InviteModal';
import Drawer from '@material-ui/core/Drawer';
import { ApplicantSummary } from './applicantSummary';
import { getEntities as positionEntities, reset as resetPosition } from '../../../entities/designation/designation.reducer';
import { VettingStates } from '../enums/VettingStates';
import {
  getEntities as personEntities,
  createEntity as createStaff,
  deleteEntity as deletePerson,
} from '../../../entities/person/person.reducer';
import { getEntities as departmentEntities } from '../../../entities/department/department.reducer';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { getPersonalSettings } from 'app/modules/Setting/Personal-Setting.reducer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import { Vetting } from 'app/shared/layout/header/header-components';

export interface IApplicantsProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}
export const Applicants = props => {
  const { applicantsList, match, loading } = props;
  const [filterTag, setFilterTag] = useState('PROGRESS');
  const [progressBtnColor, setProgressBtnColor] = useState({ bgColor: '#4720b7', color: 'white' });
  const [completedBtnColor, setCompletedBtnColor] = useState({ bgColor: 'white', color: 'black' });
  const [activePage, setActivePage] = useState(1);
  const [searched, setSearched] = useState<string>('');
  const [summaryOpen, setSummaryOpen] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [positionsList, setPositionsList] = useState(props.designationList);
  const [departmentList, setDepartmentList] = useState(props.departmentList);
  const [filterList, setFilterList] = useState(props.applicantsList);
  const [summaryUser, setSummaryUser] = useState({});
  const [selectedDateFrom, setSelectedDateFrom] = React.useState<any>(moment(Date.now()).subtract(7, 'd').format('YYYY-MM-DD'));
  const [selectedDateTo, setSelectedDateTo] = React.useState<any>(moment(Date.now()).format('YYYY-MM-DD'));
  const [dateFormat, setdateFormat] = useState(props.setting.date_format);

  const [personList, setPersonList] = useState(props.personList);
  const [applicantSummary, setApplicantSummary] = useState(null);
  const [summaryValues, setSummaryValues] = useState({});
  const [documentOriginalSeen, setDocumentOriginalSeen] = useState({});
  const [supportingDocumentOriginalSeen, setSupportingDocumentOriginalSeen] = useState({});
  const drawerWidth = '80%';
  const useStyles = makeStyles(theme => ({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      backgroundColor: 'grey',
    },
    drawerPaper: {
      width: drawerWidth,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(1),
      backgroundColor: '#F3F3F3',
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    closeButton: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
    },
  }));
  const classes = useStyles();
  const vettingApiUrl = 'api/vetting';
  const requestSearch = (searchedVal: string) => {
    const value = searchedVal.toLowerCase();
    if (value) {
      const searchedResults = applicantsList.filter(
        x =>
          x.email.toLowerCase().indexOf(value) !== -1 ||
          x.first_name.toLowerCase().indexOf(value) !== -1 ||
          x.last_name.toLowerCase().indexOf(value) !== -1 ||
          `${x.first_name} ${x.last_name}`.toLowerCase().indexOf(value) !== -1
      );
      setFilterList(searchedResults);
    } else {
      setFilterList(applicantsList);
    }
  };

  const cancelSearch = () => {
    setSearched('');
    requestSearch(searched);
  };

  useEffect(() => {
    setdateFormat(props.setting?.date_format);
  }, [props.setting]);

  useEffect(() => {
    setDocumentOriginalSeen(summaryValues['summaryDocument']);
  }, [summaryValues]);

  useEffect(() => {
    setSupportingDocumentOriginalSeen(summaryValues['summarySupportingDocument']);
  }, [summaryValues]);

  useEffect(() => {
    props.resetPosition();
    props.getEntities({ start: selectedDateFrom, end: selectedDateTo });
    props.positionEntities(-1);
    props.departmentEntities();
    if (props.isUser) {
      props.history.push('/Logout');
    }
  }, []);

  useEffect(() => {
    props.getEntities({ start: selectedDateFrom, end: selectedDateTo });
  }, [selectedDateFrom, selectedDateTo]);

  useEffect(() => {
    setPositionsList(props.designationList.filter(x => x.is_vettable === true));
  }, [props.designationList]);

  useEffect(() => {
    setDepartmentList(props.departmentList);
  }, [props.departmentList]);

  useEffect(() => {
    setFilterList(props.applicantsList);
  }, [props.applicantsList]);

  const toggleButtons = btn => {
    if (btn === 'PROGRESS') {
      setFilterTag('PROGRESS');
      setProgressBtnColor(oldValues => ({ ...oldValues, ['bgColor']: '#4720b7', ['color']: 'white' }));
      setCompletedBtnColor(oldValues => ({ ...oldValues, ['bgColor']: 'white', ['color']: 'black' }));
    } else if (btn === 'COMPLETED') {
      setFilterTag('COMPLETED');
      setProgressBtnColor(oldValues => ({ ...oldValues, ['bgColor']: 'white', ['color']: 'black' }));
      setCompletedBtnColor(oldValues => ({ ...oldValues, ['bgColor']: '#4720b7', ['color']: 'white' }));
    }
  };

  const handleDeletePerson = id => {
    props.deletePerson(114).then(() => {});
  };
  const TrashIcon = props => {
    const { user } = props;
    return (
      <>
        {user.email}
        <span style={{ float: 'right' }}>
          <FontAwesomeIcon
            icon={faPen}
            style={{
              color: '#D58BD3',
            }}
          />
          &nbsp;
          <FontAwesomeIcon
            icon={faTrash}
            style={{
              color: '#D58BD3',
            }}
            onClick={() => {
              handleDeletePerson(user.id);
            }}
          />
        </span>
      </>
    );
  };

  const updateOriginalSeen = async (data) => {
    const keyToUpdate = Object.keys(documentOriginalSeen).find(
      (key) => documentOriginalSeen[key].id === data.id
    );
  
    if (!keyToUpdate) return;
  
    const currentData = documentOriginalSeen[keyToUpdate];
    const updatedValue = !currentData.original_seen;
  
    const response = await props.updateOriginalSeenDoc(currentData, { original_seen: updatedValue });
  
    if (response) {
      setDocumentOriginalSeen((prev) => ({
        ...prev,
        [keyToUpdate]: {
          ...prev[keyToUpdate],
          original_seen: updatedValue,
        },
      }));
    }
  };

  const supportingDocOriginalSeen = async (data) => {
    const keyToUpdate = Object.keys(supportingDocumentOriginalSeen).find(
      (key) => supportingDocumentOriginalSeen[key].id === data.id
    );
  
    if (!keyToUpdate) return;
  
    const currentData = supportingDocumentOriginalSeen[keyToUpdate];
    const updatedValue = !currentData.original_seen;
  
    const response = await props.updateOriginalSeenDoc(currentData, { original_seen: updatedValue });
  
    if (response) {
      setSupportingDocumentOriginalSeen((prev) => ({
        ...prev,
        [keyToUpdate]: {
          ...prev[keyToUpdate],
          original_seen: updatedValue,
        },
      }));
    }
  };


  const handleCardClick = async e => {
    if (summaryOpen) {
      setSummaryOpen(false);
    } else {
      setSummaryUser(filterList.filter(x => x.id === e)[0]);
      setSummaryOpen(true);
      await props
        .getApplicantDetail(e)
        .then(data => {
          if (data) {
            setSummaryValues({
              personId: e,
              requestUser: data.value.data.request_user,
              summaryScreener: data.value.data.screener_info,
              summaryPerson: data.value.data.person,
              summaryDocument: data.value.data.documents,
              summarySupportingDocument: data.value.data.supporting_document,
              summaryAboutPosition: data.value.data.position,
              summaryPersonalInformation: data.value.data.personal_information,
              summaryOrganization: data.value.data.organization,
              summaryEducation: data.value.data.professional_history.EDUCATION,
              summaryEmployment: data.value.data.professional_history.EMPLOYMENT,
              summaryEmploymentHistoryFiles: data.value.data.professional_history.EMPLOYMENT_HISTORY_FILES,
              summaryOthers: data.value.data.professional_history.OTHERS,
              summaryEmploymentReferences: data.value.data.professional_history.EMPLOYMENT_REFERENCES,
              summaryEducationReferences: data.value.data.professional_history.EDUCATION_REFERENCES,
              summaryOtherReferences: data.value.data.professional_history.OTHER_REFERENCES,
              summaryVettingActivity: data.value.data.vetting_activity,
              electoral_roll_entry: data.value.data.electoral_roll_entry,
              connect_api_information: data.value.data.connect_api_information,
              ref_tracking: data.value.data.ref_tracking,
              employment_ref_response: data.value.data.employment_ref_response,
              signed_off_data : data.value.data.signed_off_data,
              employment_history: data.value.data.employment_history
            });
          }
        })
        .catch(err => {});
    }
  };

  const handleClose = () => {
    setSummaryValues({});
    setSummaryOpen(false);
  };

  const summaryReportUpdateEntity = async data => {
    await props
      .updateEntity(data)
      .then(data => {
        if (data.action.payload.status === 200) {
          toast.success('Application status updated');
          props.getEntities({ start: selectedDateFrom, end: selectedDateTo });
        } else {
          toast.error('This transition is not allowed');
        }
      })
      .catch(err => {
        toast.error('This transition is not allowed');
      });
  };

  const handleCardDrageEnd = async (cardId, sourceLaneId, targetLaneId) => {
    if (sourceLaneId === targetLaneId) {
      return;
    }
    if (flowLogic[sourceLaneId].indexOf(targetLaneId) !== -1) {
      const data = {
        person_id: cardId,
        oldState: sourceLaneId.toString(),
        newState: targetLaneId.toString(),
      };
      await props
        .updateEntity(data)
        .then(data => {
          toast.success('Application status updated');
          const updatedApplicants = filterList.map(x => {
            if (x.id === cardId) {
              return { ...x, status: targetLaneId };
            }
            return x;
          });
          setFilterList(updatedApplicants);
          props.getEntities({ start: selectedDateFrom, end: selectedDateTo });
        })
        .catch(err => {});
    } else {
      toast.error('This transition is not allowed');
      const updatedApplicants = await filterList.map(x => {
        if (x.id === cardId) {
          return { ...x, status: sourceLaneId };
        }
        return x;
      });
      setFilterList(updatedApplicants);
    }
  };

  const flowLogic = {
    NewApplicant: [
      VettingStates.Submitted,
      VettingStates.InProgress,
      VettingStates.InvalidApplication
    ],
    Submitted: [
      VettingStates.InProgress,
      VettingStates.InvalidApplication,
      VettingStates.Failed
    ],
    InProgress: [
      VettingStates.PreVetted,
      VettingStates.InvalidApplication,
      VettingStates.Failed      
    ],
    PreVetted: [
      VettingStates.InvalidApplication,
      VettingStates.Completed,
      VettingStates.Failed
    ],
    InvalidApplication: [ 
      VettingStates.InProgress,
      VettingStates.PreVetted,
      VettingStates.Completed,
      VettingStates.Failed
    ],
  };

  const data = {
    lanes: [
      {
        id: 'NewApplicant',
        title: 'New Applications',
        draggable: false,
        cards: filterList
          .filter(x => x.status === VettingStates.NewApplicant || x.status === VettingStates.Submitted)
          .map((user, index) => {
            return {
              id: user.id,
              title: user.first_name + ' ' + user.last_name,
              description: user.email,
              showDeleteButton: false,
              draggable: user.status === VettingStates.Submitted,
              label: user.status === VettingStates.Submitted ? 'Submitted' : '',
              style: {
                backgroundColor: user.status === VettingStates.Submitted ? '#DBF3F0' : '#FFFFFF',
              },
            };
          }),
        style: {
          backgroundColor: '#EEDEFF',
          color: '#924EE4',
          width: '15%',
        },
      },
      {
        id: 'InProgress',
        title: 'In Progress (Pre-Vetting)',
        draggable: false,
        style: {
          backgroundColor: '#EEDEFF',
          color: '#924EE4',
          width: '15%',
        },
        cards: filterList
          .filter(x => x.status === VettingStates.InProgress)
          .map((user, index) => {
            return {
              id: user.id,
              title: user.first_name + ' ' + user.last_name,
              description: user.email,
              showDeleteButton: false,
              draggable: user.status === VettingStates.InProgress,
              
              style: {
                backgroundColor: '#FFFFFF',
              },
            };
          }),
      },
      {
        id: 'PreVetted',
        title: 'Screening in progress (Pre-Vetted)',
        draggable: false,
        cards: filterList
          .filter(x => x.status === VettingStates.PreVetted)
          .map((user, index) => {
            return {
              id: user.id,
              title: user.first_name + ' ' + user.last_name,
              description: user.email,
              showDeleteButton: false,
              draggable: false,
              style: {
                backgroundColor: '#FFFFFF',
                width: '250px',
                padding: '5px',
              },
            };
          }),
        style: {
          backgroundColor: '#EEDEFF',
          color: '#924EE4',
          width: '16%',
        },
      },
      {
        id: 'Completed',
        title: 'Completed',
        draggable: false,
        droppable: false,
        cards: filterList
          .filter(x => x.status === VettingStates.Completed)
          .map((user, index) => {
            return {
              id: user.id,
              title: user.first_name + ' ' + user.last_name,
              description: user.email,
              showDeleteButton: false,
              draggable: false,
              style: {
                backgroundColor: '#FFFFFF',
              },
            };
          }),
        style: {
          backgroundColor: '#DBF3F0',
          color: '#3BBDA9',
          width: '15%',
        },
      },
      {
        id: 'InvalidApplication',
        title: 'Invalid Applications',
        draggable: false,
        cards: filterList
          .filter(x => x.status === VettingStates.InvalidApplication)
          .map((user, index) => {
            return {
              id: user.id,
              title: user.first_name + ' ' + user.last_name,
              description: user.email,
              showDeleteButton: false,
              style: {
                backgroundColor: '#FFFFFF',
              },
            };
          }),
        style: {
          backgroundColor: '#FCE5E5',
          color: '#ED6F6F',
          width: '15%',
        },
      },
      {
        id: 'Failed',
        title: 'Vetting failed',
        draggable: false,
        droppable: false,
        cards: filterList
          .filter(x => x.status === VettingStates.Failed)
          .map((user, index) => {
            return {
              id: user.id,
              title: user.first_name + ' ' + user.last_name,
              description: user.email,
              showDeleteButton: false,
              draggable: false,
              style: {
                backgroundColor: '#FFFFFF',
              },
            };
          }),
        style: {
          backgroundColor: '#FCE5E5',
          color: '#ED6F6F',
          width: '15%',
        },
      },
    ],
  };

  const handleAddStaff = async (data: any) => {
    data = {
      ...data,
      processVetting: true,
      is_vetted: false,
    };
    await props.createStaff(data, null, null).then(() => {
      setModalOpen(false);
      props.getEntities({ start: selectedDateFrom, end: selectedDateTo });
    });
  };

  return (
    <div className="vetting-applicants-outer-div">
      {props.loading && <Spinner />}
      <ToastContainer position={toast.POSITION.TOP_RIGHT} className="toastify-container" toastClassName="toastify-toast" />
      <InviteModalComponent
        designations={positionsList}
        departments={departmentList}
        handleClose={state => {
          if (state === 's') {
            props.getEntities({ start: selectedDateFrom, end: selectedDateTo });
            setModalOpen(false);
          } else {
            setModalOpen(false);
          }
        }}
        handleSubmit={data => {
          handleAddStaff(data);
        }}
        open={modalOpen}
      />
      <div>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={summaryOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <ApplicantSummary
            handleClose={handleClose}
            applicantData={summaryValues}
            summaryReportUpdateEntity={summaryReportUpdateEntity}
            updateOriginalSeen={updateOriginalSeen}
            documentValues={documentOriginalSeen}
            supportingDocOriginalSeen={supportingDocOriginalSeen}
            supportingDocValue={supportingDocumentOriginalSeen}
          />
        </Drawer>
      </div>
      <div className="vettingTopButtonsDiv">
        <div className="shiftListTitleName">
          <Paper elevation={2}>
            <SearchBar
              value={searched}
              className="shiftListSearchBar"
              onChange={searchVal => requestSearch(searchVal)}
              onCancelSearch={() => cancelSearch()}
              placeholder="Search here .."
            />
          </Paper>
        </div>
        <div className="vettingCreateButtonDiv">
          <Button variant="text" style={{ color: '#619BFF' }}>
            &nbsp;&nbsp;Download History&nbsp;&nbsp;
          </Button>
          &nbsp;
          { (departmentList[0]?.organization.status !== "trial" || filterList.length < 1) &&
            <Button
              variant="contained"
              className="vetting-dashboard-addApplicant-button"
              style={{ backgroundColor: '#ED6F6F', color: 'white' }}
              onClick={e => {
                setModalOpen(true);
              }}
            >
              <FontAwesomeIcon icon="plus" />
              &nbsp;Add Applicant
            </Button>
          }
          &nbsp;
        </div>
      </div>
      <div className="vetting-dashboard">
        <Board
          data={data}
          onCardClick={handleCardClick}
          handleDragEnd={handleCardDrageEnd}
          style={{
            backgroundColor: 'white',
            width: '100%',
          }}
        />
      </div>
    </div>
  );
};

export const ApplicantsInstance = props => {
  const getProcessPercentage = status => {
    if (status === 'SUBMITTED') {
      return 25;
    } else if (status === 'LIMITED_PROCESS') {
      return 55;
    } else if (status === 'FULL_PROCESS') {
      return 75;
    } else if (status === 'REJECTED') {
      return 25;
    }
  };

  const { id, name, percentage, date, color, status } = props;

  return (
    <TableRow className="applicant-instance-table-rows">
      <TableCell className="applicant-instance-cells">
        <div className="applicants-instance-name-progress-div">
          <div className="applicants-instance-progress-div">
            <CircularProgressbar
              value={getProcessPercentage(status)}
              text={`${getProcessPercentage(status)}%`}
              strokeWidth={15}
              styles={buildStyles({
                strokeLinecap: 'butt',
                textSize: '24px',
                pathTransitionDuration: 0.5,
                pathColor: `#0e86b5`,
                textColor: '#0e86b5',
                trailColor: '#d6d6d6',
                backgroundColor: '#3e98c7',
              })}
            />
          </div>
          <div className="applicants-instance-name-div">
            <h6 style={{ alignSelf: 'center', marginBottom: 'unset', fontFamily: 'roboto' }}> {name} </h6>
          </div>
        </div>
      </TableCell>
      <TableCell className="applicant-instance-cells">
        <div className="applicants-instance-recieved-date-status-div">
          <div className="applicants-instance-recieved-date-div">
            <small> Recieved, {moment(new Date(date)).format('ll')} </small>
          </div>
          <div className="applicants-instance-status-outer-div">
            <div className="applicants-instance-status-color-box-div" style={{ backgroundColor: color }}>
              {' '}
            </div>
            <div className="applicants-instance-status-div" style={{ color: props.color }}>
              {status === 'SUBMITTED' && <i> Preliminary screening in process </i>}
              {status === 'REJECTED' && <i> Rejected</i>}
              {status === 'LIMITED_PROCESS' && <i> Limited screening in process </i>}
              {status === 'FULL_PROCESS' && <i> Full screening in process </i>}
            </div>
          </div>
        </div>
      </TableCell>
      <TableCell className="applicant-instance-cells">
        <div className="applicants-instance-buttons-div">
          <Button
            className="applicant-instance-view-detials-button"
            component={Link}
            to={{
              pathname: `vetting`,
              state: id,
            }}
          >
            View Detail
          </Button>
          {status === 'VERIFIED' && <Button className="applicant-instance-perform-checks-button"> Perform Checks </Button>}
        </div>
      </TableCell>
    </TableRow>
  );
};

const mapStateToProps = (store: IRootState) => ({
  applicantsList: store.VettingApplicants.entities,
  loading: store.VettingApplicants.loading,
  designationList: store.designation.entities,
  departmentList: store.department.entities,
  setting: store.PersonalSettings.storedPersonalSettings,
  isUser: hasAnyAuthority(store.authentication.account.authorities, [AUTHORITIES.USER]),
});

const mapDispatchToProps = {
  getEntities,
  createStaff,
  personEntities,
  positionEntities,
  departmentEntities,
  getPersonalSettings,
  updateEntity,
  getApplicantDetail,
  deletePerson,
  resetPosition,
  updateOriginalSeenDoc,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Applicants);
