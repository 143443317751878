import React, { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Grid, TextField, IconButton, Checkbox, FormControl, FormControlLabel, Divider } from '@material-ui/core';
import '../automated-control-room.scss';
import IOSSwitch from 'app/modules/Setting/RolesAndPermissionsSettings/Components/IOSswitchComponent';
import { Autocomplete } from '@material-ui/lab';
import Chip from '@material-ui/core/Chip';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import shift_confirmation_times from '../../../shared/data/shift_confirmation_times.json';
import shift_sign_in_times from '../../../shared/data/shift_sign_in_times.json';

export default function ShiftConfirmationAdvanced(props) {
  const { values, setValues } = props;
  const [shiftConfirmValue, setShiftConfirmValue] = React.useState<{ label: string; id: number }>(null);
  const [signInValue, setSignInValue] = React.useState<{ label: string; id: number }>(null);
  const [signOutValue, setSignOutValue] = React.useState<{ label: string; id: number }>(null);

  useEffect(() => {
    updateAutoCompleteBoxes(values);
  }, [props.ACRShiftSettingsValues]);

  const handleChange = (event, time, name) => {
    if (name === 'Shift Confirmation') {
      setShiftConfirmValue(time);
    }
    if (name === 'Sign In') {
      setSignInValue(time);
    }
    if (name === 'Sign Out') {
      setSignOutValue(time);
    }
    setValues(oldValues => ({
      ...oldValues,
      [name]: { ...oldValues[name], ['minimum_time']: time ? time.id : 0 },
    }));
  };

  const updateAutoCompleteBoxes = newValues => {
    if (newValues && newValues['Shift Confirmation'] && newValues['Shift Confirmation']['minimum_time']) {
      const time = Object.values(shift_confirmation_times).find(e => e.id === newValues['Shift Confirmation']['minimum_time']);
      if (time) {
        setShiftConfirmValue(time);
      } else {
        setShiftConfirmValue({ label: 'Custom', id: 0 });
      }
    }
    if (newValues && newValues['Sign In'] && newValues['Sign In']['minimum_time']) {
      const time = Object.values(shift_sign_in_times).find(e => e.id === newValues['Sign In']['minimum_time']);
      if (time) {
        setSignInValue(time);
      } else {
        setSignInValue({ label: 'Custom', id: 0 });
      }
    }
    if (newValues && newValues['Sign Out'] && newValues['Sign Out']['minimum_time']) {
      const time = Object.values(shift_sign_in_times).find(e => e.id === newValues['Sign Out']['minimum_time']);
      if (time) {
        setSignOutValue(time);
      } else {
        setSignOutValue({ label: 'Custom', id: 0 });
      }
    }
  };

  const handleTextfields = event => {
    setValues(oldValues => ({
      ...oldValues,
      [event.target.name]: { ...oldValues[event.target.name], ['minimum_time']: parseInt(event.target.value, 10) },
    }));
  };

  return (
    <div className="general-advanced-settings-main-div">
      <div className="general-advanced-settings-main-heading">
        <span>Shift Confirmation/ Sign In/ Out Times (Mins)</span>
      </div>

      <div className="general-advanced-settings-sign-in-div">
        <div className="general-advanced-settings-sign-in-heading">
          <span>Max time for Shift Confirmation before shift starts</span>
        </div>

        <Grid container spacing={1}>
          <Grid item sm={9} lg={9} md={9}></Grid>

          <Grid className="label-custom-time" item sm={3} lg={3} md={3}>
            {shiftConfirmValue && shiftConfirmValue['id'] === 0 && <label className="custom-label">Custom Time</label>}
          </Grid>
        </Grid>

        <Grid container spacing={0}>
          <Grid item xs={9} md={9} sm={9}>
            <FormControl className="send-reminder-field">
              <Autocomplete
                options={shift_confirmation_times}
                noOptionsText={'No Records'}
                classes={{ listbox: 'autocompletelistbox' }}
                ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                getOptionLabel={option => (option['label'] ? option['label'] : '')}
                getOptionSelected={(option, value) => option['id'] === value['id']}
                value={shiftConfirmValue ? shiftConfirmValue : []}
                onChange={(event, option) => handleChange(event, option, 'Shift Confirmation')}
                renderInput={params => (
                  <TextField {...params} variant="outlined" size="small" label="Time for Shift Confirmation" margin="normal" fullWidth />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item sm={3} lg={3} md={3}>
            {shiftConfirmValue && shiftConfirmValue['id'] === 0 && (
              <React.Fragment>
                <TextField
                  variant="outlined"
                  className="custom-time-field"
                  size="small"
                  margin="normal"
                  fullWidth
                  type="number"
                  onChange={handleTextfields}
                  defaultValue={0}
                  name="Shift Confirmation"
                  value={values && values['Shift Confirmation'] && values['Shift Confirmation']['minimum_time']}
                />
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      </div>

      <Divider className="general-popup-divider"></Divider>

      <div className="general-advanced-settings-sign-in-div">
        <div className="general-advanced-settings-sign-in-heading">
          <span>Max time to Sign In before shift starts</span>
        </div>

        <Grid container spacing={1}>
          <Grid item sm={9} lg={9} md={9}></Grid>

          <Grid className="label-custom-time" item sm={3} lg={3} md={3}>
            {signInValue && signInValue['id'] === 0 && <label className="custom-label">Custom Time</label>}
          </Grid>
        </Grid>

        <Grid container spacing={0}>
          <Grid item xs={9} md={9} sm={9}>
            <FormControl className="send-reminder-field">
              <Autocomplete
                options={shift_sign_in_times}
                noOptionsText={'No Records'}
                classes={{ listbox: 'autocompletelistbox' }}
                ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                getOptionLabel={option => (option['label'] ? option['label'] : '')}
                getOptionSelected={(option, value) => option['id'] === value['id']}
                value={signInValue ? signInValue : []}
                onChange={(event, option) => handleChange(event, option, 'Sign In')}
                renderInput={params => (
                  <TextField {...params} variant="outlined" size="small" label="Time for Sign In" margin="normal" fullWidth />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item sm={3} lg={3} md={3}>
            {signInValue && signInValue['id'] === 0 && (
              <React.Fragment>
                <TextField
                  variant="outlined"
                  className="custom-time-field"
                  size="small"
                  margin="normal"
                  fullWidth
                  type="number"
                  onChange={handleTextfields}
                  defaultValue={0}
                  name="Sign In"
                  value={values && values['Sign In'] && values['Sign In']['minimum_time']}
                />
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      </div>

      <Divider className="general-popup-divider"></Divider>

      <div className="general-advanced-settings-sign-in-div">
        <div className="general-advanced-settings-sign-in-heading">
          <span>Max time to Sign Out after shift ends</span>
        </div>

        <Grid container spacing={1}>
          <Grid item sm={9} lg={9} md={9}></Grid>

          <Grid className="label-custom-time" item sm={3} lg={3} md={3}>
            {signOutValue && signOutValue['id'] === 0 && <label className="custom-label">Custom Time</label>}
          </Grid>
        </Grid>

        <Grid container spacing={0}>
          <Grid item xs={9} md={9} sm={9}>
            <FormControl className="send-reminder-field">
              <Autocomplete
                options={shift_sign_in_times}
                noOptionsText={'No Records'}
                classes={{ listbox: 'autocompletelistbox' }}
                ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                getOptionLabel={option => (option['label'] ? option['label'] : '')}
                getOptionSelected={(option, value) => option['id'] === value['id']}
                value={signOutValue ? signOutValue : []}
                onChange={(event, option) => handleChange(event, option, 'Sign Out')}
                renderInput={params => (
                  <TextField {...params} variant="outlined" size="small" label="Time for Sign Out" margin="normal" fullWidth />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item sm={3} lg={3} md={3}>
            {signOutValue && signOutValue['id'] === 0 && (
              <React.Fragment>
                <TextField
                  variant="outlined"
                  className="custom-time-field"
                  size="small"
                  margin="normal"
                  fullWidth
                  type="number"
                  onChange={handleTextfields}
                  defaultValue={0}
                  name="Sign Out"
                  value={values && values['Sign Out'] && values['Sign Out']['minimum_time']}
                />
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
