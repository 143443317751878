import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { Link, RouteComponentProps } from 'react-router-dom';
import { getOrganizationSettings } from './generalSettings.reducer';
import { updateOrganizationSettings } from './generalSettings.reducer';
import { Select, MenuItem, FormControl, InputLabel, makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import 'react-toastify/dist/ReactToastify.css';
import currencies_list from '../../shared/data/currencies_list.json';
import days_of_week from '../../shared/data/days_of_week.json';
import distance_units_large_types from '../../shared/data/distance_units_large_types.json';
import distance_units_small_types from '../../shared/data/distance_units_small_types.json';
import language_types from '../../shared/data/language_types.json';
import country_types from '../../shared/data/country_types.json';
import { ToastContainer, toast } from 'react-toastify';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router';
import './index.scss';
import { Autocomplete } from '@material-ui/lab';
import { validationMessageOnChange, validationMessageBeforeSubmit } from '../../../utils/validationMessages';
import { getPrePopulateSettingInSettings } from '../../../utils/FilterValuesFucntions';
import { getEntities as getPositions } from 'app/entities/designation/designation.reducer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import { Storage } from 'react-jhipster';
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';
import { textAlign } from '@material-ui/system';

const useStyles = makeStyles(theme => ({
  selectBox: {
    width: '100%',
    margin: 'auto',
    marginTop: '1vh',
    paddingTop: '1vh',
  },
  textBox: {
    width: '100%',
    margin: 'auto',
    marginTop: '1vh',
    paddingTop: '1vh',
  },
  buttons: {
    minWidth: 100,
    marginTop: '3vh',
    marginRight: '2vh',
    float: 'right',
  },
  sideBySide: {
    display: 'flex',
    flexDirection: 'row',
  },
  active: {
    background: '#F4F4F4',
  },
  title: {
    textAlign: 'left',
    marginLeft: '10vh',
  },
  mainTitle: {
    margin: '3% 0% 0% 5%',
    textAlign: 'left',
    color: '#4720b7',
    fontWeight: 450,
    fontSize: '24px',
    fontFamily: 'Roboto',
  },
  flexContainer: {
    background: 'linear-gradient(to bottom,#F3F5F7 1%, #ffffff 99%)',
    backgroundColor: '#f3f5f7',
  },
}));

export interface IOrganizationSettingsProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

function GeneralSettings(props: IOrganizationSettingsProps) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [values, setValues] = useState(props.settings);
  const [countryOfOperations, setCountryOfOperations] = useState({
    id: props.settings.country_of_operations,
    text: getPrePopulateSettingInSettings('country_types', props.settings.country_of_operations),
  });
  const [currency, setCurrency] = useState({
    id: props.settings.currency,
    text: getPrePopulateSettingInSettings('currencies_list', props.settings.currency),
  });
  const [startDayOfWeek, setStartDayOfWeek] = useState({
    id: props.settings.start_day_of_week,
    text: getPrePopulateSettingInSettings('days_of_week', props.settings.start_day_of_week),
  });
  const [DistanceUnitsLarge, setDistanceUnitsLarge] = useState({
    id: props.settings.distance_units_large,
    text: getPrePopulateSettingInSettings('distance_units_large_types', props.settings.distance_units_large),
  });
  const [DistanceUnitsSmall, setDistanceUnitsSmall] = useState({
    id: props.settings.distance_units_small,
    text: getPrePopulateSettingInSettings('distance_units_small_types', props.settings.distance_units_small),
  });
  const [Language, setLanguage] = useState({
    id: props.settings.language,
    text: getPrePopulateSettingInSettings('language_types', props.settings.language),
  });

  const [image, setImage] = useState(null);
  const [first_login] = useState(Storage.local.get('first_login'));
  const [run, setRunState] = useState(false);
  const [runAgain, setRunAgainState] = useState(false);

  const [fieldError, setFieldError] = useState({
    company_name_error: false,
    company_name_helper: '',
    company_address_error: false,
    company_address_helper: '',
    company_phone_error: false,
    company_phone_helper: '',
    company_mobile_phone_error: false,
    company_mobile_phone_helper: '',
    operations_phone_error: false,
    operations_phone_helper: '',
    company_email_error: false,
    company_email_helper: '',
    operations_email_error: false,
    operations_email_helper: '',
    currency_error: false,
    start_day_of_week_error: false,
  });

  useEffect(() => {
    props.getOrganizationSettings();
    if (first_login === 'true' && props.isOrganizationAdministrator && !props.settings.company_address) {
      setRunState(true);
    }
  }, []);

  useEffect(() => {
    setValues(props.settings);
    setCountryOfOperations({
      id: props.settings.country_of_operations,
      text: getPrePopulateSettingInSettings('country_types', props.settings.country_of_operations),
    });
    setCurrency({ id: props.settings.currency, text: getPrePopulateSettingInSettings('currencies_list', props.settings.currency) });
    setStartDayOfWeek({
      id: props.settings.start_day_of_week,
      text: getPrePopulateSettingInSettings('days_of_week', props.settings.start_day_of_week),
    });
    setDistanceUnitsLarge({
      id: props.settings.distance_units_large,
      text: getPrePopulateSettingInSettings('distance_units_large_types', props.settings.distance_units_large),
    });
    setDistanceUnitsSmall({
      id: props.settings.distance_units_small,
      text: getPrePopulateSettingInSettings('distance_units_small_types', props.settings.distance_units_small),
    });
    setLanguage({ id: props.settings.language, text: getPrePopulateSettingInSettings('language_types', props.settings.language) });
  }, [props.settings]);

  useEffect(() => {
    if (props.settings.logo && props.settings.logo !== null) {
      setImage(props.settings.logo.file);
    }
  }, [props.settings.logo]);

  const setNewValues = e => {
    setValues(oldValues => ({ ...oldValues, [e.target.name]: e.target.value }));
    if (e.target.name === 'company_name' && (!/^(?! )[a-zA-Z\s().-]*$/.test(e.target.value) || e.target.value === '')) {
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: true }));
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_helper']: validationMessageOnChange.name }));
    } else if (e.target.name === 'company_address' && e.target.value === '') {
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: true }));
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_helper']: validationMessageOnChange.address }));
    } else if ((e.target.name === 'start_day_of_week' || e.target.name === 'currency') && e.target.value === null) {
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: true }));
    } else if (
      (e.target.name === 'company_phone' || e.target.name === 'company_mobile_phone' || e.target.name === 'operations_phone') &&
      !/^(?:[0-9 +]*)$/.test(e.target.value)
    ) {
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: true }));
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_helper']: validationMessageOnChange.number }));
    } else if (
      (e.target.name === 'company_email' || e.target.name === 'operations_email') &&
      !/^(([^<>()[\],;:\s@]+(\.[^<>()[\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+\.)+[^<>()[\],;:\s@]{2,})$/i.test(e.target.value)
    ) {
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: true }));
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_helper']: validationMessageOnChange.email }));
    } else {
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: false, [e.target.name + '_helper']: '' }));
    }
  };

  const handleUploadImage = e => {
    setValues(oldValues => ({ ...oldValues, ['organization_image']: e.target.files[0] }));
    if (e.target.files && e.target.files[0]) {
      setImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleGeneralSettingData = (_, e, name) => {
    if (e) {
      if (name === 'country_of_operations') {
        setCountryOfOperations({ id: e.id, text: e.text });
      } else if (name === 'currency') {
        setCurrency({ id: e.id, text: e.text });
      } else if (name === 'start_day_of_week') {
        setStartDayOfWeek({ id: e.id, text: e.text });
      } else if (name === 'distance_units_large') {
        setDistanceUnitsLarge({ id: e.id, text: e.text });
      } else if (name === 'distance_units_small') {
        setDistanceUnitsSmall({ id: e.id, text: e.text });
      } else if (name === 'language') {
        setLanguage({ id: e.id, text: e.text });
      }
      setValues(oldValues => ({ ...oldValues, [name]: e.id }));
    } else {
      setValues(oldValues => ({ ...oldValues, [name]: null }));
    }
  };

  const checkForValidations = () => {
    const result =
      !fieldError['company_name_error'] &&
      !fieldError['company_address_error'] &&
      !fieldError['currency_error'] &&
      !fieldError['start_day_of_week_error'] &&
      !(values['company_name'] === undefined) &&
      !(values['company_address'] === undefined) &&
      !(values['currency'] === undefined) &&
      !(values['start_day_of_week'] === undefined);
    return result;
  };

  const checkValidationsBeforeSubmit = () => {
    if (values['company_name'] === undefined) {
      setFieldError(oldFieldError => ({ ...oldFieldError, ['company_name_error']: true }));
      setFieldError(oldFieldError => ({ ...oldFieldError, ['company_name_helper']: validationMessageBeforeSubmit.name }));
    }
    if (values['company_address'] === undefined) {
      setFieldError(oldFieldError => ({ ...oldFieldError, ['company_address_error']: true }));
      setFieldError(oldFieldError => ({ ...oldFieldError, ['company_address_helper']: validationMessageBeforeSubmit.address }));
    }
    if (values['currency'] === undefined) {
      setFieldError(oldFieldError => ({ ...oldFieldError, ['currency_error']: true }));
    }
    if (values['start_day_of_week'] === undefined) {
      setFieldError(oldFieldError => ({ ...oldFieldError, ['start_day_of_week_error']: true }));
    }
  };

  const submit = () => {
    event.preventDefault();
    if (checkForValidations()) {
      const formData = new FormData();
      if (values['organization_image'] !== undefined) {
        formData.append('avatar', values['organization_image']);
      }
      props.updateOrganizationSettings(values, formData);
      if (first_login === 'true' && props.isOrganizationAdministrator) {
        setRunAgainState(true);
      }
    } else {
      checkValidationsBeforeSubmit();
    }
  };

  const reset = () => {
    setValues(oldValues => ({ ...oldValues, ['company_name']: null }));
    setValues(oldValues => ({ ...oldValues, ['company_address']: null }));
    setValues(oldValues => ({ ...oldValues, ['company_phone']: null }));
    setValues(oldValues => ({ ...oldValues, ['company_mobile_phone']: null }));
    setValues(oldValues => ({ ...oldValues, ['operations_phone']: null }));
    setValues(oldValues => ({ ...oldValues, ['company_email']: null }));
    setValues(oldValues => ({ ...oldValues, ['operations_email']: null }));
    setValues(oldValues => ({ ...oldValues, ['country_of_operations']: null }));
    setValues(oldValues => ({ ...oldValues, ['currency']: null }));
    setValues(oldValues => ({ ...oldValues, ['company_office_hours']: null }));
    setValues(oldValues => ({ ...oldValues, ['start_day_of_week']: null }));
    setValues(oldValues => ({ ...oldValues, ['distance_units_large']: null }));
    setValues(oldValues => ({ ...oldValues, ['distance_units_small']: null }));
    setValues(oldValues => ({ ...oldValues, ['language']: null }));
    setImage(null);
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, type } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRunState(false);
    }
  };

  const handleJoyrideCallbackAgain = (data: CallBackProps) => {
    const { action, index, type, status } = data;

    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
    if (finishedStatuses.includes(status)) {
      setRunAgainState(false);
    }
  };

  return (
    <>
      <Joyride
        callback={data => {
          handleJoyrideCallback(data);
        }}
        run={run}
        continuous={true}
        locale={{
          last: (
            <strong style={{ fontSize: '17px' }} aria-label="last">
              Next
            </strong>
          ),
          back: <strong aria-label="back">Previous</strong>,
          next: (
            <strong style={{ fontSize: '17px' }} aria-label="next">
              Next
            </strong>
          ),
        }}
        styles={{
          buttonNext: {
            background: '#d48bd3',
          },
          buttonSkip: {
            background: '#f7b017',
          },
          tooltipContainer: {
            height: 75,
          },
          options: {
            width: 300,
          },
        }}
        steps={[
          {
            target: '.headerIconOther',
            content: (
              <>
                <h2 style={{ fontSize: '20px' }}>Let&apos;s Start</h2>
                <h1 style={{ fontSize: '17px' }}>
                  Complete your <b style={{ color: '#7a55de' }}>Profile</b> first
                </h1>
              </>
            ),
            placement: 'center',
            styles: {},
          },
        ]}
      />
      <Joyride
        callback={data => {
          handleJoyrideCallbackAgain(data);
        }}
        run={runAgain}
        continuous={true}
        locale={{
          last: <strong aria-label="last">Next</strong>,
          back: <strong aria-label="back">Previous</strong>,
          next: <strong aria-label="next">Next</strong>,
        }}
        disableScrolling
        showSkipButton
        styles={{
          buttonNext: {
            background: '#d48bd3',
          },
          buttonSkip: {
            background: '#f7b017',
          },
          tooltipContainer: {
            height: 100,
          },
          options: {
            width: 300,
          },
        }}
        steps={[
          {
            target: '.headerIconOther',
            title: (
              <h2 style={{ fontSize: '20px' }}>
                Tap <b>Others</b>
              </h2>
            ),
            content: (
              <h1 style={{ fontSize: '17px' }}>
                Click on <b style={{ color: '#7a55de' }}>Clients</b> from dropdown menu
              </h1>
            ),
            disableBeacon: true,
            disableOverlayClose: true,
            hideCloseButton: true,
            hideFooter: true,
            spotlightClicks: true,
            placement: 'bottom',
          },
        ]}
      />
      <React.Fragment>
        <div className={classes.flexContainer}>
          <Typography variant="h5" className={classes.mainTitle}>
            General Settings
          </Typography>
          <div style={{ textAlign: 'left', paddingBottom: '2%', marginLeft: '5%' }}>Edit your information below</div>
          <div className="general-settings-outer-div">
            <div className="addWhiteSpaceInGeneralSetting" />
            <Typography variant="h6" className={classes.title}>
              Edit Profile
            </Typography>
            <div style={{ display: 'flex' }}>
              <form style={{ width: '90%' }}>
                <Paper className="general-settings-paper1" square>
                  <div className={classes.sideBySide}>
                    <Grid item xs={12} md={12} sm={12} justify="flex-start">
                      <FormControl className={classes.textBox}>
                        <TextField
                          required
                          id="companyName"
                          variant="outlined"
                          name="company_name"
                          inputProps={{ min: 0, style: { textAlign: 'left' }, maxLength: 255 }}
                          value={values['company_name'] ? values['company_name'] : ''}
                          onChange={setNewValues}
                          label="Company Name"
                          fullWidth
                          autoComplete="given-name"
                          error={fieldError['company_name_error']}
                          helperText={fieldError['company_name_helper']}
                        />
                      </FormControl>
                    </Grid>
                  </div>

                  <div className={classes.sideBySide}>
                    <Grid item xs={12} md={12} sm={12} justify="flex-start">
                      <FormControl className={classes.textBox}>
                        <TextField
                          required
                          id="companyAddress"
                          variant="outlined"
                          name="company_address"
                          inputProps={{ min: 0, style: { textAlign: 'left' }, maxLength: 255 }}
                          value={values['company_address'] ? values['company_address'] : ''}
                          onChange={setNewValues}
                          label="Company Address"
                          fullWidth
                          autoComplete="given-name"
                          error={fieldError['company_address_error']}
                          helperText={fieldError['company_address_helper']}
                        />
                      </FormControl>
                    </Grid>
                  </div>

                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} sm={12} justify="flex-start">
                      <FormControl className={classes.textBox}>
                        <TextField
                          id="companyEmail"
                          variant="outlined"
                          name="company_email"
                          inputProps={{ min: 0, style: { textAlign: 'left' }, maxLength: 255 }}
                          value={values['company_email'] ? values['company_email'] : ''}
                          onChange={setNewValues}
                          label="Company Email"
                          fullWidth
                          autoComplete="given-name"
                          error={fieldError['company_email_error']}
                          helperText={fieldError['company_email_helper']}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6} sm={12} justify="flex-start">
                      <FormControl className={classes.textBox}>
                        <TextField
                          id="companyMobilePhone"
                          variant="outlined"
                          name="company_mobile_phone"
                          inputProps={{ min: 0, style: { textAlign: 'left' }, maxLength: 16 }}
                          value={values['company_mobile_phone'] ? values['company_mobile_phone'] : ''}
                          onChange={setNewValues}
                          label="Company Mobile"
                          fullWidth
                          autoComplete="given-name"
                          error={fieldError['company_mobile_phone_error']}
                          helperText={fieldError['company_mobile_phone_helper']}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} sm={12} justify="flex-start">
                      <FormControl className={classes.textBox}>
                        <TextField
                          id="companyPhone"
                          variant="outlined"
                          name="company_phone"
                          inputProps={{ min: 0, style: { textAlign: 'left' }, maxLength: 16 }}
                          value={values['company_phone'] ? values['company_phone'] : ''}
                          onChange={setNewValues}
                          label="Company Landline"
                          fullWidth
                          autoComplete="given-name"
                          error={fieldError['company_phone_error']}
                          helperText={fieldError['company_phone_helper']}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6} sm={12} justify="flex-start">
                      <FormControl className={classes.textBox}>
                        <TextField
                          id="companyOfficeHours"
                          variant="outlined"
                          name="company_office_hours"
                          inputProps={{ min: 0, style: { textAlign: 'left' } }}
                          value={values['company_office_hours'] ? values['company_office_hours'] : ''}
                          onChange={setNewValues}
                          label="Company Office Hours"
                          fullWidth
                          autoComplete="given-name"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} sm={12} justify="flex-start">
                      <FormControl className={classes.textBox}>
                        <TextField
                          id="operationsEmail"
                          variant="outlined"
                          name="operations_email"
                          inputProps={{ min: 0, style: { textAlign: 'left' }, maxLength: 255 }}
                          value={values['operations_email'] ? values['operations_email'] : ''}
                          onChange={setNewValues}
                          label="Operations Email"
                          fullWidth
                          autoComplete="given-name"
                          error={fieldError['operations_email_error']}
                          helperText={fieldError['operations_email_helper']}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6} sm={12} justify="flex-start">
                      <FormControl className={classes.textBox}>
                        <TextField
                          id="operationsPhone"
                          variant="outlined"
                          name="operations_phone"
                          inputProps={{ min: 0, style: { textAlign: 'left' }, maxLength: 16 }}
                          value={values['operations_phone'] ? values['operations_phone'] : ''}
                          onChange={setNewValues}
                          label="Operations Phone"
                          fullWidth
                          autoComplete="given-name"
                          error={fieldError['operations_phone_error']}
                          helperText={fieldError['operations_phone_helper']}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} sm={12} justify="flex-start">
                      <FormControl className={classes.selectBox}>
                        <Autocomplete
                          options={
                            country_types &&
                            country_types.map(countryTypes => ({
                              id: countryTypes.value,
                              text: countryTypes.label,
                            }))
                          }
                          noOptionsText={'No Records'}
                          classes={{ listbox: 'autocompletelistbox' }}
                          ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                          getOptionLabel={option => option.text || ''}
                          className="PersonAutocomplete"
                          onChange={(_, e, name) => handleGeneralSettingData(_, e, 'country_of_operations')}
                          value={countryOfOperations}
                          renderInput={params => (
                            <TextField
                              {...params}
                              variant="outlined"
                              size="small"
                              name="country_of_operations"
                              className="personAutocompleteTextfield"
                              label="Country Of Operations"
                              margin="normal"
                              fullWidth
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid container item xs={12} md={6} spacing={2}></Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} sm={12} justify="flex-start">
                      <FormControl className={classes.selectBox}>
                        <Autocomplete
                          options={
                            currencies_list &&
                            currencies_list.map(currencyList => ({
                              id: currencyList.value,
                              text: currencyList.label + ' ' + currencyList.symbol,
                            }))
                          }
                          noOptionsText={'No Records'}
                          classes={{ listbox: 'autocompletelistbox' }}
                          ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                          getOptionLabel={option => option.text || ''}
                          className="PersonAutocomplete"
                          onChange={(_, e, name) => handleGeneralSettingData(_, e, 'currency')}
                          value={currency}
                          renderInput={params => (
                            <TextField
                              {...params}
                              variant="outlined"
                              size="small"
                              name="currency"
                              className="personAutocompleteTextfield autocompleteLabel"
                              label="Currency"
                              margin="normal"
                              fullWidth
                              error={fieldError['currency_error']}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6} sm={12} justify="flex-start">
                      <FormControl className={classes.selectBox}>
                        <Autocomplete
                          options={
                            days_of_week &&
                            days_of_week.map(days => ({
                              id: days.value,
                              text: days.label,
                            }))
                          }
                          noOptionsText={'No Records'}
                          classes={{ listbox: 'autocompletelistbox' }}
                          ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                          getOptionLabel={option => option.text || ''}
                          className="PersonAutocomplete"
                          onChange={(_, e, name) => handleGeneralSettingData(_, e, 'start_day_of_week')}
                          value={startDayOfWeek}
                          renderInput={params => (
                            <TextField
                              {...params}
                              variant="outlined"
                              size="small"
                              name="start_day_of_week"
                              className="personAutocompleteTextfield autocompleteLabel"
                              label="Start Day Of Week"
                              margin="normal"
                              fullWidth
                              error={fieldError['start_day_of_week_error']}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Paper>

                <div className="addWhiteSpaceInGeneralSetting" />
                <Typography variant="h6" className={classes.title}>
                  Distance/ Language Setting
                </Typography>
                <br />
                <Paper className="general-settings-paper2" square>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} sm={12} justify="flex-start">
                      <FormControl className={classes.selectBox}>
                        <Autocomplete
                          options={
                            distance_units_large_types &&
                            distance_units_large_types.map(units => ({
                              id: units.value,
                              text: units.label,
                            }))
                          }
                          noOptionsText={'No Records'}
                          classes={{ listbox: 'autocompletelistbox' }}
                          ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                          getOptionLabel={option => option.text || ''}
                          className="PersonAutocomplete"
                          onChange={(_, e, name) => handleGeneralSettingData(_, e, 'distance_units_large')}
                          value={DistanceUnitsLarge}
                          renderInput={params => (
                            <TextField
                              {...params}
                              variant="outlined"
                              size="small"
                              name="distance_units_large"
                              className="personAutocompleteTextfield"
                              label="Distance Units (Large)"
                              margin="normal"
                              fullWidth
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6} sm={12} justify="flex-start">
                      <FormControl className={classes.selectBox}>
                        <Autocomplete
                          options={
                            distance_units_small_types &&
                            distance_units_small_types.map(units => ({
                              id: units.value,
                              text: units.label,
                            }))
                          }
                          noOptionsText={'No Records'}
                          classes={{ listbox: 'autocompletelistbox' }}
                          ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                          getOptionLabel={option => option.text || ''}
                          className="PersonAutocomplete"
                          onChange={(_, e, name) => handleGeneralSettingData(_, e, 'distance_units_small')}
                          value={DistanceUnitsSmall}
                          renderInput={params => (
                            <TextField
                              {...params}
                              variant="outlined"
                              size="small"
                              name="distance_units_small"
                              className="personAutocompleteTextfield"
                              label="Distance Units (Small)"
                              margin="normal"
                              fullWidth
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} sm={12} justify="flex-start">
                      <FormControl className={classes.selectBox}>
                        <Autocomplete
                          options={
                            language_types &&
                            language_types.map(langauge => ({
                              id: langauge.value,
                              text: langauge.label,
                            }))
                          }
                          noOptionsText={'No Records'}
                          classes={{ listbox: 'autocompletelistbox' }}
                          ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                          getOptionLabel={option => option.text || ''}
                          className="PersonAutocomplete"
                          onChange={(_, e, name) => handleGeneralSettingData(_, e, 'language')}
                          value={Language}
                          renderInput={params => (
                            <TextField
                              {...params}
                              variant="outlined"
                              size="small"
                              name="language"
                              className="personAutocompleteTextfield"
                              label="Language"
                              margin="normal"
                              fullWidth
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6} sm={12} justify="flex-start" />
                  </Grid>
                </Paper>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <FormControl className="organizationSetting-fields organizationSettingSaveDiscardButtons">
                    <Button variant="outlined" className="DiscardButtonLayout organizationSettingDiscardButton" href="/">
                      Cancel
                    </Button>
                    &nbsp;
                    <Button
                      onClick={() => {
                        submit();
                      }}
                      variant="contained"
                      id="save-entity"
                      type="submit"
                      className="SaveButtonLayout organizationSettingSaveButton"
                    >
                      Save Changes
                    </Button>
                  </FormControl>
                </Grid>
              </form>

              <div className="organizationImageDiv">
                <div className="organizationImageLayoutOuter">
                  <img src={image ? image : null} className="organizationImageLayoutInner" />
                </div>
                <input type="file" id="file-input" name="organization_image" onChange={handleUploadImage} style={{ display: 'none' }} />
                <label htmlFor="file-input">
                  <Button variant="contained" component="span" className="organizationImageButton">
                    Upload a picture
                  </Button>
                </label>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    </>
  );
}

const mapStateToProps = (storeState: IRootState) => ({
  settings: storeState.organizationSettings.storedOrganizationSettings,
  uploadedAvatar: storeState.organizationSettings.uploadedAvatar,
  postion: storeState.designation.entities,
  isOrganizationAdministrator: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.ORGANIZATION_ADMINISTRATOR]),
});

const mapDispatchToProps = {
  updateOrganizationSettings,
  getOrganizationSettings,
  getPositions,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(GeneralSettings);
