import jsPDF from 'jspdf';
import 'jspdf-autotable';
import autoTable from 'jspdf-autotable';
import moment from 'moment';

export const exportPDF = (header, reportTitle, reportContent, filename) => {
  const unit = 'mm';
  const size = [500, 700];
  const orientation = 'portrait';
  const img = 'content/images/ReportPDF.png';

  const doc = new jsPDF(orientation, unit, size);
  const headers = header;
  const data = reportContent;
  const title = reportTitle;

  doc.addImage(img, 'PNG', 15, 10, 470, 25);
  doc.setFont('Roboto');
  doc.setFontSize(30).text(title, 25, 25);

  autoTable(doc, {
    startY: 50,
    head: headers,
    body: data,
    styles: { halign: 'left' },
    headStyles: { fillColor: [255, 214, 125], textColor: [0, 0, 0], fontSize: 14 },
    bodyStyles: { fontSize: 14 },
    alternateRowStyles: { fillColor: [249, 238, 248] },
    tableLineColor: [249, 238, 248],
    tableLineWidth: 0.1,
  });
  doc.save(filename);
};
