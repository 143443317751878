import React from 'react';
import { TextField, Grid, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Paper } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import CustomToolbarComponent from '../service-area/CustomToolbarComponent';
import './person.scss';
import { getDateFormat } from 'app/shared/util/calender-utils';
import { IPerson } from 'app/shared/model/person.model';
import { IPersonalSettings } from 'app/shared/model/personalSetting.model';
import Divider from '@material-ui/core/Divider';

interface PersonUpdateVisaInformationProps {
  visaRequired?: string | any;
  showDivs?: boolean | any;
  handleHideShowDiv?: (data: any, value: boolean) => void;
  visaRequiredhandleChange?: (data: any) => void;
  setFormValues?: (data: any) => void;
  personEntity?: IPerson | any;
  fieldValues?: IPerson | any;
  fieldError?: Record<string, any>;
  setting?: IPersonalSettings | any;
  visaRequiredDate?: Date | any;
  handleVisaExpiryDate?: (data: any) => void;
}

const PersonUpdateVisaInformation: React.FC<PersonUpdateVisaInformationProps> = props => {
  return (
    <React.Fragment>
      <Paper className="personOtherPaper">
        <Grid item xs={12} md={12} sm={12}>
          <FormControl component="fieldset" className="person-fields">
            <FormLabel component="legend" style={{ color: 'black' }}>
              Right to Work?
            </FormLabel>
            <RadioGroup
              aria-label="visa_required"
              name="visa_required"
              className="personRadioButtons"
              value={props.fieldValues['visa_required'] ? props.fieldValues['visa_required'] : null}
              onChange={props.visaRequiredhandleChange}
            >
              <FormControlLabel
                value="yes"
                control={<Radio style={{ color: '#4620B7' }} onClick={() => props.handleHideShowDiv('visaRequired', true)} value="yes" />}
                label="Yes"
              />
              <FormControlLabel
                value="no"
                control={<Radio style={{ color: '#4620B7' }} onClick={() => props.handleHideShowDiv('visaRequired', false)} value="no" />}
                label="No"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        {props.showDivs.visaRequired && (
          <>
            <div className="personFieldDivsideBySide">
              <Grid item xs={12} md={6} sm={12}>
                <FormControl className="person-fields">
                  <TextField
                    variant="outlined"
                    onChange={props.setFormValues}
                    placeholder="Write your description here"
                    label="Visa Details"
                    name="visaDetails"
                    id="person_department"
                    defaultValue={props.personEntity.visaDetails}
                    value={props.fieldValues['visaDetails'] ? props.fieldValues['visaDetails'] : ''}
                    error={props.fieldError['visaDetails_error']}
                    inputProps={{ maxLength: 255 }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} sm={12}>
                <FormControl className="person-fields">
                  <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                    <KeyboardDatePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      disablePast
                      label="Visa Expiry"
                      format={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'MM/DD/YYYY'}
                      value={props.fieldValues['visaRequiredDate']}
                      ToolbarComponent={prop => (
                        <>
                          <CustomToolbarComponent {...prop} settingFormat={props.setting.date_format} />
                        </>
                      )}
                      placeholder={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'MM/DD/YYYY'}
                      InputAdornmentProps={{ position: 'start' }}
                      onChange={date => props.handleVisaExpiryDate(date)}
                    />{' '}
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Grid>
            </div>
          </>
        )}
      </Paper>
    </React.Fragment>
  );
};
export default PersonUpdateVisaInformation;
