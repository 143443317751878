import dayjs from 'dayjs';

export interface IEmploymentDetails {
  id?: number;
  startYear?: string | null;
  jobTitle?: string | null;
  organizationName?: string | null;
  description?: string | null;
  pay_rate?: string | null;
  pay_rate_effective_date?: string | null;
  pay_rate_start_date?: string | null;
}

export const defaultValue: Readonly<IEmploymentDetails> = {};
