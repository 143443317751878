import React from 'react';
import { useState } from 'react';
import { Button, FormGroup, FormControl, Grid, Checkbox } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Modal from '@material-ui/core/Modal';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import '../../../modules/calender/calendar.scss';
import TextField from '@material-ui/core/TextField';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import { deleteStaffEntity } from '../person.reducer';
import { useHistory } from 'react-router-dom';

export interface IModalComponent {
  showDeleteModal: boolean;
  deleteStaff: () => void;
  setShowDeleteModal: (value: boolean) => void;
}

export const DeleteModal = (props: IModalComponent) => {
  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const { showDeleteModal, setShowDeleteModal, deleteStaff } = props;
  const history = useHistory();
  const handleDelete = () => {
    deleteStaff();
    setShowDeleteModal(false);
    history.push(`/staff`);
  };

  return (
    <Modal open={showDeleteModal} onClose={handleCloseDeleteModal} className="create-modal">
      <div className="delete-shift-modal-paper">
        <h2 className="shift-modal-title">Delete Staff</h2>

        <p>Are you sure you want to delete this Staff?</p>

        <FormControl className="modal-forms" style={{ marginTop: '20px' }}>
          <FormGroup row={true}>
            <Grid item xs={3} lg={4}>
              <Button variant="contained" className="delete-modal-button-cancel" onClick={handleCloseDeleteModal}>
                Cancel
              </Button>
            </Grid>

            <Grid item xs={3} lg={4}>
              <Button
                variant="contained"
                className="delete-modal-button-save"
                onClick={() => {
                  handleDelete();
                }}
              >
                Delete
              </Button>
            </Grid>
          </FormGroup>
        </FormControl>
      </div>
    </Modal>
  );
};

export default DeleteModal;
