import React from 'react';
import PermissionsRowComponent from './PermissionsRowComponent';

export default function PermissionsDataComponent(props) {
  return (
    <React.Fragment>
      <PermissionsRowComponent
        msg="Can add,edit and remove clients ?"
        ls={props.supervisorPerm && props.supervisorPerm.add_remove_clients}
        ds={props.userPerm && props.userPerm.add_remove_clients}
        ma={props.mngrPerm && props.mngrPerm.add_remove_clients}
        sa={props.orgAdminPerm && props.orgAdminPerm.add_remove_clients}
        name="add_remove_clients"
        handler={props.handleChange}
      />
      <PermissionsRowComponent
        msg="Can add,edit and remove locations?"
        ls={props.supervisorPerm && props.supervisorPerm.add_remove_locations}
        ds={props.userPerm && props.userPerm.add_remove_locations}
        ma={props.mngrPerm && props.mngrPerm.add_remove_locations}
        sa={props.orgAdminPerm && props.orgAdminPerm.add_remove_locations}
        name="add_remove_locations"
        handler={props.handleChange}
      />
      <PermissionsRowComponent
        msg="Can see,add,edit and remove notes for clients, locations and people?"
        ls={props.supervisorPerm && props.supervisorPerm.add_remove_notes}
        ds={props.userPerm && props.userPerm.add_remove_notes}
        ma={props.mngrPerm && props.mngrPerm.add_remove_notes}
        sa={props.orgAdminPerm && props.orgAdminPerm.add_remove_notes}
        name="add_remove_notes"
        handler={props.handleChange}
      />
      <PermissionsRowComponent
        msg="Can see details for people excluding documents, assets and action history?"
        ls={props.supervisorPerm && props.supervisorPerm.details_for_people}
        ds={props.userPerm && props.userPerm.details_for_people}
        ma={props.mngrPerm && props.mngrPerm.details_for_people}
        sa={props.orgAdminPerm && props.orgAdminPerm.details_for_people}
        name="details_for_people"
        handler={props.handleChange}
      />
      <PermissionsRowComponent
        msg="Can add and edit details for people including documents and assets?"
        ls={props.supervisorPerm && props.supervisorPerm.can_add_and_edit_details_for_people}
        ds={props.userPerm && props.userPerm.can_add_and_edit_details_for_people}
        ma={props.mngrPerm && props.mngrPerm.can_add_and_edit_details_for_people}
        sa={props.orgAdminPerm && props.orgAdminPerm.can_add_and_edit_details_for_people}
        name="can_add_and_edit_details_for_people"
        handler={props.handleChange}
      />
      <PermissionsRowComponent
        msg="Can edit login credentials password and email for people?"
        ls={props.supervisorPerm && props.supervisorPerm.can_edit_login_credentials}
        ds={props.userPerm && props.userPerm.can_edit_login_credentials}
        ma={props.mngrPerm && props.mngrPerm.can_edit_login_credentials}
        sa={props.orgAdminPerm && props.orgAdminPerm.can_edit_login_credentials}
        name="can_edit_login_credentials"
        handler={props.handleChange}
      />
      <PermissionsRowComponent
        msg="Can see,add, edit and manage subcontractors?"
        ls={props.supervisorPerm && props.supervisorPerm.manage_subcontractors}
        ds={props.userPerm && props.userPerm.manage_subcontractors}
        ma={props.mngrPerm && props.mngrPerm.manage_subcontractors}
        sa={props.orgAdminPerm && props.orgAdminPerm.manage_subcontractors}
        name="manage_subcontractors"
        handler={props.handleChange}
      />
    </React.Fragment>
  );
}
