import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Grid, FormControl, TextField, MenuItem } from '@material-ui/core';
import { Row, Col, Label } from 'reactstrap';
import { AvFeedback, form, AvInput } from 'availity-reactstrap-validation';
import { Translate, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import './employment-status.scss';
import { getEntity, updateEntity, createEntity, reset } from './employment-status.reducer';
import { IEmploymentStatus } from 'app/shared/model/employment-status.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface IEmploymentStatusUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const EmploymentStatusUpdate = (props: IEmploymentStatusUpdateProps) => {
  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const { employmentStatusEntity, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/employment-status');
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }
  }, []);

  useEffect(() => {}, [employmentStatusEntity]);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = event => {
    event.preventDefault();

    const entity = {
      id: event.target.id.value,
      name: event.target.name.value,
      urduName: event.target.urduName.value,
    };

    if (isNew) {
      props.createEntity(entity);
    } else {
      props.updateEntity(entity);
    }
  };

  return (
    <div className="employmentStatus-full-container">
      <Grid item md={12} container justify="center">
        <Grid item md={2}></Grid>
        <Grid item md={8}>
          <h2 id="wfmApp.employmentStatus.home.createOrEditLabel" data-cy="EmploymentStatusCreateUpdateHeading">
            <Translate contentKey="wfmApp.employmentStatus.home.createOrEditLabel">Create or edit a EmploymentStatus</Translate>
          </h2>
        </Grid>
      </Grid>
      <Grid item md={12} container justify="center" spacing={1}>
        <Grid item md={2}></Grid>
        <Grid item md={8} spacing={2}>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <form onSubmit={saveEntity} className="employmentStatus-form">
              {!isNew ? (
                <Grid item md={12} container>
                  <Grid item md={12} xs={12}>
                    <FormControl className="employmentStatus-fields">
                      <TextField
                        id="employment-status-id"
                        className="employmentstatusid"
                        variant="outlined"
                        name="id"
                        required
                        inputProps={{ readOnly: true }}
                        label={<Translate contentKey="global.field.id">ID</Translate>}
                        value={employmentStatusEntity.id}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              ) : null}
              <Grid item md={12} container>
                <Grid item md={12} xs={12}>
                  <FormControl className="employmentStatus-fields">
                    <TextField
                      id="employment-status-name"
                      data-cy="name"
                      className="employmentstatusname"
                      variant="outlined"
                      name="name"
                      label={<Translate contentKey="wfmApp.employmentStatus.name">Name</Translate>}
                      defaultValue={employmentStatusEntity.name}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item md={12} container>
                <Grid item md={12} xs={12}>
                  <FormControl className="employmentStatus-fields">
                    <TextField
                      id="employment-status-urduName"
                      data-cy="urduName"
                      className="employmentstatusurduName"
                      variant="outlined"
                      name="urduName"
                      label={<Translate contentKey="wfmApp.employmentStatus.urduName">Urdu Name</Translate>}
                      defaultValue={employmentStatusEntity.urduName}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item md={12} container>
                <Grid item md={12} xs={12}>
                  <FormControl style={{ display: 'inline-block' }} className="employmentStatus-fields">
                    <Button
                      component={Link}
                      id="cancel-save"
                      to="/employment-status"
                      replace
                      style={{ color: 'black', backgroundColor: 'rgb(13, 202, 240)' }}
                    >
                      <FontAwesomeIcon icon="arrow-left" />
                      &nbsp;
                      <span className="d-none d-md-inline">
                        <Translate contentKey="entity.action.back">Back</Translate>
                      </span>
                    </Button>
                    &nbsp;
                    <Button
                      style={{ color: 'white', backgroundColor: 'blue' }}
                      id="save-entity"
                      data-cy="entityCreateSaveButton"
                      type="submit"
                      disabled={updating}
                    >
                      <FontAwesomeIcon icon="save" />
                      &nbsp;
                      <Translate contentKey="entity.action.save">Save</Translate>
                    </Button>
                  </FormControl>
                </Grid>
              </Grid>
            </form>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  employmentStatusEntity: storeState.employmentStatus.entity,
  loading: storeState.employmentStatus.loading,
  updating: storeState.employmentStatus.updating,
  updateSuccess: storeState.employmentStatus.updateSuccess,
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EmploymentStatusUpdate);
