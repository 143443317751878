import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import {} from 'react-jhipster';
import { IRootState } from 'app/shared/reducers';
import { IServiceArea } from 'app/shared/model/service-area.model';
import '../leave-view.scss';
import IconButton from '@material-ui/core/IconButton';

interface ILeaveViewProps {
  heading?: string | any;
  data?: any;
  flag?: any;
}

export const PastLeavesComponent = props => {
  return (
    <div className="shift-leaves-main-container">
      <div className="shift-leaves-heading">{props.heading}</div>
      <div className="leaves-shifts-rows">
        {props.data && props.data.length > 0
          ? props.data.map((x, i) => (
              <tr data-cy="entityTable" key={i} className="subcontractor-documentsTableBodyRows">
                <td className="leave-shifts-date-cell">
                  <dd>{x.start_date}</dd>
                </td>
                <td className="leave-shifts-date-cell">
                  <dd>{props.flag === 'leave' ? x.leave_type?.name : x.service_area?.name}</dd>
                </td>
                <td className="text-right subcontractor-documentsTableBodyCell subcontractorTableBodyActioncell">
                  <div className="btn-group serviceAreaActionCellButtons">
                    <>
                      <IconButton
                        component={Link}
                        to={props.flag === 'leave' ? `/leave/${x.id}` : `/roster/${x.id}/edit`}
                        className="departmentButton"
                        size="small"
                        style={{ margin: '1%' }}
                        data-cy="entityEditButton"
                      >
                        <img src="content/images/View1.png" />
                      </IconButton>
                    </>
                  </div>
                </td>
              </tr>
            ))
          : null}
      </div>
    </div>
  );
};

const mapStateToProps = ({ organization }: IRootState) => ({});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PastLeavesComponent);
