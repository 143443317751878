import React, { useState, useEffect, Fragment } from 'react';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import { Grid, Divider } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { getDateFormat } from 'app/shared/util/calender-utils';
import CustomToolbarComponent from '../../../../../entities/service-area/CustomToolbarComponent';
import moment from 'moment';

export const EducationHistorySections = props => {
  const {
    index,
    setFormValues,
    setHistory,
    fieldError,
    values,
    counter,
    reference,
    handleFilesData,
    dateSetting,
    education,
    isEdit,
  } = props;
  const [fileName, setFileName] = useState('');
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    if (isEdit && values[`edu_proof_name_${index}`]) {
      setIsSelected(true);
      setFileName(values[`edu_proof_name_${index}`]);
    }
  }, []);

  const handleProofs = (event, index) => {
    setIsSelected(true);
    setFileName(event.target.files[0].name);
    handleFilesData(event, `edu_proof_${index}`);
  };

  return (
    <div className="EducationHistoryOuterDiv">
      {index !== 0}
      <Grid container spacing={3}>
        <Grid item xs={6} className="professional-history-custom-field professional-history-fields">
          <TextField
            type="text"
            variant="outlined"
            className="form-control"
            onChange={setFormValues}
            name={`degree_title_${index}`}
            value={values[`degree_title_${index}`] ? values[`degree_title_${index}`] : ''}
            id="degree_title"
            label="Diploma/ Course/ Degree title"
          />
        </Grid>
        <Grid item xs={6} className="professional-history-custom-field professional-history-fields">
          <TextField
            type="text"
            variant="outlined"
            className="form-control"
            onChange={setFormValues}
            name={`institute_name_${index}`}
            value={values[`institute_name_${index}`] ? values[`institute_name_${index}`] : ''}
            id="institute_name"
            label="Institute Name"
          />
        </Grid>
        <Grid item xs={6} className="professional-history-fields">
          <TextField
            type="text"
            variant="outlined"
            className="form-control"
            onChange={setFormValues}
            name={`institute_location_${index}`}
            value={values[`institute_location_${index}`] ? values[`institute_location_${index}`] : ''}
            id="institute_location"
            label="Institute Location"
          />
        </Grid>
        <Grid item xs={6} className="professional-history-fields">
          <TextField
            type="text"
            variant="outlined"
            className="form-control"
            onChange={setFormValues}
            name={`education_post_code_${index}`}
            value={values[`education_post_code_${index}`] ? values[`education_post_code_${index}`] : ''}
            id="education_post_code"
            label="Post Code"
          />
        </Grid>
        <Grid item xs={3} className="professional-history-fields">
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <KeyboardDatePicker
              autoOk
              variant="inline"
              inputVariant="outlined"
              name={`edu_start_date_${index}`}
              className="professional-history-date_picker"
              defaultValue="From"
              id="edu_start_date"
              style={{
                width: '100%',
              }}
              label="From"
              format={dateSetting ? getDateFormat(dateSetting) : 'MM/DD/YYYY'}
              value={values[`edu_start_date_${index}`] && values[`edu_start_date_${index}`]}
              ToolbarComponent={prop => (
                <>
                  <CustomToolbarComponent {...prop} settingFormat={dateSetting} />
                </>
              )}
              placeholder={dateSetting ? getDateFormat(dateSetting) : 'MM/DD/YYYY'}
              keyboardIcon={<img src="content/images/icons8-calendar-22.png" />}
              InputAdornmentProps={{ position: 'end' }}
              onChange={date => {
                setHistory(date, 'start_date', 'edu_start_date', `edu_start_date_${index}`);
              }}
              helperText={fieldError[`edu_start_date_${index}`] ? fieldError[`edu_start_date_${index}_helper`] : ''}
              error={fieldError[`edu_start_date_${index}`]}
            />{' '}
          </MuiPickersUtilsProvider>
        </Grid>
        <div style={{ alignSelf: 'center', marginTop: '25px' }}> - </div>
        <Grid item xs={3} className="professional-history-fields">
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <KeyboardDatePicker
              autoOk
              variant="inline"
              className="professional-history-edu-end-date professional-history-date_picker"
              inputVariant="outlined"
              name={`edu_end_date_${index}`}
              defaultValue="From"
              id="edu_end_date"
              style={{
                width: '100%',
              }}
              label="Till"
              format={dateSetting ? getDateFormat(dateSetting) : 'MM/DD/YYYY'}
              value={values[`edu_end_date_${index}`] && values[`edu_end_date_${index}`]}
              ToolbarComponent={prop => (
                <>
                  <CustomToolbarComponent {...prop} settingFormat={dateSetting} />
                </>
              )}
              placeholder={dateSetting ? getDateFormat(dateSetting) : 'MM/DD/YYYY'}
              keyboardIcon={<img src="content/images/icons8-calendar-22.png" />}
              InputAdornmentProps={{ position: 'end' }}
              onChange={date => {
                setHistory(date, 'end_date', 'edu_end_date', `edu_end_date_${index}`);
              }}
              helperText={fieldError[`edu_end_date_${index}`] ? fieldError[`edu_end_date_${index}_helper`] : ''}
              error={fieldError[`edu_end_date_${index}`]}
            />{' '}
          </MuiPickersUtilsProvider>
        </Grid>
        <input
          type="file"
          name={`edu-proof_${index}`}
          onChange={() => handleProofs(event, index)}
          id={`contained-button-file_${index}`}
          style={{ display: 'none' }}
        />
        <label htmlFor={`contained-button-file_${index}`}>
          <Button className="professional-history-education-button" component="span">
            Attach Proof
          </Button>
        </label>
        {isSelected && <label id="file_chosen" style={{ marginTop: '23px', fontSize: 'small', marginLeft: '12px' }}>{fileName ? fileName : ''}</label>}
        {/* <Button className="professional-history-education-button"> *Please Attach Proof </Button> */}
      </Grid>
      <br />
    </div>
  );
};
