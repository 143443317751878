import './home.scss';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { TodaysShift } from './TodaysShift';
import ActionRequired, { IActionsHistory } from './ActionRequired/ActionsRequired';
import { Link, RouteComponentProps } from 'react-router-dom';
import { getShiftDetails, getWeeklyShifts, getShiftDetailsForUser, reset } from './TodaysShift.reducer';
import { getAllShifts, UpdateShifts } from './TodaysShift.reducer';
import { updateNotifications, getNotifications } from './Alert&Notifications/Alerts&Notifications.reducer';
import ShiftDetails from './ShiftsDetails/ShiftDetailsComponent';
import ShiftsColumnChart from './DashBoardChart/ShiftsColumnChart';
import AlertsAndNotifications from './Alert&Notifications/Alerts&Notifications';
import { IRootState } from 'app/shared/reducers';
import { Button } from '@material-ui/core';
import moment from 'moment';
import { getActionsLog } from './ActionRequired/ActionRequired.reducer';
import {
  handleFilterCurrentWeekStartDate,
  handleFilterCurrentWeekEndDate,
  getSelectedTimePeriodFilter,
} from '../../../utils/TimeFormatAndTimezone';
import { getOrganizationSettings } from '../../modules/Setting/generalSettings.reducer';
import KeyboardArrowLeftRounded from '@material-ui/icons/KeyboardArrowLeftRounded';
import KeyboardArrowRightRounded from '@material-ui/icons/KeyboardArrowRightRounded';
import { getCustomHeaderDate, moveNext, movePrev } from '../../../utils/TimeFormatAndTimezone';
import { Storage } from 'react-jhipster';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import { getPersonsList } from '../../modules/calender/calendar.reducer';
import {
  getBannedEmployeesOfOrganization,
  getEntities as getLocations,
  reset as resetServiceAreas,
} from '../../entities/service-area/service-area.reducer';
import { getEntities as getDesignations, reset as resetDesignations } from '../../entities/designation/designation.reducer';
import Loader from '../../commonUI/loader';
import { getPersonalSettings } from '../Setting/Personal-Setting.reducer';
import { getPermissionsSettings } from '../Setting/RolesAndPermissionsSettings/RolesAndPermissions.reducer';
import activeStaffServiceArea from '../../../content/images/Active_Service_Areas_PNG.png';
import scheduledHours from '../../../content/images/Scheduled_Hours_PNG.png';
import scheduledShifts from '../../../content/images/Scheduled_Shifts_PNG.png';
import unassignedShifts from '../../../content/images/Unassigned_Shifts_PNG.png';
interface UserDispatchProps {
  getWeeklyShifts: (data, pgNo) => void;
  getShiftDetails: (data) => void;
  getPersonsList: (data) => void;
  getLocations: () => void;
  getDesignations: () => void;
  getBannedEmployeesOfOrganization: () => void;
}

export interface IHomeProp extends StateProps, UserDispatchProps, IActionsHistory, RouteComponentProps<{ url: string }> {}

export const Home = props => {
  const { account, shiftDetails, shiftsList, loading, actionLogs, personalSettings, organizationSettings } = props;

  const [values, setValues] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [headerDate, setHeaderDate] = useState('');
  const [first_login] = useState(Storage.local.get('first_login'));

  useEffect(() => {
    if (!props.isVetted) {
      props.history.push('/employeeForm');
    }
    if (first_login === 'true' && props.isOrganizationAdministrator) {
      props.history.push('/settings');
    }
    props.reset();
    props.resetDesignations();
    props.resetServiceAreas();
    if (first_login === 'true' && props.isOrganizationAdministrator && !props.organizationSettings.company_address) {
      props.history.push('/settings');
    }
  }, []);

  useEffect(() => {
    if (!Storage.local.get('organizationSelected')) {
      props.history.push('/organizations');
    }
  }, []);

  useEffect(() => {
    if (pageNumber > 1) {
      props.getWeeklyShifts(values, pageNumber);
    }
  }, [pageNumber]);

  useEffect(() => {
    if (Object.keys(values).length !== 0 && !props.isUser) {
      props.getShiftDetails(values);
      props.getWeeklyShifts(values, '');
      props.getLocations(-1, '');
      props.getDesignations(-1);
      props.getPersonsList({ from_date: '', to_date: '', current_day: moment().format('YYYY-MM-DD') });
      setHeaderDate(getCustomHeaderDate(values));
      props.getBannedEmployeesOfOrganization();
    } else if (Object.keys(values).length !== 0 && props.isUser) {
      props.getWeeklyShifts(values, '');
      props.getShiftDetailsForUser(values);
      setHeaderDate(getCustomHeaderDate(values));
    }
  }, [values]);

  useEffect(() => {
    if (personalSettings?.timezone) {
      setValues({
        from_date: getSelectedTimePeriodFilter('Current week', 'setSelectedDateFrom'),
        to_date: getSelectedTimePeriodFilter('Current week', ''),
        current_day: '',
        option: 'WEEK',
      });
    }
  }, [personalSettings, organizationSettings]);

  const hanldleFilters = tag => {
    let first;
    let last;

    if (tag === 'DAY') {
      props.reset();
      setPageNumber(1);
      setValues(oldValues => ({
        ...oldValues,
        ['from_date']: '',
        ['to_date']: '',
        ['option']: 'DAY',
        ['current_day']: moment(new Date()).format('YYYY-MM-DD'),
      }));
    } else if (tag === 'WEEK') {
      first = handleFilterCurrentWeekStartDate();
      last = handleFilterCurrentWeekEndDate();
      props.reset();
      setPageNumber(1);
      setValues(oldValues => ({
        ...oldValues,
        ['from_date']: moment(first).format('YYYY-MM-DD'),
        ['to_date']: moment(last).format('YYYY-MM-DD'),
        ['option']: 'WEEK',
        ['current_day']: '',
      }));
    } else if (tag === 'MONTH') {
      props.reset();
      setPageNumber(1);

      setValues(oldValues => ({
        ...oldValues,
        ['from_date']: moment().startOf('month').format('YYYY-MM-DD'),
        ['to_date']: moment().endOf('month').format('YYYY-MM-DD'),
        ['option']: 'MONTH',
        ['current_day']: '',
      }));
    }
  };

  return (
    <div className="home-main-container">
      <div className="dashboard-header-div">
        <div className="dashboard-header-scroll-btns-div">
          <Button
            variant="contained"
            onClick={() => {
              props.reset();
              movePrev(values, setValues);
            }}
            style={{ width: '10%', background: 'white' }}
          >
            <KeyboardArrowLeftRounded />
          </Button>
          {<span className="date-text">&nbsp;{headerDate ? headerDate : <Loader size={15}/> }&nbsp;</span>}
          <Button
            variant="contained"
            onClick={() => {
              props.reset();
              moveNext(values, setValues);
            }}
            style={{ background: 'white' }}
          >
            <KeyboardArrowRightRounded />
          </Button>
        </div>
        <div className="dashboard-header-period-filters-div">
          <Button
            className=" mb-3"
            id="dayButton"
            variant="contained"
            style={{
              width: '12%',
              backgroundColor: values['option'] === 'DAY' ? '#D48BD3' : 'white',
              color: values['option'] === 'DAY' ? 'white' : 'black',
            }}
            onClick={() => {
              hanldleFilters('DAY');
            }}
          >
            Day
          </Button>
          <Button
            className="mb-3"
            id="weekButton"
            variant="contained"
            style={{
              width: '12%',
              backgroundColor: values['option'] === 'WEEK' ? '#D48BD3' : 'white',
              color: values['option'] === 'WEEK' ? 'white' : 'black',
            }}
            onClick={() => {
              hanldleFilters('WEEK');
            }}
          >
            Week
          </Button>
          <Button
            className="mb-3"
            id="monthButton"
            variant="contained"
            style={{
              width: '12%',
              backgroundColor: values['option'] === 'MONTH' ? '#D48BD3' : 'white',
              color: values['option'] === 'MONTH' ? 'white' : 'black',
            }}
            onClick={() => {
              hanldleFilters('MONTH');
            }}
          >
            Month
          </Button>
        </div>
        <div className="dashboard-header-create-shift-btn-div">
          <Button
            component={Link}
            to={{
              pathname: '/Calendar',
              state: true,
            }}
            className="mr-1 mb-3"
            variant="contained"
            color="secondary"
            style={{ background: '#D48BD3', textDecoration: 'none', color: 'white' }}
          >
            + Create New Shift
          </Button>
        </div>
      </div>

      {!loading ? (
        <div className="dashboard-outer-main-div">
          <div className="dashboard-shift-details-outer-div">
            <ShiftDetails
              color="#a2a2a2"
              count={shiftDetails.total_scheduled_hours >= 0 ? shiftDetails.total_scheduled_hours : <Loader size = {30}/>}
              details="Scheduled Hours"
              customClass="dashboard-total-hours-div"
              image={scheduledHours}
              isHome={true}
            />
            <ShiftDetails
              color="#ed6f6f"
              count={shiftDetails.active_sites >= 0 ? shiftDetails.active_sites : <Loader size = {30}/>}
              details="Active Service Areas"
              customClass=""
              image={activeStaffServiceArea}
              isHome={true}
            />
            <ShiftDetails
              color="#8660f3"
              count={shiftDetails.scheduled_shifts >= 0 ? shiftDetails.scheduled_shifts : <Loader size = {30}/>}
              details="Scheduled Shifts"
              customClass=""
              image={scheduledShifts}
              isHome={true}
            />
            <ShiftDetails
              color="#efbb4b"
              count={shiftDetails.open_shifts >= 0 ? shiftDetails.open_shifts : <Loader size = {30}/>}
              details="Unassigned Shifts"
              customClass=""
              image={unassignedShifts}
              isHome={true}
            />
          </div>
          <div className="dashboard-todays-shift-action-log-outer-div">
            <div className="dashboard-action-log-separete-div-container">
              <ActionRequired {...actionLogs} />
            </div>
            {personalSettings?.timezone && (
              <div className="dashboard-todays-shift-chart-separate-div-container">
                <div className="dashboard-todays-shifts-separete-div-container">
                  <TodaysShift
                    UpdateShifts={props.UpdateShifts}
                    shiftsList={shiftsList}
                    setting={personalSettings}
                    values={values}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    reset={reset}
                    isUser={props.isUser}
                  />
                </div>
                <div className="dashboard-upcoming-shifts-separete-div-container">
                  <ShiftsColumnChart {...shiftDetails} setting={personalSettings} />
                </div>
              </div>
            )}
            <div className="dashboard-alerts-and-notifications-div-container">
              <AlertsAndNotifications />
            </div>
          </div>
          <br />
        </div>
      ) : (
        <div className="lds-roller"></div>
      )}
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  account: storeState.authentication.account,
  isAuthenticated: storeState.authentication.isAuthenticated,
  isVetted: storeState.authentication.is_vetted,
  shiftDetails: storeState.TodaysShift.shiftDetails,
  shiftsList: storeState.TodaysShift.AllShifts,
  personalSettings: storeState.PersonalSettings.storedPersonalSettings,
  loading: storeState.TodaysShift.loading,
  actionLogs: storeState.ActionLogs.entities,
  organizationSettings: storeState.organizationSettings.storedOrganizationSettings,
  rolesList: storeState.person.roles,
  isOrganizationAdministrator: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.ORGANIZATION_ADMINISTRATOR]),
  organizationSelected: storeState.authentication.organizationSelected,
  isUser: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.USER]),
});

const mapDispatchToProps = {
  getShiftDetails,
  getWeeklyShifts,
  getAllShifts,
  UpdateShifts,
  getActionsLog,
  updateNotifications,
  getNotifications,
  reset,
  getOrganizationSettings,
  getPersonsList,
  getLocations,
  getDesignations,
  getBannedEmployeesOfOrganization,
  resetServiceAreas,
  resetDesignations,
  getPersonalSettings,
  getPermissionsSettings,
  getShiftDetailsForUser,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Home);
