import React, { useEffect } from 'react';
import './header.scss';
import { AdminMenu, EntitiesMenu, Account, SchedulesMenuItems, LocaleMenu, PeopleMenu, HRMenu } from '../menus';
import { Home, Brand, Client, Vetting } from './header-components';
import { ReportsMenu } from '../menus/reports';
import Tabs from '@material-ui/core/Tabs';
import Notifications from '../../../modules/notifications/Notifications';

const HeaderComponentSmallScreen = props => {
  useEffect(() => {
    props.imageUrl;
  }, [props.imageUrl]);

  const isRoleAllowed = (organizationadministrator, supervisor, manager, user = false) => {
    if (organizationadministrator || supervisor || manager || user) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Tabs className="header-second-internal-nav-div" id="header-tabs">
        {props.isAuthenticated && props.organizationSelected && props.isVetted && <Home />}
        {props.isAuthenticated && props.organizationSelected && props.isVetted && props.isSystemAdmin && (
          <>
            <Client />
            <AdminMenu showOpenAPI={props.isOpenAPIEnabled} showDatabase={!props.isInProduction} />
          </>
        )}
        {props.isAuthenticated &&
          props.organizationSelected &&
          props.isVetted &&
          isRoleAllowed(props.isOrganizationAdministrator, props.isSupervisor, props.isManager) && (
            <>
              <SchedulesMenuItems />
            </>
          )}
        {props.isAuthenticated && props.isVetted && props.isOrganizationAdministrator && <HRMenu />}
        {props.isAuthenticated &&
          props.organizationSelected &&
          props.isVetted &&
          isRoleAllowed(props.isOrganizationAdministrator, props.isSupervisor, props.isManager) && (
            <>
              <ReportsMenu />
              <PeopleMenu
                viewClients={props.canViewClients}
                viewSubcontractors={props.canViewSubContractors}
                viewServiceArea={props.canViewServiceArea}
              />
              {/* <OthersMenu
                viewClients={props.canViewClients}
                viewSubcontractors={props.canViewSubContractors}
                viewServiceArea={props.canViewServiceArea}
              /> */}

              {/* <HRMenu /> */}
            </>
          )}

        {/* {props.isAuthenticated && props.isSystemAdmin && <EntitiesMenu />} */}
        {/* {props.isAuthenticated && props.isOrganizationAdministrator && <Vetting />} */}
      </Tabs>
      <Tabs className="Account">
        {props.isAuthenticated && (
          <>
            <span className="notificationsTab headerIcon">
              {' '}
              {props.organizationSelected && <Notifications notificationsCount={4} iconColor="#344563" />}
            </span>
            <span className="accountTab">
              <Account
                isAuthenticated={props.isAuthenticated}
                isVetted={props.isVetted}
                isSystemAdmin={props.isSystemAdmin}
                imageUrl={props.imageUrl}
              />
            </span>
          </>
        )}
      </Tabs>
    </>
  );
};

export default HeaderComponentSmallScreen;
