import { ICountry } from 'app/shared/model/country.model';
import { IState } from 'app/shared/model/state.model';

export interface ICity {
  id?: number;
  name?: string | null;
  state?: IState | null;
  country?: ICountry | null;
}

export const defaultValue: Readonly<ICity> = {};
