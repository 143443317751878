import React, { useState } from 'react';
import { DocumentRecordComponent } from './documentsRecordComponent';

export const VettingUploadedDocuments = props => {
  const [isOpenWork, setIsOpenWork] = useState(false);
  const [isOpenAddress, setIsOpenAddress] = useState(false);
  const [isOpenId, setIsOpenId] = useState(false);

  const { item } = props;
  return (
    <div className="vetting-documents-uploaded-documents-div">
      {item.right_to_work ? (
        <DocumentRecordComponent setIsOpen={setIsOpenWork} isOpen={isOpenWork} url={item.right_to_work} name="Right to Work" />
      ) : (
        ''
      )}
      {item.proof_of_address ? (
        <DocumentRecordComponent setIsOpen={setIsOpenAddress} isOpen={isOpenAddress} url={item.proof_of_address} name="Proof of Address" />
      ) : (
        ''
      )}
      {item.proof_of_id ? (
        <DocumentRecordComponent setIsOpen={setIsOpenId} isOpen={isOpenId} url={item.proof_of_id} name="Proof of ID" />
      ) : (
        ''
      )}
    </div>
  );
};
