import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { IServiceArea } from 'app/shared/model/service-area.model';

interface ServiceAreaClientInformationProps {
  clientsList?: Array<any> | any;
  handleClientData?: (data: any, event: any) => void;
  client?: { id: number; text: string } | any;
  fieldError?: Record<string, any>;
  designations?: Array<any> | any;
  handlePositionData?: (data: any, event: any) => void;
  values?: IServiceArea | any;
  isEdit: boolean;
}

const ServiceAreaClientInformation: React.FC<ServiceAreaClientInformationProps> = props => {
  return (
    <div className="create-service-area-details-outer-div create-service-area-fieldsets">
      <div className="create-service-area-detail-fields-outer-div">
        <Grid container spacing={3}>
          <Grid item lg={6} xs={12}>
            <Autocomplete
              options={
                props.clientsList &&
                props.clientsList.map(client => ({
                  id: client.id,
                  clientName: client.clientName,
                }))
              }
              noOptionsText={'Please Create Client First'}
              classes={{ listbox: 'autocompletelistbox' }}
              ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
              getOptionLabel={option => option.clientName || ''}
              className="PersonAutocomplete"
              onChange={props.handleClientData}
              value={props.values.client || null}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  className="personAutocompleteTextfield autocompleteLabel"
                  label="Client"
                  margin="normal"
                  required
                  fullWidth
                  error={props.fieldError['clientId_error']}
                  style={{ backgroundColor: 'white' }}
                />
              )}
              disabled={!props.isEdit} // Disable the Autocomplete if isEdit is false
            />
          </Grid>

          <Grid item lg={6} xs={12}>
            <Autocomplete
              options={
                props.designations &&
                props.designations.map(position => ({
                  id: position.id,
                  name: position.name,
                }))
              }
              noOptionsText={'Please Create Position First'}
              classes={{ listbox: 'autocompletelistbox' }}
              ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
              getOptionLabel={option => option.name || ''}
              className="PersonAutocomplete"
              onChange={props.handlePositionData}
              value={props.values.designation || null}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  className="personAutocompleteTextfield autocompleteLabel"
                  label="Position"
                  margin="normal"
                  required
                  fullWidth
                  error={props.fieldError['positionId_error']}
                  style={{ backgroundColor: 'white' }}
                />
              )}
              disabled={!props.isEdit}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
export default ServiceAreaClientInformation;
