import React, { useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Grid, TextField, FormControl } from '@material-ui/core';
import '../automated-control-room.scss';
import { Autocomplete } from '@material-ui/lab';
import shift_confirmation_times from '../../../shared/data/shift_confirmation_times.json';

export default function CheckCallAdvancedSettings(props) {
  const { values, setValues } = props;
  const [shiftValue, setShiftValue] = React.useState<{ label: string; id: number }>(null);

  useEffect(() => {
    updateAutoCompleteBoxes(values);
  }, [props.ACRShiftSettingsValues]);

  const handleChange = (event, time) => {
    setShiftValue(time);
    setValues(oldValues => ({
      ...oldValues,
      ['Welfare Check']: { ...oldValues['Welfare Check'], ['minimum_time']: time ? time.id : 0 },
    }));
  };

  const updateAutoCompleteBoxes = newValues => {
    if (newValues && newValues['Welfare Check'] && newValues['Welfare Check']['minimum_time']) {
      const time = Object.values(shift_confirmation_times).find(e => e.id === newValues['Welfare Check']['minimum_time']);
      if (time) {
        setShiftValue(time);
      } else {
        setShiftValue({ label: 'Custom', id: 0 });
      }
    }
  };

  const handleTextfields = event => {
    setValues(oldValues => ({
      ...oldValues,
      ['Welfare Check']: { ...oldValues['Welfare Check'], ['minimum_time']: event.target.value },
    }));
  };

  return (
    <div className="general-advanced-settings-main-div">
      <div className="general-advanced-settings-main-heading">
        <span>Welfare Check (Mins)</span>
      </div>

      <div className="general-advanced-settings-sign-in-div">
        <div className="shift-advanced-settings-heading">
          <span>If someone makes manual welfare check before the scheduled check</span>
        </div>

        <Grid container spacing={1}>
          <Grid item sm={9} lg={9} md={9}></Grid>

          <Grid className="label-custom-time" item sm={3} lg={3} md={3}>
            {shiftValue && shiftValue['id'] === 0 && <label className="custom-label">Custom Time</label>}
          </Grid>
        </Grid>

        <Grid container spacing={0}>
          <Grid item sm={9} lg={9} md={9}>
            <FormControl className="send-reminder-field">
              <Autocomplete
                options={shift_confirmation_times}
                noOptionsText={'No Records'}
                classes={{ listbox: 'autocompletelistbox' }}
                ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                getOptionLabel={option => (option['label'] ? option['label'] : '')}
                getOptionSelected={(option, value) => option['id'] === value['id']}
                value={shiftValue ? shiftValue : []}
                onChange={(event, option) => handleChange(event, option)}
                renderInput={params => <TextField {...params} variant="outlined" size="small" label="Time" margin="normal" fullWidth />}
              />
            </FormControl>
          </Grid>

          <Grid item sm={3} lg={3} md={3}>
            <FormControl className="send-reminder-field">
              {shiftValue && shiftValue['id'] === 0 && (
                <TextField
                  variant="outlined"
                  className="custom-time-field"
                  size="small"
                  margin="normal"
                  fullWidth
                  type="number"
                  onChange={handleTextfields}
                  defaultValue={0}
                  value={values && values['Welfare Check'] && values['Welfare Check']['minimum_time']}
                />
              )}
            </FormControl>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
