import axios from 'axios';
import { Storage } from 'react-jhipster';
import { IPersonalSettings } from 'app/shared/model/personalSetting.model';
import { getlabel } from '../../../utils/TimeFormatAndTimezone';
import moment from 'moment-timezone';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

export const ACTION_TYPES = {
  STORE_PERSONAL_SETTINGS: 'settings/STORE_PERSONAL_SETTINGS',
  GET_PERSONAL_SETTINGS: 'settings/GET_PERSONAL_SETTINGS',
};

const initialState = {
  setting: [] as Array<IPersonalSettings>,
  errorMessage: null,
  updateSuccess: false,
  updating: false,
  loading: false,
  storedPersonalSettings: [] as IPersonalSettings,
};

export type PersonalSettingState = Readonly<typeof initialState>;

export default (state: PersonalSettingState = initialState, action): PersonalSettingState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.GET_PERSONAL_SETTINGS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.STORE_PERSONAL_SETTINGS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.GET_PERSONAL_SETTINGS):
    case FAILURE(ACTION_TYPES.STORE_PERSONAL_SETTINGS):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };

    case SUCCESS(ACTION_TYPES.GET_PERSONAL_SETTINGS):
      Storage.local.set('PersonalSetting', action.payload.data);
      moment.tz.setDefault(
        getlabel(action.payload.data.timezone) ? getlabel(action.payload.data.timezone) : Intl.DateTimeFormat().resolvedOptions().timeZone
      );
      return {
        ...state,
        loading: false,
        storedPersonalSettings: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.STORE_PERSONAL_SETTINGS):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        setting: action.payload,
      };

    default:
      return state;
  }
};

const apiUrl = 'api/PersonalSetting';

export const getPersonalSettings: ICrudGetAllAction<IPersonalSettings> = () => ({
  type: ACTION_TYPES.GET_PERSONAL_SETTINGS,
  payload: axios.get<IPersonalSettings>(`${apiUrl}`),
});

export const storePersonalSettings: ICrudPutAction<IPersonalSettings> = settings => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.STORE_PERSONAL_SETTINGS,
    payload: axios.post(`${apiUrl}`, settings),
  });
  dispatch(getPersonalSettings());
  return result;
};
