import moment from 'moment';
import { exportPDF } from '../timesheet-summary/exportPDF';

const header = ['Shift Date/ Time', 'Assignee', 'Status', 'Service Area', 'Reason', 'Deleted By'];

export const convertToPDF = (jsonData, selectedDateFrom, selectedDateTo) => {
  const DateFrom = selectedDateFrom === undefined ? '' : moment(selectedDateFrom).format('DD-MM-YYYY');
  const DateTo = selectedDateTo === undefined ? '' : moment(selectedDateTo).format('DD-MM-YYYY');
  const headers = [header];
  const title = 'Deleted Shifts';
  const data = jsonData.map(row => [
    row.start_time && row.start_time,
    row.assignee && row.assignee,
    row.status && row.status,
    row.service_area && row.service_area,
    row.reason && row.reason,
    row.deleted_by && row.deleted_by,
  ]);
  const filename = 'Deleted Shifts.pdf';

  exportPDF(DateFrom, DateTo, headers, title, data, filename);
};
