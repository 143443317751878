/* eslint-disable no-undef */
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { toast } from 'react-toastify';
import { getPushNotification } from './app/modules/notifications/Notifications.reducer';
import config from './app/config/constants';

const firebaseConfig = {
  apiKey: config.FIREBASE_API_KEY,
  authDomain: config.FIREBASE_AUTH_DOMAIN,
  projectId: config.FIREBASE_PROJECT_ID,
  storageBucket: config.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: config.FIREBASE_MESSAGE_SENDER_ID,
  appId: config.FIREBASE_APP_ID,
  measurementId: config.FIREBASE_MEASUREMENT_ID,
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const RegisterServiceWorker = async () => {
  const serviceWorker = await navigator.serviceWorker.register('./content/js/firebase-messaging-sw.js');
  return getClientToken(serviceWorker);
};

export const getClientToken = async serviceWorker => {
  let res = false;
  await getToken(messaging, {
    serviceWorkerRegistration: serviceWorker,
    vapidKey: config.FIREBASE_VAPID_KEY,
  })
    .then(currentToken => {
      if (currentToken) {
        res = currentToken;
      } else {
        res = false;
      }
    })
    .catch(err => {
      toast.info('To get notifications please enable them from browser`s settings');
    });
  return res;
};

export const onMessageListener = () =>
  new Promise(resolve => {
    onMessage(messaging, payload => {
      getPushNotification(payload.data);
      resolve(payload);
    });
  });
