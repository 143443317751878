import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { IRootState } from 'app/shared/reducers';
import { Divider, Paper } from '@material-ui/core';
import { Button, Grid, FormControl } from '@material-ui/core';
import {
  getEntity,
  bannedEntity,
  getBannedEmployees,
  updateBannedPeople,
  addNewPerson,
  getPersonsByServiceAreaId,
  getActivePersonsByServiceAreaId,
} from '../service-area.reducer';
import Typography from '@material-ui/core/Typography';
import ServiceAreaTeamActiveEmployees from '../component/service-area-team-activeEmployees';
import ServiceAreaTeamBannedEmployees from '../component/service-area-team-bannedEmployees';
import '../servicearea.scss';
import { deleteStaffEntity, deleteEntity, getEntities as getPersons, getPersonForFilters } from '../../person/person.reducer';
import { BannedComponent } from './banned-modal';
import { AddPersonComponent } from './new-person-modal';
import ServiceArea_Edit_Del_Btn from '../component/serviceArea-edit-del-btn';
import Spinner from 'app/commonUI/spinner/spinner';

export interface IEntityDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const ServiceAreaTeam = props => {
  const [showBannedModal, setShowBannedModal] = useState(false);
  const [openPersonModal, setOpenPersonModal] = useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [BannedModalMode, setBannedModalMode] = useState({
    Mode: '',
    banned_id: null,
  });

  const closeEditMode = props => {
    setIsEdit(false);
    ServiceAreaTeam(props);
  };
  useEffect(() => {
    props.getEntity(props.match.params.id);
    props.getPersonForFilters();
    props.getPersonsByServiceAreaId(props.serviceAreaEntity.id);
    props.getActivePersonsByServiceAreaId(props.serviceAreaEntity.id);
  }, []);

  useEffect(() => {
    if (props.serviceAreaEntity && props.serviceAreaEntity.id) {
      props.getBannedEmployees({ id: props.serviceAreaEntity.id });
    }
  }, [props.serviceAreaEntity]);

  const handleAddNewButton = () => {
    setShowBannedModal(true);
    setBannedModalMode(oldValues => ({ ...oldValues, Mode: 'new' }));
  };

  const handleAddNewPersonButton = () => {
    setOpenPersonModal(true);
  };

  return (
    <div className="service-area-profile-details-main-container">
      {props.loading && <Spinner /> }
      <Paper className="service-area-details-paper">
        {isEdit === !true && (
          <div className="subcontractor_edit_del_Btn_div">
            <ServiceArea_Edit_Del_Btn isEdit={isEdit} setIsEdit={setIsEdit} props={props} flag="Team" />
          </div>
        )}
        <Typography className="service-area-main-title-heading" variant="h5" gutterBottom style={{ color: '#4720b7', marginTop: '8%' }}>
          <span> Active Staff </span>
          {isEdit ? (
            <span className="add-new-service-area-button" onClick={() => handleAddNewPersonButton()}>
              <div style={{ fontFamily: 'Roboto', color: '#FFFFFF', fontSize: '16px' }}>Add New </div>
            </span>
          ) : null}
        </Typography>
        <ServiceAreaTeamActiveEmployees {...props} isEdit={isEdit} />
        <Typography className="service-area-main-title-heading" variant="h5" gutterBottom style={{ color: '#4720b7', marginTop: '8%' }}>
          <span> Unauthorised Staff </span>
          {isEdit ? (
            <span className="add-new-service-area-button" onClick={() => handleAddNewButton()}>
              <div style={{ fontFamily: 'Roboto', color: '#FFFFFF', fontSize: '16px' }}>Add New </div>
            </span>
          ) : null}
        </Typography>
        <ServiceAreaTeamBannedEmployees
          prop={props}
          setShowBannedModal={setShowBannedModal}
          setBannedModalMode={setBannedModalMode}
          isEdit={isEdit}
        />

        {isEdit ? (
          <>
            <Divider style={{ margin: '4% 0' }} />
            <Grid item xs={12}>
              <FormControl style={{ flexDirection: 'row', justifyContent: 'flex-end', width: '100%' }}>
                <>
                  <Button
                    variant="contained"
                    className="service-area-discard-button"
                    onClick={() => {
                      closeEditMode(props);
                    }}
                  >
                    Cancel
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button
                    onClick={() => {
                      closeEditMode(props);
                    }}
                    variant="contained"
                    id="save-entity"
                    type="submit"
                    className="service-area-save-button"
                  >
                    Save
                  </Button>
                </>
              </FormControl>
            </Grid>
          </>
        ) : null}
      </Paper>
      <BannedComponent
        showBannedModal={showBannedModal}
        setShowBannedModal={setShowBannedModal}
        BannedModalMode={BannedModalMode}
        setBannedModalMode={setBannedModalMode}
        prop={props}
        setting={props.setting.date_format}
      />
      <AddPersonComponent prop={props} openPersonModal={openPersonModal} setOpenPersonModal={setOpenPersonModal} />
    </div>
  );
};

const mapStateToProps = ({ serviceArea, person, PersonalSettings }: IRootState) => ({
  serviceAreaEntity: serviceArea.entity,
  loading: serviceArea.loading,
  personsList: person.filterPersons,
  bannedEmployees: serviceArea.banned,
  setting: PersonalSettings.storedPersonalSettings,
  activePersonServiceArea: serviceArea.activeServiceAreaPersons,
  personServiceArea: serviceArea.serviceAreaPersons,
});

const mapDispatchToProps = {
  getEntity,
  deleteEntity,
  getPersons,
  bannedEntity,
  getBannedEmployees,
  addNewPerson,
  updateBannedPeople,
  getPersonsByServiceAreaId,
  getActivePersonsByServiceAreaId,
  getPersonForFilters,
  deleteStaffEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ServiceAreaTeam);
