import axios from 'axios';
import { translate } from 'react-jhipster';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

export const ACTION_TYPES = {
  UPDATE_PASSWORD: 'account/UPDATE_PASSWORD',
  RESET: 'account/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  updateSuccess: false,
  updateFailure: false,
};

export type PasswordState = Readonly<typeof initialState>;

// Reducer
export default (state: PasswordState = initialState, action): PasswordState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.UPDATE_PASSWORD):
      return {
        ...initialState,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.UPDATE_PASSWORD):
      return {
        ...initialState,
        loading: false,
        updateSuccess: false,
        updateFailure: true,
      };
    case SUCCESS(ACTION_TYPES.UPDATE_PASSWORD):
      return {
        ...initialState,
        loading: false,
        updateSuccess: true,
        updateFailure: false,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

// Actions
const apiUrl = 'api/reset-password';

export const savePassword = (id, currentPassword, newPassword) => ({
  type: ACTION_TYPES.UPDATE_PASSWORD,
  payload: axios.post(`${apiUrl}`, { id, currentPassword, newPassword }),
  meta: {
    successMessage: translate('password.messages.success'),
  },
});

export const setNewPassword = async values => {
  const result = axios
    .post('api/set-password', values)
    .then(res => {
      return res;
    })
    .catch(err => {
      return { status: 500 };
    });
  return result;
};

export const resetForgottenPassword = async values => {
  const result = axios
    .post('api/resetForgottenPassword', values)
    .then(res => {
      return res;
    })
    .catch(err => {
      return { status: 500 };
    });
  return result;
};

const apiUrlEmail = 'api/forgotPasswordEmail';
export const sendResetPasswordEmail = async email => {
  event.preventDefault();
  let result;
  await axios
    .post(apiUrlEmail, email)
    .then(res => {
      result = res;
    })
    .catch(err => {
      result = { status: 500 };
    });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
