import React from 'react';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const PeopleGeneralComponent = props => {
  const deleteGeneralRecords = () => {
    props.remove(props.id);
  };

  return (
    <>
      <tr>
        <td style={{ textAlign: 'left', width: '60%' }}>
          <a href="#" style={{ marginLeft: '20px' }}>
            {' '}
            {props.msg}{' '}
          </a>
        </td>
        <td style={{ textAlign: 'right', width: '40%' }}>
          <a>
            <FontAwesomeIcon icon={faTrashAlt} onClick={deleteGeneralRecords} />
          </a>
        </td>
      </tr>
    </>
  );
};

export default PeopleGeneralComponent;
