import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { IRootState } from 'app/shared/reducers';
import { getEntity } from '../organization.reducer';
import { Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ServiceAreaComponent from './organization-service_areas';
import { getServiceAreasByOrganizationId } from '../../service-area/service-area.reducer';

export interface IEntityDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const OrganizationServiceAreas = (props: IEntityDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
    props.getServiceAreasByOrganizationId(props.match.params.id);
  }, []);

  const { organizationEntity, serviceAreasList } = props;
  return (
    <div className="organization-view-profile-details-main-container">
      <Paper className="organization-view-details-paper">
        <Typography className="organization-view-main-title-heading" variant="h5" gutterBottom style={{ color: '#4720b7' }}>
          Active Service Areas
        </Typography>
        <ServiceAreaComponent {...props} />
      </Paper>
    </div>
  );
};

const mapStateToProps = ({ organization, serviceArea }: IRootState) => ({
  organizationEntity: organization.entity,
  serviceAreasList: serviceArea.organizationServiceAreas,
});

const mapDispatchToProps = { getEntity, getServiceAreasByOrganizationId };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationServiceAreas);
