import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ContactType from './contact-type';
import ContactTypeDetail from './contact-type-detail';
import ContactTypeUpdate from './contact-type-update';
import ContactTypeDeleteDialog from './contact-type-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ContactTypeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ContactTypeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ContactTypeDetail} />
      <ErrorBoundaryRoute path={match.url} component={ContactType} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={ContactTypeDeleteDialog} />
  </>
);

export default Routes;
