import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import timezones from '../../shared/data/timezones.json';
import './organization.scss';
import { IOrganization } from 'app/shared/model/organization.model';
import { FormControl } from '@material-ui/core';
import { IDesignation } from 'app/shared/model/designation.model';
import PhoneInput from 'react-phone-input-2';
import { Autocomplete } from '@material-ui/lab';

interface OrganizationUpdateContactPersonProps {
  positionsList?: Array<any> | any;
  values?: IOrganization | any;
  setFormValues?: (data: any) => void;
  handleContactPersonPhoneNumber?: (data: any) => void;
  valuesError?: IOrganization | any;
  countryOfOperations?: number | any;
  countryTypes?: string | any;
  handlePositionData?: (data: any, event: any) => void;
}

const OrganizationUpdateContactPerson: React.FC<OrganizationUpdateContactPersonProps> = props => {
  return (
    <>
      <div className="organizationInnerFirstDivTitle">Contact Person</div>
      <div style={{ display: 'flex' }}>
        <Paper className="organizationInner2ndPaper">
          <div className="organizationFieldDivsideBySide">
            <Grid item xs={12} md={12} sm={12}>
              <FormControl className="organization_fields">
                <TextField
                  required
                  id="organization_person_first_name"
                  name="personFirstName"
                  onChange={props.setFormValues}
                  label="First Name"
                  type="text"
                  variant="outlined"
                  value={props.values['personFirstName'] ? props.values['personFirstName'] : ''}
                  error={props.valuesError['personFirstName_error']}
                  helperText={props.valuesError['personFirstName_helper']}
                  inputProps={{ maxLength: 255 }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <FormControl className="organization_fields">
                <TextField
                  required
                  id="organization_person_last_name"
                  name="personLastName"
                  onChange={props.setFormValues}
                  label="Last Name"
                  type="text"
                  variant="outlined"
                  value={props.values['personLastName'] ? props.values['personLastName'] : ''}
                  error={props.valuesError['personLastName_error']}
                  helperText={props.valuesError['personLastName_helper']}
                  inputProps={{ maxLength: 255 }}
                />
              </FormControl>
            </Grid>
          </div>
          <div className="organizationFieldDivsideBySide">
            <Grid item xs={12} md={12} sm={12}>
              <FormControl className="organization_fields">
                <TextField
                  required
                  id="organization_person_email"
                  name="personEmail"
                  onChange={props.setFormValues}
                  label="Email"
                  type="email"
                  variant="outlined"
                  value={props.values['personEmail'] ? props.values['personEmail'] : ''}
                  error={props.valuesError['personEmail_error']}
                  helperText={props.valuesError['personEmail_helper']}
                  inputProps={{ maxLength: 255 }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <FormControl className="organization_fields">
                <PhoneInput
                  inputProps={{
                    name: 'personPhone',
                    autoFocus: false,
                    required: true,
                  }}
                  isValid={value => {
                    if (value === '' || value === undefined) {
                      return false;
                    }
                    return true;
                  }}
                  country={
                    props.countryOfOperations
                      ? props.countryTypes.find(country => country.value === props.countryOfOperations)
                        ? props.countryTypes.find(country => country.value === props.countryOfOperations).phoneName
                        : 'fr'
                      : 'fr'
                  }
                  value={props.values['personPhone']}
                  onChange={personPhone => props.handleContactPersonPhoneNumber(personPhone)}
                  placeholder="Enter phone number"
                />
              </FormControl>
            </Grid>
          </div>
          <Grid item xs={12} md={12} sm={12}>
            <FormControl className="organization_PositionField organizationPersonPositionField">
              <Autocomplete
                options={
                  props.positionsList &&
                  props.positionsList.map(position => ({
                    id: position.id,
                    name: position.name,
                  }))
                }
                noOptionsText={'No positions available'}
                classes={{ listbox: 'autocompletelistbox' }}
                ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                getOptionLabel={option => option.name || ''}
                getOptionSelected={(option, value) => option.id === value.id}
                className="PersonAutocomplete"
                onChange={props.handlePositionData}
                value={props.values.designation || null}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    className="personAutocompleteTextfield autocompleteLabel"
                    label="Position"
                    required
                    margin="normal"
                    fullWidth
                    error={props.valuesError['personPosition_error']}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Paper>
      </div>
    </>
  );
};

export default OrganizationUpdateContactPerson;
