import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import NotificationsIcon from '@material-ui/icons/Notifications';
import InfiniteScroll from 'react-infinite-scroll-component';
import Link from '@material-ui/core/Link';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import { getNotifications, markAllAsRead, markSingleNotificationAsRead } from './Notifications.reducer';
import '../../../content/css/notifications.css';
import { NavLink } from 'react-router-dom';

const Notifications = props => {
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [count, setCount] = useState(0);

  const { notifications, notificationsCount, iconColor } = props;
  const newNotifications = `You have ${count} new notifications!`;
  const noNotifications = 'No new notifications';

  useEffect(() => {
    if (pageNumber > 1) {
      props.getNotifications(pageNumber);
    }
  }, [pageNumber]);

  useEffect(() => {
    if (notifications.length > 0) {
      let num = 0;
      notifications?.forEach(item => {
        if (!item.read) {
          num = num + 1;
        }
      });
      setCount(num);
    }
  }, [notifications]);

  const handleOpenMenu = event => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };

  const handleClick = e => {
    handleOpenMenu(e);
  };

  const readNotifications = e => {
    e.preventDefault();
    props.markAllAsRead();
  };

  return (
    <Tooltip arrow title={notifications.length ? newNotifications : noNotifications} placement="right-start">
      <IconButton color={iconColor}>
        <Badge badgeContent={count} color="error">
          <NotificationsIcon onClick={handleClick} />
          <NotificationsMenu
            markSingleNotificationAsRead={props.markSingleNotificationAsRead}
            notifications={notifications}
            open={openMenu}
            anchorEl={anchorEl}
            handleClose={handleCloseMenu}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            count={count}
            readNotifications={readNotifications}
          />
        </Badge>
      </IconButton>
    </Tooltip>
  );
};

export const NotificationsMenu = props => {
  const { notifications, open, anchorEl, handleClose, pageNumber, setPageNumber, readNotifications, count } = props;

  const readSingleNotification = item => {
    handleClose();
    if (item.read === false) {
      props.markSingleNotificationAsRead(item.id);
    }
  };

  const generateRoute = item => {
    if (item.leaveId !== null) {
      return `/Leave/${item.leaveId}`;
    } else if (item.incidentId !== null) {
      return `/incident-management/${item.incidentId}`;
    } else {
      return '';
    }
  };

  return (
    <>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        className="notifications-menu"
      >
        <div className="notifications-menu-main-heading-div">
          <div className="notifications-menu-heading-text">Notifications</div>
          <Badge badgeContent={count} color="error" className="notifications-menu-unread-count" />
          <div className="notifications-menu-heading-mark-read">
            <a href="#" onClick={readNotifications}>
              {' '}
              Mark all as read{' '}
            </a>
          </div>
        </div>
        <hr className="notifications-header-divider" />
        <div id="notifications-box-div">
          <InfiniteScroll
            dataLength={notifications.length}
            next={() => {
              setPageNumber(pageNumber + 1);
            }}
            scrollableTarget="notifications-box-div"
            hasMore={true}
            loader={''}
          >
            {Array.isArray(notifications) &&
              notifications
                .slice()
                .sort((a, b) => {
                  if (a.read && !b.read) return 1;
                  if (!a.read && b.read) return -1;

                  return new Date(b.date).getTime() - new Date(a.date).getTime();
                })
                .map((item, index) => (
                  <NavLink to={generateRoute(item)} key={index} style={{ textDecoration: 'none' }}>
                    <MenuItem key={index} onClick={() => readSingleNotification(item)} className="notifications-menu-notification-card">
                      <Avatar
                        alt="Remy Sharp"
                        className="notifications-menu-notification-avatar"
                        src={
                          item.imageUrl === undefined
                            ? 'content/images/icon5.png'
                            : item.imageUrl === null || item.imageUrl === ''
                            ? 'content/images/icon5.png'
                            : item.imageUrl
                        }
                      />
                      <div className="notifications-menu-notification-body-div">
                        <div className="notifications-menu-notification-title">{item.name}</div>
                        <div className="notifications-menu-notification-message">{item.message}</div>
                        <div className="notifications-menu-notification-message">
                          {item.date &&
                            new Date(item.date).toLocaleDateString('en-US', {
                              hour: 'numeric',
                              minute: 'numeric',
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                            })}
                        </div>
                      </div>
                      {!item.read && <div className="notifications-unread-badge" />}
                    </MenuItem>
                  </NavLink>
                ))}
          </InfiniteScroll>
        </div>
      </Menu>
    </>
  );
};

const mapStateToProps = ({ Notifications }: IRootState) => ({
  notifications: Notifications.notifications,
});
const mapDispatchToProps = {
  markAllAsRead,
  getNotifications,
  markSingleNotificationAsRead,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
