/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import { IRootState } from 'app/shared/reducers';
import Divider from '@material-ui/core/Divider';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import { Radio, RadioGroup } from '@material-ui/core';
import '../person.scss';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker, TimePicker } from '@material-ui/pickers';
import CustomToolbarComponent from '../../service-area/CustomToolbarComponent';
import DateFnsUtils from '@date-io/date-fns';
import Chip from '@material-ui/core/Chip';
import { getDateFormat } from '../../../shared/util/calender-utils';
import moment from 'moment';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import Checkbox from '@material-ui/core/Checkbox';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';

export interface IBannedComponent {
  open: boolean;
  setOpen: (value: boolean) => void;
  BannedModalMode: any;
  setBannedModalMode: (value: any) => void;
  prop: any;
  setting: any;
}

export const BannedComponent = (props: IBannedComponent) => {
  const { open, setOpen, BannedModalMode, setBannedModalMode, prop, setting } = props;
  const [fieldError, setFieldError] = useState({
    serviceAreaField_error: false,
    DescriptionField_error: false,
    startDateField_error: false,
    endDateField_error: false,
  });
  const [activeWarning, setActiveWarning] = useState(false);
  const [bannedModal, setBannedModal] = useState({
    selectServiceArea: null,
    banningReason: '',
    allServiceAreas: false,
    start_date: null,
    end_date: null,
  });

  const handleClose = () => {
    setOpen(false);
    setFieldError(oldValues => ({
      ...oldValues,
      employeeField_error: false,
      DescriptionField_error: false,
      startDateField_error: false,
      endDateField_error: false,
    }));
    reset();
  };

  useEffect(() => {
    if (bannedModal.selectServiceArea) {
      const activeServiceArea = prop?.personEntity.serviceAreas?.filter(rec => rec.id === bannedModal?.selectServiceArea?.id);
      if (activeServiceArea?.length !== 0) {
        setActiveWarning(true);
      } else {
        setActiveWarning(false);
      }
    } else if (bannedModal.allServiceAreas && prop?.personEntity?.serviceAreas) {
      setActiveWarning(true);
    } else {
      setActiveWarning(false);
    }
  }, [bannedModal]);

  useEffect(() => {
    if (BannedModalMode.Mode === 'edit' || BannedModalMode.Mode === 'delete') {
      setValuesInBannedModal();
    }
  }, [BannedModalMode]);

  const setValuesInBannedModal = () => {
    let selectedBannedEmployeeRecord = prop?.bannedServiceAreas?.filter(rec => rec.id === BannedModalMode.banned_id);
    if (selectedBannedEmployeeRecord) {
      selectedBannedEmployeeRecord = {
        selectServiceArea: selectedBannedEmployeeRecord[0].serviceArea,
        banningReason: selectedBannedEmployeeRecord[0].description,
        allServiceAreas: false,
        start_date: selectedBannedEmployeeRecord[0].start_date,
        end_date: selectedBannedEmployeeRecord[0].end_date,
      };
    }
    setBannedModal(selectedBannedEmployeeRecord);
  };

  const handleServiceAreaValues = (_, e) => {
    setBannedModal(oldValues => ({ ...oldValues, selectServiceArea: e, allServiceAreas: false }));
    if (bannedModal.selectServiceArea !== null || bannedModal.selectServiceArea !== undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['serviceAreaField_error']: false }));
    }
  };

  const handleDescriptionChange = event => {
    const alphabetWithSpaceRegex = /^[A-Za-z\s]+$/;
    if (alphabetWithSpaceRegex.test(event.target.value) || event.target.value === '') {
      setBannedModal(oldValues => ({ ...oldValues, banningReason: event.target.value }));
    }
    if (bannedModal.banningReason !== '' || bannedModal.banningReason !== undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['DescriptionField_error']: false }));
    }
  };

  const handleAllServiceAreas = () => {
    setBannedModal(oldValues => ({ ...oldValues, selectServiceArea: null, allServiceAreas: true }));
    if (bannedModal.selectServiceArea === null || bannedModal.selectServiceArea === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['serviceAreaField_error']: false }));
    }
  };

  const handleStartDate = event => {
    setBannedModal(oldValues => ({ ...oldValues, start_date: event }));
    if (bannedModal.start_date !== null || bannedModal.start_date !== undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['startDateField_error']: false }));
    }
  };

  const handleEndDate = event => {
    setBannedModal(oldValues => ({ ...oldValues, end_date: event }));
    if (bannedModal.end_date !== null || bannedModal.end_date !== undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['endDateField_error']: false }));
    }
  };

  const reset = () => {
    setBannedModal(oldValues => ({
      ...oldValues,
      selectServiceArea: null,
      banningReason: '',
      allServiceAreas: false,
      start_date: null,
      end_date: null,
    }));
  };

  const checkValidation = () => {
    let result;
    if (BannedModalMode.Mode === 'new' && bannedModal.allServiceAreas === false && bannedModal.selectServiceArea === null) {
      setFieldError(oldValues => ({ ...oldValues, ['serviceAreaField_error']: true }));
      result = true;
    }
    if (BannedModalMode.Mode === 'new' && bannedModal.banningReason === '') {
      setFieldError(oldValues => ({ ...oldValues, ['DescriptionField_error']: true }));
      result = true;
    }
    if (BannedModalMode.Mode === 'new' && bannedModal.start_date === null) {
      setFieldError(oldValues => ({ ...oldValues, ['startDateField_error']: true }));
      result = true;
    }
    if (BannedModalMode.Mode === 'delete' && bannedModal.end_date === null) {
      setFieldError(oldValues => ({ ...oldValues, ['endDateField_error']: true }));
      result = true;
    }
    return result;
  };

  const handleBanServiceAreas = () => {
    if (!checkValidation()) {
      const bannedData = {
        person: prop.personEntity,
        service_area: bannedModal.allServiceAreas === false ? bannedModal.selectServiceArea : null,
        description: bannedModal.banningReason,
        start_date: moment(bannedModal.start_date).format('YYYY-MM-DD'),
        end_date: moment(bannedModal.end_date).format('YYYY-MM-DD'),
      };
      if (bannedModal.allServiceAreas === false) {
        prop.bannedEntity('currentServiceArea', bannedData, 'person');
      } else {
        prop.bannedEntity('allServiceAreas', bannedData, 'person');
      }
      reset();
      setOpen(false);
    } else {
      const bannedData = {
        person: prop.personEntity,
        service_area: bannedModal.allServiceAreas === false ? bannedModal.selectServiceArea : null,
        description: bannedModal.banningReason,
        start_date: moment(bannedModal.start_date).format('YYYY-MM-DD'),
        end_date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      };
      if (bannedModal.allServiceAreas === false) {
        prop.bannedEntity('currentServiceArea', bannedData, 'person');
      } else {
        prop.bannedEntity('allServiceAreas', bannedData, 'person');
      }
      reset();
      setOpen(false);
    }
  };

  const handleUpdateBanEmployee = () => {
    if (BannedModalMode.Mode === 'edit') {
      if (!checkValidation()) {
        const bannedData = {
          person: prop.personEntity,
          service_area: bannedModal.allServiceAreas === false ? bannedModal.selectServiceArea : null,
          description: bannedModal.banningReason,
          start_date: moment(bannedModal.start_date).format('YYYY-MM-DD'),
          end_date: moment(bannedModal.end_date).format('YYYY-MM-DD'),
          allServiceAreas: bannedModal.allServiceAreas === false ? false : true,
        };
        prop.updateBannedPeople(bannedData, BannedModalMode.banned_id, 'person');
        reset();
        setOpen(false);
      }
    } else {
      const bannedData = {
        person: prop.personEntity,
        service_area: bannedModal.allServiceAreas === false ? bannedModal.selectServiceArea : null,
        description: bannedModal.banningReason,
        start_date: moment(bannedModal.start_date).format('YYYY-MM-DD'),
        end_date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
        allServiceAreas: bannedModal.allServiceAreas === false ? false : true,
      };
      prop.updateBannedPeople(bannedData, BannedModalMode.banned_id, 'person');
      reset();
      setOpen(false);
    }
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <div className="personBannedModaPaper">
          <h2 className="personBannedModalTitle">
            {BannedModalMode.Mode === 'delete' ? 'Reauthorise Service Area(s)' : 'Unauthorise Service Area(s)'}
          </h2>
          <p style={{ marginLeft: '4%' }}>
            Are you sure you want to {BannedModalMode.Mode === 'delete' ? 'reauthorise' : 'unauthorise'} this service area(s)?
          </p>

          <Divider className="personBannedModalDivider" />

          <Grid item className="personBannedModalSelectBox" xs={12} lg={12}>
            <FormControl variant="outlined" size="small" className="personBannedModalFields radioButtonAndOR">
              <FormControlLabel
                className={
                  (BannedModalMode.Mode === 'delete' ? 'unbannedModalRadioButtion' : 'personBannedModalRadioButtion') +
                  ' ' +
                  (bannedModal.allServiceAreas === true ? 'personBannedModalRadioButton' : 'personFormControlLabelWhite')
                }
                label={BannedModalMode.Mode === 'delete' ? 'Reauthorise All Service Areas' : 'Unauthorise All Service Areas'}
                labelPlacement="start"
                control={
                  <Checkbox
                    icon={
                      <CircleUnchecked
                        className={bannedModal.allServiceAreas === false ? 'personCircleUncheckedInBannedModal' : 'personCheckBoxWhite'}
                      />
                    }
                    checkedIcon={
                      <CircleCheckedFilled
                        className={bannedModal.allServiceAreas === true ? 'personCheckCircleFilledInBannedModal' : 'personCheckBoxWhite'}
                      />
                    }
                    checked={bannedModal.allServiceAreas}
                    onChange={handleAllServiceAreas}
                    className="personBannedModalFirstRadioButtion"
                  />
                }
              />
              <span className="personBannedModalORText">Or</span>
            </FormControl>
          </Grid>

          <Grid item className="personBannedModalSelectBox" xs={12} lg={12}>
            <FormControl variant="outlined" size="small" className="personBannedModalFields" style={{ marginTop: '1%' }}>
              <Autocomplete
                className="PersonBannedModalAutocomplete"
                options={prop.serviceAreaList}
                noOptionsText={'No Records'}
                classes={{ listbox: 'autocompletelistbox' }}
                ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                getOptionLabel={option => option.name || ''}
                value={bannedModal.selectServiceArea}
                disabled={prop.loading}
                onChange={handleServiceAreaValues}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    className="personAutocompleteTextfield personBannedSelectBox"
                    label="Select Service Area(s)"
                    error={fieldError['serviceAreaField_error']}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item className="personBannedModalDatesBox" lg={12} xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                autoOk
                variant="inline"
                size="small"
                inputVariant="outlined"
                label="From"
                format={setting.date_format ? getDateFormat(setting.date_format) : 'MM/dd/yyyy'}
                value={bannedModal.start_date}
                className="personBannedModalStartDate"
                ToolbarComponent={prop => (
                  <>
                    <CustomToolbarComponent {...prop} settingFormat={setting.date_format} />
                  </>
                )}
                placeholder={setting.date_format ? getDateFormat(setting.date_format) : 'MM/dd/yyyy'}
                InputAdornmentProps={{ position: 'end' }}
                onChange={date => handleStartDate(date)}
                error={fieldError['startDateField_error']}
                disabled={BannedModalMode.Mode === 'delete'}
                InputProps={{
                  readOnly: BannedModalMode.Mode === 'delete',
                }}
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                autoOk
                variant="inline"
                size="small"
                inputVariant="outlined"
                label="Till"
                format={setting.date_format ? getDateFormat(setting.date_format) : 'MM/dd/yyyy'}
                value={bannedModal.end_date}
                className="personBannedModalEndDate"
                ToolbarComponent={prop => (
                  <>
                    <CustomToolbarComponent {...prop} settingFormat={setting.date_format} />
                  </>
                )}
                placeholder={setting.date_format ? getDateFormat(setting.date_format) : 'MM/dd/yyyy'}
                InputAdornmentProps={{ position: 'end' }}
                onChange={date => handleEndDate(date)}
                minDate={moment(bannedModal.start_date)}
                error={fieldError['endDateField_error']}
                disabled={BannedModalMode.Mode === 'delete' ? true : false}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          {BannedModalMode.Mode !== 'delete' ? (
            <FormControlLabel
              style={{ marginLeft: '5px' }}
              control={
                <Checkbox
                  checked={bannedModal.end_date === null}
                  onChange={event => {
                    if (event.target.checked) {
                      setBannedModal(oldValues => ({ ...oldValues, end_date: null }));
                    }
                  }}
                  color="primary"
                />
              }
              label="Until Further Notice"
            />
          ) : (
            ' '
          )}
          <FormControl className="personBannedModalFields">
            <FormGroup row={true}>
              <Grid item className="personBannedModalSelectBox" xs={12} lg={12}>
                <TextField
                  id="reason-for-banning"
                  label="Reason for unauthorising"
                  className="personBannedModalNote"
                  variant="outlined"
                  value={bannedModal.banningReason}
                  onChange={handleDescriptionChange}
                  multiline={true}
                  minRows={3}
                  InputLabelProps={{
                    shrink: bannedModal.banningReason === '' ? false : true,
                  }}
                  error={fieldError['DescriptionField_error']}
                />
              </Grid>
            </FormGroup>
          </FormControl>

          {activeWarning === true ? (
            <div style={{ paddingLeft: '12px', marginTop: '10px' }}>
              <div style={{ color: '#856404', fontSize: '12px' }}>Attention:</div>
              <div style={{ color: '#856404', fontSize: '10px' }}>
                This person have future shifts assigned to them for the selected service area.
              </div>
            </div>
          ) : null}
          <Divider className="personBannedModalDivider" />

          <FormControl className="personBannedModalFields">
            <FormGroup row={true}>
              <Grid item xs={3} lg={4}>
                <Button variant="contained" className="personBannedModalCancelButton" onClick={handleClose}>
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={3} lg={4}>
                <Button
                  variant="contained"
                  className="personBannedModalSaveButton"
                  onClick={() => {
                    if (BannedModalMode.Mode === 'edit' || BannedModalMode.Mode === 'delete') {
                      handleUpdateBanEmployee();
                    } else {
                      handleBanServiceAreas();
                    }
                  }}
                >
                  Confirm
                </Button>
              </Grid>
            </FormGroup>
          </FormControl>
        </div>
      </Modal>
    </>
  );
};
