const config = {
  VERSION: process.env.VERSION,
  FIREBASE_API_KEY: 'AIzaSyC7m-Y8mdOfHscQ9al0b2MBcbmUNLnRTSA',
  FIREBASE_AUTH_DOMAIN: 'fir-project-308109.firebaseapp.com',
  FIREBASE_PROJECT_ID: 'firebase-project-308109',
  FIREBASE_STORAGE_BUCKET: 'firebase-project-308109.appspot.com',
  FIREBASE_MESSAGE_SENDER_ID: '354877687154',
  FIREBASE_APP_ID: '1:354877687154:web:6502e88c0018ec7c1689eb',
  FIREBASE_MEASUREMENT_ID: 'G-KHK40MPVYS',
  FIREBASE_VAPID_KEY: 'BFoH9rpMMeK7FdIfz7xn-1fEESTcrZeeLp7CqbZZU06PDpiMzuDBSMRpjb99-XirPdYW2k0LSX35DgbBdO7iE-Q',
};

export default config;

export const SERVER_API_URL = process.env.SERVER_API_URL;

export const AUTHORITIES = {
  SYSTEM_ADMIN: 'System Admin',
  ORGANIZATION_ADMINISTRATOR: 'Organization Administrator',
  SUPERVISOR: 'Supervisor',
  MANAGER: 'Manager',
  USER: 'User',
};

export const messages = {
  DATA_ERROR_ALERT: 'Internal Error',
};

export const APP_DATE_FORMAT = 'DD/MM/YY HH:mm';
export const APP_TIMESTAMP_FORMAT = 'DD/MM/YY HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT = 'DD/MM/YYYY';
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';
export const APP_LOCAL_DATETIME_FORMAT_Z = 'YYYY-MM-DDTHH:mm Z';
export const APP_WHOLE_NUMBER_FORMAT = '0,0';
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = '0,0.[00]';

export const PATROL_CHECK_POINT_HEADING = 'Checkpoint(s) list according to creation';
