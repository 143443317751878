import React, { useState } from 'react';
import { Grid, Paper, makeStyles } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RoomIcon from '@material-ui/icons/Room';
import Divider from '@material-ui/core/Divider';
import { faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons';
import serviceArea from 'app/entities/service-area/service-area';
import { CardContent, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import buildingIcon from '../../../../content/images/buildingIcon.png';
import forwardIcon from '../../../../content/images/forwardIcon.png';
import { Link } from 'react-router-dom';
const useStyles = makeStyles(theme => ({
  selectBox: {
    minWidth: '35vh',
    margin: 'auto',
    marginTop: '2vh',
    paddingTop: '1vh',
  },
  textBox: {
    minWidth: 200,
    margin: 'auto',
    marginTop: '1vh',
    paddingTop: '1vh',
  },
  buttons: {
    minWidth: 100,
    marginTop: '3vh',
    marginRight: '2vh',
    float: 'right',
  },
  sideBySide: {
    display: 'flex',
    flexDirection: 'row',
  },
  drawer: {
    width: '18%',
  },
  drawerPaper: {
    width: '18%',
    position: 'absolute',
    marginTop: '1vh',
    marginLeft: 0,
    zIndex: 1,
  },
  active: {
    background: '#F4F4F4',
  },
  title: {
    textAlign: 'left',
    marginLeft: '10vh',
  },
  mainTitle: {
    padding: theme.spacing(2),
    marginLeft: '3.2vh',
    textAlign: 'left',
    color: '#6610f2',
    fontWeight: 450,
  },
  flexContainer: {
    display: 'flex',
    background: 'linear-gradient(to top, #FFFFFF 50%, #EBECF0 50%)',
  },
}));

const ActiveServiceAreaComponent = props => {
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteServiceAreaId, setDeleteServiceAreaId] = useState(null);
  const [deletePersonId, setDeletePersonId] = useState(props.prop.personEntity.id);
  const [deleteMessage, setDeleteMessage] = useState('');
  const [isHovered, setIsHovered] = useState(false);

  const handleEditClose = service_area_id => {
    props.prop.history.push(`/service-areas/${service_area_id}`);
  };

  const handleDeleteOpen = entity => {
    setDeleteServiceAreaId(entity.service_area_id);
    setDeleteMessage(`${entity.service_area.name} will be removed from this Staff`);
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const confirmDelete = () => {
    if (deleteServiceAreaId !== null) {
      props.prop.deleteStaffEntity(deleteServiceAreaId, deletePersonId);
      setOpenDialog(false);
    }
  };

  return (
    <Grid container spacing={2} className="Card-Container" style={{ display: 'flex', flexWrap: 'wrap' }}>
      {props.prop && props.prop.activeServiceAreasList && props.prop.activeServiceAreasList !== null
        ? props.prop.activeServiceAreasList.map((entity, i) => (
            <Grid item lg={6} md={6} sm={6} xs={6} key={i}>
              <Paper elevation={2} className="service-area-component-main-container">
                <div className="service_area_component_name_div">
                  <span style={{ display: 'flex' }}>
                    <img src="content/icons/Khired Networks.png" style={{ marginRight: '2%' }} />
                    <h5>{entity.service_area.name}</h5>
                  </span>
                  <span>
                    <img
                      src={forwardIcon}
                      alt="Forward icon"
                      style={{
                        cursor: 'pointer',
                        fontFamily: 'Roboto',
                        width: '12px',
                        height: '12px',
                        opacity: 1,
                        marginLeft: '250px',
                        marginTop: '13px',
                      }}
                      onClick={() => handleEditClose(entity.service_area_id)}
                    />
                  </span>
                </div>
                <div className="service_area_component_adress_div">
                  <span style={{ display: 'flex' }}>
                    <img src="content/icons/location.svg" style={{ marginRight: '2%' }} />
                    <p>{entity.service_area.address !== null ? entity.service_area.address.address_line_one : null}</p>
                  </span>
                  {props.editMode === true ? (
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      color="#bebebe"
                      style={{ cursor: 'pointer', marginLeft: '10px' }}
                      onClick={() => handleDeleteOpen(entity)}
                    />
                  ) : null}
                </div>
              </Paper>
            </Grid>
          ))
        : !props.loading && <div className="no-record-warning">No Service Area found</div>}

      <Dialog open={openDialog} onClose={handleClose} className="DeleteBox">
        <DialogTitle data-cy="serviceAreaDeleteDialogHeading">
          <span>{deleteMessage}</span>
        </DialogTitle>
        <DialogContent id="wfmApp.serviceArea.delete.question">
          <span>Are you sure you want to continue?</span>
        </DialogContent>
        <DialogActions>
          <Button style={{ color: 'white', backgroundColor: '#14a37f' }} onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button style={{ color: 'white', backgroundColor: '#b71c1c' }} onClick={confirmDelete} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default ActiveServiceAreaComponent;
