import React, { useState } from 'react';
import { Button, FormGroup, FormControl, Grid } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Modal from '@material-ui/core/Modal';
import { Radio, RadioGroup } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import './calendar.scss';
import { UpdateSchedule } from './calendarModalFunctions';

export interface IModalComponent {
  show: boolean;
  setShow: (value: boolean) => void;
  modalProp: any;
  calendarDisplayWindow: any;
}

const UpdateModal = (props: IModalComponent) => {
  const { show, setShow, modalProp, calendarDisplayWindow } = props;
  const [occurrenceUpdate, setOccurrenceUpdate] = useState(false);
  const [seriesUpdate, setSeriesUpdate] = useState(false);

  const handleOccurrenceChange = () => {
    setOccurrenceUpdate(true);
    modalProp?.setModalForm(oldValues => ({
      ...oldValues,
      isRepeat: false,
      repeatEnd: '',
      repeatStart: '',
      repeatType: '',
      repeatPattern: '',
    }));
    if (seriesUpdate === true) {
      setSeriesUpdate(false);
    }
  };

  const handleSeriesChange = () => {
    setSeriesUpdate(true);
    modalProp?.setModalForm(oldValues => ({
      ...oldValues,
      isRepeat: true,
      repeatEnd: modalProp.modalForm.repeatEnd,
      repeatStart: modalProp.modalForm.start_date,
      repeatType: modalProp.modalForm.repeatType,
      repeatPattern: modalProp.modalForm.repeatPattern,
    }));
    if (occurrenceUpdate === true) {
      setOccurrenceUpdate(false);
    }
  };

  const handleClose = () => setShow(false);

  return (
    <Modal open={show} onClose={handleClose} className="create-modal">
      <div className="paper-update">
        <h2 className="shift-modal-title">Update Shift</h2>
        <p>Are you sure you want to update this shift?</p>

        <Divider className="calendar-new-shift-modal-divider" />

        <Grid item className="modal-input" xs={6} lg={6}>
          <FormControl variant="outlined" size="small">
            <RadioGroup className="checkboxes">
              <FormControlLabel
                className="check-boxes"
                label="Update current shift"
                control={<Radio style={{ color: '#4620B7' }} checked={occurrenceUpdate} onChange={handleOccurrenceChange} />}
              />
              <FormControlLabel
                className="check-boxes"
                label="Update series"
                control={<Radio style={{ color: '#4620B7' }} checked={seriesUpdate} onChange={handleSeriesChange} />}
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Divider className="calendar-new-shift-modal-divider" />

        <FormControl className="modal-forms">
          <FormGroup row={true}>
            <Grid item xs={3} lg={4}>
              <Button
                variant="contained"
                className="modal-button-save"
                onClick={() => {
                  UpdateSchedule(modalProp.parentProp, modalProp, calendarDisplayWindow);
                  setShow(false);
                }}
              >
                Confirm
              </Button>
            </Grid>
            <Grid item xs={3} lg={4}>
              <Button variant="contained" className="modal-button-cancel" onClick={handleClose}>
                Cancel
              </Button>
            </Grid>
          </FormGroup>
        </FormControl>
      </div>
    </Modal>
  );
};

export default UpdateModal;
