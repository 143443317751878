import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, Redirect } from 'react-router-dom';
import { Button, Grid, FormControl, TextField, MenuItem, Paper, InputAdornment, IconButton } from '@material-ui/core';
import { Translate, translate } from 'react-jhipster';
import { IRootState } from 'app/shared/reducers';
import './servicearea.scss';
import { getEntities as getDesignations, reset as resetDes } from 'app/entities/designation/designation.reducer';
import { getEntity, updateEntity, createEntity, reset, setPageNumber } from './service-area.reducer';
import { getAllClients as getClients, reset as resetClient } from 'app/entities/client/client.reducer';
import 'react-toastify/dist/ReactToastify.css';
import { getPersonalSettings } from '../../modules/Setting/Personal-Setting.reducer';
import { getOrganizationSettings } from '../../modules/Setting/generalSettings.reducer';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { CurrencyChecker } from '../../../utils/TimeFormatAndTimezone';
import { validationMessageOnChange, validationMessageBeforeSubmit } from '../../../utils/validationMessages';
import Checkpoint from './checkpoint';
import { FilterPositionInServiveArea, FilterClientInServiveArea } from '../../../utils/FilterValuesFucntions';
import { getEntities as getShifts } from 'app/entities/shift/shift.reducer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import { Storage } from 'react-jhipster';
import { useLoadScript } from '@react-google-maps/api';
import MenuIcon from '@material-ui/icons/Menu';
import DeleteIcon from '@material-ui/icons/Delete';
import GoogleMapsComponent from './map';
import ServiceAreaClientInformation from './service-area-update-client-information';
import ServiceAreaChargerateInformation from './service-area-chargerate-information';
import ServiceAreaPatrolSettings from './service-area-update-patrol-settings';
import ServiceAreaShiftExecution from './service-area-update-shift-execution';

export interface IServiceAreaUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const ServiceAreaUpdate = (props: IServiceAreaUpdateProps) => {
  const [isNew] = useState(!props.match.params || !props.match.params.id);
  const { serviceAreaEntity, designations, loading, updating, clientsList, match } = props;
  const [values, setValues] = React.useState<any | null>(serviceAreaEntity);
  const [currency, setCurrency] = useState(props.settings.currency);
  const [chargeRateEnd, setChargeRateEnd] = React.useState(null);
  const [chargeRateStart, setChargeRateStart] = React.useState(null);
  const [openMap, setOpenMap] = useState(false);
  const [fieldError, setFieldError] = useState({
    name_error: false,
    name_helper: '',
    address_line_one_error: false,
    address_line_one_helper: '',
    checkpoints_helper: '',
    sequence_helper: '',
    positionId_error: false,
    checkpointsId_error: false,
    sequenceId_error: false,
    clientId_error: false,
    serviceAreaChargeRate_error: false,
    identificationNumber_error: false,
    identificationNumber_helper: '',
  });
  const [colorCheckPoint, setColorCheckPoint] = useState({ ['colorCheckPoint']: 'false', ['colorMap']: 'false' });
  const [checkPointsAllowed, setCheckPointsAllowed] = useState({ ['checkPointsAllowed']: false });
  const [startError, setStartError] = useState(false);
  const [endError, setEndError] = useState(false);
  const [checkpointsList, setCheckpointsList] = useState([]);
  const [client, setClient] = useState({});
  const [first_login] = useState(Storage.local.get('first_login'));
  const [center, setCenter] = useState({ lat: 31.469547, lng: 74.451082 });
  const [coordinates, setCoordinates] = React.useState([]);
  const [markerCount, setMarkerCount] = React.useState(1);
  const dragItem = React.useRef<any>(null);
  const dragOverItem = React.useRef<any>(null);
  const [tab, setTab] = useState({ option: 'general' });
  const [isEdit, setIsEdit] = useState(false);

  const handleChange = (tag, value) => {
    setValues(oldValues => ({ ...oldValues, [tag]: value }));
    if (value === true && tag === 'checkpoints') {
      setColorCheckPoint(oldValues => ({ ...oldValues, ['colorCheckPoint']: 'true' }));
      setCheckPointsAllowed(oldValues => ({ ...oldValues, ['checkPointsAllowed']: true }));
    } else if (value === false && tag === 'checkpoints') {
      setColorCheckPoint(oldValues => ({ ...oldValues, ['colorCheckPoint']: 'false' }));
      setCheckPointsAllowed(oldValues => ({ ...oldValues, ['checkPointsAllowed']: false }));
    }
  };

  const handleMapChange = (tag, value) => {
    setValues(oldValues => ({ ...oldValues, [tag]: value }));
    // deleteMarks();
    if (value === true && tag === 'map') {
      setColorCheckPoint(oldValues => ({ ...oldValues, ['colorMap']: 'true' }));
      setCheckPointsAllowed(oldValues => ({ ...oldValues, ['mapAllowed']: true }));
    } else if (value === false && tag === 'map') {
      setColorCheckPoint(oldValues => ({ ...oldValues, ['colorMap']: 'false' }));
      setCheckPointsAllowed(oldValues => ({ ...oldValues, ['mapAllowed']: false }));
    }
  };

  const handleClose = () => {
    props.history.push('/service-areas');
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }
    props.resetClient();
    props.resetDes();
    props.getDesignations(-1);
    props.getClients(-1);
  }, []);

  useEffect(() => {
    setCurrency(props.settings.currency);
  }, [props.settings]);

  useEffect(() => {
    setValues(serviceAreaEntity);
    if (serviceAreaEntity['chargeRateStartDate']) {
      setChargeRateStart(new Date(serviceAreaEntity['chargeRateStartDate']));
    }
    if (serviceAreaEntity['chargeRateEndDate']) {
      setChargeRateEnd(new Date(serviceAreaEntity['chargeRateEndDate']));
    }
    if (serviceAreaEntity['checkpoints'] === true) {
      setCheckPointsAllowed(oldValues => ({ ...oldValues, ['checkPointsAllowed']: true }));
      // populateCheckpoints();
    }
    if (serviceAreaEntity['map'] === true) {
      setCheckPointsAllowed(oldValues => ({ ...oldValues, ['mapAllowed']: true }));
      // populateMap();
    }
  }, [serviceAreaEntity, clientsList]);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const handleChargeRateStart = e => {
    if (chargeRateEnd !== undefined && chargeRateEnd !== null && chargeRateEnd.valueOf() < e.valueOf()) {
      setStartError(true);
    } else {
      setStartError(false);
      const d = moment(e).format('YYYY-MM-DD');
      setChargeRateStart(e);
      setValues(oldValues => ({ ...oldValues, ['chargeRateStartDate']: d }));
    }
  };

  const handleChargeRateEnd = e => {
    if (chargeRateStart !== undefined && chargeRateStart !== null && chargeRateStart.valueOf() > e.valueOf()) {
      setEndError(true);
    } else {
      setEndError(false);
      const d = moment(e).format('YYYY-MM-DD');
      setChargeRateEnd(e);
      setValues(oldValues => ({ ...oldValues, ['chargeRateEndDate']: d }));
    }
  };

  const setNewValues = e => {
    if (e.target.name === 'address_line_one') {
      setValues(prevState => ({ ...prevState, address: { ...prevState.address, address_line_one: e.target.value } }));
      setFieldError(oldFieldError => ({
        ...oldFieldError,
        [e.target.name + '_error']: false,
        [e.target.name + '_helper']: '',
      }));
      if (e.target.value === '') {
        setFieldError(oldFieldError => ({
          ...oldFieldError,
          [e.target.name + '_error']: true,
          [e.target.name + '_helper']: validationMessageOnChange.address,
        }));
      }
    } else {
      setValues(oldValues => ({ ...oldValues, [e.target.name]: e.target.value }));
      if (e.target.name === 'name' && (!/^(?! )[a-zA-Z0-9\s(),.-]*$/.test(e.target.value) || e.target.value === '')) {
        setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: true }));
        setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_helper']: validationMessageOnChange.name }));
      } else if ((e.target.name === 'clientId' || e.target.name === 'serviceAreaChargeRate') && e.target.value === null) {
        setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: true }));
      } else if (e.target.name === 'identificationNumber' && !/^(?! )[a-zA-Z0-9\s(),.-]*$/.test(e.target.value)) {
        setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: true }));
        setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_helper']: validationMessageOnChange.number }));
      } else {
        setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: false, [e.target.name + '_helper']: '' }));
      }
    }
  };

  const handleClientData = (_, e) => {
    setValues(oldValues => ({ ...oldValues, ['client']: e }));
    setFieldError(oldFieldError => ({ ...oldFieldError, ['clientId_error']: false }));
  };

  const handlePositionData = (_, e) => {
    setValues(oldValues => ({ ...oldValues, ['designation']: e }));
    setFieldError(oldFieldError => ({ ...oldFieldError, ['positionId_error']: false }));
  };

  const checkForValidations = () => {
    const result =
      !fieldError['name_error'] &&
      !fieldError['address_line_one_error'] &&
      !fieldError['positionId_error'] &&
      !fieldError['clientId_error'] &&
      !fieldError['serviceAreaChargeRate_error'] &&
      !fieldError['identificationNumber_error'] &&
      !endError &&
      !startError &&
      values['name'] !== undefined &&
      values['address']['address_line_one'] !== undefined &&
      values['designation']['name'] !== undefined &&
      values['client'] !== undefined &&
      values['serviceAreaChargeRate'] !== undefined;
    return result;
  };

  const checkForRequiredValidations = () => {
    let output = true;
    if (values['name'] === undefined) {
      setFieldError(oldFieldError => ({ ...oldFieldError, ['name_error']: true }));
      output = false;
    }
    if (values['address'] === undefined) {
      setFieldError(oldFieldError => ({ ...oldFieldError, ['address_line_one_error']: true }));
      output = false;
    }
    if (values['designation'] === undefined || values['designation'] === null) {
      setFieldError(oldFieldError => ({ ...oldFieldError, ['positionId_error']: true }));
      output = false;
    }
    if (values['client'] === undefined || values['client'] === null) {
      setFieldError(oldFieldError => ({ ...oldFieldError, ['clientId_error']: true }));
      output = false;
    }
    if (values['serviceAreaChargeRate'] === undefined) {
      setFieldError(oldFieldError => ({ ...oldFieldError, ['serviceAreaChargeRate_error']: true }));
      output = false;
    }
    return output;
  };

  const saveEntity = e => {
    e.preventDefault();
    if (isNew && checkForRequiredValidations() && checkForValidations()) {
      props.createEntity(values);
      if (first_login === 'true' && props.isOrganizationAdministrator && (props.shifts === undefined || props.shifts.length === 0)) {
        props.history.push('/calendar');
      }
    } else if (!isNew && checkForRequiredValidations() && checkForValidations()) {
      props.updateEntity(values);
    }
  };

  const handleServiceAreaTabs = tag => {
    if (tag === 'general') {
      setTab(oldValues => ({ ...oldValues, ['option']: 'general' }));
    } else if (tag === 'patrol') {
      setTab(oldValues => ({ ...oldValues, ['option']: 'patrol' }));
    } else if (tag === 'shift') {
      setTab(oldValues => ({ ...oldValues, ['option']: 'shift' }));
    }
  };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,

    libraries: ['places'],
  });

  if (!isLoaded) {
    return <div />;
  }

  return (
    <div className="service-arae-create-main-container">
      <div className="servicearea-full-container">
        <span className="service-area-main-heading">Create/ Edit a Service Area</span>
        <br></br>
        <span className="service-area-sub-heading">You can edit this info anytime</span>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <div className="innerPaper">
            <Paper style={{ overflow: 'auto', padding: '5%' }}>
              <div className="service-area-tabs">
                <ButtonGroup style={{ marginLeft: '80px' }} className="control-room-button-group">
                  <Button
                    disableRipple={true}
                    disableFocusRipple={true}
                    id="automated-button-group"
                    className={tab['option'] === 'general' ? 'activeTab' : 'nonActiveTab'}
                    onClick={() => {
                      handleServiceAreaTabs('general');
                    }}
                  >
                    General
                  </Button>
                  <Button
                    id="automated-button-group"
                    className={tab['option'] === 'patrol' ? 'activeTab' : 'nonActiveTab'}
                    onClick={() => {
                      handleServiceAreaTabs('patrol');
                    }}
                  >
                    Patrol Settings
                  </Button>
                  <Button
                    id="automated-button-group"
                    className={tab['option'] === 'shift' ? 'activeTab' : 'nonActiveTab'}
                    onClick={() => {
                      handleServiceAreaTabs('shift');
                    }}
                  >
                    Shift Execution Settings
                  </Button>
                </ButtonGroup>
              </div>

              <form className="servicearea-form" noValidate>
                {tab['option'] === 'general' ? (
                  <>
                    {!isNew ? (
                      <div className="edit-service-area-details-outer-div create-service-area-fieldsets">
                        <div className="edit-service-area-detail-fields-outer-div">
                          <Grid container spacing={3}>
                            <Grid item lg={6} xs={12}>
                              <TextField
                                id="service-area-id"
                                className="service-area-fields"
                                variant="outlined"
                                name="id"
                                required
                                inputProps={{ readOnly: true }}
                                label={<Translate contentKey="global.field.id">ID</Translate>}
                                value={serviceAreaEntity.id}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    ) : null}

                    <div className="create-service-area-details-outer-div create-service-area-fieldsets">
                      {/* <span className="create-service-area-details-heading-span"> Service Area detail </span> */}
                      <div className="create-service-area-detail-fields-outer-div">
                        <Grid container spacing={3}>
                          <Grid item lg={6} xs={12}>
                            <TextField
                              required
                              className="service-area-fields"
                              variant="outlined"
                              name="name"
                              label="Name"
                              value={values['name'] ? values['name'] : ''}
                              onChange={setNewValues}
                              defaultValue={serviceAreaEntity.name}
                              error={fieldError['name_error']}
                              helperText={fieldError['name_helper']}
                              inputProps={{ maxLength: 255 }}
                            />
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <TextField
                              className="service-area-fields"
                              variant="outlined"
                              label="Id / Contract Number"
                              name="identificationNumber"
                              onChange={setNewValues}
                              defaultValue={serviceAreaEntity.identification_number}
                              value={values['identificationNumber'] ? values['identificationNumber'] : ''}
                              error={fieldError['identificationNumber_error']}
                              helperText={fieldError['identificationNumber_helper']}
                              inputProps={{ maxLength: 15 }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              required
                              className="service-area-address"
                              variant="outlined"
                              label="Address"
                              name="address_line_one"
                              value={values?.address?.address_line_one || null}
                              onChange={setNewValues}
                              error={fieldError['address_line_one_error']}
                              helperText={fieldError['address_line_one_helper']}
                              inputProps={{ maxLength: 255 }}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </div>

                    <ServiceAreaClientInformation
                      clientsList={clientsList}
                      handleClientData={handleClientData}
                      client={client}
                      fieldError={fieldError}
                      designations={designations}
                      handlePositionData={handlePositionData}
                      values={values}
                      isEdit={isEdit}
                    />

                    <ServiceAreaChargerateInformation
                      settings={props.settings}
                      setNewValues={setNewValues}
                      serviceAreaEntity={serviceAreaEntity}
                      startError={startError}
                      setting={props.setting}
                      chargeRateStart={chargeRateStart}
                      handleChargeRateStart={handleChargeRateStart}
                      endError={endError}
                      chargeRateEnd={chargeRateEnd}
                      handleChargeRateEnd={handleChargeRateEnd}
                      values={values}
                      fieldError={fieldError}
                      isEdit={isEdit}
                    />
                  </>
                ) : null}

                {tab['option'] === 'patrol' ? (
                  <>
                    <ServiceAreaPatrolSettings
                      values={values}
                      setValues={setValues}
                      isNew={isNew}
                    />
                  </>
                ) : null}

                {tab['option'] === 'shift' ? (
                  <>
                    <ServiceAreaShiftExecution
                      setValues={setValues}
                      coordinates={coordinates}
                      setCoordinates={setCoordinates}
                      openMap={openMap}
                      setOpenMap={setOpenMap}
                      values={values}
                      isNew={isNew}
                      serviceAreaEntity={serviceAreaEntity}
                    />
                  </>
                ) : null}

                <Grid item xs={12} className="service-area-settings-button-div">
                  <FormControl style={{ display: 'inline-block', float: 'right' }}>
                    <Button
                      className="service-area-discard-button"
                      component={Link}
                      to="/service-areas"
                      onClick={() => {
                        props.reset();
                      }}
                    >
                      &nbsp; Discard
                    </Button>
                    &nbsp;
                    <Button className="service-area-save-button" type="submit" disabled={updating} onClick={saveEntity}>
                      &nbsp; Save Changes
                    </Button>
                  </FormControl>
                </Grid>
              </form>
            </Paper>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  clientsList: storeState.client.entities,
  designations: storeState.designation.entities,
  serviceAreaEntity: storeState.serviceArea.entity,
  loading: storeState.serviceArea.loading,
  updating: storeState.serviceArea.updating,
  updateSuccess: storeState.serviceArea.updateSuccess,
  setting: storeState.PersonalSettings.storedPersonalSettings,
  settings: storeState.organizationSettings.storedOrganizationSettings,
  shifts: storeState.shift.entities,
  isOrganizationAdministrator: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.ORGANIZATION_ADMINISTRATOR]),
});

const mapDispatchToProps = {
  getClients,
  getDesignations,
  getEntity,
  updateEntity,
  createEntity,
  reset,
  resetDes,
  resetClient,
  getPersonalSettings,
  getOrganizationSettings,
  getShifts,
  setPageNumber,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ServiceAreaUpdate);
