import React, { useState } from 'react';
import { Divider, Drawer } from '@material-ui/core';
import { Select, MenuItem, FormControl, InputLabel, makeStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import WorkIcon from '@material-ui/icons/Work';
import StarsIcon from '@material-ui/icons/Stars';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useHistory, useLocation } from 'react-router';
import { faBookOpen, faEdit, faRuler } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DescriptionIcon from '@material-ui/icons/Description';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import LocationOnIcon from '@material-ui/icons/LocationOn';

const useStyles = makeStyles(theme => ({
  selectBox: {
    minWidth: '35vh',
    margin: 'auto',
    marginTop: '2vh',
    paddingTop: '1vh',
  },
  textBox: {
    minWidth: 200,
    margin: 'auto',
    marginTop: '1vh',
    paddingTop: '1vh',
  },
  buttons: {
    minWidth: 100,
    marginTop: '3vh',
    marginRight: '2vh',
    float: 'right',
  },
  sideBySide: {
    display: 'flex',
    flexDirection: 'row',
  },
  drawerPaper: {
    width: '20% !important',
    position: 'absolute',
    marginTop: '104px',
    marginLeft: 0,
    zIndex: 1,
    paddingLeft: '18px',
    paddingTop: '20px',
  },
  active: {
    background: '#F3F4FA',
    color: '#4820B8',
  },
  title: {
    textAlign: 'left',
    marginLeft: '10vh',
  },
  mainTitle: {
    padding: theme.spacing(2),
    marginLeft: '3.2vh',
    textAlign: 'left',
    color: '#6610f2',
    fontWeight: 450,
  },
  flexContainer: {
    display: 'flex',
    background: 'linear-gradient(to top, #FFFFFF 50%, #EBECF0 50%)',
  },
}));

const PersonDrawerComponent = props => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const [showEditPage, setShowEditPage] = useState(!props.match.params || !props.match.params.id ? true : false);
  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const menuItems = [
    {
      text: 'Profile Details',
      icon: <img src="content/icons/Personal Details/Service Areas.png" />,
      activeIcon: <img src="content/icons/General Active.svg" />,
      pageNo: 1,
    },
    {
      text: 'Company Info',
      icon: <img src="content/icons/Personal Details/Company Info.png" />,
      activeIcon: <img src="content/icons/Shift Confirmation Call Active.svg" />,
      pageNo: 10,
    },
    {
      text: 'Certifications & Others',
      icon: <img src="content/icons/Personal Details/Qualifications  Certifications.png" />,
      activeIcon: <img src="content/icons/Welfare Call Active.svg" />,
      pageNo: 3,
    },
    {
      text: 'Service Areas',
      icon: <img src="content/icons/Personal Details/Service Areas.png" />,
      activeIcon: <img src="content/icons/General Active.svg" />,
      pageNo: 2,
    },
    // {
    //   text: 'Background & Education',
    //   icon: <FontAwesomeIcon icon={faBookOpen} color="#4720b7" style={{ width: '24px', height: '20px', marginLeft: '2px' }} />,
    //   pageNo: 4,
    // },
    // {
    //   text: 'Rate Card',
    //   icon: <MonetizationOnIcon style={{ color: '#4720b7' }} />,
    //   pageNo: 5,
    // },
    // {
    //   text: 'Health',
    //   icon: <AddCircleIcon style={{ color: '#4720b7' }} />,
    //   pageNo: 6,
    // },
    {
      text: 'Documents',
      icon: <img src="content/icons/Personal Details/Company Info.png" />,
      activeIcon: <img src="content/icons/Shift Confirmation Call Active.svg" />,
      pageNo: 7,
    },
    {
      text: 'Measurements',
      icon: <img src="content/icons/Personal Details/Qualifications  Certifications.png" />,
      activeIcon: <img src="content/icons/Welfare Call Active.svg" />,
      pageNo: 8,
    },
    {
      text: 'Notification Settings',
      icon: <img src="content/icons/Personal Details/Qualifications  Certifications.png" />,
      activeIcon: <img src="content/icons/Welfare Call Active.svg" />,
      pageNo: 9,
    },
  ];

  const handleClick = () => {
    setOpen(!open);
  };

  const handleDrawerMenu = pageNo => {
    props.setPage(pageNo);
  };

  return (
    <Drawer id="drawer-menu" variant="permanent" anchor="left" classes={{ paper: classes.drawerPaper }}>
      <div>
        <Typography variant="h5" className={classes.title}></Typography>
      </div>
      <List>
        {menuItems
          .filter(item => (isNew ? item.pageNo === 1 || item.pageNo === 10 : true))
          .map(item => (
            <ListItem
              key={item.text}
              button
              onClick={() => {
                if (isNew) {
                  if (props.isProfileFilled) {
                    handleDrawerMenu(item.pageNo);
                  } else {
                    props.setCheckValidation(true);
                  }
                } else {
                  handleDrawerMenu(item.pageNo);
                }
              }}
              className={props.page === item.pageNo ? classes.active : null}
              divider={true}
            >
              <ListItemIcon>{props.page === item.pageNo ? item.activeIcon : item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
      </List>
    </Drawer>
  );
};

export default PersonDrawerComponent;
