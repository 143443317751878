import React from 'react';
import { NavLink as Link } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import { NewNavDropdown } from './menu-components';
import DescriptionIcon from '@material-ui/icons/Description';
import Item from './Item';

export const ReportsMenu = () => {
  return (
    <div className="headerIconScheduler">
      <NewNavDropdown name="Reports" id="schedules-menu" data-cy="schedulesMenu">
        <div className="grid-container-report">
          <span className="grid-containers3"></span>
          <Item
            stylesProperty="clients"
            link="/time-card"
            unFillImage="../../../../content/images/Time Card.png"
            fillImage="../../../../content/images/Time Cardfill.png"
            name="Time Card"
          />
          <Item
            stylesProperty="client-report"
            link="/client-report"
            unFillImage="../../../../content/images/Client Report.png"
            fillImage="../../../../content/images/Client Reportfill.png"
            name="Client Report"
          />
          <Item
            stylesProperty="leave"
            link="/activity-log"
            unFillImage="../../../../content/images/Activity Log.png"
            fillImage="../../../../content/images/Activity Logfill.png"
            name="Activity Log"
          />
          <Item
            stylesProperty="positions"
            link="/patrol-report"
            unFillImage="../../../../content/images/Patrol Reports.png"
            fillImage="../../../../content/images/Patrol Reportsfill.png"
            name="Patrol Reports"
          />
          <Item
            stylesProperty="calllog"
            link="/call-log"
            unFillImage="../../../../content/images/Call Log.png"
            fillImage="../../../../content/images/Call Logfill.png"
            name="Call Log"
          />
          <Item
            stylesProperty="banned"
            link="/unauthorized-people"
            unFillImage="../../../../content/images/Banned.png"
            fillImage="../../../../content/images/Bannedfill.png"
            name="Unauthorised Staff"
          />
        </div>
      </NewNavDropdown>
      <img className="icon" src="../../../../content/images/angle-right.png" alt="dropdown-icon" />
    </div>
  );
};
export default ReportsMenu;
