import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';
import { Storage } from 'react-jhipster';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IActionHistory, defaultValue } from 'app/shared/model/action-history.model';

export const ACTION_TYPES = {
  FETCH_ACTION_HISTORY: 'action-history/FETCH_ACTION_HISTORY',
  RESET: 'action-history/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IActionHistory>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

export type ActionHistoryState = Readonly<typeof initialState>;

export default (state: ActionHistoryState = initialState, action): ActionHistoryState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_ACTION_HISTORY):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };

    case FAILURE(ACTION_TYPES.FETCH_ACTION_HISTORY):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_ACTION_HISTORY):
      return {
        ...state,
        loading: false,
        entities: [...state.entities, ...action.payload.data],
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/activity-log';

export const getReport = (data, pageNo) => ({
  type: ACTION_TYPES.FETCH_ACTION_HISTORY,
  payload: axios.get<IActionHistory>(
    `${apiUrl}?filter[from_date]=${data.from_date}&filter[to_date]=${data.to_date}&filter[assignee]=${data.people}&page=${pageNo}`
  ),
});

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
