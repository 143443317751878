import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import PhoneInput from 'react-phone-input-2';
import country_types from '../../../../../shared/data/country_types.json';

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: '32%',
    margin: 'auto',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '20%',
    left: '35%',
    borderRadius: '10px',
  },
  shiftModalTitle: {
    textAlign: 'left',
    marginTop: '10px',
    marginBottom: '10px',
    paddingLeft: '10px',
  },
}));

interface Props {
  open: boolean;
  counter: number;
  values: any;
  settings: any;
  identifyReference: string;
  setProvideReference: (value: boolean) => void;
  setOpen: (value: boolean) => void;
  setValues: (data: any) => void;
  setCounter: (value: number) => void;
}

export const ReferenceModal: React.FC<Props> = ({
  open,
  counter,
  values,
  setOpen,
  setValues,
  setProvideReference,
  setCounter,
  settings,
  identifyReference,
}) => {
  const [fieldError, setFieldError] = useState({
    reference_name: false,
    reference_telephone: false,
    reference_email: false,
    error_message: 'Required',
  });
  const handleTelephonePhoneNumber = phone => {
    setValues(oldValues => ({ ...oldValues, [`${identifyReference}_reference_telephone_${counter}`]: phone }));
  };

  const classes = useStyles();
  const handleClose = () => setOpen(!open);

  const handleReferenceModal = () => {
    const isAllowed = allowSubmit();
    if (isAllowed) {
      setCounter(counter + 1);
      setProvideReference(false);
      handleClose();
    }
  };

  const checkValidations = e => {
    if (e.target.value === undefined || e.target.value === '') {
      setFieldError(oldValues => ({ ...oldValues, [e.target.id]: true }));
    } else {
      setFieldError(oldValues => ({ ...oldValues, [e.target.id]: false }));
    }
  };

  const allowSubmit = () => {
    const name = values[`${identifyReference}_reference_name_${counter}`];
    const email = values[`${identifyReference}_reference_email_${counter}`];
    const telephone = values[`${identifyReference}_reference_telephone_${counter}`];
    let flag = true;

    if (name === undefined || name === '') {
      setFieldError(oldValues => ({ ...oldValues, ['reference_name']: true }));
      flag = false;
    }
    if (email === undefined || email === '') {
      setFieldError(oldValues => ({ ...oldValues, ['reference_email']: true }));
      flag = false;
    }
    if (telephone === undefined || telephone === '') {
      setFieldError(oldValues => ({ ...oldValues, ['reference_telephone']: true }));
      flag = false;
    }
    return flag;
  };

  const setFormValues = e => {
    checkValidations(e);
    setValues(oldValues => ({ ...oldValues, [e.target.name]: e.target.value }));
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <div className={classes.paper}>
        <div className={classes.shiftModalTitle}>
          <h3 style={{ color: '#4720b7' }}> Reference </h3>
        </div>
        <div className="reference_modal_body_main_div reference-modal-fields">
          <div className="form-group referencefields">
            <div className="row ">
              <div className="col">
                <TextField
                  type="text"
                  variant="outlined"
                  style={{ backgroundColor: '#ebebeb', border: 'unset' }}
                  className="reference_modal_fields_radius fieldsOfReferenceModal"
                  onChange={setFormValues}
                  value={values ? values[`${identifyReference}_reference_name_${counter}`] : ''}
                  name={`${identifyReference}_reference_name_${counter}`}
                  helperText={fieldError['reference_name'] ? fieldError['error_message'] : ''}
                  error={fieldError['reference_name']}
                  id="reference_name"
                  label="Name"
                />
              </div>
            </div>
          </div>
          <div className="form-group referencefields">
            <div className="row ">
              <div className="col">
                <TextField
                  type="text"
                  variant="outlined"
                  onChange={setFormValues}
                  name={`${identifyReference}_reference_email_${counter}`}
                  value={values ? values[`${identifyReference}_reference_email_${counter}`] : ''}
                  helperText={fieldError['reference_email'] ? fieldError['error_message'] : ''}
                  error={fieldError['reference_email']}
                  id="reference_email"
                  label="Email"
                  className="fieldsOfReferenceModal"
                />
              </div>
            </div>
          </div>
          <div className="form-group referencefields">
            <div className="row ">
              <div className="col">
                <TextField
                  type="text"
                  variant="outlined"
                  onChange={setFormValues}
                  name={`${identifyReference}_reference_post_code_${counter}`}
                  value={values ? values[`${identifyReference}_reference_post_code_${counter}`] : ''}
                  id="reference_post_code"
                  label="Post code"
                  className="fieldsOfReferenceModal"
                />
              </div>
            </div>
          </div>
          <div className="form-group referencefields">
            <div className="row ">
              <div className="col">
                <PhoneInput
                  country={settings ? country_types.find(country => country.value === settings)?.phoneName : 'fr'}
                  isValid={value => {
                    if (value === '' || value === undefined) {
                      return false;
                    }
                    return true;
                  }}
                  inputProps={{
                    name: `${identifyReference}_reference_telephone_${counter}`,
                    autoFocus: false,
                  }}
                  value={values ? values[`${identifyReference}_reference_telephone_${counter}`] : ''}
                  onChange={phone => handleTelephonePhoneNumber(phone)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="reference_modal_action_buttons_div">
          <div className="col-6 reference_modal_back_btn_div">
            <Button variant="contained" className="reference_modal_back_btn" onClick={handleClose}>
              Cancel
            </Button>
          </div>
          <div className="col-6  reference_modal_save_btn_div">
            <Button variant="contained" className="reference_modal_save_btn" onClick={handleReferenceModal}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ReferenceModal;
