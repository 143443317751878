import React, { useState } from 'react';
type Theme = any;
type ThemeContext = { theme: Theme; toggleTheme: () => void };

export const ThemeContext = React.createContext<ThemeContext>({} as ThemeContext);

export const ThemeProvider: React.FC = ({ children }) => {
  const localTheme = window.localStorage.getItem('theme');
  const activeTheme = localTheme ? localTheme : 'light';
  const [theme, setTheme] = useState<Theme>(activeTheme);
  const toggleTheme = () => {
    window.localStorage.setItem('theme', theme === 'light' ? 'dark' : 'light');
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  const color = theme === 'light' ? '#333' : '#FFF';
  const backgroundColor = theme === 'light' ? '#FFF' : '#333';

  // const x = document.querySelectorAll('li a');

  // for (let i = 0; i < x.length; i++) {
  //   x[i].style.color = theme === 'light' ? '#333' : '#FFF';
  // }

  document.body.style.color = color;
  document.body.style.backgroundColor = backgroundColor;

  return <ThemeContext.Provider value={{ theme, toggleTheme }}>{children}</ThemeContext.Provider>;
};
