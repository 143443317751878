import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, List, ListItem, ListItemText, Collapse } from '@material-ui/core';
import { getDocuments, verifyPersonInfo } from './documents.reducer';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { IVettingDocuments as VettingModel } from 'app/shared/model/vetting-documents.model';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { VettingDocumentsPersonalDetails } from './personalDetailsComponent';
import { VettingDocumentsMedicalDetails } from './medicalDetailsComponent';
import { VettingProfessionalHistory } from './professionalHistoryComponent';
import { VettingUploadedDocuments } from './uploadedDocumentsComponent';
import './vetting-documents.scss';
import PreliminaryScreeningComponent from './preliminaryScreeningHistory';
import LimitedScreeningComponent from './limitedScreeningProcess';
import FullScreeningComponent from './fullScreeningProcess';

export interface IVettingDocuments extends VettingModel, StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const VettingDocuments = (props: IVettingDocuments) => {
  const history = useHistory();
  const [isOpenPersonalInfo, setIsOpenPersonalInfo] = useState(true);
  const [isOpenMedicalDetails, setIsOpenMedicalDetails] = useState(true);
  const [isOpenDocuments, setIsOpenDocuments] = useState(true);
  const [isOpenProfessionalHistory, setIsOpenProfessionalHistory] = useState(true);
  const [isOpenPreliminaryHistory, setIsOpenPreliminaryHistory] = useState(true);
  const [isOpenLimitedHistory, setIsOpenLimitedHistory] = useState(true);
  const [isOpenFullHistory, setIsOpenFullHistory] = useState(true);

  const { state } = props.location;
  const percentage = 50;

  useEffect(() => {
    if (state) {
      props.getDocuments(state);
    } else {
      history.push('/vettingDashboard');
    }
  }, []);

  useEffect(() => {}, [props.personInformation]);

  const getProcessPercentage = status => {
    if (status === 'SUBMITTED') {
      return 25;
    } else if (status === 'LIMITED_PROCESS') {
      return 55;
    } else if (status === 'FULL_PROCESS') {
      return 75;
    } else if (status === 'REJECTED') {
      return 25;
    }
  };

  const changeNamePattern = (first_name, lastName) => {
    return first_name.charAt(0).toUpperCase() + first_name.slice(1) + ' ' + lastName.charAt(0).toUpperCase() + lastName.slice(1);
  };

  const changePattern = value => {
    return value.charAt(0).toUpperCase() + value.slice(1);
  };

  const verifyDocuments = (id, status) => {
    props.verifyPersonInfo(id, status);
  };

  const { personInformation, loading } = props;
  return (
    <div className="vetting-documents-preview-external-div">
      <div className="vetting-documents-header-div">
        <h3>BS 7858 Standards Vetting Process </h3>
      </div>
      <hr style={{ width: '80%', marginTop: 'unset', color: 'grey' }} />
      {!loading && personInformation && Object.keys(personInformation).length > 0 ? (
        <div className="vetting-documents-body-main-div">
          <div className="vetting-documents-name-progress-div">
            <div className="vetting-documents-progress-div">
              <CircularProgressbar
                value={getProcessPercentage(personInformation.person.status)}
                text={`${getProcessPercentage(personInformation.person.status)}%`}
                strokeWidth={15}
                styles={buildStyles({
                  strokeLinecap: 'butt',
                  textSize: '24px',
                  pathTransitionDuration: 0.5,
                  pathColor: `#0e86b5`,
                  textColor: '#0e86b5',
                  trailColor: '#d6d6d6',
                  backgroundColor: '#3e98c7',
                })}
              />
            </div>
            <div className="vetting-documents-name-div">
              <h6 style={{ alignSelf: 'center', marginBottom: 'unset', fontFamily: 'roboto' }}>
                {`${changeNamePattern(personInformation.person.first_name, personInformation.person.last_name)} - Screening File`}
              </h6>
            </div>
          </div>
          <div className="vetting-documents-person-information-outer-div">
            <div className="vetting-documents-personal-info-div">
              <div className="vetting-documents-product-logo-div">
                <img src="../../../content/images/kswf_documents_vetting_logo.png" className="vetting-documents-logo" />
              </div>
              <div className="vetting-documents-personal-info-name-div">
                <h4> {changeNamePattern(personInformation.person.first_name, personInformation.person.last_name)} </h4>
              </div>
              <div className="vetting-documents-job-info-name-div">
                Applied for: {changePattern(personInformation.position.applied_position)} <br />
                Availability: {changePattern(personInformation.position.job_availability)} <br />
                Start Date: {personInformation.position.expected_date_start}
              </div>
            </div>
            <List component="nav" aria-labelledby="nested-list-subheader">
              <ListItem
                button
                onClick={() => {
                  setIsOpenPersonalInfo(!isOpenPersonalInfo);
                }}
                className="vetting-documents-rows-headings vetting-documents-personal-information-heading-div"
              >
                <ListItemText primary="Personal Information" />
              </ListItem>
              <Collapse in={isOpenPersonalInfo} timeout="auto" unmountOnExit>
                <VettingDocumentsPersonalDetails item={personInformation.person} />
              </Collapse>
              <ListItem
                button
                onClick={() => {
                  setIsOpenProfessionalHistory(!isOpenProfessionalHistory);
                }}
                className="vetting-documents-rows-headings vetting-documents-professional-history-heading-div"
              >
                <ListItemText primary="Professional History" />
              </ListItem>
              <Collapse in={isOpenProfessionalHistory} timeout="auto" unmountOnExit>
                {personInformation.professional_history && Object.keys(personInformation.professional_history).length > 1 ? (
                  <VettingProfessionalHistory item={personInformation.professional_history} />
                ) : (
                  ''
                )}
              </Collapse>
              <ListItem
                button
                onClick={() => {
                  setIsOpenMedicalDetails(!isOpenMedicalDetails);
                }}
                className="vetting-documents-rows-headings vetting-documents-medical-details-heading-div"
              >
                <ListItemText primary="Medical Details" />
              </ListItem>
              <Collapse in={isOpenMedicalDetails} timeout="auto" unmountOnExit>
                {personInformation.medical_details && Object.keys(personInformation.medical_details).length > 1 ? (
                  <VettingDocumentsMedicalDetails item={personInformation.medical_details} />
                ) : (
                  ''
                )}
              </Collapse>
              <ListItem
                button
                onClick={() => {
                  setIsOpenDocuments(!isOpenDocuments);
                }}
                className="vetting-documents-rows-headings vetting-documents-required-heading-div"
              >
                <ListItemText primary="Documents Required" />
              </ListItem>
              <Collapse in={isOpenDocuments} timeout="auto" unmountOnExit>
                {personInformation.documents && Object.keys(personInformation.documents).length > 1 ? (
                  <VettingUploadedDocuments item={personInformation.documents} />
                ) : (
                  ''
                )}
              </Collapse>
            </List>
          </div>
          {personInformation.person.status !== 'SUBMITTED' && (
            <div>
              <div className="vetting-documents-name-progress-div" style={{ marginTop: '50px' }}>
                <div className="vetting-documents-history-text-div">
                  <h5 style={{ alignSelf: 'center', marginBottom: 'unset', fontFamily: 'roboto' }}>{`Screening Process History`}</h5>
                </div>
              </div>
              <div className="vetting-documents-person-information-outer-div">
                <List component="nav" aria-labelledby="nested-list-subheader">
                  {personInformation.person.verified_by && (
                    <>
                      <ListItem
                        button
                        onClick={() => {
                          setIsOpenPreliminaryHistory(!isOpenPreliminaryHistory);
                        }}
                        className="vetting-documents-rows-headings vetting-documents-personal-information-heading-div"
                      >
                        <ListItemText primary="Preliminary Screening Process" />
                      </ListItem>
                      <Collapse in={isOpenPreliminaryHistory} timeout="auto" unmountOnExit>
                        <PreliminaryScreeningComponent person={personInformation.person} />
                      </Collapse>
                    </>
                  )}
                  {personInformation.person.emp_verification_sent ? (
                    <>
                      <ListItem
                        button
                        onClick={() => {
                          setIsOpenLimitedHistory(!isOpenLimitedHistory);
                        }}
                        className="vetting-documents-rows-headings vetting-documents-personal-information-heading-div"
                      >
                        <ListItemText primary="Limited Screening Process" />
                      </ListItem>
                      <Collapse in={isOpenLimitedHistory} timeout="auto" unmountOnExit>
                        <LimitedScreeningComponent person={personInformation.person} completeLimitedProcess={verifyDocuments} />
                      </Collapse>
                    </>
                  ) : (
                    ''
                  )}
                  {personInformation.person.status === 'FULL_PROCESS' ? (
                    <>
                      <ListItem
                        button
                        onClick={() => {
                          setIsOpenFullHistory(!isOpenFullHistory);
                        }}
                        className="vetting-documents-rows-headings vetting-documents-personal-information-heading-div"
                      >
                        <ListItemText primary="Full Screening Process" />
                      </ListItem>
                      <Collapse in={isOpenFullHistory} timeout="auto" unmountOnExit>
                        <FullScreeningComponent person={personInformation.person} />
                      </Collapse>
                    </>
                  ) : (
                    ''
                  )}
                  {personInformation.person.status === 'REJECTED' && (
                    <div style={{ marginTop: '10px', marginLeft: '50px', width: '95%', color: 'red' }}>
                      <b> NOTE: Rejected due to invalid information </b>
                    </div>
                  )}
                </List>
              </div>
            </div>
          )}
          <div className="vetting-documents-action-buttons-div">
            {personInformation.person.status === 'SUBMITTED' && (
              <>
                <Button
                  className="vetting-documents-button vetting-documents-invalid-info-button"
                  onClick={() => {
                    verifyDocuments(personInformation.person.id, 'REJECTED');
                  }}
                >
                  Invalid Information
                </Button>
                <Button
                  className="vetting-documents-button vetting-documents-verified-info-button"
                  onClick={() => {
                    verifyDocuments(personInformation.person.id, 'LIMITED_PROCESS');
                  }}
                >
                  Information Verified
                </Button>
              </>
            )}
            {personInformation.person.status === 'FULL_PROCESS' && personInformation.person.credit_check_sent === false && (
              <Button
                className="vetting-documents-button vetting-documents-perform-checks-button"
                onClick={() => {
                  verifyDocuments(personInformation.person.id, 'CREDIT_CHECKS');
                }}
              >
                Perform Checks
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div className="lds-roller"></div>
      )}
    </div>
  );
};

const mapStateToProps = ({ VettingDocuments }: IRootState) => ({
  personInformation: VettingDocuments.entities,
  loading: VettingDocuments.loading,
});

const mapDispatchToProps = {
  getDocuments,
  verifyPersonInfo,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(VettingDocuments);
