import React from 'react';
import { FormControl, FormControlLabel, Checkbox } from '@material-ui/core';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';

const PictureTypeCheckboxComponenet = ({ label, picType, currentPicType, handleChange }) => {
  const isChecked = currentPicType === picType;

  return (
    <FormControl style={{ minWidth: 150 }} className="pictures-type-buttons" variant="outlined" size="small">
      <FormControlLabel
        id={isChecked ? 'checked' : 'unchecked'}
        className="pictures-checkbox-buttons"
        label={label}
        labelPlacement="start"
        control={
          <Checkbox
            icon={<CircleCheckedFilled />}
            checkedIcon={<CircleCheckedFilled />}
            onChange={handleChange}
            checked={isChecked}
          />
        }
        style={{
          color: isChecked ? 'white' : 'black',
          background: isChecked ? 'linear-gradient(to bottom, #efb5ee, #d488d3)' : 'linear-gradient(to bottom, #FCFCFC, #EFEFEF)',
          border: isChecked ? '1px solid #E5A5E4' : '1px solid #E9E9E9',
        }}
      />
    </FormControl>
  );
};

export default PictureTypeCheckboxComponenet;
