/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import { IServiceArea } from 'app/shared/model/service-area.model';
import { Button, Grid, Paper, Table, IconButton, Switch } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import { Divider } from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';
import ServiceAreaShiftExecutionTable from './component/shift-exectuion-table';
import PinLocationMap from './pinLocationMap';
import PictureCheckbox from './component/PictureTypeCheckboxComponent';
import FeatureCheckbox from './component/featureCheckboxComponent';
import './servicearea.scss';

interface ServiceAreaShiftExecutionProps {
  clientsList?: Array<any> | any;
  client?: { id: number; text: string } | any;
  fieldError?: Record<string, any>;
  values?: IServiceArea | any;
  serviceAreaEntity?: IServiceArea | any;
  setOpenMap?: any;
  openMap?: boolean;
  setCoordinates?: (data: any) => void;
  coordinates?: any;
  handleReminderCheckboxes?: (index: any, data: any, event: any) => void;
  setValues?: (data: any) => void;
  isNew: boolean;
}

const ServiceAreaShiftExecution: React.FC<ServiceAreaShiftExecutionProps> = props => {
  const [leftPanelNames] = React.useState(['Shift Confirmation', 'Sign In', 'Sign Out', 'Welfare Check', 'Patrol Start', 'Patrol End']);
  const [picRequired, setPicRequired] = React.useState(false);
  const [mobileCheck, setMobileCheck] = React.useState(false);
  const [isPictureTypeSelected, setIsPictureTypeSelected] = useState(false);
  const [settings, setServiceAreaSettings] = React.useState<any | null>({});
  const [picSection, setPicSection] = React.useState(null);
  const [center, setCenter] = useState({ lat: 51.509865, lng: -0.118092 });
  const options = useState({
    options: {
      strokeColor: '#ff0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.35,
    },
  });
  const [places, setPlaces] = useState({
    latitude: null,
    longitude: null,
    circle: {
      radius: null,
    },
  });

  useEffect(() => {
    if (Object.keys(settings).length !== 0) {
      props.setValues(prevState => ({ ...prevState, serviceAreaSettings: { ...prevState.serviceAreaSettings, ['settings']: settings } }));
    }
  }, [settings]);

  useEffect(() => {
    if (props.coordinates.length > 0) {
      if (props.openMap === false) {
        props.setOpenMap(true);
      }
    }
  }, [props.coordinates]);

  useEffect(() => {
    if (!props.isNew) {
        if (props.values?.serviceAreaSettings?.pic_type != null) {
          setPicRequired(true);
          setIsPictureTypeSelected(true);
        } else {
          setPicRequired(false);
          setIsPictureTypeSelected(false);
        }
    }
    setCenter({
      lat: props.coordinates && props.coordinates[0] ? props.coordinates[0].lat : 51.509865,
      lng: props.coordinates && props.coordinates[0] ? props.coordinates[0].lng : -0.118092,
    });

    if (props.coordinates.length > 0) {
      setPlaces({
        latitude: props.coordinates[0].lat,
        longitude: props.coordinates[0].lng,
        circle: {
          radius:
            props.values && props.values['serviceAreaSettings'] && props.values['serviceAreaSettings']['allowed_radius']
              ? props.values['serviceAreaSettings']['allowed_radius']
              : 0,
        },
      });
    }
  }, [props.values]);

  useEffect(() => {
    if (!props.isNew) {
      if (props.values && props.values['serviceAreaSettings'] && props.values['serviceAreaSettings']['settings']) {
        setServiceAreaSettings(props.values['serviceAreaSettings']['settings']);
      }

      if (props.values && props.values['serviceAreaSettings'] && props.values['serviceAreaSettings']['location'] !== null) {
        const splitCoordinates =
          props.values['serviceAreaSettings'] &&
          props.values['serviceAreaSettings']['location'] &&
          props.values['serviceAreaSettings']['location'].split(',');

        const splitLat = splitCoordinates && splitCoordinates[0]?.split('(');
        const splitLng = splitCoordinates && splitCoordinates[1]?.split(')');

        props.setCoordinates([
          ...props.coordinates,
          {
            lat: splitLat ? parseFloat(splitLat[1]) : '',
            lng: splitLng ? parseFloat(splitLng[0]) : '',
          },
        ]);
      }
    }
  }, [props.serviceAreaEntity]);

  useEffect(() => {
    if (props.values && props.values['serviceAreaSettings'] && props.values['serviceAreaSettings']['settings']) {
      const settings = props.values['serviceAreaSettings']['settings'];
      const mobileAppFeatures = [
        'Patrolling',
        'Shift Confirmation',
        'Sign In',
        'Sign Out',
        'Welfare Check',
        'Patrol Start',
        'Patrol End'
      ];
      
      const isMobileAppEnabled = mobileAppFeatures.some(feature => settings[feature]?.['Mobile App'] === true);
  
      if (isMobileAppEnabled) {
        setMobileCheck(true);
      } else {
        setMobileCheck(false);
        props.setOpenMap(false);
        deleteMarks();
      }
    }
  }, [props.values]);

  useEffect(() => {
    const newList = [];

    if (props.values && props.values['serviceAreaSettings'] && props.values['serviceAreaSettings']['settings']) {
      const items = props.values['serviceAreaSettings']['settings'];

      Object.values(items).filter(fields => {
        if (fields['Mobile App'] === true || fields['Outbound Automated Call'] === true || fields['Inbound Call'] === true) {
          newList.push(fields);
        }

        if (newList && newList.length > 0) {
          setPicSection(true);
        } else {
          setPicSection(false);
        }
      });
    }
  }, [props.values]);

  const handleSiteButtons = () => {
    setIsPictureTypeSelected(true);
    props.setValues(oldValues => ({ ...oldValues, serviceAreaSettings: { ...oldValues.serviceAreaSettings, pic_type: 'site' } }));
  };

  const handleSelfieButtons = () => {
    setIsPictureTypeSelected(true);
    props.setValues(oldValues => ({ ...oldValues, serviceAreaSettings: { ...oldValues.serviceAreaSettings, pic_type: 'selfie' } }));
  };

  const handlePictureSwitch = event => {
    if (!event.target.checked) { // if the On-Site pictures is turned off, remove the pic_type from the serviceAreaSettings
      props.setValues(oldValues => { 
        const newServiceAreaSettings = { ...oldValues.serviceAreaSettings };
        delete newServiceAreaSettings.pic_type;
        return { ...oldValues, serviceAreaSettings: newServiceAreaSettings };
      }
    );
      handleFeatureCheckButtons("Sign In", event);
      handleFeatureCheckButtons("Sign Out", event);
      handleFeatureCheckButtons("Patrol Start", event);
      handleFeatureCheckButtons("Patrol End", event);
      handleFeatureCheckButtons("Welfare Check", event);
    }
    setPicRequired(event.target.checked);
  };

  const handleMandatorySwitch = event => {
    props.setValues(oldValues => ({
      ...oldValues,
      serviceAreaSettings: { ...oldValues.serviceAreaSettings, mandatory: event.target.checked },
    }));
  };

  const handleReminderCheckboxes = (index, data, event) => {
    setServiceAreaSettings(prevState => ({ ...prevState, [data]: { ...prevState[data], [index]: event.target.checked } }));
  };

  const handlePlayersChange = (event, value) => {
    props.setValues(oldValues => ({ ...oldValues, serviceAreaSettings: { ...oldValues.serviceAreaSettings, allowed_radius: value } }));
  };

  const handleFeatureCheckButtons = (data, event) => {
    setServiceAreaSettings(prevState => ({ ...prevState, [data]: { ...prevState[data], ['attachments']: event.target.checked } }));
  };

  const setMark = coord => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();

    props.setCoordinates([
      {
        lat,
        lng,
      },
    ]);

    props.setValues(oldValues => ({
      ...oldValues,
      serviceAreaSettings: { ...oldValues.serviceAreaSettings, location: `(${lat},${lng})` },
    }));
  };

  const handlePinButton = () => {
    props.setOpenMap(true);
  };
  const handleMapModalClose = () => {
    props.setOpenMap(false);
  };

  const handleLocationDelete = () => {
    props.setCoordinates([]);
    props.setValues(oldValues => ({ ...oldValues, serviceAreaSettings: { ...oldValues.serviceAreaSettings, location: '' } }));
  };

  const deleteMarks = () => {
    props.setCoordinates([]);
    props.setValues(oldValues => ({ ...oldValues, serviceAreaSettings: { ...oldValues.serviceAreaSettings, location: '' } }));
    setPlaces({
      latitude: '',
      longitude: '',
      circle: {
        radius: '',
      },
    });
  };
  const currentPicType = props.values?.serviceAreaSettings?.pic_type;
  const getCheckedCondition = (label) =>
    props.values?.serviceAreaSettings?.settings?.[label]?.attachments === true;
    
  return (
    <div className="shift-execution-main-container">
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className="shift-execution-heading">Select Method(s) for sending/recieving reminders</div>

        {mobileCheck === true ? (
          <div className="shift-execution-mobile-app-switch">
            <div className="shift-settings-mobile-app-inner-div">
              <span>Mandatory</span>
              <Switch
                onChange={handleMandatorySwitch}
                color="primary"
                name="outbound_reminders"
                inputProps={{ 'aria-label': 'primary checkbox' }}
                className="mobile-app-mandatory-switch"
                checked={
                  props.values &&
                  props.values['serviceAreaSettings'] &&
                  props.values['serviceAreaSettings']['mandatory'] &&
                  props.values['serviceAreaSettings']['mandatory']
                }
              />
            </div>
          </div>
        ) : null}
      </div>
      <div className="shift-execution-table">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <thead>
              <tr className="shift-execution-table-header-row">
                <th style={{ width: '150px', justifyContent: 'unset', background: '#f3f5f7' }}>
                  {' '}
                  <Button></Button>
                </th>
                <th style={{ marginLeft: '20px' }}>
                  {' '}
                  <Button className="shift-execution-table-button">In-bound Call </Button>
                </th>
                <th>
                  <Button className="shift-execution-table-button">Out-bound Call</Button>
                </th>
                <th>
                  <Button style={{ marginLeft: '10px' }} className="shift-execution-table-button">
                    Mobile App
                  </Button>
                </th>
              </tr>
            </thead>
            <tbody style={{ backgroundColor: '#f3f5f7' }}>
              {leftPanelNames && leftPanelNames.length > 0
                ? leftPanelNames.map((name, i) => (
                    <ServiceAreaShiftExecutionTable
                      key={i}
                      leftPanelName={name}
                      name={name}
                      values={props.values}
                      handleReminderCheckboxes={handleReminderCheckboxes}
                      settings={settings}
                    />
                  ))
                : null}
            </tbody>
          </Table>
        </TableContainer>
      </div>

      {mobileCheck === true ? (
        <div className="location-div">
          Add your{' '}
          <Button className="service-area-location-button" onClick={handlePinButton}>
            {' '}
            Pin Location
          </Button>{' '}
          to setup a radius (Sign In / Sign Out ) Permission
        </div>
      ) : null}

      {props.openMap === true ? (
        <PinLocationMap
          center={center}
          setValues={props.setValues}
          setCenter={setCenter}
          places={places}
          options={options}
          setCoordinates={props.setCoordinates}
          setMark={setMark}
          coordinates={props.coordinates}
          deleteMarks={deleteMarks}
        />
      ) : null}

      {mobileCheck === true ? (
        <div className="service-area-location-div">
          <div className="location-coordinates-div">
            {props.coordinates && props.coordinates.length !== 0 ? (
              <>
                <div style={{ marginLeft: '5%' }}> Latitude : </div>
                <div style={{ marginLeft: '1%' }}>{props.coordinates && props.coordinates[0] ? props.coordinates[0].lat : '-'}</div>

                <div style={{ marginLeft: '10%' }}> Longitude : </div>
                <div style={{ marginLeft: '1%' }}>{props.coordinates && props.coordinates[0] ? props.coordinates[0].lng : '-'}</div>
              </>
            ) : (
              <div style={{ fontStyle: 'italic' }}> No location selected </div>
            )}
          </div>

          <IconButton
            className="service_area_checkpoint_delete_from_list_icon"
            onClick={() => {
              handleLocationDelete();
            }}
            style={{ marginTop: '8px' }}
          >
            <img style={{ width: '100%', height: '10%' }} src="content/images/Trash4.png" />
          </IconButton>
        </div>
      ) : null}

      {mobileCheck === true ? (
        <div className="set-radius-div">
          <div className="set-radius-text-div">Set Radius (In Meters)</div>
          <style>
            {`
          .MuiSlider-valueLabel {
            font-size: 10px;
          }
        `}
          </style>
          <Slider
            defaultValue={
              props.values && props.values['serviceAreaSettings'] && props.values['serviceAreaSettings']['allowed_radius']
                ? props.values['serviceAreaSettings']['allowed_radius']
                : 0
            }
            aria-labelledby="discrete-slider-always"
            max={300}
            step={5}
            valueLabelFormat={value => `${value}M`}
            valueLabelDisplay="on"
            onChange={handlePlayersChange}
          />
        </div>
      ) : null}

      <Divider className="shift-execution-settings-divider"></Divider>

      {picSection === true ? (
        <div className="shift-execution-pic-settings-div">
          <div className="shift-execution-pics-outer-div">
            <div className="shift-settings-pics-button-div">
              <div className="switch-div-text">
                <span className="pictures-switch-heading" style={{ color: '#7A55DE', fontSize: '22px' }}>
                  On-Site Pictures{' '}
                </span>
              </div>

              <div className="switch-div-icon">
                <Switch
                  onChange={handlePictureSwitch}
                  color="primary"
                  name="outbound_reminders"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  checked={picRequired}
                />
              </div>
            </div>

            {picRequired === true ? (
              <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, auto)', gap: '20px', alignItems: 'center' }}>
                <PictureCheckbox
                    label="Selfie"
                    picType="selfie"
                    currentPicType={currentPicType}
                    handleChange={handleSelfieButtons}
                  />
                <PictureCheckbox
                  label="Site Pics"
                  picType="site"
                  currentPicType={currentPicType}
                  handleChange={handleSiteButtons}
                />
              </div>
            ) : null}
          </div>

          {picRequired === true ? (
            <div className={`picture-event-type-div ${!isPictureTypeSelected ? 'disabled' : ''}`} >
              <span>This feature will be applicable on your selection in the following: </span>
              <Grid container spacing={2} style={{ marginTop: '30px' }}>
                <FeatureCheckbox
                  label="Sign In"
                  checkedCondition={getCheckedCondition('Sign In')}
                  handleFeatureCheckButtons={handleFeatureCheckButtons}
                />
                <FeatureCheckbox
                  label="Sign Out"
                  checkedCondition={getCheckedCondition('Sign Out')}
                  handleFeatureCheckButtons={handleFeatureCheckButtons}
                />               
                <FeatureCheckbox
                  label="Patrol Start"
                  checkedCondition={getCheckedCondition('Patrol Start')}
                  handleFeatureCheckButtons={handleFeatureCheckButtons}
                />
                <FeatureCheckbox
                  label="Patrol End"
                  checkedCondition={getCheckedCondition('Patrol End')}
                  handleFeatureCheckButtons={handleFeatureCheckButtons}
                />
                <FeatureCheckbox
                  label="Welfare Check"
                  checkedCondition={getCheckedCondition('Welfare Check')}
                  handleFeatureCheckButtons={handleFeatureCheckButtons}
                />
              </Grid>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
export default ServiceAreaShiftExecution;
