import React, { useState, useEffect, Fragment } from 'react';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Grid, Divider, InputAdornment, Icon } from '@material-ui/core';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { getDateFormat } from 'app/shared/util/calender-utils';
import CustomToolbarComponent from '../../../../../entities/service-area/CustomToolbarComponent';
import country_types from '../../../../../shared/data/country_types.json';
import PhoneInput from 'react-phone-input-2';

export const EmploymentHistorySections = props => {
  const { index, setFormValues, setHistory, fieldError, values, dateSetting, setValues, settings, setPhoneValues } = props;

  return (
    <div className="EmploymentHistoryFormDiv">
      {index !== 0}
      <Grid container spacing={3}>
        <Grid item xs={6} className="professional-history-custom-field professional-history-fields">
          <TextField
            type="text"
            variant="outlined"
            className="form-control"
            onChange={setFormValues}
            name={`company_name_${index}`}
            value={values[`company_name_${index}`] ? values[`company_name_${index}`] : ''}
            id="company_name"
            label="Company Name"
            style={{width : '100%'}}
          />
        </Grid>
        <Grid item xs={6} className="professional-history-custom-field professional-history-fields">
          <TextField
            type="text"
            variant="outlined"
            className="form-control"
            onChange={setFormValues}
            name={`job_title_${index}`}
            value={values[`job_title_${index}`] ? values[`job_title_${index}`] : ''}
            id="job_title"
            label="Job Title"
            style={{width : '100%'}}
          />
        </Grid>
        <Grid item xs={6} className="professional-history-fields">
          <TextField
            type="text"
            variant="outlined"
            className="form-control"
            onChange={setFormValues}
            name={`company_location_${index}`}
            value={values[`company_location_${index}`] ? values[`company_location_${index}`] : ''}
            id="company_location"
            label="Company Address"
            style={{width : '100%'}}
          />
        </Grid>
        <Grid item xs={6} className="professional-history-fields">
          <TextField
            type="text"
            variant="outlined"
            className="form-control"
            onChange={setFormValues}
            name={`emp_post_code_${index}`}
            value={values[`emp_post_code_${index}`] ? values[`emp_post_code_${index}`] : ''}
            id="post_code"
            label="Post Code"
            style={{width : '100%'}}
          />
        </Grid>
        <Grid item xs={6} className="professional-history-fields emp_start_date_field">
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <KeyboardDatePicker
              autoOk
              variant="inline"
              inputVariant="outlined"
              name={`emp_start_date_${index}`}
              className="professional-history-date_picker"
              defaultValue="From"
              id="emp_start_date"
              style={{
                width: '100%',
              }}
              label="From"
              format={dateSetting ? getDateFormat(dateSetting) : 'MM/DD/YYYY'}
              value={values[`emp_start_date_${index}`] && values[`emp_start_date_${index}`]}
              ToolbarComponent={prop => (
                <>
                  <CustomToolbarComponent {...prop} settingFormat={dateSetting} />
                </>
              )}
              placeholder={dateSetting ? getDateFormat(dateSetting) : 'MM/DD/YYYY'}
              keyboardIcon={<img src="content/images/icons8-calendar-22.png" />}
              InputAdornmentProps={{ position: 'end' }}
              onChange={date => {
                setHistory(date, 'start_date', 'emp_start_date', `emp_start_date_${index}`);
              }}
              helperText={fieldError[`emp_start_date_${index}`] ? fieldError[`emp_start_date_${index}_helper`] : ''}
              error={fieldError[`emp_start_date_${index}`]}
            />{' '}
          </MuiPickersUtilsProvider>
        </Grid>
        {/* <div style={{ alignSelf: 'center', marginTop: '25px' }}></div> */}
        <Grid item xs={6} className="professional-history-fields emp_end_date_field">
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <KeyboardDatePicker
              autoOk
              variant="inline"
              className="professional-history-emp-end-date professional-history-date_picker"
              inputVariant="outlined"
              name={`emp_end_date_${index}`}
              defaultValue="From"
              id="emp_end_date"
              style={{
                width: '100%',
              }}
              label="Till"
              format={dateSetting ? getDateFormat(dateSetting) : 'MM/DD/YYYY'}
              value={values[`emp_end_date_${index}`] && values[`emp_end_date_${index}`]}
              ToolbarComponent={prop => (
                <>
                  <CustomToolbarComponent {...prop} settingFormat={dateSetting} />
                </>
              )}
              placeholder={dateSetting ? getDateFormat(dateSetting) : 'MM/DD/YYYY'}
              keyboardIcon={<img src="content/images/icons8-calendar-22.png" />}
              InputAdornmentProps={{ position: 'end' }}
              onChange={date => {
                setHistory(date, 'end_date', 'emp_end_date', `emp_end_date_${index}`);
              }}
              helperText={fieldError[`emp_end_date_${index}`] ? fieldError[`emp_end_date_${index}_helper`] : ''}
              error={fieldError[`emp_end_date_${index}`]}
            />{' '}
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} className="professional-history-fields">
          <InputLabel htmlFor="professional-history-leaving-reason-text-area"> Reason(s) for Leaving </InputLabel>
          <TextareaAutosize
            aria-label="empty textarea"
            className="form-control professional-history-leaving-reason-text-area"
            name={`emp_leaving_reason_${index}`}
            value={values[`emp_leaving_reason_${index}`] ? values[`emp_leaving_reason_${index}`] : ''}
            id="emp_leaving_reason"
            onChange={setFormValues}
          />
        </Grid>
        <Grid item xs={12} className="professional-history-fields" style={{ paddingBottom: 'unset' }}>
          <InputLabel htmlFor="emp_contact_name" style={{ color: 'black', marginBottom: '0px' }}>
            {' '}
            Contact Person Details{' '}
          </InputLabel>
        </Grid>
        <Grid item xs={6} className="professional-history-fields emp_contact_name_field">
          <TextField
            variant="outlined"
            onChange={setFormValues}
            type="text"
            className="form-control"
            name={`emp_contact_name_${index}`}
            value={values[`emp_contact_name_${index}`] ? values[`emp_contact_name_${index}`] : ''}
            id="emp_contact_name"
            label="Name"
            style={{width : '100%'}}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Icon>
                    <img src="content/images/icons8-user-22.png" />
                  </Icon>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6} className="professional-history-fields emp_contact_email_field">
          <TextField
            type="text"
            variant="outlined"
            className="form-control"
            onChange={setFormValues}
            name={`emp_contact_email_${index}`}
            value={values[`emp_contact_email_${index}`] ? values[`emp_contact_email_${index}`] : ''}
            id="emp_contact_email"
            label="Email"
            style={{width : '100%'}}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Icon>
                    <img src="content/images/icons8-email-22.png" />
                  </Icon>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6} className="about-you-fields">
          <InputLabel htmlFor="mobile_no"> Mobile no. </InputLabel>
          <PhoneInput
            country={
              props.settings.country_of_operations
                ? country_types.find(country => country.value === props.settings.country_of_operations).phoneName
                : 'gb'
            }
            isValid={value => {
              if (value === '' || value === undefined) {
                return false;
              }
              return true;
            }}
            inputProps={{
              name: `emp_contact_telephone_${index}`,
              autoFocus: false,
            }}
            onChange={event => setPhoneValues(event, index)}
            value={values[`emp_contact_telephone_${index}`] ? values[`emp_contact_telephone_${index}`] : ''}
          />
        </Grid>
      </Grid>
      <br />
    </div>
  );
};
