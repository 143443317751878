import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { Storage } from 'react-jhipster';
import { IActionHistory, defaultValue } from 'app/shared/model/action-history.model';

export const ACTION_TYPES = {
  FETCH_ACTIONS_LIST: 'actions/FETCH_ACTIONS_LIST',
  RESET: 'actions/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as Array<IActionHistory>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  breaks: [],
};

export type ActionLogsState = Readonly<typeof initialState>;

// Reducer

export default (state: ActionLogsState = initialState, action): ActionLogsState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_ACTIONS_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_ACTIONS_LIST):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_ACTIONS_LIST):
      return {
        ...state,
        loading: false,
        entities: [...state.entities, ...action.payload.data],
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/activity-log';

// Actions

export const getActionsLog = pageNo => ({
  type: ACTION_TYPES.FETCH_ACTIONS_LIST,
  payload: axios.get<IActionHistory>(`${apiUrl}?filter[from_date]=&filter[to_date]=&filter[assignee]=&page=${pageNo}`),
});

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
