/* eslint-disable no-debugger */
import axios from 'axios';
import { toast } from 'react-toastify';
import { store } from '../../index';

export const ACTION_TYPES = {
  GET_PUSH_NOTIFICATION: 'notifications/GET_PUSH_NOTIFICATION',
  GET_NOTIFICATIONS: 'notifications/GET_NOTIFICATIONS',
  RESET_NOTIFICATION: 'notifications/RESET_NOTIFICATIONS',
  GET_BACKGROUND_NOTIFICATION: 'notifications/GET_BACKGROUND_NOTIFICATION',
};

const initialState = {
  notifications: [],
};

export type NotificationsState = Readonly<typeof initialState>;
export default (state: NotificationsState = initialState, action): NotificationsState => {
  switch (action.type) {
    case ACTION_TYPES.GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: [...state.notifications, ...action.payload],
      };
    case ACTION_TYPES.GET_PUSH_NOTIFICATION:
      return {
        ...state,
        notifications: [action.payload, ...state.notifications],
      };
    case ACTION_TYPES.GET_BACKGROUND_NOTIFICATION:
      return {
        ...state,
        notifications: action.payload,
      };
    case ACTION_TYPES.RESET_NOTIFICATION:
      return {
        ...state,
        notifications: [],
      };
    default:
      return state;
  }
};

export const getNotifications = pageNumber => dispatch => {
  axios
    .get(`api/realTimeNotifications?page=${pageNumber}`)
    .then(res => {
      dispatch({
        type: ACTION_TYPES.GET_NOTIFICATIONS,
        payload: res.data,
      });
    })
    .catch(err => {
      toast.error('Notifications Error : ', err);
    });
};

export const getPushNotification = data => {
  store.dispatch({
    type: ACTION_TYPES.GET_PUSH_NOTIFICATION,
    payload: data,
  });
  return;
};

export const markAllAsRead = () => dispatch => {
  axios
    .patch('api/markAllAsRead')
    .then(res => {
      dispatch(resetNotifications());
      dispatch(getNotifications(1));
    })
    .catch(err => {
      toast.error('Notifications Error : ', err);
    });
};

export const getBackGroundPushNotifications = () => {
  axios
    .get(`api/realTimeNotifications?page=${1}`)
    .then(res => {
      store.dispatch({
        type: ACTION_TYPES.GET_BACKGROUND_NOTIFICATION,
        payload: res.data,
      });
    })
    .catch(err => {
      toast.error('Notifications Error : ', err);
    });
};

export const resetNotifications = () => ({
  type: ACTION_TYPES.RESET_NOTIFICATION,
});

export const markSingleNotificationAsRead = id => dispatch => {
  axios
    .patch(`api/markSingleRead/${id}`)
    .then(response => {
      dispatch(resetNotifications());
      dispatch(getNotifications(1));
    })
    .catch(err => {
      toast.error('Notifications Error: ' + err);
    });
};

export const getBackGroundPushNotificationsForUser = () => {
  axios
    .get(`api/realTimeNotificationsForUser?page=${1}`)
    .then(res => {
      store.dispatch({
        type: ACTION_TYPES.GET_BACKGROUND_NOTIFICATION,
        payload: res.data,
      });
    })
    .catch(err => {
      toast.error('Notifications Error : ', err);
    });
};
