import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Table } from '@material-ui/core';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntities, setPageNumber, reset } from './organization.reducer';
import { IOrganization } from 'app/shared/model/organization.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import SearchBar from 'material-ui-search-bar';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import InfiniteScroll from 'react-infinite-scroll-component';
import TableContainer from '@material-ui/core/TableContainer';

export interface IOrganizationProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const Organization = (props: IOrganizationProps) => {
  const [disabled, setDisabled] = useState(0);
  const [searched, setSearched] = useState<string>('');
  const [records, setRecords] = useState(props.organizationList);
  const [isNew] = useState(!props.match.params || !props.match.params.id);

  useEffect(() => {
    props.reset();
    props.setPageNumber({ pageNumber: 1 });
  }, []);

  const handleSyncList = () => {
    props.reset();
    props.setPageNumber({ pageNumber: 1 });
  };

  useEffect(() => {
    setRecords(props.organizationList);
  }, [props.organizationList]);

  const requestSearch = (searchedVal: string) => {
    const value = searchedVal.toLowerCase();
    const filteredRows = props.organizationList.filter(row => {
      return (
        (row.id && row.id.toString().includes(value)) ||
        (row.name && row.name.toLowerCase().includes(value)) ||
        (row.address && row.address.id && row.address.id.toString().toLowerCase().includes(value)) ||
        (row.serviceAreas && row.serviceAreas.toString().includes(value)) ||
        (row.organizationType && row.organizationType.id && row.organizationType.id.toString().includes(value))
      );
    });
    setRecords(filteredRows);
  };

  const cancelSearch = () => {
    setSearched('');
    requestSearch(searched);
  };

  const { organizationList, match, loading } = props;
  return (
    <div className="organization-main-container">
      <div className="organization-outer-container">
        <div className="organization-header-container">
          <div className="organization-main-title-heading">
            <Paper elevation={3}>
              <SearchBar
                value={searched}
                className="departmentSearchBar"
                onChange={searchVal => requestSearch(searchVal)}
                onCancelSearch={() => cancelSearch()}
                placeholder="Search anything here .."
              />
            </Paper>
          </div>
          <div className="organizationCreateButtonDiv">
            <Button className="mr-2" id="organizationRefreshButton" onClick={handleSyncList} disabled={loading}>
              &nbsp;&nbsp;Refresh&nbsp;&nbsp;
            </Button>
            <Link
              to={`${match.url}/new`}
              className="btn btn-primary jh-create-entity organizationCreateButton"
              id="jh-create-entity"
              data-cy="entityCreateButton"
            >
              <FontAwesomeIcon icon="plus" />
              &nbsp; Create New
            </Link>
          </div>
        </div>
        <div className="table-responsive">
          {records && records.length > 0 ? (
            <InfiniteScroll
              dataLength={organizationList.length}
              scrollThreshold="200px"
              next={() => {
                if (organizationList.length > 0) {
                  props.setPageNumber({ pageNumber: props.pageId['pageNumber'] + 1 });
                }
              }}
              hasMore={true}
              loader={''}
            >
              <TableContainer component={Paper}>
                <Table>
                  <thead className="globalTableHeaderGradientColor">
                    <tr className="serviceAreaTableHeaderRow">
                      <th className="serviceAreaTableHeaderCell serviceAreaHeaderIdCell">ID No.</th>
                      <th className="serviceAreaTableHeaderCell serviceAreaHeaderNameCell">Customer Name </th>
                      <th className="serviceAreaTableHeaderCell serviceAreaHeaderClientNameCell">Address</th>
                      <th className="serviceAreaTableHeaderCell serviceAreaHeaderRemoteAllowedCell">Organization Type</th>
                      <th className="serviceAreaTableHeaderCell serviceAreaHeaderActionCell">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {records &&
                      records.map((organization, i) => (
                        <tr key={`entity-${i}`} data-cy="entityTable" className="organizationTableBodyRows">
                          <td className="organizationTableBodyCell organizationTableBodyIDcell">{i + 1}</td>
                          <td className="organizationTableBodyCell organizationTableBodyNamecell">{organization.name}</td>
                          <td className="organizationTableBodyCell organizationTableBodyAddresscell">
                            {organization.address ? organization.address.address_line_one : ''}
                          </td>
                          <td className="organizationTableBodyCell organizationTableBodyOrganizationTypecell">
                            {organization.organizationType ? (
                              <Link to={`organization-type/${organization.organizationType.id}`}>{organization.organizationType.name}</Link>
                            ) : (
                              ''
                            )}
                          </td>
                          <td className="text-right organizationTableBodyCell organizationTableBodyActioncell">
                            <div className="btn-group serviceAreaActionCellButtons">
                              <IconButton
                                component={Link}
                                to={`${match.url}/${organization.id}`}
                                className="organizationButton organizationViewButton"
                                style={{ margin: '1%' }}
                                size="small"
                                data-cy="entityDetailsButton"
                              >
                                <img src="content/images/View1.png" />
                              </IconButton>
                              &nbsp;
                              <>
                                <IconButton
                                  component={Link}
                                  to={`${match.url}/${organization.id}/edit`}
                                  className="organizationButton"
                                  size="small"
                                  style={{ margin: '1%' }}
                                  data-cy="entityEditButton"
                                >
                                  <img src="content/images/Edit1.png" />
                                </IconButton>
                                &nbsp;
                                <IconButton
                                  component={Link}
                                  to={`${match.url}/${organization.id}/delete`}
                                  className="organizationButton"
                                  size="small"
                                  style={{ margin: '1%' }}
                                  data-cy="entityDeleteButton"
                                  disabled={disabled === organization.id ? true : false}
                                  onClick={() => {
                                    setDisabled(organization.id);
                                    setTimeout(() => {
                                      setDisabled(0);
                                    }, 4000);
                                  }}
                                >
                                  <img src="content/images/Trash1.png" />
                                </IconButton>
                              </>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </TableContainer>
            </InfiniteScroll>
          ) : (
            !loading && <div className="no-record-warning">No Customers found</div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ organization }: IRootState) => ({
  organizationList: organization.entities,
  loading: organization.loading,
  pageId: organization.pageNumber,
});

const mapDispatchToProps = {
  getEntities,
  setPageNumber,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Organization);
