import axios from 'axios';
import moment from 'moment';
import { IPerson } from 'app/shared/model/person.model';
import { IServiceArea } from 'app/shared/model/service-area.model';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { ToastContainer, toast } from 'react-toastify';

export const ACTION_TYPES = {
  FETCH_PERSON_LIST: 'person/FETCH_PERSON_LIST',
  FETCH_SHIFT_HISTORY: 'calender/FETCH_SHIFT_HISTORY',
  CREATE_SHIFT: 'calender/CREATE_NEW_SHIFT',
  UPDATE_SHIFT: 'calender/UPDATE_SHIFT',
  DELETE_SHIFT: 'calender/DELETE_SHIFT',
  DELETE_SERIES: 'calendar/DELETE_SERIES',
  GET_ALL_SHIFTS: 'calender/GET_ALL_SHIFTS',
  GET_NO_OF_CHECKPOINTS: 'calender/ GET_NO_OF_CHECKPOINTS',
  GET_SHIFT_EDIT_HISTORY: 'calender/GET_SHIFT_EDIT_HISTORY',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as Array<IPerson>,
  newShift: null,
  updatedShift: null,
  allScheduledShifts: [],
  updating: false,
  updateSuccess: false,
  shiftDeleted: false,
  joyRide: false,
  check_points: null,
  shiftEditHistory: [],
};

export type CalendarState = Readonly<typeof initialState>;

export default (state: CalendarState = initialState, action): CalendarState => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_PERSON_LIST:
      return {
        ...state,
        loading: false,
        entities: action.payload,
      };
    case ACTION_TYPES.FETCH_SHIFT_HISTORY:
      return {
        ...state,
        loading: false,
        shiftEditHistory: action.payload,
      };
    case REQUEST(ACTION_TYPES.GET_NO_OF_CHECKPOINTS):
      return {
        ...state,
        loading: true,
      };
    case SUCCESS(ACTION_TYPES.GET_NO_OF_CHECKPOINTS):
      return {
        ...state,
        loading: false,
        check_points: action.payload.data,
      };
    case FAILURE(ACTION_TYPES.GET_NO_OF_CHECKPOINTS):
      return {
        ...state,
        loading: false,
      };
    case SUCCESS(ACTION_TYPES.CREATE_SHIFT):
      return {
        ...state,
        loading: false,
        joyRide: true,
        newShift: action.payload.data,
      };
    case FAILURE(ACTION_TYPES.CREATE_SHIFT):
      return {
        ...state,
        loading: false,
      };
    case SUCCESS(ACTION_TYPES.UPDATE_SHIFT):
      return {
        ...state,
        updatedShift: action.payload.data,
      };
    case FAILURE(ACTION_TYPES.UPDATE_SHIFT):
      return {
        ...state,
        loading: false,
      };
    case ACTION_TYPES.GET_ALL_SHIFTS:
      return {
        ...state,
        allScheduledShifts: action.payload.data,
      };
    case ACTION_TYPES.DELETE_SHIFT:
      return {
        ...state,
        shiftDeleted: true,
      };
    case ACTION_TYPES.DELETE_SERIES:
      return {
        ...state,
        shiftDeleted: true,
      };
    case ACTION_TYPES.GET_SHIFT_EDIT_HISTORY:
      return {
        ...state,
        shiftEditHistory: action.payload.data,
      };
    case REQUEST(ACTION_TYPES.GET_SHIFT_EDIT_HISTORY):
      return {
        ...state,
        loading: true,
      };
    case SUCCESS(ACTION_TYPES.GET_SHIFT_EDIT_HISTORY):
      return {
        ...state,
        loading: false,
        shiftEditHistory: action.payload.data,
      };
    case FAILURE(ACTION_TYPES.GET_SHIFT_EDIT_HISTORY):
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

const personApiUrl = 'api/calendarPersons';
// Actions

export const getPersonsList = data => dispatch => {
  axios
    .get<IPerson>(
      `${personApiUrl}?filter[from_shifts]=${data.from_date}&filter[to_shifts]=${data.to_date}&filter[live_shifts]=${data.current_day}
                            &cacheBuster=${new Date().getTime()}`
    )
    .then(res => {
      dispatch(getEmployees(res.data));
    })
    .catch(err => {});
};

export const getEmployees = res => {
  return {
    type: ACTION_TYPES.FETCH_PERSON_LIST,
    payload: res,
  };
};

const shiftApiUrl = 'api/shift';

export const storeScheduledShift = (action, shiftData, calendarDisplayWindow) => dispatch => {
  if (action === 'create') {
    axios
      .post(shiftApiUrl, shiftData)
      .then(res => {
        dispatch({
          type: ACTION_TYPES.CREATE_SHIFT,
          payload: res,
        });

        dispatch(
          getScheduledShifts({
            from_date: moment(calendarDisplayWindow.start_date).format('YYYY-MM-DD'),
            to_date: moment(calendarDisplayWindow.end_date).format('YYYY-MM-DD'),
            current_day: '',
          })
        );
      })
      .catch(err => {});
  } else if (action === 'update') {
    axios.put(`${shiftApiUrl}/modify/${shiftData.scheduleId}`, shiftData).then(res => {
      dispatch({
        type: ACTION_TYPES.UPDATE_SHIFT,
        payload: res.data,
      });
      dispatch(
        getScheduledShifts({
          from_date: moment(calendarDisplayWindow.start_date).format('YYYY-MM-DD'),
          to_date: moment(calendarDisplayWindow.end_date).format('YYYY-MM-DD'),
          current_day: '',
        })
      );
    });
  }
};

export const updateShiftOccurence = (shiftData, calendarDisplayWindow) => dispatch => {
  axios
    .put(`${shiftApiUrl}/modifyOccurrence/${shiftData.scheduleId}`, shiftData)
    .then(res => {
      dispatch({
        type: ACTION_TYPES.UPDATE_SHIFT,
        payload: res.data,
      });

      dispatch(
        getScheduledShifts({
          from_date: moment(calendarDisplayWindow.start_date).format('YYYY-MM-DD'),
          to_date: moment(calendarDisplayWindow.end_date).format('YYYY-MM-DD'),
          current_day: '',
        })
      );
    })
    .catch(err => {});
};

export const deleteScheduledShift = (id, url, dateFilter, reason) => dispatch => {
  axios
    .delete(`${url}/${id}`, { data: reason })
    .then(res => {
      dispatch({
        type: ACTION_TYPES.DELETE_SHIFT,
        payload: res.data,
      });
      dispatch(
        getScheduledShifts({
          from_date: moment(dateFilter.start_date).format('YYYY-MM-DD'),
          to_date: moment(dateFilter.end_date).format('YYYY-MM-DD'),
          current_day: '',
        })
      );
    })
    .catch(err => {});
};

const seriesApiUrl = 'api/deleteSeries';

export const deleteSeriesShift = (id, dateFilter, reason) => dispatch => {
  axios
    .delete(`${seriesApiUrl}/${id}`, { data: reason })
    .then(res => {
      dispatch({
        type: ACTION_TYPES.DELETE_SERIES,
        payload: res.data,
      });
      dispatch(
        getScheduledShifts({
          from_date: moment(dateFilter.start_date).format('YYYY-MM-DD'),
          to_date: moment(dateFilter.end_date).format('YYYY-MM-DD'),
          current_day: '',
        })
      );
    })
    .catch(err => {});
};

export const getScheduledShifts = data => dispatch => {
  axios
    .get(
      `${shiftApiUrl}?filter[from_shifts]=${data.from_date}&filter[to_shifts]=${data.to_date}&filter[live_shifts]=${data.current_day}
            &cacheBuster=${new Date().getTime()}`
    )
    .then(res => {
      dispatch({
        type: ACTION_TYPES.GET_ALL_SHIFTS,
        payload: res,
      });
    })
    .catch(err => {});
};

const apiUrlCheckPoints = 'api/getNoOfCheckPoints';
export const getNoOfCheckPoints = id => ({
  type: ACTION_TYPES.GET_NO_OF_CHECKPOINTS,
  payload: axios.get<IServiceArea>(`${apiUrlCheckPoints}/${id}`),
});

const shiftHistoryApiUrl = 'api/getShiftEditHistory';
export const getScheduleEditHistory = id => dispatch => {
  axios
    .get(`${shiftHistoryApiUrl}/${id}`)
    .then(res => {
      dispatch({
        type: ACTION_TYPES.FETCH_SHIFT_HISTORY,
        payload: res.data,
      });
    })
    .catch(err => {});
};

export const shiftDetails = async (id: string): Promise<unknown> => {
  if (id != null) {
    const selectedShift = await axios
      .get(`api/shift/${id}`)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        toast.error('No Shift Exist with given id');
      });
    return selectedShift;
  }
};
