import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { datesGenerator } from 'dates-generator';
import KeyboardArrowRightRounded from '@material-ui/icons/KeyboardArrowRightRounded';
import KeyboardArrowLeftRounded from '@material-ui/icons/KeyboardArrowLeftRounded';

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const MonthText = styled.div`
  font-size: 26px;
  font-weight: bold;
`;

const CustomCalendar = props => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [dates, setDates] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [calendar, setCalendar] = useState({
    month: selectedDate.getMonth(),
    year: selectedDate.getFullYear(),
    nextMonth: '',
    nextYear: '',
    previousMonth: '',
    previousYear: '',
  });

  useEffect(() => {
    const body = {
      month: calendar.month,
      year: calendar.year,
    };
    const { dates, nextMonth, nextYear, previousMonth, previousYear } = datesGenerator(body);
    const active = false;

    for (let i = 0; i < dates.length; i++) {
      for (let j = 0; j < dates[i].length; j++) {
        dates[i][j].active = false;
      }
    }
    setDates([...dates]);
    setCalendar({
      ...calendar,
      nextMonth,
      nextYear,
      previousMonth,
      previousYear,
    });
  }, []);

  const onClickNext = () => {
    const body = { month: calendar.nextMonth, year: calendar.nextYear };
    const { dates, nextMonth, nextYear, previousMonth, previousYear } = datesGenerator(body);

    for (let i = 0; i < dates.length; i++) {
      for (let j = 0; j < dates[i].length; j++) {
        dates[i][j].active = false;
      }
    }

    setDates([...dates]);
    setCalendar({
      ...calendar,
      month: Number(calendar.nextMonth),
      year: Number(calendar.nextYear),
      nextMonth,
      nextYear,
      previousMonth,
      previousYear,
    });
  };

  const onClickPrevious = () => {
    const body = { month: calendar.previousMonth, year: calendar.previousYear };
    const { dates, nextMonth, nextYear, previousMonth, previousYear } = datesGenerator(body);

    for (let i = 0; i < dates.length; i++) {
      for (let j = 0; j < dates[i].length; j++) {
        dates[i][j].active = false;
      }
    }

    setDates([...dates]);
    setCalendar({
      ...calendar,
      month: Number(calendar.previousMonth),
      year: Number(calendar.previousYear),
      nextMonth,
      nextYear,
      previousMonth,
      previousYear,
    });
  };

  const onSelectDate = date => {
    for (let i = 0; i < dates.length; i++) {
      for (let j = 0; j < dates[i].length; j++) {
        if (date.date === dates[i][j].date && date.month === dates[i][j].month && date.year === dates[i][j].year) {
          dates[i][j].active = !dates[i][j].active;
        }
      }
    }
    const selectedArr = objectFindByKeyArr(dates, 'active', true);
    setSelectedValue(selectedArr);
    setDates([...dates]);
    props.selectedMonth(selectedArr);
    setSelectedDate(new Date(date.year, date.month, date.date));
  };

  const objectFindByKeyArr = (array, key, value) => {
    const arr = [];
    for (let i = 0; i < array.length; i++) {
      for (let j = 0; j < array.length; j++) {
        if (array[i][j][key] === value) {
          arr.push(array[i][j].date + ',');
        }
      }
    }
    return arr;
  };

  return (
    <div style={{ width: '100%', paddingLeft: '210px' }}>
      <div style={{ padding: 10 }}></div>
      <div>
        <div>
          <div className="d-flex">
            <div onClick={onClickPrevious} style={{ paddingRight: '10px', marginTop: '8px', cursor: 'pointer' }}>
              <KeyboardArrowLeftRounded />
            </div>
            <MonthText>{months[calendar.month]}</MonthText>
            <div onClick={onClickNext} style={{ paddingLeft: '10px', marginTop: '8px', cursor: 'pointer' }}>
              <KeyboardArrowRightRounded />
            </div>
          </div>

          <table style={{ width: '75%' }}>
            <tbody>
              <tr>
                {days.map(day => (
                  <td key={day} className="padding-table">
                    <div className="month">{day}</div>
                  </td>
                ))}
              </tr>

              {dates.length > 0 &&
                dates.map(week => (
                  <tr key={JSON.stringify(week[0])}>
                    {week.map(each => (
                      <td key={JSON.stringify(each)} className="padding-table">
                        <div
                          onClick={() => onSelectDate(each)}
                          className="calendar-dates"
                          style={{ background: each.active ? '#3bbda9' : '' }}
                        >
                          <span> {each.date}</span>
                        </div>
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CustomCalendar;
