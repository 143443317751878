import axios from 'axios';
import { Storage, ICrudGetAllAction } from 'react-jhipster';
import { IOrganizationQualification } from 'app/shared/model/organization-qualification.model';
import moment from 'moment-timezone';
import { IDesignationQualification } from 'app/shared/model/designation-qualification.model';
import { IDesignationQualificationTypeProps } from 'app/entities/designation-qualification-type/designation-qualification-type';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

export const ACTION_TYPES = {
  STORE_ORGANIZATION_QUALIFICATION: 'organization_qualification/STORE_ORGANIZATION_QUALIFICATION',
  FETCH_ORGANIZATION_QUALIFICATION: 'organization_qualification/GET_ORGANIZATION_QUALIFICATION',
  RESET: 'organization_qualification/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  updateSuccess: false,
  qualification: [] as Array<IOrganizationQualification>,
  storedQualifications: [] as Array<IOrganizationQualification>,
};

export type OrganizationQualificationState = Readonly<typeof initialState>;

export default (state: OrganizationQualificationState = initialState, action): OrganizationQualificationState => {
  switch (action.type) {
    case ACTION_TYPES.STORE_ORGANIZATION_QUALIFICATION:
      return {
        ...state,
        qualification: action.payload,
      };

    case ACTION_TYPES.FETCH_ORGANIZATION_QUALIFICATION:
      return {
        ...state,
        storedQualifications: action.payload.data,
      };

    case REQUEST(ACTION_TYPES.FETCH_ORGANIZATION_QUALIFICATION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };

    case SUCCESS(ACTION_TYPES.FETCH_ORGANIZATION_QUALIFICATION):
      return {
        ...state,
        loading: false,
        updateSuccess: true,
        storedQualifications: action.payload.data,
      };

    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrlQualification = 'api/designation-qualification-types';
export const createOrganizationQualification = name => dispatch => {
  const result = axios
    .post(`${apiUrlQualification}`, name)
    .then(res => {
      dispatch(reset());
      dispatch(getOrganizationQualifications());
    })
    .catch(err => {});
};

export const deleteQualification = id => dispatch => {
  const result = axios
    .delete(`api/designation-qualification-types/${id}`)
    .then(res => {
      dispatch(getOrganizationQualifications());
    })
    .catch(err => {});
};

export const getOrganizationQualifications = () => ({
  type: ACTION_TYPES.FETCH_ORGANIZATION_QUALIFICATION,
  payload: axios.get(`api/designation-qualification-types`),
});

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
