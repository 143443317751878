import { Button, FormControl, Grid, makeStyles, Typography } from '@material-ui/core';
import { IRootState } from 'app/shared/reducers';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import { CurrencyChecker } from '../../../utils/TimeFormatAndTimezone';
import { validationMessageBeforeSubmit, validationMessageOnChange } from '../../../utils/validationMessages';
import { getOrganizationSettings } from '../../modules/Setting/generalSettings.reducer';
import { getOrganizationQualifications } from '../../modules/Setting/OrganizationQualification/Organization-Qualification.reducer';
import { getPersonalSettings } from '../../modules/Setting/Personal-Setting.reducer';
import country_types from '../../shared/data/country_types.json';
import { getEntities as departmentEntities, reset as resetDep } from '../department/department.reducer';
import { getEntities as positionEntities, reset as resetPos } from '../designation/designation.reducer';
import { getCity, getCountry, getOrganizationWithSubContractors, reset as resetCountry } from '../sub-contractor/subcontractor.reducer';
import PersonUpdateBirthInformation from './person-update-birth-information';
import PersonUpdateCompanyInformation from './person-update-company-information';
import PersonUpdateGeneralInformation from './person-update-general-information';
import PersonUpdateOrganizationQualification from './person-update-organization-qualification';
import PersonUpdatePayrateInformation from './person-update-payrate-information';
import PersonUpdateVisaInformation from './person-update-visa-information';
import { createEntity, getEntity, getAllCountry, getRoles, reset, setPageNumber, updateEntity, verifyEmail } from './person.reducer';
import './person.scss';
import { values } from 'lodash';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  listbox: {
    border: '1px solid #dfdbdb !important',
  },
}));

export interface IPersonUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const PersonUpdate = props => {
  const classes = useStyles();
  const [company, setCompanyValue] = React.useState<any[]>([]);
  const [rolesList, setRolesList] = useState([]);
  const [isNew] = useState(!props.match.params || !props.match.params.id);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const history = useHistory();
  const { personEntity, loading, match, subcontractors } = props;
  const [fieldValues, setFieldValues] = useState(personEntity);
  const [dateFormat, setdateFormat] = useState(props.setting.date_format);
  const [pay_rate_effective_date, setPayRateEnd] = useState(null);
  const [pay_rate_start_date, setPayRateStart] = useState(null);
  const [expiry_date, setExpiry_date] = useState(null);
  const [visaRequiredDate, setVisaRequiredDate] = useState(null);
  const [date_of_birth, setDateOfBirth] = useState(null);
  const [currency, setCurrency] = useState(props.settings.currency);
  const [gender, setGender] = React.useState();
  const [driverLicense, setDriverLicense] = React.useState();
  const [visaRequired, setVisaRequired] = React.useState();
  const [showDivs, setShowDivs] = useState({ visaRequired: false });
  const [processVetting, setProcessVetting] = useState(false);
  const [showVetting, setShowVetting] = useState(false);
  const [showMalaysiaFields, setShowMalaysiaFields] = useState(false);
  const [fieldError, setFieldError] = useState({
    first_name_error: false,
    last_name_error: false,
    email_error: false,
    email_helper: '',
    phoneNumber_error: false,
    phoneNumber_helper: '',
    siaLicenceNumber_error: false,
    siaLicenceNumber_helper: '',
    siaExpiryDate_error: false,
    siaExpiryDate_helper: '',
    role_error: false,
    position_error: false,
    department_error: false,
    address_error: false,
    address_helper: '',
    identification_number_error: false,
    identification_number_helper: '',
    payRate_error: false,
    company_error: false,
    payRateStart_error: false,
    payRateStart_helper: '',
    payRateEnd_error: false,
    payRateEnd_helper: '',
  });
  const [positionsList, setPositionsList] = useState(props.designationList);
  const [departmentsList, setDepartmentsList] = useState(props.departmentList);
  const [qualificationDiv, setQualificationDiv] = useState(false);
  const [organizationQualificationList, setOrganizationQualificationList] = useState(props.organizationQualification);

  const setNewValues = e => {
    setFieldValues(oldValues => ({ ...oldValues, [e.target.name]: e.target.value }));

    if (
      e.target.name === 'first_name' &&
      (!/^(?! )[a-zA-Z0-9\s(),.-]*$/.test(e.target.value) || e.target.value === '' || e.target.value.length < 1)
    ) {
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: true }));
      setFieldError(oldFieldError => ({
        ...oldFieldError,
        [e.target.name + '_helper']: validationMessageOnChange.firstName,
      }));
    } else if (
      e.target.name === 'last_name' &&
      (!/^(?! )[a-zA-Z0-9\s(),.-]*$/.test(e.target.value) || e.target.value === '' || e.target.value.length < 1)
    ) {
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: true }));
      setFieldError(oldFieldError => ({
        ...oldFieldError,
        [e.target.name + '_helper']: validationMessageOnChange.lastName,
      }));
    } else if (e.target.name === 'identification_number' && !/^(?! )[a-zA-Z0-9\s(),.-]*$/.test(e.target.value)) {
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: true }));
      setFieldError(oldFieldError => ({
        ...oldFieldError,
        [e.target.name + '_helper']: validationMessageOnChange.number,
      }));
    } else if (e.target.name === 'address' && e.target.value === '') {
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: true }));
      setFieldError(oldFieldError => ({
        ...oldFieldError,
        [e.target.name + '_helper']: validationMessageOnChange.address,
      }));
    } else if ((e.target.name === 'role' || e.target.name === 'department') && e.target.value === null) {
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: true }));
    } else if (e.target.name === 'siaLicenceNumber' && e.target.value.length > 0 && !/^[0-9]{16}$/.test(e.target.value)) {
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: true }));
      setFieldError(oldFieldError => ({
        ...oldFieldError,
        [e.target.name + '_helper']: validationMessageOnChange.SIAnumber,
      }));
    } else if (
      e.target.name === 'email' &&
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        e.target.value
      )
    ) {
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: true }));
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_helper']: validationMessageOnChange.email }));
    } else {
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: false, [e.target.name + '_helper']: '' }));
    }
  };

  const genderhandleChange = event => {
    setGender(event.target.value);
    setFieldValues(oldFieldValues => ({ ...oldFieldValues, ['gender']: event.target.value }));
  };
  const driverhandleChange = event => {
    setDriverLicense(event.target.value);
    setFieldValues(oldFieldValues => ({ ...oldFieldValues, ['driverLicense']: event.target.value }));
  };
  const visaRequiredhandleChange = event => {
    setVisaRequired(event.target.value);
    setFieldValues(oldFieldValues => ({ ...oldFieldValues, ['visa_required']: event.target.value }));
    if (event.target.value === 'no') {
      setFieldValues(oldValues => ({ ...oldValues, ['visaDetails']: null }));
      setFieldValues(oldValues => ({ ...oldValues, ['visaRequiredDate']: null }));
    }
  };

  const handlePayRateStart = e => {
    if (e && e !== undefined && e !== null) {
      if (
        pay_rate_effective_date &&
        pay_rate_effective_date !== undefined &&
        pay_rate_effective_date !== null &&
        pay_rate_effective_date.valueOf() < e.valueOf()
      ) {
        setFieldError(oldFieldError => ({
          ...oldFieldError,
          ['payRateStart_error']: true,
          ['payRateStart_helper']: 'Start Date should be less than End Date',
        }));
      } else {
        setFieldError(oldFieldError => ({
          ...oldFieldError,
          ['payRateStart_error']: false,
          ['payRateStart_helper']: '',
        }));
        const d = moment(e).format('YYYY-MM-DD');
        setPayRateStart(e);
        setFieldValues(oldFieldValues => ({
          ...oldFieldValues,

          employment_detail: { ...oldFieldValues.employment_detail, pay_rate_start_date: d },
        }));
      }
    }
  };

  const handlePayRateEnd = e => {
    if (e && e !== undefined && e !== null) {
      if (
        pay_rate_start_date &&
        pay_rate_start_date !== undefined &&
        pay_rate_start_date !== null &&
        pay_rate_start_date.valueOf() > e.valueOf()
      ) {
        setFieldError(oldFieldError => ({
          ...oldFieldError,
          ['payRateEnd_error']: true,
          ['payRateEnd_helper']: 'End Date should be greater than Start Date',
        }));
      } else {
        setFieldError(oldFieldError => ({
          ...oldFieldError,
          ['payRateEnd_error']: false,
          ['payRateEnd_helper']: '',
        }));
        const d = moment(e).format('YYYY-MM-DD');
        setPayRateEnd(e);
        setFieldValues(oldFieldValues => ({
          ...oldFieldValues,

          employment_detail: { ...oldFieldValues.employment_detail, pay_rate_effective_date: d },
        }));
      }
    }
  };

  const handleDateOfBirth = e => {
    const d = moment(e).format('YYYY-MM-DD');
    setDateOfBirth(e);
    setFieldValues(oldValues => ({ ...oldValues, ['date_of_birth']: d }));
  };
  const handleVisaExpiryDate = e => {
    const d = moment(e).format('YYYY-MM-DD');
    setVisaRequiredDate(e);
    setFieldValues(oldValues => ({ ...oldValues, ['visaRequiredDate']: d }));
  };

  const handleClose = () => {
    props.history.push('/staff');
  };

  useEffect(() => {
    setCurrency(props.settings.currency);
  }, [props.settings]);

  useEffect(() => {
    setdateFormat(props.setting.date_format);
  }, [props.setting]);

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }
    props.resetCountry();
    props.resetPos();
    props.resetDep();
    props.getOrganizationWithSubContractors();
    props.getRoles();
    props.positionEntities(-1);
    props.departmentEntities(-1);
  }, []);

  useEffect(() => {
    if (personEntity && personEntity?.country && personEntity.country && personEntity.country.id) {
      const countryData = { id: personEntity.country.id, text: personEntity.country.name };
      props.getCity(countryData.id);
    }
    setFieldValues(personEntity);
  }, [personEntity]);

  useEffect(() => {
    if (!isNew) {
      if (personEntity && Object.keys(personEntity).length !== 0) {
        if (personEntity.subcontractors && personEntity.subcontractors.length === 0) {
          setCompanyValue(addAttributesToCompanies(personEntity.organization, 'ORGANIZATION'));
        } else if (personEntity.organization === null) {
          setCompanyValue(addAttributesToCompanies(personEntity.subcontractors, 'SUBCONTRACTOR'));
        } else {
          setCompanyValue([
            ...addAttributesToCompanies(personEntity.organization, 'ORGANIZATION'),
            ...addAttributesToCompanies(personEntity.subcontractors, 'SUBCONTRACTOR'),
          ]);
        }
      }
    }
    if (personEntity && personEntity.visa_required === 'yes') {
      setShowDivs(oldValues => ({ ...oldValues, visaRequired: true }));
    }
    if (personEntity && personEntity['country'] && personEntity['country']['name'] === 'Malaysia') {
      setShowMalaysiaFields(true);
    } else {
      setShowMalaysiaFields(false);
    }
  }, [personEntity]);

  useEffect(() => {
    setFieldValues(personEntity);
  }, [personEntity]);

  useEffect(() => {
    setRolesList(props.rolesList);
  }, [props.rolesList]);

  useEffect(() => {
    setDepartmentsList(props.departmentList);
  }, [props.departmentList]);

  useEffect(() => {
    setPositionsList(props.designationList);
  }, [props.designationList]);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  useEffect(() => {
    setOrganizationQualificationList(props.organizationQualification);
  }, [props.organizationQualification]);

  useEffect(() => {
    if (props.personEntity && props.personEntity.imageUrl && props.personEntity.imageUrl !== null) {
      setImageFile(props.personEntity.imageUrl);
    } else {
      setImageFile('content/images/profileImage.png');
    }
  }, [props.personEntity.imageUrl]);

  const addAttributesToCompanies = (company, type) => {
    if (type === 'ORGANIZATION') {
      return [{ ...company, id: 0, combinedId: 0, status: 'ORGANIZATION' }];
    }
    if (type === 'SUBCONTRACTOR') {
      company.forEach(element => {
        element.combinedId = element.id;
        element.status = 'SUBCONTRACTOR';
      });
      return company;
    }
  };

  const handleIncomeTaxNumber = e => {
    if (!isNew) {
      if (personEntity.income_tax_number) {
        setFieldValues(oldFieldValues => ({
          ...oldFieldValues,
          ['income_tax_number']: {
            id: personEntity.income_tax_number.id,
            value: e.target.value,
            name: 'IncomeTaxNumber',
            designation_qualification_type_id: personEntity.income_tax_number.designation_qualification_type_id,
            person_id: props.personEntity.id,
          },
        }));
      } else {
        setFieldValues(oldFieldValues => ({
          ...oldFieldValues,
          ['income_tax_number']: {
            id: null,
            value: e.target.value,
            name: 'IncomeTaxNumber',
            designation_qualification_type_id: personEntity['income_tax_number_type']
              ? props.personEntity['income_tax_number_type']['id']
              : '',
            person_id: props.personEntity.id,
          },
        }));
      }
    } else {
      setFieldValues(oldFieldValues => ({ ...oldFieldValues, [e.target.name]: e.target.value }));
    }
  };

  const handlePassportNumber = e => {
    if (!isNew) {
      if (personEntity.passport_number) {
        setFieldValues(oldFieldValues => ({
          ...oldFieldValues,
          ['passport_number']: {
            id: personEntity.passport_number.id,
            value: e.target.value,
            name: 'PassportNumber',
            designation_qualification_type_id: personEntity.passport_number.designation_qualification_type_id,
            person_id: props.personEntity.id,
          },
        }));
      } else {
        setFieldValues(oldFieldValues => ({
          ...oldFieldValues,
          ['passport_number']: {
            id: null,
            value: e.target.value,
            name: 'PassportNumber',
            designation_qualification_type_id: personEntity['passport_number_type'] ? props.personEntity['passport_number_type']['id'] : '',
            person_id: props.personEntity.id,
          },
        }));
      }
    } else {
      setFieldValues(oldFieldValues => ({ ...oldFieldValues, [e.target.name]: e.target.value }));
    }
  };

  const handleKwspNumber = e => {
    if (!isNew) {
      if (personEntity.kwsp_number) {
        setFieldValues(oldFieldValues => ({
          ...oldFieldValues,
          ['kwsp_number']: {
            id: personEntity.kwsp_number.id,
            value: e.target.value,
            name: 'KwspNumber',
            designation_qualification_type_id: personEntity.kwsp_number.designation_qualification_type_id,
            person_id: props.personEntity.id,
          },
        }));
      } else {
        setFieldValues(oldFieldValues => ({
          ...oldFieldValues,
          ['kwsp_number']: {
            id: null,
            value: e.target.value,
            name: 'KwspNumber',
            designation_qualification_type_id: personEntity['kwsp_number_type'] ? props.personEntity['kwsp_number_type']['id'] : '',
            person_id: props.personEntity.id,
          },
        }));
      }
    } else {
      setFieldValues(oldFieldValues => ({ ...oldFieldValues, [e.target.name]: e.target.value }));
    }
  };

  const setFormValues = e => {
    setNewValues(e);
    return ({ target: { value } }) => {
      setFieldValues(oldFieldValues => ({ ...oldFieldValues, [e.target.name]: value }));
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: false }));
    };
  };

  const handleEmploymentDetails = e => {
    setFieldError(oldFieldError => ({ ...oldFieldError, ['payRate_error']: false }));
    if (isNew) {
      setFieldValues(oldFieldValues => ({
        ...oldFieldValues,
        ['employment_detail']: { pay_rate: e.target.value },
      }));
    } else {
      setFieldValues(oldFieldValues => ({
        ...oldFieldValues,
        employment_detail: {
          ...oldFieldValues.employment_detail,
          id: personEntity ? (personEntity.employment_detail ? personEntity.employment_detail.id : null) : null,
          pay_rate: e.target.value,
        },
      }));
    }
  };

  const handlePositionData = (_, e) => {
    if (e) {
      if (e.is_vettable) {
        setShowVetting(true);
        setProcessVetting(true);
      } else {
        setShowVetting(false);
        setProcessVetting(false);
      }
      setFieldValues(oldValues => ({ ...oldValues, ['designation']: e }));
      if (fieldValues['designation'] !== null || fieldValues['designation'] !== undefined) {
        setFieldError(oldFieldError => ({ ...oldFieldError, ['position_error']: false }));
      }
    } else {
      setFieldValues(oldValues => ({ ...oldValues, ['designation']: null }));
    }
  };
  const handleRoleData = (_, e) => {
    if (isNew) {
      setFieldValues(oldValues => ({ ...oldValues, ['role']: e }));
      if (fieldValues['role'] !== null || fieldValues['role'] !== undefined) {
        setFieldError(oldFieldError => ({ ...oldFieldError, ['role_error']: false }));
      }
    } else {
      setFieldValues(oldValues => ({ ...oldValues, ['role']: [e] }));
      if (fieldValues['role'] !== null || fieldValues['role'] !== undefined) {
        setFieldError(oldFieldError => ({ ...oldFieldError, ['role_error']: false }));
      }
    }
  };
  const handleDepartmentData = (_, e) => {
    if (e) {
      setFieldValues(oldValues => ({ ...oldValues, ['department']: e }));
      setFieldError(oldFieldError => ({ ...oldFieldError, ['department_error']: false }));
    } else {
      setFieldValues(oldValues => ({ ...oldValues, ['department']: null }));
    }
  };

  const handlePersonCompany = (_, companies) => {
    if (companies.length !== 0) {
      setFieldError(oldFieldValues => ({ ...oldFieldValues, ['company_error']: false }));
    } else {
      setFieldError(oldFieldValues => ({ ...oldFieldValues, ['company_error']: true }));
    }
    setCompanyValue(companies);
    let selected_org = null;
    const selected_subs = [];
    const data = companies.map(a => a.id);

    if (data && data.length > 0) {
      data.forEach(element => {
        const subcontractorsList = props.subcontractors;
        const selection = subcontractorsList.find(company => company.combinedId === element);
        if (selection.status === 'ORGANIZATION') {
          selected_org = selection;
        } else {
          selected_subs.push({ id: selection?.id, name: selection?.name });
        }
      });
      setFieldValues(oldFieldValues => ({
        ...oldFieldValues,
        ['organization']: selected_org ? { id: selected_org?.id, name: selected_org?.name } : null,
      }));
      setFieldValues(oldFieldValues => ({ ...oldFieldValues, ['subcontractors']: selected_subs }));
    }
  };

  const handleProcessVetting = event => {
    setProcessVetting(event.target.checked);
    setFieldValues(oldFieldValues => ({ ...oldFieldValues, ['processVetting']: event.target.checked }));
  };

  const handlePhoneNumber = phone => {
    setFieldError(oldFieldValues => ({ ...oldFieldValues, ['phoneNumber_error']: false, ['phoneNumber_helper']: '' }));
    setPhoneNumber(phone);
    if (isNew) {
      setFieldValues(oldFieldValues => ({
        ...oldFieldValues,
        ['phoneNumber']: phone,
      }));
    } else {
      setFieldValues(oldFieldValues => ({ ...oldFieldValues, ['phoneNumber']: { value: phone } }));
    }
  };

  const handleEmail = e => {
    setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: false, [e.target.name + '_helper']: '' }));
    if (isNew) {
      setFieldValues(oldFieldValues => ({
        ...oldFieldValues,
        ['email']: e.target.value,
      }));
    } else {
      setFieldValues(oldFieldValues => ({ ...oldFieldValues, ['email']: [{ value: e.target.value }] }));
    }
  };

  const handleAddress = e => {
    if (isNew) {
      setFieldValues(oldFieldValues => ({
        ...oldFieldValues,
        ['address']: e.target.value,
      }));
    } else {
      if (personEntity.address && Object.keys(personEntity.address).length !== 0) {
        setFieldValues(oldFieldValues => ({
          ...oldFieldValues,
          ['address']: {
            id: personEntity ? (personEntity.address ? personEntity.address[0].id : null) : null,
            address_line_one: e.target.value,
            address_line_two: null,
            address_unit_identifier_value: null,
          },
        }));
      } else {
        setFieldValues(oldFieldValues => ({
          ...oldFieldValues,
          ['address']: { id: null, address_line_one: e.target.value },
        }));
      }
    }
    setFieldError(oldFieldError => ({ ...oldFieldError, ['address_error']: false }));
  };

  const handleProfilePicture = e => {
    setFieldValues(oldFieldValues => ({ ...oldFieldValues, ['person_image']: e.target.files[0] }));
    if (e.target.files && e.target.files[0]) {
      setImageFile(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleCountryData = (_, e) => {
    if (e) {
      setFieldValues(oldValues => ({ ...oldValues, ['country']: e }));
      props.getCity(e.id);
      if (e.name === 'Malaysia') {
        setShowMalaysiaFields(true);
      } else {
        setFieldValues(oldValues => ({ ...oldValues, ['income_tax_number']: null }));
        setFieldValues(oldValues => ({ ...oldValues, ['passport_number']: null }));
        setFieldValues(oldValues => ({ ...oldValues, ['kwsp_number']: null }));
        setShowMalaysiaFields(false);
      }
    } else {
      setFieldValues(oldValues => ({ ...oldValues, ['country']: null }));
    }
  };

  const handleCityData = (_, e) => {
    if (e) {
      setFieldValues(oldValues => ({ ...oldValues, ['city']: e }));
    } else {
      setFieldValues(oldValues => ({ ...oldValues, ['city']: null }));
    }
  };
  const handleBirthCountryData = (_, e) => {
    if (e) {
      setFieldValues(oldValues => ({ ...oldValues, ['birthCountry']: e }));
      props.getCity(e.id);
    } else {
      setFieldValues(oldValues => ({ ...oldValues, ['birthCountry']: null }));
    }
  };
  const handleNationalitiesData = (_, e) => {
    if (e) {
      setFieldValues(oldValues => ({ ...oldValues, ['nationality']: e }));
    } else {
      setFieldValues(oldValues => ({ ...oldValues, ['nationality']: null }));
    }
  };

  const handleBirthCityData = (_, e) => {
    if (e) {
      setFieldValues(oldValues => ({ ...oldValues, ['birthCity']: e }));
    } else {
      setFieldValues(oldValues => ({ ...oldValues, ['birthCity']: null }));
    }
  };

  const handleHideShowDiv = (name, value) => {
    setShowDivs(oldValues => ({ ...oldValues, [name]: value }));
  };

  const [qualificationFileName, setQualificationFileName] = useState({});

  const handleQualificationSwitch = () => {
    setQualificationDiv(prev => !prev);
  };

  const [personFiles, setPersonFiles] = useState([]);
  const [qualificationIndex, setQualificationIndex] = useState({});

  const handleQualificationIndex = index => {
    setQualificationIndex(index);
  };

  const handleQualificationProofs = (event, index) => {
    const makeFilesCopy = personFiles;
    makeFilesCopy.push({ file_name: index, file: event.target.files[0] });
    setPersonFiles(makeFilesCopy);
    const key = `file-${index.someProperty}`;
    setQualificationFileName(oldValues => ({ ...oldValues, [key]: event.target.files[0].name }));
  };

  useEffect(() => {
    props.getCountry();
    props.getAllCountry();
    props.getOrganizationQualifications();
  }, []);

  const checkForValidations = () => {
    const result =
      !fieldError['first_name_error'] &&
      !fieldError['last_name_error'] &&
      !fieldError['email_error'] &&
      !fieldError['department_error'] &&
      !fieldError['position_error'] &&
      !fieldError['role_error'] &&
      !fieldError['address_error'] &&
      !fieldError['company_error'] &&
      !fieldError['identification_number_error'] &&
      !fieldError['payRateStart_error'] &&
      !fieldError['payRateEnd_error'] &&
      !(company.length === 0) &&
      !(fieldValues['phoneNumber'] === undefined) &&
      !(fieldValues['first_name'] === undefined) &&
      !(fieldValues['last_name'] === undefined) &&
      !(fieldValues['email'] === undefined) &&
      !(fieldValues['role'] === undefined) &&
      !(fieldValues['designation'] === undefined) &&
      !(fieldValues['department'] === undefined) &&
      !(fieldValues['address'] === undefined) &&
      !(fieldValues['employment_detail']['pay_rate'] === undefined) &&
      !(company === undefined);

    return result;
  };

  const checkForRequiredValidations = () => {
    let output = true;
    if (fieldValues['first_name'] === undefined) {
      setFieldError(oldFieldError => ({ ...oldFieldError, ['first_name_error']: true }));
      setFieldError(oldFieldError => ({ ...oldFieldError, ['first_name_helper']: validationMessageBeforeSubmit.firstName }));
      output = false;
    }
    if (fieldValues['address'] === undefined || fieldValues['address'].length === 0) {
      setFieldError(oldFieldError => ({ ...oldFieldError, ['address_error']: true }));
      output = false;
    }
    if (fieldValues['last_name'] === undefined) {
      setFieldError(oldFieldError => ({ ...oldFieldError, ['last_name_error']: true }));
      setFieldError(oldFieldError => ({ ...oldFieldError, ['last_name_helper']: validationMessageBeforeSubmit.lastName }));
      output = false;
    }
    if (fieldValues['email'] === undefined) {
      setFieldError(oldFieldError => ({ ...oldFieldError, ['email_error']: true }));
      setFieldError(oldFieldError => ({ ...oldFieldError, ['email_helper']: validationMessageBeforeSubmit.email }));
      output = false;
    }
    if (fieldValues['role'] === undefined) {
      setFieldError(oldFieldError => ({ ...oldFieldError, ['role_error']: true }));
      output = false;
    }
    if (fieldValues['designation'] === undefined || fieldValues['designation'] === null) {
      setFieldError(oldFieldError => ({ ...oldFieldError, ['position_error']: true }));
      output = false;
    }
    if (fieldValues['department'] === undefined || fieldValues['department'] === null) {
      setFieldError(oldFieldError => ({ ...oldFieldError, ['department_error']: true }));
      output = false;
    }
    if (
      fieldValues['pay_rate'] === undefined &&
      (fieldValues['employment_detail'] ? fieldValues['employment_detail']['pay_rate'] === undefined : true)
    ) {
      setFieldError(oldFieldError => ({ ...oldFieldError, ['payRate_error']: true }));
      output = false;
    }
    if (company.length === 0) {
      setFieldError(oldFieldError => ({ ...oldFieldError, ['company_error']: true }));
      output = false;
    }
    if (fieldValues['phoneNumber'] === undefined || Object.keys(fieldValues['phoneNumber']).length === 0) {
      setFieldError(oldFieldError => ({
        ...oldFieldError,
        ['phoneNumber_error']: true,
        ['phoneNumber_helper']: 'The Phone Number is required',
      }));
      output = false;
    }
    return output;
  };

  const checkSiaLicenceValidity = () => {
    if (fieldValues['siaLicenceNumber'] && fieldValues['siaLicenceNumber'].length >= 0) {
      if (fieldError['siaLicenceNumber_error'] === false && fieldValues['siaLicenceNumber'].length === 16) {
        if (fieldValues['expiry_date'] && fieldError['siaExpiryDate_error'] === false) {
          return true;
        } else {
          setFieldError(oldFieldError => ({
            ...oldFieldError,
            ['siaExpiryDate_error']: true,
            ['siaExpiryDate_helper']: 'Enter licence expiry date',
          }));
          return false;
        }
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const saveEntity = event => {
    event.preventDefault();
    const formData = new FormData();
    if (fieldValues['person_image'] !== undefined) {
      formData.append('avatar', fieldValues['person_image']);
    }
    const object = new FormData();
    if (personFiles.length > 0) {
      personFiles.forEach((element, index) => {
        object.append(`avatar-${index}`, element.file);
      });
      object.append('doc_type', '6');
    }
    if (isNew && checkForRequiredValidations() && checkForValidations() && checkSiaLicenceValidity()) {
      props.createEntity(fieldValues, formData, object);
      props.reset();
    } else if (!isNew && checkForRequiredValidations() && checkForValidations() && checkSiaLicenceValidity()) {
      props.updateEntity(fieldValues, formData, object);
    } else {
      checkForRequiredValidations();
    }
  };

  const onKeyUp = event => {
    if (event.key === 'Enter') {
      saveEntity(event);
    }
  };

  return (
    <div className="person-create-main-container">
      <div className="person_create_layout_div">
        <Typography variant="h5" className="personTopTitle">
          Create/ Edit a New Person
        </Typography>
        <div className="personTopUnderTitle">Please fill all of the given fields.</div>
        <div className="personOuterDiv" style={{ display: 'flex' }}>
          {loading ? (
            <div className="lds-roller"></div>
          ) : (
            <form
              onKeyPress={e => {
                onKeyUp(e);
              }}
              noValidate
              className="person-form"
            >
              <div style={{ display: 'flex' }}>
                <PersonUpdateGeneralInformation
                  gender={gender}
                  genderhandleChange={genderhandleChange}
                  setFormValues={setFormValues}
                  handleIncomeTaxNumber={handleIncomeTaxNumber}
                  handlePassportNumber={handlePassportNumber}
                  handleKwspNumber={handleKwspNumber}
                  personEntity={personEntity}
                  fieldValues={fieldValues}
                  fieldError={fieldError}
                  handleEmail={handleEmail}
                  handlePhoneNumber={handlePhoneNumber}
                  handleAddress={handleAddress}
                  handleCountryData={handleCountryData}
                  countriesList={props.countriesList}
                  showMalaysiaFields={showMalaysiaFields}
                  handleCityData={handleCityData}
                  citiesList={props.citiesList}
                  imageFile={imageFile}
                  handleProfilePicture={handleProfilePicture}
                  verifyEmail={verifyEmail}
                  setFieldError={setFieldError}
                  countryOfOperations={props.settings.country_of_operations}
                  countryTypes={country_types}
                  classes={classes}
                  isNew={isNew}
                />
              </div>
              <PersonUpdateCompanyInformation
                handleProcessVetting={handleProcessVetting}
                showVetting={showVetting}
                handlePersonCompany={handlePersonCompany}
                company={company}
                subcontractors={subcontractors}
                setFieldValues={setFieldValues}
                personEntity={personEntity}
                fieldValues={fieldValues}
                fieldError={fieldError}
                positionsList={positionsList}
                rolesList={props.rolesList}
                departmentsList={departmentsList}
                setting={props.setting}
                expiry_date={expiry_date}
                setExpiry_date={setExpiry_date}
                setFieldError={setFieldError}
                classes={classes}
                handlePositionData={handlePositionData}
                handleRoleData={handleRoleData}
                handleDepartmentData={handleDepartmentData}
                setNewValues={setNewValues}
                isNew={isNew}
              />
              <PersonUpdateBirthInformation
                setting={props.setting}
                date_of_birth={date_of_birth}
                handleDateOfBirth={handleDateOfBirth}
                handleNationalitiesData={handleNationalitiesData}
                personEntity={personEntity}
                allCountriesList={props.allCountriesList}
                fieldValues={fieldValues}
                handleBirthCountryData={handleBirthCountryData}
                handleBirthCityData={handleBirthCityData}
                citiesList={props.citiesList}
                setFormValues={setFormValues}
                driverLicense={driverLicense}
                driverhandleChange={driverhandleChange}
                classes={classes}
              />
              <PersonUpdateVisaInformation
                visaRequired={visaRequired}
                showDivs={showDivs}
                handleHideShowDiv={handleHideShowDiv}
                visaRequiredhandleChange={visaRequiredhandleChange}
                setFormValues={setFormValues}
                personEntity={personEntity}
                fieldValues={fieldValues}
                fieldError={fieldError}
                setting={props.setting}
                visaRequiredDate={visaRequiredDate}
                handleVisaExpiryDate={handleVisaExpiryDate}
              />

              <PersonUpdatePayrateInformation
                CurrencyChecker={CurrencyChecker}
                currency={currency}
                fieldValues={fieldValues}
                fieldError={fieldError}
                setting={props.setting}
                pay_rate_start_date={pay_rate_start_date}
                handlePayRateStart={handlePayRateStart}
                pay_rate_effective_date={pay_rate_effective_date}
                handlePayRateEnd={handlePayRateEnd}
                handleEmploymentDetail={handleEmploymentDetails}
              />

              {props.organizationQualification && props.organizationQualification.length > 0 ? (
                <PersonUpdateOrganizationQualification
                  CurrencyChecker={CurrencyChecker}
                  currency={currency}
                  fieldValues={fieldValues}
                  fieldError={fieldError}
                  setting={props.setting}
                  setFieldValues={setFieldValues}
                  handleQualificationSwitch={handleQualificationSwitch}
                  qualificationDiv={qualificationDiv}
                  setQualificationDiv={setQualificationDiv}
                  organizationQualificationList={organizationQualificationList}
                  handleQualificationProofs={handleQualificationProofs}
                  qualificationProofs={props.qualificationProofs}
                  isNew={isNew}
                  personEntity={personEntity}
                  handleQualificationIndex={handleQualificationIndex}
                  qualificationFileName={qualificationFileName}
                />
              ) : null}

              <Grid item xs={12} md={12} sm={12} style={{ textAlign: 'center' }}>
                <FormControl className="person-fields PersonSaveDiscardButtons">
                  <Button
                    variant="outlined"
                    className="personDiscardButton"
                    onClick={() => {
                      props.reset();
                    }}
                    component={Link}
                    to="/staff"
                  >
                    Cancel
                  </Button>
                  &nbsp;
                  <Button
                    onClick={() => {
                      saveEntity(event);
                    }}
                    variant="contained"
                    id="save-entity"
                    type="submit"
                    className="personSaveButton"
                  >
                    Save Changes
                  </Button>
                </FormControl>
              </Grid>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  personEntity: storeState.person.entity,
  rolesList: storeState.person.roles,
  loading: storeState.person.loading,
  updateSuccess: storeState.person.updateSuccess,
  subcontractors: storeState.subcontractor.subcontractors_list,
  siaLicenceInfo: storeState.person.siaLicenceInfo,
  settings: storeState.organizationSettings.storedOrganizationSettings,
  setting: storeState.PersonalSettings.storedPersonalSettings,
  countriesList: storeState.subcontractor.CountryEntity,
  allCountriesList: storeState.person.AllCountryEntity,
  citiesList: storeState.subcontractor.CityEntity,
  designationList: storeState.designation.entities,
  departmentList: storeState.department.entities,
  organizationQualification: storeState.OrganizationQualification.storedQualifications,
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  getRoles,
  reset,
  getOrganizationWithSubContractors,
  positionEntities,
  resetPos,
  departmentEntities,
  resetDep,
  getOrganizationSettings,
  getPersonalSettings,
  getCity,
  getCountry,
  getAllCountry,
  resetCountry,
  setPageNumber,
  getOrganizationQualifications,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PersonUpdate);
