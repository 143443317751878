// Dummy
import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Table, Divider, Grid } from '@material-ui/core';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../../utils/GlobalCssRequiredInWebsite.scss';
import Spinner from '../../commonUI/spinner/spinner';
import './person.scss';
import { IRootState } from 'app/shared/reducers';
import { getEntities, setPageNumber, reset } from './person.reducer';
import IconButton from '@material-ui/core/IconButton';
import { getPersonalSettings } from '../../modules/Setting/Personal-Setting.reducer';
import { shiftListStartEndDate } from '../../../utils/TimeFormatAndTimezone';
import SearchBar from 'material-ui-search-bar';
import Paper from '@material-ui/core/Paper';
import InfiniteScroll from 'react-infinite-scroll-component';
import TableContainer from '@material-ui/core/TableContainer';
import debounce from 'lodash.debounce';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import CustomToolbarComponent from '../service-area/CustomToolbarComponent';
import moment from 'moment';
import FormControl from '@material-ui/core/FormControl';
import MomentUtils from '@date-io/moment';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { dateFTConverter } from '../../../utils/TimeFormatAndTimezone';
import { getSelectedTimePeriodFilter } from '../../../utils/TimeFormatAndTimezone';
import { handleFilterCurrentWeekStartDate, handleFilterCurrentWeekEndDate } from '../../../utils/TimeFormatAndTimezone';
import { convertToPDF } from './convertToPDF';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import GetAppIcon from '@material-ui/icons/GetApp';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import QrCodePopper from '../components/QrCodePopper';

export interface IPersonProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> { }

export const Person = (props: IPersonProps) => {
  const [dateFormat, setdateFormat] = useState(props.setting.date_format);
  const [records, setRecords] = useState(props.personList);
  const [isNew] = useState(!props.match.params || !props.match.params.id);
  const [isSearch, setSearch] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedPerson, setSelectedPerson] = React.useState<any | null>(null);
  const [selectedLocation, setSelectedLocation] = React.useState<any | null>(null);
  const [selectedTimePeriod, setTimePeriod] = useState({ id: 3, text: 'Current week' });
  const [values, setValues] = useState({
    people: '',
    location: '',
    from_date: moment(handleFilterCurrentWeekStartDate()).format('YYYY-MM-DD'),
    to_date: moment(handleFilterCurrentWeekEndDate()).format('YYYY-MM-DD'),
  });

  useEffect(() => {
    props.reset();
    props.setPageNumber({ pageNumber: 1 });
  }, []);

  useEffect(() => {
    setdateFormat(props.setting.date_format);
  }, [props.setting]);

  const handleSyncList = () => {
    props.reset();
    props.setPageNumber({ pageNumber: 1 });
    setSearchTerm('');
  };

  useEffect(() => {
    setRecords(props.personList);
  }, [props.personList]);

  const search = e => {
    props.reset();
    setSearchTerm(e);
    debouncedSearch(e);
    setSearch(true);
  };

  const debouncedSearch = useCallback(
    debounce(term => {
      props.reset();
      props.getEntities(-1, term);
    }, 500),
    []
  );

  const cancelSearch = () => {
    props.reset();
    props.setPageNumber({ pageNumber: 1 });
    setSearch(false);
  };

  const [selectedDateFrom, setSelectedDateFrom] = React.useState<any | null>(
    getSelectedTimePeriodFilter(selectedTimePeriod.text, 'setSelectedDateFrom')
  );

  const [selectedDateTo, setSelectedDateTo] = React.useState<any | null>(
    getSelectedTimePeriodFilter(selectedTimePeriod.text, 'setSelectedDateTo')
  );

  const handleDateFrom = e => {
    props.reset();
    const d = moment(e).format('YYYY-MM-DD');
    setSelectedDateFrom(e);
    if (d !== 'Invalid date') {
      setValues(oldValues => ({ ...oldValues, ['from_date']: d }));
    }
  };

  const handleDateTo = e => {
    props.reset();
    const d = moment(e).format('YYYY-MM-DD');
    setSelectedDateTo(e);
    if (d !== 'Invalid date') {
      setValues(oldValues => ({ ...oldValues, ['to_date']: d }));
    }
  };

  const setFilterValues = (name, value) => {
    setValues(oldValues => ({ ...oldValues, [name]: value }));
  };

  const handlePersonFilterValues = (_, e) => {
    if (e) {
      setSelectedPerson({ id: e.id, text: e.text });
      return setFilterValues(e.name, e.id);
    } else {
      setValues(oldValues => ({ ...oldValues, people: '' }));
    }
  };

  const handleLocationFilterValues = (_, e) => {
    if (e) {
      setSelectedLocation({ id: e.id, text: e.text });
      return setFilterValues(e.name, e.id);
    } else {
      setValues(oldValues => ({ ...oldValues, location: '' }));
    }
  };

  const handleRefreshButton = () => {
    props.reset();
    props.getEntities(1, '');
  };

  const jsonData = [];
  if (props.personList && props.personList.length > 0) {
    props.personList.forEach(element => {
      const data = { ...element };
      jsonData.push(data);
    });
  }
  const { personList, match, loading, role } = props;
  return (
    <div className="entities-reports-main-container">
      {props.loading && <Spinner />}
      <div className="entities-reports-filter-area">
        <Grid container spacing={2} style={{ flexWrap: 'nowrap', width: '65%' }}>
          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
            <FormControl variant="outlined" size="small">
              <SearchBar
                onChange={e => {
                  search(e);
                }}
                value={searchTerm}
                onCancelSearch={() => cancelSearch()}
                placeholder="Search Here..."
                style={{
                  outline: '1px solid #bbc4c2',
                  borderRadius: '4px',
                  height: '40px',
                }}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container className="entities_reports_refresh_and_download_div" style={{ width: '35%' }}>
          <IconButton
            size="small"
            className="mr-3"
            id="entities_reports_btn"
            onClick={() =>
              convertToPDF(jsonData, moment(selectedDateFrom).format('YYYY-MM-DD'), moment(selectedDateTo).format('YYYY-MM-DD'))
            }
            disabled={props.loading}
          >
            <GetAppIcon fontSize="large" />
          </IconButton>

          <IconButton size="small" className="mr-3" id="entities_reports_btn" disabled={props.loading} onClick={handleSyncList}>
            <AutorenewIcon fontSize="large" />
          </IconButton>

          {/* <Grid item xl={4} lg={4} md={4} sm={4} xs={4}> */}
          <Link
            to={`${match.url}/new`}
            className="btn jh-create-entity serviceAreaCreateButton incidentCreateButton"
            id="jh-create-entity"
            data-cy="entityCreateButton"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp; Create New
          </Link>
          {/* </Grid> */}
        </Grid>
      </div>
      {/* <div className="StaffTopButtonsDiv">
        <Paper elevation={3} className="staffSearchDiv">
          <SearchBar
            className="staffSearch"
            onChange={e => {
              search(e);
            }}
            value={searchTerm}
            onCancelSearch={() => cancelSearch()}
            placeholder="Search anything here .."
          />
        </Paper>
        <div className="StaffCreateButtonDiv">
          <IconButton
            onClick={() =>
              convertToPDF(jsonData, moment(selectedDateFrom).format('YYYY-MM-DD'), moment(selectedDateTo).format('YYYY-MM-DD'))
            }
            className="mr-2 downloadBtn"
            id="StaffDownloadButton"
            data-cy="entityEditButton"
          >
            <GetAppIcon fontSize="large" />
          </IconButton>

          <IconButton onClick={handleRefreshButton} className="mr-2 refreshBtn" id="StaffRefreshButton" data-cy="entityEditButton">
            <AutorenewIcon fontSize="large" />
          </IconButton>

          <Link
            to={`${match.url}/new`}
            className="btn btn-primary jh-create-entity personCreateButton"
            id="jh-create-entity"
            data-cy="entityCreateButton"
          >
            <div className="content">
              <AddCircleIcon />
              &nbsp; Add New
            </div>
          </Link>
        </div>
      </div> */}
      <div>
        <div className="globalTableHeaderGradientColor personTableHeaderRow sticky_div">
          <div className="personTableHeaderCell personHeaderNameCell">Employee Name</div>
          <div className="personTableHeaderCell personHeaderCompanyCell">Company/ Subcontractor</div>
          <div className="personTableHeaderCell personHeaderRoleCell">Position</div>
          <div className="personTableHeaderCell personHeaderContactDetailsCell">Contact Details</div>
          <div className="personTableHeaderCell personHeaderSIANoCell">SIA License No.</div>
          <div className="personTableHeaderCell personHeaderLicenseExpiryCell">License Expiry</div>
          <div className="personTableHeaderCell personQR">QR</div>
        </div>

        {records && records.length > 0 ? (
          <InfiniteScroll
            dataLength={personList.length}
            scrollThreshold="200px"
            next={() => {
              if (personList.length > 0 && isSearch !== true) {
                props.setPageNumber({ pageNumber: props.pageId['pageNumber'] + 1 });
              }
            }}
            hasMore={true}
            loader={''}
          >
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <tbody>
                  {records
                    ? records.map((person, i) => (
                      <tr key={`entity-${i}`} data-cy="entityTable" className="personTableBodyRows">
                        <td className="personTableBodyCell personTableBodyNamecell">
                          <a href={`${match.url}/${person.id}`}>
                            {person.first_name}&nbsp;{person.last_name}
                          </a>
                        </td>
                        <td className="personTableBodyCell personTableBodyDepartmentcell">
                          {person.organization
                            ? person.organization.name
                            : Array.isArray(person.subcontractors) &&
                            person.subcontractors.map((entity, index) => (
                              <React.Fragment key={index}>
                                <span className={'personTable new-line'}>{entity.name}</span>
                              </React.Fragment>
                            ))}
                        </td>
                        <td className="personTableBodyCell personTableBodyDesignationcell employeeName">
                          {person.designation ? person.designation.name : null}
                        </td>
                        <td className="personTableBodyCell personTableBodyEmailNumbercell">
                          {person && person.phoneNumber && person.phoneNumber[0] ? person.phoneNumber[0].value : null}
                          <br />
                          {person && person.email && person.email[0] ? (
                            <span className="personBodyCellEmail">{person.email[0].value}</span>
                          ) : null}
                        </td>
                        <td className="personTableBodyCell personTableBodySiaLicenceNumbercell">
                          {person && person.siaLicenceNumber && person.siaLicenceNumber.value}
                        </td>
                        <td className="personTableBodyCell personTableBodySiaExpiryDatecell">
                            {person && person.siaLicenceNumber && person.siaLicenceNumber.expiry_date && (
                              <span>{shiftListStartEndDate(person.siaLicenceNumber.expiry_date, dateFormat)}</span>
                            )}
                        </td>
                        <td className="personTableBodyCell personQR">
                          <QrCodePopper value={`${person.person_identity_number}`} index={i} />
                        </td>
                      </tr>
                    ))
                    : null}
                </tbody>
              </Table>
            </TableContainer>
          </InfiniteScroll>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="wfmApp.person.home.notFound">No People found</Translate>
            </div>
          )
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState, serviceArea: IRootState) => ({
  personList: storeState.person.entities,
  loading: storeState.person.loading,
  setting: storeState.PersonalSettings.storedPersonalSettings,
  role: storeState.authentication.account,
  pageId: storeState.person.pageNumber,
  serviceAreaList: storeState.serviceArea.entities,
  subcontractorsList: storeState.subcontractor.entities,
});

const mapDispatchToProps = {
  getEntities,
  getPersonalSettings,
  setPageNumber,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Person);
