import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { Divider, IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import SubcontractorDeleteDialog from '../subcontractor-delete-dialog';

export default function Subcontractor_Edit_Del_Btn({ isEdit, setIsEdit, props, flag }) {
  const { match, subcontractorEntity } = props;
  const [isDelete, setIsDelete] = useState(false);
  return (
    <div className="subcontractorEditBtnContainer">
      <div className="btnDiv">
        <Button
          onClick={() => setIsEdit(true)}
          className="subcontractor_edit_btn"
          variant="contained"
          startIcon={<img src="content/icons/Edit.png" />}
        >
          Edit
        </Button>
        {flag !== 'subcontractor_document' && (
          <Button
            className="subcontractor_del_btn"
            variant="contained"
            startIcon={<img src="content/icons/Icon awesome-trash-alt.png" />}
            onClick={() => setIsDelete(true)}
          >
            Delete Profile
          </Button>
        )}
      </div>
      <Divider style={{ margin: '4% 0' }} />
      {isDelete && <SubcontractorDeleteDialog props={props} isDelete={isDelete} setIsDelete={setIsDelete} />}
    </div>
  );
}
