import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Grid, Table, Modal, Typography, Divider } from '@material-ui/core';
import { IRootState } from 'app/shared/reducers';
import {
  saveSubcontractorDocuments,
  getSubcontractorDocuments,
  deleteSubcontractorDocument,
  getSubDocumentById,
} from '../subcontractor.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { Paper } from '@material-ui/core';
import '../subcontractor.scss';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FileViewer from 'react-file-viewer';
import BackupIcon from '@material-ui/icons/Backup';
import Subcontractor_Edit_Del_Btn from '../component/subcontractor-edit-del-btn';

export interface IEntityDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const SubcontractorDocuments = (props: IEntityDetailProps) => {
  const { subcontractorEntity, subcontractorDocuments } = props;
  const [values, setValues] = useState(subcontractorEntity);
  const [fileName, setFilesName] = useState({ name: 'No File Chosen' });
  const [modalOpen, setModalOpen] = useState(false);
  const [docdata, setDocData] = useState(props.documentData);
  const [isEdit, setIsEdit] = React.useState(false);
  const [isCancleEdit, setIsCancleEdit] = React.useState(Boolean);

  const saveSubcontractorDocuments = event => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('avatar', values['subcontractor_docs']);
    props.saveSubcontractorDocuments(values, formData);
    setValues(oldFieldValues => ({ ...oldFieldValues, ['brief_description']: '' }));
    setFilesName(oldFieldValues => ({ ...oldFieldValues, ['name']: '' }));
  };

  useEffect(() => {
    setDocData(props.documentData);
  }, [props.documentData]);

  useEffect(() => {
    setValues(subcontractorEntity);
  }, [subcontractorEntity]);

  const Trigger = name => {
    return ({ target: { value } }) => {
      setValues(oldValues => ({ ...oldValues, [name]: value }));
    };
  };

  useEffect(() => {
    props.getSubcontractorDocuments(props.match.params.id);
  }, []);

  const handleSubcontractorDocs = e => {
    if (e.target.files[0]) {
      setValues(oldFieldValues => ({ ...oldFieldValues, ['subcontractor_docs']: e.target.files[0] }));
      setFilesName(oldFieldValues => ({ ...oldFieldValues, ['name']: e.target.files[0].name }));
    }
  };

  const handleDeleteSubcontractorDocument = document_id => {
    props.deleteSubcontractorDocument(document_id, props.match.params.id);
  };

  const handleViewSubcontractorDocument = document_id => {
    setModalOpen(true);
    props.getSubDocumentById(document_id);
  };

  const handleDownloadSubcontractorDocument = () => {
    fetch(docdata?.file).then(response => {
      response.blob().then(blob => {
        const fileURL = window.URL.createObjectURL(blob);
        const name = 'Sample.' + docdata?.ext;
        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = name;
        alink.click();
      });
    });
  };

  const handleCloseViewDocModal = () => {
    setModalOpen(false);
    setDocData({ ext: null, file: null });
  };

  const printFile = () => {
    window.print();
  };

  const cancleEditHandler = () => {
    setIsEdit(!true);
  };
  return (
    <>
      <div className="subcontractor-create-main-container">
        <div className="subcontractor-main-outer-container" style={{ padding: '5%' }}>
          <div>
            <Grid container spacing={2}>
              <Grid item lg={8} xs={8}>
                <TextField
                  variant="outlined"
                  size="small"
                  name="brief_description"
                  className="documents-briefDescription"
                  value={values['brief_description'] ? values['brief_description'] : ''}
                  onChange={Trigger('brief_description')}
                  label="Brief Description"
                  fullWidth
                  style={{ background: '#ffffff' }}
                />
              </Grid>

              <Grid item lg={2} xs={2}>
                <input
                  type="file"
                  id="file-input"
                  name="subcontractor_docs"
                  onChange={handleSubcontractorDocs}
                  style={{ display: 'none' }}
                />
                <label htmlFor="file-input" style={{ width: '100%' }}>
                  <Button variant="contained" component="span" className="subcontractor-view-uploadDocumentButton">
                    <span className="upload-subcontractor-document-text">Upload Document</span>
                  </Button>
                </label>
              </Grid>

              <Grid item lg={2} xs={2}>
                <Button
                  variant="contained"
                  component="span"
                  className="subcontractor-view-submitButton"
                  onClick={saveSubcontractorDocuments}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
            <span id="file-chosen4">{fileName.name}</span>

            {subcontractorDocuments && subcontractorDocuments.length > 0 ? (
              <Table>
                <thead className="globalTableHeaderGradientColor">
                  <tr className="subcontractor-documentsTableHeaderRow">
                    <th className="subcontractor-documentsTableHeaderCell subcontractorHeaderDocumentCell">Document</th>
                    <th className="subcontractor-documentsTableHeaderCell subcontractorHeaderDescriptionCell">Description</th>
                    <th className="subcontractor-documentsTableHeaderCell subcontractorHeaderActionsCell">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {subcontractorDocuments
                    ? subcontractorDocuments.map((docs, i) => (
                        <tr key={`entity-${i}`} data-cy="entityTable" className="subcontractor-documentsTableBodyRows">
                          <td className="subcontractor-documentsTableBodyCell subcontractorTableBodyDocumentcell">
                            <a
                              className="subcontractor_doc_name"
                              onClick={() => {
                                handleViewSubcontractorDocument(docs.id);
                              }}
                            >
                              {'Doc ' + (i + 1)}
                            </a>
                          </td>
                          <td className="subcontractor-documentsTableBodyCell subcontractorTableBodyDescriptioncell">
                            <dd>{docs.brief_description}</dd>
                          </td>
                          <td className="text-right subcontractor-documentsTableBodyCell subcontractorTableBodyActioncell">
                            <div className="btn-group serviceAreaActionCellButtons">
                              <>
                                &nbsp; &nbsp;
                                <IconButton
                                  className="subcontractor_doc_delete_btn"
                                  data-cy="entityDeleteButton"
                                  onClick={() => {
                                    handleDeleteSubcontractorDocument(docs.id);
                                  }}
                                >
                                  <img src="content/icons/Icon awesome-trash-alt.png" />
                                </IconButton>
                              </>
                            </div>
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </Table>
            ) : (
              <div className="no-record-warning">No Record found</div>
            )}

            <Modal open={modalOpen} className="file-view-modal">
              <Paper style={{ width: '100%', height: '100%' }}>
                <div style={{ padding: '20px' }}>
                  <IconButton
                    className="downloaANdPrintButton"
                    size="small"
                    style={{ marginLeft: '48%' }}
                    onClick={() => {
                      handleDownloadSubcontractorDocument();
                    }}
                  >
                    <img src="content/images/icons8-download-24.png" />
                  </IconButton>
                  <IconButton
                    className="downloaANdPrintButton"
                    size="small"
                    style={{ marginLeft: '2%' }}
                    onClick={() => {
                      printFile();
                    }}
                  >
                    <img src="content/images/icons8-print-24.png" />
                  </IconButton>
                  <CloseIcon style={{ marginLeft: '40%' }} onClick={handleCloseViewDocModal}></CloseIcon>{' '}
                </div>
                <div className="file-viewer-style">
                  <FileViewer fileType={docdata?.ext} filePath={docdata?.file} />
                </div>
              </Paper>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ subcontractor }: IRootState) => ({
  subcontractorEntity: subcontractor.entity,
  subcontractorDocuments: subcontractor.SubcontractorDocuments,
  documentData: subcontractor.documentData,
});

const mapDispatchToProps = {
  saveSubcontractorDocuments,
  getSubcontractorDocuments,
  deleteSubcontractorDocument,
  getSubDocumentById,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SubcontractorDocuments);
