import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Table } from '@material-ui/core';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import { IRootState } from 'app/shared/reducers';
import { faPlay, faStop } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Tune } from '@material-ui/icons';

interface UserDispatchProps {
  UpdateShifts: (obj, data) => void;
  shiftsList: any;
  setting: any;
}

// tslint:disable-next-line
export interface ITodaysShifts extends UserDispatchProps {
  values: any;
  setPageNumber: (data) => void;
  pageNumber: number;
  reset: any;
  isUser: boolean;
}

const th = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.white,
    },
    body: {
      fontSize: 12,
    },
  })
)(TableCell);

const tr = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(all)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles({
  table: {
    maxWidth: '100vh',
    fontSize: '1.3vh',
  },
});

export const TodaysShift = (props: ITodaysShifts) => {
  const [timezoneFormat, setTimezoneFormat] = useState(props.setting.timezone);
  const [locale, setLocale] = useState(props.setting.time_format);
  const [dateFormat, setdateFormat] = useState(props.setting.date_format);
  const [shifts, setShifts] = useState([]);
  const classes = useStyles();
  const { shiftsList, values } = props;

  useEffect(() => {
    if (shiftsList.length > 0) {
      props.reset();

      const uniqueShiftIds = new Set();

      const uniqueShifts = shiftsList.filter(shift => {
        const shiftId = shift.id.toString();
        if (!uniqueShiftIds.has(shiftId)) {
          uniqueShiftIds.add(shiftId);
          return true;
        }
        return false;
      });

      const shiftsSort = [...uniqueShifts];
      shiftsSort.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));

      setShifts(shiftsSort);
    } else {
      props.reset();
      setShifts(shiftsList);
    }
  }, [shiftsList]);

  useEffect(() => {
    setdateFormat(props.setting.date_format);
    setLocale(props.setting.time_format);
    setTimezoneFormat(props.setting.timezone);
  }, [props.setting]);

  const handleClockIn = (actionStatus, shiftId) => {
    const entity = {
      id: shiftId,
      shiftStatus: actionStatus + 1,
      time: new Date().toISOString(),
    };
    props.setPageNumber(1);
    props.UpdateShifts(entity, values);
  };

  const dateConverter = dateformat => {
    if (dateformat === null) {
      if (dateFormat === 1) {
        return 'DD-MM-YY';
      } else if (dateFormat === 2) {
        return 'DD-MM-YYYY';
      } else if (dateFormat === 3) {
        return 'MM/DD/YY';
      } else if (dateFormat === 4) {
        return 'MM/DD/YYYY';
      } else if (dateFormat === 5) {
        return 'DD.MM.YY';
      } else if (dateFormat === 6) {
        return 'DD.MM.YYYY';
      } else if (dateFormat === 7) {
        return 'YY/MM/DD';
      } else if (dateFormat === 8) {
        return 'YYYY/MM/DD';
      }
    } else {
      if (dateFormat === 1) {
        return moment(dateformat).format('DD-MM-YY');
      } else if (dateFormat === 2) {
        return moment(dateformat).format('DD-MM-YYYY');
      } else if (dateFormat === 3) {
        return moment(dateformat).format('MM/DD/YY');
      } else if (dateFormat === 4) {
        return moment(dateformat).format('MM/DD/YYYY');
      } else if (dateFormat === 5) {
        return moment(dateformat).format('DD.MM.YY');
      } else if (dateFormat === 6) {
        return moment(dateformat).format('DD.MM.YYYY');
      } else if (dateFormat === 7) {
        return moment(dateformat).format('YY/MM/DD');
      } else if (dateFormat === 8) {
        return moment(dateformat).format('YYYY/MM/DD');
      }
    }
  };
  const history = useHistory();
  const handleLocation = shift => {
    history.push({
      pathname: `/location/${shift.id}`,
      state: { shiftData: shift },
    });
  };


  return (
    <div className="dashboard-live-shifts-full-container">
      <div className="todays-shifts-header-div">
        <div className="todays-shifts-header-todays-shift-label">
          <strong>
            {values['option'] === 'DAY' ? dateConverter(values['current_day']) : ''}
            {values['option'] === 'WEEK' ? dateConverter(values['from_date']) + ' - ' + dateConverter(values['to_date']) : ''}
            {values['option'] === 'MONTH' ? dateConverter(values['from_date']) + ' - ' + dateConverter(values['to_date']) : ''}
          </strong>
        </div>
      </div>
      <InfiniteScroll
        dataLength={shifts.length}
        scrollableTarget="scrollableDiv"
        scrollThreshold="200px"
        next={() => {
          props.setPageNumber(props.pageNumber + 1);
        }}
        hasMore={true}
        loader={''}
      >
        <TableContainer id="scrollableDiv" style={{ maxHeight: 360, minHeight: 360 }} component={Paper}>
          <Table stickyHeader className={classes.table} aria-label="customized table">
            <TableHead className="todays-shifts-table-head">
              <tr className="th2">
                <th className="t2 todays_thCells" align="center">
                  Name
                </th>
                <th className="t2 todays_thCells" align="center">
                  Service Area
                </th>
                <th className="t2 todays_thCells" align="center">
                  Sign In
                </th>
                <th className="t2 todays_thCells" align="center">
                  -
                </th>
                <th className="t2 todays_thCells" align="center">
                  Sign Out
                </th>
              </tr>
            </TableHead>
            {shifts && shifts.length > 0 ? (
              <tbody className="todays-shifts-table-body">
                {shifts.map((shift, index) => (
                  <tr className="todays-shifts-table-row" key={index}>
                    <td className="todays-shifts-table-time-cell todays_tdCells" width="30%" text-align="left">
                      <span className="todays-shifts-table-text-span todays-shift-person-name-span">
                        {' '}
                        {shift.person ? shift.person.first_name + ' ' + shift.person.last_name : null}{' '}
                      </span>
                      <span className="todays-shifts-table-text-span todays-shift-position-name-span">
                        {' '}
                        {shift.position ? shift.position.name : null}{' '}
                      </span>
                      <span className="todays-shifts-table-text-span todays-shift-time-text-span" style={{ color: '#808080' }}>
                        {' '}
                        {values['option'] === 'DAY' ? null : dateConverter(shift.date)}
                      </span>
                    </td>
                    <td className="todays-shifts-table-person-cell todays_tdCells" width="30%" text-align="left">
                      <span className="todays-shifts-table-text-span todays-shift-location-text-span">
                        {' '}
                        {shift.location ? shift.location.name : null}{' '}
                      </span>
                      <span className="todays-shifts-table-text-span todays-shift-time-text-span">
                        {moment(shift.shiftStartTime).format(`${locale === 1 ? 'HH:mm' : 'hh:mm A'}`)}
                        {' - '}
                        {moment(shift.shiftEndTime).format(`${locale === 1 ? 'HH:mm' : 'hh:mm A'}`)}
                      </span>
                      <span className="todays-shifts-table-text-span todays-shift-position-name-span"></span>
                      {shift.patrol_frequency !== 0 && shift.status !== 1 ? (
                        <span
                          style={{
                            color: 'blue',
                            fontFamily: 'Helvetica',
                            textDecoration: 'underline',
                            fontStyle: 'italic',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            handleLocation(shift);
                          }}
                        >
                          Live Location
                        </span>
                      ) : null}
                    </td>
                    <td className="c1 todays_tdCells" align="center">
                      {shift.status === 1 ? (
                        <Button
                          variant="outlined"
                          className={'btn1'}
                          onClick={() => {
                            handleClockIn(shift.status, shift.id);
                          }}
                        >
                          <FontAwesomeIcon icon={faPlay} color="white" />
                        </Button>
                      ) : (
                        <span className="span1">{moment(shift.checkIn).format(`${locale === 1 ? 'HH:mm' : 'hh:mm A'}`)}</span>
                      )}
                    </td>
                    <td className="c2 todays_tdCells" align="center">
                      {shift.break ? shift.break : <span>--</span>}
                    </td>
                    <td className="c3 todays_tdCells" align="center">
                      {shift.status === 1 && <span>--</span>}
                      {shift.status === 2 && (
                        <Button
                          className="btn2"
                          onClick={() => {
                            handleClockIn(shift.status, shift.id);
                          }}
                        >
                          <FontAwesomeIcon icon={faStop} color="white" />
                        </Button>
                      )}
                      {(shift.status === 3 || shift.status === 4) && (
                        <span className="span2"> {moment(shift.checkOut).format(`${locale === 1 ? 'HH:mm' : 'hh:mm A'}`)} </span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <div className="no-record-warning">No Shifts found</div>
            )}
          </Table>
        </TableContainer>
      </InfiniteScroll>
    </div>
  );
};
