import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IShift, defaultValue } from 'app/shared/model/shift.model';
import { toast } from 'react-toastify';

export const ACTION_TYPES = {
  FETCH_SHIFT_LIST: 'shift/FETCH_SHIFT_LIST',
  FETCH_SHIFT: 'shift/FETCH_SHIFT',
  CREATE_SHIFT: 'shift/CREATE_SHIFT',
  UPDATE_SHIFT: 'shift/UPDATE_SHIFT',
  PARTIAL_UPDATE_SHIFT: 'shift/PARTIAL_UPDATE_SHIFT',
  DELETE_SHIFT: 'shift/DELETE_SHIFT',
  FETCH_DELETED_SHIFTS: 'shift/FETCH_DELETED_SHIFTS',
  RESET: 'shift/RESET',
  FETCH_LOCATION_DATA: 'shift/FETCH_LOCATION_DATA',
  FETCH_CHECKPOINTLOGS_DATA: 'shift/FETCH_CHECKPOINTLOGS_DATA',
  FETCH_PATROL_TRACK_SUMMARY_DATA: 'shift/FETCH_PATROL_TRACK_SUMMARY_DATA',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IShift>,
  deleted_shifts: [] as ReadonlyArray<IShift>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  rescheduleSuccess: false,
  locationData: [],
  checkpointLogs: [],
};

export type ShiftState = Readonly<typeof initialState>;

// Reducer

export default (state: ShiftState = initialState, action): ShiftState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_CHECKPOINTLOGS_DATA):
      return {
        ...state,
        checkpointLogs: action.payload,
      };
    case REQUEST(ACTION_TYPES.FETCH_PATROL_TRACK_SUMMARY_DATA):
      return {
        ...state,
        checkpointLogs: action.payload,
      };
    case REQUEST(ACTION_TYPES.FETCH_LOCATION_DATA):
      return {
        ...state,
        locationData: action.payload,
      };
    case REQUEST(ACTION_TYPES.FETCH_SHIFT_LIST):
    case REQUEST(ACTION_TYPES.FETCH_SHIFT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };

    case REQUEST(ACTION_TYPES.FETCH_DELETED_SHIFTS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_SHIFT):
    case REQUEST(ACTION_TYPES.UPDATE_SHIFT):
    case REQUEST(ACTION_TYPES.DELETE_SHIFT):
    case REQUEST(ACTION_TYPES.PARTIAL_UPDATE_SHIFT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_SHIFT_LIST):
    case FAILURE(ACTION_TYPES.FETCH_SHIFT):
    case FAILURE(ACTION_TYPES.FETCH_DELETED_SHIFTS):
    case FAILURE(ACTION_TYPES.CREATE_SHIFT):
    case FAILURE(ACTION_TYPES.UPDATE_SHIFT):
    case FAILURE(ACTION_TYPES.PARTIAL_UPDATE_SHIFT):
    case FAILURE(ACTION_TYPES.FETCH_LOCATION_DATA):
    case FAILURE(ACTION_TYPES.DELETE_SHIFT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_SHIFT_LIST):
      return {
        ...state,
        loading: false,
        entities: [...state.entities, ...action.payload.data],
      };
    case SUCCESS(ACTION_TYPES.FETCH_SHIFT):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_CHECKPOINTLOGS_DATA):
      return {
        ...state,
        loading: false,
        checkpointLogs: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_PATROL_TRACK_SUMMARY_DATA):
      return {
        ...state,
        loading: false,
        checkpointLogs: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_LOCATION_DATA):
      return {
        ...state,
        loading: false,
        locationData: action.payload.data.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_DELETED_SHIFTS):
      return {
        ...state,
        loading: false,
        deleted_shifts: [...state.deleted_shifts, ...action.payload.data],
      };
    case SUCCESS(ACTION_TYPES.CREATE_SHIFT):
    case SUCCESS(ACTION_TYPES.UPDATE_SHIFT):
    case SUCCESS(ACTION_TYPES.PARTIAL_UPDATE_SHIFT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
        rescheduleSuccess: true,
      };
    case SUCCESS(ACTION_TYPES.DELETE_SHIFT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/shift';
const apiLocation = 'api/location';
const apiCheckpointLogs = 'api/checkpointlog';

// Actions

export const fetchCheckpointLogs = shiftId => {
  const requestUrl = `${apiCheckpointLogs}/${shiftId}`;
  return {
    type: ACTION_TYPES.FETCH_CHECKPOINTLOGS_DATA,
    payload: axios.get(requestUrl),
  };
};

export const fetchPatrolTrackSummary = shiftId => {
  const requestUrl = `/api/patrolTrackSummary/${shiftId}`;
  return {
    type: ACTION_TYPES.FETCH_PATROL_TRACK_SUMMARY_DATA,
    payload: axios.get(requestUrl),
  };
};

export const fetchLocationData = shiftId => {
  const requestUrl = `${apiLocation}/${shiftId}`;
  return {
    type: ACTION_TYPES.FETCH_LOCATION_DATA,
    payload: axios.get(requestUrl),
  };
};

export const getEntities = data => ({
  type: ACTION_TYPES.FETCH_SHIFT_LIST,
  payload: axios.get<IShift>(
    `api/shiftsList?filter[from_shifts]=${data.from_date}&filter[to_shifts]=${
      data.to_date
    }&filter[live_shifts]=&cacheBuster=${new Date().getTime()}&page=${data.pageNumber}`
  ),
});

export const getEntity: ICrudGetAction<IShift> = shiftId => {
  const requestUrl = `${apiUrl}/${shiftId}`;
  return {
    type: ACTION_TYPES.FETCH_SHIFT,
    payload: axios.get<IShift>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IShift> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_SHIFT,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(reset());
  // dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IShift> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_SHIFT,
    payload: axios.put(`${apiUrl}/${entity.shiftId}`, cleanEntity(entity)),
  });
  return result;
};

export const partialUpdate: ICrudPutAction<IShift> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.PARTIAL_UPDATE_SHIFT,
    payload: axios.patch(`${apiUrl}/${entity.shiftId}`, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity = (shiftId, reason, filterValues) => dispatch => {
  axios
    .delete(`${apiUrl}/${shiftId}`, { data: reason })
    .then(res => {
      dispatch({
        type: ACTION_TYPES.DELETE_SHIFT,
        payload: res.data,
      });
      dispatch(reset());
      dispatch(getEntities(filterValues));
    })
    .catch(err => {});
};

export const getDeletedShifts = (data, pageNumber) => {
  const requestUrl = 'api/getDeletedShifts';
  return {
    type: ACTION_TYPES.FETCH_DELETED_SHIFTS,
    payload: axios.get<IShift>(
      `api/getDeletedShifts?include=person&filter[person_id]=${data.people}&filter[from_shifts]=${data.from_date}&filter[to_shifts]=${data.to_date}&page=${pageNumber}`
    ),
  };
};

const rescheduleApi = 'api/rescheduleDeletedShift';
export const rescheduleShift = (shiftId, values) => async dispatch => {
  const requestUrl = `${rescheduleApi}/${shiftId}`;
  const result = await dispatch({
    type: ACTION_TYPES.PARTIAL_UPDATE_SHIFT,
    payload: axios.patch(requestUrl),
  });
  dispatch(reset());
  dispatch(getDeletedShifts(values, ''));
  toast.success('Shift Rescheduled Successfully');
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
