import axios from 'axios';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { ITimeclock, defaultValue } from 'app/shared/model/timeclock.model';
import { Dispatch } from 'redux';

export const ACTION_TYPES = {
  FETCH_SHIFTS_LIST: 'shifts/FETCH_SHIFTS_LIST',
  FETCH_SHIFT: 'shifts/FETCH_SHIFT',
  UPDATE_SHIFT: 'shifts/UPDATE_SHIFT',
  APPROVE_ALL_SHIFT: 'shifts/APPROVE_ALL_SHIFT',
  ABNORMAL_COMPLETED_SHIFT: 'shifts/ABNORMAL_COMPLETED_SHIFT',
  UPDATE_SHIFTS_BREAKS: 'shifts/UPDATE_BREAKS',
  START_SHIFTS_BREAKS: 'shifts/START_BREAK',
  END_SHIFTS_BREAKS: 'shifts/END_BREAK',
  FETCH_TIME_CLOCK_SHIFTS_LIST: 'shifts/TIME_CLOCK_SHIFTS',
  UPDATE_SINGLE_SHIFT_CLOCKS: 'shifts/UPDATE_SINGLE_SHIFT_CLOCKS',
  RESET: 'shifts/RESET',
  FETCH_SHIFT_APPROVAL_HISTORY: 'shifts/FETCH_SHIFT_APPROVAL_HISTORY',
  FETCH_SHIFT_COUNTS: 'shifts/FETCH_SHIFT_COUNTS',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ITimeclock>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  shiftApprovalHistory: [],
  shiftCounts: [],
};

export type TimeclockState = Readonly<typeof initialState>;

// Reducer

export default (state: TimeclockState = initialState, action): TimeclockState => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_SHIFT_APPROVAL_HISTORY:
      return {
        ...state,
        loading: false,
        shiftApprovalHistory: action.payload,
      };
    case ACTION_TYPES.FETCH_SHIFT_COUNTS:
      return {
        ...state,
        loading: false,
        shiftCounts: action.payload,
    };
    case REQUEST(ACTION_TYPES.FETCH_SHIFTS_LIST):
    case REQUEST(ACTION_TYPES.FETCH_TIME_CLOCK_SHIFTS_LIST):
    case REQUEST(ACTION_TYPES.FETCH_SHIFT_APPROVAL_HISTORY):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        shiftApprovalHistory: action.payload,
      };
    case REQUEST(ACTION_TYPES.FETCH_SHIFT_COUNTS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        shiftCounts: action.payload,
      };    
    case REQUEST(ACTION_TYPES.UPDATE_SHIFT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.START_SHIFTS_BREAKS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.END_SHIFTS_BREAKS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.APPROVE_ALL_SHIFT):
    case FAILURE(ACTION_TYPES.ABNORMAL_COMPLETED_SHIFT):
    case FAILURE(ACTION_TYPES.FETCH_SHIFTS_LIST):
    case FAILURE(ACTION_TYPES.FETCH_TIME_CLOCK_SHIFTS_LIST):
    case FAILURE(ACTION_TYPES.FETCH_SHIFT):
    case FAILURE(ACTION_TYPES.FETCH_SHIFT_APPROVAL_HISTORY):
    case FAILURE(ACTION_TYPES.FETCH_SHIFT_COUNTS):
    case FAILURE(ACTION_TYPES.UPDATE_SHIFT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_SHIFTS_LIST):
      return {
        ...state,
        loading: false,

        entities: [...state.entities, ...(action.payload.data?.shifts || [])],
      };
    case SUCCESS(ACTION_TYPES.FETCH_TIME_CLOCK_SHIFTS_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data.shifts,
      };
    case SUCCESS(ACTION_TYPES.FETCH_SHIFT):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.APPROVE_ALL_SHIFT):
    case SUCCESS(ACTION_TYPES.UPDATE_SHIFT):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.UPDATE_SHIFTS_BREAKS):
      return {
        ...state,
        loading: false,
      };
    case SUCCESS(ACTION_TYPES.START_SHIFTS_BREAKS):
      return {
        ...state,
        loading : false,
      };  
    case SUCCESS(ACTION_TYPES.END_SHIFTS_BREAKS):
      return {
        ...state,
        loading : false,
      };  
    case SUCCESS(ACTION_TYPES.ABNORMAL_COMPLETED_SHIFT):
      return {
        ...state,
        loading: false,
      };

    case SUCCESS(ACTION_TYPES.UPDATE_SINGLE_SHIFT_CLOCKS):
      return {
        ...state,
        loading: false,
      };

    case SUCCESS(ACTION_TYPES.FETCH_SHIFT_APPROVAL_HISTORY):
      return {
        ...state,
        loading: false,
        shiftApprovalHistory: action.payload,
      };

    case SUCCESS(ACTION_TYPES.FETCH_SHIFT_COUNTS):
      return {
        ...state,
        loading: false,
        shiftCounts: action.payload,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrlLiveShifts = 'api/liveShifts';

// Actions

export const getTimeClockShifts = (data, pageNo) => ({
  type: ACTION_TYPES.FETCH_SHIFTS_LIST,
  payload: axios.get<ITimeclock>(
    `${apiUrlLiveShifts}?include=person&filter[person_id]=${data.people}&filter[designation_id]=${data.position}&filter[service_area.client_id]=${data.client}&filter[service_area_id]=${data.location}
      &filter[from_shifts]=${data.from_date}&filter[to_shifts]=${data.to_date}&filter[live_shifts]=${data.current_day}&filter[department_for_person]=${data.department}&page=${pageNo}
      &filter[shift_type]=${data.shiftType}`
  ),
});

export const getInfinteTimeClockShifts = (data, pageNo) => ({
  type: ACTION_TYPES.FETCH_TIME_CLOCK_SHIFTS_LIST,
  payload: axios.get<ITimeclock>(
    `${apiUrlLiveShifts}?include=person&filter[person_id]=${data.people}&filter[designation_id]=${data.position}&filter[service_area.client_id]=${data.client}&filter[service_area_id]=${data.location}
      &filter[from_shifts]=${data.from_date}&filter[to_shifts]=${data.to_date}&filter[live_shifts]=${data.current_day}&filter[department_for_person]=${data.department}&page=${pageNo}
      &filter[shift_type]=${data.shiftType}`
  ),
});

const apiUrl = 'api/shift';
const approveAllShiftsApiUrl = 'api/shift/approveAll';
const abnormalCompletedShiftsApiUrl = 'api/abnormalCompletedShifts';
const clockUrl = 'api/shiftClocks';
const breakUrl = 'api/shiftBreak';
const startBreak = 'api/shiftBreak';

export const updateShift = (entity, values) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_SHIFT,
    payload: axios.put(`${apiUrl}/${entity.id}`, cleanEntity(entity)),
  });
  return result;
};

export const approveAllShifts = (entity, values) => async dispatch => {
  try {
    const result = await dispatch({
      type: ACTION_TYPES.APPROVE_ALL_SHIFT,
      payload: axios.post(`${approveAllShiftsApiUrl}`, cleanEntity(entity)),
    });

    dispatch(getInfinteTimeClockShifts(values, ''));
    return result; // Ensure returning the data part of the result
  } catch (error) {
    console.error("Error in approveAllShifts action:", error);
    throw error; // Rethrow the error for the calling function to handle
  }
};

export const abnormalCompletedShifts = () => async (dispatch: Dispatch<any>) => {
  try {
    const res = await axios.get(`${abnormalCompletedShiftsApiUrl}`);
    dispatch({
      type: ACTION_TYPES.ABNORMAL_COMPLETED_SHIFT,
      payload: res.data,
    });
    return res.data; 
  } catch (err) {
    console.error('Error fetching abnormal shifts:', err);
    throw err; // Re-throw the error to handle in the component or elsewhere
  }
};

export const updateSingleShiftClocks = (singleClockData, values) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_SINGLE_SHIFT_CLOCKS,
    payload: axios.put(`${clockUrl}/${singleClockData.id}`, cleanEntity(singleClockData.data)),
  });
  return result;
};

export const updateShiftBreak = (singleBreakData, values) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_SHIFTS_BREAKS,
    payload: axios.put(`${breakUrl}/${singleBreakData.id}`, cleanEntity(singleBreakData.data)),
  });

  dispatch(getInfinteTimeClockShifts(values, ''));
  return result;
};

export const startShiftBreak = (value, values) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.START_SHIFTS_BREAKS,
    payload: axios.post(`${startBreak}`, cleanEntity(value)),
  });
  return result;
};

export const endShiftBreak = (breakRecord, entity, values) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.END_SHIFTS_BREAKS,
    payload: axios.put(`${startBreak}/${breakRecord.id}`, cleanEntity(entity)),
  });

  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

export const getShiftApprovalHistory = id => dispatch => {
  axios
    .get(`${'api/getShiftApprovalHistory'}/${id}`)
    .then(res => {
      dispatch({
        type: ACTION_TYPES.FETCH_SHIFT_APPROVAL_HISTORY,
        payload: res.data,
      });
    })
    .catch(err => {});
};


export const getShiftCountsAgainstServiceAreaId = id => dispatch => {
  axios
    .get(`${'api/getShiftDetailsByServiceAreaId'}/${id}`)
    .then(res => {
      dispatch({
        type: ACTION_TYPES.FETCH_SHIFT_COUNTS,
        payload: res.data,
      });
    })
    .catch(err => {});
};
