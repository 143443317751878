import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';

import { useHistory, useLocation } from 'react-router';
import { ThemeProvider } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RoomIcon from '@material-ui/icons/Room';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import { faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons';
import PersonSettingTextToggler from './person-settings-text-toggle';
import Switch from '@material-ui/core/Switch';
import IOSSwitch from 'app/modules/Setting/RolesAndPermissionsSettings/Components/IOSswitchComponent';

const useStyles = makeStyles(theme => ({
  selectBox: {
    minWidth: '35vh',
    margin: 'auto',
    marginTop: '2vh',
    paddingTop: '1vh',
  },
  textBox: {
    minWidth: 200,
    margin: 'auto',
    marginTop: '1vh',
    paddingTop: '1vh',
  },
  buttons: {
    minWidth: 100,
    marginTop: '3vh',
    marginRight: '2vh',
    float: 'right',
  },
  sideBySide: {
    display: 'flex',
    flexDirection: 'row',
  },
  drawer: {
    width: '18%',
  },
  drawerPaper: {
    width: '18%',
    position: 'absolute',
    marginTop: '1vh',
    marginLeft: 0,
    zIndex: 1,
  },
  active: {
    background: '#F4F4F4',
  },
  title: {
    textAlign: 'left',
    marginLeft: '10vh',
  },
  mainTitle: {
    padding: theme.spacing(2),
    marginLeft: '3.2vh',
    textAlign: 'left',
    color: '#6610f2',
    fontWeight: 450,
  },
  flexContainer: {
    display: 'flex',
    background: 'linear-gradient(to top, #FFFFFF 50%, #EBECF0 50%)',
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  detailNote: {
    width: '100%',
  },
}));

const PersonSettingComponent = props => {
  const [open, setOpen] = useState(false);
  const [details, setDetails] = useState('');
  const classes = useStyles();
  const history = useHistory();

  const modalNotesChange = event => {
    setDetails(event.target.value.toString().trim());
  };

  return (
    <div className="shift-asign-switch-button-div">
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>{props.title}</span>
        <span>
          <IOSSwitch
            disabled={props.showEditPage === false ? true : false}
            name="isCheckedA"
            checked={props.value}
            onChange={event => props.handleNotificationSettings(event, props.name)}
          />
        </span>
      </div>
      <Divider style={{ marginBottom: '10px', marginTop: '10px' }}></Divider>
    </div>
  );
};

export default PersonSettingComponent;
