import React, { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Grid, TextField, Button } from '@material-ui/core';
import { GoogleMap, Marker, Polyline } from 'react-google-maps';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import { Combobox, ComboboxInput, ComboboxPopover, ComboboxList, ComboboxOption } from '@reach/combobox';
import '@reach/combobox/styles.css';
import withScriptjs from 'react-google-maps/lib/withScriptjs';
import withGoogleMap from 'react-google-maps/lib/withGoogleMap';

export default function GoogleMapsComponent(props) {
  const PlacesAutocomplete = ({ setSelected, setCenter }) => {
    const {
      ready,
      value,
      setValue,
      suggestions: { status, data },
      clearSuggestions,
    } = usePlacesAutocomplete();

    const handleSelect = async address => {
      setValue(address, false);
      clearSuggestions();

      const results = await Promise.resolve(getGeocode({ address }));
      const { lat, lng } = await Promise.resolve(getLatLng(results[0]));
      setSelected({ lat, lng });
      setCenter({ lat, lng });
      props.setMarkOnEnteringAddress(lat, lng);
    };

    return (
      <Combobox onSelect={handleSelect}>
        <ComboboxInput value={value} onChange={e => setValue(e.target.value)} disabled={!ready} placeholder="Search an address" />
        <ComboboxPopover>
          <ComboboxList>
            {status === 'OK' && data.map(({ place_id, description }) => <ComboboxOption key={place_id} value={description} />)}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    );
  };

  const Map = () => {
    return (
      <>
        {props.center && (
          <GoogleMap defaultZoom={13} defaultCenter={props.center} onClick={props.setMark}>
            {props.coordinates.map((marks, index) => (
              <>
                <Marker
                  key={index}
                  position={marks}
                  icon={{
                    url: `./content/images/${index + 1}.svg`,
                    anchor: new google.maps.Point(17, 46),
                    scaledSize: new google.maps.Size(37, 37),
                  }}
                />
                <Polyline key={index} path={props.coordinates} />
              </>
            ))}
          </GoogleMap>
        )}
      </>
    );
  };
  const WrappedMap: any = withScriptjs(withGoogleMap(Map));
  const [selected, setSelected] = useState(null);
  return (
    <>
      <div style={{ width: '100%', height: '500px' }}>
        <div className="places-container">
          <PlacesAutocomplete setSelected={setSelected} setCenter={props.setCenter} />
          <Button className="delete-marks-button" onClick={props.deleteMarks}>
            Delete Marks{' '}
          </Button>
        </div>
        <div style={{ width: '100%', height: '95%', marginBottom: '5%' }}>
          <WrappedMap
            googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`}
            containerElement={<div style={{ height: '100%' }} />}
            mapElement={<div style={{ height: '100%' }} />}
            loadingElement={<div style={{ height: '100%' }} />}
          />
        </div>
      </div>
    </>
  );
}
