import React from 'react';
// import { DropdownItem } from 'reactstrap';
import MenuItem from '@material-ui/core/MenuItem';
import { NavLink as Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface IMenuItem {
  icon: IconProp;
  to: string;
  id?: string;
  children?: React.ReactNode;
  'data-cy'?: string;
}

export default class MenuITem extends React.Component<IMenuItem> {
  render() {
    const { to, icon, id, children, 'data-cy': string } = this.props;

    return (
      <MenuItem component={Link} to={to} id={id} data-cy={this.props['data-cy']}>
        <FontAwesomeIcon icon={icon} fixedWidth /> {children}
      </MenuItem>
    );
  }
}
