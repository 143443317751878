import axios from 'axios';
import { Storage, ICrudGetAllAction } from 'react-jhipster';
import { IOrganizationQualification } from 'app/shared/model/organization-qualification.model';
import moment from 'moment-timezone';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { IIncident } from 'app/shared/model/incident';
import { IIncidentType } from 'app/shared/model/incident';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { toast, ToastContainer } from 'react-toastify';

export const ACTION_TYPES = {
  STORE_INCIDENT: 'incident/STORE_ORGANIZATION_QUALIFICATION',
  FETCH_INCIDENT_LIST: 'incident/GET_INCIDENT_LIST',
  FETCH_INCIDENT: 'incident/GET_INCIDENT',
  FETCH_INCIDENT_TYPE_LIST: 'incident/GET_INCIDENT_TYPE_LIST',
  FETCH_ATTACHMENT_TYPE_LIST: 'incident/GET_ATTACHMENT_TYPE_LIST',
  UPDATE_INCIDENT: 'incident/UPDATE_INCIDENT',
  PARTIAL_UPDATE_INCIDENT: 'incident/PARTIAL_UPDATE_INCIDENT',
  STORE_INCIDENT_ATTACHMENTS: 'incident/STORE_INCIDENT_ATTACHMENTS',
  RESET: 'incident/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  updateSuccess: false,
  successfullyCreated: false,
  incident: [] as Array<IIncident>,
  incident_list: [] as Array<IIncident>,
  incident_type_list: [] as Array<IIncidentType>,
  attachment_list: [],
};

export type IncidentState = Readonly<typeof initialState>;

export default (state: IncidentState = initialState, action): IncidentState => {
  switch (action.type) {
    case ACTION_TYPES.STORE_INCIDENT:
      return {
        ...state,
        incident: action.payload,
      };

    case REQUEST(ACTION_TYPES.STORE_INCIDENT):
      return {
        ...state,
        loading: true,
        errorMessage: null,
        updateSuccess: false,
      };

    case SUCCESS(ACTION_TYPES.STORE_INCIDENT):
      return {
        ...state,
        updateSuccess: true,
        loading: false,
        incident: action.payload.data,
      };

    case ACTION_TYPES.FETCH_INCIDENT_LIST:
      return {
        ...state,
        incident_list: action.payload.data,
      };

    case REQUEST(ACTION_TYPES.FETCH_INCIDENT_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };

    case SUCCESS(ACTION_TYPES.FETCH_INCIDENT_LIST):
      return {
        ...state,
        loading: false,
        incident_list: action.payload.data,
      };

    case ACTION_TYPES.FETCH_INCIDENT_TYPE_LIST:
      return {
        ...state,
        incident_type_list: action.payload.data,
      };

    case REQUEST(ACTION_TYPES.FETCH_INCIDENT_TYPE_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };

    case SUCCESS(ACTION_TYPES.FETCH_INCIDENT_TYPE_LIST):
      return {
        ...state,
        loading: false,
        incident_type_list: action.payload.data,
      };

    case ACTION_TYPES.FETCH_INCIDENT:
      return {
        ...state,
        incident: action.payload.data,
      };

    case REQUEST(ACTION_TYPES.FETCH_INCIDENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };

    case SUCCESS(ACTION_TYPES.FETCH_INCIDENT):
      return {
        ...state,
        loading: false,
        incident: action.payload.data,
      };

    case ACTION_TYPES.FETCH_ATTACHMENT_TYPE_LIST:
      return {
        ...state,
        attachment_list: action.payload.data,
      };

    case REQUEST(ACTION_TYPES.FETCH_ATTACHMENT_TYPE_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };

    case SUCCESS(ACTION_TYPES.FETCH_ATTACHMENT_TYPE_LIST):
      return {
        ...state,
        loading: false,
        attachment_list: action.payload.data,
      };

    case REQUEST(ACTION_TYPES.PARTIAL_UPDATE_INCIDENT):
      return {
        ...state,
        errorMessage: null,
        loading: true,
        updateSuccess: false,
      };

    case SUCCESS(ACTION_TYPES.PARTIAL_UPDATE_INCIDENT):
      return {
        ...state,
        updateSuccess: true,
        loading: false,
        incident: action.payload.data,
      };

    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrlIncident = 'api/incident';
export const createIncidentReport = values => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.STORE_INCIDENT,
    payload: axios.post(`${apiUrlIncident}`, values),
  });

  toast.success('Incident Report Created Successfully');
  return result;
};

export const updateIncident = (entity, id) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.PARTIAL_UPDATE_INCIDENT,
    payload: axios.patch(`api/incident/${id}`, entity),
  });

  toast.success('Incident Report Updated Successfully');
  return result;
};

export const saveIncidentAttachments = files => dispatch => {
  axios
    .post(`api/incident-attachment`, files, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(res => {})
    .catch(err => {});
};

export const getIncidents = data => ({
  type: ACTION_TYPES.FETCH_INCIDENT_LIST,
  payload: axios.get(
    `api/incident?filter[created_by]=${data.people}&filter[service_area_id]=${data.location}&filter[from_incidents]=${data.from_date}&filter[to_incidents]=${data.to_date}`
  ),
  loading: true,
});

export const getIncidentById = id => ({
  type: ACTION_TYPES.FETCH_INCIDENT,
  payload: axios.get(`api/incident/${id}`),
});

export const getIncidentTypes = () => ({
  type: ACTION_TYPES.FETCH_INCIDENT_TYPE_LIST,
  payload: axios.get(`api/incident-type`),
});

export const getAttachmentTypes = () => ({
  type: ACTION_TYPES.FETCH_ATTACHMENT_TYPE_LIST,
  payload: axios.get(`api/attachment-type`),
});

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});