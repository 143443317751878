import { ICountryAdminUnit } from 'app/shared/model/country-admin-unit.model';

export interface IAddress {
  id?: number;
  addressLineOne?: string | null;
  address_line_one?: string | null;
  addressLineTwo?: string | null;
  addressUnitIdentifierValue?: string | null;
  countryAdminUnit?: ICountryAdminUnit | null;
}

export const defaultValue: Readonly<IAddress> = {};
