import React from 'react';
import PermissionsRowComponent from './PermissionsRowComponent';

export default function PermissionsPayRunComponent(props) {
  return (
    <React.Fragment>
      <PermissionsRowComponent
        msg="Can see Pay Run?"
        ls={props.supervisorPerm && props.supervisorPerm.can_see_pay_run}
        ds={props.userPerm && props.userPerm.can_see_pay_run}
        ma={props.mngrPerm && props.mngrPerm.can_see_pay_run}
        sa={props.orgAdminPerm && props.orgAdminPerm.can_see_pay_run}
        name="can_see_pay_run"
        handler={props.handleChange}
      />
      <PermissionsRowComponent
        msg="Can add Pay lock?"
        ls={props.supervisorPerm && props.supervisorPerm.can_add_pay_lock}
        ds={props.userPerm && props.userPerm.can_add_pay_lock}
        ma={props.mngrPerm && props.mngrPerm.can_add_pay_lock}
        sa={props.orgAdminPerm && props.orgAdminPerm.can_add_pay_lock}
        name="can_add_pay_lock"
        handler={props.handleChange}
      />
      <PermissionsRowComponent
        msg="Can remove Pay lock??"
        ls={props.supervisorPerm && props.supervisorPerm.can_remove_pay_lock}
        ds={props.userPerm && props.userPerm.can_remove_pay_lock}
        ma={props.mngrPerm && props.mngrPerm.can_remove_pay_lock}
        sa={props.orgAdminPerm && props.orgAdminPerm.can_remove_pay_lock}
        name="can_remove_pay_lock"
        handler={props.handleChange}
      />
    </React.Fragment>
  );
}
