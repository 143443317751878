import React from 'react';
import IOSSwitch from '../../RolesAndPermissionsSettings/Components/IOSswitchComponent';

const PeopleEmploymentComponent = props => {
  return (
    <>
      <tr>
        <td>
          <IOSSwitch />
        </td>
        <td style={{ textAlign: 'left' }}>
          <a href="#" style={{ marginLeft: '10px' }}>
            {' '}
            {props.msg}{' '}
          </a>
        </td>
        <td></td>
        <td>
          <a style={{ color: 'gray', marginLeft: '15px' }}> {props.msg2} </a>
        </td>
        <td>
          <a style={{ color: 'gray' }}> {props.msg3} </a>
        </td>
      </tr>
    </>
  );
};

export default PeopleEmploymentComponent;
