import dayjs from 'dayjs';
import { IDesignationQualificationType } from 'app/shared/model/designation-qualification-type.model';
import { IDesignation } from 'app/shared/model/designation.model';

export interface IDesignationQualification {
  id?: number;
  name?: string | null;
  value?: string | null;
  issue_date?: string | null;
  expiry_date?: string | null;
  designationQualificationType?: IDesignationQualificationType | null;
  designations?: IDesignation[] | null;
  status?: string | null;
}

export const defaultValue: Readonly<IDesignationQualification> = {};
