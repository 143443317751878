import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { IRootState } from 'app/shared/reducers';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { FormControlLabel, Grid, FormControl, RadioGroup, Radio, FormLabel } from '@material-ui/core';
import './data-delete.scss';
import axios from 'axios';
import { userDataDelete } from 'app/shared/reducers/authentication';
import { ToastContainer, toast } from 'react-toastify';

export interface ILoginProps extends StateProps, DispatchProps, RouteComponentProps<any> {}

export const DataDeleteRequest = props => {
  const [values, setValues] = useState([]);

  const setNewValues = e => {
    setValues(oldValues => ({ ...oldValues, [e.target.name]: e.target.value }));
  };

  const handleSubmitButton = async event => {
    const result = await userDataDelete(values);
    if (result.status === 200) {
      toast.success('Request Succesfully submitted. Our team will get back to you in 24 hours');
      clearValues();
    } else {
      toast.error('Request Failed');
    }
  };

  const clearValues = () => {
    setValues([]);
  };

  return (
    <div style={{ marginTop: '0px' }}>
      <div className="data-delete-header-div">
        <div style={{ marginLeft: '100px' }}>
          <img src="../../../content/images/logo-2.png" className="delete-page-logo" />
        </div>
        <div className="data-delete-visit-website">
          <a target="_blank" rel="noopener noreferrer" href="https://smartworkforce.co.uk/">
            Visit Website
          </a>
        </div>
      </div>

      <div className="data-delete-form">
        <div style={{ fontWeight: 'bold', marginBottom: '50px' }}>
          We would appreciate your help and ask that you kindly complete the below questionnaire as soon as possible!
        </div>

        <Grid container spacing={2}>
          <Grid item xs={6} md={5} sm={6} lg={6} justify="flex-start">
            <TextField
              name="first_name"
              value={values['first_name'] ? values['first_name'] : ''}
              onChange={setNewValues}
              label="First Name"
              placeholder="First Name"
              variant="outlined"
              fullWidth
            />
          </Grid>

          <Grid item xs={6} md={5} sm={6} lg={6} justify="flex-start">
            <TextField
              name="last_name"
              variant="outlined"
              label="Last Name"
              fullWidth
              onChange={setNewValues}
              value={values['last_name'] ? values['last_name'] : ''}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6} md={6} sm={6} lg={6} justify="flex-start">
            <FormControl style={{ marginTop: '16px' }} className="delete-form-fields">
              <TextField
                name="email"
                variant="outlined"
                label="Email"
                fullWidth
                onChange={setNewValues}
                value={values['email'] ? values['email'] : ''}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid item xs={12} md={8} sm={12}>
          <FormControl component="fieldset" className="person-fields">
            <FormLabel component="legend" style={{ color: 'black' }}>
              Are you sure you want to delete your data? This action cannot be undone
            </FormLabel>
            <RadioGroup
              aria-label="gender"
              name="are_you_sure"
              className="personRadioButtons"
              value={values['are_you_sure'] ? values['are_you_sure'] : ''}
              onChange={setNewValues}
            >
              <FormControlLabel value="true" control={<Radio style={{ color: '#4620B7' }} />} label="Yes" />
              <FormControlLabel value="false" control={<Radio style={{ color: '#4620B7' }} />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6} sm={12}>
          <FormControl component="fieldset" className="person-fields">
            <FormLabel component="legend" style={{ color: 'black' }}>
              What would you like to delete ?
            </FormLabel>

            <RadioGroup
              aria-label="gender"
              name="specific_data"
              className="personRadioButtons"
              value={values['specific_data'] ? values['specific_data'] : ''}
              onChange={setNewValues}
            >
              <FormControlLabel value="true" control={<Radio style={{ color: '#4620B7' }} />} label="Specific Data" />
              <FormControlLabel value="false" control={<Radio style={{ color: '#4620B7' }} />} label="Complete Account" />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={12} sm={12} lg={12} justify="flex-start">
          <FormControl style={{ marginTop: '16px' }} className="delete-form-fields">
            <FormLabel component="legend" style={{ color: 'black' }}>
              Reason(s) to Delete*
            </FormLabel>
            <TextField
              name="reason"
              variant="outlined"
              multiline
              rows={6}
              fullWidth
              onChange={setNewValues}
              value={values['reason'] ? values['reason'] : ''}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <FormControl className="data-delete-save-button">
            <Button
              id="save-entity"
              data-cy="entityCreateSaveButton"
              className="submit-delete-data-button"
              type="submit"
              onClick={handleSubmitButton}
            >
              &nbsp; Submit
            </Button>
          </FormControl>
        </Grid>
      </div>
    </div>
  );
};

const mapStateToProps = ({ authentication }: IRootState) => ({});

const mapDispatchToProps = { userDataDelete };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(DataDeleteRequest);
