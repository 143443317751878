import { IEducationalInstituteType } from 'app/shared/model/educational-institute-type.model';
import { ICity } from 'app/shared/model/city.model';

export interface IEducationalInstitute {
  id?: number;
  name?: string | null;
  educationInstituteType?: IEducationalInstituteType | null;
  city?: ICity | null;
}

export const defaultValue: Readonly<IEducationalInstitute> = {};
