import { ExportPDF } from '../components/downloadPDFs/exportPDF';

export const designationConvertToPDF = jsonData => {
  const header = ['Position Name'];

  const headers = [header];
  const title = 'List of Positions';
  const data = jsonData.map(row => [row.name]);
  const filename = 'List of Positions';
  ExportPDF(headers, title, data, filename);
};
