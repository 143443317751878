import axios from 'axios';
import { translate } from 'react-jhipster';

import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { ToastContainer, toast } from 'react-toastify';
import { IRegisterProps } from './register';

export const ACTION_TYPES = {
  CREATE_ACCOUNT: 'register/CREATE_ACCOUNT',
  SEND_VERIFICATION_EMAIL: 'register/SEND_VERIFICATION_EMAIL',
  GET_SELF_REGISTER_USERS: 'register/GET_SELF_REGISTER_USERS',
  RESET: 'register/RESET',
};

const initialState = {
  loading: false,
  registrationSuccess: false,
  registrationFailure: false,
  errorMessage: null,
  user_email: [] as any,
};

export type RegisterState = Readonly<typeof initialState>;

// Reducer
export default (state: RegisterState = initialState, action): RegisterState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.CREATE_ACCOUNT):
      return {
        ...state,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.CREATE_ACCOUNT):
      return {
        ...initialState,
        registrationFailure: true,
        errorMessage: action.payload.response.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_ACCOUNT):
      return {
        ...initialState,
        registrationSuccess: true,
      };
    case REQUEST(ACTION_TYPES.SEND_VERIFICATION_EMAIL):
      return {
        ...state,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.SEND_VERIFICATION_EMAIL):
      return {
        ...initialState,
        errorMessage: action.payload,
        registrationFailure: true,
        loading: false,
      };
    case SUCCESS(ACTION_TYPES.SEND_VERIFICATION_EMAIL):
      return {
        ...initialState,
        loading: false,
        registrationFailure: false,
        registrationSuccess: true,
      };
    case ACTION_TYPES.GET_SELF_REGISTER_USERS:
      return {
        ...state,
        user_email: action.payload.data,
      };
    case REQUEST(ACTION_TYPES.GET_SELF_REGISTER_USERS):
      return {
        ...state,
        loading: true,
      };
    case SUCCESS(ACTION_TYPES.GET_SELF_REGISTER_USERS):
      return {
        ...state,
        loading: false,
        user_email: action.payload.data,
      };
    case FAILURE(ACTION_TYPES.GET_SELF_REGISTER_USERS):
      return {
        ...state,
        loading: false,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

// Actions
export const handleRegister = async values => {
  let result;
  await axios
    .post('api/createOrganization', values)
    .then(res => {
      result = res;
    })
    .catch(err => {
      result = { status: 500 };
    });
  return result;
};

const apiUrl = 'api/emailforSelfRegister';
export const getSelfRegisterUsers = id => ({
  type: ACTION_TYPES.GET_SELF_REGISTER_USERS,
  payload: axios.get(`${apiUrl}/${id}`),
});

export const sendEmailVerification = async email => {
  let result;
  await axios
    .post(apiUrl, email)
    .then(res => {
      result = res;
    })
    .catch(err => {
      result = { status: 500 };
    });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
