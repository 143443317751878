/* eslint-disable no-console */
import axios from 'axios';
import { Storage } from 'react-jhipster';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { setLocale } from 'app/shared/reducers/locale';
import { RegisterServiceWorker } from '../../../firebase';

import moment from 'moment-timezone';

export const ACTION_TYPES = {
  LOGIN: 'authentication/LOGIN',
  GET_SESSION: 'authentication/GET_SESSION',
  LOGOUT: 'authentication/LOGOUT',
  CLEAR_AUTH: 'authentication/CLEAR_AUTH',
  ERROR_MESSAGE: 'authentication/ERROR_MESSAGE',
  STORE_DEVICE_TOKEN: 'authentication/STORE_DEVICE_TOKEN',
  DESTROY_DEVICE_TOKEN: 'authentication/DESTROY_DEVICE_TOKEN',
  GET_ALL_ORGANIZATIONS: 'authentication/GET_ALL_ORGANIZATIONS',
};

const AUTH_TOKEN_KEY = 'jhi-authenticationToken';

const initialState = {
  loading: false,
  isAuthenticated: false,
  loginSuccess: false,
  loginError: false, // Errors returned from server side
  showModalLogin: false,
  is_vetted: false,
  process_vetting: false,
  account: {} as any,
  errorMessage: (null as unknown) as string, // Errors returned from server side
  redirectMessage: (null as unknown) as string,
  sessionHasBeenFetched: false,
  idToken: (null as unknown) as string,
  logoutUrl: (null as unknown) as string,
  deviceToken: {} as any,
  organziation_list: [] as any,
  organizationSelected: false,
};

export type AuthenticationState = Readonly<typeof initialState>;

// Reducer

export default (state: AuthenticationState = initialState, action): AuthenticationState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.LOGIN):
    case REQUEST(ACTION_TYPES.GET_SESSION):
      return {
        ...state,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.GET_ALL_ORGANIZATIONS):
      return {
        ...state,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.LOGIN):
      return {
        ...initialState,
        errorMessage: action.payload,
        showModalLogin: true,
        loginError: true,
        loading: false,
      };
    case FAILURE(ACTION_TYPES.GET_SESSION):
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        sessionHasBeenFetched: true,
        showModalLogin: true,
        errorMessage: action.payload,
      };
    case FAILURE(ACTION_TYPES.GET_ALL_ORGANIZATIONS):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.LOGIN):
      Storage.local.set('first_login', action.payload.data.first_login);
      // Storage.local.set('organizations', action.payload.data.organizations);
      return {
        ...state,
        loading: false,
        loginError: false,
        showModalLogin: false,
        loginSuccess: true,
        isAuthenticated: true,
      };
    case ACTION_TYPES.LOGOUT:
      return {
        ...initialState,
        showModalLogin: true,
      };
    case SUCCESS(ACTION_TYPES.GET_SESSION): {
      const isAuthenticated = action.payload && action.payload.data && action.payload.data.activated;
      const is_vetted = (action.payload && action.payload.data && action.payload.data.is_vetted) || false;
      const process_vetting = (action.payload && action.payload.data && action.payload.data.process_vetting) || false;
      Storage.local.set('user_id', action.payload.data.id);
      Storage.local.set('user_name', action.payload.data.first_name + ' ' + action.payload.data.last_name);
      if (JSON.parse(localStorage.getItem('IndividualSetting'))) {
        moment.updateLocale('en', {
          week: {
            dow: JSON.parse(localStorage.getItem('IndividualSetting')).start_day_of_week,
          },
        });
      }
      return {
        ...state,
        isAuthenticated,
        loading: false,
        is_vetted,
        process_vetting,
        sessionHasBeenFetched: true,
        account: action.payload.data,
      };
    }
    case SUCCESS(ACTION_TYPES.GET_ALL_ORGANIZATIONS):
      return {
        ...state,
        loginError: false,
        showModalLogin: false,
        loginSuccess: true,
        isAuthenticated: true,
        organziation_list: action.payload.data,
      };
    case ACTION_TYPES.STORE_DEVICE_TOKEN:
      return {
        ...state,
        deviceToken: action.payload.data,
      };
    case ACTION_TYPES.ERROR_MESSAGE:
      return {
        ...initialState,
        showModalLogin: true,
        redirectMessage: action.message,
      };
    case ACTION_TYPES.CLEAR_AUTH:
      return {
        ...state,
        loading: false,
        showModalLogin: true,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};

export const displayAuthError = message => ({ type: ACTION_TYPES.ERROR_MESSAGE, message });

export const getSession: () => void = () => async (dispatch, getState) => {
  await dispatch({
    type: ACTION_TYPES.GET_SESSION,
    payload: axios.get('api/account').then(res => {
      if (res?.data?.person_id) {
        RegisterServiceWorker()
          .then(token => {
            if (token) {
              dispatch(storeDeviceToken(token, res?.data?.person_id));
            }
          })
          .catch(() => {});
      }
      return res;
    }),
  });

  const { account } = getState().authentication;
  if (account && account.langKey) {
    const langKey = Storage.session.get('locale', account.langKey);
    await dispatch(setLocale(langKey));
  }
  return;
};

export const login: (username: string, password: string, rememberMe?: boolean) => void = (username, password, rememberMe) => async (
  dispatch,
  getState
) => {
  clearAuthToken();
  const result = await dispatch({
    type: ACTION_TYPES.LOGIN,
    payload: axios.post('api/authenticate', { username, password, rememberMe }),
  });
  const bearerToken = result.value.data.id_token;
  if (bearerToken) {
    const jwt = bearerToken;
    if (rememberMe) {
      Storage.local.set(AUTH_TOKEN_KEY, jwt);
    } else {
      Storage.session.set(AUTH_TOKEN_KEY, jwt);
    }
    // window.location.href = '/organizations';
  }
  await dispatch(getSession());
  // dispatch(getPersonalSettings());
  // dispatch(getOrganizationSettings());
  // dispatch(getPermissionsSettings());
  // dispatch(getNotifications(1));
};

export const storeDeviceToken = (deviceToken, personId) => async dispatch => {
  dispatch({
    type: ACTION_TYPES.STORE_DEVICE_TOKEN,
    payload: await axios.post('api/deviceToken', { device_token: deviceToken, person_id: personId }),
  });
};

export const destroyDeviceToken = id => {
  if (id) {
    return axios.delete(`api/deviceToken/${id}`);
  } else {
    return;
  }
};

export const clearAuthToken = () => {
  if (Storage.local.get(AUTH_TOKEN_KEY)) {
    Storage.local.remove(AUTH_TOKEN_KEY);
  }
  if (Storage.session.get(AUTH_TOKEN_KEY)) {
    Storage.session.remove(AUTH_TOKEN_KEY);
  }
};

export const logout: () => void = () => dispatch => {
  clearAuthToken();
  Storage.local.remove('organizationSelected');
  dispatch({
    type: ACTION_TYPES.LOGOUT,
  });
};

export const clearAuthentication = messageKey => (dispatch, getState) => {
  clearAuthToken();
  dispatch(displayAuthError(messageKey));
  dispatch({
    type: ACTION_TYPES.CLEAR_AUTH,
  });
};

export const setOrganization = organization => async dispatch => {
  const encode = btoa(organization.organization_id);
  Storage.local.set('organizationSelected', encode);
  await dispatch(getSession());
  // dispatch(getPersonalSettings());
  // dispatch(getOrganizationSettings());
  // dispatch(getPermissionsSettings());
  // dispatch(getNotifications(1));
};

export const getMultiplerganizations = () => ({
  type: ACTION_TYPES.GET_ALL_ORGANIZATIONS,
  payload: axios.get(`api/getMultipleOrganizations`),
});

export const userDataDelete = async values => {
  const result = axios
    .post('api/userDataDelete', values)
    .then(res => {
      return res;
    })
    .catch(err => {
      return { status: 500 };
    });
  return result;
};
