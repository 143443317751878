import React, { useState } from 'react';
import { Popper, IconButton, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import QRCode from 'react-qr-code';

interface QrCodePopperProps {
  value: string;
  index: number;
}

const QrCodePopper: React.FC<QrCodePopperProps> = ({ value, index }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openPopperIndex, setOpenPopperIndex] = useState(null);

  const handleQRcode = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setOpenPopperIndex(index);
  };

  const handleQRPopperClose = () => {
    setAnchorEl(null);
    setOpenPopperIndex(null);
  };

  const QrCodeDownload = () => {
    const svgElement = document.getElementById(`qr-gen` + index);
    if (svgElement) {
      const svgString = svgElement.outerHTML;
      const svgWithBackground = `
        <svg width="256" height="256" xmlns="http://www.w3.org/2000/svg">
          <rect width="100%" height="100%" fill="white" />
          ${svgString}
        </svg>
      `;
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const img = new Image();
      img.onload = () => {
        canvas.width = img.width + 10;
        canvas.height = img.height + 10;
        context.fillStyle = 'white';
        context.fillRect(0, 0, canvas.width, canvas.height);
        context.drawImage(img, 5, 5);
        const dataUrl = canvas.toDataURL();
        const downloadLink = document.createElement('a');
        downloadLink.href = dataUrl;
        downloadLink.download = `QRcode.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      };
      img.src = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgWithBackground)));
    }
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton onClick={handleQRcode} className="service_area_checkpoint_delete_from_list_icon" size="small">
        <img src="content/images/qrcode.png" alt="QR Code" />
      </IconButton>

      <Popper open={openPopperIndex === index && open} anchorEl={anchorEl} placement="left" className="service-area-qr-code-popper">
        <div style={{ height: 'auto', margin: '0 auto', width: '100%' }}>
          <div style={{ width: '100%' }}>
            <div style={{ width: '10%', marginLeft: 'auto' }}>
              <CloseIcon onClick={handleQRPopperClose}></CloseIcon>
            </div>
          </div>

          <div style={{ width: '55%', marginLeft: '20%', marginTop: '10%', marginBottom: '10%' }}>
            <QRCode
              id={'qr-gen' + index}
              size={256}
              style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
              value={value}
              viewBox={`0 0 256 256`}
            />
          </div>
          <span style={{ color: '#7A55DE', fontSize: '22px', marginLeft: '45px' }}>
            <Button
              variant="contained"
              color="secondary"
              style={{ background: '#D48BD3', textDecoration: 'none', color: 'white', width: '150px' }}
              onClick={QrCodeDownload}
            >
              Download
            </Button>
          </span>
        </div>
      </Popper>
    </>
  );
};

export default QrCodePopper;
