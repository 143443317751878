import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core';
import { Collapse } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const useStyles = makeStyles(theme => ({
  mainPaper: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #d3d3d3',
    padding: '1vh 0vh',
    width: '87%',
    marginLeft: '6.5%',
  },
  subDiv: {
    marginTop: '5px',
    textAlign: 'left',
    width: '59%',
    cursor: 'pointer',
  },
  subContainer: {
    marginTop: '5px',
    marginLeft: '1.9%',
    textAlign: 'left',
    width: '57.2%',
  },
}));

export default function SchedulingLocation(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.mainPaper}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className={classes.subDiv} onClick={props.barHandler('panel7')}>
            {props.barForProps.panel7 ? <RemoveIcon /> : <AddIcon />}Notify &quot;Location Supervisor&quot; of any activity on their
            location
          </div>

          <div>
            <Switch
              checked={props.valueForProps['scheduling_location_email'] ? props.valueForProps['scheduling_location_email'] : false}
              onChange={props.handler}
              color="primary"
              name="scheduling_location_email"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </div>

          <div style={{ marginLeft: '13%' }}>
            <Switch
              checked={props.valueForProps['scheduling_location_sms'] ? props.valueForProps['scheduling_location_sms'] : false}
              onChange={props.handler}
              color="primary"
              name="scheduling_location_sms"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Collapse in={props.barForProps.panel7} style={{ width: '100%' }}>
            <div style={{ display: 'flex' }}>
              <div className={classes.subContainer}>Cancellation of shift or shift request rejection by person</div>
              <div>
                <Switch
                  checked={
                    props.valueForProps['scheduling_location_cancellation_email']
                      ? props.valueForProps['scheduling_location_cancellation_email']
                      : false
                  }
                  onChange={props.handler}
                  color="primary"
                  name="scheduling_location_cancellation_email"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </div>
              <div style={{ marginLeft: '13%' }}>
                <Switch
                  checked={
                    props.valueForProps['scheduling_location_cancellation_sms']
                      ? props.valueForProps['scheduling_location_cancellation_sms']
                      : false
                  }
                  onChange={props.handler}
                  color="primary"
                  name="scheduling_location_cancellation_sms"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </div>
            </div>

            <div style={{ display: 'flex' }}>
              <div className={classes.subContainer}>Shift request confirmed by person</div>
              <div>
                <Switch
                  checked={
                    props.valueForProps['scheduling_location_shift_request_email']
                      ? props.valueForProps['scheduling_location_shift_request_email']
                      : false
                  }
                  onChange={props.handler}
                  color="primary"
                  name="scheduling_location_shift_request_email"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </div>
              <div style={{ marginLeft: '13%' }}>
                <Switch
                  checked={
                    props.valueForProps['scheduling_location_shift_request_sms']
                      ? props.valueForProps['scheduling_location_shift_request_sms']
                      : false
                  }
                  onChange={props.handler}
                  color="primary"
                  name="scheduling_location_shift_request_sms"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </div>
            </div>

            <div style={{ display: 'flex' }}>
              <div className={classes.subContainer}>Shift reconfirmed by person</div>
              <div>
                <Switch
                  checked={
                    props.valueForProps['scheduling_location_shift_reconfirmed_email']
                      ? props.valueForProps['scheduling_location_shift_reconfirmed_email']
                      : false
                  }
                  onChange={props.handler}
                  color="primary"
                  name="scheduling_location_shift_reconfirmed_email"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </div>
              <div style={{ marginLeft: '13%' }}>
                <Switch
                  checked={
                    props.valueForProps['scheduling_location_shift_reconfirmed_sms']
                      ? props.valueForProps['scheduling_location_shift_reconfirmed_sms']
                      : false
                  }
                  onChange={props.handler}
                  color="primary"
                  name="scheduling_location_shift_reconfirmed_sms"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </div>
            </div>

            <div style={{ display: 'flex' }}>
              <div className={classes.subContainer}>Shift reconfirmation rejected by person</div>
              <div>
                <Switch
                  checked={
                    props.valueForProps['scheduling_location_shift_reconfirmation_email']
                      ? props.valueForProps['scheduling_location_shift_reconfirmation_email']
                      : false
                  }
                  onChange={props.handler}
                  color="primary"
                  name="scheduling_location_shift_reconfirmation_email"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </div>
              <div style={{ marginLeft: '13%' }}>
                <Switch
                  checked={
                    props.valueForProps['scheduling_location_shift_reconfirmation_sms']
                      ? props.valueForProps['scheduling_location_shift_reconfirmation_sms']
                      : false
                  }
                  onChange={props.handler}
                  color="primary"
                  name="scheduling_location_shift_reconfirmation_sms"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </div>
            </div>

            <div style={{ display: 'flex' }}>
              <div className={classes.subContainer}>Statement of work accepted by subcontractor</div>
              <div>
                <Switch
                  checked={
                    props.valueForProps['scheduling_location_statement_email']
                      ? props.valueForProps['scheduling_location_statement_email']
                      : false
                  }
                  onChange={props.handler}
                  color="primary"
                  name="scheduling_location_statement_email"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </div>
              <div style={{ marginLeft: '13%' }}>
                <Switch
                  checked={
                    props.valueForProps['scheduling_location_statement_sms']
                      ? props.valueForProps['scheduling_location_statement_sms']
                      : false
                  }
                  onChange={props.handler}
                  color="primary"
                  name="scheduling_location_statement_sms"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </div>
            </div>

            <div style={{ display: 'flex' }}>
              <div className={classes.subContainer}>Person added an expense to shift</div>
              <div>
                <Switch
                  checked={
                    props.valueForProps['scheduling_location_person_expense_email']
                      ? props.valueForProps['scheduling_location_person_expense_email']
                      : false
                  }
                  onChange={props.handler}
                  color="primary"
                  name="scheduling_location_person_expense_email"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </div>
              <div style={{ marginLeft: '13%' }}>
                <Switch
                  checked={
                    props.valueForProps['scheduling_location_person_expense_sms']
                      ? props.valueForProps['scheduling_location_person_expense_sms']
                      : false
                  }
                  onChange={props.handler}
                  color="primary"
                  name="scheduling_location_person_expense_sms"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </div>
            </div>

            <div style={{ display: 'flex' }}>
              <div className={classes.subContainer}>Supervisor confirmed shift request for person</div>
              <div>
                <Switch
                  checked={
                    props.valueForProps['scheduling_location_super_confirmed_email']
                      ? props.valueForProps['scheduling_location_super_confirmed_email']
                      : false
                  }
                  onChange={props.handler}
                  color="primary"
                  name="scheduling_location_super_confirmed_email"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </div>
              <div style={{ marginLeft: '13%' }}>
                <Switch
                  checked={
                    props.valueForProps['scheduling_location_super_confirmed_sms']
                      ? props.valueForProps['scheduling_location_super_confirmed_sms']
                      : false
                  }
                  onChange={props.handler}
                  color="primary"
                  name="scheduling_location_super_confirmed_sms"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </div>
            </div>

            <div style={{ display: 'flex' }}>
              <div className={classes.subContainer}>Supervisor accepted shift application from person</div>
              <div>
                <Switch
                  checked={
                    props.valueForProps['scheduling_location_super_accepted_email']
                      ? props.valueForProps['scheduling_location_super_accepted_email']
                      : false
                  }
                  onChange={props.handler}
                  color="primary"
                  name="scheduling_location_super_accepted_email"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </div>
              <div style={{ marginLeft: '13%' }}>
                <Switch
                  checked={
                    props.valueForProps['scheduling_location_super_accepted_sms']
                      ? props.valueForProps['scheduling_location_super_accepted_sms']
                      : false
                  }
                  onChange={props.handler}
                  color="primary"
                  name="scheduling_location_super_accepted_sms"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </div>
            </div>

            <div style={{ display: 'flex' }}>
              <div className={classes.subContainer}>Supervisor swapped a shift</div>
              <div>
                <Switch
                  checked={
                    props.valueForProps['scheduling_location_super_swapped_email']
                      ? props.valueForProps['scheduling_location_super_swapped_email']
                      : false
                  }
                  onChange={props.handler}
                  color="primary"
                  name="scheduling_location_super_swapped_email"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </div>
              <div style={{ marginLeft: '13%' }}>
                <Switch
                  checked={
                    props.valueForProps['scheduling_location_super_swapped_sms']
                      ? props.valueForProps['scheduling_location_super_swapped_sms']
                      : false
                  }
                  onChange={props.handler}
                  color="primary"
                  name="scheduling_location_super_swapped_sms"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </div>
            </div>

            <div style={{ display: 'flex' }}>
              <div className={classes.subContainer}>Person added as preferred to supervised location</div>
              <div>
                <Switch
                  checked={
                    props.valueForProps['scheduling_location_person_super_email']
                      ? props.valueForProps['scheduling_location_person_super_email']
                      : false
                  }
                  onChange={props.handler}
                  color="primary"
                  name="scheduling_location_person_super_email"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </div>
              <div style={{ marginLeft: '13%' }}>
                <Switch
                  checked={
                    props.valueForProps['scheduling_location_person_super_sms']
                      ? props.valueForProps['scheduling_location_person_super_sms']
                      : false
                  }
                  onChange={props.handler}
                  color="primary"
                  name="scheduling_location_person_super_sms"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </div>
            </div>
          </Collapse>
        </div>
      </div>
    </React.Fragment>
  );
}
