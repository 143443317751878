import { ICountry } from 'app/shared/model/country.model';

export interface ICountryAdminUnitType {
  id?: number;
  name?: string | null;
  parent?: ICountryAdminUnitType | null;
  country?: ICountry | null;
}

export const defaultValue: Readonly<ICountryAdminUnitType> = {};
