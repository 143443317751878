import dayjs from 'dayjs';
import { ICountry } from 'app/shared/model/country.model';
import { IIdentificationType } from 'app/shared/model/identification-type.model';

export interface IIdentification {
  id?: number;
  name?: string | null;
  issueDate?: string | null;
  expiryDate?: string | null;
  issuingCountry?: ICountry | null;
  identificationType?: IIdentificationType | null;
}

export const defaultValue: Readonly<IIdentification> = {};
