import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Grid, FormControl, TextField, MenuItem } from '@material-ui/core';
import { Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import './contact-type.scss';
import { getEntity, updateEntity, createEntity, reset } from './contact-type.reducer';
import { IContactType } from 'app/shared/model/contact-type.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface IContactTypeUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const ContactTypeUpdate = (props: IContactTypeUpdateProps) => {
  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const { contactTypeEntity, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/contact-type');
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }
  }, []);

  useEffect(() => {}, [contactTypeEntity]);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = event => {
    event.preventDefault();

    const entity = {
      id: event.target.id.value,
      name: event.target.name.value,
    };

    if (isNew) {
      props.createEntity(entity);
    } else {
      props.updateEntity(entity);
    }
  };

  return (
    <div className="contact-type-full-container">
      <Grid item md={12} container justify="center">
        <Grid item md={2}></Grid>
        <Grid item md={8}>
          <h2 id="wfmApp.contactType.home.createOrEditLabel" data-cy="ContactTypeCreateUpdateHeading">
            <Translate contentKey="wfmApp.contactType.home.createOrEditLabel">Create or edit a ContactType</Translate>
          </h2>
        </Grid>
      </Grid>
      <Grid item md={12} container justify="center" spacing={1}>
        <Grid item md={2}></Grid>
        <Grid item md={8} spacing={2}>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <form onSubmit={saveEntity} className="contact-type-form">
              {!isNew ? (
                <Grid item md={12} container spacing={1}>
                  <Grid item md={12} spacing={2}>
                    <FormControl className="contact-type-fields">
                      <TextField
                        id="contact-type-id"
                        variant="outlined"
                        className="country"
                        name="id"
                        required
                        inputProps={{ readOnly: true }}
                        label={<Translate contentKey="global.field.id">ID</Translate>}
                        value={contactTypeEntity.id}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              ) : null}
              <Grid item md={12} container spacing={1}>
                <Grid item md={12} spacing={2}>
                  <FormControl className="contact-type-fields">
                    <TextField
                      id="contact-type-name"
                      data-cy="name"
                      className="contacttypename"
                      variant="outlined"
                      name="name"
                      label={<Translate contentKey="wfmApp.contactType.name">Name</Translate>}
                      defaultValue={contactTypeEntity.name}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item md={12} container spacing={1}>
                <Grid item md={12} spacing={2}>
                  <FormControl style={{ display: 'inline-block' }} className="contact-type-fields">
                    <Button
                      component={Link}
                      id="cancel-save"
                      to="/contact-type"
                      replace
                      style={{ color: 'black', backgroundColor: 'rgb(13, 202, 240)' }}
                    >
                      <FontAwesomeIcon icon="arrow-left" />
                      &nbsp;
                      <span className="d-none d-md-inline">
                        <Translate contentKey="entity.action.back">Back</Translate>
                      </span>
                    </Button>
                    &nbsp;
                    <Button
                      style={{ color: 'white', backgroundColor: 'blue' }}
                      id="save-entity"
                      data-cy="entityCreateSaveButton"
                      type="submit"
                      disabled={updating}
                    >
                      <FontAwesomeIcon icon="save" />
                      &nbsp;
                      <Translate contentKey="entity.action.save">Save</Translate>
                    </Button>
                  </FormControl>
                </Grid>
              </Grid>
            </form>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  contactTypeEntity: storeState.contactType.entity,
  loading: storeState.contactType.loading,
  updating: storeState.contactType.updating,
  updateSuccess: storeState.contactType.updateSuccess,
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ContactTypeUpdate);
