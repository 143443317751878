import axios from 'axios';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { ToastContainer, toast } from 'react-toastify';
import { ACRGeneralSettings } from 'app/shared/model/automated-control-room-general-settings.model';
import { ACRShiftSettings } from 'app/shared/model/automated-control-room-shift-settings.model';
import { ICallLogs } from 'app/shared/model/call-logs.model';

export const ACTION_TYPES = {
  UPDATE_ACRGENERAL_SETTINGS: 'ACR/UPDATE_ACRGENERAL_SETTINGS',
  UPDATE_ACRSHIFT_SETTINGS: 'ACR/UPDATE_ACRSHIFT_SETTINGS',
  GET_ACRSHIFT_SETTINGS: 'ACR/GET_ACRSHIFT_SETTINGS',
  GET_ACRGENERAL_SETTINGS: 'ACR/GET_ACRGENERAL_SETTINGS',
  FETCH_CALL_LOGS: 'ACR/FETCH_TIMESHEET_SUMMARY',
  RESET: 'ACR/RESET',
};

const initialState = {
  ACRGeneralSettings: [] as Array<ACRGeneralSettings>,
  ACRShiftSettings: [] as Array<ACRShiftSettings>,
  storedACRShiftSettings: [] as ACRShiftSettings,
  storedACRGeneralSettings: [] as ACRGeneralSettings,
  entities: [] as ReadonlyArray<ICallLogs>,
  loading: false,
  errorMessage: null,
  updating: false,
  updateSuccess: false,
};

export type AutomatedControlRoomState = Readonly<typeof initialState>;
export default (state: AutomatedControlRoomState = initialState, action): AutomatedControlRoomState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.GET_ACRGENERAL_SETTINGS):
    case REQUEST(ACTION_TYPES.GET_ACRSHIFT_SETTINGS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.UPDATE_ACRGENERAL_SETTINGS):
    case REQUEST(ACTION_TYPES.UPDATE_ACRSHIFT_SETTINGS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case REQUEST(ACTION_TYPES.FETCH_CALL_LOGS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_CALL_LOGS):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case FAILURE(ACTION_TYPES.GET_ACRGENERAL_SETTINGS):
    case FAILURE(ACTION_TYPES.UPDATE_ACRGENERAL_SETTINGS):
    case FAILURE(ACTION_TYPES.GET_ACRSHIFT_SETTINGS):
    case FAILURE(ACTION_TYPES.UPDATE_ACRSHIFT_SETTINGS):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.GET_ACRGENERAL_SETTINGS):
      return {
        ...state,
        loading: false,
        storedACRGeneralSettings: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.GET_ACRSHIFT_SETTINGS):
      return {
        ...state,
        loading: false,
        storedACRShiftSettings: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.UPDATE_ACRGENERAL_SETTINGS):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        ACRGeneralSettings: action.payload,
      };
    case SUCCESS(ACTION_TYPES.UPDATE_ACRSHIFT_SETTINGS):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        ACRShiftSettings: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_CALL_LOGS):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const updateACRGeneralSettings: ICrudPutAction<ACRGeneralSettings> = settings => dispatch => {
  let result;
  axios
    .put('api/ACRGeneralSettings', cleanEntity(settings))
    .then(res => {
      dispatch(reset());
      dispatch(getACRGeneralSettings());
      toast.success('Settings Saved');
    })
    .catch(err => {
      toast.error('Error');
    });

  return result;
};

export const updateACRShiftSettings: ICrudPutAction<ACRShiftSettings> = settings => dispatch => {
  let result;
  axios
    .put('api/ACRShiftSettings', cleanEntity(settings))
    .then(res => {
      dispatch(reset());
      dispatch(getACRShiftSettings());
    })
    .catch(err => {
      toast.error('Error');
    });

  return result;
};

export const getACRGeneralSettings = () => ({
  type: ACTION_TYPES.GET_ACRGENERAL_SETTINGS,
  payload: axios.get<ACRGeneralSettings>('api/ACRGeneralSettings'),
});

export const getACRShiftSettings = () => ({
  type: ACTION_TYPES.GET_ACRSHIFT_SETTINGS,
  payload: axios.get<ACRShiftSettings>('api/ACRShiftSettings'),
});
const apiUrl = 'api/callLogReports';

export const getReport = data => ({
  type: ACTION_TYPES.FETCH_CALL_LOGS,
  payload: axios.get<ICallLogs>(
    `${apiUrl}?filter[from_shifts]=${data.from_date}&filter[to_shifts]=${data.to_date}&filter[person_id]=${data.people}`
  ),
});

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
