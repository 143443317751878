import moment from 'moment';
import 'moment-timezone';
import currencies_list from '../app/shared/data/currencies_list.json';
import timezones from '../app/shared/data/timezones.json';
import { monthPrefix } from '../utils/global';

export const getlabel = timezoneFormat => {
  let labels = '';
  timezones.forEach(element => {
    if (element.value === timezoneFormat) {
      labels = element.label;
    }
  });
  return labels;
};

export const changeTimeDateFormat = (time, timeFormat, dateFormat) => {
  if (time !== undefined && time !== null) {
    const dateTimeArray = time.split(' ');
    const dateWithChangedFormat = dateFTConverter(dateTimeArray[0], dateFormat);
    const timeWithChangedFormat = moment(time).format(`${timeFormat === 1 ? 'HH:mm' : 'hh:mm A'}`);
    return dateWithChangedFormat + ' ' + timeWithChangedFormat;
  }
  return '';
};

export const changeDateFormat = time => {
  if (time !== undefined && time !== null) {
    const dateWithChangedFormat = moment(time).format('D MMM YYYY');
    return dateWithChangedFormat;
  }
  return '';
};

export const changeTimeFormat = (time, timeFormat) => {
  if (time !== undefined && time !== null) {
    const timeWithChangedFormat = moment(time).format(`${timeFormat === 1 ? 'HH:mm' : 'hh:mm A'}`);
    return timeWithChangedFormat;
  }
  return '';
};

export const shiftListStartEndDate = (shiftTime, dateFormat) => {
  if (shiftTime !== '' && shiftTime !== null && shiftTime !== undefined) {
    const Shifttime = shiftTime.split(' ');
    const date1 = dateFTConverter(Shifttime[0], dateFormat);
    return date1;
  }
  return shiftTime;
};

export const ActivityDescriptionDateFormat = (shiftTime, dateFormat) => {
  if (shiftTime !== '' && shiftTime !== null) {
    const timeString = shiftTime?.split(' ');
    const dateString = timeString[0];
    return '( Shift Date: ' + dateFTConverter(dateString, dateFormat) + ' )';
  }
};

export const dateFTConverter = (date, dateFormat) => {
  if (date === null) {
    if (dateFormat === 1) {
      return 'DD-MM-YY';
    } else if (dateFormat === 2) {
      return 'DD-MM-YYYY';
    } else if (dateFormat === 3) {
      return 'MM/DD/YY';
    } else if (dateFormat === 4) {
      return 'MM/DD/YYYY';
    } else if (dateFormat === 5) {
      return 'DD.MM.YY';
    } else if (dateFormat === 6) {
      return 'DD.MM.YYYY';
    } else if (dateFormat === 7) {
      return 'YY/MM/DD';
    } else if (dateFormat === 8) {
      return 'YYYY/MM/DD';
    } else {
      return 'DD-MM-YY';
    }
  } else {
    if (dateFormat === 1) {
      return moment(date).format('DD-MM-YY');
    } else if (dateFormat === 2) {
      return moment(date).format('DD-MM-YYYY');
    } else if (dateFormat === 3) {
      return moment(date).format('MM/DD/YY');
    } else if (dateFormat === 4) {
      return moment(date).format('MM/DD/YYYY');
    } else if (dateFormat === 5) {
      return moment(date).format('DD.MM.YY');
    } else if (dateFormat === 6) {
      return moment(date).format('DD.MM.YYYY');
    } else if (dateFormat === 7) {
      return moment(date).format('YY/MM/DD');
    } else if (dateFormat === 8) {
      return moment(date).format('YYYY/MM/DD');
    } else {
      return moment(date).format('DD-MM-YY');
    }
  }
};

export const YearPrefix = (month, year) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  if (month === months['jan']) {
    return year + 1;
  } else {
    return year;
  }
};

export const dayPrefix = (day, month, year) => {
  const date = day;
  if (date === day) {
    return date + ' ' + month + ' ' + year;
  }
};

export const SEtimeConverter = e => {
  const d = new Date();
  const time = /(\d+)(:(\d\d))?\s*(p?)/i.exec(e);
  d.setHours(parseInt(time[1], 10) + (parseInt(time[1], 10) < 12 && time[4] ? 12 : 0));
  d.setMinutes(parseInt(time[3], 10) || 0);
  d.setSeconds(0, 0);
  return d;
};

export const calendarDateconverter = date => {
  const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  const d = new Date(date);
  const m = months[d.getMonth()];
  const dd = d.getDate();
  const y = d.getFullYear();
  if (date === null) {
    return 'YYYY-MM-DD';
  } else {
    return y + '-' + m + '-' + dd;
  }
};

export const calculateShiftListTimeDuration = (startTime, endTime) => {
  if ((startTime !== null || startTime !== undefined) && (endTime !== null || endTime !== undefined)) {
    const timeDifference = moment.duration(moment(endTime).diff(moment(startTime)));
    const hoursDifference =
      Math.abs(timeDifference.hours()).toString().length === 1
        ? '0' + Math.abs(timeDifference.hours()).toString()
        : Math.abs(timeDifference.hours()).toString();
    const minutesDifference =
      Math.abs(timeDifference.minutes()).toString().length === 1
        ? '0' + Math.abs(timeDifference.minutes()).toString()
        : Math.abs(timeDifference.minutes()).toString();

    return hoursDifference + ':' + minutesDifference;
  } else {
    return '';
  }
};

export const CurrencyChecker = currency => {
  if (currency && currency !== undefined) {
    return currencies_list.find(x => x.value === currency).symbol;
  }
};

export const getSelectedTimePeriodFilter = (filterName, setDateName) => {
  switch (filterName) {
    case 'Current week': {
      if (setDateName === 'setSelectedDateFrom') {
        const start = handleFilterCurrentWeekStartDate();
        return moment(start).format('YYYY-MM-DD');
      } else {
        const end = handleFilterCurrentWeekEndDate();
        return moment(end).format('YYYY-MM-DD');
      }
    }
    case 'Last week': {
      if (setDateName === 'setSelectedDateFrom') {
        const start = handleFilterLastWeekStartDate();
        return moment(start).format('YYYY-MM-DD');
      } else {
        const end = handleFilterLastWeekEndDate();
        return moment(end).format('YYYY-MM-DD');
      }
    }
    case 'Current month': {
      if (setDateName === 'setSelectedDateFrom') {
        const start = moment().startOf('month').format('YYYY-MM-DD');
        return start;
      } else {
        const end = moment().endOf('month').format('YYYY-MM-DD');
        return end;
      }
    }
    case 'Last month': {
      if (setDateName === 'setSelectedDateFrom') {
        const start = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
        return start;
      } else {
        const end = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
        return end;
      }
    }
    default: {
      if (setDateName === 'setSelectedDateFrom') {
        return null;
      } else {
        return null;
      }
    }
  }
};

export const handleFilterCurrentWeekStartDate = () => {
  const from_date = moment().startOf('week');
  return from_date;
};

export const handleFilterCurrentWeekEndDate = () => {
  const to_date = moment().endOf('week');
  return to_date;
};

export const handleFilterLastWeekStartDate = () => {
  const from_date = moment().startOf('week').subtract(7, 'days');
  return from_date;
};

export const handleFilterLastWeekEndDate = () => {
  const to_date = moment().startOf('week').subtract(1, 'days');
  return to_date;
};

export const getTodayDate = () => {
  const first = new Date();
  return moment(first).format('YYYY-MM-DD');
};

export const calculateShiftDuration = (startTime, endTime) => {
  const diff = moment.duration(moment(endTime).diff(moment(startTime)));
  const diff_hours = diff.hours().toString().length === 1 ? '0' + diff.hours().toString() : diff.hours().toString();
  const diff_mins = diff.minutes().toString().length === 1 ? '0' + diff.minutes().toString() : diff.minutes().toString();

  if (parseInt(diff_hours, 10) < 0 || parseInt(diff_mins, 10) < 0) {
    const endTimePlusOne = new Date(endTime).setDate(new Date(endTime).getDate() + 1);
    const epochToDate = new Date(endTimePlusOne);
    const newDuration = calculateShiftDuration(startTime, epochToDate);
    return newDuration;
  }
  return diff_hours + ':' + diff_mins;
};

export const check_time_overlap = (shift, start_time, end_time, date) => {
  const start_time1 = moment(shift.start_time).format('YYYY-MM-DD HH:mm');
  let end_time1 = moment(shift.end_time).format('YYYY-MM-DD HH:mm');
  const start_time2 = moment(start_time)
    .set({
      year: moment(date).year(),
      month: moment(date).month(),
      date: moment(date).date(),
    })
    .format('YYYY-MM-DD HH:mm');
  let end_time2 = moment(end_time)
    .set({
      year: moment(date).year(),
      month: moment(date).month(),
      date: moment(date).date(),
    })
    .format('YYYY-MM-DD HH:mm');

  if (end_time1 < start_time1) {
    end_time1 = moment(end_time1).add(24, 'hours').format('YYYY-MM-DD HH:mm');
  }

  if (end_time2 < start_time2) {
    end_time2 = moment(end_time2).add(24, 'hours').format('YYYY-MM-DD HH:mm');
  }

  if (
    (start_time1 >= start_time2 && start_time1 <= end_time2) ||
    (end_time1 >= start_time2 && end_time1 <= end_time2) ||
    (start_time2 >= start_time1 && start_time2 <= end_time1) ||
    (end_time2 >= start_time1 && end_time2 <= end_time1)
  ) {
    return true;
  } else {
    return false;
  }

  // if (start_time1 < start_time2 && start_time1 < end_time2 && end_time1 < start_time2 && end_time1 < end_time2) {
  //   return false;
  // } else if (start_time1 > start_time2 && start_time1 > end_time2 && end_time2 > start_time2 && end_time1 > end_time2) {
  //   return false;
  // } else {
  //   return true;
  // }
};

export const getCustomHeaderDate = values => {
  if (values.option === 'DAY') {
    const date = moment(values.current_day, 'YYYY-MM-DD').toDate();
    return date.getDate().toString() + ' ' + monthPrefix(date.getMonth() + 1);
  } else if (values.option === 'WEEK') {
    const dateStart = moment(values.from_date, 'YYYY-MM-DD').toDate();
    const monthStart = monthPrefix(dateStart.getMonth() + 1);
    const dateEnd = moment(values.to_date, 'YYYY-MM-DD').toDate();
    const monthEnd = monthPrefix(dateEnd.getMonth() + 1);

    return (
      dateStart.getDate().toString() +
      ' ' +
      monthPrefix(dateStart.getMonth() + 1) +
      ' ' +
      YearPrefix(monthStart, dateStart.getFullYear()) +
      ' - ' +
      dateEnd.getDate().toString() +
      ' ' +
      monthPrefix(dateEnd.getMonth() + 1) +
      ' ' +
      YearPrefix(monthEnd, dateEnd.getFullYear())
    );
  } else {
    const date = moment(values.from_date, 'YYYY-MM-DD').toDate();
    const month = monthPrefix(date.getMonth() + 1);
    return month + ' ' + YearPrefix(month, date.getFullYear());
  }
};

export const moveNext = (values, setValues) => {
  if (values.option === 'DAY') {
    setValues(oldValues => ({
      ...oldValues,
      ['from_date']: '',
      ['to_date']: '',
      ['option']: 'DAY',
      ['current_day']: moment(values.current_day, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD'),
    }));
  } else if (values.option === 'MONTH') {
    setValues(oldValues => ({
      ...oldValues,
      ['from_date']: moment(values.from_date, 'YYYY-MM-DD').add(1, 'M').startOf('month').format('YYYY-MM-DD'),
      ['to_date']: moment(values.to_date, 'YYYY-MM-DD').add(1, 'M').endOf('month').format('YYYY-MM-DD'),
      ['option']: 'MONTH',
      ['current_day']: '',
    }));
  } else {
    setValues(oldValues => ({
      ...oldValues,
      ['from_date']: moment(values.from_date, 'YYYY-MM-DD').add(7, 'days').format('YYYY-MM-DD'),
      ['to_date']: moment(values.to_date, 'YYYY-MM-DD').add(7, 'days').format('YYYY-MM-DD'),
      ['option']: 'WEEK',
      ['current_day']: '',
    }));
  }
};

export const movePrev = (values, setValues) => {
  if (values.option === 'DAY') {
    setValues(oldValues => ({
      ...oldValues,
      ['from_date']: '',
      ['to_date']: '',
      ['option']: 'DAY',
      ['current_day']: moment(values.current_day, 'YYYY-MM-DD').subtract(1, 'days').format('YYYY-MM-DD'),
    }));
  } else if (values.option === 'MONTH') {
    setValues(oldValues => ({
      ...oldValues,
      ['from_date']: moment(values.from_date, 'YYYY-MM-DD').subtract(1, 'M').startOf('month').format('YYYY-MM-DD'),
      ['to_date']: moment(values.to_date, 'YYYY-MM-DD').subtract(1, 'M').endOf('month').format('YYYY-MM-DD'),
      ['option']: 'MONTH',
      ['current_day']: '',
    }));
  } else {
    setValues(oldValues => ({
      ...oldValues,
      ['from_date']: moment(values.from_date, 'YYYY-MM-DD').subtract(7, 'days').format('YYYY-MM-DD'),
      ['to_date']: moment(values.to_date, 'YYYY-MM-DD').subtract(7, 'days').format('YYYY-MM-DD'),
      ['option']: 'WEEK',
      ['current_day']: '',
    }));
  }
};

export const formatDate = date => {
  const newDate = moment();
  newDate.set('year', date.getFullYear());
  newDate.set('month', date.getMonth());
  newDate.set('date', date.getDate());

  return newDate.format('YYYY-MM-DD');
};
