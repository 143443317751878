import { ExportPDF } from '../components/downloadPDFs/exportPDF';

export const subcontractorConvertToPDF = jsonData => {
  const header = ['Subcontractor Name', 'Point of Contact', 'Conatct Details', 'Region'];

  const headers = [header];
  const title = 'List of Subcontractors';
  const data = jsonData.map(row => [row.name, row.contactPerson, row.phone + ',  ' + row.email, row.city && row.city.name]);
  const filename = 'List of Subcontractors.pdf';
  ExportPDF(headers, title, data, filename);
};
