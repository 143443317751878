/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { IRootState } from 'app/shared/reducers';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { FormControl, Grid, Paper, TextField } from '@material-ui/core';
import '../../sub-contractor/subcontractor.scss';
import Typography from '@material-ui/core/Typography';
import PersonCertificationComponent from '../component/person-certifications-and-others';
import Divider from '@material-ui/core/Divider';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import '../person.scss';
import { validationMessageBeforeSubmit, validationMessageOnChange } from '../../../../utils/validationMessages';
import { verifySiaLicenceNumber } from '../../person/person.reducer';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import CustomToolbarComponent from '../../service-area/CustomToolbarComponent';
import { getDateFormat } from 'app/shared/util/calender-utils';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import { createEntity, getEntity, reset, updateEntity } from '../person.reducer';
import PersonUpdateOrganizationQualification from '../person-update-organization-qualification';
import { CurrencyChecker } from '../../../../utils/TimeFormatAndTimezone';
import { getOrganizationQualifications } from '../../../modules/Setting/OrganizationQualification/Organization-Qualification.reducer';

export interface IPersonDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const PersonCertificationsAndOthers = props => {
  const [fieldValues, setFieldValues] = useState(props.personEntity);
  const [showEditPage, setShowEditPage] = useState(false);
  const [expiry_date, setExpiry_date] = useState(null);
  const [siaName, setSiaName] = useState();
  const [expiry, setExpiry] = useState(null);
  const [isNew] = useState(!props.match.params || !props.match.params.id);
  // const [currency, setCurrency] = useState(props.settings.currency);
  const [qualificationDiv, setQualificationDiv] = useState(false);
  const [qualificationIndex, setQualificationIndex] = useState({});
  const [qualificationFileName, setQualificationFileName] = useState({});
  const [organizationQualificationList, setOrganizationQualificationList] = useState(props.organizationQualification);
  const [personFiles, setPersonFiles] = useState([]);
  const [fieldError, setFieldError] = useState({
    siaLicenceNumber_error: false,
    siaLicenceNumber_helper: '',
    siaExpiryDate_error: false,
    siaExpiryDate_helper: '',
  });

  useEffect(() => {
    setFieldValues(props.personEntity);
  }, [props.personEntity]);

  useEffect(() => {
    setOrganizationQualificationList(props.organizationQualification);
  }, [props.organizationQualification]);

  useEffect(() => {
    props.getEntity(props.match.params.id);
    props.getOrganizationQualifications();
  }, []);

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }
  }, []);

  const handleQualificationProofs = (event, index) => {
    const makeFilesCopy = personFiles;
    makeFilesCopy.push({ file_name: index, file: event.target.files[0] });
    setPersonFiles(makeFilesCopy);
    const key = `file-${index.someProperty}`;
    setQualificationFileName(oldValues => ({ ...oldValues, [key]: event.target.files[0].name }));
  };

  const handleQualificationIndex = index => {
    setQualificationIndex(index);
  };

  const setNewValues = e => {
    setFieldValues(oldValues => ({ ...oldValues, [e.target.name]: e.target.value }));

    if (e.target.name === 'siaLicenceNumber' && e.target.value.length > 0 && !/^[0-9]{16}$/.test(e.target.value)) {
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: true }));
      setFieldError(oldFieldError => ({
        ...oldFieldError,
        [e.target.name + '_helper']: validationMessageOnChange.SIAnumber,
      }));
    } else {
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: false }));
      setFieldError(oldFieldError => ({
        ...oldFieldError,
        [e.target.name + '_helper']: '',
      }));
    }
  };

  const handleQualificationSwitch = () => {
    setQualificationDiv(prev => !prev);
  };

  const profilePageHandler = () => {
    setFieldValues(props.personEntity);
    setShowEditPage(false);
  };

  const editPageHandler = () => {
    setShowEditPage(true);
  };
  const handleSiaLicenceNumber = event => {
    if (event.target.value.length <= 0) {
      setFieldValues(oldValues => ({ ...oldValues, ['siaLicenceNumber']: null }));
      setExpiry_date(null);
      setSiaName(null);
    }
    setNewValues(event);
  };

  const isValidSiaLicence = async event => {
    if (event.target.value.length === 16) {
      const isValidLicence = await verifySiaLicenceNumber(parseInt(event.target.value, 10));
      if (isValidLicence.status === 200 && isValidLicence.data.status === 'Valid') {
        setSiaName(isValidLicence?.data?.name);
        setExpiry(new Date(isValidLicence?.data?.expiry));
        setExpiry_date(isValidLicence?.data?.expiry);
        setFieldValues(oldValues => ({
          ...oldValues,
          expiry_date: new Date(isValidLicence?.data?.expiry).toISOString(),
        }));

        setFieldError(oldFieldError => ({ ...oldFieldError, ['siaLicenceNumber_error']: false, ['siaExpiryDate_error']: false }));
        if (!isNew) {
          if (props.personEntity.siaLicenceNumber) {
            setFieldValues(oldFieldValues => ({
              ...oldFieldValues,
              ['siaLicenceNumber']: {
                id: props.personEntity.siaLicenceNumber.id,
                value: event.target.value,
                expiry_date: isValidLicence?.data?.expiry,
                designation_qualification_type_id: 1,
                person_id: props.personEntity.id,
                name: 'licence',
              },
            }));
          } else {
            setFieldValues(oldFieldValues => ({
              ...oldFieldValues,
              ['siaLicenceNumber']: {
                id: null,
                value: event.target.value,
                expiry_date: isValidLicence?.data?.expiry,
                designation_qualification_type_id: 1,
                person_id: props.personEntity.id,
                name: 'licence',
              },
            }));
          }
        } else {
          setFieldValues(oldFieldValues => ({ ...oldFieldValues, ['siaLicenceNumber']: event.target.value }));
        }
      } else {
        setSiaName(null);
        setFieldError(oldFieldError => ({
          ...oldFieldError,
          ['siaLicenceNumber_error']: true,
          ['siaLicenceNumber_helper']: 'Invalid licence no',
        }));
      }
      return 0;
    }
  };

  const handleLicenceExpiryDate = event => {
    if (fieldValues['siaLicenceNumber'] === '' || fieldValues['siaLicenceNumber'] === undefined) {
      setFieldValues(oldValues => ({ ...oldValues, expiry_date: null }));
      setFieldError(oldFieldError => ({ ...oldFieldError, ['siaExpiryDate_error']: false }));
    } else {
      const selectedDate = new Date(event);
      setExpiry_date(event);
      setFieldValues(oldValues => ({ ...oldValues, expiry_date: event }));
      if (expiry === null && fieldError['siaLicenceNumber_error'] === true) {
        setFieldError(oldFieldError => ({
          ...oldFieldError,
          ['siaExpiryDate_error']: true,
          ['siaExpiryDate_helper']: 'Invalid expiry date',
        }));
      } else {
        if (
          moment(selectedDate).date() === moment(expiry).date() &&
          moment(selectedDate).month() === moment(expiry).month() &&
          moment(selectedDate).year() === moment(expiry).year()
        ) {
          setFieldError(oldFieldError => ({ ...oldFieldError, ['siaExpiryDate_error']: false }));
        } else {
          setFieldError(oldFieldError => ({
            ...oldFieldError,
            ['siaExpiryDate_error']: true,
            ['siaExpiryDate_helper']: 'Invalid expiry date',
          }));
        }
      }
    }
  };

  const handleEnhancedNumber = e => {
    if (!isNew) {
      if (props.personEntity.enhanced_dbs) {
        setFieldValues(oldFieldValues => ({
          ...oldFieldValues,
          ['enhanced_dbs']: {
            id: props.personEntity?.enhanced_dbs?.id,
            value: e.target.value,
            name: 'enhancedDBS',
            designation_qualification_type_id: 12,
            person_id: props.personEntity.id,
          },
        }));
      } else {
        setFieldValues(oldFieldValues => ({
          ...oldFieldValues,
          ['enhanced_dbs']: {
            id: null,
            value: e.target.value,
            name: 'enhancedDBS',
            designation_qualification_type_id: 12,
            person_id: props.personEntity.id,
          },
        }));
      }
    } else {
      setFieldValues(oldFieldValues => ({
        ...oldFieldValues,
        ['enhanced_dbs']: {
          value: e.target.value,
          name: 'enhancedDBS',
          designation_qualification_type_id: 12,
          issue_date:
            fieldValues && fieldValues['enhanced_dbs'] && fieldValues['enhanced_dbs']['issue_date']
              ? fieldValues['enhanced_dbs']['issue_date']
              : null,
        },
      }));
    }
  };

  const handleBDSExpiryDate = e => {
    if (!isNew) {
      if (props.personEntity.enhanced_dbs) {
        setFieldValues(oldFieldValues => ({
          ...oldFieldValues,
          ['enhanced_dbs']: {
            ...oldFieldValues.enhanced_dbs,
            id: props.personEntity?.enhanced_dbs?.id,
            value: props.personEntity.enhanced_dbs ? fieldValues.enhanced_dbs.value : null,
            issue_date: new Date(e),
            name: 'enhancedDBS',
            designation_qualification_type_id: 12,
            person_id: props.personEntity.id,
          },
        }));
      } else {
        setFieldValues(oldFieldValues => ({
          ...oldFieldValues,
          ['enhanced_dbs']: {
            ...oldFieldValues.enhanced_dbs,
            id: null,
            value:
              fieldValues && fieldValues['enhanced_dbs'] && fieldValues['enhanced_dbs']['value']
                ? fieldValues['enhanced_dbs']['value']
                : null,
            issue_date: new Date(e),
            name: 'enhancedDBS',
            designation_qualification_type_id: 12,
            person_id: props.personEntity.id,
          },
        }));
      }
    } else {
      setFieldValues(oldFieldValues => ({
        ...oldFieldValues,
        ['enhanced_dbs']: {
          value:
            fieldValues && fieldValues['enhanced_dbs'] && fieldValues['enhanced_dbs']['value']
              ? fieldValues['enhanced_dbs']['value']
              : null,
          issue_date: new Date(e),
          name: 'enhancedDBS',
          designation_qualification_type_id: 12,
        },
      }));
    }
  };

  const handleReferenceNumber = e => {
    if (!isNew) {
      if (props.personEntity.NSVS_clearance) {
        setFieldValues(oldFieldValues => ({
          ...oldFieldValues,
          ['NSVS_clearance']: {
            id: props.personEntity.NSVS_clearance.id,
            value: e.target.value,
            issue_date: props.personEntity.NSVS_clearance ? props.personEntity.NSVS_clearance.issue_date : null,
            expiry_date: props.personEntity.NSVS_clearance ? props.personEntity.NSVS_clearance.expiry_date : null,
            clearance_level: props.personEntity.NSVS_clearance ? props.personEntity.NSVS_clearance.clearance_level : null,
            name: 'NSVS_clearance',
            designation_qualification_type_id: 13,
            person_id: props.personEntity.id,
          },
        }));
      } else {
        setFieldValues(oldFieldValues => ({
          ...oldFieldValues,
          ['NSVS_clearance']: {
            id: null,
            value: e.target.value,
            issue_date:
              fieldValues && fieldValues['NSVS_clearance'] && fieldValues['NSVS_clearance']['issue_date']
                ? fieldValues['NSVS_clearance']['issue_date']
                : null,
            expiry_date:
              fieldValues && fieldValues['NSVS_clearance'] && fieldValues['NSVS_clearance']['expiry_date']
                ? fieldValues['NSVS_clearance']['expiry_date']
                : null,
            clearance_level:
              fieldValues && fieldValues['NSVS_clearance'] && fieldValues['NSVS_clearance']['clearance_level']
                ? fieldValues['NSVS_clearance']['clearance_level']
                : null,
            name: 'NSVS_clearance',
            designation_qualification_type_id: 13,
            person_id: props.personEntity.id,
          },
        }));
      }
    } else {
      setFieldValues(oldFieldValues => ({
        ...oldFieldValues,
        ['NSVS_clearance']: {
          value: e.target.value,
          issue_date:
            fieldValues && fieldValues['NSVS_clearance'] && fieldValues['NSVS_clearance']['issue_date']
              ? fieldValues['NSVS_clearance']['issue_date']
              : null,
          expiry_date:
            fieldValues && fieldValues['NSVS_clearance'] && fieldValues['NSVS_clearance']['expiry_date']
              ? fieldValues['NSVS_clearance']['expiry_date']
              : null,
          clearance_level:
            fieldValues && fieldValues['NSVS_clearance'] && fieldValues['NSVS_clearance']['clearance_level']
              ? fieldValues['NSVS_clearance']['clearance_level']
              : null,
          name: 'NSVS_clearance',
          designation_qualification_type_id: 13,
        },
      }));
    }
  };

  const handleClearanceLevel = e => {
    if (!isNew) {
      if (props.personEntity.NSVS_clearance) {
        setFieldValues(oldFieldValues => ({
          ...oldFieldValues,
          ['NSVS_clearance']: {
            id: props.personEntity.NSVS_clearance.id,
            value: props.personEntity.NSVS_clearance ? props.personEntity.NSVS_clearance.value : null,
            issue_date: props.personEntity.NSVS_clearance ? props.personEntity.NSVS_clearance.issue_date : null,
            expiry_date: props.personEntity.NSVS_clearance ? props.personEntity.NSVS_clearance.expiry_date : null,
            clearance_level: e.target.value,
            name: 'NSVS_clearance',
            designation_qualification_type_id: 13,
            person_id: props.personEntity.id,
          },
        }));
      } else {
        setFieldValues(oldFieldValues => ({
          ...oldFieldValues,
          ['NSVS_clearance']: {
            id: null,
            value:
              fieldValues && fieldValues['NSVS_clearance'] && fieldValues['NSVS_clearance']['value']
                ? fieldValues['NSVS_clearance']['value']
                : null,
            issue_date:
              fieldValues && fieldValues['NSVS_clearance'] && fieldValues['NSVS_clearance']['issue_date']
                ? fieldValues['NSVS_clearance']['issue_date']
                : null,
            expiry_date:
              fieldValues && fieldValues['NSVS_clearance'] && fieldValues['NSVS_clearance']['expiry_date']
                ? fieldValues['NSVS_clearance']['expiry_date']
                : null,
            clearance_level: e.target.value,
            name: 'NSVS_clearance',
            designation_qualification_type_id: 13,
            person_id: props.personEntity.id,
          },
        }));
      }
    } else {
      setFieldValues(oldFieldValues => ({
        ...oldFieldValues,
        ['NSVS_clearance']: {
          value:
            fieldValues && fieldValues['NSVS_clearance'] && fieldValues['NSVS_clearance']['value']
              ? fieldValues['NSVS_clearance']['value']
              : null,
          issue_date:
            fieldValues && fieldValues['NSVS_clearance'] && fieldValues['NSVS_clearance']['issue_date']
              ? fieldValues['NSVS_clearance']['issue_date']
              : null,
          expiry_date:
            fieldValues && fieldValues['NSVS_clearance'] && fieldValues['NSVS_clearance']['expiry_date']
              ? fieldValues['NSVS_clearance']['expiry_date']
              : null,
          clearance_level: e.target.value,
          name: 'NSVS_clearance',
          designation_qualification_type_id: 13,
        },
      }));
    }
  };

  const handleNSVSIssueDate = e => {
    if (!isNew) {
      if (props.personEntity.NSVS_clearance) {
        setFieldValues(oldFieldValues => ({
          ...oldFieldValues,
          ['NSVS_clearance']: {
            id: props.personEntity.NSVS_clearance.id,
            value: props.personEntity.NSVS_clearance ? props.personEntity.NSVS_clearance.value : null,
            issue_date: new Date(e),
            expiry_date: props.personEntity.NSVS_clearance ? props.personEntity.NSVS_clearance.expiry_date : null,
            clearance_level: props.personEntity.NSVS_clearance ? props.personEntity.NSVS_clearance.clearance_level : null,
            name: 'NSVS_clearance',
            designation_qualification_type_id: 13,
            person_id: props.personEntity.id,
          },
        }));
      } else {
        setFieldValues(oldFieldValues => ({
          ...oldFieldValues,
          ['NSVS_clearance']: {
            id: null,
            value:
              fieldValues && fieldValues['NSVS_clearance'] && fieldValues['NSVS_clearance']['value']
                ? fieldValues['NSVS_clearance']['value']
                : null,
            issue_date: new Date(e),
            expiry_date:
              fieldValues && fieldValues['NSVS_clearance'] && fieldValues['NSVS_clearance']['expiry_date']
                ? fieldValues['NSVS_clearance']['expiry_date']
                : null,
            clearance_level:
              fieldValues && fieldValues['NSVS_clearance'] && fieldValues['NSVS_clearance']['clearance_level']
                ? fieldValues['NSVS_clearance']['clearance_level']
                : null,
            name: 'NSVS_clearance',
            designation_qualification_type_id: 13,
            person_id: props.personEntity.id,
          },
        }));
      }
    } else {
      setFieldValues(oldFieldValues => ({
        ...oldFieldValues,
        ['NSVS_clearance']: {
          value:
            fieldValues && fieldValues['NSVS_clearance'] && fieldValues['NSVS_clearance']['value']
              ? fieldValues['NSVS_clearance']['value']
              : null,
          issue_date: new Date(e),
          expiry_date:
            fieldValues && fieldValues['NSVS_clearance'] && fieldValues['NSVS_clearance']['expiry_date']
              ? fieldValues['NSVS_clearance']['expiry_date']
              : null,
          clearance_level:
            fieldValues && fieldValues['NSVS_clearance'] && fieldValues['NSVS_clearance']['clearance_level']
              ? fieldValues['NSVS_clearance']['clearance_level']
              : null,
          name: 'NSVS_clearance',
          designation_qualification_type_id: 13,
        },
      }));
    }
  };

  const handleNSVSExpiryDate = e => {
    if (!isNew) {
      if (props.personEntity.NSVS_clearance) {
        setFieldValues(oldFieldValues => ({
          ...oldFieldValues,
          ['NSVS_clearance']: {
            id: props.personEntity.NSVS_clearance.id,
            value: props.personEntity.NSVS_clearance ? props.personEntity.NSVS_clearance.value : null,
            issue_date: props.personEntity.NSVS_clearance ? props.personEntity.NSVS_clearance.issue_date : null,
            expiry_date: new Date(e),
            clearance_level: props.personEntity.NSVS_clearance ? props.personEntity.NSVS_clearance.clearance_level : null,
            name: 'NSVS_clearance',
            designation_qualification_type_id: 13,
            person_id: props.personEntity.id,
          },
        }));
      } else {
        setFieldValues(oldFieldValues => ({
          ...oldFieldValues,
          ['NSVS_clearance']: {
            id: null,
            value:
              fieldValues && fieldValues['NSVS_clearance'] && fieldValues['NSVS_clearance']['value']
                ? fieldValues['NSVS_clearance']['value']
                : null,
            issue_date:
              fieldValues && fieldValues['NSVS_clearance'] && fieldValues['NSVS_clearance']['issue_date']
                ? fieldValues['NSVS_clearance']['issue_date']
                : null,
            expiry_date: new Date(e),
            clearance_level:
              fieldValues && fieldValues['NSVS_clearance'] && fieldValues['NSVS_clearance']['clearance_level']
                ? fieldValues['NSVS_clearance']['clearance_level']
                : null,
            name: 'NSVS_clearance',
            designation_qualification_type_id: 13,
            person_id: props.personEntity.id,
          },
        }));
      }
    } else {
      setFieldValues(oldFieldValues => ({
        ...oldFieldValues,
        ['NSVS_clearance']: {
          value:
            fieldValues && fieldValues['NSVS_clearance'] && fieldValues['NSVS_clearance']['value']
              ? fieldValues['NSVS_clearance']['value']
              : null,
          issue_date:
            fieldValues && fieldValues['NSVS_clearance'] && fieldValues['NSVS_clearance']['issue_date']
              ? fieldValues['NSVS_clearance']['issue_date']
              : null,
          expiry_date: new Date(e),
          clearance_level:
            fieldValues && fieldValues['NSVS_clearance'] && fieldValues['NSVS_clearance']['clearance_level']
              ? fieldValues['NSVS_clearance']['clearance_level']
              : null,
          name: 'NSVS_clearance',
          designation_qualification_type_id: 13,
        },
      }));
    }
  };
  const checkSiaLicenceValidity = () => {
    if (fieldValues['siaLicenceNumber'] && fieldValues['siaLicenceNumber'].length >= 0) {
      if (fieldError['siaLicenceNumber_error'] === false && fieldValues['siaLicenceNumber'].length === 16) {
        if (fieldValues['expiry_date'] && fieldError['siaExpiryDate_error'] === false) {
          return true;
        } else {
          setFieldError(oldFieldError => ({
            ...oldFieldError,
            ['siaExpiryDate_error']: true,
            ['siaExpiryDate_helper']: 'Enter licence expiry date',
          }));
          return false;
        }
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const saveEntity = event => {
    event.preventDefault();
    const formData = new FormData();
    if (fieldValues['person_image'] !== undefined) {
      formData.append('avatar', fieldValues['person_image']);
    }
    const object = new FormData();
    if (personFiles.length > 0) {
      personFiles.forEach((element, index) => {
        object.append(`avatar-${index}`, element.file);
      });
      object.append('doc_type', '6');
    }
    if (isNew && checkSiaLicenceValidity()) {
      props.createEntity(fieldValues, formData, object);
      props.reset();
    }
    if (!isNew && checkSiaLicenceValidity()) {
      props.updateEntity(fieldValues, formData, object);
    }
    setShowEditPage(false);
    props.getEntity(props.match.params.id);
  };

  return (
    <div className="person-view-main-container">
      {showEditPage === false && (
        <>
          <div className="PersonProfileEditBtnContainer">
            <Button
              variant="contained"
              className="Profile_Detials_edit_btn"
              onClick={editPageHandler}
              startIcon={<img src="content/icons/Personal Details/Edit.png" />}
            >
              <span style={{ color: '#4820B8' }}>Edit</span>
            </Button>
          </div>
          <Divider style={{ margin: '4% 0' }} />
        </>
      )}

      <div className="field-container">
        <div className="certification-field-container">
          <Typography className="subcontractor-main-title-heading" variant="h5" gutterBottom style={{ color: '#4720b7' }}>
            SIA Licensing
          </Typography>

          <Grid container spacing={2}>
            <Grid item lg={5} md={6} sm={12}>
              <FormControl className="person-certification-formControl">
                <TextField
                  type="text"
                  variant="outlined"
                  onChange={handleSiaLicenceNumber}
                  placeholder="Enter your sia number"
                  label="Licence No."
                  name="siaLicenceNumber"
                  id="person_licence_number"
                  value={fieldValues['siaLicenceNumber']?.value}
                  disabled={showEditPage === false ? true : false}
                  error={fieldError['siaLicenceNumber_error']}
                  helperText={fieldError['siaLicenceNumber_error'] ? fieldError['siaLicenceNumber_helper'] : ''}
                  inputProps={{ maxLength: 16 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <img src="content/icons/Personal Details/License No..png" />
                      </InputAdornment>
                    ),
                  }}
                  onBlur={event => isValidSiaLicence(event)}
                />
              </FormControl>
            </Grid>

            <Grid item lg={4} md={6} sm={12}>
              <FormControl className="person-certification-formControl">
                <MuiPickersUtilsProvider libInstance={moment} utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk
                    disablePast={true}
                    variant="inline"
                    inputVariant="outlined"
                    label="Licence Expiry Date"
                    format={'dd/MM/yyyy'}
                    value={fieldValues['siaLicenceNumber']?.expiry_date ? fieldValues['siaLicenceNumber'].expiry_date : expiry_date}
                    disabled={showEditPage === false ? true : false}
                    error={fieldError['siaExpiryDate_error']}
                    helperText={fieldError['siaExpiryDate_error'] ? fieldError['siaExpiryDate_helper'] : ''}
                    ToolbarComponent={prop => (
                      <>
                        <CustomToolbarComponent {...prop} settingFormat={props.setting.date_format} />
                      </>
                    )}
                    placeholder={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'dd/MM/YYYY'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <img src="content/icons/Personal Details/License Expiry.png" />
                        </InputAdornment>
                      ),
                    }}
                    onChange={date => handleLicenceExpiryDate(date)}
                  />{' '}
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>

            <Grid item lg={3} md={12} sm={12}>
              <FormControl className="person-certification-formControl" style={{ width: '98%' }}>
                <TextField
                  type="text"
                  variant="outlined"
                  placeholder="Name on the Badge"
                  label="Name on the Badge"
                  name="siaLicenceNumber"
                  id="person_licence_number"
                  value={siaName ? siaName : ''}
                  disabled={showEditPage === false ? true : false}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <img src="content/icons/Personal Details/Last Name.png" />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </div>
        <Divider style={{ margin: '4% 0' }} />
      </div>

      <div className="field-container">
        <div className="certification-field-container">
          <Typography className="subcontractor-main-title-heading" variant="h5" gutterBottom style={{ color: '#4720b7' }}>
            Enhanced DBS
          </Typography>

          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={12}>
              <FormControl className="person-certification-formControl">
                <TextField
                  type="text"
                  variant="outlined"
                  onChange={handleEnhancedNumber}
                  placeholder="Certificate number"
                  label="Certificate number"
                  name="enhanced_dbs"
                  id="person_licence_number"
                  value={fieldValues['enhanced_dbs']?.value}
                  inputProps={{ maxLength: 16 }}
                  disabled={showEditPage === false ? true : false}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <img src="content/icons/Personal Details/Certificate No..png" />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item lg={6} md={6} sm={12}>
              <FormControl className="person-certification-formControl">
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                  <KeyboardDatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="Issue Date"
                    format={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'MM/dd/yyyy'}
                    value={
                      fieldValues['enhanced_dbs'] && fieldValues['enhanced_dbs']['issue_date']
                        ? fieldValues['enhanced_dbs']['issue_date']
                        : props.personEntity && props.personEntity['enhanced_dbs'] && props.personEntity['enhanced_dbs']['issue_date']
                        ? props.personEntity['enhanced_dbs']['issue_date']
                        : null
                    }
                    ToolbarComponent={prop => (
                      <>
                        <CustomToolbarComponent {...prop} settingFormat={props.setting.date_format} />
                      </>
                    )}
                    placeholder={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'MM/DD/YYYY'}
                    disabled={showEditPage === false ? true : false}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <img src="content/icons/Personal Details/DOB.png" />
                        </InputAdornment>
                      ),
                    }}
                    onChange={date => handleBDSExpiryDate(date)}
                  />{' '}
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>
          </Grid>
        </div>

        <Divider style={{ margin: '4% 0' }} />
      </div>

      <div className="field-container NSVS_Clearance_Div">
        <div className="certification-field-container">
          <Typography className="subcontractor-main-title-heading" variant="h5" gutterBottom style={{ color: '#4720b7' }}>
            NSVS Clearance
          </Typography>

          <Grid container spacing={2}>
            <Grid item lg={3} md={6} sm={6}>
              <FormControl className="person-certification-formControl">
                <TextField
                  type="text"
                  variant="outlined"
                  onChange={handleReferenceNumber}
                  placeholder="Reference number"
                  label="Reference number"
                  name="siaLicenceNumber"
                  id="person_licence_number"
                  value={fieldValues['NSVS_clearance']?.value}
                  disabled={showEditPage === false ? true : false}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <img src="content/icons/Personal Details/Post Code.png" />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item lg={3} md={6} sm={6}>
              <FormControl className="person-certification-formControl">
                <TextField
                  type="text"
                  variant="outlined"
                  onChange={handleClearanceLevel}
                  placeholder="Clearance level"
                  label="Clearance level"
                  name="siaLicenceNumber"
                  id="person_licence_number"
                  value={fieldValues['NSVS_clearance']?.clearance_level}
                  disabled={showEditPage === false ? true : false}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <img src="content/icons/Personal Details/Clearance Level.png" />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item lg={3} md={6} sm={6}>
              <FormControl className="person-certification-formControl">
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                  <KeyboardDatePicker
                    autoOk
                    disablePast={true}
                    variant="inline"
                    inputVariant="outlined"
                    label="Start Date"
                    format={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'MM/dd/yyyy'}
                    value={
                      fieldValues['NSVS_clearance'] && fieldValues['NSVS_clearance']['issue_date']
                        ? fieldValues['NSVS_clearance']['issue_date']
                        : props.personEntity && props.personEntity['NSVS_clearance'] && props.personEntity['NSVS_clearance']['issue_date']
                        ? props.personEntity['NSVS_clearance']['issue_date']
                        : null
                    }
                    ToolbarComponent={prop => (
                      <>
                        <CustomToolbarComponent {...prop} settingFormat={props.setting.date_format} />
                      </>
                    )}
                    disabled={showEditPage === false ? true : false}
                    placeholder={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'MM/DD/YYYY'}
                    InputAdornmentProps={{ position: 'start' }}
                    onChange={date => handleNSVSIssueDate(date)}
                  />{' '}
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>

            <Grid item lg={3} md={6} sm={6}>
              <FormControl className="person-certification-formControl">
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                  <KeyboardDatePicker
                    autoOk
                    disablePast={true}
                    variant="inline"
                    inputVariant="outlined"
                    label="End Date"
                    format={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'MM/dd/yyyy'}
                    value={
                      fieldValues['NSVS_clearance'] && fieldValues['NSVS_clearance']['expiry_date']
                        ? fieldValues['NSVS_clearance']['expiry_date']
                        : props.personEntity && props.personEntity['NSVS_clearance'] && props.personEntity['NSVS_clearance']['expiry_date']
                        ? props.personEntity['NSVS_clearance']['expiry_date']
                        : null
                    }
                    ToolbarComponent={prop => (
                      <>
                        <CustomToolbarComponent {...prop} settingFormat={props.setting.date_format} />
                      </>
                    )}
                    disabled={showEditPage === false ? true : false}
                    placeholder={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'MM/DD/YYYY'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <img src="content/icons/Personal Details/DOB.png" />
                        </InputAdornment>
                      ),
                    }}
                    onChange={date => handleNSVSExpiryDate(date)}
                  />{' '}
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>
          </Grid>
        </div>
      </div>

      {/* <PersonUpdateOrganizationQualification/> */}

      {props.organizationQualification && props.organizationQualification.length > 0 ? (
        <PersonUpdateOrganizationQualification
          CurrencyChecker={CurrencyChecker}
          // currency={currency}
          fieldValues={fieldValues}
          fieldError={fieldError}
          setting={props.setting}
          setFieldValues={setFieldValues}
          handleQualificationSwitch={handleQualificationSwitch}
          qualificationDiv={qualificationDiv}
          setQualificationDiv={setQualificationDiv}
          organizationQualificationList={organizationQualificationList}
          handleQualificationProofs={handleQualificationProofs}
          qualificationProofs={props.qualificationProofs}
          isNew={isNew}
          personEntity={props.personEntity}
          handleQualificationIndex={handleQualificationIndex}
          qualificationFileName={qualificationFileName}
          isEdit={showEditPage}
        />
      ) : null}

      {showEditPage === true ? (
        <>
          <Divider style={{ margin: '4% 0' }} />
          <Grid item xs={12}>
            <FormControl style={{ flexDirection: 'row', justifyContent: 'flex-end', width: '100%' }}>
              {!isNew ? (
                <>
                  <Button variant="contained" className="Person-profile-Discard-Button" onClick={profilePageHandler}>
                    Cancel
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button
                    onClick={() => {
                      saveEntity(event);
                    }}
                    variant="contained"
                    id="save-entity"
                    type="submit"
                    className="Person-profile-Save-Button"
                  >
                    Save
                  </Button>
                </>
              ) : (
                <Button
                  onClick={() => {
                    saveEntity(event);
                  }}
                  variant="contained"
                  id="save-entity"
                  type="submit"
                  className="Person-profile-Save-Button"
                >
                  Next
                </Button>
              )}
            </FormControl>
          </Grid>
        </>
      ) : null}
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  personEntity: storeState.person.entity,
  setting: storeState.PersonalSettings.storedPersonalSettings,
  organizationQualification: storeState.OrganizationQualification.storedQualifications,
});

const mapDispatchToProps = {
  createEntity,
  getEntity,
  reset,
  updateEntity,
  getOrganizationQualifications,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PersonCertificationsAndOthers);
