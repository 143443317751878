import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import timezones from '../../shared/data/timezones.json';
import PhoneInput from 'react-phone-input-2';
import './organization.scss';
import { ICountry } from 'app/shared/model/country.model';
import { ICity } from 'app/shared/model/city.model';
import { IOrganization } from 'app/shared/model/organization.model';
import { FormControl, Button } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { IState } from 'app/shared/model/state.model';
interface OrganizationUpdateComapnyInfoProps {
  countriesList?: Array<ICountry>;
  values?: IOrganization | any;
  handleCountryData?: (data: any, event: any) => void;
  handleCityData?: (data: any, event: any) => void;
  handleStateData?: (data: any, event: any) => void;
  citiesList?: Array<ICity>;
  stateList?: Array<IState>;
  setFormValues?: (data: any) => void;
  handleOrganizationPhoneNumber?: (data: any) => void;
  image?: string | any;
  handleUploadImage?: (data: any) => void;
  valuesError?: IOrganization | any;
  countryOfOperations?: number | any;
  countryTypes?: string | any;
  handleTimezoneData?: (data: any, event: any) => void;
}
const OrganizationUpdateCompanyInfo: React.FC<OrganizationUpdateComapnyInfoProps> = props => {
  return (
    <>
      <div className="organizationInnerFirstDivTitle">Company Info</div>
      <div style={{ display: 'flex' }}>
        <Paper className="organizationInnerFirstPaper">
          <div className="organizationFieldDivsideBySide">
            <Grid item xs={12} md={12} sm={12}>
              <FormControl className="organization_fields">
                <TextField
                  required
                  id="organization_name"
                  name="name"
                  onChange={props.setFormValues}
                  label="Company Name"
                  type="text"
                  variant="outlined"
                  value={props.values['name'] ? props.values['name'] : ''}
                  error={props.valuesError['organizationName_error']}
                  helperText={props.valuesError['organizationName_helper']}
                  inputProps={{ maxLength: 255 }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <FormControl className="organization_fields">
                <TextField
                  required
                  id="email"
                  name="organizationEmail"
                  onChange={props.setFormValues}
                  label="Company Email"
                  type="email"
                  variant="outlined"
                  value={props.values['organizationEmail'] ? props.values['organizationEmail'] : ''}
                  error={props.valuesError['organizationEmail_error']}
                  helperText={props.valuesError['organizationEmail_helper']}
                  inputProps={{ maxLength: 255 }}
                />
              </FormControl>
            </Grid>
          </div>
          <div className="organizationFieldDivsideBySide">
            <Grid item xs={12} md={12} sm={12}>
              <FormControl className="organization_fields">
                <PhoneInput
                  inputProps={{
                    name: 'organizationPhone',
                    autoFocus: false,
                    required: true,
                  }}
                  isValid={value => {
                    if (value === '' || value === undefined) {
                      return false;
                    }
                    return true;
                  }}
                  country={
                    props.countryOfOperations
                      ? props.countryTypes.find(country => country.value === props.countryOfOperations)
                        ? props.countryTypes.find(country => country.value === props.countryOfOperations).phoneName
                        : 'fr'
                      : 'fr'
                  }
                  value={props.values['organizationPhone']}
                  onChange={organizationPhone => props.handleOrganizationPhoneNumber(organizationPhone)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <FormControl className="organization_fields">
                <Autocomplete
                  options={
                    timezones &&
                    timezones.map(tz => ({
                      id: tz.value,
                      text: tz.label,
                    }))
                  }
                  noOptionsText={'No Records'}
                  classes={{ listbox: 'autocompletelistbox' }}
                  ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                  getOptionLabel={option => option.text || ''}
                  getOptionSelected={(option, value) => option.id === value.id}
                  className="PersonAutocomplete"
                  onChange={props.handleTimezoneData}
                  value={props.values.organizationTimezone || null}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      required
                      className="personAutocompleteTextfield autocompleteLabel"
                      label="Time period"
                      margin="normal"
                      fullWidth
                      error={props.valuesError['organizationTimezone_error']}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </div>
          <div className="organizationFieldDivsideBySide">
            <Grid item xs={12} md={12} sm={12}>
              <FormControl className="organization_fields">
                <TextField
                  required
                  variant="outlined"
                  type="text"
                  onChange={props.setFormValues}
                  placeholder="Enter postcode"
                  label="Postcode"
                  name="postCode"
                  id="organization_postCode"
                  value={props.values['postcode']}
                  inputProps={{ maxLength: 10 }}
                  error={props.valuesError['postCode_error']}
                  helperText={props.valuesError['postCode_helper']}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <FormControl className="organization_fields">
                <Autocomplete
                  options={
                    props.countriesList &&
                    props.countriesList.map(country => ({
                      id: country.id,
                      name: country.name,
                    }))
                  }
                  noOptionsText={'No Records'}
                  classes={{ listbox: 'autocompletelistbox' }}
                  ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                  getOptionLabel={option => option.name || ''}
                  getOptionSelected={(option, value) => option.id === value.id}
                  className="PersonAutocomplete"
                  onChange={props.handleCountryData}
                  value={props.values.country || null}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      required
                      className="personAutocompleteTextfield autocompleteLabel"
                      label="Country"
                      margin="normal"
                      fullWidth
                      error={props.valuesError['country_error']}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </div>
          <div className="organizationFieldDivsideBySide">
            <Grid item xs={12} md={12} sm={12}>
              <FormControl className="organization_fields">
                <Autocomplete
                  options={
                    props.stateList &&
                    props.stateList.map(state => ({
                      id: state.id,
                      name: state.name,
                    }))
                  }
                  noOptionsText={'Select Country First'}
                  classes={{ listbox: 'autocompletelistbox' }}
                  ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                  getOptionLabel={option => option.name || ''}
                  getOptionSelected={(option, value) => option.id === value.id}
                  className="PersonAutocomplete"
                  onChange={props.handleStateData}
                  value={props.values.state || null}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      required
                      className="personAutocompleteTextfield autocompleteLabel"
                      label="State"
                      margin="normal"
                      fullWidth
                      error={props.valuesError['state_error']}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <FormControl className="organization_fields">
                <Autocomplete
                  options={
                    props.citiesList &&
                    props.citiesList.map(city => ({
                      id: city.id,
                      name: city.name,
                    }))
                  }
                  noOptionsText={'Select State First'}
                  classes={{ listbox: 'autocompletelistbox' }}
                  ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                  getOptionLabel={option => option.name || ''}
                  getOptionSelected={(option, value) => option.id === value.id}
                  className="PersonAutocomplete"
                  onChange={props.handleCityData}
                  value={props.values.city || null}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      className="personAutocompleteTextfield autocompleteLabel"
                      label="City"
                      required
                      margin="normal"
                      fullWidth
                      error={props.valuesError['city_error']}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </div>
          <Grid item xs={12} md={12} sm={12}>
            <FormControl className="organizationAddressField">
              <TextField
                required
                id="organization_address"
                name="organizationAddress"
                onChange={props.setFormValues}
                placeholder="Enter business street address"
                label="Street Address"
                type="text"
                variant="outlined"
                value={props.values['organizationAddress'] ? props.values['organizationAddress'] : ''}
                error={props.valuesError['organizationAddress_error']}
                helperText={props.valuesError['organizationAddress_helper']}
                inputProps={{ maxLength: 255 }}
              />
            </FormControl>
          </Grid>
        </Paper>
        <div className="organizationUploadImage">
          <div className="organizationImagediv">
            <img src={props.image ? props.image : null} className="organizationImagesize" />
          </div>
          <input type="file" id="file-input" name="organization_image" onChange={props.handleUploadImage} style={{ display: 'none' }} />
          <label htmlFor="file-input">
            <Button variant="contained" component="span" className="organizationImageButtonStyle">
              Upload Company Logo
            </Button>
          </label>
        </div>
      </div>
    </>
  );
};

export default OrganizationUpdateCompanyInfo;
