import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { useState, useEffect } from 'react';
import '../oraganization_qualification.scss';
import { Grid, TextField, IconButton, FormControl, Button, makeStyles, FormLabel } from '@material-ui/core';
import { Radio, RadioGroup } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import { Divider } from '@material-ui/core';
import IOSSwitch from '../../RolesAndPermissionsSettings/Components/IOSswitchComponent';

export default function AddQualification(props) {
  return (
    <>
      <div style={{ display: 'flex' }}>
        <Grid item xs={10}>
          <TextField
            className="add-qualification-text-field"
            variant="outlined"
            name="name"
            onChange={() => {
              props.handleQualification(event);
            }}
            value={props.values.name}
            error={props.fieldError['name_error']}
          />
        </Grid>
      </div>

      <div className="qualification-attributes-text">Please pick attributes for this Qualification</div>

      <Grid container spacing={2}>
        <Grid item className="modal-input" xs={3} sm={3} md={3} lg={3}>
          <FormControl variant="outlined" size="small" className="qualification-checkbox-buttons">
            <FormControlLabel
              id={props.expiryCheck === true ? 'checked' : 'unchecked'}
              className="organization-qualification-checkbox"
              name="expiry"
              label="Expiry"
              labelPlacement="start"
              control={
                <Checkbox
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleCheckedFilled />}
                  onChange={event => props.handleExpiryCheck(event)}
                />
              }
              style={{
                backgroundColor: props.expiryCheck === true ? '#D48BD3' : 'white',
                color: props.expiryCheck === true ? 'white' : 'black',
              }}
            />
          </FormControl>
        </Grid>

        <Grid item className="modal-input" xs={3} sm={3} md={3} lg={3}>
          <FormControl variant="outlined" size="small" className="qualification-checkbox-buttons">
            <FormControlLabel
              id={props.proofCheck === true ? 'checked' : 'unchecked'}
              className="organization-qualification-checkbox"
              name="attachProof"
              label="Attach Proof"
              labelPlacement="start"
              control={
                <Checkbox
                  checked={props.values.proof_required}
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleCheckedFilled />}
                  onChange={event => props.handleProofCheck(event)}
                />
              }
              style={{
                backgroundColor: props.proofCheck === true ? '#D48BD3' : 'white',
                color: props.proofCheck === true ? 'white' : 'black',
              }}
            />
          </FormControl>

          {props.proofCheck === true ? (
            <div>
              {props.optionalSwitch === true ? (
                <a style={{ fontWeight: 'normal' }}>Mandatory</a>
              ) : (
                <a style={{ fontWeight: 'normal' }}>Optional</a>
              )}
              <IOSSwitch onChange={event => props.handleProofSwitch(event)}> </IOSSwitch>
            </div>
          ) : null}
        </Grid>
      </Grid>

      <Divider style={{ color: '#00000066', width: '90%', marginTop: '50px', marginBottom: '50px' }} />
    </>
  );
}
