import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './designation-qualification.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IDesignationQualificationDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const DesignationQualificationDetail = (props: IDesignationQualificationDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { designationQualificationEntity } = props;
  return (
    <div>
      <Grid item container>
        <Grid item xs={8}>
          <h2 data-cy="designationQualificationDetailsHeading">
            <Translate contentKey="wfmApp.designationQualification.detail.title">DesignationQualification</Translate>
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="id">
                <Translate contentKey="global.field.id">ID</Translate>
              </span>
            </dt>
            <dd>{designationQualificationEntity.id}</dd>
            <dt>
              <span id="name">
                <Translate contentKey="wfmApp.designationQualification.name">Name</Translate>
              </span>
            </dt>
            <dd>{designationQualificationEntity.name}</dd>
            <dt>
              <span id="value">
                <Translate contentKey="wfmApp.designationQualification.value">Value</Translate>
              </span>
            </dt>
            <dd>{designationQualificationEntity.value}</dd>
            <dt>
              <span id="issueDate">
                <Translate contentKey="wfmApp.designationQualification.issueDate">Issue Date</Translate>
              </span>
            </dt>
            <dd>
              {designationQualificationEntity.issue_date ? (
                <TextFormat value={designationQualificationEntity.issue_date} type="date" format={APP_LOCAL_DATE_FORMAT} />
              ) : null}
            </dd>
            <dt>
              <span id="expiryDate">
                <Translate contentKey="wfmApp.designationQualification.expiryDate">Expiry Date</Translate>
              </span>
            </dt>
            <dd>
              {designationQualificationEntity.expiry_date ? (
                <TextFormat value={designationQualificationEntity.expiry_date} type="date" format={APP_LOCAL_DATE_FORMAT} />
              ) : null}
            </dd>
            <dt>
              <Translate contentKey="wfmApp.designationQualification.designationQualificationType">
                Designation Qualification Type
              </Translate>
            </dt>
            <dd>
              {designationQualificationEntity.designationQualificationType
                ? designationQualificationEntity.designationQualificationType.name
                : ''}
            </dd>
          </dl>
          <Button
            component={Link}
            to="/designation-qualification"
            replace
            style={{ color: 'black', backgroundColor: 'rgb(13, 202, 240)' }}
            data-cy="entityDetailsBackButton"
          >
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button
            component={Link}
            to={`/designation-qualification/${designationQualificationEntity.id}/edit`}
            replace
            style={{ color: 'white', backgroundColor: 'blue' }}
          >
            <FontAwesomeIcon icon="pencil-alt" />
            &nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = ({ designationQualification }: IRootState) => ({
  designationQualificationEntity: designationQualification.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(DesignationQualificationDetail);
