import React, { useState, useEffect } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import { DocumentRecordComponent } from './documentsRecordComponent';

export const VettingDocumentsPersonalDetails = props => {
  const [isOpenFront, setIsOpenFront] = useState(false);
  const [isOpenBack, setIsOpenBack] = useState(false);

  const { item } = props;
  return (
    <div className="vetting-documents-about-you-details">
      <div className="vetting-documents-about-you-details-inner-divs"> First Name: Adeel </div>
      <div className="vetting-documents-about-you-details-inner-divs"> Last Name: Ahmad </div>
      <div className="vetting-documents-about-you-details-inner-divs"> Current Address: B#22 </div>
      <div className="vetting-documents-about-you-details-top-table-rows-div">
        <Table style={{ borderCollapse: 'separate', borderSpacing: '0px 15px' }}>
          <TableRow>
            <TableCell scope="left" className="documents-about-you-table-cells-dark">
              Date of Birth
            </TableCell>
            <TableCell align="left" className="documents-about-you-table-cells-light">
              {item.date_of_birth}
            </TableCell>
            <TableCell align="left" className="documents-about-you-table-cells-dark">
              Post Code
            </TableCell>
            <TableCell align="left" className="documents-about-you-table-cells-light">
              {item.post_code}
            </TableCell>
            <TableCell align="left" className="documents-about-you-table-cells-dark">
              National Insurance No.
            </TableCell>
            <TableCell align="left" className="documents-about-you-table-cells-light">
              {item.national_insurance_no}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell scope="left" className="documents-about-you-table-cells-dark">
              Telephone No.
            </TableCell>
            <TableCell align="left" className="documents-about-you-table-cells-light">
              {item.telephone_no}
            </TableCell>
            <TableCell align="left" className="documents-about-you-table-cells-dark">
              Mobile No.
            </TableCell>
            <TableCell align="left" className="documents-about-you-table-cells-light">
              {item.mobile_no}
            </TableCell>
            <TableCell align="left" className="documents-about-you-table-cells-dark">
              Email Address:
            </TableCell>
            <TableCell align="left" className="documents-about-you-table-cells-light">
              {item.email_address}
            </TableCell>
          </TableRow>
        </Table>
      </div>
      <div className="vetting-documents-about-you-details-job-availability"> Job Availability: Yes </div>
      <div className="vetting-documents-about-you-details-job-availability">
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vel quia quibusdam ab, illum, at illo eum veritatis distinctio nam
        recusandae non quaerat blanditiis facilis ut ad assumenda sit? Maxime, dolorem.
      </div>
      <div className="vetting-documents-about-you-details-top-table-rows-div">
        <Table style={{ borderCollapse: 'separate', borderSpacing: '0px 15px' }}>
          <TableRow>
            <TableCell scope="left" className="documents-about-you-table-cells-dark">
              Can Work in UK?
            </TableCell>
            <TableCell align="left" className="documents-about-you-table-cells-light">
              {item.work_uk}
            </TableCell>
            <TableCell align="left" className="documents-about-you-table-cells-dark">
              Convicted of a crime?
            </TableCell>
            <TableCell align="left" className="documents-about-you-table-cells-light">
              {item.crime_conviction}
            </TableCell>
            <TableCell align="left" className="documents-about-you-table-cells-dark">
              UK/EU Driving License Holder
            </TableCell>
            <TableCell align="left" className="documents-about-you-table-cells-light">
              {item.driving_license_uk_uae}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell scope="left" className="documents-about-you-table-cells-dark">
              Name of License
            </TableCell>
            <TableCell align="left" className="documents-about-you-table-cells-light">
              {item.license_name}
            </TableCell>
            <TableCell align="left" className="documents-about-you-table-cells-dark">
              SIA License No.
            </TableCell>
            <TableCell align="left" className="documents-about-you-table-cells-light">
              {item.sia_license_no}
            </TableCell>
            <TableCell align="left" className="documents-about-you-table-cells-dark">
              SIA License Expires
            </TableCell>
            <TableCell align="left" className="documents-about-you-table-cells-light">
              {item.license_expiration}
            </TableCell>
          </TableRow>
        </Table>
      </div>
      <div className="vetting-documents-about-you-details-uploaded-docs"> Uploaded Document(s): </div>
      <DocumentRecordComponent
        setIsOpen={setIsOpenFront}
        url={item.sia_license_image_front}
        isOpen={isOpenFront}
        name="Sia License Front"
      />
      <DocumentRecordComponent setIsOpen={setIsOpenBack} url={item.sia_license_image_back} isOpen={isOpenBack} name="Sia License Back" />
    </div>
  );
};
