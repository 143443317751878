import React from 'react';
import PermissionsRowComponent from './PermissionsRowComponent';

export default function PermissionsGeneralComponent(props) {
  return (
    <React.Fragment>
      <PermissionsRowComponent
        msg="Can access Admin Portal?"
        ls={props.supervisorPerm && props.supervisorPerm.admin_portal}
        ds={props.userPerm && props.userPerm.admin_portal}
        ma={props.mngrPerm && props.mngrPerm.admin_portal}
        sa={props.orgAdminPerm && props.orgAdminPerm.admin_portal}
        name="admin_portal"
        handler={props.handleChange}
      />
      <PermissionsRowComponent
        msg="Can see and edit financial information on people,shifts and reports?"
        ls={props.supervisorPerm && props.supervisorPerm.financial_info}
        ds={props.userPerm && props.userPerm.financial_info}
        ma={props.mngrPerm && props.mngrPerm.financial_info}
        sa={props.orgAdminPerm && props.orgAdminPerm.financial_info}
        name="financial_info"
        handler={props.handleChange}
      />
      <PermissionsRowComponent
        msg="Can grant and remove roles for people?"
        ls={props.supervisorPerm && props.supervisorPerm.add_remove_roles}
        ds={props.userPerm && props.userPerm.add_remove_roles}
        ma={props.mngrPerm && props.mngrPerm.add_remove_roles}
        sa={props.orgAdminPerm && props.orgAdminPerm.add_remove_roles}
        name="add_remove_roles"
        handler={props.handleChange}
      />
      <PermissionsRowComponent
        msg="Can manage Smart Work Force account details ?"
        ls={props.supervisorPerm && props.supervisorPerm.account_details}
        ds={props.userPerm && props.userPerm.account_details}
        ma={props.mngrPerm && props.mngrPerm.account_details}
        sa={props.orgAdminPerm && props.orgAdminPerm.account_details}
        name="account_details"
        handler={props.handleChange}
      />
    </React.Fragment>
  );
}
