import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import CountryAdminUnitType from './country-admin-unit-type';
import CountryAdminUnitTypeDetail from './country-admin-unit-type-detail';
import CountryAdminUnitTypeUpdate from './country-admin-unit-type-update';
import CountryAdminUnitTypeDeleteDialog from './country-admin-unit-type-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={CountryAdminUnitTypeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={CountryAdminUnitTypeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={CountryAdminUnitTypeDetail} />
      <ErrorBoundaryRoute path={match.url} component={CountryAdminUnitType} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={CountryAdminUnitTypeDeleteDialog} />
  </>
);

export default Routes;
