import { exportPDF } from './exportPDF';

const header = ['ID No.', 'Client Name', 'Country of Residence', 'Contact Person', 'Contact Details'];

export const convertToPDF = jsonData => {
  const headers = [header];
  const title = 'Client Report';

  const data = jsonData.map(row => [
    row.identification_number,
    row.clientName,
    row.state.name,
    row.personFirstName + ' ' + row.personLastName,
    row.phone + '\n ' + row.personEmail,
  ]);
  const filename = 'Client_Report.pdf';

  exportPDF(headers, title, data, filename);
};
