import { Button, FormControl, FormControlLabel, FormLabel, Grid, MenuItem, Paper, Radio, RadioGroup, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { ICity } from 'app/shared/model/city.model';
import { ICountry } from 'app/shared/model/country.model';
import { IPerson } from 'app/shared/model/person.model';
import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import './person.scss';

interface PersonUpdateGeneralInformationProps {
  gender?: string | any;
  genderhandleChange?: (data: any) => void;
  setFormValues?: (data: any) => void;
  handleIncomeTaxNumber?: (data: any) => void;
  handlePassportNumber?: (data: any) => void;
  handleKwspNumber?: (data: any) => void;
  personEntity?: IPerson | any;
  fieldValues?: IPerson | any;
  fieldError?: Record<string, any>;
  phoneNumber?: string | any;
  handlePhoneNumber?: (data: any) => void;
  handleAddress?: (data: any) => void;
  handleCountryData?: (data: any, event: any) => void;
  countriesList?: Array<ICountry>;
  showMalaysiaFields: boolean;
  handleCityData?: (data: any, event: any) => void;
  citiesList?: Array<ICity>;
  imageFile?: string | any;
  handleProfilePicture?: (data: any) => void;
  verifyEmail?: (data: any) => Record<string, any> | any;
  setFieldError?: (data: any) => void;
  countryOfOperations?: number | any;
  countryTypes?: string | any;
  classes?: any;
  handleEmail?: (data: any) => void;
  isNew?: boolean;
}

const PersonUpdateGeneralInformation: React.FC<PersonUpdateGeneralInformationProps> = props => {
  const onBlurEmailField = async event => {
    if (props.fieldError['email_error'] !== true) {
      if (event.target.value !== undefined && event.target.value !== '') {
        const duplicateEmail = await props.verifyEmail(event.target.value);

        if (duplicateEmail.response.status === 400 || duplicateEmail.response.status === 500) {
          props.setFieldError(oldFieldError => ({ ...oldFieldError, ['email_error']: true }));
          props.setFieldError(oldFieldError => ({ ...oldFieldError, ['email_helper']: 'This Email has already been taken' }));
        }
        return duplicateEmail;
      }
    }
  };

  const selectCountryFirst = 'Select Country First' as any;
  return (
    <React.Fragment>
      <Paper className="personInnerFirstPaper">
        <Grid item xs={12} md={12} sm={12}>
          <FormControl component="fieldset" className="person-fields">
            <FormLabel component="legend" style={{ color: 'black' }}>
              Gender
            </FormLabel>
            <RadioGroup
              aria-label="gender"
              name="gender1"
              className="personRadioButtons"
              value={props.fieldValues['gender'] ? props.fieldValues['gender'] : null}
              onChange={props.genderhandleChange}
            >
              <FormControlLabel value="male" control={<Radio style={{ color: '#4620B7' }} />} label="Male" />
              <FormControlLabel value="female" control={<Radio style={{ color: '#4620B7' }} />} label="Female" />
              <FormControlLabel value="other" control={<Radio style={{ color: '#4620B7' }} />} label="Other" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <div className="personFieldDivsideBySide">
          {!props.isNew ? (
            <Grid item xs={12} md={6} sm={12} justify="flex-start">
              <FormControl className="person-fields">
                <TextField
                  id="person-id"
                  variant="outlined"
                  name="id"
                  required
                  fullWidth
                  inputProps={{ readOnly: true }}
                  label="ID"
                  value={props.fieldValues.id ? props.fieldValues.id : ''}
                />
              </FormControl>
            </Grid>
          ) : null}
          <Grid item xs={12} md={6} sm={12}>
            <FormControl className="person-fields">
              <TextField
                variant="outlined"
                label="Id / Contract Number"
                name="identification_number"
                onChange={props.setFormValues}
                value={props.fieldValues['identification_number'] ? props.fieldValues['identification_number'] : ''}
                error={props.fieldError['identification_number_error']}
                helperText={props.fieldError['identification_number_helper']}
                inputProps={{ maxLength: 15 }}
              />
            </FormControl>
          </Grid>
        </div>
        <div className="personFieldDivsideBySide">
          <Grid item xs={12} md={6} sm={12}>
            <FormControl className="person-fields">
              <TextField
                variant="outlined"
                type="text"
                onChange={props.setFormValues}
                placeholder="Enter your first name here"
                label="First Name"
                required
                name="first_name"
                id="person_first_name"
                value={props.fieldValues['first_name'] ? props.fieldValues['first_name'] : ''}
                error={props.fieldError['first_name_error']}
                helperText={props.fieldError['first_name_helper']}
                inputProps={{ maxLength: 255 }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} sm={12}>
            <FormControl className="person-fields">
              <TextField
                variant="outlined"
                type="text"
                onChange={props.setFormValues}
                placeholder="Enter your last name here"
                label="Last Name"
                required
                name="last_name"
                id="person_last_name"
                value={props.fieldValues['last_name'] ? props.fieldValues['last_name'] : ''}
                error={props.fieldError['last_name_error']}
                helperText={props.fieldError['last_name_helper']}
                inputProps={{ maxLength: 255 }}
              />
            </FormControl>
          </Grid>
        </div>
        <div className="personFieldDivsideBySide">
          <Grid item xs={12} md={6} sm={12}>
            <FormControl className="person-fields">
              <TextField
                variant="outlined"
                type="text"
                onChange={props.handleEmail}
                disabled={!props.isNew}
                placeholder="Enter your email ID"
                label="Email"
                required
                name="email"
                id="person_email"
                value={props.fieldValues['email'] ? (props.fieldValues['email'][0] ? props.fieldValues['email'][0]['value'] : '') : ''}
                error={props.fieldError['email_error']}
                helperText={props.fieldError['email_helper']}
                inputProps={{ maxLength: 255 }}
                onBlur={event => onBlurEmailField(event)}
                InputLabelProps={{ shrink: props.fieldValues['email'] ? (props.fieldValues['email'][0] ? true : false) : false }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} sm={12}>
            <FormControl className="person-fields">
              <PhoneInput
                inputProps={{
                  name: 'phone',
                  autoFocus: false,
                }}
                country={
                  props.countryOfOperations
                    ? props.countryTypes.find(country => country.value === props.countryOfOperations)
                      ? props.countryTypes.find(country => country.value === props.countryOfOperations).phoneName
                      : 'fr'
                    : 'fr'
                }
                value={
                  props.fieldValues['phoneNumber']
                    ? props.fieldValues['phoneNumber'][0]
                      ? props.fieldValues['phoneNumber'][0]['value']
                      : null
                    : null
                }
                onChange={props.handlePhoneNumber}
                placeholder="Enter your phone no."
              />
              {<span style={{ color: 'red', fontSize: '17' }}>{props.fieldError['phoneNumber_helper']}</span>}
            </FormControl>
          </Grid>
        </div>
        <div className="personFieldDivsideBySide">
          <Grid item xs={12} md={6} sm={12}>
            <FormControl className="person-fields">
              <TextField
                variant="outlined"
                type="text"
                onChange={props.handleAddress}
                placeholder="Enter your address here"
                label="Address"
                name="address"
                id="person_address"
                required
                value={
                  props.fieldValues['address']
                    ? props.fieldValues['address'][0]
                      ? props.fieldValues['address'][0]['address_line_one']
                      : null
                    : null
                }
                error={props.fieldError['address_error']}
                helperText={props.fieldError['address_helper']}
                inputProps={{ maxLength: 255 }}
                InputLabelProps={{ shrink: Boolean(props.fieldValues['address']?.[0]?.address_line_one) || props.fieldValues['address'] }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} sm={12}>
            <FormControl className="person-fields">
              <TextField
                variant="outlined"
                type="text"
                onChange={props.setFormValues}
                placeholder="Enter your postCode"
                label="Post Code"
                name="postCode"
                id="person_postCode"
                value={props.fieldValues['postCode'] ? props.fieldValues['postCode'] : ''}
                inputProps={{ maxLength: 255 }}
                InputLabelProps={{ shrink: props.fieldValues['postCode'] ? true : false }}
              />
            </FormControl>
          </Grid>
        </div>
        <div className="personFieldDivsideBySide">
          <Grid item xs={12} md={6} sm={12}>
            <FormControl className="person-fields">
              <Autocomplete
                options={
                  props.countriesList &&
                  props.countriesList.map(country => ({
                    id: country.id,
                    name: country.name,
                  }))
                }
                noOptionsText={'No Records'}
                classes={{ listbox: props.classes.listbox }}
                ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                getOptionLabel={option => option.name}
                className="PersonAutocomplete"
                onChange={props.handleCountryData}
                value={props.fieldValues['country'] ? props.fieldValues['country'] : null}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    className="personAutocompleteTextfield autocompleteLabel"
                    label="Country"
                    margin="normal"
                    fullWidth
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} sm={12}>
            <FormControl className="person-fields">
              <Autocomplete
                options={
                  props.citiesList &&
                  props.citiesList.map(city => ({
                    id: city.id,
                    name: city.name,
                  }))
                }
                noOptionsText={'Select Country First'}
                classes={{ listbox: props.classes.listbox }}
                ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                getOptionLabel={option => option.name || ''}
                className="PersonAutocomplete"
                onChange={props.handleCityData}
                value={props.fieldValues['city'] ? props.fieldValues['city'] : null}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    className="personAutocompleteTextfield autocompleteLabel"
                    label="City"
                    margin="normal"
                    fullWidth
                  />
                )}
              />
            </FormControl>
          </Grid>
        </div>
        {props.showMalaysiaFields && (
          <>
            <div className="personFieldDivsideBySide">
              <Grid item xs={12} md={6} sm={12}>
                <FormControl className="person-fields">
                  <TextField
                    className="clientCompanyName"
                    variant="outlined"
                    label="Income Tax Number"
                    name="income_tax_number"
                    value={props.fieldValues['income_tax_number'] ? props.fieldValues['income_tax_number']['value'] : ''}
                    onChange={props.handleIncomeTaxNumber}
                    inputProps={{ maxLength: 13 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} sm={12}>
                <FormControl className="person-fields">
                  <TextField
                    className="clientCompanyName"
                    variant="outlined"
                    label="Passport Number"
                    name="passport_number"
                    value={props.fieldValues['passport_number'] ? props.fieldValues['passport_number']['value'] : ''}
                    onChange={props.handlePassportNumber}
                    inputProps={{ minLength: 22 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </Grid>
            </div>
            <div className="personFieldDivsideBySide">
              <Grid item xs={12} md={6} sm={12}>
                <FormControl className="person-fields mb-2">
                  <TextField
                    className="clientCompanyName"
                    variant="outlined"
                    label="Kwsp Number"
                    name="kwsp_number"
                    value={props.fieldValues['kwsp_number'] ? props.fieldValues['kwsp_number']['value'] : ''}
                    onChange={props.handleKwspNumber}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </Grid>
            </div>
          </>
        )}
      </Paper>
      <div className="personUploadImage">
        <div className="personImagediv">
          <img src={props.imageFile ? (props.imageFile !== null ? props.imageFile : null) : null} className="personImagesize" />
        </div>
        <input type="file" id="file-input" name="person_image" onChange={props.handleProfilePicture} style={{ display: 'none' }} />
        <label htmlFor="file-input">
          <Button variant="contained" component="span" className="personImageButtonStyle">
            Upload a picture
          </Button>
        </label>
      </div>
    </React.Fragment>
  );
};
export default PersonUpdateGeneralInformation;
