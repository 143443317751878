import { getReport } from 'app/entities/timesheet-summary/timesheet-summary.reducer';
import React, { useEffect, useCallback, useRef, useState, useLayoutEffect } from 'react';
import { calendars } from '../../shared/util/calender-utils';
import TUICalendar from '@khired/toast-ui.react-calendar';
import { RouteComponentProps } from 'react-router-dom';
import { makeStyles, Paper, Button } from '@material-ui/core';
import { monthPrefix, weeks } from '../../../utils/global';
import CalendarFilters from './calendarsFilters';
import EmployeeHistory from './employeeHistory';
import { IRootState } from 'app/shared/reducers';
import Divider from '@material-ui/core/Divider';
import ModalComponent from './calendarModal';
import CalendarHeader from './CalendarHeader';
import { Storage } from 'react-jhipster';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  getPersonsList,
  storeScheduledShift,
  deleteScheduledShift,
  deleteSeriesShift,
  getScheduledShifts,
  getNoOfCheckPoints,
  updateShiftOccurence,
  getScheduleEditHistory,
  shiftDetails,
} from './calendar.reducer';
import {
  getBannedEmployeesOfOrganization,
  getEntities as getLocations,
  reset as resetLocations,
} from '../../entities/service-area/service-area.reducer';
import { getEntities as getDesignations, reset as resetPositions } from '../../entities/designation/designation.reducer';
import { getLeavesForCalendar } from '../../entities/leave-management/leave.reducer';
import {
  calendarDateconverter,
  YearPrefix,
  dayPrefix,
  check_time_overlap,
  calculateShiftDuration,
  formatDate,
} from '../../../utils/TimeFormatAndTimezone';
import '../../../content/css/calender.css';
import check_in_frequencies from '../../shared/data/check_in_frequencies.json';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteModal from './deleteModal';
import '../../../../../../node_modules/@khired/kwfm-toastui-calender/dist/tui-calendar.css';
import {
  resetModal,
  handleResetWeek,
  objfinder,
  timeOverlapWithPreviousShift,
  applyPeriodShiftsFilter,
  sorting,
  _getFormattedTime,
  objectFindByKey,
  _getTimeTemplate,
} from './calendarModalFunctions';
import '../../../content/css/loading.css';
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS, Step } from 'react-joyride';
import { AUTHORITIES } from 'app/config/constants';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { ContactsOutlined } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  filterButton: {
    margin: theme.spacing(1),
  },
  filterTitle: {
    margin: theme.spacing(1),
  },
  weekLists: {
    width: '100%',
    display: 'flex',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer - 1,
    color: '#fff',
  },
  calendarRightScroll: {
    float: 'right',
  },
  calendarLeftScroll: {
    float: 'left',
  },
  dropdownTick: {
    backgroundColor: '#43D8B0!important',
    borderRadius: '20px',
    marginTop: '5px',
  },
  listbox: {
    border: '1px solid #dfdbdb !important',
  },
}));

export interface IPersonProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string; id: string }> {}

const SchedulingCalender = (props: IPersonProps) => {
  const [startDayWeek, setStartDayOfWeek] = useState(
    props.settings.start_day_of_week
      ? props.settings.start_day_of_week
      : JSON.parse(localStorage.getItem('IndividualSetting')).start_day_of_week
  );
  const classes = useStyles();
  const cal = useRef(null);
  const [locale, setLocale] = useState(props.setting.time_format);
  const [calendarFiltersData, setCalendarFiltersData] = useState({
    leavesFilter: false,
    colorFilter: false,
    filtersEnabled: false,
    filteredShifts: [],
    filteredPersons: [],
  });
  const [calendarPropsData, setCalendarPropsData] = useState({
    leavesData: [],
    scheduledShifts: [],
    locations: [],
    positions: [],
    entities: [],
  });
  const [calenderMode, setCalenderMode] = useState('week');
  const [currency, setCurrency] = useState(props.settings.currency);
  const [timezoneFormat, setTimezoneFormat] = useState(props.setting.timezone);
  const [shiftDuration, setDuration] = useState(props.setting.shift_duration ? props.setting.shift_duration : 12);
  const [openEmployeeHistory, setOpenEmployeeHistory] = useState(false);
  const [deleteModalSchedule, setDeleteModalSchedule] = useState(false);
  const [isRepeatShift, setIsRepeatShift] = useState(false);
  const [leaveRecords, setLeaveRecords] = useState(props.leavesObj);
  const [values, setValues] = useState({
    people: '',
    from_date: '',
    to_date: '',
    newPage: 1,
    location: '',
    position: '',
    department: '',
    subcontractor: '',
  });
  const [showDetailModal, setShowDetailModal] = useState<{ mouseX: number; mouseY: number } | null>(null);
  const [calendarDisplayWindow, setCalendarDisplayWindow] = useState({ start_date: '', end_date: '' });
  const [modalForm, setModalForm] = useState({
    id: null,
    start_date: moment(new Date().toISOString()).format('YYYY-MM-DD'),
    end_date: moment(new Date().toISOString()).format('YYYY-MM-DD'),
    person: null,
    service_area: null,
    designation: '0',
    note: null,
    pay_rate: 10,
    charge_rate: 15,
    subcontractor: null,
    organization: null,
    no_of_breaks: 0,
    start_time: new Date(),
    end_time: moment(new Date()).add(shiftDuration, 'hours'),
    check_call_frequency: { label: 'Not Required', id: 1 },
    isRepeat: false,
    repeatEnd: moment(new Date().toISOString()).format('YYYY-MM-DD'),
    repeatType: 'Day',
    repeatPattern: '1',
    patrol_frequency: 0,
    repeatPatternObject: { id: 0, text: 'Does not repeat' },
    duration: shiftDuration,
    selectedCompany: null,
    timeOverlapPersons: [],
    repeatEvery: { value: 'Day' },
    shift_Id: null,
    copied_Id: null,
    getBeforeCreateData: {},
    modalSchedulePaste: false,
    checkPersonListInBannedPeople: [],
    week: weeks,
    modalScheduleUpdate: false,
    setModalOpen: false,
    dbShiftData: [],
  });
  const [fieldError, setFieldError] = useState({
    modalScheduleLocation_error: false,
    modalScheduleDesignation_error: false,
    timeGapWithPerviousShift_warning: false,
    shiftConflict_helper: '',
    personField_error: false,
    personField_helper: '',
    personShift_error: false,
    personShift_helper: '',
    timeOverlap_error: false,
    timeOverlap_helper: '',
    banned_error: false,
    banned_helper: '',
    dateError: false,
    selectCompanyField_error: false,
  });
  const [run, setRunState] = useState(false);
  const [runAgain, setRunAgainState] = useState(false);
  const [first_login] = useState(Storage.local.get('first_login'));
  const { state } = props.location;

  useEffect(() => {
    props.resetPositions();
    props.resetLocations();
    applyPeriodShiftsFilter('WEEK', props, setCalendarDisplayWindow);
    getShiftDetails(props.match.params.id);
    setModalForm({
      ...modalForm,
      end_time: moment(modalForm.start_time).add(props.setting.shift_duration ? props.setting.shift_duration : 12, 'hours'),
    });
    // repeated
    setModalForm(oldValues => ({
      ...oldValues,
      end_time: moment(modalForm.start_time).add(props.setting.shift_duration ? props.setting.shift_duration : 12, 'hours'),
    }));
    // repeated
    if (props.entities.length === 0 || props.locations.length === 0 || props.positions.length === 0) {
      props.getPersonsList({ from_date: '', to_date: '', current_day: moment().format('YYYY-MM-DD') });
      props.getLocations(-1, '');
      props.getDesignations(-1);
    }
    if (props.bannedEmployees.length === 0) {
      props.getBannedEmployeesOfOrganization();
    }
    if (first_login === 'true' && props.isOrganizationAdministrator && props.shifts.length < 1) {
      setRunState(true);
    }
  }, []);

  useEffect(() => {
    if (first_login === 'true' && props.isOrganizationAdministrator && props.joyRide === true) {
      setRunAgainState(true);
    }
    if (runAgain && runAgain === true) {
      setRunState(false);
    }
  }, [props.shifts]);

  const getShiftDetails = async id => {
    const calenderDetails = await shiftDetails(id);

    if (calenderDetails !== undefined) {
      showShiftView(calenderDetails);
    }
  };

  useEffect(() => {
    if (state) {
      if (state === true) {
        setModalForm(oldValues => ({ ...oldValues, setModalOpen: true }));
      } else {
        getShiftDetails(state);
      }
    }
  }, [state]);

  useEffect(() => {
    props.getLeavesForCalendar();
  }, []);

  useEffect(() => {
    if (props.shifts.length > 0 && calendarFiltersData['leavesFilter'] === false) {
      const shifts_new = props.shifts.map(x => {
        x.start = moment(moment(x.start_date).format('YYYY-MM-DD') + ' ' + moment(x.start_time).format('HH:mm')).format(
          `MM/DD/YYYY ${locale === 1 ? 'HH:mm' : 'hh:mm A'}`
        );
        x.end = moment(moment(x.start_date).format('YYYY-MM-DD') + ' ' + moment(x.end_time).format('HH:mm')).format(
          `MM/DD/YYYY ${locale === 1 ? 'HH:mm' : 'hh:mm A'}`
        );

        if (x.person && x.person && x.person.id) {
          x.category = x.person.first_name + ' ' + x.person.last_name + '__' + x.person.id;
          x.calendarId = 1;
          x.isAllDay = false;
          x.isVisible = true;
          x.title = calenderMode === 'month' ? x.service_area.name + '\n' + x.start + '\n' + x.end : '';
          x.bgColor = getShiftColor(x.start_time);
          x.borderColor = getBorderColor(x);
          return x;
        }
        x.category = x.person === null ? 'Open Shift' : 'allday';
        x.calendarId = 2;
        x.isAllDay = true;
        x.isVisible = true;
        x.title = calenderMode === 'month' ? x.service_area.name + '\n' + x.start + '\n' + x.end : '';
        x.bgColor = getShiftColor(x.start_time);
        x.borderColor = getShiftColor(x.start_time);
        return x;
      });
      setModalForm(oldValues => ({ ...oldValues, week: sorting(startDayWeek, modalForm), dbShiftData: shifts_new }));
      setCalendarPropsData(oldValues => ({ ...oldValues, scheduledShifts: shifts_new, entities: sortCalendarPersons() }));
    } else if (props.leaves.length > 0 && calendarFiltersData['leavesFilter'] === true) {
      const shifts_new = props.leaves.map(x => {
        x.start = moment(moment(x.start_date).format('YYYY-MM-DD') + ' ' + moment(x.start_time).format('HH:mm')).format(
          `MM/DD/YYYY ${locale === 1 ? 'HH:mm' : 'hh:mm A'}`
        );
        x.end = moment(
          moment(x.end_date ? x.end_date : x.start_date).format('YYYY-MM-DD') + ' ' + moment(x.end_time).format('HH:mm')
        ).format(`MM/DD/YYYY ${locale === 1 ? 'HH:mm' : 'hh:mm A'}`);

        if (x.person && x.person && x.person.id) {
          x.category = x.person.first_name + ' ' + x.person.last_name + '__' + x.person.id;
          x.calendarId = 1;
          x.isAllDay = false;
          x.isVisible = true;
          x.title = calenderMode === 'month' ? x.leave_type.name + '\n' + x.start + '\n' + x.end : '';
          x.bgColor = getShiftColor(x.start_time);
          x.borderColor = getShiftColor(x.start_time);
          x.bgColor = '#2fa7a7';
          x.borderColor = '#2fa7a7';

          return x;
        }
        x.category = x.person === null ? 'Open Shift' : 'allday';
        x.calendarId = 2;
        x.isAllDay = true;
        x.isVisible = true;
        x.title = calenderMode === 'month' ? x.service_area.name + '\n' + x.start + '\n' + x.end : '';
        x.bgColor = '#2fa7a7';
        x.borderColor = '#2fa7a7';
        return x;
      });
      setModalForm(oldValues => ({ ...oldValues, week: sorting(startDayWeek, modalForm), dbShiftData: shifts_new }));
      setCalendarPropsData(oldValues => ({ ...oldValues, leavesData: shifts_new, entities: sortCalendarPersons() }));
      setCalendarFiltersData(oldValues => ({ ...oldValues, filteredShifts: shifts_new }));
    }
    document.addEventListener('click', e => {
      const target = e.target as HTMLTextAreaElement;
      if (target.id.includes('employee')) {
        const peopleId = target.id.split('-')[1];
        setValues({ ...values, people: peopleId });
        handleEmployeeHistory();
      }
    });
    document.addEventListener('contextmenu', e => {
      const target = e.target as HTMLTextAreaElement;
      if (target?.className?.includes('tui-full-calendar')) {
        e.preventDefault();
        localStorage.setItem('coordinates', JSON.stringify({ X: e.clientX, Y: e.clientY }));
      }
    });
  }, [props.shifts, props.leaves, calendarFiltersData.leavesFilter]);

  useEffect(() => {
    if (props.bannedEmployees.length === 0) {
      props.getBannedEmployeesOfOrganization();
    }
  }, []);

  useEffect(() => {
    if (values.people) {
      props.getReport(values);
    }
  }, [values]);

  useEffect(() => {
    if (props.leavesObj) {
      setLeaveRecords(props.leavesObj);
    }
  }, [props.leavesObj]);

  useEffect(() => {
    if (props.entities && props.entities.length > 0) {
      setCalendarPropsData(oldValues => ({ ...oldValues, entities: sortCalendarPersons() }));
    }
  }, [props.entities]);

  useEffect(() => {
    if (props.locations && props.locations.length > 0) {
      setCalendarPropsData(oldValues => ({ ...oldValues, locations: [...props.locations] }));
    }
  }, [props.locations]);

  useEffect(() => {
    if (props.positions && props.positions.length > 0) {
      setCalendarPropsData(oldValues => ({ ...oldValues, positions: [...props.positions] }));
    }
  }, [props.positions]);

  useEffect(() => {
    setCurrency(props.settings.currency);
    setStartDayOfWeek(props.settings.start_day_of_week);
  }, [props.settings]);

  useEffect(() => {
    setLocale(props.setting.time_format);
    setTimezoneFormat(props.setting.timezone);
    setDuration(props.setting.shift_duration);
  }, [props.setting]);

  const getShiftColor = start => {
    if (start !== undefined && start !== null) {
      if (locale === 1) {
        if (moment(start).hours() >= 4 && moment(start).hours() <= 12) {
          return '#4888C8';
        } else if (moment(start).hours() > 12 && moment(start).hours() < 20) {
          return '#DB901C';
        } else if (moment(start).hours() >= 20 || moment(start).hours() < 4) {
          return '#0B1E38';
        }
      } else if (locale === 2) {
        const tag = moment(start).format('A');
        if (tag === 'AM') {
          if (moment(start).hours() >= 4) {
            return '#4888C8';
          } else if (moment(start).hours() < 4) {
            return '#0B1E38';
          }
        } else if (tag === 'PM') {
          if (moment(start).hours() >= 12 && moment(start).hours() < 20) {
            return '#DB901C';
          } else if (moment(start).hours() >= 20) {
            return '#0B1E38';
          }
        }
      }
    }
  };

  const getBorderColor = shift => {
    if (shift.start_date !== undefined && shift.start_date !== null) {
      if (shift.border_flag === true) {
        return '#ff0000';
      } else {
        return getShiftColor(shift.start_time);
      }
    }
  };

  const onClickSchedule = e => {
    if (!calendarFiltersData.leavesFilter) {
      if (e.button === 0 || e.event.button === 0) {
        setShowDetailModal(null);
        const shift_id = e.button === 0 ? modalForm.shift_Id : e.schedule.id;
        let selectedSchedule = calendarPropsData?.scheduledShifts.find(x => x.id === shift_id);
        if (selectedSchedule === undefined) selectedSchedule = e;
        if (selectedSchedule) {
          objfinder(selectedSchedule.repeatPattern?.split(','), modalForm, setModalForm);
          selectedSchedule = {
            ...selectedSchedule,
            check_call_frequency: selectedSchedule.check_call_frequency
              ? check_in_frequencies.find(x => x.id === selectedSchedule.check_call_frequency)
              : null,
            repeatPatternObject: getShiftRepeatPatternObject(selectedSchedule.repeatPattern, selectedSchedule.repeatType),
            selectedCompany: getShiftMultipleCompanies(selectedSchedule.organization, selectedSchedule.subcontractor),
            duration: calculateShiftDuration(selectedSchedule.start_time, selectedSchedule.end_time),
            timeOverlapPersons: timeOverlapWithPreviousShift(
              selectedSchedule.start_time,
              selectedSchedule.end_time,
              selectedSchedule.start_date,
              props
            ),
          };
          setModalForm({
            ...selectedSchedule,
            modalSchedulePaste: true,
            modalScheduleUpdate: true,
            setModalOpen: true,
            week: weeks,
            dbShiftData: props.shifts,
          });
          setFieldError(oldValues => ({ ...oldValues, ['modalScheduleDesignation_error']: false }));
          setFieldError(oldValues => ({ ...oldValues, ['modalScheduleLocation_error']: false }));
          setFieldError(oldValues => ({ ...oldValues, ['selectCompanyField_error']: false }));
        }
      } else {
        handleShowForDetail(e);
      }
    }
  };

  const getShiftRepeatPatternObject = (repeatPattern, repeatType) => {
    if (repeatPattern === '1,2,3,4,5') {
      return { id: 1, text: 'Every weekday (Mon-Fri)' };
    } else if (repeatPattern === '1,2,3,4,5,6,7') {
      return { id: 3, text: 'Weekly' };
    } else if (repeatPattern === '1' && repeatType === 'Day') {
      return { id: 2, text: 'Daily' };
    }
  };

  const detailHandleClose = () => setShowDetailModal(null);

  const scrollX = window.scrollX;
  const scrollY = window.scrollY;
  useLayoutEffect(() => {
    window.scrollTo(scrollX, scrollY);
  });

  const handleShowForDetail = event => {
    let x, y;
    const shiftId = getShiftId(event);
    if (shiftId === undefined) {
      x = Storage.local.get('coordinates').X;
      y = Storage.local.get('coordinates').Y;
    } else {
      x = event.event.clientX;
      y = event.event.clientY;
    }
    setShowDetailModal(
      showDetailModal === null
        ? navigator.userAgent.indexOf('Windows') > 0
          ? {
              mouseX: Number(x) + 2,
              mouseY: Number(y) - 65,
            }
          : {
              mouseX: Number(x) + 2,
              mouseY: Number(y) - 50,
            }
        : null
    );
  };

  const windowDetailPopup = e => {
    e.preventDefault();
    if (showDetailModal === null) {
      const element = document.elementFromPoint(e.clientX, e.clientY);
      const clickEvent = new MouseEvent('mouseup', {
        bubbles: true,
        cancelable: false,
        view: window,
        button: 2,
        buttons: 0,
        clientX: e.clientX,
        clientY: e.clientY,
      });
      if (clickEvent.button === 2 && clickEvent.which === 3) {
        element.dispatchEvent(clickEvent);
        clickEvent.preventDefault();
      }
    }
  };

  const getShiftId = e => {
    let shiftId;
    if (e.schedule) {
      shiftId = e.schedule.id;
    } else {
      setModalForm(oldValues => ({
        ...oldValues,
        getBeforeCreateData: { category: e.category, start: formatDate(e.start._date), end: formatDate(e.end._date) },
      }));
    }
    setModalForm(oldValues => ({ ...oldValues, shift_Id: shiftId }));
    return shiftId;
  };

  const CopySchedule = () => {
    const copyId = modalForm.shift_Id;
    setModalForm(oldValues => ({ ...oldValues, copied_Id: copyId }));
    setShowDetailModal(null);
    return copyId;
  };

  const PasteSchedule = () => {
    setShowDetailModal(null);
    let personId, person;
    const copiedId = modalForm.copied_Id;
    let copiedShift = calendarPropsData?.scheduledShifts.find(x => x.id === copiedId);
    copiedShift = { ...copiedShift, duration: calculateShiftDuration(copiedShift.start_time, copiedShift.end_time) };
    const getDayGridData: any = modalForm.getBeforeCreateData;
    if (getDayGridData.category === undefined) {
      person = null;
      copiedShift.start_date = getDayGridData.start;
      copiedShift.end_date = getDayGridData.end;
    } else {
      personId = getDayGridData.category.split('__')[1];
      person = [calendarPropsData?.entities && calendarPropsData?.entities.find(x => x.id === personId)];
      copiedShift.category = getDayGridData.category;
      copiedShift.start_date = getDayGridData.start;
      copiedShift.end_date = getDayGridData.end;
    }
    copiedShift.person = person;
    copiedShift.check_call_frequency = check_in_frequencies.find(x => x.id === copiedShift.check_call_frequency);
    setModalForm({ setModalOpen: true, ...copiedShift, modalSchedulePaste: true, modalScheduleUpdate: false, modalPersonIdentifier: true });
  };

  const onBeforeCreateSchedule = useCallback(
    scheduleData => {
      resetModal(modalForm, setModalForm, props);
      let name, person_id, person, personObject;
      const shiftStartDate = formatDate(scheduleData.start._date);
      if (scheduleData.isAllDay === true) {
        if (scheduleData.category === 'allday') {
          person_id = null;
          personObject = null;
        } else {
          [name, person_id] = scheduleData.category.split('__');
          if (calendarPropsData?.entities.length > 0) {
            personObject = calendarPropsData?.entities.find(x => x?.id === person_id);
          }
        }
      } else {
        person_id = null;
        personObject = null;
      }
      setModalForm(oldValues => ({
        ...oldValues,
        setModalOpen: true,
        start_date: shiftStartDate,
        end_date: shiftStartDate,
        modalSchedulePersonId: person_id,
        person: [personObject],
        modalScheduleUpdate: false,
        modalPersonIdentifier: true,
        pay_rate: personObject ? (personObject?.employment_detail ? personObject?.employment_detail['pay_rate'] : 10) : 10,
      }));
    },
    [calendarPropsData]
  );

  const detailOptionDelete = () => {
    setShowDetailModal(null);
    const shift_id = modalForm.id === null ? modalForm.shift_Id : modalForm.id;
    const selectedShift = calendarPropsData?.scheduledShifts.find(x => x.id === shift_id);
    if (selectedShift.isRepeat === true) {
      setDeleteModalSchedule(true);
      setIsRepeatShift(true);
    } else {
      setDeleteModalSchedule(true);
      setIsRepeatShift(false);
    }
  };

  const deleteSchedule = reason => {
    props.history.push('/calendar');
    const shift_id = modalForm.id === null ? modalForm.shift_Id : modalForm.id;
    const selectedShift = calendarPropsData?.scheduledShifts.find(x => x.id === shift_id);
    props.deleteScheduledShift(selectedShift.id, 'api/shift', calendarDisplayWindow, reason);
    const calenderId = selectedShift.category === null ? 2 : 1;
    cal.current.calendarInst.deleteSchedule(selectedShift.id.toString(), calenderId.toString());
    const newScheduleStack = calendarPropsData.scheduledShifts.filter(x => x.id !== selectedShift.id);
    setCalendarPropsData(oldValues => ({ ...oldValues, scheduledShifts: newScheduleStack }));
    setModalForm(oldValues => ({ ...oldValues, modalScheduleUpdate: false, setModalOpen: false }));
    handleResetWeek(modalForm);
    setDeleteModalSchedule(false);
    setIsRepeatShift(false);
    resetModal(modalForm, setModalForm, props);
  };

  const deleteSeries = reason => {
    props.history.push('/calendar');
    const shift_id = modalForm.id === null ? modalForm.shift_Id : modalForm.id;
    const selectedShift = calendarPropsData?.scheduledShifts.find(x => x.id === shift_id);
    props.deleteSeriesShift(selectedShift.id, calendarDisplayWindow, reason);
    const calenderId = selectedShift.category === null ? 2 : 1;
    cal.current.calendarInst.deleteSchedule(selectedShift.id.toString(), calenderId.toString());
    setModalForm(oldValues => ({ ...oldValues, modalScheduleUpdate: false, setModalOpen: false }));
    handleResetWeek(modalForm);
    setDeleteModalSchedule(false);
    setIsRepeatShift(false);
    resetModal(modalForm, setModalForm, props);
  };

  const onBeforeUpdateSchedule = useCallback(
    e => {
      handleDragUpdate(e.schedule);
      const { schedule, changes } = e;
      cal.current.calendarInst.updateSchedule(schedule.id, schedule.calendarId, changes);
    },
    [calendarPropsData]
  );

  const handleDragUpdate = async e => {
    const date = (await e.end.getFullYear()) + '-' + ('0' + (e.end.getMonth() + 1)).slice(-2) + '-' + ('0' + e.end.getDate()).slice(-2);
    const start = cal?.current?.calendarInst?.getDateRangeStart()._date;
    const end = cal?.current?.calendarInst?.getDateRangeEnd()._date;
    const foundArray = calendarPropsData.scheduledShifts.find(x => x.id === e.id);
    const foundLocation = calendarPropsData.locations.find(x => x.id === foundArray.service_area.id);
    const modalShiftData = {
      person: foundArray.person,
      start_time: moment(moment(date).format('YYYY-MM-DD') + ' ' + moment(foundArray.start_time).format('HH:mm')).toISOString(),
      end_time: moment(moment(date).format('YYYY-MM-DD') + ' ' + moment(foundArray.end_time).format('HH:mm')).toISOString(),
      start_date: date,
      end_date: date,
      status: 1,
      pay_rate: foundArray.pay_rate,
      no_of_breaks: foundArray.no_of_breaks,
      charge_rate: foundArray.charge_rate,
      isAllDay: foundArray.isAllDay,
      service_area: foundLocation,
      designation: foundArray.designation,
      time: new Date().toISOString(),
      scheduleId: e.id,
      isRepeat: foundArray.isRepeat,
      subcontractor: foundArray.subcontractor ? foundArray.subcontractor : null,
      organization: foundArray.subcontractor ? null : foundArray.organization ? foundArray.organization : null,
      repeatEnd: foundArray.isRepeat ? moment(foundArray.repeatEnd).format('YYYY-MM-DD') : '',
      repeatType: foundArray.isRepeat ? foundArray.repeatType : '',
      repeatPattern: foundArray.isRepeat ? foundArray.repeatPattern : '',
      note: foundArray.note,
      check_call_frequency: foundArray.check_call_frequency
        ? check_in_frequencies.find(x => x.id === foundArray.check_call_frequency)
        : { label: 'Not Required', id: 1 },
      patrol_frequency: foundArray.patrol_frequency,
    };
    props.updateShiftOccurence(modalShiftData, { ['start_date']: formatDate(start), ['end_date']: formatDate(end) });
  };

  const templates = {
    time(schedule) {
      return _getTimeTemplate(schedule, false);
    },
  };

  const getShiftMultipleCompanies = (organization, subcontractor) => {
    if (subcontractor) {
      return { id: subcontractor?.id, name: subcontractor?.name, status: 'SUBCONTRACTOR' };
    } else {
      return { id: organization?.id, name: organization?.name, status: 'ORGANIZATION' };
    }
  };

  const handleEmployeeHistory = () => {
    setOpenEmployeeHistory(prevCheck => !prevCheck);
  };

  const handleShiftOpen = () => {
    resetModal(modalForm, setModalForm, props);
    setModalForm(oldValues => ({ ...oldValues, modalScheduleUpdate: false, setModalOpen: true }));
    setRunState(false);
  };

  const sortCalendarPersons = () => {
    let shift_counts;
    let calendarPersons = JSON.parse(JSON.stringify(props.entities));
    calendarPersons = calendarPersons?.sort((a, b) => {
      if (props.shifts.length > 0) {
        shift_counts = props?.shifts.filter(s => Number(s?.person?.id) === Number(a?.id) && s.isDeleted === false).length;
      }
      if (shift_counts > 0) {
        a['type'] = true;
      } else {
        a['type'] = false;
      }
      return a.type === b.type ? 0 : a.type ? -1 : 1;
    });
    return calendarPersons;
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, index, type, status } = data;

    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
    if (finishedStatuses.includes(status)) {
      setRunState(false);
    }
  };

  const handleJoyrideCallbackAgain = (data: CallBackProps) => {
    const { action, index, type, status } = data;

    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
    if (finishedStatuses.includes(status)) {
      setRunAgainState(false);
    }
  };

  const showShiftView = e => {
    if (!calendarFiltersData.leavesFilter) {
      setShowDetailModal(null);
      const shift_id = e.id;
      let selectedSchedule = calendarPropsData?.scheduledShifts.find(x => x.id === shift_id);
      if (selectedSchedule === undefined) selectedSchedule = e;
      if (selectedSchedule) {
        objfinder(selectedSchedule.repeatPattern?.split(','), modalForm, setModalForm);
        selectedSchedule = {
          ...selectedSchedule,
          check_call_frequency: selectedSchedule.check_call_frequency
            ? check_in_frequencies.find(x => x.id === selectedSchedule.check_call_frequency)
            : null,
          repeatPatternObject: getShiftRepeatPatternObject(selectedSchedule.repeatPattern, selectedSchedule.repeatType),
          selectedCompany: getShiftMultipleCompanies(selectedSchedule.organization, selectedSchedule.subcontractor),
          duration: calculateShiftDuration(selectedSchedule.start_time, selectedSchedule.end_time),
          timeOverlapPersons: timeOverlapWithPreviousShift(
            selectedSchedule.start_time,
            selectedSchedule.end_time,
            selectedSchedule.start_date,
            props
          ),
        };
        setModalForm({
          ...selectedSchedule,
          modalSchedulePaste: true,
          modalScheduleUpdate: true,
          setModalOpen: true,
          week: weeks,
          dbShiftData: props.shifts,
        });
        setFieldError(oldValues => ({ ...oldValues, ['modalScheduleDesignation_error']: false }));
        setFieldError(oldValues => ({ ...oldValues, ['modalScheduleLocation_error']: false }));
        setFieldError(oldValues => ({ ...oldValues, ['selectCompanyField_error']: false }));
      }
    }
  };

  return (
    <>
      <div className="calender-main-container">
        <Paper elevation={3}>
          <Joyride
            callback={data => {
              handleJoyrideCallbackAgain(data);
            }}
            run={runAgain}
            disableScrolling
            continuous={true}
            locale={{
              last: <strong aria-label="last">Close</strong>,
              back: <strong aria-label="back">Previous</strong>,
              next: <strong aria-label="next">Next</strong>,
            }}
            styles={{
              buttonNext: {
                background: '#d48bd3',
              },
              buttonSkip: {
                background: '#f7b017',
              },
              tooltipContainer: {
                height: 70,
              },
              options: {
                width: 300,
              },
            }}
            steps={[
              {
                target: '.App',
                content: (
                  <>
                    <h2 style={{ fontSize: '20px' }}>You&apos;re all set! </h2>
                    <h1 style={{ fontSize: '17px' }}>Start managing your teams</h1>
                  </>
                ),
                placement: 'center',
              },
            ]}
          />
          <div className="App">
            {props.entities && props.entities.length ? (
              <React.Fragment>
                <div className="Container">
                  <CalendarHeader
                    handleJoyrideCallback={handleJoyrideCallback}
                    run={run}
                    cal={cal}
                    prop={props}
                    calenderMode={calenderMode}
                    setCalenderMode={setCalenderMode}
                    handleShiftOpen={handleShiftOpen}
                    setCalendarDisplayWindow={setCalendarDisplayWindow}
                  />
                  <Divider />
                  <div className="row">
                    <CalendarFilters
                      prop={props}
                      calenderMode={calenderMode}
                      classes={classes}
                      calendarPropsData={calendarPropsData}
                      calendarFiltersData={calendarFiltersData}
                      setCalendarFiltersData={setCalendarFiltersData}
                    />
                    <div
                      id="tuiCalendar"
                      className="col-12"
                      onContextMenu={navigator.userAgent.indexOf('Windows') > 0 ? windowDetailPopup : null}
                    >
                      <TUICalendar
                        ref={cal}
                        height="100%"
                        view={calenderMode}
                        week={{ startDayOfWeek: startDayWeek ? startDayWeek : 1 }}
                        taskView={['allday']}
                        scheduleView={['persons']}
                        usageStatistics={false}
                        useCreationPopup={false}
                        useDetailPopup={false}
                        disableClick={false}
                        disableDblClick={true}
                        template={templates}
                        timeFlag={locale === 1 ? false : true}
                        calendars={calendars}
                        personsList={
                          calendarFiltersData.filtersEnabled === true ? calendarFiltersData.filteredPersons : calendarPropsData?.entities
                        }
                        schedules={
                          calendarFiltersData.colorFilter === true ? calendarFiltersData.filteredShifts : calendarPropsData?.scheduledShifts
                        }
                        onClickSchedule={onClickSchedule}
                        onBeforeCreateSchedule={onBeforeCreateSchedule}
                        onBeforeUpdateSchedule={onBeforeUpdateSchedule}
                        onContextMenu={handleShowForDetail}
                      />
                      <Menu
                        open={showDetailModal !== null}
                        onClose={detailHandleClose}
                        anchorReference="anchorPosition"
                        anchorPosition={
                          showDetailModal !== null ? { top: showDetailModal.mouseY, left: showDetailModal.mouseX } : undefined
                        }
                      >
                        <MenuItem
                          onClick={CopySchedule}
                          disabled={modalForm.shift_Id === null || modalForm.shift_Id === undefined ? true : false}
                        >
                          Copy
                        </MenuItem>
                        <MenuItem
                          onClick={PasteSchedule}
                          disabled={
                            modalForm.copied_Id === null
                              ? true
                              : modalForm.shift_Id === null || modalForm.shift_Id === undefined
                              ? false
                              : modalForm.copied_Id === null || modalForm.copied_Id === undefined
                              ? true
                              : false
                          }
                        >
                          Paste
                        </MenuItem>
                        <MenuItem
                          disabled={modalForm.shift_Id === null || modalForm.shift_Id === undefined ? true : false}
                          onClick={onClickSchedule}
                        >
                          Edit
                        </MenuItem>
                        <MenuItem
                          disabled={modalForm.shift_Id === null || modalForm.shift_Id === undefined ? true : false}
                          onClick={detailOptionDelete}
                        >
                          Delete
                        </MenuItem>
                      </Menu>
                    </div>
                  </div>
                  <ModalComponent
                    modalForm={modalForm}
                    parentProp={props}
                    setModalForm={setModalForm}
                    fieldError={fieldError}
                    setFieldError={setFieldError}
                    deleteSchedule={deleteSchedule}
                    deleteSeries={deleteSeries}
                    calendarDisplayWindow={calendarDisplayWindow}
                    calendarPropsData={calendarPropsData}
                  />
                  <EmployeeHistory
                    openEmployeeHistory={openEmployeeHistory}
                    timesheetSummaryList={props.timesheetSummaryList?.[0]?.payload?.[0]?.report?.[0]?.PAYLOAD}
                  />
                  <DeleteModal
                    showDeleteModal={deleteModalSchedule}
                    setShowDeleteModal={setDeleteModalSchedule}
                    deleteSchedule={deleteSchedule}
                    deleteSeries={deleteSeries}
                    isRepeatShift={isRepeatShift}
                  />
                </div>
              </React.Fragment>
            ) : (
              !props.loading && <div className="lds-roller"></div>
            )}
          </div>
        </Paper>
      </div>
    </>
  );
};

const mapStateToProps = ({
  calendar,
  organizationSettings,
  PersonalSettings,
  timesheetSummary,
  serviceArea,
  designation,
  authentication,
  Leave,
}: IRootState) => ({
  entities: calendar.entities,
  shifts: calendar.allScheduledShifts,
  locations: serviceArea.entities,
  positions: designation.entities,
  newShift: calendar.newShift,
  updatedShift: calendar.updatedShift,
  settings: organizationSettings.storedOrganizationSettings,
  setting: PersonalSettings.storedPersonalSettings,
  timesheetSummaryList: timesheetSummary.entities,
  NoOfCheckPoints: calendar.check_points,
  loading: calendar.loading,
  shiftHistory: calendar.shiftEditHistory,
  schduleShifts: calendar.allScheduledShifts,
  bannedEmployees: serviceArea.banned,
  isOrganizationAdministrator: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.ORGANIZATION_ADMINISTRATOR]),
  joyRide: calendar.joyRide,
  leaves: Leave.calendar_leave_list,
  leavesObj: Leave.leave_category,
});

const mapDispatchToProps = {
  storeScheduledShift,
  deleteScheduledShift,
  deleteSeriesShift,
  getScheduledShifts,
  getReport,
  getNoOfCheckPoints,
  updateShiftOccurence,
  getScheduleEditHistory,
  shiftDetails,
  getPersonsList,
  getLocations,
  getDesignations,
  resetPositions,
  resetLocations,
  getBannedEmployeesOfOrganization,
  getLeavesForCalendar,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SchedulingCalender);
