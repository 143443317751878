import React from 'react';
import { useState, useEffect } from 'react';
import { Button, FormGroup, FormControl, Grid, Checkbox } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Modal from '@material-ui/core/Modal';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import './calendar.scss';
import TextField from '@material-ui/core/TextField';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker, TimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

export interface IModalComponent {
  showDeleteModal: boolean;
  setShowDeleteModal: (value: boolean) => void;
  setModalForm: (value: any) => void;
  modalForm: any;
  //   setting: void;
}

export const PatrolDurationModal = props => {
  const [occurrenceChecked, setOccurrenceChecked] = useState(false);
  const [seriesChecked, setSeriesChecked] = useState(false);
  const [patrolDurationList, setPatrolDurationList] = useState([]);
  const [patrolDurations, setPatrolDurations] = useState(props.modalForm.patrol_durations);

  useEffect(() => {
    setPatrolDurationList(prevList => {
      let newList = [];
      for (let j = 1; j <= props.patrol_frequency; j++) {
        newList = [...newList, { id: new Date().getTime(), start_time: '', end_time: '' }];
      }
      return newList;
    });
  }, [props.patrol_frequency]);

  useEffect(() => {
    props.setModalForm(oldValues => ({ ...oldValues, patrol_durations: patrolDurations }));
  }, [patrolDurations]);

  const handlePatrolTimeStart = (event, index) => {
    setPatrolDurations(oldValues => ({
      ...oldValues,
      [index]: {
        start_time: moment(moment(event).format('YYYY-MM-DD') + ' ' + moment(event).format('HH:mm')).toISOString(),
        end_time: patrolDurations && patrolDurations[index] ? patrolDurations[index]['end_time'] : null,
      },
    }));
  };

  const handlePatrolTimeEnd = (event, index) => {
    setPatrolDurations(oldValues => ({
      ...oldValues,
      [index]: {
        start_time: patrolDurations && patrolDurations[index] ? patrolDurations[index]['start_time'] : null,
        end_time: moment(moment(event).format('YYYY-MM-DD') + ' ' + moment(event).format('HH:mm')).toISOString(),
      },
    }));
  };

  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const { showDeleteModal, setShowDeleteModal } = props;

  return (
    <Modal open={showDeleteModal} onClose={handleCloseDeleteModal} className="create-modal">
      <div className="delete-shift-modal-paper">
        <h2 className="shift-modal-title">Patrol Durations</h2>

        <Divider className="calendar-new-shift-modal-divider" style={{ marginBottom: '20px' }} />

        {patrolDurationList &&
          patrolDurationList.map((item, index) => (
            <>
              <Grid container spacing={2} key={index}>
                <Grid item xs={5} lg={5}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardTimePicker
                      size="small"
                      ampm={props.setting.time_format === 1 ? false : true}
                      inputVariant="outlined"
                      label="Start time"
                      placeholder="Start Time"
                      value={patrolDurations && patrolDurations[index] ? patrolDurations[index]['start_time'] : null}
                      onChange={event => {
                        handlePatrolTimeStart(event, index);
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={5} lg={5}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardTimePicker
                      size="small"
                      ampm={props.setting.time_format === 1 ? false : true}
                      inputVariant="outlined"
                      label="End time"
                      placeholder="End Time"
                      // className={classes.modalNote}
                      value={patrolDurations && patrolDurations[index] ? patrolDurations[index]['end_time'] : null}
                      onChange={event => {
                        handlePatrolTimeEnd(event, index);
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
            </>
          ))}

        <FormControl className="modal-forms" style={{ marginTop: '20px' }}>
          <FormGroup row={true}>
            <Grid item xs={3} lg={4}>
              <Button variant="contained" className="delete-modal-button-cancel" onClick={handleCloseDeleteModal}>
                Cancel
              </Button>
            </Grid>

            <Grid item xs={3} lg={4}>
              <Button variant="contained" className="delete-modal-button-save" onClick={handleCloseDeleteModal}>
                Done
              </Button>
            </Grid>
          </FormGroup>
        </FormControl>
      </div>
    </Modal>
  );
};

export default PatrolDurationModal;
