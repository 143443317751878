import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { IRootState } from 'app/shared/reducers';
import { faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InfiniteScroll from 'react-infinite-scroll-component';
import { TableContainer, TableHead, TableRow, TableBody, Table, Collapse, Box, TableCell, Paper } from '@material-ui/core';
import { getActionsLog, reset } from './ActionRequired.reducer';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { unset } from 'lodash';
import '../home.scss';
import { Divider } from '@material-ui/core';

export interface IActionsHistory extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}
function ActionLogNotifications(props) {
  const { data } = props;

  useEffect(() => {}, [props.data]);

  return (
    <div className="action-log-notifications-main-div">
      <div className="action-log-notifications-details">
        <div className="action-log-notifications-icon">
          <FontAwesomeIcon icon={faCheckCircle} color="#3bbda9" size="lg" />
        </div>
        <div className="action-log-notifications-details-text">
          <div className="action-log-notifications-message">
            {data.dashboardActivityDescription} {moment(data.time).format('dddd')} ({moment(data.time).format('LL')}){' '}
          </div>

          <div className="action-log-notifications-address">{data.dashboardActivityLocation}</div>
          <div className="action-log-notifications-date">
            <i>{moment(data.time).startOf(data.time).fromNow()}</i>
          </div>
        </div>
      </div>
      <Divider style={{ marginBottom: '3%', marginTop: '6%' }} />
    </div>
  );
}

function ActionRequired(props: IActionsHistory) {
  const { actions } = props;
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    props.reset();
    props.getActionsLog(1);
  }, []);

  useEffect(() => {
    if (pageNumber > 1) {
      props.getActionsLog(pageNumber);
    }
  }, [pageNumber]);

  return (
    <div id="scrollableDivActionLog" className="dashboard-actions-required-outer-div" style={{ maxHeight: 871, minHeight: 871 }}>
      <InfiniteScroll
        dataLength={props.actions.length}
        scrollableTarget="scrollableDivActionLog"
        scrollThreshold="200px"
        next={() => {
          if (actions.length > 0) {
            setPageNumber(pageNumber + 1);
          }
        }}
        hasMore={true}
        loader={''}
      >
        <div className="dashboard-action-required-header">Notifications</div>
        <Divider style={{ marginBottom: '3%', marginTop: '6%' }} />
        {props.actions && props.actions.map((item, index) => <ActionLogNotifications key={index} data={item} />)}
      </InfiniteScroll>
    </div>
  );
}

const mapStateToProps = (storeState: IRootState) => ({
  actions: storeState.ActionLogs.entities,
});

const mapDispatchToProps = {
  getActionsLog,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ActionRequired);
