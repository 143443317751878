import React, { useEffect, useState } from 'react';
import ClientDrawerComponent from './components/clientDrawer';
import ProfileDetail from './client-view-designs/client-profileDetails';
import ClientServiceAreas from './client-view-designs/client-serviceArea';
import ClientRateCard from './client-view-designs/ratecard';
import ClientUpdate from './client-update';

const ClientView = props => {
  const [page, setPage] = useState(1);

  return (
    <div className="client-view-deisgns-main-container" style={{ padding: '32px' }}>
      <div className="settings-outer-div">
        <div className="settings-drawer-container">
          <ClientDrawerComponent setPage={setPage} page={page} />
        </div>
        <div
          style={{
            width: '83%',
            height: '100%',
          }}
        >
          {page === 1 && <ClientUpdate {...props} />}
          {page === 2 && <ClientServiceAreas {...props} />}
        </div>
      </div>
    </div>
  );
};

export default ClientView;
