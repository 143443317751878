import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Table, Grid } from '@material-ui/core';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { getEntities, reset, setPageNumber } from './designation.reducer';
import { IDesignation } from 'app/shared/model/designation.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import SearchBar from 'material-ui-search-bar';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import InfiniteScroll from 'react-infinite-scroll-component';
import TableContainer from '@material-ui/core/TableContainer';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import GetAppIcon from '@material-ui/icons/GetApp';
import { designationConvertToPDF } from './designationConvertToPDF';
import FormControl from '@material-ui/core/FormControl';
import Spinner from 'app/commonUI/spinner/spinner';

export interface IDesignationProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const Designation = (props: IDesignationProps) => {
  const [disabled, setDisabled] = useState(0);
  const [searched, setSearched] = useState<string>('');
  const [records, setRecords] = useState(props.designationList);
  const [isNew] = useState(!props.match.params || !props.match.params.id);

  useEffect(() => {
    props.reset();
    props.setPageNumber({ pageNumber: 1 });
  }, []);

  const handleSyncList = () => {
    props.reset();
    setSearched('');
    props.setPageNumber({ pageNumber: 1 });
  };

  useEffect(() => {
    setRecords(props.designationList);
  }, [props.designationList]);

  const requestSearch = (searchedVal: string) => {
    setSearched(searchedVal);
    const value = searchedVal.toLowerCase();
    const filteredRows = props.designationList.filter(row => {
      return (row.name !== null && row.name.toLowerCase().includes(value)) || (row.id !== null && row.id.toString().includes(value));
    });
    setRecords(filteredRows);
  };

  const cancelSearch = () => {
    setSearched('');
    requestSearch('');
  };

  const jsonData = [];
  if (records.length > 0) {
    records.map(elem => {
      const data = { ...elem };
      jsonData.push(data);
    });
  }
  const { designationList, match, loading } = props;
  return (
    <>
      <div className="entities-reports-main-container">
        {loading && <Spinner />}
        <div className="entities-reports-filter-area">
          <Grid container spacing={2} style={{ flexWrap: 'nowrap', width: '65%' }}>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
              <FormControl variant="outlined" size="small">
                <SearchBar
                  value={searched}
                  onChange={searchVal => requestSearch(searchVal)}
                  onCancelSearch={() => cancelSearch()}
                  placeholder="Search Here..."
                  style={{
                    outline: '1px solid #bbc4c2',
                    borderRadius: '4px',
                    height: '40px',
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container className="entities_reports_refresh_and_download_div" style={{ width: '35%' }}>
            <IconButton
              size="small"
              className="report-download-button"
              id="entities_reports_btn"
              onClick={() => designationConvertToPDF(jsonData)}
              disabled={props.loading}
            >
              <GetAppIcon fontSize="large" />
            </IconButton>

            <IconButton
              size="small"
              className="report-download-button"
              id="entities_reports_btn"
              disabled={props.loading}
              onClick={handleSyncList}
            >
              <AutorenewIcon fontSize="large" />
            </IconButton>

            {/* <Grid item xl={4} lg={4} md={4} sm={4} xs={4}> */}
            <Link
              to={`${match.url}/new`}
              className="btn jh-create-entity serviceAreaCreateButton incidentCreateButton"
              id="jh-create-entity"
              data-cy="entityCreateButton"
            >
              <FontAwesomeIcon icon="plus" />
              &nbsp; Create New
            </Link>
            {/* </Grid> */}
          </Grid>
        </div>

        <div>
          <div className="globalTableHeaderGradientColor designationTableHeaderRow sticky_div">
            <div className="designationTableHeaderCell designationHeaderDateCell">ID</div>
            <div className="designationTableHeaderCell designationHeaderNameCell">Position Name</div>
          </div>

          <InfiniteScroll
            dataLength={designationList.length}
            scrollThreshold="200px"
            next={() => {
              if (designationList.length > 0) {
                props.setPageNumber({ pageNumber: props.pageId['pageNumber'] + 1 });
              }
            }}
            hasMore={true}
            loader={''}
          >
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <tbody>
                  {records && records.length > 0
                    ? records.map((position, i) => (
                        <tr key={`entity-${i}`} data-cy="entityTable" className="designationTableBodyRows">
                          <td className="designationTableBodyCell designationTableBodyDatecell">{i + 1}</td>
                          <td className="designationTableBodyCell designationTableBodyNamecell">
                            <Link to={`${match.url}/${position.id}/edit`}>{position.name}</Link>
                          </td>
                        </tr>
                      ))
                    : !loading && <div className="no-record-warning">No Positions found</div>}
                </tbody>
              </Table>
            </TableContainer>
          </InfiniteScroll>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ designation }: IRootState) => ({
  designationList: designation.entities,
  loading: designation.loading,
  pageId: designation.pageNumber,
});

const mapDispatchToProps = {
  getEntities,
  reset,
  setPageNumber,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Designation);
