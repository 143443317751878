import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './employee-contract.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IEmployeeContractDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const EmployeeContractDetail = (props: IEmployeeContractDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { employeeContractEntity } = props;
  return (
    <div>
      <Grid item container>
        <Grid item xs={8}>
          <h2 data-cy="employeeContractDetailsHeading">
            <Translate contentKey="wfmApp.employeeContract.detail.title">EmployeeContract</Translate>
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="id">
                <Translate contentKey="global.field.id">ID</Translate>
              </span>
            </dt>
            <dd>{employeeContractEntity.id}</dd>
            <dt>
              <span id="startDate">
                <Translate contentKey="wfmApp.employeeContract.startDate">Start Date</Translate>
              </span>
            </dt>
            <dd>
              {employeeContractEntity.startDate ? (
                <TextFormat value={employeeContractEntity.startDate} type="date" format={APP_DATE_FORMAT} />
              ) : null}
            </dd>
            <dt>
              <span id="endDate">
                <Translate contentKey="wfmApp.employeeContract.endDate">End Date</Translate>
              </span>
            </dt>
            <dd>
              {employeeContractEntity.endDate ? (
                <TextFormat value={employeeContractEntity.endDate} type="date" format={APP_DATE_FORMAT} />
              ) : null}
            </dd>
            <dt>
              <Translate contentKey="wfmApp.employeeContract.employeeContractType">Employee Contract Type</Translate>
            </dt>
            <dd>{employeeContractEntity.employeeContractType ? employeeContractEntity.employeeContractType.id : ''}</dd>
            <dt>
              <Translate contentKey="wfmApp.employeeContract.person">Person</Translate>
            </dt>
            <dd>{employeeContractEntity.person ? employeeContractEntity.person.id : ''}</dd>
          </dl>
          <Button
            component={Link}
            to="/employee-contract"
            replace
            style={{ color: 'black', backgroundColor: 'rgb(13, 202, 240)' }}
            data-cy="entityDetailsBackButton"
          >
            <FontAwesomeIcon icon="arrow-left" />
            nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button
            component={Link}
            to={`/employee-contract/${employeeContractEntity.id}/edit`}
            replace
            style={{ color: 'white', backgroundColor: 'blue' }}
          >
            <FontAwesomeIcon icon="pencil-alt" />
            nbsp;
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = ({ employeeContract }: IRootState) => ({
  employeeContractEntity: employeeContract.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeContractDetail);
