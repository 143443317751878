import jsPDF from 'jspdf';
import 'jspdf-autotable';
import autoTable from 'jspdf-autotable';
import moment from 'moment';

export const exportPDF = (DateFrom, DateTo, header, reportTitle, reportContent, filename) => {
  const unit = 'mm';
  const size = [500, 700];
  const orientation = 'portrait';
  const img = 'content/images/ReportPDF.png';

  const doc = new jsPDF(orientation, unit, size);
  const headers = header;
  const data = reportContent;
  const title = reportTitle;
  const issusDate = moment().format('DD-MM-YYYY');
  const dateFromTo = DateFrom === '' && DateTo === '' ? 'Until now' : DateFrom + ' to ' + DateTo;

  doc.addImage(img, 'PNG', 15, 10, 470, 25);

  doc.addFont('content/fonts/Roboto-Regular.ttf', 'Roboto', 'normal');
  doc.setFont('Roboto');
  doc.setFontSize(30).text(title, 25, 25);
  doc.setFontSize(16).setTextColor(189, 170, 239).text('Issue Date:', 180, 20);
  doc.setFontSize(18).setTextColor(255, 255, 255).text(issusDate, 180, 30);
  doc.setFontSize(16).setTextColor(189, 170, 239).text('Duration:', 235, 20);
  doc.setFontSize(18).setTextColor(255, 255, 255).text(dateFromTo, 235, 30);

  autoTable(doc, {
    startY: 50,
    head: headers,
    body: data,
    styles: { halign: 'center' },
    headStyles: { fillColor: [255, 214, 125], textColor: [0, 0, 0], fontSize: 14 },
    bodyStyles: { fontSize: 14 },
    alternateRowStyles: { fillColor: [249, 238, 248] },
    tableLineColor: [249, 238, 248],
    tableLineWidth: 0.1,
  });
  doc.save(filename);
};
