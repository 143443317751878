import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';
import { Storage } from 'react-jhipster';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IClientCoverage, defaultValue } from 'app/shared/model/client-coverage.model';

export const ACTION_TYPES = {
  FETCH_CLIENT_COVERAGE: 'client-coverage/FETCH_CLIENT_COVERAGE',
  RESET: 'client-coverage/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IClientCoverage>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

export type ClientCoverageState = Readonly<typeof initialState>;

export default (state: ClientCoverageState = initialState, action): ClientCoverageState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_CLIENT_COVERAGE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };

    case FAILURE(ACTION_TYPES.FETCH_CLIENT_COVERAGE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_CLIENT_COVERAGE):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/clientCoverage';

export const getReport = data => ({
  type: ACTION_TYPES.FETCH_CLIENT_COVERAGE,
  payload: axios.get<IClientCoverage>(
    `${apiUrl}?filter[service_area.client_id]=${data.client}&filter[service_area_id]=${data.location}&filter[designation_id]=${data.position}&filter[department_for_person]=${data.department}&filter[subcontractor_id]=${data.subcontractor}&filter[from_shifts]=${data.from_date}&filter[to_shifts]=${data.to_date}&filter[status]=${data.status}&fields[shift]=id`
  ),
});

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
