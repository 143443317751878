import React, { useEffect, useState } from 'react';
import './RolesAndPermissionsSettings.scss';
import { Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import PermissionsRowComponent from './Components/PermissionsRowComponent';
import { Grid, FormControl, Button } from '@material-ui/core';
import { storePermissionsSettings, getPermissionsSettings } from './RolesAndPermissions.reducer';
import { connect } from 'react-redux';
import { PinDropSharp } from '@material-ui/icons';
import { IRootState } from 'app/shared/reducers';
import PermissionsGeneralComponent from './Components/PermissionsGeneralComponent';
import PermissionsDataComponent from './Components/PermissionsDataComponent';
import PermissionsScheduleComponent from './Components/PermissionsScheduleComponent';
import PermissionsDocumentsComponent from './Components/PermissionsDocumentsComponent';
import PermissionsPayRunComponent from './Components/PermissionsPayRunComponent';

function PersonRolePermissionSettings(props) {
  const [supervisorPerm, setSupervisorPermissions] = useState({
    admin_portal: false,
    financial_info: false,
    add_remove_roles: false,
    account_details: false,
    add_remove_clients: false,
    add_remove_locations: false,
    add_remove_notes: false,
    details_for_people: false,
    can_add_and_edit_details_for_people: false,
    can_edit_login_credentials: false,
    manage_subcontractors: false,
    manage_schedule: false,
    schedule_of_people_in_departments: false,
    add_remove_documents: false,
    see_timesheet_summary: false,
    see_timeclock_eport: false,
    see_checktime_report: false,
    see_absenses_report: false,
    see_absense_summary_report: false,
    see_position_coverage_report: false,
    see_revenue_summary_report: false,
    see_action_history_report: false,
    can_see_pay_run: false,
    can_add_pay_lock: false,
    can_remove_pay_lock: false,
  });
  const [userPerm, setUserPermissions] = useState({
    admin_portal: false,
    financial_info: false,
    add_remove_roles: false,
    account_details: false,
    add_remove_clients: false,
    add_remove_locations: false,
    add_remove_notes: false,
    details_for_people: false,
    can_add_and_edit_details_for_people: false,
    can_edit_login_credentials: false,
    manage_subcontractors: false,
    manage_schedule: false,
    schedule_of_people_in_departments: false,
    add_remove_documents: false,
    see_timesheet_summary: false,
    see_timeclock_eport: false,
    see_checktime_report: false,
    see_absenses_report: false,
    see_absense_summary_report: false,
    see_position_coverage_report: false,
    see_revenue_summary_report: false,
    see_action_history_report: false,
    can_see_pay_run: false,
    can_add_pay_lock: false,
    can_remove_pay_lock: false,
  });
  const [mngrPerm, setManagerPermissions] = useState({
    admin_portal: false,
    financial_info: false,
    add_remove_roles: false,
    account_details: false,
    add_remove_clients: false,
    add_remove_locations: false,
    add_remove_notes: false,
    details_for_people: false,
    can_add_and_edit_details_for_people: false,
    can_edit_login_credentials: false,
    manage_subcontractors: false,
    manage_schedule: false,
    schedule_of_people_in_departments: false,
    add_remove_documents: false,
    see_timesheet_summary: false,
    see_timeclock_eport: false,
    see_checktime_report: false,
    see_absenses_report: false,
    see_absense_summary_report: false,
    see_position_coverage_report: false,
    see_revenue_summary_report: false,
    see_action_history_report: false,
    can_see_pay_run: false,
    can_add_pay_lock: false,
    can_remove_pay_lock: false,
  });
  const [orgAdminPerm, setOrgAdminPermissions] = useState({
    admin_portal: true,
    financial_info: true,
    add_remove_roles: true,
    account_details: true,
    add_remove_clients: true,
    add_remove_locations: true,
    add_remove_notes: true,
    details_for_people: true,
    can_add_and_edit_details_for_people: true,
    can_edit_login_credentials: true,
    manage_subcontractors: true,
    manage_schedule: true,
    schedule_of_people_in_departments: true,
    add_remove_documents: true,
    see_timesheet_summary: true,
    see_timeclock_eport: true,
    see_checktime_report: true,
    see_absenses_report: true,
    see_absense_summary_report: true,
    see_position_coverage_report: true,
    see_revenue_summary_report: true,
    see_action_history_report: true,
    can_see_pay_run: true,
    can_add_pay_lock: true,
    can_remove_pay_lock: true,
  });

  useEffect(() => {
    if (Object.keys(props.permissions).length) {
      setSupervisorPermissions(props.permissions['Supervisor']);
      setUserPermissions(props.permissions['User']);
      setManagerPermissions(props.permissions['Manager']);
      setOrgAdminPermissions(props.permissions['Organization Administrator']);
    }
  }, [props.permissions]);

  const handleChange = (name, role, value) => {
    if (role === 'Supervisor') {
      setSupervisorPermissions(oldValues => ({ ...oldValues, [name]: !value }));
    } else if (role === 'User') {
      setUserPermissions(oldValues => ({ ...oldValues, [name]: !value }));
    } else if (role === 'Manager') {
      setManagerPermissions(oldValues => ({ ...oldValues, [name]: !value }));
    } else if (role === 'Organization Administrator') {
      setOrgAdminPermissions(oldValues => ({ ...oldValues, [name]: !value }));
    }
    submit(name, value, role);
  };

  const submit = (name, value, role) => {
    props.storePermissionsSettings({ permission_name: name, role_name: role, permission_value: !value });
  };

  return (
    <div className="roles-and-permissions-settings-external-div">
      <div className="settings-heading-div">
        <div className="settings-main-heading">
          <Typography variant="h5" className="roles-and-permission-heading-main-title">
            Roles And Permission Settings
          </Typography>
        </div>
        <div className="settings-main-heading-message">Edit your information below</div>
      </div>
      <div className="settings-roles-permission-external-div">
        <tr className="rolePermissionTr" style={{ borderColor: 'black' }}>
          <td style={{ width: '60%', textAlign: 'center' }}> </td>
          <td className="roles-permission-table-headings">
            <span className="roles-permissions-location-supervisor">Supervisor </span>
          </td>
          <td className="roles-permission-table-headings">
            <span className="roles-permissions-department-supervisor">User </span>
          </td>
          <td className="roles-permission-table-headings">
            <span className="roles-permissions-manager"> Manager </span>
          </td>
          <td className="roles-permission-table-headings">
            <span className="roles-permissions-organization-administrator">Organization Administrator</span>
          </td>
        </tr>
        <h5 style={{ color: 'black' }}> GENERAL </h5>
        <br />
        <Paper className="roles-and-permissions-settings-paper" elevation={2} square style={{ width: '100%', height: 'auto' }}>
          <PermissionsGeneralComponent
            supervisorPerm={supervisorPerm}
            userPerm={userPerm}
            mngrPerm={mngrPerm}
            orgAdminPerm={orgAdminPerm}
            handleChange={handleChange}
          />
        </Paper>

        <br />
        <br />
        <h5 style={{ color: 'black' }}> DATA </h5>
        <br />
        <Paper className="roles-and-permissions-settings-paper" elevation={2} square style={{ width: '100%', height: 'auto' }}>
          <PermissionsDataComponent
            supervisorPerm={supervisorPerm}
            userPerm={userPerm}
            mngrPerm={mngrPerm}
            orgAdminPerm={orgAdminPerm}
            handleChange={handleChange}
          />
        </Paper>

        <br />
        <br />
        <h5 style={{ color: 'black' }}> SCHEDULE </h5>
        <br />
        <Paper className="roles-and-permissions-settings-paper" elevation={2} square style={{ width: '100%', height: 'auto' }}>
          <PermissionsScheduleComponent
            supervisorPerm={supervisorPerm}
            userPerm={userPerm}
            mngrPerm={mngrPerm}
            orgAdminPerm={orgAdminPerm}
            handleChange={handleChange}
          />
        </Paper>

        <br />
        <br />
        <h5 style={{ color: 'black' }}> DOCUMENTS </h5>
        <br />
        <Paper className="roles-and-permissions-settings-paper" elevation={2} square style={{ width: '100%', height: 'auto' }}>
          <PermissionsDocumentsComponent
            supervisorPerm={supervisorPerm}
            userPerm={userPerm}
            mngrPerm={mngrPerm}
            orgAdminPerm={orgAdminPerm}
            handleChange={handleChange}
          />
        </Paper>

        <br />
        <br />
        <h5 style={{ color: 'black' }}> PAY RUN </h5>
        <br />
        <Paper className="roles-and-permissions-settings-paper" elevation={2} square style={{ width: '100%', height: 'auto' }}>
          <PermissionsPayRunComponent
            supervisorPerm={supervisorPerm}
            userPerm={userPerm}
            mngrPerm={mngrPerm}
            orgAdminPerm={orgAdminPerm}
            handleChange={handleChange}
          />
        </Paper>
      </div>
    </div>
  );
}

const mapStateToProps = (storeState: IRootState) => ({
  permissions: storeState.PermissionsSetting.settings,
  loading: storeState.PermissionsSetting.loading,
});

const mapDispatchToProps = {
  storePermissionsSettings,
  getPermissionsSettings,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PersonRolePermissionSettings);
