import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';

export const Declaration = props => {
  const acceptDeclaration = () => {
    props.handleIncrementDecrement('INCREMENT', 1);
  };

  return (
    <div className="employment-form-declaration-main-div">
      <div className="job-application-form-sections-top-heading"> Terms </div>
      <hr style={{ marginTop: '1rem', marginBottom: '1rem' }} />
      <div className="form-group">
        <p style={{ textAlign: 'justify' }}>
          The company has been engaged to undertake the full vetting function for all candidates in compliance with BS7858:2019. To carry
          out the vetting process, we will be required to disclose your personal information held on file as part of your application
          process, we are registered as a data controller with the Information Commissioners Office. The company will treat the information
          supplied within the strictest of confidence as set out within the guidelines of the Data Protection Act 1998. The information
          supplied will be for the exclusive use of the company. The company will use the information that you have supplied on your
          application form, together with any information that we obtain with your consent from third parties for assessing your suitability
          for employment within a security related occupation. Please be advised that it may be necessary to disclose your information to
          our agents and other service providers.
        </p>
        <p style={{ textAlign: 'justify' }}>
          By returning this form, you consent to our processing sensitive personal data about you where this is necessary which may include,
          for example, information about your credit status and qualifications. You also consent to the transfer of your information to your
          current and future potential employers where this is necessary. Your information will be held electronically and in paper filing
          systems. By accepting you agree to this process and confirm that you do not have a criminal record subject to the current
          Rehabilitation of Offenders Act and any impacting amendments. You have the right to apply for a copy of the information held on
          you, for which a small charge may be applicable, and to have any inaccuracies correcte.
        </p>
        <p> Please accept to continue. </p>
      </div>
      <div className="employment-form-declaration-action-div">
        <Button variant="outlined" className="accept-declaration-btn" onClick={acceptDeclaration}>
          {' '}
          I Accept{' '}
        </Button>
      </div>
    </div>
  );
};

export default Declaration;
