import React from 'react';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ShiftDetails = ({ color, count, details, customClass, image, isHome }) => (
  <div className={`shift-details-main-div ${customClass}`} style={{ borderRadius: '2%' }}>
    <div className="shift-details-icon" style={{ backgroundColor: color }}>
      {isHome ? (
        <img src={image} alt={details} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
      ) : (
        <div className="shift-details-icon-outer-circle">
          <FontAwesomeIcon icon={faClock} color="white" size="2x" style={{ fontWeight: 'lighter' }} />
        </div>
      )}
    </div>
    <div className="dash-board-shift-details-content-div">
      <div className="dash-board-shift-details-counter"> {count ? count : 0} </div>
      <div className="dash-board-shift-details-text"> {details} </div>
    </div>
  </div>
);
export default ShiftDetails;
