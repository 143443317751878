import React, { useState, useEffect } from 'react';
import { Grid, Paper, makeStyles } from '@material-ui/core';
import forwardIcon from '../../../../content/images/forwardIcon.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Divider from '@material-ui/core/Divider';
import { faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import '../servicearea.scss';
export interface IServiceAreaTeamBannedComponent {
  setShowBannedModal: (value: boolean) => void;
  setBannedModalMode: (value: any) => void;
  prop: any;
  isEdit: boolean;
}

const ServiceAreaTeamBannedEmployees = (props: IServiceAreaTeamBannedComponent) => {
  const { setShowBannedModal, setBannedModalMode, prop } = props;
  const [records, setRecords] = useState(prop.bannedEmployees);

  useEffect(() => {
    if (prop.bannedEmployees && prop.bannedEmployees.length > 0) {
      setRecords(prop.bannedEmployees);
    }
  }, [prop.bannedEmployees]);

  const handleRedirect = id => {
    window.location.href = `/staff/${id}`;
  };
  const handleEditIconButton = id => {
    setShowBannedModal(true);
    setBannedModalMode(oldValues => ({ ...oldValues, Mode: 'edit', banned_id: id }));
  };

  const handleDeleteIconButton = id => {
    setShowBannedModal(true);
    setBannedModalMode(oldValues => ({ ...oldValues, Mode: 'delete', banned_id: id }));
  };

  return (
    <Grid container spacing={2} className="Card-Container" style={{ display: 'flex', flexWrap: 'wrap' }}>
      {records && records.length > 0
        ? records.map((banned, i) => (
            <Grid item lg={6} md={6} sm={6} xs={6} key={i}>
              <Paper elevation={2} className="service-area-component-main-container">
                <div className="service-area-team-component-text-container">
                  <div className="service-area-component-name-div">
                    {banned.person && banned.person.first_name + ' ' + banned.person.last_name}
                  </div>

                  <div className="service-area-component-address-div">
                    {/* <EmailIcon className="service-area-component-location-icon" />{' '}
                  {banned.person && banned.person.email && banned.person.email[0] && banned.person.email[0].value} */}
                    {props.prop.serviceAreaEntity.designation.name || 'No designation found'}
                  </div>
                  {/* <div className="service-area-component-address-div">
                  <PhoneIcon className="service-area-component-location-icon" />{' '}
                  {banned.person && banned.person.phoneNumber ? banned.person.phoneNumber[0] && banned.person.phoneNumber[0].value : ''}
                </div> */}
                  {props.isEdit ? (
                    <div className="service-area-component-icons-container">
                      <FontAwesomeIcon icon={faEdit} className="team-component-edit-icon" onClick={() => handleEditIconButton(banned.id)} />
                      &nbsp;
                      <Divider orientation="vertical" className="service-area-component-icons-divider" flexItem />
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        className="team-component-delete-icon"
                        onClick={() => handleDeleteIconButton(banned.id)}
                      />
                    </div>
                  ) : null}
                </div>

                <div className="redirect_icon">
                  <img
                    src={forwardIcon}
                    alt="Forward icon"
                    style={{
                      cursor: 'pointer',
                      fontFamily: 'Roboto',
                      width: '12px',
                      height: '12px',
                      opacity: 1,
                      marginLeft: '250px',
                      marginTop: '13px',
                    }}
                    onClick={() => handleRedirect(banned.id)}
                  />
                </div>
              </Paper>
            </Grid>
          ))
        : !prop.loading && <div className="no-record-warning">No Banned Employee found</div>}
    </Grid>
  );
};

export default ServiceAreaTeamBannedEmployees;
