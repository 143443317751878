import PersonalSetting from '../../modules/Setting/PersonalSetting';
import { IPersonalSettings } from 'app/shared/model/personalSetting.model';

export interface ITimesheetSummary {
  type?: string | null;
  person?: string | null;
  description?: string | null;
  location?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  date?: string | null;
  timeFrom?: string | null;
  timeTo?: string | null;
  checkIn?: string | null;
  checkOut?: string | null;
  scheduledTime?: string | null;
  actualTime?: string | null;
  rate?: number | null;
  scheduledPay?: number | null;
  actualPay?: number | null;
  expenses?: number | null;
  PAYLOAD?: IData[];
  payload?: IData[];
  report?: IData[];
  totalPages?: number | null;
  totalItems?: number | null;
  setting?: IPersonalSettings | null;
  time_format?: IPersonalSettings | null;
  payRate?: number | null;
  chargeRate?: number | null;
}

interface IData {
  report: any;
  person?: string | null;
  description?: string | null;
  location?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  date?: string | null;
  timeFrom?: string | null;
  timeTo?: string | null;
  checkIn?: string | null;
  checkOut?: string | null;
  scheduledTime?: string | null;
  actualTime?: string | null;
  rate?: number | null;
  scheduledPay?: number | null;
  actualPay?: number | null;
  expenses?: number | null;
  payRate?: number | null;
  chargeRate?: number | null;
}
export const defaultValue: Readonly<ITimesheetSummary> = {};
