import axios from 'axios';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { IActionRequired, defaultValue } from 'app/shared/model/actionsRequired.model';
import { IVettingDocuments, defaultValue as VettingModel } from 'app/shared/model/vetting-documents.model';

export const ACTION_TYPES = {
  FETCH_DOCUMENT: 'vetting/FETCH_DOCUMENT',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as any,
  updating: false,
  updateSuccess: false,
  breaks: [],
};

export type VettingDocumentsState = Readonly<typeof initialState>;

// Reducer

export default (state: VettingDocumentsState = initialState, action): VettingDocumentsState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_DOCUMENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_DOCUMENT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_DOCUMENT):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/getVettingDocuments';
// Actions

export const getDocuments = id => ({
  type: ACTION_TYPES.FETCH_DOCUMENT,
  payload: axios.get(`${apiUrl}/${id}`),
});

export const approveNotifications = id => dispatch => {
  return axios
    .put<IActionRequired>(`${apiUrl}`, id)
    .then(res => {
      //   dispatch(getNotifications());
    })
    .catch(err => {
      //
    });
};

export const verifyPersonInfo = (id, verificationStatus) => dispatch => {
  const result = null;
  axios
    .put(`api/verifyPersonInformation/${id}`, { status: verificationStatus })
    .then(res => {
      dispatch(getDocuments(id));
    })
    .catch(err => {
      //
    });
  return result;
};
