/* eslint-disable complexity */

import React, { useEffect, useState } from 'react';
import { TextField, Grid, MenuItem, FormControl, Paper, FormControlLabel } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import CustomToolbarComponent from '../service-area/CustomToolbarComponent';
import { getDateFormat } from 'app/shared/util/calender-utils';
import { IPerson } from 'app/shared/model/person.model';
import { IPersonalSettings } from 'app/shared/model/personalSetting.model';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Checkbox } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { verifySiaLicenceNumber } from './person.reducer';
import './person.scss';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import DateFnsUtils from '@date-io/date-fns';

interface PersonUpdateCompanyInformationProps {
  handlePersonCompany?: (data: any, data2: any) => void;
  company?: Array<any> | any;
  subcontractors?: Array<any> | any;
  setFieldValues?: (data: any) => void;
  handlePositionData?: (data: any, event: any) => void;
  handleRoleData?: (data: any, event: any) => void;
  handleDepartmentData?: (data: any, event: any) => void;
  personEntity?: IPerson | any;
  fieldValues?: IPerson | any;
  fieldError?: Record<string, any>;
  positionsList?: Array<any> | any;
  rolesList?: Array<any> | any;
  departmentsList?: Array<any> | any;
  setting?: IPersonalSettings | any;
  expiry_date?: Date | any;
  setExpiry_date?: Date | any;
  setFieldError?: (data: any) => void;
  setNewValues?: (event: any) => void;
  handleProcessVetting?: (event: any) => void;
  classes?: any;
  isNew?: boolean;
  showVetting?: boolean;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const PersonUpdateCompanyInformation: React.FC<PersonUpdateCompanyInformationProps> = props => {
  const items = props.subcontractors.map(company => ({
    id: company.combinedId,
    name: company.name,
  }));
  const [expiry, setExpiry] = useState(null);
  const [siaName, setSiaName] = useState();

  const handleSiaLicenceNumber = event => {
    if (event.target.value.length <= 0) {
      props.setFieldValues(oldValues => ({ ...oldValues, ['siaLicenceNumber']: null }));
      props.setExpiry_date(null);
      setSiaName(null);
    }
    props.setNewValues(event);
  };

  const handleLicenceExpiryDate = event => {
    if (props.fieldValues['siaLicenceNumber'] === '' || props.fieldValues['siaLicenceNumber'] === undefined) {
      props.setFieldValues(oldValues => ({ ...oldValues, expiry_date: null }));
      props.setFieldError(oldFieldError => ({ ...oldFieldError, ['siaExpiryDate_error']: false }));
    } else {
      const selectedDate = new Date(event);
      props.setExpiry_date(event);
      props.setFieldValues(oldValues => ({ ...oldValues, expiry_date: event }));
      if (expiry === null && props.fieldError['siaLicenceNumber_error'] === true) {
        props.setFieldError(oldFieldError => ({
          ...oldFieldError,
          ['siaExpiryDate_error']: true,
          ['siaExpiryDate_helper']: 'Invalid expiry date',
        }));
      } else {
        if (
          moment(selectedDate).date() === moment(expiry).date() &&
          moment(selectedDate).month() === moment(expiry).month() &&
          moment(selectedDate).year() === moment(expiry).year()
        ) {
          props.setFieldError(oldFieldError => ({ ...oldFieldError, ['siaExpiryDate_error']: false }));
        } else {
          props.setFieldError(oldFieldError => ({
            ...oldFieldError,
            ['siaExpiryDate_error']: true,
            ['siaExpiryDate_helper']: 'Invalid expiry date',
          }));
        }
      }
    }
  };

  const isValidSiaLicence = async event => {
    if (event.target.value.length === 16 && props.fieldError['siaLicenceNumber_error'] === false) {
      const isValidLicence = await verifySiaLicenceNumber(parseInt(event.target.value, 10));
      if (isValidLicence.status === 200 && isValidLicence.data.status === 'Valid') {
        setSiaName(isValidLicence?.data?.name);
        setExpiry(new Date(isValidLicence?.data?.expiry));
        props.setExpiry_date(isValidLicence?.data?.expiry);
        props.setFieldValues(oldValues => ({ ...oldValues, expiry_date: new Date(isValidLicence?.data?.expiry) }));
        props.setFieldError(oldFieldError => ({ ...oldFieldError, ['siaLicenceNumber_error']: false, ['siaExpiryDate_error']: false }));
        if (!props.isNew) {
          if (props.personEntity.siaLicenceNumber) {
            props.setFieldValues(oldFieldValues => ({
              ...oldFieldValues,
              ['siaLicenceNumber']: {
                id: props.personEntity.siaLicenceNumber.id,
                value: event.target.value,
                expiry_date: isValidLicence?.data?.expiry,
                designation_qualification_type_id: 1,
                person_id: props.personEntity.id,
                name: 'licence',
              },
            }));
          } else {
            props.setFieldValues(oldFieldValues => ({
              ...oldFieldValues,
              ['siaLicenceNumber']: {
                id: null,
                value: event.target.value,
                expiry_date: isValidLicence?.data?.expiry,
                designation_qualification_type_id: 1,
                person_id: props.personEntity.id,
                name: 'licence',
              },
            }));
          }
        } else {
          props.setFieldValues(oldFieldValues => ({ ...oldFieldValues, ['siaLicenceNumber']: event.target.value }));
        }
      } else {
        setSiaName(null);
        props.setFieldError(oldFieldError => ({
          ...oldFieldError,
          ['siaLicenceNumber_error']: true,
          ['siaLicenceNumber_helper']: 'Invalid licence no',
        }));
      }
      return 0;
    }
  };

  const handleEnhancedNumber = e => {
    if (!props.isNew) {
      if (props.personEntity.enhanced_dbs) {
        props.setFieldValues(oldFieldValues => ({
          ...oldFieldValues,
          ['enhanced_dbs']: {
            id: props.personEntity?.enhanced_dbs?.id,
            value: e.target.value,
            name: 'enhancedDBS',
            designation_qualification_type_id: 12,
            person_id: props.personEntity.id,
          },
        }));
      } else {
        props.setFieldValues(oldFieldValues => ({
          ...oldFieldValues,
          ['enhanced_dbs']: {
            id: null,
            value: e.target.value,
            name: 'enhancedDBS',
            designation_qualification_type_id: 12,
            person_id: props.personEntity.id,
          },
        }));
      }
    } else {
      props.setFieldValues(oldFieldValues => ({
        ...oldFieldValues,
        ['enhanced_dbs']: {
          value: e.target.value,
          name: 'enhancedDBS',
          designation_qualification_type_id: 12,
          issue_date:
            props.fieldValues && props.fieldValues['enhanced_dbs'] && props.fieldValues['enhanced_dbs']['issue_date']
              ? props.fieldValues['enhanced_dbs']['issue_date']
              : null,
        },
      }));
    }
  };

  const handleBDSExpiryDate = e => {
    if (!props.isNew) {
      if (props.personEntity.enhanced_dbs) {
        props.setFieldValues(oldFieldValues => ({
          ...oldFieldValues,
          ['enhanced_dbs']: {
            id: props.personEntity?.ehanced_dbs?.id,
            value: props.personEntity.enhanced_dbs ? props.personEntity.enhanced_dbs.value : null,
            issue_date: new Date(e),
            name: 'enhancedDBS',
            designation_qualification_type_id: 12,
            person_id: props.personEntity.id,
          },
        }));
      } else {
        props.setFieldValues(oldFieldValues => ({
          ...oldFieldValues,
          ['enhanced_dbs']: {
            id: null,
            value:
              props.fieldValues && props.fieldValues['enhanced_dbs'] && props.fieldValues['enhanced_dbs']['value']
                ? props.fieldValues['enhanced_dbs']['value']
                : null,
            issue_date: new Date(e),
            name: 'enhancedDBS',
            designation_qualification_type_id: 12,
            person_id: props.personEntity.id,
          },
        }));
      }
    } else {
      props.setFieldValues(oldFieldValues => ({
        ...oldFieldValues,
        ['enhanced_dbs']: {
          value:
            props.fieldValues && props.fieldValues['enhanced_dbs'] && props.fieldValues['enhanced_dbs']['value']
              ? props.fieldValues['enhanced_dbs']['value']
              : null,
          issue_date: new Date(e),
          name: 'enhancedDBS',
          designation_qualification_type_id: 12,
        },
      }));
    }
  };

  const handleReferenceNumber = e => {
    if (!props.isNew) {
      if (props.personEntity.NSVS_clearance) {
        props.setFieldValues(oldFieldValues => ({
          ...oldFieldValues,
          ['NSVS_clearance']: {
            id: props.personEntity.NSVS_clearance.id,
            value: e.target.value,
            issue_date: props.personEntity.NSVS_clearance ? props.personEntity.NSVS_clearance.issue_date : null,
            expiry_date: props.personEntity.NSVS_clearance ? props.personEntity.NSVS_clearance.expiry_date : null,
            clearance_level: props.personEntity.NSVS_clearance ? props.personEntity.NSVS_clearance.clearance_level : null,
            name: 'NSVS_clearance',
            designation_qualification_type_id: 13,
            person_id: props.personEntity.id,
          },
        }));
      } else {
        props.setFieldValues(oldFieldValues => ({
          ...oldFieldValues,
          ['NSVS_clearance']: {
            id: null,
            value: e.target.value,
            issue_date:
              props.fieldValues && props.fieldValues['NSVS_clearance'] && props.fieldValues['NSVS_clearance']['issue_date']
                ? props.fieldValues['NSVS_clearance']['issue_date']
                : null,
            expiry_date:
              props.fieldValues && props.fieldValues['NSVS_clearance'] && props.fieldValues['NSVS_clearance']['expiry_date']
                ? props.fieldValues['NSVS_clearance']['expiry_date']
                : null,
            clearance_level:
              props.fieldValues && props.fieldValues['NSVS_clearance'] && props.fieldValues['NSVS_clearance']['clearance_level']
                ? props.fieldValues['NSVS_clearance']['clearance_level']
                : null,
            name: 'NSVS_clearance',
            designation_qualification_type_id: 13,
            person_id: props.personEntity.id,
          },
        }));
      }
    } else {
      props.setFieldValues(oldFieldValues => ({
        ...oldFieldValues,
        ['NSVS_clearance']: {
          value: e.target.value,
          issue_date:
            props.fieldValues && props.fieldValues['NSVS_clearance'] && props.fieldValues['NSVS_clearance']['issue_date']
              ? props.fieldValues['NSVS_clearance']['issue_date']
              : null,
          expiry_date:
            props.fieldValues && props.fieldValues['NSVS_clearance'] && props.fieldValues['NSVS_clearance']['expiry_date']
              ? props.fieldValues['NSVS_clearance']['expiry_date']
              : null,
          clearance_level:
            props.fieldValues && props.fieldValues['NSVS_clearance'] && props.fieldValues['NSVS_clearance']['clearance_level']
              ? props.fieldValues['NSVS_clearance']['clearance_level']
              : null,
          name: 'NSVS_clearance',
          designation_qualification_type_id: 13,
        },
      }));
    }
  };

  const handleClearanceLevel = e => {
    if (!props.isNew) {
      if (props.personEntity.NSVS_clearance) {
        props.setFieldValues(oldFieldValues => ({
          ...oldFieldValues,
          ['NSVS_clearance']: {
            id: props.personEntity.NSVS_clearance.id,
            value: props.personEntity.NSVS_clearance ? props.personEntity.NSVS_clearance.value : null,
            issue_date: props.personEntity.NSVS_clearance ? props.personEntity.NSVS_clearance.issue_date : null,
            expiry_date: props.personEntity.NSVS_clearance ? props.personEntity.NSVS_clearance.expiry_date : null,
            clearance_level: e.target.value,
            name: 'NSVS_clearance',
            designation_qualification_type_id: 13,
            person_id: props.personEntity.id,
          },
        }));
      } else {
        props.setFieldValues(oldFieldValues => ({
          ...oldFieldValues,
          ['NSVS_clearance']: {
            id: null,
            value:
              props.fieldValues && props.fieldValues['NSVS_clearance'] && props.fieldValues['NSVS_clearance']['value']
                ? props.fieldValues['NSVS_clearance']['value']
                : null,
            issue_date:
              props.fieldValues && props.fieldValues['NSVS_clearance'] && props.fieldValues['NSVS_clearance']['issue_date']
                ? props.fieldValues['NSVS_clearance']['issue_date']
                : null,
            expiry_date:
              props.fieldValues && props.fieldValues['NSVS_clearance'] && props.fieldValues['NSVS_clearance']['expiry_date']
                ? props.fieldValues['NSVS_clearance']['expiry_date']
                : null,
            clearance_level: e.target.value,
            name: 'NSVS_clearance',
            designation_qualification_type_id: 13,
            person_id: props.personEntity.id,
          },
        }));
      }
    } else {
      props.setFieldValues(oldFieldValues => ({
        ...oldFieldValues,
        ['NSVS_clearance']: {
          value:
            props.fieldValues && props.fieldValues['NSVS_clearance'] && props.fieldValues['NSVS_clearance']['value']
              ? props.fieldValues['NSVS_clearance']['value']
              : null,
          issue_date:
            props.fieldValues && props.fieldValues['NSVS_clearance'] && props.fieldValues['NSVS_clearance']['issue_date']
              ? props.fieldValues['NSVS_clearance']['issue_date']
              : null,
          expiry_date:
            props.fieldValues && props.fieldValues['NSVS_clearance'] && props.fieldValues['NSVS_clearance']['expiry_date']
              ? props.fieldValues['NSVS_clearance']['expiry_date']
              : null,
          clearance_level: e.target.value,
          name: 'NSVS_clearance',
          designation_qualification_type_id: 13,
        },
      }));
    }
  };

  const handleNSVSIssueDate = e => {
    if (!props.isNew) {
      if (props.personEntity.NSVS_clearance) {
        props.setFieldValues(oldFieldValues => ({
          ...oldFieldValues,
          ['NSVS_clearance']: {
            id: props.personEntity.NSVS_clearance.id,
            value: props.personEntity.NSVS_clearance ? props.personEntity.NSVS_clearance.value : null,
            issue_date: new Date(e),
            expiry_date: props.personEntity.NSVS_clearance ? props.personEntity.NSVS_clearance.expiry_date : null,
            clearance_level: props.personEntity.NSVS_clearance ? props.personEntity.NSVS_clearance.clearance_level : null,
            name: 'NSVS_clearance',
            designation_qualification_type_id: 13,
            person_id: props.personEntity.id,
          },
        }));
      } else {
        props.setFieldValues(oldFieldValues => ({
          ...oldFieldValues,
          ['NSVS_clearance']: {
            id: null,
            value:
              props.fieldValues && props.fieldValues['NSVS_clearance'] && props.fieldValues['NSVS_clearance']['value']
                ? props.fieldValues['NSVS_clearance']['value']
                : null,
            issue_date: new Date(e),
            expiry_date:
              props.fieldValues && props.fieldValues['NSVS_clearance'] && props.fieldValues['NSVS_clearance']['expiry_date']
                ? props.fieldValues['NSVS_clearance']['expiry_date']
                : null,
            clearance_level:
              props.fieldValues && props.fieldValues['NSVS_clearance'] && props.fieldValues['NSVS_clearance']['clearance_level']
                ? props.fieldValues['NSVS_clearance']['clearance_level']
                : null,
            name: 'NSVS_clearance',
            designation_qualification_type_id: 13,
            person_id: props.personEntity.id,
          },
        }));
      }
    } else {
      props.setFieldValues(oldFieldValues => ({
        ...oldFieldValues,
        ['NSVS_clearance']: {
          value:
            props.fieldValues && props.fieldValues['NSVS_clearance'] && props.fieldValues['NSVS_clearance']['value']
              ? props.fieldValues['NSVS_clearance']['value']
              : null,
          issue_date: new Date(e),
          expiry_date:
            props.fieldValues && props.fieldValues['NSVS_clearance'] && props.fieldValues['NSVS_clearance']['expiry_date']
              ? props.fieldValues['NSVS_clearance']['expiry_date']
              : null,
          clearance_level:
            props.fieldValues && props.fieldValues['NSVS_clearance'] && props.fieldValues['NSVS_clearance']['clearance_level']
              ? props.fieldValues['NSVS_clearance']['clearance_level']
              : null,
          name: 'NSVS_clearance',
          designation_qualification_type_id: 13,
        },
      }));
    }
  };

  const handleNSVSExpiryDate = e => {
    if (!props.isNew) {
      if (props.personEntity.NSVS_clearance) {
        props.setFieldValues(oldFieldValues => ({
          ...oldFieldValues,
          ['NSVS_clearance']: {
            id: props.personEntity.NSVS_clearance.id,
            value: props.personEntity.NSVS_clearance ? props.personEntity.NSVS_clearance.value : null,
            issue_date: props.personEntity.NSVS_clearance ? props.personEntity.NSVS_clearance.issue_date : null,
            expiry_date: new Date(e),
            clearance_level: props.personEntity.NSVS_clearance ? props.personEntity.NSVS_clearance.clearance_level : null,
            name: 'NSVS_clearance',
            designation_qualification_type_id: 13,
            person_id: props.personEntity.id,
          },
        }));
      } else {
        props.setFieldValues(oldFieldValues => ({
          ...oldFieldValues,
          ['NSVS_clearance']: {
            id: null,
            value:
              props.fieldValues && props.fieldValues['NSVS_clearance'] && props.fieldValues['NSVS_clearance']['value']
                ? props.fieldValues['NSVS_clearance']['value']
                : null,
            issue_date:
              props.fieldValues && props.fieldValues['NSVS_clearance'] && props.fieldValues['NSVS_clearance']['issue_date']
                ? props.fieldValues['NSVS_clearance']['issue_date']
                : null,
            expiry_date: new Date(e),
            clearance_level:
              props.fieldValues && props.fieldValues['NSVS_clearance'] && props.fieldValues['NSVS_clearance']['clearance_level']
                ? props.fieldValues['NSVS_clearance']['clearance_level']
                : null,
            name: 'NSVS_clearance',
            designation_qualification_type_id: 13,
            person_id: props.personEntity.id,
          },
        }));
      }
    } else {
      props.setFieldValues(oldFieldValues => ({
        ...oldFieldValues,
        ['NSVS_clearance']: {
          value:
            props.fieldValues && props.fieldValues['NSVS_clearance'] && props.fieldValues['NSVS_clearance']['value']
              ? props.fieldValues['NSVS_clearance']['value']
              : null,
          issue_date:
            props.fieldValues && props.fieldValues['NSVS_clearance'] && props.fieldValues['NSVS_clearance']['issue_date']
              ? props.fieldValues['NSVS_clearance']['issue_date']
              : null,
          expiry_date: new Date(e),
          clearance_level:
            props.fieldValues && props.fieldValues['NSVS_clearance'] && props.fieldValues['NSVS_clearance']['clearance_level']
              ? props.fieldValues['NSVS_clearance']['clearance_level']
              : null,
          name: 'NSVS_clearance',
          designation_qualification_type_id: 13,
        },
      }));
    }
  };

  const handleCNICNumber = e => {
    if (!props.isNew) {
      if (props.personEntity.CNIC) {
        props.setFieldValues(oldFieldValues => ({
          ...oldFieldValues,
          ['CNIC']: {
            id: props.personEntity?.CNIC?.id,
            value: e.target.value,
            expiry_date:
              props.fieldValues && props.fieldValues['CNIC'] && props.fieldValues['CNIC']['expiry_date']
                ? props.fieldValues['CNIC']['expiry_date']
                : null,
            name: 'CNIC',
            designation_qualification_type_id: 14,
            person_id: props.personEntity.id,
          },
        }));
      } else {
        props.setFieldValues(oldFieldValues => ({
          ...oldFieldValues,
          ['CNIC']: {
            id: null,
            value: e.target.value,
            expiry_date:
              props.fieldValues && props.fieldValues['CNIC'] && props.fieldValues['CNIC']['expiry_date']
                ? props.fieldValues['CNIC']['expiry_date']
                : null,
            name: 'CNIC',
            designation_qualification_type_id: 14,
            person_id: props.personEntity.id,
          },
        }));
      }
    } else {
      props.setFieldValues(oldFieldValues => ({
        ...oldFieldValues,
        ['CNIC']: {
          value: e.target.value,
          name: 'CNIC',
          designation_qualification_type_id: 14,
          expiry_date:
            props.fieldValues && props.fieldValues['CNIC'] && props.fieldValues['CNIC']['expiry_date']
              ? props.fieldValues['CNIC']['expiry_date']
              : null,
        },
      }));
    }
  };

  const handleCNICExpiryDate = e => {
    if (!props.isNew) {
      if (props.personEntity.CNIC) {
        props.setFieldValues(oldFieldValues => ({
          ...oldFieldValues,
          ['CNIC']: {
            id: props.personEntity?.CNIC?.id,
            value: props.personEntity.CNIC ? props.personEntity.CNIC.value : null,
            expiry_date: new Date(e),
            name: 'CNIC',
            designation_qualification_type_id: 14,
            person_id: props.personEntity.id,
          },
        }));
      } else {
        props.setFieldValues(oldFieldValues => ({
          ...oldFieldValues,
          ['CNIC']: {
            id: null,
            value:
              props.fieldValues && props.fieldValues['CNIC'] && props.fieldValues['CNIC']['value']
                ? props.fieldValues['CNIC']['value']
                : null,
            expiry_date: new Date(e),
            name: 'CNIC',
            designation_qualification_type_id: 14,
            person_id: props.personEntity.id,
          },
        }));
      }
    } else {
      props.setFieldValues(oldFieldValues => ({
        ...oldFieldValues,
        ['CNIC']: {
          value:
            props.fieldValues && props.fieldValues['CNIC'] && props.fieldValues['CNIC']['value']
              ? props.fieldValues['CNIC']['value']
              : null,
          expiry_date: new Date(e),
          name: 'CNIC',
          designation_qualification_type_id: 14,
        },
      }));
    }
  };

  return (
    <React.Fragment>
      <Paper className="personOtherPaper">
        <div className="personFieldDivsideBySide">
          <Grid item xs={12} md={6} sm={12}>
            <Autocomplete
              className={'person-fields'}
              ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
              limitTags={1}
              multiple
              options={items}
              disableCloseOnSelect
              getOptionLabel={option => option.name}
              value={props.company ? props.company : null}
              renderTags={values => values.map(value => <Chip key={value.id} label={value.name} />)}
              getOptionSelected={(option, value) => option.id === value.id}
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected ? selected : null} />
                  {option.name}
                </React.Fragment>
              )}
              onChange={props.handlePersonCompany}
              renderInput={params => (
                <TextField {...params} error={props.fieldError['company_error']} required variant="outlined" label="Company Name" />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6} sm={12}>
            <FormControl className="person-fields">
              <Autocomplete
                options={props.positionsList}
                noOptionsText={'Please add new Positions'}
                classes={{ listbox: props.classes.listbox }}
                ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                getOptionLabel={option => option.name || ''}
                className="PersonAutocomplete"
                onChange={props.handlePositionData}
                value={props.fieldValues['designation'] ? props.fieldValues['designation'] : null}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    className="personAutocompleteTextfield autocompleteLabel"
                    label="Position"
                    margin="normal"
                    fullWidth
                    required
                    error={props.fieldError['position_error']}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </div>
        {props.showVetting && (
          <div className="personFieldDivsideBySide">
            <Grid item xs={12} md={6} sm={12}>
              <FormControl style={{ minWidth: 150 }} className="shift-missed-buttons" variant="outlined" size="small">
                <FormControlLabel
                  id={'processVetting'}
                  className="shift-missed-checkbox-buttons"
                  name="processVetting"
                  label="Process Vetting"
                  labelPlacement="end"
                  control={
                    <Checkbox
                      name={'processVetting'}
                      icon={<CircleUnchecked />}
                      checkedIcon={<CircleCheckedFilled />}
                      onChange={props.handleProcessVetting}
                      defaultChecked={true}
                    />
                  }
                  style={{
                    color: 'black',
                    background: 'linear-gradient(to bottom, #efb5ee, #d488d3)',
                    border: '1px solid #E5A5E4',
                  }}
                />
              </FormControl>
            </Grid>
          </div>
        )}
        <div className="personFieldDivsideBySide">
          <Grid item xs={12} md={6} sm={12}>
            <FormControl className="person-fields">
              <Autocomplete
                options={props.rolesList}
                noOptionsText={'Please add new Roles'}
                classes={{ listbox: props.classes.listbox }}
                ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                getOptionLabel={option => option.name || ''}
                className="PersonAutocomplete"
                onChange={props.handleRoleData}
                value={
                  props.fieldValues['role']
                    ? props.fieldValues['role'][0]
                      ? props.fieldValues['role'][0]
                      : props.fieldValues['role']
                    : null
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    className="personAutocompleteTextfield autocompleteLabel"
                    label="Role"
                    margin="normal"
                    fullWidth
                    required
                    error={props.fieldError['role_error']}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} sm={12}>
            <FormControl className="person-fields">
              <Autocomplete
                options={props.departmentsList}
                noOptionsText={'Please add new Departments'}
                classes={{ listbox: props.classes.listbox }}
                ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                getOptionLabel={option => option.name || ''}
                className="PersonAutocomplete"
                onChange={props.handleDepartmentData}
                value={props.fieldValues['department'] ? props.fieldValues['department'] : null}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    className="personAutocompleteTextfield autocompleteLabel"
                    label="Department"
                    margin="normal"
                    fullWidth
                    required
                    error={props.fieldError['department_error']}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </div>
        <div className="personFieldDivsideBySide">
          {props.fieldValues['country']?.name === 'Pakistan' && (
            <>
              <Grid item lg={6} xs={12} md={6} sm={12}>
                <FormControl className="person-fields">
                  <TextField
                    type="text"
                    variant="outlined"
                    onChange={handleCNICNumber}
                    placeholder="Enter your sia number"
                    label="CNIC"
                    name="CNIC"
                    id="person_licence_number"
                    value={props.fieldValues['CNIC']?.value}
                    inputProps={{ maxLength: 16 }}
                  />
                </FormControl>
              </Grid>
              <Grid item lg={6} xs={12} md={6} sm={12}>
                <FormControl className="dbs-details-fields">
                  <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                    <KeyboardDatePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      label="CNIC Expiry Date"
                      format={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'MM/dd/yyyy'}
                      value={
                        props.fieldValues['CNIC'] && props.fieldValues['CNIC']['expiry_date']
                          ? props.fieldValues['CNIC']['expiry_date']
                          : props.personEntity && props.personEntity['CNIC'] && props.personEntity['CNIC']['expiry_date']
                          ? props.personEntity['CNIC']['expiry_date']
                          : null
                      }
                      ToolbarComponent={prop => (
                        <>
                          <CustomToolbarComponent {...prop} settingFormat={props.setting.date_format} />
                        </>
                      )}
                      placeholder={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'MM/DD/YYYY'}
                      InputAdornmentProps={{ position: 'start' }}
                      onChange={date => handleCNICExpiryDate(date)}
                    />{' '}
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Grid>
            </>
          )}

          {props.fieldValues['country']?.name === 'United Kingdom' && (
            <>
              <Grid item lg={6} xs={12} md={6} sm={12}>
                <FormControl className="person-fields">
                  <TextField
                    type="text"
                    variant="outlined"
                    onChange={handleSiaLicenceNumber}
                    placeholder="Enter your sia number"
                    label="SIA Licence Number"
                    name="siaLicenceNumber"
                    id="person_licence_number"
                    value={props.fieldValues['siaLicenceNumber']?.value}
                    error={props.fieldError['siaLicenceNumber_error']}
                    helperText={props.fieldError['siaLicenceNumber_error'] ? props.fieldError['siaLicenceNumber_helper'] : ''}
                    inputProps={{ maxLength: 16 }}
                    onBlur={event => isValidSiaLicence(event)}
                  />
                </FormControl>
              </Grid>
              <Grid item lg={3} xs={3} md={3} sm={12}>
                <FormControl className="person-sia-details-fields">
                  <MuiPickersUtilsProvider libInstance={moment} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      disablePast={true}
                      variant="inline"
                      inputVariant="outlined"
                      label="Licence Expiry Date"
                      format={'dd/MM/yyyy'}
                      value={
                        props.fieldValues['siaLicenceNumber']?.expiry_date
                          ? props.fieldValues['siaLicenceNumber'].expiry_date
                          : props.expiry_date
                      }
                      error={props.fieldError['siaExpiryDate_error']}
                      helperText={props.fieldError['siaExpiryDate_error'] ? props.fieldError['siaExpiryDate_helper'] : ''}
                      ToolbarComponent={prop => (
                        <>
                          <CustomToolbarComponent {...prop} settingFormat={props.setting.date_format} />
                        </>
                      )}
                      placeholder={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'dd/MM/YYYY'}
                      InputAdornmentProps={{ position: 'start' }}
                      onChange={date => handleLicenceExpiryDate(date)}
                    />{' '}
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Grid>
              <Grid item lg={3} xs={3} md={3} sm={12}>
                <FormControl className="person-sia-details-fields">
                  <TextField
                    type="text"
                    variant="outlined"
                    placeholder="Name on the Badge"
                    label="Name on the Badge"
                    name="siaLicenceNumber"
                    id="person_licence_number"
                    value={siaName ? siaName : ''}
                  />
                </FormControl>
              </Grid>
            </>
          )}
        </div>

        {props.fieldValues['country']?.name === 'United Kingdom' && (
          <>
            <p style={{ marginTop: '15px', marginBottom: '0px' }}>Enhanced DBS</p>
            <div className="personFieldDivsideBySide">
              <Grid item lg={6} xs={12} md={6} sm={12}>
                <FormControl className="person-fields">
                  <TextField
                    type="text"
                    variant="outlined"
                    onChange={handleEnhancedNumber}
                    placeholder="Certificate number"
                    label="Certificate number"
                    name="enhanced_dbs"
                    id="person_licence_number"
                    value={props.fieldValues['enhanced_dbs']?.value}
                    inputProps={{ maxLength: 16 }}
                  />
                </FormControl>
              </Grid>
              <Grid item lg={6} xs={12} md={6} sm={12}>
                <FormControl className="dbs-details-fields">
                  <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                    <KeyboardDatePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      label="Issue Date"
                      format={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'MM/dd/yyyy'}
                      value={
                        props.fieldValues['enhanced_dbs'] && props.fieldValues['enhanced_dbs']['issue_date']
                          ? props.fieldValues['enhanced_dbs']['issue_date']
                          : props.personEntity && props.personEntity['enhanced_dbs'] && props.personEntity['enhanced_dbs']['issue_date']
                          ? props.personEntity['enhanced_dbs']['issue_date']
                          : null
                      }
                      ToolbarComponent={prop => (
                        <>
                          <CustomToolbarComponent {...prop} settingFormat={props.setting.date_format} />
                        </>
                      )}
                      placeholder={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'MM/DD/YYYY'}
                      InputAdornmentProps={{ position: 'start' }}
                      onChange={date => handleBDSExpiryDate(date)}
                    />{' '}
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Grid>
            </div>

            <p style={{ marginTop: '15px', marginBottom: '0px' }}>NSVS Clearance</p>
            <div className="personFieldDivsideBySide">
              <Grid item lg={6} xs={12} md={6} sm={12}>
                <FormControl className="person-fields">
                  <TextField
                    type="text"
                    variant="outlined"
                    onChange={handleReferenceNumber}
                    placeholder="Reference number"
                    label="Reference number"
                    name="siaLicenceNumber"
                    id="person_licence_number"
                    value={props.fieldValues['NSVS_clearance']?.value}
                    inputProps={{ maxLength: 16 }}
                  />
                </FormControl>
              </Grid>

              <Grid item lg={6} xs={12} md={6} sm={12}>
                <FormControl className="person-fields">
                  <TextField
                    type="text"
                    variant="outlined"
                    onChange={handleClearanceLevel}
                    placeholder="Clearance level"
                    label="Clearance level"
                    name="siaLicenceNumber"
                    id="person_licence_number"
                    value={props.fieldValues['NSVS_clearance']?.clearance_level}
                    inputProps={{ maxLength: 16 }}
                  />
                </FormControl>
              </Grid>
            </div>

            <div className="personFieldDivsideBySide">
              <Grid item lg={6} xs={12} md={6} sm={12}>
                <FormControl className="dbs-details-fields">
                  <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                    <KeyboardDatePicker
                      autoOk
                      disablePast={true}
                      variant="inline"
                      inputVariant="outlined"
                      label="Start Date"
                      format={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'MM/dd/yyyy'}
                      value={
                        props.fieldValues['NSVS_clearance'] && props.fieldValues['NSVS_clearance']['issue_date']
                          ? props.fieldValues['NSVS_clearance']['issue_date']
                          : props.personEntity && props.personEntity['NSVS_clearance'] && props.personEntity['NSVS_clearance']['issue_date']
                          ? props.personEntity['NSVS_clearance']['issue_date']
                          : null
                      }
                      ToolbarComponent={prop => (
                        <>
                          <CustomToolbarComponent {...prop} settingFormat={props.setting.date_format} />
                        </>
                      )}
                      placeholder={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'MM/DD/YYYY'}
                      InputAdornmentProps={{ position: 'start' }}
                      onChange={date => handleNSVSIssueDate(date)}
                    />{' '}
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Grid>

              <Grid item lg={6} xs={12} md={6} sm={12}>
                <FormControl className="dbs-details-fields">
                  <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                    <KeyboardDatePicker
                      autoOk
                      disablePast={true}
                      variant="inline"
                      inputVariant="outlined"
                      label="End Date"
                      format={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'MM/dd/yyyy'}
                      value={
                        props.fieldValues['NSVS_clearance'] && props.fieldValues['NSVS_clearance']['expiry_date']
                          ? props.fieldValues['NSVS_clearance']['expiry_date']
                          : props.personEntity &&
                            props.personEntity['NSVS_clearance'] &&
                            props.personEntity['NSVS_clearance']['expiry_date']
                          ? props.personEntity['NSVS_clearance']['expiry_date']
                          : null
                      }
                      ToolbarComponent={prop => (
                        <>
                          <CustomToolbarComponent {...prop} settingFormat={props.setting.date_format} />
                        </>
                      )}
                      placeholder={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'MM/DD/YYYY'}
                      InputAdornmentProps={{ position: 'start' }}
                      onChange={date => handleNSVSExpiryDate(date)}
                    />{' '}
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Grid>
            </div>
          </>
        )}
      </Paper>
    </React.Fragment>
  );
};

export default PersonUpdateCompanyInformation;
