import { ExportPDF } from '../components/downloadPDFs/exportPDF';
import moment from 'moment';

export const deparmentConvertToPDF = data => {
  const header = ['Date of Creation', 'Department Name'];

  const Header = [header];
  const title = 'List of Departments';
  const jsonData = data.map(row => [moment(row.created_at).format('DD-MM-YYYY'), row.name]);
  const filename = 'List of Departments';

  ExportPDF(Header, title, jsonData, filename);
};
