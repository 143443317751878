import { useTable, useExpanded } from 'react-table';
import React, { useState, useEffect } from 'react';
import './call-log.scss';

function SubRows({ row, rowProps, data, loading }) {
  return (
    <>
      {data && data.length > 0
        ? data.map((x, i) => {
            return (
              <tr {...rowProps} key={`${rowProps.key}-expanded-${i}`} className="entities_Reports_React_Table_InnerRow">
                {row.cells.map((cell, i) => {
                  return (
                    <td {...cell.getCellProps()} key={i} className="entities_Reports_React_Table_InnerRow_Cell">
                      {cell.render(cell.column.SubCell ? 'SubCell' : 'Cell', {
                        value: cell.column.accessor && cell.column.accessor(x, i),
                        row: { ...row, original: x },
                      })}
                    </td>
                  );
                })}
              </tr>
            );
          })
        : !loading && <div className="no-record-warning">No Records found</div>}
    </>
  );
}

export function SubRowAsync({ row, rowProps, loading }) {
  const [data, setData] = React.useState([]);
  const detailData = [];
  row.original.PAYLOAD.map(val => {
    const data = { ...val };
    detailData.push(data);
  });

  React.useEffect(() => {
    setData(detailData);
  }, []);

  return <SubRows row={row} rowProps={rowProps} data={data} loading={loading} />;
}

export function CallReportTable({ columns: userColumns, data, renderRowSubComponent, calLogsList, loading }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { expanded },
  } = useTable(
    {
      columns: userColumns,
      data,
    },
    useExpanded
  );
  return (
    <>
      <div className="globalTableHeaderGradientColor clientTableHeaderRow sticky_div">
        <div className="subcontractorTableHeaderCell callLogHeader" style={{ border: 'none' }}>
          Name
        </div>
        <div className="subcontractorTableHeaderCell callLogHeaderExpand"></div>
        <div className="subcontractorTableHeaderCell callLogHeader">Date/Time</div>
        <div className="subcontractorTableHeaderCell callLogHeader">Status</div>
        <div className="subcontractorTableHeaderCell callLogHeader">Shift Event type</div>
        <div className="subcontractorTableHeaderCell callLogHeader">Phone Number</div>
        <div className="subcontractorTableHeaderCell callLogHeader">On-Site Pictures</div>
      </div>
      <table {...getTableProps()} className="entities_Reports_React_Table">
        {/* <thead className="entities_Reports_React_Table_Header">
          {headerGroups && headerGroups.length > 0
            ? headerGroups.map((headerGroup, i) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                  {headerGroup.headers.map((column, i) => (
                    <th {...column.getHeaderProps()} key={i} className="callLogTableBodyCell">
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))
            : !loading && <div className="no-record-warning">No Header found</div>}
        </thead> */}
        <tbody {...getTableBodyProps()}>
          {rows && rows.length > 0
            ? rows.map((row, i) => {
                prepareRow(row);
                const rowProps = row.getRowProps();
                return (
                  <React.Fragment key={rowProps.key}>
                    <tr {...rowProps} key={i} className="entities_Reports_React_Table_OuterRow">
                      {row.cells.map((cell, i) => {
                        return (
                          <td {...cell.getCellProps()} key={i} className="callLogTableBodyCell">
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                    {row.isExpanded && renderRowSubComponent({ row, rowProps, loading })}
                  </React.Fragment>
                );
              })
            : !loading && (
                <tr style={{ borderBottom: 'unset' }}>
                  <td colSpan={headerGroups[0].headers.length} style={{ borderBottom: 'unset' }}>
                    <div className="no-record-warning">No Records found</div>
                  </td>
                </tr>
              )}
        </tbody>
        <br />
        <br />
      </table>
    </>
  );
}
