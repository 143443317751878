import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Grid, FormControl, TextField, FormHelperText, FormControlLabel, Checkbox } from '@material-ui/core';
import { Col, Row } from 'reactstrap';
import { form } from 'availity-reactstrap-validation';
import { Translate, translate, getUrlParameter } from 'react-jhipster';
import { RouteComponentProps } from 'react-router-dom';
import './password-reset.scss';
import { handlePasswordResetFinish, reset } from '../password-reset.reducer';
import PasswordStrengthBar from 'app/shared/layout/password/password-strength-bar';

export interface IPasswordResetFinishProps extends DispatchProps, RouteComponentProps<{ key: string }> {}

export const PasswordResetFinishPage = (props: IPasswordResetFinishProps) => {
  const [password, setPassword] = useState('');
  const [key] = useState(getUrlParameter('key', props.location.search));

  useEffect(
    () => () => {
      props.reset();
    },
    []
  );

  const handleValidSubmit = event => props.handlePasswordResetFinish(key, event.newPassword);

  const updatePassword = event => setPassword(event.target.value);

  const getResetForm = () => {
    return (
      <Grid item md={12} container justify="center">
        <Grid item md={2}></Grid>
        <Grid item md={8}>
          <form onSubmit={handleValidSubmit} className="password-reset-form">
            <Grid item md={12} container>
              <Grid item md={12} xs={12}>
                <FormControl className="password-reset-fields">
                  <TextField
                    name="newPassword"
                    label={translate('global.form.newpassword.label')}
                    placeholder={translate('global.form.newpassword.placeholder')}
                    type="password"
                    required
                    inputProps={{
                      minLength: 4,
                      maxLength: 50,
                    }}
                    onChange={updatePassword}
                    data-cy="resetPassword"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <PasswordStrengthBar password={password} />
            <Grid item md={12} container>
              <Grid item md={12} xs={12}>
                <FormControl className="password-reset-fields">
                  <TextField
                    name="confirmPassword"
                    variant="outlined"
                    label={translate('global.form.confirmpassword.label')}
                    placeholder={translate('global.form.confirmpassword.placeholder')}
                    type="password"
                    required
                    inputProps={{
                      minLength: 4,
                      maxLength: 50,
                      match: 'newPassword',
                    }}
                    data-cy="confirmResetPassword"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item md={12} container>
              <Grid item md={12} xs={12}>
                <FormControl className="password-reset-fields">
                  <Button style={{ backgroundColor: '#388e3c', display: 'block' }} type="submit" data-cy="submit">
                    <Translate contentKey="reset.finish.form.button">Validate new password</Translate>
                  </Button>
                </FormControl>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    );
  };

  return (
    <div className="password-reset-full-container">
      <Grid item md={12} container justify="center">
        <Grid item md={2}></Grid>
        <Grid item md={4} xs={4}>
          <h1>
            <Translate contentKey="reset.finish.title">Reset password</Translate>
          </h1>
          <div>{key ? getResetForm() : null}</div>
        </Grid>
      </Grid>
    </div>
  );
};

const mapDispatchToProps = { handlePasswordResetFinish, reset };

type DispatchProps = typeof mapDispatchToProps;

export default connect(null, mapDispatchToProps)(PasswordResetFinishPage);
