import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import {} from 'react-jhipster';
import { IRootState } from 'app/shared/reducers';
import { IServiceArea } from 'app/shared/model/service-area.model';

interface ILeaveViewProps {
  leave_tab_heading?: string | any;
  leave_tab_heading2?: string | any;
  leave_tab_data?: string | any;
  leave_tab_data2?: string | any;
  style_flag?: boolean | any;
}

export const LeaveViewComponent = (props: ILeaveViewProps) => {
  return (
    <div className="incident-details-type-div">
      <div className="incident-details-left-div">
        <div className="incident-details-type-tab">{props.leave_tab_heading}</div>
        <div className="incident-details-types-div">
          <div id="leave-details-type-text">{props.leave_tab_data}</div>
        </div>
      </div>

      <div className="incident-details-right-div">
        <div className="incident-details-type-tab2">{props.leave_tab_heading2}</div>
        <div className="incident-details-types-div">
          <div id="incident-details-serviceArea-text">{props.leave_tab_data2}</div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ organization }: IRootState) => ({});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(LeaveViewComponent);
