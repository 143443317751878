import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { faAlignLeft, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

export const PeopleQualificationComponent = props => {
  return (
    <>
      <tr className="tr-border" style={{ height: '5%', borderBottom: '1px solid #e2e1e1' }}>
        <td style={{ textAlign: 'left' }}>
          <FontAwesomeIcon icon={faSquare} color="#DAA520" style={{ marginLeft: '5%' }} />
        </td>
        <td style={{ textAlign: 'left' }}>
          <a href="#">{props.msg}</a>
        </td>
        <td style={{ textAlign: 'left' }}>{props.msg2}</td>
        <td style={{ textAlign: 'center' }}>
          <FontAwesomeIcon icon={faCheckCircle} color="#006400" />
        </td>
        <td style={{ textAlign: 'center' }}>
          <FontAwesomeIcon icon={faTimesCircle} color="#B22222" />
        </td>
      </tr>
    </>
  );
};

export default PeopleQualificationComponent;
