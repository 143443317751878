import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Storage } from 'react-jhipster';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { INotificationSettings } from 'app/shared/model/notification-setting.model';
import { ToastContainer, toast } from 'react-toastify';

export const ACTION_TYPES = {
  UPDATE_NOTIFICATION_SETTINGS: 'settings/UPDATE_NOTIFICATION_SETTINGS',
  GET_NOTIFICATION_SETTINGS: 'settings/GET_NOTIFICATION_SETTINGS',
};

const initialState = {
  settings: [] as Array<INotificationSettings>,
  storedSettings: [] as INotificationSettings,
  loading: false,
  errorMessage: null,
  updating: false,
  updateSuccess: false,
};

export type NotificationSettingState = Readonly<typeof initialState>;
export default (state: NotificationSettingState = initialState, action): NotificationSettingState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.GET_NOTIFICATION_SETTINGS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.UPDATE_NOTIFICATION_SETTINGS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.GET_NOTIFICATION_SETTINGS):
    case FAILURE(ACTION_TYPES.UPDATE_NOTIFICATION_SETTINGS):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.GET_NOTIFICATION_SETTINGS):
      return {
        ...state,
        loading: false,
        storedSettings: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.UPDATE_NOTIFICATION_SETTINGS):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        settings: action.payload,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/notificationSettings';
export const updateNotificationSettings: ICrudPutAction<INotificationSettings> = settings => dispatch => {
  let result;
  axios
    .put(apiUrl, cleanEntity(settings))
    .then(res => {
      dispatch(getNotificationSettings());
      toast.success('Settings Saved');
    })
    .catch(err => {
      toast.error('Error');
    });

  return result;
};

export const getNotificationSettings = () => ({
  type: ACTION_TYPES.GET_NOTIFICATION_SETTINGS,
  payload: axios.get<INotificationSettings>(apiUrl),
});
