import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';

export const VettingDocumentsMedicalDetails = props => {
  const changePattern = value => {
    return value.charAt(0).toUpperCase() + value.slice(1);
  };

  const { item } = props;
  return (
    <div className="vetting-documents-medical-details-div">
      <div className="vetting-documents-about-you-details-inner-divs">
        {' '}
        Doctor&apos;s Name: {item.doctor_name ? changePattern(item.doctor_name) : item.doctor_name}
      </div>
      <div className="vetting-documents-about-you-details-inner-divs"> Last Visit: {item.last_visit} </div>
      <div className="vetting-documents-about-you-details-inner-divs"> Reason of the Visit: {item.reason_of_visit}</div>
      <div className="vetting-documents-about-you-details-inner-divs"> Clinic/Hospital Location: {item.clinic_location} </div>
      <div className="vetting-documents-about-you-details-top-table-rows-div">
        <Table style={{ borderCollapse: 'separate', borderSpacing: '0px 15px' }}>
          <TableRow>
            <TableCell scope="left" className="documents-about-you-table-cells-dark">
              Height
            </TableCell>
            <TableCell align="left" className="documents-about-you-table-cells-light">
              {item.height}
            </TableCell>
            <TableCell align="left" className="documents-about-you-table-cells-dark">
              Weight
            </TableCell>
            <TableCell align="left" className="documents-about-you-table-cells-light">
              {item.weight}
            </TableCell>
            <TableCell align="left" className="documents-about-you-table-cells-dark">
              Waist
            </TableCell>
            <TableCell align="left" className="documents-about-you-table-cells-light">
              {item.waist}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell scope="left" className="documents-about-you-table-cells-dark">
              Disability
            </TableCell>
            <TableCell align="left" className="documents-about-you-table-cells-light">
              {item.Disability}
            </TableCell>
            <TableCell align="left" className="documents-about-you-table-cells-dark">
              Good Health
            </TableCell>
            <TableCell align="left" className="documents-about-you-table-cells-light">
              {item.healthy}
            </TableCell>
            <TableCell align="left" className="documents-about-you-table-cells-dark">
              Recieving Treatment
            </TableCell>
            <TableCell align="left" className="documents-about-you-table-cells-light">
              {item.recieving_treatment}
            </TableCell>
          </TableRow>
        </Table>
      </div>
      <div className="vetting-documents-about-you-details-job-availability"> Do you smoke? : {item.smoke} </div>
      {item.smoke && (
        <div className="vetting-documents-about-you-details-top-table-rows-div">
          <Table style={{ borderCollapse: 'separate', borderSpacing: '0px 15px' }}>
            <TableRow>
              <TableCell scope="left" className="documents-about-you-table-cells-dark">
                No of cigerattes smoked by you per day
              </TableCell>
              <TableCell align="left" className="documents-about-you-table-cells-light">
                {item.no_of_ciggerates}
              </TableCell>
              <TableCell align="left" className="documents-about-you-table-cells-dark">
                Alcohal units consumed by you per week?
              </TableCell>
              <TableCell align="left" className="documents-about-you-table-cells-light">
                {item.alcohal_units}
              </TableCell>
            </TableRow>
          </Table>
        </div>
      )}
      <div className="vetting-documents-about-you-details-job-availability">
        {' '}
        No of days you were unable to work last year? : {item.no_of_leaves}{' '}
      </div>
      <div className="vetting-documents-about-you-details-top-table-rows-div">
        <Table style={{ borderCollapse: 'separate', borderSpacing: '0px 15px' }}>
          <TableRow>
            <TableCell scope="left" className="documents-about-you-table-cells-dark">
              Diabetes
            </TableCell>
            <TableCell align="left" className="documents-about-you-table-cells-light">
              {item.diabetes}
            </TableCell>
            <TableCell align="left" className="documents-about-you-table-cells-dark">
              Arthritis or Rheumatism
            </TableCell>
            <TableCell align="left" className="documents-about-you-table-cells-light">
              {item.arthritis}
            </TableCell>
            <TableCell align="left" className="documents-about-you-table-cells-dark">
              Join or Back pain
            </TableCell>
            <TableCell align="left" className="documents-about-you-table-cells-light">
              {item.joint_pain}
            </TableCell>
          </TableRow>
        </Table>
      </div>
    </div>
  );
};
