export interface IClientCoverage {
  type?: string | null;
  date?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  client?: string | null;
  location?: string | null;
  position?: string | null;
  plannedPositions?: number | null;
  actualPositions?: number | null;
  confirmed?: number | null;
  waiting?: number | null;
  coverage?: string | null;
  scheduledTime?: string | null;
  actualTime?: string | null;
  plannedCharge?: number | null;
  actualCharge?: number | null;
  plannedPay?: number | null;
  actualPay?: number | null;
  PAYLOAD?: IPayload[];
  totalPages?: number | null;
  totalItems?: number | null;
  shiftStartTime?: string | null;
  shiftEndTime?: string | null;
}

interface IPayload {
  date?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  client?: string | null;
  location?: string | null;
  position?: string | null;
  plannedPositions?: number | null;
  actualPositions?: number | null;
  confirmed?: number | null;
  waiting?: number | null;
  coverage?: string | null;
  scheduledTime?: string | null;
  actualTime?: string | null;
  plannedCharge?: number | null;
  actualCharge?: number | null;
  plannedPay?: number | null;
  actualPay?: number | null;
  shiftStartTime?: string | null;
  shiftEndTime?: string | null;
}

export const defaultValue: Readonly<IClientCoverage> = {};
