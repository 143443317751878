import { combineReducers } from 'redux';
import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';

import locale, { LocaleState } from './locale';
import authentication, { AuthenticationState } from './authentication';
import applicationProfile, { ApplicationProfileState } from './application-profile';

import administration, { AdministrationState } from 'app/modules/administration/administration.reducer';
import userManagement, { UserManagementState } from 'app/modules/administration/user-management/user-management.reducer';
import register, { RegisterState } from 'app/modules/account/register/register.reducer';
import activate, { ActivateState } from 'app/modules/account/activate/activate.reducer';
import password, { PasswordState } from 'app/modules/account/password/password.reducer';
import settings, { SettingsState } from 'app/modules/account/settings/settings.reducer';
import passwordReset, { PasswordResetState } from 'app/modules/account/password-reset/password-reset.reducer';
import calendar, { CalendarState } from 'app/modules/calender/calendar.reducer';
import timeclock, { TimeclockState } from 'app/modules/timeclock/timeclock.reducer';
import organizationSettings, { OrganizationSettingsState } from 'app/modules/Setting/generalSettings.reducer';
import client, { ClientState } from 'app/entities/client/client.reducer';
import timesheetSummary, { TimesheetSummaryState } from 'app/entities/timesheet-summary/timesheet-summary.reducer';
import patrolReport, { PatrolReportState } from 'app/entities/patrol-report/patrol-report.reducer';
import bannedPeople, { BannedPeopleState } from 'app/entities/banned-people-report/banned-people.reducer';
import clientCoverage, { ClientCoverageState } from 'app/entities/client-coverage/client-coverage.reducer';
import TodaysShift, { TodaysShiftState } from 'app/modules/home/TodaysShift.reducer';
import ActionRequired, { ActionRequiredState } from 'app/modules/home/Alert&Notifications/Alerts&Notifications.reducer';
import ActionLogs, { ActionLogsState } from 'app/modules/home/ActionRequired/ActionRequired.reducer';
import subcontractor, { SubcontractorState } from 'app/entities/sub-contractor/subcontractor.reducer';
import ActionHistory, { ActionHistoryState } from 'app/entities/action-history/action-history.reducer';
import PersonalSettings, { PersonalSettingState } from 'app/modules/Setting/Personal-Setting.reducer';
import NotificationSetting, { NotificationSettingState } from 'app/modules/Setting/notification-setting.reducer';
import PermissionsSetting, { PermissionsSettingState } from 'app/modules/Setting/RolesAndPermissionsSettings/RolesAndPermissions.reducer';
import VettingDocuments, { VettingDocumentsState } from 'app/modules/vetting/documents/documents.reducer';
import VettingApplicants, { VettingApplicantsState } from 'app/modules/vetting/applicants/applicants.reducer';
import ApplicationForm, { ApplicationFormState } from 'app/modules/employeeApplicationForm/applicationForm.reducer';
import Notifications, { NotificationsState } from 'app/modules/notifications/Notifications.reducer';
import PeopleSetting, { PeopleSettingState } from 'app/modules/Setting/PeopleSettings/peopleSettings.reducer';
import OrganizationQualification, {
  OrganizationQualificationState,
} from 'app/modules/Setting/OrganizationQualification/Organization-Qualification.reducer';
import AutomatedControlRoom, { AutomatedControlRoomState } from 'app/entities/check-call/automated-control-room.reducer';
import Incident, { IncidentState } from 'app/entities/incident/incident.reducer';
import Leave, { LeaveState } from 'app/entities/leave-management/leave.reducer';
import LeaveType, { LeaveTypeState } from 'app/entities/leave-types/leave.type.reducer';

import VettingReferenceVerification, {
  VettingReferenceVerificationState,
} from 'app/modules/applicantsVerification/ApplicantVerification.reducer';
// prettier-ignore
import employmentStatus, {
  EmploymentStatusState
} from 'app/entities/employment-status/employment-status.reducer';
// prettier-ignore
import employmentDetails, {
  EmploymentDetailsState
} from 'app/entities/employment-details/employment-details.reducer';
// prettier-ignore
import educationLevel, {
  EducationLevelState
} from 'app/entities/education-level/education-level.reducer';
// prettier-ignore
import education, {
  EducationState
} from 'app/entities/education/education.reducer';
// prettier-ignore
import educationalInstituteType, {
  EducationalInstituteTypeState
} from 'app/entities/educational-institute-type/educational-institute-type.reducer';
// prettier-ignore
import educationalInstitute, {
  EducationalInstituteState
} from 'app/entities/educational-institute/educational-institute.reducer';
// prettier-ignore
import country, {
  CountryState
} from 'app/entities/country/country.reducer';
// prettier-ignore
import countryAdminUnitType, {
  CountryAdminUnitTypeState
} from 'app/entities/country-admin-unit-type/country-admin-unit-type.reducer';
// prettier-ignore
import countryAdminUnit, {
  CountryAdminUnitState
} from 'app/entities/country-admin-unit/country-admin-unit.reducer';
// prettier-ignore
import city, {
  CityState
} from 'app/entities/city/city.reducer';
// prettier-ignore
import address, {
  AddressState
} from 'app/entities/address/address.reducer';
// prettier-ignore
import contactType, {
  ContactTypeState
} from 'app/entities/contact-type/contact-type.reducer';
// prettier-ignore
import contact, {
  ContactState
} from 'app/entities/contact/contact.reducer';
// prettier-ignore
import employeeContractType, {
  EmployeeContractTypeState
} from 'app/entities/employee-contract-type/employee-contract-type.reducer';
// prettier-ignore
import employeeContract, {
  EmployeeContractState
} from 'app/entities/employee-contract/employee-contract.reducer';
// prettier-ignore
import serviceAreaType, {
  ServiceAreaTypeState
} from 'app/entities/service-area-type/service-area-type.reducer';
// prettier-ignore
import serviceArea, {
  ServiceAreaState
} from 'app/entities/service-area/service-area.reducer';
// prettier-ignore
import person, {
  PersonState
} from 'app/entities/person/person.reducer';
// prettier-ignore
import shift, {
  ShiftState
} from 'app/entities/shift/shift.reducer';
// prettier-ignore
import identificationType, {
  IdentificationTypeState
} from 'app/entities/identification-type/identification-type.reducer';
// prettier-ignore
import identification, {
  IdentificationState
} from 'app/entities/identification/identification.reducer';
// prettier-ignore
import designationQualificationType, {
  DesignationQualificationTypeState
} from 'app/entities/designation-qualification-type/designation-qualification-type.reducer';
// prettier-ignore
import designationQualification, {
  DesignationQualificationState
} from 'app/entities/designation-qualification/designation-qualification.reducer';
// prettier-ignore
import organizationType, {
  OrganizationTypeState
} from 'app/entities/organization-type/organization-type.reducer';
// prettier-ignore
import organization, {
  OrganizationState
} from 'app/entities/organization/organization.reducer';
// prettier-ignore
import department, {
  DepartmentState
} from 'app/entities/department/department.reducer';
// prettier-ignore
import designation, {
  DesignationState
} from 'app/entities/designation/designation.reducer';
import automatedControlRoom from 'app/entities/check-call/automated-control-room';
// prettier-ignore

/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

export interface IRootState {
  readonly authentication: AuthenticationState;
  readonly locale: LocaleState;
  readonly applicationProfile: ApplicationProfileState;
  readonly administration: AdministrationState;
  readonly userManagement: UserManagementState;
  readonly register: RegisterState;
  readonly activate: ActivateState;
  readonly passwordReset: PasswordResetState;
  readonly password: PasswordState;
  readonly settings: SettingsState;
  readonly employmentStatus: EmploymentStatusState;
  readonly employmentDetails: EmploymentDetailsState;
  readonly educationLevel: EducationLevelState;
  readonly education: EducationState;
  readonly educationalInstituteType: EducationalInstituteTypeState;
  readonly educationalInstitute: EducationalInstituteState;
  readonly country: CountryState;
  readonly countryAdminUnitType: CountryAdminUnitTypeState;
  readonly countryAdminUnit: CountryAdminUnitState;
  readonly city: CityState;
  readonly address: AddressState;
  readonly contactType: ContactTypeState;
  readonly contact: ContactState;
  readonly employeeContractType: EmployeeContractTypeState;
  readonly employeeContract: EmployeeContractState;
  readonly serviceAreaType: ServiceAreaTypeState;
  readonly serviceArea: ServiceAreaState;
  readonly person: PersonState;
  readonly shift: ShiftState;
  readonly identificationType: IdentificationTypeState;
  readonly identification: IdentificationState;
  readonly designationQualificationType: DesignationQualificationTypeState;
  readonly designationQualification: DesignationQualificationState;
  readonly organizationType: OrganizationTypeState;
  readonly organization: OrganizationState;
  readonly department: DepartmentState;
  readonly designation: DesignationState;
  /* jhipster-needle-add-reducer-type - JHipster will add reducer type here */
  readonly loadingBar: any;
  readonly calendar: CalendarState;
  readonly organizationSettings: OrganizationSettingsState;
  readonly timeclock: TimeclockState
  readonly client: ClientState;
  readonly timesheetSummary: TimesheetSummaryState;
  readonly patrolReport: PatrolReportState;
  readonly bannedPeople: BannedPeopleState;
  readonly clientCoverage: ClientCoverageState;
  readonly TodaysShift: TodaysShiftState;
  readonly subcontractor: SubcontractorState;
  readonly ActionHistory: ActionHistoryState;
  readonly ActionRequired: ActionRequiredState;
  readonly ActionLogs: ActionLogsState;
  readonly PersonalSettings: PersonalSettingState;
  readonly NotificationSetting: NotificationSettingState;
  readonly PermissionsSetting: PermissionsSettingState
  readonly VettingDocuments: VettingDocumentsState
  readonly VettingApplicants: VettingApplicantsState
  readonly ApplicationForm: ApplicationFormState
  readonly PeopleSetting: PeopleSettingState;
  readonly VettingReferenceVerification:VettingReferenceVerificationState;
  readonly Notifications:NotificationsState;
  readonly OrganizationQualification:OrganizationQualificationState;
  readonly AutomatedControlRoom:AutomatedControlRoomState;
  readonly Incident:IncidentState;
  readonly Leave:LeaveState;
  readonly LeaveType:LeaveTypeState;
}

const rootReducer = combineReducers<IRootState>({
  authentication,
  locale,
  applicationProfile,
  administration,
  userManagement,
  register,
  activate,
  passwordReset,
  password,
  settings,
  employmentStatus,
  employmentDetails,
  educationLevel,
  education,
  educationalInstituteType,
  educationalInstitute,
  country,
  countryAdminUnitType,
  countryAdminUnit,
  city,
  address,
  contactType,
  contact,
  employeeContractType,
  employeeContract,
  serviceAreaType,
  serviceArea,
  person,
  shift,
  identificationType,
  identification,
  designationQualificationType,
  designationQualification,
  organizationType,
  organization,
  department,
  designation,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
  loadingBar,
  calendar,
  organizationSettings,
  timeclock,
  client,
  timesheetSummary,
  patrolReport,
  bannedPeople,
  clientCoverage,
  TodaysShift,
  subcontractor,
  ActionHistory,
  ActionRequired,
  ActionLogs,
  PersonalSettings,
  NotificationSetting,
  PermissionsSetting,
  VettingDocuments,
  VettingApplicants,
  ApplicationForm,
  PeopleSetting,
  VettingReferenceVerification,
  Notifications,
  OrganizationQualification,
  AutomatedControlRoom,
  Incident,
  Leave,
  LeaveType,
});

export default rootReducer;
