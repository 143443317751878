import React from 'react';
import { TextField, Grid, MenuItem, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Paper } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import CustomToolbarComponent from '../service-area/CustomToolbarComponent';
import './person.scss';
import { getDateFormat } from 'app/shared/util/calender-utils';
import { IPersonalSettings } from 'app/shared/model/personalSetting.model';
import { IPerson } from 'app/shared/model/person.model';
import { ICountry } from 'app/shared/model/country.model';
import { ICity } from 'app/shared/model/city.model';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';

interface PersonUpdateBirthInformationProps {
  setting?: IPersonalSettings | any;
  date_of_birth?: Date | any;
  handleDateOfBirth?: (data: any) => void;
  handleNationalitiesData?: (data: any, event: any) => void;
  personEntity?: IPerson | any;
  allCountriesList?: Array<ICountry>;
  fieldValues?: IPerson | any;
  handleBirthCountryData?: (data: any, event: any) => void;
  handleBirthCityData?: (data: any, event: any) => void;
  citiesList?: Array<ICity>;
  setFormValues?: (data: any) => void;
  driverLicense?: string | any;
  driverhandleChange?: (data: any) => void;
  classes?: any;
}

const PersonUpdateBirthInformationt: React.FC<PersonUpdateBirthInformationProps> = props => {
  return (
    <React.Fragment>
      <Paper className="personOtherPaper">
        <div className="personFieldDivsideBySide">
          <Grid item xs={12} md={6} sm={12}>
            <FormControl className="person-fields">
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <KeyboardDatePicker
                  autoOk
                  disableFuture
                  variant="inline"
                  inputVariant="outlined"
                  label="Date of Birth"
                  format={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'MM/DD/YYYY'}
                  value={props.fieldValues['date_of_birth']}
                  ToolbarComponent={prop => (
                    <>
                      <CustomToolbarComponent {...prop} settingFormat={props.setting.date_format} />
                    </>
                  )}
                  placeholder={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'MM/DD/YYYY'}
                  InputAdornmentProps={{ position: 'start' }}
                  onChange={date => props.handleDateOfBirth(date)}
                />{' '}
              </MuiPickersUtilsProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} sm={12}>
            <FormControl className="person-fields">
              <Autocomplete
                options={
                  props.allCountriesList &&
                  props.allCountriesList.map(country => ({
                    id: country.id,
                    name: country.name,
                  }))
                }
                noOptionsText={'No Records'}
                classes={{ listbox: props.classes.listbox }}
                ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                getOptionLabel={option => option.name || ''}
                className="PersonAutocomplete"
                onChange={props.handleNationalitiesData}
                value={props.fieldValues['nationality'] ? props.fieldValues['nationality'] : null}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    className="personAutocompleteTextfield autocompleteLabel"
                    label="Nationality"
                    margin="normal"
                    fullWidth
                  />
                )}
              />
            </FormControl>
          </Grid>
        </div>
        <div className="personFieldDivsideBySide">
          <Grid item xs={12} md={6} sm={12}>
            <FormControl className="person-fields">
              <Autocomplete
                options={
                  props.allCountriesList &&
                  props.allCountriesList.map(country => ({
                    id: country.id,
                    name: country.name,
                  }))
                }
                noOptionsText={'No Records'}
                classes={{ listbox: props.classes.listbox }}
                ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                getOptionLabel={option => option.name || ''}
                className="PersonAutocomplete"
                onChange={props.handleBirthCountryData}
                value={props.fieldValues['birthCountry'] ? props.fieldValues['birthCountry'] : null}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    className="personAutocompleteTextfield autocompleteLabel"
                    label="Country of Birth"
                    margin="normal"
                    fullWidth
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} sm={12}>
            <FormControl className="person-fields">
              <Autocomplete
                options={
                  props.citiesList &&
                  props.citiesList.map(city => ({
                    id: city.id,
                    name: city.name,
                  }))
                }
                noOptionsText={'Select Country First'}
                classes={{ listbox: props.classes.listbox }}
                ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                getOptionLabel={option => option.name || ''}
                className="PersonAutocomplete"
                onChange={props.handleBirthCityData}
                value={props.fieldValues['birthCity'] ? props.fieldValues['birthCity'] : null}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    className="personAutocompleteTextfield autocompleteLabel"
                    label="City of Birth"
                    margin="normal"
                    fullWidth
                  />
                )}
              />
            </FormControl>
          </Grid>
        </div>
        <div className="personFieldDivsideBySide">
          <Grid item xs={12} md={6} sm={12}>
            <FormControl className="person-fields">
              <TextField
                type="text"
                variant="outlined"
                onChange={props.setFormValues}
                placeholder="Enter your ni no."
                label="NI No."
                name="national_insurance_number"
                id="national_insurance_number"
                value={props.fieldValues['national_insurance_number'] ? props.fieldValues['national_insurance_number'] : ''}
                inputProps={{ maxLength: 16 }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} sm={12}>
            <FormControl component="fieldset" className="person-fields">
              <FormLabel component="legend" style={{ color: 'black' }}>
                Do you have driver license?
              </FormLabel>
              <RadioGroup
                aria-label="driverLicense"
                name="driverLicense"
                className="personRadioButtons"
                value={props.fieldValues['driverLicense'] ? props.fieldValues['driverLicense'] : null}
                onChange={props.driverhandleChange}
              >
                <FormControlLabel value="yes" control={<Radio style={{ color: '#4620B7' }} />} label="Yes" />
                <FormControlLabel value="no" control={<Radio style={{ color: '#4620B7' }} />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
        </div>
      </Paper>
    </React.Fragment>
  );
};
export default PersonUpdateBirthInformationt;
