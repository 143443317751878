import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { MenuItem, Table, Grid } from '@material-ui/core';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './incident.scss';
import IconButton from '@material-ui/core/IconButton';
import { IRootState } from 'app/shared/reducers';
import SearchBar from 'material-ui-search-bar';
import Paper from '@material-ui/core/Paper';
import { IServiceArea } from 'app/shared/model/service-area.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import '../../../utils/GlobalCssRequiredInWebsite.scss';
import InfiniteScroll from 'react-infinite-scroll-component';
import TableContainer from '@material-ui/core/TableContainer';
import { getIncidents, reset } from './incident.reducer';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import CustomToolbarComponent from '../service-area/CustomToolbarComponent';
import MomentUtils from '@date-io/moment';
import {
  getSelectedTimePeriodFilter,
  handleFilterCurrentWeekStartDate,
  handleFilterCurrentWeekEndDate,
  handleFilterLastWeekStartDate,
  handleFilterLastWeekEndDate,
  dateFTConverter,
} from '../../../utils/TimeFormatAndTimezone';
import moment from 'moment';
import FormControl from '@material-ui/core/FormControl';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { getEntities as getPersons, reset as resetPerson, getPersonForFilters } from 'app/entities/person/person.reducer';
import { getEntities as getLocations, reset as resetLoc } from 'app/entities/service-area/service-area.reducer';
import { convertToPDF } from './convertToPDF';
import PrintIcon from '@material-ui/icons/Print';
import GetAppIcon from '@material-ui/icons/GetApp';
import Spinner from 'app/commonUI/spinner/spinner';

export interface IIncidentProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const Incident = (props: IIncidentProps) => {
  const [records, setRecords] = useState(props.incidents);
  const [searched, setSearched] = useState<string>('');
  const [selectedTimePeriod, setTimePeriod] = useState({ id: 1, text: 'Until now' });
  const [selectedDateTo, setSelectedDateTo] = React.useState<any | null>(
    getSelectedTimePeriodFilter(selectedTimePeriod.text, 'setSelectedDateTo')
  );
  const [selectedDateFrom, setSelectedDateFrom] = React.useState<any | null>(
    getSelectedTimePeriodFilter(selectedTimePeriod.text, 'setSelectedDateFrom')
  );
  const [values, setValues] = useState({
    people: '',
    location: '',
    from_date: '',
    to_date: '',
  });
  const [selectedPerson, setSelectedPerson] = React.useState<any | null>(null);
  const [selectedLocation, setSelectedLocation] = React.useState<any | null>(null);
  const { serviceAreaList, match, loading, permissions } = props;
  const [reportArray, setReportArray] = useState(props.incidents);
  const csvLinks = [''];
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  useEffect(() => {
    props.reset();
    props.resetPerson();
    props.resetLoc();
    props.getPersonForFilters();
    props.getLocations(-1, '');
  }, []);

  useEffect(() => {
    setRecords(props.incidents);
  }, [props.incidents]);

  useEffect(() => {
    props.getIncidents(values);
  }, [values]);

  useEffect(() => {
    setReportArray(props.incidents);
  }, [props.incidents]);

  const requestSearch = (searchedVal: string) => {
    const value = searchedVal.trim().toLowerCase();
    const searchTerms = value.split(' ');
    const filteredRows = props.incidents.filter(row => {
      return searchTerms.some(
        term =>
          (row.incident_time && row.incident_time !== null && row.incident_time.toLowerCase().includes(term)) ||
          (row.incident_type.name && row.incident_type.name !== null && row.incident_type.name.toLowerCase().includes(term)) ||
          (row.service_area.name && row.service_area.name !== null && row.service_area.name.toLowerCase().includes(term)) ||
          (row.person && row.person.first_name !== null && row.person.first_name.toLowerCase().includes(term)) ||
          (row.person && row.person.last_name !== null && row.person.last_name.trim().toLowerCase().includes(term))
      );
    });
    setRecords(filteredRows);
  };

  const cancelSearch = () => {
    setSearched('');
    requestSearch(searched);
  };

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleDateFrom = e => {
    props.reset();
    const d = moment(e).format('YYYY-MM-DD');
    setSelectedDateFrom(e);
    if (d !== 'Invalid date') {
      setValues(oldValues => ({ ...oldValues, ['from_date']: d }));
    } else {
      setValues(oldValues => ({ ...oldValues, ['from_date']: '', ['to_date']: '' }));
      setSelectedDateTo('');
      setSelectedDateFrom('');
    }
  };

  const handleDateTo = e => {
    props.reset();
    const d = moment(e).format('YYYY-MM-DD');
    setSelectedDateTo(e);
    if (d !== 'Invalid date') {
      setValues(oldValues => ({ ...oldValues, ['to_date']: d }));
    } else {
      setValues(oldValues => ({ ...oldValues, ['from_date']: '', ['to_date']: '' }));
      setSelectedDateTo('');
      setSelectedDateFrom('');
    }
  };

  const handlePersonFilterValues = (_, e) => {
    if (e) {
      setSelectedPerson({ id: e.id, text: e.text });
      return setFilterValues(e.name, e.id);
    } else {
      setValues(oldValues => ({ ...oldValues, people: '' }));
      setSelectedPerson({ id: '', text: '' });
    }
  };

  const handleLocationFilterValues = (_, e) => {
    if (e) {
      setSelectedLocation({ id: e.id, text: e.text });
      return setFilterValues(e.name, e.id);
    } else {
      setValues(oldValues => ({ ...oldValues, location: '' }));
      setSelectedLocation({ id: '', text: '' });
    }
  };

  const setFilterValues = (name, value) => {
    setValues(oldValues => ({ ...oldValues, [name]: value }));
  };

  const handleRefreshButton = () => {
    props.reset();
    setValues(oldValues => ({ ...oldValues, location: '' }));
    setValues(oldValues => ({ ...oldValues, people: '' }));
    setValues(oldValues => ({ ...oldValues, from_date: moment(handleFilterCurrentWeekStartDate()).format('YYYY-MM-DD') }));
    setValues(oldValues => ({ ...oldValues, to_date: moment(handleFilterCurrentWeekEndDate()).format('YYYY-MM-DD') }));
    props.getIncidents(values);
  };

  const jsonData = [];
  if (reportArray && reportArray.length > 0) {
    reportArray.forEach(element => {
      const data = { ...element };
      jsonData.push(data);
    });
  }

  return (
    <div className="entities-reports-main-container">
      {props.loading && <Spinner />}
      <div className="entities-reports-filter-area">
        <Grid container spacing={2} style={{ flexWrap: 'nowrap', width: '65%' }}>
          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
            <FormControl variant="outlined" size="small">
              <SearchBar
                value={searched}
                onChange={searchVal => requestSearch(searchVal)}
                onCancelSearch={() => cancelSearch()}
                placeholder="Search Here..."
                style={{
                  outline: '1px solid #bbc4c2',
                  borderRadius: '4px',
                  height: '40px',
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <KeyboardDatePicker
                clearable={true}
                id="date"
                placeholder="From"
                inputVariant="outlined"
                className="entities_report_filter_fields datePicker"
                name="from_date"
                size="small"
                ToolbarComponent={prop => (
                  <>
                    <CustomToolbarComponent {...prop} settingFormat={props.setting.date_format} />
                  </>
                )}
                labelFunc={selectedDateFrom ? dateFTConverter : () => 'From'}
                value={selectedDateFrom ? moment(selectedDateFrom) : null}
                onChange={handleDateFrom}
                InputLabelProps={{ shrink: true }}
                disabled={loading}
              />{' '}
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <KeyboardDatePicker
                clearable={true}
                id="date"
                inputVariant="outlined"
                className="entities_report_filter_fields datePicker"
                size="small"
                ToolbarComponent={prop => (
                  <>
                    <CustomToolbarComponent {...prop} settingFormat={props.setting.date_format} />
                  </>
                )}
                labelFunc={selectedDateFrom ? dateFTConverter : () => 'Till'}
                value={selectedDateTo ? moment(selectedDateTo).format('YYYY-MM-DD') : null}
                name="to_date"
                onChange={handleDateTo}
                InputLabelProps={{ shrink: true }}
                disabled={loading}
              />{' '}
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
            <FormControl variant="outlined" fullWidth className="entities_report_filter_fields staffFilterTimeSheet">
              <Autocomplete
                options={
                  props.personsList &&
                  props.personsList.map(person => ({
                    id: person.id,
                    text: person.first_name + ' ' + person.last_name,
                    name: 'people',
                  }))
                }
                noOptionsText={'No Records'}
                classes={{ listbox: 'autocompletelistbox' }}
                ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                getOptionLabel={option => option.text || ''}
                onChange={handlePersonFilterValues}
                value={selectedPerson}
                disabled={loading}
                renderInput={params => <TextField {...params} variant="outlined" size="small" placeholder="Staff" />}
              />
            </FormControl>
          </Grid>

          <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
            <FormControl variant="outlined" fullWidth className="entities_report_filter_fields staffFilterTimeSheet">
              <Autocomplete
                options={
                  props.serviceAreaList &&
                  props.serviceAreaList.map(location => ({
                    id: location.id,
                    text: location.name,
                    name: 'location',
                  }))
                }
                noOptionsText={'No Records'}
                classes={{ listbox: 'autocompletelistbox' }}
                ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                getOptionLabel={option => option.text || ''}
                onChange={handleLocationFilterValues}
                value={selectedLocation}
                disabled={loading}
                renderInput={params => <TextField {...params} variant="outlined" size="small" placeholder="Service Areas" fullWidth />}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container className="entities_reports_refresh_and_download_div" style={{ width: '35%' }}>
          <IconButton
              size="small"
              className="report-download-button"
              id="entities_reports_btn"
              onClick={() =>
                  convertToPDF(jsonData, moment(selectedDateFrom).format('YYYY-MM-DD'), moment(selectedDateTo).format('YYYY-MM-DD'))
              }
                disabled={props.loading}
          >
              <GetAppIcon fontSize="large" />
          </IconButton>
          <IconButton
              size="small"
              className="report-download-button"
              id="entities_reports_btn"
              disabled={props.loading}
              onClick={() => window.print()}
          >
              <PrintIcon fontSize="large" />
          </IconButton>

          {/* <Grid item xl={4} lg={4} md={4} sm={4} xs={4}> */}
          <Link
            to={`${match.url}/new`}
            className="btn jh-create-entity serviceAreaCreateButton incidentCreateButton"
            id="jh-create-entity"
            data-cy="entityCreateButton"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp; Create New
          </Link>
          {/* </Grid> */}
        </Grid>
      </div>
      <div className="globalTableHeaderGradientColor sticky_div">
        <div className="serviceAreaTableHeaderCell serviceAreaTBHC col_size">Incident Date/Time</div>
        <div className="serviceAreaTableHeaderCell serviceAreaTBHC col_size">Incident Type</div>
        <div className="serviceAreaTableHeaderCell serviceAreaTBHC col_size">Name</div>
        <div className="serviceAreaTableHeaderCell serviceAreaTBHC col_size">Service Area</div>
        <div className="serviceAreaTableHeaderCell serviceAreaTBHC col_size">Actions</div>
      </div>

      <div>
        {
          <TableContainer component={Paper}>
            <Table>
              <tbody>
                {records && records.length > 0
                  ? records.map((record, i) => (
                      <tr key={`entity-${i}`} data-cy="entityTable" className="serviceAreaTableBodyRows">
                        <td className="serviceAreaTableBodyCell serviceAreaTBC col_size">
                          {
                            <>
                              <span>
                                {record ? (record.incident_time ? moment(record.incident_time).format('YYYY-MM-DD') : null) : null}
                              </span>

                              <br></br>

                              <span>
                                {record
                                  ? record.incident_time
                                    ? moment(moment(record.incident_time).format('HH:mm')).toISOString()
                                    : null
                                  : null}
                              </span>
                            </>
                          }
                        </td>
                        <td className="serviceAreaTableBodyCell serviceAreaTBC col_size">
                          {record ? (record.incident_type.name ? record.incident_type.name : null) : null}
                        </td>
                        <td className="serviceAreaTableBodyCell serviceAreaTBC col_size">
                          {record ? (record.person ? record.person.first_name + ' ' + record.person.last_name : null) : null}
                        </td>
                        <td className="serviceAreaTableBodyCell serviceAreaTBC col_size">
                          {record.service_area ? (record.service_area.name ? record.service_area.name : null) : null}
                        </td>
                        <td className="serviceAreaTableBodyCell serviceAreaTBC col_size">
                          <div className="btn-group serviceAreaActionCellButtons">
                            <IconButton
                              component={Link}
                              className="personButton"
                              to={`${match.url}/${record.id}`}
                              style={{ margin: '1%' }}
                              size="small"
                              data-cy="entityDetailsButton"
                            >
                              <img src="content/images/View1.png" />
                            </IconButton>
                            &nbsp;
                            <IconButton
                              component={Link}
                              className="personButton"
                              to={`${match.url}/${record.id}/edit`}
                              style={{ margin: '1%' }}
                              size="small"
                              data-cy="entityEditButton"
                            >
                              <img src="content/images/Edit1.png" />
                            </IconButton>
                          </div>
                        </td>
                      </tr>
                    ))
                  : !loading && <div className="no-record-warning">No Incident Found</div>}
              </tbody>
            </Table>
          </TableContainer>
        }
      </div>
    </div>
  );
};

const mapStateToProps = ({ serviceArea, authentication, client, Incident, PersonalSettings, person }: IRootState) => ({
  serviceAreaList: serviceArea.entities,
  loading: serviceArea.loading,
  permissions: authentication.account.permission,
  pageId: serviceArea.pageNumber,
  incidents: Incident.incident_list,
  setting: PersonalSettings.storedPersonalSettings,
  personsList: person.filterPersons,
});

const mapDispatchToProps = {
  getIncidents,
  reset,
  getPersons,
  getLocations,
  resetLoc,
  resetPerson,
  getPersonForFilters,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Incident);
