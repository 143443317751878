import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { IRootState } from 'app/shared/reducers';
import { getEntity, setPageNumber, reset } from './department.reducer';
import { Button, Grid, FormControl, TextField, MenuItem } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';

export interface IDepartmentDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const DepartmentDetail = (props: IDepartmentDetailProps) => {
  const [values, setValues] = useState(props.departmentEntity);

  useEffect(() => {
    setValues(props.departmentEntity);
  }, [props.departmentEntity]);

  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { departmentEntity } = props;
  return (
    <React.Fragment>
      <div className="department-create-main-container">
        <div className="department-full-container">
          <Typography variant="h5" className="departmentTitle">
            Department Details
          </Typography>
          <div style={{ display: 'flex' }}>
            <Paper className="departmentContainerPaper">
              <div className="departmentInnerDiv">
                <Grid item md={12} container justify="flex-start" spacing={1}>
                  <form className="department-form" noValidate>
                    {' '}
                    <div className="departmentFieldDivsideBySide">
                      <Grid item md={12} container>
                        <Grid item md={12} sm={12} xs={12}>
                          <FormControl className="department-fields">
                            <TextField
                              id="department-id"
                              variant="outlined"
                              name="id"
                              required
                              inputProps={{ readOnly: true }}
                              label="ID"
                              value={departmentEntity.id}
                              disabled={true}
                              InputLabelProps={{ shrink: true }}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="sideBySide">
                      <Grid item md={12} container>
                        <Grid item md={12} sm={12} xs={12}>
                          <FormControl className="department-fields">
                            <TextField
                              required
                              id="department-name"
                              data-cy="name"
                              variant="outlined"
                              name="name"
                              label={<Translate contentKey="wfmApp.department.name">Name</Translate>}
                              placeholder="Write department name"
                              defaultValue={departmentEntity.name}
                              value={values['name'] ? values['name'] : ''}
                              inputProps={{ maxLength: 255 }}
                              disabled={true}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="departmentDiscardSaveButtons">
                      <Grid item md={12} container justify="center">
                        <Grid item md={12} sm={6} xs={6}>
                          <FormControl className="departmentButtons departmentBackButton">
                            <Button
                              component={Link}
                              to="/departments"
                              onClick={() => props.reset()}
                              id="cancel-save"
                              className="departmentBackButton"
                            >
                              &nbsp;
                              <span className="d-none d-md-inline">Back</span>
                            </Button>
                          </FormControl>
                        </Grid>
                      </Grid>{' '}
                      &nbsp;&nbsp;
                      <Grid item md={12} container justify="center">
                        <Grid item md={12} sm={6} xs={6}>
                          <FormControl className="departmentButtons departmentSaveButton">
                            <Button
                              className="departmentSaveButton"
                              id="save-entity"
                              data-cy="entityCreateSaveButton"
                              type="submit"
                              component={Link}
                              to={`/departments/${props.match.params.id}/edit`}
                            >
                              &nbsp; Edit
                            </Button>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </div>
                  </form>
                </Grid>
              </div>
            </Paper>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ department }: IRootState) => ({
  departmentEntity: department.entity,
});

const mapDispatchToProps = { getEntity, setPageNumber, reset };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentDetail);
