import { exportPDF } from '../timesheet-summary/exportPDF';

const header = ['Employee_Name', 'Company_Subcontractor', 'Position', 'Contact_Details', 'SIA_License_No.', 'License_Expiry'];

export const convertToPDF = (jsonData, selectedDateFrom, selectedDateTo) => {
  const headers = [header];
  const title = 'Staff List';
  const data = jsonData.map(row => [
    row.first_name + ' ' + row.last_name,
    row ?.organization?.name ||
    (Array.isArray(row?.subcontractors)
        ? row.subcontractors.map(
            entity => entity.name
        ).join(', ')
        : ''
    ),
    row.designation?.name,
    (row.phoneNumber && row.phoneNumber[0] ? row.phoneNumber[0].value : null) + '\n' + (row.email && row.email[0] ? row.email[0].value : null),
    row.siaLicenceNumber,
    row.siaLicenceNumber?.expiry_date
  ]);
  const filename = 'Staff Report.pdf';

  exportPDF(selectedDateFrom, selectedDateTo, headers, title, data, filename);
};
