/* eslint-disable complexity */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Grid, FormControl, TextField, Divider, InputAdornment } from '@material-ui/core';
import { IRootState } from 'app/shared/reducers';
import { Storage } from 'react-jhipster';
import './client.scss';
import { getEntity, updateEntity, createNewClient, reset, setPageNumber } from './client.reducer';
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { getState, getCityByState, reset as resetorg } from '../organization/organization.reducer';
import { getCity, getCountry } from '../sub-contractor/subcontractor.reducer';
import { validationMessageOnChange, validationMessageBeforeSubmit } from '../../../utils/validationMessages';
import { getOrganizationSettings } from '../../modules/Setting/generalSettings.reducer';
import country_types from '../../shared/data/country_types.json';
import { Autocomplete } from '@material-ui/lab';
import { getEntities as getServiceArea } from 'app/entities/service-area/service-area.reducer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { getDateFormat } from 'app/shared/util/calender-utils';
import { getPersonalSettings } from '../../modules/Setting/Personal-Setting.reducer';
import moment from 'moment';
import CustomToolbarComponent from '../service-area/CustomToolbarComponent';
import Client_Edit_Del_Btn from './components/Client_Edit_Del_Btn';
import Spinner from 'app/commonUI/spinner/spinner';

export interface IClientUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const ClientUpdate = props => {
  const [isNew] = useState(!props.match.params || !props.match.params.id);
  const { clientEntity, loading, updating, match } = props;
  const [values, setValues] = useState(clientEntity);
  const [imageFile, setImageFile] = useState(null);
  const [dateFormat, setdateFormat] = useState(props.setting.date_format);
  const [first_login] = useState(Storage.local.get('first_login'));
  const [fieldError, setFieldError] = useState({
    clientName_error: false,
    clientName_helper: '',
    addressLineOne_error: false,
    addressLineOne_helper: '',
    city_error: false,
    postCode_error: false,
    postCode_helper: '',
    country_error: false,
    personFirstName_error: false,
    personFirstName_helper: '',
    personLastName_error: false,
    personLastName_helper: '',
    contractStart_error: false,
    contractStart_helper: '',
    contractEnd_error: false,
    contractEnd_helper: '',
    personEmail_error: false,
    personEmail_helper: '',
    identification_number_error: false,
    identification_number_helper: '',
    state_error: false,
    jobTitle_error: false,
    jobTitle_helper: '',
  });
  const [isEdit, setIsEdit] = React.useState(false);

  useEffect(() => {
    setdateFormat(props.setting.date_format);
  }, [props.setting]);

  useEffect(() => {
    props.reset();
    props.resetorg();
    props.getCountry();
    props.getPersonalSettings();
  }, []);

  useEffect(() => {
    if (clientEntity && clientEntity !== null) {
      setValues(clientEntity);
      if (clientEntity.logo && clientEntity.logo != null) {
        setImageFile(clientEntity.logo);
      }
    }
  }, [clientEntity]);

  useEffect(() => {
    props.getServiceArea(-1);
  }, []);

  const handleClose = () => {
    props.history.push('/clients');
  };

  useEffect(() => {
    props.reset();
    if (!isNew) {
      props.getEntity(props.match.params.id);
    }
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  useEffect(() => {
    if (clientEntity && clientEntity?.country && clientEntity.country && clientEntity.country.id) {
      const countryData = { id: clientEntity.country.id, text: clientEntity.country.name };
      props.getState(countryData.id);
    }
    if (clientEntity && clientEntity?.state && clientEntity.state && clientEntity.state.id) {
      const stateData = { id: clientEntity.state.id, text: clientEntity.state.name };
      props.getCityByState(stateData.id);
    }
    setValues(clientEntity);
  }, [clientEntity]);

  const handleStateData = (_, e) => {
    if (e) {
      setValues(oldValues => ({ ...oldValues, ['state']: e }));
      props.getCityByState(e.id);
      if (values['state'] !== null || values['state'] !== undefined) {
        setFieldError(oldFieldError => ({ ...oldFieldError, ['state_error']: false }));
      }
    } else {
      setValues(oldValues => ({ ...oldValues, ['state']: null }));
    }
  };

  const handleCountryData = (_, e) => {
    if (e) {
      setValues(oldValues => ({ ...oldValues, ['country']: e }));
      if (values['country'] !== null || values['country'] !== undefined) {
        setFieldError(oldValues => ({ ...oldValues, ['country_error']: false }));
      }
      props.getState(e.id);
    } else {
      setValues(oldValues => ({ ...oldValues, ['country']: null }));
    }
  };

  const handleCityData = (_, e) => {
    if (e) {
      setValues(oldValues => ({ ...oldValues, ['city']: e }));
      if (values['city'] !== null || values['city'] !== undefined) {
        setFieldError(oldValues => ({ ...oldValues, ['city_error']: false }));
      }
    } else {
      setValues(oldValues => ({ ...oldValues, ['city']: null }));
    }
  };

  const handlePhoneNumber = phone => {
    setValues(oldValues => ({ ...oldValues, ['phone']: phone }));
  };

  const setNewValues = e => {
    setValues(oldValues => ({ ...oldValues, [e.target.name]: e.target.value }));
    if (e.target.name === 'clientName' && (!/^(?! )[a-zA-Z0-9\s(),.-]*$/.test(e.target.value) || e.target.value === '')) {
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: true }));
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_helper']: validationMessageOnChange.name }));
    } else if (e.target.name === 'personFirstName' && (!/^(?! )[a-zA-Z\s().-]*$/.test(e.target.value) || e.target.value === '')) {
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: true }));
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_helper']: validationMessageOnChange.firstName }));
    } else if (e.target.name === 'personLastName' && (!/^(?! )[a-zA-Z\s().-]*$/.test(e.target.value) || e.target.value === '')) {
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: true }));
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_helper']: validationMessageOnChange.lastName }));
    } else if (e.target.name === 'jobTitle' && (!/^[^-\s][a-zA-Z\s]*$/.test(e.target.value) || e.target.value === '')) {
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: true }));
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_helper']: validationMessageOnChange.position }));
    } else if (e.target.name === 'addressLineOne' && e.target.value === '') {
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: true }));
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_helper']: validationMessageOnChange.address }));
    } else if (e.target.name === 'postCode' && !/^[^-\s][a-zA-Z0-9\s]*$/.test(e.target.value)) {
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: true }));
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_helper']: validationMessageOnChange.postcode }));
    } else if (
      e.target.name === 'personEmail' &&
      !/^(([^<>()[\],;:\s@]+(\.[^<>()[\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+\.)+[^<>()[\],;:\s@]{2,})$/i.test(e.target.value)
    ) {
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: true }));
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_helper']: validationMessageOnChange.email }));
    } else if (e.target.name === 'identification_number' && !/^(?! )[a-zA-Z0-9\s(),.-]*$/.test(e.target.value)) {
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: true }));
      setFieldError(oldFieldError => ({
        ...oldFieldError,
        [e.target.name + '_helper']: validationMessageOnChange.number,
      }));
    } else {
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: false, [e.target.name + '_helper']: '' }));
    }
  };

  const checkValidationsBeforeSubmit = () => {
    if (values['clientName'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['clientName_error']: true }));
      setFieldError(oldValues => ({ ...oldValues, ['clientName_helper']: validationMessageBeforeSubmit.name }));
    }
    if (values['addressLineOne'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['addressLineOne_error']: true }));
      setFieldError(oldValues => ({ ...oldValues, ['addressLineOne_helper']: validationMessageBeforeSubmit.address }));
    }
    if (values['postCode'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['postCode_error']: true }));
      setFieldError(oldValues => ({ ...oldValues, ['postCode_helper']: validationMessageBeforeSubmit.postcode }));
    }
    if (values['country'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['country_error']: true }));
    }
    if (values['personFirstName'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['personFirstName_error']: true }));
      setFieldError(oldValues => ({ ...oldValues, ['personFirstName_helper']: validationMessageBeforeSubmit.firstName }));
    }
    if (values['personLastName'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['personLastName_error']: true }));
      setFieldError(oldValues => ({ ...oldValues, ['personLastName_helper']: validationMessageBeforeSubmit.lastName }));
    }
    if (values['personEmail'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['personEmail_error']: true }));
      setFieldError(oldValues => ({ ...oldValues, ['personEmail_helper']: validationMessageBeforeSubmit.email }));
    }
  };

  const checkForValidations = () => {
    const result =
      !fieldError['clientName_error'] &&
      !fieldError['addressLineOne_error'] &&
      !fieldError['city_error'] &&
      !fieldError['postCode_error'] &&
      !fieldError['country_error'] &&
      !fieldError['personFirstName_error'] &&
      !fieldError['personLastName_error'] &&
      !fieldError['contractStart_error'] &&
      !fieldError['contractEnd_error'] &&
      !fieldError['personEmail_error'] &&
      !fieldError['identification_number_error'] &&
      !(values['clientName'] === undefined) &&
      !(values['addressLineOne'] === undefined) &&
      !(values['postCode'] === undefined) &&
      !(values['country'] === undefined) &&
      !(values['personFirstName'] === undefined) &&
      !(values['personLastName'] === undefined) &&
      !(values['personEmail'] === undefined);
    return result;
  };

  const handleContractStartDate = e => {
    if (e && e !== undefined && e !== null) {
      if (
        values['contract_end_date'] &&
        values['contract_end_date'] !== undefined &&
        values['contract_end_date'] !== null &&
        values['contract_end_date'].valueOf() < moment(e).format('YYYY-MM-DD').valueOf()
      ) {
        setFieldError(oldFieldError => ({
          ...oldFieldError,
          ['contractStart_error']: true,
          ['contractStart_helper']: 'Start Date should be less than End Date',
        }));
      } else {
        setFieldError(oldFieldError => ({
          ...oldFieldError,
          ['contractStart_error']: false,
          ['contractStart_helper']: '',
          ['contractEnd_error']: false,
          ['contractEnd_helper']: '',
        }));
      }

      const d = moment(e).format('YYYY-MM-DD');
      setValues(oldFieldValues => ({ ...oldFieldValues, ['contract_start_date']: d }));
    }
  };

  const handleContractEndDate = e => {
    if (e && e !== undefined && e !== null) {
      if (
        values['contract_start_date'] &&
        values['contract_start_date'] !== undefined &&
        values['contract_start_date'] !== null &&
        values['contract_start_date'].valueOf() > moment(e).format('YYYY-MM-DD').valueOf()
      ) {
        setFieldError(oldFieldError => ({
          ...oldFieldError,
          ['contractEnd_error']: true,
          ['contractEnd_helper']: 'End Date should be greater than Start Date',
        }));
      } else {
        setFieldError(oldFieldError => ({
          ...oldFieldError,
          ['contractEnd_error']: false,
          ['contractEnd_helper']: '',
          ['contractStart_error']: false,
          ['contractStart_helper']: '',
        }));
      }
      const d = moment(e).format('YYYY-MM-DD');
      setValues(oldFieldValues => ({ ...oldFieldValues, ['contract_end_date']: d }));
    }
  };

  const editEntity = event => {
    event.preventDefault();
    const formData = new FormData();
    if (values['client-logo'] !== undefined) {
      formData.append('avatar', values['client-logo']);
    }
    if (isNew && checkForValidations()) {
      props.createNewClient(values, formData);
      if (
        first_login === 'true' &&
        props.isOrganizationAdministrator &&
        (props.serviceArea === undefined || props.serviceArea.length === 0)
      ) {
        props.history.push('/service-areas/new');
      }
    } else if (!isNew && checkForValidations()) {
      props.updateEntity(values, formData);
    } else {
      checkValidationsBeforeSubmit();
    }
  };

  const onKeyUp = event => {
    if (event.key === 'Enter') {
      editEntity(event);
    }
  };

  const handleProfilePicture = e => {
    if (e.target.files && e.target.files[0]) {
      setValues(oldFieldValues => ({ ...oldFieldValues, ['client-logo']: e.target.files[0] }));
      setImageFile(URL.createObjectURL(e.target.files[0]));
    }
  };

  const cancleEditHandler = () => {
    setValues(clientEntity);
    setImageFile(clientEntity.imageUrl);
    setIsEdit(!isEdit);
  };

  return (
    <React.Fragment>
      <div className="client-new-main-container">
        <div className="client-full-container" style={{ padding: isNew ? '5%' : '0 5%' }}>
          {!isNew && (
            <>
              {!isEdit && (
                <div className="client_edit_del_Btn_div">
                  <Client_Edit_Del_Btn isEdit={isEdit} setIsEdit={setIsEdit} props={props} />
                </div>
              )}
            </>
          )}
          <div className="ClientOuterDiv">
            {loading ? (
              <Spinner/>
            ) : (
              <>
                <div className="client-form-main-div">
                  <div className="client-logo-div">
                    <div className="client-logo">
                      <input type="file" id="file-input" name="client-logo" onChange={handleProfilePicture} style={{ display: 'none' }} />
                      <label htmlFor="file-input" className="client-add-logo-button-label">
                        {isNew || isEdit ? <img src="content/icons/Edit_Pic_Icon.png" className="client-pic-edit-icon" /> : ''}
                      </label>
                      <img src={imageFile ? imageFile : null} className="client-upload-logo-size" />
                    </div>
                  </div>

                  <form
                    onKeyPress={e => {
                      onKeyUp(e);
                    }}
                    noValidate
                    className="client-form"
                  >
                    <div className="clientInnerFirstPaper">
                      <div className="clientFieldDivsideBySide">
                        <Grid item xs={12} md={6} sm={12} justify="flex-start">
                          <FormControl style={{ width: '97%' }}>
                            <TextField
                              required
                              id="clientName"
                              name="clientName"
                              className="clientCompanyName"
                              value={values['clientName'] ? values['clientName'] : ''}
                              onChange={setNewValues}
                              label="Company Name"
                              placeholder="Enter client company name"
                              fullWidth
                              autoComplete="given-name"
                              error={fieldError['clientName_error']}
                              helperText={fieldError['clientName_helper']}
                              inputProps={{ maxLength: 255 }}
                              variant="outlined"
                              disabled={isNew || isEdit ? false : true}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <img src="content/icons/Company Name.png" />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} sm={12} justify="flex-start">
                          <FormControl style={{ width: '97%' }}>
                            <TextField
                              className="clientCompanyName"
                              variant="outlined"
                              label="Id / Contract Number"
                              name="identification_number"
                              onChange={setNewValues}
                              value={values['identification_number'] ? values['identification_number'] : ''}
                              error={fieldError['identification_number_error']}
                              helperText={fieldError['identification_number_helper']}
                              inputProps={{ maxLength: 15 }}
                              disabled={isNew || isEdit ? false : true}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <img src="content/icons/Contact No..png" />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </FormControl>
                        </Grid>
                      </div>
                      <Grid item xs={12} sm={12} md={12}>
                        <FormControl className="clientAddressLineOne">
                          <TextField
                            required
                            id="address1"
                            name="addressLineOne"
                            value={values['addressLineOne'] ? values['addressLineOne'] : ''}
                            onChange={setNewValues}
                            label="Street Address"
                            placeholder="Enter street address"
                            fullWidth
                            autoComplete="shipping address-line1"
                            error={fieldError['addressLineOne_error']}
                            helperText={fieldError['addressLineOne_helper']}
                            inputProps={{ maxLength: 255 }}
                            variant="outlined"
                            disabled={isNew || isEdit ? false : true}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <img src="content/icons/location.svg" />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <div className="clientFieldDivsideBySide">
                        <Grid item xs={12} sm={6} md={6}>
                          <FormControl className="client-fields">
                            <TextField
                              required
                              id="zip"
                              name="postCode"
                              value={values['postCode'] ? values['postCode'] : ''}
                              onChange={setNewValues}
                              label="Postcode"
                              placeholder="Enter postcode"
                              fullWidth
                              autoComplete="shipping postal-code"
                              error={fieldError['postCode_error']}
                              helperText={fieldError['postCode_helper']}
                              inputProps={{ maxLength: 10 }}
                              variant="outlined"
                              disabled={isNew || isEdit ? false : true}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <FormControl className="client-fields">
                            <Autocomplete
                              options={
                                props.countriesList &&
                                props.countriesList.map(country => ({
                                  id: country.id,
                                  name: country.name,
                                }))
                              }
                              noOptionsText={'No Records'}
                              classes={{ listbox: 'autocompletelistbox' }}
                              ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                              getOptionLabel={option => option.name || ''}
                              getOptionSelected={(option, value) => option.id === value.id}
                              className="PersonAutocomplete"
                              onChange={handleCountryData}
                              disabled={isNew || isEdit ? false : true}
                              value={values.country || null}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  size="small"
                                  className="personAutocompleteTextfield autocompleteLabel"
                                  label="Country"
                                  required
                                  fullWidth
                                  error={fieldError['country_error']}
                                />
                              )}
                            />
                          </FormControl>
                        </Grid>
                      </div>
                      <div className="clientFieldDivsideBySide">
                        <Grid item xs={12} sm={6} md={6}>
                          <FormControl className="client-fields">
                            <Autocomplete
                              options={
                                props.stateList &&
                                props.stateList.map(state => ({
                                  id: state.id,
                                  name: state.name,
                                }))
                              }
                              noOptionsText={'Select Country First'}
                              classes={{ listbox: 'autocompletelistbox' }}
                              ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                              getOptionLabel={option => option.name || ''}
                              getOptionSelected={(option, value) => option.id === value.id}
                              className="PersonAutocomplete"
                              onChange={handleStateData}
                              value={values.state || null}
                              disabled={isNew || isEdit ? false : true}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  size="small"
                                  className="personAutocompleteTextfield autocompleteLabel"
                                  label="State"
                                  required
                                  fullWidth
                                  error={fieldError['state_error']}
                                />
                              )}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <FormControl className="client-fields">
                            <Autocomplete
                              options={
                                props.citiesList &&
                                props.citiesList.map(city => ({
                                  id: city.id,
                                  name: city.name,
                                }))
                              }
                              noOptionsText={'Select State First'}
                              classes={{ listbox: 'autocompletelistbox' }}
                              ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                              getOptionLabel={option => option.name || ''}
                              getOptionSelected={(option, value) => option.id === value.id}
                              className="PersonAutocomplete"
                              onChange={handleCityData}
                              value={values.city || null}
                              disabled={isNew || isEdit ? false : true}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  size="small"
                                  className="personAutocompleteTextfield autocompleteLabel"
                                  label="City"
                                  required
                                  fullWidth
                                  error={fieldError['city_error']}
                                />
                              )}
                            />
                          </FormControl>
                        </Grid>
                      </div>
                      <div className="personFieldDivsideBySide">
                        <Grid item xs={12} md={6} sm={12}>
                          <FormControl className="person-fields">
                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                              <KeyboardDatePicker
                                autoOk
                                variant="inline"
                                inputVariant="outlined"
                                label="Cotract Start Date"
                                name="contract_start_date"
                                error={fieldError['contractStart_error']}
                                format={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'MM/dd/yyyy'}
                                value={values['contract_start_date'] ? values['contract_start_date'] : null}
                                disabled={isNew || isEdit ? false : true}
                                ToolbarComponent={prop => (
                                  <>
                                    <CustomToolbarComponent {...prop} settingFormat={props.setting.date_format} />
                                  </>
                                )}
                                placeholder={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'MM/dd/yyyy'}
                                InputAdornmentProps={{ position: 'start' }}
                                onChange={date => handleContractStartDate(date)}
                              />{' '}
                            </MuiPickersUtilsProvider>
                            {fieldError['contractStart_error'] && (
                              <span style={{ color: 'red', fontSize: '12' }}>{fieldError['contractStart_helper']}</span>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} sm={12}>
                          <FormControl className="person-fields">
                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                              <KeyboardDatePicker
                                autoOk
                                variant="inline"
                                inputVariant="outlined"
                                label="Contract End Date"
                                name="contract_end_date"
                                error={fieldError['contractEnd_error']}
                                format={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'MM/dd/yyyy'}
                                value={values['contract_end_date'] ? values['contract_end_date'] : null}
                                disabled={isNew || isEdit ? false : true}
                                ToolbarComponent={prop => (
                                  <>
                                    <CustomToolbarComponent {...prop} settingFormat={props.setting.date_format} />
                                  </>
                                )}
                                placeholder={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'MM/dd/yyyy'}
                                InputAdornmentProps={{ position: 'start' }}
                                onChange={date => handleContractEndDate(date)}
                              />{' '}
                            </MuiPickersUtilsProvider>
                            {fieldError['contractEnd_error'] && (
                              <span style={{ color: 'red', fontSize: '12' }}>{fieldError['contractEnd_helper']}</span>
                            )}
                          </FormControl>
                        </Grid>
                      </div>
                    </div>
                    <Divider className="clientUpdateFeildDivider" style={{ margin: '6% 0' }} />
                    <div className="clientInner2ndDivTitle">Point of Contact</div>
                    <div className="clientInner2ndPaper">
                      <div className="clientFieldDivsideBySide">
                        <Grid item xs={12} md={6} sm={12}>
                          <FormControl className="client-fields">
                            <TextField
                              id="personFirstName"
                              fullWidth
                              className="person-First-Name"
                              placeholder="Enter first name"
                              data-cy="personFirstName"
                              variant="outlined"
                              name="personFirstName"
                              label="First Name"
                              defaultValue={clientEntity.personFirstName}
                              onChange={setNewValues}
                              required
                              value={values['personFirstName'] ? values['personFirstName'] : ''}
                              error={fieldError['personFirstName_error']}
                              helperText={fieldError['personFirstName_helper']}
                              inputProps={{ maxLength: 255 }}
                              disabled={isNew || isEdit ? false : true}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <img src="content/icons/Last Name.png" />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} sm={12}>
                          <FormControl className="client-fields">
                            <TextField
                              id="personLastName"
                              fullWidth
                              className="person-Last-Name"
                              placeholder="Enter last name"
                              data-cy="personLastName"
                              variant="outlined"
                              name="personLastName"
                              label="Last Name"
                              defaultValue={clientEntity.personLastName}
                              onChange={setNewValues}
                              required
                              value={values['personLastName'] ? values['personLastName'] : ''}
                              error={fieldError['personLastName_error']}
                              helperText={fieldError['personLastName_helper']}
                              inputProps={{ maxLength: 255 }}
                              disabled={isNew || isEdit ? false : true}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <img src="content/icons/Last Name.png" />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </FormControl>
                        </Grid>
                      </div>
                      <div className="clientFieldDivsideBySide">
                        <Grid item xs={12} md={6} sm={12}>
                          <FormControl className="client-fields">
                            <TextField
                              id="personEmail"
                              fullWidth
                              className="person-Email"
                              data-cy="personEmail"
                              placeholder="Enter email"
                              variant="outlined"
                              name="personEmail"
                              label="Email"
                              defaultValue={clientEntity.personEmail}
                              onChange={setNewValues}
                              required
                              value={values['personEmail'] ? values['personEmail'] : ''}
                              error={fieldError['personEmail_error']}
                              helperText={fieldError['personEmail_helper']}
                              inputProps={{ maxLength: 255 }}
                              disabled={isNew || isEdit ? false : true}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <img src="content/icons/Personal Details/Email Address.png" />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} sm={12}>
                          <FormControl className="client-fields">
                            <PhoneInput
                              inputProps={{
                                name: 'phone',
                                autoFocus: false,
                              }}
                              country={
                                props.settings.country_of_operations
                                  ? country_types.find(country => country.value === props.settings.country_of_operations)
                                    ? country_types.find(country => country.value === props.settings.country_of_operations).phoneName
                                    : 'fr'
                                  : 'fr'
                              }
                              placeholder="Enter phone number"
                              value={values['phone'] ? values['phone'] : ''}
                              onChange={phone => handlePhoneNumber(phone)}
                              disabled={isNew || isEdit ? false : true}
                            />
                          </FormControl>
                        </Grid>
                      </div>
                      <Grid item xs={12} md={6} sm={12}>
                        <FormControl className="client-fields">
                          <TextField
                            id="jobTitle"
                            fullWidth
                            className="job-Title"
                            data-cy="jobTitle"
                            placeholder="Enter job title"
                            variant="outlined"
                            name="jobTitle"
                            label="Designation"
                            defaultValue={clientEntity.jobTitle}
                            onChange={setNewValues}
                            value={values['jobTitle'] ? values['jobTitle'] : ''}
                            error={fieldError['jobTitle_error']}
                            helperText={fieldError['jobTitle_helper']}
                            inputProps={{ maxLength: 255 }}
                            disabled={isNew || isEdit ? false : true}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <img src="content/icons/Last Name.png" />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </div>
                    {isEdit === true || isNew ? (
                      <>
                        <Divider style={{ margin: '6% 0' }} />
                        <Grid item xs={12} style={{ width: '98%' }}>
                          <FormControl
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'flex-end',
                              marginBottom: '20px',
                            }}
                          >
                            <Button
                              id="cancel-save"
                              className="clientDiscardButton"
                              component={Link}
                              to={isNew && '/clients'}
                              style={{ color: 'black', backgroundColor: '#17a2b8' }}
                              onClick={cancleEditHandler}
                            >
                              &nbsp; Cancel
                            </Button>
                            &nbsp; &nbsp; &nbsp;
                            <Button
                              style={{ color: 'white', backgroundColor: 'blue' }}
                              id="save-entity"
                              data-cy="entityCreateSaveButton"
                              className="clientSaveButton"
                              type="submit"
                              disabled={updating}
                              onClick={editEntity}
                            >
                              &nbsp; Save
                            </Button>
                          </FormControl>
                        </Grid>
                      </>
                    ) : (
                      ''
                    )}
                  </form>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <br />
    </React.Fragment>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  clientEntity: storeState.client.entity,
  loading: storeState.client.loading,
  updating: storeState.client.updating,
  updateSuccess: storeState.client.updateSuccess,
  settings: storeState.organizationSettings.storedOrganizationSettings,
  setting: storeState.PersonalSettings.storedPersonalSettings,
  stateList: storeState.organization.StateEntity,
  countriesList: storeState.subcontractor.CountryEntity,
  citiesList: storeState.organization.CityEntity,
  serviceArea: storeState.serviceArea.entities,
  isOrganizationAdministrator: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.ORGANIZATION_ADMINISTRATOR]),
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createNewClient,
  reset,
  resetorg,
  getOrganizationSettings,
  getState,
  getCity,
  getCountry,
  getCityByState,
  getServiceArea,
  getPersonalSettings,
  setPageNumber,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ClientUpdate);
