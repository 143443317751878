import React, { useEffect, useCallback, useRef, useState, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import KeyboardArrowLeftRounded from '@material-ui/icons/KeyboardArrowLeftRounded';
import KeyboardArrowRightRounded from '@material-ui/icons/KeyboardArrowRightRounded';
import {
  YearPrefix,
  dayPrefix,
  handleFilterCurrentWeekStartDate,
  handleFilterCurrentWeekEndDate,
  formatDate,
} from '../../../utils/TimeFormatAndTimezone';
import { monthPrefix, weeks } from '../../../utils/global';
import moment from 'moment';
import { resetModal, handleResetWeek, timeOverlapWithPreviousShift, applyPeriodShiftsFilter } from './calendarModalFunctions';
import debounce from 'lodash.debounce';
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS, Step } from 'react-joyride';

export interface ICalendarHeader extends StateProps, DispatchProps {
  calenderMode: string;
  prop: any;
  setCalendarDisplayWindow: (data: any) => void;
  cal: any;
  setCalenderMode: (data: string) => void;
  handleShiftOpen: () => void;
  handleJoyrideCallback: (data: any) => void;
  run: boolean;
}

export const CalendarHeader = (props: ICalendarHeader) => {
  const { prop, calenderMode, cal, setCalenderMode, setCalendarDisplayWindow, handleShiftOpen } = props;

  const [monthName, setMonthName] = useState<any | null>(monthPrefix(new Date().getMonth() + 1));
  const [yearName, setYearName] = useState<any | null>(YearPrefix(monthPrefix(new Date().getMonth() + 1), new Date().getFullYear()));
  const [dayName, setDayName] = useState<any | null>(
    dayPrefix(
      new Date().getDate(),
      monthPrefix(new Date().getMonth() + 1),
      YearPrefix(monthPrefix(new Date().getMonth() + 1), new Date().getFullYear())
    )
  );
  const [weekStartDate, setWeekStartDate] = useState<any | null>(moment(handleFilterCurrentWeekStartDate()).format('DD MMM YYYY'));
  const [weekEndDate, setWeekEndDate] = useState<any | null>(moment(handleFilterCurrentWeekEndDate()).format('DD MMM YYYY'));

  const scrollCalendar = useCallback(action => {
    switch (action) {
      case 'move-prev':
        cal.current.calendarInst.prev();
        calendarStartEndDate(true);
        break;
      case 'move-next':
        cal.current.calendarInst.next();
        calendarStartEndDate(true);
        break;
      case 'move-today':
        cal.current.calendarInst.today();
        calendarStartEndDate(null);
        break;
      case 'today':
        cal.current.calendarInst.today();
        calendarStartEndDate(true);
        applyPeriodShiftsFilter(calenderMode, prop, setCalendarDisplayWindow);
        break;
      default:
        return;
    }
  }, []);

  const setCalenderView = cview => {
    setCalenderMode(cview);
    scrollCalendar('move-today');
    if (cview === 'week') {
      applyPeriodShiftsFilter('WEEK', prop, setCalendarDisplayWindow);
    } else if (cview === 'month') {
      applyPeriodShiftsFilter('MONTH', prop, setCalendarDisplayWindow);
    } else if (cview === 'day') {
      applyPeriodShiftsFilter('DAY', prop, setCalendarDisplayWindow);
    }
  };

  const calendarStartEndDate = debounce(isToday => {
    const dateStart = cal?.current?.calendarInst?.getDateRangeStart();
    const dateEnd = cal?.current?.calendarInst?.getDateRangeEnd();
    if (dateStart && dateEnd) {
      const val = cal?.current?.calendarInst?.getDate();
      const month = monthPrefix(val.getMonth() + 1);
      const year = YearPrefix(month, val._date.getFullYear());
      const day = dayPrefix(val._date.getDate(), month, year);
      const startDate = dateStart._date.getDate() + ' ' + monthPrefix(dateStart._date.getMonth() + 1) + ' ' + dateStart._date.getFullYear();
      const endDate = dateEnd._date.getDate() + ' ' + monthPrefix(dateEnd._date.getMonth() + 1) + ' ' + dateEnd._date.getFullYear();

      if (isToday === true && calenderMode !== 'month') {
        prop.getScheduledShifts({
          from_date: formatDate(dateStart),
          to_date: formatDate(dateEnd),
          current_day: '',
        });
        setCalendarDisplayWindow(oldValues => ({ ...oldValues, ['start_date']: startDate, ['end_date']: endDate }));
        setWeekStartDate(startDate);
        setWeekEndDate(endDate);
      } else if (isToday === true && calenderMode === 'month') {
        const myNewDate = new Date(new Date(year, val.getMonth() + 1, -1));
        const newDate = myNewDate.setDate(myNewDate.getDate() + 2);

        const first = formatDate(new Date(year, val.getMonth(), 1));
        const last = moment(newDate).format('YYYY-MM-DD');
        prop.getScheduledShifts({ from_date: first, to_date: last, current_day: '' });
        setCalendarDisplayWindow(oldValues => ({ ...oldValues, ['start_date']: first, ['end_date']: last }));
      } else {
        setCalendarDisplayWindow(oldValues => ({
          ...oldValues,
          ['start_date']: handleFilterCurrentWeekStartDate().format('DD MMM YYYY'),
          ['end_date']: handleFilterCurrentWeekEndDate().format('DD MMM YYYY'),
        }));
        setWeekStartDate(handleFilterCurrentWeekStartDate().format('DD MMM YYYY'));
        setWeekEndDate(handleFilterCurrentWeekEndDate().format('DD MMM YYYY'));
      }
      setMonthName(month);
      setYearName(year);
      setDayName(day);
    }
  }, 1);

  return (
    <>
      <Joyride
        callback={data => {
          props.handleJoyrideCallback(data);
        }}
        run={props.run}
        disableScrolling
        continuous={true}
        locale={{
          last: <strong aria-label="last">Next</strong>,
          back: <strong aria-label="back">Previous</strong>,
          next: <strong aria-label="next">Next</strong>,
        }}
        showSkipButton
        styles={{
          buttonNext: {
            background: '#d48bd3',
          },
          buttonSkip: {
            background: '#f7b017',
          },
          tooltipContainer: {
            height: 50,
          },
          options: {
            width: 300,
          },
        }}
        steps={[
          {
            target: '#btnCreatShift',
            content: (
              <h1 style={{ fontSize: '17px', alignContent: 'center', alignSelf: 'baseline' }}>
                Click here to create New <b style={{ color: '#7a55de' }}>Shift</b>
              </h1>
            ),
            disableBeacon: true,
            disableOverlayClose: true,
            hideCloseButton: true,
            hideFooter: true,
            spotlightClicks: true,
            placement: 'left',
          },
        ]}
      />
      <div className="dashboard-header-div">
        <div className="dashboard-header-scroll-btns-div">
          <Button variant="contained" onClick={() => scrollCalendar('today')} style={{ background: 'white' }}>
            Today
          </Button>
        </div>
        <div className="dashboard-header-scroll-btns-div">
          <Button variant="contained" onClick={() => scrollCalendar('move-prev')} style={{ width: '10%', background: 'white' }}>
            <KeyboardArrowLeftRounded />
          </Button>
          {calenderMode === 'day' && <span className="date-text">&nbsp;{dayName}&nbsp;</span>}
          {calenderMode === 'week' && <span className="date-text">&nbsp;{weekStartDate + ' - ' + weekEndDate}&nbsp;</span>}
          {calenderMode === 'month' && <span className="date-text">&nbsp;{monthName + ' ' + YearPrefix(monthName, yearName)}&nbsp;</span>}
          <Button variant="contained" onClick={() => scrollCalendar('move-next')} style={{ background: 'white' }}>
            <KeyboardArrowRightRounded />
          </Button>
        </div>
        <div className="dashboard-header-period-filters-div">
          <Button
            className="mb-3"
            variant="contained"
            id="dayButton"
            color={calenderMode === 'day' ? 'primary' : 'default'}
            onClick={() => setCalenderView('day')}
            style={{ width: '10%', background: calenderMode === 'day' ? '#D48BD3' : 'white' }}
          >
            Day
          </Button>
          <Button
            className="mb-3"
            variant="contained"
            id="weekButton"
            color={calenderMode === 'week' ? 'primary' : 'default'}
            onClick={() => setCalenderView('week')}
            style={{ width: '10%', background: calenderMode === 'week' ? '#D48BD3' : 'white' }}
          >
            Week
          </Button>
          <Button
            className="mb-3"
            variant="contained"
            id="monthButton"
            color={calenderMode === 'month' ? 'primary' : 'default'}
            onClick={() => setCalenderView('month')}
            style={{ width: '10%', background: calenderMode === 'month' ? '#D48BD3' : 'white' }}
          >
            Month
          </Button>
        </div>
        <div className="dashboard-header-create-shift-btn-div calendar-header-shift-creation-btns-div">
          <Button
            className="mr-2 mb-3"
            variant="contained"
            id="btnCreatShift"
            color="secondary"
            onClick={() => handleShiftOpen()}
            style={{ background: '#D48BD3', borderRadius: 'unset', marginRight: '12px' }}
          >
            + Create New Shift
          </Button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(CalendarHeader));
