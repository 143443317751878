import React, { Fragment, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Grid, TextField, Button } from '@material-ui/core';
import { Circle, GoogleMap, Marker, Polyline } from 'react-google-maps';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import { Combobox, ComboboxInput, ComboboxPopover, ComboboxList, ComboboxOption } from '@reach/combobox';
import '@reach/combobox/styles.css';
import withScriptjs from 'react-google-maps/lib/withScriptjs';
import withGoogleMap from 'react-google-maps/lib/withGoogleMap';
export default function PinLocationMap(props) {
  const PlacesAutocomplete = ({ setSelected, setCenter }) => {
    const {
      ready,
      value,
      setValue,
      suggestions: { status, data },
      clearSuggestions,
    } = usePlacesAutocomplete();

    const handleSelect = async address => {
      setValue(address, false);
      clearSuggestions();
      const results = await Promise.resolve(getGeocode({ address }));
      const { lat, lng } = await Promise.resolve(getLatLng(results[0]));
      setSelected({ lat, lng });
      setCenter({ lat, lng });
      props.setCoordinates([
        {
          lat: lat ? lat : '',
          lng: lng ? lng : '',
        },
      ]);
      props.setValues(oldValues => ({
        ...oldValues,
        serviceAreaSettings: { ...oldValues.serviceAreaSettings, location: `(${lat},${lng})` },
      }));
    };

    return (
      <Combobox onSelect={handleSelect}>
        <ComboboxInput value={value} onChange={e => setValue(e.target.value)} disabled={!ready} placeholder="Search an address" />
        <ComboboxPopover>
          <ComboboxList>
            {status === 'OK' && data.map(({ place_id, description }) => <ComboboxOption key={place_id} value={description} />)}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    );
  };

  const Map = () => {
    return (
      <>
        {props.center && (
          <GoogleMap defaultZoom={16} defaultCenter={props.center} onClick={props.setMark}>
            {props.places && (
              <Fragment>
                <Marker
                  position={{
                    lat: parseFloat(props.places.latitude),
                    lng: parseFloat(props.places.longitude),
                  }}
                />
                {props.places.circle && (
                  <Circle
                    defaultCenter={{
                      lat: parseFloat(props.places.latitude),
                      lng: parseFloat(props.places.longitude),
                    }}
                    radius={props.places.circle.radius}
                    options={props.options}
                  />
                )}
              </Fragment>
            )}
          </GoogleMap>
        )}
      </>
    );
  };
  const WrappedMap: any = withScriptjs(withGoogleMap(Map));
  const [selected, setSelected] = useState(null);
  return (
    <>
      <div style={{ width: '90%', height: '500px' }}>
        <div className="places-container">
          <PlacesAutocomplete setSelected={setSelected} setCenter={props.setCenter} />
          <Button className="delete-marks-button" onClick={props.deleteMarks}>
            Reset{' '}
          </Button>
        </div>
        <div style={{ width: '100%', height: '90%', marginBottom: '5%' }}>
          <WrappedMap
            googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`}
            containerElement={<div style={{ height: '100%' }} />}
            mapElement={<div style={{ height: '100%' }} />}
            loadingElement={<div style={{ height: '100%' }} />}
          />
        </div>
      </div>
    </>
  );
}
