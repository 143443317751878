import React, { useState, useEffect, Fragment, ReactFragment } from 'react';
import Button from '@material-ui/core/Button';
import { ActionButtons } from '../actionButtons/actionButtons';
import MomentUtils from '@date-io/moment';
import { EmploymentHistorySections } from './professionalHistoryEmploymentSection';
import { ProfessionalHistoryOthersSection } from './professionalHistoryOthersSection';
import { EducationHistorySections } from './professionalHistoryEducationSection';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import InputLabel from '@material-ui/core/InputLabel';
import { ReferenceModal } from './ReferenceModal';
import CustomizedSnackbar from '../otherComponents/snackBarComponent';
import '../../form.scss';
import { ReactChild } from 'react';
import moment from 'moment';
import settings from 'app/modules/account/settings/settings';
import { parseInt } from 'lodash';

interface Props {
  submit: (data: any) => void;
  setValues: (data: any) => void;
  provideReference: boolean;
  setProvideReference: (data: boolean) => void;
  professionalHistory: any;
  setProfessionalHistory: (data: any) => void;
  educationHistory: any;
  setEducationHistory: (data: any) => void;
  componentArr: any;
  setComponentsArray: (data: any) => void;
  educationComponentArr: any;
  setEducationComponentsArray: (data: any) => void;
  values: any;
  setting: any;
  settings: any;
  employeeFormData: any;
  isEdit: boolean;
}

export const ProfessionalHistory: React.FC<Props> = props => {
  const {
    submit,
    setValues,
    values,
    provideReference,
    setProvideReference,
    educationHistory,
    setEducationHistory,
    professionalHistory,
    setProfessionalHistory,
    componentArr,
    setComponentsArray,
    educationComponentArr,
    setEducationComponentsArray,
    employeeFormData,
    isEdit,
  } = props;

  const [openReferenceModal, setOpenReferenceModal] = useState(false);
  const [eduGap, setEduGap] = useState(false);
  const [empGap, setEmpGap] = useState(false);
  const [otherGap, setOtherGap] = useState(false);
  const [counter, setCounter] = useState(values['emp_counter'] ? values['emp_counter'] : 1);
  const [educationCounter, setEducationCounter] = useState(values['edu_counter'] ? values['edu_counter'] : 1);
  const [refCounter, setRefCounter] = useState(0);
  const [gapCounter, setGapCounter] = useState(0);
  const [gapReason, setGapReason] = useState({ job: false, holidays: false, health: false, others: false });
  const [openEmpGapSnackBar, setOpenEmpGapSnackBar] = React.useState(false);
  const [openEmpHistSnackBar, setOpenEmpHistSnackBar] = React.useState(false);
  const [filterTag, setFilterTag] = useState('EMPLOYMENT');
  const [recordYears, setRecordYears] = useState(0);
  const [files, setFiles] = useState([]);
  const [showError, setShowError] = useState(false);
  const [totalEmployment, setTotalEmployment] = useState(0);
  const [fieldError, setFieldError] = useState({
    emp_start_date_0: false,
    emp_start_date_0_helper: 'Required',
    emp_end_date_0: false,
    emp_end_date_0_helper: 'Required',
    edu_start_date_0: false,
    edu_start_date_0_helper: 'Required',
    edu_end_date_0: false,
    edu_end_date_0_helper: 'Required',
  });

  useEffect(() => {
    setValues(oldValues => ({ ...oldValues, ['emp_counter']: counter }));
    setValues(oldValues => ({ ...oldValues, ['ref_counter']: refCounter }));
    setValues(oldValues => ({ ...oldValues, ['edu_counter']: educationCounter }));
  }, []);

  useEffect(() => {
    setValues(oldValues => ({ ...oldValues, ['emp_counter']: counter }));
  }, [counter]);

  useEffect(() => {
    setValues(oldValues => ({ ...oldValues, ['edu_counter']: educationCounter }));
  }, [educationCounter]);

  useEffect(() => {
    setValues(oldValues => ({ ...oldValues, ['ref_counter']: refCounter }));
  }, [refCounter]);

  useEffect(() => {
    if (isEdit && employeeFormData) {
      const employmentCounter = employeeFormData['professional_history']['EMPLOYMENT']?.length || 0;
      setCounter(employmentCounter);
      const editComponentArr = componentArr;
      for (let i = 1; i < employmentCounter; i++) {
        editComponentArr.push('item-' + i);
      }
      setComponentsArray(editComponentArr);
    }
  }, [employeeFormData]);

  useEffect(() => {
    if (isEdit && employeeFormData) {
      const educationEditCounter = employeeFormData['professional_history']['EDUCATION']?.length || 0;
      setEducationCounter(educationEditCounter);
      const editComponentArr = educationComponentArr;
      for (let i = 1; i < educationEditCounter; i++) {
        editComponentArr.push('item-' + i);
      }
      setEducationComponentsArray(editComponentArr);
    }
  }, [employeeFormData]);

  useEffect(() => {
    if (isEdit && employeeFormData) {
      const employmentCounter = employeeFormData['professional_history']['EMPLOYMENT']?.length || 0;
      if (employmentCounter > 0) {
        const employmentValues = employeeFormData['professional_history']['EMPLOYMENT'];
        employmentValues.map((item, index) => {
          setValues(values => ({
            ...values,
            [`company_name_${index}`]: item.company_name,
            [`job_title_${index}`]: item.job_title,
            [`company_location_${index}`]: item.company_location,
            [`emp_post_code_${index}`]: item.post_code,
            [`emp_start_date_${index}`]: item.emp_start_date,
            [`emp_end_date_${index}`]: item.emp_end_date,
            [`emp_leaving_reason_${index}`]: item.leaving_reason,
            [`emp_contact_name_${index}`]: item.contact_name,
            [`emp_contact_email_${index}`]: item.contact_email,
            [`emp_contact_telephone_${index}`]: item.contact_telephone,
          }));
        });
        setValues(oldValues => ({ ...oldValues, ['person_id']: employmentValues[0].person_id }));
        setValues(oldValues => ({ ...oldValues, ['emp_counter']: employmentCounter }));
      }
    }
  }, [employeeFormData]);

  useEffect(() => {
    if (isEdit && employeeFormData) {
      const educationEditCounter = employeeFormData['professional_history']['EDUCATION']?.length || 0;
      if (educationEditCounter > 0) {
        const educationValues = employeeFormData['professional_history']['EDUCATION'];
        educationValues.map((item, index) => {
          let editFileName = '';
          if (item.proof) {
            const string = item.proof['file'];
            const parts = string.split(',');
            const file = parts[1].slice(0, 30);
            editFileName = file + '.' + item.proof['ext'];
          }
          setValues(values => ({
            ...values,
            [`degree_title_${index}`]: item.degree_title,
            [`institute_name_${index}`]: item.institute_name,
            [`institute_location_${index}`]: item.institute_location,
            [`education_post_code_${index}`]: item.post_code,
            [`edu_start_date_${index}`]: item.start_date,
            [`edu_end_date_${index}`]: item.end_date,
            [`edu_proof_name_${index}`]: editFileName,
          }));
        });

        setValues(oldValues => ({ ...oldValues, ['edu_counter']: educationEditCounter }));
      }
    }
  }, [employeeFormData]);

  useEffect(() => {
    if (isEdit && employeeFormData) {
      const othersValuesCount = employeeFormData['professional_history']['OTHERS']?.length || 0;
      if (othersValuesCount > 0) {
        const othersValues = employeeFormData['professional_history']['OTHERS'];

        let editFileName = '';
        if (othersValues[0]['proof']) {
          const string = othersValues[0]['proof']['file'];
          const parts = string.split(',');
          const file = parts[1].slice(0, 30);
          editFileName = file + '.' + othersValues[0]['proof']['ext'];
        }

        setValues(values => ({
          ...values,
          ['history-others-reason']: othersValues[0].reason,
          ['history_others_reason_start_date']: othersValues[0].start_date,
          ['history_others_reason_end_date']: othersValues[0].end_date,
          ['history-others-reason-extra-note']: othersValues[0].note,
          ['history_other_proof_name']: editFileName,
        }));
      }
    }
  }, [employeeFormData]);

  useEffect(() => {
    if (isEdit && employeeFormData) {
      const referenceCounter = employeeFormData['professional_history']['ALL_REFERENCES_COUNT'];
      setRefCounter(referenceCounter);
      setValues(oldValues => ({ ...oldValues, ['ref_counter']: referenceCounter }));
      if (referenceCounter > 0 && employeeFormData['professional_history']['OTHER_REFERENCES']) {
        const othersValues = employeeFormData['professional_history']['OTHER_REFERENCES'];
        setValues(values => ({
          ...values,
          [`other_reference_name_${referenceCounter}`]: othersValues['ref_name'],
          [`other_reference_email_${referenceCounter}`]: othersValues['ref_email'],
          [`other_reference_telephone_${referenceCounter}`]: othersValues['ref_telephone'],
          [`other_reference_post_code_${referenceCounter}`]: othersValues['ref_post_code'],
        }));
      }
    }
  }, [employeeFormData]);

  // Extend and Reduce form of employment
  const handleExtendForm = () => {
    setProvideReference(false);
    const newComponentArr = componentArr;
    setCounter(counter + 1);
    const AddNewElem = newComponentArr.push('item-' + counter);
    setComponentsArray(newComponentArr);
    setValues(oldValues => ({ ...oldValues, ['emp_counter']: counter }));
  };

  // Extend and Reduce form of education
  const handleEducationExtendForm = () => {
    setProvideReference(false);
    const newComponentArr = educationComponentArr;
    setEducationCounter(educationCounter + 1);
    const AddNewElem = newComponentArr.push('item-' + educationCounter);
    setEducationComponentsArray(newComponentArr);
    setValues(oldValues => ({ ...oldValues, ['edu_counter']: educationCounter }));
  };

  const handleReduceForm = () => {
    const newComponentArr = componentArr;
    setCounter(counter - 1);
    const AddNewElem = newComponentArr.pop();
    setComponentsArray(newComponentArr);
  };

  const handleFilesData = (event, name) => {
    const makeFilesCopy = files;
    makeFilesCopy.push({ file_name: name, file: event.target.files[0] });
    setFiles(makeFilesCopy);
  };

  const toggleButtons = btn => {
    if (btn === 'EMPLOYMENT') {
      setFilterTag('EMPLOYMENT');
      setEduGap(false);
      setOtherGap(false);
    } else if (btn === 'EDUCATION') {
      setFilterTag('EDUCATION');
      setEmpGap(false);
      setOtherGap(false);
      if (!eduGap && provideReference) {
        setProvideReference(false);
      }
    } else if (btn === 'OTHERS') {
      setFilterTag('OTHERS');
      setOtherGap(true);
    }
  };

  const chooseBackgroundColor = btn => {
    if (btn === filterTag) {
      return '#4720b7';
    } else {
      return '#fff';
    }
  };

  const chooseTextColor = btn => {
    if (btn === filterTag) {
      return 'white';
    } else {
      return 'black';
    }
  };

  const handleReferenceModal = () => {
    setOpenReferenceModal(!openReferenceModal);
  };

  const findProfessioanlHistoryGaps = (value, tag, localCounter) => {
    if (!fieldError[`${tag}_start_date_${localCounter - 1}`] && !fieldError[`${tag}_end_date_${localCounter - 2}`]) {
      const date1 = new Date(value);
      const date2 = new Date(professionalHistory['end_date']);
      const Difference_In_Time = date1.getTime() - date2.getTime();
      const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      if (Difference_In_Days > 31) {
        setProvideReference(true);
        if (tag === 'emp') {
          setEmpGap(true);
          setEduGap(false);
        } else {
          setEmpGap(false);
          setEduGap(true);
        }
      } else {
        setProvideReference(false);
      }
    }
  };

  const checkSingleFieldValidations = (date, id, eName) => {
    if (date === undefined || date === '' || date === null) {
      setFieldError(oldValues => ({ ...oldValues, [eName]: true }));
      setFieldError(oldValues => ({ ...oldValues, [`${eName}_helper`]: 'Required' }));
    } else {
      setFieldError(oldValues => ({ ...oldValues, [eName]: false }));
    }
    if (id === 'emp_start_date' && counter > 1) {
      findProfessioanlHistoryGaps(date, 'emp', counter);
    }
    if (id === 'edu_start_date' && educationCounter > 1) {
      findProfessioanlHistoryGaps(date, 'edu', educationCounter);
    }
  };

  const extendFormCheckDatesValidations = (nameStart, nameEnd, tag) => {
    let startDate = null;
    let endDate = null;
    let localCounter = null;
    if (tag === 'EMPLOYMENT') {
      startDate = values[`${nameStart}_${counter - 1}`];
      endDate = values[`${nameEnd}_${counter - 1}`];
      localCounter = counter;
    } else if (tag === 'EDUCATION') {
      startDate = values[`${nameStart}_${educationCounter - 1}`];
      endDate = values[`${nameEnd}_${educationCounter - 1}`];
      localCounter = educationCounter;
    }

    if (startDate === undefined || startDate === '') {
      if (endDate === undefined || endDate === '') {
        setFieldError(oldValues => ({ ...oldValues, [`${nameStart}_${localCounter - 1}`]: true }));
        setFieldError(oldValues => ({ ...oldValues, [`${nameEnd}_${localCounter - 1}`]: true }));
      } else {
        setFieldError(oldValues => ({ ...oldValues, [`${nameStart}_${localCounter - 1}`]: true }));
        setFieldError(oldValues => ({ ...oldValues, [`${nameEnd}_${localCounter - 1}`]: false }));
      }
    } else if (endDate === undefined || endDate === '') {
      setFieldError(oldValues => ({ ...oldValues, [`${nameStart}_${localCounter - 1}`]: false }));
      setFieldError(oldValues => ({ ...oldValues, [`${nameEnd}_${localCounter - 1}`]: true }));
    } else {
      setFieldError(oldValues => ({ ...oldValues, [`${nameStart}_${localCounter - 1}`]: false }));
      setFieldError(oldValues => ({ ...oldValues, [`${nameEnd}_${localCounter - 1}`]: false }));
      if (!provideReference) {
        if (tag === 'EMPLOYMENT') {
          handleExtendForm();
        } else if (tag === 'EDUCATION') {
          handleEducationExtendForm();
        }
      }
    }
  };

  const setProfessionalHistoryDates = (date, name, id, eName) => {
    const d = moment(date).format('YYYY-MM-DD');
    setProfessionalHistory(oldValues => ({ ...oldValues, [name]: date }));
    setValues(oldValues => ({ ...oldValues, [eName]: d }));
    checkSingleFieldValidations(d, id, eName);
  };

  const handleEmployementHistory = () => {
    let difference = 0;
    for (let i = 0; i <= counter; i++) {
      if (values[`emp_start_date_${i}`] && values[`emp_end_date_${i}`]) {
        const date1 = new Date(values[`emp_start_date_${i}`]);
        const date2 = new Date(values[`emp_end_date_${i}`]);

        const Difference_In_Time = date2.getTime() - date1.getTime();
        const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        difference = difference + Difference_In_Days;
      }
    }
    for (let i = 0; i < educationCounter; i++) {
      if (values[`edu_start_date_${i}`] && values[`edu_end_date_${i}`]) {
        const date1 = new Date(values[`edu_start_date_${i}`]);
        const date2 = new Date(values[`edu_end_date_${i}`]);

        const Difference_In_Time = date2.getTime() - date1.getTime();
        const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        difference = difference + Difference_In_Days;
      }
    }
    if (values['history_others_reason_start_date'] && values['history_others_reason_end_date']) {
      const date1 = new Date(values['history_others_reason_start_date']);
      const date2 = new Date(values['history_others_reason_end_date']);

      const Difference_In_Time = date2.getTime() - date1.getTime();
      const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      difference = difference + Difference_In_Days;
    }

    if (difference > 365) {
      setRecordYears(Math.floor(difference / 365));
    } else {
      setRecordYears(0);
    }

    if (difference >= 5 * 365) {
      setShowError(false);
      return true;
    } else {
      setShowError(true);
      return false;
    }
  };

  useEffect(() => {
    handleEmployementHistory();
  }, [values]);

  const employmentHistoryGapEdit = () => {
    if (isEdit && values && values['emp_counter'] > 1) {
      setProfessionalHistory({ start_date: '', end_date: '' });
      setProfessionalHistory({
        start_date: values[`emp_start_date_${values['emp_counter'] - 1}`],
        end_date: values[`emp_end_date_${values['emp_counter'] - 2}`],
      });
      findProfessioanlHistoryGaps(values[`emp_start_date_${values['emp_counter'] - 1}`], 'emp', values['emp_counter']);
    }
  };
  const educationHistoryGapEdit = () => {
    if (isEdit && values && values['edu_counter'] > 1) {
      setProfessionalHistory({ start_date: '', end_date: '' });
      setProfessionalHistory({
        start_date: values[`edu_start_date_${values['edu_counter'] - 1}`],
        end_date: values[`edu_end_date_${values['edu_counter'] - 2}`],
      });
      findProfessioanlHistoryGaps(values[`edu_start_date_${values['edu_counter'] - 1}`], 'emp', values['edu_counter']);
    }
  };

  const handleGapReason = e => {
    if (e.target.name === 'job') {
      setGapReason(oldValues => ({ ...oldValues, ['job']: true, ['health']: false, ['holidays']: false, ['others']: false }));
      setValues(oldValues => ({ ...oldValues, [`gap_reason_${gapCounter}`]: 'job hunt' }));
    } else if (e.target.name === 'health') {
      setGapReason(oldValues => ({ ...oldValues, ['job']: false, ['health']: true, ['holidays']: false, ['others']: false }));
      setValues(oldValues => ({ ...oldValues, [`gap_reason_${gapCounter}`]: 'medical break' }));
    } else if (e.target.name === 'holidays') {
      setGapReason(oldValues => ({ ...oldValues, ['job']: false, ['health']: false, ['holidays']: true, ['others']: false }));
      setValues(oldValues => ({ ...oldValues, [`gap_reason_${gapCounter}`]: 'holidays' }));
    } else if (e.target.name === 'others') {
      setGapReason(oldValues => ({ ...oldValues, ['job']: false, ['health']: false, ['holidays']: false, ['others']: true }));
      setValues(oldValues => ({ ...oldValues, [`gap_reason_${gapCounter}`]: 'others' }));
    }
    setGapCounter(gapCounter + 1);
    setValues(oldValues => ({ ...oldValues, ['gap_counter']: gapCounter }));
  };

  const handleGapProof = e => {
    const makeFilesCopy = files;
    makeFilesCopy.push({ file_name: eduGap ? `edu_gap_proof_${gapCounter}` : `emp_gap_proof_${gapCounter}`, file: e.target.files[0] });
    setFiles(makeFilesCopy);
  };

  const setFormValues = e => {
    setValues(oldValues => ({ ...oldValues, [e.target.name]: e.target.value }));
  };
  const setPhoneValues = (e, index) => {
    setValues(oldValues => ({ ...oldValues, [`emp_contact_telephone_${index}`]: e }));
  };

  const submitProfessionalHistory = () => {
    const check = handleEmployementHistory();
    if (check) {
      const object = new FormData();
      files.forEach(element => {
        object.append(element.file_name, element.file);
      });
      submit(object);
    }
  };

  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
      <fieldset className="professional-history-section">
        <div className="professional-history-section-top-heading-outer-div">
          <div className="job-application-form-sections-top-heading-inner-div">
            <div className="job-application-form-heading-record-time"> Record Provided for: {recordYears} Years </div>
          </div>
          {recordYears < 5 && showError && (
            <div style={{ marginTop: '30px', fontFamily: 'Roboto', fontSize: '14px' }}>
              <p style={{ color: '#ed6f6f' }}>
                So far you entered {recordYears} years record but you need to add record of your previous 05 years. Please add{' '}
                {5 - recordYears} more years.
              </p>
            </div>
          )}
        </div>
        <div className="professional-history-section-body">
          <div className="professional-history-body-navigation-buttons">
            <Button
              className={filterTag === 'EMPLOYMENT' ? 'active-professional-history-buttons' : 'not-active-professional-history-buttons'}
              onClick={() => {
                toggleButtons('EMPLOYMENT');
                // employmentHistoryGapEdit();
              }}
            >
              I was Employed
            </Button>
            <Button
              className={
                filterTag === 'EDUCATION'
                  ? 'active-professional-history-buttons seekingEducationButton'
                  : 'not-active-professional-history-buttons seekingEducationButton'
              }
              onClick={() => {
                toggleButtons('EDUCATION');
                // educationHistoryGapEdit()
              }}
            >
              I was seeking Education
            </Button>
            <Button
              className={filterTag === 'OTHERS' ? 'active-professional-history-buttons' : 'not-active-professional-history-buttons'}
              onClick={() => {
                toggleButtons('OTHERS');
              }}
              style={{marginLeft : '10px'}}
            >
              Other(s)
            </Button>
          </div>
          <div className="professional-history-form">
            {filterTag === 'EMPLOYMENT' && (
              <React.Fragment>
                <>
                  {componentArr.map((item, index) => (
                    <EmploymentHistorySections
                      key={index}
                      index={index}
                      setFormValues={setFormValues}
                      setHistory={setProfessionalHistoryDates}
                      values={values}
                      fieldError={fieldError}
                      dateSetting={props.setting.date_format}
                      setValues={setValues}
                      settings={settings}
                      setPhoneValues={setPhoneValues}
                      isEdit={isEdit}
                    />
                  ))}
                </>
                <>
                  {provideReference && empGap && (
                    <JustifyGapComponent
                      setFormValues={setFormValues}
                      handleGapReason={handleGapReason}
                      openModal={handleReferenceModal}
                      eduGap={eduGap}
                      gapReason={gapReason}
                      fileNameGap={`edu_gap_proof_${gapCounter}`}
                      handleData={handleGapProof}
                    />
                  )}
                </>
              </React.Fragment>
            )}

            {filterTag === 'EDUCATION' && (
              <React.Fragment>
                <>
                  {educationComponentArr.map((item, index) => (
                    <EducationHistorySections
                      key={index}
                      index={index}
                      counter={educationCounter}
                      reference={provideReference}
                      setFormValues={setFormValues}
                      setHistory={setProfessionalHistoryDates}
                      handleFilesData={handleFilesData}
                      values={values}
                      fieldError={fieldError}
                      dateSetting={props.setting.date_format}
                      isEdit={isEdit}
                    />
                  ))}
                </>
                <>
                  {provideReference && eduGap && (
                    <JustifyGapComponent
                      setFormValues={setFormValues}
                      handleGapReason={handleGapReason}
                      openModal={handleReferenceModal}
                      eduGap={eduGap}
                      gapReason={gapReason}
                      fileNameGap={`emp_gap_proof_${gapCounter}`}
                      handleData={handleGapProof}
                    />
                  )}
                </>
              </React.Fragment>
            )}
            {filterTag === 'OTHERS' && (
              <ProfessionalHistoryOthersSection
                values={values}
                setFormValues={setFormValues}
                setHistory={setProfessionalHistoryDates}
                handleReferenceModal={handleReferenceModal}
                fieldError={fieldError}
                handleFilesData={handleFilesData}
                dateSetting={props.setting.date_format}
                isEdit={isEdit}
              />
            )}
          </div>
        </div>

        {filterTag === 'EMPLOYMENT' && (
          <div className="form-group add_employment_btn_div">
            <div className="row test_row">
              <div className="col test_col">
                <Button
                  variant="contained"
                  id="add_more_employment_section_btn"
                  onClick={() => {
                    extendFormCheckDatesValidations('emp_start_date', 'emp_end_date', 'EMPLOYMENT');
                  }}
                  className="btn button_color add_more_employment_section"
                >
                  Add Employment
                </Button>
              </div>
            </div>
          </div>
        )}
        {filterTag === 'EDUCATION' && (
          <div className="form-group add_employment_btn_div">
            <div className="row test_row">
              <div className="col test_col">
                <Button
                  variant="contained"
                  id="add_more_employment_section_btn"
                  onClick={() => {
                    extendFormCheckDatesValidations('edu_start_date', 'edu_end_date', 'EDUCATION');
                  }}
                  className="btn button_color add_more_employment_section"
                >
                  Add More Education
                </Button>
              </div>
            </div>
          </div>
        )}
        <br />
        <ActionButtons backButton={true} buttonText={true} submit={submitProfessionalHistory} />
        <ReferenceModal
          open={openReferenceModal}
          counter={refCounter}
          values={values}
          setOpen={setOpenReferenceModal}
          setProvideReference={setProvideReference}
          identifyReference={otherGap ? 'other' : eduGap ? 'edu' : 'emp'}
          setValues={setValues}
          setCounter={setRefCounter}
          settings={props.settings.country_of_operations}
        />
      </fieldset>
    </MuiPickersUtilsProvider>
  );
};

const JustifyGapComponent = props => {
  const { handleGapReason, eduGap, gapReason, setFormValues, handleData, openModal, fileNameGap } = props;
  const [fileName, setFileName] = useState('');
  const [provideGapReason, setProvideGapReason] = useState(false);
  const [gapReasonError, setGapReasonError] = useState(false);
  const [val, setVal] = useState(false);
  const [gapProofError, setGapProofError] = useState(false);

  const setValues = event => {
    setVal(true);
    setGapProofError(false);
    setFileName(event.target.files[0].name);
    handleData(event);
  };

  const handleGapReasonValue = event => {
    setProvideGapReason(true);
    setGapReasonError(false);
    handleGapReason(event);
  };

  const validation = () => {
    if (val && provideGapReason) {
      setGapProofError(false);
      setGapReasonError(false);
      openModal();
    } else if (provideGapReason) {
      setGapProofError(true);
      setGapReasonError(false);
    } else {
      setGapProofError(false);
      setGapReasonError(true);
    }
  };
  return (
    <React.Fragment>
      <>
        {eduGap ? (
          <div style={{ fontFamily: 'Roboto', fontSize: '13px', color: 'black', marginTop: '10px' }}>
            Please justify your gap as it is <span style={{ color: 'red' }}> greater than 31 days </span> bettween education
          </div>
        ) : (
          <div style={{ fontFamily: 'Roboto', fontSize: '13px', color: 'black', marginTop: '10px' }}>
            Please justify your gap as your <i>`Not Working`</i> period is <span style={{ color: 'red' }}> greater than 31 days </span>
          </div>
        )}
      </>
      <div className="professional-history-gap-check-boxes">
        <FormGroup style={{ flexDirection: 'row' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={gapReason.job}
                onChange={handleGapReasonValue}
                className={gapReason.job ? 'checked' : 'notChecked'}
                name="job"
                size="small"
                style={{
                  color: gapReason.job ? '#3BBDA9' : 'rgba(0, 0, 0, 0.54)',
                }}
              />
            }
            style={{ color: gapReason.job ? '#3BBDA9' : 'black' }}
            label="I was looking for a job"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={gapReason.holidays}
                onChange={handleGapReasonValue}
                className={gapReason.holidays ? 'checked' : 'notChecked'}
                name="holidays"
                size="small"
                style={{
                  color: gapReason.holidays ? '#3BBDA9' : 'rgba(0, 0, 0, 0.54)',
                }}
              />
            }
            style={{ color: gapReason.holidays ? '#3BBDA9' : 'black', marginLeft: '5px' }}
            label="I was on holidays"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={gapReason.health}
                onChange={handleGapReasonValue}
                className={gapReason.health ? 'checked' : 'notChecked'}
                name="health"
                size="small"
                style={{
                  color: gapReason.health ? '#3BBDA9' : 'rgba(0, 0, 0, 0.54)',
                }}
              />
            }
            style={{ color: gapReason.health ? '#3BBDA9' : 'black', marginLeft: '5px' }}
            label="My health was not good"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={gapReason.others}
                onChange={handleGapReasonValue}
                className={gapReason.others ? 'checked' : 'notChecked'}
                name="others"
                size="small"
                style={{
                  color: gapReason.others ? '#3BBDA9' : 'rgba(0, 0, 0, 0.54)',
                }}
              />
            }
            style={{ color: gapReason.others ? '#3BBDA9' : 'black', marginLeft: '5px' }}
            label="Other"
          />
        </FormGroup>
      </div>
      <>
        {gapReason.others && (
          <Grid item xs={12} className="professional-history-justify-gap-others-reason-explanation">
            <TextareaAutosize
              aria-label="empty textarea"
              className="form-control history-others-reason-extra-note"
              name="professional-history-justify-gap-others-reason-explanation"
              onChange={setFormValues}
            />
          </Grid>
        )}
      </>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>
            <input type="file" name={fileNameGap} onChange={setValues} id="gap-button-file" style={{ display: 'none' }} />
            <label htmlFor="gap-button-file">
              {!gapReasonError ? (
                <Button className="professional-history-add-reference-proof-buttons" component="span">
                  Attach Proof
                </Button>
              ) : (
                <Button className="professional-history-add-reference-proof-buttons" disabled component="span">
                  Attach Proof
                </Button>
              )}
            </label>
          </div>
          {gapProofError && <label style={{ color: 'red', fontSize: 'small' }}>Required</label>}
          {val && <label style={{ fontSize: 'small' }}>{fileName}</label>}
        </div>
        <Button className="professional-history-add-reference-proof-buttons add-personal-reference-button" onClick={validation}>
          {' '}
          Add Reference{' '}
        </Button>
      </div>
      {gapReasonError && (
        <label style={{ color: 'red', fontSize: 'small' }}>
          Please choose an option and provide proof to justify gap in order to proceed further.
        </label>
      )}
    </React.Fragment>
  );
};
