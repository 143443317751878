import React, { useState, useEffect } from 'react';
import { Translate, translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Button, Grid, FormHelperText, FormControl, TextField } from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';
import '../password.scss';
import { IRootState } from 'app/shared/reducers';
import { getSession } from 'app/shared/reducers/authentication';
import PasswordStrengthBar from 'app/shared/layout/password/password-strength-bar';
import { resetForgottenPassword, reset } from '../password.reducer';
import { Storage } from 'react-jhipster';
import { Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { ToastContainer, toast } from 'react-toastify';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { encryptData } from '../../../../modules/login/encryption';

export interface UserDispatchProps {
  setPassword: (value) => void;
  reset: () => void;
  getSession: () => void;
}

export interface IResetPasswordProps extends StateProps, UserDispatchProps, RouteComponentProps<{ url: string }> {}

export const ResetForgotPasswordPage = (props: IResetPasswordProps) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState({ name_error: false, name_helper: '' });
  const [spaceError, setSpaceError] = useState({});
  const [value, setValue] = useState(null);

  useEffect(() => {
    props.reset();
    props.getSession();
    return () => {
      props.reset();
    };
  }, []);

  useEffect(() => {
    const uri = window.location.href;
    const parts = uri.split('?');
    const user_uid = parts[1];
    setValue(oldValues => ({ ...oldValues, ['uid']: user_uid }));
  }, []);

  const checkEmptyFields = () => {
    if (value['password'] === undefined) {
      setError(oldValues => ({ ...oldValues, ['password_error']: true }));
    }
    if (value['confirmPassword'] === undefined) {
      setError(oldValues => ({ ...oldValues, ['confirmPassword_error']: true }));
    }
  };

  const checkFormValidations = () => {
    const result =
      !error['password_error'] &&
      !error['confirmPassword_error'] &&
      value['password'] !== undefined &&
      value['confirmPassword'] !== undefined;
    return result;
  };

  const handlePasswordFormValues = e => {
    if (e.target.name !== null || e.target.name !== undefined) {
      setError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: false }));
    }
    if (e.target.name === 'password') {
      setPassword(e.target.value);
    }
    if (e.target.name === 'confirmPassword') {
      if (e.target.value !== value['password']) {
        setError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: true }));
        setError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_helper']: 'Passwords do not match' }));
      } else {
        setError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: false }));
        setError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_helper']: '' }));
      }
    }
    setValue(oldValues => ({ ...oldValues, [e.target.name]: e.target.value }));
  };

  const handleValidSubmit = async event => {
    event.preventDefault();
    if (CheckPassword(value['password']) && checkFormValidations()) {
      value['password'] = encryptData(value['password']);
      value['confirmPassword'] = encryptData(value['confirmPassword']);
      const result = await resetForgottenPassword(value);
      if (result.status === 200) {
        toast.success('Password Successfully Saved !');
        props.history.push('/login');
      } else {
        toast.error('Password Setting Failed');
      }
    } else {
      checkEmptyFields();
    }
  };

  const updatePassword = event => setPassword(event.target.value);

  const CheckPassword = value => {
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,50}$/;
    const whitespaces = /^\S*$/;
    if (value.match(passwordRegex)) {
      if (value.match(whitespaces)) {
        setError(oldError => ({ ...oldError, ['password_error']: false }));
        setError(oldError => ({ ...oldError, ['password_helper']: '' }));
        return true;
      } else {
        setError(oldError => ({ ...oldError, ['password_error']: true }));
        setError(oldError => ({ ...oldError, ['password_helper']: 'Spaces are not allowed ! ' }));
        return false;
      }
    } else {
      setError(oldError => ({ ...oldError, ['password_error']: true }));
      setError(oldError => ({
        ...oldError,
        ['password_helper']: 'Your password is required to have upper case letter, lower case letter, number and a special character.',
      }));
      return false;
    }
  };

  const [values, setValues] = React.useState({
    showPassword: false,
  });

  const handleShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  return (
    <React.Fragment>
      <div className="password-full-container">
        <div style={{ display: 'flex' }}>
          <Paper elevation={2} square className="createNewPasswordContainerPaper">
            <div style={{ width: '100%' }}>
              <p className="forgot-password-title">Create New Password</p>
              <p className="forgot-password-heading-2">Please use a unique password</p>
            </div>
            <form className="password-form">
              <Grid
                container
                item
                xs={10}
                md={12}
                lg={12}
                spacing={2}
                direction="row"
                alignItems="center"
                justifyContent="center"
                className="reset-forgot-password-fields"
              >
                <FormControl variant="outlined" className="create-new-password-fields" style={{ marginTop: '60px' }}>
                  <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={values.showPassword ? 'text' : 'password'}
                    name="password"
                    onChange={handlePasswordFormValues}
                    error={error['password_error']}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={handleShowPassword} edge="end">
                          {values.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />
                  {!!error['password_helper'] && (
                    <FormHelperText error id="error-name_helper">
                      {error['password_helper']}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid
                container
                item
                xs={10}
                md={12}
                lg={12}
                spacing={2}
                direction="row"
                alignItems="center"
                justifyContent="center"
                style={{ marginTop: '30px' }}
                className="reset-forgot-password-fields"
              >
                <FormControl className="create-new-password-fields">
                  <TextField
                    name="confirmPassword"
                    className="password-confirmpassword"
                    variant="outlined"
                    label="Confirm Password"
                    placeholder="Confirm Password"
                    onChange={handlePasswordFormValues}
                    error={error['confirmPassword_error']}
                    helperText={error['confirmPassword_helper']}
                    type="password"
                    required
                    inputProps={{
                      minLength: 6,
                      maxLength: 50,
                      match: 'newPassword',
                    }}
                    data-cy="confirmPassword"
                  />
                </FormControl>
              </Grid>

              <Grid
                container
                item
                xs={10}
                md={12}
                lg={12}
                spacing={2}
                direction="row"
                alignItems="center"
                justifyContent="center"
                style={{ marginTop: '20px' }}
                className="reset-forgot-password-button"
              >
                <FormControl className="create-new-password-submit-button">
                  <Button className="passwordSaveButton" type="submit" data-cy="submit" onClick={handleValidSubmit}>
                    Set Password
                  </Button>
                </FormControl>
              </Grid>
              <br />
            </form>
          </Paper>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ authentication }: IRootState) => ({});

const mapDispatchToProps = { getSession, reset };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ResetForgotPasswordPage);
