import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

export const ExportPDF = (headers, Title, Data, Filename) => {
  const unit = 'mm';
  const size = [500, 700];
  const orientation = 'portrait';
  const img = 'content/images/ReportPDF.png';

  const doc = new jsPDF(orientation, unit, size);
  const header = headers;
  const title = Title;
  const data = Data;

  doc.addImage(img, 'PNG', 15, 10, 470, 25);

  doc.addFont('content/fonts/Roboto-Regular.ttf', 'Roboto', 'normal');
  doc.setFont('Roboto');
  doc.setFontSize(30).text(title, 25, 25);

  autoTable(doc, {
    startY: 50,
    head: header,
    body: data,
    styles: { halign: 'center' },
    headStyles: { fillColor: [149, 119, 229], textColor: [255, 255, 255], fontSize: 14 },
    bodyStyles: { fontSize: 14 },
    tableLineColor: [249, 238, 248],
    tableLineWidth: 0.1,
  });

  doc.save(Filename);
};
