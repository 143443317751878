export const monthPrefix = passedMonth => {
  switch (passedMonth.toString()) {
    case '1':
      return 'Jan';
    case '2':
      return 'Feb';
    case '3':
      return 'Mar';
    case '4':
      return 'Apr';
    case '5':
      return 'May';
    case '6':
      return 'Jun';
    case '7':
      return 'Jul';
    case '8':
      return 'Aug';
    case '9':
      return 'Sep';
    case '10':
      return 'Oct';
    case '11':
      return 'Nov';
    case '12':
      return 'Dec';
    default:
      return 'Month';
  }
};

export const occurence = [
  {
    value: 'Day',
    label: 'Day',
  },
  {
    value: 'Week',
    label: 'Week',
  },
  // {
  //   value: 'Month',
  //   label: 'Month',
  // },
];
export const pattern = [
  {
    value: 0,
    label: 'Does not repeat',
  },
  {
    value: 1,
    label: 'Every weekday (Mon-Fri)',
  },
  {
    value: 2,
    label: 'Daily',
  },
  {
    value: 3,
    label: 'Weekly',
  },
  {
    value: 4,
    label: 'Custom',
  },
];
export const end = [
  {
    value: 'Never',
    label: 'Never',
  },
  {
    value: 'Until',
    label: 'Until',
  },
];
export const weeks = [
  {
    value: 'Mon',
    active: false,
    id: 1,
  },
  {
    value: 'Tue',
    active: false,
    id: 2,
  },
  {
    value: 'Wed',
    active: false,
    id: 3,
  },
  {
    value: 'Thu',
    active: false,
    id: 4,
  },
  {
    value: 'Fri',
    active: false,
    id: 5,
  },
  {
    value: 'Sat',
    active: false,
    id: 6,
  },
  {
    value: 'Sun',
    active: false,
    id: 7,
  },
];

export const tableRow = [
  {
    date: 'Mon, 6 Nov 2021',
    siteName: 'Moto Motorway(ABC 123)',
    role: 'Security Officer',
    time: '08:00 - 16:00',
    hours: '8 hrs',
  },
  {
    date: 'Mon, 12 Nov 2021',
    siteName: 'Moto Sports(XYZ 123)',
    role: 'Security Head',
    time: '12:00 - 20:00',
    hours: '8 hrs',
  },
];
