import React from 'react';
import { useState } from 'react';
import { Button, FormGroup, FormControl, Grid, Checkbox } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Modal from '@material-ui/core/Modal';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import './calendar.scss';
import TextField from '@material-ui/core/TextField';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import { deleteEntity } from '../../entities/person/person.reducer';

export interface IModalComponent {
  showDeleteModal: boolean;
  deleteSeries: (value) => void;
  deleteSchedule: (value) => void;
  setShowDeleteModal: (value: boolean) => void;
  isRepeatShift: boolean;
}

export const DeleteModal = (props: IModalComponent) => {
  const [occurrenceChecked, setOccurrenceChecked] = useState(false);
  const [seriesChecked, setSeriesChecked] = useState(false);
  const [reason, setReason] = useState([]);
  const [reasonError, setReasonError] = useState(false);

  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const handleDeleteOccurrenceChange = () => {
    setOccurrenceChecked(!occurrenceChecked);
    if (seriesChecked === true) {
      setSeriesChecked(!seriesChecked);
    }
  };

  const handleDeleteSeriesChange = () => {
    setSeriesChecked(!seriesChecked);
    if (occurrenceChecked === true) {
      setOccurrenceChecked(!occurrenceChecked);
    }
  };

  const { showDeleteModal, setShowDeleteModal, deleteSchedule, deleteSeries, isRepeatShift } = props;

  const handleDelete = () => {
    if (reason.length !== 0) {
      setShowDeleteModal(false);
      if (occurrenceChecked === true) {
        setShowDeleteModal(false);
        deleteSchedule(reason);
        setReason([]);
      } else if (seriesChecked === true) {
        deleteSeries(reason);
        setReason([]);
      } else deleteSchedule(reason);
      setReason([]);
    } else {
      setReasonError(true);
    }
  };

  const addShiftDeletionReason = event => {
    setReasonError(false);
    setReason(oldValues => ({ ...oldValues, delete_reason: event.target.value }));
  };

  return (
    <Modal open={showDeleteModal} onClose={handleCloseDeleteModal} className="create-modal">
      <div className="delete-shift-modal-paper">
        <h2 className="shift-modal-title">Delete Shift</h2>

        <p>Are you sure you want to delete this shift?</p>

        <Divider className="calendar-new-shift-modal-divider" />

        {isRepeatShift === true ? (
          <>
            <Grid item className="delete-shift-modal-input" xs={6} lg={6} md={6}>
              <FormControl style={{ minWidth: 220 }} className="shift-missed-buttons" variant="outlined" size="small">
                <FormControlLabel
                  id={occurrenceChecked === true ? 'checked' : 'unchecked'}
                  className="shift-missed-checkbox-buttons"
                  name="alertAdmin"
                  label="Delete Current Shift"
                  labelPlacement="start"
                  control={
                    <Checkbox
                      name={'Delete Current Shift'}
                      icon={<CircleCheckedFilled />}
                      checkedIcon={<CircleCheckedFilled />}
                      onChange={handleDeleteOccurrenceChange}
                      defaultChecked={occurrenceChecked}
                    />
                  }
                  style={{
                    color: occurrenceChecked === true ? 'white' : 'black',
                    background:
                      occurrenceChecked === true
                        ? 'linear-gradient(to bottom, #efb5ee, #d488d3)'
                        : 'linear-gradient(to bottom, #FCFCFC, #EFEFEF)',
                    border: occurrenceChecked === true ? '1px solid #E5A5E4' : '1px solid #E9E9E9',
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item className="delete-shift-modal-input" xs={6} lg={6} md={6}>
              <FormControl style={{ minWidth: 220 }} className="shift-missed-buttons" variant="outlined" size="small">
                <FormControlLabel
                  id={seriesChecked === true ? 'checked' : 'unchecked'}
                  className="shift-missed-checkbox-buttons"
                  name="alertAdmin"
                  label="Delete Entire Series"
                  labelPlacement="start"
                  control={
                    <Checkbox
                      name={'Delete Entire Series Shift'}
                      icon={<CircleCheckedFilled />}
                      checkedIcon={<CircleCheckedFilled />}
                      onChange={handleDeleteSeriesChange}
                      defaultChecked={seriesChecked}
                    />
                  }
                  style={{
                    color: seriesChecked === true ? 'white' : 'black',
                    background:
                      seriesChecked === true
                        ? 'linear-gradient(to bottom, #efb5ee, #d488d3)'
                        : 'linear-gradient(to bottom, #FCFCFC, #EFEFEF)',
                    border: seriesChecked === true ? '1px solid #E5A5E4' : '1px solid #E9E9E9',
                  }}
                />
              </FormControl>
            </Grid>

            <Divider className="calendar-new-shift-modal-divider" style={{ marginTop: '30px', marginBottom: '30px' }} />
          </>
        ) : null}

        <Grid item className="delete-shift-modal-input" xs={12} lg={12}>
          <TextField
            variant="outlined"
            name="delete_reason"
            size="small"
            id="shift-chargerate"
            label="Add Reason"
            required
            multiline
            rows={3}
            className="delete-shift-reason-textField"
            onChange={() => addShiftDeletionReason(event)}
            error={reasonError}
          />
        </Grid>

        <FormControl className="modal-forms" style={{ marginTop: '20px' }}>
          <FormGroup row={true}>
            <Grid item xs={3} lg={4}>
              <Button variant="contained" className="delete-modal-button-cancel" onClick={handleCloseDeleteModal}>
                Cancel
              </Button>
            </Grid>

            <Grid item xs={3} lg={4}>
              <Button
                variant="contained"
                className="delete-modal-button-save"
                onClick={() => {
                  handleDelete();
                }}
              >
                Delete
              </Button>
            </Grid>
          </FormGroup>
        </FormControl>
      </div>
    </Modal>
  );
};

export default DeleteModal;
