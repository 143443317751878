import { Button, Divider } from '@material-ui/core';
import React from 'react';
import DesignationDeleteDialog from './designation-delete-dialog';

export default function Designation_Edit_Del_Btn({ isEdit, setIsEdit, props }) {
  const [isDelete, setIsDelete] = React.useState(false);
  return (
    <div className="clientEditBtnContainer">
      <div className="btnDiv">
        <Button
          className="client_edit_btn"
          variant="contained"
          startIcon={<img src="content/icons/Edit.png" />}
          onClick={() => setIsEdit(true)}
        >
          Edit
        </Button>
        <Button
          className="client_del_btn"
          variant="contained"
          onClick={() => setIsDelete(true)}
          startIcon={<img src="content/icons/Icon awesome-trash-alt.png" />}
        >
          Delete
        </Button>
      </div>
      <Divider style={{ margin: '4% 0' }} />
      {isDelete === true && <DesignationDeleteDialog props={props} isDelete={isDelete} setIsDelete={setIsDelete} />}
    </div>
  );
}
