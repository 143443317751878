import { useTable, useExpanded } from 'react-table';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { CurrencyChecker } from '../../../../utils/TimeFormatAndTimezone';

export const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;
    width: 100%;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

function SubRows({ row, rowProps, data, loading, currency }) {
  const currencySign = CurrencyChecker(currency);
  return (
    <>
      {data && data.length > 0
        ? data.map((x, i) => {
            return (
              <tr {...rowProps} key={`${rowProps.key}-expanded-${i}`} className="timesheetInnerRows">
                {row.cells.map((cell, i) => {
                  return (
                    <td {...cell.getCellProps()} key={i} className="timesheetInnerRowCells">
                      {cell.render('Header') === 'Charge Rate' ? (cell.value === '-' || cell.value === null ? '' : currencySign) : ''}
                      {cell.render('Header') === 'Pay Rate' ? (cell.value === '-' || cell.value === null ? '' : currencySign) : ''}
                      {cell.render('Header') === 'Pay Scheduled' ? (cell.value === '-' || cell.value === null ? '' : currencySign) : ''}
                      {cell.render('Header') === 'Pay Actual' ? (cell.value === '-' || cell.value === null ? '' : currencySign) : ''}
                      {cell.render('Header') === 'Expenses' ? (cell.value === '-' || cell.value === null ? '' : currencySign) : ''}
                      {cell.render('Header') === 'Profit' ? (cell.value === '-' || cell.value === null ? '' : currencySign) : ''}
                      {cell.render(cell.column.SubCell ? 'SubCell' : 'Cell', {
                        value: cell.column.accessor && cell.column.accessor(x, i),
                        row: { ...row, original: x },
                      })}
                    </td>
                  );
                })}
              </tr>
            );
          })
        : !loading && <div className="no-record-warning">No Records found</div>}
    </>
  );
}

export function SubRowAsync({ row, rowProps, loading, currency }) {
  const [data, setData] = React.useState([]);
  const detailData = [];

  row.original.PAYLOAD.map(val => {
    const data = { ...val };
    detailData.push(data);
  });

  React.useEffect(() => {
    setData(detailData);
  }, []);

  return <SubRows row={row} rowProps={rowProps} data={data} loading={loading} currency={currency} />;
}

function GetSpecificCoulmnsForAgggregateReports({ subcontractorEntity, headerGroups, loading, currency }) {
  const currencySign = CurrencyChecker(currency);
  return (
    <>
      {headerGroups && headerGroups.length > 0
        ? headerGroups.map(headerGroup => {
            return headerGroup.headers.map((column, i) => {
              return (
                <>
                  <td {...column.getHeaderProps()} key={i} className="timesheetTotalTextDesignAndFont timesheetTotals">
                    {column.render('Header') === 'Hours Scheduled'
                      ? subcontractorEntity && subcontractorEntity.length > 0
                        ? subcontractorEntity[0]['aggregateReport'].aggregateScheduledTime
                        : 0
                      : ''}
                    {column.render('Header') === 'Hours Actual'
                      ? subcontractorEntity && subcontractorEntity.length > 0
                        ? subcontractorEntity[0]['aggregateReport'].aggregateActualTime
                        : 0
                      : ''}
                    {column.render('Header') === 'Pay Scheduled'
                      ? subcontractorEntity && subcontractorEntity.length > 0
                        ? currencySign + '' + subcontractorEntity[0]['aggregateReport'].aggregateScheduledPay
                        : 0
                      : ''}
                    {column.render('Header') === 'Pay Actual'
                      ? subcontractorEntity && subcontractorEntity.length > 0
                        ? currencySign + '' + subcontractorEntity[0]['aggregateReport'].aggregateActualPay
                        : 0
                      : ''}
                    {column.render('Header') === 'Profit' ? currencySign + '' + 0 : ''}
                  </td>
                </>
              );
            });
          })
        : !loading && <div className="no-record-warning">No Totals found</div>}
    </>
  );
}

export function Table({ columns: userColumns, data, renderRowSubComponent, subcontractorEntity, currency, loading }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { expanded },
  } = useTable(
    {
      columns: userColumns,
      data,
    },
    useExpanded
  );
  const currencySign = CurrencyChecker(currency);
  return (
    <>
      <table {...getTableProps()} className="timesheetReactTable">
        <thead className="globalTableHeaderGradientColor">
          {headerGroups && headerGroups.length > 0
            ? headerGroups.map((headerGroup, i) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                  {headerGroup.headers.map((column, i) => (
                    <th {...column.getHeaderProps()} key={i} className="timesheetHeaderCells">
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))
            : !loading && <div className="no-record-warning">No Header found</div>}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows && rows.length > 0
            ? rows.map((row, i) => {
                prepareRow(row);
                const rowProps = row.getRowProps();
                return (
                  <React.Fragment key={rowProps.key}>
                    <tr {...rowProps} key={i} className="timesheetOuterRows">
                      {row.cells.map((cell, i) => {
                        return (
                          <td {...cell.getCellProps()} key={i} className="timesheetOuterRowCells">
                            {cell.render('Header') === 'Charge Rate' ? (cell.value === '-' || cell.value === null ? '' : currencySign) : ''}
                            {cell.render('Header') === 'Pay Rate' ? (cell.value === '-' || cell.value === null ? '' : currencySign) : ''}
                            {cell.render('Header') === 'Pay Scheduled'
                              ? cell.value === '-' || cell.value === null
                                ? ''
                                : currencySign
                              : ''}
                            {cell.render('Header') === 'Pay Actual' ? (cell.value === '-' || cell.value === null ? '' : currencySign) : ''}
                            {cell.render('Header') === 'Expenses' ? (cell.value === '-' || cell.value === null ? '' : currencySign) : ''}
                            {cell.render('Header') === 'Profit' ? (cell.value === '-' || cell.value === null ? '' : currencySign) : ''}
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                    {row.isExpanded && renderRowSubComponent({ row, rowProps, loading, currency })}
                  </React.Fragment>
                );
              })
            : !loading && (
                <tr style={{ borderBottom: 'unset' }}>
                  <div className="no-record-warning">No Records found</div>
                </tr>
              )}

          <GetSpecificCoulmnsForAgggregateReports
            subcontractorEntity={subcontractorEntity}
            headerGroups={headerGroups}
            loading={loading}
            currency={currency}
          />
        </tbody>
      </table>
    </>
  );
}
