import React from "react";
import './spinner.css';

const Spinner = () => {
  return (
  <div className="spinner-overlay" style={{ opacity: 0.9 }}>
    <div className="spinner-container" />
  </div>
  );
};

export default Spinner;