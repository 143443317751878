import { ICountryAdminUnitType } from 'app/shared/model/country-admin-unit-type.model';

export interface ICountryAdminUnit {
  id?: number;
  name?: string | null;
  parent?: ICountryAdminUnit | null;
  countryAdminUnitType?: ICountryAdminUnitType | null;
}

export const defaultValue: Readonly<ICountryAdminUnit> = {};
