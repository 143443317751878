import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { IRootState } from 'app/shared/reducers';
import { Box, FormControl, Grid, Paper, TextField } from '@material-ui/core';
import '../../sub-contractor/subcontractor.scss';
import Divider from '@material-ui/core/Divider';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Checkbox } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { getCity, getCountry, getOrganizationWithSubContractors, reset as resetCountry } from '../../sub-contractor/subcontractor.reducer';
import { validationMessageBeforeSubmit, validationMessageOnChange } from '../../../../utils/validationMessages';
import { CurrencyChecker } from '../../../../utils/TimeFormatAndTimezone';
import {
  createEntity,
  getEntity,
  getAllCountry,
  getRoles,
  reset,
  setPageNumber,
  updateEntity,
  verifyEmail,
  getEntities,
} from '../person.reducer';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { getDateFormat } from 'app/shared/util/calender-utils';
import CustomToolbarComponent from '../../service-area/CustomToolbarComponent';
import { getEntities as positionEntities, reset as resetPos } from '../../designation/designation.reducer';
import { getEntities as departmentEntities, reset as resetDep } from '../../department/department.reducer';
import { useLocation, useHistory } from 'react-router-dom';
import debounce from 'lodash.debounce';

export interface IPersonProfileProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export const PersonCompanyInfo = props => {
  const [showEditPage, setShowEditPage] = useState(!props.match.params || !props.match.params.id ? true : false);
  const [subcontractorValue, setSubcontractorValue] = useState(props.subcontractors);
  const { personEntity, loading, match, subcontractors } = props;
  const [fieldValues, setFieldValues] = useState(!props.match.params || !props.match.params.id ? props.profileData : personEntity);
  const [isNew] = useState(!props.match.params || !props.match.params.id);
  const [rolesList, setRolesList] = useState([]);
  const [fieldError, setFieldError] = useState({
    first_name_error: false,
    last_name_error: false,
    email_error: false,
    email_helper: '',
    phoneNumber_error: false,
    phoneNumber_helper: '',
    siaLicenceNumber_error: false,
    siaLicenceNumber_helper: '',
    siaExpiryDate_error: false,
    siaExpiryDate_helper: '',
    role_error: false,
    position_error: false,
    department_error: false,
    address_error: false,
    address_helper: '',
    identification_number_error: false,
    identification_number_helper: '',
    payRate_error: false,
    company_error: false,
    payRateStart_error: false,
    payRateStart_helper: '',
    payRateEnd_error: false,
    payRateEnd_helper: '',
  });
  const [positionsList, setPositionsList] = useState(props.designationList);
  const [showVetting, setShowVetting] = useState(false);
  const [processVetting, setProcessVetting] = useState(false);
  const [departmentsList, setDepartmentsList] = useState(props.departmentList);
  const [currency, setCurrency] = useState(props.settings.currency);
  const [company, setCompanyValue] = React.useState<any[]>([]);
  const [pay_rate_effective_date, setPayRateEnd] = useState(null);
  const [pay_rate_start_date, setPayRateStart] = useState(null);
  const [personFiles, setPersonFiles] = useState([]);
  const [records, setRecords] = useState(props.personList);
  useEffect(() => {
    props.getOrganizationWithSubContractors();
    props.getRoles();
  }, []);

  useEffect(() => {
    setSubcontractorValue(props.subcontractors);
  }, [props.subcontractors]);

  useEffect(() => {
    setRolesList(props.rolesList);
  }, [props.rolesList]);

  useEffect(() => {
    setDepartmentsList(props.departmentList);
  }, [props.departmentList]);

  useEffect(() => {
    setCurrency(props.settings.currency);
  }, [props.settings]);

  useEffect(() => {
    setPositionsList(props.designationList);
  }, [props.designationList]);

  useEffect(() => {
    if (!isNew) {
      props.getEntity(props.match.params.id);
    }
    props.resetCountry();
    props.resetPos();
    props.resetDep();
    props.getOrganizationWithSubContractors();
    props.getRoles();
    props.positionEntities(-1);
    props.departmentEntities(-1);
    props.getEntities(-1, '');
  }, []);

  useEffect(() => {
    if (isNew) {
      props.setProfileData(fieldValues);
    }
  }, [fieldValues]);

  useEffect(() => {
    setRecords(props.personList);
  }, [props.personList]);

  useEffect(() => {
    if (!isNew) {
      if (personEntity && Object.keys(personEntity).length !== 0) {
        if (personEntity.subcontractors && personEntity.subcontractors.length === 0) {
          setCompanyValue(addAttributesToCompanies(personEntity.organization, 'ORGANIZATION'));
        } else if (personEntity.organization === null) {
          setCompanyValue(addAttributesToCompanies(personEntity.subcontractors, 'SUBCONTRACTOR'));
        } else if (company.length !== 0) {
          setCompanyValue([
            ...addAttributesToCompanies(personEntity.organization, 'ORGANIZATION'),
            ...addAttributesToCompanies(personEntity.subcontractors, 'SUBCONTRACTOR'),
          ]);
        }
      }
    }
  }, [personEntity]);

  useEffect(() => {
    if (isNew) {
      if (props.profileData && Object.keys(props.profileData).length !== 0) {
        if (props.profileData.subcontractors && props.profileData.subcontractors.length === 0) {
          setCompanyValue(addAttributesToCompanies(props.profileData.organization, 'ORGANIZATION'));
        } else if (props.profileData.organization === null) {
          setCompanyValue(addAttributesToCompanies(props.profileData.subcontractors, 'SUBCONTRACTOR'));
        } else if (company.length !== 0) {
          setCompanyValue([
            ...addAttributesToCompanies(props.profileData.organization, 'ORGANIZATION'),
            ...addAttributesToCompanies(props.profileData.subcontractors, 'SUBCONTRACTOR'),
          ]);
        }
      }
    }
  }, []);

  const addAttributesToCompanies = (company, type) => {
    if (type === 'ORGANIZATION') {
      return [{ ...company, id: 0, combinedId: 0, status: 'ORGANIZATION' }];
    }
    if (type === 'SUBCONTRACTOR' && company !== undefined) {
      company.forEach(element => {
        element.combinedId = element.id;
        element.status = 'SUBCONTRACTOR';
      });
      return company;
    }
  };

  const profilePageHandler = () => {
    setFieldValues(personEntity);
    setShowEditPage(false);
  };

  const handleGoBack = () => {
    props.setProfileData(fieldValues);
    props.setPage(1);
  };

  const editPageHandler = () => {
    setShowEditPage(true);
  };

  const items = props.subcontractors.map(company => ({
    id: company.combinedId,
    name: company.name,
  }));

  const setNewValues = e => {
    setFieldValues(oldValues => ({ ...oldValues, [e.target.name]: e.target.value }));

    if (e.target.name === 'identification_number' && !/^(?! )[a-zA-Z0-9\s(),.-]*$/.test(e.target.value)) {
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: true }));
      setFieldError(oldFieldError => ({
        ...oldFieldError,
        [e.target.name + '_helper']: validationMessageOnChange.number,
      }));
    } else {
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: false, [e.target.name + '_helper']: '' }));
    }
  };

  const handleRoleData = (_, e) => {
    if (isNew) {
      setFieldValues(oldValues => ({ ...oldValues, ['role']: e }));
      if (fieldValues['role'] !== null || fieldValues['role'] !== undefined) {
        setFieldError(oldFieldError => ({ ...oldFieldError, ['role_error']: false }));
      }
    } else {
      setFieldValues(oldValues => ({ ...oldValues, ['role']: [e] }));
      if (fieldValues['role'] !== null || fieldValues['role'] !== undefined) {
        setFieldError(oldFieldError => ({ ...oldFieldError, ['role_error']: false }));
      }
    }
  };

  const handlePositionData = (_, e) => {
    if (e) {
      if (e.is_vettable) {
        setShowVetting(true);
        setProcessVetting(true);
      } else {
        setShowVetting(false);
        setProcessVetting(false);
      }
      setFieldValues(oldValues => ({ ...oldValues, ['designation']: e }));
      if (fieldValues['designation'] !== null || fieldValues['designation'] !== undefined) {
        setFieldError(oldFieldError => ({ ...oldFieldError, ['position_error']: false }));
      }
    } else {
      setFieldValues(oldValues => ({ ...oldValues, ['designation']: null }));
    }
  };

  const handleDepartmentData = (_, e) => {
    if (e) {
      setFieldValues(oldValues => ({ ...oldValues, ['department']: e }));
      setFieldError(oldFieldError => ({ ...oldFieldError, ['department_error']: false }));
    } else {
      setFieldValues(oldValues => ({ ...oldValues, ['department']: null }));
    }
  };

  const setFormValues = e => {
    setNewValues(e);
    return ({ target: { value } }) => {
      setFieldValues(oldFieldValues => ({ ...oldFieldValues, [e.target.name]: value }));
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: false }));
    };
  };
  const handleEmploymentDetail = e => {
    setFieldError(oldFieldError => ({ ...oldFieldError, ['payRate_error']: false }));
    if (isNew) {
      setFieldValues(oldFieldValues => ({
        ...oldFieldValues,
        ['employment_detail']: { ...oldFieldValues.employment_detail, pay_rate: e.target.value },
      }));
    } else {
      setFieldValues(oldFieldValues => ({
        ...oldFieldValues,
        employment_detail: {
          ...oldFieldValues.employment_detail,
          id: personEntity ? (personEntity.employment_detail ? personEntity.employment_detail.id : null) : null,
          pay_rate: e.target.value,
        },
      }));
    }
  };

  const handlePersonCompany = (_, companies) => {
    if (companies.length !== 0) {
      setFieldError(oldFieldValues => ({ ...oldFieldValues, ['company_error']: false }));
    } else {
      setFieldError(oldFieldValues => ({ ...oldFieldValues, ['company_error']: true }));
    }
    setCompanyValue(companies);
    let selected_org = null;
    const selected_subs = [];
    const data = companies.map(a => a.id);

    if (data && data.length > 0) {
      data.forEach(element => {
        const subcontractorsList = props.subcontractors;
        const selection = subcontractorsList.find(company => company.combinedId === element);
        if (selection.status === 'ORGANIZATION') {
          selected_org = selection;
        } else {
          selected_subs.push({ id: selection?.id, name: selection?.name });
        }
      });
      setFieldValues(oldFieldValues => ({
        ...oldFieldValues,
        ['organization']: selected_org ? { id: selected_org?.id, name: selected_org?.name } : null,
      }));
      setFieldValues(oldFieldValues => ({ ...oldFieldValues, ['subcontractors']: selected_subs }));
    }
  };

  const handlePayRateStart = e => {
    if (e && e !== undefined && e !== null) {
      if (
        pay_rate_effective_date &&
        pay_rate_effective_date !== undefined &&
        pay_rate_effective_date !== null &&
        pay_rate_effective_date.valueOf() < e.valueOf()
      ) {
        setFieldError(oldFieldError => ({
          ...oldFieldError,
          ['payRateStart_error']: true,
          ['payRateStart_helper']: 'Start Date should be less than End Date',
        }));
      } else {
        setFieldError(oldFieldError => ({
          ...oldFieldError,
          ['payRateStart_error']: false,
          ['payRateStart_helper']: '',
        }));
        const d = moment(e).format('YYYY-MM-DD');
        setPayRateStart(e);
        setFieldValues(oldFieldValues => ({
          ...oldFieldValues,

          employment_detail: { ...oldFieldValues.employment_detail, pay_rate_start_date: d },
        }));
      }
    }
  };
  const handlePayRateEnd = e => {
    if (e && e !== undefined && e !== null) {
      if (
        pay_rate_start_date &&
        pay_rate_start_date !== undefined &&
        pay_rate_start_date !== null &&
        pay_rate_start_date.valueOf() > e.valueOf()
      ) {
        setFieldError(oldFieldError => ({
          ...oldFieldError,
          ['payRateEnd_error']: true,
          ['payRateEnd_helper']: 'End Date should be greater than Start Date',
        }));
      } else {
        setFieldError(oldFieldError => ({
          ...oldFieldError,
          ['payRateEnd_error']: false,
          ['payRateEnd_helper']: '',
        }));
        const d = moment(e).format('YYYY-MM-DD');
        setPayRateEnd(e);
        setFieldValues(oldFieldValues => ({
          ...oldFieldValues,

          employment_detail: { ...oldFieldValues.employment_detail, pay_rate_effective_date: d },
        }));
      }
    }
  };

  const checkForValidations = () => {
    const result =
      !fieldError['department_error'] &&
      !fieldError['position_error'] &&
      !fieldError['role_error'] &&
      !fieldError['company_error'] &&
      !fieldError['identification_number_error'] &&
      !fieldError['payRateStart_error'] &&
      !fieldError['payRateEnd_error'] &&
      !fieldError['first_name_error'] &&
      !fieldError['last_name_error'] &&
      !fieldError['email_error'] &&
      !fieldError['address_error'] &&
      !fieldError['identification_number_error'] &&
      !(fieldValues['phoneNumber'] === undefined) &&
      !(fieldValues['first_name'] === undefined) &&
      !(fieldValues['last_name'] === undefined) &&
      !(fieldValues['email'] === undefined) &&
      !(fieldValues['address'] === undefined);
    company.length !== 0 &&
      fieldValues['role'] !== undefined &&
      fieldValues['designation'] !== undefined &&
      fieldValues['department'] !== undefined &&
      fieldValues['employment_detail']['pay_rate'] !== undefined &&
      company !== undefined;

    return result;
  };

  const checkForRequiredValidations = () => {
    let output = true;
    if (fieldValues['role'] === undefined) {
      setFieldError(oldFieldError => ({ ...oldFieldError, ['role_error']: true }));
      output = false;
    }
    if (fieldValues['designation'] === undefined || fieldValues['designation'] === null) {
      setFieldError(oldFieldError => ({ ...oldFieldError, ['position_error']: true }));
      output = false;
    }
    if (fieldValues['department'] === undefined || fieldValues['department'] === null) {
      setFieldError(oldFieldError => ({ ...oldFieldError, ['department_error']: true }));
      output = false;
    }
    if (
      fieldValues['pay_rate'] === undefined &&
      (fieldValues['employment_detail'] ? fieldValues['employment_detail']['pay_rate'] === undefined : true)
    ) {
      setFieldError(oldFieldError => ({ ...oldFieldError, ['payRate_error']: true }));
      output = false;
    }
    if (company.length === 0) {
      setFieldError(oldFieldError => ({ ...oldFieldError, ['company_error']: true }));
      output = false;
    }
    return output;
  };

  const saveEntity = event => {
    event.preventDefault();
    const formData = new FormData();
    if (fieldValues['person_image'] !== undefined) {
      formData.append('avatar', fieldValues['person_image']);
    }
    const object = new FormData();
    if (personFiles.length > 0) {
      personFiles.forEach((element, index) => {
        object.append(`avatar-${index}`, element.file);
      });
      object.append('doc_type', '6');
    }
    if (isNew && checkForRequiredValidations() && checkForValidations()) {
      props.createEntity(fieldValues, formData, object);
      props.history.push(`/staff`);
    } else if (!isNew && checkForRequiredValidations() && checkForValidations()) {
      props.updateEntity(fieldValues, formData, object);
    } else {
      checkForRequiredValidations();
    }
    if (!isNew) {
      setShowEditPage(false);
    }
  };

  const handleLineManager = (_, e) => {
    if (e) {
      setFieldValues(oldValues => ({ ...oldValues, ['line_manager']: e }));
    }
  };

  const handleNoOfHolidays = e => {
    setFieldValues(oldValues => ({ ...oldValues, ['no_of_holidays']: e.target.value }));
  };

  return (
    <div className="person-view-main-container">
      <div className="person-company-details-div">
        {showEditPage === false ? (
          <>
            <div className="PersonProfileEditBtnContainer">
              <Button
                variant="contained"
                className="Profile_Detials_edit_btn"
                onClick={editPageHandler}
                startIcon={<img src="content/icons/Personal Details/Edit.png" />}
              >
                Edit
              </Button>
            </div>
            <Divider style={{ margin: '4% 0' }} />
          </>
        ) : (
          ''
        )}

        <div className="field-container ">
          <div className="person-company-info-div">
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} style={{ marginBottom: '1.7rem' }}>
                <FormControl id = "company-select-box" className="person-company-info-field-cont" style={{ background: '#0000' }}>
                  <Autocomplete
                    ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                    limitTags={1}
                    multiple
                    options={items && items}
                    disableCloseOnSelect
                    getOptionLabel={option => option.name || ''}
                    value={company ? company : null}
                    disabled={showEditPage === false ? true : false}
                    renderTags={values => values.map(value => <Chip key={value.id} label={value.name} />)}
                    getOptionSelected={(option, value) => option.id === value.id}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected ? selected : null} />
                        {option.name}
                      </React.Fragment>
                    )}
                    onChange={handlePersonCompany}
                    renderInput={params => (
                      <TextField {...params} error={fieldError['company_error']} required variant="outlined" label="Company Name" />
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item md={6} lg={6} sm={12} xs={12} style={{ marginBottom: '1.7rem' }}>
                <FormControl id="staff-role-select" className="person-company-info-field-cont">
                  <Autocomplete
                    disabled={showEditPage === false ? true : false}
                    options={props.rolesList}
                    value={fieldValues['role'] ? (fieldValues['role'][0] ? fieldValues['role'][0] : fieldValues['role']) : null}
                    getOptionLabel={option => option.name || ''}
                    noOptionsText={'No Records'}
                    onChange={handleRoleData}
                    ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        className="personAutocompleteNationalityTextfield automaticcompleteLabel"
                        label="Role"
                        fullWidth
                        required
                        error={fieldError['role_error']}
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item md={3} lg={3} sm={12} xs={12}>
                <FormControl id="staff-position-select" className="person-company-info-field-cont">
                  <Autocomplete
                    options={positionsList}
                    noOptionsText={'Please add new Positions'}
                    ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                    getOptionLabel={option => option.name || ''}
                    className="PersonAutocomplete"
                    onChange={handlePositionData}
                    value={fieldValues['designation'] ? fieldValues['designation'] : null}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        className="personAutocompleteTextfield autocompleteLabel"
                        label="Position"
                        fullWidth
                        required
                        error={fieldError['position_error']}
                      />
                    )}
                    disabled={showEditPage === false ? true : false}
                  />
                </FormControl>
              </Grid>
              <Grid item md={3} lg={3} sm={12} xs={12}>
                <FormControl id="staff-department-select" className="person-company-info-field-cont">
                  <Autocomplete
                    disabled={showEditPage === false ? true : false}
                    options={departmentsList}
                    noOptionsText={'Please add new Departments'}
                    // classes={{ listbox: props.classes.listbox }}
                    ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                    getOptionLabel={option => option.name || ''}
                    className="PersonAutocomplete"
                    onChange={handleDepartmentData}
                    value={fieldValues['department'] ? fieldValues['department'] : null}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        className="personAutocompleteTextfield autocompleteLabel"
                        label="Department"
                        fullWidth
                        required
                        error={fieldError['department_error']}
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item lg={6} md={6} sm={12}>
                <FormControl className="person-company-info-field-cont">
                  <Autocomplete
                    options={props.personList}
                    noOptionsText={'No Records'}
                    ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                    getOptionLabel={option => option.first_name + ' ' + option.last_name || ''}
                    onChange={handleLineManager}
                    value={fieldValues['line_manager'] ? fieldValues['line_manager'] : null}
                    disabled={showEditPage === false ? true : false}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        className="personAutocompleteNationalityTextfield automaticcompleteLabel"
                        label="Line Manager"
                        name="line_manager"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </div>

          <Divider style={{ margin: '4% 0' }} />
        </div>

        <div className="field-container ">
          <div className="person-company-info-div">
            <Grid container spacing={2}>
              <Grid item lg={3} md={3} sm={12}>
                <FormControl className="person-company-info-field-cont">
                  <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                    <KeyboardDatePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      label="Contract Start"
                      name="pay_rate_start_date"
                      disabled={showEditPage === false ? true : false}
                      error={fieldError['payRateStart_error']}
                      format={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'MM/DD/YYYY'}
                      value={
                        fieldValues['employment_detail']
                          ? fieldValues['employment_detail'].pay_rate_start_date
                            ? fieldValues['employment_detail'].pay_rate_start_date
                            : null
                          : null
                      }
                      ToolbarComponent={prop => (
                        <>
                          <CustomToolbarComponent {...prop} settingFormat={props.setting.date_format} />
                        </>
                      )}
                      placeholder={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'MM/DD/YYYY'}
                      InputAdornmentProps={{ position: 'start' }}
                      onChange={date => handlePayRateStart(date)}
                    />{' '}
                  </MuiPickersUtilsProvider>
                  {fieldError['payRateStart_error'] && (
                    <span style={{ color: 'red', fontSize: '17' }}>{props.fieldError['payRateStart_helper']}</span>
                  )}
                </FormControl>
              </Grid>

              <Grid item lg={3} md={3} sm={12}>
                <FormControl className="person-company-info-field-cont">
                  <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                    <KeyboardDatePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      label="Contract End"
                      name="pay_rate_effective_date"
                      disabled={showEditPage === false ? true : false}
                      error={fieldError['payRateEnd_error']}
                      format={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'MM/DD/YYYY'}
                      value={
                        fieldValues['employment_detail']
                          ? fieldValues['employment_detail'].pay_rate_effective_date
                            ? fieldValues['employment_detail'].pay_rate_effective_date
                            : null
                          : null
                      }
                      ToolbarComponent={prop => (
                        <>
                          <CustomToolbarComponent {...prop} settingFormat={props.setting.date_format} />
                        </>
                      )}
                      placeholder={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'MM/DD/YYYY'}
                      InputAdornmentProps={{ position: 'start' }}
                      onChange={date => handlePayRateEnd(date)}
                    />{' '}
                  </MuiPickersUtilsProvider>
                  {fieldError['payRateEnd_error'] && (
                    <span style={{ color: 'red', fontSize: '17' }}>{fieldError['payRateEnd_helper']}</span>
                  )}
                </FormControl>
              </Grid>

              <Grid item lg={6} md={6} sm={12}>
                <FormControl className="person-company-info-field-cont">
                  <TextField
                    variant="outlined"
                    type="number"
                    onChange={handleEmploymentDetail}
                    label="Pay Rate"
                    required
                    name="pay_rate"
                    id="shift-payrate"
                    disabled={showEditPage === false ? true : false}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <span className="personCurrencySign">{CurrencyChecker(currency)}</span>
                          <img src="content/icons/Personal Details/Last Name.png" />
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{
                      step: '0.01',
                      min: 0,
                    }}
                    value={fieldValues['employment_detail'] ? fieldValues['employment_detail']['pay_rate'] : fieldValues['pay_rate']}
                    error={fieldError['payRate_error']}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </div>

          <div className="person-company-info-div" style={{ marginTop: '50px' }}>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12}>
                <FormControl className="person-company-info-field-cont">
                  <TextField
                    variant="outlined"
                    type="number"
                    onChange={handleNoOfHolidays}
                    label="Holiday Qouta / yr"
                    name="holiday_qouta"
                    id="shift-payrate"
                    disabled={showEditPage === false ? true : false}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <img src="content/icons/Personal Details/Last Name.png" />
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{
                      step: '1',
                      min: 0,
                    }}
                    value={fieldValues['no_of_holidays'] ? fieldValues['no_of_holidays'] : null}
                    error={fieldError['payRate_error']}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </div>

          {showEditPage === true || isNew ? (
            <>
              <Divider style={{ margin: '4% 0' }} />
              <Grid item xs={12}>
                <FormControl style={{ flexDirection: 'row', justifyContent: 'flex-end', width: '100%' }}>
                  <>
                    <Button
                      variant="contained"
                      className="Person-profile-Discard-Button"
                      onClick={profilePageHandler}
                      style={{ display: isNew && 'none' }}
                    >
                      Cancel
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button
                      onClick={() => {
                        saveEntity(event);
                      }}
                      variant="contained"
                      id="save-entity"
                      type="submit"
                      className="Person-profile-Save-Button"
                    >
                      Save
                    </Button>
                  </>
                </FormControl>
              </Grid>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  subcontractors: storeState.subcontractor.subcontractors_list,
  personEntity: storeState.person.entity,
  designationList: storeState.designation.entities,
  departmentList: storeState.department.entities,
  settings: storeState.organizationSettings.storedOrganizationSettings,
  rolesList: storeState.person.roles,
  setting: storeState.PersonalSettings.storedPersonalSettings,
  personList: storeState.person.entities,
});

const mapDispatchToProps = {
  getOrganizationWithSubContractors,
  getRoles,
  positionEntities,
  resetPos,
  getEntity,
  updateEntity,
  reset,
  resetCountry,
  resetDep,
  departmentEntities,
  createEntity,
  getAllCountry,
  getEntities,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PersonCompanyInfo);
