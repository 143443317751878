import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { ActionButtons } from '../actionButtons/actionButtons';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { connect } from 'react-redux';
import '../../form.scss';
import { AccessTime, BorderBottom, BorderColor } from '@material-ui/icons';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { getDateFormat } from 'app/shared/util/calender-utils';
import CustomToolbarComponent from '../../../../../entities/service-area/CustomToolbarComponent';
import moment from 'moment';

interface Props {
  storeAboutPositionSection: (data: any) => void;
  updateAboutPositionSection: (data: any) => void;
  handleIncrementDecrement: (tag: string) => void;
  setting: any;
}

export interface IAboutPosition extends Props, StateProps, RouteComponentProps<{ url: string }> {}

export const AboutPosition = (props: IAboutPosition) => {
  const { position } = props['employeeFormData'];
  const [availability, setAvailability] = useState({
    Morning: false,
    Day: false,
    Night: false,
  });
  const [fieldError, setFieldError] = useState({
    applied_position: false,
    applied_position_helper: '',
    job_availability: false,
    expected_date_start: false,
    shift_time: false,
  });
  const [dateFormat, setdateFormat] = useState(null);
  const [locale, setLocale] = useState(props.setting.time_format);
  const jobType = [
    { name: 'Part-time', value: 'part time' },
    { name: 'Full-time', value: 'full time' },
    { name: 'Weekend', value: 'Weekend' },
    { name: 'Adhoc', value: 'Adhoc' },
  ];
  const shiftTime = ['Morning', 'Day', 'Night'];
  const [values, setValues] = useState(position);
  const isEdit = props['editEmployeeForm'];
  useEffect(() => {
    if (isEdit && position) {
      setValues(oldValues => ({
        ...oldValues,
        id: position.id,
        applied_position: position.applied_position,
        job_availability: position.job_availability,
        expected_date_start: position.expected_date_start,
      }));
      setAvailability(oldValues => ({
        ...oldValues,
        Morning: position.shift_time ? position.shift_time.Morning : false,
        Day: position.shift_time ? position.shift_time.Day : false,
        Night: position.shift_time ? position.shift_time.Night : false,
      }));
    }
  }, [position]);

  const setFormValues = e => {
    setValues(oldValues => ({ ...oldValues, [e.target.name]: e.target.value }));
    if (e.target.name === 'applied_position') {
      checkNumbersNotAllowedValidation(e);
    } else {
      checkSingleFieldValidations(e);
    }
  };

  const handleDateOfStartWork = e => {
    const d = moment(e).format('YYYY-MM-DD');
    setdateFormat(e);
    setValues(oldValues => ({ ...oldValues, ['expected_date_start']: d }));
    if (values['expected_date_start'] !== undefined || values['expected_date_start'] !== null) {
      setFieldError(oldValues => ({ ...oldValues, ['expected_date_start']: false }));
    }
  };

  useEffect(() => {
    setValues(oldValues => ({ ...oldValues, ['shift_time']: availability }));
    stateAvailabilityValidations();
  }, [availability]);

  const setPersonAvailability = e => {
    const { name, checked } = e.target;
    setAvailability(prevValues => ({
      ...prevValues,
      [name]: checked,
    }));
  };

  const checkFormValueValidation = () => {
    if (
      !fieldError['applied_position'] &&
      !fieldError['job_availability'] &&
      !fieldError['expected_date_start'] &&
      !fieldError['shift_time'] &&
      values['applied_position'] !== undefined &&
      values['job_availability'] !== undefined &&
      values['expected_date_start'] !== undefined &&
      values['shift_time'] !== undefined
    ) {
      return true;
    } else {
      return false;
    }
  };

  const submitSection = () => {
    checkValidationsBeforeSubmit();
    if (stateAvailabilityValidations()) {
      if (isEdit && checkFormValueValidation()) {
        props.updateAboutPositionSection({ ...values, id: props['employeeFormData'].person.id });
      } else {
        if (!isEdit && checkFormValueValidation()) {
          props.storeAboutPositionSection(values);
        }
      }
    }
  };

  const checkNumbersNotAllowedValidation = e => {
    const expression = /\d/g;
    if (e.target.value === undefined || e.target.value === '' || e.target.value === null) {
      setFieldError(oldValues => ({ ...oldValues, ['applied_position']: true }));
      setFieldError(oldValues => ({ ...oldValues, ['applied_position_helper']: '' }));
    } else if (expression.test(e.target.value)) {
      setFieldError(oldValues => ({ ...oldValues, ['applied_position']: true }));
      setFieldError(oldValues => ({ ...oldValues, ['applied_position_helper']: 'Numbers are not allowed' }));
    } else {
      setFieldError(oldValues => ({ ...oldValues, ['applied_position']: false }));
      setFieldError(oldValues => ({ ...oldValues, ['applied_position_helper']: '' }));
    }
  };

  const checkSingleFieldValidations = e => {
    if (e.target.value === undefined || e.target.value === '' || e.target.value === null) {
      setFieldError(oldValues => ({ ...oldValues, [e.target.name]: true }));
    } else {
      setFieldError(oldValues => ({ ...oldValues, [e.target.name]: false }));
    }
  };

  const checkValidationsBeforeSubmit = () => {
    if (values['applied_position'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['applied_position']: true }));
    }
    if (values['job_availability'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['job_availability']: true }));
    }
    if (values['expected_date_start'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['expected_date_start']: true }));
    }
    if (values['shift_time'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['shift_time']: true }));
    }
  };
  const stateAvailabilityValidations = () => {
    if (availability['Morning'] || availability['Day'] || availability['Night']) {
      setFieldError(oldValues => ({ ...oldValues, ['shift_time']: false }));
      return true;
    }
    setFieldError(oldValues => ({ ...oldValues, ['shift_time']: true }));
    return false;
  };

  return (
    <fieldset className="about-position-section">
      <div className="about-position-form">
        <Grid container spacing={3}>
          <Grid item xs={12} className="about-position-fields">
            <TextField
              type="text"
              name="applied_position"
              variant="outlined"
              label="Position Applied For"
              error={fieldError['applied_position']}
              helperText={fieldError['applied_position'] && fieldError['applied_position_helper']}
              className="form-control"
              id="applied_position"
              onChange={setFormValues}
              defaultValue={position && position.applied_position}
              value={values ? values['applied_position'] : ''}
            />
          </Grid>
          <Grid item xs={12} className="about-position-fields">
            <FormGroup>
              <div style={{ borderBottom: fieldError['job_availability'] ? '1px solid red' : '' }}>
                <RadioGroup
                  aria-label="gender"
                  name="job_availability"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                  onChange={setFormValues}
                >
                  {jobType.map((item, index) => (
                    <FormControlLabel
                      key={index}
                      control={
                        <Radio
                          name="job_availability"
                          checked={values && values['job_availability'] === item.value}
                          size="small"
                          style={{ color: '#4620B7' }}
                          value={item.value}
                        />
                      }
                      className="vetting-form-about-position-job-availability-radio-button"
                      label={item.name}
                    />
                  ))}
                </RadioGroup>
              </div>
            </FormGroup>
          </Grid>
          <Grid item xs={12} className="about-position-fields">
            <FormGroup>
              <div style={{ display: 'flex' }}>
                <FormGroup>
                  <InputLabel htmlFor=""> Please state your availability </InputLabel>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'inherit',
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={'Morning'}
                          checked={availability['Morning']}
                          onChange={setPersonAvailability}
                          style={{ color: '#4620B7' }}
                          size="small"
                        />
                      }
                      className="vetting-form-about-position-availability-checkbox"
                      label={'Morning'}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={'Day'}
                          checked={availability['Day']}
                          onChange={setPersonAvailability}
                          style={{ color: '#4620B7' }}
                          size="small"
                        />
                      }
                      className="vetting-form-about-position-availability-checkbox"
                      label={'Day'}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={'Night'}
                          checked={availability['Night']}
                          onChange={setPersonAvailability}
                          style={{ color: '#4620B7' }}
                          size="small"
                        />
                      }
                      className="vetting-form-about-position-availability-checkbox"
                      label={'Night'}
                    />
                  </div>
                </FormGroup>
              </div>
            </FormGroup>
          </Grid>
          <Grid item xs={12} className="about-position-fields">
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <KeyboardDatePicker
                autoOk
                variant="inline"
                className="form-control aboutPositionStartWorkDateIcon"
                inputVariant="outlined"
                name="expected_date_start"
                label="Expected Date to Start Work"
                format={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'MM/DD/YYYY'}
                value={values ? values['expected_date_start'] : moment().format('DD-MM-YYYY')}
                ToolbarComponent={prop => (
                  <>
                    <CustomToolbarComponent {...prop} settingFormat={props.setting.date_format} />
                  </>
                )}
                placeholder={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'MM/DD/YYYY'}
                keyboardIcon={<img src="content/images/icons8-calendar-22.png" />}
                InputAdornmentProps={{ position: 'end' }}
                style={{ color: '#D48BD3' }}
                error={fieldError['expected_date_start']}
                onChange={date => handleDateOfStartWork(date)}
              />{' '}
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </div>
      <div
        className="job-application-form-action-buttons-outer-div"
        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}
      >
        <ActionButtons backButton={true} buttonText={true} submit={submitSection} />
      </div>
    </fieldset>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AboutPosition);
