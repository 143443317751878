export interface IPatrolReport {
  miniReport?: IMiniPatrolReport[] | null;
  patrols_completed?: number | null;
  patrols_missed?: number | null;
  checkpoints_completed?: number | null;
  checkpoints_missed?: number | null;
  pictures_uploaded?: number | null;
}

interface IMiniPatrolReport {
  start_time?: string | null;
  end_time?: string | null;
  assignee_name?: string | null;
  position?: string | null;
  service_area?: string | null;
  start_end?: string | null;
  patrol_frequency?: number | null;
  patrols_completed?: number | null;
  patrols_missed?: number | null;
  no_of_checkpoints?: number | null;
  checkpoints_completed?: number | null;
  checkpoints_missed?: number | null;
  pictures_uploaded?: number | null;
  any_note_received?: number | null;
  patrol_object?: IMiniPatrolObject[] | null;
}

interface IMiniPatrolObject {
  start_time?: string | null;
  end_time?: string | null;
  assignee_name?: string | null;
  position?: string | null;
  service_area?: string | null;
  start_end?: string | null;
  type?: string | null;
  patrols_completed?: string | null;
  patrols_missed?: string | null;
  checkpoints_completed?: number | null;
  checkpoints_missed?: number | null;
  pictures_uploaded?: IMiniPatrolPictureObject | null;
  any_note_received?: string | null;
}

interface IMiniPatrolPictureObject {
  id?: number;
  data?: string;
}

export const defaultValue: Readonly<IPatrolReport> = {};
