export interface IAppFormPersonalInfo {
  id?: number;
  first_name?: string | null;
  last_name?: string | null;
  current_address?: string | null;
  post_code?: number | string | null;
  date_of_birth?: Date | null;
  national_insurance_no?: number | string | null;
  telephone_no?: number | string | null;
  mobile_no?: number | string | null;
  email_address?: string | null;
  sia_license?: boolean | null;
  license_name?: string | null;
  sia_license_no?: number | string | null;
  license_expiration?: Date | null;
  work_uk?: boolean | null;
  crime_conviction?: boolean | null;
  crime_conviction_details?: string | null;
  driving_license_uk_uae?: boolean | null;
}

export const defaultValue: Readonly<IAppFormPersonalInfo> = {};
