import React from 'react';
import { Grid, FormControl, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { IPerson } from 'app/shared/model/person.model';
import { IServiceArea } from 'app/shared/model/service-area.model';
interface IPersonFilterProps {
    personsList: readonly IPerson[];
    selectedPerson: any;
    loading: boolean;
    handlePersonFilterValues: (event: any, newValue: any) => void;
}
  
interface ILocationFilterProps {
    locationsList: readonly IServiceArea[];
    selectedLocation: any;
    loading: boolean;
    handleLocationFilterValues: (event: any, newValue: any) => void;
}

export const PersonFilter: React.FC<IPersonFilterProps> = ({
  personsList,
  selectedPerson,
  loading,
  handlePersonFilterValues,
}) => (
  <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
    <FormControl variant="outlined" fullWidth className="entities_report_filter_fields staffFilterTimeSheet">
      <Autocomplete
        options={
          personsList &&
          personsList.map(person => ({
            id: person.id,
            text: person.first_name + ' ' + person.last_name,
            name: 'people',
          }))
        }
        noOptionsText={'No Records'}
        classes={{ listbox: 'autocompletelistbox' }}
        ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
        getOptionLabel={option => option.text || ''}
        onChange={handlePersonFilterValues}
        value={selectedPerson}
        disabled={loading}
        renderInput={params => <TextField {...params} variant="outlined" size="small" placeholder="Staff" />}
      />
    </FormControl>
  </Grid>
);

export const LocationFilter: React.FC<ILocationFilterProps> = ({
  locationsList,
  selectedLocation,
  loading,
  handleLocationFilterValues,
}) => (
  <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
    <FormControl variant="outlined" fullWidth className="entities_report_filter_fields staffFilterTimeSheet">
      <Autocomplete
        options={
          locationsList &&
          locationsList.map(location => ({
            id: location.id,
            text: location.name,
            name: 'location',
          }))
        }
        noOptionsText={'No Records'}
        classes={{ listbox: 'autocompletelistbox' }}
        ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
        getOptionLabel={option => option.text || ''}
        onChange={handleLocationFilterValues}
        value={selectedLocation}
        disabled={loading}
        renderInput={params => <TextField {...params} variant="outlined" size="small" placeholder="Service Areas" />}
      />
    </FormControl>
  </Grid>
);

interface ShiftTypeFilterProps {
    selectedShiftType,
    handleShiftTypeFilterValues: (event: React.ChangeEvent<HTMLElement>, value: any) => void;
  }

export const ShiftTypeFilter: React.FC<ShiftTypeFilterProps> = ({
    selectedShiftType, 
    handleShiftTypeFilterValues,
  })  => (

    <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
      <FormControl variant="outlined" fullWidth className="entities_report_filter_fields staffFilterTimeSheet">
        <Autocomplete
          options={[
            { id: '1', text: 'Approved', name: 'shiftType'},
            { id: '2', text: 'Abnormal', name: 'shiftType'},
            { id: '3', text: 'Signed In', name: 'shiftType'},
            { id: '4', text: 'Signed Out', name: 'shiftType'},
            { id: '5', text: 'Breaks', name: 'shiftType'},

          ]}
          noOptionsText={'No Records'}
          classes={{ listbox: 'autocompletelistbox' }}
          onChange={handleShiftTypeFilterValues}
          ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
          value={selectedShiftType}
          getOptionLabel={option => option.text || ''}
          renderInput={params => <TextField {...params} variant="outlined" size="small" placeholder="Shift Status" />}
        />
      </FormControl>
    </Grid>
);