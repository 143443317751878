import React, { useState, useEffect } from 'react';
import { Translate, translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Alert } from '@material-ui/lab';
import { Button, Grid, FormControl, TextField, makeStyles, Paper } from '@material-ui/core';
import { AvForm } from 'availity-reactstrap-validation';
import { Row, Col } from 'reactstrap';
import './register.scss';
import PasswordStrengthBar from 'app/shared/layout/password/password-strength-bar';
import { IRootState } from 'app/shared/reducers';
import { handleRegister, reset, sendEmailVerification } from './register.reducer';
import { Link, RouteComponentProps } from 'react-router-dom';

export interface IRegisterProps extends StateProps, DispatchProps {
  history;
  match;
}

export const RegisterPage = (props: IRegisterProps) => {
  const [password, setPassword] = useState('');
  const [values, setValues] = useState({});
  const [fieldError, setFieldError] = useState({ name_error: false, name_helper: '' });

  useEffect(
    () => () => {
      props.reset();
    },
    []
  );

  const handleEmailValue = e => {
    if (
      e.target.name === undefined ||
      !/^(([^<>()[\],;:\s@]+(\.[^<>()[\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+\.)+[^<>()[\],;:\s@]{2,})$/i.test(e.target.value)
    ) {
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: true }));
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_helper']: 'Please Enter Correct Email !' }));
    } else if (
      e.target.name === undefined ||
      !/^[a-zA-Z0-9._%+-]+@(?!gmail)(?!yahoo)(?!hotmail)(?!aol)(?!live)(?!outlook)(?!gmx)(?!icloud)(?!proton)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/i.test(
        e.target.value
      )
    ) {
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: true }));
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_helper']: 'Please Enter Organization Email !' }));
    } else {
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: false }));
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_helper']: '' }));
    }
    setValues(oldValues => ({ ...oldValues, [e.target.name]: e.target.value }));
  };

  const checkFormValidations = () => {
    const result = !fieldError['email_error'] && values['email'] !== undefined;
    return result;
  };

  const checkEmptyFileds = () => {
    if (values['email'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['email_error']: true }));
    }
  };

  const submitEmail = async () => {
    if (checkFormValidations()) {
      const result = await sendEmailVerification(values);
      if (result.status === 200) {
        props.history.push('/verification');
      } else {
        setFieldError(oldFieldError => ({ ...oldFieldError, ['email_error']: true }));
        setFieldError(oldFieldError => ({ ...oldFieldError, ['email_helper']: 'Email already exists. Try Logging In !' }));
      }
    } else {
      checkEmptyFileds();
    }
  };

  const updatePassword = event => setPassword(event.target.value);
  const { match } = props;
  return (
    <React.Fragment>
      <div className="register-acount-main-div">
        <div className="register-account-image-div">
          <img className="register-account-image" src="content/images/account-register-image.png"></img>
        </div>
        <div className="register-account-form-div">
          <div className="register-account-form-main-heading">
            Get Started with Your <br></br> 14-Days Free Trial.
          </div>
          <div className="register-account-form-sub-heading">You are just a few steps away.</div>
          <Grid container item xs={12} md={10} lg={10} className="register-account-email-field">
            <FormControl className="accountsregister-fields">
              <TextField
                name="email"
                variant="outlined"
                label={translate('global.form.email.label')}
                placeholder="Email"
                onChange={handleEmailValue}
                error={fieldError['email_error']}
                helperText={fieldError['email_helper']}
                type="email"
                inputProps={{
                  pattern: '^[a-zA-Z0-9!$&*+=?^_`{|}~.-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$|^[_.@A-Za-z0-9-]+$',
                  minLength: 5,
                  maxLength: 254,
                }}
                data-cy="email"
              />
            </FormControl>
          </Grid>

          <Grid container item xs={12} md={10} lg={10}>
            <FormControl style={{ display: 'block' }} className="accountsregister-fields">
              <Button
                id="register-submit"
                type="submit"
                onClick={submitEmail}
                variant="outlined"
                data-cy="submit"
                className="accountregister_register_button"
              >
                Continue
              </Button>
            </FormControl>
          </Grid>
          <div className="redirect-sign-in-text">
            Already have an account ?{' '}
            <a style={{ color: '#532AC5' }} href="/login">
              Sign In
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ locale }: IRootState) => ({
  currentLocale: locale.currentLocale,
});

const mapDispatchToProps = { handleRegister, sendEmailVerification, reset };
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);
