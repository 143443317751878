import dayjs from 'dayjs';
import { IAddress } from 'app/shared/model/address.model';
import { IIdentification } from 'app/shared/model/identification.model';
import { IEducationalInstitute } from 'app/shared/model/educational-institute.model';
import { IEmploymentDetails } from 'app/shared/model/employment-details.model';
import { IDepartment } from 'app/shared/model/department.model';
import { IDesignation } from 'app/shared/model/designation.model';
import { ICountry } from 'app/shared/model/country.model';
import { ICity } from 'app/shared/model/city.model';
import { IContact } from 'app/shared/model/contact.model';
import { IEducation } from 'app/shared/model/education.model';
import { IRole } from './role.model';
import { ISubcontractor } from 'app/shared/model/subcontractor.model';
import { IOrganization } from 'app/shared/model/organization.model';

export interface IPerson {
  organizations_list?: any;
  id?: number;
  person_identity_number?: number | null;
  first_name?: string | null;
  last_name?: string | null;
  date_of_birth?: string | null;
  gender?: string | null;
  national_insurance_number?: string | null;
  visa_required?: string | null;
  place_of_birth?: string | null;
  birth_city_id?: string | null;
  birth_country_id?: string | null;
  address?: IAddress | null;
  identification_number?: string | null;
  educational_institute?: IEducationalInstitute | null;
  employment_detail?: IEmploymentDetails | null;
  department?: IDepartment | null;
  siaLicenceNumber?: any;
  designation?: IDesignation | null;
  expiry_date?: string | null;
  visaRequiredDate?: string | null;
  phoneNumber?: IContact | null;
  role?: IRole[] | null;
  postCode?: string | null;
  city?: ICity | null;
  country?: ICountry | null;
  visaDetails?: string | null;
  imageUrl?: string | null;
  nationality?: ICountry | null;
  education?: IEducation[] | null;
  email?: IContact[] | null;
  driverLicense?: string | null;
  organization?: IOrganization | null;
  subcontractors?: ISubcontractor[] | null;
  company?: any;
  birthCity?: ICity | null;
  birthCountry?: ICountry | null;
  measurements?: IMeasurements | null;
  notificationSettings?: INotificationSettings | null;
  enhanced_dbs?: any;
  NSVS_clearance?: any;
}

export interface IPersonDocs {
  description?: string | null;
  name?: string | null;
  subcontractor_id?: number;
  id?: number;
}

export interface IMeasurements {
  hard_hat?: string | null;
  jacket?: string | null;
  shirt_collar_size?: number;
  shirt_chest_size?: number;
  shirt_sleeves_length?: number;
  shirt_body_length?: number;
  polo_shirt?: number;
  tie?: string | null;
  hi_viz?: string | null;
  pant_size?: string | null;
  waist_size?: number;
  hips_size?: number;
  pant_length?: number;
  shoes_standard?: string | null;
  shoes_size?: number;
}

export interface INotificationSettings {
  shift_creation_notification?: boolean | null;
  allow_sign_in?: boolean | null;
}

export const defaultValue: IPerson = {};
