import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { IRootState } from 'app/shared/reducers';
import { getEntity } from '../person.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { Paper } from '@material-ui/core';
import '../../sub-contractor/subcontractor.scss';
import Typography from '@material-ui/core/Typography';
import SearchBar from 'material-ui-search-bar';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Table, Styles } from '../component/person-rate-card';

export interface IPersonDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const PersonRateCard = (props: IPersonDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { personEntity, match, loading } = props;

  const header = [
    { label: 'SERVICE AREA', key: 'location' },
    { label: 'EMPLOYEE', key: 'person' },
    { label: 'POSITION', key: 'description' },
    { label: 'CHARGE RATE', key: 'chargeRate' },
    { label: 'PAY RATE', key: 'payRate' },
    { label: 'EXPENSES', key: 'expenses' },
  ];

  const headersDetailed = [
    { label: 'SERVICE AREA', key: 'location' },
    { label: 'EMPLOYEE', key: 'person' },
    { label: 'POSITION', key: 'description' },
    { label: 'CHARGE RATE', key: 'chargeRate' },
    { label: 'PAY RATE', key: 'payRate' },
    { label: 'EXPENSES', key: 'expenses' },
  ];

  const columns = React.useMemo(
    () => [
      {
        Header: () => null,
        id: 'expander',
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()} className="ArrowUpDownIcon">
            {row.isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </span>
        ),
        SubCell: () => null,
      },
      {
        Header: 'Service Area',
        accessor: d => (
          <span>
            <span className="timesheetStartDateCell"></span>
            {'-'}
            <br />
            <span className="timesheetEndDateCell"></span>
            <br />
            <span className="timesheetTimeCell">
              <span className="timesheetStartTimeCell"></span>
              {'-'}
              <br />
              <span className="timesheetEndTimeCell"></span>
            </span>
          </span>
        ),
        SubCell: cellProps => <>{cellProps.value}</>,
      },
      {
        Header: 'Employee',
        accessor: d => (
          <span>
            {d.person}
            <br />
            <span className="timesheetTypeCell">{d.type}</span>
          </span>
        ),
      },
      {
        Header: 'Position',
        accessor: d => d.position,
      },
      {
        Header: 'Charge Rate',
      },
      {
        Header: 'Pay Rate',
      },
      {
        Header: 'Expenses',
      },
    ],
    []
  );

  let Tabledata = [];
  const newData = [];
  const isDescending = true;
  if (props.personEntity) {
    Tabledata = newData.sort((a, b) => {
      return isDescending
        ? new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
        : new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
    });
  }

  return (
    <div className="subcontractor-view-documents-main-container">
      <Paper className="subcontractor-details-paper">
        <SearchBar className="subcontractor-ratecardSearchBar" placeholder="Search anything here .." />
        <div className="subcontractor-view-documents-outer-div">
          <Styles>
            <Table columns={columns} data={Tabledata} subcontractorEntity={''} currency={''} renderRowSubComponent={''} loading={''} />
          </Styles>
        </div>
      </Paper>
    </div>
  );
};

const mapStateToProps = (setState: IRootState) => ({
  personEntity: setState.person.entity,
  loading: setState.timesheetSummary.loading,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PersonRateCard);
