/* eslint-disable complexity */
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import autoTable from 'jspdf-autotable';
import moment, { now } from 'moment';
import { grey } from '@material-ui/core/colors';
import logo from '../../../../content/images/logo-2.png';
import pdfHeader from '../../../../content/images/pdf_header.png';
import { getDocument, GlobalWorkerOptions} from 'pdfjs-dist';

import React from 'react';
import tableContent from './SummaryPdfTable';
import { render } from '@testing-library/react';

GlobalWorkerOptions.workerSrc = 'https://cdn.jsdelivr.net/npm/pdfjs-dist@4.10.38/build/pdf.worker.min.mjs';

export const exportPDF = async (data, filename) => {
  const unit = 'px';
  const size = [500, 700];
  const orientation = 'portrait';

  const doc = new jsPDF(orientation, unit, size);

  const pdf_header = pdfHeader;
  doc.addImage(pdf_header, 'PNG', 0, 0, doc.internal.pageSize.getWidth(), 130);

  doc.addFont('content/fonts/Roboto-Regular.ttf', 'Roboto', 'normal');
  doc.setFont('Roboto');

  doc.setFontSize(25).setFont('Roboto', 'bold').setTextColor('#5833BE').text('Vetting Report', 40, 30);
  doc.setFontSize(11).setFont(undefined, 'bold').setTextColor('white').text('REPORT PREPARED FOR:', 42, 60);
  doc
    .setFontSize(11)
    .setFont(undefined, 'normal')
    .setTextColor('black')
    .text(`${data['summaryOrganization'] ? data['summaryOrganization'].name : '' }`, 42, 72);
  doc.setFontSize(11).setFont(undefined, 'bold').setTextColor('white').text('CANDIDATE NAME:', 200, 60);
  doc
    .setFontSize(11)
    .setFont(undefined, 'normal')
    .setTextColor('black')
    .text(
      `${data['personalInformation'].first_name ? data['personalInformation'].first_name : ''} ${
        data['personalInformation'].middle_name ? data['personalInformation'].middle_name : ''} ${
          data['personalInformation'].last_name ? data['personalInformation'].last_name : ''
      }`,
      200,
      72
    );
  const img = logo;
  doc.addImage(img, 'PNG', 380, 15, 85, 20);

  doc.setFont('Roboto', 'normal');
  // First Page Start

  if (data['summaryOrganization'] && data['summaryOrganization']['setting'].logo) {
    const organizationLogo = `${
      data['summaryOrganization'] && data['summaryOrganization']['setting'].logo && data['summaryOrganization']['setting'].logo.file
    }`;

    doc.addImage(
      organizationLogo,
      `${data['summaryOrganization'] && data['summaryOrganization']['setting'].logo && data['summaryOrganization']['setting'].logo.ext}`,
      (doc.internal.pageSize.width - 70) / 2,
      150,
      70,
      60
    );
  }

  const Text = doc.getTextWidth(`Report generated on: ${moment().format('DD MMM YYYY')}`);
  doc.setFontSize(11).text(`Report Generated On: ${moment().format('DD MMM YYYY')}`, (doc.internal.pageSize.width - Text) / 2, 220);

  autoTable(doc, {
    html: '#screenerDetail',
    theme: 'plain',
    startY: 280,
    styles: { halign: 'center' },
    headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 214], fontSize: 11, lineWidth: 0.1 },
    bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 11, lineWidth: 0.1, lineColor: [216, 216, 216] },
    columnStyles: {
      0: { cellWidth: 145 }, // Set width for the first column
      1: { cellWidth: 145 }, // Set width for the second column
      2: { cellWidth: 145 }, // Set width for the third column
    },
  });
  if (
    (data['summaryPerson'] && data['summaryPerson']['vetting_status'] === 'InvalidApplication') ||
    (data['summaryPerson'] && data['summaryPerson'].vetting_status === 'Failed')
  ) {
    doc.setFontSize(11).setTextColor('red').text('Candidate does not meet the requirements of BS7858 2019', 253, 350);
  }

  autoTable(doc, {
    html: '#candidateInfo',
    theme: 'plain',
    startY: (doc as any).previousAutoTable.finalY + 0,
    styles: { halign: 'center' },
    headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 214], fontSize: 11, lineWidth: 0.1 },
    bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 11, lineWidth: 0.1, lineColor: [216, 216, 216] },
    columnStyles: {
      0: { cellWidth: 145 }, // Set width for the first column
      1: { cellWidth: 145 }, // Set width for the second column
      2: { cellWidth: 145 }, // Set width for the third column
    },
  });

  autoTable(doc, {
    html: '#candidateSummary',
    theme: 'plain',
    startY: (doc as any).previousAutoTable.finalY + 50,
    styles: { halign: 'left' },
    headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 214], fontSize: 11, lineWidth: 0.1 },
    bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 11, lineWidth: 0.1, lineColor: [216, 216, 216] },
  });

  // doc
  //   .setFontSize(11)
  //   .setTextColor('red')
  //   .text('Candidate failed to provide monthly bank statements from March 2021 – May 2021 to cover period of unemployment.', 30, 480);
  doc.setTextColor('black');

  const ReportText = doc.getTextWidth('Report generated by: Smart Workforce');
  doc
    .setFontSize(11)
    .setFont(undefined, 'normal')
    .text('Report generated by: Smart Workforce', (doc.internal.pageSize.width - ReportText) / 2, 695);
  // First Page End
  doc.addPage();
  // Second Page Start

  const signatureImage = data.signed_off_data ? data.signed_off_data.signature : null;
  const tableRows = [
    [{ content: 'SIGN OFF DETAILS', colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', textColor: [0,0,214]} }],
    [
      'Date & Time',
      data.signed_off_data
        ? `Date: ${moment(data.signed_off_data.created_at).format('DD MMM YYYY')} | Time: ${moment(data.signed_off_data.created_at).format('h:mm A z')}`
        : ''
    ],
    ['Responsible Person', data.signed_off_data ? data.signed_off_data.signed_by.first_name + ' ' +data.signed_off_data.signed_by.last_name : ''],
    ['Job Title', data.signed_off_data ? data.signed_off_data.signed_by.designations[0].name : ''],
    ['Documents Checked', data.signed_off_data?.document_check === true ?  'Yes' : 'No'],
    ['Relationship Checked', data.signed_off_data?.relationship_check === true ? 'Yes' : 'No'],
    ['Address Checked', data.signed_off_data?.address_check === true ? 'Yes': 'No' ],
    ['Comments', data?.signed_off_data?.comments ? data.signed_off_data.comments : ''],
    ['Signature', ''],
  ];

  autoTable(doc, {
    body: tableRows,
    theme: 'plain',
    startY: 60,
    styles: { halign: 'center' },
    headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 214], fontSize: 11, lineWidth: 0.1 },
    bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 11, lineWidth: 0.1, lineColor: [216, 216, 216] },
    didDrawCell(cellData) {
      if (cellData.column.index === 1 && cellData.row.index === 8 && signatureImage) {
        doc.addImage(
          signatureImage,
          'PNG',
          cellData.cell.x + 4,
          cellData.cell.y + 4,
          150,
          70
        );
      }
    },
    didParseCell(data) {
      if (data.row.index === 8 && data.column.index === 1) {
        data.cell.styles.minCellHeight = 80;
      }
    },
  });

  autoTable(doc, {
    html: '#candidateInfo',
    theme: 'plain',
    startY: (doc as any).previousAutoTable.finalY + 20,
    styles: { halign: 'center' },
    headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 214], fontSize: 11, lineWidth: 0.1 },
    bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 11, lineWidth: 0.1, lineColor: [216, 216, 216] },
  });
  autoTable(doc, {
    html: '#personalInformation',
    theme: 'plain',
    startY: (doc as any).previousAutoTable.finalY + 20,
    styles: { halign: 'left' },
    headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 214], fontSize: 11, lineWidth: 0.1 },
    bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 11, lineWidth: 0.1, lineColor: [216, 216, 216] },
  });

  doc.setFontSize(12).setTextColor('#0000d6').setFont(undefined, 'bold').text('SIA LICENSE DETAILS', 30, (doc as any).previousAutoTable.finalY + 20);
  autoTable(doc, {
    html: '#licenseDetail',
    theme: 'plain',
    startY: (doc as any).previousAutoTable.finalY + 30,
    styles: { halign: 'left' },
    headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 214], fontSize: 11, lineWidth: 0.1 },
    bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 11, lineWidth: 0.1, lineColor: [216, 216, 216] },
  });

  doc
    .setFontSize(11)
    .setFont(undefined, 'normal')
    .text('Report generated by: Smart Workforce', (doc.internal.pageSize.width - ReportText) / 2, 695);
  // Second Page End

  doc.addPage();

  // Third Page Start

  doc.setFontSize(15).setTextColor('#0000d6').setFont(undefined, 'bold').text('EMPLOYMENT HISTORY', 185, 60);

  const candidateTables = document.querySelectorAll('[id^="employmentDetail-"]'); // Select all candidate tables
  const refereeTables = document.querySelectorAll('[id^="refereeDetail-"]'); // Select all referee tables
  const responseTables = document.querySelectorAll('[id^="empRefEmailResponse-"]'); // Select all referee tables
  const emailTrackTables = document.querySelectorAll('[id^="refEmailTracking-"]'); // Select all referee tables

    const allTables = [];
    const maxLength = Math.max(candidateTables.length, refereeTables.length, responseTables.length, emailTrackTables.length);

    for (let i = 0; i < maxLength; i++) {
      if (candidateTables[i]) allTables.push(candidateTables[i]); // Add candidate table if it exists
      if (refereeTables[i]) allTables.push(refereeTables[i]); // Add referee table if it exists
      if (responseTables[i]) allTables.push(responseTables[i]); // Add referee table if it exists
      if (emailTrackTables[i]) allTables.push(emailTrackTables[i]); // Add referee table if it exists
    }

  allTables.forEach((table, index) => {
    autoTable(doc, {
      html: `#${table.id}`, // Dynamically fetch the table id
      theme: 'plain',
      startY: index === 0 ? 110 : (doc as any).previousAutoTable.finalY + 30, // Positioning
      styles: { halign: 'left' },
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 214],
        fontSize: 11,
        lineWidth: 0.1,
      },
      bodyStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        fontSize: 11,
        lineWidth: 0.9,
        lineColor: [216, 216, 216],
      },
    });
  });
  
  if (data['summaryEmploymentHistoryFiles'] && data['summaryEmploymentHistoryFiles'].length === 2) {
    doc.addPage();
  }
  autoTable(doc, {
    html: '#supportingLiterature',
    theme: 'plain',
    startY: (doc as any).previousAutoTable.finalY + 20,

    styles: { halign: 'left' },
    headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 214], fontSize: 11, lineWidth: 0.1 },
    bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 11, lineWidth: 0.1, lineColor: [216, 216, 216] },
  });
  if (data['summaryEmploymentReferences'] && data['summaryEmploymentReferences'].length === 2) {
    doc.addPage();
  }

  doc
    .setFontSize(11)
    .setFont(undefined, 'normal')
    .text('Report generated by: Smart Workforce', (doc.internal.pageSize.width - ReportText) / 2, 695);
  // Third Page End

  if (data['education']) {
    doc.addPage();
    doc.setFontSize(15).setTextColor('#0000d6').setFont(undefined, 'bold').text('Education History', 185, 60);

    const educationTables = document.querySelectorAll('[id^="educationHistory-"]'); // Select all candidate tables

    educationTables.forEach((table, index) => {
      autoTable(doc, {
        html: `#${table.id}`, // Dynamically fetch the table id
        theme: 'plain',
        startY: index === 0 ? 110 : (doc as any).previousAutoTable.finalY + 30, // Positioning
        styles: { halign: 'left' },
        headStyles: {
          fillColor: [255, 255, 255],
          textColor: [0, 0, 214],
          fontSize: 11,
          lineWidth: 0.1,
        },
        bodyStyles: {
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 11,
          lineWidth: 0.9,
          lineColor: [216, 216, 216],
        },
      });
    });

    let yPoscurrentY = (doc as any).previousAutoTable.finalY + 10; // Start after the last table
    const imgWidth = 250;
    const imgHeight = 250;

      data['summaryEducation']?.forEach((education, index) => {
        if(education.proof){
          doc.addImage(education.proof?.file, education.proof?.ext, 40, yPoscurrentY, imgWidth, imgHeight);
        }
        yPoscurrentY += imgHeight + 10; // Add image height + some margin
      });

   
      
    doc
      .setFontSize(11)
      .setFont(undefined, 'normal')
      .setTextColor('black')
      .text('Report generated by: Smart Workforce', (doc.internal.pageSize.width - ReportText) / 2, 695);
  

  doc.addPage();

  const educationRefereeTables = document.querySelectorAll('[id^="educationReferee-"]');
  const educationRefEmailResponse = document.querySelectorAll('[id^="educationRefEmailResponse-"]');
  const educationRefEmailTracking = document.querySelectorAll('[id^="educationRefEmailTracking-"]');

    const allEducationTables = [];
    const educationMaxLength = Math.max(educationRefereeTables.length, educationRefEmailResponse.length, educationRefEmailTracking.length);

    for (let i = 0; i < educationMaxLength; i++) {
      if (educationRefereeTables[i]) allEducationTables.push(educationRefereeTables[i]);
      if (educationRefEmailResponse[i]) allEducationTables.push(educationRefEmailResponse[i]);
      if (educationRefEmailTracking[i]) allEducationTables.push(educationRefEmailTracking[i]);
    }

    allEducationTables.forEach((table, index) => {
    autoTable(doc, {
      html: `#${table.id}`, // Dynamically fetch the table id
      theme: 'plain',
      startY: index === 0 ? 110 : (doc as any).previousAutoTable.finalY + 30, // Positioning
      styles: { halign: 'left' },
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 214],
        fontSize: 11,
        lineWidth: 0.1,
      },
      bodyStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        fontSize: 11,
        lineWidth: 0.9,
        lineColor: [216, 216, 216],
      },
    });
  });
}

  doc.addPage();

  // Fourth Page Start
  autoTable(doc, {
    html: '#activityClaimDetail',
    theme: 'plain',
    startY: 60,

    styles: { halign: 'left' },
    headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 214], fontSize: 11, lineWidth: 0.1 },
    bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 11, lineWidth: 0.1, lineColor: [216, 216, 216] },
  });
  autoTable(doc, {
    html: '#supportingLiterature2',
    theme: 'plain',
    startY: (doc as any).previousAutoTable.finalY + 20,

    styles: { halign: 'left' },
    headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 214], fontSize: 11, lineWidth: 0.1 },
    bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 11, lineWidth: 0.1, lineColor: [216, 216, 216] },
  });
  autoTable(doc, {
    html: '#referenceDetail2',
    theme: 'plain',
    startY: (doc as any).previousAutoTable.finalY + 20,

    styles: { halign: 'left' },
    headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 214], fontSize: 11, lineWidth: 0.1 },
    bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 11, lineWidth: 0.1, lineColor: [216, 216, 216] },
  });

  doc
    .setFontSize(11)
    .setFont(undefined, 'normal')
    .text('Report generated by: Smart Workforce', (doc.internal.pageSize.width - ReportText) / 2, 695);
  // Fourth Page End


  // Fifth Page Start

  if (data['personalInformation'] && data['personalInformation'].sia_license_image_front) {

  doc.addPage();

  doc.setFontSize(15).setTextColor('#0000d6').setFont(undefined, 'bold').text('SIA License', 185, 60);

  doc.setFontSize(15).setTextColor('#0000d6').setFont(undefined, 'bold').text('Front Side', 80, 90);

    const siaLicenseFrontImage = `${
      data['personalInformation'] &&
      data['personalInformation'].sia_license_image_front &&
      data['personalInformation'].sia_license_image_front.file
    }`;

    const siaLicenseBackImage = `${
      data['personalInformation'] &&
      data['personalInformation'].sia_license_image_back &&
      data['personalInformation'].sia_license_image_back.file
    }`;

    if (data['personalInformation'].sia_license_image_front.ext !== 'pdf') {
      doc.addImage(
        siaLicenseFrontImage,
        `${
          data['personalInformation'] &&
          data['personalInformation'].sia_license_image_front &&
          data['personalInformation'].sia_license_image_front.ext
        }`,
        100,
        110,
        300,
        150
      );
    }
    if (data['personalInformation'].sia_license_image_back.ext !== 'pdf') {

      doc.setFontSize(15).setTextColor('#0000d6').setFont(undefined, 'bold').text('Back Side', 80, 300);
      doc.addImage(
        siaLicenseBackImage,
        `${
          data['personalInformation']?.sia_license_image_back?.ext
        }`,
        100,
        320,
        300,
        150
      );
    }
  }

  doc
    .setFontSize(11)
    .setFont(undefined, 'normal')
    .text('Report generated by: Smart Workforce', (doc.internal.pageSize.width - ReportText) / 2, 695);
  // Fifth Page End
  doc.addPage();
  // Sixth Page Start
  autoTable(doc, {
    html: '#appendixDetail',
    theme: 'plain',
    startY: 60,

    styles: { halign: 'center' },
    headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 214], fontSize: 11, lineWidth: 0.1, halign: 'left' },
    bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 11, lineWidth: 0.1, lineColor: [216, 216, 216] },
  });
  doc
    .setFontSize(11)
    .setFont(undefined, 'normal')
    .text('Report generated by: Smart Workforce', (doc.internal.pageSize.width - ReportText) / 2, 695);
  // Sixth Page EndCreditSafe

  doc.addPage();

  // Seven Page Start

  const getCreditSafeRequestedDate = () => {
        if(data['summaryVettingActivity']){
          const requestedDate =  data['summaryVettingActivity'].filter((item) => item.description === "NewApplicant-->InProgress");
          if(requestedDate){
            return moment(requestedDate[0]?.created_at).format('D MMM YYYY, h:mm a');
          }
        }
        return null;
  }

  const getCreditSafeCompletedDate = () => {
    if(data['connect_api_information']){
        return moment(data['connect_api_information']?.created_at).format('D MMM YYYY, h:mm a');
    }
    return '-';
    
  }

  doc
    .setFontSize(15)
    .setTextColor('#0000d6')
    .setFont(undefined, 'bold')
    .text(
      data['connect_api_information'] ? `CH – ${data['connect_api_information']['customerId']} SANCTIONS` : 'CH – 1168549 SANCTIONS',
      185,
      60
    );
  doc.setFontSize(10).setFont(undefined, 'normal').setTextColor('black').text(`Requested: ${getCreditSafeRequestedDate()}`, 200, 75);
  doc.setFontSize(10).setFont(undefined, 'normal').setTextColor('black').text(`Completed: ${getCreditSafeCompletedDate()}`, 200, 85);

  autoTable(doc, {
    html: '#detailProcessed',
    theme: 'plain',
    startY: 120,

    styles: { halign: 'left' },
    headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 214], fontSize: 11, lineWidth: 0.1 },
    bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 11, lineWidth: 0.1, lineColor: [216, 216, 216] },
  });
  autoTable(doc, {
    html: '#ragScore',
    theme: 'plain',
    startY: (doc as any).previousAutoTable.finalY + 20,

    styles: { halign: 'left' },
    headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 214], fontSize: 11, lineWidth: 0.1 },
    bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 11, lineWidth: 0.1, lineColor: [216, 216, 216] },
  });

  autoTable(doc, {
    html: '#alerts',
    theme: 'plain',
    startY: (doc as any).previousAutoTable.finalY + 20,

    styles: { halign: 'left' },
    headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 214], fontSize: 11, lineWidth: 0.1 },
    bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 11, lineWidth: 0.1, lineColor: [216, 216, 216] },
  });

  doc
    .setFontSize(11)
    .setFont(undefined, 'normal')
    .text('Report generated by: Smart Workforce', (doc.internal.pageSize.width - ReportText) / 2, 695);
  // Seven Page End

  doc.addPage();

  // Eight Page Start

  doc
    .setFontSize(15)
    .setTextColor('#0000d6')
    .setFont(undefined, 'bold')
    .text(
      data['connect_api_information']
        ? `CH – ${data['connect_api_information']['customerId']} ADVERSE FINANCIAL DETAIL`
        : 'CH – 1168683 ADVERSE FINANCIAL DETAIL',
      145,
      60
    );
  // doc
  //   .setFontSize(10)
  //   .setFont(undefined, 'normal')
  //   .setTextColor('black')
  //   .text(`Requested: ${moment().format('MM/DD/YYYY hh:mm a')}`, 200, 75);
  // doc.setFontSize(10).setFont(undefined, 'normal').setTextColor('black').text('Completed; 14/1/2022, 11:00 pm', 200, 85);

  autoTable(doc, {
    html: '#detailProcessed2',
    theme: 'plain',
    startY: 120,

    styles: { halign: 'left' },
    headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 214], fontSize: 11, lineWidth: 0.1 },
    bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 11, lineWidth: 0.1, lineColor: [216, 216, 216] },
  });
  autoTable(doc, {
    html: '#identityInformation',
    theme: 'plain',
    startY: (doc as any).previousAutoTable.finalY + 20,

    styles: { halign: 'left' },
    headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 214], fontSize: 11, lineWidth: 0.1 },
    bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 11, lineWidth: 0.1, lineColor: [216, 216, 216] },
  });
  autoTable(doc, {
    html: '#electoralRole',
    theme: 'plain',
    startY: (doc as any).previousAutoTable.finalY + 20,

    styles: { halign: 'left' },
    headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 214], fontSize: 11, lineWidth: 0.1 },
    bodyStyles: {
      fillColor: [255, 255, 255],
      textColor: [0, 0, 0],
      fontSize: 11,
      lineWidth: 0.1,
      lineColor: [216, 216, 216],
      halign: 'center',
    },
  });

  doc
    .setFontSize(11)
    .setFont(undefined, 'normal')
    .text('Report generated by: Smart Workforce', (doc.internal.pageSize.width - ReportText) / 2, 695);
  // Eight Page End

  doc.addPage();

  // Nine Page Start
  autoTable(doc, {
    html: '#rollingRegister',
    theme: 'plain',
    startY: 60,

    styles: { halign: 'left' },
    headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 214], fontSize: 11, lineWidth: 0.1 },
    bodyStyles: {
      fillColor: [255, 255, 255],
      textColor: [0, 0, 0],
      fontSize: 11,
      lineWidth: 0.1,
      lineColor: [216, 216, 216],
      halign: 'center',
    },
  });

  doc
    .setFontSize(11)
    .setFont(undefined, 'normal')
    .text('Report generated by: Smart Workforce', (doc.internal.pageSize.width - ReportText) / 2, 695);
  // Nine Page End

  // Ten Page Start
  doc.addPage();
  doc.setFontSize(11).setTextColor('#0000d6').setFont(undefined, 'bold').text('ADVERSE FINANCIAL HISTORY SUMMARY', 30, 155);
  doc.setFontSize(10).setTextColor('black').setFont('Roboto', 'bold').text('Potential aliases', 30, 165);
  doc.setFontSize(10).setTextColor('black').setFont('Roboto', 'bold').text('No', 290, 165);
  doc.setFontSize(10).setFont('Roboto', 'normal').text('Value of most recent CCJ (satisfied, unsatisfied and bankruptcy)', 30, 180);
  doc.setFontSize(10).setFont('Roboto', 'normal').text('$0', 290, 180);
  doc.setFontSize(10).setFont('Roboto', 'normal').text('Number of CCJs last year (satisfied, unsatisfied and bankruptcy)', 30, 190);
  doc.setFontSize(10).setFont('Roboto', 'normal').text('None Found', 290, 190);
  doc.setFontSize(10).setFont('Roboto', 'normal').text('Number of CCJs last 6 years', 30, 200);
  doc.setFontSize(10).setFont('Roboto', 'normal').text('None Found', 290, 200);
  doc.setFontSize(10).setFont('Roboto', 'normal').text('Number of Insolvencies last 6 years', 30, 210);
  doc.setFontSize(10).setFont('Roboto', 'normal').text('None Found', 290, 210);
  doc.setFontSize(10).setFont('Roboto', 'normal').text('Number of bankruptcies, DROs and LILAs last 6 years', 30, 220);
  doc.setFontSize(10).setFont('Roboto', 'normal').text('None Found', 290, 220);
  doc.setFontSize(10).setFont('Roboto', 'normal').text('Number of bankruptcies, DROs and LILAs last 6 years (not discharged)', 30, 230);
  doc.setFontSize(10).setFont('Roboto', 'normal').text('None Found', 290, 230);
  doc.setFontSize(10).setFont('Roboto', 'normal').text('Number of previous linked addresses', 30, 240);
  doc.setFontSize(10).setFont('Roboto', 'normal').text('None Found', 290, 240);
  doc.setFontSize(10).setFont('Roboto', 'normal').text('Number of next linked addresses', 30, 250);
  doc.setFontSize(10).setFont('Roboto', 'normal').text('None Found', 290, 250);
  doc.setFontSize(10).setFont('Roboto', 'normal').text('Number of next linked addresses', 30, 260);
  doc.setFontSize(10).setFont('Roboto', 'normal').text('None Found', 290, 260);

  doc.setFontSize(11).setTextColor('#0000d6').setFont(undefined, 'bold').text('ADVERSE FINANCIAL HISTORY DETAIL', 30, 290);

  doc
    .setFontSize(10)
    .setTextColor('black')
    .setFont('Roboto', 'normal')
    .text('Number of Insolvencies last year (Linked next address)', 30, 310);
  doc.setFontSize(10).setFont('Roboto', 'normal').text('None Found', 290, 310);
  doc.setFontSize(10).setFont('Roboto', 'normal').text('Number of Insolvencies last 6 years (Linked previous addresses)', 30, 320);
  doc.setFontSize(10).setFont('Roboto', 'normal').text('None Found', 290, 320);
  doc.setFontSize(10).setFont('Roboto', 'normal').text('Number of Insolvencies last 6 years (Linked next address)', 30, 330);
  doc.setFontSize(10).setFont('Roboto', 'normal').text('None Found', 290, 330);
  doc.setFontSize(10).setFont('Roboto', 'normal').text('Number of CCJs last 6 years (Linked previous addresses)', 30, 340);
  doc.setFontSize(10).setFont('Roboto', 'normal').text('None Found', 290, 340);
  doc.setFontSize(10).setFont('Roboto', 'normal').text('Number of CCJs last 6 years (Linked next address)', 30, 350);
  doc.setFontSize(10).setFont('Roboto', 'normal').text('None Found', 290, 350);
  doc.setFontSize(10).setFont('Roboto', 'normal').text('Number of satisfied CCJs last 6 years (linked previous addresses)', 30, 360);
  doc.setFontSize(10).setFont('Roboto', 'normal').text('None Found', 290, 360);
  doc.setFontSize(10).setFont('Roboto', 'normal').text('Number of satisfied CCJs last 6 years (linked next address)', 30, 370);
  doc.setFontSize(10).setFont('Roboto', 'normal').text('None Found', 290, 370);
  doc.setFontSize(10).setFont('Roboto', 'normal').text('Number of bankruptcies last 6 years (ICO, linked previous addresses)', 30, 380);
  doc.setFontSize(10).setFont('Roboto', 'normal').text('None Found', 290, 380);
  doc.setFontSize(10).setFont('Roboto', 'normal').text('Number of bankruptcies last 6 years (ICO, linked next address)', 30, 390);
  doc.setFontSize(10).setFont('Roboto', 'normal').text('None Found', 290, 390);
  doc.setFontSize(10).setFont('Roboto', 'normal').text('Number of bankruptcies, DROs and LILAs last 6 years', 30, 400);
  doc.setFontSize(10).setFont('Roboto', 'normal').text('(not discharged, previous linked addresses)', 30, 410);
  doc.setFontSize(10).setFont('Roboto', 'normal').text('None Found', 290, 410);
  doc.setFontSize(10).setFont('Roboto', 'normal').text('Number of IVAs last 6 years (not satisfied, previous linked addresses)', 30, 420);
  doc.setFontSize(10).setFont('Roboto', 'normal').text('None Found', 290, 420);
  doc
    .setFontSize(11)
    .setFont(undefined, 'normal')
    .text('Report generated by: Smart Workforce', (doc.internal.pageSize.width - ReportText) / 2, 695);
  // Ten Page End

  doc.addPage();

  // Ten page Start

  autoTable(doc, {
    html: '#insolvencyInformation',
    theme: 'plain',
    startY: 60,

    styles: { halign: 'left' },
    headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 214], fontSize: 11, lineWidth: 0.1 },
    bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 11, lineWidth: 0.1, lineColor: [216, 216, 216] },
  });

  autoTable(doc, {
    html: '#correctionDisputeData',
    theme: 'plain',
    startY: (doc as any).previousAutoTable.finalY + 20,

    styles: { halign: 'left' },
    headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 214], fontSize: 11, lineWidth: 0.1 },
    bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 11, lineWidth: 0.1, lineColor: [216, 216, 216] },
  });

  autoTable(doc, {
    html: '#aliases',
    theme: 'plain',
    startY: (doc as any).previousAutoTable.finalY + 20,

    styles: { halign: 'left' },
    headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 214], fontSize: 11, lineWidth: 0.1 },
    bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 11, lineWidth: 0.1, lineColor: [216, 216, 216] },
  });

  doc
    .setFontSize(11)
    .setFont(undefined, 'normal')
    .text('Report generated by: Smart Workforce', (doc.internal.pageSize.width - ReportText) / 2, 695);
  // Ten Page End

  const convertPdfToImage = async (pdfFile) => {
    const loadingTask = getDocument(pdfFile);
    const pdf = await loadingTask.promise;
    const numPages = pdf.numPages;  // Get the total number of pages
    const images = [];
  
    for (let pageNum = 1; pageNum <= numPages; pageNum++) {
      const page = await pdf.getPage(pageNum);
      const viewport = page.getViewport({ scale: 1.5 });
  
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
  
    canvas.width = viewport.width;
    canvas.height = viewport.height;
  
    await page.render({
      canvasContext:ctx,
      viewport,
    }).promise;
  
      const imageData = canvas.toDataURL('image/png');
      images.push(imageData);
    }
  
    return images;
  };

  const renderDocument = async (doc, documentData, title, originalSeenData) => {
    const file = documentData.file;
    const ext = documentData.ext;
  
    doc.addPage();
    doc.setFontSize(15).setTextColor('#0000d6').setFont(undefined, 'bold').text('Documents', 185, 60);
    doc.setFontSize(15).setTextColor('#000000').setFont(undefined, 'bold').text('', 60, 75);
    doc.setFontSize(13).setTextColor('#000000').setFont(undefined, 'bold').text(title, 60, 90);

    if (ext === 'pdf') {
      const pdfImages = await convertPdfToImage(file);
      pdfImages.forEach((pdfImage, index) => {
        if (index > 0) {
          doc.addPage();
        }
        doc.addImage(pdfImage, 'PNG', 40, 100, 400, 460);
      });
    } else {
      doc.addImage(file, ext, 40, 100, 400, 460);
    }

    if (originalSeenData.original_seen === true) {
      doc.setFontSize(11).setTextColor('#000000').setFont(undefined, 'normal').text('Original Seen:', 60, 610);
      doc.setFontSize(11).setTextColor('#000000').setFont(undefined, 'normal').text('Yes', 140, 610);
      doc.setFontSize(11).setTextColor('#000000').setFont(undefined, 'normal').text('Original seen by:', 60, 620);
      doc
        .setFontSize(11)
        .setTextColor('#000000')
        .setFont(undefined, 'normal')
        .text(`${originalSeenData.original_seen_by.first_name} ${originalSeenData.original_seen_by.last_name}`, 140, 620);
  
      doc.setFontSize(11).setTextColor('#000000').setFont(undefined, 'normal').text('Original seen at:', 60, 630);
      doc.setFontSize(11).setTextColor('#000000').setFont(undefined, 'normal').text(`${moment(originalSeenData.original_seen_at).format('DD MMM YYYY')}  ${moment(originalSeenData.original_seen_at).format('h:mm A z')}`, 140, 630);
    }else{
      doc.setFontSize(11).setTextColor('#000000').setFont(undefined, 'normal').text('Original Seen:', 60, 610);
      doc.setFontSize(11).setTextColor('#000000').setFont(undefined, 'normal').text('No', 140, 610);
    }
  
    doc
      .setFontSize(11)
      .setFont(undefined, 'normal')
      .text('Report generated by: Smart Workforce', (doc.internal.pageSize.width - ReportText) / 2, 695);
  };


  if (data.documents && data.documents.proof_of_id_data && data.documents.proof_of_id_data.file) {
    const originalSeenData = {
      original_seen: data.documents.proof_of_id?.original_seen,
      original_seen_by: data.documents.proof_of_id?.original_seen_by,
      original_seen_at: data.documents.proof_of_id?.original_seen_at
    };
    await renderDocument(doc, data.documents.proof_of_id_data, 'Proof of ID', originalSeenData);
  }
  // Page end

  if (data.documents && data.documents.proof_of_address_data && data.documents.proof_of_address_data.file) {
    const originalSeenData = {
      original_seen: data.documents.proof_of_address?.original_seen,
      original_seen_by: data.documents.proof_of_address?.original_seen_by,
      original_seen_at: data.documents.proof_of_address?.original_seen_at
    };
    await renderDocument(doc, data.documents.proof_of_address_data, 'Proof of Address', originalSeenData);
  }

  if (data.documents && data.documents.right_to_work_data && data.documents.right_to_work_data.file) {
    const originalSeenData = {
      original_seen: data.documents.right_to_work?.original_seen,
      original_seen_by: data.documents.right_to_work?.original_seen_by,
      original_seen_at: data.documents.right_to_work?.original_seen_at
    };
    await renderDocument(doc, data.documents.right_to_work_data, 'Right to Work', originalSeenData);
  }

  // doc.addPage();

  // Eleven Page Start

  // doc.setFontSize(15).setTextColor('#0000d6').setFont(undefined, 'bold').text('LINKED ADDRESSES', 185, 60);
  // doc.setTextColor('black');

  // autoTable(doc, {
  //   html: '#electoralRole2',
  //   theme: 'plain',
  //   startY: 100,

  //   styles: { halign: 'left' },
  //   headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 214], fontSize: 11, lineWidth: 0.1 },
  //   bodyStyles: {
  //     fillColor: [255, 255, 255],
  //     textColor: [0, 0, 0],
  //     fontSize: 11,
  //     lineWidth: 0.1,
  //     lineColor: [216, 216, 216],
  //     halign: 'center',
  //   },
  // });
  // autoTable(doc, {
  //   html: '#rollingRegister2',
  //   theme: 'plain',
  //   startY: (doc as any).previousAutoTable.finalY + 20,

  //   styles: { halign: 'left' },
  //   headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 214], fontSize: 11, lineWidth: 0.1 },
  //   bodyStyles: {
  //     fillColor: [255, 255, 255],
  //     textColor: [0, 0, 0],
  //     fontSize: 11,
  //     lineWidth: 0.1,
  //     lineColor: [216, 216, 216],
  //     halign: 'center',
  //   },
  // });

  // doc
  //   .setFontSize(11)
  //   .setFont(undefined, 'normal')
  //   .text('Report generated by: Smart Workforce', (doc.internal.pageSize.width - ReportText) / 2, 695);
  // Eleven Page End
  doc.addPage();
  // Tweleve Page Start
  doc.setFontSize(15).setTextColor('#0000d6').setFont(undefined, 'bold').text('GDPR & PRIVACY AGREEMENT', 175, 60);

  doc.setTextColor('black');

  autoTable(doc, {
    html: '#privacyIntroduction',
    theme: 'plain',
    startY: 90,

    styles: { halign: 'left' },
    headStyles: { fillColor: [255, 255, 255], textColor: 'black', fontSize: 10, lineWidth: 0.1, fontStyle: 'normal' },
    bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 11, lineWidth: 0.1, lineColor: [216, 216, 216] },
  });

  autoTable(doc, {
    html: '#privacyPolicy',
    theme: 'plain',
    startY: (doc as any).previousAutoTable.finalY + 20,

    styles: { halign: 'left' },
    headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 214], fontSize: 10, lineWidth: 0.1 },
    bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 11, lineWidth: 0.1, lineColor: [216, 216, 216] },
  });

  doc.setFontSize(10).setFont('Roboto', 'bold').text('What data do we hold?', 30, 420);
  doc
    .setFontSize(10)
    .setFont('Roboto', 'normal')
    .text(
      'We hold and process data provided by and consented for by you as part of the application/vetting process carried out by and as\n' +
        'contractually requested by our clients and your employer.',
      30,
      440
    );
  doc
    .setFontSize(10)
    .text(
      'This data will have been provided in writing or electronically as part of an initial application or as part of ongoing vetting and \n' +
        'screening.',
      30,
      465
    );
  doc.setFontSize(10).text('This data includes:', 30, 490);
  doc
    .setFontSize(10)
    .text(
      'Your Name, Address, Date of Birth and other personal data. Your contact details including mobile phone number, landline and email\n' +
        'addresses Details of your employment or other background history. Details of your character referee’s including name, address and\n' +
        'contact details. Details of your Driving license and other Identity Documents Details of your SIA license including number and\n' +
        'renewal date Records of any contact made with us during the vetting and screening process. Verbally or if provided in writing.\n' +
        'Information obtained from third parties as part of the vetting and screening process.',
      30,
      505
    );

  doc
    .setFontSize(11)
    .setFont(undefined, 'normal')
    .text('Report generated by: Smart Workforce', (doc.internal.pageSize.width - ReportText) / 2, 695);
  // Tweleve Page End

  doc.addPage();

  // Thirteen Page Start

  doc
    .setFontSize(10)
    .text(
      'Personnel information obtained from credit reference agencies if applicable to requested vetting and screening and to include\n' +
        '(Electoral Roll, Public Data, CCJ’s, Bankruptcies and Insolvencies) Your residency and/or citizenship status, if relevant, such as\n' +
        'your nationality, your length of residency in the UK and/or whether you have the permanent right to reside in UK Criminal Record\n' +
        'History requested via the DBS and Disclosure Scotland if applicable to requested vetting and screening.',
      30,
      60
    );

  doc
    .setFontSize(10)
    .text(
      'All data is held centrally at our Head Office in Huddersfield West Yorkshire. All data is held securely on password protected Servers\n' +
        'and PC’s.',
      30,
      100
    );

  doc.setFontSize(10).text('All backups are encrypted and carried out within the EU.', 30, 120);

  doc
    .setFontSize(10)
    .text(
      'Our internal systems are 2 x hard firewalled and 1 x soft firewalled and all electronic communication is scanned via: MSP\n' +
        'SolarWinds.',
      30,
      140
    );

  doc.setFontSize(10).setFont('Roboto', 'bold').text('Where do we get this data from?', 30, 165);
  doc.setFont('Roboto', 'normal');
  doc
    .setFontSize(10)
    .text(
      'From you when completing an application either electronically or in writing or as part of the ongoing vetting and screening process\n' +
        'requested by your employer and our client.',
      30,
      185
    );

  doc
    .setFontSize(10)
    .text(
      'From other sources such as Credit Reference Agencies, HMRC, DWP, publicly available directories and information (for example,\n' +
        'telephone directory, social media, internet, news articles).',
      30,
      210
    );

  doc
    .setFontSize(10)
    .text(
      'From references returned from the contacts provided by you on your initial application and during ongoing vetting and screening.',
      30,
      235
    );

  doc.setFontSize(10).setFont('Roboto', 'bold').text('What do we use this data for?', 30, 255);
  doc.setFont('Roboto', 'normal');
  doc.setFontSize(10).text('To process your application to the requested vetting and screening standard.', 30, 270);
  doc
    .setFontSize(10)
    .text('We may also share your data with the below third parties as part of the vetting and screening requested.', 30, 280);

  doc.setFontSize(10).text('1. Equifax Plc:', 30, 300);
  doc.setFontSize(10).setTextColor('#3366CC').textWithLink('https://www.equifax.co.uk/crain', 80, 300, {});

  doc.setFontSize(10).setTextColor('black').text('2. Disclosure Scotland', 30, 310);
  doc.setFontSize(10).setTextColor('#3366CC').textWithLink('https://www.mygov.scot/organisations/disclosure-scotland/', 110, 310, {});

  doc.setFontSize(10).setTextColor('black').text('3. DBS', 30, 320);
  doc
    .setFontSize(10)
    .setTextColor('#3366CC')
    .textWithLink('https://www.gov.uk/government/organisations/disclosure-and-barring-service', 55, 320, {});

  doc.setFontSize(10).setTextColor('black').text('4. Owens Online Incorporated', 30, 330);
  doc.setFontSize(10).setTextColor('#3366CC').textWithLink('https://www.owens.com/', 130, 330, {});

  doc.setFontSize(10).setTextColor('black').text('5. The SIA', 30, 340);
  doc.setFontSize(10).setTextColor('#3366CC').textWithLink('https://www.sia.homeoffice.gov.uk', 65, 340, {});

  doc.setTextColor('black');

  doc.setFontSize(10).setFont('Roboto', 'bold').text('Do we pass data cross border and if so, why?', 30, 360);
  doc.setFont('Roboto', 'normal');

  doc.setFontSize(10).text('We are based in the UK but we may pass data cross border for the following reasons:', 30, 380);
  doc
    .setFontSize(10)
    .text(
      'A previous employer or institution or individual as provided by you and consented to by you requires the data to be sent cross border\n' +
        'to receive the required result. You have has consented to an International Credit Check or Criminal Certificate check to be carried\n' +
        'out as part of the checks requested by your employer.',
      30,
      400
    );

  doc.setFontSize(10).setFont('Roboto', 'bold').text('How long do we keep your data?', 30, 440);
  doc.setFont('Roboto', 'normal');

  doc
    .setFontSize(10)
    .text(
      'For as long as we have a reasonable business need to do so. For as long as your application for and your vetting and screening is\n' +
        'ongoing. Your data is retained only whilst of “use” to us.',
      30,
      460
    );

  doc.setFontSize(10).setFont('Roboto', 'bold').text('Your rights under GDPR.', 30, 490);
  doc.setFont('Roboto', 'normal');

  doc.setFontSize(10).text('You have the right to:', 30, 510);
  doc.setFontSize(10).setTextColor('black').text('1. To Access: You have a right to access all data held by the “Data Controller”', 30, 530);
  doc.setFontSize(10).setTextColor('black').text('2. To Rectify: You have a right to request data held is rectified.', 30, 540);
  doc.setFontSize(10).setTextColor('black').text('3. To Erase: You have a right to request your data is erased.', 30, 550);
  doc.setFontSize(10).setTextColor('black').text('4. To withdraw consent: You have the right to withdraw consent', 30, 560);
  doc.setFontSize(10).setTextColor('black').text('5. To make a complaint: All complaints should be made to:', 30, 570);
  doc
    .setFontSize(10)
    .setTextColor('black')
    .text(
      'The Information Commissioner’s Office which enforces data protection laws: https://ico.org.uk/. Or you can contact us directly via',
      30,
      590
    );
  doc.setFontSize(10).setTextColor('#3366CC').textWithLink('info@smartworkforce.co.uk', 30, 600, {});
  doc.setTextColor('black');

  doc
    .setFontSize(11)
    .setFont(undefined, 'normal')
    .text('Report generated by: Smart Workforce', (doc.internal.pageSize.width - ReportText) / 2, 695);
  // Thirteen Page End

  doc.addPage();
  // Fourteen Page Start

  autoTable(doc, {
    html: '#autoMatedGeneratedChecklist',
    theme: 'plain',
    startY: 60,

    styles: { halign: 'left' },
    headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 10, lineWidth: 0.1 },
    bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 11, lineWidth: 0.1, lineColor: [216, 216, 216] },
  });


  doc.setFontSize(10).setTextColor('#0000d6').setFont(undefined, 'bold').text('RIGHT TO OBJECT', 30, 260);
  doc.setFontSize(10).setTextColor('black').setFont(undefined, 'bold').text('Do you have to provide personal information to us?', 30, 280);

  doc
    .setFontSize(10)
    .setTextColor('black')
    .setFont(undefined, 'normal')
    .text(
      'No but if you do not provide this it may mean that we are unable to complete the requested vetting and screening on behalf of your\n' +
        'employer.',
      30,
      300
    );

  doc.setFontSize(10).setTextColor('#0000d6').setFont(undefined, 'bold').text('DATA SUBJECT RIGHTS PROCESSES', 30, 330);

  doc
    .setFontSize(10)
    .setTextColor('black')
    .setFont(undefined, 'normal')
    .text(
      'To make a complaint All complaints should be made to: The Information Commissioner’s Office which enforces data protection laws:',
      30,
      340
    );
  doc
    .setFontSize(10)
    .setTextColor('#3366CC')
    .textWithLink('https://ico.org.uk/. Or you can contact us directly via info@smartworkforce.co.uk', 30, 350, {});
  doc.setFontSize(10).setTextColor('black').setFont(undefined, 'normal').text('To make a request of rights please contact us', 295, 350);
  doc.setFontSize(10).setTextColor('black').setFont(undefined, 'normal').text('directly via', 30, 360);
  doc.setFontSize(10).setTextColor('#3366CC').textWithLink('info@smartworkforce.co.uk', 65, 360, {});

  doc.setFillColor(255, 255, 0);
  doc.rect(30, 384, 195, 10, 'F');
  doc
    .setFontSize(10)
    .setTextColor('black')
    .setFont(undefined, 'normal')
    .text('The candidate selected ‘Agree’ when presented with the above.', 30, 392);

  doc
    .setFontSize(11)
    .setFont(undefined, 'normal')
    .text('Report generated by: Smart Workforce', (doc.internal.pageSize.width - ReportText) / 2, 695);
  // Fourteen Page End

  doc.addPage();

  const textWidth = doc.getTextDimensions('ACCEPTED TERMS AND CONDITIONS').w;
  const pageWidth = doc.internal.pageSize.width;
  const xPos = (pageWidth - textWidth) / 2;

  doc.setFontSize(13).setTextColor('#0000d6').setFont(undefined, 'bold').text('ACCEPTED TERMS AND CONDITIONS', 165, 60);

  doc.setFontSize(10).setTextColor('black').setFont(undefined, 'normal').text('Candidate Terms and Conditions', 30, 90);
  doc
    .setFontSize(10)
    .setTextColor('black')
    .setFont(undefined, 'normal')
    .text(
      'I give consent to my employer and/or Smart Workforce to process the data I have provided as part of my vetting and screening\n' +
        'application and confirm that it is true and complete to the best of my knowledge.',
      30,
      110
    );
  doc
    .setFontSize(10)
    .setTextColor('black')
    .setFont(undefined, 'normal')
    .text(
      'I give consent for my data to be passed from my employer and/or Smart Workforce to Equifax PLC to enable them to complete the\n' +
        'required checks requested as part of my vetting and screening application:',
      30,
      140
    );
  doc
    .setFontSize(10)
    .setTextColor('black')
    .setFont(undefined, 'normal')
    .text(
      'I give consent for my data to be passed from my employer and/or Smart Workforce to those referee’s as provided in writing as part\n' +
        'of the screening and vetting application and during the ongoing process of vetting and screening until completed or ended and not\n' +
        'after.',
      30,
      170
    );
  doc
    .setFontSize(10)
    .setTextColor('black')
    .setFont(undefined, 'normal')
    .text(
      'Smart Workforce will validate by direct contact and issue references to all referee’s provided in writing and verbally as part of the\n' +
        'ongoing screening and vetting process until completed or ended and not after.',
      30,
      200
    );
  doc
    .setFontSize(10)
    .setTextColor('black')
    .setFont(undefined, 'normal')
    .text(
      'By accepting these terms, you have consented to us requesting references to include: Employment, Education,\n' +
        'Self-Employment, DWP and HMRC, Personnel and Documentary evidence to cover any unaccounted periods in your applicable\n' +
        'history. You also consent to the transfer of your information back to your current employer.',
      30,
      230
    );
  doc
    .setFontSize(10)
    .setTextColor('black')
    .setFont(undefined, 'normal')
    .text(
      'You are also confirming that you have read the GDPR Privacy notices from both your employer and Smart Workforce and are aware\n' +
        'of your rights under the GDPR Regulations. You agree to us processing sensitive personal data about you where this is necessary,\n' +
        'for example information about your credit status, whether you have current or previous CCJ’s, bankruptcies or insolvencies.\n' +
        'Whether you are present on any Global Sanctions lists. This search will leave a footprint on your credit file at Equifax PLC. You have\n' +
        'the right to contact Equifax PLC and request this is amended or removed. You also consent to the transfer of your information back\n' +
        'to your current employer.',
      30,
      270
    );
  doc
    .setFontSize(10)
    .setTextColor('black')
    .setFont(undefined, 'normal')
    .text(
      'Your data will be held on our computer databases and in our paper filing systems whilst it of use to us. You have full rights under the\n' +
        'GDPR 2016 Regulations and we have provided a link to these rights.\n' +
        'If you have any concerns in regards to how Smart Workforce will process your data please speak with your Employer or contact us\n' +
        'at: info@smartworkforce.co.uk',
      30,
      330
    );

  doc.setFillColor(255, 255, 0);
  doc.rect(30, 375, 185, 10, 'F');
  doc
    .setFontSize(10)
    .setTextColor('black')
    .setFont(undefined, 'normal')
    .text(
      `${data['personalInformation'] && data['personalInformation'].first_name} ${
        data['personalInformation'] && data['personalInformation'].last_name
      } has accepted these terms and conditions.`,
      30,
      383
    );

  const currentDate = new Date();
  const formattedDate = `${currentDate.getDate()} ${new Intl.DateTimeFormat('en-US', { month: 'short' }).format(
    currentDate
  )} ${currentDate.getFullYear()}, ${currentDate.toLocaleTimeString()}`;

  doc.setFontSize(10).setTextColor('black').setFont(undefined, 'normal').text(`Signed electronically:  ${formattedDate}`, 30, 393);

  doc
    .setFontSize(11)
    .setFont(undefined, 'normal')
    .text('Report generated by: Smart Workforce', (doc.internal.pageSize.width - ReportText) / 2, 695);

  doc.save(filename);
};
