import React from 'react';
import { Translate, translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Button, Grid, FormControl, TextField } from '@material-ui/core';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Alert } from '@material-ui/lab';
import './init.scss';
import { handlePasswordResetInit, reset } from '../password-reset.reducer';

export type IPasswordResetInitProps = DispatchProps;

export class PasswordResetInit extends React.Component<IPasswordResetInitProps> {
  componentWillUnmount() {
    this.props.reset();
  }

  handleValidSubmit = event => {
    this.props.handlePasswordResetInit(event.target.email.value);
    event.preventDefault();
  };

  render() {
    return (
      <div className="password-reset-init-full-container ">
        <Grid item md={12} container justify="center">
          <Grid item md={2}></Grid>
          <Grid item md={8}>
            <h1>
              <Translate contentKey="reset.request.title">Reset your password</Translate>
            </h1>
            <Alert severity="warning">
              <p>
                <Translate contentKey="reset.request.messages.info">Enter the email address you used to register</Translate>
              </p>
            </Alert>
            <form onSubmit={this.handleValidSubmit} className="password-reset-init-form">
              <Grid item md={12} container>
                <Grid item md={12} xs={12}>
                  <FormControl className="password-reset-init-fields">
                    <TextField
                      name="email"
                      label={translate('global.form.email.label')}
                      placeholder={translate('global.form.email.placeholder')}
                      type="email"
                      variant="outlined"
                      required
                      inputProps={{
                        minLength: 5,
                        maxLength: 254,
                      }}
                      data-cy="emailResetPassword"
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item md={12} container>
                <Grid item md={12} xs={12}>
                  <FormControl style={{ display: 'block' }} className="password-reset-init-fields">
                    <Button style={{ color: 'white', backgroundColor: '#3f51b5' }} type="submit" data-cy="submit">
                      <Translate contentKey="reset.request.form.button">Reset password</Translate>
                    </Button>
                  </FormControl>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapDispatchToProps = { handlePasswordResetInit, reset };

type DispatchProps = typeof mapDispatchToProps;

export default connect(null, mapDispatchToProps)(PasswordResetInit);
