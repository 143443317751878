import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: '50%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '10%',
    left: '24%',
    overflow: 'auto',
  },
  checkBoxes: {
    backgroundColor: 'white important!',
    boxShadow: '1px 1px 1px #dfdbdb important!',
    border: '1px solid #cfcccc',
    width: '300px',
    '&:hover': {
      backgroundColor: '#d68fd9',
      color: 'white',
    },
  },
  paperUpdate: {
    position: 'absolute',
    width: '25%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '10%',
    left: '36%',
    overflow: 'auto',
  },
  shiftModalTitle: {
    textAlign: 'center',
    marginTop: '30px',
    marginBottom: '20px',
    color: '#4620B7',
  },
  modalForms: {
    marginBottom: theme.spacing(2),
    minWidth: 120,
    width: '100%',
  },
  modalLabel: {
    alignItems: 'center',
    display: 'flex',
  },
  modalButtonSave: {
    backgroundColor: '#4620b7',
    color: 'white',
    width: '95%',
  },
  modalButtonCancel: {
    backgroundColor: 'white',
    color: 'black',
    width: '95%',
  },
  modalNote: {
    width: '100%',
  },
  modalButton: {
    width: '35%',
  },
  modalInput: {
    width: '75%',
  },
  weekLists: {
    width: '100%',
    display: 'flex',
  },
  modalFormForAutoComplete: {
    marginTop: '0 !important',
  },
  listbox: {
    border: '1px solid #dfdbdb !important',
  },
}));
