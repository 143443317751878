import { Avatar, Button, Dialog, DialogContent, DialogTitle, Divider, FormLabel, MenuItem, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import '../../../content/css/calender.css';
import CustomCalendar from './customCalendar';
import { weeks, occurence, end } from '../../../utils/global';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import CustomToolbarComponent from '../../entities/service-area/CustomToolbarComponent';

export const PatternModal = props => {
  const [open, setOpen] = useState(false);
  const [number, setNumber] = useState(1);
  const [occurenceList, setOccurenceList] = useState('Day');
  const [endList, setEndList] = useState('Never');
  const [week, setWeek] = useState(weeks);
  const handleClose = () => setOpen(false);
  const [dateFormat, setdateFormat] = useState(props.dateFormatSetting);
  const [start_date, setStartDate] = React.useState();

  const handleWeek = value => {
    const index = week.findIndex(week => week.value === value.value);
    const newWeek = [...week];
    newWeek[index].value = value.value;
    newWeek[index].active = !value.active;
    setWeek(newWeek);
  };

  useEffect(() => {
    setdateFormat(props.dateFormatSetting);
  }, [props.setting]);

  useEffect(() => {
    if (props.openPatternModal) {
      setOpen(true);
    }
  }, [props.openPatternModal]);

  const dateConverter = dateformat => {
    if (dateformat === null) {
      if (dateFormat === 1) {
        return 'DD-MM-YY';
      } else if (dateFormat === 2) {
        return 'DD-MM-YYYY';
      } else if (dateFormat === 3) {
        return 'MM/DD/YY';
      } else if (dateFormat === 4) {
        return 'MM/DD/YYYY';
      } else if (dateFormat === 5) {
        return 'DD.MM.YY';
      } else if (dateFormat === 6) {
        return 'DD.MM.YYYY';
      } else if (dateFormat === 7) {
        return 'YY/MM/DD';
      } else if (dateFormat === 8) {
        return 'YYYY/MM/DD';
      }
    } else {
      if (dateFormat === 1) {
        return moment(dateformat).format('DD-MM-YY');
      } else if (dateFormat === 2) {
        return moment(dateformat).format('DD-MM-YYYY');
      } else if (dateFormat === 3) {
        return moment(dateformat).format('MM/DD/YY');
      } else if (dateFormat === 4) {
        return moment(dateformat).format('MM/DD/YYYY');
      } else if (dateFormat === 5) {
        return moment(dateformat).format('DD.MM.YY');
      } else if (dateFormat === 6) {
        return moment(dateformat).format('DD.MM.YYYY');
      } else if (dateFormat === 7) {
        return moment(dateformat).format('YY/MM/DD');
      } else if (dateFormat === 8) {
        return moment(dateformat).format('YYYY/MM/DD');
      }
    }
  };

  const handleDate = e => {
    const d = moment(e).format('YYYY-MM-DD');
    setStartDate(e);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'md'}>
        <div className="d-flex justify-content-center">
          <DialogTitle className="pattern-title">Repeat Shift</DialogTitle>
        </div>
        <Divider />
        <DialogContent>
          <div className="d-flex padding-dialog">
            <div className="row">
              <div className="col-md-12">
                <FormLabel className="pattern-label">Start</FormLabel>
              </div>
              <div className="col-md-12">
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                  <>
                    <KeyboardDatePicker
                      clearable={true}
                      id="start-date"
                      inputVariant="outlined"
                      className="modal-input"
                      ToolbarComponent={prop => (
                        <>
                          <CustomToolbarComponent {...prop} settingFormat={props.dateFormatSetting} />
                        </>
                      )}
                      labelFunc={dateConverter}
                      value={start_date ? moment(start_date) : null}
                      onChange={handleDate}
                    />{' '}
                  </>
                </MuiPickersUtilsProvider>
              </div>
              <div className="col-md-12">
                <FormLabel className="pattern-label">Repeats every</FormLabel>
              </div>
              <div className="col-md-6">
                <TextField
                  variant="outlined"
                  id="number"
                  type="number"
                  className="modal-input"
                  defaultValue={number}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-md-6">
                <TextField
                  variant="outlined"
                  id="occurence"
                  select
                  className="modal-input"
                  defaultValue={occurenceList}
                  onChange={e => setOccurenceList(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  {occurence.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className="col-md-12">
                {occurenceList === 'Week' && (
                  <div className="d-flex ">
                    {week.map(option => (
                      <Avatar
                        className="week-circle"
                        style={{ background: option.active ? '#3bbda9' : '#e4e4e4' }}
                        onClick={() => handleWeek(option)}
                        key={option.value}
                      >
                        {option.value}
                      </Avatar>
                    ))}
                  </div>
                )}
                {occurenceList === 'Month' && <CustomCalendar />}
              </div>
              <div className="col-md-12">
                <FormLabel className="pattern-label">End</FormLabel>
              </div>
              <div className="col-md-12">
                <TextField
                  variant="outlined"
                  id="end"
                  select
                  className="modal-input"
                  defaultValue={endList}
                  onChange={e => setEndList(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  {end.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              {endList === 'Until' && (
                <>
                  <div className="col-md-2 d-flex justify-content-end">
                    <div className="box selected-color "></div>
                  </div>
                  <div className="col-md-10">
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                      <>
                        <KeyboardDatePicker
                          clearable={true}
                          id="start-date"
                          inputVariant="outlined"
                          className="modal-input margin-from-top"
                          ToolbarComponent={prop => (
                            <>
                              <CustomToolbarComponent {...prop} settingFormat={props.dateFormatSetting} />
                            </>
                          )}
                          labelFunc={dateConverter}
                          value={start_date ? moment(start_date) : null}
                          onChange={handleDate}
                        />{' '}
                      </>
                    </MuiPickersUtilsProvider>
                  </div>
                </>
              )}
              <div className="col-md-6">
                <Button fullWidth variant="contained" className="login-submit">
                  Save Repeat Shift
                </Button>
              </div>
              <div className="col-md-6">
                <Button fullWidth variant="contained" className="discard-btn">
                  Discard
                </Button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PatternModal;
