import { ICity } from 'app/shared/model/city.model';
import { ICountry } from 'app/shared/model/country.model';
export interface ISubcontractor {
  id?: number;
  name?: string | null;
  contactPerson?: string | null;
  email?: string | null;
  phone?: string | null;
  address?: string | null;
  postcode?: string | null;
  city?: ICity | null;
  country?: ICountry | null;
  logo?: string | null;
}

export interface IOrganizationWithSubcontractor {
  id?: number;
  counter?: number;
  name?: string | null;
  status?: string | null;
}

export interface ISubcontractorDocs {
  brief_description?: string | null;
  name?: string | null;
  subcontractor_id?: number;
  id?: number;
}

export const defaultValue: Readonly<ISubcontractor> = {};
