import React, { useState, useEffect } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';

export const FullScreeningComponent = props => {
  const { person } = props;
  return (
    <div className="vetting-documents-history-full-process">
      <Table style={{ borderCollapse: 'separate', borderSpacing: '0px 15px' }}>
        {person.credit_check_sent && (
          <>
            <TableRow>
              <TableCell scope="left" className="documents-about-you-table-cells-dark">
                Adverse credit check request sent
              </TableCell>
              <TableCell align="left" className="documents-about-you-table-cells-light">
                true
              </TableCell>
              {person.credit_check_sent_at && (
                <>
                  <TableCell align="left" className="documents-about-you-table-cells-dark">
                    Adverse credit check request sent at
                  </TableCell>
                  <TableCell align="left" className="documents-about-you-table-cells-light">
                    {person.credit_check_sent_at}
                  </TableCell>
                </>
              )}
            </TableRow>
            <TableRow>
              <TableCell scope="left" className="documents-about-you-table-cells-dark">
                Sanctions check request sent
              </TableCell>
              <TableCell align="left" className="documents-about-you-table-cells-light">
                true
              </TableCell>
              {person.credit_check_sent_at && (
                <>
                  <TableCell align="left" className="documents-about-you-table-cells-dark">
                    Sanctions check request sent at
                  </TableCell>
                  <TableCell align="left" className="documents-about-you-table-cells-light">
                    {person.credit_check_sent_at}
                  </TableCell>
                </>
              )}
            </TableRow>
          </>
        )}
      </Table>
      {person.credit_check_sent && (
        <div style={{ marginTop: '10px', width: '95%' }}>
          <b> NOTE: Waiting for adverse credit check and sanctions check response to complete full screening process </b>
        </div>
      )}
    </div>
  );
};

export default FullScreeningComponent;
