import axios from 'axios';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { IActionRequired, defaultValue } from 'app/shared/model/actionsRequired.model';
import { ToastContainer, toast } from 'react-toastify';

export const ACTION_TYPES = {
  FETCH_NOTIFICATIONS_LIST: 'notifications/FETCH_NOTIFICATIONS_LIST',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IActionRequired>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  breaks: [],
};

export type ActionRequiredState = Readonly<typeof initialState>;

// Reducer

export default (state: ActionRequiredState = initialState, action): ActionRequiredState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_NOTIFICATIONS_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_NOTIFICATIONS_LIST):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_NOTIFICATIONS_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/action-required';

// Actions

export const getNotifications = () => ({
  type: ACTION_TYPES.FETCH_NOTIFICATIONS_LIST,
  payload: axios.get<IActionRequired>(apiUrl),
});

export const updateNotifications = (notificationId, notificationStatus) => dispatch => {
  let result;
  axios
    .put<IActionRequired>(`${apiUrl}/${notificationId}`, { status: notificationStatus })
    .then(res => {
      result = res;
      dispatch(getNotifications());
    })
    .catch(err => {
      toast.error('Error :' + err);
    });

  return result;
};
