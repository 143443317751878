import CryptoJS from 'crypto-js';

const encryptData = data => {
  const secretKey = process.env.REACT_APP_SECRET_KEY;
  const iv = CryptoJS.lib.WordArray.random(16);
  const encrypted = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(secretKey), {
    iv,
  });
  const encryptedData = iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64);

  return encryptedData;
};

export { encryptData };
