import React, { useState, useEffect } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';

export const PreliminaryScreeningComponent = props => {
  const { person } = props;

  const changePattern = value => {
    return value.charAt(0).toUpperCase() + value.slice(1);
  };

  return (
    <div className="vetting-documents-preliminary-history-table-rows-div">
      <Table style={{ borderCollapse: 'separate', borderSpacing: '0px 15px' }}>
        <TableRow>
          {person.verified_by && (
            <React.Fragment>
              <TableCell scope="left" className="documents-about-you-table-cells-dark">
                Information verified by
              </TableCell>
              <TableCell align="left" className="documents-about-you-table-cells-light">
                {changePattern(person.verified_by)}
              </TableCell>
            </React.Fragment>
          )}
          {person.verfied_at && (
            <React.Fragment>
              <TableCell align="left" className="documents-about-you-table-cells-dark">
                Information verified at
              </TableCell>
              <TableCell align="left" className="documents-about-you-table-cells-light">
                {person.verfied_at}
              </TableCell>
            </React.Fragment>
          )}
        </TableRow>
      </Table>
    </div>
  );
};

export default PreliminaryScreeningComponent;
