import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Grid, Table } from '@material-ui/core';
import { IRootState } from 'app/shared/reducers';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { Paper } from '@material-ui/core';
import '../../sub-contractor/subcontractor.scss';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Autocomplete } from '@material-ui/lab';
import { getIncidents, getIncidentById, getIncidentTypes, getAttachmentTypes, saveIncidentAttachments } from '../incident.reducer';
import { IncidentDocuments } from './incident-documents-table';
import { values } from 'lodash';

export interface IIncidentAttachmentsProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
  setValues?: (data: any) => void;
  values?: any;
  isNew?: any;
}

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: '100%',
  },
}));

export const IncidentAttachments = (props: IIncidentAttachmentsProps) => {
  const [fileName, setFilesName] = useState({ name: 'No File Chosen' });

  const handleAttachmentTypes = (_, e) => {
    if (props.isNew) {
      props.setValues(oldValues => ({
        ...oldValues,
        ['incident_attachments']: {
          name: 'avatar',
          attachment_description:
            props.values['incident_attachments'] && props.values['incident_attachments']['attachment_description']
              ? props.values['incident_attachments']['attachment_description']
              : null,
          attachment_type: e,
        },
      }));
    } else {
      props.setValues(oldValues => ({
        ...oldValues,
        ['incident_attachments']: {
          incident: props.values,
          name: 'avatar',
          attachment_description:
            props.values['incident_attachments'] && props.values['incident_attachments']['attachment_description']
              ? props.values['incident_attachments']['attachment_description']
              : null,
          attachment_type: e,
        },
      }));
    }
  };

  const handleDescription = e => {
    if (props.isNew) {
      props.setValues(oldValues => ({
        ...oldValues,
        ['incident_attachments']: {
          name: 'avatar',
          attachment_description: e.target.value,
          attachment_type:
            props.values['incident_attachments'] && props.values['incident_attachments']['attachment_type']
              ? props.values['incident_attachments']['attachment_type']
              : null,
        },
      }));
    } else {
      props.setValues(oldValues => ({
        ...oldValues,
        ['incident_attachments']: {
          incident: props.values,
          name: 'avatar',
          attachment_description: e.target.value,
          attachment_type:
            props.values['incident_attachments'] && props.values['incident_attachments']['attachment_type']
              ? props.values['incident_attachments']['attachment_type']
              : null,
        },
      }));
    }
  };

  const handleIncidentAttachments = e => {
    props.setValues(oldFieldValues => ({ ...oldFieldValues, ['attachment']: e.target.files[0] }));
    setFilesName(oldFieldValues => ({ ...oldFieldValues, ['name']: e.target.files[0].name }));
  };

  useEffect(() => {
    props.getAttachmentTypes();
  }, []);

  const submitIncidentAttachments = () => {
    const formData = new FormData();
    formData.append('avatar', props.values['attachment']);
    formData.append('details', JSON.stringify(props.values['incident_attachments']));
    props.saveIncidentAttachments(formData);
  };

  return (
    <div className="incident-attachment-outer-div">
      <Grid container spacing={2}>
        <Grid item lg={3} md={3} sm={3} xs={3}>
          <FormControl required style={{ width: '90%' }} className="person-doc-type-field" id="incident-attachment-type-field" size="small">
            <Autocomplete
              options={
                props.attachment_types &&
                props.attachment_types.map(type => ({
                  id: type.id,
                  name: type.name,
                }))
              }
              noOptionsText={'No Option'}
              classes={{ listbox: 'autocompletelistbox' }}
              ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
              getOptionLabel={option => option.name || ''}
              onChange={handleAttachmentTypes}
              renderInput={params => (
                <TextField {...params} variant="outlined" size="small" label="Type" margin="normal" required fullWidth />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={2}>
          <input type="file" id="file-input" name="subcontractor_docs" style={{ display: 'none' }} onChange={handleIncidentAttachments} />
          <label htmlFor="file-input" style={{ width: '100%' }}>
            <Button variant="contained" component="span" id="person-upload-docments-button">
              <span className="upload-subcontractor-document-text">Upload Document</span>
            </Button>
          </label>
          <span style={{ fontSize: '10px' }} id="file-chosen4">
            {fileName.name}
          </span>
        </Grid>

        <Grid item lg={5} md={5} sm={2} xs={5}>
          <TextField
            variant="outlined"
            size="small"
            name="brief_description"
            onChange={handleDescription}
            label="Brief Description"
            fullWidth
            value={props.values && props.values['incident_attachment'] && props.values['incident_attachment']['attachment_description']}
          />
        </Grid>

        {!props.isNew ? (
          <Grid item lg={2} md={2} sm={2} xs={2}>
            <Button
              variant="contained"
              component="span"
              disabled={props.isNew ? true : false}
              className="subcontractor-view-submitButton"
              onClick={submitIncidentAttachments}
            >
              Submit
            </Button>
          </Grid>
        ) : null}
      </Grid>

      {!props.isNew ? <IncidentDocuments incident_attachment={props.values.incident_attachments} /> : null}
    </div>
  );
};

const mapStateToProps = ({ Incident }: IRootState) => ({
  attachment_types: Incident.attachment_list,
});

const mapDispatchToProps = {
  getAttachmentTypes,
  saveIncidentAttachments,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(IncidentAttachments);
