import { useTable } from 'react-table';
import React from 'react';
import './bannedPeople.scss';
import '../../../utils/GlobalCssRequiredInWebsite.scss';
import styled from 'styled-components';

export const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;
    width: 100%;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

export function Table({ columns: userColumns, data, loading }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns: userColumns,
    data,
  });
  return (
    <>
      <div className="globalTableHeaderGradientColor clientTableHeaderRow sticky_div">
        <div className="clientTableHeaderCell bannedEmployeeHeader">Employee Name</div>
        <div className="clientTableHeaderCell bannedEmployeeHeader">Service Area</div>
        <div className="clientTableHeaderCell bannedEmployeeHeader">Start Date</div>
        <div className="clientTableHeaderCell bannedEmployeeHeader">End Date</div>
        <div className="clientTableHeaderCell bannedEmployeeHeaderReason">Description</div>
      </div>

      <table {...getTableProps()} className="bannedReactTable">
        {/* <thead className="globalTableHeaderGradientColor">
          {headerGroups && headerGroups.length > 0
            ? headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={i} className="clientTableHeaderRow">
                {headerGroup.headers.map((column, i) => (
                  <th {...column.getHeaderProps()} key={i} className="clientTableHeaderCell">
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))
            : !loading && <div className="no-record-warning">No Header found</div>}
        </thead> */}
        <tbody {...getTableBodyProps()}>
          {rows && rows.length > 0
            ? rows.map((row, i) => {
                prepareRow(row);
                const rowProps = row.getRowProps();
                return (
                  <React.Fragment key={rowProps.key}>
                    <tr {...rowProps} key={i} className="BannedPeopleTableBodyRows">
                      {row.cells.map((cell, i) => {
                        return (
                          <td
                            style={{ padding: '1rem !important' }}
                            {...cell.getCellProps()}
                            key={i}
                            className="BannedPeopleTableBodyCell bannedEmployeeHeader"
                          >
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  </React.Fragment>
                );
              })
            : !loading && (
                <tr style={{ borderBottom: 'unset' }}>
                  <div className="no-record-warning">No Records found</div>
                </tr>
              )}
        </tbody>
      </table>
    </>
  );
}
