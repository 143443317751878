import { IDesignationQualification } from 'app/shared/model/designation-qualification.model';
import { IPerson } from 'app/shared/model/person.model';

export interface IDesignation {
  id?: number;
  name?: string | null;
  is_vettable?: boolean;
  designationQualitifications?: IDesignationQualification[] | null;
  people?: IPerson[] | null;
}

export const defaultValue: Readonly<IDesignation> = {};
