import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { IRootState } from 'app/shared/reducers';
import { getEntity, getServiceAreasByClientId } from '../../service-area/service-area.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { Grid, Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ServiceAreaComponent from '../components/client-ServiceAreaComponent';
import { getOrganizationSettings } from 'app/modules/Setting/generalSettings.reducer';
import '../client.scss';

export interface IEntityDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const ServiceAreaTeam = (props: IEntityDetailProps) => {
  const { clientEntity } = props;
  useEffect(() => {
    const clientId = clientEntity.id;
    props.getServiceAreasByClientId(clientId);
    props.getOrganizationSettings();
  }, []);

  return (
    <div className="client-view-profile-details-main-container">
      <div style={{ padding: '5%' }}>
        <Typography className="client-main-title-heading" variant="h5" style={{ color: '#4720b7', marginBottom: '3%' }}>
          Active Service Area(s)
        </Typography>
        <Grid container spacing={3}>
          {props.serviceAreas.map((serviceArea, index) => (
            <ServiceAreaComponent key={index} serviceArea={serviceArea} setting={props.settings} />
          ))}
        </Grid>
      </div>
    </div>
  );
};

const mapStateToProps = ({ client, serviceArea, organizationSettings }: IRootState) => ({
  clientEntity: client.entity,
  serviceAreas: serviceArea.entities,
  settings: organizationSettings.storedOrganizationSettings,
});

const mapDispatchToProps = {
  getEntity,
  getServiceAreasByClientId,
  getOrganizationSettings,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ServiceAreaTeam);
