import React from 'react';
import MenuITem from 'app/shared/layout/menus/menu-item';
import MenuItem from '@material-ui/core/MenuItem';

import { DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavDropdown } from './menu-components';
import { Translate, translate } from 'react-jhipster';

const adminMenuItems = (
  <>
    <MenuITem icon="users" to="/admin/user-management">
      &nbsp;
      <Translate contentKey="global.menu.admin.userManagement">User management</Translate>
    </MenuITem>
    <MenuITem icon="tachometer-alt" to="/admin/metrics">
      &nbsp;
      <Translate contentKey="global.menu.admin.metrics">Metrics</Translate>
    </MenuITem>
    <MenuITem icon="heart" to="/admin/health">
      &nbsp;
      <Translate contentKey="global.menu.admin.health">Health</Translate>
    </MenuITem>
    <MenuITem icon="cogs" to="/admin/configuration">
      &nbsp;
      <Translate contentKey="global.menu.admin.configuration">Configuration</Translate>
    </MenuITem>
    <MenuITem icon="tasks" to="/admin/logs">
      &nbsp;
      <Translate contentKey="global.menu.admin.logs">Logs</Translate>
    </MenuITem>
    {/* jhipster-needle-add-element-to-admin-menu - JHipster will add entities to the admin menu here */}
  </>
);

const openAPIItem = (
  <MenuITem icon="book" to="/admin/docs">
    &nbsp;
    <Translate contentKey="global.menu.admin.apidocs">API</Translate>
  </MenuITem>
);

const databaseItem = (
  <MenuItem component="a" href="./h2-console/" target="_tab">
    &nbsp;
    <FontAwesomeIcon icon="database" fixedWidth /> <Translate contentKey="global.menu.admin.database">Database</Translate>
  </MenuItem>
);

export const AdminMenu = ({ showOpenAPI, showDatabase }) => (
  <NavDropdown icon="users-cog" name={translate('global.menu.admin.main')} id="admin-menu" data-cy="adminMenu">
    {adminMenuItems}
    {showOpenAPI && openAPIItem}

    {showDatabase && databaseItem}
  </NavDropdown>
);

export default AdminMenu;
