import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { getEntity, updateEntity, createEntity, reset, getState, getCityByState, setPageNumber } from './organization.reducer';
import { getEntities as getDesignations, reset as resetDes } from 'app/entities/designation/designation.reducer';
import { FormControl } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MenuItem from '@material-ui/core/MenuItem';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import timezones from '../../shared/data/timezones.json';
import { Typography } from '@material-ui/core';
import './organization.scss';
import { getCity, getCountry } from '../sub-contractor/subcontractor.reducer';
import OrganizationUpdateCompanyInfo from './organization-update-companyInfo';
import OrganizationUpdateContactPerson from './organization-update-contactPerson';
import { getOrganizationSettings } from '../../modules/Setting/generalSettings.reducer';
import { validationMessageOnChange, validationMessageBeforeSubmit } from '../../../utils/validationMessages';
import country_types from '../../shared/data/country_types.json';

export interface IOrganizationUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const OrganizationUpdate = props => {
  const [values, setValues] = useState({});
  const [isNew] = useState(!props.match.params || !props.match.params.id);
  const [positionsList, setPositionsList] = useState(props.positionsList);
  const [image, setImage] = useState(null);
  const [hasImage, setHasImage] = useState(null);
  const [valuesError, setValuesError] = useState({
    organizationName_helper: '',
    organizationName_error: false,
    organizationEmail_helper: '',
    organizationEmail_error: false,
    organizationPhone_helper: '',
    organizationPhone_error: false,
    organizationAddress_helper: '',
    organizationAddress_error: false,
    organizationTimezone_error: false,
    organizationTimezone_helper: '',
    postCode_error: false,
    postCode_helper: '',
    state_error: false,
    state_helper: '',
    country_error: false,
    city_error: false,
    personFirstName_error: false,
    personFirstName_helper: '',
    personLastName_error: false,
    personLastName_helper: '',
    personEmail_error: false,
    personEmail_helper: '',
    personPhone_error: false,
    personPhone_helper: '',
    personPosition_error: false,
    personPosition_helper: '',
  });

  const { loading, updating, match } = props;

  const handleClose = () => {
    props.history.push('/customer');
  };

  useEffect(() => {
    props.reset();
    if (isNew) {
      props.resetDes();
      event.preventDefault();
      props.getDesignations(-1);
      props.getCountry();
    } else {
      props.getEntity(props.match.params.id);
      props.getDesignations(-1);
      props.getCountry();
    }
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  useEffect(() => {
    setPositionsList(props.positionsList);
  }, [props.positionsList]);

  useEffect(() => {
    setValues(props.organizationEntity);
    if (props.organizationEntity && props.organizationEntity.organization_image) {
      setImage(props.organizationEntity.organization_image);
    }
    if (props.organizationEntity && props.organizationEntity.organizationTimezone) {
      const timeZoneObject = timezones.find(item => item.value === props.organizationEntity.organizationTimezone);
      setValues(oldValues => ({
        ...oldValues,
        organizationTimezone: { id: timeZoneObject.value, text: timeZoneObject.label },
      }));
    }
  }, [props.organizationEntity]);

  useEffect(() => {
    if (
      props.organizationEntity &&
      props.organizationEntity?.country &&
      props.organizationEntity.country &&
      props.organizationEntity.country.id
    ) {
      const countryData = { id: props.organizationEntity.country.id, text: props.organizationEntity.country.name };
      props.getState(countryData.id);
    }
    if (
      props.organizationEntity &&
      props.organizationEntity?.state &&
      props.organizationEntity.state &&
      props.organizationEntity.state.id
    ) {
      const stateData = { id: props.organizationEntity.state.id, text: props.organizationEntity.state.name };
      props.getCityByState(stateData.id);
    }
    setValues(props.organizationEntity);
  }, [props.organizationEntity]);

  const setNewValues = e => {
    setValues(oldValues => ({ ...oldValues, [e.target.name]: e.target.value }));
    if (e.target.name === 'name' && (!/^(?! )[a-zA-Z0-9\s(),.-]*$/.test(e.target.value) || e.target.value === '')) {
      setValuesError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: true }));
      setValuesError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_helper']: validationMessageOnChange.name }));
    } else if (e.target.name === 'personFirstName' && (!/^(?! )[a-zA-Z\s().-]*$/.test(e.target.value) || e.target.value === '')) {
      setValuesError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: true }));
      setValuesError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_helper']: validationMessageOnChange.firstName }));
    } else if (e.target.name === 'personLastName' && (!/^(?! )[a-zA-Z\s().-]*$/.test(e.target.value) || e.target.value === '')) {
      setValuesError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: true }));
      setValuesError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_helper']: validationMessageOnChange.lastName }));
    } else if (e.target.name === 'organizationAddress' && e.target.value === '') {
      setValuesError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: true }));
      setValuesError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_helper']: validationMessageOnChange.address }));
    } else if (
      (e.target.name === 'organizationTimezone' ||
        e.target.name === 'designation' ||
        e.target.name === 'organizationPhone' ||
        e.target.name === 'personPhone') &&
      e.target.value === null
    ) {
      setValuesError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: true }));
    } else if (
      (e.target.name === 'organizationEmail' || e.target.name === 'personEmail') &&
      !/^(([^<>()[\],;:\s@]+(\.[^<>()[\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+\.)+[^<>()[\],;:\s@]{2,})$/i.test(e.target.value)
    ) {
      setValuesError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: true }));
      setValuesError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_helper']: validationMessageOnChange.email }));
    } else {
      setValuesError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: false, [e.target.name + '_helper']: '' }));
    }
  };

  const setFormValues = name => {
    setNewValues(name);
    return ({ target: { value } }) => {
      setValues(oldValues => ({ ...oldValues, [name]: value }));
    };
  };

  const handleTimezoneData = (_, e) => {
    if (e) {
      setValues(oldValues => ({ ...oldValues, ['organizationTimezone']: e }));
      if (values['organizationTimezone'] !== null) {
        setValuesError(oldFieldError => ({ ...oldFieldError, ['organizationTimezone_error']: false }));
      }
    } else {
      setValues(oldValues => ({ ...oldValues, ['organizationTimezone']: null }));
    }
  };

  const handlePositionData = (_, e) => {
    if (e) {
      setValues(oldValues => ({ ...oldValues, ['designation']: e }));
      if (values['designation'] !== null) {
        setValuesError(oldFieldError => ({ ...oldFieldError, ['personPosition_error']: false }));
      }
    } else {
      setValues(oldValues => ({ ...oldValues, ['designation']: null }));
    }
  };

  const handleCountryData = (_, e) => {
    if (e) {
      setValues(oldValues => ({ ...oldValues, ['country']: e }));
      props.getState(e.id);
      if (values['country'] !== null) {
        setValuesError(oldFieldError => ({ ...oldFieldError, ['country_error']: false }));
      }
    } else {
      setValues(oldValues => ({ ...oldValues, ['country']: null }));
    }
  };

  const handleStateData = (_, e) => {
    if (e) {
      setValues(oldValues => ({ ...oldValues, ['state']: e }));
      props.getCityByState(e.id);
      if (values['state'] !== null) {
        setValuesError(oldFieldError => ({ ...oldFieldError, ['state_error']: false }));
      }
    } else {
      setValues(oldValues => ({ ...oldValues, ['state']: null }));
    }
  };

  const handleCityData = (_, e) => {
    if (e) {
      setValues(oldValues => ({ ...oldValues, ['city']: e }));
      if (values['city'] !== null) {
        setValuesError(oldFieldError => ({ ...oldFieldError, ['city_error']: false }));
      }
    } else {
      setValues(oldValues => ({ ...oldValues, ['city']: null }));
    }
  };

  const handleOrganizationPhoneNumber = phone => {
    setValues(oldFieldValues => ({ ...oldFieldValues, ['organizationPhone']: phone }));
  };

  const handleContactPersonPhoneNumber = phone => {
    setValues(oldFieldValues => ({ ...oldFieldValues, ['personPhone']: phone }));
  };

  const handleUploadImage = e => {
    setValues(oldValues => ({ ...oldValues, ['organization_image']: e.target.files[0] }));
    if (e.target.files && e.target.files[0]) {
      setImage(URL.createObjectURL(e.target.files[0]));
    }
    setHasImage(true);
  };

  const checkForValidations = () => {
    const result =
      !valuesError['organizationName_error'] &&
      !valuesError['organizationEmail_error'] &&
      !valuesError['organizationAddress_error'] &&
      !valuesError['postCode_error'] &&
      !valuesError['state_error'] &&
      !valuesError['country_error'] &&
      !valuesError['city_error'] &&
      !valuesError['organizationTimezone_error'] &&
      !valuesError['personFirstName_error'] &&
      !valuesError['personLastName_error'] &&
      !valuesError['personEmail_error'] &&
      !valuesError['personPosition_error'] &&
      !(values['name'] === undefined) &&
      !(values['organizationEmail'] === undefined) &&
      !(values['organizationAddress'] === undefined) &&
      !(values['organizationTimezone'] === undefined) &&
      !(values['country'] === undefined) &&
      !(values['state'] === undefined) &&
      !(values['postCode'] === undefined) &&
      !(values['city'] === undefined) &&
      !(values['personFirstName'] === undefined) &&
      !(values['personLastName'] === undefined) &&
      !(values['personEmail'] === undefined) &&
      !(values['designation'] === undefined);

    return result;
  };

  const checkValidationsBeforeSubmit = () => {
    if (values['name'] === undefined) {
      setValuesError(oldFieldError => ({ ...oldFieldError, ['organizationName_error']: true }));
      setValuesError(oldFieldError => ({ ...oldFieldError, ['organizationName_helper']: validationMessageBeforeSubmit.name }));
    }
    if (values['organizationEmail'] === undefined) {
      setValuesError(oldFieldError => ({ ...oldFieldError, ['organizationEmail_error']: true }));
      setValuesError(oldFieldError => ({
        ...oldFieldError,
        ['organizationEmail_helper']: validationMessageBeforeSubmit.email,
      }));
    }
    if (values['organizationAddress'] === undefined) {
      setValuesError(oldFieldError => ({ ...oldFieldError, ['organizationAddress_error']: true }));
      setValuesError(oldFieldError => ({
        ...oldFieldError,
        ['organizationAddress_helper']: validationMessageBeforeSubmit.address,
      }));
    }
    if (values['organizationTimezone'] === undefined) {
      setValuesError(oldFieldError => ({ ...oldFieldError, ['organizationTimezone_error']: true }));
    }
    if (values['organizationPhone'] === undefined) {
      setValuesError(oldFieldError => ({ ...oldFieldError, ['organizationPhone_error']: true }));
    }
    if (values['country'] === undefined) {
      setValuesError(oldFieldError => ({ ...oldFieldError, ['country_error']: true }));
    }
    if (values['city'] === undefined) {
      setValuesError(oldFieldError => ({ ...oldFieldError, ['city_error']: true }));
    }
    if (values['state'] === undefined) {
      setValuesError(oldFieldError => ({ ...oldFieldError, ['state_error']: true }));
    }
    if (values['postCode'] === undefined) {
      setValuesError(oldFieldError => ({ ...oldFieldError, ['postCode_error']: true }));
      setValuesError(oldFieldError => ({ ...oldFieldError, ['postCode_helper']: validationMessageBeforeSubmit.postcode }));
    }
    if (values['personFirstName'] === undefined) {
      setValuesError(oldFieldError => ({ ...oldFieldError, ['personFirstName_error']: true }));
      setValuesError(oldFieldError => ({ ...oldFieldError, ['personFirstName_helper']: validationMessageBeforeSubmit.firstName }));
    }
    if (values['personLastName'] === undefined) {
      setValuesError(oldFieldError => ({ ...oldFieldError, ['personLastName_error']: true }));
      setValuesError(oldFieldError => ({ ...oldFieldError, ['personLastName_helper']: validationMessageBeforeSubmit.lastName }));
    }
    if (values['personEmail'] === undefined) {
      setValuesError(oldFieldError => ({ ...oldFieldError, ['personEmail_error']: true }));
      setValuesError(oldFieldError => ({ ...oldFieldError, ['personEmail_helper']: validationMessageBeforeSubmit.email }));
    }
    if (values['personPhone'] === undefined) {
      setValuesError(oldFieldError => ({ ...oldFieldError, ['personPhone_error']: true }));
    }
    if (values['designation'] === undefined) {
      setValuesError(oldFieldError => ({ ...oldFieldError, ['personPosition_error']: true }));
    }
  };

  const saveEntity = event => {
    event.preventDefault();
    const formData = new FormData();
    if (hasImage === true) {
      formData.append('avatar', values['organization_image']);
    }
    if (isNew && checkForValidations()) {
      props.createEntity(values, formData);
    } else if (!isNew && checkForValidations()) {
      props.updateEntity(values, formData);
    } else {
      checkValidationsBeforeSubmit();
    }
  };

  return (
    <div className="organization-create-main-container">
      <div className="organization_full_container">
        <Typography variant="h5" className="organizationTopTitle">
          Create / Edit a New Customer
        </Typography>
        <div className="organizationTopUnderTitle">Please fill all of the given fields.</div>
        <div className="organizationOuterDiv" style={{ display: 'flex' }}>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <form onSubmit={saveEntity} className="organization_form" noValidate>
              <OrganizationUpdateCompanyInfo
                countriesList={props.countriesList}
                values={values}
                handleCountryData={handleCountryData}
                handleCityData={handleCityData}
                handleStateData={handleStateData}
                citiesList={props.citiesList}
                stateList={props.stateList}
                setFormValues={setFormValues}
                handleOrganizationPhoneNumber={handleOrganizationPhoneNumber}
                image={image}
                handleUploadImage={handleUploadImage}
                valuesError={valuesError}
                countryOfOperations={props.settings.country_of_operations}
                countryTypes={country_types}
                handleTimezoneData={handleTimezoneData}
              />
              <OrganizationUpdateContactPerson
                positionsList={positionsList}
                values={values}
                setFormValues={setFormValues}
                handleContactPersonPhoneNumber={handleContactPersonPhoneNumber}
                valuesError={valuesError}
                countryOfOperations={props.settings.country_of_operations}
                countryTypes={country_types}
                handlePositionData={handlePositionData}
              />
              <Grid item xs={12} md={12} sm={12} style={{ textAlign: 'center' }}>
                <FormControl className="organization_fields OrganizationSaveDiscardButtons">
                  <Button
                    variant="outlined"
                    className="organizationDiscardButton"
                    onClick={() => props.reset()}
                    component={Link}
                    to="/customer"
                  >
                    Cancel
                  </Button>
                  &nbsp;
                  <Button variant="contained" id="save-entity" type="submit" className="organizationSaveButton">
                    Save Changes
                  </Button>
                </FormControl>
              </Grid>
            </form>
          )}
        </div>
        {/* </Paper>
      </Grid> */}
      </div>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  organizationEntity: storeState.organization.entity,
  loading: storeState.organization.loading,
  updating: storeState.organization.updating,
  updateSuccess: storeState.organization.updateSuccess,
  positionsList: storeState.designation.entities,
  countriesList: storeState.subcontractor.CountryEntity,
  citiesList: storeState.organization.CityEntity,
  stateList: storeState.organization.StateEntity,
  settings: storeState.organizationSettings.storedOrganizationSettings,
  uploadedAvatar: storeState.organizationSettings.uploadedAvatar,
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset,
  getDesignations,
  resetDes,
  getOrganizationSettings,
  getCity,
  getCountry,
  getState,
  getCityByState,
  setPageNumber,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationUpdate);
