import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ServiceAreaType from './service-area-type';
import ServiceAreaTypeDetail from './service-area-type-detail';
import ServiceAreaTypeUpdate from './service-area-type-update';
import ServiceAreaTypeDeleteDialog from './service-area-type-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ServiceAreaTypeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ServiceAreaTypeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ServiceAreaTypeDetail} />
      <ErrorBoundaryRoute path={match.url} component={ServiceAreaType} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={ServiceAreaTypeDeleteDialog} />
  </>
);

export default Routes;
