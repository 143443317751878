import React from 'react';
import 'react-toastify/dist/ReactToastify.css';

import StaffDataOperations from './staff-data-components/staff-data-operations';
import StaffDataManager from './staff-data-components/staff-data-manager';
import StaffDataDepartment from './staff-data-components/staff-data-department';
import StaffDataLocation from './staff-data-components/staff-data-location';

export default function StaffData(props) {
  return (
    <React.Fragment>
      <StaffDataOperations
        handler={props.handler}
        barForProps={props.barForProps}
        barHandler={props.barHandler}
        valueForProps={props.valueForProps}
      />
      <StaffDataManager
        handler={props.handler}
        barForProps={props.barForProps}
        barHandler={props.barHandler}
        valueForProps={props.valueForProps}
      />
      <StaffDataDepartment
        handler={props.handler}
        barForProps={props.barForProps}
        barHandler={props.barHandler}
        valueForProps={props.valueForProps}
      />
      <StaffDataLocation
        handler={props.handler}
        barForProps={props.barForProps}
        barHandler={props.barHandler}
        valueForProps={props.valueForProps}
      />
    </React.Fragment>
  );
}
