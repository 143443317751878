import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Grid, Table, Modal } from '@material-ui/core';
import { IRootState } from 'app/shared/reducers';
import { Paper } from '@material-ui/core';
import '../../sub-contractor/subcontractor.scss';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import '../incident.scss';
import { FileViewer } from 'react-file-viewer';
import CloseIcon from '@material-ui/icons/Close';

interface IIncidentDocumentsProps {
  incident_attachment?: any;
}

export const IncidentDocuments = (props: IIncidentDocumentsProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [attachmentData, setAttachmentData] = useState({ ext: '', file: '' });

  const handleViewIncidentAttachment = docs => {
    setAttachmentData({ ext: docs.ext, file: docs.attachment_data });
    setModalOpen(true);
  };
  const handleDownloadSubcontractorDocument = () => {
    fetch(attachmentData?.file).then(response => {
      response.blob().then(blob => {
        const fileURL = window.URL.createObjectURL(blob);
        const name = 'Sample.' + attachmentData?.ext;
        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = name;
        alink.click();
      });
    });
  };

  const handleCloseViewDocModal = () => {
    setModalOpen(false);
    setAttachmentData({ ext: null, file: null });
  };

  const printFile = () => {
    window.print();
  };

  return (
    <div className="incident-report-details-div">
      <div className="subcontractor-view-documents-table-div">
        <div className="table-responsive">
          {props.incident_attachment && props.incident_attachment.length > 0 ? (
            <Table>
              <thead className="globalTableHeaderGradientColor">
                <tr className="subcontractor-documentsTableHeaderRow">
                  <th className="subcontractor-documentsTableHeaderCell subcontractorHeaderDocumentCell">Attachment</th>
                  <th className="subcontractor-documentsTableHeaderCell subcontractorHeaderDescriptionCell">Description</th>
                  <th className="subcontractor-documentsTableHeaderCell subcontractorHeaderActionsCell">Actions</th>
                </tr>
              </thead>
              <tbody>
                {props.incident_attachment
                  ? props.incident_attachment.map((docs, i) => (
                      <tr key={`entity-${i}`} data-cy="entityTable" className="subcontractor-documentsTableBodyRows">
                        <td className="subcontractor-documentsTableBodyCell subcontractorTableBodyDocumentcell">
                          <dd>{`Attachment-${i + 1}`}</dd>
                        </td>
                        <td className="subcontractor-documentsTableBodyCell subcontractorTableBodyDescriptioncell">
                          <dd>{docs.attachment_description}</dd>
                        </td>
                        <td className="text-right subcontractor-documentsTableBodyCell subcontractorTableBodyActioncell">
                          <div className="btn-group serviceAreaActionCellButtons">
                            <>
                              <IconButton
                                className="departmentButton"
                                size="small"
                                style={{ margin: '1%' }}
                                data-cy="entityEditButton"
                                onClick={() => handleViewIncidentAttachment(docs)}
                              >
                                <img src="content/images/View1.png" />
                              </IconButton>
                              &nbsp;
                              <IconButton className="departmentButton" size="small" style={{ margin: '1%' }} data-cy="entityDeleteButton">
                                <img src="content/images/Trash1.png" />
                              </IconButton>
                            </>
                          </div>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </Table>
          ) : null}

          <Modal open={modalOpen} className="file-view-modal">
            <Paper style={{ width: '100%', height: '100%' }}>
              <div style={{ padding: '20px' }}>
                <IconButton
                  className="downloaANdPrintButton"
                  size="small"
                  style={{ marginLeft: '48%' }}
                  onClick={() => {
                    handleDownloadSubcontractorDocument();
                  }}
                >
                  <img src="content/images/icons8-download-24.png" />
                </IconButton>
                <IconButton
                  className="downloaANdPrintButton"
                  size="small"
                  style={{ marginLeft: '2%' }}
                  onClick={() => {
                    printFile();
                  }}
                >
                  <img src="content/images/icons8-print-24.png" />
                </IconButton>
                <CloseIcon style={{ marginLeft: '40%' }} onClick={handleCloseViewDocModal}></CloseIcon>{' '}
              </div>
              <div className="file-viewer-style">
                <FileViewer fileType={attachmentData?.ext} filePath={attachmentData?.file} />
              </div>
            </Paper>
          </Modal>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ person }: IRootState) => ({});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(IncidentDocuments);
