import MomentUtils from '@date-io/moment';
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import SearchBar from 'material-ui-search-bar';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import CustomToolbarComponent from '../service-area/CustomToolbarComponent';
import './leave-apply.scss';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import { getLeaveTypes, getLeaveCategory, getLeavePayment } from '../leave-types/leave.type.reducer';
import { getEntities as getPerson } from '../person/person.reducer';
import { storeLeave } from './leave.reducer';
import { Autocomplete } from '@material-ui/lab';
import { dateFTConverter } from '../../../utils/TimeFormatAndTimezone';
import { Link, RouteComponentProps } from 'react-router-dom';

export const LeaveApply = props => {
  const [leaveType, setLeaveType] = useState([props.leaveType]);
  const [leaveCategory, setLeaveCategory] = useState(props.leaveCategory);
  const [leavePayment, setLeavePayment] = useState(props.leavePayment);
  const [personArray, setPersonArray] = useState([props.people]);

  const [annualLeaveType, setAnnualLeaveType] = useState(false);
  const [emergencyLeaveType, setemergencyLeaveType] = useState(true);
  const [otherType, setOtherType] = useState(false);
  const [halfDayType, setHalfDayType] = useState(false);
  const [multipleType, setMultipleType] = useState(false);
  const leaveTypeArray = ['Annual Leave', 'Emergency/ Sick', 'Other'];
  const annualTypeArray = ['Half Day', 'One Day', 'Multiple'];
  const [values, setValues] = useState([]);
  const i = 1;

  useEffect(() => {
    props.getLeaveTypes();
    props.getLeaveCategory();
    props.getLeavePayment();
    props.getPerson(-1, '');
  }, []);

  useEffect(() => {
    setLeaveType(props.leaveType);
    setLeaveCategory(props.leaveCategory);
    setLeavePayment(props.leavePayment);
    setPersonArray(props.people);
  }, [props.leaveCategory, props.leaveType, props.leavePayment, props.people]);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const handleClose = () => {
    props.history.push('/Leave');
  };

  const handleLeaveType = (event, leaveTypeObj) => {
    setValues(oldValues => ({ ...oldValues, leave_type: leaveTypeObj }));
  };

  const typeButtonClass = x => {
    if (x === values['leave_type']) {
      return 'typeSelected';
    } else {
      return 'typeNotSelected';
    }
  };

  const categoryButtonValue = x => {
    if (x === values['leave_category']) {
      return 'typeSelected';
    } else {
      return 'typeNotSelected';
    }
  };

  const paymentButtonValue = x => {
    if (x === values['leave_payment']) {
      return 'typeSelected';
    } else {
      return 'typeNotSelected';
    }
  };

  const handleCategory = (event, x) => {
    setValues(oldValues => ({ ...oldValues, leave_category: x }));
  };

  const handlePayment = (event, x) => {
    setValues(oldValues => ({ ...oldValues, leave_payment: x }));
  };

  const handleLeaveStartDate = e => {
    const d = moment(e).format('YYYY-MM-DD');
    setValues(oldValues => ({ ...oldValues, start_date: d }));
  };

  const handleLeaveEndDate = e => {
    const d = moment(e).format('YYYY-MM-DD');
    setValues(oldValues => ({ ...oldValues, end_date: d }));
  };

  const handlePerson = (_, e) => {
    setValues(prevState => ({ ...prevState, person: e }));
  };

  const handleRemarks = event => {
    setValues(prevState => ({ ...prevState, remarks: event.target.value }));
  };

  const saveEntity = event => {
    props.storeLeave(values);
  };

  return (
    <React.Fragment>
      <div className="leave-apply-container">
        <Typography variant="h5" className="leaveTopTitle">
          Create New Leave Application
        </Typography>

        <Paper className="personLeavePaper">
          {/* <div className="personLeaveDivsideBySide"> */}

          <span>Choose Leave Type</span>

          <Grid container style={{ marginTop: '15px' }}>
            {leaveType && leaveType.length > 0
              ? leaveType.map(x => (
                  <Grid item className="modal-input" key={i} xs={2} lg={2} md={2}>
                    <FormControl
                      style={{ width: '90%', marginTop: '20px' }}
                      className="incident-type-buttons"
                      variant="outlined"
                      size="small"
                    >
                      <Button
                        onClick={() => {
                          handleLeaveType(event, x);
                        }}
                        key={i}
                        className={typeButtonClass(x)}
                        style={{
                          border: '2px solid #D4D4D4',
                        }}
                      >
                        {x.name}
                      </Button>
                    </FormControl>
                  </Grid>
                ))
              : null}
          </Grid>

          <br></br>
          <br></br>

          <span style={{ marginTop: '25px' }}>Choose Leave Category</span>
          <Grid container style={{ marginTop: '15px' }}>
            {leaveCategory && leaveCategory.length > 0
              ? leaveCategory.map(x => (
                  <Grid item className="modal-input" key={i} xs={2} lg={2} md={2}>
                    <FormControl style={{ width: '90%' }} className="incident-type-buttons" variant="outlined" size="small">
                      <Button
                        onClick={() => {
                          handleCategory(event, x);
                        }}
                        key={i}
                        className={categoryButtonValue(x)}
                        style={{
                          border: '2px solid #D4D4D4',
                        }}
                      >
                        {x.name}
                      </Button>
                    </FormControl>
                  </Grid>
                ))
              : null}
          </Grid>

          <br></br>
          <br></br>

          <div style={{ marginTop: '30px' }}>
            <Grid item xs={12} md={8} sm={12} style={{ marginBottom: '20px' }}>
              <Autocomplete
                options={
                  personArray &&
                  personArray.map(entity => ({
                    id: entity.id,
                    name: entity.first_name + ' ' + entity.last_name,
                  }))
                }
                noOptionsText={'No Person Found'}
                classes={{ listbox: 'autocompletelistbox' }}
                ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                getOptionLabel={option => option?.name}
                getOptionSelected={(option, value) => option.id === value.id}
                className="PersonAutocomplete"
                onChange={handlePerson}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    className="personAutocompleteTextfield autocompleteLabel"
                    margin="normal"
                    label="Employee Name"
                    required
                    fullWidth
                  />
                )}
              />
            </Grid>
          </div>

          <div style={{ marginTop: '40px' }}>
            <span>Leave Date Range</span>
            <br></br>
            <br></br>

            <Grid container spacing={2}>
              <Grid item xs={3} lg={3} md={3}>
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                  <KeyboardDatePicker
                    autoOk
                    id="date_of_birth"
                    variant="inline"
                    size="small"
                    label="Start Date"
                    className="form-control"
                    inputVariant="outlined"
                    name="date_of_birth"
                    labelFunc={event => dateFTConverter(event, props.setting.date_format)}
                    InputLabelProps={{ shrink: true }}
                    value={values && values['start_date'] ? values['start_date'] : null}
                    ToolbarComponent={prop => <>{<CustomToolbarComponent {...prop} settingFormat={props.setting.date_format} />}</>}
                    onChange={date => handleLeaveStartDate(date)}
                    style={{
                      width: '100%',
                    }}
                  />{' '}
                </MuiPickersUtilsProvider>
              </Grid>

              {values &&
              values['leave_type'] &&
              values['leave_category'] &&
              values['leave_type'].name === 'Annual' &&
              values['leave_category'].name === 'Full Day' ? (
                <Grid item xs={3} lg={3} md={3}>
                  <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                    <KeyboardDatePicker
                      autoOk
                      id="date_of_birth"
                      variant="inline"
                      size="small"
                      label="End Date"
                      className="form-control"
                      inputVariant="outlined"
                      name="date_of_birth"
                      InputLabelProps={{ shrink: true }}
                      labelFunc={event => dateFTConverter(event, props.setting.date_format)}
                      value={values && values['end_date'] ? values['end_date'] : null}
                      ToolbarComponent={prop => <>{<CustomToolbarComponent {...prop} settingFormat={props.setting.date_format} />}</>}
                      onChange={date => handleLeaveEndDate(date)}
                      style={{
                        width: '100%',
                      }}
                    />{' '}
                  </MuiPickersUtilsProvider>
                </Grid>
              ) : null}

              {/* <Grid container style={{ marginTop: '15px' }}> */}
              {leavePayment && leavePayment.length > 0
                ? leavePayment.map(x => (
                    <Grid item className="modal-input" key={i} xs={2} lg={2} md={2}>
                      <FormControl style={{ width: '90%' }} className="incident-type-buttons" variant="outlined" size="small">
                        <Button
                          onClick={() => {
                            handlePayment(event, x);
                          }}
                          key={i}
                          className={paymentButtonValue(x)}
                          style={{
                            border: '2px solid #D4D4D4',
                          }}
                        >
                          {x.name}
                        </Button>
                      </FormControl>
                    </Grid>
                  ))
                : null}
              {/* </Grid> */}
            </Grid>
          </div>

          <div>
            <Grid item lg={12} xs={12} sm={12} style={{ marginTop: '40px' }}>
              <FormControl variant="outlined" className="leave-remarks-field">
                Remarks(Optional)
                <TextField
                  variant="outlined"
                  size="small"
                  margin="normal"
                  onChange={() => handleRemarks(event)}
                  value={values && values['police_involvement_comments'] ? values['police_involvement_comments'] : null}
                  multiline
                  rows={6}
                  fullWidth
                />
              </FormControl>
            </Grid>
          </div>

          <Grid item xs={12} md={12} sm={12} style={{ textAlign: 'center', marginTop: '50px' }}>
            <FormControl style={{ display: 'inline-block', marginLeft: '60%' }}>
              <Button variant="outlined" className="incidentDiscardButton" component={Link} to="/Leave">
                Cancel
              </Button>
              &nbsp;
              <Button
                onClick={() => {
                  saveEntity(event);
                }}
                variant="contained"
                id="save-entity"
                type="submit"
                className="leaveSaveButton"
              >
                Apply
              </Button>
            </FormControl>
          </Grid>
        </Paper>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  leaveType: storeState.LeaveType.leave_type_list,
  leaveCategory: storeState.LeaveType.leave_category_list,
  leavePayment: storeState.LeaveType.leave_payment_list,
  people: storeState.person.entities,
  setting: storeState.PersonalSettings.storedPersonalSettings,
  updateSuccess: storeState.Leave.updateSuccess,
});

const mapDispatchToProps = {
  getLeaveTypes,
  getLeaveCategory,
  getLeavePayment,
  getPerson,
  storeLeave,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(LeaveApply);
