import Modal from '@material-ui/core/Modal';
import React, { useState, useEffect } from 'react';
import { Paper, TextField, Grid, Button, FormLabel, FormControl} from '@material-ui/core';
import {  RouteComponentProps } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import SignatureCanvas from 'react-signature-canvas';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import { getPersonByUId } from 'app/entities/person/person.reducer';
import {storeVettingSignOff} from 'app/modules/employeeApplicationForm/applicationForm.reducer';
import VettingSignOffRadioGroup from './signOffRadioComponent';

export interface IVettingSignOffComponent extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}
function VettingSignOff (props) {

  const [signaturePad, setSignaturePad] = useState(null);
  const [signature, setSignature] = useState(null);
  const [person, setPerson] = useState(null);
  const [selectedValues, setSelectedValues] = useState({
    documentCheck: null,
    relationshipCheck: null,
    addressCheck: null,
    comments : ''
  });
  const [signatureError, setSignatureError] = useState('');
  const [fieldError, setFieldError] = useState({
    documentCheck: false,
    documentCheckHelper: 'Required',
    relationshipCheck: false,
    relationshipCheckHelper: 'Required',
    addressCheck: false,
    addressCheckHelper: 'Required',
  });

  useEffect(() => {
    const uid = JSON.parse(localStorage.getItem('user_id'));
    props.getPersonByUId(uid);
  }, []);

  useEffect(() => {
    setPerson(props.person);
  }, [props.person]);


  const handleClose = () => {
    props.setVettingSignOffModalOpen(false);
  };

  const clearSignature = () => {
    signaturePad.clear();
    setSignatureError('');
  };

  const saveSignature = () => {
    const canvas = signaturePad.getCanvas();
    canvas.toBlob(blob => {
      setSignature(blob);
    });
    setSignatureError('');
  };


  const handleChange = (name) => (event) => {
    setSelectedValues({
      ...selectedValues,
      [name]: event.target.value,
    });
    if (event.target.value !== null && event.target.value !== undefined) {
      setFieldError((prevErrors) => ({
        ...prevErrors,
        [name]: false,
      }));
    }
  };


  const handleComments =  (event) => {
    setSelectedValues({
      ...selectedValues,
      ['comments']: event.target.value,
    });
  };

  const checkValidationsBeforeSubmit = () => {
    if (selectedValues.documentCheck === null || selectedValues.documentCheck === undefined) {
      setFieldError(oldValues => ({ ...oldValues, documentCheck: true }));
    }
    if (selectedValues.relationshipCheck === null || selectedValues.relationshipCheck === undefined) {
      setFieldError(oldValues => ({ ...oldValues, relationshipCheck: true }));
    }
    if (selectedValues.addressCheck === null || selectedValues.addressCheck === undefined) {
      setFieldError(oldValues => ({ ...oldValues, addressCheck: true }));
    }
  }
  

  const allowedToSubmit = () => {
    checkValidationsBeforeSubmit();
    if (
      !fieldError['documentCheck'] &&
      !fieldError['relationshipCheck'] &&
      !fieldError['addressCheck'] &&
      selectedValues['documentCheck'] !== undefined &&
      selectedValues['relationshipCheck'] !== undefined &&
      selectedValues['addressCheck'] !== undefined
    ){
    return true;
    }else {
      return false;
    }
  }

  const submit = () => {
    if (allowedToSubmit()) {
      if (signature) { 
        const object = new FormData();
        object.append('signature', signature);
        object.append('person_id', props.person.id);
        object.append('applicant_id', props.applicant_id);
        object.append('document_check', selectedValues.documentCheck);
        object.append('relationship_check', selectedValues.relationshipCheck);
        object.append('address_check', selectedValues.addressCheck);
        object.append('comments', selectedValues.comments);
        props.storeVettingSignOff(object);
        props.setVettingSignOffModalOpen(false);
      }else{
        setSignatureError('Please provide your signature before Sign-off.');
      }
    }
  };



  return (
    <Modal open={props.open} onClose={handleClose} className="create-modal">
        <div style={{ display: 'flex',justifyContent: 'center', alignItems: 'center', height: '100vh' }} >
            <Paper style={{ width: '40%', padding: '20px', position: 'relative'}}>
                <CloseIcon style={{ position: 'absolute',top: '10px',right: '10px',cursor: 'pointer'}} onClick={handleClose} />
                
                <div style={{color: "rgb(146, 78, 228)", fontSize : "22px", fontWeight : "bold", textAlign : 'center', marginBottom: '20px'}}>Vetting Sign Off Form</div>
               <Grid container spacing={2}>   
                 <Grid item lg={5} sm={6}>      
                  <TextField
                    id="address-addressUnitIdentifierValue"
                    data-cy="addressUnitIdentifierValue"
                    className="address_identifier"
                    variant="outlined"
                    name="addressUnitIdentifierValue"
                    label= 'Name'
                    size="small"
                    value={person?.first_name && person?.last_name ? `${person.first_name} ${person.last_name}` : null}
                  />
                  </Grid>
                
                <Grid item lg={5} sm={6}>
                  <TextField
                    id="address-addressUnitIdentifierValue"
                    data-cy="addressUnitIdentifierValue"
                    className="address_identifier"
                    variant="outlined"
                    name="addressUnitIdentifierValue"
                    label= 'Position'
                    size="small"
                    value={person?.designations?.length > 0 ? person.designations[0].name : null}
                  />
                  </Grid>
               </Grid>   
              <div style={{ borderBottom: fieldError['documentCheck'] ? '1px solid red' : '' }}>
              <VettingSignOffRadioGroup
                label="Checked the exact dates on all documents with new recruitment to ensure no gap of more than 31 days is identified"
                name="documentCheck"
                value={selectedValues.documentCheck}
                onChange={handleChange('documentCheck')}
              />
              </div>
              <div style={{ borderBottom: fieldError['relationshipCheck'] ? '1px solid red' : '' }}>
              <VettingSignOffRadioGroup
                label="Identified the relationship between the applicant and the referee?"
                name="relationshipCheck"
                value={selectedValues.relationshipCheck}
                onChange={handleChange('relationshipCheck')}
              />
              </div>
              <div style={{ borderBottom: fieldError['addressCheck'] ? '1px solid red' : '' }}>
              <VettingSignOffRadioGroup
                label="Verified the referees home address to ensure it is genuine"
                name="addressCheck"
                value={selectedValues.addressCheck}
                onChange={handleChange('addressCheck')}
              />
              </div>
             <div className="control-room-remarks-div" style={{marginTop : '20px'}}>
                <Grid item lg={12} xs={12} sm={12}>
                <FormLabel component="legend" style={{ color: 'black' }}>
                    Additional Comments
                  </FormLabel>
                  <FormControl variant="outlined" className="incident-details-field">
                    <TextField
                      variant="outlined"
                      margin="normal"
                      multiline
                      fullWidth
                      onChange={handleComments}
                    />
                  </FormControl>
                </Grid>
            </div>

               <div style={{fontSize : "16px", textAlign : 'center', marginTop : "10px"}}>Please draw your signature here</div>
              <div className="vetting-consent-applicant-signature-div" style = {{ backgroundColor: 'rgb(233 233 233)' }}>

              <button onClick={clearSignature} style={{ width : '90px', height : '40px', float: 'right', marginTop: '10px', marginRight: '10px' }}>
                  Try Again
                </button>

                <SignatureCanvas
                  penColor="black"
                  canvasProps={{ width: 1000, height: 100, className: 'sigCanvas' }}
                  ref={ref => {
                    setSignaturePad(ref);
                  }}
                  onEnd={() => saveSignature()} 
                />
              </div>
              {signatureError && (
                    <div style={{ color: 'red', marginTop: '0.5rem' }}>
                      {signatureError}
                    </div>
                  )}

              <div style={{ width: "100%", display: 'flex', justifyContent: 'flex-end', gap: '10px', padding: '10px' }}>
                <Button className={'signatureModalCancelButton'} onClick={handleClose}  >Cancel</Button>
              
                <Button onClick={submit} className={'signatureModalSubmitButton'} >
                      Sign-off
                </Button>
              </div>

            </Paper>
        </div>
    </Modal>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  person : storeState.person.entity
});

const mapDispatchToProps = {
  getPersonByUId,
  storeVettingSignOff
 
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(VettingSignOff);
